import React from "react";
import _ from "lodash";
import { styled } from "@mui/system";
import { Select, MenuItem } from "@mui/material";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { StyledBadge } from "features/metadesk/importList/ImportUserList.styles";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const StyledSelect = styled(Select)(
  ({ theme, size, border, noExpandedBorder, error }) => ({
    background: "white",
    minWidth: 90,
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    fontWeight: 500,
    fontFamily: "Inter",

    ...(error && {
      background: "#FFDED7",
      border: "1px solid #FC5555",
    }),
    ...(size === SIZE.XL && { height: 40 }),
    ...(size === SIZE.LARGE && { height: 36 }),
    ...(size === SIZE.MEDIUM && {
      height: 32,
      minWidth: 50,
      padding: "0 2px",
    }),
    ...(size === SIZE.SMALL && {
      height: 28,
      padding: "0 2px",
      minWidth: 50,
    }),
    ...(size === SIZE.XS && {
      height: 24,
      padding: "0 2px",
      minWidth: 30,
    }),
    ...(size === SIZE.XSS && {
      height: 16,
      padding: "0 0px",
      minWidth: 30,
    }),
    "& .MuiSelect-select": {
      paddingRight: "32px !important",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.customColors.purple[500],
        ...(!border && {
          borderColor: "transparent",
        }),
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.customColors.grey[300]}`,
      ...(!border && {
        border: "none",
        borderColor: "transparent",
      }),
    },

    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.customColors.purple[500],
      },
    },
    "& .MuiSelect-icon": {
      right: 0, // Adjust the position of the icon
    },
  })
);

const StyledMenuItem = styled(MenuItem)({
  fontSize: 12,
  fontWeight: 500,
  "&.Mui-selected": {
    backgroundColor: "#F7F1FE",
    color: "#383538",
    "&:hover": {
      backgroundColor: "#F7F1FE",
    },
    "&:focus": {
      backgroundColor: "#F7F1FE",
    },
  },
});

const Input = styled(Box)({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "28px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
});

const Label = styled("div")({
  marginBottom: 6.5,
  fontWeight: 500,
  fontSize: 14,
});

const ErrorText = styled("div")({
  color: "red",
});

export const CustomDropdown = ({
  data,
  onSelect,
  value,
  style,
  sm,
  size = SIZE.XL,
  label,
  border,
  noExpandedBorder,
  renderInput,
  fullWidth,
  disabled,
  placeholder,
  render,
  error,
  multipleValues = false,
  limitCount,
  multiple,
  preText,
  readonly = false,
  ...props
}) => {
  if (sm) size = SIZE.MEDIUM;

  const renderInputContent = (selected, match) => {
    if (multiple && limitCount) {
      return (
        <div className="startRow">
          {match.map((value, i) => {
            if (i > limitCount - 1) return null;
            if (value)
              return renderInput
                ? renderInput(value)
                : _.get(value, "label", "");
          })}
          {match.length > limitCount && (
            <StyledBadge badgeContent={selected.length - 1} color="secondary" />
          )}
        </div>
      );
    }
    return renderInput ? renderInput(match) : _.get(match, "label", "");
  };

  if (readonly) {
    let match = _.find(data, ["value", value]);
    if (multiple) {
      match = _.filter(data, (o) => {
        return _.includes(value, o._id);
      });
    }
    if (multipleValues) {
      match = _.find(data, ["values", value]);
    }

    return (
      <div>
        {label && <Label>{label}</Label>}
        <Input>
          {preText}
          {renderInputContent(value, match)}
        </Input>
      </div>
    );
  }

  return (
    <div>
      {label && <Label>{label}</Label>}
      <StyledSelect
        disabled={disabled || data.length === 0}
        value={value}
        multiple={multiple}
        fullWidth={fullWidth}
        size={size}
        border={border}
        noExpandedBorder={noExpandedBorder}
        error={error}
        style={{ ...style }}
        onChange={onSelect}
        displayEmpty={true}
        renderValue={(selected) => {
          if (!value || !selected) {
            return (
              <Box color={(theme) => theme.customColors.grey[700]}>
                {placeholder ?? ""}
              </Box>
            );
          }
          let match = _.find(data, ["value", selected]);
          if (multiple) {
            match = _.filter(data, (o) => {
              return _.includes(selected, o._id);
            });
          }
          if (multipleValues) {
            match = _.find(data, ["values", selected]);
          }

          return (
            <Input>
              {preText}
              {renderInputContent(selected, match)}
            </Input>
          );
        }}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          MenuListProps: { disablePadding: true },
          PaperProps: {
            sx: {
              zIndex: 1,
              color: "#383538",
              fontWeight: 500,
              borderRadius: "3px",
              maxHeight: 300,
              "&& .Mui-selected": {
                backgroundColor: "#F7F1FE",
                color: "#383538",
                "&:hover": {
                  backgroundColor: "#F7F1FE",
                },
              },
            },
          },
        }}
        {...props}
      >
        {_.map(data, (d) =>
          render ? (
            render(d)
          ) : (
            <StyledMenuItem
              key={multipleValues ? _.get(d, "label") : _.get(d, "value")}
              value={multipleValues ? _.get(d, "values") : _.get(d, "value")}
              disabled={_.get(d, "disabled")}
            >
              {_.get(d, "label")}
            </StyledMenuItem>
          )
        )}
      </StyledSelect>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default CustomDropdown;
