import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledTag = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, backgroundColor }) => ({
  color: theme.customColors.grey[800],
  display: "flex",
  alignItems: "center",
  height: "20px",
  margin: "2px",
  lineHeight: "22px",
  backgroundColor: backgroundColor
    ? backgroundColor
    : theme.customColors.grey[400],
  borderRadius: "3px",
  boxSizing: "border-box",
  padding: "2px 5px",
  outline: "0",
  overflow: "hidden",

  "& span": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "& svg": {
    fontSize: "12px",
    cursor: "pointer",
    padding: "4px",
  },

  "& .MuiButtonBase-root": {
    "& i": {
      color: theme.customColors.grey[800],
    },
  },
}));
