import { createSelector } from "reselect";

const selectMacroReducer = (state) => {
  return state.metadesk.macro;
};

export const selectCategoriesInfo = createSelector(
  [selectMacroReducer],
  (macro) => macro.categoriesInfo
);

export const selectMacroCategories = createSelector(
  [selectCategoriesInfo],
  (categoriesInfo) => categoriesInfo.categories
);

export const selectMacroCategoryLength = createSelector(
  [selectCategoriesInfo],
  (categoriesInfo) => categoriesInfo.totalCount
);

export const selectMacroTags = createSelector(
  [selectMacroReducer],
  (macro) => macro.tags
);

export const selectMacroList = createSelector(
  [selectMacroReducer],
  (macro) => macro.macroList
);

export const selectMacroInfo = createSelector(
  [selectMacroReducer],
  (macro) => macro.macroInfo
);

export const selectMacroIsLoading = createSelector(
  [selectMacroReducer],
  (macro) => macro.isLoading
);

export const selectMacroIsEditLoading = createSelector(
  [selectMacroReducer],
  (macro) => macro.isEditLoading
);
