import React, { memo } from "react";
import { Grid } from "@mui/material";

const CardTitle = ({ data }) => {
  return (
    <Grid container alignItems="center" className={"cardTitle"}>
      {data.map((cell, index) => (
        <Grid key={index} item className={cell.className}>
          {/* {cell.icon && <img alt="" src={require("assets/img/" + cell.icon)} className="icon" />} */}
          {cell.title && <div>{cell.title}</div>}
          {/* <span>{cell.label}</span> */}
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(CardTitle);
