import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import _ from "lodash";
import { useOutletContext, useNavigate, Link } from "react-router-dom";
import { Button, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";
import { apiUrl } from "features/configure";
import axios from "axios";
import { useIntl } from "react-intl";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import moment from "moment";
import SortDropDown from "./components/SortDropDown";
import StatusBox from "./components/StatusBox";
import useGetIntegrationArr from "./useGetIntegrationArr";
import FilterDropDown from "./components/FilterDropDown";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { formatDate } from "features/helpers/utils";

const Integration = () => {
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();
  const { fetchWidgetConfig } = useFetchWidgetConfig();
  const navigate = useNavigate();
  const [entityName] = useOutletContext();
  const [sortKey, setSortKey] = useState(""); // 新增排序鍵值
  const [sortOrder, setSortOrder] = useState("Ascending"); // 新增排序順序
  const [selectedFilters, setSelectedFilters] = useState([]); // 新增多選 filter 狀態
  const [statusFilters, setStatusFilters] = useState([]); // 新增多選 Status filter 狀態

  const setSortCondition = (key, order) => {
    setSortKey(key);
    setSortOrder(order);
  };

  // 定義排序函數
  const applySort = (arr, key, order) => {
    if (!key) return arr; // 如果沒有選擇排序鍵值，直接返回
    let sortedArr = _.cloneDeep(arr);
    switch (key) {
      case "Name":
        sortedArr = _.sortBy(arr, ["title"]);
        break;
      case "Status":
        sortedArr = _.sortBy(arr, ["status"]);
        break;
      case "Last Updated":
        sortedArr = _.sortBy(arr, (item) => moment(item.updatedAt).valueOf());
        break;
      default:
        break;
    }

    if (order === "Descending") {
      sortedArr.reverse();
    }
    return sortedArr;
  };

  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
    fetchWidgetConfig({ entityName });
  }, []);

  const { formatMessage } = useIntl();

  const integrationWayArr = useGetIntegrationArr({
    combinedWidgetConfig: true,
  });

  // 使用 useMemo 來優化篩選和排序操作
  const FilterAndSortedArr = useMemo(() => {
    const FilterArr = integrationWayArr.filter((item) => {
      // 未連接狀態為 not-connected 的項目不顯示
      if (item.status === "not-connected") return false;
      // 依據 filter 過濾
      if (selectedFilters.length > 0 && !selectedFilters.includes(item.title))
        return false;
      // 依據 Status 過濾
      if (statusFilters.length > 0 && !statusFilters.includes(item.status))
        return false;

      return true;
    });
    return applySort(FilterArr, sortKey, sortOrder);
  }, [integrationWayArr, sortKey, sortOrder, selectedFilters, statusFilters]);

  return (
    <div>
      <div className="title">
        {formatMessage({ id: "integration.header.title" })}
      </div>
      <div className="description">
        {formatMessage({ id: "integration.header.description" })}
        {entityConfig.demoSite && (
          <Button
            color={COLOR.INFO}
            style={{ marginLeft: 10, minWidth: 0, padding: 10 }}
            onClick={async () => {
              try {
                await axios.post(apiUrl + "/api/manage/integration/reset", {
                  entityName: entityName,
                });
                fetchEntityConfig({ entityName });
              } catch (error) {
                console.log("error reset integration", error);
              }
            }}
          >
            Reset
          </Button>
        )}
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m={"40px 0 8px"}
      >
        <Box sx={{ fontSize: "14px", fontWeight: 700, color: "#777" }}>
          My Integration(s)
        </Box>
        {/* filter and sort  */}
        <Box sx={{ display: "flex", alignItems: "stretch" }}>
          <SortDropDown setSortCondition={setSortCondition} />
          <FilterDropDown
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters} // 傳遞 status filter 狀態管理
          />
          <Box
            width="1px"
            bgcolor="#A5A5A5"
            mx="12px"
            height="32px"
            my="2px"
          ></Box>
          <Link to="add">
            <Button>Add Integration</Button>
          </Link>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {FilterAndSortedArr.map((integrationWayItem, index) => {
          const {
            auth,
            icon,
            title,
            link,
            setting,
            description,
            updatedAt,
            status,
          } = integrationWayItem;

          if (!auth) return null;
          return (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <StyledWhiteContainer
                key={index}
                sx={{
                  marginBottom: "0px",
                  cursor: setting ? "pointer" : "default",
                  minHeight: "120px",
                  width: "100%",
                  ":hover": {
                    backgroundColor: "#FAF7FF",
                  },
                }}
                onClick={
                  // handleClickIntegration(setting, status === "Setup Incomplete")
                  setting ? setting : () => {}
                }
              >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  {icon}
                  <Box
                    ml="16px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="80px"
                  >
                    <Box flex={1}>
                      <div className="subTitle">{title}</div>
                      <StatusBox status={status} />
                    </Box>
                    <Box>
                      {link === "widgetSupport" && (
                        <Box color="#777" fontSize="12px" fontWeight={400}>
                          {description}
                        </Box>
                      )}
                      <Box color="#A5A5A5" fontSize="10px" mt="4px">
                        {updatedAt ? formatDate(updatedAt) : "N/A"}
                      </Box>
                    </Box>
                  </Box>
                </div>
              </StyledWhiteContainer>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#7B61FF",
              height: "100%",
              width: "100%",
              cursor: "pointer",
              border: "1px dashed #7B61FF",
              borderRadius: "4px",
              height: "120px",
              ":hover": {
                backgroundColor: "#FAF7FF",
              },
            }}
            onClick={() => navigate("add")}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              <i className="meta-crm-icon-ic_add font-size-16" />
              <Box ml="4px">Add Integration</Box>{" "}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Integration;
