import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledSlackConfigContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.customColors.grey[300]}`,
  padding: "20px 20px",
  flex: 1,
  marginRight: 16,
  background: "white",
}));

export const StyledIntegrationWrapper = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isSetting }) => ({
  ...(!isSetting && {
    padding: "60px 100px",

    [theme.breakpoints.down("md")]: {
      padding: "40px 20px",
    },
  }),
}));
