import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import TextField from "@mui/material/TextField";

export const StyledRadioButton = styled(Radio, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "3px",
  marginRight: "4px",
}));

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginRight: 0,
  marginLeft: 0,
}));

export const StyledRadioIcon = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  borderRadius: "50%",
  width: 18,
  height: 18,
  border: `2px solid ${theme.customColors.purple[500]}`,
  backgroundColor: "#f5f8fa",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const getSVG = (color) =>
  `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.5655 4.24464L6.21755 12.2292L3 8.18214L4.56553 6.9375L6.21755 9.01543L11 3L12.5655 4.24464Z" fill="${encodeURIComponent(
    color
  )}"/></svg>')`;

export const StyledRadioCheckedIcon = styled(StyledRadioIcon, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  backgroundColor: theme.customColors.purple[500],
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    transform: " translateX(-0.5px)",
    backgroundImage: getSVG(theme.customColors.white),
    content: '""',
  },
}));

export const StyledRadioLabel = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.customColors.grey[700],
}));

export const StyledCollapseContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginTop: "8px",
}));

export const StyledFieldTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isRequired }) => ({
  color: theme.customColors.grey[700],
  marginBottom: "4px",
  ...(isRequired && {
    "&::after": {
      content: "'*'",
      marginLeft: "5px",
      color: theme.customColors.pink[600],
    },
  }),
}));

export const StyledCustomDropdown = styled(CustomDropdown, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiSelect-root": {
    padding: "3px 10px",
  },
}));

export const StyledErrorHint = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  color: theme.customColors.pink[600],
  bottom: "-20px",
}));

export const StyledPurpleTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 700,
  color: theme.customColors.purple[500],
  marginBottom: "8px",
}));

export const StyledFetchABIButton = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, disabled }) => ({
  padding: "7.5px",
  border: `1px solid ${theme.customColors.purple[500]}`,
  color: theme.customColors.purple[500],
  borderRadius: "3px",
  boxShadow: "1px 1px 1px 0px #0000001A",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "8px",
  marginBottom: "24px",
  display: "block",
  width: "100%",
  background: "unset",

  ...(disabled && {
    border: `1px solid ${theme.customColors.grey[500]}`,
    color: theme.customColors.grey[500],
    cursor: "default",
  }),
}));

export const StyledCustomPaper = styled(Paper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiAutocomplete-listbox": {
    "& .MuiAutocomplete-option[aria-selected=true]": {
      backgroundColor: theme.customColors.purple[300],

      "&.Mui-focused": {
        backgroundColor: theme.customColors.purple[300],
      },
    },
  },
}));

export const StyledAutoCompleteTextField = styled(TextField, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: theme.customColors.grey[700],
      opacity: 1,
    },
  },
}));

export const StyledAddParameterArea = styled(Grid, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledAddParameterBtn = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  border: `1px dashed ${theme.customColors.grey[300]}`,
  borderRadius: "3px",
  height: "36px",
  cursor: "pointer",
  color: theme.customColors.grey[500],
  display: "flex",
  alignItems: "center",
  padding: "12px 14px",
}));
