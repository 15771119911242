import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import axios from "axios";
import { apiUrl } from "features/configure";
import { makeCustomTrackingLink } from "../utils";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useNavigateEntity from "hooks/useNavigateEntity";
import { useFetchTicketList } from "features/metadesk/redux/fetchTicketList";
import _ from "lodash";
import moment from "moment";

export default function useCampaignSave() {
  const { navigateEntity, entityName } = useNavigateEntity();

  const dispatchSnackbar = useDispatchSnackbar();
  const { stepData, shouldNavigate, setShouldNavigate, isTracker, setLoading } =
    useContext(CampaignCreationContext);
  const { campaignId } = useParams();

  async function checkName({ fakeCampaignName = "" } = {}) {
    try {
      await axios.post(`${apiUrl}/api/campaign/${entityName}/check`, {
        name: stepData.name ? stepData.name : fakeCampaignName,
        campaignId,
      });
      return true;
    } catch (err) {
      dispatchSnackbar({
        err,
      });
      return false;
    }
  }

  async function publishCampaign({ isDraft, fakeCampaignName }) {
    try {
      setLoading(true);
      const newStepData = {
        ...stepData,
        name: stepData.name ? stepData.name : fakeCampaignName,
      };

      const reqData = {
        ...newStepData, // Spread operator to copy existing properties
        campaignContentSets: stepData.campaignContentSets?.map(
          (set, tabIndex) => {
            return {
              ...set,
              customWebsiteUrl: set.customWebsiteUrl?.trim(),
              customLinkSettings: set.customLinkSettings?.map((setting) => ({
                ...setting,
                mediums: setting.mediums.map((item) => item.value),
                terms: setting.terms.map((item) => item.value),
                contents: setting.contents.map((item) => item.value),
              })),
              engagementDistributionSettings:
                set.engagementDistributionSettings?.map((setting) => ({
                  ...setting,
                  websiteUrl: setting.useOnchain
                    ? setting.websiteUrl?.trim()
                    : "",
                  trackingLink: setting.useOnchain
                    ? makeCustomTrackingLink(setting, stepData.name, tabIndex)
                    : "",
                })),
            };
          }
        ),
        // 至少要有 ChainId 才會傳給後端
        objectives: stepData.objectives.filter((item) => item.chainId),
      };

      if (campaignId) {
        await axios.put(`${apiUrl}/api/campaign/${entityName}/${campaignId}`, {
          ...reqData,
          isDraft,
        });
      } else {
        await axios.post(`${apiUrl}/api/campaign/${entityName}`, {
          ...reqData,
          isDraft,
        });
      }

      dispatchSnackbar({
        message: isDraft
          ? "Campaign saved draft successfully"
          : "Campaign published successfully",
        variant: "success",
      });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (shouldNavigate) {
      navigateEntity(shouldNavigate);
    }
  }, [shouldNavigate]);

  const { getStatsCounts } = useFetchTicketList();
  async function saveCampaign({ isDraft, customUrl = "", tempCampaignName }) {
    try {
      const fakeCampaignName = tempCampaignName
        ? tempCampaignName
        : `Draft Attribution Link ${moment().toISOString()}`;
      const isNameAvailable = await checkName({ fakeCampaignName });
      if (!isNameAvailable) return;
      await publishCampaign({ isDraft, fakeCampaignName });
      getStatsCounts({ entityName });

      if (customUrl) {
        navigateEntity(customUrl);
        return;
      }

      setShouldNavigate(
        isTracker
          ? "/analytics/settings/attribution-link"
          : "/marketing/campaign/all"
      );
    } catch (err) {
      console.log(err);
      dispatchSnackbar({
        err,
        message: "Error in saving or publishing campaign",
      });
    }
  }
  return { checkName, saveCampaign };
}
