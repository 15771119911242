import React from "react";
import _ from "lodash";
import { Button, SIZE, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { useOpenState } from "contexts/stepContext/Open.context";
import BasicDialog from "@metacrm/metacrm-material-ui/dist/Dialog";
import { Box } from "@mui/material";

const UpgradePlan = () => {
  const { formatMessage } = useIntl();
  const { openState, setOpen } = useOpenState();
  return (
    <BasicDialog
      dialogRootStyle={{ maxWidth: "346px" }}
      open={_.get(openState, "upgrade")}
      dialogTitle={""}
      dialogContent={
        <Box width="250px">
          <img src={require("assets/img/upgradePlan.png")} />
          <Box className="title" mb="10px" mt="30px">
            {formatMessage({
              id: "global.popup.unavailableFeature.title",
            })}
          </Box>
          <Box>
            {formatMessage({
              id: "global.popup.unavailableFeature.description",
            })}
          </Box>
        </Box>
      }
      confirmText={formatMessage({ id: "global.btn.contactUs" })}
      cancelText={formatMessage({ id: "global.status.close" })}
      onClose={() => setOpen({ upgrade: false })}
      onHandleConfirm={() => {
        window.open("mailto:bd@metacrm.inc");
      }}
    />
  );
};

export default UpgradePlan;
