import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledOnboardingWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
}));

export const StyledStepLine = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, active }) => ({
  borderTop: active
    ? `1px solid ${theme.customColors.purple[500]}`
    : `1px dashed ${theme.customColors.grey[500]}`,
  width: "144px",
}));

export const StyledStepDotContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, active, sx }) => ({
  color: active ? "#7B61FF" : "#A5A5A5",
  position: "absolute",
  bottom: "-4px",
  left: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  transform: "translate(-50%, 0%)",
  whiteSpace: "nowrap",
  fontSize: "12px",
  ...sx,
}));

export const StyledStepDot = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, active, overStep, sx }) => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: overStep ? theme.customColors.purple[500] : "#FAFAFD",
  border: active
    ? `1px solid ${theme.customColors.purple[500]}`
    : `1px solid ${theme.customColors.grey[500]}`,
  ...sx,
}));

export const StyledOtpInput = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, error }) => ({
  width: "100%",
  marginBottom: "12px",
  "& > div": {
    width: "100%",
    justifyContent: "space-between",
    "& input[type='number']::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type='number']::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type='number']": {
      MozAppearance: "textfield",
    },
    "& input": {
      background: "#FAFAFD",
      border: 0,
      borderBottom: `1px solid ${
        error ? theme.customColors.pink[600] : theme.customColors.grey[300]
      }`,

      "&:focus": {
        outline: 0,
        borderBottom: `1px solid ${
          error ? theme.customColors.pink[600] : theme.customColors.grey[700]
        }`,
      },
    },
  },
}));
