import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const Tab = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, disabled }) => ({
  display: "inline-flex",
  alignItems: "center",
  maxHeight: "14px",
  cursor: "pointer",
  gap: 4,
}));
