import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import { Grid, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTrackingPaper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginTop: "20px",
  background: "#FFFFFF",
  boxShadow: "0px 1.2824229001998901px 6.412113666534424px 0px #0000001A",
  position: "relative",
  borderRadius: "4px",
}));

export const StyledDisabledOverlay = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: "rgba(230, 230, 230, 0.6)",
  backdropFilter: "blur(5px)",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  height: "100%",
  width: "100%",
  position: "absolute",
  zIndex: 10000,
}));

export const StyledTrackingTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color }) => ({
  fontSize: "16px",
  color: color || theme.customColors.grey[700],
  paddingBottom: "12px",
}));

export const StyledTrackingContainer = styled(Grid, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledTrackingStepTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color }) => ({
  fontSize: "16px",
  color: color || theme.customColors.grey[700],
  marginBottom: "4px",
  marginTop: "20px",
}));

export const StyledAPIURL = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  border: `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: 4,
  marginBottom: "20px",
  position: "relative",
  background: theme.customColors.grey[200],
  padding: 14,
  color: theme.customColors.grey[500],
  fontWeight: 500,
  fontSize: 14,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.customColors.grey[600],
  fontSize: 12,
  fontWeight: 500,
  border: `0.75px solid #F7F5FF`,
}));

export const StyledHeadCell = styled(TableCell)(({ theme }) => ({
  color: theme.customColors.grey[600],
  fontSize: 14,
  fontWeight: 500,
  border: `0.75px solid #FFFFFF`,
}));
export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: "#F7F5FF",
}));
