import React, { useContext } from "react";
import { StyledGrid, StyledGridItem } from "../CampaignCreation.styles";
import { AddButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { Radio } from "@metacrm/metacrm-material-ui/dist/Radio";
import useCampaignOptions from "./useCampaignOptions";
import EditInput from "./EditInput";
import EditInputRadio from "./EditInputRadio";
import { useIntl } from "react-intl";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { useTheme } from "@mui/styles";
import { TrackingOptionsContext } from "./OptionsProvider";

export default function CampaignOptions({
  data,
  type, // 'mediums', 'terms', 'contents', or 'sources'
  handleClick,
  handleRemove,
}) {
  const { options } = useContext(TrackingOptionsContext);
  const { addOption, updateOption, deleteOption, defaultSourceArray } =
    useCampaignOptions();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const typeOptions = options[type];
  // Dynamic titles and add button texts
  const titles = {
    mediums: "campaignBuild.secondStep.campaignMedium",
    terms: "campaignCreate.custom.term.title",
    contents: "campaignCreate.custom.content.title",
    sources: "campaignBuild.secondStep.campaignSource",
  };
  const tooltips = {
    mediums: "campaignCreate.custom.mediums.tooltip",
    terms: "campaignCreate.custom.terms.tooltip",
    contents: "campaignCreate.custom.contents.tooltip",
    sources: "campaignCreate.custom.sources.tooltip",
  };
  const addButtons = {
    mediums: "campaignCreate.custom.medium.add",
    terms: "campaignCreate.custom.term.add",
    contents: "campaignCreate.custom.content.add",
    sources: "campaignCreate.custom.source.add",
  };

  const isSource = type === "sources";
  return (
    <>
      <p style={{ marginBottom: "16px" }}>
        {formatMessage({ id: titles[type] })}{" "}
        {type !== "contents" && type !== "terms" && (
          <span className="required" style={{ marginRight: 4 }}>
            *
          </span>
        )}
        <IconTooltip
          sx={{
            color: theme.customColors.grey[700],
          }}
          content={formatMessage({
            id: tooltips[type],
          })}
        />
      </p>
      <StyledGrid>
        {isSource &&
          defaultSourceArray?.map((item) => (
            <StyledGridItem
              key={item.value}
              checked={data.source === item.value}
            >
              <Radio
                checked={data.source === item.value}
                onChange={() => handleClick(item.value)}
                label={
                  <div
                    style={{
                      margin: "0 8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.value}
                    <img
                      style={{ marginLeft: "8px" }}
                      width="22px"
                      height="22px"
                      src={require(`assets/img/source/${item.img}`)}
                      alt={item.value}
                    />
                  </div>
                }
              />
            </StyledGridItem>
          ))}
        {isSource &&
          typeOptions?.map((option) => (
            <div
              key={option._id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledGridItem checked={data.source === option.value}>
                <EditInputRadio
                  defaultValue={option}
                  updateOption={(val, oldVal) =>
                    updateOption(type, option._id, val, oldVal)
                  }
                  checked={data.source === option.value}
                  handleClick={(objValue) => handleClick(objValue.value)}
                  removeClick={() => handleRemove(option)}
                  placeholder="e.g., banner, email, post"
                />
              </StyledGridItem>
              <i
                className="meta-crm-icon-ic_trash font-size-20"
                onClick={() => {
                  handleRemove(option);
                  deleteOption(type, option._id);
                }}
                style={{ marginLeft: "8px", cursor: "pointer" }}
              />
            </div>
          ))}
        {!isSource &&
          typeOptions?.map((option) => (
            <div
              key={option._id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledGridItem
                checked={data[type].some((item) => item._id === option._id)}
              >
                <EditInput
                  defaultValue={option}
                  removeClick={() => handleRemove(option)}
                  updateOption={(val, oldVal) =>
                    updateOption(type, option._id, val, oldVal)
                  }
                  checked={data[type].some((item) => item._id === option._id)}
                  handleClick={(objValue) => handleClick(objValue)}
                  placeholder="e.g., banner, email, post"
                />
              </StyledGridItem>
              <i
                className="meta-crm-icon-ic_trash font-size-20"
                onClick={() => {
                  handleRemove(option);
                  deleteOption(type, option._id);
                }}
                style={{ marginLeft: "8px", cursor: "pointer" }}
              />
            </div>
          ))}
      </StyledGrid>
      <AddButton onClick={() => addOption(type)}>
        {formatMessage({ id: addButtons[type] })}
      </AddButton>
    </>
  );
}
