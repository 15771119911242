import React, { useState } from "react";
import _ from "lodash";
import { getShortAddress } from "features/helpers/utils";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { makeStyles, useTheme } from "@mui/styles";
import { getUserAvatarIcon } from "../utils";
import { Box } from "@mui/material";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
const useStyles = makeStyles((theme) => ({
  icon: {
    width: 16,
    height: 16,
    borderRadius: 20,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "11px 16px",
    fontSize: 11,
    color: "#555555",
    fontWeight: 400,
    background: "#ffffff",
    borderRadius: 2.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "0.5px solid #a5a5a5",
    minWidth: 140,
    boxShadow: "3px 3px 1px rgba(0, 0, 0, 0.2)",
  },
}));
const Address = ({ user, iconKey = "_id", showExtra = false }) => {
  const theme = useTheme();

  const iconStyle = { borderRadius: 20, marginRight: 5, height: 28, width: 28 };

  if (!user) return <div>-</div>;
  const icon = getUserAvatarIcon(user[iconKey], theme);
  return (
    <div style={{ position: "relative", minWidth: "160px" }}>
      <div
        className="startRow"
        style={{
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        <img src={require(`assets/img/${icon}`)} style={iconStyle} />
        <Box ml={"8px"}>
          {user.address ? (
            <IconTooltip
              customColor="inheritance"
              size="inheritance"
              withoutMaxWidth={true}
              icon={getShortAddress(user.address)}
              content={
                <Box className="startRow">
                  <span style={{ color: "#383538" }}>{user.address}</span>

                  <IconButton
                    style={{ padding: 0, marginLeft: 10 }}
                    size={18}
                    onClick={(e) => {
                      navigator.clipboard.writeText(user.address);
                      e.stopPropagation();
                    }}
                  >
                    <i className="meta-crm-icon-ic_copy2 font-size-18" />
                  </IconButton>
                </Box>
              }
            />
          ) : (
            "-"
          )}
          {showExtra && (
            <Box display="flex" alignItems="center">
              {user.mostRecentDiscordHandle ? (
                <IconTooltip
                  icon={
                    <i
                      className="meta-crm-icon-ic_discord font-size-16"
                      style={{ marginRight: 5 }}
                    />
                  }
                  content={user.mostRecentDiscordHandle}
                />
              ) : (
                <i
                  className="meta-crm-icon-ic_discord font-size-16"
                  style={{ color: "#E6E6E6", marginRight: 5 }}
                />
              )}
              {user.mostRecentTwitterHandle ? (
                <IconTooltip
                  icon={
                    <i
                      className="meta-crm-icon-ic_x-1 font-size-12"
                      style={{ marginRight: 5, color: "#383538" }}
                    />
                  }
                  content={user.mostRecentTwitterHandle}
                />
              ) : (
                <i
                  className="meta-crm-icon-ic_x-1 font-size-12"
                  style={{ color: "#E6E6E6", marginRight: 5 }}
                />
              )}
              {user.email ? (
                <IconTooltip
                  icon={
                    <i
                      className="meta-crm-icon-ic_email font-size-16"
                      style={{ marginRight: 5 }}
                    />
                  }
                  content={user.email}
                />
              ) : (
                <i
                  className="meta-crm-icon-ic_email font-size-16"
                  style={{ color: "#E6E6E6", marginRight: 5 }}
                />
              )}
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Address;
