import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Loading } from "features/common";
import { useOutletContext, useParams } from "react-router";
import XLinkDropDown from "./XLinkDropDown";
import CustomToggle from "components/CustomToggle/CustomToggle";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
import Percent from "components/Percent/Percent";
import { formatDate, formatTime } from "features/helpers/utils";
import { marketingColors } from "features/configure/chartColors";
import { useIntl } from "react-intl";

export default function XPerformanceChart({ postLinks }) {
  const { campaignId } = useParams();
  const { formatMessage } = useIntl();
  const [data, setData] = useState({
    chartData: [],
    total: {
      impressions: {
        count: 0,
        growth: 0,
      },
      likes: {
        count: 0,
        growth: 0,
      },
      comments: {
        count: 0,
        growth: 0,
      },
      reposts: {
        count: 0,
        growth: 0,
      },
    },
  });
  const [day, setDay] = useState("");
  const [link, setLink] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const impressionTotal = [
    {
      label: "Total Impressions",
      key: "impressions",
      lineName: "Impressions",
      tooltip: formatMessage({
        id: "xCampaignTracking.totalImpression.tooltip",
      }),
    },
  ];

  const totals = [
    {
      label: "Total Likes",
      key: "likes",
      lineName: "Likes",
      tooltip: formatMessage({ id: "xCampaignTracking.totalLikes.tooltip" }),
    },
    {
      label: "Total Comments",
      key: "comments",
      lineName: "Comments",
      tooltip: formatMessage({ id: "xCampaignTracking.totalComments.tooltip" }),
    },
    {
      label: "Total Retweets",
      key: "reposts",
      lineName: "Retweets",
      tooltip: formatMessage({ id: "xCampaignTracking.totalRetweets.tooltip" }),
    },
  ];

  const colors = marketingColors.small;

  const [entityName] = useOutletContext();

  const dispatchSnackbar = useDispatchSnackbar();
  const getChatData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios(
        `${apiUrl}/api/x/${entityName}/campaign/${campaignId}/performance?${
          link ? `link=${link}&` : ""
        }${day ? `interval=${day}` : ""}`
      );
      setData(data);
    } catch (err) {
      console.log(err);
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const { chartData, total: totalData } = data;

  useEffect(() => {
    getChatData();
  }, [link, day]);

  const xAxisData = chartData.map((d) => {
    if (day === "1")
      return (
        formatDate(d.date, { month: true, utc0: true }) +
        "\n" +
        formatTime(d.date, { utc0: true })
      );
    return formatDate(d.date, { month: true });
  }); // 去重後的日期

  const impressionData = impressionTotal.map((total, index) => ({
    name: total.lineName,
    type: "line",
    data: chartData.map((d) => d[total.key]),
    lineStyle: { color: colors[index] },
    showSymbol: false,
  }));

  // 針對每個指標建立 series
  const seriesData = totals.map((total, index) => ({
    name: total.lineName,
    type: "line",
    data: chartData.map((d) => d[total.key]),
    lineStyle: { color: colors[index + 1] },
    showSymbol: false,
  }));

  const chartOptions = (type = "impression") => ({
    tooltip: {
      trigger: "axis",
      position: (point) => ({ left: point[0] + 10, top: point[1] + 30 }), // 固定 tooltip 在右側
    },
    color: type === "impression" ? colors[0] : colors.slice(1, 4),
    grid: {
      left: "2%",
      right: "10%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xAxisData, // 日期作為 x 軸
    },
    yAxis: {
      type: "value",
    },
    series: type === "impression" ? impressionData : seriesData,
  });

  const daysOptions = [
    { text: "1 Day", value: "1" },
    { text: "7 Days", value: "7" },
    { text: "30 Days", value: "30" },
    { text: "All", value: "" },
  ];

  const renderTotalStats = (total, index) => (
    <Box
      height="52px"
      sx={{
        minWidth: "113px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Box className="item-flex" gap="4px">
        <Box
          width="12px"
          height="8px"
          borderRadius="2px"
          bgcolor={colors[index]}
        />
        <Box fontSize={12} color="#777">
          {total.label}
        </Box>
        <IconTooltip content={total.tooltip} customColor="#A5A5A5" />
      </Box>
      <Box className="item-flex" justifyContent="space-between" gap="10px">
        <Box fontSize={17} fontWeight={700} color="#555">
          {totalData[total.key].count.toLocaleString()}
        </Box>
        {day && (
          <Percent
            data={
              totalData[total.key].growth === "-"
                ? "-"
                : (totalData[total.key].growth * 100).toFixed(2)
            }
          />
        )}
      </Box>
    </Box>
  );

  return (
    <StyledWhiteContainer sx={{ marginBottom: "8px" }}>
      <Box className="item-flex" justifyContent="space-between" mb="10px">
        <Box
          className="item-flex"
          sx={{ gap: "8px", mt: "10px", flexWrap: "wrap" }}
        >
          <Box sx={{ whiteSpace: "nowrap", color: "#555" }}>Select Link:</Box>
          <XLinkDropDown link={link} setLink={setLink} options={postLinks} />
        </Box>
        <CustomToggle
          data={daysOptions}
          value={day}
          onChange={(e, newValue) => {
            if (newValue == null) return;
            setDay(newValue);
          }}
        />
      </Box>
      <Box
        className="item-flex"
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
        mt="32px"
      >
        {isLoading ? (
          <Box
            height="300px"
            width="100%"
            className="item-flex"
            justifyContent="center"
          >
            <LoadingIcon style={{ height: 30, width: 30 }} />
          </Box>
        ) : (
          <>
            <Box width="20%" flexGrow={1} minWidth="200px">
              {impressionTotal.map((total, index) => (
                <>{renderTotalStats(total, index)}</>
              ))}
              <Box height="310px" width={"100%"} position={"relative"}>
                {chartData?.length === 0 ? (
                  <Box
                    position="absolute"
                    width="100%"
                    className="item-flex"
                    justifyContent="center"
                    height="100%"
                    flexDirection="column"
                  >
                    <img src={require("assets/img/barChartAlt.png")} />
                    <Box color="#555" fontWeight={700} m="12px 0 8px">
                      No data to display
                    </Box>
                    <Box textAlign="center" color="#777" width="188px">
                      This is where your session analysis graph will appear
                    </Box>
                  </Box>
                ) : (
                  <ReactECharts
                    option={chartOptions("impression")}
                    autoResize={true}
                    style={{ height: "100%" }}
                    notMerge={true}
                  />
                )}
              </Box>
            </Box>
            <Box
              width="1px"
              ml="25px"
              bgcolor="#e6e6e6"
              height="300px"
              flexShrink={0}
              sx={{
                "@media (max-width: 1470px)": {
                  display: "none",
                },
              }}
            />
            <Box width="70%" flexGrow={1} minWidth="600px">
              <Box className="item-flex" justifyContent="flex-end">
                {totals.map((total, index) => (
                  <>
                    {renderTotalStats(total, index + 1)}
                    {index < totals.length - 1 && (
                      <Box
                        width="1px"
                        height="52px"
                        bgcolor="#E6E6E6"
                        mx="12px"
                      />
                    )}
                  </>
                ))}
              </Box>
              <Box height="310px" width={"100%"} position={"relative"}>
                {chartData?.length === 0 ? (
                  <Box
                    position="absolute"
                    width="100%"
                    className="item-flex"
                    justifyContent="center"
                    height="100%"
                    flexDirection="column"
                  >
                    <img src={require("assets/img/barChartAlt.png")} />
                    <Box color="#555" fontWeight={700} m="12px 0 8px">
                      No data to display
                    </Box>
                    <Box textAlign="center" color="#777">
                      This is where your session analysis graph will appear
                    </Box>
                  </Box>
                ) : (
                  <ReactECharts
                    option={chartOptions("stats")}
                    autoResize={true}
                    style={{ height: "100%" }}
                    notMerge={true}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/* <Box height="310px" width={"100%"} position={"relative"}>
        {isLoading ? (
          <Loading open={isLoading} fullScreen={false} />
        ) : (
          <>
            <ReactECharts
              option={chartOptions}
              autoResize={true}
              style={{ height: "100%" }}
              notMerge={true}
            />
            <ReactECharts
              option={chartOptions}
              autoResize={true}
              style={{ height: "100%" }}
              notMerge={true}
            />
          </>
        )}
      </Box> */}
    </StyledWhiteContainer>
  );
}
