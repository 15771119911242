import React from "react";

import {
  StyledDialog,
  StyledTrialDialogTitle,
  StyledTrialDialogSubTitle,
  StyledLinkTrail,
  StyledImg,
  StyledLinkTitle,
  StyledLinkText,
  StyledHoverShow,
} from "./TrialDialog.styles";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const TrialDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledDialog open={open} onClose={onClose} scroll="body">
      <StyledTrialDialogTitle>Choose Your Role</StyledTrialDialogTitle>
      <Box display="flex" justifyContent="center">
        <StyledTrialDialogSubTitle>
          To lead you to the solution that fits your needs
        </StyledTrialDialogSubTitle>
      </Box>

      <Grid
        container
        mt="26px"
        alignItems="stretch"
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12} md={6}>
          <StyledLinkTrail
            href="/trial/marketing"
            target="_blank"
            computer={computer}
          >
            <StyledImg
              src={require("assets/img/marketing-link.svg").default}
            ></StyledImg>
            <StyledLinkTitle>Marketing & Sales</StyledLinkTitle>
            {computer && (
              <StyledLinkText>
                For marketing professional & sales to customize user
                interaction, track marketing analytics, and enhance your
                marketing strategies.
              </StyledLinkText>
            )}
            <StyledHoverShow>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
              </Box>
              <Box>MetaCRM for Marketing</Box>
            </StyledHoverShow>
          </StyledLinkTrail>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledLinkTrail
            href="/trial/service"
            target="_blank"
            computer={computer}
          >
            <StyledImg
              src={require("assets/img/service-link.svg").default}
            ></StyledImg>
            <StyledLinkTitle>Customer Support</StyledLinkTitle>
            {computer && (
              <StyledLinkText>
                For customer support to effectively manage all inbound requests
                & smoothly collaborate with your team.
              </StyledLinkText>
            )}
            <StyledHoverShow>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
              </Box>
              <Box>MetaCRM for Service</Box>
            </StyledHoverShow>
          </StyledLinkTrail>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default TrialDialog;
