import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";

import {
  StyledSettingAreaContainer,
  StyledSettingAreaTitle,
  StyledSettingAreaHint,
  StyledSettingListContainer,
} from "./TrialSetStepTemplate.styles";
import {
  StyledSettingSupportCenterContainer,
} from "features/metadesk/settings/Customization/SettingSupportCenter/SettingSupportCenter.styles";

const TrialSetStepTemplate = ({
  title,
  titleHint,
  renderLeftArea,
  renderButtonArea,
  renderRightArea,
}) => {
  return (
    <StyledSettingSupportCenterContainer>
      <Box
        className="setting-container"
      >
        <Box maxWidth="460px" mr="100px">
          <StyledSettingAreaContainer>
            <StyledSettingAreaTitle>{title}</StyledSettingAreaTitle>
            <StyledSettingAreaHint>{titleHint}</StyledSettingAreaHint>

            <>
              <StyledSettingListContainer>
                {renderLeftArea()}
              </StyledSettingListContainer>
              {renderButtonArea()}
            </>
          </StyledSettingAreaContainer>
        </Box>
        <Box flex="1">
          <Box display="flex" justifyContent="center">
            {renderRightArea()}
          </Box>
        </Box>
      </Box>
    </StyledSettingSupportCenterContainer>
  );
};

export default TrialSetStepTemplate;
