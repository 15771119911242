import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Popper,
} from "@mui/material";
import { TAG_COLORS } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useGetPagePermission from "hooks/useGetPagePermission";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useFetchUserWalletInfo } from "../redux/fetchUserWalletInfo";
import { useFetchTags, useFetchUserList } from "../redux/hooks";

const UserTag = ({
  entityName,
  width = 320,
  minWidth = 200,
  showNumber = 2,
  userInfo,
  initTags,
  setInitTags,
  containerStyle,
  tagListHeight = 170,
  embed = false,
  disabled = false,
  setUser = () => {},
}) => {
  const { tags, addTag, removeTag, editTag } = useFetchTags();
  const [inputValue, setInputValue] = useState("");
  const { fetchUserWalletInfoPending } = useFetchUserWalletInfo();
  const { updateUser } = useFetchUserList();
  const [editTagValue, setEditTagValue] = useState("");
  const [showTagList, setShowTagList] = useState(false);
  const [showCurrentTagList, setShowCurrentTagList] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const addRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState(
    !userInfo ? initTags : _.map(_.get(userInfo, "tags", []), "_id")
  );
  const pagePermission = useGetPagePermission();
  const { show, canEdit } = pagePermission("customerList");

  const disabledSetting = disabled || !show;

  const values = selectedTags;
  const dispatchSnackbar = useDispatchSnackbar();
  const setValues = async (v) => {
    try {
      setSelectedTags(v);
      if (setInitTags) setInitTags(v);
      if (!userInfo) return;
      const { tags } = await updateUser({
        entityName,
        userId: userInfo._id,
        userTagIds: v,
        userAddress: userInfo.address,
      });
      if (setUser) {
        setUser({
          id: userInfo._id,
          tags,
        });
      }
    } catch (err) {
      if (err) {
        dispatchSnackbar({ err });
      }
    }
  };

  useEffect(() => {
    if (userInfo) setSelectedTags(_.map(_.get(userInfo, "tags", []), "_id"));
  }, [userInfo]);

  useEffect(() => {
    if (initTags) setSelectedTags(initTags);
  }, [initTags]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !inputRef.current?.contains(event.target) &&
        !popoverRef.current?.contains(event.target)
      ) {
        setShowTagList(false);
        setSelectedTag(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [inputRef]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (embed && !inputRef.current?.contains(event.target)) {
        setShowCurrentTagList(false);
        setSelectedTag(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [addRef]);

  useEffect(() => {
    if (showTagList) {
      addRef.current?.focus();
    }
  }, [showTagList]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const onAddTag = async () => {
    if (!inputValue) return;
    if (!_.find(tags[entityName], { value: inputValue.toString() })) {
      const addedTag = await addTag({
        tag: {
          value: inputValue,
        },
        entityName,
      });
      setValues([...values, addedTag._id]);

      if (!embed) {
        addRef.current?.blur();
        setShowTagList(false);
      }
    }

    setInputValue("");
  };
  const handleInputKeyDown = async (event) => {};

  const handleTagClick = (e, tag) => {
    if (!_.includes(values, tag._id)) {
      setValues([...values, tag._id]);
    }
    setShowTagList(false);
  };

  const updateTagName = async () => {
    if (!_.trim(editTagValue)) return;
    await editTag({
      tag: selectedTag.tag,
      entityName,
      newValue: { value: editTagValue },
    });
    setSelectedTag(null);
  };

  // const plusStyle =
  //   width == "auto"
  //     ? {}
  //     : {
  //         position: "absolute",
  //         right: 10,
  //         top: 6,
  //       };

  const renderSelectedTags = () => {
    if (!values || values.length == 0) return;
    return (
      <div
        className="startRow"
        style={{ marginBottom: 8, background: "#F8F8F8", padding: 4 }}
      >
        {_.map(
          _.filter(tags[entityName], (t) => _.includes(values, t._id)),
          (o, i) => {
            return (
              <MetaCRMTag
                key={i}
                option={{ color: o.color, label: o.value }}
                onDelete={(e) => {
                  const newValue = [...values];
                  _.pullAt(newValue, [i]);
                  setValues(newValue);
                }}
              />
            );
          }
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        {renderSelectedTags()}

        {canEdit && (
          <InputField
            value={inputValue}
            size={SIZE.MEDIUM}
            endAdornment={
              <Button
                size={SIZE.XS}
                style={{
                  background: "#F7F1FE",
                  color: "#383538",
                  padding: "0 5px",
                  minWidth: 0,
                }}
                onClick={() => {
                  onAddTag();
                }}
              >
                Add+
              </Button>
            }
            ref={addRef}
            onChange={handleInputChange}
            onFocus={() => setShowCurrentTagList(true)}
            onEnter={(e) => onAddTag()}
          />
        )}
        {(!embed || showCurrentTagList) && (
          <div
            style={{
              boxShadow: embed
                ? "0px 9.348px 18.696px 0px rgba(0, 0, 0, 0.07)"
                : "",
              position: embed ? "absolute" : "relative",
              width: "100%",
              background: "#FFFFFF",
              borderRadius: 5,
              zIndex: 14000,
              padding: embed ? 8 : 0,
            }}
          >
            <div style={{ marginTop: 5 }}>
              Select an option {canEdit ? "or create one" : ""}
            </div>
            <div style={{ maxHeight: tagListHeight, overflow: "auto" }}>
              {tags[entityName] &&
                tags[entityName].map((tag, i) => {
                  if (!tag) return <div key={i} />;
                  return (
                    <div
                      key={tag.value}
                      onClick={(e) => {
                        handleTagClick(e, tag);
                      }}
                      className="betweenRow"
                      style={{ padding: "5px 0", cursor: "pointer" }}
                    >
                      <MetaCRMTag
                        option={{ color: tag.color, label: tag.value }}
                      />
                      {canEdit && (
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={(event) => {
                            setEditTagValue(tag.value);
                            setSelectedTag({
                              target: event.currentTarget,
                              tag,
                            });
                            event.stopPropagation();
                          }}
                        >
                          <MoreVertIcon style={{ color: "#555555" }} />
                        </IconButton>
                      )}
                    </div>
                  );
                })}{" "}
            </div>
          </div>
        )}
        {canEdit && (
          <Popover
            className={"modal"}
            open={_.get(selectedTag, "target")}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            anchorEl={_.get(selectedTag, "target")}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            onClose={() => setSelectedTag(null)}
          >
            <div
              style={{
                padding: 5,
                maxWidth: 100,
                fontWeight: 400,
                color: "#555555",
              }}
            >
              <div
                style={{
                  background: "#F8F8F8",
                  border: "0.3px solid #7B61FF",
                  borderRadius: 3,
                }}
              >
                <InputField
                  value={editTagValue}
                  size="small"
                  onChange={(e) => {
                    setEditTagValue(e.target.value);
                  }}
                  onEnter={() => {
                    updateTagName();
                  }}
                  onBlur={() => {
                    updateTagName();
                  }}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 4 }}>Colors</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {TAG_COLORS.map((color, i) => {
                  return (
                    <span
                      key={i}
                      onClick={(event) => {
                        editTag({
                          tag: selectedTag.tag,
                          entityName,
                          newValue: { color },
                        });
                        setSelectedTag(null);
                      }}
                      style={{
                        width: 16,
                        height: 16,
                        background: color,
                        cursor: "pointer",
                        borderRadius: 3,
                        margin: 3,
                        border:
                          _.get(selectedTag, "tag.color") == color
                            ? "2px solid #7B61FF"
                            : "",
                      }}
                    ></span>
                  );
                })}
              </div>
            </div>
            <MenuItem
              style={{
                padding: "10px 5px",
                borderTop: "1px solid #D6D6D6",
              }}
              onClick={(event) => {
                removeTag({ tag: selectedTag.tag, entityName });
                setSelectedTag(null);
                event.stopPropagation();
              }}
            >
              <div className="startRow" style={{ color: "#555555" }}>
                <i className="meta-crm-icon-ic_trash font-size-18 mgr4" />{" "}
                Delete
              </div>
            </MenuItem>
          </Popover>
        )}
      </div>
    );
  };

  const renderEmbed = () => {
    return renderContent();
  };
  const renderPopover = () => {
    if (!embed && !showTagList) return;

    const popoverContainerStyle = {
      maxWidth: 350,
      zIndex: 12000,
      background: "#FFFFFF",
      border: "1px solid #D6D6D6",
      borderRadius: 4,
      width,
      minWidth: 300,
      padding: 10,
      color: "gray",
    };
    return (
      <Popper
        placement="bottom-start"
        disablePortal={false}
        open={showTagList}
        anchorEl={inputRef.current}
        style={popoverContainerStyle}
        ref={popoverRef}
      >
        {renderContent()}
      </Popper>
    );
  };

  const renderPlusTag = () => {
    const plusCount = values.length - showNumber > 0;
    if (plusCount <= 0 && disabledSetting) return;
    if (plusCount > 0) {
      return (
        <MetaCRMTag
          style={{}}
          option={{
            label: (
              <IconTooltip
                content={
                  <div className="startColumn">
                    {_.map(_.slice(values, showNumber), (tagId, i) => {
                      const tag = _.find(tags[entityName], { _id: tagId });
                      if (!tag) return;
                      return (
                        <MetaCRMTag
                          key={i}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            lineHeight: "normal",
                          }}
                          option={{
                            color: _.get(tag, "color", "#E6E6E6"),
                            label: tag.value,
                          }}
                        />
                      );
                    })}
                  </div>
                }
                icon={
                  <span style={{ fontSize: 14, color: "#383838" }}>
                    {`${values.length - showNumber}`}
                  </span>
                }
              />
            ),
            color: "#F7F1FE",
          }}
        />
      );
    } else {
      return (
        <i
          className="meta-crm-icon-ic_add font-size-18 hoverPurple"
          style={{ color: "#777", marginLeft: "4px", fontWeight: 700 }}
        />
      );
    }
  };

  const renderInputDisplay = () => {
    return (
      <div className="startRow" style={{ minHeight: 28, alignItems: "center" }}>
        {!values && fetchUserWalletInfoPending && (
          <CircularProgress size="1rem" />
        )}
        {_.map(values, (tagId, i) => {
          const o = _.find(tags[entityName], { _id: tagId });
          if (!o || i > showNumber - 1) return;
          return (
            <span style={{ marginRight: 4 }} key={i}>
              <MetaCRMTag key={i} option={{ color: o.color, label: o.value }} />
            </span>
          );
        })}
        {renderPlusTag()}
      </div>
    );
  };

  return (
    <div
      ref={inputRef}
      onClick={() => {
        if (disabledSetting) return;
        setShowTagList(true);
      }}
      style={{
        borderRadius: 4,
        padding: "3px 0px",
        position: "relative",
        width,
        minWidth,
        cursor: disabledSetting ? "" : "pointer",
        ...containerStyle,
      }}
    >
      {!embed && renderInputDisplay()}
      {embed ? renderEmbed() : renderPopover()}
    </div>
  );
};

export default UserTag;
