import React from "react";
import { useIntl } from "react-intl";
import useDispatchSnackbar from "./useDispatchSnackbar";

export default function useCopyToClipboard(copyText, completeCopiedText) {
  const dispatchSnackbar = useDispatchSnackbar();
  const { formatMessage } = useIntl();

  const copyFn = () => {
    navigator.clipboard.writeText(copyText);
    dispatchSnackbar({
      message: (
        <div style={{ color: "white" }} className="formTitle startRow">
          {completeCopiedText || formatMessage({ id: "global.status.copied" })}
        </div>
      ),
      variant: "success",
    });
  };

  return copyFn;
}
