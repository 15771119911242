import Button, {
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import UserTag from "features/metadesk/components/UserTag";
import React from "react";

import { Box, Modal } from "@mui/material";
import { Loading } from "features/common";
import { useOutletContext } from "react-router";
import PureModal from "features/metadesk/campaign/campaignList/component/PureModal";
import { useIntl } from "react-intl";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";

export default function ContactInformationModal({
  open,
  handleClose,
  contactInfo,
  setContactInfo,
  isLoading = false,
  ...props
}) {
  const [entityName] = useOutletContext();
  const [user, setUser] = React.useState(contactInfo);

  function handleSave() {
    setContactInfo(user);
    handleClose();
  }

  const disabledSave =
    user.name.trim() === "" ||
    user.email.trim() === "" ||
    JSON.stringify(user) === JSON.stringify(contactInfo);

  

  const { formatMessage } = useIntl();
  return (
    <PureModal
      open={open}
      closeBtn={false}
      onClose={handleClose}
      sx={{ zIndex: 10000 }}
      maxWidth="600px"
      {...props}
    >
      <Box p={"48px 40px"} position="relative">
        <Loading open={isLoading} fullScreen={false} />
        <Box sx={{ fontSize: "18px", fontWeight: 700, mb: "12px" }}>
          {formatMessage({ id: 'contactInfo.popup.title' })}
        </Box>
        <Box color="#5C5C5C" fontSize="14px" mb="24px">
          {formatMessage({ id: 'contactInfo.popup.description' })}
        </Box>
        <InputField
          isRequired
          title={"Name"}
          placeholder={"Please enter your name"}
          value={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value.trim() })}
        />

        <Box height="16px"></Box>
        <InputField
          isRequired
          title="E-mail"
          placeholder={"Please enter your e-mail"}
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value.trim() })}
        />
        <Box className="endRow" mt="125px">
          <Button
            color={COLOR.SECONDARY}
            variant={VARIANT.TEXT}
            onClick={handleClose}
            style={{ width: 120 }}
          >
            {formatMessage({ id: "global.btn.cancel" })}
          </Button>
          <Button
            color={COLOR.SECONDARY}
            style={{ width: 120 }}
            onClick={handleSave}
            disabled={disabledSave}
          >
            {formatMessage({ id: "global.btn.save" })}
          </Button>
        </Box>
      </Box>
    </PureModal>
  );
}
