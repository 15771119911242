import XSortDropDown from "./XSortDropDown";
import XFilterDropDown from "./XFilterDropDown";
import axios from "axios";
import { Loading } from "features/common";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { Box, Skeleton } from "@mui/material";
import moment from "moment";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { selectRemindBarShow } from "features/metadesk/redux/globalState/globalState.selector";
import { useSelector } from "react-redux";

export default function XFeed() {
  const [hovered, setHovered] = useState(null);
  const [feed, setFeed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState(null);
  const [filters, setFilters] = useState([]);
  const { campaignId } = useParams();
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  const { formatMessage } = useIntl();
  const remindBarShow = useSelector(selectRemindBarShow);

  const getXFeed = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios(
        `${apiUrl}/api/x/${entityName}/campaign/${campaignId}/feed${
          sort ? `?sort=${sort}&order=desc` : ""
        }`
      );
      setFeed(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getXFeed();
  }, [sort]);

  function formatNumber(num) {
    if (num < 1000) return num.toString();

    const units = ["K", "M", "B"];
    let unitIndex = -1;
    let formattedNum = num;

    while (formattedNum >= 1000 && unitIndex < units.length - 1) {
      formattedNum = Math.floor(formattedNum / 1000);
      unitIndex++;
    }

    return formattedNum + units[unitIndex];
  }

  const filterFeed = useMemo(() => {
    if (filters.length === 0) return feed;
    return feed.filter((item) => filters.includes(item.author.name));
  }, [feed, filters]);

  const renderSkelton = () => (
    <Box bgcolor="white" borderRadius="4px" p="16px" mb="12px">
      <Box className="item-flex" mb="13px">
        <Skeleton
          variant="circular"
          width={24}
          height={24}
          sx={{ mr: "9px" }}
        />
        <Box>
          <Skeleton
            variant="rounded"
            width={91}
            height={14}
            sx={{ mb: "6px" }}
          />
          <Skeleton variant="rounded" width={74} height={10} />
        </Box>
      </Box>
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rounded" width={120} height={10} sx={{ mb: "14px" }} />
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={10}
        sx={{ mb: "8px" }}
      />
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={10}
        sx={{ mb: "8px" }}
      />
      <Skeleton variant="rounded" width={"100%"} height={128} />
    </Box>
  );

  return (
    <Box
      sx={{
        bgcolor: "#E6E6E6",
        p: "36px 16px",
        width: "287px",
        borderRadius: "8px",
        flexShrink: 0,
        position: "fixed",
        right: "20px",
        top: remindBarShow ? "70px" : "10px",
        height: remindBarShow ? "calc(100vh - 70px)" : "calc(100vh - 20px)",
      }}
    >
      <Box
        mb="9px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box fontSize={20}>
          {formatMessage({ id: "xCampaignTracking.Feed.title" })}
        </Box>
        <Box className="item-flex">
          <XSortDropDown sortKey={sort} setSortKey={setSort} />
          <XFilterDropDown
            list={feed.map((item) => item.author)}
            selectedFilters={filters}
            setSelectedFilters={setFilters}
          />
        </Box>
      </Box>
      {isLoading ? (
        <>
          {renderSkelton()}
          {renderSkelton()}
        </>
      ) : (
        <Box sx={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto" }}>
          {filterFeed?.map((item, index) => {
            const {
              author,
              text,
              postLink,
              postDate,
              likes,
              comments,
              reposts,
              image: postImage,
              impressions,
            } = item;
            const {
              name: authorName,
              username: authorUsername,
              image: authorImage,
            } = author;

            return (
              <Link
                to={
                  postLink.includes("https://")
                    ? postLink
                    : "https://" + postLink
                }
                target="_blank"
                rel="noreferrer noopener"
                key={index}
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
              >
                <Box
                  width={"100%"}
                  borderRadius="4px"
                  bgcolor={"#FFF"}
                  p="16px"
                  mb="12px"
                  cursor="pointer"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb="9px"
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={authorImage}
                        width={24}
                        height={24}
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                      <Box ml="9px">
                        <Box
                          mr="4px"
                          fontSize={12}
                          color="#555"
                          fontWeight={700}
                        >
                          {truncate(authorName, { length: 15 })}
                        </Box>
                        <Box fontSize="10px" color="#777">
                          @{truncate(authorUsername, { length: 15 })}
                        </Box>
                      </Box>
                    </Box>
                    {hovered === index ? (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        color="#7B61FF"
                        fontSize={10}
                      >
                        <Box mr="4px">See On X</Box>
                        <i
                          className="meta-crm-icon-ic_arrow_go font-size-14"
                          style={{ marginBottom: "2px" }}
                        />
                      </Box>
                    ) : (
                      <Box color="#A5A5A5" fontSize={10}>
                        {moment(postDate).fromNow()}
                      </Box>
                    )}
                  </Box>

                  <Box
                    className="line-clamp-5"
                    mb="6px"
                    color="#777"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {text}
                  </Box>
                  {postImage && (
                    <Box
                      component={"img"}
                      src={postImage}
                      sx={{
                        width: "100%",
                        height: "128px",
                        objectFit: "cover",
                        mb: "10px",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                  <Box
                    className="item-flex"
                    sx={{
                      justifyContent: "space-between",
                      color: "#777",
                      fontSize: "10px",
                    }}
                  >
                    <Box className="item-flex" gap="2px">
                      <img
                        src={require("assets/img/twitterComment.svg").default}
                      />
                      <Box pt="1px">{formatNumber(comments)}</Box>
                    </Box>
                    <Box className="item-flex" gap="2px">
                      <img
                        src={require("assets/img/twitterRetweet.svg").default}
                      />
                      <Box pt="1px">{formatNumber(reposts)}</Box>
                    </Box>
                    <Box className="item-flex" gap="2px">
                      <img
                        src={require("assets/img/twitterLike.svg").default}
                      />
                      <Box pt="1px">{formatNumber(likes)}</Box>
                    </Box>
                    <Box className="item-flex" gap="2px">
                      <img
                        src={require("assets/img/twitterActivity.svg").default}
                      />
                      <Box pt="1px">{formatNumber(impressions)}</Box>
                    </Box>
                  </Box>
                </Box>
              </Link>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
