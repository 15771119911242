import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { get, filter, map, debounce } from "lodash-es";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import Box from "@mui/material/Box";
import { enqueueSnackbar } from "features/common/redux/actions";

import {
  StyledSwiper,
  StyledAttributionLinkContainer,
  StyledAttributionLink,
} from "./EditNotificationPreview.styles";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import {
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import useNavigateEntity from "hooks/useNavigateEntity";

function formatNumber(num) {
  return num < 10 ? "0" + num : String(num);
}

const CustomEditPreview = ({ data, imgs = [] }) => {
  const dispatch = useDispatch();
  const [isShortLink, setIsShortLink] = useState(false);
  const [search, setSearch] = useState("");
  const [links, setLinks] = useState([]);
  const [filteredLinks, setFilterLinks] = useState(links);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { navigateEntity } = useNavigateEntity();

  useEffect(() => {
    setLinks(data.trackingLinks);
  }, [data]);

  useEffect(() => {
    const newFilteredAttributionLink = links.filter((attributionLink) => {
      return get(attributionLink, "long", "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase());
    });
    setFilterLinks(newFilteredAttributionLink);
  }, [links, search]);
  return (
    <StyledWhiteContainer minHeight="80vh">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        mb="12px"
      >
        <Box color={theme.customColors.grey[700]} fontWeight="700">
          {formatMessage({ id: "engagementCampaign.contentSetting.title" })}
        </Box>
        <Box>
          <Switch
            label={
              <Box
                fontSize="12px"
                color={theme.customColors.grey[700]}
                mr="4px"
              >
                {formatMessage({ id: "campaign.edit.shorten" })}
              </Box>
            }
            onChange={(v) => setIsShortLink(v)}
            checked={isShortLink}
            labelPlacement="start"
          />
        </Box>
      </Box>

      <Box>
        <InputField
          size={SIZE.MEDIUM}
          placeholder={formatMessage({ id: "campaigns.searchbar.placeholder" })}
          onChange={(e, value) => setSearch(e.target.value)}
          value={search}
          type="search"
        />
      </Box>

      <Box>
        {filteredLinks.map((linkItem, index) => (
          <StyledAttributionLinkContainer key={index}>
            <Box
              fontSize="12px"
              color={theme.customColors.grey[600]}
              fontWeight="400"
              display={"flex"}
              alignItems={"center"}
            >
              {formatNumber(index + 1)}
              <Box ml="3px" mr="3px">
                :
              </Box>
            </Box>
            <IconTooltip
              icon={
                <StyledAttributionLink>
                  {isShortLink ? get(linkItem, "short") : get(linkItem, "long")}
                </StyledAttributionLink>
              }
              content={linkItem.long}
              sx={{
                flex: 1,
                overflow: "hidden",
              }}
            ></IconTooltip>

            <IconButton
              sx={{ marginLeft: "auto" }}
              size={18}
              color={theme.customColors.grey[500]}
              onClick={(e) => {
                navigator.clipboard.writeText(
                  isShortLink ? linkItem.short : linkItem.long
                );
                dispatch(
                  enqueueSnackbar({
                    message: (
                      <div
                        style={{
                          color: theme.customColors.purple[500],
                        }}
                        className="formTitle startRow"
                      >
                        <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
                        {formatMessage({ id: "global.status.copied" })}
                      </div>
                    ),
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "info",
                    },
                  })
                );
                e.stopPropagation();
              }}
            >
              <i className="meta-crm-icon-ic_copy font-size-18" />
            </IconButton>
          </StyledAttributionLinkContainer>
        ))}
      </Box>
    </StyledWhiteContainer>
  );
};

export default CustomEditPreview;
