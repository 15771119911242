import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useIntl } from "react-intl";
import axios from "axios";
import { apiUrl } from "features/configure";
import { get } from "lodash-es";
import { SinglePerformanceContext } from "../context/SinglePerformanceProvider";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import moment from "moment";
import { Box } from "@mui/material";

export default function useGetPerformanceDetailTable() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({
    addressTotal: 0,
    objectiveAchievedTotal: 0,
  });
  const [page, setPage] = useState(1);
  const { formatMessage } = useIntl();
  const { filterDetail, filterData, update, setIsLoading } = useContext(
    SinglePerformanceContext
  );
  const params = new URLSearchParams(window.location.search);
  const dimension = params.get("dimension") || "";
  const dimensionValue = params.get("dimensionValue") || "";

  function handleUpdateUser({ id, tags }) {
    const updatedData = data.map((u) => {
      if (u._id === id) {
        return { ...u, tags };
      }
      return u;
    });
    setData(updatedData);
  }

  const {
    userType,
    walletBalance,
    walletAge,
    objectiveAchieved,
    periodStart,
    periodEnd,
    tag,
    utmId,
  } = filterData;

  // const {
  //   userType,
  //   walletBalance,
  //   walletAge,
  //   objectiveAchieved,
  //   periodStart,
  //   periodEnd,
  //   tag,
  // } = filterDetail;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("conversionCount");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const { entity, id } = useParams();
  const dispatchSnackbar = useDispatchSnackbar();

  const [needUpdate, setNeedUpdate] = useState(false);

  async function getPerformanceData() {
    try {
      setIsLoading((prev) => ({ ...prev, table: true }));
      const { data, headers } = await axios.get(
        `${apiUrl}/api/campaignPerformance/${entity}/detail/${id}?` +
          `walletBalance=${walletBalance}&` +
          `walletAge=${walletAge}&` +
          `userType=${userType}&` +
          `objectiveAchieved=${objectiveAchieved}&` +
          `tag=${tag}&` +
          `${
            periodStart ? "from=" + moment(periodStart).valueOf() + "&" : ""
          }` +
          `${periodEnd ? "to=" + moment(periodEnd).valueOf() + "&" : ""}` +
          `dimensionValue=${dimensionValue}&` +
          `dimension=${dimension}&` +
          `utmId=${utmId}&` +
          `order=${order === "asc" ? 1 : -1}&` +
          `page=${page}&` +
          `sort=${orderBy}`
      );

      const { users, objectiveAchievedTotal } = data;
      setData(users);
      setTotal({
        addressTotal: get(headers, "x-total-count", 0),
        objectiveAchievedTotal,
      });
    } catch (err) {
      dispatchSnackbar({
        err,
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, table: false }));
    }
  }

  // useEffect(() => {
  //   getPerformanceData();
  // }, [
  //   userType,
  //   walletBalance,
  //   walletAge,
  //   objectiveAchieved,
  //   periodStart,
  //   periodEnd,
  //   tag,
  //   update,
  //   order,
  //   orderBy,
  //   utmId,
  // ]);

  useEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      setNeedUpdate(true);
    }
  }, [
    userType,
    walletBalance,
    walletAge,
    objectiveAchieved,
    periodStart,
    periodEnd,
    tag,
    update,
    order,
    orderBy,
    utmId,
  ]);

  // 當切換頁數時，重新取得資料
  useEffect(() => {
    setNeedUpdate(true);
  }, [page]);

  // 用 needUpdate 來判斷是否需要更新
  useEffect(() => {
    if (!needUpdate) return;
    getPerformanceData();
    setNeedUpdate(false);
  }, [needUpdate]);

  const headerContent = (content) => (
    <Box
      width="30px"
      h="30px"
      my="8px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {content}
    </Box>
  );

  const headCells = [
    {
      show: true,
      disabledSort: true,
      id: "address",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({
            id: "walletAnalytics.columnName.addressOrWidgetId.title",
          })}
          {headerContent()}
        </div>
      ),
      title: formatMessage({
        id: "walletAnalytics.columnName.addressOrWidgetId.title",
      }),
    },
    {
      show: true,
      disabledSort: false,
      id: "conversionCount",
      width: 150,
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          Objective Achieved
          {headerContent(
            <Box
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#383538",
              }}
            >
              {total.objectiveAchievedTotal}
            </Box>
          )}
        </div>
      ),
      title: "Token Transfer In",
    },
    {
      show: true,
      disabledSort: true,
      id: "userType",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({
            id: "walletAnalytics.columnName.newReturning.title",
          })}
        </div>
      ),
      title: formatMessage({
        id: "walletAnalytics.columnName.newReturning.title",
      }),
    },
    {
      show: true,
      disabledSort: true,
      id: "tags",
      width: 150,
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "walletAnalytics.columnName.tags" })}
        </div>
      ),
      title: formatMessage({ id: "walletAnalytics.columnName.tags" }),
    },
    {
      show: true,
      disabledSort: false,
      id: "walletAge",
      width: 150,

      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "walletAnalytics.columnName.walletAge" })}
        </div>
      ),
      title: formatMessage({ id: "walletAnalytics.columnName.walletAge" }),
    },
    {
      show: true,
      disabledSort: false,
      id: "walletBalance",
      width: 150,
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({
            id: "walletAnalytics.columnName.walletBalance.title",
          })}
        </div>
      ),
      title: formatMessage({
        id: "walletAnalytics.columnName.walletBalance.title",
      }),
    },
    {
      show: true,
      disabledSort: false,
      id: "lastTimestamp",
      width: 150,
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          Latest Achieved Date
        </div>
      ),
      title: "Latest Achieved Date",
    },
  ];
  return {
    data,
    headCells,
    totalCount: total.addressTotal,
    page,
    setPage,
    order,
    orderBy,
    handleRequestSort,
    handleUpdateUser,
  };
}
