import React from "react";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";

import { StyledSettingAreaButtonContainer } from "./TrialSetStepButton.styles";

const TrialSetStepButton = ({
  isValid,
  onBack,
  onSave,
  saveText,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledSettingAreaButtonContainer {...otherProps}>
      {onBack && (
        <Button
          variant={VARIANT.OUTLINED}
          sx={{ width: "120px", marginRight: "8px" }}
          onClick={onBack}
        >
          Back
        </Button>
      )}

      <Button
        sx={{ width: "120px" }}
        disabled={!isValid}
        onClick={onSave}
        isLoading={isLoading}
      >
        {saveText ? saveText : "Save"}
      </Button>
    </StyledSettingAreaButtonContainer>
  );
};

export default TrialSetStepButton;
