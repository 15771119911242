import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RADIANT_USER_DETAILS_FETCH_BEGIN,
  RADIANT_USER_DETAILS_FETCH_SUCCESS,
  RADIANT_USER_DETAILS_FETCH_FAILURE,
} from "./constants";
import axios from "axios";
import { apiUrl } from "features/configure";

export function fetchRadiantUserDetails(input) {
  return async (dispatch, getState) => {
    dispatch({
      type: RADIANT_USER_DETAILS_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let pools = await axios.get(
          `${apiUrl}/api/user/details?address=` +
            input.selectedUserAddress +
            "&entityName=" +
            input.entityName
        );
        dispatch({
          type: RADIANT_USER_DETAILS_FETCH_SUCCESS,
          data: pools.data,
        });
      } catch (err) {
        dispatch({
          type: RADIANT_USER_DETAILS_FETCH_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useFetchRadiantUserDetails() {
  const dispatch = useDispatch();

  const {
    radiantUserDetails,
    fetchRadiantUserDetailsPending,
    fetchRadiantUserDetailsDone,
  } = useSelector(
    (state) => ({
      radiantUserDetails: state.metadesk.radiantUserDetails,
      fetchRadiantUserDetailsPending:
        state.metadesk.fetchRadiantUserDetailsPending,
      fetchRadiantUserDetailsDone: state.metadesk.fetchRadiantUserDetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRadiantUserDetails(data));
    },
    [dispatch]
  );

  return {
    radiantUserDetails,
    fetchRadiantUserDetails: boundAction,
    fetchRadiantUserDetailsDone,
    fetchRadiantUserDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RADIANT_USER_DETAILS_FETCH_BEGIN:
      return {
        ...state,
        fetchRadiantUserDetailsPending: true,
      };

    case RADIANT_USER_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        radiantUserDetails: action.data,
        fetchRadiantUserDetailsDone: true,
        fetchRadiantUserDetailsPending: false,
      };

    case RADIANT_USER_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        fetchRadiantUserDetailsPending: false,
      };

    default:
      return state;
  }
}
