import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  MAGPIE_FETCH_BEGIN,
  MAGPIE_FETCH_SUCCESS,
  MAGPIE_FETCH_FAILURE,
} from "./constants";
import axios from 'axios';
import { apiUrl } from 'features/configure';

export function fetchMagpieDetails(input) {
  return (dispatch, getState) => {
    dispatch({
      type: MAGPIE_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let pools = await axios.get(
          `${apiUrl}/api/user/pool?address=` +
          input.selectedUserAddress +
          '&entityName=' +
          input.entityName
        );
        dispatch({
          type: MAGPIE_FETCH_SUCCESS,
          data: pools.data,
        });
      } catch (err) {
        dispatch({
          type: MAGPIE_FETCH_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useFetchMagpieDetails() {
  const dispatch = useDispatch();

  const { magpieDetails, fetchMagpieDetailsPending, fetchMagpieDetailsDone } =
    useSelector(
      (state) => ({
        magpieDetails: state.metadesk.magpieDetails,
        fetchMagpieDetailsPending: state.metadesk.fetchMagpieDetailsPending,
        fetchMagpieDetailsDone: state.metadesk.fetchMagpieDetailsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchMagpieDetails(data));
    },
    [dispatch]
  );

  return {
    magpieDetails,
    fetchMagpieDetails: boundAction,
    fetchMagpieDetailsDone,
    fetchMagpieDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MAGPIE_FETCH_BEGIN:
      return {
        ...state,
        fetchMagpieDetailsPending: true,
      };

    case MAGPIE_FETCH_SUCCESS:
      return {
        ...state,
        magpieDetails: action.data,
        fetchMagpieDetailsDone: true,
        fetchMagpieDetailsPending: false,
      };

    case MAGPIE_FETCH_FAILURE:
      return {
        ...state,
        fetchMagpieDetailsPending: false,
      };

    default:
      return state;
  }
}
