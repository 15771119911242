export const IC_STAR = "⭐️";
export const MAX_SURVEY_SCORE = 5;
export const DEFAULT_SURVEY_MSG = {
  star: {
    msg: "csatForm.question.rating",
  },
  emoji: {
    msg: "csatForm.question.feeling",
  },
};
export const emojiList = [
  {
    emoji: "🤬",
    text: "csatForm.mood.veryUnhappy",
  },
  {
    emoji: "😡",
    text: "csatForm.mood.unhappy",
  },
  {
    emoji: "😶",
    text: "csatForm.mood.Neutral",
  },
  {
    emoji: "😃",
    text: "csatForm.mood.Happy",
  },
  {
    emoji: "😍",
    text: "csatForm.mood.veryHappy",
  },
];
