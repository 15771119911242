import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  NOTIFICATIONS_FETCH_BEGIN,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATION_ADD_SUCCESS,
  NOTIFICATION_UPDATE_FAIL,
  NOTIFICATION_DELETE_SUCCESS,
  GET_NOTIFICATION_BEGIN,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_INFO_SUCCESS,
  NOTIFICATIONS_UPDATE_BEGIN,
  GET_PERFORMANCE_INFO_BEGIN,
  GET_PERFORMANCE_INFO_SUCCESS,
  GET_PERFORMANCE_INFO_FAILURE,
  GET_SINGLE_PERFORMANCE_BEGIN,
  GET_SINGLE_PERFORMANCE_SUCCESS,
  GET_SINGLE_PERFORMANCE_FAILURE,
  GET_SINGLE_PERFORMANCE_USERS_BEGIN,
  GET_SINGLE_PERFORMANCE_USERS_SUCCESS,
  GET_SINGLE_PERFORMANCE_USERS_FAILURE,
} from "./constants";
import axios from "axios";
import _ from "lodash";
import { convertToFile, uploadFiles } from "features/helpers/utils";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";

export function fetchNotifications({ entityName, page, published }) {
  return (dispatch, getState) => {
    dispatch({
      type: NOTIFICATIONS_FETCH_BEGIN,
      published,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let notifications = await axios.get(
          `${apiUrl}/api/notifications/${entityName}?published=${
            published ? 1 : 0
          }&page=${page}`
        );
        dispatch({
          type: NOTIFICATIONS_FETCH_SUCCESS,
          data: notifications.data,

          published,
          notificationsCount: notifications.headers["x-total-count"],
        });
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: NOTIFICATIONS_FETCH_FAILURE,
          published,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function getSubscribeInfo({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let notification = await axios.get(
          `${apiUrl}/api/notifications/${entityName}/info`
        );
        dispatch({
          type: GET_NOTIFICATION_INFO_SUCCESS,
          data: notification.data,

          entityName,
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        console.log(err);
      }
    });

    return promise;
  };
}

export function getNotificationById({ entityName, notificationId }) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_NOTIFICATION_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let notification = await axios.get(
          `${apiUrl}/api/notifications/${entityName}/${notificationId}`
        );
        dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          data: notification.data,
          entityName,
          notificationId,
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GET_NOTIFICATION_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function addNotification({
  entityName,
  images,
  inputData,
  notificationId,
  published,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: NOTIFICATIONS_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        let newNotification = { ...inputData, published };
        newNotification["images"] = await uploadFiles(
          images,
          entityName,
          "/api/gcp/uploadNotification",
          "notification"
        );

        let notifications;

        if (notificationId) {
          notifications = await axios.put(
            `${apiUrl}/api/notifications/${entityName}/${notificationId}`,
            newNotification
          );
        } else {
          notifications = await axios.post(
            `${apiUrl}/api/notifications/${entityName}`,
            newNotification
          );
        }
        dispatch({
          type: NOTIFICATION_ADD_SUCCESS,
          data: notifications.data,
          entityName,
          published,
        });
        resolve(notifications.data);
      } catch (err) {
        dispatch({
          type: NOTIFICATION_UPDATE_FAIL,
        });

        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}

export function removeNotification({ entityName, notificationId }) {
  return (dispatch, getState) => {
    dispatch({
      type: NOTIFICATIONS_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        await axios.delete(
          `${apiUrl}/api/notifications/${entityName}/${notificationId}`
        );
        dispatch({
          type: NOTIFICATION_DELETE_SUCCESS,
          data: notificationId,
          entityName,
        });
        resolve();
      } catch (err) {
        dispatch({
          type: NOTIFICATION_UPDATE_FAIL,
        });
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}
export function fetchPerformances({
  entityName,
  page,
  category,
  channels,
  orderData,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PERFORMANCE_INFO_BEGIN,
      category,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        let performance = await axios.get(
          `${apiUrl}/api/legacy/performance/${entityName}/notifications?channels=[${_.join(
            _.map(channels, (c) => `"${c}"`),
            ","
          )}]&category=${category}&page=${page}&sort=${orderData.sort}&order=${
            orderData.order
          }`
        );

        dispatch({
          type: GET_PERFORMANCE_INFO_SUCCESS,
          data: performance.data,
          category,
          performanceCount: performance.headers["x-total-count"],
        });
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GET_PERFORMANCE_INFO_FAILURE,
          category,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function getPerformanceById({ entityName, notificationId }) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_SINGLE_PERFORMANCE_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let notification = await axios.get(
          `${apiUrl}/api/legacy/performance/${entityName}/notifications/${notificationId}`
        );
        dispatch({
          type: GET_SINGLE_PERFORMANCE_SUCCESS,
          data: notification.data,
          entityName,
          notificationId,
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GET_SINGLE_PERFORMANCE_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function getPerformanceUserList({
  entityName,
  notificationId,
  type,
  page,
  orderData,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_SINGLE_PERFORMANCE_USERS_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let notification = await axios.get(
          `${apiUrl}/api/legacy/performance/${entityName}/notifications/${notificationId}/user/${type}?page=${page}&sort=${orderData.sort}&order=${orderData.order}`
        );
        dispatch({
          type: GET_SINGLE_PERFORMANCE_USERS_SUCCESS,
          data: notification.data,
          entityName,
          notificationId,
          usersCount: notification.headers["x-total-count"],
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GET_SINGLE_PERFORMANCE_USERS_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function useFetchNotifications() {
  const dispatch = useDispatch();

  const {
    notifications,
    drafts,
    fetchNotificationsPending,
    fetchDraftsPending,
    notificationsCount,
    updateNotificationPending,
    getNotificationPending,
    notificationUsers,
    notificationData,
    subscribeInfo,
    draftsCount,
    fetchPerformancesPending,
    performanceCount,
    performances,
    performanceData,
    getSinglePerformancePending,
    getPerformanceUserListPending,
    performanceUserList,
    usersCount,
  } = useSelector(
    (state) => ({
      notifications: state.metadesk.notifications,
      notificationData: state.metadesk.notificationData,
      drafts: state.metadesk.drafts,
      fetchNotificationsPending: state.metadesk.fetchNotificationsPending,
      fetchDraftsPending: state.metadesk.fetchDraftsPending,
      notificationsCount: state.metadesk.notificationsCount,
      draftsCount: state.metadesk.draftsCount,
      updateNotificationPending: state.metadesk.updateNotificationPending,
      getNotificationPending: state.metadesk.getNotificationPending,
      subscribeInfo: state.metadesk.subscribeInfo,
      fetchPerformancesPending: state.metadesk.fetchPerformancesPending,
      performanceCount: state.metadesk.performanceCount,
      performances: state.metadesk.performances,
      performanceData: state.metadesk.performanceData,
      getSinglePerformancePending: state.metadesk.getSinglePerformancePending,
      performanceUserList: state.metadesk.performanceUserList,
      getPerformanceUserListPending:
        state.metadesk.getPerformanceUserListPending,
      usersCount: state.metadesk.usersCount,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchNotifications(data));
    },
    [dispatch]
  );

  const getInfoAction = useCallback(
    (data) => {
      return dispatch(getSubscribeInfo(data));
    },
    [dispatch]
  );

  const getNotificationByIdAction = useCallback(
    (data) => {
      return dispatch(getNotificationById(data));
    },
    [dispatch]
  );

  const getPerformancesAction = useCallback(
    (data) => {
      return dispatch(fetchPerformances(data));
    },
    [dispatch]
  );

  const addAction = useCallback(
    (data) => {
      return dispatch(addNotification(data));
    },
    [dispatch]
  );
  const removeAction = useCallback(
    (data) => {
      return dispatch(removeNotification(data));
    },
    [dispatch]
  );

  const getSinglePerformanceAction = useCallback(
    (data) => {
      return dispatch(getPerformanceById(data));
    },
    [dispatch]
  );

  const getPerformanceUserListAction = useCallback(
    (data) => {
      return dispatch(getPerformanceUserList(data));
    },
    [dispatch]
  );

  return {
    notifications,
    notificationData,
    drafts,
    draftsCount,
    getNotificationPending,
    fetchDraftsPending,
    subscribeInfo,
    notificationUsers,
    getNotificationById: getNotificationByIdAction,
    getSubscribeInfo: getInfoAction,
    fetchNotifications: boundAction,
    updateNotification: addAction,
    removeNotification: removeAction,
    fetchPerformances: getPerformancesAction,
    getPerformanceById: getSinglePerformanceAction,
    getPerformanceUserList: getPerformanceUserListAction,
    fetchNotificationsPending,
    notificationsCount,
    updateNotificationPending,
    fetchPerformancesPending,
    performanceCount,
    performances,
    performanceData,
    getSinglePerformancePending,
    getPerformanceUserListPending,
    performanceUserList,
    usersCount,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case NOTIFICATIONS_FETCH_BEGIN:
      return {
        ...state,
        [action.published
          ? "fetchNotificationsPending"
          : "fetchDraftsPending"]: true,
      };
    case NOTIFICATIONS_UPDATE_BEGIN:
      return {
        ...state,
        updateNotificationPending: true,
      };

    case NOTIFICATIONS_FETCH_SUCCESS:
      const newState = {
        ...state,
        [action.published ? "notificationsCount" : "draftsCount"]:
          action.notificationsCount,
        [action.published ? "notifications" : "drafts"]: action.data,
        [action.published
          ? "fetchNotificationsPending"
          : "fetchDraftsPending"]: false,
      };
      return newState;

    case NOTIFICATION_ADD_SUCCESS:
      const addState = {
        ...state,
        notificationData: {
          ...state.notificationData,
          [action.data._id]: action.data,
        },
        updateNotificationPending: false,
      };

      return addState;
    case NOTIFICATION_UPDATE_FAIL:
      return {
        ...state,
        updateNotificationPending: false,
      };
    case NOTIFICATION_DELETE_SUCCESS:
      const removeIndex = _.findIndex(state.notifications, {
        _id: action.data,
      });
      const removeList = [...state.notifications];
      _.pullAt(removeList, [removeIndex]);

      return {
        ...state,
        notifications: removeList,
        updateNotificationPending: false,
      };

    case GET_NOTIFICATION_BEGIN:
      return {
        ...state,
        getNotificationPending: true,
      };

    case GET_NOTIFICATION_INFO_SUCCESS:
      return {
        ...state,
        subscribeInfo: action.data,
      };

    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          [action.notificationId]: action.data,
        },
        getNotificationPending: false,
      };

    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        getNotificationPending: false,
      };

    case GET_PERFORMANCE_INFO_BEGIN:
      return {
        ...state,
        fetchPerformancesPending: {
          ...state.fetchPerformancesPending,
          [action.category]: true,
        },
      };

    case GET_PERFORMANCE_INFO_SUCCESS:
      return {
        ...state,
        performances: { ...state.performances, [action.category]: action.data },
        performanceCount: {
          ...state.performanceCount,
          [action.category]: action.performanceCount,
        },
        fetchPerformancesPending: {
          ...state.fetchPerformancesPending,
          [action.category]: false,
        },
      };

    case GET_PERFORMANCE_INFO_FAILURE:
      return {
        ...state,
        fetchPerformancesPending: {
          ...state.fetchPerformancesPending,
          [action.category]: false,
        },
      };

    case NOTIFICATIONS_FETCH_FAILURE:
      return {
        ...state,
        [action.published
          ? "fetchNotificationsPending"
          : "fetchDraftsPending"]: false,
      };

    case GET_SINGLE_PERFORMANCE_BEGIN:
      return {
        ...state,
        getSinglePerformancePending: true,
      };

    case GET_SINGLE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        getSinglePerformancePending: false,
        performanceData: {
          ...state.performanceData,
          [action.notificationId]: action.data,
        },
      };

    case GET_SINGLE_PERFORMANCE_FAILURE:
      return {
        ...state,
        getSinglePerformancePending: false,
      };

    case GET_SINGLE_PERFORMANCE_USERS_BEGIN:
      return {
        ...state,
        getPerformanceUserListPending: true,
      };

    case GET_SINGLE_PERFORMANCE_USERS_SUCCESS:
      return {
        ...state,
        getPerformanceUserListPending: false,
        performanceUserList: {
          ...state.performanceUserList,
          [action.notificationId]: action.data,
        },
        usersCount: action.usersCount,
      };

    case GET_SINGLE_PERFORMANCE_USERS_FAILURE:
      return {
        ...state,
        getPerformanceUserListPending: false,
      };

    default:
      return state;
  }
}
