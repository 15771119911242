import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import OrganizationMembers from "./OrganizationMembers";
import OrganizationRoles from "./OrganizationRoles";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { useSearchParams } from "react-router-dom";
import { parse } from "date-fns";

export default function Organization() {
  const { formatMessage } = useIntl();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const initialSearchParams = parseInt(urlSearchParams.get("tab")) || 0;
  const [value, setValue] = React.useState(initialSearchParams);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: `Members`,
      content: <OrganizationMembers />,
      _id: 1,
    },
    {
      label: `Roles Setting`,
      content: <OrganizationRoles />,
      _id: 2,
    },
  ];
  return (
    <div>
      <div className="title">
        {formatMessage({ id: "organizationSetting.organization.title" })}
      </div>
      <div className="description" style={{ marginBottom: 20 }}>
        {formatMessage({ id: "organizationSetting.organization.description" })}
      </div>
      <Tabs tabs={tabs} value={value} onChange={handleChange} />
    </div>
  );
}
