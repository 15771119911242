import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledSubtitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[700],
    fontSize: "14px",
    fontWeight: 500,
  };
});

export const StyledMemo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[600],
    fontSize: "12px",
    fontWeight: 400,
  };
});

export const StyledColorLabel = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, background }) => {
  return {
    background,
    width: "12px",
    height: 8,
    borderRadius: 3,
    marginRight: 8,
  };
});

export const StyledSecondaryValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.purple[500],
    fontSize: "18px",
    fontWeight: 500,
    marginTop: 8,
    position: "absolute",
  };
});
