import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { apiUrl } from "features/configure";
import { SinglePerformanceContext } from "../context/SinglePerformanceProvider";
import moment from "moment";

export default function useGetPerformanceDetail() {
  const [chartData, setChartData] = useState([]);
  const [campaignInfo, setCampaignInfo] = useState("");
  const { filterDetail, setIsLoading, filterData, setFilterData } = useContext(
    SinglePerformanceContext
  );
  const params = new URLSearchParams(window.location.search);
  const dimension = params.get("dimension");
  const dimensionValue = params.get("dimensionValue");

  // const {
  //   userType,
  //   walletBalance,
  //   walletAge,
  //   objectiveAchieved,
  //   periodStart,
  //   periodEnd,
  //   tag,
  // } = filterDetail;

  const {
    userType,
    walletBalance,
    walletAge,
    objectiveAchieved,
    tag,
    periodStart,
    periodEnd,
    utmId,
  } = filterData;
  const { entity, id } = useParams();

  useEffect(() => {
    async function getPerformanceData() {
      try {
        setIsLoading((prev) => ({ ...prev, chart: true }));

        const { data } = await axios.get(
          `${apiUrl}/api/campaignPerformance/${entity}/detail/${id}/chart?`,
          {
            params: {
              userType,
              walletBalance,
              walletAge,
              objectiveAchieved,
              tag,
              ...(periodStart && {
                from: moment(periodStart).valueOf(),
              }),
              ...(periodEnd && {
                to: moment(periodEnd).valueOf(),
              }),
              dimensionValue: dimensionValue,
              dimension: dimension,
              utmId: utmId,
            },
          }
        );
        const { chart, campaign } = data;
        setChartData(chart);
        setCampaignInfo(campaign);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prev) => ({ ...prev, chart: false }));
      }
    }

    getPerformanceData();
  }, [filterData]);

  return { chartData, campaignInfo };
}
