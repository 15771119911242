import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import MuiDrawer from "@mui/material/Drawer";

import { Tabs, Tab, Collapse, Box } from "@mui/material";
import TabPanel from "components/TicketDetail/TabPanel";

export const drawerWidth = 200;
export const moduleWidth = 56;

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "> .MuiPaper-root": {
    overflowY: "unset",
    background: "transparent",
    position: "fixed",
    borderRight: "0px",
  },
}));

StyledDrawer.displayName = "StyledDrawer";

const StyledTabs = styled(Tabs, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({}));

StyledTabs.displayName = "StyledTabs";

const StyledDrawerContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  zIndex: 1200,
}));

const StyledDrawerMenu = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  margin: "8px 0",
  padding: "4px",
  background: "white",
  borderRadius: "8px",
  color: theme.customColors.grey[700],
  fontWeight: 500,
  height: "calc(100vh - 16px)",
  zIndex: 1000,
}));

StyledDrawerContainer.displayName = "StyledDrawerContainer";

const StyledTab = styled(Tab, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, underline, upgrade }) => ({
  "&.MuiTab-root": {
    minWidth: "unset",
    minHeight: "unset",
    padding: 0,
    marginBottom: "12px",
    width: "48px",
    height: "48px",
    overflow: "visible",
    color: theme.customColors.grey[upgrade ? 500 : 700],
    fontSize: "10px",
    fontWeight: 500,
    opacity: 1,
    textTransform: "none",
    "&:hover": {
      color: theme.customColors.purple[500],
    },
    "&.Mui-selected": {
      color: theme.customColors.purple[500],
      backgroundColor: theme.customColors.purple[100],
      borderRadius: 3,
      fontWeight: 700,
    },
    borderBottom: underline && "1px solid #e6e6e6",
  },
}));
const StyledNoneTab = styled(Tab, {
  shouldForwardProp: isStyledPropsValid,
})(({}) => ({
  "&.MuiTab-root": {
    display: "none",
  },
}));

const StyledLineTab = styled(Tab, {
  shouldForwardProp: isStyledPropsValid,
})(({}) => ({
  "&.MuiTab-root": {
    minWidth: "unset",
    minHeight: "unset",
    padding: 0,
    width: "35px",
    height: "2px",
    overflow: "visible",
    marginBottom: "10px",
    marginLeft: 5,
    borderBottom: "2px solid #e6e6e6",
  },
}));
StyledTab.displayName = "StyledTab";

const StyledTabPanel = styled(TabPanel, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  flex: 1,
}));
StyledTabPanel.displayName = "StyledTabPanel";

export {
  StyledDrawer,
  StyledTabs,
  StyledDrawerContainer,
  StyledTab,
  StyledLineTab,
  StyledTabPanel,
  StyledDrawerMenu,
  StyledNoneTab,
};
