import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";

const TextWithTooltip = ({ data, style = {}, limitRows = 3 }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const checkOverflow = () => {
    if (textRef.current) {
      setIsOverflow(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  };

  useEffect(() => {
    checkOverflow(); // Initial check on mount

    const handleResize = () => {
      checkOverflow(); // Re-check on window resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);

  const textStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: limitRows,
    WebkitBoxOrient: "vertical",
    lineHeight: "1.2",
    maxHeight: `${limitRows * 1.2}rem`,
    width: "100%",
    wordBreak: "break-word",
    fontSize: 12,
    color: "#555555",
    ...style,
  };

  return (
    <>
      {isOverflow ? (
        <IconTooltip
          content={data}
          icon={
            <Box
              ref={textRef}
              style={{
                cursor: "pointer",
                ...textStyle,
              }}
            >
              {data}
            </Box>
          }
        />
      ) : (
        <Box ref={textRef} style={textStyle}>
          {data}
        </Box>
      )}
    </>
  );
};

export default TextWithTooltip;
