import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";

import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import Box from "@mui/material/Box";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import CancelIcon from "@mui/icons-material/Cancel";

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "1200px",
    borderRadius: "5px",
    width: "90vw",
  },
}));

export const StyledDialogContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "80vh",
  display: "flex",
  flexDirection: "column",
}));

export const StyledDialogHeader = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: `0px 0px 10px 0px #0000001A`,
  borderBottom: `1px solid ${theme.customColors.grey[300]}`,
}));

export const StyledDialogContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  overflow: "hidden",
  flex: 1,
}));

const ballLoadingAnimation = keyframes({
  "0%": {
    transform: "translateY(2px)",
  },
  "50%": {
    transform: "translateY(0px)",
  },
  "100%": {
    transform: "translateY(2px)",
  },
});

export const StyledLoadingBall = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, index }) => ({
  width: "6px",
  height: "6px",
  borderRadius: "50%",
  backgroundColor: "#D9D9D9",
  animation: `${ballLoadingAnimation} 1s infinite`,
  animationDelay: index * 0.2 + "s",
}));

export const StyledDialogScrollContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  overflowY: "auto",
  height: "100%",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));

export const StyledDialogInputArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSendMsgContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  // borderTop: `1px solid #EBEBEB`,
  height: "150px",
  background: `${theme.customColors.grey[200]}`,
  borderRadius: "20px",
  position: "relative",
}));

export const StyledSendInputContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
}));

export const StyledSendInput = styled(InputField, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  display: "flex",
  "&.MuiTextField-root": {
    paddingLeft: "12px",
  },
  "& .MuiInputBase-input": {
    // background: `${theme.customColors.grey[200]}`,
    background: `transparent`,
    // borderRadius: "20px",
    padding: "10px",
    overflowY: "auto !important",
    lineHeight: 1.5,
    "&::placeholder": {
      fontSize: "12px",
    },
  },
  "& .MuiInputBase-root": {
    background: `transparent`,

    padding: 0,
    fontSize: "12px",
    "&.Mui-disabled": {
      background: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    boxShadow: "none !important",
  },
}));

export const StyledToolsBar = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  background: `#F3F3F3`,
  borderRadius: "20px",
  width: "100%",
  minHeight: "43px",
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "space-between",
  padding: "5px",
}));

export const StyledAddMsgBtn = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: `${theme.customColors.grey[300]}`,
  color: `${theme.customColors.white}`,
  padding: "7px 7px 7px 9px",
  borderRadius: "12px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& i": {
    fontSize: "16px",
    color: `${theme.customColors.white}`,
  },
}));

export const StyledSendBtn = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, disabled }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  background: `${theme.customColors.purple[500]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "& i": {
    fontSize: "16px",
    color: `${theme.customColors.white}`,
  },
  ...(disabled && {
    cursor: "default",
    background: `${theme.customColors.grey[500]}`,
  }),
}));

export const StyledCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  width: "24px",
  height: "24px",
  "& svg": {
    height: "100%",
    width: "100%",
  },
  "&:hover": {
    background: "none",

    "& path": {
      transition: "0.3s",
      fill: theme.customColors.purple[500],
    },
  },
}));

export const StyleImgPreviewContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  padding: "6.5px 10px",
  height: "50px",
}));

export const StyledCancelIcon = styled(CancelIcon, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  width: "16px",
  height: "16px",
  right: "-8px",
  top: "-8px",
  cursor: "pointer",
  display: "none",
}));

export const StyleImgPreviewItemContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "36px",
  height: "36px",
  borderRadius: "5px",
  border: `1px solid ${theme.customColors.grey[300]}`,
  marginRight: "8px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#F1F6FF",
  "& i": {
    color: theme.entityColor.primaryColor,
  },

  "& img": {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },

  "&:hover": {
    [StyledCancelIcon]: {
      display: "block",
    },
  },
}));
