import {
  COLOR,
  IconButton,
  VARIANT,
  Button,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import BasicDialog from "@metacrm/metacrm-material-ui/dist/Dialog";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { selectRemindBarShow } from "features/metadesk/redux/globalState/globalState.selector";
import { setRemindBarShow } from "features/metadesk/redux/globalState/globalState.action";
import useNavigateEntity from "hooks/useNavigateEntity";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";

export default function RemindBanner() {
  const { formatMessage } = useIntl();
  const isDemoSite = useSelector(selectDemoSite);
  const theme = useTheme();
  const dispatch = useDispatch();
  const remindBarShow = useSelector(selectRemindBarShow);
  const [remindOpen, setRemindOpen] = useState(false);
  const [remindChecked, setRemindChecked] = useState(false);
  const { navigateEntity } = useNavigateEntity();

  const handleCloseRemindBar = () => {
    if (remindChecked) {
      localStorage.setItem("version_remind_not_show", true);
    }
    dispatch(setRemindBarShow(false));
    setRemindOpen(false);
  };

  const handleOpenRemindModal = () => {
    setRemindOpen(true);
  };

  const handleToIntegration = () => {
    navigateEntity("/settings/general/app/update-list");
  };

  const renderRemindBar = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#FFC814",
          padding: "10px 20px",
          zIndex: 100,
          color: "#000000",
          alignItems: "center",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          display: remindBarShow ? "flex" : "none",
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: theme.customColors.purple[500],
          }}
        >
          <Box
            component="i"
            className="meta-crm-icon-ic_alert font-size-16"
            mr="5px"
          />
          {formatMessage({ id: "global.portal.upgrade.description" })}
        </Box>
        <Box display={"flex"} alignItems={"center"} ml="auto">
          <Box
            sx={{
              border: `1px solid ${theme.customColors.purple[500]}`,
              borderRadius: "20px",
              cursor: "pointer",
              padding: "4px 24px",
              marginRight: "10px",
              transition: "all 0.3s",
              color: theme.customColors.purple[500],
              "&:hover": {
                backgroundColor: theme.customColors.purple[500],
                color: "#ffffff",
                border: `1px solid ${theme.customColors.purple[500]}`,
              },
            }}
            onClick={handleToIntegration}
          >
            {formatMessage({ id: "global.upgrade.button" })}
          </Box>
          <IconButton
            color={theme.customColors.purple[500]}
            size={24}
            onClick={handleOpenRemindModal}
          >
            <i className="meta-crm-icon-ic_cancel font-size-24" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const renderRemindDialog = () => {
    return (
      <BasicDialog
        dialogRootStyle={{ maxWidth: "435px" }}
        open={Boolean(remindOpen)}
        onClose={() => setRemindOpen(false)}
      >
        <Box
          padding={"48px 48px 20px"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box sx={{ fontSize: "18px", fontWeight: 700, marginBottom: "12px" }}>
            {formatMessage({ id: "popup.versionNotification.title" })}
          </Box>
          <Box
            color="#5C5C5C"
            sx={{ textAlign: "center", marginBottom: "15px" }}
          >
            {formatMessage({ id: "popup.versionNotification.description" })}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mb="20px"
          >
            <Button sx={{ minWidth: "120px" }} onClick={handleCloseRemindBar}>
              {formatMessage({ id: "global.btn.confirm" })}
            </Button>

            <Button
              minWidth="120px"
              sx={{ marginTop: "5px", minWidth: "120px" }}
              color={COLOR.INFO}
              variant={VARIANT.TEXT}
              onClick={() => setRemindOpen(false)}
            >
              {formatMessage({ id: "global.btn.cancel" })}
            </Button>
          </Box>

          <Box>
            <Checkbox
              checked={remindChecked}
              onChange={() => setRemindChecked(!remindChecked)}
              label={formatMessage({ id: "global.btn.reminder" })}
            />
          </Box>
        </Box>
      </BasicDialog>
    );
  };
  if (isDemoSite) return <div />;
  return (
    <>
      {renderRemindBar()}
      {renderRemindDialog()}
    </>
  );
}
