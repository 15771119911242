import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { get, set, isEmpty } from "lodash-es";

import {
  StyledDialog,
  StyledDialogContainer,
  StyledCloseBtn,
  StyledDialogHeader,
  StyledDialogContent,
  StyledDialogScrollContent,
  StyledSendMsgContainer,
  StyledSendInput,
  StyledToolsBar,
  StyledSendBtn,
  StyledLoadingBall,
} from "./AiChatDialog.styles";
import MessageItem from "./MessageItem/MessageItem";
import axios from "axios";
import { adamUrl, apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import { v4 as uuidv4 } from "uuid";
import { AdamContext } from "./Adam.context";
import {
  StyledChatAvatar,
  StyledMsgItemContainer,
} from "./MessageItem/MessageItem.styles";
import { useOutletContext, useSearchParams } from "react-router-dom";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

const supportExtension = [".jpg", ".jpeg", ".png", ".svg"];
const fileSizeLimit = 5 * 1024 * 1024;

function AiChatDialog({ onClose }) {
  const [sendText, setSendText] = useState("");
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const [entityName, isCustomDomain] = useOutletContext();
  const [threadId, setThreadId] = useState();
  const {
    isAdamFinished,
    setIsAdamFinished,
    step,
    setStep,
    handleSetPromotionType,
    chatMsg,
    setChatMsg,
    loading,
    setLoading,
    setAudienceNumber,
    setAdamAudienceId,
  } = useContext(AdamContext);
  const dispatchSnackbar = useDispatchSnackbar();

  const frontendResponse = [
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Which promotion types are you interested in?",
        },
        {
          type: "options",
          data: [
            {
              label: "KOL Marketing",
            },
            {
              label: "Brand Collaboration",
            },
            {
              label: "New Product Launch",
            },
            {
              label: "Event Invitation",
            },
            {
              label: "Flyer Push",
            },
            {
              label: "Limited Time Offer",
            },
            {
              label: "User Survey",
            },
            {
              label: "Re-engagement Campaign",
            },
          ],
        },
      ],
    },
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "What's your campaign name?",
        },
        {
          type: "input",
          name: "campaignName",
          data: "Give a campaign name",
        },
      ],
    },
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Add campaign description?",
        },
        {
          type: "multiInput",
          name: "campaignDescription",
          data: "Give a campaign description",
        },
      ],
    },
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Add campaign content title?",
        },
        {
          type: "input",
          name: "campaignContentTitle",
          data: "Give a campaign content title",
        },
      ],
    },
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Add campaign content?",
        },
        {
          type: "multiInput",
          name: "campaignContent",
          data: "Give a campaign content",
        },
      ],
    },
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Add campaign image?",
        },
        {
          type: "options",
          data: [
            {
              label: <>Yes, add image</>,
              apiUrl: "api/gcp/uploadCampaign",
              btnType: "file",
            },
            {
              label: "No",
              func: () => {
                setStep((prevStep) => prevStep + 1);
              },
            },
          ],
        },
        {
          type: "image",
          data: "",
        },
      ],
    },
    {
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Set your publishing scheduled time?",
        },
        {
          type: "time",
          name: "campaignScheduledTime",
        },
      ],
    },

    {
      _id: "664717fe77f8234fad65q124ssd13",
      imagesUrl: [],
      isAdamResponse: true,
      contents: [
        {
          type: "content",
          data: "Here is your campaign summary",
        },
        {
          type: "campaignResult",
        },
      ],
    },
  ];

  const scrollToBottom = async () => {
    const offsetBottom = messagesEndRef.current?.scrollHeight;
    messagesEndRef.current?.scrollTo({ top: offsetBottom });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMsg.length]);

  const handleChange = (e) => {
    setSendText(e.target.value);
  };

  const handleSubmit = async (text) => {
    try {
      if (loading) return;
      const newMsgId = uuidv4();
      if (text.trim() === "") return;
      const sendData = {
        message: text,
        _id: newMsgId,
        created: new Date(),
      };
      setChatMsg((prevMsg) => [...prevMsg, { ...sendData }]);

      if (isAdamFinished) {
        handleSetPromotionType(text);
      } else {
        setLoading(true);
        setSendText("");
        const { data } = await axios.post(
          `${adamUrl}/api/adam/chat/${threadId}`,
          sendData
        );
        setLoading(false);
        if (data?.type && data.type === "result") {
          setIsAdamFinished(true);
          const { _id, count } = data.data;
          setAudienceNumber(count);
          setAdamAudienceId(_id);
          setStep((prevStep) => prevStep + 1);
        } else {
          console.log("ai message data:", data);

          // filter out empty array in data
          let filterdData = _.filter(data, (msg) => {
            return !isEmpty(msg);
          });

          let formattedMsg = _.map(filterdData, (msg) => {
            return {
              _id: uuidv4(),
              contents: msg,
              created: new Date(),
              isAdamResponse: true,
            };
          });

          // { type: "content", data: "It seems that the product description is missing some details. Please provide the following information:\n\n1. The name of the meme coin.\n2. A detailed description of the meme coin, including its features, purpose, and any other relevant information." }

          //

          setChatMsg((prevMsg) => [...prevMsg, ...formattedMsg]);
        }
      }
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step < 0) return;
    setChatMsg((prevMsg) => [
      ...prevMsg,
      { ...frontendResponse[step], created: new Date(), _id: uuidv4() },
    ]);
  }, [step]);

  const handleStartAdam = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${adamUrl}/api/adam/chat/start`);
      const { threadId, message } = data;
      setThreadId(threadId);
      setChatMsg([
        {
          _id: uuidv4(),
          contents: [{ type: "content", data: message }],
          created: new Date(),
          isAdamResponse: true,
        },
      ]);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleStartAdam();
  }, []);

  return (
    <>
      <StyledDialog open={true}>
        <StyledDialogContainer height={"80vh"}>
          <StyledDialogHeader>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                component={"img"}
                src={require("assets/img/adam.svg").default}
                marginRight={"8px"}
              ></Box>

              <Box fontWeight={700} fontSize={"18px"}>
                Adam Chat
              </Box>
            </Box>
            <StyledCloseBtn onClick={onClose}>
              <i className="meta-crm-icon-ic_cancel font-size-32" />
            </StyledCloseBtn>
          </StyledDialogHeader>
          <StyledDialogContent>
            <StyledDialogScrollContent ref={messagesEndRef}>
              <Box sx={{ padding: "30px 10px 0 20px" }}>
                {chatMsg?.map((msgInfo, index) => (
                  <MessageItem
                    handleSubmit={handleSubmit}
                    msgInfo={{
                      ...msgInfo,
                    }}
                    active={index === chatMsg.length - 1}
                    key={msgInfo.msgId}
                  />
                ))}
                {loading && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    alignItems={"flex-start"}
                    p="0 0 16px 12px"
                  >
                    <Box display="flex" alignItems="center" mb="5px">
                      <StyledChatAvatar>
                        <img
                          src={require("assets/img/adam.svg").default}
                          alt=""
                        />
                      </StyledChatAvatar>
                    </Box>
                    <Box width={"100%"}>
                      <StyledMsgItemContainer isAdamResponse={true}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                            my: "3px",
                          }}
                        >
                          {Array.from({ length: 3 }).map((_, idx) => (
                            <StyledLoadingBall index={idx} />
                          ))}
                        </Box>
                      </StyledMsgItemContainer>
                    </Box>
                  </Box>
                )}
              </Box>
            </StyledDialogScrollContent>
          </StyledDialogContent>
          <Box padding={"18px 20px"}>
            <StyledSendMsgContainer>
              <StyledSendInput
                value={sendText}
                onChange={handleChange}
                multiline
                placeholder="Tell Me What Do You Want ?"
                onEnter={() => handleSubmit(sendText)}
                rows={5}
                disabled={isAdamFinished}
              />

              <StyledToolsBar>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {!loading && (
                    <StyledSendBtn
                      size={16}
                      onClick={() =>
                        isAdamFinished ? () => {} : handleSubmit(sendText)
                      }
                      sx={{ marginLeft: "auto" }}
                      disabled={isAdamFinished}
                    >
                      <i className="meta-crm-icon-ic_send font-size-16" />
                    </StyledSendBtn>
                  )}
                </Box>
              </StyledToolsBar>
            </StyledSendMsgContainer>
          </Box>
        </StyledDialogContainer>
      </StyledDialog>
    </>
  );
}

export default AiChatDialog;
