import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  ListItem,
  ListItemSecondaryAction,
  Collapse,
  Box,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";

const StyledListItem = styled(ListItem)(
  ({
    header,
    content,
    selected,
    formPage,
    error,
    disabled,
    isMenu,
    isSubMenu,
    subMenuSelected,
  }) => ({
    color: `#555555`,
    marginLeft: 0,
    fontSize: "12px",
    fontWeight: 600,
    padding: "13px 13px",
    paddingRight: 0,
    marginTop: 0,
    minHeight: "36px",
    marginBottom: "5px",
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: `#F7F1FE`,
      color: "#5643CC",
      fontWeight: "bold",
    },
    "&:hover": {
      color: `#7B61FF`,
      background: "transparent",
      "&.Mui-selected": {
        backgroundColor: `#F7F1FE`,
        color: "#5643CC",
        fontWeight: "bold",
      },
    },
    ...(header && {
      fontSize: "12px",
      fontWeight: 500,
      minHeight: "36px",
      borderTop: "1px solid #E6E6E6",
      color: "#A5A5A5",
      "&:hover": { backgroundColor: `transparent` },
      cursor: "none",
    }),
    ...(content && {
      fontSize: "12px",
      padding: 0,
    }),
    ...(formPage && {}),
    ...((selected || subMenuSelected) && {
      color: "#5643CC",
      fontWeight: "bold",
      backgroundColor: `#F7F1FE`,
      borderRight: "3px solid #7B61FF",
    }),
    ...(error && {
      border: "1px solid #FF3296",
    }),
    ...(disabled && {
      color: "#A5A5A5",
    }),
    ...(isMenu && {
      "&:hover": { backgroundColor: "transparent", color: `#7B61FF` },
      "&.Mui-selected": {
        backgroundColor: `#F7F1FE`,
        color: "#5643CC",
        fontWeight: "bold",
      },
    }),
    ...(isSubMenu &&
      subMenuSelected && {
        backgroundColor: `transparent`,
        color: "#7B61FF",
        fontWeight: "bold",
        borderRight: "0px",
      }),
  })
);

const StyledSubItem = styled(ListItem)(({ theme }) => ({
  padding: "8px 8px",
  minHeight: "28px",
  fontSize: "12px",
  paddingLeft: "25px",
  paddingRight: 0,
  marginBottom: "4px",
  borderRadius: 0,
  cursor: "pointer",
  "&.Mui-selected": {
    backgroundColor: `#F7F1FE`,
    color: "#5643CC",
    fontWeight: "bold",
  },
  "&:hover": {
    color: `#7B61FF`,
    background: "transparent",
    "&.Mui-selected": {
      backgroundColor: `#F7F1FE`,
      color: "#5643CC",
      fontWeight: "bold",
    },
  },
}));

const StyledArrow = styled(({ open, ...other }) =>
  open ? <ExpandLessIcon {...other} /> : <ExpandMoreIcon {...other} />
)(({ theme, open }) => ({
  width: 18,
  height: 18,
  marginRight: 5,
  color: open ? "#5643CC" : "inherit",
}));

export default function CustomListItem({
  label,
  icon,
  onClick,
  header,
  content,
  rightIcon,
  formPage,
  error,
  selected,
  disabled,
  right,
  enabled = true,
  isMenu = false,
  isSubMenu = false,
  subItems = [],
  isNew,
  handleSubItemClick = () => {},
  defaultOpen = true,
  disableSubMenuSelected,
  ...props
}) {
  const [menuOpen, setMenuOpen] = useState(defaultOpen);

  const handleClick = (e) => {
    if (disabled) return;
    if (!menuOpen) setMenuOpen(true);
    if (subItems.length > 0) {
      handleItemClick(subItems[0]);
    }
    if (onClick) onClick();
  };

  const subMenuSelected =
    !disableSubMenuSelected && subItems.find((item) => item.selected);

  const renderNew = () => {
    return <StatusLabel color="warning">New</StatusLabel>;
  };

  const toggleArrow = () => {
    if (disabled) return;
    setMenuOpen((prev) => !prev);
  };

  const handleItemClick = (subItem) => {
    if (subItem.disabled) return;
    handleSubItemClick(subItem);
  };

  return (
    <Box position="relative">
      <StyledListItem
        onClick={handleClick}
        disabled={disabled}
        selected={selected || subMenuSelected}
        header={header}
        content={content}
        formPage={formPage}
        error={error}
        isMenu={isMenu}
        isSubMenu={isSubMenu}
        subMenuSelected={subMenuSelected}
        {...props}
      >
        <Box>{icon}</Box>
        <Box sx={{ overflow: "hidden" }}>{label}</Box>

        <ListItemSecondaryAction sx={{ right: "4px" }}>
          {isNew && renderNew()}
          {isMenu && (
            <Box
              onClick={toggleArrow}
              sx={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
            >
              <StyledArrow open={menuOpen} />
            </Box>
          )}
          {rightIcon}
          {error && (
            <ErrorOutlineIcon
              sx={{
                color: "#FF3296",
                verticalAlign: "middle",
                margin: "5px",
                fontSize: "11px",
              }}
            />
          )}
        </ListItemSecondaryAction>
      </StyledListItem>
      {isMenu && subItems.length > 0 && (
        <Collapse
          in={menuOpen}
          timeout="auto"
          unmountOnExit
          style={{ margin: "4px 0" }}
        >
          {subItems.map((subItem, i) => {
            if (subItem.isMenu) {
              return (
                <CustomListItem
                  key={subItem.id || i}
                  isSubMenu={true}
                  label={subItem.label}
                  disabled={subItem.disabled}
                  isMenu={true}
                  sx={{ paddingLeft: "23px", minHeight: "28px" }}
                  handleSubItemClick={(sub) => handleItemClick(sub)}
                  subItems={subItem.subItems.map((i) => ({
                    ...i,
                    style: { paddingLeft: "31px" },
                  }))}
                  defaultOpen={defaultOpen}
                />
              );
            }
            return (
              <StyledSubItem
                key={i}
                onClick={() => handleItemClick(subItem)}
                selected={subItem.selected}
                sx={{
                  background: subItem.background,
                  ...subItem.style,
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    fontWeight: 600,
                    marginLeft: "1px",
                    width: "100%",
                  }}
                >
                  {subItem.label}
                </Box>
                {subItem.rightIcon && (
                  <ListItemSecondaryAction>
                    <Box
                      sx={
                        subItem.selected
                          ? { color: "#5643CC", fontWeight: "bold" }
                          : {}
                      }
                    >
                      {subItem.rightIcon}
                    </Box>
                  </ListItemSecondaryAction>
                )}
              </StyledSubItem>
            );
          })}
        </Collapse>
      )}
    </Box>
  );
}
