import Button, {
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import PureModal from "features/metadesk/campaign/campaignList/component/PureModal";
import UserTag from "features/metadesk/components/UserTag";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useContext, useState } from "react";
import { useOutletContext } from "react-router";
import { SinglePerformanceContext } from "../../context/SinglePerformanceProvider";
import CustomAssignModal from "components/CustomModals/CustomAssignModal";

export default function AssignModal({ selected, handleClose }) {
  const { setUpdate } = useContext(SinglePerformanceContext);
  const [tags, setTags] = useState([]);
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  async function handleAssign() {
    try {
      const data = {
        type: "user",
        tagIds: tags,
        targetIds: selected.map((item) => item._id),
      };
      const result = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/batchApply/user`,
        data
      );
      setUpdate((prev) => prev + 1);

      dispatchSnackbar({
        message: "Batch assign tags success.",
        variant: "info",
      });
    } catch (error) {
      dispatchSnackbar({
        message: "Batch assign tags failed.",
      });
    } finally {
      handleClose();
    }
  }

  return (
    <CustomAssignModal
      maxWidth="500px"
      open={true}
      handleAssign={handleAssign}
      handleClose={handleClose}
      tags={tags}
      setTags={setTags}
    />
  );
}
