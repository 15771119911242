import React, { useState, useEffect, useRef, useMemo } from "react";
import { get, isEmpty } from "lodash-es";
import { useOutletContext } from "react-router-dom";
import { Grid } from "@mui/material";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import { useForm, useFormState, Controller } from "react-hook-form";
import {
  formatCalendarDateAndTime,
  isVersionLessThan,
  validateURL,
} from "features/helpers/utils";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import { parseCustomDomainUrl, getBaseUrl } from "features/helpers/utils";
import { apiUrl } from "features/configure";

import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useZoomIn } from "features/home/ZoomInDialogProvider";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";
import {
  selectServiceModule,
  selectWidgetVersionCanUpdate,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useIntl } from "react-intl";

import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import {
  StyledFormTitle,
  StyledApiKeyCreatedTime,
  StyledGoToCustomize,
  StyledPreviewImageContainer,
  StyledHoverShowIcon,
  StyledFormTitleContent,
  StyledGreyBlock,
} from "features/metadesk/settings/integrations/IntegrationWidgetSetting.styles";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import CodePannel from "components/CodePannel/CodePannel";
import {
  generateWidgetScript,
  trackingAddressCode,
} from "features/configure/appCode";
import SriCodeTab from "features/metadesk/appUpdate/SriCodeTab";
import StepContent from "./StepContent";
import { v4 as uuidv4 } from "uuid";
import WhySriBlock from "../../WhySriBlock";
import WidgetTicketType from "./components/WidgetTicketType";
import RunTest from "./components/RunTest";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import ApiKey from "./components/ApiKey";
import CustomizationDialog from "./CustomizationDialog";
import _ from "lodash";

const removeHttpsPrefix = (url) => {
  if (url?.startsWith("https://")) {
    return url?.replace(/https:\/\//g, "");
  }
  return url;
};

const formDataInit = {
  apiKey: "",
  created: new Date(),
  domainName: "",
  _id: uuidv4(),
  name: "New Widget",
  isNewAdd: true,
  connected: false,
};

const WidgetAddContent = ({
  widgetItemInfo,
  integrityInfo,
  existNameList,
  setLoading,
}) => {
  const { formatMessage } = useIntl();
  const { fetchEntityConfig, ticketTypeList, entityConfig } =
    useFetchEntityConfig();
  const [entityName, isCustomDomain] = useOutletContext();
  const [formData, setFormData] = useState(formDataInit);
  const [openCustomize, setOpenCustomize] = useState(false);
  const hasService = get(entityConfig, "modules.service");

  const {
    apiKey,
    created: apiKeyCreated,
    domainName,
    _id,
    name,
    isNewAdd,
    connected,
    fixedApiKey,
  } = formData;

  const domainNameForShow = removeHttpsPrefix(formData.domainName);

  const [cursorPosition, setCursorPosition] = useState(0);
  // https://stackoverflow.com/questions/76366010/react-input-caret-jumps-to-end-if-value-gets-modified
  const nameInputRef = useRef(null);
  const domainNameInputRef = useRef(null);
  const [activeRef, setActiveRef] = useState(null);
  const confirm = useConfirm();
  const zoomIn = useZoomIn();
  const ecosystemName = useSelector(selectEcosystemName);
  const [customizationData, setCustomizationData] = useState({
    colorTheme: {
      primaryColor: "#0086E4",
      backgroundColor: "#EAF0FA",
      hoverColor: "#555555",
      textHoverColor: "#555555",
      cardColor: "#FFFFFF",
      cardHoverColor: "#F1F6FF",
    },
    isCustomizationAllowed: true,
    position: ["bottom", "right"],
    displacement: "none",
    icon: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget01-1700205700.svg",
    logo: "https://storage.googleapis.com/metadesk-dev/chat/0x61c76Cf0F256ebC8D0dAf06A1547011E8dF41241/MetaCRM-1706854890.png",
    iconSize: "normal",
  });
  const [chooseTicketType, setChooseTicketType] = useState(ticketTypeList);

  // widgetObject 只返回 apiKey apiKeyCreated 兩個東西 被包含在 entityConfig 的 widget 裡面

  const { fetchWidgetConfig, updateWidgetConfig } = useFetchWidgetConfig();

  // 'Undetected' 'Waiting' 'Enabled' 'Failed'
  const widgetIntegrationStatus = connected ? "Enabled" : "Waiting";

  const { latestVersion } = useSelector(selectWidgetVersionCanUpdate);
  const canUpdate = isVersionLessThan(
    widgetItemInfo.jsVersion || latestVersion,
    latestVersion
  );

  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid, isDirty },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  const isSaveDisabled = () => {
    if (isNewAdd) {
      return !isValid;
    }
    return !isValid || !isDirty;
  };

  const handleCheckRepeat = (value) => {
    return existNameList?.some((item) => item === value.trim())
      ? formatMessage({ id: "global.inputCheck.repeatedName" })
      : true;
  };

  const renderWidgetIntegrationStatus = () => {
    // 新add還未儲存不用顯示
    if (isNewAdd) {
      return null;
    }
    if (widgetIntegrationStatus === "Waiting") {
      return (
        <StatusLabel color={COLOR.WARNING}>
          {formatMessage({
            id: "global.status.waiting",
          })}
        </StatusLabel>
      );
    }
    if (widgetIntegrationStatus === "Enabled") {
      return (
        <StatusLabel color={COLOR.SECONDARY}>
          {formatMessage({
            id: "global.status.enabled",
          })}
        </StatusLabel>
      );
    }
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatchSnackbar = useDispatchSnackbar();

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatchSnackbar({
      message: (
        <div
          style={{ color: theme.customColors.purple[500] }}
          className="formTitle startRow"
        >
          <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
          Copied
        </div>
      ),
      variant: "info",
    });
  };

  const handleRegenerateAPI = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/apiKey/${entityName}/widget`,
        {
          widgetId: isNewAdd ? null : widgetItemInfo?._id,
        }
      );
      handleChange({
        event: { target: { value: data.apiKey, name: "apiKey" } },
      });
      setFormData({ ...formData, apiKey: data.apiKey });
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };
  // 要是是新加入的widget 並且沒有apiKey
  useEffect(() => {
    if (!apiKey) {
      handleRegenerateAPI();
    }
  }, [apiKey]);

  const handleRegenerate = () => {
    confirm({
      title: formatMessage({
        id: "popup.regenerateApiKey.title",
      }),
      content: formatMessage({
        id: "popup.regenerateApiKey.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(async () => {
        await handleRegenerateAPI();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!activeRef?.current) return;
    activeRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
  }, [widgetItemInfo]);

  const handleChange = ({ event, fieldName }) => {
    const { name, value } = event.target;

    if (fieldName === "name") {
      setActiveRef(nameInputRef);
    } else if (fieldName === "domainName") {
      setActiveRef(domainNameInputRef);
    }

    const selectionStart = event.target.selectionStart;
    setCursorPosition(selectionStart);
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (formData?.domainName) {
      setValue(
        "domainName",
        formData?.domainName?.startsWith("https://")
          ? formData.domainName
          : `https://${formData.domainName}`,
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
  }, [formData.domainName]);

  const [isSavedId, setIsSavedId] = useState("");

  const handleSaveTicketType = async (widgetId) => {
    try {
      const { data: updatedTicketTypesData } = await axios.post(
        `${apiUrl}/api/manage/ticketTypes/${entityName}/widget/${widgetId}`,
        {
          ticketTypes: _.map(chooseTicketType, (obj) => ({
            ticketType: obj._id,
            assignee: obj.assignee,
          })),
        }
      );
      const cleanerTicketTypes = (updatedTicketTypesData || []).map((tt) => ({
        _id: tt.ticketType,
        assignee: tt.assignee || null,
      }));

      setChooseTicketType(cleanerTicketTypes);
    } catch (err) {
      throw err;
    }
  };

  const handleSave = async () => {
    const newDomainName = formData.domainName.trim();
    const sendData = {
      apiKey: formData.apiKey,
      name: formData.name,
      url: newDomainName !== "" ? `https://${formData.domainName}` : "",
      widgetId: isSavedId || null,
    };

    try {
      setLoading(true);
      const response = await updateWidgetConfig({
        entityName,
        config: sendData,
        type: "integration",
      });
      const widgetId = response.data._id;
      setIsSavedId(widgetId);
      if (hasService) {
        handleSaveTicketType(widgetId);
      }
      await updateWidgetConfig({
        entityName,
        config: { ...customizationData, widgetId: widgetId },
        type: "customization",
      });
      fetchEntityConfig({ entityName });
      fetchWidgetConfig({ entityName });

      dispatchSnackbar({
        message: formatMessage({ id: "widgetSettings.setting.saveSuccess" }),
        variant: "success",
      });
    } catch (err) {
      if (err.response.data.error === "Name already exists") {
        setError("name", {
          type: "validate",
          message:
            "This name already has a widget installed. Please choose another name.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const SDKsteps = [
    {
      stepNumber: 1,
      title: formatMessage({
        id: "widgetSettings.widgetName.title",
      }),
      describe: formatMessage({
        id: "widgetSettings.widgetName.description",
      }),
      content: (
        <>
          <StyledFormTitle center item xs={12} md={4}>
            <StyledFormTitleContent isRequired>
              {formatMessage({
                id: "global.filterCriteria.name",
              })}
            </StyledFormTitleContent>
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8}>
            <InputField
              value={formData.name}
              maxLength={15}
              name={`name`}
              placeholder={formatMessage({
                id: "global.placeholder.name",
              })}
              onChange={(event) => {
                // setTestValue(event.target.value);
                handleChange({
                  event,
                });
              }}
              rules={{
                required: {
                  value: true,
                  message: `name ${formatMessage({
                    id: "global.error.required",
                  })}`,
                },
                validate: handleCheckRepeat,
              }}
              control={control}
              errors={get(errorsInfo, "name.message")}
              ref={nameInputRef}
            ></InputField>
          </Grid>
          <Grid item xs={12} mb={"16px"}></Grid>
        </>
      ),
    },
    {
      stepNumber: 2,
      title: formatMessage({
        id: "widgetSettings.domain.title",
      }),
      describe: formatMessage({
        id: "widgetSettings.domain.description",
      }),
      content: (
        <>
          {" "}
          <StyledFormTitle center item xs={12} md={4}>
            {formatMessage({
              id: "widgetSettings.domain.input",
            })}
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8}>
            <InputField
              ref={domainNameInputRef}
              placeholder={formatMessage({
                id: "widgetSettings.domain.placeholder",
              })}
              onChange={(event) => {
                handleChange({
                  event,
                });
              }}
              name={`domainName`}
              control={control}
              errors={get(errorsInfo, "domainName.message")}
              value={domainNameForShow}
              prefixWord={"https://"}
              endAdornment={
                <IconButton
                  onClick={() =>
                    copyToClipboard(`https://${domainNameForShow}`)
                  }
                >
                  <i className="meta-crm-icon-ic_copy font-size-24" />
                </IconButton>
              }
              rules={{
                validate: (value) =>
                  validateURL(value) ||
                  formatMessage({
                    id: "global.inputCheck.invalidUrl",
                  }),
              }}
            ></InputField>
          </Grid>
        </>
      ),
    },
    {
      stepNumber: 3,
      title: formatMessage({
        id: "widgetSettings.headCode.title",
      }),
      status: renderWidgetIntegrationStatus,

      describe: formatMessage(
        {
          id: "widgetSettings.headCode.description",
        },
        {
          br: <br />,
        }
      ),

      content: (
        <>
          <StyledFormTitle item xs={12} md={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              height={"24px"}
              color={canUpdate ? "#FF9D2A" : null}
            >
              {formatMessage({
                id: "snippet.widget.integration",
              })}
              {canUpdate && (
                <Box
                  bgcolor="#FF9D2A"
                  p="2px 6px"
                  fontSize="12px"
                  borderRadius="10px"
                  color="white"
                  ml="8px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      parseCustomDomainUrl(
                        isCustomDomain,
                        entityName,
                        "/settings/general/app/update-list?updateType=widget"
                      )
                    )
                  }
                >
                  {formatMessage({ id: "integration.newVersion.title" })} :
                  {" " + latestVersion}
                </Box>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <WhySriBlock />
            </Box>
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8} mb={2}>
            {!isEmpty(integrityInfo) && apiKey && (
              <SriCodeTab
                sriCode={generateWidgetScript({ integrityInfo, apiKey })}
                nonSriCode={generateWidgetScript({ apiKey })}
                onClick={handleSave}
              />
            )}
            {!apiKey && (
              <Box position={"relative"}>
                <Box
                  height="100%"
                  width="100%"
                  backgroundColor={(theme) => theme.customColors.grey[300]}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={(theme) => theme.customColors.purple[500]}
                  fontSize={"24px"}
                  fontWeight={"700"}
                  position={"absolute"}
                  zIndex={2}
                  sx={{ opacity: 0.8 }}
                >
                  {formatMessage({
                    id: "widgetSettings.apiKey.generate.hint",
                  })}
                </Box>
                <InputField
                  multiline={true}
                  InputProps={{
                    rows: 9,
                    multiline: true,
                    inputComponent: "textarea",
                  }}
                  disabled
                />
              </Box>
            )}
            <StyledGreyBlock mt={"12px"}>
              <Box
                component="i"
                className="meta-crm-icon-ic_info font-size-16"
                mr="10px"
              />

              <Box>
                {formatMessage({
                  id: "widgetSettings.headCode.instruction",
                })}
              </Box>
            </StyledGreyBlock>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className="description">
              {formatMessage({
                id: "widgetSettings.headCode.preview",
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box style={{ textAlign: "center" }}>
              <StyledPreviewImageContainer
                onClick={() =>
                  zoomIn({
                    src: require("assets/img/widgetScriptDemo.png"),
                  }).catch(() => {})
                }
              >
                <Box
                  component="img"
                  src={require("assets/img/widgetScriptDemo.png")}
                  sx={{ width: "100%", cursor: "pointer" }}
                />
                <StyledHoverShowIcon>
                  <i className="meta-crm-icon-ic_bigger font-size-24" />
                </StyledHoverShowIcon>
              </StyledPreviewImageContainer>
            </Box>
          </Grid>
        </>
      ),
    },
    {
      stepNumber: 4,
      title: formatMessage({
        id: "widgetSettings.addressIdentification.title",
      }),
      titleHint: `(${formatMessage({
        id: "global.annotation.recommended",
      })})`,
      describe: (
        <>
          <Box>
            {formatMessage(
              {
                id: "widgetSettings.addressIdentification.description",
              },
              {
                br: <br />,
              }
            )}
          </Box>
          <Box
            color={(theme) => theme.customColors.purple[500]}
            sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
          >
            <Box mr="4px" className="meta-crm-icon-ic_info font-size-16" />
            {formatMessage({
              id: "widgetSettings.addressIdentification.explanation",
            })}
          </Box>
        </>
      ),

      content: (
        <>
          <StyledFormTitle item xs={12} md={4}>
            <Box display={"flex"} alignItems={"center"} height={"24px"}>
              {formatMessage({
                id: "snippet.widget.addressIdentification",
              })}
            </Box>
            <Box className="description">
              {ecosystemName === "Solana" && (
                <Box>
                  To ensure that you can track{" "}
                  <Box
                    sx={{ display: "inline" }}
                    color={(theme) => theme.customColors.purple[500]}
                  >
                    Solflare wallet,
                  </Box>{" "}
                  you must install this method.
                </Box>
              )}
            </Box>
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8}>
            <CodePannel code={trackingAddressCode} />

            <StyledGreyBlock mt={"12px"}>
              <Box marginRight="5px">
                <i className="meta-crm-icon-ic_info font-size-16" />
              </Box>

              <Box component="span">
                {formatMessage({
                  id: "metaCRMSDK.walletConnects.addressIdentification.instruction",
                })}
              </Box>
            </StyledGreyBlock>
          </Grid>
        </>
      ),
    },
    hasService
      ? {
          stepNumber: 5,
          title: "Choose Ticket Types for the widget",
          describe:
            "Choose which ticket types users can submit through the widget.",
          content: (
            <>
              <StyledFormTitle item xs={12} md={4}>
                Ticket Type
              </StyledFormTitle>
              <Grid position={"relative"} item xs={12} md={8}>
                <WidgetTicketType
                  widgetItemInfo={formData}
                  chooseTicketType={chooseTicketType}
                  setChooseTicketType={setChooseTicketType}
                />
              </Grid>
            </>
          ),
        }
      : null,
    {
      stepNumber: hasService ? 6 : 5,
      title: formatMessage({
        id: "widgetSettings.goToCustomization.title",
      }),
      describe: formatMessage({
        id: "widgetSettings.goToCustomization.description",
      }),
      noBottomPadding: true,
      content: (
        <Box
          component={Grid}
          item
          xs={12}
          color={(theme) => theme.customColors.purple[500]}
        >
          <StyledGoToCustomize
            variant={VARIANT.TEXT}
            color={COLOR.SECONDARY}
            onClick={() => setOpenCustomize(true)}
            sx={{ textDecoration: "underline" }}
          >
            {formatMessage({
              id: "widgetSettings.goToCustomization.hyperlink",
            })}
          </StyledGoToCustomize>
        </Box>
      ),
    },
  ];

  const handleDiscard = async () => {
    await confirm({
      title: formatMessage({
        id: "popup.delete.title",
      }),
      content: (
        <>
          <Box width={"340px"} sx={{ margin: "0 auto" }}>
            {formatMessage({
              id: "popup.delete.description",
            })}
          </Box>
          <Box color={(theme) => theme.customColors.purple[500]} mt={1}>
            <Box
              component={"i"}
              className="meta-crm-icon-ic_info font-size-14"
              sx={{ display: "inline-block", transform: "translateY(2px)" }}
            />
            {formatMessage({ id: "widgetSettings.delete.explanatory" })}
          </Box>
        </>
      ),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText: formatMessage({
        id: "global.btn.cancel",
      }),
    })
      .then(async () => {
        if (isSavedId) {
          const { data } = await axios.delete(
            `${apiUrl}/api/manage/widgetConfig/${entityName}/${isSavedId}`
          );
        }

        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration"
          )
        );
      })
      .catch(() => {});
  };

  const filteredSteps = SDKsteps.filter(Boolean);
  return (
    <>
      <StyledWhiteContainer mt="24px" style={{ marginBottom: "12px" }}>
        <ApiKey
          apiKey={apiKey}
          apiKeyCreated={apiKeyCreated}
          handleRegenerate={handleRegenerate}
        />
      </StyledWhiteContainer>
      <StyledWhiteContainer style={{ marginBottom: "12px" }}>
        {filteredSteps.map((settingStep, index) => (
          <StepContent
            key={`${settingStep.title}${_id}`}
            settingStep={settingStep}
          />
        ))}
      </StyledWhiteContainer>
      <RunTest widgetId={isSavedId} />
      <StyledWhiteContainer
        style={{ marginBottom: "12px", padding: "16px 32px 24px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size={SIZE.XL}
            variant={VARIANT.OUTLINED}
            disabled={isSaveDisabled()}
            sx={{ width: 120 }}
            onClick={handleDiscard}
          >
            Discard
          </Button>
          {/* 新建立的Widget不用在乎是不是有改過欄位 都可以存 */}
          <Button
            disabled={isSaveDisabled()}
            size={SIZE.XL}
            sx={{ ml: "12px", width: 120 }}
            onClick={async () => {
              await handleSave();
              navigate(
                parseCustomDomainUrl(
                  isCustomDomain,
                  entityName,
                  "/settings/general/integration"
                )
              );
            }}
          >
            {formatMessage({
              id: "global.btn.save",
            })}
          </Button>
        </Box>
      </StyledWhiteContainer>
      {openCustomize && (
        <CustomizationDialog
          open={setOpenCustomize}
          configs={customizationData}
          setConfigs={setCustomizationData}
          handleClose={() => setOpenCustomize(false)}
        />
      )}
    </>
  );
};

export default WidgetAddContent;
