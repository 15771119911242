import { Box } from "@mui/material";
import React from "react";

export default function WhySriBlock() {
  return (
    <a
      href="https://www.metacrm.inc/blog/how-metacrm-protects-your-web3-data"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box width="100%" pr="50px" mt="10px">
        <Box
          display="flex"
          alignItems="center"
          bgcolor="#FEFBE8"
          py="12px"
          px="16px"
          borderLeft={"2px solid #FFC814"}
        >
          <Box mr="22px">
            <Box fontSize="12px" fontWeight={500} color="#555">
              🎓 Why need SRI secured installation?
            </Box>
            <Box mt="11px" fontSize="10px" fontWeight={300} color="#555">
              Enhancing Security with SRI and Self-Hosting: How MetaCRM Protects
              Your Web3 Data
            </Box>
          </Box>
          <img src={require("assets/img/sribook.svg").default} />
          <i
            className="meta-crm-icon-ic_arrowLineRight font-size-16"
            style={{ marginLeft: "8px" }}
          />
        </Box>
      </Box>
    </a>
  );
}
