import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Loading } from "features/common";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import useInterceptor from "features/home/useInterceptor.hooks";

axios.defaults.withCredentials = true;

const IntegrationConnectDiscord = () => {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  const [searchParams] = useSearchParams();
  const guild_id = searchParams.get("guild_id");
  const { isInterceptorSetup, setIsInterceptorSetup } = useInterceptor();

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(async () => {
    try {
      if (location.search.includes("error")) {
        async function getCurrentEntity() {
          const { data } = await axios.get(
            apiUrl + `/api/session/getCurrentEntity`
          );
          const { entityName } = data;
          const url = parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            `/settings/general/integration`
          );
          navigate(url);
        }
        return getCurrentEntity();
      }
      if (!isInterceptorSetup || !guild_id) return;
      async function connectDiscord() {
        const result = await axios.post(
          apiUrl + `/api/discord/ticketBot/connectDiscord`,
          {
            serverId: guild_id,
          }
        );
        const entityName = _.get(result, "data.entityName");
        const url = parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/settings/general/integration/discord?serverId=${guild_id}`
        );
        navigate(url);
      }
      return connectDiscord();
    } catch (err) {
      console.log(err);
    }
  }, [isInterceptorSetup]);

  return <Loading open={true} fullScreen={true} />;
};

export default IntegrationConnectDiscord;
