import { TRIAL_ACTION_TYPES } from "../constants";
import { replaceItemInArray } from "./trial.action";

export const TRIAL_INITIAL_STATE = {
  trial: {
    isLoading: false,
    hasConfirm: false,
    entityName: "",
  },
};

const updateTrial = (state, changes) => {
  return { ...state, trial: { ...state.trial, ...changes } };
};

export const reducer = (state = TRIAL_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case TRIAL_ACTION_TYPES.POST_REGISTER_VERIFY_START:
      return updateTrial(state, { isLoading: true });
    case TRIAL_ACTION_TYPES.POST_REGISTER_VERIFY_SUCCESS:
      return updateTrial(state, {
        entityName: payload.entityName,
        hasConfirm: true,
        isLoading: false,
      });
    case TRIAL_ACTION_TYPES.POST_REGISTER_VERIFY_FAILED:
      return updateTrial(state, { isLoading: false });
    case TRIAL_ACTION_TYPES.SET_CONFIRM_FALSE:
      return updateTrial(state, { hasConfirm: false });

    default:
      return state;
  }
};
