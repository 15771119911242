import { createSelector } from "reselect";
import { get } from "lodash-es";
import {
  aptosChainList,
  chainList as evmChainList,
  solanaChainList,
} from "features/configure";
import { isVersionLessThan } from "features/helpers/utils";
const selectEntityConfigReducer = (state) => {
  return state.metadesk.entityConfig;
};

const chainName = {
  evm: "EVM",
  aptos: "Aptos",
  solana: "Solana",
};

const chainImages = {
  evm: require("assets/img/ETH_grey_bg.png"),
  aptos: require("assets/img/aptos.png"),
  solana: require("assets/img/solana.png"),
};

const selectTicketTypeReducer = (state) => {
  return state.metadesk.ticketTypeList;
};

export const selectTicketType = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.ticketTypes
);

export const selectTicketTypeForDropdown = createSelector(
  [selectTicketTypeReducer],
  (ticketTypeList) =>
    ticketTypeList.map((ticketType) => {
      return { ...ticketType, value: ticketType._id, label: ticketType.name };
    })
);

export const selectEntityCompany = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.company
);

export const selectEntityCampaign = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.campaign
);

export const selectServiceModule = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.serviceModule
);

export const selectMarketingModule = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.marketingModule
);

export const selectAttributionVersionCanUpdate = createSelector(
  [selectMarketingModule],
  (marketingModule) => {
    if (!marketingModule) {
      return {
        version: null,
        latestVersion: null,
        canUpdate: false,
      };
    }
    const connected = get(marketingModule, "attribution.connected");
    return {
      connected,
      version: connected && marketingModule.attribution?.version,
      latestVersion: marketingModule.attribution?.latestVersion,
      canUpdate:
        connected &&
        isVersionLessThan(
          marketingModule.attribution?.version,
          marketingModule.attribution?.latestVersion
        ),
    };
  }
);

export const selectWidgetModule = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.widgetModule
);

export const selectWidgetVersionCanUpdate = createSelector(
  [selectWidgetModule],
  (widgetModule) => {
    if (!widgetModule) {
      return {
        version: null,
        latestVersion: null,
        canUpdate: false,
      };
    }
    return {
      version: widgetModule.version,
      latestVersion: widgetModule.latestVersion,
      canUpdate: isVersionLessThan(
        widgetModule.version,
        widgetModule.latestVersion
      ),
    };
  }
);

export const selectWidgetConnected = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => {
    return (
      entityConfig?.widgetModule &&
      (entityConfig?.widgetModule.connected ||
        entityConfig?.widgetModule.version)
    );
  }
);

export const selectEntityId = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig._id
);

export const selectChainIds = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.chainIds
);

export const selectNftInfo = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.nftInfo || []
);

export const selectTicketTypes = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.ticketTypes || []
);

export const selectDemoSite = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.demoSite
);

export const selectClientSite = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.clientSite
);

export const selectWidgetObject = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.widgetModule || {}
);

export const selectEcosystemName = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => get(chainName, entityConfig.ecosystem, "EVM")
);

export const selectChainNewAddItem = createSelector(
  [selectEcosystemName],
  (ecosystemName) => {
    switch (ecosystemName) {
      case "Aptos":
        return { chainId: "aptos" };
      case "EVM":
        return { chainId: 1 };
      case "Solana":
        return { chainId: "solana" };
      default:
        return {};
    }
  }
);

export const selectEcosystemImage = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) =>
    get(
      chainImages,
      entityConfig.ecosystem,
      require("assets/img/ETH_grey_bg.png")
    )
);

export const selectEntityChainList = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => {
    if (entityConfig.ecosystem === "evm") {
      return evmChainList;
    } else if (entityConfig.ecosystem === "aptos") {
      return aptosChainList;
    } else if (entityConfig.ecosystem === "solana") {
      return solanaChainList;
    } else {
      return evmChainList;
    }
  }
);

export const selectTrackingObject = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) => entityConfig.tracking || {}
);

export const selectSurveyConfig = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) =>
    entityConfig.surveyConfig || {
      isSurveyEnabled: false,
      surveyBtnStyle: "star",
      surveyMsg: "",
    }
);
