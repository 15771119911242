import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { alpha } from "@mui/material/styles";

export const StyledWhiteContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: theme.customColors.white,
  marginTop: "20px",
  borderRadius: "8px",
}));

export const StyledContainerTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#555",
  fontSize: "14px",
  fontWeight: 700,
  padding: "8px 12px",
}));

export const StyledContainerTitleIcon = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "7px",
  fontSize: "18px",
  color: theme.customColors.purple[500],
  borderRadius: "30px",
  backgroundColor: "#FAF6FF",
  height: "32px",
  width: "32px",
  fontWeight: 300,
  marginRight: "8px",
}));

export const StyledColumnTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#555",
  fontSize: "14px",
  fontWeight: 500,
  marginRight: "4px",
  paddingTop: "1px",
}));

export const StyledContentContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "20px",
  borderTop: "1px solid #E6E6E6",
}));

export const StyledSubTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#777",
  fontSize: "12px",
  fontWeight: 500,
  paddingBottom: "10px",
}));

export const StyledReferralValue = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#000000",
  fontSize: "16px",
  fontWeight: 500,
}));
