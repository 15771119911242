import { createSelector } from "reselect";

const selectAiSupportConfigReducer = (state) => {
  return state.metadesk.aiSupport;
};

export const selectAiSupportConfig = createSelector(
  [selectAiSupportConfigReducer],
  (aiSupport) => aiSupport
);

export const selectAiConfig = createSelector(
  [selectAiSupportConfigReducer],
  (aiSupport) => aiSupport?.config?.aiConfig ?? {}
);

export const selectMagicWandConfig = createSelector(
  [selectAiSupportConfigReducer],
  (aiSupport) => aiSupport?.config?.magicWandConfig || {}
);
