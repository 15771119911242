import FixedButton from "features/metadesk/settings/common/FixedButton";
import React, { useContext, useMemo, useState } from "react";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router";
import { countTotalEngagementAndTracking } from "features/metadesk/campaign/createCampaign/utils";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";

export default function EditBottomFixedButton({
  campaignContentSets,
  onSubmit,
  isValid,
  status,
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { TrackingTotal, EstimateTotal } = useMemo(
    () => countTotalEngagementAndTracking(campaignContentSets),
    [campaignContentSets]
  );

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("campaign");

  return (
    <FixedButton
      leftComponent={
        <Button
          variant={VARIANT.OUTLINED}
          sx={{ width: "144px" }}
          onClick={() => navigate(-1)}
        >
          {formatMessage({ id: "global.btn.back" })}
        </Button>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ marginRight: "20px" }}>
          {TrackingTotal || EstimateTotal ? (
            <span style={{ color: "#777777", fontWeight: 400 }}>
              {formatMessage({ id: "campaignBuild.count.estimatedReach" })}{" "}
            </span>
          ) : (
            ""
          )}
          <span
            style={{
              fontSize: "18px",
              fontWeight: 700,
              margin: "0 4px",
            }}
          >
            {TrackingTotal ? (
              <>
                <span style={{ color: "#7B61FF" }}>{TrackingTotal} </span>
                {formatMessage({ id: "campaign.edit.trackingLinks" })}
              </>
            ) : (
              ""
            )}
            {TrackingTotal && EstimateTotal ? "," : ""}
            {EstimateTotal ? (
              <>
                <span style={{ color: "#7B61FF" }}>{EstimateTotal} </span>
                {formatMessage({ id: "campaign.type.estimatedReach" })}
              </>
            ) : (
              ""
            )}
          </span>
        </p>
        {status === "scheduled" && canEdit && (
          <Button
            sx={{ width: "144px" }}
            onClick={onSubmit}
            disabled={!isValid}
          >
            {formatMessage({ id: "global.btn.update" })}
          </Button>
        )}
      </div>
    </FixedButton>
  );
}
