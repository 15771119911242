import React, { useContext, useState, useMemo, useEffect } from "react";
import _, { set } from "lodash";
import {
  StyledContainerTitle,
  StyledEngagementTitle,
  StyledWhiteCardContainer,
  StyledTitleIcon,
} from "./CampaignCreation.styles";
import { Box } from "@mui/material";
import { Radio } from "@metacrm/metacrm-material-ui/dist/Radio";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";

import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { useTheme } from "@mui/styles";
import { get } from "lodash-es";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "features/configure";
import { makeCustomTrackingLink } from "../utils/index";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { validateURL } from "features/helpers/utils";
import useCopyToClipboard from "hooks/useCopyToClipboard";

import { useDebounceEffect } from "components/ConnectWallet/ConnectWallet";
import IntergrationCover from "./IntergrationCover";

export default function CallToAction({ step, tabIndex, showTest }) {
  const theme = useTheme();
  const {
    stepData,
    setStepValues,
    isXmtpEnabled,
    isPushEnabled,
    isXmtpConnected,
    isPushConnected,
    isWidgetActive,
    isTrackingActive,
  } = useContext(CampaignCreationContext);
  const { campaignContentSets } = stepData;
  const [entityName] = useOutletContext();
  const { formatMessage } = useIntl();

  const [customSuffixWithSettingIndex, setCustomSuffixWithSettingIndex] =
    useState("");
  const [customSuffixApiError, setCustomSuffixApiError] = useState("");

  const {
    control,
    formState: { errors: errorsInfo },
    watch,
  } = useFormContext();

  const watchCampaignContentSets = watch("campaignContentSets");

  const goal = _.get(campaignContentSets, `${tabIndex}.goal`);
  const type = _.get(campaignContentSets, `${tabIndex}.type`);

  const disabledChannels = ["debankHi"];
  const onlyCustomChannels = ["debankHi", "xmtp", "push"];
  const CUSTOM_TYPE = {
    empty: "",
    aiEnhancedUser: "aiEnhancedUser",
    segment: "segment",
    tag: "tag",
    adam: "adam",
  };

  const marketChannels = [
    {
      label: formatMessage({ id: "integration.push.name" }),
      value: "push",
      auth: isPushConnected,
      enable: isPushEnabled,
      integration: "/settings/general/integration/update/push",
      image: require("assets/img/source/push.svg").default,
      intro: formatMessage({ id: "marketingChannel.push.intro" }),
    },
    {
      label: formatMessage({ id: "integration.xmtp.name" }),
      enable: isXmtpEnabled,
      value: "xmtp",
      auth: isXmtpConnected,
      integration: "/settings/general/integration/update/xmtp",
      image: require("assets/img/source/xmtp.svg").default,
      intro: formatMessage({ id: "marketingChannel.xmtp.intro" }),
    },
  ];

  useEffect(() => {
    const channel = getActiveChannel();

    if (
      type !== "widget" &&
      channel &&
      !get(
        campaignContentSets,
        `${tabIndex}.engagementDistributionSettings[0].channel`
      )
    ) {
      const temp = [...campaignContentSets];
      temp[tabIndex].engagementDistributionSettings[0].channel = channel;
      if (onlyCustomChannels.includes(channel)) {
        temp[tabIndex].engagementDistributionSettings[0].audienceType =
          "custom";
      }
      setStepValues({ campaignContentSets: temp });
    }
  }, []);

  useEffect(() => {
    const temp = [...campaignContentSets];
    const settings = temp[tabIndex].engagementDistributionSettings;
    for (let settingIndex = 0; settingIndex < settings.length; settingIndex++) {
      const s = settings[settingIndex];
      if (s.aiEnhancedUserGroup) {
        handleChangeDistributionSettings(
          settingIndex,
          "customType",
          CUSTOM_TYPE.aiEnhancedUser
        );
      }
      if (s.userGroup) {
        handleChangeDistributionSettings(
          settingIndex,
          "customType",
          CUSTOM_TYPE.segment
        );
      }
      if (s.tag) {
        handleChangeDistributionSettings(
          settingIndex,
          "customType",
          CUSTOM_TYPE.tag
        );
      }
    }
  }, []);

  useDebounceEffect(
    async () => {
      if (!customSuffixWithSettingIndex) return;
      const parts = customSuffixWithSettingIndex.split("-");
      const settingIndex = Number(parts[0]);
      const customSuffix = parts[1];
      const { error } = await checkCustomSuffixAvailability(
        customSuffix.trim()
      );
      setCustomSuffixApiError(error);
      set(
        errorsInfo,
        `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.shortLink.message`,
        error
      );
    },
    1000,
    [customSuffixWithSettingIndex]
  );

  function handleChangeDistributionSettings(
    settingIndex,
    key,
    value,
    callback
  ) {
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings[settingIndex][key] = value;
    setStepValues({ campaignContentSets: temp });
    !_.isEmpty(value) && callback && callback();
  }

  function handleChangeDistributionSettingsData(settingIndex, data, callback) {
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings[settingIndex] = {
      ...temp[tabIndex].engagementDistributionSettings[settingIndex],
      ...data,
    };
    setStepValues({ campaignContentSets: temp });
    callback && callback();
  }

  const checkCustomSuffixAvailability = async (suffix) => {
    if (!suffix) return { error: "" };
    try {
      await axios.post(`${apiUrl}/api/campaign/${entityName}/suffixavailable`, {
        customSuffix: suffix,
      });
      return { error: "" };
    } catch (error) {
      return {
        error: get(
          error,
          "response.data.error",
          get(error, "response.data.message", "error")
        ),
      };
    }
  };

  function getActiveChannel() {
    const availableChannels = new Set(
      marketChannels
        .filter((item) => !disabledChannels.includes(item.value) && item.auth)
        .map((item) => item.value)
    );

    const settingsChannels = new Set(
      campaignContentSets[tabIndex].engagementDistributionSettings.map(
        (item) => item.channel
      )
    );

    for (const channel of availableChannels) {
      if (!settingsChannels.has(channel)) {
        return channel; // If any available channel is not in the settings, return true
      }
    }

    return false; // If all available channels are in the settings, return false
  }

  return (
    <div>
      <Box position="relative">
        <IntergrationCover
          show={
            (type === "widget" && !isWidgetActive) ||
            (type === "flyer" && !(isPushConnected || isXmtpConnected))
          }
          type={type}
        />
        {campaignContentSets[tabIndex].engagementDistributionSettings?.map(
          (distributionSetting, settingIndex) => {
            return (
              <>
                {goal === "onchain" && (
                  <StyledWhiteCardContainer mb="20px">
                    <StyledContainerTitle>
                      <StyledTitleIcon>
                        <i className="meta-crm-icon-ic_link font-size-18" />
                      </StyledTitleIcon>
                      Call to action{" "}
                      <Box
                        sx={{
                          display: "inline-block",
                          textTransform: "capitalize",
                          marginLeft: "5px",
                        }}
                      >
                        ({distributionSetting.channel})
                      </Box>
                    </StyledContainerTitle>
                    <div className="mhr" style={{ margin: 0 }}></div>
                    <Box padding="20px 16px">
                      {
                        <>
                          <Box position={"relative"}>
                            <IntergrationCover
                              zIndex={2}
                              show={!isTrackingActive}
                              type="tracker"
                            />
                            <Box>
                              <StyledEngagementTitle>
                                {formatMessage({
                                  id: "campaignCreate.custom.destination",
                                })}
                              </StyledEngagementTitle>
                              <InputField
                                title={formatMessage({
                                  id: "marketingSettings.domain.title",
                                })}
                                isRequired
                                prefixWord="https://"
                                control={control}
                                placeholder="example.com"
                                name={`campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].websiteUrl`}
                                rules={{
                                  ...(watchCampaignContentSets?.[tabIndex]
                                    ?.engagementDistributionSettings?.[
                                    settingIndex
                                  ]?.useOnchain && {
                                    required: {
                                      value: true,
                                      message: `URL ${formatMessage({
                                        id: "global.error.required",
                                      })}`,
                                    },
                                    validate: (value) =>
                                      validateURL(value) ||
                                      formatMessage({
                                        id: "global.inputCheck.invalidUrl",
                                      }),
                                  }),
                                }}
                                errors={get(
                                  errorsInfo,
                                  `campaignContentSets[${[
                                    tabIndex,
                                  ]}].engagementDistributionSettings[${settingIndex}].websiteUrl.message`
                                )}
                                value={distributionSetting.websiteUrl}
                                onChange={(e) =>
                                  handleChangeDistributionSettings(
                                    settingIndex,
                                    "websiteUrl",
                                    e.target.value
                                  )
                                }
                              />
                              <p style={{ margin: "16px 0 4px" }}>
                                {formatMessage({
                                  id: "engagementCampaign.trackSetting.linkWithParameter",
                                })}
                                <IconTooltip
                                  style={{ marginLeft: "4px" }}
                                  content={formatMessage({
                                    id: "engagementCampaign.trackSetting.linkWithParameter.tooltip",
                                  })}
                                />
                              </p>
                              <DetectURL
                                distributionSetting={distributionSetting}
                                tabIndex={tabIndex}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: 10,
                                  margin: "32px 0 0px",
                                }}
                              >
                                <StyledEngagementTitle>
                                  {"Link Format Settings"}
                                </StyledEngagementTitle>
                                <Switch
                                  label={[]}
                                  labelPlacement="start"
                                  checked={
                                    distributionSetting.useLinkFormatSetting
                                  }
                                  onChange={() => {
                                    handleChangeDistributionSettingsData(
                                      settingIndex,
                                      {
                                        useLinkFormatSetting:
                                          !distributionSetting.useLinkFormatSetting,
                                        linkSetting: {},
                                      }
                                    );
                                    set(
                                      errorsInfo,
                                      `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting`,
                                      {}
                                    );
                                    setCustomSuffixApiError("");
                                  }}
                                />
                              </div>
                              {distributionSetting.useLinkFormatSetting && (
                                <Box>
                                  {type === "widget" && (
                                    <Box display="flex" alignItems="center">
                                      <div style={{ width: "25%" }}>
                                        <Radio
                                          checked={
                                            distributionSetting?.linkSetting
                                              ?.type === "namingLink"
                                          }
                                          onChange={() =>
                                            handleChangeDistributionSettingsData(
                                              settingIndex,
                                              {
                                                linkSetting: {
                                                  type: "namingLink",
                                                },
                                              }
                                            )
                                          }
                                          label={
                                            <StyledEngagementTitle
                                              style={{ margin: "16px 0" }}
                                            >
                                              {"Naming Link"}
                                            </StyledEngagementTitle>
                                          }
                                        />
                                      </div>
                                      <div style={{ width: "75%" }}>
                                        <InputField
                                          disabled={
                                            distributionSetting?.linkSetting
                                              ?.type !== "namingLink"
                                          }
                                          control={control}
                                          placeholder="You can customize the name of the link, e.g. Let's deposit"
                                          name={`campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.linkTitle`}
                                          rules={{
                                            required: {
                                              value:
                                                watchCampaignContentSets[
                                                  tabIndex
                                                ]
                                                  ?.engagementDistributionSettings[
                                                  settingIndex
                                                ]?.useLinkFormatSetting &&
                                                watchCampaignContentSets[
                                                  tabIndex
                                                ]
                                                  ?.engagementDistributionSettings[
                                                  settingIndex
                                                ]?.linkSetting?.type ===
                                                  "namingLink",
                                              message: `Link title ${formatMessage(
                                                {
                                                  id: "global.error.required",
                                                }
                                              )}`,
                                            },
                                            validate: (value) =>
                                              value?.trim() !== "" ||
                                              formatMessage({
                                                id: "global.inputCheck.empty",
                                              }),
                                          }}
                                          errors={get(
                                            errorsInfo,
                                            `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.linkTitle.message`
                                          )}
                                          value={
                                            distributionSetting?.linkSetting
                                              ?.linkTitle || ""
                                          }
                                          onChange={(e) =>
                                            handleChangeDistributionSettingsData(
                                              settingIndex,
                                              {
                                                linkSetting: {
                                                  type: "namingLink",
                                                  linkTitle: e.target.value,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                    </Box>
                                  )}
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={"space-between"}
                                  >
                                    <div style={{ width: "25%" }}>
                                      <Radio
                                        checked={
                                          distributionSetting?.linkSetting
                                            ?.type === "customSuffix"
                                        }
                                        onChange={() =>
                                          handleChangeDistributionSettingsData(
                                            settingIndex,
                                            {
                                              linkSetting: {
                                                type: "customSuffix",
                                              },
                                            }
                                          )
                                        }
                                        label={
                                          <StyledEngagementTitle
                                            style={{ margin: "16px 0" }}
                                          >
                                            {"Custom suffix"}
                                          </StyledEngagementTitle>
                                        }
                                      />
                                    </div>
                                    <div style={{ width: "75%" }}>
                                      <InputField
                                        disabled={
                                          distributionSetting?.linkSetting
                                            ?.type !== "customSuffix"
                                        }
                                        prefixWord="https://bit.ly/"
                                        control={control}
                                        placeholder="demo"
                                        name={`campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.shortLink`}
                                        rules={{
                                          required: {
                                            value:
                                              watchCampaignContentSets[tabIndex]
                                                ?.engagementDistributionSettings[
                                                settingIndex
                                              ]?.useLinkFormatSetting &&
                                              watchCampaignContentSets[tabIndex]
                                                ?.engagementDistributionSettings[
                                                settingIndex
                                              ]?.linkSetting?.type ===
                                                "customSuffix",
                                            message: `Short Link ${formatMessage(
                                              {
                                                id: "global.error.required",
                                              }
                                            )}`,
                                          },
                                          validate: (value) =>
                                            value?.trim() !== "" ||
                                            formatMessage({
                                              id: "global.inputCheck.invalidUrl",
                                            }),
                                        }}
                                        errors={
                                          get(
                                            errorsInfo,
                                            `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.shortLink.message`
                                          ) || customSuffixApiError
                                        }
                                        value={
                                          distributionSetting?.linkSetting
                                            ?.shortLink || ""
                                        }
                                        onChange={(e) => {
                                          let suffix = e.target.value;
                                          handleChangeDistributionSettingsData(
                                            settingIndex,
                                            {
                                              linkSetting: {
                                                type: "customSuffix",
                                                shortLink: e.target.value,
                                              },
                                            }
                                          );
                                          setCustomSuffixWithSettingIndex(
                                            `${settingIndex}-${suffix}`
                                          );
                                        }}
                                      />
                                    </div>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </>
                      }
                    </Box>
                  </StyledWhiteCardContainer>
                )}
              </>
            );
          }
        )}
      </Box>
    </div>
  );
}

const DetectURL = ({ distributionSetting, tabIndex }) => {
  const { stepData } = useContext(CampaignCreationContext);
  const { name } = stepData;
  const trackingUrl = makeCustomTrackingLink(
    distributionSetting,
    name,
    tabIndex
  );
  const copyFn = useCopyToClipboard(trackingUrl);

  return (
    <InputField
      disabled={true}
      value={trackingUrl}
      endAdornment={
        <i
          className="meta-crm-icon-ic_copy font-size-16"
          style={{ color: "#A5A5A5", cursor: "pointer" }}
          onClick={copyFn}
        />
      }
    />
  );
};
