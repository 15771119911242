import { curvePoolTokensMapping } from "./configure";

export function mapPoolToken(address, token) {
    address = address.toUpperCase();
    if (!curvePoolTokensMapping[address]) {
        return token;
    }
    if (!curvePoolTokensMapping[address]["argMapping"]) {
        return token;
    }
    if (curvePoolTokensMapping[address].isMetaPool) {
        return curvePoolTokensMapping[address]["underlyingCoins"][token] ? curvePoolTokensMapping[address]["underlyingCoins"][token] : token;
    }else{
        return curvePoolTokensMapping[address]["argMapping"][token] ? curvePoolTokensMapping[address]["argMapping"][token] : token;
    }
}

export function mapPoolName(address) {
    address = address.toUpperCase();
    if (!curvePoolTokensMapping[address]) {
        return "";
    }
    return curvePoolTokensMapping[address]["name"] ? curvePoolTokensMapping[address]["name"] : "";
}

export function mapPoolType(address) {
    address = address.toUpperCase();
    if (!curvePoolTokensMapping[address]) {
        return 0;
    }
    return curvePoolTokensMapping[address]["assetType"] ? curvePoolTokensMapping[address]["assetType"] : 0;
}

export function mapMethodName(method) {
    if (method == "mint" || method == "mint_many") {
        return "claim"
    } else {
        return method;
    }
}