import React, { useState } from "react";
import Back from "../components/Back";
import { StyledWhiteContainer } from "../settings/common.styles";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Box } from "@mui/material";
import Button, { AddButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import FixedButton from "../settings/common/FixedButton";
import { useOutletContext } from "react-router";
import { isEqual } from "lodash";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useNavigateEntity from "hooks/useNavigateEntity";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useForm } from "react-hook-form";

export default function CreateXCampaign() {
  const initData = {
    name: "",
    description: "",
    links: [""],
  };
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const [data, setData] = useState(initData);
  const [isLoading, setIsLoading] = useState(false);
  const { navigateEntity } = useNavigateEntity();
  const dispatchSnackbar = useDispatchSnackbar();
  const confirm = useConfirm();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: initData,
  });

  const handleChange = (event) => {
    setData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleChangeLink = (e, index) => {
    const { name, value } = e.target;
    const { links } = data;
    links[index] = value;

    setData((prev) => ({
      ...prev,
      links,
    }));
    if (!value.includes("x.com")) {
      setError(`links${index}`, {
        type: "manual",
        message:
          formatMessage({
            id: "global.inputCheck.invalidUrl",
          }) + " on X",
      });
    } else {
      clearErrors(`links${index}`);
    }
  };

  const handleDeleteLink = (index) => {
    const { links } = data;
    if (links.length === 1) return;
    links.splice(index, 1);

    setData((prev) => ({
      ...prev,
      links,
    }));
    clearErrors(`links${index}`);
  };

  const handleCreateCampaign = async () => {
    try {
      setIsLoading(true);
      const { name, description, links } = data;
      const response = await axios.post(
        `${apiUrl}/api/x/${entityName}/campaign`,
        {
          name,
          description,
          postLinks: links.map((link) => {
            if (link.includes("https://")) return link.replace("https://", "");
            return link;
          }),
        }
      );
      const { _id } = response.data;
      confirm({
        title: formatMessage({ id: "popup.campaign.create.title" }),
        content: formatMessage({ id: "popup.campaign.create.description" }),
        confirmText: formatMessage({ id: "popup.viewCampaign.btn" }),
        cancelText: formatMessage({ id: "popup.backToList.btn" }),
        onConfirm: () =>
          navigateEntity(`/marketing/xCampaign/performance/${_id}`),
        onCancel: () => navigateEntity("/marketing/xCampaign"),
        color: "secondary",
        cancelColor: "secondary",
      })
        .then()
        .catch(() => {});
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Back />
      <div className="title">
        {formatMessage({ id: "xCampaignTracking.creation.title" })}
      </div>
      <div className="description">
        {formatMessage({ id: "xCampaignTracking.creation.description" })}
      </div>
      <StyledWhiteContainer sx={{ mt: "24px", padding: "32px" }}>
        <InputField
          title={formatMessage({
            id: "XCampaignTracking.createNew.campaignName.title",
          })}
          placeholder="e.g. MetaCRM X KOL"
          onChange={handleChange}
          name="name"
          value={data.name}
          maxLength={30}
          isRequired
          sx={{ maxWidth: "662px" }}
          rules={{
            required: {
              value: true,
              message:
                formatMessage({ id: "global.commonWord.name" }) +
                formatMessage({ id: "global.error.required" }),
            },
          }}
          control={control}
          errors={errorsInfo?.name?.message}
        />
        <Box height={24} />
        <InputField
          title={formatMessage({
            id: "XCampaignTracking.createNew.description.title",
          })}
          placeholder="e.g. ABC Campaign Conversion"
          onChange={handleChange}
          name="description"
          value={data.description}
          maxLength={300}
          multiline
          isRequired
          rows={5}
          sx={{ maxWidth: "662px" }}
          rules={{
            required: {
              value: true,
              message: `Description ${formatMessage({
                id: "global.error.required",
              })}`,
            },
          }}
          control={control}
          errors={errorsInfo?.description?.message}
        />
        <Box height={24} />

        <Box mb="4px" sx={{ color: "#555" }} className="item-flex">
          {formatMessage({ id: "XCampaignTracking.createNew.xPostLink.title" })}
          <span className="required">*</span>
        </Box>
        <Box sx={{ maxHeight: "260px", overflowY: "auto", maxWidth: "662px" }}>
          {data.links.map((link, index) => (
            <>
              <InputField
                placeholder="example.com"
                onChange={(e) => handleChangeLink(e, index)}
                name="links"
                value={data.links[index]}
                prefixWord="https://"
                sx={{
                  maxWidth: "662px",
                }}
                endAdornment={
                  data.links.length > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleDeleteLink(index);
                      }}
                    >
                      <i
                        className="meta-crm-icon-ic_trash font-size-20"
                        style={{ color: "#A5A5A5" }}
                      />
                    </Box>
                  )
                }
                errors={errorsInfo?.[`links${index}`]?.message}
              />
              <Box height={data.links.length === 1 ? "24px" : "12px"} />
            </>
          ))}
        </Box>
        {data.links.length < 200 && (
          <AddButton
            onClick={() => {
              setData((prev) => ({
                ...prev,
                links: [...prev.links, ""],
              }));
            }}
            color="#7B61FF"
            borderColor="#7B61FF"
            sx={{ mb: "24px", maxWidth: "662px" }}
          >
            {formatMessage({ id: "global.btn.addLink" })}
          </AddButton>
        )}
      </StyledWhiteContainer>
      <FixedButton>
        <Box className="item-flex">
          <Box color="#A5A5A5" mr="12px">
            Tracking Link : {data.links.length}
          </Box>
          <Button
            sx={{ mr: "12px", minWidth: "120px" }}
            variant="outlined"
            disabled={isEqual(data, initData)}
            onClick={() => {
              setData({ ...initData, links: [""] });
            }}
          >
            {formatMessage({ id: "global.btn.discard" })}
          </Button>
          <Button
            sx={{ minWidth: "120px" }}
            onClick={handleCreateCampaign}
            isLoading={isLoading}
            disabled={
              data.name === "" ||
              data.description === "" ||
              !data.links.every((link) => link !== "") ||
              Object.values(errorsInfo).length > 0
            }
          >
            {formatMessage({ id: "global.btn.create" })}
          </Button>
        </Box>
      </FixedButton>
    </Box>
  );
}
