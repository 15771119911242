import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";

export const StyledWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({}) => ({
  position: "fixed",
  width: "30px",
  height: "30px",
  right: "35px",
  bottom: "70px",
  zIndex: 9999,
  cursor: "pointer",
}));

export const StyledAdamMessage = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({}) => ({
  position: "absolute",
  width: "233px",
  padding: "8px 12px",
  textAlign: "end",
  backgroundColor: "#A2E6E2",
  color: "#006C66",
  borderRadius: "20px",
  bottom: "25px",
  right: "35px",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "normal",
}));
