import React from "react";
import _ from "lodash";
import { useIntl } from "react-intl";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import { SurveySubtitle } from "./ScoreBtnOption.styles";
import { EmojiBtn, StarBtn } from "./SurveyOptionItem";

const OPTION_CONFIG = {
  star: {
    value: "star",
  },
  emoji: {
    value: "emoji",
  },
};

export default function ScoreBtnOption({
  selectedSurveyConfig,
  surveyConfig,
  handleChangeSurveyConfig,
}) {
  const value = _.get(surveyConfig, "surveyBtnStyle", OPTION_CONFIG.star.value);
  const prevSurveyBtnStyle = _.get(
    selectedSurveyConfig,
    "surveyBtnStyle",
    OPTION_CONFIG.star.value
  );
  const prevSurveyMsg = _.get(selectedSurveyConfig, "surveyMsg");
  const { formatMessage } = useIntl();

  return (
    <>
      <SurveySubtitle>
        {formatMessage({ id: "ticketCustomization.csatSetting.formStyle" })}
      </SurveySubtitle>
      <CustomRadioGroup
        alignItems="flex-start"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          if (value !== currValue) {
            handleChangeSurveyConfig({
              key: "surveyBtnStyle",
              val: e.target.value,
            });
          }
        }}
        data={[
          {
            label: (
              <StarBtn
                isShowInputField={value === OPTION_CONFIG.star.value}
                prevSurveyBtnStyle={prevSurveyBtnStyle}
                prevSurveyMsg={prevSurveyMsg}
                handleChangeSurveyConfig={handleChangeSurveyConfig}
              />
            ),
            value: OPTION_CONFIG.star.value,
          },
          {
            label: (
              <EmojiBtn
                isShowInputField={value === OPTION_CONFIG.emoji.value}
                prevSurveyBtnStyle={prevSurveyBtnStyle}
                prevSurveyMsg={prevSurveyMsg}
                handleChangeSurveyConfig={handleChangeSurveyConfig}
              />
            ),
            value: OPTION_CONFIG.emoji.value,
          },
        ]}
      />
    </>
  );
}
