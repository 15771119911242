import React from "react";
import AssignModal from "./AssignModal";
import UnassignModal from "./UnassignModal";
import ExportModal from "./ExportModal";
import useGetPagePermission from "hooks/useGetPagePermission";

export default function PerformanceModals({
  selected,
  modalType,
  handleClose,
}) {
  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customerList");

  if (modalType === "assign" && canEdit)
    return <AssignModal selected={selected} handleClose={handleClose} />;
  if (modalType === "unassign" && canEdit)
    return <UnassignModal selected={selected} handleClose={handleClose} />;
  if (modalType === "export")
    return <ExportModal selected={selected} handleClose={handleClose} />;
  return null;
}
