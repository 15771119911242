import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const UploadComponent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, error = false }) => ({
  borderRadius: "3px",
  border: `1px dashed ${
    !error ? theme.customColors.grey[300] : theme.customColors.pink[600]
  }`,
  height: "145px",
  maxWidth: "100%",
  textAlign: "center",
  position: "relative",
  cursor: "pointer",
  background: theme.customColors.grey[100],
}));

export const FileUploadContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  padding: 24,
  background: "#FBFBFB",
  margin: "8px 0 32px",
}));

export const FileValidatorInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 4,
  marginTop: 8,
}));

export const TinyText = styled("p", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, type = "text" }) => ({
  fontSize: theme.fontSize.TINY,
  color:
    type === "text"
      ? theme.customColors.grey[400]
      : type === "error"
      ? theme.customColors.pink[600]
      : type === "info"
      ? "#5C5C5C"
      : null,
  margin: 0,
}));

export const ErrorContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  columnGap: 4,
  alignItems: "center",
  position: "relative",
  top: 8,
}));
