import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { createPublicClient, http } from "viem";
import { getAddress } from "viem";
import { mainnet, polygon, arbitrum, optimism, bsc } from "viem/chains";
import { useAppCommunicator } from "./communicator.js";

const chainIdMapping = {
  1: mainnet,
  137: polygon,
  42161: arbitrum,
  10: optimism,
  56: bsc,
};

const SafeInjectContext = createContext({
  address: undefined,
  appUrl: undefined,
  rpcUrl: undefined,
  iframeRef: null,
  latestTransaction: undefined,
  setAddress: () => {},
  setAppUrl: () => {},
  setRpcUrl: () => {},
  sendMessageToIFrame: () => {},
  setChainId: () => {},
});

export function SafeInjectProvider({ children }) {
  const [address, setAddress] = useState();
  const [appUrl, setAppUrl] = useState();
  const [rpcUrl, setRpcUrl] = useState();
  const [provider, setProvider] = useState();
  const [chainId, setChainId] = useState();
  const [latestTransaction, setLatestTransaction] = useState();

  const iframeRef = useRef(null);
  const communicator = useAppCommunicator(iframeRef);

  const sendMessageToIFrame = useCallback(
    function (message, requestId) {
      const requestWithMessage = {
        ...message,
        requestId: requestId || Math.trunc(window.performance.now()),
        version: "0.4.2",
      };

      if (iframeRef) {
        iframeRef.current?.contentWindow?.postMessage(
          requestWithMessage,
          appUrl
        );
      }
    },
    [iframeRef, appUrl]
  );

  useEffect(() => {
    if (!rpcUrl) return;
    const client = createPublicClient({
      chain: chainId ? chainIdMapping[chainId] : mainnet,
      transport: http(rpcUrl),
    });
    setProvider(client);
  }, [rpcUrl]);

  useEffect(() => {
    if (!provider) return;

    communicator?.on("getSafeInfo", async () => {
      return {
        safeAddress: address,
        chainId: chainId,
        owners: [],
        threshold: 1,
        isReadOnly: false,
      };
    });

    communicator?.on("getEnvironmentInfo", async () => ({
      origin: document.location.origin,
    }));

    communicator?.on("rpcCall", async (msg) => {
      const params = msg.data.params;

      try {
        const response = await provider.send(params.call, params.params);
        return response;
      } catch (err) {
        return err;
      }
    });

    communicator?.on("sendTransactions", (msg) => {
      const transactions = msg.data.params.txs.map(({ to, ...rest }) => ({
        to: getAddress(to), // checksummed
        ...rest,
      }));
      setLatestTransaction({
        id: parseInt(msg.data.id.toString()),
        ...transactions[0],
      });
    });

    communicator?.on("signMessage", async (msg) => {
      const { message } = msg.data.params;
    });

    communicator?.on("signTypedMessage", async (msg) => {
      const { typedData } = msg.data.params;
    });
  }, [communicator, address, provider]);

  return (
    <SafeInjectContext.Provider
      value={{
        address,
        appUrl,
        rpcUrl,
        iframeRef,
        latestTransaction,
        setAddress,
        setAppUrl,
        setRpcUrl,
        sendMessageToIFrame,
        setChainId,
      }}
    >
      {children}
    </SafeInjectContext.Provider>
  );
}

export const useSafeInject = () => useContext(SafeInjectContext);
