import React from "react";
import PropTypes from "prop-types";

import {
  StyledLoadingContainer,
  StyledLoadingImg,
  StyledLoadingText,
} from "./LoadingComponent.styles";
import LottieAnimation from "components/LottieAnimation";

function LoadingComponent({
  width,
  height,
  text,
  isAbsolute,
  withText = true,
  zIndex,
  backgroundColor,
}) {
  return (
    <StyledLoadingContainer
      isAbsolute={isAbsolute}
      backgroundColor={backgroundColor}
      zIndex={zIndex}
    >
      {/* <StyledLoadingImg
        width={width}
        height={height}
        src={require("assets/img/loading.gif")}
        alt="loading"
      /> */}
      <LottieAnimation
        animationLink={require("assets/animations/loading.json")}
        width={width}
        height={height}
      />
      {withText && (
        <StyledLoadingText>{text ? text : "Loading..."}</StyledLoadingText>
      )}
    </StyledLoadingContainer>
  );
}

LoadingComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string,
  zIndex: PropTypes.number,
};

LoadingComponent.defaultProps = {
  width: 60,
  height: 60,
  text: "Loading...",
  zIndex: 100,
};

export default LoadingComponent;
