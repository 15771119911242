import React, { useState, useEffect, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import { get, isEmpty } from "lodash-es";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import numeral from "numeral";
import { useNavigate, useOutletContext } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import _ from "lodash";
import Box from "@mui/material/Box";
import { formatDate } from "features/helpers/utils";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";

import {
  StyledGroupCardContainer,
  StyledGroupCardHeader,
  StyledGroupCardHeaderTitle,
  StyledGroupPeople,
  StyledShowMoreButton,
  StyledGroupCardContent,
  StyledGroupCategory,
  StyledGroupCategoryTitle,
  StyledGroupFilterList,
  StyledFilterItem,
  StyledSwiper,
  StyledSwiperSlide,
  StyledExcludeCategory,
  StyledRowTitle,
  StyledRowDetailInfo,
  StyledGridItem,
  StyledRow,
} from "./GroupCardNew.styles";
import { StyledMenu } from "features/metadesk/settings/Macros/QuestionBlock/QuestionBlock.styles";
import TokensWraper from "features/metadesk/components/Icons/TokensWraper";
import UserTag from "features/metadesk/components/UserTag";
import useGetPagePermission from "hooks/useGetPagePermission";

const GroupCard = ({
  groupInfo,
  isPreview,
  onEdit,
  onRemove,
  isRow = false,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const id = get(groupInfo, "_id");
  const users = get(groupInfo, "users");
  const [entityName, isCustomDomain] = useOutletContext();
  const navigate = useNavigate();
  const removeConfirmRef = useRef();
  const swiperRefAsset = useRef(null);
  const swiperRefUser = useRef(null);
  const isTimeIntervalExist = get(groupInfo, "rules.timeInterval");
  const tokens = get(groupInfo, "rules.assets", []);
  const protocols = get(groupInfo, "rules.protocols", []);
  const tags = get(groupInfo, "rules.tags", []);
  useEffect(() => {
    if (!swiperRefAsset.current || !swiperRefUser.current) {
      return;
    }
    const swiperInstanceUser = swiperRefUser.current.swiper;
    const swiperInstanceAsset = swiperRefAsset.current.swiper;
    swiperInstanceUser.update();
    swiperInstanceAsset.update();
  }, [groupInfo]);

  const handleToggle = (e) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  function formatNumber(num) {
    if (num < 1000) return num;
    return numeral(num).format("0.[00]a").toUpperCase();
  }

  const renderAmount = (targetObj, unit) => {
    let fromValue = get(targetObj, "from")
      ? formatNumber(get(targetObj, "from"))
      : null;
    let toValue = get(targetObj, "to")
      ? formatNumber(get(targetObj, "to"))
      : null;
    if (fromValue && toValue) {
      return (
        <Box ml="3.5px">
          {unit}
          {fromValue}~{unit}
          {toValue}
        </Box>
      );
    } else if (fromValue) {
      return (
        <Box ml="3.5px">
          ≥{unit}
          {fromValue}
        </Box>
      );
    } else if (toValue) {
      return (
        <Box ml="3.5px">
          ≤{unit}
          {toValue}
        </Box>
      );
    } else {
      return <Box ml="3.5px">≥{unit}0</Box>;
    }
  };

  const renderWalletAge = (walletAge) => {
    if (!walletAge) {
      return null;
    }
    return (
      <>
        {formatDate(walletAge.from)}~{formatDate(walletAge.to)}
      </>
    );
  };
  const handleToDetailPage = () => {
    if (isPreview) {
      return;
    }
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/customers/segment/${id}`
      )
    );
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(groupInfo);
    handleClose(e);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onRemove({ groupId: groupInfo._id, entityName });
  };

  const handleOpenConfirmModal = (e) => {
    e.stopPropagation();
    removeConfirmRef.current.setOpen(true);
    handleClose(e);
  };

  const groupByIncluded = (data) => {
    return _.groupBy(data, (o) => (o.included ? "included" : "excluded"));
  };
  const tokensGroup = groupByIncluded(tokens);
  const protocolsGroup = groupByIncluded(protocols);

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("segmentation");

  if (isRow) {
    return (
      <div>
        <StyledRow style={{ padding: "14px 16px" }}>
          <StyledRowTitle style={{ paddingBottom: 0 }}>
            <i className="meta-crm-icon-ic_tag font-size-18 " />
            Tags:
            <UserTag
              containerStyle={{ marginLeft: 5 }}
              initTags={_.map(tags, "_id")}
              entityName={entityName}
              width={"100%"}
              disabled={true}
              showNumber={10}
            />
          </StyledRowTitle>
        </StyledRow>
        <StyledRow>
          <Grid container alignItems="center">
            <StyledGridItem item xs={6} md={2}>
              <StyledRowTitle>
                <i className="meta-crm-icon-ic_wallentBalance font-size-18" />
                Wallet Balance ($)
              </StyledRowTitle>
              <StyledRowDetailInfo>
                {renderAmount(get(groupInfo, "rules.walletBalance"), "$")}
              </StyledRowDetailInfo>
            </StyledGridItem>
            <StyledGridItem item xs={6} md={2}>
              <StyledRowTitle>
                <i className="meta-crm-icon-ic_nft font-size-18" />
                NFT Amount
              </StyledRowTitle>
              <StyledRowDetailInfo>
                {renderAmount(get(groupInfo, "rules.nftAmount"))}
              </StyledRowDetailInfo>
            </StyledGridItem>
            <StyledGridItem item xs={6} md={2}>
              <StyledRowTitle>
                <i className="meta-crm-icon-ic_tokens font-size-18 " />
                Current Holding
              </StyledRowTitle>
              <StyledRowDetailInfo className="startRow">
                <TokensWraper
                  size={"24px"}
                  marginLeft="-8px"
                  showNumber={10}
                  tokens={_.flattenDeep(tokensGroup.included)}
                  prev={"token"}
                />
                {tokensGroup.excluded && tokensGroup.excluded.length > 0 && (
                  <StyledExcludeCategory height={"24px"}>
                    <span>Exclude</span>
                    <TokensWraper
                      prev={"token"}
                      size={"20px"}
                      marginLeft="-8px"
                      tokens={_.flattenDeep(tokensGroup.excluded)}
                    />
                  </StyledExcludeCategory>
                )}
              </StyledRowDetailInfo>
            </StyledGridItem>
            <StyledGridItem item xs={6} md={2}>
              <StyledRowTitle>
                <i className="meta-crm-icon-ic_walletAge font-size-18" />
                Wallet Age
              </StyledRowTitle>
              <StyledRowDetailInfo>
                {isTimeIntervalExist ? (
                  renderWalletAge(get(groupInfo, "rules.timeInterval"))
                ) : (
                  <>N/A</>
                )}
              </StyledRowDetailInfo>
            </StyledGridItem>
            <StyledGridItem item xs={6} md={2}>
              <StyledRowTitle>
                <i className="meta-crm-icon-ic_transaction font-size-18" />
                Transaction
              </StyledRowTitle>
              <StyledRowDetailInfo>
                {renderAmount(get(groupInfo, "rules.txnAmount"))}
              </StyledRowDetailInfo>
            </StyledGridItem>

            <StyledGridItem item xs={6} md={2}>
              <StyledRowTitle>
                <i className="meta-crm-icon-ic_protocol font-size-18 " />
                Protocol
              </StyledRowTitle>
              <StyledRowDetailInfo className="startRow">
                <TokensWraper
                  tokens={protocolsGroup.included}
                  prev={"contract"}
                  size={"24px"}
                  showNumber={10}
                  marginLeft="-8px"
                />
                {protocolsGroup.excluded &&
                  protocolsGroup.excluded.length > 0 && (
                    <StyledExcludeCategory height={"24px"}>
                      <span>Exclude</span>
                      <TokensWraper
                        prev={"contract"}
                        size={"20px"}
                        marginLeft="-8px"
                        tokens={protocolsGroup.excluded}
                      />
                    </StyledExcludeCategory>
                  )}
              </StyledRowDetailInfo>
            </StyledGridItem>
          </Grid>
        </StyledRow>
      </div>
    );
  }

  return (
    <StyledGroupCardContainer
      isPreview={isPreview}
      onClick={handleToDetailPage}
    >
      <StyledGroupCardHeader>
        <StyledGroupCardHeaderTitle>
          {_.truncate(get(groupInfo, "name"), {
            length: 18,
          })}
        </StyledGroupCardHeaderTitle>

        {!isPreview && (
          <Box display="flex" alignItems="center">
            <StyledGroupPeople>
              <i className="meta-crm-icon-ic_people font-size-12" />
              {!isEmpty(users) ? users.length : 0}
            </StyledGroupPeople>
            {canEdit && (
              <>
                <StyledShowMoreButton
                  ref={anchorRef}
                  onClick={(e) => handleToggle(e)}
                >
                  <i className="meta-crm-icon-ic_dots font-size-18" />
                </StyledShowMoreButton>

                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  sx={{ zIndex: 1500 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <StyledMenu>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={(e) => handleEdit(e)}
                              disableRipple
                            >
                              <i className="meta-crm-icon-ic_edit-1 font-size-16" />
                              Rename
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => handleOpenConfirmModal(e)}
                              disableRipple
                            >
                              <i className="meta-crm-icon-ic_trash font-size-16" />
                              Delete
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </StyledMenu>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </Box>
        )}
      </StyledGroupCardHeader>
      <StyledGroupCardContent>
        <StyledGroupCategory>
          <StyledGroupCategoryTitle>Tags</StyledGroupCategoryTitle>
          <StyledGroupFilterList>
            <UserTag
              initTags={_.map(tags, "_id")}
              showNumber={2}
              disabled={true}
              width={"100%"}
              entityName={entityName}
            />
          </StyledGroupFilterList>
        </StyledGroupCategory>
        <StyledGroupCategory>
          <StyledGroupCategoryTitle>Portfolio</StyledGroupCategoryTitle>
          <StyledGroupFilterList>
            <StyledSwiper
              ref={swiperRefAsset}
              slidesPerView="auto"
              freeMode={true}
            >
              <SwiperSlide>
                <StyledFilterItem>
                  <i className="meta-crm-icon-ic_wallentBalance font-size-18" />
                  {renderAmount(get(groupInfo, "rules.walletBalance"), "$")}
                </StyledFilterItem>
              </SwiperSlide>

              <SwiperSlide>
                <StyledFilterItem>
                  <i className="meta-crm-icon-ic_nft font-size-18" />
                  {renderAmount(get(groupInfo, "rules.nftAmount"))}
                </StyledFilterItem>
              </SwiperSlide>
              <SwiperSlide>
                <StyledFilterItem>
                  <i className="meta-crm-icon-ic_tokens font-size-18 mgr3" />
                  <TokensWraper
                    tokens={_.flattenDeep(tokensGroup.included)}
                    prev={"token"}
                  />
                  {tokensGroup.excluded && tokensGroup.excluded.length > 0 && (
                    <StyledExcludeCategory>
                      <span>Exclude</span>
                      <TokensWraper
                        prev={"token"}
                        size={"14px"}
                        tokens={_.flattenDeep(tokensGroup.excluded)}
                      />
                    </StyledExcludeCategory>
                  )}
                </StyledFilterItem>
              </SwiperSlide>
            </StyledSwiper>
          </StyledGroupFilterList>
        </StyledGroupCategory>
        <StyledGroupCategory>
          <StyledGroupCategoryTitle>On-chain activity</StyledGroupCategoryTitle>
          <StyledGroupFilterList>
            <StyledSwiper
              ref={swiperRefUser}
              slidesPerView="auto"
              freeMode={true}
            >
              <SwiperSlide>
                {isTimeIntervalExist && (
                  <StyledFilterItem>
                    <i className="meta-crm-icon-ic_walletAge font-size-18" />
                    {renderWalletAge(get(groupInfo, "rules.timeInterval"))}
                  </StyledFilterItem>
                )}
              </SwiperSlide>
              <SwiperSlide>
                <StyledFilterItem>
                  <i className="meta-crm-icon-ic_transaction font-size-18" />
                  {renderAmount(get(groupInfo, "rules.txnAmount"))}
                </StyledFilterItem>
              </SwiperSlide>
              <SwiperSlide>
                <StyledFilterItem>
                  <i className="meta-crm-icon-ic_protocol font-size-18 mgr3" />
                  <TokensWraper
                    tokens={protocolsGroup.included}
                    prev={"contract"}
                  />
                  {protocolsGroup.excluded &&
                    protocolsGroup.excluded.length > 0 && (
                      <StyledExcludeCategory>
                        <span>Exclude</span>
                        <TokensWraper
                          prev={"contract"}
                          size={"14px"}
                          tokens={protocolsGroup.excluded}
                        />
                      </StyledExcludeCategory>
                    )}
                </StyledFilterItem>
              </SwiperSlide>
            </StyledSwiper>
          </StyledGroupFilterList>
        </StyledGroupCategory>
      </StyledGroupCardContent>
      {onRemove && (
        <RemoveConfirm
          onRemove={() => {
            onRemove({ groupId: groupInfo._id, entityName });
          }}
          title={"Delete your segment"}
          ref={removeConfirmRef}
          controlByParent
        />
      )}
    </StyledGroupCardContainer>
  );
};

export default GroupCard;
