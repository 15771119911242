import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  GROUPS_FETCH_BEGIN,
  GROUPS_FETCH_SUCCESS,
  GROUPS_FETCH_FAILURE,
  GROUP_ADD_SUCCESS,
  GROUP_DELETE_SUCCESS,
  GROUP_EDIT_SUCCESS,
  GROUPS_USER_FETCH_BEGIN,
  GROUPS_USER_FETCH_SUCCESS,
  GROUPS_USER_FETCH_FAILURE,
  GET_GROUPS_USERS_BEGIN,
  GET_GROUPS_USERS_SUCCESS,
  GET_GROUPS_USERS_FAILURE,
  GROUPS_UPDATE_BEGIN,
  UPDATE_GROUP_USER_LIST_FILTER,
} from "./constants";
import axios from "axios";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import useGetPagePermission from "hooks/useGetPagePermission";

const USER_FILTER_INITIAL_STATE = {
  page: 1,
  queryPage: 1,
  orderData: { sort: "_id", order: -1 },
  search: "",
  searchTags: [],
};

export const GROUP_USER_FILTER_INITIAL_STATE = {
  groupUserFilter: {},
};

export function fetchGroups({ entityName, show }) {
  return (dispatch, getState) => {
    dispatch({
      type: GROUPS_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let groups = [];
        let groupsCount = 0;
        if (show) {
          const response = await axios.get(
            `${apiUrl}/api/manage/groups?entityName=` + entityName
          );
          groups = response.data;
          groupsCount = response.headers["x-total-count"];
        }

        dispatch({
          type: GROUPS_FETCH_SUCCESS,
          data: groups,
          entityName,
          groupsCount,
        });
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GROUPS_FETCH_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function getGroupsUsers({ entityName, groupId, filter }) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_GROUPS_USERS_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        if (!filter) {
          dispatch({
            type: UPDATE_GROUP_USER_LIST_FILTER,
            data: _.cloneDeep(USER_FILTER_INITIAL_STATE),
            groupId,
          });
        } else {
          let data = {
            searchSource: _.get(filter, "searchSource"),

            entityName: entityName,
            page: _.get(filter, "page", 1),
            search: _.get(filter, "search"),
            sort: _.get(filter, "orderData.sort"),
            order: _.get(filter, "orderData.order"),
          };
          let users = await axios.get(
            `${apiUrl}/api/manage/groups/${groupId}`,
            {
              params: data,
            }
          );

          dispatch({
            type: GET_GROUPS_USERS_SUCCESS,
            data: users.data,
            entityName,
            groupUsersCount: users.headers["x-total-count"],
          });
          resolve();
        }
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GET_GROUPS_USERS_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function fetchGroupQueryUserList({
  entityName,
  rules,
  groupId,
  filter,
}) {
  return (dispatch, getState) => {
    if (!rules || !filter) return;
    dispatch({
      type: GROUPS_USER_FETCH_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        let data = {
          entityName: entityName,
          page: _.get(filter, "queryPage", 1),
          search: _.get(filter, "search"),
          sort: _.get(filter, "orderData.sort"),
          order: _.get(filter, "orderData.order"),
          groupId,
          rules,
        };
        let users = await axios.post(`${apiUrl}/api/manage/groups/query`, data);
        dispatch({
          type: GROUPS_USER_FETCH_SUCCESS,
          data: users.data,
          entityName,
          queryUsersCount: users.headers["x-total-count"],
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        dispatch({
          type: GROUPS_USER_FETCH_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function addGroup({ entityName, users, name, rules, color }) {
  return (dispatch, getState) => {
    dispatch({
      type: GROUPS_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const newGroup = {
          name,
          addUsers: users,
          rules,
          color,
        };
        let groups = await axios.post(`${apiUrl}/api/manage/groups/update`, {
          ...newGroup,
          entityName,
        });
        dispatch(fetchGroups({ entityName }));
        dispatch({
          type: GROUP_ADD_SUCCESS,
          data: groups.data,
          entityName,
        });
        resolve(groups.data);
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}

export function removeGroup({ entityName, groupId }) {
  return (dispatch, getState) => {
    dispatch({
      type: GROUPS_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        let groups = await axios.post(`${apiUrl}/api/manage/groups/delete`, {
          groupId,
          entityName,
        });
        dispatch({
          type: GROUP_DELETE_SUCCESS,
          data: groupId,
          entityName,
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}

export function editGroup({
  entityName,
  name,
  rules,
  groupId,
  addUsers,
  removeUsers,
  color,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: GROUPS_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const newGroup = {
          name,
          groupId,
          rules,
          color,
          addUsers,
          removeUsers,
        };
        let groups = await axios.post(`${apiUrl}/api/manage/groups/update`, {
          ...newGroup,
          entityName,
        });

        dispatch({
          type: GROUP_EDIT_SUCCESS,
          data: newGroup,
          entityName,
        });
        dispatch(updateGroupUserFilter({ page: 1, queryPage: 1 }));
        resolve();
      } catch (err) {
        console.log(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        reject();
      }
    });

    return promise;
  };
}

export function updateGroupUserFilter(groupId, data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_GROUP_USER_LIST_FILTER,
      data,
      groupId,
    });
  };
}

export function useFetchGroups() {
  const dispatch = useDispatch();

  const pagePermission = useGetPagePermission();
  const { show } = pagePermission("segmentation");

  const {
    groups,
    fetchGroupsPending,
    fetchGroupsDone,
    fetchGroupsUserPending,
    queryUsers,
    queryUsersCount,
    groupsCount,
    groupUsersCount,
    updateGroupPending,
    getGroupsUsersPending,
    groupUsers,
    selectedGroup,
    groupUserFilter,
  } = useSelector(
    (state) => ({
      groups: state.metadesk.groups,
      fetchGroupsPending: state.metadesk.fetchGroupsPending,
      fetchGroupsDone: state.metadesk.fetchGroupsDone,
      queryUsers: state.metadesk.queryUsers,
      fetchGroupsUserPending: state.metadesk.fetchGroupsUserPending,
      queryUsersCount: state.metadesk.queryUsersCount,
      groupsCount: state.metadesk.groupsCount,
      groupUsersCount: state.metadesk.groupUsersCount,
      updateGroupPending: state.metadesk.updateGroupPending,
      getGroupsUsersPending: state.metadesk.getGroupsUsersPending,
      groupUsers: state.metadesk.groupUsers,
      selectedGroup: state.metadesk.selectedGroup,
      groupUserFilter: state.metadesk.groupUserFilter,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchGroups({ ...data, show }));
    },
    [dispatch]
  );

  const addAction = useCallback(
    (data) => {
      return dispatch(addGroup(data));
    },
    [dispatch]
  );
  const removeAction = useCallback(
    (data) => {
      return dispatch(removeGroup(data));
    },
    [dispatch]
  );
  const editAction = useCallback(
    (data) => {
      return dispatch(editGroup(data));
    },
    [dispatch]
  );
  const fetchGroupQueryUserListAction = useCallback(
    (data) => {
      return dispatch(fetchGroupQueryUserList(data));
    },
    [dispatch]
  );

  const getGroupsUsersAction = useCallback(
    (data) => {
      return dispatch(getGroupsUsers(data));
    },
    [dispatch]
  );

  const updateGroupsUserFilterAction = useCallback(
    (groupId, data) => {
      return dispatch(updateGroupUserFilter(groupId, data));
    },
    [dispatch]
  );

  return {
    groups,
    getGroupsUsersPending,
    groupUsers,
    fetchGroups: boundAction,
    addGroup: addAction,
    removeGroup: removeAction,
    editGroup: editAction,
    fetchGroupQueryUserList: fetchGroupQueryUserListAction,
    getGroupUsers: getGroupsUsersAction,
    updateGroupUserFilter: updateGroupsUserFilterAction,
    fetchGroupsDone,
    fetchGroupsPending,
    queryUsers,
    fetchGroupsUserPending,
    queryUsersCount,
    groupsCount,
    groupUsersCount,
    updateGroupPending,
    selectedGroup,
    groupUserFilter,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GROUPS_FETCH_BEGIN:
      return {
        ...state,
        fetchGroupsPending: true,
      };
    case GROUPS_UPDATE_BEGIN:
      return {
        ...state,
        updateGroupPending: true,
      };

    case GROUPS_FETCH_SUCCESS:
      const newState = {
        ...state,
        groupsCount: action.groupsCount,
        groups: action.data,
        fetchGroupsDone: true,
        fetchGroupsPending: false,
      };
      return newState;

    case GROUP_ADD_SUCCESS:
      const addState = {
        ...state,
        groups: [...state.groups, action.data],
        updateGroupPending: false,
      };
      return addState;
    case GROUP_DELETE_SUCCESS:
      const removeIndex = _.findIndex(state.groups, {
        _id: action.data,
      });
      const removeList = [...state.groups];
      _.pullAt(removeList, [removeIndex]);

      return {
        ...state,
        groups: removeList,
        updateGroupPending: false,
      };
    case GROUP_EDIT_SUCCESS:
      const updatedIndex = _.findIndex(state.groups, {
        _id: action.data._id,
      });
      const newList = [...state.groups];
      newList[updatedIndex] = action.data;
      return {
        ...state,
        groups: newList,
        selectedGroup: action.data,
        updateGroupPending: false,
      };
    case GET_GROUPS_USERS_BEGIN:
      return {
        ...state,
        getGroupsUsersPending: true,
      };

    case GROUPS_USER_FETCH_BEGIN:
      return {
        ...state,
        fetchGroupsUserPending: true,
      };

    case GET_GROUPS_USERS_SUCCESS:
      return {
        ...state,
        getGroupsUsersPending: false,
        groupUsers: action.data.users,
        selectedGroup: action.data.group,
        groupUsersCount: action.groupUsersCount,
      };

    case GROUPS_USER_FETCH_SUCCESS:
      const newUserData = {
        ...state,
        queryUsers: action.data,
        queryUsersCount: action.queryUsersCount,
        fetchGroupsUserPending: false,
      };
      return newUserData;
    case GET_GROUPS_USERS_FAILURE:
      return {
        ...state,
        getGroupsUsersPending: false,
      };
    case GROUPS_USER_FETCH_FAILURE:
      return {
        ...state,
        fetchGroupsUserPending: false,
      };
    case GROUPS_FETCH_FAILURE:
      return {
        ...state,
        fetchGroupsPending: false,
      };
    case UPDATE_GROUP_USER_LIST_FILTER:
      return {
        ...state,
        groupUserFilter: {
          ...state.groupUserFilter,
          [action.groupId]: {
            ...state.groupUserFilter[action.groupId],
            ...action.data,
          },
        },
      };
    default:
      return state;
  }
}
