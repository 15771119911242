import PopperMenu from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useNavigateEntity from "hooks/useNavigateEntity";
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";

export default function RolesAction({ id, fetchRoles, roleAmount }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { formatMessage } = useIntl();

  const handleClose = () => {
    setOpen(false);
  };
  const { navigateEntity, entityName } = useNavigateEntity();

  const dispatchSnackbar = useDispatchSnackbar();
  const confirm = useConfirm();
  async function handleDelete() {
    confirm({
      title:
        roleAmount > 0
          ? formatMessage({
              id: "popup.deleteRoleFailed.title",
            })
          : formatMessage({
              id: "popup.delete.title",
            }),
      content:
        roleAmount > 0
          ? formatMessage({ id: "popup.deleteRoleFailed.description" })
          : formatMessage({
              id: "popup.delete.description",
            }),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText:
        roleAmount > 0
          ? ""
          : formatMessage({
              id: "Cancel",
            }),
      color: roleAmount > 0 ? "secondary" : "error",
    })
      .then(async () => {
        if (roleAmount > 0) return;
        try {
          await axios.delete(`${apiUrl}/api/role/${entityName}/${id}`);
          // refetch roles
          await fetchRoles();
          dispatchSnackbar({
            message: "Role deleted successfully",
            variant: "success",
          });
        } catch (err) {
          dispatchSnackbar({ err });
        }
      })
      .catch((err) => {});
  }

  const itemList = [
    {
      children: (
        <Box color={"#555555"} display="flex" alignItems={"center"}>
          <i className="meta-crm-icon-ic_edit-1 font-size-20" />
          {formatMessage({ id: "global.btn.edit" })}
        </Box>
      ),
      func: (e) => {
        e.stopPropagation();
        navigateEntity(`/settings/general/organization/create/${id}`);
      },
    },
    {
      children: (
        <Box color={"#555555"} display="flex" alignItems={"center"}>
          <i className="meta-crm-icon-ic_trash font-size-20" />
          {formatMessage({ id: "global.btn.delete" })}
        </Box>
      ),
      func: (e) => {
        e.stopPropagation();
        handleDelete();
      },
    },
  ];

  return (
    <div
      className="flex-container"
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
    >
      <i
        ref={anchorRef}
        className="meta-crm-icon-ic_dots font-size-20"
        style={{ cursor: "pointer" }}
        color="#ffffff"
      />

      <PopperMenu
        itemList={itemList}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      />
    </div>
  );
}
