export const curvePoolTokensMapping = {
  "0XD51A44D3FAE010294C616388B506ACDA1BFAAE46": {
    "name": "Curve.fi USD-BTC-ETH",
    "address": "0XD51A44D3FAE010294C616388B506ACDA1BFAAE46",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "2": {
        "index": 2,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X9838ECCC42659FA8AA7DAF2AD134B53984C9427B": {
    "name": "Curve EURT-3Crv",
    "address": "0X9838ECCC42659FA8AA7DAF2AD134B53984C9427B",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EURT",
        "decimals": 6,
        "address": "0XC581B735A1688071A1746C968E0798D642EDE491"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EURT",
        "decimals": 6,
        "address": "0XC581B735A1688071A1746C968E0798D642EDE491"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X98A7F18D4E56CFE84E3D081B40001B3D5BD3EB8B": {
    "name": "Curve EURS-USDC",
    "address": "0X98A7F18D4E56CFE84E3D081B40001B3D5BD3EB8B",
    "assetType": "crypto",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      }
    },
    "underlyingCoins": null
  },
  "0X8301AE4FC9C624D1D396CBDAA1ED877821D7C511": {
    "name": "Curve CRV-ETH",
    "address": "0X8301AE4FC9C624D1D396CBDAA1ED877821D7C511",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      }
    },
    "underlyingCoins": null
  },
  "0XB576491F1E6E5E62F1D8F26062EE822B40B0E0D4": {
    "name": "Curve CVX-ETH",
    "address": "0XB576491F1E6E5E62F1D8F26062EE822B40B0E0D4",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      }
    },
    "underlyingCoins": null
  },
  "0XADCFCF9894335DC340F6CD182AFA45999F45FC44": {
    "name": "Curve XAUT-3Crv",
    "address": "0XADCFCF9894335DC340F6CD182AFA45999F45FC44",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "XAUt",
        "decimals": 6,
        "address": "0X68749665FF8D2D112FA859AA293F07A622782F38"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "XAUt",
        "decimals": 6,
        "address": "0X68749665FF8D2D112FA859AA293F07A622782F38"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X98638FACF9A3865CD033F36548713183F6996122": {
    "name": "Curve SPELL-ETH",
    "address": "0X98638FACF9A3865CD033F36548713183F6996122",
    "assetType": "crypto",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "SPELL",
        "decimals": 18,
        "address": "0X090185F2135308BAD17527004364EBCC2D37E5F6"
      }
    },
    "underlyingCoins": null
  },
  "0X752EBEB79963CF0732E9C0FEC72A49FD1DEFAEAC": {
    "name": "Curve T-ETH",
    "address": "0X752EBEB79963CF0732E9C0FEC72A49FD1DEFAEAC",
    "assetType": "crypto",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "T",
        "decimals": 18,
        "address": "0XCDF7028CEAB81FA0C6971208E83FA7872994BEE5"
      }
    },
    "underlyingCoins": null
  },
  "0XE84F5B1582BA325FDF9CE6B0C1F087CCFC924E54": {
    "name": "Curve EUROC-3Crv",
    "address": "0XE84F5B1582BA325FDF9CE6B0C1F087CCFC924E54",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBEBC44782C7DB0A1A60CB6FE97D0B483032FF1C7": {
    "name": "Curve.fi DAI/USDC/USDT",
    "address": "0XBEBC44782C7DB0A1A60CB6FE97D0B483032FF1C7",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "2": {
        "index": 2,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    },
    "underlyingCoins": null
  },
  "0XDEBF20617708857EBE4F679508E7B7863A8A8EEE": {
    "name": "Curve.fi aDAI/aUSDC/aUSDT",
    "address": "0XDEBF20617708857EBE4F679508E7B7863A8A8EEE",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "aDAI",
        "decimals": 18,
        "address": "0X028171BCA77440897B824CA71D1C56CAC55B68A3"
      },
      "1": {
        "index": 1,
        "name": "aUSDC",
        "decimals": 6,
        "address": "0XBCCA60BB61934080951369A648FB03DF4F96263C"
      },
      "2": {
        "index": 2,
        "name": "aUSDT",
        "decimals": 6,
        "address": "0X3ED3B47DD13EC9A98B44E6204A523E766B225811"
      }
    },
    "underlyingCoins": null
  },
  "0XA96A65C051BF88B4095EE1F2451C2A9D43F53AE2": {
    "name": "Curve.fi ETH/aETH",
    "address": "0XA96A65C051BF88B4095EE1F2451C2A9D43F53AE2",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "ankrETH",
        "decimals": 18,
        "address": "0XE95A203B1A91A908F9B9CE46459D101078C2C3CB"
      }
    },
    "underlyingCoins": null
  },
  "0X79A8C46DEA5ADA233ABAFFD40F3A0A2B1E5A4F27": {
    "name": "Curve.fi yDAI/yUSDC/yUSDT/yBUSD",
    "address": "0X79A8C46DEA5ADA233ABAFFD40F3A0A2B1E5A4F27",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "yDAI",
        "decimals": 18,
        "address": "0XC2CB1040220768554CF699B0D863A3CD4324CE32"
      },
      "1": {
        "index": 1,
        "name": "yUSDC",
        "decimals": 6,
        "address": "0X26EA744E5B887E5205727F55DFBE8685E3B21951"
      },
      "2": {
        "index": 2,
        "name": "yUSDT",
        "decimals": 6,
        "address": "0XE6354ED5BC4B393A5AAD09F21C46E101E692D447"
      },
      "3": {
        "index": 3,
        "name": "yBUSD",
        "decimals": 18,
        "address": "0X04BC0AB673D88AE9DBC9DA2380CB6B79C4BCA9AE"
      }
    },
    "underlyingCoins": null
  },
  "0XA2B47E3D5C44877CCA798226B7B8118F9BFB7A56": {
    "name": "Curve.fi cDAI/cUSDC",
    "address": "0XA2B47E3D5C44877CCA798226B7B8118F9BFB7A56",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cDAI",
        "decimals": 8,
        "address": "0X5D3A536E4D6DBD6114CC1EAD35777BAB948E3643"
      },
      "1": {
        "index": 1,
        "name": "cUSDC",
        "decimals": 8,
        "address": "0X39AA39C021DFBAE8FAC545936693AC917D5E7563"
      }
    },
    "underlyingCoins": null
  },
  "0X0CE6A5FF5217E38315F87032CF90686C96627CAA": {
    "name": "Curve.fi EURS/sEUR",
    "address": "0X0CE6A5FF5217E38315F87032CF90686C96627CAA",
    "assetType": "3",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      },
      "1": {
        "index": 1,
        "name": "sEUR",
        "decimals": 18,
        "address": "0XD71ECFF9342A5CED620049E616C5035F1DB98620"
      }
    },
    "underlyingCoins": null
  },
  "0X4CA9B3063EC5866A4B82E437059D2C43D1BE596F": {
    "name": "Curve.fi hBTC/wBTC",
    "address": "0X4CA9B3063EC5866A4B82E437059D2C43D1BE596F",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "HBTC",
        "decimals": 18,
        "address": "0X0316EB71485B0AB14103307BF65A021042C6D380"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      }
    },
    "underlyingCoins": null
  },
  "0X2DDED6DA1BF5DBDF597C45FCFAA3194E53ECFEAF": {
    "name": "Curve.fi cyDAI/cyUSDC/cyUSDT",
    "address": "0X2DDED6DA1BF5DBDF597C45FCFAA3194E53ECFEAF",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "iDAI",
        "decimals": 8,
        "address": "0X8E595470ED749B85C6F7669DE83EAE304C2EC68F"
      },
      "1": {
        "index": 1,
        "name": "iUSDC",
        "decimals": 8,
        "address": "0X76EB2FE28B36B3EE97F3ADAE0C69606EEDB2A37C"
      },
      "2": {
        "index": 2,
        "name": "iUSDT",
        "decimals": 8,
        "address": "0X48759F220ED983DB51FA7A8C0D2AAB8F3CE4166A"
      }
    },
    "underlyingCoins": null
  },
  "0XF178C0B5BB7E7ABF4E12A4838C7B7C5BA2C623C0": {
    "name": "Curve.fi LINK/sLINK",
    "address": "0XF178C0B5BB7E7ABF4E12A4838C7B7C5BA2C623C0",
    "assetType": "3",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      },
      "1": {
        "index": 1,
        "name": "sLINK",
        "decimals": 18,
        "address": "0XBBC455CB4F1B9E4BFC4B73970D360C8F032EFEE6"
      }
    },
    "underlyingCoins": null
  },
  "0X06364F10B501E868329AFBC005B3492902D6C763": {
    "name": "Curve.fi DAI/USDC/USDT/PAX",
    "address": "0X06364F10B501E868329AFBC005B3492902D6C763",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ycDAI",
        "decimals": 18,
        "address": "0X99D1FA417F94DCD62BFE781A1213C092A47041BC"
      },
      "1": {
        "index": 1,
        "name": "ycUSDC",
        "decimals": 6,
        "address": "0X9777D7E2B60BB01759D0E2F8BE2095DF444CB07E"
      },
      "2": {
        "index": 2,
        "name": "ycUSDT",
        "decimals": 6,
        "address": "0X1BE5D71F2DA660BFDEE8012DDC58D024448A0A59"
      },
      "3": {
        "index": 3,
        "name": "USDP",
        "decimals": 18,
        "address": "0X8E870D67F660D95D5BE530380D0EC0BD388289E1"
      }
    },
    "underlyingCoins": null
  },
  "0X93054188D876F558F4A66B2EF1D97D16EDF0895B": {
    "name": "Curve.fi renBTC/wBTC",
    "address": "0X93054188D876F558F4A66B2EF1D97D16EDF0895B",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      }
    },
    "underlyingCoins": null
  },
  "0XEB16AE0052ED37F479F7FE63849198DF1765A733": {
    "name": "Curve.fi aDAI/aSUSD",
    "address": "0XEB16AE0052ED37F479F7FE63849198DF1765A733",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "aDAI",
        "decimals": 18,
        "address": "0X028171BCA77440897B824CA71D1C56CAC55B68A3"
      },
      "1": {
        "index": 1,
        "name": "aSUSD",
        "decimals": 18,
        "address": "0X6C5024CD4F8A59110119C56F8933403A539555EB"
      }
    },
    "underlyingCoins": null
  },
  "0X7FC77B5C7614E1533320EA6DDC2EB61FA00A9714": {
    "name": "Curve.fi renBTC/wBTC/sBTC",
    "address": "0X7FC77B5C7614E1533320EA6DDC2EB61FA00A9714",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "2": {
        "index": 2,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    },
    "underlyingCoins": null
  },
  "0XC5424B857F758E906013F3555DAD202E4BDB4567": {
    "name": "Curve.fi ETH/sETH",
    "address": "0XC5424B857F758E906013F3555DAD202E4BDB4567",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "sETH",
        "decimals": 18,
        "address": "0X5E74C9036FB86BD7ECDCB084A0673EFC32EA31CB"
      }
    },
    "underlyingCoins": null
  },
  "0XDC24316B9AE028F1497C275EB9192A3EA0F67022": {
    "name": "Curve.fi ETH/stETH",
    "address": "0XDC24316B9AE028F1497C275EB9192A3EA0F67022",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      }
    },
    "underlyingCoins": null
  },
  "0XA5407EAE9BA41422680E2E00537571BCC53EFBFD": {
    "name": "Curve.fi DAI/USDC/USDT/sUSD",
    "address": "0XA5407EAE9BA41422680E2E00537571BCC53EFBFD",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "2": {
        "index": 2,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      },
      "3": {
        "index": 3,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      }
    },
    "underlyingCoins": null
  },
  "0X52EA46506B9CC5EF470C5BF89F17DC28BB35D85C": {
    "name": "Curve.fi cDAI/cUSDC/USDT",
    "address": "0X52EA46506B9CC5EF470C5BF89F17DC28BB35D85C",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cDAI",
        "decimals": 8,
        "address": "0X5D3A536E4D6DBD6114CC1EAD35777BAB948E3643"
      },
      "1": {
        "index": 1,
        "name": "cUSDC",
        "decimals": 8,
        "address": "0X39AA39C021DFBAE8FAC545936693AC917D5E7563"
      },
      "2": {
        "index": 2,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    },
    "underlyingCoins": null
  },
  "0X45F783CCE6B7FF23B2AB2D70E416CDB7D6055F51": {
    "name": "Curve.fi yDAI/yUSDC/yUSDT/yTUSD",
    "address": "0X45F783CCE6B7FF23B2AB2D70E416CDB7D6055F51",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "yDAI",
        "decimals": 18,
        "address": "0X16DE59092DAE5CCF4A1E6439D611FD0653F0BD01"
      },
      "1": {
        "index": 1,
        "name": "yUSDC",
        "decimals": 6,
        "address": "0XD6AD7A6750A7593E092A9B218D66C0A814A3436E"
      },
      "2": {
        "index": 2,
        "name": "yUSDT",
        "decimals": 6,
        "address": "0X83F798E925BCD4017EB265844FDDABB448F1707D"
      },
      "3": {
        "index": 3,
        "name": "yTUSD",
        "decimals": 18,
        "address": "0X73A052500105205D34DAF004EAB301916DA8190F"
      }
    },
    "underlyingCoins": null
  },
  "0X8038C01A0390A8C547446A0B2C18FC9AEFECC10C": {
    "name": "Curve.fi DUSD/3Crv",
    "address": "0X8038C01A0390A8C547446A0B2C18FC9AEFECC10C",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DUSD",
        "decimals": 18,
        "address": "0X5BC25F649FC4E26069DDF4CF4010F9F706C23831"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DUSD",
        "decimals": 18,
        "address": "0X5BC25F649FC4E26069DDF4CF4010F9F706C23831"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X4F062658EAAF2C1CCF8C8E36D6824CDF41167956": {
    "name": "Curve.fi GUSD/3Crv",
    "address": "0X4F062658EAAF2C1CCF8C8E36D6824CDF41167956",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GUSD",
        "decimals": 2,
        "address": "0X056FD409E1D7A124BD7017459DFEA2F387B6D5CD"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GUSD",
        "decimals": 2,
        "address": "0X056FD409E1D7A124BD7017459DFEA2F387B6D5CD"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3EF6A01A0F81D6046290F3E2A8C5B843E738E604": {
    "name": "Curve.fi HUSD/3Crv",
    "address": "0X3EF6A01A0F81D6046290F3E2A8C5B843E738E604",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "HUSD",
        "decimals": 8,
        "address": "0XDF574C24545E5FFECB9A659C229253D4111D87E1"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "HUSD",
        "decimals": 8,
        "address": "0XDF574C24545E5FFECB9A659C229253D4111D87E1"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XE7A24EF0C5E95FFB0F6684B813A78F2A3AD7D171": {
    "name": "Curve.fi LinkUSD/3Crv",
    "address": "0XE7A24EF0C5E95FFB0F6684B813A78F2A3AD7D171",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LINKUSD",
        "decimals": 18,
        "address": "0X0E2EC54FC0B509F445631BF4B91AB8168230C752"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "LINKUSD",
        "decimals": 18,
        "address": "0X0E2EC54FC0B509F445631BF4B91AB8168230C752"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8474DDBE98F5AA3179B3B3F5942D724AFCDEC9F6": {
    "name": "Curve.fi MUSD/3Crv",
    "address": "0X8474DDBE98F5AA3179B3B3F5942D724AFCDEC9F6",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "mUSD",
        "decimals": 18,
        "address": "0XE2F2A5C287993345A840DB3B0845FBC70F5935A5"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "mUSD",
        "decimals": 18,
        "address": "0XE2F2A5C287993345A840DB3B0845FBC70F5935A5"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC18CC39DA8B11DA8C3541C598EE022258F9744DA": {
    "name": "Curve.fi RSV/3Crv",
    "address": "0XC18CC39DA8B11DA8C3541C598EE022258F9744DA",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RSV",
        "decimals": 18,
        "address": "0X196F4727526EA7FB1E17B2071B3D8EAA38486988"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RSV",
        "decimals": 18,
        "address": "0X196F4727526EA7FB1E17B2071B3D8EAA38486988"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3E01DD8A5E1FB3481F0F589056B428FC308AF0FB": {
    "name": "Curve.fi USDK/3Crv",
    "address": "0X3E01DD8A5E1FB3481F0F589056B428FC308AF0FB",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDK",
        "decimals": 18,
        "address": "0X1C48F86AE57291F7686349F12601910BD8D470BB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDK",
        "decimals": 18,
        "address": "0X1C48F86AE57291F7686349F12601910BD8D470BB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X0F9CB53EBE405D49A0BBDBD291A65FF571BC83E1": {
    "name": "Curve.fi USDN/3Crv",
    "address": "0X0F9CB53EBE405D49A0BBDBD291A65FF571BC83E1",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDN",
        "decimals": 18,
        "address": "0X674C6AD92FD080E4004B2312B45F796A192D27A0"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDN",
        "decimals": 18,
        "address": "0X674C6AD92FD080E4004B2312B45F796A192D27A0"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X42D7025938BEC20B69CBAE5A77421082407F053A": {
    "name": "Curve.fi USDP/3Crv",
    "address": "0X42D7025938BEC20B69CBAE5A77421082407F053A",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDP",
        "decimals": 18,
        "address": "0X1456688345527BE1F37E9E627DA0837D6F08C925"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDP",
        "decimals": 18,
        "address": "0X1456688345527BE1F37E9E627DA0837D6F08C925"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X890F4E345B1DAED0367A877A1612F86A1F86985F": {
    "name": "Curve.fi UST/3Crv",
    "address": "0X890F4E345B1DAED0367A877A1612F86A1F86985F",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 18,
        "address": "0XA47C8BF37F92ABED4A126BDA807A7B7498661ACD"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 18,
        "address": "0XA47C8BF37F92ABED4A126BDA807A7B7498661ACD"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X071C661B4DEEFB59E2A3DDB20DB036821EEE8F4B": {
    "name": "Curve.fi bBTC/sbtcCRV",
    "address": "0X071C661B4DEEFB59E2A3DDB20DB036821EEE8F4B",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BBTC",
        "decimals": 8,
        "address": "0X9BE89D2A4CD102D8FECC6BF9DA793BE995C22541"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BBTC",
        "decimals": 8,
        "address": "0X9BE89D2A4CD102D8FECC6BF9DA793BE995C22541"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0XD81DA8D904B52208541BADE1BD6595D8A251F8DD": {
    "name": "Curve.fi oBTC/sbtcCRV",
    "address": "0XD81DA8D904B52208541BADE1BD6595D8A251F8DD",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "oBTC",
        "decimals": 18,
        "address": "0X8064D9AE6CDF087B1BCD5BDF3531BD5D8C537A68"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "oBTC",
        "decimals": 18,
        "address": "0X8064D9AE6CDF087B1BCD5BDF3531BD5D8C537A68"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X7F55DDE206DBAD629C080068923B36FE9D6BDBEF": {
    "name": "Curve.fi pBTC/sbtcCRV",
    "address": "0X7F55DDE206DBAD629C080068923B36FE9D6BDBEF",
    "assetType": "5",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pBTC",
        "decimals": 18,
        "address": "0X5228A22E72CCC52D415ECFD199F99D0665E7733B"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "pBTC",
        "decimals": 18,
        "address": "0X5228A22E72CCC52D415ECFD199F99D0665E7733B"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0XC25099792E9349C7DD09759744EA681C7DE2CB66": {
    "name": "Curve.fi tBTC/sbtcCrv",
    "address": "0XC25099792E9349C7DD09759744EA681C7DE2CB66",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TBTC",
        "decimals": 18,
        "address": "0X8DAEBADE922DF735C38C80C7EBD708AF50815FAA"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TBTC",
        "decimals": 18,
        "address": "0X8DAEBADE922DF735C38C80C7EBD708AF50815FAA"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0XECD5E75AFB02EFA118AF914515D6521AABD189F1": {
    "name": "Curve.fi Factory USD Metapool: TrueUSD",
    "address": "0XECD5E75AFB02EFA118AF914515D6521AABD189F1",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TUSD",
        "decimals": 18,
        "address": "0X0000000000085D4780B73119B644AE5ECD22B376"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TUSD",
        "decimals": 18,
        "address": "0X0000000000085D4780B73119B644AE5ECD22B376"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA": {
    "name": "Curve.fi Factory USD Metapool: Liquity",
    "address": "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XD632F22692FAC7611D2AA1C0D552930D43CAED3B": {
    "name": "Curve.fi Factory USD Metapool: Frax",
    "address": "0XD632F22692FAC7611D2AA1C0D552930D43CAED3B",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X4807862AA8B2BF68830E4C8DC86D0E9A998E085A": {
    "name": "Curve.fi Factory USD Metapool: Binance USD",
    "address": "0X4807862AA8B2BF68830E4C8DC86D0E9A998E085A",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BUSD",
        "decimals": 18,
        "address": "0X4FABB145D64652A948D72533023F6E7A623C7C53"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BUSD",
        "decimals": 18,
        "address": "0X4FABB145D64652A948D72533023F6E7A623C7C53"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XF9440930043EB3997FC70E1339DBB11F341DE7A8": {
    "name": "Curve.fi ETH/rETH",
    "address": "0XF9440930043EB3997FC70E1339DBB11F341DE7A8",
    "assetType": "2",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "rETH",
        "decimals": 18,
        "address": "0X9559AAA82D9649C7A7B220E7C461D2E74C9A3593"
      }
    },
    "underlyingCoins": null
  },
  "0X43B4FDFD4FF969587185CDB6F0BD875C5FC83F8C": {
    "name": "Curve.fi Factory USD Metapool: Alchemix USD",
    "address": "0X43B4FDFD4FF969587185CDB6F0BD875C5FC83F8C",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X80466C64868E1AB14A1DDF27A676C3FCBE638FE5": {
    "name": "Curve.fi USD-BTC-ETH",
    "address": "0X80466C64868E1AB14A1DDF27A676C3FCBE638FE5",
    "assetType": "4",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "2": {
        "index": 2,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X618788357D0EBD8A37E763ADAB3BC575D54C2C7D": {
    "name": "RAI3CRV",
    "address": "0X618788357D0EBD8A37E763ADAB3BC575D54C2C7D",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X5A6A4D54456819380173272A5E8E9B9904BDF41B": {
    "name": "Curve.fi Factory USD Metapool: Magic Internet Money 3Pool",
    "address": "0X5A6A4D54456819380173272A5E8E9B9904BDF41B",
    "assetType": "7",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MIM",
        "decimals": 18,
        "address": "0X99D8A9C45B2ECA8864373A26D1459E3DFF1E17F3"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MIM",
        "decimals": 18,
        "address": "0X99D8A9C45B2ECA8864373A26D1459E3DFF1E17F3"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFD5DB7463A3AB53FD211B4AF195C5BCCC1A03890": {
    "name": "Curve.fi Factory Plain Pool: Euro Tether",
    "address": "0XFD5DB7463A3AB53FD211B4AF195C5BCCC1A03890",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EURT",
        "decimals": 6,
        "address": "0XC581B735A1688071A1746C968E0798D642EDE491"
      },
      "1": {
        "index": 1,
        "name": "sEUR",
        "decimals": 18,
        "address": "0XD71ECFF9342A5CED620049E616C5035F1DB98620"
      }
    },
    "underlyingCoins": null
  },
  "0XDCEF968D416A41CDAC0ED8702FAC8128A64241A2": {
    "name": "Curve.fi FRAX/USDC",
    "address": "0XDCEF968D416A41CDAC0ED8702FAC8128A64241A2",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XA1F8A6807C402E4A15EF4EBA36528A3FED24E577": {
    "name": "Curve.fi ETH/frxETH",
    "address": "0XA1F8A6807C402E4A15EF4EBA36528A3FED24E577",
    "assetType": "6",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XF253F83ACA21AABD2A20553AE0BF7F65C755A07F": {
    "name": "Curve.fi wBTC/sBTC",
    "address": "0XF253F83ACA21AABD2A20553AE0BF7F65C755A07F",
    "assetType": "0",
    "implementationAddress": null,
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "1": {
        "index": 1,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    },
    "underlyingCoins": null
  },
  "0X03470B57B05089EE40C651DAC9E0387F1F3CB46F": {
    "name": "Curve.fi Factory Crypto Pool: FXS/ETH",
    "address": "0X03470B57B05089EE40C651DAC9E0387F1F3CB46F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X6EC176B5449DD7C1A87CA8D97ACECC531C0CA0D8": {
    "name": "Curve.fi Factory Crypto Pool: FXS/ETH",
    "address": "0X6EC176B5449DD7C1A87CA8D97ACECC531C0CA0D8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      }
    },
    "underlyingCoins": null
  },
  "0X1667954F14F5B22C703116D8D806F988B1E09018": {
    "name": "Curve.fi Factory Crypto Pool: FXS/ETH",
    "address": "0X1667954F14F5B22C703116D8D806F988B1E09018",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      }
    },
    "underlyingCoins": null
  },
  "0X941EB6F616114E4ECAA85377945EA306002612FE": {
    "name": "Curve.fi Factory Crypto Pool: FXS/ETH",
    "address": "0X941EB6F616114E4ECAA85377945EA306002612FE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      }
    },
    "underlyingCoins": null
  },
  "0X50F3752289E1456BFA505AFD37B241BCA23E685D": {
    "name": "Curve.fi Factory Crypto Pool: BADGER/WBTC",
    "address": "0X50F3752289E1456BFA505AFD37B241BCA23E685D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BADGER",
        "decimals": 18,
        "address": "0X3472A5A71965499ACD81997A54BBA8D852C6E53D"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      }
    },
    "underlyingCoins": null
  },
  "0X99AF0326AB1C2A68C6712A5622C1AA8E4B35FD57": {
    "name": "Curve.fi Factory Crypto Pool: INV/DOLA",
    "address": "0X99AF0326AB1C2A68C6712A5622C1AA8E4B35FD57",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "INV",
        "decimals": 18,
        "address": "0X41D5D79431A913C4AE7D69A668ECDFE5FF9DFB68"
      },
      "1": {
        "index": 1,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      }
    },
    "underlyingCoins": null
  },
  "0XC68FFDDEA3A77B456227B50EBFDCC3C33BC2A8A4": {
    "name": "Curve.fi Factory Crypto Pool: RAI/FRAX",
    "address": "0XC68FFDDEA3A77B456227B50EBFDCC3C33BC2A8A4",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0X96FB2AB514CA569A1486C50339533CA4637B338B": {
    "name": "Curve.fi Factory Crypto Pool: RAI/ETH",
    "address": "0X96FB2AB514CA569A1486C50339533CA4637B338B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0XC26B89A667578EC7B3F11B2F98D6FD15C07C54BA": {
    "name": "Curve.fi Factory Crypto Pool: YFI/ETH",
    "address": "0XC26B89A667578EC7B3F11B2F98D6FD15C07C54BA",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      }
    },
    "underlyingCoins": null
  },
  "0X48536EC5233297C367FD0B6979B75D9270BB6B15": {
    "name": "Curve.fi Factory Crypto Pool: AAVE/palStkAAVE",
    "address": "0X48536EC5233297C367FD0B6979B75D9270BB6B15",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      },
      "1": {
        "index": 1,
        "name": "palStkAAVE",
        "decimals": 18,
        "address": "0X24E79E946DEA5482212C38AAB2D0782F04CDB0E0"
      }
    },
    "underlyingCoins": null
  },
  "0X8B0AFA4B63A3581B731DA9D79774A3EAE63B5ABD": {
    "name": "Curve.fi Factory Crypto Pool: DYDX/ETH",
    "address": "0X8B0AFA4B63A3581B731DA9D79774A3EAE63B5ABD",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DYDX",
        "decimals": 18,
        "address": "0X92D6C1E31E14520E676A687F0A93788B716BEFF5"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0XFB8814D005C5F32874391E888DA6EB2FE7A27902": {
    "name": "Curve.fi Factory Crypto Pool: SDT/ETH",
    "address": "0XFB8814D005C5F32874391E888DA6EB2FE7A27902",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "SDT",
        "decimals": 18,
        "address": "0X73968B9A57C6E53D41345FD57A6E6AE27D6CDB2F"
      }
    },
    "underlyingCoins": null
  },
  "0XE07BDE9EB53DEFFA979DAE36882014B758111A78": {
    "name": "Curve.fi Factory Crypto Pool: CADC/USDC",
    "address": "0XE07BDE9EB53DEFFA979DAE36882014B758111A78",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CADC",
        "decimals": 18,
        "address": "0XCADC0ACD4B445166F12D2C07EAC6E2544FBE2EEF"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XB6D9B32407BFA562D9211ACDBA2631A46C850956": {
    "name": "Curve.fi Factory Crypto Pool: RAI/agEUR",
    "address": "0XB6D9B32407BFA562D9211ACDBA2631A46C850956",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      }
    },
    "underlyingCoins": null
  },
  "0X1DFF955CDDD55FBA58DB3CD658F9E3E3C31851EB": {
    "name": "Curve.fi Factory Crypto Pool: RocketPool rETH",
    "address": "0X1DFF955CDDD55FBA58DB3CD658F9E3E3C31851EB",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "rETH",
        "decimals": 18,
        "address": "0XAE78736CD615F374D3085123A210448E74FC6393"
      }
    },
    "underlyingCoins": null
  },
  "0XDAD60C5B748306BA5A0C9A3C3482A8D1153DAD2A": {
    "name": "Curve.fi Factory Crypto Pool: PAR/USDC",
    "address": "0XDAD60C5B748306BA5A0C9A3C3482A8D1153DAD2A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PAR",
        "decimals": 18,
        "address": "0X68037790A0229E9CE6EAA8A99EA92964106C4703"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XD8C49617E6A2C7584DDBEAB652368EE84954BF5C": {
    "name": "Curve.fi Factory Crypto Pool: DUCK/ETH",
    "address": "0XD8C49617E6A2C7584DDBEAB652368EE84954BF5C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "DUCK",
        "decimals": 18,
        "address": "0X92E187A03B6CD19CB6AF293BA17F2745FD2357D5"
      }
    },
    "underlyingCoins": null
  },
  "0XF43B15AB692FDE1F9C24A9FCE700ADCC809D5391": {
    "name": "Curve.fi Factory Crypto Pool: BTRFLY/ETH",
    "address": "0XF43B15AB692FDE1F9C24A9FCE700ADCC809D5391",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "BTRFLY",
        "decimals": 9,
        "address": "0XC0D4CEB216B3BA9C3701B291766FDCBA977CEC3A"
      }
    },
    "underlyingCoins": null
  },
  "0XD658A338613198204DCA1143AC3F01A722B5D94A": {
    "name": "Curve.fi Factory Crypto Pool: cvxFxs/Fxs",
    "address": "0XD658A338613198204DCA1143AC3F01A722B5D94A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      },
      "1": {
        "index": 1,
        "name": "cvxFXS",
        "decimals": 18,
        "address": "0XFEEF77D3F69374F66429C91D732A244F074BDF74"
      }
    },
    "underlyingCoins": null
  },
  "0XA498B08CA3C109E4EBC7FF01422B6769EAEF16EF": {
    "name": "Curve.fi Factory Crypto Pool: ALCX/ETH",
    "address": "0XA498B08CA3C109E4EBC7FF01422B6769EAEF16EF",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "ALCX",
        "decimals": 18,
        "address": "0XDBDB4D16EDA451D0503B854CF79D55697F90C8DF"
      }
    },
    "underlyingCoins": null
  },
  "0X365901DB5ADB4C789801F19D5F1F46C574783AD6": {
    "name": "Curve.fi Factory Crypto Pool: OHM/ETH",
    "address": "0X365901DB5ADB4C789801F19D5F1F46C574783AD6",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OHM",
        "decimals": 9,
        "address": "0X64AA3364F17A4D01C6F1751FD97C2BD3D7E7F1D5"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X6EC38B3228251A0C5D491FAF66858E2E23D7728B": {
    "name": "Curve.fi Factory Crypto Pool: OHM/ETH",
    "address": "0X6EC38B3228251A0C5D491FAF66858E2E23D7728B",
    "assetType": "5",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OHM",
        "decimals": 9,
        "address": "0X64AA3364F17A4D01C6F1751FD97C2BD3D7E7F1D5"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X9A22CDB1CA1CDD2371CD5BB5199564C4E89465EB": {
    "name": "Curve.fi Factory Crypto Pool: SILO/FRAX",
    "address": "0X9A22CDB1CA1CDD2371CD5BB5199564C4E89465EB",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "Silo",
        "decimals": 18,
        "address": "0X6F80310CA7F2C654691D1383149FA1A57D8AB1F8"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0X80AA1A80A30055DAA084E599836532F3E58C95E2": {
    "name": "Curve.fi Factory Crypto Pool: Goldfinch FIDU/USDC",
    "address": "0X80AA1A80A30055DAA084E599836532F3E58C95E2",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FIDU",
        "decimals": 18,
        "address": "0X6A445E9F40E0B97C92D0B8A3366CEF1D67F700BF"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X90CE3285A9CCE2D36149F12DF2C1E357AF304A1D": {
    "name": "Curve.fi Factory Crypto Pool: MKR/ETH",
    "address": "0X90CE3285A9CCE2D36149F12DF2C1E357AF304A1D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "0x4d4b520000000000000000000000000000000000000000000000000000000000",
        "decimals": 18,
        "address": "0X9F8F72AA9304C8B593D555F12EF6589CC3A579A2"
      }
    },
    "underlyingCoins": null
  },
  "0XBF9702EFEFE1303A61B7C944B5741B773DD930A7": {
    "name": "Curve.fi Factory Crypto Pool: AAVE/ETH",
    "address": "0XBF9702EFEFE1303A61B7C944B5741B773DD930A7",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      }
    },
    "underlyingCoins": null
  },
  "0X9C6751593A1424108F53E5AD6754940FEDAA5BC0": {
    "name": "Curve.fi Factory Crypto Pool: COMP/ETH",
    "address": "0X9C6751593A1424108F53E5AD6754940FEDAA5BC0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "COMP",
        "decimals": 18,
        "address": "0XC00E94CB662C3520282E6F5717214004A7F26888"
      }
    },
    "underlyingCoins": null
  },
  "0X1570AF3DF649FC74872C5B8F280A162A3BDD4EB6": {
    "name": "Curve.fi Factory Crypto Pool: ibEUR/USDC",
    "address": "0X1570AF3DF649FC74872C5B8F280A162A3BDD4EB6",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibEUR",
        "decimals": 18,
        "address": "0X96E61422B6A9BA0E068B6C5ADD4FFABC6A4AAE27"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X5B692073F141C31384FAE55856CFB6CBFFE91E60": {
    "name": "Curve.fi Factory Crypto Pool: ibAUD/USDC",
    "address": "0X5B692073F141C31384FAE55856CFB6CBFFE91E60",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibAUD",
        "decimals": 18,
        "address": "0XFAFDF0C4C1CB09D430BF88C75D88BB46DAE09967"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X6DF0D77F0496CE44E72D695943950D8641FCA5CF": {
    "name": "Curve.fi Factory Crypto Pool: ibCHF/USDC",
    "address": "0X6DF0D77F0496CE44E72D695943950D8641FCA5CF",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibCHF",
        "decimals": 18,
        "address": "0X1CC481CE2BD2EC7BF67D1BE64D4878B16078F309"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XACCE4FE9CE2A6FE9AF83E7CF321A3FF7675E0AB6": {
    "name": "Curve.fi Factory Crypto Pool: ibGBP/USDC",
    "address": "0XACCE4FE9CE2A6FE9AF83E7CF321A3FF7675E0AB6",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibGBP",
        "decimals": 18,
        "address": "0X69681F8FDE45345C3870BCD5EAF4A05A60E7D227"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XEB0265938C1190AB4E3E1F6583BC956DF47C0F93": {
    "name": "Curve.fi Factory Crypto Pool: ibJPY/USDC",
    "address": "0XEB0265938C1190AB4E3E1F6583BC956DF47C0F93",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibJPY",
        "decimals": 18,
        "address": "0X5555F75E3D5278082200FB451D1B6BA946D8E13B"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XEF04F337FCB2EA220B6E8DB5EDBE2D774837581C": {
    "name": "Curve.fi Factory Crypto Pool: ibKRW/USDC",
    "address": "0XEF04F337FCB2EA220B6E8DB5EDBE2D774837581C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibKRW",
        "decimals": 18,
        "address": "0X95DFDC8161832E4FF7816AC4B6367CE201538253"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X6B234F354EDA8FAE082BE20DCF790FD886B42340": {
    "name": "Curve.fi Factory Crypto Pool: ALUSD/BNT",
    "address": "0X6B234F354EDA8FAE082BE20DCF790FD886B42340",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "BNT",
        "decimals": 18,
        "address": "0X1F573D6FB3F13D689FF844B4CE37794D79A7FF1C"
      }
    },
    "underlyingCoins": null
  },
  "0XA6B28989B81B2FE4EC03FDE324DE1A99AE4366E4": {
    "name": "Curve.fi Factory Crypto Pool: Test",
    "address": "0XA6B28989B81B2FE4EC03FDE324DE1A99AE4366E4",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      }
    },
    "underlyingCoins": null
  },
  "0X39567DB64F0B25DB2C35FC7A4F60C3C5258E4654": {
    "name": "Curve.fi Factory Crypto Pool: USDC/STG",
    "address": "0X39567DB64F0B25DB2C35FC7A4F60C3C5258E4654",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      }
    },
    "underlyingCoins": null
  },
  "0X751D3FEFFED0890B76E9B86476CFEEAA1FCDA73D": {
    "name": "Curve.fi Factory Crypto Pool: USDC/STG",
    "address": "0X751D3FEFFED0890B76E9B86476CFEEAA1FCDA73D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      }
    },
    "underlyingCoins": null
  },
  "0X3211C6CBEF1429DA3D0D58494938299C92AD5860": {
    "name": "Curve.fi Factory Crypto Pool: STG/USDC",
    "address": "0X3211C6CBEF1429DA3D0D58494938299C92AD5860",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X75A6787C7EE60424358B449B539A8B774C9B4862": {
    "name": "Curve.fi Factory Crypto Pool: PAL/ETH",
    "address": "0X75A6787C7EE60424358B449B539A8B774C9B4862",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "PAL",
        "decimals": 18,
        "address": "0XAB846FB6C81370327E784AE7CBB6D6A6AF6FF4BF"
      }
    },
    "underlyingCoins": null
  },
  "0X21410232B484136404911780BC32756D5D1A9FA9": {
    "name": "Curve.fi Factory Crypto Pool: KP3R/ETH",
    "address": "0X21410232B484136404911780BC32756D5D1A9FA9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "KP3R",
        "decimals": 18,
        "address": "0X1CEB5CB57C4D4E2B2433641B95DD330A33185A44"
      }
    },
    "underlyingCoins": null
  },
  "0X86CF48E9735F84D3311141E8941B2494FB4B8142": {
    "name": "Curve.fi Factory Crypto Pool: JZC Miners",
    "address": "0X86CF48E9735F84D3311141E8941B2494FB4B8142",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "JZC",
        "decimals": 4,
        "address": "0X7BB46C33A972349EF48E7545CAC068FB77B89E5A"
      }
    },
    "underlyingCoins": null
  },
  "0XFE4A08F22FE65759BA91DB2E2CADA09B4415B0D7": {
    "name": "Curve.fi Factory Crypto Pool: LFT/ETH",
    "address": "0XFE4A08F22FE65759BA91DB2E2CADA09B4415B0D7",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "LFT",
        "decimals": 18,
        "address": "0XB620BE8A1949AA9532E6A3510132864EF9BC3F82"
      }
    },
    "underlyingCoins": null
  },
  "0X7472764C28F843BA246F294C44DE9456911A3454": {
    "name": "Curve.fi Factory Crypto Pool: eth/nfd",
    "address": "0X7472764C28F843BA246F294C44DE9456911A3454",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "NFD",
        "decimals": 18,
        "address": "0XDFDB7F72C1F195C5951A234E8DB9806EB0635346"
      }
    },
    "underlyingCoins": null
  },
  "0X7E050CF658777CC1DA4A4508E79D71859044B60E": {
    "name": "Curve.fi Factory Crypto Pool: JPEG/ETH",
    "address": "0X7E050CF658777CC1DA4A4508E79D71859044B60E",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "JPEG",
        "decimals": 18,
        "address": "0XE80C0CD204D654CEBE8DD64A4857CAB6BE8345A3"
      }
    },
    "underlyingCoins": null
  },
  "0X782115C863A05ABF8795DF377D89AAD1AADF4DFA": {
    "name": "Curve.fi Factory Crypto Pool: CNC/ETH",
    "address": "0X782115C863A05ABF8795DF377D89AAD1AADF4DFA",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CNC",
        "decimals": 18,
        "address": "0X9AE380F0272E2162340A5BB646C354271C0F5CFC"
      }
    },
    "underlyingCoins": null
  },
  "0X838AF967537350D2C44ABB8C010E49E32673AB94": {
    "name": "Curve.fi Factory Crypto Pool: CNC/ETH",
    "address": "0X838AF967537350D2C44ABB8C010E49E32673AB94",
    "assetType": "5",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CNC",
        "decimals": 18,
        "address": "0X9AE380F0272E2162340A5BB646C354271C0F5CFC"
      }
    },
    "underlyingCoins": null
  },
  "0X595146ED98C81DDE9BD23D0C2AB5B807C7FE2D9F": {
    "name": "Curve.fi Factory Crypto Pool: CADC/3CRV",
    "address": "0X595146ED98C81DDE9BD23D0C2AB5B807C7FE2D9F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CADC",
        "decimals": 18,
        "address": "0XCADC0ACD4B445166F12D2C07EAC6E2544FBE2EEF"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "CADC",
        "decimals": 18,
        "address": "0XCADC0ACD4B445166F12D2C07EAC6E2544FBE2EEF"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X62D1D9065B4C78964040B640AB404D86D8F68263": {
    "name": "Curve.fi Factory Crypto Pool: VNXAU/USDC",
    "address": "0X62D1D9065B4C78964040B640AB404D86D8F68263",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "VNXAU",
        "decimals": 18,
        "address": "0X6D57B2E05F26C26B549231C866BDD39779E4A488"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XF861483FA7E511FBC37487D91B6FAA803AF5D37C": {
    "name": "Curve.fi Factory Crypto Pool: FPI2Pool",
    "address": "0XF861483FA7E511FBC37487D91B6FAA803AF5D37C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "1": {
        "index": 1,
        "name": "FPI",
        "decimals": 18,
        "address": "0X5CA135CB8527D76E932F34B5145575F9D8CBE08E"
      }
    },
    "underlyingCoins": null
  },
  "0XB2E113A6B8EDEA086A44B1509013C4FC69EC4BF0": {
    "name": "Curve.fi Factory Crypto Pool: VIRTUE/ETH",
    "address": "0XB2E113A6B8EDEA086A44B1509013C4FC69EC4BF0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "VIRTUE",
        "decimals": 18,
        "address": "0X9416BA76E88D873050A06E5956A3EBF10386B863"
      }
    },
    "underlyingCoins": null
  },
  "0XD0A1D2A9350824516AE8729B8311557BA7E55BFF": {
    "name": "Curve.fi Factory Crypto Pool: ETH/VIRTUE",
    "address": "0XD0A1D2A9350824516AE8729B8311557BA7E55BFF",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "VIRTUE",
        "decimals": 18,
        "address": "0X9416BA76E88D873050A06E5956A3EBF10386B863"
      }
    },
    "underlyingCoins": null
  },
  "0X5D898FD41875B14C1781FB497AECAB8E9B24DFC9": {
    "name": "Curve.fi Factory Crypto Pool: CXD/ETH",
    "address": "0X5D898FD41875B14C1781FB497AECAB8E9B24DFC9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CXD",
        "decimals": 18,
        "address": "0X5A56DA75C50AA2733F5FA9A2442AAEFCBC60B2E6"
      }
    },
    "underlyingCoins": null
  },
  "0X4535913573D299A6372CA43B90AA6BE1CF68F779": {
    "name": "Curve.fi Factory Crypto Pool: CXD/USDC",
    "address": "0X4535913573D299A6372CA43B90AA6BE1CF68F779",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "CXD",
        "decimals": 18,
        "address": "0X5A56DA75C50AA2733F5FA9A2442AAEFCBC60B2E6"
      }
    },
    "underlyingCoins": null
  },
  "0X3C42B0F384D2912661C940D46CFFE1CD10F1C66F": {
    "name": "Curve.fi Factory Crypto Pool: [Test] Test CTDL/wBTC",
    "address": "0X3C42B0F384D2912661C940D46CFFE1CD10F1C66F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "Test CTDL",
        "decimals": 18,
        "address": "0XAF0B1FDF9C6BFEC7B3512F207553C0BA00D7F1A2"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      }
    },
    "underlyingCoins": null
  },
  "0X7F787210C83012FCA364AE79AD8FC26641C6FBE5": {
    "name": "Curve.fi Factory Crypto Pool: TRU/ETH",
    "address": "0X7F787210C83012FCA364AE79AD8FC26641C6FBE5",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "TRU",
        "decimals": 8,
        "address": "0X4C19596F5AAFF459FA38B0F7ED92F11AE6543784"
      }
    },
    "underlyingCoins": null
  },
  "0XE0E970A99BC4F53804D8145BEBBC7EBC9422BA7F": {
    "name": "Curve.fi Factory Crypto Pool: TOKE/ETH",
    "address": "0XE0E970A99BC4F53804D8145BEBBC7EBC9422BA7F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "TOKE",
        "decimals": 18,
        "address": "0X2E9D63788249371F1DFC918A52F8D799F4A38C94"
      }
    },
    "underlyingCoins": null
  },
  "0XD434EAF67BBA1903F61CDD3EDE6700CAC74A5FF2": {
    "name": "Curve.fi Factory Crypto Pool: PSL/ETH",
    "address": "0XD434EAF67BBA1903F61CDD3EDE6700CAC74A5FF2",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "WPSL",
        "decimals": 5,
        "address": "0XC775C0C30840CB9F51E21061B054EBF1A00ACC29"
      }
    },
    "underlyingCoins": null
  },
  "0X6525E7E2E8450741AB97BD3948BFA47878F83EC6": {
    "name": "Curve.fi Factory Crypto Pool: ARTH",
    "address": "0X6525E7E2E8450741AB97BD3948BFA47878F83EC6",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X7D99469FB3A530136EC0AB6981D64BC9FF81AD04": {
    "name": "Curve.fi Factory Crypto Pool: ARTH",
    "address": "0X7D99469FB3A530136EC0AB6981D64BC9FF81AD04",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XBFCA1A72EDD92FFF61A8C88F61D4E64E99232B4B": {
    "name": "Curve.fi Factory Crypto Pool: ARTH",
    "address": "0XBFCA1A72EDD92FFF61A8C88F61D4E64E99232B4B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X7F2AF2C7BFDAD063FF01DCEC077A216D95A0A944": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/USDC",
    "address": "0X7F2AF2C7BFDAD063FF01DCEC077A216D95A0A944",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      }
    },
    "underlyingCoins": null
  },
  "0XA148BD19E26FF9604F6A608E22BFB7B772D0D1A3": {
    "name": "Curve.fi Factory Crypto Pool: bSTBL/DAI",
    "address": "0XA148BD19E26FF9604F6A608E22BFB7B772D0D1A3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "1": {
        "index": 1,
        "name": "bSTBL",
        "decimals": 18,
        "address": "0X5EE08F40B637417BCC9D2C51B62F4820EC9CF5D8"
      }
    },
    "underlyingCoins": null
  },
  "0XF3456E8061461E144B3F252E69DCD5B6070FDEE0": {
    "name": "Curve.fi Factory Crypto Pool: pxCVX/CVX",
    "address": "0XF3456E8061461E144B3F252E69DCD5B6070FDEE0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "pxCVX",
        "decimals": 18,
        "address": "0XBCE0CF87F513102F22232436CCA2CA49E815C3AC"
      }
    },
    "underlyingCoins": null
  },
  "0XFD484A99D21CA118F22871134F467B1CA3F842AA": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/DAI",
    "address": "0XFD484A99D21CA118F22871134F467B1CA3F842AA",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X5114F86027D4C9A509CBA072B8135A171402C6D5": {
    "name": "Curve.fi Factory Crypto Pool: UNBNK/ETH",
    "address": "0X5114F86027D4C9A509CBA072B8135A171402C6D5",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "UNBNK",
        "decimals": 18,
        "address": "0X06B884E60794CE02AAFAB13791B59A2E6A07442F"
      }
    },
    "underlyingCoins": null
  },
  "0X6E77889FF348A16547CABA3CE011CB120ED73BFC": {
    "name": "Curve.fi Factory Crypto Pool: frETH/WETH",
    "address": "0X6E77889FF348A16547CABA3CE011CB120ED73BFC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "frETH",
        "decimals": 18,
        "address": "0XB4BD4628E6EFB0CB521D9EC35050C75840320374"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X95F3672A418230C5664B7154DFCE0ACFA7EED68D": {
    "name": "Curve.fi Factory Crypto Pool: aCRV/CRV",
    "address": "0X95F3672A418230C5664B7154DFCE0ACFA7EED68D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "aCRV",
        "decimals": 18,
        "address": "0X2B95A1DCC3D405535F9ED33C219AB38E8D7E0884"
      },
      "1": {
        "index": 1,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      }
    },
    "underlyingCoins": null
  },
  "0XEC1F6DF37B16432E520942AFFE28149BADC5BC5E": {
    "name": "Curve.fi Factory Crypto Pool: WETH/RAREPEPE",
    "address": "0XEC1F6DF37B16432E520942AFFE28149BADC5BC5E",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "RAREPEPE",
        "decimals": 18,
        "address": "0XD6242E984E6DB6B5286FB2C26C69330DA639DA12"
      }
    },
    "underlyingCoins": null
  },
  "0XFCE1B5447A3DB7E18352E8AE4BCD780029012383": {
    "name": "Curve.fi Factory Crypto Pool: NAKAMOTOCARD",
    "address": "0XFCE1B5447A3DB7E18352E8AE4BCD780029012383",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "RAREPEPE",
        "decimals": 18,
        "address": "0XD6242E984E6DB6B5286FB2C26C69330DA639DA12"
      }
    },
    "underlyingCoins": null
  },
  "0XDDBDCEBB989B1EF804338D6C9A902F91C2738936": {
    "name": "Curve.fi Factory Crypto Pool: RAREPEPE",
    "address": "0XDDBDCEBB989B1EF804338D6C9A902F91C2738936",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "RAREPEPE",
        "decimals": 18,
        "address": "0XD6242E984E6DB6B5286FB2C26C69330DA639DA12"
      }
    },
    "underlyingCoins": null
  },
  "0X039FD59541B3989C7A1E9278431038B3B6BA5F43": {
    "name": "Curve.fi Factory Crypto Pool: mxnt",
    "address": "0X039FD59541B3989C7A1E9278431038B3B6BA5F43",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MXNt",
        "decimals": 6,
        "address": "0XED03ED872159E199065401B6D0D487D78D9464AA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MXNt",
        "decimals": 6,
        "address": "0XED03ED872159E199065401B6D0D487D78D9464AA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC12A73C46D49FA0D7433C90291BC8D1A9EAE7B23": {
    "name": "Curve.fi Factory Crypto Pool: MXNT/USDC",
    "address": "0XC12A73C46D49FA0D7433C90291BC8D1A9EAE7B23",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MXNt",
        "decimals": 6,
        "address": "0XED03ED872159E199065401B6D0D487D78D9464AA"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X9BFB082F2DD5D63907AFA33DBB8F9C0BCD5C2333": {
    "name": "Curve.fi Factory Crypto Pool: Phanes",
    "address": "0X9BFB082F2DD5D63907AFA33DBB8F9C0BCD5C2333",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "PHNS",
        "decimals": 18,
        "address": "0X4425C9C6302BD2A815C21012DC7F692216FB3176"
      }
    },
    "underlyingCoins": null
  },
  "0X840CBF6861137624E60380D5E915619885C5AA95": {
    "name": "Curve.fi Factory Crypto Pool: FLOAT/USDC",
    "address": "0X840CBF6861137624E60380D5E915619885C5AA95",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FLOAT",
        "decimals": 18,
        "address": "0XB05097849BCA421A3F51B249BA6CCA4AF4B97CB9"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XD3301B7CAA76F932816A6FC7EF0B673238E217AD": {
    "name": "Curve.fi Factory Crypto Pool: BENT/FRAX",
    "address": "0XD3301B7CAA76F932816A6FC7EF0B673238E217AD",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BENT",
        "decimals": 18,
        "address": "0X01597E397605BF280674BF292623460B4204C375"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BENT",
        "decimals": 18,
        "address": "0X01597E397605BF280674BF292623460B4204C375"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XB2E2536821521174A168EDA7BE78A6C70EA6E5FA": {
    "name": "Curve.fi Factory Crypto Pool: BENTCVX/FRAX",
    "address": "0XB2E2536821521174A168EDA7BE78A6C70EA6E5FA",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bentCVX",
        "decimals": 18,
        "address": "0X9E0441E084F5DB0606565737158AA6AB6B970FE0"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "bentCVX",
        "decimals": 18,
        "address": "0X9E0441E084F5DB0606565737158AA6AB6B970FE0"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X316D2606CE036EC0E69A11432A870A4EC66ADE96": {
    "name": "Curve.fi Factory Crypto Pool: APW/ETH",
    "address": "0X316D2606CE036EC0E69A11432A870A4EC66ADE96",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "APW",
        "decimals": 18,
        "address": "0X4104B135DBC9609FC1A9490E61369036497660C8"
      }
    },
    "underlyingCoins": null
  },
  "0XC78D84EE5D970227F988A2E2DC0A4A945163BCB9": {
    "name": "Curve.fi Factory Crypto Pool: APW/ETH",
    "address": "0XC78D84EE5D970227F988A2E2DC0A4A945163BCB9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "APW",
        "decimals": 18,
        "address": "0X4104B135DBC9609FC1A9490E61369036497660C8"
      }
    },
    "underlyingCoins": null
  },
  "0XB2C248C0B0DB7D28DFA0123438B40BB31FB8AA05": {
    "name": "Curve.fi Factory Crypto Pool: MATIC/SHIB",
    "address": "0XB2C248C0B0DB7D28DFA0123438B40BB31FB8AA05",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      },
      "1": {
        "index": 1,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      }
    },
    "underlyingCoins": null
  },
  "0X799D141E83D88996C48B98A4F8EB3D96AB422DD3": {
    "name": "Curve.fi Factory Crypto Pool: MATIC/FLEET",
    "address": "0X799D141E83D88996C48B98A4F8EB3D96AB422DD3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      },
      "1": {
        "index": 1,
        "name": "FLEET",
        "decimals": 6,
        "address": "0XFD56A3DCFC0690881A466AE432D71BB2DB588083"
      }
    },
    "underlyingCoins": null
  },
  "0X383AD525211B8A1A9C13532CC021773052B2F4F8": {
    "name": "Curve.fi Factory Crypto Pool: CTR/ETH",
    "address": "0X383AD525211B8A1A9C13532CC021773052B2F4F8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CTR",
        "decimals": 18,
        "address": "0XB3AD645DB386D7F6D753B2B9C3F4B853DA6890B8"
      }
    },
    "underlyingCoins": null
  },
  "0X79CB6A84FBEC1FE2D66B705A1E7F6482C2993049": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI",
    "address": "0X79CB6A84FBEC1FE2D66B705A1E7F6482C2993049",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X91D9D17EFD378F38A48122AE6EC01B2E83D1AC98": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI-2",
    "address": "0X91D9D17EFD378F38A48122AE6EC01B2E83D1AC98",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X118629329731CE1FF01F9401212C53939F8A9EEB": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X118629329731CE1FF01F9401212C53939F8A9EEB",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XBD917239EC067A6AF82AABB6B45DAD3D09F4EFD8": {
    "name": "Curve.fi Factory Crypto Pool: test2",
    "address": "0XBD917239EC067A6AF82AABB6B45DAD3D09F4EFD8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X6E314039F4C56000F4EBB3A7854A84CC6225FB92": {
    "name": "Curve.fi Factory Crypto Pool: BTRFLY/ETH",
    "address": "0X6E314039F4C56000F4EBB3A7854A84CC6225FB92",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "BTRFLY",
        "decimals": 18,
        "address": "0XC55126051B22EBB829D00368F4B12BDE432DE5DA"
      }
    },
    "underlyingCoins": null
  },
  "0X337CA39842C448030196693F3433332FF1CF3E41": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI-3",
    "address": "0X337CA39842C448030196693F3433332FF1CF3E41",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X0D1C65B28190CB88F328D2051C524A5C63D10EB5": {
    "name": "Curve.fi Factory Crypto Pool: RAI/LUSD",
    "address": "0X0D1C65B28190CB88F328D2051C524A5C63D10EB5",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0X162B4DEEFC73A5277B09BD7A02D25DA73D66183D": {
    "name": "Curve.fi Factory Crypto Pool: RAI/LUSD-2",
    "address": "0X162B4DEEFC73A5277B09BD7A02D25DA73D66183D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0XC58FDB8A50AB921A73535656A7C69387DD863FF6": {
    "name": "Curve.fi Factory Crypto Pool: RAI/LUSD-3",
    "address": "0XC58FDB8A50AB921A73535656A7C69387DD863FF6",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0XD4E2FDC354C5DFFB865798CA98C2B9D5382F687C": {
    "name": "Curve.fi Factory Crypto Pool: FXSFPIS",
    "address": "0XD4E2FDC354C5DFFB865798CA98C2B9D5382F687C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      },
      "1": {
        "index": 1,
        "name": "FPIS",
        "decimals": 18,
        "address": "0XC2544A32872A91F4A553B404C6950E89DE901FDB"
      }
    },
    "underlyingCoins": null
  },
  "0X5FAE7E604FC3E24FD43A72867CEBAC94C65B404A": {
    "name": "Curve.fi Factory Crypto Pool: cbETH/ETH",
    "address": "0X5FAE7E604FC3E24FD43A72867CEBAC94C65B404A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "cbETH",
        "decimals": 18,
        "address": "0XBE9895146F7AF43049CA1C1AE358B0541EA49704"
      }
    },
    "underlyingCoins": null
  },
  "0X0CF8327B20A159F0CD99214C5305D49E9D8207F0": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI-4",
    "address": "0X0CF8327B20A159F0CD99214C5305D49E9D8207F0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X58257E4291F95165184B4BEA7793A1D6F8E7B627": {
    "name": "Curve.fi Factory Crypto Pool: agEUR/FRAXBP",
    "address": "0X58257E4291F95165184B4BEA7793A1D6F8E7B627",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X21D158D95C2E150E144C36FC64E3653B8D6C6267": {
    "name": "Curve.fi Factory Crypto Pool: cvxFxs/FraxBP",
    "address": "0X21D158D95C2E150E144C36FC64E3653B8D6C6267",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cvxFXS",
        "decimals": 18,
        "address": "0XFEEF77D3F69374F66429C91D732A244F074BDF74"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "cvxFXS",
        "decimals": 18,
        "address": "0XFEEF77D3F69374F66429C91D732A244F074BDF74"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XBEC570D92AFB7FFC553BDD9D4B4638121000B10D": {
    "name": "Curve.fi Factory Crypto Pool: CVX/FraxBP",
    "address": "0XBEC570D92AFB7FFC553BDD9D4B4638121000B10D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X4149D1038575CE235E03E03B39487A80FD709D31": {
    "name": "Curve.fi Factory Crypto Pool: ALCX/FraxBP",
    "address": "0X4149D1038575CE235E03E03B39487A80FD709D31",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ALCX",
        "decimals": 18,
        "address": "0XDBDB4D16EDA451D0503B854CF79D55697F90C8DF"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ALCX",
        "decimals": 18,
        "address": "0XDBDB4D16EDA451D0503B854CF79D55697F90C8DF"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X31C325A01861C7DBD331A9270296A31296D797A0": {
    "name": "Curve.fi Factory Crypto Pool: cvxCrv/FraxBP",
    "address": "0X31C325A01861C7DBD331A9270296A31296D797A0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XD9F907F7F84CBB0AF85C7829922FD692339147F9": {
    "name": "Curve.fi Factory Crypto Pool: RAI/FRAXBP",
    "address": "0XD9F907F7F84CBB0AF85C7829922FD692339147F9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X75A1FDA374FDB4E47D703D0282E94C87119FA46E": {
    "name": "Curve.fi Factory Crypto Pool: Pull",
    "address": "0X75A1FDA374FDB4E47D703D0282E94C87119FA46E",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X611A95BF3CC0EF593F22C4E4D8EB4D6C937E006C": {
    "name": "Curve.fi Factory Crypto Pool: jpyusd",
    "address": "0X611A95BF3CC0EF593F22C4E4D8EB4D6C937E006C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X13B876C26AD6D21CB87AE459EAF6D7A1B788A113": {
    "name": "Curve.fi Factory Crypto Pool: BADGER/FRAXBP",
    "address": "0X13B876C26AD6D21CB87AE459EAF6D7A1B788A113",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BADGER",
        "decimals": 18,
        "address": "0X3472A5A71965499ACD81997A54BBA8D852C6E53D"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BADGER",
        "decimals": 18,
        "address": "0X3472A5A71965499ACD81997A54BBA8D852C6E53D"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X4D19E7FD118FD751FEA7C0324D7E7B0A3D05EBA4": {
    "name": "Curve.fi Factory Crypto Pool: ZARP/USDC",
    "address": "0X4D19E7FD118FD751FEA7C0324D7E7B0A3D05EBA4",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ZARP",
        "decimals": 18,
        "address": "0X8CB24ED2E4F7E2065F4EB2BE5F6B0064B1919850"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X825722AF244432319C1E32B6B18ADED2D4A014DF": {
    "name": "Curve.fi Factory Crypto Pool: BENT/FraxBP",
    "address": "0X825722AF244432319C1E32B6B18ADED2D4A014DF",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BENT",
        "decimals": 18,
        "address": "0X01597E397605BF280674BF292623460B4204C375"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BENT",
        "decimals": 18,
        "address": "0X01597E397605BF280674BF292623460B4204C375"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X02DFA5C793A9CE4D767A86259245A162A57F2DB4": {
    "name": "Curve.fi Factory Crypto Pool: bentCVX/FraxBP",
    "address": "0X02DFA5C793A9CE4D767A86259245A162A57F2DB4",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bentCVX",
        "decimals": 18,
        "address": "0X9E0441E084F5DB0606565737158AA6AB6B970FE0"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "bentCVX",
        "decimals": 18,
        "address": "0X9E0441E084F5DB0606565737158AA6AB6B970FE0"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X27A8697FBD2ED137D88E74132A5558FA43656175": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/OHM",
    "address": "0X27A8697FBD2ED137D88E74132A5558FA43656175",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OHM",
        "decimals": 9,
        "address": "0X64AA3364F17A4D01C6F1751FD97C2BD3D7E7F1D5"
      },
      "1": {
        "index": 1,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      }
    },
    "underlyingCoins": null
  },
  "0XC7A87B0F491C1A043EB5315281163556B7F36F7F": {
    "name": "Curve.fi Factory Crypto Pool: BBLC/ETH",
    "address": "0XC7A87B0F491C1A043EB5315281163556B7F36F7F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BBLC",
        "decimals": 18,
        "address": "0X5B8C023D162EE3661CB9740EC271B8BD261767F3"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BBLC",
        "decimals": 18,
        "address": "0X5B8C023D162EE3661CB9740EC271B8BD261767F3"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X167DE3887EDEBE5012544373C5871481BD95CC4E": {
    "name": "Curve.fi Factory Crypto Pool: RAI/LUSD-4",
    "address": "0X167DE3887EDEBE5012544373C5871481BD95CC4E",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0X1631D0E588D475CEE4BE0F51B7410DAAAABD7034": {
    "name": "Curve.fi Factory Crypto Pool: ETH/FRAX",
    "address": "0X1631D0E588D475CEE4BE0F51B7410DAAAABD7034",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0X372463C1DFE3A9C269C2C5EB76A86021C637D0F4": {
    "name": "Curve.fi Factory Crypto Pool: Bombala Shares",
    "address": "0X372463C1DFE3A9C269C2C5EB76A86021C637D0F4",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BBLC",
        "decimals": 18,
        "address": "0X5B8C023D162EE3661CB9740EC271B8BD261767F3"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BBLC",
        "decimals": 18,
        "address": "0X5B8C023D162EE3661CB9740EC271B8BD261767F3"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X5538E48BFE47749D2540D3CBE83FD50465BCB6C3": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI-5",
    "address": "0X5538E48BFE47749D2540D3CBE83FD50465BCB6C3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X5FBA071AD473265DF860271998E45FDB3D3E5812": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI-6",
    "address": "0X5FBA071AD473265DF860271998E45FDB3D3E5812",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X96A3F551C99797998DC33E2D816D567DB61EE1C2": {
    "name": "Curve.fi Factory Crypto Pool: RAI/USDC",
    "address": "0X96A3F551C99797998DC33E2D816D567DB61EE1C2",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XF039050DC36FD59FF1117B14BFDFF92DFA9DE9FC": {
    "name": "Curve.fi Factory Crypto Pool: DUCK/USDP",
    "address": "0XF039050DC36FD59FF1117B14BFDFF92DFA9DE9FC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DUCK",
        "decimals": 18,
        "address": "0X92E187A03B6CD19CB6AF293BA17F2745FD2357D5"
      },
      "1": {
        "index": 1,
        "name": "USDP",
        "decimals": 18,
        "address": "0X1456688345527BE1F37E9E627DA0837D6F08C925"
      }
    },
    "underlyingCoins": null
  },
  "0XB49B238AB6106216DC26854ED3A009EAC1C419A9": {
    "name": "Curve.fi Factory Crypto Pool: DUCK/3CRV",
    "address": "0XB49B238AB6106216DC26854ED3A009EAC1C419A9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DUCK",
        "decimals": 18,
        "address": "0X92E187A03B6CD19CB6AF293BA17F2745FD2357D5"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DUCK",
        "decimals": 18,
        "address": "0X92E187A03B6CD19CB6AF293BA17F2745FD2357D5"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFEB0784F5D0940686143B3A025AF731EE6A81197": {
    "name": "Curve.fi Factory Crypto Pool: DUCK/USDT",
    "address": "0XFEB0784F5D0940686143B3A025AF731EE6A81197",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DUCK",
        "decimals": 18,
        "address": "0X92E187A03B6CD19CB6AF293BA17F2745FD2357D5"
      },
      "1": {
        "index": 1,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    },
    "underlyingCoins": null
  },
  "0XDCB11E81C8B8A1E06BF4B50D4F6F3BB31F7478C3": {
    "name": "Curve.fi Factory Crypto Pool: DCHF/3CRV",
    "address": "0XDCB11E81C8B8A1E06BF4B50D4F6F3BB31F7478C3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DCHF",
        "decimals": 18,
        "address": "0X045DA4BFE02B320F4403674B3B7D121737727A36"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DCHF",
        "decimals": 18,
        "address": "0X045DA4BFE02B320F4403674B3B7D121737727A36"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBCAA09F2873F87AB4BF3A6FE97991F4BCC959E7E": {
    "name": "Curve.fi Factory Crypto Pool: RAI/DAI-7",
    "address": "0XBCAA09F2873F87AB4BF3A6FE97991F4BCC959E7E",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      }
    },
    "underlyingCoins": null
  },
  "0X6D91A9CF0DEA0CD5BCB676EB4B1F11B6DCB44188": {
    "name": "Curve.fi Factory Crypto Pool: DeFi Franc",
    "address": "0X6D91A9CF0DEA0CD5BCB676EB4B1F11B6DCB44188",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DCHF",
        "decimals": 18,
        "address": "0X045DA4BFE02B320F4403674B3B7D121737727A36"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DCHF",
        "decimals": 18,
        "address": "0X045DA4BFE02B320F4403674B3B7D121737727A36"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X7F87A8B46A662131B87D29B2316B2D9CBD7E3B02": {
    "name": "Curve.fi Factory Crypto Pool: DeFi Franc",
    "address": "0X7F87A8B46A662131B87D29B2316B2D9CBD7E3B02",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DCHF",
        "decimals": 18,
        "address": "0X045DA4BFE02B320F4403674B3B7D121737727A36"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DCHF",
        "decimals": 18,
        "address": "0X045DA4BFE02B320F4403674B3B7D121737727A36"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XEC5FFEF96C3EDEDE587DB2EFA3AB4DEEC414CE8F": {
    "name": "Curve.fi Factory Crypto Pool: bll",
    "address": "0XEC5FFEF96C3EDEDE587DB2EFA3AB4DEEC414CE8F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0X76F7774139BF0097D2882C41AF5A37717E3641A7"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0X52F05C70D86662204C7222C35000747B177C393A": {
    "name": "Curve.fi Factory Crypto Pool: bllc",
    "address": "0X52F05C70D86662204C7222C35000747B177C393A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0X76F7774139BF0097D2882C41AF5A37717E3641A7"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0X76F7774139BF0097D2882C41AF5A37717E3641A7"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X0BE1596AA8000FF283807F276CED12C21297CF8F": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X0BE1596AA8000FF283807F276CED12C21297CF8F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9685AAB3B578C55CE206A312095FC1FA73609E38": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X9685AAB3B578C55CE206A312095FC1FA73609E38",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XB91EBF5F6A79A43927F7FA0548409C7821542D0C": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0XB91EBF5F6A79A43927F7FA0548409C7821542D0C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9D377EC646C10E99E08B5200F651F5A743E3EA78": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X9D377EC646C10E99E08B5200F651F5A743E3EA78",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XA0C7CAD1D6DDB3EB2949243A0F720F4A291367DD": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0XA0C7CAD1D6DDB3EB2949243A0F720F4A291367DD",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XAE2029F60DBDF41D14C8C0290F2B68E164360419": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0XAE2029F60DBDF41D14C8C0290F2B68E164360419",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XBE084CF7DB8FDFD6F17C041F958ABCCAF8C76603": {
    "name": "Curve.fi Factory Crypto Pool: RAI/Meta3Crv",
    "address": "0XBE084CF7DB8FDFD6F17C041F958ABCCAF8C76603",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X4885AAA80E0ED42C5C76ACF523ADAF5066FD011F": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X4885AAA80E0ED42C5C76ACF523ADAF5066FD011F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X141BE5D90C27DC5C9199A57CB828CA3CBD2C2D94": {
    "name": "Curve.fi Factory Crypto Pool: bll3",
    "address": "0X141BE5D90C27DC5C9199A57CB828CA3CBD2C2D94",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0XB185A192E5E9CE5EA2204DCFEE0F4467008D4216"
      },
      "1": {
        "index": 1,
        "name": "LUSD3CRV-f",
        "decimals": 18,
        "address": "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0XB185A192E5E9CE5EA2204DCFEE0F4467008D4216"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "2": {
        "index": 2,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    }
  },
  "0XF4A3CCA34470B5BA21E2BB1ED365ACF68B4D4598": {
    "name": "Curve.fi Factory Crypto Pool: bll3",
    "address": "0XF4A3CCA34470B5BA21E2BB1ED365ACF68B4D4598",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0X1E2391A261217C93D09FF3AE9AB1903EA237BDA8"
      },
      "1": {
        "index": 1,
        "name": "LUSD3CRV-f",
        "decimals": 18,
        "address": "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "bl",
        "decimals": 18,
        "address": "0X1E2391A261217C93D09FF3AE9AB1903EA237BDA8"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "2": {
        "index": 2,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    }
  },
  "0X9A7168EA321121A8FAF52147FC596B73E07AB8A3": {
    "name": "Curve.fi Factory Crypto Pool: bLUSD_LUSD3CRV",
    "address": "0X9A7168EA321121A8FAF52147FC596B73E07AB8A3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X20658291677A29EFDDFD0E303F8B23113D837CC7"
      },
      "1": {
        "index": 1,
        "name": "LUSD3CRV-f",
        "decimals": 18,
        "address": "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X20658291677A29EFDDFD0E303F8B23113D837CC7"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "2": {
        "index": 2,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    }
  },
  "0X9735D6530B0609BA8340ECF61FEACD922468C1E1": {
    "name": "Curve.fi Factory Crypto Pool: bLUSD_LUSD3CRV",
    "address": "0X9735D6530B0609BA8340ECF61FEACD922468C1E1",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X20658291677A29EFDDFD0E303F8B23113D837CC7"
      },
      "1": {
        "index": 1,
        "name": "LUSD3CRV-f",
        "decimals": 18,
        "address": "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X20658291677A29EFDDFD0E303F8B23113D837CC7"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "2": {
        "index": 2,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    }
  },
  "0X74ED5D42203806C8CDCF2F04CA5F60DC777B901C": {
    "name": "Curve.fi Factory Crypto Pool: bLUSD_LUSD3CRV",
    "address": "0X74ED5D42203806C8CDCF2F04CA5F60DC777B901C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "bLUSD",
        "decimals": 18,
        "address": "0XB9D7DDDCA9A4AC480991865EFEF82E01273F79C3"
      },
      "1": {
        "index": 1,
        "name": "LUSD3CRV-f",
        "decimals": 18,
        "address": "0XED279FDD11CA84BEEF15AF5D39BB4D4BEE23F0CA"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "bLUSD",
        "decimals": 18,
        "address": "0XB9D7DDDCA9A4AC480991865EFEF82E01273F79C3"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "2": {
        "index": 2,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    }
  },
  "0X3E3C6C7DB23CDDEF80B694679AAF1BCD9517D0AE": {
    "name": "Curve.fi Factory Crypto Pool: SDT / FRAXBP",
    "address": "0X3E3C6C7DB23CDDEF80B694679AAF1BCD9517D0AE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SDT",
        "decimals": 18,
        "address": "0X73968B9A57C6E53D41345FD57A6E6AE27D6CDB2F"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SDT",
        "decimals": 18,
        "address": "0X73968B9A57C6E53D41345FD57A6E6AE27D6CDB2F"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X6A6283AB6E31C2AEC3FA08697A8F806B740660B2": {
    "name": "Curve.fi Factory Crypto Pool: RSR+FRAX/USDC (FRAXBP)",
    "address": "0X6A6283AB6E31C2AEC3FA08697A8F806B740660B2",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RSR",
        "decimals": 18,
        "address": "0X320623B8E4FF03373931769A31FC52A4E78B5D70"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RSR",
        "decimals": 18,
        "address": "0X320623B8E4FF03373931769A31FC52A4E78B5D70"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X656111CA1E66678E7D300D8C4FFA4E4D21CECC55": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X656111CA1E66678E7D300D8C4FFA4E4D21CECC55",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9E73249AA5C3DC4223549BDF154215B997E0CFEC": {
    "name": "Curve.fi Factory Crypto Pool: test",
    "address": "0X9E73249AA5C3DC4223549BDF154215B997E0CFEC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XC170ECB895B28A7EFF0109F8C072AE742B1DCB69": {
    "name": "Curve.fi Factory Crypto Pool: CLEV/ETH",
    "address": "0XC170ECB895B28A7EFF0109F8C072AE742B1DCB69",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CLEV",
        "decimals": 18,
        "address": "0X72953A5C32413614D24C29C84A66AE4B59581BBF"
      }
    },
    "underlyingCoins": null
  },
  "0X342D1C4AA76EA6F5E5871B7F11A019A0EB713A4F": {
    "name": "Curve.fi Factory Crypto Pool: CLEV/ETH",
    "address": "0X342D1C4AA76EA6F5E5871B7F11A019A0EB713A4F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CLEV",
        "decimals": 18,
        "address": "0X72953A5C32413614D24C29C84A66AE4B59581BBF"
      }
    },
    "underlyingCoins": null
  },
  "0XAEB6F2ABE64EE5ED210E8AE33B1F0F5E4B3E28FC": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/3pool",
    "address": "0XAEB6F2ABE64EE5ED210E8AE33B1F0F5E4B3E28FC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X96F34BB82FCA57E475E6AD218B0DD0C5C78DF423": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/3pool",
    "address": "0X96F34BB82FCA57E475E6AD218B0DD0C5C78DF423",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X17374BDBCB79AE20CB947640915316D379A7F222": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/3pool",
    "address": "0X17374BDBCB79AE20CB947640915316D379A7F222",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XE661672521C77CA87DBB2EAC816D2CCF86197281": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/ETH",
    "address": "0XE661672521C77CA87DBB2EAC816D2CCF86197281",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X19A0CA9A0DC2A5034F47DCC164169CFFD7ED2410": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/ETH",
    "address": "0X19A0CA9A0DC2A5034F47DCC164169CFFD7ED2410",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X1628E0A42682570C815DA52480734A8C2A2CA2DD": {
    "name": "Curve.fi Factory Crypto Pool: Schmeckle^2",
    "address": "0X1628E0A42682570C815DA52480734A8C2A2CA2DD",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SMKL",
        "decimals": 0,
        "address": "0XCDD12A15DF22FAAA77D3678EDA2236B1FA3800EB"
      },
      "1": {
        "index": 1,
        "name": "SMKL",
        "decimals": 0,
        "address": "0X28A7190805C3646F7BF63B00C569F7BF06145268"
      }
    },
    "underlyingCoins": null
  },
  "0X4E6706889D519BA6E6CED330D76582F875DF3C9C": {
    "name": "Curve.fi Factory Crypto Pool: Schmeckles / ETH",
    "address": "0X4E6706889D519BA6E6CED330D76582F875DF3C9C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SMKL",
        "decimals": 0,
        "address": "0X28A7190805C3646F7BF63B00C569F7BF06145268"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X29B2178F5F9FB4F775A2F1A7FEA685FFBA0FAE32": {
    "name": "Curve.fi Factory Crypto Pool: EtherTulip.com",
    "address": "0X29B2178F5F9FB4F775A2F1A7FEA685FFBA0FAE32",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PETAL",
        "decimals": 18,
        "address": "0X2E60F6C4CA05BC55A8E577DEEBD61FCE727C4A6E"
      },
      "1": {
        "index": 1,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      }
    },
    "underlyingCoins": null
  },
  "0XD304918B2EF48A1184918E575CED08D3D04C37AD": {
    "name": "Curve.fi Factory Crypto Pool: EtherTulip.com",
    "address": "0XD304918B2EF48A1184918E575CED08D3D04C37AD",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PETAL",
        "decimals": 18,
        "address": "0X2E60F6C4CA05BC55A8E577DEEBD61FCE727C4A6E"
      },
      "1": {
        "index": 1,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      }
    },
    "underlyingCoins": null
  },
  "0X7193D845F8991E27FFDCE54E78137DE036A6F743": {
    "name": "Curve.fi Factory Crypto Pool: PETAL/RAI",
    "address": "0X7193D845F8991E27FFDCE54E78137DE036A6F743",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PETAL",
        "decimals": 18,
        "address": "0X2E60F6C4CA05BC55A8E577DEEBD61FCE727C4A6E"
      },
      "1": {
        "index": 1,
        "name": "RAI",
        "decimals": 18,
        "address": "0X03AB458634910AAD20EF5F1C8EE96F1D6AC54919"
      }
    },
    "underlyingCoins": null
  },
  "0XF2F12B364F614925AB8E2C8BFC606EDB9282BA09": {
    "name": "Curve.fi Factory Crypto Pool: Concentrator CTR/ETH",
    "address": "0XF2F12B364F614925AB8E2C8BFC606EDB9282BA09",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "CTR",
        "decimals": 18,
        "address": "0XB3AD645DB386D7F6D753B2B9C3F4B853DA6890B8"
      }
    },
    "underlyingCoins": null
  },
  "0XE6BBAF0B55AE430354B87A3F592DD5C8AD7A5E79": {
    "name": "Curve.fi Factory Crypto Pool: deth",
    "address": "0XE6BBAF0B55AE430354B87A3F592DD5C8AD7A5E79",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DIANA",
        "decimals": 18,
        "address": "0X04545C88F0839B7FFFBFFD328C38C1FC574ED6F1"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0XF4E7C1E5A21F68DF8D6A331AFFBEEBC313ED24D1": {
    "name": "Curve.fi Factory Crypto Pool: STGFRAXBP",
    "address": "0XF4E7C1E5A21F68DF8D6A331AFFBEEBC313ED24D1",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X3CC1B5DCE81A810080890FB8ADAD48F8EAA8C210": {
    "name": "Curve.fi Factory Crypto Pool: Goldfinch FIDU/FRAXBP",
    "address": "0X3CC1B5DCE81A810080890FB8ADAD48F8EAA8C210",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FIDU",
        "decimals": 18,
        "address": "0X6A445E9F40E0B97C92D0B8A3366CEF1D67F700BF"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "FIDU",
        "decimals": 18,
        "address": "0X6A445E9F40E0B97C92D0B8A3366CEF1D67F700BF"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XE01A9ECDB0AAABE2F12A25A0D289480DEBF09E89": {
    "name": "Curve.fi Factory Crypto Pool: ELONX/ETH Pool",
    "address": "0XE01A9ECDB0AAABE2F12A25A0D289480DEBF09E89",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ELONX",
        "decimals": 0,
        "address": "0X815B4CE34FAC32B951BD26EA85901E3B834204B6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ELONX",
        "decimals": 0,
        "address": "0X815B4CE34FAC32B951BD26EA85901E3B834204B6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X81F08B6B325B7266190BBDE531311F31BB3D82DE": {
    "name": "Curve.fi Factory Crypto Pool: ELONX/ETH Pool",
    "address": "0X81F08B6B325B7266190BBDE531311F31BB3D82DE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ELONX",
        "decimals": 0,
        "address": "0X815B4CE34FAC32B951BD26EA85901E3B834204B6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ELONX",
        "decimals": 0,
        "address": "0X815B4CE34FAC32B951BD26EA85901E3B834204B6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X808DB6E464279C6A77A1164E0B34D64BD6FB526E": {
    "name": "Curve.fi Factory Crypto Pool: JPEG/pETH",
    "address": "0X808DB6E464279C6A77A1164E0B34D64BD6FB526E",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "JPEG",
        "decimals": 18,
        "address": "0XE80C0CD204D654CEBE8DD64A4857CAB6BE8345A3"
      },
      "1": {
        "index": 1,
        "name": "pETH",
        "decimals": 18,
        "address": "0X836A808D4828586A69364065A1E064609F5078C7"
      }
    },
    "underlyingCoins": null
  },
  "0XFC1E8BF3E81383EF07BE24C3FD146745719DE48D": {
    "name": "Curve.fi Factory Crypto Pool: OHM/FRAXBP",
    "address": "0XFC1E8BF3E81383EF07BE24C3FD146745719DE48D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OHM",
        "decimals": 9,
        "address": "0X64AA3364F17A4D01C6F1751FD97C2BD3D7E7F1D5"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "OHM",
        "decimals": 9,
        "address": "0X64AA3364F17A4D01C6F1751FD97C2BD3D7E7F1D5"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X6575AD173801F2D58961B3D7F3628C72346EA84A": {
    "name": "Curve.fi Factory Crypto Pool: GBPT",
    "address": "0X6575AD173801F2D58961B3D7F3628C72346EA84A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GBPT",
        "decimals": 18,
        "address": "0X86B4DBE5D203E634A12364C0E428FA242A3FBA98"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GBPT",
        "decimals": 18,
        "address": "0X86B4DBE5D203E634A12364C0E428FA242A3FBA98"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XEE0DCAF26AA271FF18BB9B9B282620A7CE79F6BA": {
    "name": "Curve.fi Factory Crypto Pool: ETH/BTC",
    "address": "0XEE0DCAF26AA271FF18BB9B9B282620A7CE79F6BA",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      }
    },
    "underlyingCoins": null
  },
  "0X867FE27FC2462CFF8890B54DFD64E6D42A9D1AC8": {
    "name": "Curve.fi Factory Crypto Pool: STG/FRAX-USDC",
    "address": "0X867FE27FC2462CFF8890B54DFD64E6D42A9D1AC8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0XAF5191B0DE278C7286D6C7CC6AB6BB8A73BA2CD6"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9488317BAD789EF9AE5B70D3EF2FC12DF717FBA2": {
    "name": "Curve.fi Factory Crypto Pool: liq_BTC_UNISWAP",
    "address": "0X9488317BAD789EF9AE5B70D3EF2FC12DF717FBA2",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "1": {
        "index": 1,
        "name": "UNI",
        "decimals": 18,
        "address": "0X1F9840A85D5AF5BF1D1762F925BDADDC4201F984"
      }
    },
    "underlyingCoins": null
  },
  "0X9730E45CA84076C0A9DF80A4F2058C8379EA3ECE": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_UNISWAP",
    "address": "0X9730E45CA84076C0A9DF80A4F2058C8379EA3ECE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "UNI",
        "decimals": 18,
        "address": "0X1F9840A85D5AF5BF1D1762F925BDADDC4201F984"
      }
    },
    "underlyingCoins": null
  },
  "0X3767FF3D26B7F9FB1870AE7EBFC7E05F585EECFE": {
    "name": "Curve.fi Factory Crypto Pool: liq_WBTC_UNISWAP",
    "address": "0X3767FF3D26B7F9FB1870AE7EBFC7E05F585EECFE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "1": {
        "index": 1,
        "name": "UNI",
        "decimals": 18,
        "address": "0X1F9840A85D5AF5BF1D1762F925BDADDC4201F984"
      }
    },
    "underlyingCoins": null
  },
  "0XD0A7A9C7F2C148DC5399D89220345B50D6D13CC0": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_UNISWAP",
    "address": "0XD0A7A9C7F2C148DC5399D89220345B50D6D13CC0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "UNI",
        "decimals": 18,
        "address": "0X1F9840A85D5AF5BF1D1762F925BDADDC4201F984"
      }
    },
    "underlyingCoins": null
  },
  "0X5A784386DBE8E9188B7262E85DCAF912BCDDA441": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_MATIC",
    "address": "0X5A784386DBE8E9188B7262E85DCAF912BCDDA441",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      }
    },
    "underlyingCoins": null
  },
  "0X7DCEAF64115ED0E3D5B3E18D0896004EAEB58B4B": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_MATIC",
    "address": "0X7DCEAF64115ED0E3D5B3E18D0896004EAEB58B4B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      }
    },
    "underlyingCoins": null
  },
  "0XE94A145A797622FE38A35036729229FC6B3132CB": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_AAVE",
    "address": "0XE94A145A797622FE38A35036729229FC6B3132CB",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      }
    },
    "underlyingCoins": null
  },
  "0XC77103E44914A9E5E30B9F58CD48E990B22FB587": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_AAVE",
    "address": "0XC77103E44914A9E5E30B9F58CD48E990B22FB587",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      }
    },
    "underlyingCoins": null
  },
  "0X450A0975DBF787DF053D20FA3B57E5B362960102": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_LINK",
    "address": "0X450A0975DBF787DF053D20FA3B57E5B362960102",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      }
    },
    "underlyingCoins": null
  },
  "0X7DC87E4E57C0CA6060374E0CE2A1CFFB69EBC45A": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_LINK",
    "address": "0X7DC87E4E57C0CA6060374E0CE2A1CFFB69EBC45A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      }
    },
    "underlyingCoins": null
  },
  "0XFAF83F9D84FAF25ED4ECBC1857F6AADC3C1977C3": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_FANTOM",
    "address": "0XFAF83F9D84FAF25ED4ECBC1857F6AADC3C1977C3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FTM",
        "decimals": 18,
        "address": "0X4E15361FD6B4BB609FA63C81A2BE19D873717870"
      },
      "1": {
        "index": 1,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      }
    },
    "underlyingCoins": null
  },
  "0X1A8E46721F754660DC8B2EF623E7408856A63CAC": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_FANTOM",
    "address": "0X1A8E46721F754660DC8B2EF623E7408856A63CAC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      }
    },
    "underlyingCoins": null
  },
  "0X740F335D454AD0DB55F88C02CCCD74ED84F72A3F": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_FANTOM",
    "address": "0X740F335D454AD0DB55F88C02CCCD74ED84F72A3F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "FTM",
        "decimals": 18,
        "address": "0X4E15361FD6B4BB609FA63C81A2BE19D873717870"
      }
    },
    "underlyingCoins": null
  },
  "0X3272C5D11644DEBB2E9D04C302796EBE8DF35457": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_FANTOM",
    "address": "0X3272C5D11644DEBB2E9D04C302796EBE8DF35457",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "FTM",
        "decimals": 18,
        "address": "0X4E15361FD6B4BB609FA63C81A2BE19D873717870"
      }
    },
    "underlyingCoins": null
  },
  "0X970CF34837AA1FF611410533B69D708CBA70739D": {
    "name": "Curve.fi Factory Crypto Pool: liq_CRV_BTT",
    "address": "0X970CF34837AA1FF611410533B69D708CBA70739D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "BTT",
        "decimals": 18,
        "address": "0XC669928185DBCE49D2230CC9B0979BE6DC797957"
      }
    },
    "underlyingCoins": null
  },
  "0X183F03C8CE2A24F388A4059ADEDD05C902650174": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_BTT",
    "address": "0X183F03C8CE2A24F388A4059ADEDD05C902650174",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "BTT",
        "decimals": 18,
        "address": "0XC669928185DBCE49D2230CC9B0979BE6DC797957"
      }
    },
    "underlyingCoins": null
  },
  "0X92971A40FA0F6DD573A0F996B62DE497ECC4EF6C": {
    "name": "Curve.fi Factory Crypto Pool: liq_ETH_UNISWAP",
    "address": "0X92971A40FA0F6DD573A0F996B62DE497ECC4EF6C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "UNI",
        "decimals": 18,
        "address": "0X1F9840A85D5AF5BF1D1762F925BDADDC4201F984"
      }
    },
    "underlyingCoins": null
  },
  "0X2F0E2D1023FD3757AA58BE7020B0FC58C6A45187": {
    "name": "Curve.fi Factory Crypto Pool: PAIR_ETH_AAVE",
    "address": "0X2F0E2D1023FD3757AA58BE7020B0FC58C6A45187",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      }
    },
    "underlyingCoins": null
  },
  "0X49EAF5E4AC618C968DDCF588C000302F9BC3312D": {
    "name": "Curve.fi Factory Crypto Pool: PAIR_CRV_AAVE",
    "address": "0X49EAF5E4AC618C968DDCF588C000302F9BC3312D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      }
    },
    "underlyingCoins": null
  },
  "0X3991D59428A8F9F5C13526D92D58B23DA14230A0": {
    "name": "Curve.fi Factory Crypto Pool: PAIR_ETH_SNX",
    "address": "0X3991D59428A8F9F5C13526D92D58B23DA14230A0",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "SNX",
        "decimals": 18,
        "address": "0XC011A73EE8576FB46F5E1C5751CA3B9FE0AF2A6F"
      }
    },
    "underlyingCoins": null
  },
  "0X72D6658E562739267994BB16B952E543F0F92281": {
    "name": "Curve.fi Factory Crypto Pool: PAIR_CRV_SNX",
    "address": "0X72D6658E562739267994BB16B952E543F0F92281",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "SNX",
        "decimals": 18,
        "address": "0XC011A73EE8576FB46F5E1C5751CA3B9FE0AF2A6F"
      }
    },
    "underlyingCoins": null
  },
  "0X54888C0859A93E6FF679E4F9705E75BBB9557057": {
    "name": "Curve.fi Factory Crypto Pool: PAIR_CRV_MATIC",
    "address": "0X54888C0859A93E6FF679E4F9705E75BBB9557057",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      }
    },
    "underlyingCoins": null
  },
  "0X6BFE880ED1D639BF80167B93CC9C56A39C1BA2DC": {
    "name": "Curve.fi Factory Crypto Pool: PAIR_ETH_MATIC",
    "address": "0X6BFE880ED1D639BF80167B93CC9C56A39C1BA2DC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      }
    },
    "underlyingCoins": null
  },
  "0XB4018CB02E264C3FCFE0F21A1F5CFBCAABA9F61F": {
    "name": "Curve.fi Factory Crypto Pool: ARTH/USDC",
    "address": "0XB4018CB02E264C3FCFE0F21A1F5CFBCAABA9F61F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH",
        "decimals": 18,
        "address": "0X8CC0F052FFF7EAD7F2EDCCCAC895502E884A8A71"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X7878A141103D93CAC0D1010C48F999975B347138": {
    "name": "Curve.fi Factory Crypto Pool: DARTH_1",
    "address": "0X7878A141103D93CAC0D1010C48F999975B347138",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yCRV",
        "decimals": 18,
        "address": "0XFCC5C47BE19D06BF83EB04298B026F81069FF65B"
      }
    },
    "underlyingCoins": null
  },
  "0X68A39B6EB2F9AD3A5E58808CDC4907DA260E44C8": {
    "name": "Curve.fi Factory Crypto Pool: DARTH2",
    "address": "0X68A39B6EB2F9AD3A5E58808CDC4907DA260E44C8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yCRV",
        "decimals": 18,
        "address": "0XFCC5C47BE19D06BF83EB04298B026F81069FF65B"
      }
    },
    "underlyingCoins": null
  },
  "0XED46F331F85BAD38BD99B60938C700C1A92FD940": {
    "name": "Curve.fi Factory Crypto Pool: DARTH3",
    "address": "0XED46F331F85BAD38BD99B60938C700C1A92FD940",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yCRV",
        "decimals": 18,
        "address": "0XFCC5C47BE19D06BF83EB04298B026F81069FF65B"
      }
    },
    "underlyingCoins": null
  },
  "0XA246ED2954539F2EAA8E1FB72E02637F2A402AB7": {
    "name": "Curve.fi Factory Crypto Pool: TRYB/FRAXBP",
    "address": "0XA246ED2954539F2EAA8E1FB72E02637F2A402AB7",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XD18712370F840B1563D85EA6BD221F53D766FFA8": {
    "name": "Curve.fi Factory Crypto Pool: Curve Test/ETH",
    "address": "0XD18712370F840B1563D85EA6BD221F53D766FFA8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "Test",
        "decimals": 18,
        "address": "0X3EF73FDC7EFF65EDF3FB8E8B10B2FC5F8B3AE535"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X9F13078A313CAF45146BBED973115DFB5DE1D270": {
    "name": "Curve.fi Factory Crypto Pool: Curve Test/ETH",
    "address": "0X9F13078A313CAF45146BBED973115DFB5DE1D270",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "Test",
        "decimals": 18,
        "address": "0X3EF73FDC7EFF65EDF3FB8E8B10B2FC5F8B3AE535"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X0E9B5B092CAD6F1C5E6BC7F89FFE1ABB5C95F1C2": {
    "name": "Curve.fi Factory Crypto Pool: Curve GEAR/ETH",
    "address": "0X0E9B5B092CAD6F1C5E6BC7F89FFE1ABB5C95F1C2",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GEAR",
        "decimals": 18,
        "address": "0XBA3335588D9403515223F109EDC4EB7269A9AB5D"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X6868EB3FCEFE1684486F601D2DC10BBF0C4EEB8B": {
    "name": "Curve.fi Factory Crypto Pool: TRYB / 3pool",
    "address": "0X6868EB3FCEFE1684486F601D2DC10BBF0C4EEB8B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X53DFCF5CA1E1D5311DB29F585D56A9A9DCC2441B": {
    "name": "Curve.fi Factory Crypto Pool: TRYB/USD (3crv)",
    "address": "0X53DFCF5CA1E1D5311DB29F585D56A9A9DCC2441B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X51BCBA35E5FA277592CEA83AD14408A0963B86F8": {
    "name": "Curve.fi Factory Crypto Pool: TRYB/USD (3crv)",
    "address": "0X51BCBA35E5FA277592CEA83AD14408A0963B86F8",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TRYB",
        "decimals": 6,
        "address": "0X2C537E5624E4AF88A7AE4060C022609376C8D0EB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X4CC9385D9909D2BF529A4C1E847DC4EE01F4927A": {
    "name": "Curve.fi Factory Crypto Pool: EURS/FRAXBP",
    "address": "0X4CC9385D9909D2BF529A4C1E847DC4EE01F4927A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X675993FB30A2D58CD4D29D15F89B4BE9CA8765AE": {
    "name": "Curve.fi Factory Crypto Pool: EURS/FRAXBP",
    "address": "0X675993FB30A2D58CD4D29D15F89B4BE9CA8765AE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X6C5ACD4C044DEFE22FEC287D1362B1AF5ED16F7A": {
    "name": "Curve.fi Factory Crypto Pool: CPI/3crv",
    "address": "0X6C5ACD4C044DEFE22FEC287D1362B1AF5ED16F7A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CPI",
        "decimals": 18,
        "address": "0X8BB08042C06FA0FC26CD2474C5F0C03A1056AD2F"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "CPI",
        "decimals": 18,
        "address": "0X8BB08042C06FA0FC26CD2474C5F0C03A1056AD2F"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X514747FCAEC6A72D1DC5FCC1CBB6DD21FBAD5427": {
    "name": "Curve.fi Factory Crypto Pool: XTP/WBTC",
    "address": "0X514747FCAEC6A72D1DC5FCC1CBB6DD21FBAD5427",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "1": {
        "index": 1,
        "name": "XTP",
        "decimals": 18,
        "address": "0X6368E1E18C4C419DDFC608A0BED1CCB87B9250FC"
      }
    },
    "underlyingCoins": null
  },
  "0X0F74DC493F1076247AEFD5BA8DA677440D3BA8A4": {
    "name": "Curve.fi Factory Crypto Pool: pETHfrxETH",
    "address": "0X0F74DC493F1076247AEFD5BA8DA677440D3BA8A4",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pETH",
        "decimals": 18,
        "address": "0X836A808D4828586A69364065A1E064609F5078C7"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X593807B540D2584FD16433DC609F869421577173": {
    "name": "Curve.fi Factory Crypto Pool: CNC/3POOL Curve LP",
    "address": "0X593807B540D2584FD16433DC609F869421577173",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CNC",
        "decimals": 18,
        "address": "0X9AE380F0272E2162340A5BB646C354271C0F5CFC"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "CNC",
        "decimals": 18,
        "address": "0X9AE380F0272E2162340A5BB646C354271C0F5CFC"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X50E29CD32BEDF8ADF6C58CF0F8F2C64CB98C62AC": {
    "name": "Curve.fi Factory Crypto Pool: USV/FRAXBP",
    "address": "0X50E29CD32BEDF8ADF6C58CF0F8F2C64CB98C62AC",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USV",
        "decimals": 18,
        "address": "0X6BAD6A9BCFDA3FD60DA6834ACE5F93B8CFED9598"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USV",
        "decimals": 18,
        "address": "0X6BAD6A9BCFDA3FD60DA6834ACE5F93B8CFED9598"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XE02D418E3D522611E88CE6070A9037ECF0B16073": {
    "name": "Curve.fi Factory Crypto Pool: LDO/ETH",
    "address": "0XE02D418E3D522611E88CE6070A9037ECF0B16073",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "LDO",
        "decimals": 18,
        "address": "0X5A98FCBEA516CF06857215779FD812CA3BEF1B32"
      }
    },
    "underlyingCoins": null
  },
  "0X9409280DC1E6D33AB7A8C6EC03E5763FB61772B5": {
    "name": "Curve.fi Factory Crypto Pool: LDO/ETH",
    "address": "0X9409280DC1E6D33AB7A8C6EC03E5763FB61772B5",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "LDO",
        "decimals": 18,
        "address": "0X5A98FCBEA516CF06857215779FD812CA3BEF1B32"
      }
    },
    "underlyingCoins": null
  },
  "0XB5AE2A6B084F59FF9EF6B2E79302A1878306D58C": {
    "name": "Curve.fi Factory Crypto Pool: OGV/ETH",
    "address": "0XB5AE2A6B084F59FF9EF6B2E79302A1878306D58C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OGV",
        "decimals": 18,
        "address": "0X9C354503C38481A7A7A51629142963F98ECC12D0"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X1440AE2288345A78E753D0B1D679880031BCE653": {
    "name": "Curve.fi Factory Crypto Pool: Paraspace Supplied ETH/APE",
    "address": "0X1440AE2288345A78E753D0B1D679880031BCE653",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pWETH",
        "decimals": 18,
        "address": "0XAA4B6506493582F169C9329AC0DA99FFF23C2911"
      },
      "1": {
        "index": 1,
        "name": "pcAPE",
        "decimals": 18,
        "address": "0XDDDE38696FBE5D11497D72D8801F651642D62353"
      }
    },
    "underlyingCoins": null
  },
  "0X17CD2D7C4DDF69897CC00E2943532C27259257C5": {
    "name": "Curve.fi Factory Crypto Pool: Paraspace Supplied cAPE / ETH",
    "address": "0X17CD2D7C4DDF69897CC00E2943532C27259257C5",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "pcAPE",
        "decimals": 18,
        "address": "0XDDDE38696FBE5D11497D72D8801F651642D62353"
      }
    },
    "underlyingCoins": null
  },
  "0X098B69922A18639457B8490DB051B8854D33D2F3": {
    "name": "Curve.fi Factory Crypto Pool: FXSfrxETH",
    "address": "0X098B69922A18639457B8490DB051B8854D33D2F3",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XBDFAE7D2CF2E69E27B75A5287ECD3808F62B5A76": {
    "name": "Curve.fi Factory Crypto Pool: ALD/ETH",
    "address": "0XBDFAE7D2CF2E69E27B75A5287ECD3808F62B5A76",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "ALD",
        "decimals": 18,
        "address": "0XB26C4B3CA601136DAF98593FEAEFF9E0CA702A8D"
      }
    },
    "underlyingCoins": null
  },
  "0X0F3159811670C117C372428D4E69AC32325E4D0F": {
    "name": "Curve.fi Factory Crypto Pool: Rocketpool rETH/ETH",
    "address": "0X0F3159811670C117C372428D4E69AC32325E4D0F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "rETH",
        "decimals": 18,
        "address": "0XAE78736CD615F374D3085123A210448E74FC6393"
      }
    },
    "underlyingCoins": null
  },
  "0XCA6CF273AB17073248654363AA00B102540AA5EA": {
    "name": "Curve.fi Factory Crypto Pool: Curve.fi LYRA/wsLYRA",
    "address": "0XCA6CF273AB17073248654363AA00B102540AA5EA",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LYRA",
        "decimals": 18,
        "address": "0X01BA67AAC7F75F647D94220CC98FB30FCC5105BF"
      },
      "1": {
        "index": 1,
        "name": "wsLYRA",
        "decimals": 18,
        "address": "0X8D8802C567E70CCADAF730263AECC60A91AC163D"
      }
    },
    "underlyingCoins": null
  },
  "0XECB16535477F9CC6DB8D8EA70B36D5020499D91C": {
    "name": "Curve.fi Factory Crypto Pool: STATE / WBTC",
    "address": "0XECB16535477F9CC6DB8D8EA70B36D5020499D91C",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STATE",
        "decimals": 18,
        "address": "0X00C2999C8B2ADF4ABC835CC63209533973718EB1"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      }
    },
    "underlyingCoins": null
  },
  "0X408CBC416DBF4168143B011BF5C297DA82CE03ED": {
    "name": "Curve.fi Factory Crypto Pool: CVX_LOOKS_Pair",
    "address": "0X408CBC416DBF4168143B011BF5C297DA82CE03ED",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LOOKS",
        "decimals": 18,
        "address": "0XF4D2888D29D722226FAFA5D9B24F9164C092421E"
      },
      "1": {
        "index": 1,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      }
    },
    "underlyingCoins": null
  },
  "0X9A81F5A6EA7E60632F659F3F4E772BA977D80BD5": {
    "name": "Curve.fi Factory Crypto Pool: VEE/USDC",
    "address": "0X9A81F5A6EA7E60632F659F3F4E772BA977D80BD5",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "VEE",
        "decimals": 18,
        "address": "0X7616113782AADAB041D7B10D474F8A0C04EFF258"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X5133DA6BA0474368AD0398D953C8D31C1B75B82B": {
    "name": "Curve.fi Factory Crypto Pool: FXS/frxETH",
    "address": "0X5133DA6BA0474368AD0398D953C8D31C1B75B82B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X442F37CFD85D3F35E576AD7D63BBA7BB36FCFE4A": {
    "name": "Curve.fi Factory Crypto Pool: CRV/frxETH",
    "address": "0X442F37CFD85D3F35E576AD7D63BBA7BB36FCFE4A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X6E855D08F2984516C40C4246A385BA4A2EDFCD0A": {
    "name": "Curve.fi Factory Crypto Pool: CVX/frxETH",
    "address": "0X6E855D08F2984516C40C4246A385BA4A2EDFCD0A",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XE7C6E0A739021CDBA7AAC21B4B728779EEF974D9": {
    "name": "Curve.fi Factory Crypto Pool: rETH/frxETH",
    "address": "0XE7C6E0A739021CDBA7AAC21B4B728779EEF974D9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "rETH",
        "decimals": 18,
        "address": "0XAE78736CD615F374D3085123A210448E74FC6393"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X41EA4045DE2676727883AA0B4E43D7E32261F559": {
    "name": "Curve.fi Factory Crypto Pool:  ankrETH/frxETH",
    "address": "0X41EA4045DE2676727883AA0B4E43D7E32261F559",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ankrETH",
        "decimals": 18,
        "address": "0XE95A203B1A91A908F9B9CE46459D101078C2C3CB"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X73069892F6750CCAAABABADC54B6B6B36B3A057D": {
    "name": "Curve.fi Factory Crypto Pool: cbETH/frxETH",
    "address": "0X73069892F6750CCAAABABADC54B6B6B36B3A057D",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cbETH",
        "decimals": 18,
        "address": "0XBE9895146F7AF43049CA1C1AE358B0541EA49704"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XFDAD22C8F63B32ACA0D273D828875FCCDA3880E1": {
    "name": "Curve.fi Factory Crypto Pool: HEX/ETH",
    "address": "0XFDAD22C8F63B32ACA0D273D828875FCCDA3880E1",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "HEX",
        "decimals": 8,
        "address": "0X2B591E99AFE9F32EAA6214F7B7629768C40EEB39"
      }
    },
    "underlyingCoins": null
  },
  "0X1DF0BAE5ADD689D6BE57AC6FD18B1E4A27B4498B": {
    "name": "Curve.fi Factory Crypto Pool: INV/ETH",
    "address": "0X1DF0BAE5ADD689D6BE57AC6FD18B1E4A27B4498B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "INV",
        "decimals": 18,
        "address": "0X41D5D79431A913C4AE7D69A668ECDFE5FF9DFB68"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X2AF0A09B3421240F32953FFFB13D2D2B8D24FBAE": {
    "name": "Curve.fi Factory Crypto Pool: INV/ETH",
    "address": "0X2AF0A09B3421240F32953FFFB13D2D2B8D24FBAE",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "INV",
        "decimals": 18,
        "address": "0X41D5D79431A913C4AE7D69A668ECDFE5FF9DFB68"
      }
    },
    "underlyingCoins": null
  },
  "0X4B036A6769D5F1ABB5A249A489348389C2907334": {
    "name": "Curve.fi Factory Crypto Pool: SNT/ETH",
    "address": "0X4B036A6769D5F1ABB5A249A489348389C2907334",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SNT",
        "decimals": 18,
        "address": "0X744D70FDBE2BA4CF95131626614A1763DF805B9E"
      },
      "1": {
        "index": 1,
        "name": "ETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0XF514A54693DB2F3B4C43F78088D361B3F3A317A9": {
    "name": "Curve.fi Factory Crypto Pool: GBPT / USDC",
    "address": "0XF514A54693DB2F3B4C43F78088D361B3F3A317A9",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GBPT",
        "decimals": 18,
        "address": "0X86B4DBE5D203E634A12364C0E428FA242A3FBA98"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0X7CE013171826FEB235ED3FD4BA0171E64AC97D2B": {
    "name": "Curve.fi Factory Crypto Pool: RUBE/USD",
    "address": "0X7CE013171826FEB235ED3FD4BA0171E64AC97D2B",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RUBE",
        "decimals": 18,
        "address": "0XB97FCA5A2967ED91F36C1CBB60DBFE4C53C1DF44"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RUBE",
        "decimals": 18,
        "address": "0XB97FCA5A2967ED91F36C1CBB60DBFE4C53C1DF44"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3D2253FA44C38D9870539DC2856286AA1638467F": {
    "name": "Curve.fi Factory Crypto Pool: USDC/EUROC",
    "address": "0X3D2253FA44C38D9870539DC2856286AA1638467F",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9DB63040DBDEE832D0824220FC47361674058EF1": {
    "name": "Curve.fi Factory Crypto Pool: STG/USDC",
    "address": "0X9DB63040DBDEE832D0824220FC47361674058EF1",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0X326F5834D73A2DC02B3C492E9EF1B24399D430B3"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    },
    "underlyingCoins": null
  },
  "0XABF03448F97708F06FB1CAA5B58A30247F024D19": {
    "name": "Curve.fi Factory Crypto Pool: STG/FRAX-USDC",
    "address": "0XABF03448F97708F06FB1CAA5B58A30247F024D19",
    "assetType": "factory-crypto",
    "implementationAddress": "0XA85461AFC2DEEC01BDA23B5CD267D51F765FBA10",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0X326F5834D73A2DC02B3C492E9EF1B24399D430B3"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "STG",
        "decimals": 18,
        "address": "0X326F5834D73A2DC02B3C492E9EF1B24399D430B3"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X1F71F05CF491595652378FE94B7820344A551B8E": {
    "name": "Curve.fi Factory Plain Pool: ibEUR/sEUR",
    "address": "0X1F71F05CF491595652378FE94B7820344A551B8E",
    "assetType": "99",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibEUR",
        "decimals": 18,
        "address": "0X96E61422B6A9BA0E068B6C5ADD4FFABC6A4AAE27"
      },
      "1": {
        "index": 1,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      }
    },
    "underlyingCoins": null
  },
  "0X8461A004B50D321CB22B7D034969CE6803911899": {
    "name": "Curve.fi Factory Plain Pool: ibKRW/sKRW",
    "address": "0X8461A004B50D321CB22B7D034969CE6803911899",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibKRW",
        "decimals": 18,
        "address": "0X95DFDC8161832E4FF7816AC4B6367CE201538253"
      },
      "1": {
        "index": 1,
        "name": "sKRW",
        "decimals": 18,
        "address": "0X269895A3DF4D73B077FC823DD6DA1B95F72AAF9B"
      }
    },
    "underlyingCoins": null
  },
  "0X19B080FE1FFA0553469D20CA36219F17FCF03859": {
    "name": "Curve.fi Factory Plain Pool: ibEUR/sEUR",
    "address": "0X19B080FE1FFA0553469D20CA36219F17FCF03859",
    "assetType": "0",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibEUR",
        "decimals": 18,
        "address": "0X96E61422B6A9BA0E068B6C5ADD4FFABC6A4AAE27"
      },
      "1": {
        "index": 1,
        "name": "sEUR",
        "decimals": 18,
        "address": "0XD71ECFF9342A5CED620049E616C5035F1DB98620"
      }
    },
    "underlyingCoins": null
  },
  "0X621F13BF667207335C601F8C89EA5EC260BADA9A": {
    "name": "Curve.fi Factory Plain Pool: Curve.fi sCRV/CRV",
    "address": "0X621F13BF667207335C601F8C89EA5EC260BADA9A",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sCRV",
        "decimals": 18,
        "address": "0XD38AEB759891882E78E957C80656572503D8C1B1"
      },
      "1": {
        "index": 1,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      }
    },
    "underlyingCoins": null
  },
  "0XED24FE718EFFC6B2FC59EEAA5C5F51DD079AB6ED": {
    "name": "Curve.fi Factory Plain Pool: jGBP/TGBP",
    "address": "0XED24FE718EFFC6B2FC59EEAA5C5F51DD079AB6ED",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "jGBP",
        "decimals": 18,
        "address": "0X7409856CAE628F5D578B285B45669B36E7005283"
      },
      "1": {
        "index": 1,
        "name": "TGBP",
        "decimals": 18,
        "address": "0X00000000441378008EA67F4284A57932B1C000A5"
      }
    },
    "underlyingCoins": null
  },
  "0X6C7FC04FEE277EABDD387C5B498A8D0F4CB9C6A6": {
    "name": "Curve.fi Factory Plain Pool: cvxCRV",
    "address": "0X6C7FC04FEE277EABDD387C5B498A8D0F4CB9C6A6",
    "assetType": "99",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      }
    },
    "underlyingCoins": null
  },
  "0XDA5B670CCD418A187A3066674A8002ADC9356AD1": {
    "name": "Curve.fi Factory Plain Pool: Ahead of the Curve",
    "address": "0XDA5B670CCD418A187A3066674A8002ADC9356AD1",
    "assetType": "3",
    "implementationAddress": "0XAD4753D045D3AED5C1A6606DFB6A7D7AD67C1AD7",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yvBOOST",
        "decimals": 18,
        "address": "0X9D409A0A012CFBA9B15F6D4B36AC57A46966AB9A"
      },
      "2": {
        "index": 2,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      },
      "3": {
        "index": 3,
        "name": "sCRV",
        "decimals": 18,
        "address": "0XD38AEB759891882E78E957C80656572503D8C1B1"
      }
    },
    "underlyingCoins": null
  },
  "0X99AE07E7AB61DCCE4383A86D14F61C68CDCCBF27": {
    "name": "Curve.fi Factory BTC Metapool: Interest Bearing Bitcoin",
    "address": "0X99AE07E7AB61DCCE4383A86D14F61C68CDCCBF27",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibBTC",
        "decimals": 18,
        "address": "0XC4E15973E6FF2A35CC804C2CF9D2A1B817A8B40F"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ibBTC",
        "decimals": 18,
        "address": "0XC4E15973E6FF2A35CC804C2CF9D2A1B817A8B40F"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X87650D7BBFC3A9F10587D7778206671719D9910D": {
    "name": "Curve.fi Factory USD Metapool: Origin Dollar",
    "address": "0X87650D7BBFC3A9F10587D7778206671719D9910D",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OUSD",
        "decimals": 18,
        "address": "0X2A8E1E676EC238D8A992307B495B45B3FEAA5E86"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "OUSD",
        "decimals": 18,
        "address": "0X2A8E1E676EC238D8A992307B495B45B3FEAA5E86"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6A274DE3E2462C7614702474D64D376729831DCA": {
    "name": "Curve.fi Factory Plain Pool: AAVE Untethered",
    "address": "0X6A274DE3E2462C7614702474D64D376729831DCA",
    "assetType": "0",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "aUSDC",
        "decimals": 6,
        "address": "0XBCCA60BB61934080951369A648FB03DF4F96263C"
      },
      "1": {
        "index": 1,
        "name": "aDAI",
        "decimals": 18,
        "address": "0X028171BCA77440897B824CA71D1C56CAC55B68A3"
      }
    },
    "underlyingCoins": null
  },
  "0X06CB22615BA53E60D67BF6C341A0FD5E718E1655": {
    "name": "Curve.fi Factory USD Metapool: FEI Metapool",
    "address": "0X06CB22615BA53E60D67BF6C341A0FD5E718E1655",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FEI",
        "decimals": 18,
        "address": "0X956F47F50A910163D8BF957CF5846D573E7F87CA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "FEI",
        "decimals": 18,
        "address": "0X956F47F50A910163D8BF957CF5846D573E7F87CA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XF5A95CCDE486B5FE98852BB02D8EC80A4B9422BD": {
    "name": "Curve.fi Factory USD Metapool: GrapefruitUSD",
    "address": "0XF5A95CCDE486B5FE98852BB02D8EC80A4B9422BD",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GrapefruitUSD",
        "decimals": 18,
        "address": "0X71DF9DD3E658F0136C40E2E8EC3988A5226E9A67"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GrapefruitUSD",
        "decimals": 18,
        "address": "0X71DF9DD3E658F0136C40E2E8EC3988A5226E9A67"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X2009F19A8B46642E92EA19ADCDFB23AB05FC20A6": {
    "name": "Curve.fi Factory USD Metapool: RC_WETH_1650_DAI_2021_4_30-real",
    "address": "0X2009F19A8B46642E92EA19ADCDFB23AB05FC20A6",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RC_WETH_1650_DAI_2021_4_30",
        "decimals": 18,
        "address": "0X810C4B2A31665EF77B75D2410ACEB29F0F099096"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RC_WETH_1650_DAI_2021_4_30",
        "decimals": 18,
        "address": "0X810C4B2A31665EF77B75D2410ACEB29F0F099096"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X883F7D4B6B24F8BF1DB980951AD08930D9AEC6BC": {
    "name": "Curve.fi Factory USD Metapool: RC_INV_350_DAI_2021_5_31",
    "address": "0X883F7D4B6B24F8BF1DB980951AD08930D9AEC6BC",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RC_INV_350_DAI_2021_5_31",
        "decimals": 18,
        "address": "0X3FA372155439E9150A99D86A1DE93A92F26B00C8"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RC_INV_350_DAI_2021_5_31",
        "decimals": 18,
        "address": "0X3FA372155439E9150A99D86A1DE93A92F26B00C8"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X46F5AB27914A670CFE260A2DEDB87F84C264835F": {
    "name": "Curve.fi Factory USD Metapool: RC_xRULER_15_DAI_2021_6_30",
    "address": "0X46F5AB27914A670CFE260A2DEDB87F84C264835F",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RC_xRULER_15_DAI_2021_6_30",
        "decimals": 18,
        "address": "0XE7D18F2507869280111025F4938376846E8AD11A"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RC_xRULER_15_DAI_2021_6_30",
        "decimals": 18,
        "address": "0XE7D18F2507869280111025F4938376846E8AD11A"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X2206CF41E7DB9393A3BCBB6AD35D344811523B46": {
    "name": "Curve.fi Factory USD Metapool: RC_xCOVER_100_DAI_2021_6_30",
    "address": "0X2206CF41E7DB9393A3BCBB6AD35D344811523B46",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "RC_xCOVER_100_DAI_2021_6_30",
        "decimals": 18,
        "address": "0XB556BB40E40F4AF34D9EEA04D2FF7DB2674939BB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "RC_xCOVER_100_DAI_2021_6_30",
        "decimals": 18,
        "address": "0XB556BB40E40F4AF34D9EEA04D2FF7DB2674939BB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X67D9EAE741944D4402EB0D1CB3BC3A168EC1764C": {
    "name": "Curve.fi Factory USD Metapool: NAOS USD",
    "address": "0X67D9EAE741944D4402EB0D1CB3BC3A168EC1764C",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "nUSD",
        "decimals": 18,
        "address": "0X1BEF2E5DE862034FB0ED456DF59D29ECADC9934C"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "nUSD",
        "decimals": 18,
        "address": "0X1BEF2E5DE862034FB0ED456DF59D29ECADC9934C"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X9D0464996170C6B9E75EED71C68B99DDEDF279E8": {
    "name": "Curve.fi Factory Plain Pool: cvxCRV",
    "address": "0X9D0464996170C6B9E75EED71C68B99DDEDF279E8",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      }
    },
    "underlyingCoins": null
  },
  "0X5B3B5DF2BF2B6543F78E053BD91C4BDD820929F1": {
    "name": "Curve.fi Factory USD Metapool: USDM",
    "address": "0X5B3B5DF2BF2B6543F78E053BD91C4BDD820929F1",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDM",
        "decimals": 18,
        "address": "0X31D4EB09A216E181EC8A43CE79226A487D6F0BA9"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDM",
        "decimals": 18,
        "address": "0X31D4EB09A216E181EC8A43CE79226A487D6F0BA9"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3CFAA1596777CAD9F5004F9A0C443D912E262243": {
    "name": "Curve.fi Factory Plain Pool: Meta EUR",
    "address": "0X3CFAA1596777CAD9F5004F9A0C443D912E262243",
    "assetType": "3",
    "implementationAddress": "0X5BD47EA4494E0F8DE6E3CA10F1C05F55B72466B8",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PAR",
        "decimals": 18,
        "address": "0X68037790A0229E9CE6EAA8A99EA92964106C4703"
      },
      "1": {
        "index": 1,
        "name": "EURT",
        "decimals": 6,
        "address": "0XC581B735A1688071A1746C968E0798D642EDE491"
      },
      "2": {
        "index": 2,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      },
      "3": {
        "index": 3,
        "name": "sEUR",
        "decimals": 18,
        "address": "0XD71ECFF9342A5CED620049E616C5035F1DB98620"
      }
    },
    "underlyingCoins": null
  },
  "0X9F6664205988C3BF4B12B851C075102714869535": {
    "name": "Curve.fi Factory USD Metapool: Wasabi USD",
    "address": "0X9F6664205988C3BF4B12B851C075102714869535",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "waUSD",
        "decimals": 18,
        "address": "0XC2DB4C131ADAF01C15A1DB654C040C8578929D55"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "waUSD",
        "decimals": 18,
        "address": "0XC2DB4C131ADAF01C15A1DB654C040C8578929D55"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6D0BD8365E2FCD0C2ACF7D218F629A319B6C9D47": {
    "name": "Curve.fi Factory BTC Metapool: Wasabi BTC",
    "address": "0X6D0BD8365E2FCD0C2ACF7D218F629A319B6C9D47",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "waBTC",
        "decimals": 8,
        "address": "0XFD8E70E83E399307DB3978D3F34B060A06792C36"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "waBTC",
        "decimals": 8,
        "address": "0XFD8E70E83E399307DB3978D3F34B060A06792C36"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0XAA5A67C256E27A5D80712C51971408DB3370927D": {
    "name": "Curve.fi Factory USD Metapool: DOLA-3pool Curve LP",
    "address": "0XAA5A67C256E27A5D80712C51971408DB3370927D",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8818A9BB44FBF33502BE7C15C500D0C783B73067": {
    "name": "Curve.fi Factory Plain Pool: ibJPY/sJPY",
    "address": "0X8818A9BB44FBF33502BE7C15C500D0C783B73067",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibJPY",
        "decimals": 18,
        "address": "0X5555F75E3D5278082200FB451D1B6BA946D8E13B"
      },
      "1": {
        "index": 1,
        "name": "sJPY",
        "decimals": 18,
        "address": "0XF6B1C627E95BFC3C1B4C9B825A032FF0FBF3E07D"
      }
    },
    "underlyingCoins": null
  },
  "0X3F1B0278A9EE595635B61817630CC19DE792F506": {
    "name": "Curve.fi Factory Plain Pool: ibAUD/sAUD",
    "address": "0X3F1B0278A9EE595635B61817630CC19DE792F506",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibAUD",
        "decimals": 18,
        "address": "0XFAFDF0C4C1CB09D430BF88C75D88BB46DAE09967"
      },
      "1": {
        "index": 1,
        "name": "sAUD",
        "decimals": 18,
        "address": "0XF48E200EAF9906362BB1442FCA31E0835773B8B4"
      }
    },
    "underlyingCoins": null
  },
  "0XD6AC1CB9019137A896343DA59DDE6D097F710538": {
    "name": "Curve.fi Factory Plain Pool: ibGBP/sGBP",
    "address": "0XD6AC1CB9019137A896343DA59DDE6D097F710538",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibGBP",
        "decimals": 18,
        "address": "0X69681F8FDE45345C3870BCD5EAF4A05A60E7D227"
      },
      "1": {
        "index": 1,
        "name": "sGBP",
        "decimals": 18,
        "address": "0X97FE22E7341A0CD8DB6F6C021A24DC8F4DAD855F"
      }
    },
    "underlyingCoins": null
  },
  "0X9C2C8910F113181783C249D8F6AA41B51CDE0F0C": {
    "name": "Curve.fi Factory Plain Pool: ibCHF/sCHF",
    "address": "0X9C2C8910F113181783C249D8F6AA41B51CDE0F0C",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibCHF",
        "decimals": 18,
        "address": "0X1CC481CE2BD2EC7BF67D1BE64D4878B16078F309"
      },
      "1": {
        "index": 1,
        "name": "sCHF",
        "decimals": 18,
        "address": "0X0F83287FF768D1C1E17A42F44D644D7F22E8EE1D"
      }
    },
    "underlyingCoins": null
  },
  "0XC8A7C1C4B748970F57CA59326BCD49F5C9DC43E3": {
    "name": "Curve.fi Factory Plain Pool: OPEN MATIC",
    "address": "0XC8A7C1C4B748970F57CA59326BCD49F5C9DC43E3",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OPEN",
        "decimals": 18,
        "address": "0X69E8B9528CABDA89FE846C67675B5D73D463A916"
      },
      "1": {
        "index": 1,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      }
    },
    "underlyingCoins": null
  },
  "0X3FB78E61784C9C637D560EDE23AD57CA1294C14A": {
    "name": "Curve.fi Factory Plain Pool: Neutrino EUR",
    "address": "0X3FB78E61784C9C637D560EDE23AD57CA1294C14A",
    "assetType": "3",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EURN",
        "decimals": 18,
        "address": "0X9FCF418B971134625CDF38448B949C8640971671"
      },
      "1": {
        "index": 1,
        "name": "EURT",
        "decimals": 6,
        "address": "0XC581B735A1688071A1746C968E0798D642EDE491"
      }
    },
    "underlyingCoins": null
  },
  "0X737BC004136F66AE3F8FD5A1199E81C18388097B": {
    "name": "Curve.fi Factory Plain Pool: sdCRV",
    "address": "0X737BC004136F66AE3F8FD5A1199E81C18388097B",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sdveCRV-DAO",
        "decimals": 18,
        "address": "0X478BBC744811EE8310B461514BDC29D03739084D"
      },
      "1": {
        "index": 1,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      }
    },
    "underlyingCoins": null
  },
  "0X4E52CFC80679F402D10F7766FA3F85351A7C2530": {
    "name": "Curve.fi Factory BTC Metapool: BTCpx",
    "address": "0X4E52CFC80679F402D10F7766FA3F85351A7C2530",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BTCpx",
        "decimals": 8,
        "address": "0X9C32185B81766A051E08DE671207B34466DD1021"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BTCpx",
        "decimals": 8,
        "address": "0X9C32185B81766A051E08DE671207B34466DD1021"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X2DE8C952871317FB9F22C73BB66BF86A1EEBE1A5": {
    "name": "Curve.fi Factory USD Metapool: PWRD Metapool",
    "address": "0X2DE8C952871317FB9F22C73BB66BF86A1EEBE1A5",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PWRD",
        "decimals": 18,
        "address": "0XF0A93D4994B3D98FB5E3A2F90DBC2D69073CB86B"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PWRD",
        "decimals": 18,
        "address": "0XF0A93D4994B3D98FB5E3A2F90DBC2D69073CB86B"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X08EAF78D40ABFA6C341F05692EB48EDCA425CE04": {
    "name": "Curve.fi Factory Plain Pool: Wanna Avoid Tether?",
    "address": "0X08EAF78D40ABFA6C341F05692EB48EDCA425CE04",
    "assetType": "0",
    "implementationAddress": "0XE5F4B89E0A16578B3E0E7581327BDB4C712E44DE",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      },
      "1": {
        "index": 1,
        "name": "UST",
        "decimals": 18,
        "address": "0XA47C8BF37F92ABED4A126BDA807A7B7498661ACD"
      },
      "2": {
        "index": 2,
        "name": "MIM",
        "decimals": 18,
        "address": "0X99D8A9C45B2ECA8864373A26D1459E3DFF1E17F3"
      }
    },
    "underlyingCoins": null
  },
  "0XC4C319E2D4D66CCA4464C0C2B32C9BD23EBE784E": {
    "name": "Curve.fi Factory Pool: alETH",
    "address": "0XC4C319E2D4D66CCA4464C0C2B32C9BD23EBE784E",
    "assetType": "6",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "alETH",
        "decimals": 18,
        "address": "0X0100546F2CD4C9D97F798FFC9755E47865FF7EE6"
      }
    },
    "underlyingCoins": null
  },
  "0X66B2E9B25F8ABA6B4A10350C785D63BADE5A11E9": {
    "name": "Curve.fi Factory USD Metapool: 17PctCryptoDiversifiedDollar",
    "address": "0X66B2E9B25F8ABA6B4A10350C785D63BADE5A11E9",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "crv3crypto",
        "decimals": 18,
        "address": "0XC4AD29BA4B3C580E6D59105FFF484999997675FF"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "crv3crypto",
        "decimals": 18,
        "address": "0XC4AD29BA4B3C580E6D59105FFF484999997675FF"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XE76EBD4F9FA58E5269D3CD032B055B443239E664": {
    "name": "Curve.fi Factory USD Metapool: 17PctCryptoDiversifiedDollar",
    "address": "0XE76EBD4F9FA58E5269D3CD032B055B443239E664",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "crv3crypto",
        "decimals": 18,
        "address": "0XC4AD29BA4B3C580E6D59105FFF484999997675FF"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "crv3crypto",
        "decimals": 18,
        "address": "0XC4AD29BA4B3C580E6D59105FFF484999997675FF"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFA65AA60A9D45623C57D383FB4CF8FB8B854CC4D": {
    "name": "Curve.fi Factory BTC Metapool: tbtc2",
    "address": "0XFA65AA60A9D45623C57D383FB4CF8FB8B854CC4D",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tBTC",
        "decimals": 18,
        "address": "0X18084FBA666A33D37592FA2633FD49A74DD93A88"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "tBTC",
        "decimals": 18,
        "address": "0X18084FBA666A33D37592FA2633FD49A74DD93A88"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0XED09CA8275DFFB09C632B6EA58C035A851F73616": {
    "name": "Curve.fi Factory USD Metapool: kusd-3pool",
    "address": "0XED09CA8275DFFB09C632B6EA58C035A851F73616",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CHEESE",
        "decimals": 18,
        "address": "0X332E824E46FCEEB9E59BA9491B80D3E6D42B0B59"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "CHEESE",
        "decimals": 18,
        "address": "0X332E824E46FCEEB9E59BA9491B80D3E6D42B0B59"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XAC5F019A302C4C8CAAC0A7F28183AC62E6E80034": {
    "name": "Curve.fi Factory USD Metapool: tusd-3pool",
    "address": "0XAC5F019A302C4C8CAAC0A7F28183AC62E6E80034",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "kUSD",
        "decimals": 18,
        "address": "0XEF779CF3D260DBE6177B30FF08B10DB591A6DD9C"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "kUSD",
        "decimals": 18,
        "address": "0XEF779CF3D260DBE6177B30FF08B10DB591A6DD9C"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBCB91E689114B9CC865AD7871845C95241DF4105": {
    "name": "Curve.fi Factory USD Metapool: PWRD Metapool",
    "address": "0XBCB91E689114B9CC865AD7871845C95241DF4105",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PWRD",
        "decimals": 18,
        "address": "0XF0A93D4994B3D98FB5E3A2F90DBC2D69073CB86B"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PWRD",
        "decimals": 18,
        "address": "0XF0A93D4994B3D98FB5E3A2F90DBC2D69073CB86B"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC2F5FEA5197A3D92736500FD7733FCC7A3BBDF3F": {
    "name": "Curve.fi Factory USD Metapool: fUSD-3pool",
    "address": "0XC2F5FEA5197A3D92736500FD7733FCC7A3BBDF3F",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "fUSD",
        "decimals": 18,
        "address": "0X42EF9077D8E79689799673AE588E046F8832CB95"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "fUSD",
        "decimals": 18,
        "address": "0X42EF9077D8E79689799673AE588E046F8832CB95"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X705DA2596CF6AAA2FEA36F2A59985EC9E8AEC7E2": {
    "name": "Curve.fi Factory USD Metapool: Test Pool D",
    "address": "0X705DA2596CF6AAA2FEA36F2A59985EC9E8AEC7E2",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "wCOINBASE-IOU",
        "decimals": 18,
        "address": "0X4185CF99745B2A20727B37EE798193DD4A56CDFA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "wCOINBASE-IOU",
        "decimals": 18,
        "address": "0X4185CF99745B2A20727B37EE798193DD4A56CDFA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6870F9B4DD5D34C7FC53D0D85D9DBD1AAB339BF7": {
    "name": "Curve.fi Factory USD Metapool: DEI",
    "address": "0X6870F9B4DD5D34C7FC53D0D85D9DBD1AAB339BF7",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DEI",
        "decimals": 18,
        "address": "0XDE12C7959E1A72BBE8A5F7A1DC8F8EEF9AB011B3"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DEI",
        "decimals": 18,
        "address": "0XDE12C7959E1A72BBE8A5F7A1DC8F8EEF9AB011B3"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X55A8A39BC9694714E2874C1CE77AA1E599461E18": {
    "name": "Curve.fi Factory Plain Pool: MIM-UST",
    "address": "0X55A8A39BC9694714E2874C1CE77AA1E599461E18",
    "assetType": "0",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MIM",
        "decimals": 18,
        "address": "0X99D8A9C45B2ECA8864373A26D1459E3DFF1E17F3"
      },
      "1": {
        "index": 1,
        "name": "UST",
        "decimals": 18,
        "address": "0XA47C8BF37F92ABED4A126BDA807A7B7498661ACD"
      }
    },
    "underlyingCoins": null
  },
  "0XF03BD3CFE85F00BF5819AC20F0870CE8A8D1F0D8": {
    "name": "Curve.fi Factory Plain Pool: Sharedstake ETH/vETH2",
    "address": "0XF03BD3CFE85F00BF5819AC20F0870CE8A8D1F0D8",
    "assetType": "1",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "vETH2",
        "decimals": 18,
        "address": "0X898BAD2774EB97CF6B94605677F43B41871410B1"
      },
      "1": {
        "index": 1,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0XC8781F2193E2CB861C9325677D98297F94A0DFD3": {
    "name": "Curve.fi Factory USD Metapool: QabbalahBit Wellsprings",
    "address": "0XC8781F2193E2CB861C9325677D98297F94A0DFD3",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "QBIT",
        "decimals": 18,
        "address": "0X8B921E618DD3FA5A199B0A8B7901F5530D74EF27"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "QBIT",
        "decimals": 18,
        "address": "0X8B921E618DD3FA5A199B0A8B7901F5530D74EF27"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X9F4A88DA14F2B6DBC785C1DB3511A53B8F342BDE": {
    "name": "Curve.fi Factory USD Metapool: QBIT Well",
    "address": "0X9F4A88DA14F2B6DBC785C1DB3511A53B8F342BDE",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "QBIT",
        "decimals": 18,
        "address": "0X8B921E618DD3FA5A199B0A8B7901F5530D74EF27"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "QBIT",
        "decimals": 18,
        "address": "0X8B921E618DD3FA5A199B0A8B7901F5530D74EF27"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X04C90C198B2EFF55716079BC06D7CCC4AA4D7512": {
    "name": "Curve.fi Factory Plain Pool: Badger Locked CVX",
    "address": "0X04C90C198B2EFF55716079BC06D7CCC4AA4D7512",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "bveCVX",
        "decimals": 18,
        "address": "0XFD05D3C7FE2924020620A8BE4961BBAA747E6305"
      }
    },
    "underlyingCoins": null
  },
  "0XCEAF7747579696A2F0BB206A14210E3C9E6FB269": {
    "name": "Curve.fi Factory USD Metapool: wormhole v2 UST-3Pool",
    "address": "0XCEAF7747579696A2F0BB206A14210E3C9E6FB269",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 6,
        "address": "0XA693B19D2931D498C5B318DF961919BB4AEE87A5"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 6,
        "address": "0XA693B19D2931D498C5B318DF961919BB4AEE87A5"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X9FED7A930D86DFE5980040E18C92B1B0D381EC19": {
    "name": "Curve.fi Factory USD Metapool: DSU",
    "address": "0X9FED7A930D86DFE5980040E18C92B1B0D381EC19",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DSU",
        "decimals": 18,
        "address": "0X605D26FBD5BE761089281D5CEC2CE86EEA667109"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DSU",
        "decimals": 18,
        "address": "0X605D26FBD5BE761089281D5CEC2CE86EEA667109"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XF0C081020B9D06EB1B33E357767C00CCC138BE7C": {
    "name": "Curve.fi Factory USD Metapool: DSU",
    "address": "0XF0C081020B9D06EB1B33E357767C00CCC138BE7C",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DSU",
        "decimals": 18,
        "address": "0X605D26FBD5BE761089281D5CEC2CE86EEA667109"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DSU",
        "decimals": 18,
        "address": "0X605D26FBD5BE761089281D5CEC2CE86EEA667109"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFB9A265B5A1F52D97838EC7274A0B1442EFACC87": {
    "name": "Curve.fi Factory Pool: Ankr Reward-Earning Staked ETH",
    "address": "0XFB9A265B5A1F52D97838EC7274A0B1442EFACC87",
    "assetType": "6",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "aETHb",
        "decimals": 18,
        "address": "0XD01EF7C0A5D8C432FC2D1A85C66CF2327362E5C6"
      }
    },
    "underlyingCoins": null
  },
  "0XBAAA1F5DBA42C3389BDBC2C9D2DE134F5CD0DC89": {
    "name": "Curve.fi Factory Plain Pool: d3pool",
    "address": "0XBAAA1F5DBA42C3389BDBC2C9D2DE134F5CD0DC89",
    "assetType": "0",
    "implementationAddress": "0XE5F4B89E0A16578B3E0E7581327BDB4C712E44DE",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "1": {
        "index": 1,
        "name": "FEI",
        "decimals": 18,
        "address": "0X956F47F50A910163D8BF957CF5846D573E7F87CA"
      },
      "2": {
        "index": 2,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      }
    },
    "underlyingCoins": null
  },
  "0X1F6BB2A7A2A84D08BB821B89E38CA651175AEDD4": {
    "name": "Curve.fi Factory Plain Pool: Ankr Reward-Earning Staked MATIC",
    "address": "0X1F6BB2A7A2A84D08BB821B89E38CA651175AEDD4",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      },
      "1": {
        "index": 1,
        "name": "aMATICb",
        "decimals": 18,
        "address": "0X99534EF705DF1FFF4E4BD7BBAAF9B0DFF038EBFE"
      }
    },
    "underlyingCoins": null
  },
  "0XC270B3B858C335B6BA5D5B10E2DA8A09976005AD": {
    "name": "Curve.fi Factory USD Metapool: Paxos Dollar (USDP)",
    "address": "0XC270B3B858C335B6BA5D5B10E2DA8A09976005AD",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDP",
        "decimals": 18,
        "address": "0X8E870D67F660D95D5BE530380D0EC0BD388289E1"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDP",
        "decimals": 18,
        "address": "0X8E870D67F660D95D5BE530380D0EC0BD388289E1"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFBDCA68601F835B27790D98BBB8EC7F05FDEAA9B": {
    "name": "Curve.fi Factory BTC Metapool: ibBTC",
    "address": "0XFBDCA68601F835B27790D98BBB8EC7F05FDEAA9B",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "wibBTC",
        "decimals": 18,
        "address": "0X8751D4196027D4E6DA63716FA7786B5174F04C15"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "wibBTC",
        "decimals": 18,
        "address": "0X8751D4196027D4E6DA63716FA7786B5174F04C15"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X8DF0713B2A047C45A0BEF21C3B309BCEF91AFD34": {
    "name": "Curve.fi Factory Plain Pool: handleEUR",
    "address": "0X8DF0713B2A047C45A0BEF21C3B309BCEF91AFD34",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "fxEUR",
        "decimals": 18,
        "address": "0X116172B2482C5DC3E6F445C16AC13367AC3FCD35"
      },
      "1": {
        "index": 1,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      }
    },
    "underlyingCoins": null
  },
  "0X148A88719BA0B34F16E0F5A7537DA73BDC9C2A2A": {
    "name": "Curve.fi Factory BTC Metapool: Orkan",
    "address": "0X148A88719BA0B34F16E0F5A7537DA73BDC9C2A2A",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "VBTC",
        "decimals": 18,
        "address": "0XE1406825186D63980FD6E2EC61888F7B91C4BAE4"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "VBTC",
        "decimals": 18,
        "address": "0XE1406825186D63980FD6E2EC61888F7B91C4BAE4"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X45A8CC73EC100306AF64AB2CCB7B12E70EC549A8": {
    "name": "Curve.fi Factory Plain Pool: agEUR",
    "address": "0X45A8CC73EC100306AF64AB2CCB7B12E70EC549A8",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "sEUR",
        "decimals": 18,
        "address": "0XD71ECFF9342A5CED620049E616C5035F1DB98620"
      }
    },
    "underlyingCoins": null
  },
  "0XCBD5CC53C5B846671C6434AB301AD4D210C21184": {
    "name": "Curve.fi Factory Plain Pool: ibZAR/ZARP",
    "address": "0XCBD5CC53C5B846671C6434AB301AD4D210C21184",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ZARP",
        "decimals": 18,
        "address": "0X8CB24ED2E4F7E2065F4EB2BE5F6B0064B1919850"
      },
      "1": {
        "index": 1,
        "name": "ibZAR",
        "decimals": 18,
        "address": "0X81D66D255D47662B6B16F3C5BBFBB15283B05BC2"
      }
    },
    "underlyingCoins": null
  },
  "0XE667C793513ECBD74FB53BB4B91FDAE02BFC092D": {
    "name": "Curve.fi Factory USD Metapool: DYDX-3CRV",
    "address": "0XE667C793513ECBD74FB53BB4B91FDAE02BFC092D",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DYDX",
        "decimals": 18,
        "address": "0X92D6C1E31E14520E676A687F0A93788B716BEFF5"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DYDX",
        "decimals": 18,
        "address": "0X92D6C1E31E14520E676A687F0A93788B716BEFF5"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XB9446C4EF5EBE66268DA6700D26F96273DE3D571": {
    "name": "Curve.fi Factory Plain Pool: 3EURpool",
    "address": "0XB9446C4EF5EBE66268DA6700D26F96273DE3D571",
    "assetType": "3",
    "implementationAddress": "0X50B085F2E5958C4A87BAF93A8AB79F6BEC068494",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "EURT",
        "decimals": 6,
        "address": "0XC581B735A1688071A1746C968E0798D642EDE491"
      },
      "2": {
        "index": 2,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      }
    },
    "underlyingCoins": null
  },
  "0X06D39E95977349431E3D800D49C63B4D472E10FB": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tWETH/WETH",
    "address": "0X06D39E95977349431E3D800D49C63B4D472E10FB",
    "assetType": "1",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tWETH",
        "decimals": 18,
        "address": "0XD3D13A578A53685B4AC36A1BAB31912D2B2A2F36"
      },
      "1": {
        "index": 1,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      }
    },
    "underlyingCoins": null
  },
  "0X1033812EFEC8716BBAE0C19E5678698D25E26EDF": {
    "name": "Curve.fi Factory USD Metapool: SORA XSTUSD",
    "address": "0X1033812EFEC8716BBAE0C19E5678698D25E26EDF",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "XSTUSD",
        "decimals": 18,
        "address": "0XC7D9C108D4E1DD1484D3E2568D7F74BFD763D356"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "XSTUSD",
        "decimals": 18,
        "address": "0XC7D9C108D4E1DD1484D3E2568D7F74BFD763D356"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X679CE2A8B3180F5A00E0DCCA26783016799E9A58": {
    "name": "Curve.fi Factory USD Metapool: xDollar Interverse Money",
    "address": "0X679CE2A8B3180F5A00E0DCCA26783016799E9A58",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "XIM",
        "decimals": 18,
        "address": "0X573D2505A7EE69D136A8667B4CD915F039AC54E5"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "XIM",
        "decimals": 18,
        "address": "0X573D2505A7EE69D136A8667B4CD915F039AC54E5"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6D8FF88973B15DF3E2DC6ABB9AF29CAD8C2B5EF5": {
    "name": "Curve.fi Factory USD Metapool: xim",
    "address": "0X6D8FF88973B15DF3E2DC6ABB9AF29CAD8C2B5EF5",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "XIM",
        "decimals": 18,
        "address": "0X573D2505A7EE69D136A8667B4CD915F039AC54E5"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "XIM",
        "decimals": 18,
        "address": "0X573D2505A7EE69D136A8667B4CD915F039AC54E5"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8083B047E962CA45B210E28AC755FBDA3D773C5B": {
    "name": "Curve.fi Factory USD Metapool: RAMP rUSD",
    "address": "0X8083B047E962CA45B210E28AC755FBDA3D773C5B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "rUSD",
        "decimals": 18,
        "address": "0XCF178A685471927E977A0EA8EE555C11B2AA6B7B"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "rUSD",
        "decimals": 18,
        "address": "0XCF178A685471927E977A0EA8EE555C11B2AA6B7B"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3B22B869BA3C0A495CEAD0B8A009B70886D37FAC": {
    "name": "Curve.fi Factory USD Metapool: Bhome",
    "address": "0X3B22B869BA3C0A495CEAD0B8A009B70886D37FAC",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "HOME",
        "decimals": 6,
        "address": "0XB8919522331C59F5C16BDFAA6A121A6E03A91F62"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "HOME",
        "decimals": 6,
        "address": "0XB8919522331C59F5C16BDFAA6A121A6E03A91F62"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBB2DC673E1091ABCA3EADB622B18F6D4634B2CD9": {
    "name": "Curve.fi Factory Plain Pool: JPYC/ibJPY",
    "address": "0XBB2DC673E1091ABCA3EADB622B18F6D4634B2CD9",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "JPYC",
        "decimals": 18,
        "address": "0X2370F9D504C7A6E775BF6E14B3F12846B594CD53"
      },
      "1": {
        "index": 1,
        "name": "ibJPY",
        "decimals": 18,
        "address": "0X5555F75E3D5278082200FB451D1B6BA946D8E13B"
      }
    },
    "underlyingCoins": null
  },
  "0X1C65BA665CE39CFE85639227ECCF17BE2B167058": {
    "name": "Curve.fi Factory Plain Pool: UST-FRAX",
    "address": "0X1C65BA665CE39CFE85639227ECCF17BE2B167058",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 6,
        "address": "0XA693B19D2931D498C5B318DF961919BB4AEE87A5"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0XA0D35FAEAD5299BF18EFBB5DEFD1EC6D4AB4EF3B": {
    "name": "Curve.fi Factory Plain Pool: Fei PCV 1",
    "address": "0XA0D35FAEAD5299BF18EFBB5DEFD1EC6D4AB4EF3B",
    "assetType": "0",
    "implementationAddress": "0X50B085F2E5958C4A87BAF93A8AB79F6BEC068494",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "aFEI",
        "decimals": 18,
        "address": "0X683923DB55FEAD99A79FA01A27EEC3CB19679CC3"
      },
      "1": {
        "index": 1,
        "name": "aDAI",
        "decimals": 18,
        "address": "0X028171BCA77440897B824CA71D1C56CAC55B68A3"
      },
      "2": {
        "index": 2,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0XF083FBA98DED0F9C970E5A418500BAD08D8B9732": {
    "name": "Curve.fi Factory Plain Pool: bentCVX",
    "address": "0XF083FBA98DED0F9C970E5A418500BAD08D8B9732",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "bentCVX",
        "decimals": 18,
        "address": "0X9E0441E084F5DB0606565737158AA6AB6B970FE0"
      }
    },
    "underlyingCoins": null
  },
  "0X76264772707C8BC24261516B560CBF3CBE6F7819": {
    "name": "Curve.fi Factory USD Metapool: dForce",
    "address": "0X76264772707C8BC24261516B560CBF3CBE6F7819",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USX",
        "decimals": 18,
        "address": "0X0A5E677A6A24B2F1A2BF4F3BFFC443231D2FDEC8"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USX",
        "decimals": 18,
        "address": "0X0A5E677A6A24B2F1A2BF4F3BFFC443231D2FDEC8"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XB37D6C07482BC11CD28A1F11F1A6AD7B66DEC933": {
    "name": "Curve.fi Factory Plain Pool: agEUR/ibEUR",
    "address": "0XB37D6C07482BC11CD28A1F11F1A6AD7B66DEC933",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ibEUR",
        "decimals": 18,
        "address": "0X96E61422B6A9BA0E068B6C5ADD4FFABC6A4AAE27"
      },
      "1": {
        "index": 1,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      }
    },
    "underlyingCoins": null
  },
  "0X694650A0B2866472C2EEA27827CE6253C1D13074": {
    "name": "Curve.fi Factory Plain Pool: tFRAX/FRAX Test",
    "address": "0X694650A0B2866472C2EEA27827CE6253C1D13074",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tFRAX",
        "decimals": 18,
        "address": "0X94671A3CEE8C7A12EA72602978D1BB84E920EFB2"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0X8ED10E4E307822B969BCDAFFD49095235F6F892B": {
    "name": "Curve.fi Factory USD Metapool: ELONX POOL",
    "address": "0X8ED10E4E307822B969BCDAFFD49095235F6F892B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ELONX",
        "decimals": 0,
        "address": "0X815B4CE34FAC32B951BD26EA85901E3B834204B6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ELONX",
        "decimals": 0,
        "address": "0X815B4CE34FAC32B951BD26EA85901E3B834204B6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3A70DFA7D2262988064A2D051DD47521E43C9BDD": {
    "name": "Curve.fi Factory USD Metapool: Bean",
    "address": "0X3A70DFA7D2262988064A2D051DD47521E43C9BDD",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BEAN",
        "decimals": 6,
        "address": "0XDC59AC4FEFA32293A95889DC396682858D52E5DB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BEAN",
        "decimals": 6,
        "address": "0XDC59AC4FEFA32293A95889DC396682858D52E5DB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X7ABD51BBA7F9F6AE87AC77E1EA1C5783ADA56E5C": {
    "name": "Curve.fi Factory USD Metapool: USDV",
    "address": "0X7ABD51BBA7F9F6AE87AC77E1EA1C5783ADA56E5C",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDV",
        "decimals": 18,
        "address": "0XEA3FB6F331735252E7BFB0B24B3B761301293DBE"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDV",
        "decimals": 18,
        "address": "0XEA3FB6F331735252E7BFB0B24B3B761301293DBE"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X5B78B93FA851C357586915C7BA7258B762EB1BA0": {
    "name": "Curve.fi Factory USD Metapool: PAR/USDC",
    "address": "0X5B78B93FA851C357586915C7BA7258B762EB1BA0",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PAR",
        "decimals": 18,
        "address": "0X68037790A0229E9CE6EAA8A99EA92964106C4703"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PAR",
        "decimals": 18,
        "address": "0X68037790A0229E9CE6EAA8A99EA92964106C4703"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X0FAFAFD3C393EAD5F5129CFC7E0E12367088C473": {
    "name": "Curve.fi Factory USD Metapool: baoUSD",
    "address": "0X0FAFAFD3C393EAD5F5129CFC7E0E12367088C473",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BaoUSD",
        "decimals": 18,
        "address": "0X7945B0A6674B175695E5D1D08AE1E6F13744ABB0"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BaoUSD",
        "decimals": 18,
        "address": "0X7945B0A6674B175695E5D1D08AE1E6F13744ABB0"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XD05CE4AB1F4FB0C0E1B65EBE3ED7F2DCFC6CCF20": {
    "name": "Curve.fi Factory USD Metapool: sUSD Metapool",
    "address": "0XD05CE4AB1F4FB0C0E1B65EBE3ED7F2DCFC6CCF20",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X97AEB34AC6561146DD9CE191EFD5634F6465DEF4": {
    "name": "Curve.fi Factory USD Metapool: AETHV1",
    "address": "0X97AEB34AC6561146DD9CE191EFD5634F6465DEF4",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "aETH",
        "decimals": 18,
        "address": "0X3A3A65AAB0DD2A17E3F1947BA16138CD37D08C04"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "aETH",
        "decimals": 18,
        "address": "0X3A3A65AAB0DD2A17E3F1947BA16138CD37D08C04"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X9462F2B3C9BEEA8AFC334CDB1D1382B072E494EA": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tGAMMA/GAMMA",
    "address": "0X9462F2B3C9BEEA8AFC334CDB1D1382B072E494EA",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tGAMMA",
        "decimals": 18,
        "address": "0X2FC6E9C1B2C07E18632EFE51879415A580AD22E1"
      },
      "1": {
        "index": 1,
        "name": "GAMMA",
        "decimals": 18,
        "address": "0X6BEA7CFEF803D1E3D5F7C0103F7DED065644E197"
      }
    },
    "underlyingCoins": null
  },
  "0X50B0D9171160D6EB8AA39E090DA51E7E078E81C4": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tSNX/SNX",
    "address": "0X50B0D9171160D6EB8AA39E090DA51E7E078E81C4",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tSNX",
        "decimals": 18,
        "address": "0XEFF721EAE19885E17F5B80187D6527AAD3FFC8DE"
      },
      "1": {
        "index": 1,
        "name": "SNX",
        "decimals": 18,
        "address": "0XC011A73EE8576FB46F5E1C5751CA3B9FE0AF2A6F"
      }
    },
    "underlyingCoins": null
  },
  "0X447DDD4960D9FDBF6AF9A790560D0AF76795CB08": {
    "name": "Curve.fi Factory Plain Pool: rETH/wstETH",
    "address": "0X447DDD4960D9FDBF6AF9A790560D0AF76795CB08",
    "assetType": "1",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "rETH",
        "decimals": 18,
        "address": "0XAE78736CD615F374D3085123A210448E74FC6393"
      },
      "1": {
        "index": 1,
        "name": "wstETH",
        "decimals": 18,
        "address": "0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0"
      }
    },
    "underlyingCoins": null
  },
  "0XCAF8703F8664731CED11F63BB0570E53AB4600A9": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tAPW/APW",
    "address": "0XCAF8703F8664731CED11F63BB0570E53AB4600A9",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tAPW",
        "decimals": 18,
        "address": "0XDC0B02849BB8E0F126A216A2840275DA829709B0"
      },
      "1": {
        "index": 1,
        "name": "APW",
        "decimals": 18,
        "address": "0X4104B135DBC9609FC1A9490E61369036497660C8"
      }
    },
    "underlyingCoins": null
  },
  "0X01FE650EF2F8E2982295489AE6ADC1413BF6011F": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tTCR/TCR",
    "address": "0X01FE650EF2F8E2982295489AE6ADC1413BF6011F",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tTCR",
        "decimals": 18,
        "address": "0X15A629F0665A3EB97D7AE9A7CE7ABF73AEB79415"
      },
      "1": {
        "index": 1,
        "name": "TCR",
        "decimals": 18,
        "address": "0X9C4A4204B79DD291D6B6571C5BE8BBCD0622F050"
      }
    },
    "underlyingCoins": null
  },
  "0XC250B22D15E43D95FBE27B12D98B6098F8493EAC": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tFOX/FOX",
    "address": "0XC250B22D15E43D95FBE27B12D98B6098F8493EAC",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tFOX",
        "decimals": 18,
        "address": "0X808D3E6B23516967CEAE4F17A5F9038383ED5311"
      },
      "1": {
        "index": 1,
        "name": "FOX",
        "decimals": 18,
        "address": "0XC770EEFAD204B5180DF6A14EE197D99D808EE52D"
      }
    },
    "underlyingCoins": null
  },
  "0X0437AC6109E8A366A1F4816EDF312A36952DB856": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tSUSHI/SUSHI",
    "address": "0X0437AC6109E8A366A1F4816EDF312A36952DB856",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tSUSHI",
        "decimals": 18,
        "address": "0XF49764C9C5D644ECE6AE2D18FFD9F1E902629777"
      },
      "1": {
        "index": 1,
        "name": "SUSHI",
        "decimals": 18,
        "address": "0X6B3595068778DD592E39A122F4F5A5CF09C90FE2"
      }
    },
    "underlyingCoins": null
  },
  "0X9001A452D39A8710D27ED5C2E10431C13F5FBA74": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tALCX/ALCX",
    "address": "0X9001A452D39A8710D27ED5C2E10431C13F5FBA74",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tALCX",
        "decimals": 18,
        "address": "0XD3B5D9A561C293FB42B446FE7E237DAA9BF9AA84"
      },
      "1": {
        "index": 1,
        "name": "ALCX",
        "decimals": 18,
        "address": "0XDBDB4D16EDA451D0503B854CF79D55697F90C8DF"
      }
    },
    "underlyingCoins": null
  },
  "0X961226B64AD373275130234145B96D100DC0B655": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tFXS/FXS",
    "address": "0X961226B64AD373275130234145B96D100DC0B655",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tFXS",
        "decimals": 18,
        "address": "0XADF15EC41689FC5B6DCA0DB7C53C9BFE7981E655"
      },
      "1": {
        "index": 1,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      }
    },
    "underlyingCoins": null
  },
  "0XE7E4366F6ED6AFD23E88154C00B532BDC0352333": {
    "name": "Curve.fi Factory Plain Pool: Test Only",
    "address": "0XE7E4366F6ED6AFD23E88154C00B532BDC0352333",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tFXS",
        "decimals": 18,
        "address": "0XADF15EC41689FC5B6DCA0DB7C53C9BFE7981E655"
      },
      "1": {
        "index": 1,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      }
    },
    "underlyingCoins": null
  },
  "0X9809F2B973BDB056D24BC2B6571EA1F23DB4E861": {
    "name": "Curve.fi Factory USD Metapool: GOLD/BSD",
    "address": "0X9809F2B973BDB056D24BC2B6571EA1F23DB4E861",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BSD",
        "decimals": 18,
        "address": "0X804C23B6CCFA0A1FAE1A81C9964F42AE689C790E"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BSD",
        "decimals": 18,
        "address": "0X804C23B6CCFA0A1FAE1A81C9964F42AE689C790E"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X04ECD49246BF5143E43E2305136C46AEB6FAD400": {
    "name": "Curve.fi Factory Plain Pool: DYDX/stkDYDX",
    "address": "0X04ECD49246BF5143E43E2305136C46AEB6FAD400",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DYDX",
        "decimals": 18,
        "address": "0X92D6C1E31E14520E676A687F0A93788B716BEFF5"
      },
      "1": {
        "index": 1,
        "name": "stkDYDX",
        "decimals": 18,
        "address": "0X65F7BA4EC257AF7C55FD5854E5F6356BBD0FB8EC"
      }
    },
    "underlyingCoins": null
  },
  "0XC9467E453620F16B57A34A770C6BCEBECE002587": {
    "name": "Curve.fi Factory BTC Metapool: pbtc",
    "address": "0XC9467E453620F16B57A34A770C6BCEBECE002587",
    "assetType": "2",
    "implementationAddress": "0XC6A8466D128FBFD34ADA64A9FFFCE325D57C9A52",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pBTC",
        "decimals": 18,
        "address": "0X62199B909FB8B8CF870F97BEF2CE6783493C4908"
      },
      "1": {
        "index": 1,
        "name": "sbtcCrv",
        "decimals": 18,
        "address": "0X075B1BB99792C9E1041BA13AFEF80C91A1E70FB3"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "pBTC",
        "decimals": 18,
        "address": "0X62199B909FB8B8CF870F97BEF2CE6783493C4908"
      },
      "1": {
        "index": 1,
        "name": "renBTC",
        "decimals": 8,
        "address": "0XEB4C2781E4EBA804CE9A9803C67D0893436BB27D"
      },
      "2": {
        "index": 2,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "3": {
        "index": 3,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X8C524635D52BD7B1BD55E062303177A7D916C046": {
    "name": "Curve.fi Factory Plain Pool: sdFXS",
    "address": "0X8C524635D52BD7B1BD55E062303177A7D916C046",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      },
      "1": {
        "index": 1,
        "name": "sdFXS",
        "decimals": 18,
        "address": "0X402F878BDD1F5C66FDAF0FABABCF74741B68AC36"
      }
    },
    "underlyingCoins": null
  },
  "0X48FF31BBBD8AB553EBE7CBD84E1EA3DBA8F54957": {
    "name": "Curve.fi Factory Plain Pool: sdANGLE",
    "address": "0X48FF31BBBD8AB553EBE7CBD84E1EA3DBA8F54957",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ANGLE",
        "decimals": 18,
        "address": "0X31429D1856AD1377A8A0079410B297E1A9E214C2"
      },
      "1": {
        "index": 1,
        "name": "sdANGLE",
        "decimals": 18,
        "address": "0X752B4C6E92D96467FE9B9A2522EF07228E00F87C"
      }
    },
    "underlyingCoins": null
  },
  "0X9CA41A2DAB3CEE15308998868CA644E2E3BE5C59": {
    "name": "Curve.fi Factory Plain Pool: dfxCAD/CADC",
    "address": "0X9CA41A2DAB3CEE15308998868CA644E2E3BE5C59",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "dfxCAD",
        "decimals": 18,
        "address": "0XFE32747D0251BA92BCB80B6D16C8257ECF25AB1C"
      },
      "1": {
        "index": 1,
        "name": "CADC",
        "decimals": 18,
        "address": "0XCADC0ACD4B445166F12D2C07EAC6E2544FBE2EEF"
      }
    },
    "underlyingCoins": null
  },
  "0XD652C40FBB3F06D6B58CB9AA9CFF063EE63D465D": {
    "name": "Curve.fi Factory Plain Pool: Bean-LUSD",
    "address": "0XD652C40FBB3F06D6B58CB9AA9CFF063EE63D465D",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BEAN",
        "decimals": 6,
        "address": "0XDC59AC4FEFA32293A95889DC396682858D52E5DB"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0XD1011B637F979A5D9093DF1B32E7736C289024F5": {
    "name": "Curve.fi Factory Plain Pool: Curve sETH2-stETH",
    "address": "0XD1011B637F979A5D9093DF1B32E7736C289024F5",
    "assetType": "1",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sETH2",
        "decimals": 18,
        "address": "0XFE2E637202056D30016725477C5DA089AB0A043A"
      },
      "1": {
        "index": 1,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      }
    },
    "underlyingCoins": null
  },
  "0XE95E4C2DAC312F31DC605533D5A4D0AF42579308": {
    "name": "Curve.fi Factory Plain Pool: sETH2/stETH",
    "address": "0XE95E4C2DAC312F31DC605533D5A4D0AF42579308",
    "assetType": "1",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sETH2",
        "decimals": 18,
        "address": "0XFE2E637202056D30016725477C5DA089AB0A043A"
      },
      "1": {
        "index": 1,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      }
    },
    "underlyingCoins": null
  },
  "0X6577B46A566ADE492AD551A315C04DE3FBE3DBFA": {
    "name": "Curve.fi Factory Plain Pool: TriCRV",
    "address": "0X6577B46A566ADE492AD551A315C04DE3FBE3DBFA",
    "assetType": "3",
    "implementationAddress": "0XE5F4B89E0A16578B3E0E7581327BDB4C712E44DE",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cCRV",
        "decimals": 18,
        "address": "0XD6ECDFD41DDB7167F3ED9B37F33FB24D57543E26"
      },
      "1": {
        "index": 1,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      },
      "2": {
        "index": 2,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      }
    },
    "underlyingCoins": null
  },
  "0XDB8CC7ECED700A4BFFDE98013760FF31FF9408D8": {
    "name": "Curve.fi Factory USD Metapool: fiat",
    "address": "0XDB8CC7ECED700A4BFFDE98013760FF31FF9408D8",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FIAT",
        "decimals": 18,
        "address": "0X586AA273F262909EEF8FA02D90AB65F5015E0516"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "FIAT",
        "decimals": 18,
        "address": "0X586AA273F262909EEF8FA02D90AB65F5015E0516"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X323B3A6E7A71C1B8C257606EF0364D61DF8AA525": {
    "name": "Curve.fi Factory Plain Pool: sdCRV",
    "address": "0X323B3A6E7A71C1B8C257606EF0364D61DF8AA525",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "sdCRV",
        "decimals": 18,
        "address": "0XD1B5651E55D4CEED36251C61C50C889B36F6ABB5"
      }
    },
    "underlyingCoins": null
  },
  "0XF7B55C3732AD8B2C2DA7C24F30A69F55C54FB717": {
    "name": "Curve.fi Factory Plain Pool: sdCRV",
    "address": "0XF7B55C3732AD8B2C2DA7C24F30A69F55C54FB717",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "sdCRV",
        "decimals": 18,
        "address": "0XD1B5651E55D4CEED36251C61C50C889B36F6ABB5"
      }
    },
    "underlyingCoins": null
  },
  "0XF74BEC4BCF432A17470E9C4F71542F2677B9AF6A": {
    "name": "Curve.fi Factory Plain Pool: mCRV-CRV",
    "address": "0XF74BEC4BCF432A17470E9C4F71542F2677B9AF6A",
    "assetType": "0",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "mCRV",
        "decimals": 18,
        "address": "0X79317218DE52DFA2A233A3AEED098161889418C7"
      },
      "1": {
        "index": 1,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      }
    },
    "underlyingCoins": null
  },
  "0X5A59FD6018186471727FAAEAE4E57890ABC49B08": {
    "name": "Curve.fi Factory Plain Pool: FRAX/ARTH.usd",
    "address": "0X5A59FD6018186471727FAAEAE4E57890ABC49B08",
    "assetType": "0",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ARTH.usd",
        "decimals": 18,
        "address": "0X973F054EDBECD287209C36A2651094FA52F99A71"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0XEB07FCD7A8627281845BA3ACBED24435802D4B52": {
    "name": "Curve.fi Factory USD Metapool: PUSd",
    "address": "0XEB07FCD7A8627281845BA3ACBED24435802D4B52",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8EE017541375F6BCD802BA119BDDC94DAD6911A1": {
    "name": "Curve.fi Factory USD Metapool: PUSd",
    "address": "0X8EE017541375F6BCD802BA119BDDC94DAD6911A1",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XAA6A4F8DDCCA7D3B9E7AD38C8338A2FCFDB1E713": {
    "name": "Curve.fi Factory Plain Pool: CLever CVX",
    "address": "0XAA6A4F8DDCCA7D3B9E7AD38C8338A2FCFDB1E713",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "clevCVX",
        "decimals": 18,
        "address": "0XF05E58FCEA29AB4DA01A495140B349F8410BA904"
      }
    },
    "underlyingCoins": null
  },
  "0X8C1DE7A8F8852197B109DAF75A6FBB685C013315": {
    "name": "Curve.fi Factory Plain Pool: CLever CVX",
    "address": "0X8C1DE7A8F8852197B109DAF75A6FBB685C013315",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "clevCVX",
        "decimals": 18,
        "address": "0XF05E58FCEA29AB4DA01A495140B349F8410BA904"
      }
    },
    "underlyingCoins": null
  },
  "0XE6B5CC1B4B47305C58392CE3D359B10282FC36EA": {
    "name": "Curve.fi Factory USD Metapool: USDD/3CRV",
    "address": "0XE6B5CC1B4B47305C58392CE3D359B10282FC36EA",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDD",
        "decimals": 18,
        "address": "0X0C10BF8FCB7BF5412187A595AB97A3609160B5C6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDD",
        "decimals": 18,
        "address": "0X0C10BF8FCB7BF5412187A595AB97A3609160B5C6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X828B154032950C8FF7CF8085D841723DB2696056": {
    "name": "Curve.fi Factory Plain Pool: stETH concentrated",
    "address": "0X828B154032950C8FF7CF8085D841723DB2696056",
    "assetType": "6",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "WETH",
        "decimals": 18,
        "address": "0XC02AAA39B223FE8D0A0E5C4F27EAD9083C756CC2"
      },
      "1": {
        "index": 1,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      }
    },
    "underlyingCoins": null
  },
  "0X6F80B9543DD5A0408F162FE2A1675DB70A2CB77D": {
    "name": "Curve.fi Factory USD Metapool: $Pc/ETH",
    "address": "0X6F80B9543DD5A0408F162FE2A1675DB70A2CB77D",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "$Pc",
        "decimals": 18,
        "address": "0X2FE269292F74F0A98C5786088317B4F86313C211"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "$Pc",
        "decimals": 18,
        "address": "0X2FE269292F74F0A98C5786088317B4F86313C211"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBF5D9DECCCC762FA7B5EB9FAC668C803D42D97B6": {
    "name": "Curve.fi Factory USD Metapool: 3CRVUST",
    "address": "0XBF5D9DECCCC762FA7B5EB9FAC668C803D42D97B6",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 18,
        "address": "0XA47C8BF37F92ABED4A126BDA807A7B7498661ACD"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "UST",
        "decimals": 18,
        "address": "0XA47C8BF37F92ABED4A126BDA807A7B7498661ACD"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X1977870A4C18A728C19DD4EB6542451DF06E0A4B": {
    "name": "Curve.fi Factory Plain Pool: ApeUSD-FRAX",
    "address": "0X1977870A4C18A728C19DD4EB6542451DF06E0A4B",
    "assetType": "0",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ApeUSD",
        "decimals": 18,
        "address": "0XFF709449528B6FB6B88F557F7D93DECE33BCA78D"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      }
    },
    "underlyingCoins": null
  },
  "0X07350D8C30D463179DE6A58764C21558DB66DD9C": {
    "name": "Curve.fi Factory Plain Pool: CPI2Pool",
    "address": "0X07350D8C30D463179DE6A58764C21558DB66DD9C",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "1": {
        "index": 1,
        "name": "VOLT",
        "decimals": 18,
        "address": "0X559EBC30B0E58A45CC9FF573F77EF1E5EB1B3E18"
      }
    },
    "underlyingCoins": null
  },
  "0XC38CA214C7A82B1EE977232F045AFB6D425CFFF0": {
    "name": "Curve.fi Factory Plain Pool: CPI2Pool",
    "address": "0XC38CA214C7A82B1EE977232F045AFB6D425CFFF0",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FPI",
        "decimals": 18,
        "address": "0X5CA135CB8527D76E932F34B5145575F9D8CBE08E"
      },
      "1": {
        "index": 1,
        "name": "VOLT",
        "decimals": 18,
        "address": "0X559EBC30B0E58A45CC9FF573F77EF1E5EB1B3E18"
      }
    },
    "underlyingCoins": null
  },
  "0X5C6A6CF9AE657A73B98454D17986AF41FC7B44EE": {
    "name": "Curve.fi Factory USD Metapool: home",
    "address": "0X5C6A6CF9AE657A73B98454D17986AF41FC7B44EE",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "HOME",
        "decimals": 6,
        "address": "0XB8919522331C59F5C16BDFAA6A121A6E03A91F62"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "HOME",
        "decimals": 6,
        "address": "0XB8919522331C59F5C16BDFAA6A121A6E03A91F62"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X9558B18F021FC3CBA1C9B777603829A42244818B": {
    "name": "Curve.fi Factory USD Metapool: Ubiquity 3Pool",
    "address": "0X9558B18F021FC3CBA1C9B777603829A42244818B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "uAD",
        "decimals": 18,
        "address": "0X0F644658510C95CB46955E55D7BA9DDA9E9FBEC6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "uAD",
        "decimals": 18,
        "address": "0X0F644658510C95CB46955E55D7BA9DDA9E9FBEC6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XEE60F4A3487C07B4570CCFFEF315401C4C5744C8": {
    "name": "Curve.fi Factory USD Metapool: MBSC DeFi (USD)",
    "address": "0XEE60F4A3487C07B4570CCFFEF315401C4C5744C8",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MBSC",
        "decimals": 18,
        "address": "0X8E3CF6478582A87A30CD5E6A10E939B642CD7C16"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MBSC",
        "decimals": 18,
        "address": "0X8E3CF6478582A87A30CD5E6A10E939B642CD7C16"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XF38A67DA7A3A12AA12A9981AE6A79C0FDDDD71AB": {
    "name": "Curve.fi Factory Plain Pool: pxCVX/CVX",
    "address": "0XF38A67DA7A3A12AA12A9981AE6A79C0FDDDD71AB",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pxCVX",
        "decimals": 18,
        "address": "0XBCE0CF87F513102F22232436CCA2CA49E815C3AC"
      },
      "1": {
        "index": 1,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      }
    },
    "underlyingCoins": null
  },
  "0XDADFD00A2BBEB1ABC4936B1644A3033E1B653228": {
    "name": "Curve.fi Factory Plain Pool: Stax Frax/Temple xLP + LP",
    "address": "0XDADFD00A2BBEB1ABC4936B1644A3033E1B653228",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "xFraxTempleLP",
        "decimals": 18,
        "address": "0XBCB8B7FC9197FEDA75C101FA69D3211B5A30DCD9"
      },
      "1": {
        "index": 1,
        "name": "UNI-V2",
        "decimals": 18,
        "address": "0X6021444F1706F15465BEE85463BCC7D7CC17FC03"
      }
    },
    "underlyingCoins": null
  },
  "0XEE98D56F60A5905CBB52348C8719B247DAFE60EC": {
    "name": "Curve.fi Factory Plain Pool: WOMI/OMI",
    "address": "0XEE98D56F60A5905CBB52348C8719B247DAFE60EC",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "wOMI",
        "decimals": 18,
        "address": "0X04969CD041C0CAFB6AC462BD65B536A5BDB3A670"
      },
      "1": {
        "index": 1,
        "name": "OMI",
        "decimals": 18,
        "address": "0XED35AF169AF46A02EE13B9D79EB57D6D68C1749E"
      }
    },
    "underlyingCoins": null
  },
  "0X8116E7C29F60FDACF3954891A038F845565EF5A0": {
    "name": "Curve.fi Factory USD Metapool: OMI/USD",
    "address": "0X8116E7C29F60FDACF3954891A038F845565EF5A0",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "OMI",
        "decimals": 18,
        "address": "0XED35AF169AF46A02EE13B9D79EB57D6D68C1749E"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "OMI",
        "decimals": 18,
        "address": "0XED35AF169AF46A02EE13B9D79EB57D6D68C1749E"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XDE495223F7CD7EE0CDE1ADDBD6836046BBDF3AD3": {
    "name": "Curve.fi Factory USD Metapool: USDS/3CRV",
    "address": "0XDE495223F7CD7EE0CDE1ADDBD6836046BBDF3AD3",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDS",
        "decimals": 18,
        "address": "0X45FDB1B92A649FB6A64EF1511D3BA5BF60044838"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDS",
        "decimals": 18,
        "address": "0X45FDB1B92A649FB6A64EF1511D3BA5BF60044838"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X943B7E761F34866DA12C9B84C99888FE2EF607C5": {
    "name": "Curve.fi Factory Plain Pool: dfxSGD/XSGD",
    "address": "0X943B7E761F34866DA12C9B84C99888FE2EF607C5",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "dfxSGD",
        "decimals": 18,
        "address": "0X52DDDA10EB0ABDB34528329C4AF16D218AB95BD1"
      },
      "1": {
        "index": 1,
        "name": "XSGD",
        "decimals": 6,
        "address": "0X70E8DE73CE538DA2BEED35D14187F6959A8ECA96"
      }
    },
    "underlyingCoins": null
  },
  "0X7E46FD8A30869AA9ED55AF031067DF666EFE87DA": {
    "name": "Curve.fi Factory Plain Pool: yveCRV",
    "address": "0X7E46FD8A30869AA9ED55AF031067DF666EFE87DA",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yveCRV-DAO",
        "decimals": 18,
        "address": "0XC5BDDF9843308380375A611C18B50FB9341F502A"
      }
    },
    "underlyingCoins": null
  },
  "0X63594B2011A0F2616586BF3EEF8096D42272F916": {
    "name": "Curve.fi Factory USD Metapool: USDi",
    "address": "0X63594B2011A0F2616586BF3EEF8096D42272F916",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDI",
        "decimals": 18,
        "address": "0X2A54BA2964C8CD459DC568853F79813A60761B58"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDI",
        "decimals": 18,
        "address": "0X2A54BA2964C8CD459DC568853F79813A60761B58"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X67C7F0A63BA70A2DAC69477B716551FC921AED00": {
    "name": "Curve.fi Factory USD Metapool: 3CRV/lvUSD",
    "address": "0X67C7F0A63BA70A2DAC69477B716551FC921AED00",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "lvUSD",
        "decimals": 18,
        "address": "0X99899399C097A55AFB6B48F797DC5ACFA7D343B1"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "lvUSD",
        "decimals": 18,
        "address": "0X99899399C097A55AFB6B48F797DC5ACFA7D343B1"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X4606326B4DB89373F5377C316D3B0F6E55BC6A20": {
    "name": "Curve.fi Factory USD Metapool: USDDFRAXBP",
    "address": "0X4606326B4DB89373F5377C316D3B0F6E55BC6A20",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDD",
        "decimals": 18,
        "address": "0X0C10BF8FCB7BF5412187A595AB97A3609160B5C6"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDD",
        "decimals": 18,
        "address": "0X0C10BF8FCB7BF5412187A595AB97A3609160B5C6"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XE3C190C57B5959AE62EFE3B6797058B76BA2F5EF": {
    "name": "Curve.fi Factory USD Metapool: sUSDFRAXBP",
    "address": "0XE3C190C57B5959AE62EFE3B6797058B76BA2F5EF",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "sUSD",
        "decimals": 18,
        "address": "0X57AB1EC28D129707052DF4DF418D58A2D46D5F51"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X497CE58F34605B9944E6B15ECAFE6B001206FD25": {
    "name": "Curve.fi Factory USD Metapool: LUSDFRAXBP",
    "address": "0X497CE58F34605B9944E6B15ECAFE6B001206FD25",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X04B727C7E246CA70D496ECF52E6B6280F3C8077D": {
    "name": "Curve.fi Factory USD Metapool: apeUSDFRAXBP",
    "address": "0X04B727C7E246CA70D496ECF52E6B6280F3C8077D",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ApeUSD",
        "decimals": 18,
        "address": "0XFF709449528B6FB6B88F557F7D93DECE33BCA78D"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ApeUSD",
        "decimals": 18,
        "address": "0XFF709449528B6FB6B88F557F7D93DECE33BCA78D"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X2ED1D3E7771D64FEED7AE8F25B4032C8DD2D0B99": {
    "name": "Curve.fi Factory USD Metapool: alUSDFRAXBP",
    "address": "0X2ED1D3E7771D64FEED7AE8F25B4032C8DD2D0B99",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X4E43151B78B5FBB16298C1161FCBF7531D5F8D93": {
    "name": "Curve.fi Factory USD Metapool: GUSDFRAXBP",
    "address": "0X4E43151B78B5FBB16298C1161FCBF7531D5F8D93",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GUSD",
        "decimals": 2,
        "address": "0X056FD409E1D7A124BD7017459DFEA2F387B6D5CD"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GUSD",
        "decimals": 2,
        "address": "0X056FD409E1D7A124BD7017459DFEA2F387B6D5CD"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X8FDB0BB9365A46B145DB80D0B1C5C5E979C84190": {
    "name": "Curve.fi Factory USD Metapool: BUSDFRAXBP",
    "address": "0X8FDB0BB9365A46B145DB80D0B1C5C5E979C84190",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BUSD",
        "decimals": 18,
        "address": "0X4FABB145D64652A948D72533023F6E7A623C7C53"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BUSD",
        "decimals": 18,
        "address": "0X4FABB145D64652A948D72533023F6E7A623C7C53"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X50C8F34CEA0E65535FC2525B637CCD8A07C90896": {
    "name": "Curve.fi Factory USD Metapool: MXNT",
    "address": "0X50C8F34CEA0E65535FC2525B637CCD8A07C90896",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MXNt",
        "decimals": 6,
        "address": "0XED03ED872159E199065401B6D0D487D78D9464AA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MXNt",
        "decimals": 6,
        "address": "0XED03ED872159E199065401B6D0D487D78D9464AA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X642562115CF5A5E72AB517E6448EC8B61843DAC9": {
    "name": "Curve.fi Factory USD Metapool: EUROC",
    "address": "0X642562115CF5A5E72AB517E6448EC8B61843DAC9",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X33BAEDA08B8AFACC4D3D07CF31D49FC1F1F3E893": {
    "name": "Curve.fi Factory USD Metapool: TUSDFRAXBP",
    "address": "0X33BAEDA08B8AFACC4D3D07CF31D49FC1F1F3E893",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TUSD",
        "decimals": 18,
        "address": "0X0000000000085D4780B73119B644AE5ECD22B376"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TUSD",
        "decimals": 18,
        "address": "0X0000000000085D4780B73119B644AE5ECD22B376"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XE7A3B38C39F97E977723BD1239C3470702568E7B": {
    "name": "Curve.fi Factory Plain Pool: Euro Pool",
    "address": "0XE7A3B38C39F97E977723BD1239C3470702568E7B",
    "assetType": "3",
    "implementationAddress": "0X9B52F13DF69D79EC5AAB6D1ACE3157D29B409CC3",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cEUR",
        "decimals": 18,
        "address": "0XEE586E7EAAD39207F0549BC65F19E336942C992F"
      },
      "1": {
        "index": 1,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "2": {
        "index": 2,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      }
    },
    "underlyingCoins": null
  },
  "0XD7C10449A6D134A9ED37E2922F8474EAC6E5C100": {
    "name": "Curve.fi Factory Plain Pool: MATIC/FLEET",
    "address": "0XD7C10449A6D134A9ED37E2922F8474EAC6E5C100",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FLEET",
        "decimals": 6,
        "address": "0XFD56A3DCFC0690881A466AE432D71BB2DB588083"
      },
      "1": {
        "index": 1,
        "name": "MATIC",
        "decimals": 18,
        "address": "0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0"
      }
    },
    "underlyingCoins": null
  },
  "0XB30DA2376F63DE30B42DC055C93FA474F31330A5": {
    "name": "Curve.fi Factory USD Metapool: alUSDFRAXBP",
    "address": "0XB30DA2376F63DE30B42DC055C93FA474F31330A5",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "alUSD",
        "decimals": 18,
        "address": "0XBC6DA0FE9AD5F3B0D58160288917AA56653660E9"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XF70C5C65CF6A28E7A4483F52511E5A29678E4FFD": {
    "name": "Curve.fi Factory Plain Pool: Euro Pool",
    "address": "0XF70C5C65CF6A28E7A4483F52511E5A29678E4FFD",
    "assetType": "3",
    "implementationAddress": "0X9B52F13DF69D79EC5AAB6D1ACE3157D29B409CC3",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cEUR",
        "decimals": 18,
        "address": "0XEE586E7EAAD39207F0549BC65F19E336942C992F"
      },
      "1": {
        "index": 1,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "2": {
        "index": 2,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      }
    },
    "underlyingCoins": null
  },
  "0X649C1B0E70A80210BCFB3C4EB5DDAD175B90BE4D": {
    "name": "Curve.fi Factory Plain Pool: GCD / USDP",
    "address": "0X649C1B0E70A80210BCFB3C4EB5DDAD175B90BE4D",
    "assetType": "0",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GCD",
        "decimals": 18,
        "address": "0X213ECAE6B3CBC0AD976F7D82626546D5B63A71CB"
      },
      "1": {
        "index": 1,
        "name": "USDP",
        "decimals": 18,
        "address": "0X1456688345527BE1F37E9E627DA0837D6F08C925"
      }
    },
    "underlyingCoins": null
  },
  "0X875DF0BA24CCD867F8217593EE27253280772A97": {
    "name": "Curve.fi Factory Plain Pool: stETH/aETHb",
    "address": "0X875DF0BA24CCD867F8217593EE27253280772A97",
    "assetType": "1",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      },
      "1": {
        "index": 1,
        "name": "aETHb",
        "decimals": 18,
        "address": "0XD01EF7C0A5D8C432FC2D1A85C66CF2327362E5C6"
      }
    },
    "underlyingCoins": null
  },
  "0XC69B00366F07840FF939CC9FDF866C3DCCB10804": {
    "name": "Curve.fi Factory Plain Pool: sdLFT",
    "address": "0XC69B00366F07840FF939CC9FDF866C3DCCB10804",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LFT",
        "decimals": 18,
        "address": "0XB620BE8A1949AA9532E6A3510132864EF9BC3F82"
      },
      "1": {
        "index": 1,
        "name": "sdLFT",
        "decimals": 18,
        "address": "0X0879C1A344910C2944C29B892A1CF0C216122C66"
      }
    },
    "underlyingCoins": null
  },
  "0XC9C32CD16BF7EFB85FF14E0C8603CC90F6F2EE49": {
    "name": "Curve.fi Factory USD Metapool: Bean",
    "address": "0XC9C32CD16BF7EFB85FF14E0C8603CC90F6F2EE49",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BEAN",
        "decimals": 6,
        "address": "0XBEA0000029AD1C77D3D5D23BA2D8893DB9D1EFAB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BEAN",
        "decimals": 6,
        "address": "0XBEA0000029AD1C77D3D5D23BA2D8893DB9D1EFAB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X48FCFFA86FB24BDEB45B5739F7CED24095A7C8E8": {
    "name": "Curve.fi Factory USD Metapool: MAI+3pool",
    "address": "0X48FCFFA86FB24BDEB45B5739F7CED24095A7C8E8",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MAI",
        "decimals": 18,
        "address": "0X8D6CEBD76F18E1558D4DB88138E2DEFB3909FAD6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MAI",
        "decimals": 18,
        "address": "0X8D6CEBD76F18E1558D4DB88138E2DEFB3909FAD6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XB3BC1833AC51AACEA92ACD551FBE1AB7EDC59EDF": {
    "name": "Curve.fi Factory USD Metapool: MIMFRAXBP",
    "address": "0XB3BC1833AC51AACEA92ACD551FBE1AB7EDC59EDF",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MIM",
        "decimals": 18,
        "address": "0X99D8A9C45B2ECA8864373A26D1459E3DFF1E17F3"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MIM",
        "decimals": 18,
        "address": "0X99D8A9C45B2ECA8864373A26D1459E3DFF1E17F3"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X7C0316C925E12EBFC55E0F325794B43EAD425157": {
    "name": "Curve.fi Factory Pool: frxETH",
    "address": "0X7C0316C925E12EBFC55E0F325794B43EAD425157",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X00000000008FD4F395EC6F12920BAE9CB6C722E4"
      }
    },
    "underlyingCoins": null
  },
  "0X83D78BF3F861E898CCA47BD076B3839AB5469D70": {
    "name": "Curve.fi Factory Plain Pool: Tokemak tMYC/MYC",
    "address": "0X83D78BF3F861E898CCA47BD076B3839AB5469D70",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tMYC",
        "decimals": 18,
        "address": "0X061AEE9AB655E73719577EA1DF116D7139B2A7E7"
      },
      "1": {
        "index": 1,
        "name": "MYC",
        "decimals": 18,
        "address": "0X4B13006980ACB09645131B91D259EAA111EAF5BA"
      }
    },
    "underlyingCoins": null
  },
  "0X0AACE9B6C491D5CD9F80665A2FCC1AF09E9CCF00": {
    "name": "Curve.fi Factory USD Metapool: handleUSD",
    "address": "0X0AACE9B6C491D5CD9F80665A2FCC1AF09E9CCF00",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "fxUSD",
        "decimals": 18,
        "address": "0X8616E8EA83F048AB9A5EC513C9412DD2993BCE3F"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "fxUSD",
        "decimals": 18,
        "address": "0X8616E8EA83F048AB9A5EC513C9412DD2993BCE3F"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X92DA88E2E6F96CC7C667CD1367BD090ADF3C6053": {
    "name": "Curve.fi Factory USD Metapool: xUSD1",
    "address": "0X92DA88E2E6F96CC7C667CD1367BD090ADF3C6053",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X85F102BE3A76165BE9668BE0BF36E906A488FD33": {
    "name": "Curve.fi Factory USD Metapool: xUSD1",
    "address": "0X85F102BE3A76165BE9668BE0BF36E906A488FD33",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X87872BE0C56EF97156F2617B3083D22423FC62E9": {
    "name": "Curve.fi Factory USD Metapool: xUSD1",
    "address": "0X87872BE0C56EF97156F2617B3083D22423FC62E9",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC5481720517E1B170CF1D19CEAABE07C37896EB2": {
    "name": "Curve.fi Factory USD Metapool: xUSD1",
    "address": "0XC5481720517E1B170CF1D19CEAABE07C37896EB2",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8B3138DF9AA1F60648C65C67D6FF646BE305788B": {
    "name": "Curve.fi Factory USD Metapool: xUSD1",
    "address": "0X8B3138DF9AA1F60648C65C67D6FF646BE305788B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "xUSD1",
        "decimals": 18,
        "address": "0XBFEF1F07018B3A87FC1E12877038F9616512D587"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBA866791F98098DF41C3187D4D5433BE29215C79": {
    "name": "Curve.fi Factory Plain Pool: agEUR/EUROC",
    "address": "0XBA866791F98098DF41C3187D4D5433BE29215C79",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      }
    },
    "underlyingCoins": null
  },
  "0XBA3436FD341F2C8A928452DB3C5A3670D1D5CC73": {
    "name": "Curve.fi Factory Plain Pool: agEUR/EUROC",
    "address": "0XBA3436FD341F2C8A928452DB3C5A3670D1D5CC73",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      }
    },
    "underlyingCoins": null
  },
  "0X6E8D2B6FB24117C675C2FABC524F28CC5D81F18A": {
    "name": "Curve.fi Factory Pool: cbETH",
    "address": "0X6E8D2B6FB24117C675C2FABC524F28CC5D81F18A",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "cbETH",
        "decimals": 18,
        "address": "0XBE9895146F7AF43049CA1C1AE358B0541EA49704"
      }
    },
    "underlyingCoins": null
  },
  "0XC22936D5ECE78C048D6E7FE5D9F77FB6CAA16DBB": {
    "name": "Curve.fi Factory Pool: aETH",
    "address": "0XC22936D5ECE78C048D6E7FE5D9F77FB6CAA16DBB",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "aWETH",
        "decimals": 18,
        "address": "0X030BA81F1C18D280636F32AF80B9AAD02CF0854E"
      }
    },
    "underlyingCoins": null
  },
  "0XB548E49BB6F33A77885836723B73EF9C8DBC047B": {
    "name": "Curve.fi Factory USD Metapool: USDT/DAI",
    "address": "0XB548E49BB6F33A77885836723B73EF9C8DBC047B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "2": {
        "index": 2,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      },
      "3": {
        "index": 3,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "4": {
        "index": 4,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "5": {
        "index": 5,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3DCC3AC50CB42F7E443D7F548DD2C48EDAA8F59A": {
    "name": "Curve.fi Factory USD Metapool: USDT/DAI",
    "address": "0X3DCC3AC50CB42F7E443D7F548DD2C48EDAA8F59A",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "1": {
        "index": 1,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "2": {
        "index": 2,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      },
      "3": {
        "index": 3,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "4": {
        "index": 4,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "5": {
        "index": 5,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X172A54BA45783049216F90F85FE5E5F6BC1C08FE": {
    "name": "Curve.fi Factory USD Metapool: CRV/GNO",
    "address": "0X172A54BA45783049216F90F85FE5E5F6BC1C08FE",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GNO",
        "decimals": 18,
        "address": "0X6810E776880C02933D47DB1B9FC05908E5386B96"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GNO",
        "decimals": 18,
        "address": "0X6810E776880C02933D47DB1B9FC05908E5386B96"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XF08DBD81FCC712004E6943454C83C52DE963CDEC": {
    "name": "Curve.fi Factory USD Metapool: CRV/GNO",
    "address": "0XF08DBD81FCC712004E6943454C83C52DE963CDEC",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GNO",
        "decimals": 18,
        "address": "0X6810E776880C02933D47DB1B9FC05908E5386B96"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GNO",
        "decimals": 18,
        "address": "0X6810E776880C02933D47DB1B9FC05908E5386B96"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6788F608CFE5CFCD02E6152EC79505341E0774BE": {
    "name": "Curve.fi Factory Plain Pool: sdAPW",
    "address": "0X6788F608CFE5CFCD02E6152EC79505341E0774BE",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "APW",
        "decimals": 18,
        "address": "0X4104B135DBC9609FC1A9490E61369036497660C8"
      },
      "1": {
        "index": 1,
        "name": "sdAPW",
        "decimals": 18,
        "address": "0X26F01FE3BE55361B0643BC9D5D60980E37A2770D"
      }
    },
    "underlyingCoins": null
  },
  "0X9FE520E629A7F0DEC773A3199BFE87620E5AEA74": {
    "name": "Curve.fi Factory USD Metapool: DOLA/FRAXBP",
    "address": "0X9FE520E629A7F0DEC773A3199BFE87620E5AEA74",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XB2111B55EDD1CB5D2C18A6817E21D473FE0E5BA1": {
    "name": "Curve.fi Factory USD Metapool: pUSDFRAXBP",
    "address": "0XB2111B55EDD1CB5D2C18A6817E21D473FE0E5BA1",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC47EBD6C0F68FD5963005D28D0BA533750E5C11B": {
    "name": "Curve.fi Factory USD Metapool: pUSDFRAXBP",
    "address": "0XC47EBD6C0F68FD5963005D28D0BA533750E5C11B",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X66E335622AD7A6C9C72C98DBFCCE684996A20EF9": {
    "name": "Curve.fi Factory USD Metapool: MAI+FRAXBP",
    "address": "0X66E335622AD7A6C9C72C98DBFCCE684996A20EF9",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MAI",
        "decimals": 18,
        "address": "0X8D6CEBD76F18E1558D4DB88138E2DEFB3909FAD6"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MAI",
        "decimals": 18,
        "address": "0X8D6CEBD76F18E1558D4DB88138E2DEFB3909FAD6"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XE57180685E3348589E9521AA53AF0BCD497E884D": {
    "name": "Curve.fi Factory USD Metapool: DOLA/FRAXBP",
    "address": "0XE57180685E3348589E9521AA53AF0BCD497E884D",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9D259CA698746586107C234E9E9461D385CA1041": {
    "name": "Curve.fi Factory Plain Pool: sdBPT",
    "address": "0X9D259CA698746586107C234E9E9461D385CA1041",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BPT",
        "decimals": 18,
        "address": "0X0EC9F76202A7061EB9B3A7D6B59D36215A7E37DA"
      },
      "1": {
        "index": 1,
        "name": "sdBPT",
        "decimals": 18,
        "address": "0X825BA129B3EA1DDC265708FCBB9DD660FDD2EF73"
      }
    },
    "underlyingCoins": null
  },
  "0XA23D59FA2505638861525F8CB3005FEC7BD37B5B": {
    "name": "Curve.fi Factory USD Metapool: CRV/ZARP",
    "address": "0XA23D59FA2505638861525F8CB3005FEC7BD37B5B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ZARP",
        "decimals": 18,
        "address": "0X8CB24ED2E4F7E2065F4EB2BE5F6B0064B1919850"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ZARP",
        "decimals": 18,
        "address": "0X8CB24ED2E4F7E2065F4EB2BE5F6B0064B1919850"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XCF95AC3DAECDBF60152A16BDA8D8F8DB7D175B88": {
    "name": "Curve.fi Factory Pool: Frax Ether",
    "address": "0XCF95AC3DAECDBF60152A16BDA8D8F8DB7D175B88",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X0000000000DA9E0E8C2A40510F991CEF596AB682"
      }
    },
    "underlyingCoins": null
  },
  "0XB90A850A0802B9F281BABEA836292AADD1011972": {
    "name": "Curve.fi Factory USD Metapool: GYEN/USDC",
    "address": "0XB90A850A0802B9F281BABEA836292AADD1011972",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X9D0DE74DE698D1BA7273D09193EC20A1F6CB7D6A": {
    "name": "Curve.fi Factory USD Metapool: GYEN/USDC",
    "address": "0X9D0DE74DE698D1BA7273D09193EC20A1F6CB7D6A",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XEC4ACC9322FC4DC853E8F72631D2C95556C68EC0": {
    "name": "Curve.fi Factory USD Metapool: GYEN/USDC",
    "address": "0XEC4ACC9322FC4DC853E8F72631D2C95556C68EC0",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GYEN",
        "decimals": 6,
        "address": "0XC08512927D12348F6620A698105E1BAAC6ECD911"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3D675A52F5B572EB5C61FC5088203AC9B16BFC70": {
    "name": "Curve.fi Factory USD Metapool: Pull",
    "address": "0X3D675A52F5B572EB5C61FC5088203AC9B16BFC70",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X0309A528BBA0394DC4A2CE59123C52E317A54604": {
    "name": "Curve.fi Factory Plain Pool: yCRV",
    "address": "0X0309A528BBA0394DC4A2CE59123C52E317A54604",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yCRV",
        "decimals": 18,
        "address": "0X4C1317326FD8EFDEBDBE5E1CD052010D97723BD6"
      }
    },
    "underlyingCoins": null
  },
  "0XF8048E871DF466BD187078CB38CB914476319D33": {
    "name": "Curve.fi Factory Plain Pool: MAI/LUSD",
    "address": "0XF8048E871DF466BD187078CB38CB914476319D33",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MAI",
        "decimals": 18,
        "address": "0X8D6CEBD76F18E1558D4DB88138E2DEFB3909FAD6"
      },
      "1": {
        "index": 1,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      }
    },
    "underlyingCoins": null
  },
  "0X04F0FAE3DD0A9904F797DEB15C3E523342112811": {
    "name": "Curve.fi Factory USD Metapool: CRV/REN",
    "address": "0X04F0FAE3DD0A9904F797DEB15C3E523342112811",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "REN",
        "decimals": 18,
        "address": "0X408E41876CCCDC0F92210600EF50372656052A38"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "REN",
        "decimals": 18,
        "address": "0X408E41876CCCDC0F92210600EF50372656052A38"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X0B049EB31878176B278EF84A66810D311353DC94": {
    "name": "Curve.fi Factory USD Metapool: CRV/YFI",
    "address": "0X0B049EB31878176B278EF84A66810D311353DC94",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X5239063A86E1E251EE6FB3AB4FB67DEA3A8E1FD2": {
    "name": "Curve.fi Factory USD Metapool: CRV/YFI",
    "address": "0X5239063A86E1E251EE6FB3AB4FB67DEA3A8E1FD2",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X930792BD0FB4593063AD2EE12E86D768BD8DF7A1": {
    "name": "Curve.fi Factory USD Metapool: TPAY/ETH",
    "address": "0X930792BD0FB4593063AD2EE12E86D768BD8DF7A1",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TPAY",
        "decimals": 18,
        "address": "0X3236A63C21FC524A51001EA2627697FDCA86E897"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TPAY",
        "decimals": 18,
        "address": "0X3236A63C21FC524A51001EA2627697FDCA86E897"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XB5FC990637F15BE6420341845A64101B6BBE365D": {
    "name": "Curve.fi Factory USD Metapool: TPAY/ETH",
    "address": "0XB5FC990637F15BE6420341845A64101B6BBE365D",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TPAY",
        "decimals": 18,
        "address": "0X3236A63C21FC524A51001EA2627697FDCA86E897"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TPAY",
        "decimals": 18,
        "address": "0X3236A63C21FC524A51001EA2627697FDCA86E897"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XABE43B60F8337818C21101AB78B5B216789E19DD": {
    "name": "Curve.fi Factory USD Metapool: TPAY/ETH",
    "address": "0XABE43B60F8337818C21101AB78B5B216789E19DD",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TPAY",
        "decimals": 18,
        "address": "0X3236A63C21FC524A51001EA2627697FDCA86E897"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TPAY",
        "decimals": 18,
        "address": "0X3236A63C21FC524A51001EA2627697FDCA86E897"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X453D92C7D4263201C69AACFAF589ED14202D83A4": {
    "name": "Curve.fi Factory Plain Pool: yCRV",
    "address": "0X453D92C7D4263201C69AACFAF589ED14202D83A4",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yCRV",
        "decimals": 18,
        "address": "0XFCC5C47BE19D06BF83EB04298B026F81069FF65B"
      }
    },
    "underlyingCoins": null
  },
  "0X904BE3CE7249447167051F239C832400CA28DE71": {
    "name": "Curve.fi Factory Pool: pETH",
    "address": "0X904BE3CE7249447167051F239C832400CA28DE71",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "pETH",
        "decimals": 18,
        "address": "0X836A808D4828586A69364065A1E064609F5078C7"
      }
    },
    "underlyingCoins": null
  },
  "0X9848482DA3EE3076165CE6497EDA906E66BB85C5": {
    "name": "Curve.fi Factory Pool: pETH",
    "address": "0X9848482DA3EE3076165CE6497EDA906E66BB85C5",
    "assetType": "6",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "pETH",
        "decimals": 18,
        "address": "0X836A808D4828586A69364065A1E064609F5078C7"
      }
    },
    "underlyingCoins": null
  },
  "0XDAB9EEEE607F7952680E9433787E4EDE244A8515": {
    "name": "Curve.fi Factory USD Metapool: matic/ETH",
    "address": "0XDAB9EEEE607F7952680E9433787E4EDE244A8515",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X62CEC7899A9910E48F0DEEAB755429887B6E1979": {
    "name": "Curve.fi Factory USD Metapool: CRV/ETH",
    "address": "0X62CEC7899A9910E48F0DEEAB755429887B6E1979",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X00F93FBF00F97170B6CF295DC58888073CB5C2B8": {
    "name": "Curve.fi Factory USD Metapool: CRV/ETH",
    "address": "0X00F93FBF00F97170B6CF295DC58888073CB5C2B8",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X982DA76F0CCF868B558BD46D7A3B58BC2662D7CC": {
    "name": "Curve.fi Factory USD Metapool: CRV/ETH",
    "address": "0X982DA76F0CCF868B558BD46D7A3B58BC2662D7CC",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X578B27E257050B6011DFDCD69F67696EF24279FC": {
    "name": "Curve.fi Factory USD Metapool: CRV/ETH",
    "address": "0X578B27E257050B6011DFDCD69F67696EF24279FC",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XB4698193BCBC49BE01FCFC67C144EA4927166355": {
    "name": "Curve.fi Factory USD Metapool: CRV/ETH",
    "address": "0XB4698193BCBC49BE01FCFC67C144EA4927166355",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MANA",
        "decimals": 18,
        "address": "0X0F5D2FB29FB7D3CFEE444A200298F468908CC942"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X26C5A2F9B97F4B5FC74B05944B3BB1679D803709": {
    "name": "Curve.fi Factory USD Metapool: test",
    "address": "0X26C5A2F9B97F4B5FC74B05944B3BB1679D803709",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "1INCH",
        "decimals": 18,
        "address": "0X111111111117DC0AA78B770FA6A738034120C302"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "1INCH",
        "decimals": 18,
        "address": "0X111111111117DC0AA78B770FA6A738034120C302"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X188ABEA43270791F96DC9209E239F7B79E61203B": {
    "name": "Curve.fi Factory USD Metapool: US/3CRV",
    "address": "0X188ABEA43270791F96DC9209E239F7B79E61203B",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X333333335A28AE8A579B0F513F7BAF1B7881D9D3"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X333333335A28AE8A579B0F513F7BAF1B7881D9D3"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X28B0CF1BAFB707F2C6826D10CAF6DD901A6540C5": {
    "name": "Curve.fi Factory USD Metapool: Zunami UZD/3CRV",
    "address": "0X28B0CF1BAFB707F2C6826D10CAF6DD901A6540C5",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UZD",
        "decimals": 18,
        "address": "0XE5C987F93734CB44AB03F1B18E30A374254891B6"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "UZD",
        "decimals": 18,
        "address": "0XE5C987F93734CB44AB03F1B18E30A374254891B6"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8E27F0821873B6F5421B6CA75A4C5E1E83D3E77A": {
    "name": "Curve.fi Factory USD Metapool: GCD",
    "address": "0X8E27F0821873B6F5421B6CA75A4C5E1E83D3E77A",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GCD",
        "decimals": 18,
        "address": "0X213ECAE6B3CBC0AD976F7D82626546D5B63A71CB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GCD",
        "decimals": 18,
        "address": "0X213ECAE6B3CBC0AD976F7D82626546D5B63A71CB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6A0861625937CB3629066CC6DB88808A590B9C68": {
    "name": "Curve.fi Factory USD Metapool: GCD",
    "address": "0X6A0861625937CB3629066CC6DB88808A590B9C68",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "GCD",
        "decimals": 18,
        "address": "0X213ECAE6B3CBC0AD976F7D82626546D5B63A71CB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "GCD",
        "decimals": 18,
        "address": "0X213ECAE6B3CBC0AD976F7D82626546D5B63A71CB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X63A1EC86CD45425F1409FABE4C1CF8C5FD32A3B1": {
    "name": "Curve.fi Factory Plain Pool: CLever clevCVX-CVX",
    "address": "0X63A1EC86CD45425F1409FABE4C1CF8C5FD32A3B1",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "clevCVX",
        "decimals": 18,
        "address": "0XF05E58FCEA29AB4DA01A495140B349F8410BA904"
      }
    },
    "underlyingCoins": null
  },
  "0X8E883B9628A0D995AD758597989624EC19F3B971": {
    "name": "Curve.fi Factory Plain Pool: AAVE/SHIB/ETH",
    "address": "0X8E883B9628A0D995AD758597989624EC19F3B971",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "AAVE",
        "decimals": 18,
        "address": "0X7FC66500C84A76AD7E9C93437BFC5AC33E2DDAE9"
      },
      "1": {
        "index": 1,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      }
    },
    "underlyingCoins": null
  },
  "0XD0E24CB3E766581952DBF258B78E89C63A37F5FB": {
    "name": "Curve.fi Factory Plain Pool: TCLever test",
    "address": "0XD0E24CB3E766581952DBF258B78E89C63A37F5FB",
    "assetType": "0",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tCVX",
        "decimals": 18,
        "address": "0X1EE4DE3CD1505DDB2E60C20651A4AB7FFABDC8F6"
      },
      "1": {
        "index": 1,
        "name": "tclevCVX",
        "decimals": 18,
        "address": "0X246BE97FDA42375C39E21377AD80D8290AFDB994"
      }
    },
    "underlyingCoins": null
  },
  "0XF9078FB962A7D13F55D40D49C8AA6472ABD1A5A6": {
    "name": "Curve.fi Factory Plain Pool: Clever clevCVX/CVX",
    "address": "0XF9078FB962A7D13F55D40D49C8AA6472ABD1A5A6",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CVX",
        "decimals": 18,
        "address": "0X4E3FBD56CD56C3E72C1403E103B45DB9DA5B9D2B"
      },
      "1": {
        "index": 1,
        "name": "clevCVX",
        "decimals": 18,
        "address": "0XF05E58FCEA29AB4DA01A495140B349F8410BA904"
      }
    },
    "underlyingCoins": null
  },
  "0X498AD3352CCFAED237A91F6933A92A7A43917B72": {
    "name": "Curve.fi Factory USD Metapool: 1INCH/ETH",
    "address": "0X498AD3352CCFAED237A91F6933A92A7A43917B72",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "1INCH",
        "decimals": 18,
        "address": "0X111111111117DC0AA78B770FA6A738034120C302"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "1INCH",
        "decimals": 18,
        "address": "0X111111111117DC0AA78B770FA6A738034120C302"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XA4C231FA5A02992B740E6169F1FBFCD35D5719E1": {
    "name": "Curve.fi Factory USD Metapool: 1INCH/ETH(TEST)",
    "address": "0XA4C231FA5A02992B740E6169F1FBFCD35D5719E1",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "1INCH",
        "decimals": 18,
        "address": "0X111111111117DC0AA78B770FA6A738034120C302"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "1INCH",
        "decimals": 18,
        "address": "0X111111111117DC0AA78B770FA6A738034120C302"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XD6B03059C882F63268DD3E1A98D8E3CDEE26919C": {
    "name": "Curve.fi Factory USD Metapool: US/3CRV",
    "address": "0XD6B03059C882F63268DD3E1A98D8E3CDEE26919C",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0XE661493A2F94CCA7F0A7C0566290F9C12E69BD52"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0XE661493A2F94CCA7F0A7C0566290F9C12E69BD52"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC0EC468C1B6B94A107B0A83C7A0F6529B388F43A": {
    "name": "Curve.fi Factory USD Metapool: US/3CRV",
    "address": "0XC0EC468C1B6B94A107B0A83C7A0F6529B388F43A",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0XE661493A2F94CCA7F0A7C0566290F9C12E69BD52"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0XE661493A2F94CCA7F0A7C0566290F9C12E69BD52"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XA15E8F7E1E031E4F6F11053C6D320B2A8DC6742C": {
    "name": "Curve.fi Factory USD Metapool: CRV/SHIB",
    "address": "0XA15E8F7E1E031E4F6F11053C6D320B2A8DC6742C",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X6A52E339A4B8ABD15707F882D6ADC05875EC5223": {
    "name": "Curve.fi Factory USD Metapool: CRV/SHIB",
    "address": "0X6A52E339A4B8ABD15707F882D6ADC05875EC5223",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XA98794ACCDB3996C7EF015A354B6E1ADD2D2CE3E": {
    "name": "Curve.fi Factory USD Metapool: CRV/SHIBOK",
    "address": "0XA98794ACCDB3996C7EF015A354B6E1ADD2D2CE3E",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "SHIB",
        "decimals": 18,
        "address": "0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XE82805A9B880E6DC520B6F017537F7781D55217F": {
    "name": "Curve.fi Factory USD Metapool: US/3CRV",
    "address": "0XE82805A9B880E6DC520B6F017537F7781D55217F",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X238533E6674C798FE00CB21CFD127C002B5BFCF1"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X238533E6674C798FE00CB21CFD127C002B5BFCF1"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XBEDCA4252B27CC12ED7DAF393F331886F86CD3CE": {
    "name": "Curve.fi Factory USD Metapool: Zunami UZD/3CRV",
    "address": "0XBEDCA4252B27CC12ED7DAF393F331886F86CD3CE",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UZD",
        "decimals": 18,
        "address": "0X015B94AB2B0A14A96030573FBCD0F3D3D763541F"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "UZD",
        "decimals": 18,
        "address": "0X015B94AB2B0A14A96030573FBCD0F3D3D763541F"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XD511B5C309B2F7256FF7B3D41B029AFB96C7A331": {
    "name": "Curve.fi Factory USD Metapool: US/3CRV",
    "address": "0XD511B5C309B2F7256FF7B3D41B029AFB96C7A331",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X1B78BA1D8C437C9699AF08A8D4FB63AF2930C310"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X1B78BA1D8C437C9699AF08A8D4FB63AF2930C310"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8D35ECE39566D65D06C9207C571934DD3C3A3916": {
    "name": "Curve.fi Factory USD Metapool: 3CRV/cealv",
    "address": "0X8D35ECE39566D65D06C9207C571934DD3C3A3916",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cealv",
        "decimals": 18,
        "address": "0X72DFE359150984C8013105BBBAEE9A152335BD23"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "cealv",
        "decimals": 18,
        "address": "0X72DFE359150984C8013105BBBAEE9A152335BD23"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XA77B5D170F3AEC2F72CA06490A7B9383A70AE5EB": {
    "name": "Curve.fi Factory USD Metapool: BLARG",
    "address": "0XA77B5D170F3AEC2F72CA06490A7B9383A70AE5EB",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X1B78BA1D8C437C9699AF08A8D4FB63AF2930C310"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "US",
        "decimals": 18,
        "address": "0X1B78BA1D8C437C9699AF08A8D4FB63AF2930C310"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X1400E08F1D9F5BC90AE19ACD4BF81BEABC9E79DE": {
    "name": "Curve.fi Factory USD Metapool: XAI + crvFRAX",
    "address": "0X1400E08F1D9F5BC90AE19ACD4BF81BEABC9E79DE",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "XAI",
        "decimals": 18,
        "address": "0XD7C9F0E536DC865AE858B0C0453FE76D13C3BEAC"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "XAI",
        "decimals": 18,
        "address": "0XD7C9F0E536DC865AE858B0C0453FE76D13C3BEAC"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X326290A1B0004EEE78FA6ED4F1D8F4B2523AB669": {
    "name": "Curve.fi Factory USD Metapool: XAI/FRAXBP",
    "address": "0X326290A1B0004EEE78FA6ED4F1D8F4B2523AB669",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "XAI",
        "decimals": 18,
        "address": "0XD7C9F0E536DC865AE858B0C0453FE76D13C3BEAC"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "XAI",
        "decimals": 18,
        "address": "0XD7C9F0E536DC865AE858B0C0453FE76D13C3BEAC"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X9FD7E5B614FA071FF3543B44B68EF7699CEC4AF5": {
    "name": "Curve.fi Factory USD Metapool: cUSD / 3CRV",
    "address": "0X9FD7E5B614FA071FF3543B44B68EF7699CEC4AF5",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cUSD",
        "decimals": 18,
        "address": "0XC22956C3CFEC3EE9A9925ABEE044F05BC47F6632"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "cUSD",
        "decimals": 18,
        "address": "0XC22956C3CFEC3EE9A9925ABEE044F05BC47F6632"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X166BDDEA59C13179796653B8AFF13EEA1BD81A97": {
    "name": "Curve.fi Factory USD Metapool: EastGate Pharmaceuticals",
    "address": "0X166BDDEA59C13179796653B8AFF13EEA1BD81A97",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EGP",
        "decimals": 18,
        "address": "0X5B71952A230BBE5FF6A853EB2068713727B30478"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EGP",
        "decimals": 18,
        "address": "0X5B71952A230BBE5FF6A853EB2068713727B30478"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X613398AECDAF6BCB6EDB8E61E5956794D23F7412": {
    "name": "Curve.fi Factory USD Metapool: EastGate Pharmaceuticals",
    "address": "0X613398AECDAF6BCB6EDB8E61E5956794D23F7412",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EGP",
        "decimals": 18,
        "address": "0X5B71952A230BBE5FF6A853EB2068713727B30478"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EGP",
        "decimals": 18,
        "address": "0X5B71952A230BBE5FF6A853EB2068713727B30478"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC8FBB1CCDF2F94BA01C8B75E0A4FA4C5E1ED6791": {
    "name": "Curve.fi Factory USD Metapool: Test",
    "address": "0XC8FBB1CCDF2F94BA01C8B75E0A4FA4C5E1ED6791",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TEST",
        "decimals": 18,
        "address": "0X44DB359BF01F8B521A6295E4D56991277F410AA2"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TEST",
        "decimals": 18,
        "address": "0X44DB359BF01F8B521A6295E4D56991277F410AA2"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XA500CD4E520682E1B1113E1055D55BACEAD61122": {
    "name": "Curve.fi Factory USD Metapool: cUSD/FRAXBP",
    "address": "0XA500CD4E520682E1B1113E1055D55BACEAD61122",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "cUSD",
        "decimals": 18,
        "address": "0XC22956C3CFEC3EE9A9925ABEE044F05BC47F6632"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "cUSD",
        "decimals": 18,
        "address": "0XC22956C3CFEC3EE9A9925ABEE044F05BC47F6632"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XA77D09743F77052950C4EB4E6547E9665299BECD": {
    "name": "Curve.fi Factory USD Metapool: Titanium Dollar",
    "address": "0XA77D09743F77052950C4EB4E6547E9665299BECD",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "T",
        "decimals": 18,
        "address": "0X6967299E9F3D5312740AA61DEE6E9EA658958E31"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "T",
        "decimals": 18,
        "address": "0X6967299E9F3D5312740AA61DEE6E9EA658958E31"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFD46B54FCFF753BA058A5E9BBB45DCEDC9A71FAB": {
    "name": "Curve.fi Factory USD Metapool: FREE/USDC",
    "address": "0XFD46B54FCFF753BA058A5E9BBB45DCEDC9A71FAB",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FREE",
        "decimals": 18,
        "address": "0X2F141CE366A2462F02CEA3D12CF93E4DCA49E4FD"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "FREE",
        "decimals": 18,
        "address": "0X2F141CE366A2462F02CEA3D12CF93E4DCA49E4FD"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X413928A25D6EA1A26F2625D633207755F67BF97C": {
    "name": "Curve.fi Factory Plain Pool: agEUR/EURe",
    "address": "0X413928A25D6EA1A26F2625D633207755F67BF97C",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "EURe",
        "decimals": 18,
        "address": "0X3231CB76718CDEF2155FC47B5286D82E6EDA273F"
      }
    },
    "underlyingCoins": null
  },
  "0X3C565D9151073E8E5002B61DC570F43A139CAFE7": {
    "name": "Curve.fi Factory USD Metapool: asfasfd",
    "address": "0X3C565D9151073E8E5002B61DC570F43A139CAFE7",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "qxc",
        "decimals": 18,
        "address": "0X28A08C8808DEE574F4A23F9BE326C2D42E587ABA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "qxc",
        "decimals": 18,
        "address": "0X28A08C8808DEE574F4A23F9BE326C2D42E587ABA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X84997FAFC913F1613F51BB0E2B5854222900514B": {
    "name": "Curve.fi Factory USD Metapool: Adamant Dollar",
    "address": "0X84997FAFC913F1613F51BB0E2B5854222900514B",
    "assetType": "0",
    "implementationAddress": "0X5F890841F657D90E081BABDB532A05996AF79FE6",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "A",
        "decimals": 18,
        "address": "0XBE4FE13A73675C49A17F3524602634913C668B4C"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "A",
        "decimals": 18,
        "address": "0XBE4FE13A73675C49A17F3524602634913C668B4C"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X066B6E1E93FA7DCD3F0EB7F8BAC7D5A747CE0BF9": {
    "name": "Curve.fi Factory USD Metapool: REUSD/3Crv",
    "address": "0X066B6E1E93FA7DCD3F0EB7F8BAC7D5A747CE0BF9",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "",
        "decimals": 0,
        "address": "0X6B8734AD31D42F5C05A86594314837C416ADA984"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "",
        "decimals": 0,
        "address": "0X6B8734AD31D42F5C05A86594314837C416ADA984"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XAF25FFE6BA5A8A29665ADCFA6D30C5AE56CA0CD3": {
    "name": "Curve.fi Factory Plain Pool: CRV booster",
    "address": "0XAF25FFE6BA5A8A29665ADCFA6D30C5AE56CA0CD3",
    "assetType": "3",
    "implementationAddress": "0X5BD47EA4494E0F8DE6E3CA10F1C05F55B72466B8",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      },
      "2": {
        "index": 2,
        "name": "yCRV",
        "decimals": 18,
        "address": "0XFCC5C47BE19D06BF83EB04298B026F81069FF65B"
      },
      "3": {
        "index": 3,
        "name": "sdCRV",
        "decimals": 18,
        "address": "0XD1B5651E55D4CEED36251C61C50C889B36F6ABB5"
      }
    },
    "underlyingCoins": null
  },
  "0XF52E248CCFBF189DF0C5A4B15E9F72FA10C7FE59": {
    "name": "Curve.fi Factory USD Metapool: LOL",
    "address": "0XF52E248CCFBF189DF0C5A4B15E9F72FA10C7FE59",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "LUSD",
        "decimals": 18,
        "address": "0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X84C333E94AEA4A51A21F6CF0C7F528C50DC7592C": {
    "name": "Curve.fi Factory USD Metapool: clevUSDFRAXBP",
    "address": "0X84C333E94AEA4A51A21F6CF0C7F528C50DC7592C",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "clevUSD",
        "decimals": 18,
        "address": "0X3C20AC688410BE8F391BE1FB00AFC5C212972F86"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "clevUSD",
        "decimals": 18,
        "address": "0X3C20AC688410BE8F391BE1FB00AFC5C212972F86"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XF275CADBE0343541CE49A03E385F8B234544CDA8": {
    "name": "Curve.fi Factory USD Metapool: EUROC/USDC",
    "address": "0XF275CADBE0343541CE49A03E385F8B234544CDA8",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "EUROC",
        "decimals": 6,
        "address": "0X1ABAEA1F7C830BD89ACC67EC4AF516284B1BC33C"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X79E281BC69A03DABCCD66858C65EF6724E50AEBE": {
    "name": "Curve.fi Factory Plain Pool: sdYFI",
    "address": "0X79E281BC69A03DABCCD66858C65EF6724E50AEBE",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "YFI",
        "decimals": 18,
        "address": "0X0BC529C00C6401AEF6D220BE8C6EA1667F6AD93E"
      },
      "1": {
        "index": 1,
        "name": "sdYFI",
        "decimals": 18,
        "address": "0X97983236BE88107CC8998733EF73D8D969C52E37"
      }
    },
    "underlyingCoins": null
  },
  "0X8D7B9C013F7F614CD870FAD33E260E7A9A1D9B5B": {
    "name": "Curve.fi Factory Plain Pool: DOLA/CUSD",
    "address": "0X8D7B9C013F7F614CD870FAD33E260E7A9A1D9B5B",
    "assetType": "0",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "DOLA",
        "decimals": 18,
        "address": "0X865377367054516E17014CCDED1E7D814EDC9CE4"
      },
      "1": {
        "index": 1,
        "name": "CUSD",
        "decimals": 18,
        "address": "0XC285B7E09A4584D027E5BC36571785B515898246"
      }
    },
    "underlyingCoins": null
  },
  "0X1C899DED01954D0959E034B62A728E7FEBE593B0": {
    "name": "Curve.fi Factory Plain Pool: stLINK/LINK",
    "address": "0X1C899DED01954D0959E034B62A728E7FEBE593B0",
    "assetType": "3",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "stLINK",
        "decimals": 18,
        "address": "0XB8B295DF2CD735B15BE5EB419517AA626FC43CD5"
      },
      "1": {
        "index": 1,
        "name": "LINK",
        "decimals": 18,
        "address": "0X514910771AF9CA656AF840DFF83E8264ECF986CA"
      }
    },
    "underlyingCoins": null
  },
  "0X141ACE5FD4435FD341E396D579C91DF99FED10D4": {
    "name": "Curve.fi Factory USD Metapool: pBTC+sBTC/wBTC",
    "address": "0X141ACE5FD4435FD341E396D579C91DF99FED10D4",
    "assetType": "1",
    "implementationAddress": "0X008CFA89DF5B0C780CA3462FC2602D7F8C7AC315",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pBTC",
        "decimals": 18,
        "address": "0X62199B909FB8B8CF870F97BEF2CE6783493C4908"
      },
      "1": {
        "index": 1,
        "name": "sbtc2Crv",
        "decimals": 18,
        "address": "0X051D7E5609917BD9B73F04BAC0DED8DD46A74301"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "pBTC",
        "decimals": 18,
        "address": "0X62199B909FB8B8CF870F97BEF2CE6783493C4908"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "2": {
        "index": 2,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0XDDA1B81690B530DE3C48B3593923DF0A6C5FE92E": {
    "name": "Curve.fi Factory Plain Pool: maDAI/maUSDC/maUSDT",
    "address": "0XDDA1B81690B530DE3C48B3593923DF0A6C5FE92E",
    "assetType": "0",
    "implementationAddress": "0XE5F4B89E0A16578B3E0E7581327BDB4C712E44DE",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "maDAI",
        "decimals": 18,
        "address": "0X36F8D0D0573AE92326827C4A82FE4CE4C244CAB6"
      },
      "1": {
        "index": 1,
        "name": "maUSDC",
        "decimals": 18,
        "address": "0XA5269A8E31B93FF27B887B56720A25F844DB0529"
      },
      "2": {
        "index": 2,
        "name": "maUSDT",
        "decimals": 18,
        "address": "0XAFE7131A57E44F832CB2DE78ADE38CAD644AAC2F"
      }
    },
    "underlyingCoins": null
  },
  "0X0AD66FEC8DB84F8A3365ADA04AB23CE607AC6E24": {
    "name": "Curve.fi Factory Plain Pool: pitchFXS/FXS",
    "address": "0X0AD66FEC8DB84F8A3365ADA04AB23CE607AC6E24",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pitchFXS",
        "decimals": 18,
        "address": "0X11EBE21E9D7BF541A18E1E3AC94939018CE88F0B"
      },
      "1": {
        "index": 1,
        "name": "FXS",
        "decimals": 18,
        "address": "0X3432B6A60D23CA0DFCA7761B7AB56459D9C964D0"
      }
    },
    "underlyingCoins": null
  },
  "0X2863A328A0B7FC6040F11614FA0728587DB8E353": {
    "name": "Curve.fi Factory USD Metapool: multiBTC+WBTC/sBTC",
    "address": "0X2863A328A0B7FC6040F11614FA0728587DB8E353",
    "assetType": "1",
    "implementationAddress": "0X008CFA89DF5B0C780CA3462FC2602D7F8C7AC315",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "multiBTC",
        "decimals": 8,
        "address": "0X66EFF5221CA926636224650FD3B9C497FF828F7D"
      },
      "1": {
        "index": 1,
        "name": "sbtc2Crv",
        "decimals": 18,
        "address": "0X051D7E5609917BD9B73F04BAC0DED8DD46A74301"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "multiBTC",
        "decimals": 8,
        "address": "0X66EFF5221CA926636224650FD3B9C497FF828F7D"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "2": {
        "index": 2,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X08F9DD845D0C91B918BB90CC5B124F3FD3E98F3A": {
    "name": "Curve.fi Factory USD Metapool: Vanadium Dollar",
    "address": "0X08F9DD845D0C91B918BB90CC5B124F3FD3E98F3A",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "V",
        "decimals": 18,
        "address": "0XEE95CD26291FD1AD5D94BCED4027E396A20D1F38"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "V",
        "decimals": 18,
        "address": "0XEE95CD26291FD1AD5D94BCED4027E396A20D1F38"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XF95AAA7EBB1620E46221B73588502960EF63DBA0": {
    "name": "Curve.fi Factory USD Metapool: tBTC/wBTC/sBTC",
    "address": "0XF95AAA7EBB1620E46221B73588502960EF63DBA0",
    "assetType": "1",
    "implementationAddress": "0X008CFA89DF5B0C780CA3462FC2602D7F8C7AC315",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "tBTC",
        "decimals": 18,
        "address": "0X18084FBA666A33D37592FA2633FD49A74DD93A88"
      },
      "1": {
        "index": 1,
        "name": "sbtc2Crv",
        "decimals": 18,
        "address": "0X051D7E5609917BD9B73F04BAC0DED8DD46A74301"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "tBTC",
        "decimals": 18,
        "address": "0X18084FBA666A33D37592FA2633FD49A74DD93A88"
      },
      "1": {
        "index": 1,
        "name": "WBTC",
        "decimals": 8,
        "address": "0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599"
      },
      "2": {
        "index": 2,
        "name": "sBTC",
        "decimals": 18,
        "address": "0XFE18BE6B3BD88A2D2A7F928D00292E7A9963CFC6"
      }
    }
  },
  "0X1CC1772C8899AD2A35ADE9B9978A56254CFC64A0": {
    "name": "Curve.fi Factory USD Metapool: MTBT/3CRV",
    "address": "0X1CC1772C8899AD2A35ADE9B9978A56254CFC64A0",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X81FD92BFC9E5226910019802C5D3D07093C878ED"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X81FD92BFC9E5226910019802C5D3D07093C878ED"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X968DEE60C67C184F9808510EC92D990E7E6616C2": {
    "name": "Curve.fi Factory USD Metapool: MTBT/3CRV",
    "address": "0X968DEE60C67C184F9808510EC92D990E7E6616C2",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X4B7DC127ECEF167B31E51785F43C3840FC8F966A"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X4B7DC127ECEF167B31E51785F43C3840FC8F966A"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X23AFFC32CBE3C1A2A79376361A2D6F51CA7C9005": {
    "name": "Curve.fi Factory USD Metapool: MTBT/3CRV",
    "address": "0X23AFFC32CBE3C1A2A79376361A2D6F51CA7C9005",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X4B7DC127ECEF167B31E51785F43C3840FC8F966A"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X4B7DC127ECEF167B31E51785F43C3840FC8F966A"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC3B19502F8C02BE75F3F77FD673503520DEB51DD": {
    "name": "Curve.fi Factory USD Metapool: msUSD/FRAXBP",
    "address": "0XC3B19502F8C02BE75F3F77FD673503520DEB51DD",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "msUSD",
        "decimals": 18,
        "address": "0XAB5EB14C09D416F0AC63661E57EDB7AECDB9BEFA"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "msUSD",
        "decimals": 18,
        "address": "0XAB5EB14C09D416F0AC63661E57EDB7AECDB9BEFA"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XC897B98272AA23714464EA2A0BD5180F1B8C0025": {
    "name": "Curve.fi Factory Pool: msETH",
    "address": "0XC897B98272AA23714464EA2A0BD5180F1B8C0025",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "msETH",
        "decimals": 18,
        "address": "0X64351FC9810ADAD17A690E4E1717DF5E7E085160"
      }
    },
    "underlyingCoins": null
  },
  "0XB657B895B265C38C53FFF00166CF7F6A3C70587D": {
    "name": "Curve.fi Factory Plain Pool: alETHfrxETH",
    "address": "0XB657B895B265C38C53FFF00166CF7F6A3C70587D",
    "assetType": "1",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "alETH",
        "decimals": 18,
        "address": "0X0100546F2CD4C9D97F798FFC9755E47865FF7EE6"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XD8A114E127AA5B9F20284FC7A1BDF2BC6853A28D": {
    "name": "Curve.fi Factory USD Metapool: MTBT/3CRV",
    "address": "0XD8A114E127AA5B9F20284FC7A1BDF2BC6853A28D",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X3F92247053508C7662C9A24CCD01229FE0EF7419"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X3F92247053508C7662C9A24CCD01229FE0EF7419"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X83FC85F144BBEC4234E690B6451B105F3D7C60E4": {
    "name": "Curve.fi Factory USD Metapool: PEAR",
    "address": "0X83FC85F144BBEC4234E690B6451B105F3D7C60E4",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PEAR",
        "decimals": 18,
        "address": "0X46CD37F057DC78F6CD2A4EB89BF9F991FB81BAAB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PEAR",
        "decimals": 18,
        "address": "0X46CD37F057DC78F6CD2A4EB89BF9F991FB81BAAB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X0245918FA513E0641509BB519389A49258A2699F": {
    "name": "Curve.fi Factory USD Metapool: ZUSD",
    "address": "0X0245918FA513E0641509BB519389A49258A2699F",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ZUSD",
        "decimals": 6,
        "address": "0XC56C2B7E71B54D38AAB6D52E94A04CBFA8F604FA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ZUSD",
        "decimals": 6,
        "address": "0XC56C2B7E71B54D38AAB6D52E94A04CBFA8F604FA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X37F1D67A5AC27B7C2D0F664E73CCBB82627AC4A5": {
    "name": "Curve.fi Factory USD Metapool: ZUSD",
    "address": "0X37F1D67A5AC27B7C2D0F664E73CCBB82627AC4A5",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ZUSD",
        "decimals": 6,
        "address": "0XC56C2B7E71B54D38AAB6D52E94A04CBFA8F604FA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "ZUSD",
        "decimals": 6,
        "address": "0XC56C2B7E71B54D38AAB6D52E94A04CBFA8F604FA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X27F715999252A6E4D4794B4C9FF2CE3D6EA8FD9B": {
    "name": "Curve.fi Factory Plain Pool: pETHfrxETH",
    "address": "0X27F715999252A6E4D4794B4C9FF2CE3D6EA8FD9B",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "pETH",
        "decimals": 18,
        "address": "0X836A808D4828586A69364065A1E064609F5078C7"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XEC0DE6A9DA9CC464DA0011214D586C21F1FBE6D4": {
    "name": "Curve.fi Factory USD Metapool: MTBT/3CRV Concentrator",
    "address": "0XEC0DE6A9DA9CC464DA0011214D586C21F1FBE6D4",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X3F92247053508C7662C9A24CCD01229FE0EF7419"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X3F92247053508C7662C9A24CCD01229FE0EF7419"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XCD0148E3F3350F4B98A48535F63A38FC630E80F1": {
    "name": "Curve.fi Factory USD Metapool: BBT/ETH",
    "address": "0XCD0148E3F3350F4B98A48535F63A38FC630E80F1",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "BBT",
        "decimals": 18,
        "address": "0X9F77BA354889BF6EB5C275D4AC101E9547F15ADB"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "BBT",
        "decimals": 18,
        "address": "0X9F77BA354889BF6EB5C275D4AC101E9547F15ADB"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X1F98249637BB42EDB072DD2A8ADD44AEB80DA218": {
    "name": "Curve.fi Factory USD Metapool: STBT/3CRV",
    "address": "0X1F98249637BB42EDB072DD2A8ADD44AEB80DA218",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STBT",
        "decimals": 18,
        "address": "0X530824DA86689C9C17CDC2871FF29B058345B44A"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "STBT",
        "decimals": 18,
        "address": "0X530824DA86689C9C17CDC2871FF29B058345B44A"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X7B42D77BD2FEE3C98BAA58D559B83FF3BB4702CF": {
    "name": "Curve.fi Factory USD Metapool: STBT/3CRV",
    "address": "0X7B42D77BD2FEE3C98BAA58D559B83FF3BB4702CF",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STBT",
        "decimals": 18,
        "address": "0X530824DA86689C9C17CDC2871FF29B058345B44A"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "STBT",
        "decimals": 18,
        "address": "0X530824DA86689C9C17CDC2871FF29B058345B44A"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X50122108F7B3B10AC219D066275087D37E4F4A61": {
    "name": "Curve.fi Factory USD Metapool: MTBT/3CRV",
    "address": "0X50122108F7B3B10AC219D066275087D37E4F4A61",
    "assetType": "0",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X3F92247053508C7662C9A24CCD01229FE0EF7419"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "MTBT",
        "decimals": 18,
        "address": "0X3F92247053508C7662C9A24CCD01229FE0EF7419"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFC8C34A3B3CFE1F1DD6DBCCEC4BC5D3103B80FF0": {
    "name": "Curve.fi Factory Plain Pool: FLR/EURS",
    "address": "0XFC8C34A3B3CFE1F1DD6DBCCEC4BC5D3103B80FF0",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "FLR",
        "decimals": 18,
        "address": "0X5E5D9AEEC4A6B775A175B883DCA61E4297C14ECB"
      },
      "1": {
        "index": 1,
        "name": "EURS",
        "decimals": 2,
        "address": "0XDB25F211AB05B1C97D595516F45794528A807AD8"
      }
    },
    "underlyingCoins": null
  },
  "0X386EC09DB6F961B9E28B3DAB174AD9567E57B90C": {
    "name": "Curve.fi Factory Plain Pool: frxETHstETH",
    "address": "0X386EC09DB6F961B9E28B3DAB174AD9567E57B90C",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      },
      "1": {
        "index": 1,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      }
    },
    "underlyingCoins": null
  },
  "0X6D09C6513E620778632D36784F5C3B4B2309BD96": {
    "name": "Curve.fi Factory USD Metapool: USK/FRAXBP",
    "address": "0X6D09C6513E620778632D36784F5C3B4B2309BD96",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USK",
        "decimals": 6,
        "address": "0X1B3C515F58857E141A966B33182F2F3FEECC10E9"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USK",
        "decimals": 6,
        "address": "0X1B3C515F58857E141A966B33182F2F3FEECC10E9"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0XC61557C5D177BD7DC889A3B621EEC333E168F68A": {
    "name": "Curve.fi Factory USD Metapool: REUSD/3Crv",
    "address": "0XC61557C5D177BD7DC889A3B621EEC333E168F68A",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "REUSD",
        "decimals": 18,
        "address": "0X29B41FE7D754B8B43D4060BB43734E436B0B9A33"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "REUSD",
        "decimals": 18,
        "address": "0X29B41FE7D754B8B43D4060BB43734E436B0B9A33"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XE9123CBC5D1EA65301D417193C40A72AC8D53501": {
    "name": "Curve.fi Factory USD Metapool: 3CRV/lvUSD",
    "address": "0XE9123CBC5D1EA65301D417193C40A72AC8D53501",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "lvUSD",
        "decimals": 18,
        "address": "0X94A18D9FE00BAB617FAD8B49B11E9F1F64DB6B36"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "lvUSD",
        "decimals": 18,
        "address": "0X94A18D9FE00BAB617FAD8B49B11E9F1F64DB6B36"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X3685646651FCCC80E7CCE7EE24C5F47ED9B434AC": {
    "name": "Curve.fi Factory USD Metapool: SORA TBC Dollar",
    "address": "0X3685646651FCCC80E7CCE7EE24C5F47ED9B434AC",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TBCD",
        "decimals": 18,
        "address": "0XE2F3D42443605FC4AD5BCE82F0E9BFFFBCFFA6CA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TBCD",
        "decimals": 18,
        "address": "0XE2F3D42443605FC4AD5BCE82F0E9BFFFBCFFA6CA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X110CC323CA53D622469EDD217387E2E6B33F1DF5": {
    "name": "Curve.fi Factory USD Metapool: SORA TBC Dollar",
    "address": "0X110CC323CA53D622469EDD217387E2E6B33F1DF5",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "TBCD",
        "decimals": 18,
        "address": "0XE2F3D42443605FC4AD5BCE82F0E9BFFFBCFFA6CA"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "TBCD",
        "decimals": 18,
        "address": "0XE2F3D42443605FC4AD5BCE82F0E9BFFFBCFFA6CA"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XFFC78332F0DA6FBAABDACFE8054CCBC501EED432": {
    "name": "Curve.fi Factory USD Metapool: USDANT/USDT",
    "address": "0XFFC78332F0DA6FBAABDACFE8054CCBC501EED432",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDANT",
        "decimals": 6,
        "address": "0X1EDF1CF760EBFB6E0B7BD410C2BEBB591C7523DD"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDANT",
        "decimals": 6,
        "address": "0X1EDF1CF760EBFB6E0B7BD410C2BEBB591C7523DD"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0XC71BC7E33510AEA215E4776867148FA25C368795": {
    "name": "Curve.fi Factory USD Metapool: USDANT/USDT",
    "address": "0XC71BC7E33510AEA215E4776867148FA25C368795",
    "assetType": "0",
    "implementationAddress": "0X213BE373FDFF327658139C7DF330817DAD2D5BBE",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "USDANT",
        "decimals": 6,
        "address": "0X1EDF1CF760EBFB6E0B7BD410C2BEBB591C7523DD"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "USDANT",
        "decimals": 6,
        "address": "0X1EDF1CF760EBFB6E0B7BD410C2BEBB591C7523DD"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X8B8DBC5B2A0D07DF180B1186F179F1C6A97C8AAE": {
    "name": "Curve.fi Factory Plain Pool: agEUR/EUROe",
    "address": "0X8B8DBC5B2A0D07DF180B1186F179F1C6A97C8AAE",
    "assetType": "3",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "agEUR",
        "decimals": 18,
        "address": "0X1A7E4E63778B4F12A199C062F3EFDD288AFCBCE8"
      },
      "1": {
        "index": 1,
        "name": "EUROe",
        "decimals": 6,
        "address": "0X820802FA8A99901F52E39ACD21177B0BE6EE2974"
      }
    },
    "underlyingCoins": null
  },
  "0X4D9F9D15101EEC665F77210CB999639F760F831E": {
    "name": "Curve.fi Factory Plain Pool: stETH/frxETH",
    "address": "0X4D9F9D15101EEC665F77210CB999639F760F831E",
    "assetType": "1",
    "implementationAddress": "0X24D937143D3F5CF04C72BA112735151A8CAE2262",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "stETH",
        "decimals": 18,
        "address": "0XAE7AB96520DE3A18E5E111B5EAAB095312D7FE84"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X663AC72A1C3E1C4186CD3DCB184F216291F4878C": {
    "name": "Curve.fi Factory Plain Pool: sETH/frxETH",
    "address": "0X663AC72A1C3E1C4186CD3DCB184F216291F4878C",
    "assetType": "1",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "sETH",
        "decimals": 18,
        "address": "0X5E74C9036FB86BD7ECDCB084A0673EFC32EA31CB"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X97BA76A574BC5709B944BB1887691301C72337CA": {
    "name": "Curve.fi Factory Plain Pool: alETH/frxETH",
    "address": "0X97BA76A574BC5709B944BB1887691301C72337CA",
    "assetType": "1",
    "implementationAddress": "0X6523AC15EC152CB70A334230F6C5D62C5BD963F1",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "alETH",
        "decimals": 18,
        "address": "0X0100546F2CD4C9D97F798FFC9755E47865FF7EE6"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0XAEDA92E6A3B1028EDC139A4AE56EC881F3064D4F": {
    "name": "Curve.fi Factory USD Metapool: eUSD+FRAX/USDC (FRAXBP)",
    "address": "0XAEDA92E6A3B1028EDC139A4AE56EC881F3064D4F",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "eUSD",
        "decimals": 18,
        "address": "0XA0D69E286B938E21CBF7E51D71F6A4C8918F482F"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "eUSD",
        "decimals": 18,
        "address": "0XA0D69E286B938E21CBF7E51D71F6A4C8918F482F"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X428D03774F976F625380403E0C0AD38980943573": {
    "name": "Curve.fi Factory Pool: frxETHt",
    "address": "0X428D03774F976F625380403E0C0AD38980943573",
    "assetType": "1",
    "implementationAddress": "0X6326DEBBAA15BCFE603D831E7D75F4FC10D9B43E",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "ETH",
        "decimals": 18,
        "address": "0XEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
      },
      "1": {
        "index": 1,
        "name": "frxETH",
        "decimals": 18,
        "address": "0X5E8422345238F34275888049021821E8E08CAA1F"
      }
    },
    "underlyingCoins": null
  },
  "0X892D701D94A43BDBCB5EA28891DACA2FA22A690B": {
    "name": "Curve.fi Factory USD Metapool: STBT/3CRV",
    "address": "0X892D701D94A43BDBCB5EA28891DACA2FA22A690B",
    "assetType": "6",
    "implementationAddress": "0X55AA9BF126BCABF0BDC17FA9E39EC9239E1CE7A9",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "STBT",
        "decimals": 18,
        "address": "0X530824DA86689C9C17CDC2871FF29B058345B44A"
      },
      "1": {
        "index": 1,
        "name": "3Crv",
        "decimals": 18,
        "address": "0X6C3F90F043A72FA612CBAC8115EE7E52BDE6E490"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "STBT",
        "decimals": 18,
        "address": "0X530824DA86689C9C17CDC2871FF29B058345B44A"
      },
      "1": {
        "index": 1,
        "name": "DAI",
        "decimals": 18,
        "address": "0X6B175474E89094C44DA98B954EEDEAC495271D0F"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      },
      "3": {
        "index": 3,
        "name": "USDT",
        "decimals": 6,
        "address": "0XDAC17F958D2EE523A2206206994597C13D831EC7"
      }
    }
  },
  "0X99F5ACC8EC2DA2BC0771C32814EFF52B712DE1E5": {
    "name": "Curve.fi Factory Plain Pool: yCRV",
    "address": "0X99F5ACC8EC2DA2BC0771C32814EFF52B712DE1E5",
    "assetType": "3",
    "implementationAddress": "0XC629A01EC23AB04E1050500A3717A2A5C0701497",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "yCRV",
        "decimals": 18,
        "address": "0XFCC5C47BE19D06BF83EB04298B026F81069FF65B"
      }
    },
    "underlyingCoins": null
  },
  "0XE60986759872393A8360A4A7ABEAB3A6E0BA7848": {
    "name": "Curve.fi Factory USD Metapool: pUSDFRAXBP-A100",
    "address": "0XE60986759872393A8360A4A7ABEAB3A6E0BA7848",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "PUSd",
        "decimals": 18,
        "address": "0X466A756E9A7401B5E2444A3FCB3C2C12FBEA0A54"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  },
  "0X5007C634BD59DD211875346A5D0CFD10DFD1CDC0": {
    "name": "Curve.fi Factory Plain Pool: cvxCrv/Crv",
    "address": "0X5007C634BD59DD211875346A5D0CFD10DFD1CDC0",
    "assetType": "3",
    "implementationAddress": "0X4A4D7868390EF5CAC51CDA262888F34BD3025C3F",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      }
    },
    "underlyingCoins": null
  },
  "0X971ADD32EA87F10BD192671630BE3BE8A11B8623": {
    "name": "Curve.fi Factory Plain Pool: cvxCrv/Crv",
    "address": "0X971ADD32EA87F10BD192671630BE3BE8A11B8623",
    "assetType": "6",
    "implementationAddress": "0XC629A01EC23AB04E1050500A3717A2A5C0701497",
    "isMetaPool": false,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "CRV",
        "decimals": 18,
        "address": "0XD533A949740BB3306D119CC777FA900BA034CD52"
      },
      "1": {
        "index": 1,
        "name": "cvxCRV",
        "decimals": 18,
        "address": "0X62B9C7356A2DC64A1969E19C23E4F579F9810AA7"
      }
    },
    "underlyingCoins": null
  },
  "0X68934F60758243EAFAF4D2CFED27BF8 010BEDE3A": {
    "name": "Curve.fi Factory USD Metapool: Zunami UZD/FRAXBP",
    "address": "0X68934F60758243EAFAF4D2CFED27BF8010BEDE3A",
    "assetType": "0",
    "implementationAddress": "0X33BB0E62D5E8C688E645DD46DFB48CD613250067",
    "isMetaPool": true,
    "argMapping": {
      "0": {
        "index": 0,
        "name": "UZD",
        "decimals": 18,
        "address": "0XB40B6608B2743E691C9B54DDBDEE7BF03CD79F1C"
      },
      "1": {
        "index": 1,
        "name": "FRAXBP",
        "decimals": 18,
        "address": "0X3175DF0976DFA876431C2E9EE6BC45B65D3473CC"
      }
    },
    "underlyingCoins": {
      "0": {
        "index": 0,
        "name": "UZD",
        "decimals": 18,
        "address": "0XB40B6608B2743E691C9B54DDBDEE7BF03CD79F1C"
      },
      "1": {
        "index": 1,
        "name": "FRAX",
        "decimals": 18,
        "address": "0X853D955ACEF822DB058EB8505911ED77F175B99E"
      },
      "2": {
        "index": 2,
        "name": "USDC",
        "decimals": 6,
        "address": "0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48"
      }
    }
  }
}


export const curveTokens = [
  {
    "name": "veCRV",
    "address": "0x5f3b5DfEb7B28CDbD7FAba78963EE202a494e2A2",
    "decimals": 18
  }
]

export const curveSynthSwapNFT = {
  address: '0x58A3c68e2D3aAf316239c003779F71aCb870Ee47'
}