import React, { useState, createContext } from "react";
import { Loading } from "features/common";
import { useSelector } from "react-redux";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import moment from "moment";
const defaultDays = 0;
export const PerformanceContext = createContext();

export const PerformanceProvider = ({ children }) => {
  const isDemoSite = useSelector(selectDemoSite);

  const getInitDateRange = () => {
    if (isDemoSite) {
      return {
        from: moment("2024-4-1").utcOffset(0, true).startOf("day"),
        to: moment("2024-7-30").utcOffset(0, true).endOf("day"),
      };
    }
    const today = moment().utc().endOf("day").toDate();
    const lastWeek = moment()
      .utc()
      .subtract(defaultDays, "days")
      .startOf("day")
      .toDate();
    return { from: lastWeek, to: today };
  };
  const initDateData = getInitDateRange();
  const [startDate, setStartDate] = useState(initDateData.from);
  const [endDate, setEndDate] = useState(initDateData.to);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("walletsConnected");
  const [order, setOrder] = useState("desc");
  const [isLoading, setIsLoading] = useState({
    chart: false,
    table: false,
  });
  const [page, setPage] = useState(1);

  return (
    <PerformanceContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        isLoading,
        setIsLoading,
        page,
        setPage,
        search,
        setSearch,
        sort,
        setSort,
        order,
        setOrder,
      }}
      style={{ height: "100%", position: "relative" }}
    >
      <Loading
        open={isLoading.chart || isLoading.table}
        fullScreen={false}
        longWait
      />

      {children}
    </PerformanceContext.Provider>
  );
};
