import React, { useState, useEffect, memo } from "react";
import _, { divide } from "lodash";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableTicketListItem from "./DraggableTicketListItem";

const DraggableTicketList = memo(
  ({ ticketList, onDragEnd, onChangeTicketName, onDeleteTicket }) => {
    const { control, getValues, trigger } = useFormContext();
    const { remove } = useFieldArray({
      control,
      name: "ticketList",
    });

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {ticketList.map((ticket, index) => (
                <DraggableTicketListItem
                  ticket={ticket}
                  index={index}
                  key={ticket._id}
                  onChangeTicketName={onChangeTicketName}
                  onDeleteTicket={onDeleteTicket}
                  remove={remove}
                  control={control}
                  getValues={getValues}
                ></DraggableTicketListItem>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableTicketList;
