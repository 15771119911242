import React, { useContext, useMemo, useCallback, useEffect } from "react";
import _ from "lodash";
import { useTheme } from "@mui/styles";
import {
  StyledAttributionSubtitle,
  StyledAttributionTitle,
  StyledCollapseTitle,
  StyledContainerTitle,
  StyledInputContainer,
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
  StyledWhiteCardContainer,
} from "./CampaignCreation.styles";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  AddButton,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import { AccordionDetails, Collapse } from "@mui/material";
import { getInitCustomLinkSetting } from "../utils/index";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import CampaignOptions from "./SetOptions/CampaignOption";
import IntergrationCover from "./IntergrationCover";
import useCampaignOptions from "./SetOptions/useCampaignOptions";

export default function CustomCampaign({ step, tabIndex, stepName }) {
  const { stepData, setStepValues, isTrackingActive, isTracker } = useContext(
    CampaignCreationContext
  );
  const { campaignContentSets } = stepData;
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { fetchOptions } = useCampaignOptions();

  useEffect(() => {
    fetchOptions();
  }, []);

  function handleChangeCampaignContentSets(label, value) {
    const temp = [...campaignContentSets];
    temp[tabIndex][label] = value;
    setStepValues({ campaignContentSets: temp });
  }

  const handleChangeLinkSettings = useCallback(
    (settingIndex, key, obj) => {
      const temp = [...campaignContentSets];
      if (key === "source" || key === "isOpenTerm") {
        temp[tabIndex].customLinkSettings[settingIndex][key] = obj;
      } else {
        temp[tabIndex].customLinkSettings[settingIndex][key].push(obj);
      }
      setStepValues({ campaignContentSets: temp });
    },
    [campaignContentSets, tabIndex, setStepValues]
  );

  function handleReset(settingIndex) {
    const temp = [...campaignContentSets];
    temp[tabIndex].customLinkSettings[settingIndex] =
      getInitCustomLinkSetting();
    setStepValues({ campaignContentSets: temp });
  }
  function handleRemoveLinkSetting(settingIndex, key, obj) {
    const temp = [...campaignContentSets];
    const filter = temp[tabIndex].customLinkSettings[settingIndex][key].filter(
      (item) => item._id !== obj._id
    );
    temp[tabIndex].customLinkSettings[settingIndex][key] = filter;

    setStepValues({ campaignContentSets: temp });
  }

  function addLinkSetting() {
    const temp = [...campaignContentSets];
    temp[tabIndex].customLinkSettings.push(getInitCustomLinkSetting());
    setStepValues({ campaignContentSets: temp });
  }

  function deleteLinkSetting(index) {
    const temp = [...campaignContentSets];
    temp[tabIndex].customLinkSettings.splice(index, 1);
    setStepValues({ campaignContentSets: temp });
  }

  return (
    <>
      <Box sx={{ width: "100%", position: "relative" }}>
        <IntergrationCover show={!isTrackingActive} type={"tracker"} />
        {/* Destination */}
        <StyledWhiteCardContainer style={{ marginTop: "20px" }}>
          <StyledContainerTitle>
            {formatMessage({ id: "campaignCreate.custom.destination" })}
          </StyledContainerTitle>
          <div className="mhr" style={{ margin: 0 }}></div>
          <StyledInputContainer>
            <InputField
              title={formatMessage({ id: "marketingSettings.domain.title" })}
              isRequired={true}
              onChange={(e) =>
                handleChangeCampaignContentSets(
                  "customWebsiteUrl",
                  e.target.value
                )
              }
              value={campaignContentSets[tabIndex].customWebsiteUrl}
              prefixWord="https://"
              placeholder="example.com"
            />
          </StyledInputContainer>
        </StyledWhiteCardContainer>
        {/* Attribution Link */}
        <StyledAttributionTitle sx={{ marginTop: "20px" }}>
          {formatMessage({
            id: "campaignBuild.secondStep.attribution.linksSetting.title",
          })}
        </StyledAttributionTitle>
        <StyledAttributionSubtitle>
          {formatMessage({
            id: "campaignBuild.secondStep.attribution.linksSetting.description",
          })}
        </StyledAttributionSubtitle>
        {/* Link Setting */}
        {campaignContentSets[tabIndex].customLinkSettings?.map(
          (linkSetting, settingIndex) => {
            const linkAmount =
              linkSetting.mediums.length *
              (linkSetting.terms.length || 1) *
              (linkSetting.contents.length || 1);

            return (
              <StyledWhiteAccordion
                key={settingIndex}
                defaultExpanded
                disableGutters={true}
              >
                <StyledWhiteAccordionSummary
                  expandIcon={
                    <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
                  }
                  aria-controls={`panel${settingIndex}-content`}
                  id={`panel${settingIndex}-header`}
                  style={{ padding: 0, paddingRight: "20px", margin: 0 }}
                >
                  <StyledContainerTitle>
                    {formatMessage({ id: "campaignCreate.custom.linkSetting" })}
                    :{" "}
                    <span style={{ color: "#7B61FF", margin: "0 4px" }}>
                      {linkAmount}
                    </span>
                    {formatMessage({ id: "campaign.commonWord.link" })}
                    {campaignContentSets[tabIndex].customLinkSettings.length >
                      1 && (
                      <IconButton
                        className="list-item-btn"
                        size={14}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteLinkSetting(settingIndex);
                        }}
                        color={"#A5A5A5"}
                        sx={{ marginLeft: "4px" }}
                      >
                        <i className="meta-crm-icon-ic_trash font-size-18" />
                      </IconButton>
                    )}
                  </StyledContainerTitle>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "A5A5A5",
                      marginRight: "13px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReset(settingIndex);
                    }}
                  >
                    <i
                      className="meta-crm-icon-ic_refresh font-size-20"
                      style={{ marginRight: "4px", rotate: "-90deg" }}
                    />
                    <p style={{ color: "#A5A5A5" }}>
                      {formatMessage({ id: "global.btn.reset" })}
                    </p>
                  </div>
                </StyledWhiteAccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <div className="mhr" style={{ margin: 0 }}></div>
                  <StyledInputContainer>
                    <CampaignOptions
                      data={linkSetting}
                      handleRemove={() =>
                        handleChangeLinkSettings(settingIndex, "source", "")
                      }
                      handleClick={(objValue) =>
                        handleChangeLinkSettings(
                          settingIndex,
                          "source",
                          objValue
                        )
                      }
                      type="sources"
                    />
                    <CampaignOptions
                      data={linkSetting}
                      handleRemove={(objValue) =>
                        handleRemoveLinkSetting(
                          settingIndex,
                          "mediums",
                          objValue
                        )
                      }
                      handleClick={(objValue) =>
                        handleChangeLinkSettings(
                          settingIndex,
                          "mediums",
                          objValue
                        )
                      }
                      type="mediums"
                    />

                    {/* Campaign Term & Content Title*/}
                    <StyledCollapseTitle
                      open={linkSetting.isOpenTerm}
                      onClick={() => {
                        handleChangeLinkSettings(
                          settingIndex,
                          "isOpenTerm",
                          !linkSetting.isOpenTerm
                        );
                      }}
                    >
                      {formatMessage({
                        id: "campaignCreate.custom.terms&content",
                      })}
                      <i className="meta-crm-icon-ic_arrowLineDown font-size-16" />
                    </StyledCollapseTitle>
                    {/* Campaign Term & Content Collapse Container */}
                    <Collapse
                      in={linkSetting.isOpenTerm}
                      timeout="auto"
                      unmountOnExit
                    >
                      {/* Campaign Term */}
                      <CampaignOptions
                        data={linkSetting}
                        handleRemove={(objValue) =>
                          handleRemoveLinkSetting(
                            settingIndex,
                            "terms",
                            objValue
                          )
                        }
                        handleClick={(objValue) =>
                          handleChangeLinkSettings(
                            settingIndex,
                            "terms",
                            objValue
                          )
                        }
                        type="terms"
                      />
                      <CampaignOptions
                        data={linkSetting}
                        handleRemove={(objValue) =>
                          handleRemoveLinkSetting(
                            settingIndex,
                            "contents",
                            objValue
                          )
                        }
                        handleClick={(objValue) =>
                          handleChangeLinkSettings(
                            settingIndex,
                            "contents",
                            objValue
                          )
                        }
                        type="contents"
                      />
                    </Collapse>
                  </StyledInputContainer>
                </AccordionDetails>
              </StyledWhiteAccordion>
            );
          }
        )}
        <AddButton
          style={{
            marginTop: "20px",
            background: "white",
            padding: 20,
            border: `1px dashed ${theme.customColors.purple[500]}`,
            height: "52px",
            fontWeight: theme.fontWeight.BOLD,
          }}
          color={"#7B61FF"}
          onClick={addLinkSetting}
        >
          {formatMessage({ id: "global.btn.addLinkSetting" })}
        </AddButton>
        {/* Schedule */}
      </Box>
    </>
  );
}
