import React from "react";
import Modal from "@mui/material/Modal";

export default function PureModal({
  open,
  onClose,
  closeBtn = false,
  children,
  maxWidth = "800px",
  padding,
  overflow = "initial",
  slotProps,
  maxHeight = "auto",
}) {
  return (
    <Modal
      open={open}
      className={"modal"}
      onClose={onClose}
      slotProps={slotProps}
    >
      <div
        className="modalPaper"
        style={{
          maxWidth,
          padding,
          position: "relative",
          overflow,
          maxHeight: maxHeight,
        }}
      >
        {closeBtn && (
          <i
            className="meta-crm-icon-ic_cancel font-size-20"
            style={{
              position: "absolute",
              right: "12px",
              top: "12px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        )}
        {children}
      </div>
    </Modal>
  );
}
