import React, { useEffect } from "react";
import _ from "lodash";
import { Loading } from "features/common";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import useInterceptor from "features/home/useInterceptor.hooks";

axios.defaults.withCredentials = true;

const IntegrationConnectSlack = () => {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  const [searchParams] = useSearchParams();
  const tempCode = searchParams.get("code");
  const error = searchParams.get("error");
  const { isInterceptorSetup } = useInterceptor();

  const navigate = useNavigate();

  useEffect(async () => {
    try {
      const entityName = await getCurrentEntity();
      if (error || !tempCode) {
        navigateFallback(entityName);
        return;
      }

      if (!isInterceptorSetup) return;
      navigateIntegrationSlack(entityName);
    } catch (err) {
      console.log(err);
      navigateHome();
    }
  }, [isInterceptorSetup]);

  async function getCurrentEntity() {
    const { data } = await axios.get(apiUrl + `/api/session/getCurrentEntity`);
    const { entityName } = data;
    return entityName;
  }

  const navigateFallback = (entityName) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/general/integration`
      )
    );
  };

  const navigateIntegrationSlack = (entityName) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/general/integration/slack?code=${tempCode}`
      )
    );
  };

  const navigateHome = () => {
    window.location.replace("https://metacrm.inc/");
  };

  return <Loading open={true} fullScreen={true} />;
};

export default IntegrationConnectSlack;
