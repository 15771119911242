import React from "react";
import _ from "lodash";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import useNavigateEntity from "hooks/useNavigateEntity";
import useGetPagePermission from "hooks/useGetPagePermission";

const CampaignHeader = () => {
  const { formatMessage } = useIntl();
  const { navigateEntity } = useNavigateEntity();

  const handleToCreateCampaignPage = () => {
    navigateEntity(`/marketing/campaign/create`);
  };

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("campaigns");

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Box className="title" mb={1}>
            {formatMessage({ id: "global.function.campaigns.title" })}
          </Box>
          <Box color={(theme) => theme.customColors.grey[600]} mb="13px">
            {formatMessage({ id: "global.function.campaigns.description" })}
          </Box>
        </Box>
        {canEdit && (
          <Button onClick={handleToCreateCampaignPage}>
            {formatMessage({ id: "global.btn.createNewCampaign" })}{" "}
          </Button>
        )}
      </Box>
    </>
  );
};

export default CampaignHeader;
