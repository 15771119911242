import React, { useEffect, useState } from "react";
import { Modal, IconButton } from "@mui/material";
import _ from "lodash";
import Token from "../Icons/Token";
import { StyledTokenInputField } from "./SelectToken.styles";

const SelectTokenInputField = ({ data, onClick }) => {
  return (
    <StyledTokenInputField
      onClick={onClick}
      color={data ? "#383538" : "#A5A5A5"}
    >
      {data ? data.label || <Token data={data} /> : "Select Token"}
      <i
        className={`meta-crm-icon-ic_${
          data ? "refreshSearch" : "search"
        } font-size-16`}
      />
    </StyledTokenInputField>
  );
};

export default SelectTokenInputField;
