import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledTabContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: "24px",
}));

export const StyledNoMacroContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: "200px",
  paddingBottom: "200px",
}));

export const StyledNoMacroText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "16px",
  color: theme.customColors.grey[700],
  marginTop: "10px",
  textAlign: "center",
}));
