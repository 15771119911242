export const CURVE_FETCH_BEGIN = "CURVE_FETCH_BEGIN";
export const CURVE_FETCH_SUCCESS = "CURVE_FETCH_SUCCESS";
export const CURVE_FETCH_FAILURE = "CURVE_FETCH_FAILURE";

export const CURVE_TXS_FETCH_BEGIN = "CURVE_TXS_FETCH_BEGIN";
export const CURVE_TXS_FETCH_SUCCESS = "CURVE_TXS_FETCH_SUCCESS";
export const CURVE_TXS_FETCH_FAILURE = "CURVE_TXS_FETCH_FAILURE";

export const CURVE_ON_CHAIN_FETCH_BEGIN = "CURVE_ON_CHAIN_FETCH_BEGIN";
export const CURVE_ON_CHAIN_FETCH_SUCCESS = "CURVE_ON_CHAIN_FETCH_SUCCESS";
export const CURVE_ON_CHAIN_FETCH_FAILURE = "CURVE_ON_CHAIN_FETCH_FAILURE";
