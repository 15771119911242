import React, { useState, useEffect, memo, useRef } from "react";
import _, { divide } from "lodash";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form";
import { get } from "lodash-es";

import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";

import { Draggable } from "react-beautiful-dnd";
import {
  StyledDraggableTicketRow,
  StyledDraggableTicketBox,
  StyledDraggableTicketBoxIcon,
  StyledDraggableTicketBoxText,
  StyledDeleteIcon,
  StyledInputField,
} from "./DraggableList.styles";

const DraggableTicketListItem = ({
  ticket,
  index,
  onChangeTicketName,
  onDeleteTicket,
  remove,
  control,
  getValues,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { name, _id, hasTicket } = ticket;
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef(null);

  const handleDelete = () => {
    onDeleteTicket({ ticketId: _id, index: index });
  };

  const handleChange = (event) => {
    onChangeTicketName({ event, ticketId: _id });
  };

  const handleEditFalse = () => {
    setIsEdit(false);
  };

  const handleEditTrue = () => {
    setIsEdit(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const validateNameIsRepeat = (value) => {
    const ticketNames = getValues()
      .ticketList.map((item) => item.name)
      .filter((name) => Boolean(name));
    const count = ticketNames.filter(
      (item) => item.toLowerCase() === value.toLowerCase()
    ).length;
    if (count > 1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Draggable draggableId={ticket._id} index={index}>
      {(provided, snapshot) => (
        <StyledDraggableTicketRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? "isDragging" : ""}
        >
          <StyledDraggableTicketBox
            isError={get(errors, `ticketList[${index}]`)}
          >
            <StyledDraggableTicketBoxIcon>
              <i className="meta-crm-icon-ic_columns font-size-18" />
            </StyledDraggableTicketBoxIcon>
            <StyledDraggableTicketBoxText>
              <Box display={isEdit ? "block" : "none"}>
                <StyledInputField
                  ref={inputRef}
                  value={name}
                  onChange={handleChange}
                  size={SIZE.MEDIUM}
                  onEnter={handleEditFalse}
                  onBlur={handleEditFalse}
                  control={control}
                  name={`ticketList[${index}].name`}
                  rules={{
                    required: {
                      value: true,
                      message: "is empty",
                    },
                    validate: {
                      unique: (v) => validateNameIsRepeat(v) || "not unique",
                    },
                  }}
                />
              </Box>

              <Box display={isEdit ? "none" : "block"}>
                {name}
                <IconButton
                  fontSize={16}
                  sx={{ marginLeft: "4px" }}
                  onClick={handleEditTrue}
                >
                  <i className="meta-crm-icon-ic_edit-1 font-size-32" />
                </IconButton>
              </Box>
            </StyledDraggableTicketBoxText>
          </StyledDraggableTicketBox>
          {onDeleteTicket && (
            <StyledDeleteIcon
              onClick={handleDelete}
              size={18}
              disabled={hasTicket}
              tooltipProps={{
                content: hasTicket
                  ? "This ticket type is being used by some tickets and cannot be deleted."
                  : "",
              }}
            >
              <i className="meta-crm-icon-ic_trash font-size-32" />
            </StyledDeleteIcon>
          )}
        </StyledDraggableTicketRow>
      )}
    </Draggable>
  );
};
export default DraggableTicketListItem;
