import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";

export const StyledItemTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  marginBottom: "8px",
}));
