import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";

export const StyledBasicModalContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    position: "relative",
    width: "590px",
    background: theme.customColors.white,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    overFlow: "hidden",
    fontFamily: "Inter",

    "& *": {
      fontFamily: "Inter",
    },
  };
});

export const StyledAddTagsModalContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    position: "relative",
    width: "100%",
    maxWidth: "470px",
    padding: "48px 40px",
    background: theme.customColors.white,
    borderRadius: "10px",
    overFlow: "hidden",
    fontFamily: "Inter",

    "& *": {
      fontFamily: "Inter",
    },
  };
});
export const StyledRadioLabel = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 500,
  };
});

export const StyledCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    "&:hover": {
      background: "none",
    },
  };
});

export const StyledAbsoluteCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    position: "absolute",
    top: 10,
    right: 10,
    "&:hover": {
      background: "none",
    },
  };
});

export const StyledModalContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };
});

export const StyledModalTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: "18px",
    color: "#383538",
    display: "flex",
    alignItems: "center",
    padding: "32px 40px",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.customColors.grey[400]}`,
    fontWeight: theme.fontWeight.BOLD,
    marginBottom: "12px",
  };
});

export const StyledModalSubTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: "18px",
    color: "#383538",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: theme.fontWeight.BOLD,
    marginBottom: "12px",
  };
});

export const StyledModalContent = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[700],
    fontSize: "14px",
    fontWeight: 500,
    padding: "21px 40px",
  };
});

export const StyledPreview = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.purple[500],
    background: theme.customColors.purple[300],
    padding: 12,
    marginTop: 16,
    lineHeight: 1.4,
    borderLeft: `4px solid ${theme.customColors.purple[500]}`,
  };
});

export const StyledContentHeader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[600],
    marginBottom: 12,
  };
});

export const StyledButtonArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, noLine }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: noLine ? "0px" : `1px solid ${theme.customColors.grey[400]}`,
    padding: "22px 40px",
  };
});
