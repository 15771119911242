import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Chip, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import TicketDrawer from "components/TicketDetail/TicketDrawer";
import { Loading } from "features/common";
import { apiUrl } from "features/configure";
import { useConnectWallet } from "features/home/redux/connectWallet";
import _, { get, map } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Chat from "./Chat";
import Channel from "../components/Channel";
import Comment from "../components/Comment";
import { useFetchUserInfo } from "../redux/fetchUserInfo";
import { useFetchTeamMember, useFetchTicketList } from "../redux/hooks";
import UserInfoRoot from "./UserInfoRoot";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  StyledChatAreaContainer,
  StyledMessageInfoContainer,
  StyledTicketDetailContainer,
} from "./TicketDetail.styles";
import { alertServerError, parseCustomDomainUrl } from "features/helpers/utils";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useGetPagePermission from "hooks/useGetPagePermission";
import { useIntl } from "react-intl";
import TicketTag from "../components/TicketTag";
import TicketTypeLabel from "../components/TicketTypeLabel";
import { parseToOptionFormat } from "../settings/Macros/CreateMacroModal/dropdown.utils";

import TicketListItem from "./components/TicketListItem";
import Collaborators from "../components/Collaborators";
import Prompt from "features/common/Prompt";
const useStyles = makeStyles((theme) => {
  return {
    inboxDescriptionLabel: {
      textAlign: "left",
      fontWeight: 400,
      fontSize: "12px",
      color: "#777",
      marginBottom: 8,
    },
    avatar: {
      width: 22,
      height: 22,
      marginRight: 4,
      borderRadius: 40,
    },
    selected: {
      "&.Mui-selected": {
        backgroundColor: `#F7F1FE`,
        "&:hover": {
          backgroundColor: `#F7F1FE`,
        },
      },
    },
    field: {
      marginBottom: 12,
    },
  };
});

const TicketDetail = ({ ticketId, onClose }) => {
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [fetchListLoading, setFetchListLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {
    fetchUserTicketList,
    userTicketList,
    fetchTicketList,
    ticketListFilter,
  } = useFetchTicketList();
  const [comment, setComment] = useState("");
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const { auth, userData } = useConnectWallet();
  const { memberList, memberByTicketList } = useFetchTeamMember();
  const { fetchUserInfo } = useFetchUserInfo();
  const userAddress = _.get(userData, "address", "");
  const selectedUserAddress = _.get(selectedTicket, "userWallet.address");
  const userId = _.get(selectedTicket, "user._id");
  const { formatMessage } = useIntl();
  let [searchParams, setSearchParams] = useSearchParams();

  const discordId = _.get(
    selectedTicket,
    "user.mostRecentDiscordID.handle",
    ""
  );

  const telegramId = _.get(
    selectedTicket,
    "user.mostRecentTelegramID.handle",
    ""
  );

  const navigate = useNavigate();
  const classes = useStyles();

  const fetchTicketDetailById = async () => {
    if (!auth || !ticketId) return;
    try {
      let ticketData = await axios.get(
        `${apiUrl}/api/ticket/${ticketId}?entityName=${entityName}`
      );
      setSelectedTicket(ticketData.data);
      setComment(_.get(ticketData, "data.comment", ""));
    } catch (err) {
      dispatch(alertServerError(err));
      navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/error"));
    } finally {
      setLoading(false);
    }
  };
  const dispatchSnackbar = useDispatchSnackbar();

  const applyToTicket = async (value) => {
    try {
      let ticketData = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/ticket/${ticketId}/apply`,
        {
          tagIds: value,
        }
      );
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  const tagValue = parseToOptionFormat(get(selectedTicket, "tags", []));
  async function handleChooseTag(newValue) {
    setSelectedTicket({
      ...selectedTicket,
      tags: map(newValue, (o) => {
        return { _id: o.value, value: o.label };
      }),
    });

    await applyToTicket(map(newValue, "_id"));
  }

  useEffect(async () => {
    await fetchTicketDetailById();
  }, [ticketId]);

  useEffect(async () => {
    if (selectedTab == 1) {
      try {
        setFetchListLoading(true);
        if (userId) await fetchUserTicketList({ entityName, userId });
      } catch (err) {
        dispatch(alertServerError(err));
      } finally {
        setFetchListLoading(false);
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (userId) {
      fetchUserInfo({ entityName, userId });
    }
  }, [entityName, userId]);
  const tagTeam = _.map(selectedTicket.tagTeam, "_id");

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("tickets");
  const userCanEdit =
    canEdit || selectedTicket.isAssigned || tagTeam.includes(userAddress);

  const renderUserInfo = () => {
    return (
      <div
        style={{
          background: "#FAFAFD",
          padding: 0,
          width: "100%",
          overflowX: "hidden",
          height: "100%",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        {!selectedUserAddress && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.9)",
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("assets/img/boxOpen.svg").default}
              style={{ width: 40, height: 40 }}
            />
            <div
              style={{
                color: "#777777",
                fontWeight: 500,
                fontSize: 14,
                marginTop: 7,
              }}
            >
              {formatMessage({ id: "ticketDrawer.did.empty" })}
            </div>
          </div>
        )}
        <UserInfoRoot
          selectedUserAddress={selectedUserAddress}
          selectedUserId={userId}
          entityName={entityName}
        />
      </div>
    );
  };

  const renderAssignee = () => {
    const assignee = _.get(selectedTicket, "team._id", "");
    return (
      <div>
        <div className={classes.inboxDescriptionLabel}>
          {formatMessage({ id: "ticketDetail.ticketInfo.assignee" })}
        </div>
        <div className={classes.field}>
          {readonly ? (
            <Chip
              label={_.get(
                _.find(memberByTicketList, {
                  _id: assignee,
                }),
                "name",
                "N/A"
              )}
            />
          ) : (
            <CustomDropdown
              border
              data={_.map(memberByTicketList, (data) => {
                return {
                  label: <span>{data.name || "No Name"}</span>,
                  value: data._id,
                };
              })}
              fullWidth
              size={SIZE.LARGE}
              placeholder={"N/A"}
              value={_.get(selectedTicket, "team._id", "")}
              onSelect={async (e) => {
                let team = _.find(memberByTicketList, {
                  _id: e.target.value,
                });
                await axios.post(`${apiUrl}/api/manage/ticket/assign`, {
                  entityName,
                  ticketId: _.get(selectedTicket, "_id", ""),
                  team: team._id,
                });
                setSelectedTicket({
                  ...selectedTicket,
                  team: team,
                });
                fetchTicketList({
                  entityName,
                  filter: ticketListFilter,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderCollaborators = () => {
    const isAssignee =
      userAddress == _.get(selectedTicket, "team.address", "N/A");
    if (memberList.length == 0 || (!isAssignee && tagTeam.length == 0)) return;
    return (
      <>
        <div className={classes.inboxDescriptionLabel}>
          {formatMessage({ id: "ticketDetail.ticketInfo.collaborators" })}
        </div>
        <div className={classes.field}>
          <Collaborators
            editable={isAssignee}
            entityName={entityName}
            ticketId={_.get(selectedTicket, "_id", "")}
            selectedMembers={_.get(selectedTicket, "tagTeam", [])}
          />
        </div>
      </>
    );
  };

  const renderTicketList = () => {
    const ticketList = _.get(userTicketList, `${userId}.tickets`, []);
    if (ticketList.length == 0) return <Box />;
    return _.map(ticketList, (ticket, i) => {
      const isSelected = ticketId == ticket._id;
      return (
        <TicketListItem
          key={i}
          isSelected={isSelected}
          ticket={ticket}
          onClick={(ticketId) => {
            setSearchParams({ ticketId });
          }}
        />
      );
    });
  };

  const renderTicketInfo = () => {
    return (
      <Box>
        <div style={{ marginTop: "20px" }}>
          <div className={classes.inboxDescriptionLabel}>Title</div>
          <div className={classes.field}>{selectedTicket.title}</div>
          <div className={classes.inboxDescriptionLabel}>
            {formatMessage({ id: "ticketList.filter.Issue" })}
          </div>
          <div className={classes.field}>
            {selectedTicket && (
              <TicketTypeLabel
                ticketType={selectedTicket.type}
                edit={userCanEdit}
                callback={async (newValue) => {
                  await axios.post(`${apiUrl}/api/ticket/updateTicketType`, {
                    entityName,
                    ticketId: _.get(selectedTicket, "_id", ""),
                    ticketTypeId: newValue,
                  });
                  fetchTicketDetailById();
                  fetchTicketList({
                    entityName,
                    filter: ticketListFilter,
                  });
                }}
              />
            )}
          </div>
          <div className={classes.inboxDescriptionLabel}>
            {formatMessage({ id: "ticketList.filter.channel" })}:
          </div>
          <div className={classes.field}>
            <Channel ticket={selectedTicket} showLabel={true} />
          </div>
          <div className={classes.inboxDescriptionLabel}>
            {formatMessage({
              id: "ticketDetail.ticketInfo.ticketTag",
            })}
          </div>
          <div className={classes.field}>
            <TicketTag
              canEdit={userCanEdit}
              tags={tagValue}
              handleChooseTag={handleChooseTag}
            />
          </div>
        </div>
        <Box
          pt="20px"
          mt="20px"
          mb="25px"
          borderTop="1px solid #E6E6E6"
          color="#555"
          fontSize="12px"
        >
          {formatMessage({
            id: "ticketDetail.ticketInfo.collaboration",
          })}
        </Box>
        <div>
          {renderAssignee()}
          {renderCollaborators()}

          <div
            className={classes.inboxDescriptionLabel}
            style={{ marginTop: 12 }}
          >
            {formatMessage({
              id: "ticketDetail.ticketInfo.comment",
            })}
          </div>
          <Comment
            entityName={entityName}
            selectedTicket={selectedTicket}
            row={false}
            comment={comment}
            setComment={setComment}
          />
        </div>
      </Box>
    );
  };
  const leaveTicket = () => {
    setSelectedTab(0);
    setSelectedTicket("");
    setLoading(true);
  };

  return (
    <StyledTicketDetailContainer>
      <Loading open={loading} fullScreen={false} white zIndex={1200} />
      <Grid
        container
        alignItems="stretch"
        direction="row"
        justifyContent="center"
        height="100%"
      >
        <StyledMessageInfoContainer>
          <IconButton
            size="14px"
            color="#555"
            onClick={() => {
              onClose();
              if (message) return;
              leaveTicket();
            }}
            style={{
              marginBottom: "20px",
            }}
          >
            <i className="meta-crm-icon-ic_cancel font-size-14 mgr4" />
            <Box sx={{ fontSize: "12px", fontWeight: 500, color: "#555" }}>
              Close
            </Box>
          </IconButton>
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            tabLabelStyle={{ fontSize: 12, fontWeight: 500 }}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            tabs={[
              {
                label: <Box style={{ width: 120 }}>Ticket Info</Box>,
                content: renderTicketInfo(),
              },
              {
                label: <Box style={{ width: 120 }}>User Tickets</Box>,
                content: (
                  <Box position={"relative"} height="100%" minHeight={"400px"}>
                    <Loading open={fetchListLoading} fullScreen={false} />
                    <Box
                      style={{
                        maxHeight: "calc(100vh - 120px)",
                        overflow: "auto",
                      }}
                    >
                      {renderTicketList()}
                    </Box>
                  </Box>
                ),
              },
            ]}
          />
        </StyledMessageInfoContainer>
        <StyledChatAreaContainer>
          <Chat
            fetchTicketDetailById={fetchTicketDetailById}
            selectedTicket={selectedTicket}
            discordId={discordId}
            telegramId={telegramId}
            entityName={entityName}
            setSelectedTicket={setSelectedTicket}
            userCanEdit={userCanEdit}
            setMessage={setMessage}
            message={message}
          />
        </StyledChatAreaContainer>
        <TicketDrawer
          entityName={entityName}
          selectedUserId={userId}
          selectedTicket={selectedTicket}
          selectedUserAddress={selectedUserAddress}
          renderUserInfo={renderUserInfo}
          userCanEdit={userCanEdit}
        />
        <Prompt
          when={message}
          confirmEveryTime
          confirmCallback={() => {
            if (!ticketId) leaveTicket();
            else {
              setMessage("");
            }
          }}
        />
      </Grid>
    </StyledTicketDetailContainer>
  );
};

export default TicketDetail;
