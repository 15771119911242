import React, { useState, useEffect } from "react";
import _ from "lodash";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl } from "features/configure";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useFetchTicketList } from "../redux/fetchTicketList";

const useStyles = makeStyles((theme) => ({}));
const Comment = ({ row, selectedTicket, entityName, comment, setComment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [newComment, setNewComment] = useState(row ? "" : comment);
  const [anchorComment, setAnchorComment] = useState(null);
  const limitLength = 150;
  const { fetchTicketList, ticketListFilter } = useFetchTicketList();
  useEffect(() => {
    if (!newComment) setNewComment(comment);
  }, [comment]);

  const updateComment = async (e) => {
    if (newComment.length > limitLength) return;
    await axios.post(`${apiUrl}/api/ticket/update/comment`, {
      entityName,
      ticketId: _.get(selectedTicket, "_id", ""),
      comment: newComment,
    });
    if (!row)
      fetchTicketList({
        entityName,
        filter: ticketListFilter,
      });
    dispatch(
      enqueueSnackbar({
        message: formatMessage({
          id: "ticketDetail.ticketInfo.updateCommentSuccess",
        }),
        options: {
          variant: "info",
        },
      })
    );
    setComment(newComment);
    if (row) {
      setAnchorComment(null);
      setNewComment("");
      e.stopPropagation();
    }
  };

  const renderCommentInput = (value, setValue) => {
    return (
      <div
        style={{
          minWidth: 193,
          border: "1px solid #e6e6e6",
          borderRadius: "4px",
        }}
      >
        <InputField
          variant={"standard"}
          minRows={2}
          placeholder={formatMessage({
            id: "ticketDetail.ticketInfo.commentInputPlaceholder",
          })}
          multiline={true}
          onClick={(e) => e.stopPropagation()}
          errors={
            newComment?.length > limitLength && `Exceeds ${limitLength} limit`
          }
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <div
          style={{
            padding: "6px 12px",
            textAlign: "right",
          }}
        >
          <IconButton onClick={updateComment}>
            <i className="meta-crm-icon-ic-up-arrow-circle font-size-18" />
          </IconButton>
        </div>
      </div>
    );
  };

  if (row) {
    return (
      <div>
        <Popover
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          open={Boolean(anchorComment)}
          anchorEl={anchorComment}
          onClose={(e) => {
            setAnchorComment(null);
            setNewComment("");
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <div onClick={(e) => e.stopPropagation()}>
            {renderCommentInput(newComment, setNewComment)}
          </div>
        </Popover>
        <IconButton
          onClick={(e) => {
            setAnchorComment(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <i
            className="meta-crm-icon-ic_messageAdd font-size-18"
            style={{ color: "#A5A5A5" }}
          />
        </IconButton>
      </div>
    );
  } else {
    return renderCommentInput(newComment, setNewComment);
  }
};

export default Comment;
