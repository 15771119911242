export function convertToCountdown(timestamp) {
  const currentDate = new Date();
  const targetDate = new Date(timestamp);

  const seconds = Math.floor((targetDate - currentDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days + " days ";
  } else if (hours > 0) {
    return hours + " hours";
  } else if (minutes > 0) {
    return minutes + " minutes";
  } else if (seconds > 0) {
    return seconds + " seconds";
  } else {
    return "Unlocked";
  }
}
