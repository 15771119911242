import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Box } from "@mui/material";
import AutoCompleteDropdown from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useDispatch } from "react-redux";
import { postRegisterVerifyAsyncAction } from "features/metadesk/redux/trial/trial.action";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useAccount } from "wagmi";

export default function TrialStep3({ nextStep, stepData, handleStepData }) {
  const {
    handleSubmit,
    control,
    setError, // 引入 setError 方法來手動設置錯誤
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      workSpaceName: stepData.workSpaceName || "",
      module: stepData.module || "",
    },
  });
  
  const dispatchSnackbar = useDispatchSnackbar();
  const dispatch = useDispatch();
  const [module, setModule] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { getAuthenticatedInfo } = useConnectWallet();
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();

  const { address: evmAddress } = useAccount({});

  const handleVerify = async () => {
    const { data } = await axios.post(`${apiUrl}/api/entity/register`, {
      registerId: stepData.registerId,
      workspaceName: stepData.workSpaceName,
      address: evmAddress,
      module: module.value,
    });
    await dispatch(postRegisterVerifyAsyncAction(data));
    await getAuthenticatedInfo(stepData.workSpaceName);
    fetchEntityConfig({ entityName: stepData.workSpaceName });
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await handleVerify();
      nextStep();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        // 設置工作區名稱的錯誤訊息
        setError("workSpaceName", {
          type: "manual",
          message: "This workspace name is taken, please choose another name.",
        });
      } else {
        // 如果是其他錯誤類型，使用 snackbar 來顯示
        dispatchSnackbar({ err });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      maxWidth="400px"
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box color="#383538" fontSize="24px" fontWeight={700} mb="4px">
        Set up your company workspace
      </Box>
      <Box color="#777" fontSize="14px" mb="32px">
        Create a workspace to streamline processes, track key metrics, and
        enhance team collaboration.
      </Box>

      {/* 使用 Controller 來包裝 InputField */}
      <Box sx={{ width: "100%" }}>
        <Controller
          name="workSpaceName" // 確保 name 和 setError 中的 key 是一致的
          control={control}
          rules={{
            required: "This field is required.",
            pattern: {
              value: /^[A-Za-z0-9]+$/,
              message: "Alphanumeric characters only.",
            },
          }}
          render={({ field }) => (
            <InputField
              {...field}
              title="Workspace Name"
              isRequired={true}
              placeholder="e.g. MetaCRM"
              errors={errors.workSpaceName?.message} // 直接使用 errors 物件顯示錯誤訊息
              onChange={(e) => {
                field.onChange(e);
                handleStepData("workSpaceName", e.target.value);
              }}
            />
          )}
        />
        {errors.workSpaceName?.message && (
          <Box bgcolor="white" p="12px 16px" mt="8px" textAlign="left">
            <Box color="#555" fontSize="12px" fontWeight={700} mb="4px">
              ✍🏻 Tips for you
            </Box>
            <Box color="#777" fontSize="13px" fontWeight={400}>
              If the workspace name is already taken, one of your teammates may
              have set up your company workspace. We recommend checking with
              your team to avoid duplication.
            </Box>
          </Box>
        )}
      </Box>
      <Box height="16px" />
      <Box textAlign="left" width="100%" color="#555" mb="4px">
        What do you want to use MetaCRM for? <span className="required">*</span>
      </Box>
      <Controller
        name="module" // 確保 name 和 setError 中的 key 是一致的
        control={control}
        rules={{
          required: "This field is required.",
        }}
        render={({ field }) => (
          <AutoCompleteDropdown
            {...field}
            isRequired={true}
            options={[
              { label: "Service", value: "service" },
              { label: "Marketing", value: "marketing" },
            ]}
            errors={errors.module?.message}
            value={module}
            onChange={(e, newValue) => {
              setModule(newValue);
              field.onChange(newValue);
              handleStepData("module", newValue);
            }}
          />
        )}
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        sx={{ mt: "32px" }}
        isLoading={isLoading}
      >
        Create
      </Button>
    </Box>
  );
}
