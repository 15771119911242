import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectClientSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";

export default function DemoBanner() {
  const theme = useTheme();
  const isClientSite = useSelector(selectClientSite);
  const [remindOpen, setRemindOpen] = useState(isClientSite);

  const handleCloseRemindModal = () => {
    setRemindOpen(false);
  };

  const renderRemindBar = () => {
    if (!remindOpen) return;
    return (
      <Box
        sx={{
          backgroundColor: "#FFC814",
          padding: "10px 20px",
          zIndex: 100,
          color: "#000000",
          alignItems: "center",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          display: "flex",
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: theme.customColors.purple[500],
          }}
        >
          <Box
            component="i"
            className="meta-crm-icon-ic_alert font-size-16"
            mr="5px"
          />
          This is a demo site for showcase purposes. All data is fictitious.
        </Box>
        <Box display={"flex"} alignItems={"center"} ml="auto">
          <IconButton
            color={theme.customColors.purple[500]}
            size={24}
            onClick={handleCloseRemindModal}
          >
            <i className="meta-crm-icon-ic_cancel font-size-24" />
          </IconButton>
        </Box>
      </Box>
    );
  };
  if (!isClientSite) return <div />;
  return <>{renderRemindBar()}</>;
}
