import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form"; // 匯入 useForm 和 Controller
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import InputField, { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

export default function TrialStep1({ nextStep, stepData, handleStepData }) {
  // 使用 useForm hook 來控制表單
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange", // 設定為 onChange 模式，即在輸入時進行驗證
    defaultValues: {
      name: stepData.name || "",
      email: stepData.email || "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();

  async function handleSendVerifyEmail() {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${apiUrl}/api/entity/sendVerificationEmail`,
        {
          username: stepData.name,
          email: stepData.email,
        }
      );
      handleStepData("registerId", data.registerId);
      setIsLoading(false);
      nextStep(); // 下一步
    } catch (err) {
      dispatchSnackbar({ err });
      setIsLoading(false);
    }
  }

  // 定義表單提交的處理函數
  const onSubmit = async (data) => {
    // 當表單驗證成功時，調用 nextStep
    try {
      await handleSendVerifyEmail();
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  return (
    <Box
      maxWidth="400px"
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box color="#383538" fontSize="24px" fontWeight={700} mb="4px">
        Tell us more about yourself
      </Box>
      <Box color="#777" fontSize="14px" mb="32px">
        This information helps us tailor your experience with MetaCRM!
      </Box>

      {/* 使用 Controller 來包裝 InputField */}
      <Box sx={{ width: "100%" }}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "This field is required.", // 必填規則
          }}
          render={({ field }) => (
            <InputField
              {...field}
              title="Name"
              isRequired={true}
              placeholder="e.g. Robert Jackson"
              errors={errors.name?.message}
              onChange={(e) => {
                field.onChange(e); // 呼叫 Controller 提供的 onChange 以更新 react-hook-form 狀態
                handleStepData("name", e.target.value); // 同時更新父組件的狀態
              }}
            />
          )}
        />
      </Box>
      <Box height="16px" />
      {/* 使用 Controller 來包裝 Email 輸入欄位 */}
      <Box sx={{ width: "100%" }}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "This field is required.", // 必填規則
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email address.", // 電子郵件格式驗證
            },
          }}
          render={({ field }) => (
            <InputField
              {...field}
              title="Email"
              isRequired={true}
              placeholder="e.g. robert@gmail.com"
              errors={errors.email?.message}
              size="extraLarge"
              onChange={(e) => {
                field.onChange(e); // 呼叫 Controller 提供的 onChange 以更新 react-hook-form 狀態
                handleStepData("email", e.target.value); // 同時更新父組件的狀態
              }}
            />
          )}
        />
      </Box>
      {/* 按鈕：根據表單驗證結果啟用或禁用 */}
      <Button
        onClick={handleSubmit(onSubmit)}
        sx={{ mt: "32px" }}
        isLoading={isLoading}
      >
        Continue
      </Button>
    </Box>
  );
}
