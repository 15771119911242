import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Swiper } from "swiper/react";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

export const StyledGroupCardContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isPreview }) => ({
  padding: "11px 15px",
  border: `1px solid ${theme.customColors.grey[400]}`,
  borderRadius: "5px",
  background: theme.customColors.white,
  ...(!isPreview && {
    cursor: "pointer",
  }),
}));

export const StyledGroupCardHeader = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: "10px",
  borderBottom: `1px solid ${theme.customColors.grey[300]}`,
  justifyContent: "space-between",
}));

export const StyledGroupCardHeaderTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 700,
  fontSize: "16px",
  color: theme.customColors.grey[700],
  minHeight: "20px",
}));

export const StyledGroupPeople = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  background: theme.customColors.grey[200],
  padding: "2px 4px",
  borderRadius: "20px",
  marginRight: "4px",
  display: "flex",
  alignItems: "center",
  "& i": {
    marginRight: "2px",
  },
}));

export const StyledShowMoreButton = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledGroupCardContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginTop: "13px",
}));

export const StyledGroupCategory = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginBottom: "12px",
}));

export const StyledExcludeCategory = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, height }) => ({
  background: "#E6E6E6",
  padding: "1px 5px",
  borderRadius: "34px",
  display: "flex",
  height: height || "18px",
  alignItems: "center",
  color: "#777777",
  fontSize: "12px",
  fontWeight: 400,
  marginLeft: 2,
  "& span": {
    fontSize: "12px",
    fontWeight: 500,
    marginRight: 4,
  },
}));

export const StyledGroupCategoryTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 300,
  fontSize: "12px",
  color: theme.customColors.grey[500],
  marginBottom: "6px",
}));

export const StyledGroupFilterList = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
}));

export const StyledFilterItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.customColors.grey[400]}`,
  padding: "5px 8px",
  color: theme.customColors.grey[600],
  borderRadius: "58px",
  marginRight: "8px",
  fontSize: "12px",
}));

export const StyledSwiper = styled(Swiper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  margin: 0,
  width: "100%",
  "& .swiper-wrapper": {
    display: "flex",
  },
  "& .swiper-slide": {
    width: "fit-content",
  },
}));

export const StyledRow = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: "white",
  borderRadius: 5,
  padding: "24px 16px",
  marginBottom: 16,
}));

export const StyledRowTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: theme.customColors.grey[700],
  paddingBottom: 12,
  display: "flex",
  alignItems: "center",
  "& i": {
    marginRight: "5px",
  },
}));

export const StyledGridItem = styled(Grid, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "&:not(:nth-of-type(1))": {
    borderLeft: `1px solid ${theme.customColors.grey[400]}`,
    paddingLeft: 24,
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: "5px",
  },
}));

export const StyledRowDetailInfo = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  minHeight: "18px",
}));
