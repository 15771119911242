import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  TRANSACTION_TYPE_FETCH_BEGIN,
  TRANSACTION_TYPE_FETCH_SUCCESS,
  TRANSACTION_TYPE_FETCH_FAILURE,
} from "./constants";
import axios from "axios";
import { apiUrl } from "features/configure";
export function fetchTransactionType({ entityName }) {
  return (dispatch, getState) => {
    dispatch({
      type: TRANSACTION_TYPE_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let types = await axios.get(
          `${apiUrl}/api/manage/txTypes?entityName=` + entityName
        );
        dispatch({
          type: TRANSACTION_TYPE_FETCH_SUCCESS,
          data: types.data,
        });
      } catch (err) {
        dispatch({
          type: TRANSACTION_TYPE_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function useFetchTransactionType() {
  const dispatch = useDispatch();

  const {
    transactionTypeList,
    fetchTransactionTypePending,
    fetchTransactionTypeDone,
  } = useSelector(
    (state) => ({
      transactionTypeList: state.metadesk.transactionTypeList,
      fetchTransactionTypePending: state.metadesk.fetchTransactionTypePending,
      fetchTransactionTypeDone: state.metadesk.fetchTransactionTypeDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchTransactionType(data));
    },
    [dispatch]
  );

  return {
    transactionTypeList,
    fetchTransactionType: boundAction,
    fetchTransactionTypeDone,
    fetchTransactionTypePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TRANSACTION_TYPE_FETCH_BEGIN:
      return {
        ...state,
        fetchTransactionTypePending: true,
      };

    case TRANSACTION_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        transactionTypeList: action.data,
        fetchTransactionTypeDone: true,
        fetchTransactionTypePending: false,
      };

    case TRANSACTION_TYPE_FETCH_FAILURE:
      return {
        ...state,
        fetchTransactionTypePending: false,
      };

    default:
      return state;
  }
}
