import React, { useState, useEffect } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import { useOutletContext } from "react-router-dom";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import {
  InputField,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import { useTheme } from "@mui/styles";
import {
  Button,
  IconButton,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";

import { StyledCustomTabsContainer } from "../common.styles";
import CreateMacroModal from "./CreateMacroModal/CreateMacroModal";
import TabContent from "./TabContent/TabContent";
import { fetchCategoriesAsyncAction } from "features/metadesk/redux/macro/macro.action";
import {
  selectMacroCategories,
  selectMacroCategoryLength,
} from "features/metadesk/redux/macro/macro.selector";
import { StyledCustomTabsContentContainer } from "features/metadesk/settings/common.styles";

import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";

const Macros = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectMacroCategories);
  const categoriesShowLength = categories.filter(
    (category) => category.length
  ).length;
  const macroCategoryLength = useSelector(selectMacroCategoryLength);
  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();

  const theme = useTheme();

  const [search, setSearch] = useState("");
  const [searchValueForProps, setSearchValueForProps] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [createMacroModalOpen, setCreateMacroModalOpen] = useState(false);
  const [macroModalEditInfo, setMacroModalEditInfo] = useState({});

  useEffect(() => {
    dispatch(fetchCategoriesAsyncAction({ entityName: entityName }));
  }, []);

  useEffect(() => {
    if (selectedTab > categoriesShowLength) {
      setSelectedTab(0);
    }
  }, [selectedTab, categoriesShowLength]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setSearch(value);
  };

  const handleSearch = (value) => {
    setSearchValueForProps(value);
  };

  const handleBlurSearch = (e) => {
    handleSearch(e.target.value);
  };

  const handleOpenCreateMacroModal = (editMacroInfo = {}) => {
    setCreateMacroModalOpen(true);
    if (editMacroInfo) {
      setMacroModalEditInfo(editMacroInfo);
    }
  };

  const handleCloseCreateMacroModal = () => {
    setCreateMacroModalOpen(false);
    setMacroModalEditInfo({});
  };

  const linkifyOptions = {
    target: (href) => {
      const { hostname } = window.location;
      if (hostname && href.includes(hostname)) {
        return "";
      }
      return "_blank";
    },
    attributes: {
      rel: "noreferrer noopener",
    },
  };

  const categoryTabs = () => {
    return categories
      .filter((category) => category.length)
      .map((category) => {
        return {
          label: `${category.name} (${category.length})`,
          content: (
            <StyledCustomTabsContentContainer>
              <TabContent
                category={category}
                onSearchValue={searchValueForProps}
                onLinkifyOptions={linkifyOptions}
                onOpenEditMacroModal={handleOpenCreateMacroModal}
                onSelectedTab={selectedTab}
              />
            </StyledCustomTabsContentContainer>
          ),
        };
      });
  };

  let tabs = [
    {
      label: `All (${macroCategoryLength})`,
      content: (
        <StyledCustomTabsContentContainer>
          <TabContent
            onLinkifyOptions={linkifyOptions}
            onSearchValue={searchValueForProps}
            onOpenEditMacroModal={handleOpenCreateMacroModal}
            onSelectedTab={selectedTab}
          />
        </StyledCustomTabsContentContainer>
      ),
    },
    ...categoryTabs(),
  ];

  const handleChangeTab = (newValue) => {
    setSelectedTab(newValue);
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("macros");

  return (
    <div>
      <div>
        <div className="title">
          {formatMessage({ id: "global.header.macros" })}
        </div>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div className="description">
            {formatMessage({ id: "macrosSetting.macros.description" })}
          </div>
          {canEdit && (
            <Button
              sx={{ width: "120px" }}
              color={COLOR.SECONDARY}
              onClick={() => handleOpenCreateMacroModal({})}
            >
              {formatMessage({ id: "global.btn.create" })}
            </Button>
          )}
        </Box>
        <Box mt="24px" width="400px">
          <InputField
            name="searchValue"
            onChange={(e) => handleChange(e)}
            value={search}
            variant="outlined"
            type="search"
            size={SIZE.MEDIUM}
            placeholder={formatMessage({ id: "global.btn.search" })}
            onEnter={handleSearch}
            onBlur={(e) => handleBlurSearch(e)}
          />
        </Box>
      </div>
      <div className="hr" />
      <Box>
        <StyledCustomTabsContainer>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => handleChangeTab(newValue)}
            tabs={tabs}
          />
        </StyledCustomTabsContainer>
      </Box>
      {canEdit && (
        <CreateMacroModal
          open={createMacroModalOpen}
          onClose={handleCloseCreateMacroModal}
          onSelectMacro={() => {}}
          onLinkifyOptions={linkifyOptions}
          editInfo={macroModalEditInfo}
          onSearchValue={searchValueForProps}
        />
      )}
    </div>
  );
};

export default Macros;
