import styled from "@emotion/styled/macro";

const StyledButton = styled("a")(({ theme }) => {
  return {
    background: "#EFF5FF",
    width: 140,
    margin: "0 auto",
    padding: "8px 27px",
    borderRadius: 50,
    color: "#0096FF",
    fontSize: 14,
    fontWeight: 700,
    boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.1)",
    zIndex: 20000,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: "#0086E4",
    },
  };
});
const FeedbackButton = () => (
  <StyledButton href={"https://tally.so/r/3NX71B"} target="_blank">
    <i className="meta-crm-icon-ic_feedback1 font-size-16 mgr4" /> feedback
  </StyledButton>
);

export default FeedbackButton;
