import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import semver from "semver";
import { get, isEmpty } from "lodash-es";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "features/common/redux/actions";
import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";

import {
  StyledCustomizationContainer,
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
  StyledSurveySettingsContainer,
  StyledInputHint,
} from "./TicketType.styles";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import TicketListContainer from "./TicketListContainer";
import { Loading } from "features/common";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
import ScoreBtnOption from "./ScoreBtnOption";
import {
  selectSurveyConfig,
  selectWidgetModule,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { DEFAULT_SURVEY_MSG } from "../SurveyConstants";
import { StyledWhiteContainer } from "../../common.styles";

const hasDuplicateName = (arr) => {
  if (isEmpty(arr)) {
    return false;
  }
  const nameSet = new Set();

  for (const item of arr) {
    const itemName = get(item, "name");
    if (!itemName) {
      return false;
    }
    if (nameSet.has(itemName.toLowerCase())) {
      return true; // 如果發現重複，則返回 true
    }
    nameSet.add(item.name.toLowerCase());
  }

  return false; // 如果迴圈完成而沒有找到重複，則返回 false
};
const formDataInit = {
  ticketList: [],
};

const TicketType = () => {
  const methods = useForm({
    defaultValues: formDataInit,
    mode: "onChange",
  });
  const { append, replace } = useFieldArray({
    control: methods.control,
    name: "ticketList",
  });
  let [searchParams] = useSearchParams();
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const [ticketList, setTicketList] = useState(formDataInit.ticketList);
  const [isLoading, setIsLoading] = useState(false);
  const [isEqualData, setIsEqualData] = useState(true);
  const [surveyConfig, setSurveyConfig] = useState({
    isSurveyEnabled: false,
    surveyBtnStyle: "star",
    surveyMsg: "",
  });
  const { formatMessage } = useIntl();
  const firstFetchData = useRef({});
  const guide = searchParams.get("guide");
  const {
    userGuideInfo,
    ticketTypeList,
    fetchTicketTypeList,
    updateTicketTypeList,
    updateSurveyConfig,
  } = useFetchEntityConfig();
  const selectedSurveyConfig = useSelector(selectSurveyConfig);
  const selectedWidgetModule = useSelector(selectWidgetModule);

  const isAnyEmptyInTicketList = () => {
    return ticketList.some((obj) => obj.name === "");
  };
  const isEmptySurveyMsg = () => {
    return surveyConfig?.isSurveyEnabled && _.isEmpty(surveyConfig.surveyMsg);
  };

  const isValid =
    !hasDuplicateName(ticketList) &&
    !isEqualData &&
    !isAnyEmptyInTicketList() &&
    !isEmptySurveyMsg();
  const showWarning = hasDuplicateName(ticketList) || isAnyEmptyInTicketList();

  useEffect(() => {
    if (guide) {
      setRun(true);
    }
  }, [guide]);

  useEffect(() => {
    if (!_.isEmpty(selectedSurveyConfig)) {
      setSurveyConfig({ ...selectedSurveyConfig });
    }
  }, [selectedSurveyConfig]);

  useEffect(() => {
    const isEqual = isTicketListEqual() && isSurveyConfigEqual();
    setIsEqualData(isEqual);
  }, [ticketList, surveyConfig]);

  useEffect(() => {
    updateList(ticketTypeList);
  }, [ticketTypeList]);

  useUnload((e) => {
    if (!isEqualData) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const updateList = (data) => {
    const dataWithDefaultDiscordInfo = data.map((item) => {
      if (
        item.addressRequired === undefined ||
        item.discordButtonStyle === undefined
      ) {
        return { ...item, discordButtonStyle: 1, addressRequired: false };
      }
      return item;
    });

    setTicketList(dataWithDefaultDiscordInfo);
    firstFetchData.current = dataWithDefaultDiscordInfo;
    replace(dataWithDefaultDiscordInfo);
  };

  const getCleanSurveyMsg = ({ type, surveyConfig }) => {
    const { surveyMsg } = surveyConfig;
    switch (type) {
      case "star": {
        return surveyMsg?.length
          ? surveyMsg
          : formatMessage({ id: DEFAULT_SURVEY_MSG.star.msg });
      }
      case "emoji": {
        return surveyMsg?.length
          ? surveyMsg
          : formatMessage({ id: DEFAULT_SURVEY_MSG.emoji.msg });
      }
      default: {
        throw new Error("Invalid survey msg type provided");
      }
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      if (!isTicketListEqual()) {
        await updateTicketTypeList({
          entityName,
          ticketTypes: ticketList.filter((ticket) => ticket.name.trim() !== ""),
        });
      }

      if (!isSurveyConfigEqual()) {
        await updateSurveyConfig({
          entityName,
          surveyConfig: {
            ...surveyConfig,
            surveyMsg: getCleanSurveyMsg({
              type: surveyConfig.surveyBtnStyle,
              surveyConfig: surveyConfig,
            }),
          },
        });
      }

      dispatch(
        enqueueSnackbar({
          message: "Update success!",
          options: {
            variant: "info",
          },
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");
  const handleChangeSurveyConfig = ({ key, val }) => {
    const updatedSurveyConfig = { ...surveyConfig };
    updatedSurveyConfig[key] = val;
    setSurveyConfig(updatedSurveyConfig);
  };

  const isTicketListEqual = () => {
    return _.isEqual(ticketList, firstFetchData.current);
  };

  const isSurveyConfigEqual = () => {
    if (!selectedSurveyConfig.isSurveyEnabled && !surveyConfig.isSurveyEnabled)
      return true;
    else return _.isEqual(selectedSurveyConfig, surveyConfig);
  };

  function renderTicketTypeList() {
    return (
      <StyledGeneralSettingsContainer id="ticketTypeGuide">
        <StyledSettingTitle>
          {formatMessage({
            id: "ticketCustomization.general.subtitle",
          })}
        </StyledSettingTitle>
        <StyledDescription>
          {formatMessage(
            {
              id: "ticketCustomization.general.description",
            },
            { br: <br /> }
          )}
        </StyledDescription>
        <TicketListContainer
          ticketList={ticketList}
          setTicketList={setTicketList}
          showWarning={showWarning}
        ></TicketListContainer>
      </StyledGeneralSettingsContainer>
    );
  }

  function renderSurveySetting() {
    const widgetVersion = _.get(selectedWidgetModule, "version");
    const MIN_WIDGET_VERSION_WITH_SURVEY_FEATURE = "1.2.0";

    if (
      widgetVersion &&
      semver.lt(widgetVersion, MIN_WIDGET_VERSION_WITH_SURVEY_FEATURE)
    ) {
      return false;
    }

    return (
      <StyledSurveySettingsContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <StyledSettingTitle>
              {formatMessage({
                id: "ticketCustomization.csatSetting.title",
              })}
            </StyledSettingTitle>
            <StyledDescription>
              {formatMessage({
                id: "ticketCustomization.csatSetting.description",
              })}
            </StyledDescription>
          </Box>
          {canEdit && (
            <Box>
              <Switch
                sx={{
                  display: "flex",
                  columnGap: 14,
                }}
                label={["Disabled", "Enabled"]}
                labelPlacement="start"
                checked={surveyConfig.isSurveyEnabled}
                onChange={() =>
                  handleChangeSurveyConfig({
                    key: "isSurveyEnabled",
                    val: !surveyConfig.isSurveyEnabled,
                  })
                }
              />
            </Box>
          )}
        </Box>
        {surveyConfig.isSurveyEnabled && (
          <>
            <ScoreBtnOption
              selectedSurveyConfig={selectedSurveyConfig}
              surveyConfig={surveyConfig}
              handleChangeSurveyConfig={handleChangeSurveyConfig}
            />
            <StyledInputHint>
              <Box mr="3px" mt="1px" display="inline">
                <i className="meta-crm-icon-ic_info font-size-14" />
              </Box>
              {formatMessage({
                id: "ticketCustomization.satisfactionSurveySetting.explanatory",
              })}
            </StyledInputHint>
          </>
        )}
      </StyledSurveySettingsContainer>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box mb="20px">
        <Box className="title">Ticket</Box>
        <Box className="description">
          Create your ticket types and set up CSAT.
        </Box>
      </Box>
      <StyledWhiteContainer>
        <StyledCustomizationContainer>
          <Loading open={isLoading} fullScreen={false} />
          {renderTicketTypeList()}
          {renderSurveySetting()}
          {canEdit && (
            <FixedButton>
              <Button
                sx={{ width: "120px", marginLeft: "8px" }}
                onClick={handleSave}
                disabled={!isValid}
              >
                Save
              </Button>
            </FixedButton>
          )}
          <Prompt when={!isEqualData} />
        </StyledCustomizationContainer>
      </StyledWhiteContainer>
    </FormProvider>
  );
};

export default TicketType;
