import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RADIANT_TXS_FETCH_BEGIN,
  RADIANT_TXS_FETCH_SUCCESS,
  RADIANT_TXS_FETCH_FAILURE,
} from "./constants";
import axios from "axios";
import { apiUrl } from "features/configure";

export function fetchRadiantTxs(input) {
  return (dispatch, getState) => {
    dispatch({
      type: RADIANT_TXS_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let txs = await axios.get(
          `${apiUrl}/api/user/txs?address=` +
            input.selectedUserAddress +
            "&entityName=" +
            input.entityName
        );
        dispatch({
          type: RADIANT_TXS_FETCH_SUCCESS,
          data: txs.data,
        });
      } catch (err) {
        dispatch({
          type: RADIANT_TXS_FETCH_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useFetchRadiantTxs() {
  const dispatch = useDispatch();

  const { radiantTxs, fetchRadiantTxsPending, fetchRadiantTxsDone } =
    useSelector(
      (state) => ({
        radiantTxs: state.metadesk.radiantTxs,
        fetchRadiantTxsPending: state.metadesk.fetchRadiantTxsPending,
        fetchRadiantTxsDone: state.metadesk.fetchRadiantTxsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRadiantTxs(data));
    },
    [dispatch]
  );

  return {
    radiantTxs,
    fetchRadiantTxs: boundAction,
    fetchRadiantTxsDone,
    fetchRadiantTxsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RADIANT_TXS_FETCH_BEGIN:
      return {
        ...state,
        radiantTxs: [],
        fetchRadiantTxsPending: true,
      };

    case RADIANT_TXS_FETCH_SUCCESS:
      return {
        ...state,
        radiantTxs: action.data,
        fetchRadiantTxsDone: true,
        fetchRadiantTxsPending: false,
      };

    case RADIANT_TXS_FETCH_FAILURE:
      return {
        ...state,
        fetchRadiantTxsPending: false,
      };

    default:
      return state;
  }
}
