import React, { memo } from "react";
import _ from "lodash";
import { Button, COLOR, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { StyledTableMenuButton } from "./styles";

const TableMenu = ({ tabs, show = true }) => {
  const renderTab = ({ label, onClick, disabled, icon, button }) => {
    if (button) {
      return button;
    }
    if (onClick) {
      return (
        <StyledTableMenuButton
          disabled={disabled}
          startIcon={icon}
          onClick={onClick}
          color={COLOR.SECONDARY}
          size={SIZE.SMALL}
        >
          {label}
        </StyledTableMenuButton>
      );
    } else {
      return <span style={{ color: "#FFFFFF", marginRight: 36 }}>{label}</span>;
    }
  };
  if (!show) return <div />;
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1500,
        bottom: 30,
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#7B61FF",

        borderRadius: "60px",
        padding: "8px 36px",
        paddingRight: 0,
        fontWeight: 500,
        fontSize: 12,
      }}
    >
      <div className="row">{_.map(tabs, (t) => renderTab(t))}</div>
    </div>
  );
};

export default memo(TableMenu);
