import React, { useState, createContext, useEffect } from "react";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import axios from "axios";
import { apiUrl } from "features/configure";
import useNavigateEntity from "hooks/useNavigateEntity";
import { v4 as uuidv4 } from "uuid";

export const AdamContext = createContext({
  isAdamFinished: false,
  form: {
    campaignPromotionType: "",
    campaignName: "",
    campaignDescription: "",
    campaignContentTitle: "",
    campaignContent: "",
    campaignImageUrl: "",
    campaignScheduledTime: null,
  },
  step: -1,
  audienceNumber: 0,
});

export default function AdamContextProvider({ children }) {
  const { entityName } = useNavigateEntity();
  const [isAdamFinished, setIsAdamFinished] = useState(false);
  const [form, setForm] = useState({
    campaignName: "",
    campaignDescription: "",
    campaignContentTitle: "",
    campaignContent: "",
    campaignPromotionType: "",
    campaignImageUrl: "",
    campaignScheduledTime: null,
  });
  const dispatchSnackbar = useDispatchSnackbar();
  const [campaignId, setCampaignId] = useState(null);
  const [chatMsg, setChatMsg] = useState([]);
  const [adamAudienceId, setAdamAudienceId] = useState("");
  const [audienceNumber, setAudienceNumber] = useState(0);

  // adam response loading state
  const [loading, setLoading] = useState(false);
  // frontend step
  const [step, setStep] = useState(-1);

  function handleInput(e) {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  }

  const handleSetPromotionType = (value) => {
    setForm((prevForm) => ({ ...prevForm, campaignPromotionType: value }));
    setStep((prevStep) => prevStep + 1);
  };

  const customArray = [
    "KOL Marketing",
    "Brand Collaboration",
    "New Product Launch",
    "Event Invitation",
  ];

  const engagementArray = [
    "Flyer Push",
    "Limited Time Offer",
    "User Survey",
    "Re-engagement Campaign",
  ];

  const handleCampaignDraft = async () => {
    // type => customArray.includes(form.campaignPromotionType) ? "custom" : "engagement",

    let campaignSet;
    if (customArray.includes(form.campaignPromotionType)) {
      // will change to custom campaign set type in future
      campaignSet = {
        engagementDescription: form.campaignContent,
        engagementDistributionSettings: [
          {
            audienceType: "custom",
            channel: "widget",
            customType: "adam",
            estimatedAmount: 0,
            tag: "",
            trackingLink: "",
            useOnchain: false,
            useShortLink: true,
            userGroup: "",
            websiteUrl: "",
            adamAudience: adamAudienceId,
          },
        ],
        engagementImages: form.campaignImageUrl ? [form.campaignImageUrl] : [],
        engagementTitle: form.campaignContentTitle,
        type: "engagement",
        _id: uuidv4(),
      };
    } else {
      campaignSet = {
        engagementDescription: form.campaignContent,
        engagementDistributionSettings: [
          {
            audienceType: "custom",
            channel: "widget",
            customType: "adam",
            estimatedAmount: 0,
            tag: "",
            trackingLink: "",
            useOnchain: false,
            useShortLink: true,
            userGroup: "",
            websiteUrl: "",
            adamAudience: adamAudienceId,
          },
        ],
        engagementImages: form.campaignImageUrl ? [form.campaignImageUrl] : [],
        engagementTitle: form.campaignContentTitle,
        type: "engagement",
        _id: uuidv4(),
      };
    }
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/campaign/${entityName}`,
        {
          campaignContentSets: [campaignSet],
          description: form.campaignDescription,
          isDraft: true,
          name: form.campaignName,
          objectives: [],
          startFrom: form.campaignScheduledTime,
        }
      );
      setStep((prevStep) => prevStep + 1);
      console.log("data: ", data);
      setCampaignId(data[0]._id);
    } catch (err) {
      dispatchSnackbar({
        err,
      });
      return false;
    }
  };

  return (
    <AdamContext.Provider
      value={{
        isAdamFinished,
        setIsAdamFinished,
        form,
        handleInput,
        audienceNumber,
        setAudienceNumber,
        step,
        setStep,
        handleSetPromotionType,
        chatMsg,
        setChatMsg,
        loading,
        setLoading,
        handleCampaignDraft,
        campaignId,
        setAdamAudienceId,
      }}
    >
      {children}
    </AdamContext.Provider>
  );
}
