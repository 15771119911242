import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  ENTITY_CONFIG_FETCH_SUCCESS,
  ENTITY_CONFIG_UPDATE_SUCCESS,
  ENTITY_CONFIG_UPDATE_BEGIN,
  USER_GUIDE_INFO_FETCH_SUCCESS,
  TICKET_TYPE_FETCH_SUCCESS,
  TICKET_TYPE_UPDATE_SUCCESS,
  SURVEY_CONFIG_UPDATE_SUCCESS,
} from "../constants";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";

export function fetchEntityConfig({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let types = await axios.get(
          `${apiUrl}/api/manage/entityConfig?entityName=` + entityName
        );
        dispatch({
          type: ENTITY_CONFIG_FETCH_SUCCESS,
          data: types.data,
        });
        resolve(getState());
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function fetchUserGuideInfo({ type, entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let infos = await axios.get(
          `${apiUrl}/api/userGuide/${entityName}/${type}`
        );
        dispatch({
          type: USER_GUIDE_INFO_FETCH_SUCCESS,
          data: infos.data,
          guideType: type,
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function fetchTicketTypeList({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/manage/ticketType/${entityName}`
        );

        const dataWithDefaultDiscordInfo = data.map((item) => {
          if (
            item.addressRequired === undefined ||
            item.discordButtonStyle === undefined
          ) {
            return { ...item, discordButtonStyle: 1, addressRequired: false };
          }
          return item;
        });

        dispatch({
          type: TICKET_TYPE_FETCH_SUCCESS,
          data: dataWithDefaultDiscordInfo,
        });
      } catch (error) {
        reject(error);
      }
    });
    return promise;
  };
}

export function updateUserGuideInfo({ type, entityName, stepName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let infos = await axios.post(
          `${apiUrl}/api/userGuide/${entityName}/${type}`,
          { stepName }
        );
        dispatch(fetchUserGuideInfo({ type, entityName }));
        resolve();
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function updateTicketTypeList({ entityName, ticketTypes }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(
          `${apiUrl}/api/manage/ticketType/${entityName}`,
          {
            ticketTypes,
          }
        );
        dispatch({
          type: TICKET_TYPE_UPDATE_SUCCESS,
          data,
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function updateSurveyConfig({
  entityName,
  surveyConfig: { isSurveyEnabled, surveyBtnStyle, surveyMsg },
}) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(
          `${apiUrl}/api/manage/survey/${entityName}`,
          {
            isSurveyEnabled,
            surveyBtnStyle,
            surveyMsg,
          }
        );
        dispatch({
          type: SURVEY_CONFIG_UPDATE_SUCCESS,
          data,
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function completeUserGuideInfo({ type, entityName, optedOut }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let infos = await axios.post(
          `${apiUrl}/api/userGuide/${entityName}/completed`,
          { optedOut, type }
        );
        dispatch(fetchUserGuideInfo({ type, entityName }));
        resolve();
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function updateEntityConfig({
  entityName,
  signatureSignInDisabled,
  chainIds,
  nftInfo,
  smartcontractInfo,
  tokenProfile,
  fetchGeneralTx,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: ENTITY_CONFIG_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const data = { entityName };

        if (_.isBoolean(signatureSignInDisabled))
          data.signatureSignInDisabled = signatureSignInDisabled.toString();
        if (chainIds) data.chainIds = chainIds;
        if (nftInfo) data.nftInfo = nftInfo;
        if (smartcontractInfo) data.smartcontractInfo = smartcontractInfo;
        if (fetchGeneralTx) data.fetchGeneralTx = fetchGeneralTx;
        if (tokenProfile) data.tokenProfile = tokenProfile;
        let r = await axios.post(
          `${apiUrl}/api/manage/updateEntityConfig`,
          data
        );
        dispatch({
          type: ENTITY_CONFIG_UPDATE_SUCCESS,
          signatureSignInDisabled: _.get(r, "data.signatureSignInDisabled"),
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function useFetchEntityConfig() {
  const dispatch = useDispatch();

  const {
    entityConfig,
    updateEntityConfigPending,
    userGuideInfo,
    fetchUserGuideInfoDone,
    ticketTypeList,
  } = useSelector(
    (state) => ({
      entityConfig: state.metadesk.entityConfig,
      updateEntityConfigPending: state.metadesk.updateEntityConfigPending,
      fetchUserGuideInfoDone: state.metadesk.fetchUserGuideInfoDone,
      userGuideInfo: state.metadesk.userGuideInfo,
      ticketTypeList: state.metadesk.ticketTypeList,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchEntityConfig(data));
    },
    [dispatch]
  );
  const updateAction = useCallback(
    (data) => {
      return dispatch(updateEntityConfig(data));
    },
    [dispatch]
  );
  const userGuideAction = useCallback(
    (data) => {
      return dispatch(fetchUserGuideInfo(data));
    },
    [dispatch]
  );
  const userGuideUpdateAction = useCallback(
    (data) => {
      return dispatch(updateUserGuideInfo(data));
    },
    [dispatch]
  );
  const userGuideCompleteAction = useCallback(
    (data) => {
      return dispatch(completeUserGuideInfo(data));
    },
    [dispatch]
  );
  const fetchTicketTypeListAction = useCallback(
    (data) => {
      return dispatch(fetchTicketTypeList(data));
    },
    [dispatch]
  );
  const updateTicketTypeListAction = useCallback(
    (data) => {
      return dispatch(updateTicketTypeList(data));
    },
    [dispatch]
  );
  const updateSurveyConfigAction = useCallback(
    (data) => {
      return dispatch(updateSurveyConfig(data));
    },
    [dispatch]
  );

  return {
    entityConfig,
    userGuideInfo,
    updateEntityConfigPending,
    fetchUserGuideInfoDone,
    ticketTypeList,
    updateEntityConfig: updateAction,
    fetchEntityConfig: boundAction,
    fetchUserGuideInfo: userGuideAction,
    updateUserGuideInfo: userGuideUpdateAction,
    completeUserGuideInfo: userGuideCompleteAction,
    fetchTicketTypeList: fetchTicketTypeListAction,
    updateTicketTypeList: updateTicketTypeListAction,
    updateSurveyConfig: updateSurveyConfigAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ENTITY_CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        entityConfig: action.data,
      };
    case ENTITY_CONFIG_UPDATE_BEGIN:
      return {
        ...state,
        updateEntityConfigPending: true,
      };

    case USER_GUIDE_INFO_FETCH_SUCCESS:
      return {
        ...state,
        fetchUserGuideInfoDone: {
          ...state.fetchUserGuideInfoDone,
          [action.guideType]: true,
        },
        userGuideInfo: {
          ...state.userGuideInfo,
          [action.guideType]: action.data,
        },
      };

    case ENTITY_CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        updateEntityConfigPending: false,
        entityConfig: {
          ...state.entityConfig,
          signatureSignInDisabled: action.signatureSignInDisabled,
        },
      };
    case TICKET_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        ticketTypeList: action.data,
      };

    case TICKET_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        ticketTypeList: action.data,
      };
    case SURVEY_CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        entityConfig: {
          ...state.entityConfig,
          surveyConfig: {
            isSurveyEnabled: action.data.isSurveyEnabled,
            surveyBtnStyle: action.data.surveyBtnStyle,
            surveyMsg: action.data.surveyMsg,
          },
        },
      };
    default:
      return state;
  }
}
