import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import WidgetTheme from "../components/WidgetTheme";
import WidgetLauncher from "../components/WidgetLauncher";
import WidgetLogo from "../components/WidgetLogo";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import _, { isEqual } from "lodash";
import { useOutletContext } from "react-router";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import { uploadFiles } from "features/helpers/utils";

export default function Customization({ data, handleRefetch, setHasChanges }) {
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const { updateWidgetConfig } = useFetchWidgetConfig();

  const firstFetchData = useRef(data);
  const [configs, setConfigs] = useState(data);

  const dispatchSnackbar = useDispatchSnackbar();
  const saveConfig = async () => {
    try {
      let { logo } = configs;
      let logos = [];
      if (configs.logo !== firstFetchData.current.logo) {
        logos = await uploadFiles([logo.file], entityName);
      }

      let payload = {
        ...configs,
        logo: logos[0],
      };
      await updateWidgetConfig({
        entityName,
        config: {
          ...payload,
          widgetId: _.get(data, "_id"),
        },
        type: "customization",
      });
      await handleRefetch();
      firstFetchData.current = data;
      dispatchSnackbar({
        message: formatMessage({
          id: "widgetSettings.configure.saveSuccess",
        }),
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      dispatchSnackbar({ err });
    }
  };

  const handleDiscard = () => {
    setConfigs(firstFetchData.current); // 回到初始狀態
  };

  const isSaveDisabled = useMemo(() => {
    return isEqual(configs, firstFetchData.current);
  }, [configs]);

  useEffect(() => {
    setHasChanges(!isSaveDisabled);
  }, [configs]);

  return (
    <div>
      <StyledWhiteContainer sx={{ margin: "12px 0", padding: "40px" }}>
        <WidgetLauncher configs={configs} setConfigs={setConfigs} />
      </StyledWhiteContainer>
      <StyledWhiteContainer sx={{ margin: "12px 0", padding: "40px" }}>
        <WidgetLogo
          imgs={[configs.logo]}
          setImgs={(img) => setConfigs({ ...configs, logo: img })}
        />
      </StyledWhiteContainer>
      <StyledWhiteContainer sx={{ margin: "12px 0", padding: "40px" }}>
        <WidgetTheme widgetItemInfo={configs} setConfigs={setConfigs} />
      </StyledWhiteContainer>

      <StyledWhiteContainer
        style={{ marginBottom: "12px", padding: "16px 32px 24px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size={SIZE.XL}
            variant={VARIANT.OUTLINED}
            disabled={isSaveDisabled}
            sx={{ width: 120 }}
            onClick={handleDiscard}
          >
            Discard
          </Button>
          <Button
            disabled={isSaveDisabled}
            size={SIZE.XL}
            sx={{ ml: "12px", width: 120 }}
            onClick={saveConfig}
          >
            {formatMessage({
              id: "global.btn.save",
            })}
          </Button>
        </Box>
      </StyledWhiteContainer>
    </div>
  );
}
