import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import Box from "@mui/material/Box";
import { map } from "lodash-es";

function PerformanceUtmLinksTable({
  data,
  headCells,
  loading,
  orderBy,
  order,
  onRowClick,
  onRequestSort,
}) {
  const rowData = data.data;

  const renderContents = (report) => {
    const contents = [
      <Box slot="link">
        <Box mb={1} sx={{ wordBreak: "break-all" }}>
          {report.originalLink}
        </Box>
        {report.shortLink ? (
          <Box color={(theme) => theme.customColors.purple[500]}>
            {report.shortLink}
          </Box>
        ) : (
          <Box color={(theme) => theme.customColors.purple[500]}>-</Box>
        )}
      </Box>,

      <Box slot="linkClicked">
        {report?.goal === "message" ? (
          <IconTooltip
            icon="-"
            content={`These results require a "Tracking tag" to be installed and a "UTM link" to be tracked.`}
          />
        ) : (
          report.linkClicked
        )}
      </Box>,

      <Box slot="walletConnected">
        {report?.goal === "message" ? "-" : report.walletConnected}
      </Box>,
      <Box slot="objectiveAchieved">
        {report?.goal === "message" ? (
          <IconTooltip
            icon="-"
            content={`These results require a "Tracking tag" to be installed and a "UTM link" to be tracked.`}
          />
        ) : (
          report.objectiveAchieved
        )}
      </Box>,
      <Box slot="conversionRate">
        {report?.goal === "message" ? (
          <IconTooltip
            icon="-"
            content={`These results require a "Tracking tag" to be installed and a "UTM link" to be tracked.`}
          />
        ) : isNaN(report.objectiveAchieved / report.linkClicked) ||
          report.linkClicked === 0 ? (
          "0.00%"
        ) : (
          (report.objectiveAchieved / report.linkClicked).toFixed(2) + "%"
        )}
      </Box>,
    ];

    return contents;
  };

  return (
    <>
      <CRMTable
        // isDraggable={true}
        containerStyle={{ minHeight: 300 }}
        rowData={rowData}
        headers={headCells}
        noKey={true}
        contents={map(rowData, (user, i) => renderContents(user))}
        onRowClick={onRowClick}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
      />
    </>
  );
}

export default PerformanceUtmLinksTable;
