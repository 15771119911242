import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import {
  InputField,
  SIZE,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import { Link, useHistory } from "react-router-dom";

export const StyledRegisterContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  minHeight: "100vh",
  backgroundImage: `url(${require("assets/img/loginBg.png")})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "23px 28px",
  display: "flex",
  flexDirection: 'column'
}));

export const StyledRegisterSwitchWalletRow = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const StyledAlreadyAUser = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .MuiButtonBase-root": {
    padding: 0,
  },
}));

export const StyledRegisterMainContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: "2vh",
  flex: 1,
  alignItems: 'center'
}));

export const StyledMetaDeskTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledMetaDeskTitleImage = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "24px",
  position: "relative",
}));

export const StyledBackButton = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  color: theme.customColors.grey[500],
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  left: 0,
  top: "50%",
  transform: "translateY(-50%)",
}));

export const StyledRegisterPaperContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, step }) => ({
  borderRadius: "10px",
  boxShadow: "0px 4px 10px 0px #0000001A",
  background: theme.customColors.white,
  width: "432px",
  padding: step === 1 ? "56px 46px 40px" : "56px 80px 40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const StyledRegisterStepTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "24px",
}));

export const StyledConnectWalletContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "10px 14px",
  borderRadius: "3px",
  background: theme.customColors.purple[300],
  marginBottom: "16px",
}));

export const StyledInputField = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledRegisterConnectWallet = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
}));

export const StyledHelpHint = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  color: theme.customColors.grey[600],
}));

export const StyledLink = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginLeft: "5px",
  color: theme.customColors.purple[500],
  "&:hover": {
    color: theme.customColors.purple[600],
  },
}));
