import React from "react";
import _ from "lodash";
import {
  Button,
  IconButton,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const IntegrationFailModal = ({ onRetry, open, setOpen, canClose }) => {
  return (
    <Modal open={open} className="modal">
      <div className="modalAlert">
        <div className="modalHeader">{"Activation Failed"}</div>
        <div className="modalDescription">
          Please try again or contact us <br />(
          <a target="_blank" href="mailto:bd@metacrm.inc">
            bd@metacrm.inc
          </a>
          )
        </div>
        <div style={{ marginTop: 12 }}>
          <Button
            style={{ margin: 0 }}
            onClick={onRetry}
            color={COLOR.SECONDARY}
            autoFocus
          >
            Try Again
          </Button>
          <div style={{ marginTop: 4 }}>
            <a target="_blank" href="mailto:bd@metacrm.inc">
              <Button
                variant={VARIANT.TEXT}
                color={COLOR.SECONDARY}
                style={{ margin: 0 }}
              >
                Contact Us
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IntegrationFailModal;
