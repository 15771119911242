import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useIntl } from "react-intl";
import Button, {
  COLOR,
  VARIANT,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { th } from "date-fns/locale";
import { StyledStep1ListItem } from "./TelegramSetting.styles";
import { useTheme } from "@mui/styles";
import useNavigateEntity from "hooks/useNavigateEntity";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useOutletContext, useSearchParams } from "react-router-dom";

const TelegramSettingStep2 = ({ onPrevStep, onNextStep }) => {
  const [entityName, isCustomDomain] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();

  const [apiToken, setApiToken] = useState("");
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [failDialogShow, setFailDialogShow] = useState(false);
  const [isLOading, setIsLoading] = useState(false);

  const handleConnect = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${apiUrl}/api/telegram/ticketBot/${entityName}/connect`,
        {
          botToken: apiToken,
        }
      );
      onNextStep();
    } catch (error) {
      setFailDialogShow(true);
      dispatchSnackbar({ err: error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box component={Grid} container spacing={10}>
        <Box component={Grid} item xs={12} sm={6}>
          <Box>
            <Box
              fontSize={"24px"}
              fontWeight={"700"}
              color={(theme) => theme.customColors.grey[700]}
              mb="8px"
            >
              {formatMessage({ id: "telegramBot.integrationWizard.title" })}
            </Box>

            <Box color={(theme) => theme.customColors.grey[600]} mb="32px">
              {formatMessage({
                id: "telegramBot.integrationWizard.description",
              })}
            </Box>

            <Box
              color={(theme) => theme.customColors.grey[700]}
              fontSize={"16px"}
              mb="24px"
            >
              {formatMessage({
                id: "telegramBot.integrationWizard.step2.subtitle",
              })}
            </Box>

            <StyledStep1ListItem>
              1.
              {formatMessage({
                id: "telegramBot.integrationWizard.step2.instruction1",
              })}
              <Box
                sx={{
                  display: "inline-block",
                  fontWeight: 700,
                  color: theme.customColors.purple[500],
                }}
              >
                ( img 02 )
              </Box>
            </StyledStep1ListItem>

            <StyledStep1ListItem>
              2.
              {formatMessage({
                id: "telegramBot.integrationWizard.step2.instruction2",
              })}
            </StyledStep1ListItem>

            <Box>
              <InputField
                placeholder={formatMessage({
                  id: "telegramBot.integrationWizard.step2.placeholder",
                })}
                value={apiToken}
                onChange={(e) => {
                  setApiToken(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box mt="50px">
            <Button
              variant={VARIANT.OUTLINED}
              sx={{ width: "120px", marginRight: "8px" }}
              onClick={onPrevStep}
            >
              {formatMessage({ id: "global.btn.back" })}
            </Button>
            <Button
              sx={{ width: "120px" }}
              onClick={handleConnect}
              disabled={!apiToken}
              isLoading={isLOading}
            >
              {formatMessage({ id: "global.btn.save" })}
            </Button>
          </Box>
        </Box>
        <Box component={Grid} item xs={12} sm={6}>
          <Box sx={{ backgroundColor: "#FAFAFD", padding: "17px 13px" }}>
            <Box
              sx={{
                fontWeight: "700",
                color: theme.customColors.grey[700],
                marginBottom: "32px",
              }}
            >
              <Box
                sx={{
                  color: theme.customColors.purple[500],
                  display: "inline-block",
                }}
              >
                {" "}
                img 01
              </Box>{" "}
              . Hit the /Start button within Telegram：
            </Box>
            <Box
              sx={{ margin: "0 auto", display: "block" }}
              component={"img"}
              src={require("assets/img/tg-setting-step2.jpg")}
              width={"279px"}
            ></Box>
          </Box>
        </Box>
      </Box>

      <BasicDialog
        dialogRootStyle={{ width: "350px", maxWidth: "100%" }}
        closeBtnShow={false}
        open={failDialogShow}
        show={failDialogShow}
      >
        <Box
          padding="30px 48px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ fontSize: "18px", fontWeight: "700", marginBottom: "12px" }}
            color={(theme) => theme.customColors.grey[700]}
          >
            {formatMessage({ id: "popup.activationFailed.title.telegramBot" })}
          </Box>

          <Box
            color={(theme) => theme.customColors.grey[600]}
            sx={{ textAlign: "center", marginBottom: "16px" }}
          >
            {formatMessage({
              id: "popup.activationFailed.description.telegramBot",
            })}
          </Box>

          <Box>
            <Button
              sx={{ width: "120px", marginRight: "8px" }}
              onClick={() => {
                setFailDialogShow(false);
              }}
            >
              I See
            </Button>
          </Box>
        </Box>
      </BasicDialog>
    </Box>
  );
};

export default TelegramSettingStep2;
