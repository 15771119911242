import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_AI_SUPPORT_CONFIGS_SUCCESS,
  UPDATE_AI_CONFIG_SUCCESS,
  ENABLE_MAGIC_WAND_SUCCESS,
  UPDATE_MAGIC_WAND_CONFIG_SUCCESS,
} from "../constants";
import axios from "axios";
import { apiUrl } from "features/configure";
import _ from "lodash";

export function fetchAiSupportConfig({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let config = await axios.get(
          `${apiUrl}/api/aiSupport/gitbook/getConfig?entityName=${entityName}`
        );
        dispatch({
          type: FETCH_AI_SUPPORT_CONFIGS_SUCCESS,
          data: config.data,
        });
        resolve(config);
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function updateAiConfig({ entityName, documentUrl }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let config = await axios.post(
          `${apiUrl}/api/aiSupport/gitbook/${entityName}/sync`,
          {
            documentUrl,
          }
        );
        dispatch({
          type: UPDATE_AI_CONFIG_SUCCESS,
          data: config.data,
        });
        resolve(config);
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function toggleMagicWand({ entityName, isEnableMagicWand }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let config = await axios.post(
          `${apiUrl}/api/aiSupport/${entityName}/toggle/magicWand`,
          {
            isEnableMagicWand: isEnableMagicWand,
          }
        );
        dispatch({
          type: ENABLE_MAGIC_WAND_SUCCESS,
          data: config.data,
        });
        resolve(config);
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function updateMagicWandConfig({ entityName, tone, length }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let config = await axios.post(
          `${apiUrl}/api/aiSupport/setup/magicWand`,
          {
            entityName,
            tone,
            length,
          }
        );
        dispatch({
          type: UPDATE_MAGIC_WAND_CONFIG_SUCCESS,
          data: config.data,
        });
        resolve(config);
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function useFetchAiSupportConfig() {
  const dispatch = useDispatch();

  const { aiSupport } = useSelector(
    (state) => ({
      aiSupport: state.metadesk.aiSupport,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchAiSupportConfig(data));
    },
    [dispatch]
  );

  const updateAiConfigAction = useCallback(
    (data) => {
      return dispatch(updateAiConfig(data));
    },
    [dispatch]
  );

  const updateToggleMagicWandAction = useCallback(
    (data) => {
      return dispatch(toggleMagicWand(data));
    },
    [dispatch]
  );

  const udpateMagicWandConfigAction = useCallback(
    (data) => {
      return dispatch(updateMagicWandConfig(data));
    },
    [dispatch]
  );

  return {
    aiSupport,
    fetchAiSupportConfig: boundAction,
    updateAiConfig: updateAiConfigAction,
    toggleMagicWand: updateToggleMagicWandAction,
    updateMagicWandConfig: udpateMagicWandConfigAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_AI_SUPPORT_CONFIGS_SUCCESS:
      return {
        ...state,
        entityConfig: {
          ...state.entityConfig,
          serviceModule: {
            ...state.entityConfig.serviceModule,
            aiSupport: _.get(action, "data.serviceModuleConfig.aiSupport"),
          },
        },
        aiSupport: {
          ...state.aiSupport,
          config: action.data?.config,
        },
      };

    case UPDATE_AI_CONFIG_SUCCESS:
      return {
        ...state,
        aiSupport: {
          ...state.aiSupport,
          config: {
            ...state.aiSupport.config,
            aiConfig: action.data.aiConfig,
          },
        },
      };

    case ENABLE_MAGIC_WAND_SUCCESS:
      return {
        ...state,
        entityConfig: {
          ...state.entityConfig,
          serviceModule: {
            ...state.entityConfig.serviceModule,
            aiSupport: _.get(action.data, "serviceModuleConfig.aiSupport"),
          },
        },
        aiSupport: {
          ...state.aiSupport,
          config: {
            ...state.aiSupport.config,
            magicWandConfig: _.get(action.data, "magicWandConfig"),
          },
        },
      };

    case UPDATE_MAGIC_WAND_CONFIG_SUCCESS:
      return {
        ...state,
        aiSupport: {
          ...state.aiSupport,
          config: {
            ...state.aiSupport.config,
            magicWandConfig: _.get(action.data, "magicWandConfig"),
          },
        },
      };
    default:
      return state;
  }
}
