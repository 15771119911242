import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Box, Popover } from "@mui/material";
import { useIntl } from "react-intl";
import moment from "moment";
import CustomToggle, {
  StyledToggleButton,
} from "components/CustomToggle/CustomToggle";
import DateRangeSelect from "./DateRangeSelect";

export default function MetaCRMDateRange({
  startDate,
  endDate,
  onChange,
  hasToggle = true,
  defaultRange = "all",
  isAllText,
  custom,
  onRangeChange,
  ...props
}) {
  const [range, setRange] = useState(hasToggle ? defaultRange : "custom");
  useEffect(() => {
    if (range == "custom") return;
    onRangeChange && onRangeChange(range);
    if (range == "all") {
      onChange(isAllText ? "all" : "", isAllText ? "all" : "");
      return;
    }
    const now = moment().utc().endOf("day");
    onChange(
      moment().utc().startOf("day").subtract(parseInt(range), "days"),
      now
    );
  }, [range]);

  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
      }}
    >
      {hasToggle && (
        <CustomToggle
          data={[
            { text: "1 Day", value: 0 },
            { text: "7 Days", value: 6 },
            { text: "30 Days", value: 29 },
            { text: "All", value: "all" },
          ]}
          value={range}
          onChange={(e, newValue) => {
            if (newValue == null) return;
            setRange(newValue);
          }}
        />
      )}

      <DateRangeSelect
        value={[
          {
            startDate:
              range == "all"
                ? new Date()
                : moment(startDate).startOf("day").toDate(),
            endDate:
              range == "all"
                ? new Date()
                : moment(endDate).startOf("day").toDate(),
            key: "selection",
          },
        ]}
        onChange={(value) => {
          onRangeChange && onRangeChange("custom");
          setRange("custom");
          const convertedStartDate = moment(value[0]?.startDate)
            .utcOffset(0, true)
            .startOf("day");
          const convertedEndDate = moment(value[0]?.endDate)
            .utcOffset(0, true)
            .endOf("day");
          onChange(convertedStartDate, convertedEndDate);
        }}
        custom={
          custom && (
            <StyledToggleButton
              className={range == "custom" ? "Mui-selected" : ""}
            >
              Custom
            </StyledToggleButton>
          )
        }
        {...props}
      />
    </Box>
  );
}
