import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import { apiUrl } from "features/configure";
import axios from "axios";

import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { PopperMenu } from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useOutletContext } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useIntl } from "react-intl";
import { formatDate } from "features/helpers/utils";

const getChainNameById = (chainList, id) => {
  const chain = chainList.find((chain) => chain.id === id);
  return chain ? chain.name : null; // 如果找到則返回 name，否則返回 null
};

const getChainLogo = (chainList, id) => {
  const chain = chainList.find((chain) => chain.id === id);
  return chain ? chain.logo_url : null; // 如果找到則返回 name，否則返回 null
};

const ObjectiveItem = ({ objectInfo, onDelete }) => {
  const theme = useTheme();
  const chainList = useSelector(selectEntityChainList);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = ({ objectInfoId }) => {
    confirm({
      title: formatMessage({
        id: "popup.deleteObjective.title",
      }),
      content: formatMessage({
        id: "popup.deleteObjective.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText: formatMessage({
        id: "global.btn.cancel",
      }),
    })
      .then(() => {
        onDelete({ objectInfoId });
      })
      .catch(() => {});
  };

  const itemList = [
    {
      children: <>Delete</>,
      func: (e) => {
        handleDelete({
          objectInfoId: objectInfo._id,
        });
      },
    },
  ];

  return (
    <Box
      sx={{
        background: theme.customColors.white,
        borderRadius: "8px",
        position: "relative",
        height: "280px",
        boxShadow: "2px 2px 8px 0px #0000001A",
      }}
    >
      <IconButton
        onClick={(event) => setOpen(true)}
        size={32}
        ref={anchorRef}
        sx={{
          position: "absolute",
          right: "8px",
          top: "12px",
        }}
      >
        <MoreVertIcon></MoreVertIcon>
      </IconButton>
      <PopperMenu
        itemList={itemList}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      />
      {/* <Box
        sx={{
          padding: "20px",
          fontWeight: "700",
          color: theme.customColors.grey[700],
          borderBottom: `1px solid ${theme.customColors.grey[300]}`,
        }}
      >
        Contract Interaction - {objectInfo?.eventName}
      </Box> */}
      <Box sx={{ padding: "20px 16px", height: "100%" }}>
        <Box>
          <Box>
            <Box mb={2} sx={{ fontWeight: "700" }}>
              <Box
                sx={{
                  fontSize: "12px",
                  color: theme.customColors.grey[600],
                  marginBottom: "8px",
                }}
              >
                {formatMessage({
                  id: "global.objectiveName",
                })}
              </Box>
              <Box sx={{ color: theme.customColors.grey[700] }}>
                {objectInfo?.name}
              </Box>
            </Box>

            <Box mb={2}>
              <Box
                sx={{
                  fontSize: "12px",
                  color: theme.customColors.grey[600],
                  marginBottom: "4px",
                }}
              >
                {formatMessage({
                  id: "global.chain",
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: theme.customColors.grey[700],
                }}
              >
                <Box
                  component="img"
                  width="18px"
                  height="18px"
                  mr="4px"
                  src={getChainLogo(chainList, objectInfo?.chainId)}
                ></Box>
                {getChainNameById(chainList, objectInfo?.chainId)}
              </Box>
            </Box>
          </Box>
          <Box mb={2}>
            <Box
              sx={{
                fontSize: "12px",
                color: theme.customColors.grey[600],
                marginBottom: "4px",
              }}
            >
              {formatMessage({
                id: "global.contract.address",
              })}
            </Box>
            <Box
              sx={{
                color: theme.customColors.grey[700],
                wordBreak: "break-all",
              }}
            >
              {objectInfo?.contractAddress}
            </Box>
          </Box>
          <Box mb="20px">
            <Box
              sx={{
                fontSize: "12px",
                color: theme.customColors.grey[600],
                marginBottom: "4px",
              }}
            >
              {formatMessage({
                id: "global.targetFunction",
              })}
            </Box>

            <Box sx={{ color: theme.customColors.grey[700] }}>
              {objectInfo?.eventName}
            </Box>
          </Box>

          <Box
            sx={{
              fontSize: "12px",
              color: theme.customColors.grey[500],
            }}
          >
            {formatDate(objectInfo?.updated)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ObjectiveItem;
