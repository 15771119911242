import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import Back from "features/metadesk/components/Back";
import { v4 as uuidv4 } from "uuid";
import WidgetAddContent from "./WidgetAddContent";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { selectWidgetModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import useNavigateEntity from "hooks/useNavigateEntity";
import { Loading } from "features/common";
const IntegrationWidgetAdd = () => {
  const [entityName, isCustomDomain] = useOutletContext();
  const [integrityInfo, setIntegrityInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const { widgetId } = useParams();
  const { navigateEntity } = useNavigateEntity();
  const { fetchWidgetConfig, widgetConfig } = useFetchWidgetConfig();
  const widgetModule = useSelector(selectWidgetModule);
  const [widgetsInfo, setWidgetsInfo] = useState({
    apiKey: "",
    created: new Date(),
    domainName: "",
    _id: uuidv4(),
    name: "New Widget",
    isNewAdd: true,
    connected: false,
  });

  // 如果setup incomplete，則來到此頁面，直接進行新增
  useEffect(() => {
    if (widgetId) {
      const widget = widgetConfig.find((item) => item._id === widgetId);
      setWidgetsInfo({ ...widget, isNewAdd: false });
    }
  }, []);

  const fetchIntegrity = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/integrity/widget`);
      setIntegrityInfo(data);
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  useEffect(async () => {
    try {
      setLoading(true);
      await fetchWidgetConfig({ entityName });
      if (
        widgetModule &&
        (widgetModule.connected || widgetModule.connecting) &&
        !widgetModule.multiDomain
      ) {
        navigateEntity("/settings/general/integration/add");
        return;
      }
      fetchIntegrity();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 110px)" }}>
        <Back />
        <div className="lgTitle" style={{ marginTop: 18 }}>
          Add MetaCRM Widget
        </div>
        <div className="description betweenRow" style={{ lineHeight: "20px" }}>
          To enable MetaCRM Widget as your service channel, please complete the
          following steps.
        </div>
        <Box marginTop={"15px"}>
          <Loading open={loading} fullScreen white={false} />
          {!isEmpty(integrityInfo) && (
            <WidgetAddContent
              setLoading={setLoading}
              widgetItemInfo={widgetsInfo}
              integrityInfo={integrityInfo}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default IntegrationWidgetAdd;
