import PopperMenu from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import useGetPagePermission from "hooks/useGetPagePermission";
import useNavigateEntity from "hooks/useNavigateEntity";
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";

export default function ActionDropDown({
  id,
  handleDelete,
  status,
  campaignName,
  promotype,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { formatMessage } = useIntl();
  const handleClose = () => {
    setOpen(false);
  };
  const { navigateEntity } = useNavigateEntity();
  const pagePermission = useGetPagePermission();

  const itemList = [];
  if (
    status === "ongoing" ||
    status === "done" ||
    pagePermission("campaigns").readonly
  ) {
    if (status !== "draft") {
      itemList.push({
        children: <>{formatMessage({ id: "global.taskStatus.view" })}</>,
        func: (e) => {
          e.stopPropagation();
          navigateEntity(`/marketing/campaign/edit/${id}`);
        },
      });
    }
    if (
      pagePermission("campaignPerformance").readonly &&
      (status === "ongoing" || status === "done")
    ) {
      itemList.push({
        children: <>{formatMessage({ id: "global.taskStatus.analysis" })}</>,
        func: (e) => {
          e.stopPropagation();
          navigateEntity(
            `/marketing/dashboard/campaign?search=${campaignName}`
          );
        },
      });
    }
  } else {
    itemList.push({
      children: <>{formatMessage({ id: "global.btn.edit" })}</>,
      func: (e) => {
        e.stopPropagation();
        if (status === "draft") {
          return navigateEntity(`/marketing/campaign/create/${id}`);
        }
        navigateEntity(`/marketing/campaign/edit/${id}`);
      },
    });
  }
  itemList.push({
    children: <>{formatMessage({ id: "global.btn.delete" })}</>,
    func: async (e) => {
      e.stopPropagation();
      await handleDelete(
        id,
        status === "ongoing" &&
          promotype == "flyer" &&
          formatMessage({ id: "campaign.delete.ongoingFlyerAlertMessage" })
      );
    },
  });

  return (
    <div
      className="flex-container"
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
    >
      {status === "draft" && pagePermission("campaigns").readonly ? null : (
        <>
          <i
            ref={anchorRef}
            className="meta-crm-icon-ic_dots font-size-20"
            color="#ffffff"
          />

          <PopperMenu
            itemList={itemList}
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
          />
        </>
      )}
    </div>
  );
}
