import React from "react";
import {
  AutoCompleteDropdown,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";

import { useIntl } from "react-intl";

import TailoredAudienceSelect from "./TailoredAudienceSelect";

export default function AudienceType({
  tabIndex,
  distributionSetting,
  settingIndex,
  segmentOptions,
  aiEnhancedUserOptions,
  selectedSegment,
  selectedTag,
  selectedAiEnhancedUserGrp,
  handleDropdownChange,
  tagOptions,
  customType,
  tailoredAudienceList,
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      {customType === "campaignAudiences" && (
        <TailoredAudienceSelect
          tailoredAudienceList={tailoredAudienceList}
          selectedAudiences={distributionSetting.campaignAudiences}
          setSelectedAudiences={(newValue) => {
            handleDropdownChange(
              "campaignAudiences",
              newValue,
              tabIndex,
              settingIndex,
              distributionSetting.campaignAudiences
            );
          }}
        />
      )}
      {customType === "aiEnhancedUser" && (
        <AutoCompleteDropdown
          size={SIZE.XL}
          isOptionPreview={true}
          disableCloseOnSelect={false}
          options={aiEnhancedUserOptions}
          value={selectedAiEnhancedUserGrp(settingIndex)}
          placeholder={formatMessage({
            id: "placeholder.selection.aiEnhancedUser",
          })}
          onChange={(event, newValue) => {
            handleDropdownChange(
              "aiEnhancedUser",
              newValue,
              tabIndex,
              settingIndex,
              distributionSetting.channel
            );
          }}
        />
      )}
      {customType === "segment" && (
        <AutoCompleteDropdown
          size={SIZE.XL}
          options={segmentOptions}
          value={selectedSegment(settingIndex)}
          placeholder={formatMessage({ id: "placeholder.selection.segment" })}
          onChange={(event, newValue) => {
            handleDropdownChange(
              "segment",
              newValue,
              tabIndex,
              settingIndex,
              distributionSetting.channel
            );
          }}
        />
      )}
      {customType === "tag" && (
        <AutoCompleteDropdown
          size={SIZE.XL}
          options={tagOptions}
          isTag
          value={selectedTag(settingIndex)}
          placeholder={formatMessage({ id: "placeholder.selection.userTag" })}
          onChange={(event, newValue) => {
            handleDropdownChange(
              "tag",
              newValue,
              tabIndex,
              settingIndex,
              distributionSetting.channel
            );
          }}
        />
      )}
    </>
  );
}
