import { Badge, Box, useMediaQuery, useTheme } from "@mui/material";
import { useFetchGithub } from "features/metadesk/redux/fetchGithub";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import DIDInfo from "./DIDInfo";
import GithubIssue from "./GithubIssue";
import SearchAddressInfo from "./SearchAddressInfo";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { PopperMenu } from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import {
  StyledControlArea,
  StyledDrawer,
  StyledDrawerContainer,
  StyledTab,
  StyledTabPanel,
  StyledTabs,
} from "./TicketDrawer.styles";
import { entityConfig as entityData } from "features/configure";
import Impersonator from "components/Impersonator/Impersonator";
import { getWeb3Url } from "features/helpers/web3";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TicketDrawer = ({
  renderUserInfo,
  entityName,
  selectedTicket,
  selectedUserAddress,
  selectedUserId,
  userCanEdit,
}) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(0);
  const anchorRef = useRef(null);
  const [selectedMirrorChain, setSelectedMirrorChain] = useState(0);
  const [open, setOpen] = useState(true);
  const [openMirror, setOpenMirror] = useState(false);
  const { githubIssues } = useFetchGithub();
  const { entityConfig } = useFetchEntityConfig();
  const theme = useTheme();
  const [openImpersonator, setOpenImpersonator] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderArrow = () => {
    let arrow;
    if (open) {
      arrow = !mobile ? (
        <i className="meta-crm-icon-ic_arrowLineRight font-size-16" />
      ) : (
        <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
      );
    } else {
      arrow = !mobile ? (
        <i className="meta-crm-icon-ic_arrowLineLeft font-size-16" />
      ) : (
        <i className="meta-crm-icon-ic_arrowLineDown font-size-16" />
      );
    }
    return arrow;
  };

  const renderSearchAddressInfo = () => (
    <SearchAddressInfo entityName={entityName} />
  );

  const renderDIDInfo = () => {
    return (
      <div
        style={{
          background: "#FAFAFD",
          padding: 0,
          overflowX: "hidden",
          height: "100%",
          overflowY: "auto",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        {!selectedUserAddress && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.9)",
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("assets/img/boxOpen.svg").default}
              style={{ width: 40, height: 40 }}
            />
            <div
              style={{
                color: "#777777",
                fontWeight: 500,
                fontSize: 14,
                marginTop: 7,
              }}
            >
              {formatMessage({ id: "ticketDrawer.did.empty" })}
            </div>
          </div>
        )}
        <DIDInfo
          selectedUserAddress={selectedUserAddress}
          selectedUserId={selectedUserId}
          entityName={entityName}
        />
      </div>
    );
  };

  const renderGithubIssue = () => (
    <GithubIssue selectedTicket={selectedTicket} />
  );

  const tabsLabel = [
    <i className="meta-crm-icon-ic_appUserProfile font-size-40" />,
    <i className="meta-crm-icon-ic_search font-size-28" />,
    <i className="meta-crm-icon-ic_appDid font-size-40" />,
  ];

  const tabsContent = [
    renderUserInfo(),
    renderSearchAddressInfo(),
    renderDIDInfo(),
  ];

  if (_.get(entityConfig, "serviceModule.github.connected")) {
    tabsLabel.push(
      <Badge
        color="secondary"
        badgeContent={
          <span style={{ marginTop: 2 }}>{githubIssues.length}</span>
        }
      >
        <i className="meta-crm-icon-ic_github font-size-20" />
      </Badge>
    );
    tabsContent.push(renderGithubIssue());
  }

  return (
    <StyledDrawer
      anchor={!mobile ? "right" : "top"}
      variant="permanent"
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Impersonator
        address={selectedUserAddress}
        webUrl={
          _.get(entityData, `[${entityName}]`, entityData.default)["mirrorUrl"]
        }
        rpcURL={getWeb3Url(selectedMirrorChain)} // TODO: change to selected chain
        open={openImpersonator}
        closeAction={() => {
          setSelectedMirrorChain(null);
          setOpenImpersonator(false);
        }}
        chainId={selectedMirrorChain}
      />
      <StyledControlArea onClick={handleToggle}>
        {renderArrow()}
      </StyledControlArea>
      <StyledDrawerContainer open={open}>
        {userCanEdit && (
          <StyledTabs
            orientation={!mobile ? "vertical" : "horizontal"}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            indicatorColor={"transparent"}
          >
            {tabsLabel.map((tab, index) => (
              <StyledTab
                label={tab}
                {...a11yProps(index)}
                key={index}
                // disabled={!selectedUserAddress}
              />
            ))}
            {_.get(entityData, `[${entityName}]`, entityData.default)[
              "mirrorUrl"
            ] && (
              <Box
                width={"40px"}
                height={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <PopperMenu
                  itemList={_.map(
                    _.get(entityData, `[${entityName}].chains`, []),
                    (chain, i) => {
                      return {
                        children: <>{chain.label}</>,
                        func: () => {
                          setSelectedMirrorChain(chain.value);
                          setOpenImpersonator(true);
                        },
                      };
                    }
                  )}
                  placement="bottom-end"
                  open={openMirror}
                  onClose={() => setOpenMirror(false)}
                  anchorEl={anchorRef.current}
                />
                <IconButton
                  size={"36px"}
                  onClick={() => {
                    setOpenMirror(true);
                  }}
                  color="#888"
                  ref={anchorRef}
                >
                  <i className="meta-crm-icon-ic_mirror font-size-28" />
                </IconButton>
              </Box>
            )}
          </StyledTabs>
        )}

        {tabsContent.map((tabContent, index) => (
          <StyledTabPanel open={open} value={value} index={index} key={index}>
            {tabContent}
          </StyledTabPanel>
        ))}
      </StyledDrawerContainer>
    </StyledDrawer>
  );
};

export default TicketDrawer;
