import FixedButton from "features/metadesk/settings/common/FixedButton";
import React, { useContext, useMemo, useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";
import ConfirmPublishModal from "./component/ConfirmPublishModal";
import { countTotalEngagementAndTracking } from "./utils";
import useCampaignSave from "./hook/useCampaignSave";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { getInitCustomLinkSetting } from "./utils/index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default function BottomFixedButton({ step, setStep, setIsOpen }) {
  const {
    stepData,
    isFromValid,
    shouldNavigate,
    isWidgetActive,
    isPushConnected,
    isXmtpConnected,
    isTrackingActive,
    isTracker,
    loading,
  } = useContext(CampaignCreationContext);

  const { campaignContentSets } = stepData;
  const { checkName, saveCampaign } = useCampaignSave(step);
  const { formatMessage } = useIntl();
  const ecosystemName = useSelector(selectEcosystemName);

  const goal = _.get(campaignContentSets, `${0}.goal`);
  const type = _.get(campaignContentSets, `${0}.type`);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useUnload((e) => {
    if (!shouldNavigate) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  async function next() {
    // if (step === 1 && !isTracker) {
    //   const isNameAvailable = await checkName();
    //   if (!isNameAvailable) return;
    //   if (campaignContentSets.length > 0) return setStep((prev) => prev + 1);
    //   return setIsOpen(true);
    // }
    if (step === 3 || (isTracker && step === 2)) {
      return setPublish(true);
    }
    setStep((prev) => prev + 1);
  }
  function prev() {
    if (step === 1) return;
    setStep((prev) => prev - 1);
  }

  const { TrackingTotal, EstimateTotal } = useMemo(
    () => countTotalEngagementAndTracking(campaignContentSets),
    [campaignContentSets]
  );
  const [publish, setPublish] = useState(false);

  const disabledNext = () => {
    if (loading) return true;
    if (!isFromValid) return !isFromValid;
    if (step === 1) {
      if (type === "tracker") return !isTrackingActive;
      if (type === "flyer") return !isPushConnected && !isXmtpConnected;
      if (type === "widget") return !isWidgetActive;
    }
    return false;
  };

  const renderSaveDraft = () => {
    const tempCampaignName = `Campaign ${moment().toISOString()}`;
    return (
      <Button
        variant={VARIANT.OUTLINED}
        onClick={() => saveCampaign({ isDraft: true, tempCampaignName })}
        sx={{ width: "144px", mr: "8px" }}
        disabled={loading || (step === 1 && !isFromValid && !isTracker)}
      >
        {formatMessage({ id: "global.btn.saveDraft" })}
      </Button>
    );
  };

  const renderNextButtonText = () => {
    if (step === 3) {
      return formatMessage({ id: "global.btn.create" });
    }
    if (isTracker && step === 2) {
      return formatMessage({ id: "global.btn.create" });
    }
    return formatMessage({ id: "global.btn.next" });
  };
  return (
    <>
      <FixedButton
        leftComponent={
          step > 1 &&
          !isMobile && (
            <Button
              variant={VARIANT.OUTLINED}
              sx={{
                width: "144px",
                marginLeft: 0,
              }}
              onClick={prev}
            >
              {formatMessage({ id: "global.btn.back" })}
            </Button>
          )
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {
            <p style={{ marginRight: "20px" }}>
              {TrackingTotal || EstimateTotal ? (
                <span style={{ color: "#777777", fontWeight: 400 }}>
                  Total:{" "}
                </span>
              ) : (
                ""
              )}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                  margin: "0 4px",
                }}
              >
                {TrackingTotal ? (
                  <>
                    <span style={{ color: "#7B61FF" }}>{TrackingTotal} </span>
                    {formatMessage({ id: "campaign.edit.trackingLinks" })}
                  </>
                ) : (
                  ""
                )}
                {TrackingTotal && EstimateTotal ? "," : ""}
                {!isTracker ? (
                  <>
                    <span style={{ color: "#7B61FF" }}>{EstimateTotal} </span>
                    {formatMessage({ id: "campaign.type.estimatedReach" })}
                  </>
                ) : (
                  ""
                )}
              </span>
            </p>
          }
          {renderSaveDraft()}

          <Button
            sx={{ width: "144px" }}
            onClick={next}
            disabled={disabledNext()}
          >
            {renderNextButtonText()}
          </Button>
        </div>
      </FixedButton>
      {(step === 3 || (isTracker && step === 2)) && (
        <ConfirmPublishModal open={publish} onClose={() => setPublish(false)} />
      )}

      <Prompt when={!shouldNavigate} />
    </>
  );
}
