import appTheme from "features/home/jss/appTheme";
import config from "../../config";
export {
  distributeABI,
  distributeNFTABI,
  erc20ABI,
  erc721ABI,
  masterMagpieABI,
  masterRadiantABI,
  vlMGPABI,
} from "./abi";
export { contracts } from "./contracts";
export { pools, rewardToCoingeckoIdMap } from "./pools";

export const images = {};
const context = require.context("assets/img", false, /\.(png|jpe?g|svg|gif)$/);

context.keys().forEach((item, index) => {
  images[item.replace("./", "")] = context(item);
});

export const defaultImage = "empty-token.png";

export const apiUrl = config.apiUrl;
export const socketIOUrl = config.socketIOUrl;

export const supportCenterBaseUrl = config.supportCenterBaseUrl;
export const adamUrl = config.adamUrl;

export const XMTP_ENV = config.xmtpEnv;
export const PUSH_ENV = config.pushEnv;

export const widgetJsUrl = (version) => {
  return version
    ? `${config.widgetUrl}widget-${version?.replace(/\./g, "-")}.js`
    : `${config.widgetUrl}widget.js`;
};
export const attributionJsUrl = (version) => {
  return version
    ? `${config.trackingUrl}tracking-${version?.replace(/\./g, "-")}.js`
    : `${config.trackingUrl}tracking.js`;
};
export const tokenApiBaseUrl = "https://api.1inch.io/v4.0/1";
export const nativeAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export const alchemyApiKey = "iGnhEUY1xDTVIN_WIl4bdkiFDWXHg22Z";

export const memberAvatarsMapping = {
  Admin: require(`assets/img/ic_alpacaPurple.svg`).default,
  Assistant: require(`assets/img/ic_alpacaGreen.svg`).default,
  Staff: require(`assets/img/ic_alpacaPink.svg`).default,
  Team: require(`assets/img/ic_alpacaYellow.svg`).default,
};

const renderChain = (name, img) => {
  return (
    <span className="startRow">
      <img src={require("assets/img/" + img)} className="smIcon" />
      <span>{name}</span>
    </span>
  );
};

export const solanaChainList = [
  {
    label: renderChain("Solana", "solana.png"),
    value: "solana",
    nativeToken: "solana",
    nativeTokenIcon: "solana.png",
    id: "solana",
    name: "Solana",
    logo_url: require("assets/img/solana.png"),
    explorer: "https://solscan.io/",
  },
];

export const aptosChainList = [
  {
    label: renderChain("Aptos", "aptos.png"),
    value: "aptos",
    nativeToken: "APTOS",
    nativeTokenIcon: "aptos.png",
    id: "aptos",
    name: "Aptos",
    logo_url: require("assets/img/aptos.png"),
    explorer: "https://aptoscan.com/",
  },
];

export const chainList = [
  {
    label: renderChain("Ethereum", "ETH.png"),
    value: 1,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    id: 1,
    name: "Ethereum",
    logo_url: require("assets/img/ETH.png"),
    explorer: "https://etherscan.io",
  },
  {
    label: renderChain("Polygon", "polygon.png"),
    value: 137,
    nativeToken: "MATIC",
    nativeTokenIcon: "polygon.png",
    id: 137,
    name: "Polygon",
    logo_url: require("assets/img/polygon.png"),
    explorer: "https://polygonscan.com",
  },
  {
    label: renderChain("Arbitrum", "arbitrum.svg"),
    value: 42161,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "Arbitrum",
    logo_url: require("assets/img/arbitrum.svg").default,
    id: 42161,
    explorer: "https://arbiscan.io",
  },
  {
    label: renderChain("BSC", "BSC.svg"),
    value: 56,
    id: 56,
    nativeToken: "BNB",
    nativeTokenIcon: "bsc.png",
    name: "BSC",
    logo_url: require("assets/img/BSC.svg").default,
    explorer: "https://bscscan.com",
  },
  {
    label: renderChain("Optimism", "op.png"),
    value: 10,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "Optimism",
    id: 10,
    logo_url: require("assets/img/op.png"),
    explorer: "https://optimistic.etherscan.io",
  },
  {
    label: renderChain("Blast", "blast.png"),
    value: 81457,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "Blast",
    id: 81457,
    logo_url: require("assets/img/blast.png"),
    explorer: "https://blastscan.io",
  },
  {
    label: renderChain("Manta", "manta.png"),
    value: 169,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "Manta",
    id: 169,
    logo_url: require("assets/img/manta.png"),
    explorer: "https://pacific-explorer.manta.network",
  },
  {
    label: renderChain("Moonbeam", "moonbeam.png"),
    value: 1284,
    nativeToken: "GLMR",
    nativeTokenIcon: "glmr.png",
    name: "Moonbeam",
    id: 1284,
    logo_url: require("assets/img/moonbeam.png"),
    explorer: "https://moonscan.io",
  },
  {
    label: renderChain("Base", "base.png"),
    value: 8453,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "Base",
    id: 8453,
    logo_url: require("assets/img/base.png"),
    explorer: "https://basescan.org",
  },
  {
    label: renderChain("zkSync", "zksync.png"),
    value: 324,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "zkSync",
    id: 324,
    logo_url: require("assets/img/zksync.png"),
    explorer: "https://era.zksync.network",
  },
  {
    label: renderChain("Linea", "linea.png"),
    value: 59144,
    nativeToken: "ETH",
    nativeTokenIcon: "ETH.png",
    name: "Linea",
    id: 59144,
    logo_url: require("assets/img/linea.png"),
    explorer: "https://lineascan.build",
  },
  {
    label: renderChain("Mantle", "mantle.png"),
    value: 5000,
    nativeToken: "MNT",
    nativeTokenIcon: "mantle.png",
    name: "Mantle",
    id: 5000,
    logo_url: require("assets/img/mantle.png"),
    explorer: "https://mantlescan.xyz",
  },
  {
    label: renderChain("Kaia", "kaia.png"),
    value: 8217,
    nativeToken: "KLAY",
    nativeTokenIcon: "kaia.png",
    name: "Kaia",
    id: 8217,
    logo_url: require("assets/img/kaia.png"),
    explorer: "https://kaiascan.io",
    disabledImport: true,
    disabledMarketing: true,
  },
  {
    label: renderChain("Soneium", "soneium.png"),
    value: 1946,
    nativeToken: "ETH",
    nativeTokenIcon: "soneium.png",
    name: "Soneium",
    id: 1946,
    logo_url: require("assets/img/soneium.png"),
    explorer: "https://explorer-testnet.soneium.org",
    disabledImport: true,
    disabledMarketing: true,
  },
];

export const chainIdMapping = {
  eth: 1,
  bsc: 56,
  polygon: 137,
  arb: 42161,
  arbitrum: 42161,
  op: 10,
  optimism: 10,
  avax: 43114,
  okt: 66,
  ftm: 250,
  xdai: 100,
  celo: 42220,
  blast: 81457,
  mobm: 1284,
  manta: 169,
  linea: 59144,
  base: 8453,
  zksync: 324,
  mantle: 5000,
  mnt: 5000,
  kaia: 8217,
  soneium: 1946,
};

export const importTypes = [
  {
    name: "nft",
    label: "NFT Holders",
    value: "nft-holder",
    id: "nft-holder",
  },
  {
    name: "token",
    label: "Token Holders",
    value: "token-holder",
    id: "token-holder",
  },
  {
    name: "contract",
    label: "Contract Interactors",
    value: "contract-interactor",
    id: "contract-interactor",
  },
];

export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#d2d0ee",
  "#b7f1a4",
  "#ae7d95",
  "#e194e6",
  "#696beb",
];

export const TAG_COLORS = [
  "#C2F0B6",
  "#D6BBFB",
  "#FDA19B",
  "#FEC84B",
  "#6CE9A6",
  "#7CD4FD",
  "#A4BCFD",
  "#BDB4FE",
  "#FAA7E0",
  "#EBD9B7",
  "#D0D5DD",
  "#EABBFB",
  "#FDBE9B",
  "#FEAC4B",
  "#6CE9E2",
  "#7CB0FD",
  "#BBFDA4",
  "#E0FF89",
  "#FCCEEE",
  "#F3E87F",
];

export const OPENSEA_TOP_LIST = [
  "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb", //CryptoPunk
  "0x05da517b1bf9999b7762eaefa8372341a1a47559", //KPR
  "0x60bb1e2aa1c9acafb4d34f71585d7e959f387769", //Art Gobbler
  "0x942bc2d3e7a589fe5bd4a5c6ef9727dfd82f5c8a", //Friendship Bracelets by Alexis André
  "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7", //Meebits
  "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b", //CloneX
  "0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258", //Otherdeed for Otherside
  "0x60e4d786628fea6478f785a6d7e704777c86a7c6", //Mutant Ape
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d", //Bored Ape
  "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85", //ENS
];

export const OPENSEA_TOP_LIST_MAP = {
  "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb": "CryptoPunk",
  "0x05da517b1bf9999b7762eaefa8372341a1a47559": "KPR",
  "0x60bb1e2aa1c9acafb4d34f71585d7e959f387769": "Art Gobbler",
  "0x942bc2d3e7a589fe5bd4a5c6ef9727dfd82f5c8a":
    "Friendship Bracelets by Alexis André",
  "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7": "Meebits",
  "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b": "CloneX",
  "0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258": "Otherdeed for Otherside",
  "0x60e4d786628fea6478f785a6d7e704777c86a7c6": "Mutant Ape",
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d": "Bored Ape",
  "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85": "ENS",
};

export const defaultThemeConfig = {
  theme: (mode) => appTheme(mode),
  headerLogo: require("assets/img/header.svg").default,
};

export const entityConfig = {
  default: {
    mirrorUrl: "",
    enableMagic: false,
  },
  jeff: {
    mirrorUrl: "https://app.radiant.capital/#/dashboard",
    chains: [
      { label: "ARB", value: 42161 },
      { label: "BSC", value: 56 },
    ],
    enableMagic: false,
  },
  radiant: {
    mirrorUrl: "https://app.radiant.capital/#/dashboard",
    chains: [
      { label: "ARB", value: 42161 },
      { label: "BSC", value: 56 },
    ],
    enableMagic: false,
  },
};

export const objectiveList = [
  { label: "All", value: "" },
  { label: "Token Transfer In", value: "tokenIn" },
  { label: "Token Transfer Out", value: "tokenOut" },
  { label: "Mint NFT", value: "mintNFT" },
  { label: "Contract Interaction", value: "contract" },
];
