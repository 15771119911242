import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { Modal } from "@mui/material";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { isAddress } from "features/helpers/utils";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useFetchTeamMember } from "../../redux/fetchTeamMember";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useDispatch } from "react-redux";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
import {
  InputField,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import { useTheme } from "@mui/styles";
import { IconButton, Button } from "@metacrm/metacrm-material-ui/dist/Button";
import { useForm } from "react-hook-form";
import { alertServerError } from "features/helpers/utils";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
import useGetRoleList from "./hooks/useGetRoleList";
import { Loading } from "features/common";
import { getUserAvatarIcon } from "features/metadesk/utils";

const useStyles = makeStyles((theme) => ({
  info: {
    border: `1px solid #E6E6E6`,
    padding: "12px 16px",
    borderRadius: 8,
  },
  pending: {
    border: `1px dashed #E6E6E6`,
    padding: "12px 16px",
    borderRadius: 8,
    marginRight: 12,
  },
  name: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 4,
  },
  address: {
    fontWeight: 500,
    fontSize: 12,
    color: "#a5a5a5",
  },
  role: {
    color: "#0096FF",
    fontWeight: 500,
  },
}));

const OrganizationMembers = () => {
  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();
  const classes = useStyles();
  const { userData, getAuthenticatedInfo } = useConnectWallet();
  const [groupMembers, setGroupMembers] = useState({
    all: [],
    member: [],
    pending: [],
  });
  const [editAddressList, setEditAddressList] = useState({});
  const [openCopy, setOpenCopy] = useState(false);
  const [editNameMapping, setEditNameMapping] = useState({});
  const [editNameError, setEditNameError] = useState({});
  const theme = useTheme();
  const { memberList, fetchTeamMember, fetchTeamMemberByTicketPermission } =
    useFetchTeamMember();
  const dispatch = useDispatch();
  const copyLink = window.location.href.split("/settings/")[0];
  const userAddress = _.get(userData, "address", "N/A");
  const [serverError, setServerError] = useState();

  const formDataInit = useMemo(
    () => ({
      role: "",
      name: "",
      address: "",
    }),
    []
  );
  const [formData, setFormData] = useState(formDataInit);

  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: { ...formDataInit },
  });

  useEffect(() => {
    const members = [];
    const pending = [];
    const all = [];
    let self;
    if (userData) {
      let filteredMemberList = _.filter(memberList, function (m) {
        return !m.internal;
      });
      for (let m of filteredMemberList) {
        all.push(m);
        if (_.toLower(m.address) == _.toLower(userAddress)) {
          self = m;
        } else if (m.latestLogin) {
          members.push(m);
        } else {
          pending.push(m);
        }
      }
      setGroupMembers({ all, members, pending, self });
    }
  }, [memberList, userData]);

  const handleFieldChange = (field) => (event) => {
    setFormData((prevState) => ({ ...prevState, [field]: event.target.value }));
  };

  const handleAddMember = async () => {
    try {
      const result = await axios.post(`${apiUrl}/api/manage/team/add`, {
        entityName,
        teamMember: [formData],
      });

      const resultData = _.get(result, "data", []);
      let hasError = false;
      for (let r of resultData) {
        if (r.error) {
          setServerError(r.error);
          hasError = true;
          dispatch(
            enqueueSnackbar({
              message: `${_.get(r, "address")} ${r.error}`,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
        }
      }
      if (!hasError) {
        fetchTeamMember({ entityName });
        fetchTeamMemberByTicketPermission({ entityName });
        setOpenCopy(true);
        setFormData(formDataInit);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };

  const { allRoles, loading } = useGetRoleList();
  const pagePermission = useGetPagePermission();
  const { readonly } = pagePermission("organization");

  const canEdit = (address) => {
    if (readonly || userAddress == address) return false;
    return true;
  };

  const renderCopyLinkModal = () => {
    return (
      <Modal
        open={openCopy}
        onClose={(e) => {
          setOpenCopy(false);
        }}
        className="modal"
      >
        <div className="modalAlert" style={{ textAlign: "left", width: 491 }}>
          <IconButton
            onClick={(e) => setOpenCopy(false)}
            className="modalClose"
          >
            <i className="meta-crm-icon-ic_cancel font-size-18" />
          </IconButton>
          <div className="modalHeader">{"Invitation Link"}</div>
          <div className="modalDescription">
            {
              "The invitee will gain access to your project workspace by the link below."
            }
          </div>
          <div className="inputTitle" style={{ marginTop: 24 }}>
            {formatMessage({ id: "global.btn.link" })}
          </div>
          <div
            className="betweenRow formTitle"
            style={{
              color: theme.customColors.grey[700],
              background: theme.customColors.grey[200],
              borderRadius: 3,
              padding: 8,
              border: `1px solid ${theme.customColors.grey[400]}`,
            }}
          >
            {copyLink}
            <IconButton onClick={() => copyInviteLink()}>
              <i className="meta-crm-icon-ic_copy2 font-size-16" />
            </IconButton>
          </div>
          <div style={{ textAlign: "right", marginTop: 32 }}>
            <Button color="secondary" onClick={() => copyInviteLink()}>
              {formatMessage({ id: "global.btn.copy" })}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };
  const copyInviteLink = () => {
    navigator.clipboard.writeText(
      "Please use the following link to join our workspace on MetaCRM " +
        copyLink
    );
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };

  const updateTeamMember = async ({ role, address, name }) => {
    try {
      await axios.post(`${apiUrl}/api/manage/team/update`, {
        entityName,
        role,
        name,
        address,
      });
      if (address == userAddress) {
        getAuthenticatedInfo(entityName);
      }
      fetchTeamMember({ entityName });
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  const renderEditButton = (member) => {
    const { role, latestLogin, address, name } = member;
    if (!latestLogin)
      return (
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            copyInviteLink();
          }}
        >
          <span style={{ color: "#7B61FF" }} className="startRow">
            <i className="meta-crm-icon-ic_copy2 font-size-16 mgr4" />
            {formatMessage({
              id: "organizationSetting.organization.inviteLink",
            })}
          </span>
        </Button>
      );

    if (!canEdit(address)) return role.name;
    return (
      <CustomDropdown
        border
        fullWidth={true}
        placeholder={"N/A"}
        data={allRoles}
        value={role._id}
        onSelect={(e) => {
          updateTeamMember({ role: e.target.value, name, address });
        }}
      />
    );
  };

  const renderRemove = (memberRole, address) => {
    if (!canEdit(address)) return;
    return (
      <RemoveConfirm
        title={formatMessage({ id: "global.warning.confirm" })}
        text={formatMessage({
          id: "organizationSetting.users.deleteDescription",
        })}
        onRemove={async () => {
          try {
            await axios.post(`${apiUrl}/api/manage/team/delete`, {
              entityName,
              address,
            });
            setEditAddressList({
              ...editAddressList,
              [address]: "",
            });
            fetchTeamMember({ entityName });
          } catch (error) {
            dispatch(
              enqueueSnackbar({
                message: _.get(error, "response.data.error", "Error"),
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
          }
        }}
      />
    );
  };
  const renderSelfData = () => {
    const selfData = _.get(groupMembers, "self");
    if (!selfData) return;
    return (
      <Grid
        container
        columnSpacing={1.5}
        alignItems="center"
        style={{
          marginTop: 16,
        }}
      >
        <Grid item xs={readonly ? 12 : 11}>
          {renderMemberInfo(selfData)}
        </Grid>
        {!readonly && <Grid item xs={1} className="description"></Grid>}
      </Grid>
    );
  };

  const renderMemberInfo = (member) => {
    const { role, name, address } = member;
    return (
      <div
        className={`betweenRow ${
          member.latestLogin ? classes.info : classes.pending
        }`}
      >
        <div className="startRow">
          <img
            src={require(`assets/img/${getUserAvatarIcon(role._id)}`)}
            alt="roleImage"
            className="icon"
          />
          <div>
            {editNameMapping[address] ? (
              <div>
                <InputField
                  size={SIZE.MEDIUM}
                  variant={VARIANT.OUTLINED}
                  placeholder="Name"
                  value={editNameMapping[address]}
                  onChange={(e) => {
                    setEditNameMapping({
                      ...editNameMapping,
                      [address]: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.target.blur();
                    }
                  }}
                  errors={editNameError[address]}
                  onBlur={async () => {
                    const newName = editNameMapping[address];
                    if (
                      !newName ||
                      newName.length < 1 ||
                      newName.length > 25 ||
                      !/^[0-9A-Za-z\s_-]+$/g.test(newName)
                    ) {
                      setEditNameError({
                        ...editNameError,
                        [address]:
                          "Invalid name. Name's length should be between 1 and 25 without special characters.",
                      });
                      return;
                    }

                    await updateTeamMember({
                      name: newName,
                      role: role._id,
                      address,
                    });
                    const newData = _.cloneDeep(editNameMapping);
                    delete newData[address];
                    setEditNameMapping(newData);
                    delete editNameError[address];
                  }}
                />
              </div>
            ) : (
              <div className={`${classes.name} startRow`}>
                <div style={{ marginRight: 3 }}>{member.name || "N/A"}</div>
                {canEdit(address) && (
                  <IconButton
                    size={16}
                    onClick={() => {
                      setEditNameMapping({
                        ...editNameMapping,
                        [member.address]: name,
                      });
                    }}
                  >
                    <i className="meta-crm-icon-ic_edit-1" />
                  </IconButton>
                )}
              </div>
            )}

            <div className={classes.address}>{address}</div>
          </div>
        </div>

        {renderEditButton(member)}
      </div>
    );
  };

  return (
    <div>
      <Loading open={loading} fullScreen={false} />
      {renderCopyLinkModal()}

      <StyledWhiteContainer style={{ marginTop: "20px" }}>
        {!readonly && (
          <>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                  <InputField
                    fullWidth
                    name="address"
                    control={control}
                    size={SIZE.MEDIUM}
                    variant={VARIANT.OUTLINED}
                    rules={{
                      required: {
                        value: true,
                        message:
                          formatMessage({ id: "global.commonWord.address" }) +
                          formatMessage({ id: "global.error.required" }),
                      },
                      validate: {
                        required: (value) => {
                          if (!isAddress(value))
                            return formatMessage({
                              id: "global.error.addressValid",
                            });
                        },
                      },
                    }}
                    title={
                      <div>
                        {formatMessage({ id: "global.commonWord.address" })}
                        <span className="required">* </span>
                      </div>
                    }
                    placeholder="ex:0x123456789..."
                    errors={_.get(errorsInfo, "address.message")}
                    value={formData.address}
                    onChange={(event) => handleFieldChange("address")(event)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputField
                    fullWidth
                    control={control}
                    size={SIZE.MEDIUM}
                    name="name"
                    variant={VARIANT.OUTLINED}
                    rules={{
                      required: {
                        value: true,
                        message:
                          formatMessage({
                            id: "global.commonWord.name",
                          }) +
                          formatMessage({
                            id: "global.error.required",
                            length: 1,
                          }),
                      },
                      minLength: {
                        value: 1,
                        message:
                          formatMessage({
                            id: "global.commonWord.name",
                          }) +
                          formatMessage({
                            id: "global.error.minLength",
                            length: 1,
                          }),
                      },
                      maxLength: {
                        value: 25,
                        message:
                          formatMessage({
                            id: "global.commonWord.name",
                          }) +
                          formatMessage(
                            {
                              id: "global.error.maxLength",
                            },
                            { length: 25 }
                          ),
                      },
                      pattern: {
                        value: /^[0-9A-Za-z\s_-]+$/g,
                        message: formatMessage({
                          id: "global.error.nameValid",
                        }),
                      },
                    }}
                    title={
                      <div>
                        {formatMessage({ id: "global.commonWord.name" })}
                        <span className="required">* </span>
                      </div>
                    }
                    errors={_.get(errorsInfo, "name.message")}
                    placeholder={formatMessage({
                      id: "global.commonWord.name",
                    })}
                    value={formData.name}
                    onChange={(event) => handleFieldChange("name")(event)}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <div className="inputTitle startRow">
                    {formatMessage({
                      id: "organizationSetting.organization.permissionRole",
                    })}
                    <span className="required" style={{ marginRight: 2 }}>
                      *{" "}
                    </span>
                  </div>
                  <CustomDropdown
                    border
                    sm
                    name="role"
                    rules={{
                      required: {
                        value: true,
                        message: "Role is required",
                      },
                    }}
                    control={control}
                    error={_.get(errorsInfo, "role.message")}
                    fullWidth={true}
                    placeholder={"N/A"}
                    data={allRoles}
                    value={formData.role}
                    onSelect={(event) => handleFieldChange("role")(event)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    color="secondary"
                    disabled={!isValid || !formData.role}
                    size={SIZE.MEDIUM}
                    style={{ minWidth: 120, marginTop: 23 }}
                    onClick={() => {
                      handleSubmit(handleAddMember)();
                    }}
                  >
                    {formatMessage({ id: "global.btn.invite" })}
                  </Button>
                </Grid>
              </Grid>

              {serverError && <div className="error">{serverError}</div>}
            </div>
            <div className="hr" />
          </>
        )}
        <div>
          <div className="subTitle" style={{ marginBottom: 16 }}>
            {formatMessage({ id: "organizationSetting.users.title" })}
          </div>
          <div className="description">
            {formatMessage({ id: "organizationSetting.users.description" })}
          </div>
          <div>
            {renderSelfData()}
            {_.get(groupMembers, "members", []).map((member, i) => {
              return (
                <Grid
                  container
                  key={i}
                  alignItems="center"
                  style={{ marginTop: 16 }}
                  columnSpacing={1.5}
                >
                  <Grid item xs={readonly ? 12 : 11}>
                    {renderMemberInfo(member)}
                  </Grid>
                  {!readonly && (
                    <Grid item xs={1}>
                      {renderRemove(member.role?.name, member.address)}
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </div>
          <div className="hr" />
          <div className="subTitle">
            {formatMessage({ id: "organizationSetting.pendingInvites.title" })}
          </div>
          <div className="description">
            {formatMessage({
              id: "organizationSetting.pendingInvites.description",
            })}
          </div>
          <div style={{ minHeight: 200 }}>
            {_.get(groupMembers, "pending", []).map((member, i) => {
              return (
                <Grid
                  container
                  columnSpacing={1.5}
                  key={i}
                  alignItems="center"
                  style={{ marginTop: 16 }}
                >
                  <Grid item xs={readonly ? 12 : 11}>
                    {renderMemberInfo(member)}
                  </Grid>
                  {!readonly && (
                    <Grid item xs={1}>
                      {renderRemove(member.role?.name, member.address)}
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </div>
        </div>
      </StyledWhiteContainer>
    </div>
  );
};

export default OrganizationMembers;
