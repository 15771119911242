import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import { get, isEmpty, isEqual } from "lodash-es";

import {
  Button,
  IconButton,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  StyledSettingAreaContainer,
  StyledSettingAreaTitle,
  StyledSettingAreaHint,
  StyledSettingListContainer,
  StyledSettingAreaButtonContainer,
} from "./SettingSupportCenter.styles";
import { StyledSettingSupportCenterContainer } from "features/metadesk/settings/Customization/SettingSupportCenter/SettingSupportCenter.styles";
import SettingItem from "./SettingItem/SettingItem";
import DemoTemplate from "./DemoTemplate/DemoTemplate";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { useForm, useFormState } from "react-hook-form";

import axios from "axios";
import { apiUrl } from "features/configure";

import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";

import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";

const defaultColor = [
  {
    name: "primary",
    color: "#FFFFFF",
    hint: "Used for header and body.",
  },
  {
    name: "text",
    color: "#383538",
    hint: "Used for titles and body text across support center.",
  },
  {
    name: "border",
    color: "#D6D6D6",
    hint: "Used for the outline of text blocks, columns and labels.",
  },
  {
    name: "button",
    color: "#7B61FF",
    hint: "Used for the main button.",
  },
  {
    name: "buttonText",
    color: "#FFFFFF",
    hint: "Used for the text color of main button.",
  },
  {
    name: "hover",
    color: "#F7F1FE",
    hint: "Used for the color of selected list.",
  },
  {
    name: "background",
    color: "#FFFFFF",
    hint: "Used for background color.",
  },
];

const SettingSupportCenter = () => {
  const [colorArray, setColorArray] = useState(defaultColor);
  const navigate = useNavigate();
  const [entityName, isCustomDomain] = useOutletContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsSuccess, setSaveIsSuccess] = useState(false);

  const colorValueForForm = (dataArray) =>
    dataArray.reduce((acc, item) => {
      acc[item.name] = item.color;
      return acc;
    }, {});

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: colorValueForForm(colorArray),
  });
  const { isDirty } = useFormState({ control });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newColorArray = colorArray.map((item) => {
      if (item.name === name) {
        return { ...item, color: value };
      }
      return item;
    });
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setColorArray(newColorArray);
  };

  const handleBack = () => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/settings/general/integration/update/support-center"
      )
    );
  };

  const updateColorArray = (apiData) => {
    const updatedColors = colorArray.map((item) => {
      if (apiData[item.name]) {
        return {
          ...item,
          color: apiData[item.name],
        };
      }
      return item;
    });
    setColorArray(updatedColors);
  };

  const sendInfo = async (formData) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/theme/${entityName}`,
        {
          customColor: {
            primary: formData.primary,
            text: formData.text,
            border: formData.border,
            button: formData.button,
            hover: formData.hover,
            background: formData.background,
            buttonText: formData.buttonText,
          },
        }
      );
      const customColor = get(data, "customColor");
      updateColorArray(customColor);

      await reset(customColor);
      setSaveIsSuccess(true);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (saveIsSuccess) {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/settings/general/integration/update/support-center"
        )
      );
    }
  }, [saveIsSuccess]);

  const handleSubmitInfo = () => {
    handleSubmit(sendInfo)();
  };

  const fetchTheme = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/theme/${entityName}`
      );
      if (isEmpty(get(data, "customColor"))) {
        setColorArray(defaultColor);
      } else {
        updateColorArray(get(data, "customColor"));
        reset(get(data, "customColor"));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTheme();
  }, []);

  useUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  return (
    <StyledSettingSupportCenterContainer>
      <Box className="setting-container" sx={{ gap: "12px" }}>
        <Box flex="1">
          <StyledSettingAreaContainer>
            <StyledSettingAreaTitle>
              Choose color for your Support Center
            </StyledSettingAreaTitle>
            <StyledSettingAreaHint>
              Adjust the color scheme of your support center to let consumers{" "}
              <br /> feel your brand atmosphere
            </StyledSettingAreaHint>

            {isLoading ? (
              <Box
                sx={{
                  minHeight: "50vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Box>
            ) : (
              <>
                <StyledSettingListContainer>
                  {colorArray.map((colorSetting) => (
                    <SettingItem
                      colorSetting={colorSetting}
                      key={colorSetting.name}
                      control={control}
                      onChange={handleChange}
                      errorsInfo={errorsInfo}
                    />
                  ))}
                </StyledSettingListContainer>
                <StyledSettingAreaButtonContainer>
                  <Button
                    variant={VARIANT.OUTLINED}
                    sx={{ width: "120px", marginRight: "8px" }}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{ width: "120px" }}
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmitInfo}
                  >
                    Save
                  </Button>
                </StyledSettingAreaButtonContainer>
              </>
            )}
          </StyledSettingAreaContainer>
        </Box>
        <Box flex="1">
          {isLoading ? (
            <Box
              sx={{
                minHeight: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingComponent />
            </Box>
          ) : (
            <DemoTemplate colorArray={colorArray} />
          )}
        </Box>
      </Box>

      <Prompt when={isDirty} />
    </StyledSettingSupportCenterContainer>
  );
};

export default SettingSupportCenter;
