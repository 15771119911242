import React from "react";
import {
  StyledSettingWidgetStepContainerOutSide,
  StyledSettingWidgetStepContainer,
  StyledSettingWidgetStepNumber,
  StyledSettingWidgetStepTitle,
  StyledSettingWidgetStepDescribe,
} from "features/metadesk/settings/integrations/IntegrationWidgetSetting.styles";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Box } from "@mui/material";

export default function StepContent({ settingStep }) {
  return (
    <StyledSettingWidgetStepContainerOutSide>
      <StyledSettingWidgetStepContainer
        noBottomPadding={settingStep?.noBottomPadding}
        container
        spacing={1}
        isIsolateComponent={false}
      >
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          {settingStep.stepNumber && (
            <StyledSettingWidgetStepNumber>
              {settingStep.stepNumber}
            </StyledSettingWidgetStepNumber>
          )}

          <StyledSettingWidgetStepTitle color={settingStep.titleColor}>
            {settingStep.title}
          </StyledSettingWidgetStepTitle>
          {settingStep.titleHint && (
            <Box
              display={"inline-block"}
              fontSize={"16px"}
              color={(theme) => theme.customColors.grey[500]}
              mr={1}
            >
              {settingStep.titleHint}
            </Box>
          )}
          {settingStep.status && settingStep.status()}
          {settingStep.titleContent}
          {settingStep.titleButton && settingStep.titleButton}
        </Grid>
        <Grid item xs={12}>
          <StyledSettingWidgetStepDescribe>
            {settingStep.describe}
          </StyledSettingWidgetStepDescribe>
        </Grid>
        {settingStep.content}
      </StyledSettingWidgetStepContainer>
    </StyledSettingWidgetStepContainerOutSide>
  );
}
