import _ from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RADIANT_ON_CHAIN_FETCH_BEGIN,
  RADIANT_ON_CHAIN_FETCH_SUCCESS,
  RADIANT_ON_CHAIN_FETCH_FAILURE,
} from "./constants";
import { Multicall } from "ethereum-multicall";
import {
  convertAmountFromRawNumber,
  convertHexToString,
} from "features/helpers/bignumber";
import axios from "axios";
import { apiUrl } from "features/configure";
const { getWeb3 } = require("../../../helpers/web3");

export function fetchRadiantOnchainDetails(input) {
  return async (dispatch, getState) => {
    let chainId = input.chainId;

    dispatch({
      type: RADIANT_ON_CHAIN_FETCH_BEGIN,
      chainId: chainId,
    });

    let userAddress = input.selectedUserAddress;
    const provider = getWeb3(chainId);
    const multicall = new Multicall({ web3Instance: provider });

    let resources = await axios.get(
      `${apiUrl}/api/entityOnchainResource/resource?chainId=` +
        chainId +
        "&entityName=" +
        input.entityName
    );

    const chefIncentivesControllerCallResource = _.find(resources.data, {
      name: "chefIncentivesController",
    });
    if (!chefIncentivesControllerCallResource) return;

    let chefIncentivesControllerCall = {
      reference: "incentives",
      contractAddress: chefIncentivesControllerCallResource.address,
      abi: chefIncentivesControllerCallResource.abi,
      calls: [
        {
          methodName: "allPendingRewards",
          methodParameters: [userAddress],
        },
      ],
    };

    const earnedBalancesCallResource = _.find(resources.data, {
      name: "multiFeeDistribution",
    });
    let earnedBalancesCall = {
      reference: "earnedBalances",
      contractAddress: earnedBalancesCallResource.address,
      abi: earnedBalancesCallResource.abi,
      calls: [
        {
          methodName: "earnedBalances",
          methodParameters: [userAddress],
        },
      ],
    };

    let calls = [];
    calls.push(chefIncentivesControllerCall);
    calls.push(earnedBalancesCall);

    try {
      const response = await multicall.call(calls);

      let earnedBalances =
        response.results.earnedBalances.callsReturnContext[0].returnValues;
      let incentives =
        response.results.incentives.callsReturnContext[0].returnValues;

      let currentlyVesting = convertAmountFromRawNumber(
        convertHexToString(earnedBalances[0]["hex"]),
        18
      );

      let readyToVest = convertAmountFromRawNumber(
        convertHexToString(incentives[0]["hex"]),
        18
      );

      let output = {};
      output["currentlyVesting"] = currentlyVesting;
      output["readyToVest"] = readyToVest;

      dispatch({
        type: RADIANT_ON_CHAIN_FETCH_SUCCESS,
        data: output,
        chainId: chainId,
      });
    } catch (error) {
      dispatch({
        type: RADIANT_ON_CHAIN_FETCH_FAILURE,
        chainId: chainId,
      });
      throw error;
    }
  };
}

export function useFetchRadiantOnchainDetails() {
  const dispatch = useDispatch();

  const {
    radiantOnchainDetails,
    fetchRadiantOnchainDetailsPending,
    fetchRadiantOnchainDetailsDone,
  } = useSelector(
    (state) => ({
      radiantOnchainDetails: state.metadesk.radiantOnchainDetails,
      fetchRadiantOnchainDetailsPending:
        state.metadesk.fetchRadiantOnchainDetailsPending,
      fetchRadiantOnchainDetailsDone:
        state.metadesk.fetchRadiantOnchainDetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRadiantOnchainDetails(data));
    },
    [dispatch]
  );

  return {
    radiantOnchainDetails,
    fetchRadiantOnchainDetails: boundAction,
    fetchRadiantOnchainDetailsDone,
    fetchRadiantOnchainDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RADIANT_ON_CHAIN_FETCH_BEGIN:
      return {
        ...state,
        fetchRadiantOnchainDetailsPending: {
          ...state.fetchRadiantOnchainDetailsPending,
          [action.chainId]: true,
        },
      };

    case RADIANT_ON_CHAIN_FETCH_SUCCESS:
      return {
        ...state,
        radiantOnchainDetails: {
          ...state.radiantOnchainDetails,
          [action.chainId]: action.data,
        },
        fetchRadiantOnchainDetailsDone: {
          ...state.fetchRadiantOnchainDetailsDone,
          [action.chainId]: true,
        },
        fetchRadiantOnchainDetailsPending: {
          ...state.fetchRadiantOnchainDetailsPending,
          [action.chainId]: false,
        },
      };

    case RADIANT_ON_CHAIN_FETCH_FAILURE:
      return {
        ...state,
        fetchRadiantOnchainDetailsPending: {
          ...state.fetchRadiantOnchainDetailsPending,
          [action.chainId]: false,
        },
      };

    default:
      return state;
  }
}
