import React from "react";
import SinglePerformanceHeader from "./SinglePerformanceHeader";
import SinglePerformanceTable from "./SinglePerformanceTable";
import { SinglePerformanceProvider } from "./context/SinglePerformanceProvider";

export default function CampaignSinglePerformance() {
  return (
    <SinglePerformanceProvider>
      <SinglePerformanceHeader />
      <div className="mhr" />
      <SinglePerformanceTable />
    </SinglePerformanceProvider>
  );
}
