export const RADIANT_FETCH_BEGIN = "RADIANT_FETCH_BEGIN";
export const RADIANT_FETCH_SUCCESS = "RADIANT_FETCH_SUCCESS";
export const RADIANT_FETCH_FAILURE = "RADIANT_FETCH_FAILURE";

export const RADIANT_V1_FETCH_BEGIN = "RADIANT_V1_FETCH_BEGIN";
export const RADIANT_V1_FETCH_SUCCESS = "RADIANT_V1_FETCH_SUCCESS";
export const RADIANT_V1_FETCH_FAILURE = "RADIANT_V1_FETCH_FAILURE";

export const RADIANT_TXS_FETCH_BEGIN = "RADIANT_TXS_FETCH_BEGIN";
export const RADIANT_TXS_FETCH_SUCCESS = "RADIANT_TXS_FETCH_SUCCESS";
export const RADIANT_TXS_FETCH_FAILURE = "RADIANT_TXS_FETCH_FAILURE";

export const RADIANT_USER_DETAILS_FETCH_BEGIN =
  "RADIANT_USER_DETAILS_FETCH_BEGIN";
export const RADIANT_USER_DETAILS_FETCH_SUCCESS =
  "RADIANT_USER_DETAILS_FETCH_SUCCESS";
export const RADIANT_USER_DETAILS_FETCH_FAILURE =
  "RADIANT_USER_DETAILS_FETCH_FAILURE";

export const RADIANT_ON_CHAIN_FETCH_BEGIN = "RADIANT_ON_CHAIN_FETCH_BEGIN";
export const RADIANT_ON_CHAIN_FETCH_SUCCESS = "RADIANT_ON_CHAIN_FETCH_SUCCESS";
export const RADIANT_ON_CHAIN_FETCH_FAILURE = "RADIANT_ON_CHAIN_FETCH_FAILURE";
