import { parseCustomDomainUrl } from "features/helpers/utils";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { selectTrialEntityName } from "features/metadesk/redux/trial/trial.selector";

export default function useNavigateEntity() {
  const navigate = useNavigate();
  const envEntity = process.env.REACT_APP_ENTITY;
  const context = useOutletContext();
  const selectorEntityName = useSelector(selectTrialEntityName);

  // 如果上下文中没有entityName和isCustomDomain，则从Redux和环境中获取
  const isCustomDomain = context?.[1] ?? Boolean(envEntity);
  const entityName = context?.[0] ?? selectorEntityName;

  function navigateEntity(url) {
    navigate(parseCustomDomainUrl(isCustomDomain, entityName, url));
  }

  function linkToEntity(url) {
    return parseCustomDomainUrl(isCustomDomain, entityName, url);
  }

  return { navigateEntity, linkToEntity, entityName, isCustomDomain, navigate };
}
