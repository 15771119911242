import React, { useState, useCallback } from "react";
import _ from "lodash";

import { Popover } from "@mui/material";

import WordCloud from "react-d3-cloud";
import { scaleOrdinal } from "d3-scale";
import {
  StyledTokenPopover,
  StyledSecondarySmallValue,
} from "features/metadesk/legacy/webpush/Notification.styles";
import { formatTvl } from "features/helpers/format";
const schemeCategory10ScaleOrdinal = scaleOrdinal([
  "#7B61FF",
  "#FFC814",
  "#FE90E6",
]);

export default function MetaWordCloud({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event, value) => {
    setAnchorEl({ event, value });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onWordMouseOver = useCallback((event, d) => {
    handlePopoverOpen(event, d);
  }, []);
  const open = Boolean(anchorEl);
  const tartgetData = _.find(data, { text: _.get(anchorEl, "value.text") });
  return (
    <div>
      <WordCloud
        data={data}
        width={700}
        height={200}
        font="Inter"
        fontWeight="500"
        fontSize={(word) => Math.log2(word.value) * 3}
        spiral="rectangular"
        rotate={(word) => (word.value % 4) * 90}
        padding={5}
        random={() => 0.8}
        fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
        onWordMouseOver={onWordMouseOver}
        onWordMouseOut={handlePopoverClose}
      />
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={_.get(anchorEl, "event.target")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <StyledTokenPopover>
          <div>{_.get(tartgetData, "text")}</div>
          <div className="xshr" />
          <div>Number of holders</div>
          <StyledSecondarySmallValue>
            {_.get(tartgetData, "holder")}
          </StyledSecondarySmallValue>
          <div>TVL</div>
          <StyledSecondarySmallValue>
            {formatTvl(_.get(tartgetData, "value"))}
          </StyledSecondarySmallValue>
        </StyledTokenPopover>
      </Popover>
    </div>
  );
}

MetaWordCloud.defaultProps = {};
