import React from "react";
import { StyledSettingWidgetStepDescribe } from "../settings/integrations/IntegrationWidgetSetting.styles";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import useNavigateEntity from "hooks/useNavigateEntity";
import { Link } from "react-router-dom";
const ReferralRules = ({ hasReferral }) => {
  const { navigateEntity } = useNavigateEntity();
  if (hasReferral) {
    return (
      <Box style={{ fontWeight: 400, color: "#777", lineHeight: "24px" }}>
        <Box mb={"8px"}>
          Your referral program is now ready to use! Next, please choose one of
          the following methods to generate your referral link
        </Box>
        <Box>
          <b>1. Manual Referral Link Generation:</b> <br />
          <Box ml="16px">
            If you choose to manually generate referral links, please provide
            the UTM link in the following format:
          </Box>
          <ul style={{ marginTop: "-2px", marginLeft: "-24px" }}>
            <li className="disc-li">Website URL: Your destination links</li>
            <li className="disc-li">Source: metacrmReferral</li>
            <li className="disc-li">{"Content: {referrer wallet address}"}</li>
            <li className="disc-li">
              Example:{" "}
              <span className="link">
                https://demo.example.com/?utm_source=metacrmReferral&utm_content=address
              </span>
            </li>
          </ul>
          <b>2. Automatic Referral Link Generation:</b> <br />
          <Box ml="16px">
            {" "}
            If you prefer to automate the generation of referral links, please
            refer to our API documentation for setup.{" "}
            <Link
              className="linkWithLine"
              onClick={() => {
                navigateEntity("/analytics/settings/referral/api");
              }}
            >
              API documentation
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box style={{ fontWeight: 400, color: "#777", lineHeight: "24px" }}>
      How to get started with referral：
      <Box>
        <b>1. Install the MetaCRM tracking tag</b> to activate your referral
        program (API optional).{" "}
        <a
          className="linkWithLine"
          href="https://metacrm.gitbook.io/metacrm/products/metacrm-for-marketing/referrals"
          target="_blank"
        >
          Referrals Tutorial
        </a>
      </Box>
      <Box>
        <b>2. Create your referral program</b> and set your objectives.
      </Box>
      <Box>
        <b>3. Generate referral links manually</b> using our UTM rules:{" "}
        <span
          className="linkWithLine"
          onClick={() => {
            navigateEntity("/analytics/settings/referral/api");
          }}
        >
          API documentation
        </span>
        <br />
        Example:{" "}
        <b>
          https://demo.example.com/?utm_source=metacrmReferral&utm_content=address
        </b>
      </Box>
      <Box>
        <b>4. (Optional) Use the MetaCRM API</b> to automate referral links,
        track referral activities, and view the leaderboard.{" "}
        <span
          className="linkWithLine"
          onClick={() => {
            navigateEntity("/analytics/settings/referral/api");
          }}
        >
          API documentation
        </span>
      </Box>
    </Box>
  );
};

export default ReferralRules;
