import { createContext, useContext } from "react";

const CreateGroupContext = createContext(null);

export const useCreateGroupContext = () => {
  const context = useContext(CreateGroupContext);

  if (!context) console.log("useCreateGroupContext error");

  return context;
};

export default CreateGroupContext;
