import React from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";

const NotFound = ({}) => {
  const navigate = useNavigate();
  return (
    <div
      className="loadingPaper"
      style={{
        background: "white",
        position: "relative",
        textAlign: "center",
      }}
    >
      <Button
        style={{ position: "absolute", top: "20px", right: "20px" }}
        variant={VARIANT.TEXT}
        onClick={() => {
          navigate("/workspace");
        }}
      >
        WorkSpace
      </Button>

      <div>
        <img src={require("assets/img/404.svg").default} />
        <div
          style={{
            marginTop: 32,
            marginBottom: 20,
            fontSize: 36,
            fontWeight: 800,
          }}
        >
          This page is lost.
        </div>
        <div style={{ fontWeight: 500, fontSize: 18 }}>
          We've explored deep and wide,
          <br /> but we can't find the page you were looking for.
        </div>
        <Button
          onClick={() => navigate("/")}
          variant={VARIANT.OUTLINED}
          style={{
            borderRadius: 5,
            marginTop: 32,
            height: 50,
          }}
        >
          <img
            src={require("assets/img/backButton.svg").default}
            style={{ marginRight: 5 }}
          />
          Navigate Back Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
