import styled from "@emotion/styled/macro";
import { Box } from "@mui/material";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledTicketDetailContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    height: "100%",
    borderRadius: "8px",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      overflowY: "auto",
    },
  };
});

export const StyledMessageInfoContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    width: "240px",
    padding: "12px",
    borderRight: "1px solid #e6e6e6",
    backgroundColor: "white",
    borderRadius: "8px 0 0 8px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  };
});

export const StyledChatAreaContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  position: "relative",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const StyledUserInfoContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  overflow: "auto",
  flexDirection: "column",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    height: "100vh",
  },
}));
