import React, { useEffect, useState } from "react";
import { useSafeInject } from "./SafeInjectContext"; // Adjust this import to your file structure
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const AppLoader = ({ appUrl, address, rpcURL, closeAction, chainId }) => {
  const {
    setAddress,
    setAppUrl,
    setRpcUrl,
    sendMessageToIFrame,
    iframeRef,
    setChainId,
  } = useSafeInject();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setAddress(address);
    setAppUrl(appUrl);
    setRpcUrl(rpcURL);
    setChainId(chainId);

    // // Assuming a message needs to be sent to initiate the app loading.
    // // This may vary depending on how your specific app functions.
    // sendMessageToIFrame({
    //   id: InterfaceMessageIds.Init,
    //   data: {}
    // });
  }, [address, appUrl, setAddress, setAppUrl, sendMessageToIFrame, rpcURL]);

  return (
    <div>
      <IconButton
        onClick={closeAction}
        style={{ position: "absolute", right: 3, top: 3, color: "#383538" }}
      >
        <CloseIcon />
      </IconButton>
      {isLoading && <div>Loading...</div>}
      <iframe
        ref={iframeRef}
        src={appUrl}
        title="Web3 App"
        width="1500px"
        height="800px"
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};

export default AppLoader;
