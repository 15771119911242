import { styled } from "@mui/material/styles";
import { Menu, MenuItem } from "@mui/material";
import isStyledPropsValid from "utils/isStyledPropsValid";
export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "300px",
    marginTop: "10px",
    position: "relative",
  },
  "& .MuiList-padding": {
    paddingTop: 0,
  },
  "& .sub-title": {
    fontSize: "12px !important",
    color: "#A5A5A5",
    opacity: 1,
    borderTop: "1px solid #F8F8F8",
    paddingTop: "16px",
  },
}));

export const StyledMenuTitle = styled("div")(({ theme }) => ({
  fontWeight: "700",
  fontSize: "14px",
  color: "#555555",
  cursor: "default",
  "&:hover": {
    backgroundColor: "unset",
  },

  "& button": {
    marginLeft: "auto",
    "&:hover, &:focus": {
      "& .MuiButton-label": {
        color: "#5643CC",
      },
    },
    "& .MuiButton-label": {
      color: "#5643CC",
      fontWeight: "500",
    },
  },
}));

export const StyledShownArea = styled("div")(({ theme }) => ({
  marginBottom: "16px",
}));

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isFixed }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "default",
  margin: "4px 0",
  "&:hover": {
    backgroundColor: "#F7F1FE",
  },

  "& .list-item-btn": {
    marginLeft: "auto",
    opacity: 1,
    "& i": {
      color: isFixed ? "#E6E6E6" : "#383538",
      fontWeight: "400",
    },
  },
}));

export const StyledDragHandlerBarArea = styled("div")(({ theme }) => ({
  width: "20px",
  height: "20px",
  marginRight: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#A5A5A5",
}));
