import React, { useState } from "react";
import useOutsideClick from "../../../campaign/campaignDetailPerformance/hook/useOutsideClick";
import { Box } from "@mui/material";
import {
  StyledDropDownContainer,
  StyledDropDownBtn,
  StyledDropDownText,
  StyledHRLine,
} from "./integrationComponent.styles";
import useGetIntegrationArr from "../useGetIntegrationArr";

export default function FilterDropDown({
  selectedFilters,
  setSelectedFilters,
  statusFilters,
  setStatusFilters,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  const integrationArr = useGetIntegrationArr({ combinedWidgetConfig: false });
  // const integrationList = integrationArr.filter((item) => item.auth);
  const integrationList = integrationArr
    .filter((item) => item.auth)
    .map((item) => item.title);

  const statusList = ["Active", "Update Available", "Setup Incomplete"];

  const handleReset = () => {
    setSelectedFilters([]);
    setStatusFilters([]); // 同時重置 status 篩選條件
  };

  const handleFilterChange = (filter, setFilterState, currentFilters) => {
    setFilterState(
      currentFilters.includes(filter)
        ? currentFilters.filter((f) => f !== filter) // 移除選中的項目
        : [...currentFilters, filter] // 新增選中的項目
    );
  };

  return (
    <Box ref={ref} position="relative">
      <StyledDropDownBtn onClick={() => setIsOpen(!isOpen)} active={isOpen}>
        <i className="meta-crm-icon-ic_filter2 font-size-16" />
        Filter {selectedFilters.length > 0 && `(${selectedFilters.length})`}
      </StyledDropDownBtn>
      {isOpen && (
        <StyledDropDownContainer sx={{ width: "183px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            height="24px"
            alignItems="center"
            fontSize="10px"
          >
            <Box color="#A5A5A5">Filter</Box>
            <Box
              color="#7B61FF"
              sx={{ cursor: "pointer" }}
              onClick={handleReset}
            >
              Clear All
            </Box>
          </Box>
          <StyledHRLine />
          <Box fontSize={12} color="#555">
            <Box color="#7B61FF" fontSize={10} m="13px 0 7px" fontWeight={700}>
              Integration {`(${selectedFilters.length})`}
            </Box>
            {integrationList.map((item, index) => (
              <StyledDropDownText
                key={index}
                onClick={() =>
                  handleFilterChange(item, setSelectedFilters, selectedFilters)
                }
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {item}
                {selectedFilters.includes(item) ? (
                  <i
                    className="meta-crm-icon-ic_check font-size-24"
                    style={{ color: "#7B61FF" }}
                  />
                ) : (
                  <Box width="24px" />
                )}
              </StyledDropDownText>
            ))}
          </Box>
          <StyledHRLine />
          <Box fontSize={12} color="#555">
            <Box color="#7B61FF" fontSize={10} m="13px 0 7px" fontWeight={700}>
              Status {`(${statusFilters.length})`}
            </Box>
            {statusList.map((item, index) => (
              <StyledDropDownText
                key={index}
                onClick={() =>
                  handleFilterChange(item, setStatusFilters, statusFilters)
                }
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {item}
                {statusFilters.includes(item) ? (
                  <i
                    className="meta-crm-icon-ic_check font-size-24"
                    style={{ color: "#7B61FF" }}
                  />
                ) : (
                  <Box width="24px" />
                )}
              </StyledDropDownText>
            ))}
          </Box>
        </StyledDropDownContainer>
      )}
    </Box>
  );
}
