import React, { useState, useRef, useContext, createContext } from "react";

// Create Context
const OpenContext = createContext();

export const useOpenState = () => useContext(OpenContext);

export const StateOpenContextProvider = ({ children }) => {
  const modulePinned =
    localStorage.getItem("metacrm_module_not_pinned") !== "true";
  const menuOpen = localStorage.getItem("metacrm_menu_not_open") !== "true";
  const [openState, setOpenState] = useState({
    wallet: false, // for login wallet modal
    menu: menuOpen, // for module -> 2nd menu open or not
    upgrade: false, // for upgrade popup
    hideSideMenu: false, // for single page width no sidebar
    loading: false,
    modulePinned,
    moduleOpen: modulePinned,
  });

  const setOpen = (values) => {
    setOpenState((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };
  return (
    <>
      <OpenContext.Provider value={{ openState, setOpen }}>
        {children}
      </OpenContext.Provider>
    </>
  );
};

export default StateOpenContextProvider;
