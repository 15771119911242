import React, { useState, useContext } from "react";
import _ from "lodash";
import {
  Button,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
  StyledTicketNameTitle,
  StyledChainDataContainer,
  StyledTokenRow,
  StyledTokenList,
} from "./OnChainDataSource.styles";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { apiUrl } from "features/configure";
import axios from "axios";
import useGetPagePermission from "hooks/useGetPagePermission";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import Box from "@mui/material/Box";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const TokenDisplayPreference = ({
  tokenDisplayChains,

  setTokenDisplayChains,
  entityName,
}) => {
  const [tokenList, setTokenList] = useState("");
  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");
  const chainList = useSelector(selectEntityChainList);
  const { formatMessage } = useIntl();

  return (
    <StyledGeneralSettingsContainer>
      <BasicDialog
        open={!_.isEmpty(tokenList)}
        onClose={() => {
          setTokenList("");
        }}
        dialogRootStyle={{ maxWidth: "420px" }}
      >
        <Box
          sx={{
            width: "400px",
            padding: "24px",
            display: !_.isEmpty(tokenList) ? "flex" : "none",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{ fontWeight: "700", fontSize: "18px", marginBottom: "12px" }}
          >
            Token List
          </Box>
          <Box
            color={(theme) => theme.customColors.grey[600]}
            sx={{ marginBottom: "24px" }}
          >
            <div className="startRow">
              Chain :{" "}
              {_.find(chainList, { value: _.get(tokenList, "chainId") })?.label}
            </div>
          </Box>
          <StyledTokenList>
            <Box>
              {_.map(_.get(tokenList, "tokens", []), (token, i) => {
                return (
                  <StyledTokenRow key={i}>
                    <img
                      className="smIcon"
                      src={token.icon}
                      style={{ marginRight: 8, borderRadius: 24 }}
                    />
                    {token.name}
                  </StyledTokenRow>
                );
              })}
            </Box>
          </StyledTokenList>
        </Box>
      </BasicDialog>
      <StyledSettingTitle>
        {formatMessage({
          id: "dataSourceSetting.tokenDisplay.title",
        })}
      </StyledSettingTitle>
      <StyledDescription>
        {formatMessage({
          id: "dataSourceSetting.tokenDisplay.description",
        })}
      </StyledDescription>
      <StyledTicketNameTitle>Chain</StyledTicketNameTitle>
      {_.map(chainList, (chain, i) => {
        return (
          <StyledChainDataContainer
            selected={tokenDisplayChains.includes(chain.value)}
            key={i}
          >
            {canEdit ? (
              <CustomCheckbox
                value={chain.value}
                style={{ marginRight: 12 }}
                checked={tokenDisplayChains.includes(chain.value)}
                onChange={(e) => {
                  const { value, checked } = e.target;
                  if (checked) {
                    setTokenDisplayChains([
                      ...tokenDisplayChains,
                      parseInt(value),
                    ]);
                  } else {
                    setTokenDisplayChains(
                      tokenDisplayChains.filter((item) => item != value)
                    );
                  }
                }}
              />
            ) : null}
            {chain.label}
            <Button
              variant={VARIANT.TEXT}
              color={COLOR.SECONDARY}
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                right: 5,
              }}
              onClick={async (e) => {
                const result = await axios.get(
                  apiUrl +
                    `/api/dataSource/${entityName}/assetList?chainId=${chain.value}&assetType=token`
                );
                setTokenList({
                  chainId: chain.value,
                  tokens: result.data,
                });
              }}
            >
              <span style={{ fontWeight: 700 }}>
                {formatMessage({
                  id: "global.function.detail",
                })}
              </span>
              <i
                className="meta-crm-icon-ic_arrowLineRight font-size-16"
                style={{ marginTop: 2 }}
              />
            </Button>
          </StyledChainDataContainer>
        );
      })}
    </StyledGeneralSettingsContainer>
  );
};

export default TokenDisplayPreference;
