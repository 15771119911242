import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { get, isEmpty, isEqual } from "lodash-es";
import SettingItem from "features/metadesk/settings/Customization/SettingSupportCenter/SettingItem/SettingItem";
import { StyledSettingListContainer } from "features/metadesk/settings/Customization/SettingSupportCenter/SettingSupportCenter.styles";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import useGetPagePermission from "hooks/useGetPagePermission";
import WidgetDemoTemplate from "features/metadesk/settings/integrations/widget/components/WidgetDemoTemplate";

const WidgetTheme = ({ widgetItemInfo, setConfigs }) => {
  const { formatMessage } = useIntl();

  const [entityName, isCustomDomain] = useOutletContext();
  const defaultColor = [
    {
      name: "primaryColor",
      hint: "Used for header and button",
      title: "Primary Color",
    },

    {
      name: "backgroundColor",
      hint: "Used for home background",
      title: "Background Color",
    },
    {
      name: "hoverColor",
      hint: "Used for text color",
      title: "Text Color",
    },
    {
      name: "textHoverColor",
      hint: "Used for the text color as hovering",
      title: "Text Hover Color",
    },
    {
      name: "cardColor",
      hint: "Used for header and button",
      title: "Card Color",
    },
    {
      name: "cardHoverColor",
      hint: "Used for the card color as hovering",
      title: "Card Hover Color",
    },
  ];

  const [colorArray, setColorArray] = useState(defaultColor);
  const colorValueForForm = (dataArray) =>
    dataArray.reduce((acc, item) => {
      acc[item.name] = item.color;
      return acc;
    }, {});

  useEffect(() => {
    if (get(widgetItemInfo, "colorTheme")) {
      updateColorArray(get(widgetItemInfo, "colorTheme")); //backend will assing default theme
    }
  }, [widgetItemInfo]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newColorArray = colorArray.map((item) => {
      if (item.name === name) {
        return { ...item, color: value };
      }
      return item;
    });

    setColorArray(newColorArray);
    setConfigs((prevConfigs) => ({
      ...prevConfigs,
      colorTheme: colorValueForForm(newColorArray),
    }));
  };

  const updateColorArray = (apiData) => {
    const updatedColors = colorArray.map((item) => {
      if (apiData[item?.name]) {
        return {
          ...item,
          color: apiData[item.name],
        };
      }
      return item;
    });
    setColorArray(updatedColors);
  };

  return (
    <Box
      sx={{
        background: "white",
      }}
    >
      <Box color="#555" fontSize={16} fontWeight={700} mb="8px">
        Color
      </Box>
      <Box color="#777" fontSize={14} fontWeight={500} mb="40px">
        You can adjust the default colors ​​to match your brand identity.
      </Box>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <Box sx={{ margin: "0 auto", paddingRight: "32px" }}>
            <div>
              <div style={{ marginBottom: 20 }} className="text">
                Set Your Color Theme
              </div>
              <StyledSettingListContainer>
                {colorArray.map((colorSetting, index) => {
                  return (
                    <>
                      <SettingItem
                        index={index + 1}
                        colorSetting={colorSetting}
                        key={colorSetting.name}
                        onChange={handleChange}
                      />
                      {index !== colorArray.length - 1 && (
                        <div className="mhr"></div>
                      )}
                    </>
                  );
                })}
              </StyledSettingListContainer>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box fontSize="14px" color="#A5A5A5" mb="4px">
            Preview
          </Box>
          <Box
            style={{
              background: "#F8F8F8",
              padding: "35px",
              textAlign: "center",
            }}
          >
            <WidgetDemoTemplate colorArray={colorArray} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WidgetTheme;
