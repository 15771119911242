import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  ATTRIBUTIONS_FETCH_BEGIN,
  ATTRIBUTIONS_FETCH_SUCCESS,
  ATTRIBUTIONS_FETCH_FAILURE,
  GET_ATTRIBUTION_BEGIN,
  GET_ATTRIBUTION_SUCCESS,
  GET_ATTRIBUTION_FAILURE,
  UPDATE_ATTRIBUTIONS_FILTER,
  UPDATE_SINGLE_ATTRIBUTION_FILTER,
} from "./constants";
import axios from "axios";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";

export const ATTRIBUTION_FILTER_INITIAL_STATE = {
  attributionFilterData: {
    page: 1,
    objective: "",
    searchName: "",
    sort: "created",
    objectiveAchieved: "",
    order: -1,
  },
};

export const ATTRIBUTION_DATA_FILTER_INITIAL_STATE = {
  singleAttributionFilter: {
    page: 1,
    userType: "",
    dateRange: [
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ],
    walletBalance: "",
    walletAge: "",
    sort: "created",
    order: -1,
  },
};

export function updateAttributionsFilter(data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ATTRIBUTIONS_FILTER,
      data,
    });
  };
}
export function updateSingleAttributionFilter({ campaignId, data }) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SINGLE_ATTRIBUTION_FILTER,
      data,
      campaignId,
    });
  };
}

export function fetchAttributions({ entityName, attributionsFilterData }) {
  return (dispatch, getState) => {
    dispatch({
      type: ATTRIBUTIONS_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const params = {
          page: _.get(attributionsFilterData, "page", 1),
          campaignName: _.get(attributionsFilterData, "searchName", ""),
          objective: _.get(attributionsFilterData, "objective", ""),
          sort: _.get(attributionsFilterData, "sort", "created"),
          order: _.get(attributionsFilterData, "order", -1),
        };
        let notifications = await axios.get(
          `${apiUrl}/api/legacy/campaign/${entityName}/performance`,
          { params }
        );
        const data = notifications.data;
        dispatch({
          type: ATTRIBUTIONS_FETCH_SUCCESS,
          data: data,
          attributionsFilterData,
          attributionsCount: notifications.headers["x-total-count"],
        });
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: ATTRIBUTIONS_FETCH_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}
export function getAttributionById({
  entityName,
  campaignId,
  filter,
  chartType,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ATTRIBUTION_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let params = {
          page: _.get(filter, "page", 1),
          periodStart: _.get(filter, "dateRange[0].startDate", ""),
          periodEnd: _.get(filter, "dateRange[0].endDate", ""),
          sort: _.get(filter, "sort", "created"),
          order: _.get(filter, "order", -1),
        };
        if (chartType == 1) {
          params = {
            ...params,
            walletBalance: _.get(filter, "walletBalance", ""),
            walletAge: _.get(filter, "walletAge", ""),
            userType: _.get(filter, "userType", ""),
            objectiveAchieved: _.get(filter, "objectiveAchieved", ""),
          };
        }
        let attributionData = await axios.get(
          `${apiUrl}/api/legacy/campaign/${entityName}/performance/${campaignId}`,
          { params }
        );
        const data = attributionData.data;
        let usersData = await axios.get(
          `${apiUrl}/api/legacy/campaign/${entityName}/userList/${campaignId}`,
          { params }
        );
        const users = usersData.data;
        dispatch({
          type: GET_ATTRIBUTION_SUCCESS,
          data: data,
          users: users,
          userCount: usersData.headers["x-total-count"],
          entityName,
          campaignId,
          filter,
        });
        resolve();
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: GET_ATTRIBUTION_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}

export function useFetchAttributions() {
  const dispatch = useDispatch();

  const {
    attributions,
    attributionData,
    fetchAttributionsPending,
    attributionsCount,
    attributionsFilterData,
    singleAttributionFilter,
  } = useSelector(
    (state) => ({
      attributions: state.metadesk.attributions,
      attributionData: state.metadesk.attributionData,
      fetchAttributionsPending: state.metadesk.fetchAttributionsPending,
      attributionsCount: state.metadesk.attributionsCount,
      attributionsFilterData: state.metadesk.attributionsFilterData,
      singleAttributionFilter: state.metadesk.singleAttributionFilter,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchAttributions(data));
    },
    [dispatch]
  );

  const getInfoAction = useCallback(
    (data) => {
      return dispatch(getAttributionById(data));
    },
    [dispatch]
  );
  const updateFilterAction = useCallback(
    (data) => {
      return dispatch(updateAttributionsFilter(data));
    },
    [dispatch]
  );

  const updateFilterAction2 = useCallback(
    (data) => {
      return dispatch(updateSingleAttributionFilter(data));
    },
    [dispatch]
  );

  return {
    attributions,
    attributionData,
    fetchAttributions: boundAction,
    getAttributionById: getInfoAction,
    updateAttributionsFilter: updateFilterAction,
    updateSingleAttributionFilter: updateFilterAction2,
    fetchAttributionsPending,
    attributionsCount,
    singleAttributionFilter,
    attributionsFilterData,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ATTRIBUTIONS_FETCH_BEGIN:
      return {
        ...state,
        fetchAttributionsPending: true,
      };

    case ATTRIBUTIONS_FETCH_SUCCESS:
      const newState = {
        ...state,
        attributionsCount: action.attributionsCount,
        attributions: action.data,
        attributionsFilterData: action.attributionsFilterData,
        fetchAttributionsPending: false,
      };
      return newState;

    case GET_ATTRIBUTION_BEGIN:
      return {
        ...state,
        fetchAttributionsPending: true,
      };

    case GET_ATTRIBUTION_SUCCESS:
      return {
        ...state,
        attributionData: {
          ...state.attributionData,
          [action.campaignId]: {
            ..._.get(state, `attributionData[${action.campaignId}]`, {}),
            data: action.data,
            users: action.users,
            userCount: action.userCount,
          },
        },
        fetchAttributionsPending: false,
      };
    case UPDATE_ATTRIBUTIONS_FILTER:
      return {
        ...state,
        attributionsFilterData: {
          ...state.attributionsFilterData,
          ...action.data,
        },
      };

    case UPDATE_SINGLE_ATTRIBUTION_FILTER:
      return {
        ...state,
        singleAttributionFilter: {
          ...state.singleAttributionFilter,
          [action.campaignId]: {
            ..._.get(
              state,
              `singleAttributionFilter[${action.campaignId}]`,
              ATTRIBUTION_DATA_FILTER_INITIAL_STATE.singleAttributionFilter
            ),
            ...action.data,
          },
        },
      };

    case ATTRIBUTIONS_FETCH_FAILURE:
    case GET_ATTRIBUTION_FAILURE:
      return {
        ...state,
        fetchAttributionsPending: false,
      };

    default:
      return state;
  }
}
