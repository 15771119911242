import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { apiUrl } from "features/configure";
import { useFetchTicketList } from "features/metadesk/redux/fetchTicketList";
function useSSEConnection({ auth, userAddress, entityName }) {
  const dispatch = useDispatch();
  const { fetchTicketList, ticketListFilter } = useFetchTicketList();
  const location = useLocation();
  useEffect(() => {
    let ticketEventSource;

    if (auth && userAddress && entityName) {
      // 監聽ticket
      if (!ticketEventSource) {
        // Establish SSE connection
        ticketEventSource = new EventSource(
          `${apiUrl}/api/sse/${entityName}/${userAddress}/events/tickets`
        );

        ticketEventSource.onmessage = (event) => {
          const message = JSON.parse(event.data);
          const pageStatus = location.pathname.split("/").pop();
          const ticketStatus = _.toLower(_.get(message, "data.ticketStatus"));
          switch (message.type) {
            case "newUserMsg":
              if (
                pageStatus == "all" ||
                (ticketStatus && ticketStatus == pageStatus)
              ) {
                fetchTicketList({
                  entityName,
                  filter: ticketListFilter,
                  status: pageStatus,
                });
              }
              break;
            case "newTicketCreated":
              if (pageStatus == "new" || pageStatus == "all") {
                fetchTicketList({
                  entityName,
                  filter: ticketListFilter,
                  status: pageStatus,
                });
              }
              break;
            default:
              break;
          }
        };

        ticketEventSource.onerror = (error) => {
          console.error("ticketEventSource error:", error);
          ticketEventSource.close();
        };
      }
    }

    // Cleanup on component unmount
    return () => {
      if (ticketEventSource) {
        ticketEventSource.close();
      }
    };
  }, [auth, userAddress, entityName, location]);
}

useSSEConnection.propTypes = {};

useSSEConnection.defaultProps = {};

export default useSSEConnection;
