import _ from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CURVE_ON_CHAIN_FETCH_BEGIN,
  CURVE_ON_CHAIN_FETCH_SUCCESS,
  CURVE_ON_CHAIN_FETCH_FAILURE,
} from "./constants";
import { curveTokens, curveSynthSwapNFT } from "../configure";
import { erc20ABI, alchemyApiKey } from "../../../configure";
import { Multicall } from "ethereum-multicall";
import {
  convertAmountFromRawNumber,
  convertHexToString,
} from "features/helpers/bignumber";
import {
  initializeAlchemy,
  getNftsForOwner,
  NftExcludeFilters,
} from "@alch/alchemy-sdk";
import * as Sentry from "@sentry/react";
const { getWeb3 } = require("../../../helpers/web3");

export function fetchCurveOnchainDetails(input) {
  return async (dispatch, getState) => {
    dispatch({
      type: CURVE_ON_CHAIN_FETCH_BEGIN,
    });

    const chainId = 1;
    const provider = getWeb3(chainId);
    const multicall = new Multicall({ web3Instance: provider });

    let allTokens = [...curveTokens];

    let calls = allTokens.map((token) => ({
      reference: token.name,
      contractAddress: token.address,
      abi: erc20ABI,
      calls: [
        {
          methodName: "balanceOf",
          methodParameters: [input.selectedUserAddress],
        },
      ],
    }));

    const settings = {
      apiKey: alchemyApiKey,
      maxRetries: 10,
    };

    const alchemy = initializeAlchemy(settings);
    let nftList = await getNftsForOwner(alchemy, input.selectedUserAddress, {
      excludeFilters: [NftExcludeFilters.SPAM],
      contractAddresses: [curveSynthSwapNFT.address],
    });

    const curveSynthSwapNFTList = nftList.ownedNfts.map((nft) => ({
      contractAddress: nft.contract.address,
      tokenId: nft.tokenId,
    }));

    try {
      const response = await multicall.call(calls);

      let pools = [];
      for (let i = 0; i < allTokens.length; i++) {
        let pool = allTokens[i];
        const poolBalances = convertHexToString(
          response.results[pool.name]["callsReturnContext"][0][
            "returnValues"
          ][0]["hex"]
        );
        pool["balances"] = convertAmountFromRawNumber(
          poolBalances,
          pool.decimals
        );
        pools.push(pool);
      }

      let output = {};
      output["pools"] = pools;
      output["curveSynthSwapNFTList"] = curveSynthSwapNFTList;

      dispatch({
        type: CURVE_ON_CHAIN_FETCH_SUCCESS,
        data: output,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch({
        type: CURVE_ON_CHAIN_FETCH_FAILURE,
      });
    }
  };
}

export function useFetchCurveOnchainDetails() {
  const dispatch = useDispatch();

  const {
    curveOnchainDetails,
    fetchCurveOnchainDetailsPending,
    fetchCurveOnchainDetailsDone,
  } = useSelector(
    (state) => ({
      curveOnchainDetails: state.metadesk.curveOnchainDetails,
      fetchCurveOnchainDetailsPending:
        state.metadesk.fetchCurveOnchainDetailsPending,
      fetchCurveOnchainDetailsDone: state.metadesk.fetchCurveOnchainDetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchCurveOnchainDetails(data));
    },
    [dispatch]
  );

  return {
    curveOnchainDetails,
    fetchCurveOnchainDetails: boundAction,
    fetchCurveOnchainDetailsDone,
    fetchCurveOnchainDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CURVE_ON_CHAIN_FETCH_BEGIN:
      return {
        ...state,
        fetchCurveOnchainDetailsPending: true,
      };

    case CURVE_ON_CHAIN_FETCH_SUCCESS:
      return {
        ...state,
        curveOnchainDetails: action.data,
        fetchCurveOnchainDetailsDone: true,
        fetchCurveOnchainDetailsPending: false,
      };

    case CURVE_ON_CHAIN_FETCH_FAILURE:
      return {
        ...state,
        fetchCurveOnchainDetailsPending: false,
      };

    default:
      return state;
  }
}
