import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";

export const StyledBasicModalContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, width, maxWidth }) => {
  return {
    position: "relative",
    minWidth: "350px",
    background: theme.customColors.white,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    outline: "none",
    overFlow: "hidden",
    fontFamily: "Inter",
    "& *": {
      fontFamily: "Inter",
    },
  };
});

export const StyledCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "block",
    marginLeft: "auto",
    marginTop: "16px",
    marginRight: "16px",
    "&:hover": {
      background: "none",
    },
  };
});

export const StyledModalContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "12px 30px 32px",
    width: "100%"
  };
});

export const StyledModalTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textAlign }) => {
  return {
    fontSize: "18px",
    fontWeight: theme.fontWeight.BOLD,
    marginBottom: "12px",
    textAlign: textAlign,
    width: "100%",
  };
});

export const StyledModalContent = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textAlign }) => {
  return {
    color: theme.customColors.grey[600],
    textAlign: "center",
    marginBottom: "12px",
    textAlign: textAlign,
    width: "100%",
  };
});

export const StyledButtonArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };
});
export const StyledButtonRowArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  };
});
