import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { Grid, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BigNumber from "bignumber.js";
import CustomDataFetching from "components/CustomDataFetching/CustomDataFetching";
import { chainIdMapping } from "features/configure";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import _ from "lodash";
import { useEffect, useState } from "react";
import { getNFTImageData } from "../utils";
import TextWithTooltip from "components/Text/TextWithTooltip";
import { useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";
import Token from "./Icons/Token";
import { useSelector } from "react-redux";
import { convertIfNumeric, formatDate } from "features/helpers/utils";

const useStyles = makeStyles((theme) => ({
  coinImage: {
    width: 20,
    height: 20,
    margin: "5px",
  },
  rowHeader: {
    color: "#7B61FF",
    fontSize: 10,
    fontWeight: 500,
  },
  row: {
    background: "white",
    padding: "3px 10px",
    fontSize: 12,
    fontWeight: 500,
    borderBottom: "1px solid #D6D6D6",
  },
}));

const UserDetail = ({ userInfo }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedChain, setSelectedChain] = useState();
  const [selectedNFT, setSelectedNFT] = useState();
  const [groupedEntitySpecificNFTs, setGroupedEntitySpecificNFTs] = useState(
    {}
  );
  let [searchParams] = useSearchParams();

  const { formatMessage } = useIntl();
  const [filteredWalletInfos, setFilteredWalletInfos] = useState({});
  const chainList = useSelector(selectEntityChainList);

  useEffect(() => {
    const entitySpecificNFTList = _.flatMap(
      Object.values(_.get(userInfo, "entitySpecificNFTInfoByChain", {})),
      "nftList"
    );
    let nftList = entitySpecificNFTList || [];

    if (nftList && nftList.length > 0) {
      var grouped = _.groupBy(nftList, "name");
      setGroupedEntitySpecificNFTs(grouped);
    } else {
      setGroupedEntitySpecificNFTs({});
    }
  }, [userInfo]);

  useEffect(() => {
    const filteredWalletInfos = _.pickBy(
      _.get(userInfo, "walletInfoByChain"),
      (walletInfo) => walletInfo.walletValue !== 0
    );

    if (filteredWalletInfos && _.keys(filteredWalletInfos).length > 0) {
      setFilteredWalletInfos(filteredWalletInfos);
    } else {
      setFilteredWalletInfos({});
    }

    const chainIds = Object.keys(filteredWalletInfos);
    const firstChainId = chainIds[0];
    setSelectedChain(firstChainId);
  }, [userInfo]);

  const renderTokenDetails = () => {
    return (
      <div>
        <CustomDataFetching
          fetching={_.some(userInfo.updateList, { onChainDataFetching: true })}
          loading={false}
          hasData={_.get(userInfo, "walletValue", 0) > 0}
          contents={renderTokenDetailsList}
        ></CustomDataFetching>
      </div>
    );
  };

  const renderTokenDetailsList = () => {
    return (
      <div style={{ marginTop: 10 }}>
        <div>
          <Grid container alignItems="center" className={classes.rowHeader}>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={2}>
              {formatMessage({ id: "userInfoContent.userDetail.asset" })}
            </Grid>
            <Grid item xs={12} sm={2}>
              {formatMessage({ id: "userInfoContent.userDetail.chain" })}
            </Grid>
            <Grid item xs={12} sm={2}>
              {formatMessage({ id: "userInfoContent.userDetail.value" })}
            </Grid>
            <Grid item xs={12} sm={3}>
              {formatMessage({ id: "userInfoContent.userDetail.balance" })}
            </Grid>
            <Grid item xs={12} sm={2}>
              {formatMessage({ id: "userInfoContent.userDetail.price" })}
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            marginTop: 5,
          }}
        >
          {_.sortBy(
            _.get(userInfo, `walletInfoByChain[${selectedChain}].tokenList`),
            [
              function (token) {
                return token.price * token.amount * -1;
              },
            ]
          ).map((token, i) => {
            const value = new BigNumber(token.price * token.amount).toFormat(0);
            const chainId =
              token.chainId || chainIdMapping[token.chain] || token.chain;

            return (
              <div className={classes.row} key={i}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={1}>
                    <Token
                      data={{
                        address: token.address,
                        chainId,
                        icon: token.icon,
                      }}
                      width="14px"
                      height="14px"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextWithTooltip data={token.symbol || token.name} />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {_.toUpper(token.chain)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {parseFloat(value) > 0.1 ? `$${value}` : "< $0.1"}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {new BigNumber(token.amount).toFormat(2) != "0.00"
                      ? new BigNumber(token.amount).toFormat(2)
                      : "< 0.01"}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {parseFloat(token.price) >= 1
                      ? `$${new BigNumber(token.price).toFormat(1)}`
                      : parseFloat(token.price) < 0.001
                      ? "< $0.001"
                      : `$${new BigNumber(token.price).toFormat(4)}`}
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderNFTDetails = () => {
    return (
      <div style={{ padding: 10 }}>
        <CustomDataFetching
          fetching={_.some(userInfo.updateList, { onChainDataFetching: true })}
          loading={false}
          hasData={
            groupedEntitySpecificNFTs &&
            _.keys(groupedEntitySpecificNFTs).length > 0
          }
          contents={renderNFTDetailsList}
        ></CustomDataFetching>
      </div>
    );
  };

  const renderNFTDetailsList = () => {
    return (
      <div style={{ padding: 10 }}>
        {_.map(_.keys(groupedEntitySpecificNFTs), (key) => {
          return (
            <>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 12,
                  margin: "10px 10px 10px 0px",
                }}
              >
                {key && key != "null" ? key : "Untitled"}
              </div>
              {_.map(groupedEntitySpecificNFTs[key], (nft) => {
                return (
                  <img
                    onClick={() => {
                      let output = _.find(userInfo.NFTTxs, {
                        to: userInfo.address.toLowerCase(),
                        contractAddress: nft.token_address,
                        tokenID: nft.token_id,
                      });
                      setOpen(true);
                      setSelectedNFT({ ...nft, intx: output });
                    }}
                    src={getNFTImageData(nft)}
                    style={{
                      height: 64,
                      width: 64,
                      margin: "0 5px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                );
              })}
            </>
          );
        })}
        <Modal
          open={open}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div
            className={classes.modalPaper}
            style={{
              margin: 10,
              minWidth: "50%",
              padding: 20,
              overflow: "auto",
              textAlign: "center",
              fontFamily: "Inter",
              backgroundColor: "white",
              borderRadius: 20,
            }}
          >
            <Grid container>
              <Grid item xs={6} md={3}>
                <img
                  style={{ width: 110, height: 110, borderRadius: 7.5 }}
                  src={selectedNFT ? getNFTImageData(selectedNFT) : ""}
                />
              </Grid>
              <Grid item xs={6} md={9}>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  {selectedNFT && selectedNFT.name && selectedNFT.name != "null"
                    ? selectedNFT.name
                    : "Untitled"}{" "}
                  {selectedNFT && selectedNFT.token_id
                    ? "#" + selectedNFT.token_id
                    : ""}
                  {selectedNFT && selectedNFT.amount > 1
                    ? "x" + selectedNFT.amount
                    : ""}{" "}
                </p>
              </Grid>
            </Grid>
            {selectedNFT && selectedNFT.intx && (
              <Grid
                container
                alignItems="center"
                style={{
                  background: "white",
                  margin: 5,
                  padding: "10px",
                  borderRadius: 5,
                  fontSize: 12,
                  fontWeight: 500,
                  border: "1px solid rgba(165, 165, 165, 0.5)",
                }}
              >
                <Grid item xs={2}>
                  <div style={{ color: "#A5A5A5" }}>
                    {selectedNFT.intx.timeStamp
                      ? formatDate(selectedNFT.intx.timeStamp * 1000, {
                          time: true,
                        })
                      : ""}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ fontWeight: 700 }} className="startRow">
                    <img
                      src={require(`assets/img/approve.svg`).default}
                      className="smIcon"
                    />
                    <div>
                      <div style={{ color: "#A5A5A5", marginBottom: 3 }}>
                        {selectedNFT.intx.tokenSymbol
                          ? selectedNFT.intx.tokenSymbol
                          : selectedNFT.tokenName
                          ? selectedNFT.tokenName
                          : ""}{" "}
                        -{" "}
                        {selectedNFT.intx.tokenID
                          ? selectedNFT.intx.tokenID
                          : ""}
                      </div>
                      {selectedNFT.intx.direction
                        ? selectedNFT.intx.direction
                        : ""}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ overflow: "hidden", margin: "0 5px" }}>
                    {selectedNFT.intx.marketPlace != "unknown"
                      ? selectedNFT.intx.marketPlace
                      : "-"}
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <a
                    href={
                      selectedNFT.intx.hash
                        ? `https://etherscan.io/tx/${selectedNFT.intx.hash}`
                        : ""
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Etherscan
                  </a>
                </Grid>
              </Grid>
            )}
          </div>
        </Modal>
      </div>
    );
  };

  let tabs = [
    {
      label: "TOKEN",
      content: <div>{renderTokenDetails()}</div>,
    },
  ];

  if (
    groupedEntitySpecificNFTs &&
    _.keys(groupedEntitySpecificNFTs).length > 0
  ) {
    tabs = [
      {
        label: "TOKEN",
        content: <div>{renderTokenDetails()}</div>,
      },
      {
        label: "NFT",
        content: <div>{renderNFTDetails()}</div>,
      },
    ];
  }

  return (
    <div className="userInfoContainer">
      <div
        style={{
          fontSize: 12,
          fontWeight: 700,
          marginBottom: "8px",
        }}
      >
        {formatMessage({
          id: "userInfoContent.userDetail.walletNetValue:",
        })}
        :
      </div>
      <div style={{ fontWeight: 700, fontSize: 16 }}>
        $ {new BigNumber(_.get(userInfo, "walletValue", 0)).toFormat(0)}
      </div>
      <div
        style={{
          fontSize: 12,
          fontWeight: 500,
          color: "#555555",
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        {formatMessage({ id: "userInfoContent.userDetail.selectChain" })}:
      </div>

      <Grid container spacing="8px" mb="20px">
        {_.map(filteredWalletInfos, (walletInfo, chainId) => {
          const isSelected = selectedChain == chainId;
          const chainData = _.find(chainList, {
            value: convertIfNumeric(chainId),
          });
          return (
            <Grid
              item
              xs={6}
              md={searchParams.get("userId") ? 3 : 6}
              key={chainId}
            >
              <div
                className="startRow"
                onClick={() => setSelectedChain(chainId)}
                style={{
                  fontWeight: 500,
                  cursor: "pointer",
                  background: isSelected ? "#FAF6FF" : "white",
                  border: isSelected ? "1px solid #7B61FF" : "1px solid white",
                  borderRadius: 4,
                  padding: "4px 8px",
                  height: 40,
                }}
              >
                <img
                  src={_.get(chainData, `logo_url`)}
                  style={{
                    marginRight: 8,
                    width: 20,
                    height: 20,
                    borderRadius: 30,
                  }}
                />
                <div>
                  <div
                    style={{
                      color: "#777",
                      fontWeight: 500,
                      overflowWrap: "break-word",
                      fontSize: 10,
                    }}
                  >
                    {formatMessage({
                      id: "userInfoContent.userDetail.assetsOn",
                    })}{" "}
                    {_.get(chainData, `name`)}
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, color: "#555" }}>
                    ${new BigNumber(walletInfo.walletValue).toFormat(0)}
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <div style={{ position: "relative" }}>
        <Tabs
          variant={"standard"}
          tabLabelStyle={{ fontSize: 12, fontWeight: 500 }}
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          tabs={tabs}
        />
      </div>
    </div>
  );
};

export default UserDetail;
