import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledTrialHeaderFixedContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "fixed",
  width: "100%",
  zIndex: 100,
}));

export const StyledTrialHeaderContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "60px",
  paddingLeft: "33px",
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${theme.customColors.grey[300]}`,
  background: theme.customColors.white,
}));
