import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { AccordionDetails, Box, Grid } from "@mui/material";
import TextWithTooltip from "components/Text/TextWithTooltip";
import _ from "lodash";
import { useState } from "react";
import {
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
} from "./CampaignCreation.styles";
import {
  AudienceCardTitle,
  AudienceCardValue,
} from "./TailoredAudienceSelect.styles";
export default function TailoredAudienceSelect({
  selectedAudiences,
  setSelectedAudiences,
  tailoredAudienceList,
}) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const defaultTailoredAudience = urlSearchParams.get("tailoredAudience");
  const [search, setSearch] = useState("");

  const toggleSelected = (data, isSelected) => {
    if (!isSelected) {
      if (selectedAudiences && selectedAudiences.length > 0) {
        setSelectedAudiences([...selectedAudiences, data]);
      } else {
        setSelectedAudiences([data]);
      }
    } else {
      const newSelected = _.filter(
        selectedAudiences,
        (item) => item._id !== data._id
      );
      setSelectedAudiences(newSelected.length == 0 ? "" : newSelected);
    }
  };
  const renderAudienceCard = (data, isSelected) => {
    return (
      <Box
        backgroundColor="white"
        height="100%"
        p="16px"
        style={{ cursor: "pointer" }}
        onClick={() => {
          toggleSelected(data, isSelected);
        }}
        border={isSelected ? "1px solid #7B61FF" : "1px dashed #E6E6E6"}
        borderRadius={"4px"}
      >
        <AudienceCardTitle>Audience Name</AudienceCardTitle>
        <AudienceCardValue style={{ fontWeight: "700" }}>
          {data.name}
        </AudienceCardValue>
        <AudienceCardTitle>Traffic Source </AudienceCardTitle>
        <AudienceCardValue>
          <TextWithTooltip
            data={data.trafficSource == "all" ? "All" : data.trafficSource}
            limitRows={2}
            style={{ fontSize: "14px", color: "#777777" }}
          />
        </AudienceCardValue>
        <AudienceCardTitle>Objective Status </AudienceCardTitle>
        <AudienceCardValue>
          {_.upperFirst(data.objectiveStatus)}
        </AudienceCardValue>
        <AudienceCardTitle>Total Wallet </AudienceCardTitle>
        <AudienceCardValue>{data.totalCount}</AudienceCardValue>
      </Box>
    );
  };

  const renderSelectedAudienceItemRow = (data) => {
    return (
      <Box
        mt="20px"
        p="16px"
        backgroundColor="#FAFAFD"
        borderLeft={"1px solid #7B61FF"}
      >
        <AudienceCardValue style={{ fontWeight: 700 }}>
          {data.name}
        </AudienceCardValue>
        <Box m="4px 0" color="#7B61FF" className="startRow" fontWeight={400}>
          <i className="meta-crm-icon-ic_wallet font-size-16 mgr4" />
          <Box className="mgr4">Estimated Reach :</Box>{" "}
          <Box fontSize={"24px"} fontWeight={700}>
            {data.totalWallet}
          </Box>
        </Box>
        <Box backgroundColor="white" p="24px">
          <Grid container gap="10px">
            <Grid item xs={12} md={3}>
              <Box mb="8px" fontWeight={500} color="#555">
                Traffic Source
              </Box>
              <Box fontWeight={400} color="#777777">
                <TextWithTooltip
                  data={
                    data.trafficSource == "all" ? "All" : data.trafficSource
                  }
                  limitRows={2}
                  style={{ fontSize: "14px", color: "#777777" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box mb="8px" fontWeight={500} color="#555">
                Objective Status
              </Box>
              <Box fontWeight={400} color="#777777">
                {_.upperFirst(data.objectiveStatus)}
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box mb="8px" fontWeight={500} color="#555">
                Total Wallet
              </Box>
              <Box fontWeight={400} color="#777777">
                {data.totalCount}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderSelectedTag = (audience, index) => {
    return (
      <Box
        key={index}
        sx={{
          border: "1px solid #7B61FF",
          backgroundColor: "#F7F1FE",
          borderRadius: "40px",
          height: "30px",
          padding: "5px 12px",
          color: "#555",
          fontSize: "14px",
          fontWeight: 500,
          marginRight: "8px",
        }}
      >
        {audience.name}
        <IconButton
          fontSize="14px"
          style={{ marginLeft: "4px" }}
          onClick={(e) => {
            toggleSelected(audience, true);
            e.stopPropagation();
          }}
        >
          <i className="meta-crm-icon-ic_cancel font-size-14" />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <StyledWhiteAccordion
          defaultExpanded={defaultTailoredAudience}
          disableGutters={true}
          style={{
            border: "1px dashed #E6E6E6",
            margin: "0px",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <StyledWhiteAccordionSummary
            expandIcon={
              <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
            }
            style={{ minHeight: "39px" }}
          >
            <Box
              p="6px 14px"
              fontSize="14px"
              color="rgb(85, 85, 85)"
              className="startRow"
            >
              {selectedAudiences && selectedAudiences.length > 0
                ? _.map(selectedAudiences, (audience, i) => {
                    return renderSelectedTag(audience, i);
                  })
                : "Select your Tailored Audience"}
            </Box>
          </StyledWhiteAccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#FAFAFD" }}>
            <InputField
              placeholder="Search for tailored audience"
              type="search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <Grid
              container
              mt="12px"
              alignItems={"stretch"}
              spacing={"8px"}
              maxHeight={"500px"}
              overflow={"auto"}
            >
              {_.map(
                _.filter(tailoredAudienceList, (o) => {
                  const searchLower = search.toLowerCase();
                  return ["name", "trafficSource", "objectiveStatus"].some(
                    (key) =>
                      o[key] && o[key].toLowerCase().includes(searchLower)
                  );
                }),
                (audience, i) => {
                  return (
                    <Grid item xs={12} md={3} key={i}>
                      {renderAudienceCard(
                        audience,
                        _.includes(
                          _.map(selectedAudiences, "_id"),
                          audience._id
                        )
                      )}
                    </Grid>
                  );
                }
              )}
            </Grid>
          </AccordionDetails>
        </StyledWhiteAccordion>{" "}
      </Box>
      {_.map(selectedAudiences, (audience, i) => {
        return renderSelectedAudienceItemRow(audience);
      })}
    </Box>
  );
}
