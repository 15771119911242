import React, { useState, useRef, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  SIZE,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { PerformanceContext } from "features/metadesk/campaign/campaignPerformance/context/PerformanceProvider";

const HoverImage = ({
  originalImg,
  hoverShowImg,
  hoverShowText,
  onClick,
  buttonText = "Go to integrate",
  showButton = true,
  showDropdown,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [containerHeight, setContainerHeight] = useState("auto");
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const originalImgRef = useRef(null);

  const {
    actionOptions = [],
    actionValue = {},
    setActionValue = () => {},
  } = useContext(PerformanceContext) || {};

  const handleChange = (event, newValue) => {
    setActionValue(newValue);
  };

  useEffect(() => {
    if (isHovered && contentRef.current && containerRef.current) {
      const contentHeight = contentRef.current.offsetHeight;
      const originalImgHeight = originalImgRef.current.offsetHeight;
      const newHeight = Math.max(originalImgHeight, contentHeight);
      setContainerHeight(`${newHeight}px`);
    } else {
      setContainerHeight("auto");
    }
  }, [isHovered]);
  return (
    <Box
      ref={containerRef}
      width="100%"
      height={containerHeight}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      position="relative"
      overflow="hidden"
    >
      {showDropdown && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 5,
            width: "200px",
            marginRight: "20px",
            paddingTop: "12px",
          }}
        >
          <AutoCompleteDropdown
            options={actionOptions}
            value={actionValue}
            onChange={handleChange}
          ></AutoCompleteDropdown>
        </Box>
      )}

      <Box
        ref={originalImgRef}
        component="img"
        src={originalImg}
        height={"100%"}
        width="100%"
      />
      <Box
        ref={contentRef}
        onClick={showButton ? null : onClick}
        sx={{
          display: isHovered ? "flex" : "none",
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: "4px",
          width: "100%",
          background: "white",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          boxSizing: "border-box",
          minHeight: containerHeight,
          cursor: showButton ? "default" : "pointer",
        }}
      >
        <Box component="img" src={hoverShowImg} mb="16px" maxWidth="180px" />
        <Box width="100%" sx={{ textAlign: "center", maxWidth: "260px" }}>
          {hoverShowText}
        </Box>
        {showButton && (
          <Button onClick={onClick} style={{ marginTop: "16px" }}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default HoverImage;
