import React from "react";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  selectChain: {
    color: "#555555",
    fontWeight: 400,
    fontSize: 12,
    padding: "0 13px",
  },
}));

const SelectChain = ({
  chains,
  tabName,
  selectedChain,
  setSelectedChainByTab,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.selectChain} startRow`}>
      <div style={{ marginRight: 5 }}>Select Chain:</div>
      {chains.map((chainData, i) => {
        const isSelected = selectedChain[tabName] == chainData.id;
        return (
          <div
            key={i}
            className="startRow"
            onClick={() => setSelectedChainByTab(tabName, chainData.id)}
            style={{
              fontWeight: 700,
              cursor: "pointer",
              background: isSelected ? "#F7F1FE" : "white",
              border: isSelected ? "1px solid #7B61FF" : "1px solid white",
              boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
              borderRadius: 2.5,
              padding: "3px 12px",
              height: 28,
              fontSize: 13,
              margin: "5px 5px 5px 0px",
            }}
          >
            <div
              style={{
                color: "#555555",
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              {chainData.name}
            </div>
            {chainData.logo_url && (
              <img
                src={chainData.logo_url}
                style={{ marginLeft: 5, width: 20, height: 20 }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SelectChain;
