import React from "react";
import _ from "lodash";
import IconIcMetaCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaCRM";

import { StyledTrialHeaderContainer } from "./TrialHeader.styles";

const TrialHeader = ({ isCustomDomain, entityName }) => {
  return (
    <>
      <StyledTrialHeaderContainer>
        <IconIcMetaCRM width={135}></IconIcMetaCRM>
      </StyledTrialHeaderContainer>
    </>
  );
};

export default TrialHeader;
