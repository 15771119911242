import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";

export const StyledDatePickerContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .react-datepicker-wrapper": {
    display: "block",
    "& .react-datepicker__input-container": {
      "& .react-datepicker__calendar-icon": {
        top: "50%",
        transform: "translateY(-50%)",
        left: "14px",
        padding: 0,
      },
    },
  },
}));

export const StyledDatePicker = styled(DatePicker, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: "3px",
  fontSize: "14px",

  padding: "11px 10px 11px 40px !important",
  "&:focus": {
    outline: `1px solid ${theme.customColors.purple[500]}`,
  },
  "&::placeholder": {
    color: theme.customColors.grey[500],
    fontWeight: 500,
    fontSize: "14px",
  },
}));
