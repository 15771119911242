import { Box } from "@mui/material";
import React from "react";
import {
  StyledFormTitle,
  StyledGreyBlock,
  StyledHoverShowIcon,
  StyledPreviewImageContainer,
} from "../../IntegrationWidgetSetting.styles";
import { isVersionLessThan } from "features/helpers/utils";
import { useIntl } from "react-intl";
import WhySriBlock from "features/metadesk/settings/WhySriBlock";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { selectWidgetVersionCanUpdate } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import useNavigateEntity from "hooks/useNavigateEntity";
import { generateWidgetScript } from "features/configure/appCode";
import { isEmpty } from "lodash";
import { useZoomIn } from "features/home/ZoomInDialogProvider";
import SriCodeTab from "features/metadesk/appUpdate/SriCodeTab";
import StepContent from "../StepContent";

export default function CodeSnipper({ data, integrityInfo }) {
  const { apiKey, created: apiKeyCreated, jsVersion } = data;
  const zoomIn = useZoomIn();
  const { formatMessage } = useIntl();
  const { latestVersion } = useSelector(selectWidgetVersionCanUpdate);

  const canUpdate = isVersionLessThan(
    jsVersion || latestVersion,
    latestVersion
  );

  const codeBlock = {
    title: formatMessage({
      id: "widgetSettings.headCode.title",
    }),
    describe: formatMessage(
      {
        id: "widgetSettings.headCode.description",
      },
      {
        br: <br />,
      }
    ),

    content: (
      <>
        <StyledFormTitle item xs={12} md={4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            height={"24px"}
            color={canUpdate ? "#FF9D2A" : null}
          >
            {formatMessage({
              id: "snippet.widget.integration",
            })}
            {canUpdate && (
              <Box
                bgcolor="#FF9D2A"
                p="2px 6px"
                fontSize="12px"
                borderRadius="10px"
                color="white"
                ml="8px"
                style={{ cursor: "pointer" }}
              >
                {formatMessage({ id: "integration.newVersion.title" })} :
                {" " + latestVersion}
              </Box>
            )}
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <WhySriBlock />
          </Box>
        </StyledFormTitle>
        <Grid position={"relative"} item xs={12} md={8} mb={2}>
          {!isEmpty(integrityInfo) && apiKey && (
            <SriCodeTab
              sriCode={generateWidgetScript({ integrityInfo, apiKey })}
              nonSriCode={generateWidgetScript({ apiKey })}
            />
          )}
          {!apiKey && (
            <Box position={"relative"}>
              <Box
                height="100%"
                width="100%"
                backgroundColor={(theme) => theme.customColors.grey[300]}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                color={(theme) => theme.customColors.purple[500]}
                fontSize={"24px"}
                fontWeight={"700"}
                position={"absolute"}
                zIndex={2}
                sx={{ opacity: 0.8 }}
              >
                {formatMessage({
                  id: "widgetSettings.apiKey.generate.hint",
                })}
              </Box>
              <InputField
                multiline={true}
                InputProps={{
                  rows: 9,
                  multiline: true,
                  inputComponent: "textarea",
                }}
                disabled
              />
            </Box>
          )}
          <StyledGreyBlock mt={"12px"}>
            <Box
              component="i"
              className="meta-crm-icon-ic_info font-size-16"
              mr="10px"
            />

            <Box>
              {formatMessage({
                id: "widgetSettings.headCode.instruction",
              })}
            </Box>
          </StyledGreyBlock>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className="description">
            {formatMessage({
              id: "widgetSettings.headCode.preview",
            })}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box style={{ textAlign: "center" }}>
            <StyledPreviewImageContainer
              onClick={() =>
                zoomIn({
                  src: require("assets/img/widgetScriptDemo.png"),
                }).catch(() => {})
              }
            >
              <Box
                component="img"
                src={require("assets/img/widgetScriptDemo.png")}
                sx={{ width: "100%", cursor: "pointer" }}
              />
              <StyledHoverShowIcon>
                <i className="meta-crm-icon-ic_bigger font-size-24" />
              </StyledHoverShowIcon>
            </StyledPreviewImageContainer>
          </Box>
        </Grid>
      </>
    ),
  };

  return <StepContent settingStep={codeBlock} />;
}
