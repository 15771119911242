import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import { Grid, Modal } from "@mui/material";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";

export const StyledSettingWidgetStepContainerOutSide = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  // "&:first-of-type": {
  //   marginTop: "24px",
  // },
  padding: "20px",
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.customColors.grey[300]}`,
    marginBottom: "12px",
  },
}));

export const StyledSettingWidgetStepContainer = styled(Grid, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isIsolateComponent, noBottomPadding }) => ({
  ...(!isIsolateComponent &&
    {
      // maxWidth: "850px",
    }),
  paddingBottom: noBottomPadding ? 0 : "32px",
}));

export const StyledSettingWidgetStepNumber = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  background: theme.customColors.purple[300],
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "5px",
}));

export const StyledSettingWidgetStepTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color }) => ({
  fontSize: "16px",
  color: color || theme.customColors.grey[700],
  marginRight: "8px",
}));

export const StyledSettingWidgetStepDescribe = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  marginBottom: "8px",
  lineHeight: "24px",
}));

export const StyledFormTitle = styled(Grid, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, center }) => ({
  color: theme.customColors.grey[700],
  fontSize: "16px",
  ...(center && { display: "flex", alignItems: "center" }),
}));

export const StyledApiKeyCreatedTime = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  position: "absolute",
  marginTop: "8px",
}));

export const StyledGoToCustomize = styled(Button, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  textDecoration: "underline",
  padding: 0,
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const StyledPreviewImageContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  width: "100%",
  lineHeight: 0,
  "&:hover": {
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      backgroundColor: "#0B0B0B",
      opacity: 0.6,
    },
    [StyledHoverShowIcon]: {
      display: "flex",
    },
  },
}));

export const StyledHoverShowIcon = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "36px",
  height: "36px",
  borderRadius: "50%",
  backgroundColor: theme.customColors.white,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  alignItems: "center",
  justifyContent: "center",
  color: theme.customColors.grey[600],
  display: "none",
}));

export const StyledFormTitleContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, center, isRequired }) => ({
  position: "relative",
  ...(isRequired && {
    "&::after": {
      content: '"*"',
      position: "absolute",
      color: theme.customColors.pink[500],
      top: 0,
      right: "-10px",
    },
  }),
}));

export const StyledGreyBlock = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, center, isRequired }) => ({
  border: `1px solid ${theme.customColors.grey[300]}`,
  background: theme.customColors.grey[200],
  fontSize: "12",
  fontWeight: 500,
  padding: "9px 12px",
  display: "flex",
  color: theme.customColors.grey[600],
  borderRadius: "4px",
  alignItems: "flex-start",
}));
