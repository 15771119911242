import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  StyledPopover,
  StyledInputArea,
  StyledDeleteArea,
  StyledLine,
  StyledInputField,
  StyledPickColorArea,
  StyledPickColorAreaTitle,
  StyledPalette,
  StyledSingleColor,
} from "./EditPopover.styles";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import Popover from "@mui/material/Popover";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { TAG_COLORS } from "features/configure";

export default function EditPopover({
  popoverId,
  open,
  anchorEl,
  onClose,
  onEditOptionItem,
  onDeleteItem,
  selectedTag,
  onPickOptionColor,
}) {
  const [itemValue, setItemValue] = useState("");

  useEffect(() => {
    if (open) {
      setItemValue(selectedTag.option.label);
    } else {
      setItemValue("");
    }
  }, [open]);

  const handleChange = (e) => {
    setItemValue(e.target.value);
  };

  const clearItemValue = () => {
    setItemValue("");
    onClose();
  };

  const handleKeyDown = (value) => {
    onEditOptionItem({
      value: value,
      cb: clearItemValue,
      target: selectedTag,
    });
  };

  const handleDelete = () => {
    onDeleteItem({ target: selectedTag, cb: onClose });
  };

  const handleChooseColor = (color) => {
    onPickOptionColor({ value: color, cb: onClose, target: selectedTag });
  };
  return (
    <StyledPopover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <StyledInputArea>
        <StyledInputField
          onClick={(e) => e.stopPropagation()}
          value={itemValue}
          onChange={handleChange}
          onEnter={handleKeyDown}
        ></StyledInputField>
      </StyledInputArea>
      <StyledDeleteArea onClick={handleDelete}>
        <i className="meta-crm-icon-ic_trash font-size-16" />
        <span className="delete-text">Delete</span>
      </StyledDeleteArea>

      {onPickOptionColor && (
        <>
          <StyledLine></StyledLine>
          <StyledPickColorArea>
            <StyledPickColorAreaTitle>Colors</StyledPickColorAreaTitle>
            <StyledPalette>
              <Grid container spacing={0.5}>
                {TAG_COLORS.map((color, i) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="center"
                      key={i}
                    >
                      <StyledSingleColor
                        onClick={() => handleChooseColor(color)}
                        color={color}
                      ></StyledSingleColor>
                    </Grid>
                  );
                })}
              </Grid>
            </StyledPalette>
          </StyledPickColorArea>
        </>
      )}
    </StyledPopover>
  );
}

EditPopover.propTypes = {};
EditPopover.defaultProps = {};
