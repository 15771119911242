import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

export const StyledQuestionBoxContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    border: `1px solid ${theme.customColors.grey[300]}`,
    borderRadius: "8px",
    // width: "780px",
    position: "relative",
    marginBottom: "16px",
  };
});

export const StyledQuiz = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  background: theme.customColors.purple[300],
  position: "absolute",
  padding: "2px 7px",
  width: "45px",
  height: "20px",
  left: "24px",
  top: "-10px",
  borderRadius: "10px",
}));

export const StyledQuestionLine = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, expanded }) => ({
  display: "flex",
  alignItems: "center",
  padding: "24px",
  width: "100%",
  ...(!expanded && {
    height: "67px",
  }),
}));

export const StyledQuestionText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "&:before": {
    content: '"Ｑ： "',
  },
  fontSize: "16px",
  fontWeight: 700,
  color: theme.customColors.grey[700],
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const StyledQuestionButtons = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginLeft: "auto",
  color: theme.customColors.purple[500],
  display: "flex",
}));

export const StyledExpandButton = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, expanded }) => ({
  marginRight: "10px",
  transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const StyledShowMoreButton = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
}));

export const StyledMenu = styled(Paper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "&.MuiPaper-root": {
    borderRadius: 6,
    background: theme.customColors.white,
    marginTop: theme.spacing(1),
    minWidth: 90,
    color: theme.customColors.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiList-root": {
      padding: "4px",
    },
    "& .MuiMenuItem-root": {
      color: theme.customColors.grey[700],
      size: "13px",
      padding: "8px 14px",
      "&.Mui-focusVisible": {
        backgroundColor: "unset",
      },
      "& i": {
        marginRight: "4px",
      },

      "&:hover": {
        background: theme.customColors.purple[300],
      },
    },
  },
}));

export const StyledQuestionExpandContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {});

export const StyledRelatedQuestionsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  backgroundColor: theme.customColors.grey[200],
  padding: "15px 20px",
  color: theme.customColors.grey[600],
  display: "flex",
  alignItems: "flex-start",
}));

export const StyledRelatedQuestionsQ = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  backgroundColor: theme.customColors.purple[500],
  color: theme.customColors.white,
  height: "20px",
  width: "25px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "10px",
}));

export const StyledRelatedQuestionsList = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: "2px",
}));

export const StyledRelatedQuestions = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginBottom: "12px",
}));

export const StyledDividingLine = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "1px",
  background: theme.customColors.grey[300],
}));

export const StyledQuestionExpandContent = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "24px",
}));

export const StyledQuestionExpandTextContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  fontSize: "16px",
  marginBottom: "30px",
  color: theme.customColors.grey[700],
  "& a": {
    color: theme.customColors.purple[500],
  },
}));

export const StyledQuestionExpandText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 2,
  wordBreak: "break-word",
}));

export const StyledAppliedChannels = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "end",
}));

export const StyledIcon = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginLeft: "8px",
}));

export const StyledQuestionExpandImgContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "20px",
}));

export const StyledImage = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "80px",
  marginRight: "10px",
}));

export const StyledTagList = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "16px",
}));

export const StyledQuestionExpandEditor = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  "& .editor-title": {
    fontSize: "14px",
  },
  "& .avatar-img": {
    width: "16px",
    height: "16px",
    marginLeft: "5px",
    marginRight: "5px",
  },
}));
