import React, { useState, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import _ from "lodash";
import { defaultThemeConfig } from "features/configure";
import ColorModeContext from "@metacrm/metacrm-material-ui/dist/contexts/ColorMode.context";
import useColorMode from "@metacrm/metacrm-material-ui/dist/hooks/useColorMode.hooks";

export const ThemeContext = React.createContext((themeName) => {});

const CustomThemeProvider = (props) => {
  const { colorModeHooksValue, theme: mainTheme } = useColorMode();
  const { mode } = colorModeHooksValue;

  const [entityName, setEntityName] = useState("default");

  const [theme, setTheme] = useState(
    Object.assign({}, defaultThemeConfig.theme(mode), mainTheme)
  );

  useEffect(() => {
    const entityTheme = defaultThemeConfig.theme;
    let themeMode = mode;
    const resultTheme = Object.assign({}, entityTheme(themeMode), mainTheme);
    setTheme(resultTheme);
  }, [mainTheme, entityName, mode]);

  const setThemeName = (themeName) => {
    setEntityName(themeName);
  };

  return (
    <ColorModeContext.Provider value={colorModeHooksValue}>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default CustomThemeProvider;
