import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { Grid, Modal } from "@mui/material";
import { useTheme } from "@mui/styles";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/Button";

import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import { uploadFiles } from "features/helpers/utils";
import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { StyledWidgetTitle } from "features/metadesk/settings/integrations/widget/components/WidgetDemoTemplate.styles";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import useGetPagePermission from "hooks/useGetPagePermission";
import ImageUpload from "features/metadesk/components/ImageUpload";
import {
  FileUploadContainer,
  FileValidatorInfo,
  TinyText,
  UploadComponent,
} from "features/metadesk/settings/integrations/widget/components/WidgetLauncher.styles";
import { StyledCircleNumber } from "./Widget.styles";

const widgetIcons = {
  0: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget01-1700205700.svg",
  1: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget02-1700205700.svg",
  2: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget03-1700205700.svg",
  3: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget04-1700205700.svg",
  4: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget05-1700205700.svg",
  5: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget06-1700205700.svg",
  6: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget07-1700205700.svg",
  7: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget08-1700205700.svg",
  8: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget09-1700205700.svg",
  9: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget10-1700205700.svg",
  custom_ic_option: require(`assets/img/ic_editWidgetLauncherIcon.png`),
};

const getWidgetIcUrl = (iconUrl) => {
  const url = Object.values(widgetIcons).find((url) => url === iconUrl);
  return url ? url : widgetIcons[`custom_ic_option`];
};

const isCustomOption = (value) => {
  return value === widgetIcons["custom_ic_option"];
};

const positionValues = [
  { label: "The bottom right", value: "bottom_right" },
  { label: "The bottom left", value: "bottom_left" },
  { label: "The top right", value: "top_right" },
  { label: "The top left", value: "top_left" },
];

const displacementValues = [
  { label: "None", value: "none" },
  { label: "Vertically shift", value: "verticallyShift" },
  {
    label: "Horizontally shift",
    value: "horizontallyShift",
  },
];

const defaultValues = {
  icon: widgetIcons[0],
  displacement: displacementValues[0],
  position: positionValues[0],
  iconSize: "normal",
};

const widgetPositionOptions = [
  {
    label: "The bottom right",
    value: ["bottom", "right"],
  },
  { label: "The bottom left", value: ["bottom", "left"] },
  { label: "The top right", value: ["top", "right"] },
  { label: "The top left", value: ["top", "left"] },
];

const WidgetLauncher = ({ configs, setConfigs }) => {
  const { formatMessage } = useIntl();

  const [entityName] = useOutletContext();
  const [uploadedImg, setUploadedImg] = useState([]);
  const theme = useTheme();

  const isCustomizationAllowed = _.get(configs, "isCustomizationAllowed");

  useEffect(() => {
    /**
     * need to remove the custom icon from the option only when the
     * customization is not allowed i.e isCustomizationAllowed is false
     */
    if (isCustomizationAllowed === false) {
      delete widgetIcons.custom_ic_option;
    }
  }, [isCustomizationAllowed]);

  const customIconStyle = () => {
    let style = {};
    switch (configs.position[0] + "_" + configs.position[1]) {
      case "bottom_right":
        style = { bottom: "5%", right: "3%" };
        break;
      case "bottom_left":
        style = { bottom: "5%", left: "3%" };
        break;
      case "top_right":
        style = { top: "14%", right: "3%" };
        break;
      case "top_left":
        style = { top: "14%", left: "3%" };
        break;
      default:
        style = {};
    }
    if (configs.displacement.value === "verticallyShift") {
      style.top ? (style.top = "27%") : (style.bottom = "18%");
    } else if (configs.displacement.value === "horizontallyShift") {
      style.right ? (style.right = "12%") : (style.left = "12%");
    }

    return style;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newConfigs = { ...configs, [name]: value };

    if (name === "icon" && isCustomOption(value)) {
      newConfigs[name] =
        getCustomPreviewIcon() ?? widgetIcons[`custom_ic_option`];
    }
    setConfigs(newConfigs);
  };

  const handleUploadImageChange = async (list) => {
    const customLauncherIcon = [list[list.length - 1]];
    const iconUrl = await uploadFiles(customLauncherIcon, entityName);
    setConfigs({ ...configs, icon: iconUrl[0] });
  };

  const getCustomPreviewIcon = () => {
    let customIcon = _.get(uploadedImg, "[0]");

    if (customIcon) return customIcon;

    const icon = _.get(configs, "icon", defaultValues.icon);
    const ic_url = getWidgetIcUrl(icon);
    customIcon = ic_url === widgetIcons[`custom_ic_option`] ? icon : null;

    return customIcon;
  };

  const getLogo = (width = 80, height = 80, logoStyle = {}) => {
    const customIcon = getCustomPreviewIcon();

    return customIcon ? (
      <>
        <img
          src={customIcon}
          style={{
            width: width,
            height: height,
            objectFit: "contain",
            ...logoStyle,
          }}
        />
        <i
          className="meta-crm-icon-ic_uploadImage"
          style={{
            color: theme.customColors.grey[500],
            fontSize: 20,
            position: "absolute",
            bottom: 8,
            right: 8,
          }}
        />
      </>
    ) : (
      <>
        <i
          className="meta-crm-icon-ic_uploadImage"
          style={{
            color: theme.customColors.grey[300],
            fontSize: 90,
          }}
        />
        <TinyText
          style={{
            fontWeight: theme.fontWeight.BOLD,
            paddingTop: 4,
          }}
        >
          Click to upload or Drag & Drop
        </TinyText>
      </>
    );
  };

  const renderImageUploader = () => {
    const iconUrl = getWidgetIcUrl(configs.icon);

    if (!isCustomizationAllowed || iconUrl !== widgetIcons[`custom_ic_option`])
      return false;

    return (
      <FileUploadContainer>
        <StyledWidgetTitle>
          {formatMessage({ id: "launcherCustomization.upload.subtitle" })}
        </StyledWidgetTitle>
        <ImageUpload
          maxFileSize={10 * 1024 * 1024}
          max={1}
          acceptType={["png", "jpg", "jpeg", "svg", "heic", "gif", "webp"]}
          replace={true}
          uploadComponent={
            <UploadComponent className="column">{getLogo()}</UploadComponent>
          }
          value={uploadedImg}
          onChange={handleUploadImageChange}
        />
        <FileValidatorInfo style={{ color: "#5C5C5C" }}>
          <TinyText type="info">
            {formatMessage({ id: "uploader.restriction.imageType" })}{" "}
            <span style={{ color: theme.customColors.purple[500] }}>
              {`SVG, PNG, JPG, JPEG, HEIC, WEBP, GIF`}
            </span>
          </TinyText>
          <TinyText type="info">Maximum image size：10mb</TinyText>
          <TinyText type="info">
            {formatMessage({ id: "uploader.restriction.resolution" })}
            <span>
              {formatMessage({ id: "restriction.resolution.120x120" })}
            </span>
          </TinyText>
        </FileValidatorInfo>
      </FileUploadContainer>
    );
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  return (
    <div>
      <Box color="#555" fontSize={16} fontWeight={700} mb="8px">
        Launcher
      </Box>
      <Box color="#777" fontSize={14} fontWeight={500} mb="40px">
        Customize your launcher's positioning on the screen.
      </Box>
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={12} lg={5}>
          <Box>
            <div>
              <Box display="flex">
                <StyledCircleNumber>1</StyledCircleNumber>
                <Box>
                  <StyledWidgetTitle>
                    {formatMessage({
                      id: "widgetCustomization.launcherIcon.subtitle",
                    })}{" "}
                  </StyledWidgetTitle>
                  <CustomRadioGroup
                    readonly={readonly}
                    row
                    data={_.map(Object.keys(widgetIcons), (key, index) => {
                      const url = widgetIcons[key];
                      return {
                        label: (
                          <img
                            src={url}
                            style={{
                              margin: "10px 0",
                              marginLeft: 8,
                              width: 46,
                              height: 46,
                              ...(url === widgetIcons[`custom_ic_option`] && {
                                position: "relative",
                                scale: "1.4",
                                top: 6,
                              }),
                            }}
                          />
                        ),
                        value: url,
                      };
                    })}
                    value={getWidgetIcUrl(configs.icon)}
                    name="icon"
                    onChange={handleChange}
                  />
                  {renderImageUploader()}
                </Box>
              </Box>
            </div>
            {/* Position */}
            <Box display="flex" mt="40px">
              <StyledCircleNumber>2</StyledCircleNumber>
              <Box sx={{ flex: 1 }}>
                <StyledWidgetTitle>
                  {formatMessage({
                    id: "widgetCustomization.launcherPosition.subtitle",
                  })}
                </StyledWidgetTitle>
                <Grid container spacing={1} alignItems="center" mt="24px">
                  <Grid item xs={12} md={4}>
                    <Box color="#555" fontSize={14} flexShrink={0}>
                      {formatMessage({
                        id: "widgetCustomization.launcherPosition.cornerOption",
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoCompleteDropdown
                      readonly={readonly}
                      fullWidth
                      size={SIZE.MEDIUM}
                      options={widgetPositionOptions}
                      value={widgetPositionOptions.find((option) =>
                        _.isEqual(option.value, configs.position)
                      )}
                      name={"position"}
                      onChange={(e, option) =>
                        setConfigs({
                          ...configs,
                          position: option.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" mt="24px">
                  <Grid item xs={12} md={4}>
                    <Box color="#555" fontSize={14} flexShrink={0}>
                      {formatMessage({
                        id: "widgetCustomization.launcherPosition.displacement",
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoCompleteDropdown
                      readonly={readonly}
                      fullWidth
                      size={SIZE.MEDIUM}
                      options={[
                        { label: "None", value: "none" },
                        {
                          label: "Vertically shift",
                          value: "verticallyShift",
                        },
                        {
                          label: "Horizontally shift",
                          value: "horizontallyShift",
                        },
                      ]}
                      value={configs.displacement}
                      name={"displacement"}
                      onChange={(e, option) =>
                        setConfigs({ ...configs, displacement: option.value })
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* Icon Size */}
            <Box display="flex" mt="40px">
              <StyledCircleNumber>3</StyledCircleNumber>
              <Box>
                <StyledWidgetTitle>
                  {formatMessage({
                    id: "widgetCustomization.launcherSize.subtitle",
                  })}
                </StyledWidgetTitle>{" "}
                <Box mt="24px">
                  <CustomRadioGroup
                    readonly={readonly}
                    row
                    value={_.get(configs, "iconSize")}
                    name={"iconSize"}
                    onChange={handleChange}
                    data={[
                      {
                        label: (
                          <div className="startRow">
                            {formatMessage({
                              id: "widgetCustomization.launcherSize.normal",
                            })}{" "}
                            <img
                              src={_.get(configs, "icon", widgetIcons[0])}
                              style={{
                                width: 46,
                                marginLeft: 12,
                              }}
                            />
                          </div>
                        ),
                        value: "normal",
                      },
                      {
                        label: (
                          <div className="startRow">
                            {formatMessage({
                              id: "widgetCustomization.launcherSize.bigger",
                            })}
                            <img
                              src={_.get(configs, "icon", widgetIcons[0])}
                              style={{
                                width: 60,
                                marginLeft: 12,
                              }}
                            />
                          </div>
                        ),
                        value: "bigger",
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box
            sx={{
              marginLeft: { xs: 0, lg: "32px" },
              position: "relative",
            }}
          >
            <Box fontSize="14px" color="#A5A5A5" mb="4px">
              Preview
            </Box>
            <img
              src={require("assets/img/widgetPosition.svg").default}
              style={{ width: "100%" }}
            />
            <img
              src={_.get(configs, "icon", widgetIcons[0])}
              style={{
                position: "absolute",
                width: _.get(configs, "iconSize") == "bigger" ? 60 : 46,
                height: _.get(configs, "iconSize") == "bigger" ? 60 : 46,
                ...customIconStyle(),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default WidgetLauncher;
