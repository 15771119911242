import { get, isEmpty, isArray } from "lodash-es";
import moment from "moment";

export const imgFileTypes = ["png", "jpg", "jpeg"];

export const parseFilenames = (urls) => {
  if (!isArray(urls)) {
    return null;
  }
  return urls.map((url) => {
    const parts = url.split("/");

    const filenameWithTimestamp = parts[parts.length - 1];

    const partsOfFilename = filenameWithTimestamp.split(".");
    const extension = partsOfFilename.pop();

    const nameParts = partsOfFilename[0].split("-");
    const name = nameParts.slice(0, nameParts.length - 1).join("-");

    return { name, extension, img: url };
  });
};
const convertToFile = async ({ dataURI, fileName, allowFile }) => {
  const allowPdf = allowFile.includes("pdf");
  if (!dataURI || typeof dataURI !== "string") return null;
  if (allowPdf) {
    if (
      !dataURI.startsWith("data:image/") &&
      !dataURI.startsWith("data:application/pdf")
    ) {
      return null;
    }
  } else if (!dataURI.startsWith("data:image/")) {
    return null;
  }

  const res = await fetch(dataURI, { mode: "no-cors" });
  const buf = await res.arrayBuffer();
  const file = new File([buf], fileName, {
    type: "image/png",
  });
  return file;
};

export const uploadImgFunc = async ({
  images,
  address,
  uploadApi,
  allowFile,
}) => {
  try {
    if (!images || images.length === 0) {
      return [];
    }
    const postFormData = new FormData();
    const uploadFiles = [];

    const promises = images.map((image, index) =>
      convertToFile({
        dataURI: get(image, "data_url"),
        fileName: get(image, "file.name"),
        allowFile,
      })
    );

    // Wait for all promises to complete
    const files = await Promise.all(promises);

    files.forEach((file, index) => {
      if (file) {
        postFormData.append("img", file);
        uploadFiles.push(index);
      }
    });

    postFormData.append("address", address);

    const uploadResult = await uploadApi({ data: postFormData });
    return get(uploadResult, "img", []);
  } catch (error) {
    throw new Error(error);
    // You can also throw the error if you want the calling function to be aware of it.
    // throw error;

    // Or you can return a default/failure value.
  }
};

export const linkifyOptions = {
  target: (href) => {
    const { hostname } = window.location;
    if (hostname && href.includes(hostname)) {
      return "";
    }
    return "_blank";
  },
  attributes: {
    rel: "noreferrer noopener",
  },
};

export const formatSendingTime = (time) => {
  const date = moment(time);
  const today = moment().startOf("day");

  if (date.isSame(today, "day")) {
    // 是今天，只顯示時間
    return date.format("HH:mm");
  }
  // 不是今天，顯示日期和時間
  return date.format("DD MMM HH:mm");
};
