import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFetchTicketList } from "../redux/hooks";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import UserInfoRoot from "./UserInfoRoot";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  Button,
  IconButton,
  COLOR,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { enqueueSnackbar } from "features/common/redux/actions";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useDispatch, useSelector } from "react-redux";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { Loading } from "features/common";
import { useFetchUserInfo } from "../redux/fetchUserInfo";
import GroupLabel from "../group/GroupLabel";
import UserTag from "../components/UserTag";

import {
  alertServerError,
  formatDiscordUsername,
} from "features/helpers/utils";
import Box from "@mui/material/Box";
import useGetPagePermission from "hooks/useGetPagePermission";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
import {
  StyledChatAreaContainer,
  StyledMessageInfoContainer,
} from "./TicketDetail.styles";
import TicketListItem from "./components/TicketListItem";
import { Grid, useMediaQuery } from "@mui/material";
const sideBarWidth = "300px";
const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    color: "#383538",
  },
  input: {
    background: "#FFFFFF",
    padding: "10px 10px",
    height: 40,
    margin: "5px 0",
    position: "relative",
    border: "2px solid #7B61FF",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    fontWeight: 700,
    fontSize: 14,
    alignItems: "center",
  },
  inboxDescriptionLabel: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
    color: "#777",
    marginTop: "20px",
    marginBottom: "8px",
  },
  accountInfo: {
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    padding: "7px 14px",
    overflow: "hidden",
    fontWeight: 500,
    fontSize: 14,
    position: "relative",
  },
}));
const UserOverview = ({ userId, onClose }) => {
  const classes = useStyles();
  const [entityName] = useOutletContext();
  const { auth } = useConnectWallet();
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fetchListLoading, setFetchListLoading] = useState(false);
  const [discordIdInput, setDiscordIdInput] = useState("");
  const [discordUserResult, setDiscordUserResult] = useState("");
  const dispatch = useDispatch();
  const { fetchUserTicketList, userTicketList } = useFetchTicketList();
  const { fetchUserInfo, userInfos } = useFetchUserInfo();
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const ecosystemName = useSelector(selectEcosystemName);
  const pagePermission = useGetPagePermission();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const { show } = pagePermission("tickets");
  useEffect(async () => {
    if (auth && userId && entityName) {
      await fetchUserInfo({ entityName, userId });
      setLoading(false);
    }
  }, [entityName, userId, auth]);

  useEffect(async () => {
    if (selectedTab == 1) {
      try {
        setFetchListLoading(true);
        if (userId && show) await fetchUserTicketList({ entityName, userId });
      } catch (err) {
        dispatch(alertServerError(err));
      } finally {
        setFetchListLoading(false);
      }
    }
  }, [selectedTab]);

  const ticketListData = _.get(userTicketList, `[${userId}]`, {});
  const userInfoData = _.get(userInfos, `[${userId}]`, {});
  const userAddress = _.get(userInfoData, "addresses.0.address");
  const userTags = _.get(userInfoData, "tags", []);
  const groups = _.get(ticketListData, `groups`, []);

  const renderCopy = (content, isAbsolute = false) => {
    return (
      <IconButton
        style={{
          position: isAbsolute ? "absolute" : "relative",
          right: "0px",
          padding: "0 5px",
          background: "white",
        }}
        onClick={() => {
          navigator.clipboard.writeText(content);
        }}
      >
        <img src={require("assets/img/copy.svg").default} />
      </IconButton>
    );
  };

  const saveDiscordId = async () => {
    try {
      await axios.post(apiUrl + "/api/user/updateDiscord", {
        userId,
        entityName,
        handle: discordUserResult.handle,
        uid: discordUserResult.uid,
      });
      fetchUserTicketList({ entityName, userId });
      fetchUserInfo({ entityName, userId });
      setDiscordIdInput("");
      setDiscordUserResult("");
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };

  const onSearchDiscordUser = async () => {
    if (!discordIdInput) return;
    try {
      setDiscordUserResult({
        loading: true,
      });
      const response = await axios.post(
        `https://lookupguru.herokuapp.com/lookup`,
        {
          input: discordIdInput,
        }
      );

      const result = response.data;
      if (result.success)
        setDiscordUserResult({
          uid: discordIdInput,
          handle: result.data.username + "#" + result.data.discriminator,
        });
      else {
        throw result.error;
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "User not found",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
      setDiscordUserResult("");
    }
  };

  const renderDiscordInput = () => {
    const data = _.get(userInfoData, "mostRecentDiscordID.handle", "");
    const discordUsername = formatDiscordUsername(data);
    return (
      <div style={{ position: "relative" }}>
        <div className={classes.inboxDescriptionLabel}>Discord ID</div>
        {discordUserResult && (
          <div
            style={{
              position: "absolute",
              top: 70,
              zIndex: 1500,
              left: 0,
              background: "#FFFFFF",
              width: 260,
              borderRadius: 4,
              padding: "3px 0",
              border: "1px solid #D6D6D6",
            }}
          >
            <div
              style={{
                background: "#F7F1FE",
                height: 40,
                padding: "4px 7px",
                position: "relative",
              }}
            >
              {discordUserResult.loading ? (
                <div
                  style={{
                    color: "#7B61FF",
                    fontSize: 13,
                    fontWeight: 500,
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  Searching...{" "}
                  <LoadingIcon className="xsIcon" style={{ marginLeft: 10 }} />
                </div>
              ) : (
                <div className="betweenRow">
                  <span style={{ fontWeight: 500, fontSize: 14 }}>
                    {discordUserResult.handle}
                  </span>
                  <Button color="primary" size="sm" onClick={saveDiscordId}>
                    Select
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {!discordUsername ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <InputField
              onChange={(e) => setDiscordIdInput(e.target.value)}
              value={discordIdInput}
              placeholder={"Give an ID here..."}
              endAdornment={
                discordIdInput && (
                  <Button
                    color={COLOR.SECONDARY}
                    size={SIZE.XS}
                    onClick={onSearchDiscordUser}
                  >
                    Search
                  </Button>
                )
              }
            />
          </div>
        ) : (
          <div className={classes.accountInfo}>
            <div className="betweenRow">
              <span
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  maxWidth: 160,
                }}
              >
                {discordUsername || "-"}
              </span>
              <div>{renderCopy(discordUsername)}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <Box>
        {renderDiscordInput()}
        <div className={classes.inboxDescriptionLabel}>Tag</div>
        <div className="inputField">
          <UserTag
            width={"100%"}
            showNumber={10}
            entityName={entityName}
            userInfo={{
              _id: userId,
              address: userAddress,
              tags: userTags,
            }}
          />
        </div>

        {ecosystemName === "EVM" && (
          <>
            <div
              className={classes.inboxDescriptionLabel}
              style={{ marginTop: 12 }}
            >
              Segment
            </div>
            <div className="inputField">
              <GroupLabel entityName={entityName} groups={groups} all={true} />
            </div>
          </>
        )}
      </Box>
    );
  };

  const renderTicketList = () => {
    const ticketList = _.get(userTicketList, `${userId}.tickets`, []);
    if (ticketList.length == 0) return <Box />;
    return _.map(ticketList, (ticket, i) => {
      return (
        <TicketListItem
          key={i}
          goService={true}
          ticket={ticket}
          onClick={(ticketId) => {
            setSearchParams({ ticketId });
          }}
        />
      );
    });
  };

  return (
    <Box
      position={"relative"}
      height="100%"
      borderRadius={"8px"}
      overflow={computer ? "auto" : "auto"}
    >
      <Loading open={loading} fullScreen={false} white zIndex={1200} />
      <Box
        alignItems="stretch"
        flexDirection={computer ? "row" : "column"}
        display="flex"
        width="100%"
        height={computer ? "100%" : "unset"}
      >
        <StyledMessageInfoContainer
          style={{
            width: computer ? sideBarWidth : "100%",
            overflow: "auto",
          }}
        >
          <IconButton
            size="14px"
            color="#555"
            onClick={() => {
              setLoading(true);
              setSelectedTab(0);
              onClose();
            }}
            style={{
              marginBottom: "20px",
            }}
          >
            <i className="meta-crm-icon-ic_cancel font-size-14 mgr4" />
            <Box sx={{ fontSize: "12px", fontWeight: 500, color: "#555" }}>
              Close
            </Box>
          </IconButton>

          <Tabs
            variant="fullWidth"
            tabLabelStyle={{ fontSize: 12, fontWeight: 500 }}
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            tabs={[
              {
                label: <Box>User Info</Box>,
                content: renderUserInfo(),
              },
              {
                label: <Box style={{ width: "105px" }}>User Tickets</Box>,
                content: (
                  <Box position={"relative"} height="100%" minHeight={"400px"}>
                    <Loading open={fetchListLoading} fullScreen={false} />
                    <Box
                      style={{
                        maxHeight: "calc(100vh - 120px)",
                        overflow: "auto",
                      }}
                    >
                      {renderTicketList()}
                    </Box>
                  </Box>
                ),
              },
            ]}
          />
        </StyledMessageInfoContainer>
        <StyledChatAreaContainer
          style={{
            backgroundColor: "#FAFAFD",
            width: computer ? `calc(100% - ${sideBarWidth})` : "100%",
          }}
        >
          <UserInfoRoot
            selectedUserId={userId}
            selectedUserAddress={userAddress}
            entityName={entityName}
          />
        </StyledChatAreaContainer>
      </Box>
    </Box>
  );
};

export default UserOverview;
