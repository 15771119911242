import styled from "@emotion/styled/macro";
import { Typography } from "@mui/material";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";
export const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const StyledContent = styled("div")(({ theme }) => ({
  padding: 18,
}));

export const StyledList = styled("div")(({ theme }) => ({
  maxHeight: 800,
  flexGrow: 1,
  height: "100%",
  backgroundColor: "#F8F8F8",
  position: "relative",
  padding: "10px 18px",
  overflow: "auto",
}));

export const StyledInfo = styled("div")(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  color: "#A5A5A5",
}));

export const StyledTitle = styled("div")(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  color: "#555",
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  color: "#555",
}));

export const StyledIssue = styled("div")(({ theme }) => ({
  padding: 10,
  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  backgroundColor: "#ffffff",
  borderRadius: 3,
  marginBottom: 8,
}));

export const StyledBottomBar = styled("div")(({ theme }) => ({
  padding: "29px 17px",
  textAlign: "right",
  a: {
    color: theme.customColors.grey[500],
    textDecoration: "none",
    fontWeight: 500,
  },
}));

export const OpenLabel = styled("span")(({ theme }) => ({
  color: "white",
  background: "#14C864",
  fontWeight: 500,
  padding: "2px 4px",
  fontSize: 12,
  display: "inline-flex",
  alignItems: "center",
  marginTop: 8,
  borderRadius: 20,
}));

export const CloseLabel = styled("div")(({ theme }) => ({
  color: "white",
  background: "#7F43CC",
  fontWeight: 500,
  padding: "2px 4px",
  fontSize: 12,
  display: "inline-flex",
  alignItems: "center",
  marginTop: 8,
  borderRadius: 20,
}));
