import styled from "styled-components";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import { Pagination, Badge } from "@mui/material";

export const StyledInfoNumber = styled.div`
  display: inline-block;
  color: #a5a5a5;
  font-size: 12px;
  margin-left: 5px;
`;

export const StyledStartRowTitleInsideContainer = styled.div`
  padding-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  fill: ${(props) => (props.white ? "#fff" : "#141314")};

  & svg {
    margin: 0 2px;
  }
  &:hover {
    background: ${(props) => (props.white ? "#fff" : "#7b61ff")};
    fill: ${(props) => (props.white ? "#7b61ff" : "#fff")};
  }
`;

export const StyledListFunctionRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding-right: 40px;
  flex-wrap: wrap;
`;
export const StyledBadge = styled(Badge)`
  margin-left: 20px;
  margin-right: 10px;
  & .MuiBadge-standard {
    line-height: 12px;
  }
`;
export const StyledCustomTabs = styled.div`
  & .MuiTabs-root {
    min-height: 20px;
    margin-bottom: -40px;

    ${(props) => ({
      ...(props.borderBottom && {
        "border-bottom": "1px solid #a5a5a5",
      }),
    })};
  }
  & .Mui-selected {
    background: #e4f2ff;
  }

  & .MuiTab-labelIcon {
    min-height: unset;
  }

  & .MuiTab-root {
    text-transform: capitalize;
    min-width: ${(props) => (props.computer ? "90px" : 0)};
    margin-right: 5px;
  }

  & .MuiTab-wrapper {
    flex-direction: row;
    color: #555555;
    font-weight: 700;
    font-size: 13px;

    & > :first-child {
      margin-bottom: 3px;
      order: 1;
      margin-left: 5px;
    }

    & > img {
    }
  }
`;

export const StyleImportTabInput = styled.input`
  max-width: 90px;
`;

export const StyledPagination = styled(Pagination)`
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
`;
