import React, { useEffect, useRef, useState } from "react";
import { StyledLinkInput } from "../CampaignCreation.styles";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";

export default function EditInput({
  defaultValue,
  placeholder,
  updateOption,
  checked,
  handleClick,
  removeClick,
  ...arg
}) {
  const [edit, setEdit] = useState(false);
  const [objValue, setObjValue] = useState(defaultValue);
  const inputRef = useRef(null);
  useEffect(() => {
    setObjValue(defaultValue);
  }, [defaultValue]);

  function update() {
    setEdit(false);
    updateOption(objValue.value, defaultValue.value);
  }

  function onEnter(e) {
    if (e.key !== "Enter") return;
    update();
  }

  function handleEdit() {
    setEdit(true);
    removeClick();
  }

  function handleCheck() {
    if (checked) {
      removeClick();
    } else {
      handleClick(objValue);
    }
  }

  useEffect(() => {
    if (edit) {
      inputRef.current.focus();
    }
  }, [edit]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={checked}
        onChange={handleCheck}
        disabled={edit}
        controlStyle={{ width: "100%" }}
        label={
          !edit ? (
            <p style={{ display: "flex", padding: "1px 2px" }}>
              {objValue.value || placeholder}
              <i
                style={{ cursor: "pointer", marginLeft: "2px" }}
                className="meta-crm-icon-ic_edit-1 font-size-16"
                onClick={handleEdit}
              />
            </p>
          ) : (
            <StyledLinkInput
              {...arg}
              ref={inputRef}
              placeholder={placeholder}
              value={objValue.value}
              onChange={(e) =>
                setObjValue((prev) => ({ ...prev, value: e.target.value }))
              }
              onBlur={update}
              onKeyDown={onEnter}
            />
          )
        }
      />
    </div>
  );
}
