import Back from "features/metadesk/components/Back";
import React, { useState } from "react";
import { StyledWhiteContainer } from "../common.styles";
import { Box } from "@mui/material";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Radio } from "@metacrm/metacrm-material-ui/dist/Radio";
import FixedButton from "../common/FixedButton";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { get, lowerCase, set, upperFirst } from "lodash";
import { useIntl } from "react-intl";
import useGetRolesHeader from "./hooks/useGetRolesHeader";
import useCreateRole from "./hooks/useCreateRole";
import { Controller } from "react-hook-form";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";

export default function OrganizationCreation() {
  const { header, loading } = useGetRolesHeader();
  const {
    form,
    isLoading,
    handleChange,
    handleClickOption,
    saveRoleSetting,
    control,
    errorsInfo,
    isValid,
    isDirty,
  } = useCreateRole();
  const options = ["none", "read", "edit"];
  const { formatMessage } = useIntl();
  const renderRadios = ({ field: { onChange: onFormChange } }, child) => {
    return (
      <>
        {options.map((option) => (
          <Box key={option}>
            <Radio
              disabled={child?.disabled?.includes(option)}
              checked={form.permissions[child.value] === option}
              onChange={() => {
                handleClickOption(child.value, option);
                onFormChange(option);
              }}
              label={upperFirst(option)}
            />
          </Box>
        ))}
      </>
    );
  };

  return (
    <div>
      {loading && <LoadingComponent isAbsolute={true} />}
      <Back />
      <div className="lgTitle" style={{ marginTop: 18 }}>
        {formatMessage({ id: "roleSetting.customization.title" })}
      </div>
      <div className="description betweenRow" style={{ lineHeight: "20px" }}>
        {formatMessage({ id: "roleSetting.customization.description" })}
      </div>
      <StyledWhiteContainer mt={4} style={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            padding: "20px",
            borderBottom: "1px solid #E6E6E6",
          }}
        >
          <Box sx={{ width: "350px", marginRight: "40px", flexShrink: 0 }}>
            <Box sx={{ fontSize: "16px", color: "#555", marginBottom: "8px" }}>
              {formatMessage({ id: "roleCustomization.introduction.title" })}
            </Box>
            <Box sx={{ color: "#777", fontSize: "12px" }}>
              {formatMessage({
                id: "roleCustomization.introduction.description",
              })}
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <InputField
              onChange={handleChange}
              title="Role Name"
              isRequired
              placeholder={formatMessage({
                id: "roleCustomization.name.placeholder",
              })}
              style={{ width: "100%" }}
              name="name"
              value={form.name}
              control={control}
              errors={get(errorsInfo, "name.message")}
              rules={{
                required: {
                  value: true,
                  message:
                    "Role Name" +
                    formatMessage({ id: "global.error.required" }),
                },
              }}
            />
            <Box mt={"12px"} />
            <InputField
              title="Role Description"
              onChange={handleChange}
              multiline
              rows={4}
              placeholder={formatMessage({
                id: "roleCustomization.description.placeholder",
              })}
              style={{ width: "100%" }}
              name="description"
              value={form.description}
              control={control}
            />
          </Box>
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Box fontSize={"16px"} color="#555" sx={{ marginBottom: "8px" }}>
            {formatMessage({
              id: "roleCustomization.permissionConfiguration.title",
            })}
          </Box>
          <Box color="#777">
            {formatMessage({
              id: "roleCustomization.permissionConfiguration.description",
            })}
          </Box>
          {header?.map((role) => (
            <Box key={role.title} sx={{ marginTop: "20px", display: "flex" }}>
              <Box fontSize={"14px"} color="#333" sx={{ width: "40%" }}>
                {upperFirst(lowerCase(role.title))}
              </Box>
              <Box>
                {role?.children?.map((child) => (
                  <Box key={child.title} mb={"16px"}>
                    <Box
                      sx={{
                        fontSize: "14px",
                        color: "#555",
                        paddingLeft: "4px",
                      }}
                    >
                      {child.title}
                      <span className="required">*</span>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "2px",
                        width: "300px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Controller
                        name={child.value}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                          validate: (fruit) =>
                            fruit.value !== "" || "At least select one",
                        }}
                        render={(field) => renderRadios(field, child)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </StyledWhiteContainer>
      <FixedButton>
        <Button
          style={{ marginLeft: "8px", width: 140 }}
          onClick={saveRoleSetting}
          disabled={!isValid || !isDirty}
          isLoading={isLoading}
        >
          {formatMessage({ id: "global.btn.save" })}
        </Button>
      </FixedButton>
    </div>
  );
}
