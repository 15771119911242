import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledChannelCell = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingRight: "5px",
}));

export const StyledChannelIconContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginRight: "7px",
  display: "flex",
  alignItems: "center",
}));

export const StyledChannelCellName = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[700],
  whiteSpace: "nowrap",
}));
