import styled from "@emotion/styled/macro";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
const styles = (theme) => ({
  title: {
    fontSize: 12,
    fontWeight: 400,
    padding: "0px 3px",

    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },
  tableTitle: {
    backgroundColor: "#F8F8F8",
    borderBottom: `0px solid ${theme.palette.text.primary}`,
    fontWeight: 400,
    fontSize: 13,
    color: "#383538",
  },
  outlinedTitle: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    color: "#7B61FF",
    borderRight: `0.7px solid rgba(56, 56, 56, 0.2)`,
    borderBottom: `2px solid #7B61FF`,
  },

  columnTitle: {
    borderBottom: `0px solid ${theme.palette.text.primary}`,
    borderRight: `0px solid ${theme.palette.text.primary}`,
  },
  value: {
    fontSize: 14,
    fontWeight: 500,
    padding: "3px",
    height: 54,
    color: "#383538",
    backgroundColor: "#FFFFFF",
    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },
  tableRow: {
    position: "relative",
    "&:hover": {
      backgroundColor: "#F7F1FE !important",
      // border: "1px solid blue !important",
    },
    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },

  card: {
    flexGrow: 1,

    backgroundColor: "transparent",
    // overflow: "visible",
    marginTop: 10,
  },
  tableValue: {
    // borderBottom: "1px solid #A5A5A5 !important",
    // borderTop: "1px solid rgba(165, 165, 165, 0.5)",
    // borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
    "&:first-child": {
      borderRadius: "5px 0 0 5px",
    },
    "&:last-child": {
      borderRadius: "5px 5px 5px 5px ",
      borderRight: "1px solid #d6d6d6 !important",
    },
  },
  outlinedValue: {
    border: `0.7px solid rgba(56, 56, 56, 0.2)`,
    padding: 5,
    "&:first-child": {
      borderLeft: "0px",
    },
    // "&:last-child": {
    //   borderRight: "0px",
    // },
  },
  columnValue: {
    borderRight: `0px solid ${theme.palette.text.primary}`,
    borderBottom: "none",
  },
  selected: {
    backgroundColor: "#F7F1FE",
  },
  icon: {
    opacity: 0.6,
  },
});

export const StyledTableMenuButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  marginRight: 36,
  boxShadow: "0px 0px 0px 0px",
  padding: "8px 12px",
  minWidth: 0,
  border: "0px",
  "&:hover": {
    boxShadow: "0px 0px 0px 0px",
  },
}));

export default styles;
