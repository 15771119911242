import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import {
  ErrorText,
  OptionItem,
  OptionItemGroup,
  SurveyMsgContainer,
  SurveyMsgInput,
} from "./SurveyOptionItem.style";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  DEFAULT_SURVEY_MSG,
  IC_STAR,
  MAX_SURVEY_SCORE,
  emojiList,
} from "../SurveyConstants";
import _ from "lodash";

const StarBtn = ({
  isShowInputField,
  prevSurveyBtnStyle,
  prevSurveyMsg,
  handleChangeSurveyConfig,
}) => {
  const { formatMessage } = useIntl();
  const starInputRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [msg, setMsg] = useState(
    formatMessage({ id: DEFAULT_SURVEY_MSG.star.msg })
  );
  const [error, setError] = useState(false);

  const starCounts = Array.from(
    { length: MAX_SURVEY_SCORE },
    (_, index) => index + 1
  );

  useEffect(() => {
    if (prevSurveyBtnStyle === "star" && prevSurveyMsg) {
      setMsg(prevSurveyMsg);
    }
  }, [prevSurveyBtnStyle, prevSurveyMsg]);

  useEffect(() => {
    if (isShowInputField) {
      handleChangeSurveyConfig({
        key: "surveyMsg",
        val: msg,
      });
    }
  }, [msg, isShowInputField]);

  function handleEditFalse() {
    setIsEdit(false);
  }

  function handleEditTrue() {
    setIsEdit(true);
    setTimeout(() => {
      if (starInputRef.current) {
        starInputRef.current.focus();
      }
    }, 0);
  }

  function handleMsgChange(e) {
    const value = e.target.value;
    !value.length ? setError(true) : setError(false);
    setMsg(value);
  }

  function renderStars(count) {
    const stars = [];
    for (let i = 0; i < count; i++) {
      const ele = (
        <span key={i} style={{ fontSize: 16 }}>
          {IC_STAR}
        </span>
      );
      stars.push(ele);
    }
    return stars;
  }

  return (
    <>
      <OptionItemGroup>
        {starCounts.map((count, index) => (
          <OptionItem key={index}>{renderStars(count)}</OptionItem>
        ))}
      </OptionItemGroup>
      {isShowInputField ? (
        isEdit ? (
          <SurveyMsgInput
            ref={starInputRef}
            value={msg}
            isError={error}
            onChange={handleMsgChange}
            size={SIZE.MEDIUM}
            onEnter={handleEditFalse}
            onBlur={handleEditFalse}
            name="star_survey_msg"
          />
        ) : (
          <SurveyMsgContainer isError={error}>
            {msg}
            <IconButton
              fontSize={16}
              sx={{ marginLeft: "4px" }}
              onClick={handleEditTrue}>
              <i className="meta-crm-icon-ic_edit-1 font-size-32" />
            </IconButton>
          </SurveyMsgContainer>
        )
      ) : (
        false
      )}
      {error && isShowInputField && (
        <ErrorText>{"msg can't be empty"}</ErrorText>
      )}
    </>
  );
};

const EmojiBtn = ({
  isShowInputField,
  prevSurveyBtnStyle,
  prevSurveyMsg,
  handleChangeSurveyConfig,
}) => {
  const { formatMessage } = useIntl();
  const emojiInputRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const theme = useTheme();
  const [msg, setMsg] = useState(
    formatMessage({ id: DEFAULT_SURVEY_MSG.emoji.msg })
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    if (prevSurveyBtnStyle === "emoji" && prevSurveyMsg) {
      setMsg(prevSurveyMsg);
    }
  }, [prevSurveyBtnStyle, prevSurveyMsg]);

  useEffect(() => {
    if (isShowInputField) {
      handleChangeSurveyConfig({
        key: "surveyMsg",
        val: msg,
      });
    }
  }, [msg, isShowInputField]);

  function handleEditFalse() {
    setIsEdit(false);
  }

  function handleEditTrue() {
    setIsEdit(true);
    setTimeout(() => {
      if (emojiInputRef.current) {
        emojiInputRef.current.focus();
      }
    }, 0);
  }

  function handleMsgChange(e) {
    const value = e.target.value;
    !value.length ? setError(true) : setError(false);
    setMsg(value);
  }

  function renderEmoji(idx) {
    const emojiDetail = emojiList[idx];
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <div style={{ fontSize: 24 }}>{emojiDetail.emoji}</div>
        <div style={{ fontSize: 10, color: theme.customColors.grey[500] }}>
          {formatMessage({ id: emojiDetail.text })}
        </div>
      </span>
    );
  }

  return (
    <>
      <OptionItemGroup>
        {emojiList.map((_, index) => (
          <OptionItem key={index}>{renderEmoji(index)}</OptionItem>
        ))}
      </OptionItemGroup>
      {isShowInputField ? (
        isEdit ? (
          <SurveyMsgInput
            ref={emojiInputRef}
            value={msg}
            isError={error}
            onChange={handleMsgChange}
            size={SIZE.MEDIUM}
            onEnter={handleEditFalse}
            onBlur={handleEditFalse}
            name="emoji_survey_msg"
          />
        ) : (
          <SurveyMsgContainer isError={error}>
            {msg}
            <IconButton
              fontSize={16}
              sx={{ marginLeft: "4px" }}
              onClick={handleEditTrue}>
              <i className="meta-crm-icon-ic_edit-1 font-size-32" />
            </IconButton>
          </SurveyMsgContainer>
        )
      ) : (
        false
      )}
      {error && isShowInputField && (
        <ErrorText>{"msg can't be empty"}</ErrorText>
      )}
    </>
  );
};

export { StarBtn, EmojiBtn };
