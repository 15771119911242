import React, { useState, createContext } from "react";
const defaultValues = {
  sources: [],
  mediums: [],
  terms: [],
  contents: [],
};
export const TrackingOptionsContext = createContext();

export const TrackingOptionsProvider = ({ children }) => {
  const [options, setOptions] = useState(defaultValues);
  return (
    <TrackingOptionsContext.Provider value={{ options, setOptions }}>
      {children}
    </TrackingOptionsContext.Provider>
  );
};
