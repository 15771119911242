import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Checkbox, COLOR } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import Slider from "@mui/material/Slider";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

export const StyledCheckCollapseContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginBottom: "12px",
}));

export const StyledCheckboxContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: 32,
  padding: "4px 8px",
  width: 110,
  display: "flex",
  alignItems: "center",
  marginRight: 8,
}));

export const StyledInputField = styled(InputField, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiInputBase-input": {
    "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
}));

export const StyledTitleRow = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginRight: "8px",
  "& + .MuiFormControlLabel-label": {
    display: "flex",
    alignItems: "center",
    color: theme.customColors.grey[700],
    fontSize: "12px",
    "& i": {
      color: theme.customColors.grey[600],
      marginRight: "6px",
    },
  },
}));

export const StyledSliderContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "300px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid rgb(230, 230, 230)`,
  borderRadius: "3px",
}));

export const StyledSlider = styled(Slider, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: 0,
  marginBottom: 0,
  color: theme.customColors.purple[500],
  height: "8px",

  "& .MuiSlider-markLabel": {
    transform: "unset",
    color: `${theme.customColors.grey[700]} !important`,
  },

  '& .MuiSlider-markLabel[data-index="0"]': {
    top: "-5px",
    left: "-16px !important",
  },

  '& .MuiSlider-markLabel[data-index="1"]': {
    top: "-5px",
    right: "-21px !important",
    left: "unset !important",
  },

  "& .MuiSlider-mark": {
    display: "none",
  },

  "& .MuiSlider-thumb": {
    height: "14px",
    width: "14px",
    border: `1px solid ${theme.customColors.purple[500]}`,
    backgroundColor: theme.customColors.white,
    "&:hover": {
      boxShadow: `${alpha(
        theme.customColors.purple[500],
        0.16
      )} 0px 0px 0px 8px`,
    },
    "&.Mui-focusVisible": {
      boxShadow: `${alpha(
        theme.customColors.purple[500],
        0.16
      )} 0px 0px 0px 8px`,
    },
  },

  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 20,
    height: 20,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: theme.customColors.purple[500],
    transformOrigin: "bottom left",
    transform: "translate(50%, -50%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -50%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));
