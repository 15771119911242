import { attributionJsUrl, widgetJsUrl } from ".";

export const generateWidgetScript = ({ integrityInfo, apiKey }) => {
  const integrityHash = integrityInfo ? integrityInfo.hash : "";
  const scriptSrc = widgetJsUrl(integrityInfo?.version);
  const integrityAttr = integrityHash
    ? `\n      script.integrity = "${integrityHash}";`
    : "";
  return `<script>
  async function loadScript(src) {
    return new Promise((resolve, reject) => {
      var fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById('widget-dom-id')) return;
      const script = document.createElement('script');
      script.crossOrigin="anonymous";
      script.id = 'widget-dom-id';
      script.src = src;${integrityAttr}
      script.onload = () => resolve(script);
      script.onerror = () =>
        reject(new Error(\`Script load error for \${src}\`));
      fjs.parentNode.insertBefore(script, fjs);
    });
  }
  (async function () {
    try {
      await loadScript('${scriptSrc}');
      MetaCRMWidget.init({
        apiKey: '${apiKey}',${
    process.env.REACT_APP_ENV == "production"
      ? ""
      : `\n        MetaCRMWidgetExecutionEnvironment: "${process.env.REACT_APP_ENV}"`
  }
      });
    } catch (error) {
      console.error('Failed to load widget.js', error);
    }
  })();
</script>`;
};

export const generateTrackingScript = ({
  integrityInfo,
  apiKey,
  entityId,
  ecosystemName,
}) => {
  const scriptSrc = attributionJsUrl(integrityInfo?.version);
  const integrityHash = integrityInfo ? integrityInfo.hash : "";
  const integrityAttr = integrityHash
    ? `\n    integrity="${integrityHash}"`
    : "";
  return `<script 
    src="${scriptSrc}" 
    data-entity-id="${entityId}" 
    id="metacrm-tracking" 
    crossorigin="anonymous" 
    apiKey="${apiKey}" 
    ecoSystem="${ecosystemName}"${integrityAttr}${
    process.env.REACT_APP_ENV == "production"
      ? ""
      : `\n    MetaCRMTrackingExecutionEnvironment="${process.env.REACT_APP_ENV}"`
  }></script>`;
};
export const trackingAddressCode = `import { useAccount } from "wagmi";
// if your app is using wagmi, you can use useAccount hook
// your app might not use wagmi, and you might need some other way to grab wallet address

// ...
const Component = () => {
  const { address } = useAccount();

  useEffect(() => {
    if (window?.MetaCRMWidget?.manualConnectWallet) {
      window.MetaCRMWidget.manualConnectWallet(address);
    }
    
    const handleConnectWidget = () => {
      window.MetaCRMWidget.manualConnectWallet(address);
    };
    document.addEventListener('MetaCRMLoaded', handleConnectWidget);

    return () => {
      document.removeEventListener('MetaCRMLoaded', handleConnectWidget);
    };
  }, [address]);
  // ...
};
  `;
