import React, { useState, useEffect, useRef } from "react";
import { get, isEmpty } from "lodash-es";
import Box from "@mui/material/Box";

import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import { v4 as uuidv4 } from "uuid";
import {
  StyledGeneralSettingsContainer,
  StyledDraggableListContainer,
  StyledTicketNameTitle,
  StyledAddTicketTypeButton,
  StyledAlertText,
} from "./TicketListContainer.styles";
import DraggableTicketList from "../DraggableTicket/DraggableTicketList";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";

const TicketListContainer = ({
  ticketList,
  setTicketList,
  isValid,
  showWarning,
}) => {
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const methods = useFormContext();
  const { fields, append, replace, remove } = useFieldArray({
    control: methods.control,
    name: "ticketList",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(ticketList, source.index, destination.index);

    setTicketList(newItems);
    replace(newItems);
    methods.trigger();
  };

  const handleAddNewTicket = async () => {
    const data = {
      _id: uuidv4(),
      discordButtonStyle: 1,
      name: "Ex : General",
      addressRequired: false,
      isCreate: true,
    };
    setTicketList([...ticketList, data]);
    await append(data);
    methods.trigger();
  };

  const handleChangeTicketName = ({ event, ticketId }) => {
    const newArr = ticketList.map((item) => {
      if (item._id === ticketId) {
        return { ...item, name: event.target.value };
      }
      return item;
    });
    setTicketList(newArr);
    replace(newArr);
    methods.trigger();
  };

  const handleDeleteTicketItem = ({ ticketId, index }) => {
    confirm({
      title: formatMessage({
        id: "popup.delete.title",
      }),
      content: formatMessage({
        id: "popup.delete.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(() => {
        const newArr = ticketList.filter((ticket) => ticket._id !== ticketId);
        setTicketList(newArr);
        remove(index);
        methods.trigger();
      })
      .catch(() => {});
  };

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");

  return (
    <StyledGeneralSettingsContainer>
      <StyledTicketNameTitle readonly={!canEdit}>
        Ticket Type
      </StyledTicketNameTitle>
      <StyledDraggableListContainer>
        {canEdit ? (
          <>
            <DraggableTicketList
              ticketList={ticketList}
              onDragEnd={onDragEnd}
              onChangeTicketName={handleChangeTicketName}
              onDeleteTicket={
                ticketList.length === 1 ? null : handleDeleteTicketItem
              }
            />
            <StyledAddTicketTypeButton onClick={handleAddNewTicket}>
              <span className="plus">
                <i className="meta-crm-icon-ic_add font-size-16" />
              </span>{" "}
              {formatMessage({
                id: "ticketCustomization.btn.addTicketType",
              })}
            </StyledAddTicketTypeButton>
            {showWarning && (
              <StyledAlertText>
                <Box mr={"6px"}>
                  <i className="meta-crm-icon-ic_alert font-size-16" />
                </Box>
                Ticket Type must be unique and not empty.
              </StyledAlertText>
            )}
          </>
        ) : (
          <>
            {ticketList.map((item) => (
              <Box
                key={item._id}
                mb={"8px"}
                sx={{
                  background: "white",
                  border: `1px solid #E6E6E6`,
                  borderRadius: "8px",
                  width: "500px",
                  display: "flex",
                  alignItems: "center",
                  color: "#555",
                  padding: "0 14px",
                  height: "40px",
                  marginRight: "8px",
                }}
              >
                {item.name}
              </Box>
            ))}
          </>
        )}
      </StyledDraggableListContainer>
    </StyledGeneralSettingsContainer>
  );
};

export default TicketListContainer;
