import React from "react";
import { Box } from "@mui/material";
import { getUserAvatarIcon } from "features/metadesk/utils";

const Member = ({ member }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      sx={{ pl: "4px", columnGap: "4px" }}
    >
      <Box
        sx={{
          width: "20px",
          height: "20px",
          "& img": { width: "100%", height: "100%" },
        }}
      >
        <img
          src={require(`assets/img/${getUserAvatarIcon(member._id)}`)}
          alt="roleImage"
        />
      </Box>
      <Box fontSize={"12px"} color={(theme) => theme.customColors.grey[700]}>
        {member.name}
      </Box>
    </Box>
  );
};

export default Member;
