import { useConnectWallet } from "features/home/redux/connectWallet";
import { transformPermissions } from "features/metadesk/settings/Organization/hooks/useCreateRole";
import { get } from "lodash-es";

export default function useGetPagePermission() {
  const { userData } = useConnectWallet();

  function pagePermission(key) {
    // 還沒取到資料就先回傳空值
    if (!userData) {
      return {
        show: false,
        readonly: false,
        canEdit: false,
        permission: "none",
      };
    }
    // 管理員可以看到所有權限
    if (userData?.team?.role?.isAdmin) {
      return {
        show: true,
        readonly: false,
        canEdit: true,
        permission: "edit",
      };
    }

    // 取得使用者權限
    const userPermissions = userData?.team?.role?.permissions;
    const permissions = transformPermissions(userPermissions);
    const permission = get(permissions, key, "none");
    const option = {
      show: permission !== "none",
      readonly: permission === "read",
      canEdit: permission === "edit",
      permission,
    };

    return option;
  }
  return pagePermission;
}
