import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import { get, isEmpty, isEqual } from "lodash-es";

import {
  Button,
  IconButton,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  useNavigate,
  useLocation,
  useOutletContext,
  redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useTheme } from "@mui/styles";
import ReactPlayer from "react-player";
import { useForm, Controller, useFormState } from "react-hook-form";

import {
  StyledImgContainer,
  StyledUploadIconSmall,
  StyledUploadImageAreaContainer,
  StyledUploadInput,
  StyledUploadLimit,
  StyledRightImage,
  StyledImgErrorText,
  StyledRightImageContainer,
  StyledPreviewLogo,
} from "./TrialMarketingSetStep1.styles";

import axios from "axios";
import { apiUrl } from "features/configure";
import useNavigateEntity from "hooks/useNavigateEntity";
import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";

import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";

import TrialSetStepTemplate from "features/home/pages/Trial/TrialSetStepTemplate/TrialSetStepTemplate";
import TrialSetStepButton from "features/home/pages/Trial/TrialSetStepButton/TrialSetStepButton";
import { useStepData } from "contexts/stepContext/Step.context";
import useGetPagePermission from "hooks/useGetPagePermission";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { LinkOffOutlined } from "@mui/icons-material";

const formDataInit = {
  companyLogo: "",
};

const supportExtension = [".jpg", ".jpeg", ".png", ".svg"];
const fileSizeLimit = 2 * 1024 * 1024;

const TrialMarketingSetStep1 = ({
  step,
  onNextStep,
  entityName,
  moduleName,
}) => {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [uploadImgLoading, setIsUploadImgLoading] = useState(false);
  const [img, setImg] = useState("");
  const [imgErrorText, setImgErrorText] = useState("");
  const { setStepValues, stepData } = useStepData();

  const dispatch = useDispatch();

  useUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
    }
  });
  const { navigateEntity } = useNavigateEntity();

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors: errorsInfo, isValid },
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  useEffect(() => {
    if (!isEmpty(stepData)) {
      setImg(stepData.companyLogo);
      reset(stepData);
    }
  }, [stepData]);

  const validateFile = (file) => {
    if (!supportExtension.some((extension) => file.name.endsWith(extension))) {
      setImgErrorText("File format does not match");
      return false;
    }
    if (file.size > fileSizeLimit) {
      setImgErrorText("File is oversize");
      return false;
    }
    setImgErrorText("");
    return true;
  };

  const uploadFile = async (file, onFormChange) => {
    if (validateFile(file)) {
      let postFormData = new FormData();

      postFormData.append("logo", file);
      postFormData.append("entityName", entityName);
      try {
        setIsUploadImgLoading(true);

        const {
          data: { logo },
        } = await axios.post(
          `${apiUrl}/api/gcp/uploadCompanyLogo`,
          postFormData
        );
        setImg(logo[0]);
        onFormChange(logo[0]);
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
        setIsUploadImgLoading(false);
      }
    }
  };

  const renderUploadImgContent = () => {
    if (uploadImgLoading) {
      return (
        <Box color={(theme) => theme.customColors.grey[700]}>
          <LoadingComponent />
        </Box>
      );
    } else {
      return img ? (
        <>
          <StyledImgContainer>
            <img src={img} alt="upload-logo" />
          </StyledImgContainer>
          <StyledUploadIconSmall>
            <i className="meta-crm-icon-ic_uploadImage font-size-24" />
          </StyledUploadIconSmall>
        </>
      ) : (
        <>
          <Box>
            <i className="meta-crm-icon-ic_uploadImage big-upload" />
          </Box>
          <Box color={(theme) => theme.customColors.grey[500]} fontWeight={700}>
            Click to upload or drag & drop
          </Box>
        </>
      );
    }
  };

  const handleChooseFile = (event, onFormChange) => {
    const [file] = event.target.files;
    event.target.value = null;
    uploadFile(file, onFormChange);
  };

  const handleSubmitInfo = async (sendData) => {
    try {
      if (sendData.companyLogo !== stepData.companyLogo) {
        const { data } = await axios.post(
          `${apiUrl}/api/manage/profile/${entityName}/updateCompany`,
          {
            companyLogo: sendData.companyLogo,
          }
        );
        setStepValues(sendData);
      }
      await reset(sendData);
      setTimeout(() => {
        onNextStep();
      }, 0);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsPostLoading(false);
    }
  };

  const renderLeftArea = () => {
    return (
      <>
        <StyledUploadImageAreaContainer img={img} imgErrorText={imgErrorText}>
          <Controller
            name="companyLogo"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Logo is required",
              },
            }}
            render={({
              field: { onChange: onFormChange, value, ...field },
            }) => (
              <StyledUploadInput
                {...field}
                type="file"
                accept=".jpg,.jpeg,.png,.svg"
                onChange={(e) => {
                  handleChooseFile(e, onFormChange);
                }}
              />
            )}
          />

          {renderUploadImgContent()}
        </StyledUploadImageAreaContainer>

        {imgErrorText && (
          <StyledImgErrorText>
            <i className="meta-crm-icon-ic_alert font-size-16" />
            {imgErrorText}
          </StyledImgErrorText>
        )}
        <StyledUploadLimit>
          <Box>Maximum image size: 2mb</Box>
          <Box>Recommended resolution: 320 x 60 pixels</Box>
        </StyledUploadLimit>
      </>
    );
  };

  const renderRightArea = () => {
    return (
      <StyledRightImageContainer>
        <StyledPreviewLogo component="img" src={img}></StyledPreviewLogo>
        <StyledRightImage
          component="img"
          src={require("assets/img/metadesk_logoPreview.png")}
        ></StyledRightImage>
      </StyledRightImageContainer>
    );
  };

  const handleSkip = () => {
    let link = "";
    if (moduleName == "service") {
      link = `/${moduleName}/ticket/new`;
    }
    if (moduleName == "marketing") {
      link = `/${moduleName}/campaign/draft`;
    }
    navigateEntity(link);
  };

  const renderButtonArea = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          style={{ width: 120 }}
          onClick={handleSkip}
          variant={VARIANT.OUTLINED}
          sx={{ marginTop: "32px", marginRight: "16px" }}
        >
          Skip
        </Button>
        <TrialSetStepButton
          onSave={handleSubmit(handleSubmitInfo)}
          saveText="Next"
          isValid={isValid}
          isLoading={isPostLoading}
        />
      </Box>
    );
  };

  return (
    <>
      <TrialSetStepTemplate
        step={step}
        title="Upload your company logo"
        titleHint="Please upload your company logo. It will be shown in your own MetaCRM Portal."
        renderLeftArea={renderLeftArea}
        renderButtonArea={renderButtonArea}
        renderRightArea={renderRightArea}
      ></TrialSetStepTemplate>
      <Prompt when={isDirty} />
    </>
  );
};

export default TrialMarketingSetStep1;
