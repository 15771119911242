import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { generateKey, decryptToken } from "features/helpers/encrypt.js";
import { useAccount } from "wagmi";
import { useWallet } from "@manahippo/aptos-wallet-adapter";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "features/common/redux/actions";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useConnectWallet } from "features/home/redux/hooks";
import useInterceptor from "./useInterceptor.hooks";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";

const InterceptorContext = React.createContext({
  address: "",
  aptosConnect: () => {},
  aptosSignMessage: () => {},
  isConnected: false,
  isConnecting: false,
  aptosDisconnect: () => {},
  isEcoSystemLoading: false,
  isInterceptorSetup: false,
  handleGetEntityEcosystem: () => {},
  ecoSystemData: null,
});
export const InterceptorContextProvider = ({ children, isTrial }) => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  const envEntity = process.env.REACT_APP_ENTITY;
  const { entity } = useParams();
  const { authCheck } = useConnectWallet();

  const [entityName, setEntityName] = useState(envEntity || entity);
  const [ecoSystemData, setEcoSystemData] = useState(null);
  const [isEcoSystemLoading, setIsEcoSystemLoading] = useState(false);
  const [requestAuthPending, setRequestAuthPending] = useState(false);
  const [requestAuthDone, setRequestAuthDone] = useState(false);
  const intervalId = useRef(null);
  const { isInterceptorSetup, setIsInterceptorSetup } = useInterceptor();

  const dispatch = useDispatch();

  useEffect(() => {
    setEntityName(envEntity || entity);
  }, [entity]);

  const {
    connect: aptosConnect,
    disconnect: aptosDisconnect,
    connecting: aptosConnecting,
    connected: aptosConnected,
    account: aptosAccount,
    signMessage: aptosSignMessage,
  } = useWallet();

  const {
    select: solanaSelect,
    connect: solanaConnect,
    disconnect: solanaDisconnect,
    connecting: solanaConnecting,
    connected: solanaConnected,
    publicKey: solanaAccount,
    signMessage: solanaSignMessage,
  } = useSolanaWallet();

  const {
    address: evmAddress,
    isConnected: evmConnected,
    isConnecting: evmConnecting,
  } = useAccount({
    onConnect() {
      if (!computer) {
        setRequestAuthPending(false);
        setRequestAuthDone(false);
      }
    },
    onDisconnect() {
      setRequestAuthPending(false);
      setRequestAuthDone(false);
    },
  });

  const address = (() => {
    if (ecoSystemData === "aptos") {
      return aptosAccount?.address;
    } else if (ecoSystemData === "evm") {
      return evmAddress;
    } else if (ecoSystemData === "solana") {
      const test = solanaAccount?.toBase58();
      return test;
    } else {
      return evmAddress;
    }
  })();

  const isConnected = (() => {
    if (ecoSystemData === "aptos") {
      return aptosConnected;
    } else if (ecoSystemData === "evm") {
      return evmConnected;
    } else if (ecoSystemData === "solana") {
      return solanaConnected;
    } else {
      return evmConnected;
    }
  })();

  const isConnecting = (() => {
    if (ecoSystemData === "aptos") {
      return aptosConnecting;
    } else if (ecoSystemData === "evm") {
      return evmConnecting;
    } else if (ecoSystemData === "solana") {
      return solanaConnecting;
    } else {
      return evmConnecting;
    }
  })();

  useEffect(() => {
    async function fetchData() {
      if (isInterceptorSetup) {
        authCheck(entityName);

        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        intervalId.current = setInterval(() => {
          authCheck(entityName);
        }, 30000);
      }
    }

    fetchData();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [isInterceptorSetup]);

  const handleGetEntityEcosystem = async () => {
    setIsEcoSystemLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/entityEcosystem?entityName=${entityName}`
      );
      setEcoSystemData(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsEcoSystemLoading(false);
    }
  };

  useEffect(() => {
    if (entityName) {
      handleGetEntityEcosystem();
    }
  }, [entityName]);

  return (
    <InterceptorContext.Provider
      value={{
        address,
        aptosConnect,
        solanaSelect,
        solanaConnect,
        aptosSignMessage,
        solanaSignMessage,
        isConnected,
        isConnecting,
        aptosDisconnect,
        solanaDisconnect,
        isEcoSystemLoading,
        isInterceptorSetup,
        ecoSystemData,
        handleGetEntityEcosystem,
        requestAuthPending,
        setRequestAuthPending,
        requestAuthDone,
        setRequestAuthDone,
      }}
    >
      {children}
    </InterceptorContext.Provider>
  );
};

export default InterceptorContext;
