import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RADIANT_V1_FETCH_BEGIN,
  RADIANT_V1_FETCH_SUCCESS,
  RADIANT_V1_FETCH_FAILURE,
} from "./constants";
import { Multicall } from "ethereum-multicall";
import { erc20ABI } from "../../../configure";
import {
  radiantAccountingTokensV1,
  radiantGovernaceTokenV1,
  multiFeeDistributionContract,
  multiFeeDistributionABI,
} from "../configure/radiantV1Contracts";
import {
  convertAmountFromRawNumber,
  convertHexToString,
} from "features/helpers/bignumber";
const { getWeb3 } = require("../../../helpers/web3");

export function fetchRadiantV1Details(userAddress) {
  return async (dispatch, getState) => {
    dispatch({
      type: RADIANT_V1_FETCH_BEGIN,
    });

    const chainId = 42161;
    const provider = getWeb3(chainId);
    const multicall = new Multicall({ web3Instance: provider });

    let allTokens = [...radiantAccountingTokensV1, radiantGovernaceTokenV1];

    let calls = allTokens.map((token) => ({
      reference: token.name,
      contractAddress: token.address,
      abi: erc20ABI,
      calls: [{ methodName: "balanceOf", methodParameters: [userAddress] }],
    }));

    let vestingRdntCall = {
      reference: "vestingRdnt",
      contractAddress: multiFeeDistributionContract,
      abi: multiFeeDistributionABI,
      calls: [
        {
          methodName: "earnedBalances",
          methodParameters: [userAddress],
        },
      ],
    };

    calls.push(vestingRdntCall);

    try {
      const response = await multicall.call(calls);

      let output = {};
      let pools = [];
      for (let i = 0; i < allTokens.length; i++) {
        let pool = allTokens[i];
        const poolBalances = convertHexToString(
          response.results[pool.name]["callsReturnContext"][0][
            "returnValues"
          ][0]["hex"]
        );

        if (poolBalances > 0) {
          pool["balances"] = convertAmountFromRawNumber(
            poolBalances,
            pool.decimals
          );
          pools.push(pool);
        }
      }
      output["pools"] = pools;

      const vestingRdntBalances = convertHexToString(
        response.results["vestingRdnt"]["callsReturnContext"][0][
          "returnValues"
        ][0]["hex"]
      );

      output["vestingRdntBalances"] = convertAmountFromRawNumber(
        vestingRdntBalances,
        radiantGovernaceTokenV1.decimals
      );

      dispatch({
        type: RADIANT_V1_FETCH_SUCCESS,
        data: output,
      });
    } catch (error) {
      dispatch({
        type: RADIANT_V1_FETCH_FAILURE,
      });
      throw error;
    }
  };
}

export function useFetchRadiantV1Details() {
  const dispatch = useDispatch();

  const {
    radiantV1Details,
    fetchRadiantV1DetailsPending,
    fetchRadiantV1DetailsDone,
  } = useSelector(
    (state) => ({
      radiantV1Details: state.metadesk.radiantV1Details,
      fetchRadiantV1DetailsPending: state.metadesk.fetchRadiantV1DetailsPending,
      fetchRadiantV1DetailsDone: state.metadesk.fetchRadiantV1DetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRadiantV1Details(data));
    },
    [dispatch]
  );

  return {
    radiantV1Details,
    fetchRadiantV1Details: boundAction,
    fetchRadiantV1DetailsDone,
    fetchRadiantV1DetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RADIANT_V1_FETCH_BEGIN:
      return {
        ...state,
        fetchRadiantV1DetailsPending: true,
      };

    case RADIANT_V1_FETCH_SUCCESS:
      return {
        ...state,
        radiantV1Details: action.data,
        fetchRadiantV1DetailsDone: true,
        fetchRadiantV1DetailsPending: false,
      };

    case RADIANT_V1_FETCH_FAILURE:
      return {
        ...state,
        fetchRadiantV1DetailsPending: false,
      };

    default:
      return state;
  }
}
