import React from "react";
import { useSelector } from "react-redux";
import NewImportUserList from "./NewImportUserList";
import ImportUserList from "./ImportUserList";
import _ from "lodash";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";

export default function ImportList() {
  const isDemoSite = useSelector(selectDemoSite);

  if (isDemoSite) return <NewImportUserList />;
  return <ImportUserList />;
}
