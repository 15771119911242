import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useDispatch } from "react-redux";

import { useTheme } from "@mui/styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import ImageUpload from "features/metadesk/components/ImageUpload";
import IconLogoCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconLogoCRM";
import { formatSizeUnits } from "features/helpers/format";
import { useForm, useFormState, useFieldArray } from "react-hook-form";
import { enqueueSnackbar } from "features/common/redux/actions";
import { parseCustomDomainUrl, uploadFiles } from "features/helpers/utils";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import { Loading } from "features/common";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";

const WidgetLogo = ({ imgs, setImgs }) => {
  const { formatMessage } = useIntl();
  const uploadLogo = _.get(imgs, "[0]");

  const getLogo = (width = 80, height = 80, logoStyle = {}) =>
    uploadLogo ? (
      <img
        src={_.get(uploadLogo, "data_url", uploadLogo)}
        style={{
          width: width,
          height: height,
          objectFit: "contain",
          ...logoStyle,
        }}
      />
    ) : (
      <IconLogoCRM width={width} height={height} style={{ ...logoStyle }} />
    );

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  return (
    <Box>
      <Box color="#555" fontSize={16} fontWeight={700} mb="8px">
        Logo
      </Box>
      <Box color="#777" fontSize={14} fontWeight={500} mb="40px">
        Upload a logo that represents your brand.
      </Box>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <Box sx={{ margin: "0 auto 20px" }}>
            <div>
              <div style={{ marginBottom: 20 }} className="text">
                {formatMessage({
                  id: "widgetCustomization.widgetLogo.subtitle",
                })}{" "}
              </div>
              {readonly ? (
                <Box
                  className="column"
                  sx={{
                    borderRadius: "3px",
                    border: "1px solid #E6E6E6",
                    height: "250px",
                    maxWidth: { xs: "100%", lg: "90%" },
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  {getLogo()}
                </Box>
              ) : (
                <ImageUpload
                  maxFileSize={2097152}
                  acceptType={["png"]}
                  max={2}
                  replace={true}
                  uploadComponent={
                    <Box
                      className="column"
                      sx={{
                        borderRadius: "3px",
                        border: "1px solid #E6E6E6",
                        height: "250px",
                        maxWidth: { xs: "100%", lg: "90%" },
                        textAlign: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      {getLogo()}
                      <i
                        className="meta-crm-icon-ic_uploadImage"
                        style={{
                          color: "#A5A5A5",
                          fontSize: 20,
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                        }}
                      />
                    </Box>
                  }
                  value={imgs}
                  onChange={(list) => {
                    const logo = list[list.length - 1];
                    setImgs(logo);
                  }}
                />
              )}
              <div
                style={{
                  marginTop: 12,
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#5C5C5C",
                }}
              >
                <Box mb="4px">
                  {formatMessage({ id: "uploader.restriction.imageType" })}
                  <span style={{ color: "#7B61FF" }}>
                    {formatMessage({
                      id: "uploader.restriction.imageType.png",
                    })}
                  </span>
                </Box>
                <Box mb="4px">
                  {formatMessage({ id: "uploader.restriction.imageSize" })}
                </Box>
                <Box mb="4px">
                  {formatMessage({ id: "uploader.restriction.resolution" })}
                  <span>
                    {formatMessage({ id: "restriction.resolution.200x200" })}
                  </span>
                </Box>
              </div>
            </div>
          </Box>
        </Grid>

        <Box component={Grid} item xs={12} lg={7}>
          <Box fontSize="14px" color="#A5A5A5" mb="4px">
            Preview
          </Box>
          <Box
            sx={{
              padding: "40px 80px 0",
              paddingBottom: 0,
              background: "#F8F8F8",
              position: "relative",
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <div style={{ position: "relative" }}>
                <img
                  src={require("assets/img/widgetLogoBg.png")}
                  style={{ width: "320px" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    height: "36px",
                    top: "18px",
                    left: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getLogo("unset", "28px")}
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default WidgetLogo;
