import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import {
  InputField,
  SIZE,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import { Link, useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

export const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: theme.customColors.grey[200],
}));
