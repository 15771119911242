export const ticketMetrics = {
  ticketMetrics: {
    ticketCount: {
      new: 461,
      ongoing: 248,
      done: 3392,
      newlyCreated: 4101,
    },
    avgFrt: 79921721.47197802,
    avgResolveTime: 4323764499.745283,
    avgCsat: 3.641025641025641,
    csatRatingPercent: 9.740259740259742,
    ticketTypeStats: {
      distribution: {
        "Staking Issue": 1016,
        General: 820,
        "Claiming Issue": 793,
        "Fee Related": 21,
        "Liquidity Issue": 160,
        "Partnership Inquiry": 735,
        Others: 556,
      },
      distributionByDate: {
        "2023-04-13": {
          total: 3,
          "Staking Issue": 1,
          General: 2,
        },
        "2023-04-14": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-04-19": {
          total: 3,
          "Claiming Issue": 1,
          "Fee Related": 1,
          General: 1,
        },
        "2023-04-21": {
          total: 1,
          "Fee Related": 1,
        },
        "2023-04-22": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-04-23": {
          total: 3,
          "Staking Issue": 1,
          General: 2,
        },
        "2023-04-25": {
          total: 2,
          General: 2,
        },
        "2023-04-28": {
          total: 2,
          "Claiming Issue": 2,
        },
        "2023-05-01": {
          total: 2,
          General: 1,
          "Staking Issue": 1,
        },
        "2023-05-03": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-05-04": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-05-07": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-05-09": {
          total: 1,
          General: 1,
        },
        "2023-05-10": {
          total: 2,
          "Claiming Issue": 1,
          "Staking Issue": 1,
        },
        "2023-05-11": {
          total: 1,
          General: 1,
        },
        "2023-05-13": {
          total: 1,
          General: 1,
        },
        "2023-05-16": {
          total: 1,
          General: 1,
        },
        "2023-05-22": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-05-23": {
          total: 1,
          General: 1,
        },
        "2023-05-24": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-05-26": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-05-29": {
          total: 2,
          "Claiming Issue": 1,
          General: 1,
        },
        "2023-05-30": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-06-07": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-06-09": {
          total: 2,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
        },
        "2023-06-12": {
          total: 3,
          General: 1,
          "Staking Issue": 2,
        },
        "2023-06-13": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-06-15": {
          total: 1,
          General: 1,
        },
        "2023-06-18": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-06-20": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-06-21": {
          total: 4,
          General: 2,
          "Staking Issue": 2,
        },
        "2023-06-27": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-06-28": {
          total: 2,
          "Claiming Issue": 2,
        },
        "2023-06-29": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-06-30": {
          total: 4,
          General: 1,
          "Claiming Issue": 1,
          "Liquidity Issue": 2,
        },
        "2023-07-02": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-07-03": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-07-04": {
          total: 1,
          General: 1,
        },
        "2023-07-06": {
          total: 2,
          General: 1,
          "Staking Issue": 1,
        },
        "2023-07-08": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-07-09": {
          total: 3,
          General: 3,
        },
        "2023-07-11": {
          total: 1,
          General: 1,
        },
        "2023-07-12": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-07-13": {
          total: 20,
          General: 1,
          "Staking Issue": 1,
          "Partnership Inquiry": 11,
          Others: 7,
        },
        "2023-07-14": {
          total: 8,
          Others: 3,
          "Partnership Inquiry": 3,
          "Staking Issue": 1,
          General: 1,
        },
        "2023-07-15": {
          total: 4,
          "Partnership Inquiry": 3,
          "Staking Issue": 1,
        },
        "2023-07-17": {
          total: 1,
          General: 1,
        },
        "2023-07-19": {
          total: 11,
          "Staking Issue": 1,
          Others: 4,
          General: 1,
          "Partnership Inquiry": 5,
        },
        "2023-07-20": {
          total: 9,
          "Claiming Issue": 1,
          Others: 2,
          General: 1,
          "Partnership Inquiry": 5,
        },
        "2023-07-21": {
          total: 5,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
          "Liquidity Issue": 1,
        },
        "2023-07-22": {
          total: 2,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2023-07-23": {
          total: 4,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
        },
        "2023-07-24": {
          total: 6,
          Others: 1,
          "Partnership Inquiry": 4,
          "Claiming Issue": 1,
        },
        "2023-07-25": {
          total: 4,
          General: 1,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
        },
        "2023-07-26": {
          total: 4,
          "Partnership Inquiry": 3,
          Others: 1,
        },
        "2023-07-27": {
          total: 1,
          Others: 1,
        },
        "2023-07-28": {
          total: 3,
          Others: 2,
          "Partnership Inquiry": 1,
        },
        "2023-07-30": {
          total: 7,
          "Partnership Inquiry": 1,
          "Liquidity Issue": 3,
          Others: 3,
        },
        "2023-07-31": {
          total: 2,
          General: 1,
          "Staking Issue": 1,
        },
        "2023-08-01": {
          total: 9,
          Others: 2,
          "Staking Issue": 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 5,
        },
        "2023-08-02": {
          total: 4,
          "Partnership Inquiry": 1,
          General: 2,
          Others: 1,
        },
        "2023-08-03": {
          total: 9,
          General: 1,
          "Staking Issue": 2,
          "Partnership Inquiry": 6,
        },
        "2023-08-04": {
          total: 3,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
        },
        "2023-08-05": {
          total: 3,
          General: 1,
          Others: 1,
          "Staking Issue": 1,
        },
        "2023-08-06": {
          total: 2,
          "Partnership Inquiry": 1,
          General: 1,
        },
        "2023-08-07": {
          total: 2,
          Others: 2,
        },
        "2023-08-08": {
          total: 5,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
          Others: 1,
        },
        "2023-08-09": {
          total: 5,
          Others: 3,
          General: 2,
        },
        "2023-08-10": {
          total: 7,
          "Claiming Issue": 1,
          General: 4,
          "Staking Issue": 2,
        },
        "2023-08-13": {
          total: 2,
          "Partnership Inquiry": 2,
        },
        "2023-08-14": {
          total: 2,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2023-08-15": {
          total: 1,
          Others: 1,
        },
        "2023-08-16": {
          total: 7,
          "Partnership Inquiry": 5,
          "Claiming Issue": 1,
          "Staking Issue": 1,
        },
        "2023-08-17": {
          total: 5,
          "Partnership Inquiry": 2,
          "Staking Issue": 2,
          "Claiming Issue": 1,
        },
        "2023-08-18": {
          total: 5,
          General: 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          "Liquidity Issue": 1,
        },
        "2023-08-19": {
          total: 2,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2023-08-20": {
          total: 1,
          "Partnership Inquiry": 1,
        },
        "2023-08-21": {
          total: 3,
          "Partnership Inquiry": 2,
          General: 1,
        },
        "2023-08-22": {
          total: 5,
          "Staking Issue": 2,
          Others: 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
        },
        "2023-08-23": {
          total: 2,
          Others: 1,
          "Claiming Issue": 1,
        },
        "2023-08-24": {
          total: 2,
          "Staking Issue": 2,
        },
        "2023-08-25": {
          total: 2,
          "Partnership Inquiry": 2,
        },
        "2023-08-27": {
          total: 1,
          General: 1,
        },
        "2023-08-28": {
          total: 2,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2023-08-29": {
          total: 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2023-08-30": {
          total: 2,
          "Staking Issue": 1,
          General: 1,
        },
        "2023-08-31": {
          total: 4,
          "Partnership Inquiry": 1,
          Others: 2,
          "Claiming Issue": 1,
        },
        "2023-09-01": {
          total: 2,
          General: 1,
          "Claiming Issue": 1,
        },
        "2023-09-02": {
          total: 3,
          "Staking Issue": 1,
          "Claiming Issue": 2,
        },
        "2023-09-03": {
          total: 2,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
        },
        "2023-09-04": {
          total: 5,
          "Partnership Inquiry": 4,
          "Staking Issue": 1,
        },
        "2023-09-05": {
          total: 1,
          "Partnership Inquiry": 1,
        },
        "2023-09-06": {
          total: 4,
          "Partnership Inquiry": 2,
          Others: 2,
        },
        "2023-09-07": {
          total: 3,
          "Partnership Inquiry": 3,
        },
        "2023-09-08": {
          total: 2,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2023-09-10": {
          total: 3,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
        },
        "2023-09-11": {
          total: 3,
          "Claiming Issue": 1,
          "Staking Issue": 1,
          General: 1,
        },
        "2023-09-12": {
          total: 2,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
        },
        "2023-09-13": {
          total: 2,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2023-09-14": {
          total: 2,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2023-09-15": {
          total: 5,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
          Others: 2,
        },
        "2023-09-16": {
          total: 3,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
        },
        "2023-09-17": {
          total: 2,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2023-09-18": {
          total: 5,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
          "Staking Issue": 1,
        },
        "2023-09-19": {
          total: 2,
          Others: 2,
        },
        "2023-09-20": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-09-21": {
          total: 1,
          "Partnership Inquiry": 1,
        },
        "2023-09-22": {
          total: 2,
          "Partnership Inquiry": 2,
        },
        "2023-09-23": {
          total: 1,
          General: 1,
        },
        "2023-09-24": {
          total: 8,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
          General: 4,
          "Staking Issue": 2,
        },
        "2023-09-25": {
          total: 4,
          "Partnership Inquiry": 1,
          Others: 2,
          "Claiming Issue": 1,
        },
        "2023-09-26": {
          total: 6,
          General: 1,
          "Staking Issue": 1,
          Others: 1,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
        },
        "2023-09-27": {
          total: 4,
          Others: 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2023-09-28": {
          total: 10,
          "Partnership Inquiry": 3,
          Others: 3,
          "Claiming Issue": 2,
          "Staking Issue": 2,
        },
        "2023-09-29": {
          total: 7,
          "Claiming Issue": 1,
          Others: 2,
          General: 1,
          "Partnership Inquiry": 3,
        },
        "2023-09-30": {
          total: 1,
          "Staking Issue": 1,
        },
        "2023-10-01": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-10-02": {
          total: 3,
          Others: 3,
        },
        "2023-10-03": {
          total: 3,
          "Staking Issue": 1,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2023-10-04": {
          total: 9,
          "Claiming Issue": 1,
          "Liquidity Issue": 1,
          "Staking Issue": 4,
          "Partnership Inquiry": 2,
          General: 1,
        },
        "2023-10-05": {
          total: 4,
          General: 1,
          "Partnership Inquiry": 3,
        },
        "2023-10-06": {
          total: 7,
          "Partnership Inquiry": 4,
          "Staking Issue": 2,
          Others: 1,
        },
        "2023-10-07": {
          total: 6,
          "Fee Related": 2,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          Others: 2,
        },
        "2023-10-08": {
          total: 1,
          General: 1,
        },
        "2023-10-09": {
          total: 5,
          "Claiming Issue": 1,
          Others: 2,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2023-10-10": {
          total: 4,
          Others: 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2023-10-11": {
          total: 4,
          "Claiming Issue": 2,
          Others: 2,
        },
        "2023-10-12": {
          total: 8,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 2,
          Others: 2,
          General: 3,
        },
        "2023-10-13": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-10-14": {
          total: 3,
          General: 1,
          "Partnership Inquiry": 1,
          Others: 1,
        },
        "2023-10-15": {
          total: 3,
          General: 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
        },
        "2023-10-16": {
          total: 2,
          "Staking Issue": 1,
          Others: 1,
        },
        "2023-10-17": {
          total: 4,
          General: 1,
          "Partnership Inquiry": 3,
        },
        "2023-10-18": {
          total: 2,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2023-10-19": {
          total: 4,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
        },
        "2023-10-20": {
          total: 1,
          General: 1,
        },
        "2023-10-21": {
          total: 1,
          "Partnership Inquiry": 1,
        },
        "2023-10-22": {
          total: 2,
          Others: 2,
        },
        "2023-10-23": {
          total: 7,
          "Claiming Issue": 2,
          "Partnership Inquiry": 4,
          "Staking Issue": 1,
        },
        "2023-10-24": {
          total: 3,
          "Staking Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2023-10-25": {
          total: 3,
          General: 1,
          "Partnership Inquiry": 1,
          Others: 1,
        },
        "2023-10-26": {
          total: 2,
          "Partnership Inquiry": 2,
        },
        "2023-10-27": {
          total: 6,
          "Staking Issue": 1,
          "Claiming Issue": 2,
          "Partnership Inquiry": 3,
        },
        "2023-10-28": {
          total: 1,
          General: 1,
        },
        "2023-10-29": {
          total: 3,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
          General: 1,
        },
        "2023-10-30": {
          total: 4,
          "Partnership Inquiry": 3,
          "Staking Issue": 1,
        },
        "2023-10-31": {
          total: 4,
          "Partnership Inquiry": 3,
          General: 1,
        },
        "2023-11-01": {
          total: 9,
          "Claiming Issue": 6,
          Others: 1,
          General: 2,
        },
        "2023-11-02": {
          total: 1,
          "Partnership Inquiry": 1,
        },
        "2023-11-03": {
          total: 7,
          "Staking Issue": 2,
          "Claiming Issue": 3,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2023-11-04": {
          total: 2,
          "Claiming Issue": 1,
          Others: 1,
        },
        "2023-11-05": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-11-06": {
          total: 7,
          General: 2,
          Others: 1,
          "Partnership Inquiry": 4,
        },
        "2023-11-07": {
          total: 6,
          General: 1,
          "Partnership Inquiry": 3,
          "Claiming Issue": 2,
        },
        "2023-11-08": {
          total: 4,
          "Claiming Issue": 2,
          "Staking Issue": 2,
        },
        "2023-11-09": {
          total: 16,
          General: 3,
          Others: 4,
          "Staking Issue": 1,
          "Claiming Issue": 3,
          "Partnership Inquiry": 5,
        },
        "2023-11-10": {
          total: 6,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          "Claiming Issue": 2,
          Others: 2,
        },
        "2023-11-11": {
          total: 4,
          General: 1,
          "Staking Issue": 3,
        },
        "2023-11-12": {
          total: 4,
          Others: 2,
          "Staking Issue": 2,
        },
        "2023-11-14": {
          total: 1,
          "Claiming Issue": 1,
        },
        "2023-11-15": {
          total: 3,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
        },
        "2023-11-16": {
          total: 3,
          "Partnership Inquiry": 1,
          Others: 1,
          "Claiming Issue": 1,
        },
        "2023-11-17": {
          total: 3,
          General: 1,
          "Partnership Inquiry": 2,
        },
        "2023-11-18": {
          total: 2,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
        },
        "2023-11-19": {
          total: 4,
          Others: 3,
          "Partnership Inquiry": 1,
        },
        "2023-11-20": {
          total: 4,
          "Claiming Issue": 2,
          Others: 1,
          "Staking Issue": 1,
        },
        "2023-11-21": {
          total: 2,
          "Claiming Issue": 1,
          "Staking Issue": 1,
        },
        "2023-11-22": {
          total: 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2023-11-23": {
          total: 12,
          "Liquidity Issue": 2,
          Others: 2,
          "Staking Issue": 6,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2023-11-24": {
          total: 3,
          "Claiming Issue": 1,
          General: 1,
          "Staking Issue": 1,
        },
        "2023-11-25": {
          total: 1,
          "Liquidity Issue": 1,
        },
        "2023-11-26": {
          total: 6,
          "Staking Issue": 5,
          Others: 1,
        },
        "2023-11-27": {
          total: 3,
          "Liquidity Issue": 1,
          "Fee Related": 1,
          General: 1,
        },
        "2023-11-28": {
          total: 4,
          "Partnership Inquiry": 3,
          General: 1,
        },
        "2023-11-29": {
          total: 7,
          "Staking Issue": 1,
          "Partnership Inquiry": 3,
          General: 1,
          "Claiming Issue": 2,
        },
        "2023-11-30": {
          total: 1,
          "Partnership Inquiry": 1,
        },
        "2023-12-01": {
          total: 3,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
          Others: 1,
        },
        "2023-12-02": {
          total: 5,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
          Others: 1,
          "Claiming Issue": 2,
        },
        "2023-12-03": {
          total: 4,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
          "Staking Issue": 1,
        },
        "2023-12-04": {
          total: 3,
          "Partnership Inquiry": 3,
        },
        "2023-12-05": {
          total: 4,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
        },
        "2023-12-06": {
          total: 4,
          "Partnership Inquiry": 2,
          General: 1,
          "Claiming Issue": 1,
        },
        "2023-12-07": {
          total: 5,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
          General: 2,
        },
        "2023-12-08": {
          total: 10,
          General: 1,
          Others: 3,
          "Partnership Inquiry": 4,
          "Claiming Issue": 2,
        },
        "2023-12-09": {
          total: 2,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2023-12-10": {
          total: 4,
          Others: 1,
          "Claiming Issue": 1,
          "Staking Issue": 1,
          General: 1,
        },
        "2023-12-11": {
          total: 8,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
          Others: 3,
          "Liquidity Issue": 1,
          General: 1,
        },
        "2023-12-12": {
          total: 8,
          General: 2,
          Others: 5,
          "Partnership Inquiry": 1,
        },
        "2023-12-13": {
          total: 7,
          General: 1,
          "Staking Issue": 1,
          "Claiming Issue": 1,
          Others: 1,
          "Partnership Inquiry": 3,
        },
        "2023-12-14": {
          total: 8,
          General: 1,
          "Staking Issue": 2,
          Others: 1,
          "Claiming Issue": 2,
          "Partnership Inquiry": 2,
        },
        "2023-12-15": {
          total: 3,
          "Partnership Inquiry": 1,
          General: 2,
        },
        "2023-12-16": {
          total: 3,
          Others: 2,
          "Partnership Inquiry": 1,
        },
        "2023-12-17": {
          total: 2,
          Others: 2,
        },
        "2023-12-18": {
          total: 1,
          Others: 1,
        },
        "2023-12-19": {
          total: 3,
          "Partnership Inquiry": 3,
        },
        "2023-12-20": {
          total: 1,
          Others: 1,
        },
        "2023-12-21": {
          total: 2,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2023-12-22": {
          total: 9,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          "Claiming Issue": 3,
          General: 1,
          Others: 3,
        },
        "2023-12-23": {
          total: 4,
          Others: 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2023-12-24": {
          total: 6,
          General: 3,
          "Staking Issue": 1,
          Others: 1,
          "Liquidity Issue": 1,
        },
        "2023-12-25": {
          total: 1,
          General: 1,
        },
        "2023-12-26": {
          total: 1,
          General: 1,
        },
        "2023-12-27": {
          total: 6,
          General: 2,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2023-12-28": {
          total: 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2023-12-29": {
          total: 5,
          General: 2,
          "Liquidity Issue": 1,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2023-12-30": {
          total: 3,
          "Partnership Inquiry": 1,
          Others: 2,
        },
        "2023-12-31": {
          total: 2,
          General: 1,
          "Liquidity Issue": 1,
        },
        "2024-01-01": {
          total: 4,
          "Staking Issue": 1,
          Others: 1,
          General: 2,
        },
        "2024-01-02": {
          total: 8,
          Others: 3,
          "Claiming Issue": 2,
          "Staking Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2024-01-03": {
          total: 8,
          General: 2,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          Others: 2,
        },
        "2024-01-04": {
          total: 8,
          "Staking Issue": 2,
          Others: 2,
          "Claiming Issue": 1,
          General: 3,
        },
        "2024-01-05": {
          total: 14,
          "Claiming Issue": 2,
          Others: 2,
          "Fee Related": 1,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 5,
          General: 2,
        },
        "2024-01-06": {
          total: 11,
          General: 1,
          "Partnership Inquiry": 5,
          Others: 4,
          "Staking Issue": 1,
        },
        "2024-01-07": {
          total: 2,
          "Partnership Inquiry": 2,
        },
        "2024-01-08": {
          total: 12,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          "Claiming Issue": 2,
          "Partnership Inquiry": 7,
          General: 1,
        },
        "2024-01-09": {
          total: 5,
          "Partnership Inquiry": 1,
          Others: 1,
          "Claiming Issue": 3,
        },
        "2024-01-10": {
          total: 5,
          "Claiming Issue": 1,
          Others: 1,
          "Partnership Inquiry": 1,
          General: 2,
        },
        "2024-01-11": {
          total: 14,
          "Claiming Issue": 9,
          General: 1,
          "Partnership Inquiry": 2,
          "Liquidity Issue": 1,
          Others: 1,
        },
        "2024-01-12": {
          total: 8,
          Others: 1,
          "Claiming Issue": 3,
          "Partnership Inquiry": 2,
          General: 2,
        },
        "2024-01-13": {
          total: 8,
          Others: 3,
          General: 2,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2024-01-14": {
          total: 5,
          "Claiming Issue": 3,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2024-01-15": {
          total: 7,
          Others: 1,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 2,
          General: 2,
        },
        "2024-01-16": {
          total: 4,
          General: 2,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2024-01-17": {
          total: 10,
          Others: 2,
          "Partnership Inquiry": 2,
          "Staking Issue": 3,
          General: 3,
        },
        "2024-01-18": {
          total: 2,
          "Partnership Inquiry": 2,
        },
        "2024-01-19": {
          total: 6,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
          "Staking Issue": 1,
          Others: 1,
        },
        "2024-01-20": {
          total: 6,
          Others: 1,
          General: 2,
          "Liquidity Issue": 1,
          "Staking Issue": 2,
        },
        "2024-01-21": {
          total: 4,
          General: 2,
          Others: 1,
          "Staking Issue": 1,
        },
        "2024-01-22": {
          total: 6,
          "Partnership Inquiry": 4,
          "Claiming Issue": 2,
        },
        "2024-01-23": {
          total: 7,
          Others: 2,
          General: 1,
          "Partnership Inquiry": 4,
        },
        "2024-01-24": {
          total: 8,
          Others: 1,
          "Partnership Inquiry": 5,
          General: 2,
        },
        "2024-01-25": {
          total: 15,
          Others: 1,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 8,
          "Claiming Issue": 2,
          General: 3,
        },
        "2024-01-26": {
          total: 6,
          Others: 1,
          General: 1,
          "Claiming Issue": 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2024-01-27": {
          total: 4,
          "Partnership Inquiry": 1,
          General: 2,
          Others: 1,
        },
        "2024-01-28": {
          total: 18,
          "Staking Issue": 10,
          General: 2,
          "Claiming Issue": 1,
          "Liquidity Issue": 1,
          Others: 4,
        },
        "2024-01-29": {
          total: 25,
          "Claiming Issue": 1,
          General: 7,
          "Staking Issue": 7,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 5,
          Others: 2,
          "Fee Related": 2,
        },
        "2024-01-30": {
          total: 11,
          "Staking Issue": 4,
          General: 6,
          "Claiming Issue": 1,
        },
        "2024-01-31": {
          total: 21,
          General: 2,
          "Staking Issue": 7,
          "Fee Related": 1,
          "Claiming Issue": 1,
          Others: 6,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 3,
        },
        "2024-02-01": {
          total: 21,
          "Claiming Issue": 5,
          "Staking Issue": 7,
          General: 3,
          Others: 1,
          "Partnership Inquiry": 5,
        },
        "2024-02-02": {
          total: 13,
          General: 3,
          "Staking Issue": 8,
          "Partnership Inquiry": 2,
        },
        "2024-02-03": {
          total: 14,
          General: 4,
          "Staking Issue": 4,
          Others: 5,
          "Partnership Inquiry": 1,
        },
        "2024-02-04": {
          total: 25,
          "Claiming Issue": 5,
          "Fee Related": 2,
          "Staking Issue": 3,
          Others: 7,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 3,
          General: 4,
        },
        "2024-02-05": {
          total: 29,
          "Claiming Issue": 1,
          Others: 6,
          General: 7,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 4,
          "Staking Issue": 10,
        },
        "2024-02-06": {
          total: 25,
          Others: 6,
          "Claiming Issue": 1,
          "Staking Issue": 8,
          "Partnership Inquiry": 1,
          General: 9,
        },
        "2024-02-07": {
          total: 19,
          General: 3,
          "Partnership Inquiry": 3,
          "Staking Issue": 8,
          Others: 5,
        },
        "2024-02-08": {
          total: 25,
          "Staking Issue": 10,
          "Partnership Inquiry": 4,
          "Claiming Issue": 1,
          Others: 4,
          General: 6,
        },
        "2024-02-09": {
          total: 20,
          "Fee Related": 1,
          "Partnership Inquiry": 1,
          General: 2,
          Others: 2,
          "Staking Issue": 14,
        },
        "2024-02-10": {
          total: 19,
          General: 3,
          "Staking Issue": 9,
          Others: 4,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          "Fee Related": 1,
        },
        "2024-02-11": {
          total: 12,
          "Partnership Inquiry": 1,
          "Staking Issue": 9,
          Others: 1,
          "Claiming Issue": 1,
        },
        "2024-02-12": {
          total: 11,
          "Staking Issue": 7,
          "Partnership Inquiry": 2,
          Others: 1,
          "Liquidity Issue": 1,
        },
        "2024-02-13": {
          total: 8,
          "Claiming Issue": 2,
          "Partnership Inquiry": 1,
          General: 1,
          "Staking Issue": 4,
        },
        "2024-02-14": {
          total: 15,
          "Claiming Issue": 6,
          "Staking Issue": 5,
          General: 1,
          Others: 1,
          "Partnership Inquiry": 2,
        },
        "2024-02-15": {
          total: 16,
          "Claiming Issue": 2,
          Others: 2,
          "Partnership Inquiry": 1,
          General: 4,
          "Staking Issue": 7,
        },
        "2024-02-16": {
          total: 16,
          "Staking Issue": 6,
          Others: 2,
          "Partnership Inquiry": 3,
          General: 1,
          "Claiming Issue": 4,
        },
        "2024-02-17": {
          total: 5,
          "Claiming Issue": 1,
          Others: 2,
          "Liquidity Issue": 2,
        },
        "2024-02-18": {
          total: 12,
          "Liquidity Issue": 3,
          Others: 2,
          General: 4,
          "Staking Issue": 3,
        },
        "2024-02-19": {
          total: 10,
          "Partnership Inquiry": 1,
          Others: 3,
          "Liquidity Issue": 1,
          General: 1,
          "Claiming Issue": 1,
          "Staking Issue": 3,
        },
        "2024-02-20": {
          total: 16,
          Others: 1,
          "Staking Issue": 6,
          "Partnership Inquiry": 5,
          "Claiming Issue": 1,
          General: 3,
        },
        "2024-02-21": {
          total: 12,
          General: 4,
          "Fee Related": 1,
          "Partnership Inquiry": 3,
          Others: 1,
          "Claiming Issue": 1,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
        },
        "2024-02-22": {
          total: 13,
          "Partnership Inquiry": 6,
          "Liquidity Issue": 1,
          "Claiming Issue": 3,
          General: 1,
          "Staking Issue": 1,
          Others: 1,
        },
        "2024-02-23": {
          total: 10,
          General: 3,
          Others: 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 4,
        },
        "2024-02-24": {
          total: 9,
          "Staking Issue": 4,
          Others: 2,
          "Partnership Inquiry": 3,
        },
        "2024-02-25": {
          total: 8,
          "Staking Issue": 2,
          "Claiming Issue": 1,
          General: 5,
        },
        "2024-02-26": {
          total: 17,
          "Staking Issue": 4,
          "Liquidity Issue": 1,
          Others: 4,
          "Partnership Inquiry": 8,
        },
        "2024-02-27": {
          total: 13,
          "Partnership Inquiry": 1,
          General: 3,
          Others: 3,
          "Staking Issue": 6,
        },
        "2024-02-28": {
          total: 12,
          General: 3,
          "Claiming Issue": 1,
          "Partnership Inquiry": 3,
          "Staking Issue": 2,
          Others: 3,
        },
        "2024-02-29": {
          total: 15,
          "Claiming Issue": 3,
          "Staking Issue": 4,
          Others: 3,
          "Liquidity Issue": 2,
          General: 1,
          "Partnership Inquiry": 2,
        },
        "2024-03-01": {
          total: 14,
          "Staking Issue": 3,
          General: 5,
          "Claiming Issue": 2,
          "Partnership Inquiry": 1,
          Others: 3,
        },
        "2024-03-02": {
          total: 4,
          "Partnership Inquiry": 1,
          General: 3,
        },
        "2024-03-03": {
          total: 11,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
          General: 1,
          "Staking Issue": 7,
        },
        "2024-03-04": {
          total: 22,
          General: 6,
          "Claiming Issue": 2,
          "Staking Issue": 8,
          "Liquidity Issue": 4,
          Others: 2,
        },
        "2024-03-05": {
          total: 11,
          "Claiming Issue": 2,
          Others: 2,
          General: 1,
          "Staking Issue": 4,
          "Partnership Inquiry": 2,
        },
        "2024-03-06": {
          total: 8,
          "Partnership Inquiry": 2,
          Others: 1,
          General: 2,
          "Staking Issue": 2,
          "Claiming Issue": 1,
        },
        "2024-03-07": {
          total: 12,
          "Claiming Issue": 2,
          Others: 2,
          General: 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 6,
        },
        "2024-03-08": {
          total: 17,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          General: 7,
          Others: 3,
          "Staking Issue": 5,
        },
        "2024-03-09": {
          total: 6,
          General: 2,
          "Staking Issue": 3,
          "Claiming Issue": 1,
        },
        "2024-03-10": {
          total: 10,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          General: 1,
          "Liquidity Issue": 1,
          Others: 1,
          "Staking Issue": 4,
        },
        "2024-03-11": {
          total: 21,
          "Claiming Issue": 5,
          "Partnership Inquiry": 1,
          General: 2,
          Others: 3,
          "Staking Issue": 10,
        },
        "2024-03-12": {
          total: 7,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
          General: 1,
          "Claiming Issue": 2,
          Others: 2,
        },
        "2024-03-13": {
          total: 14,
          Others: 2,
          "Partnership Inquiry": 2,
          General: 1,
          "Staking Issue": 6,
          "Liquidity Issue": 1,
          "Claiming Issue": 2,
        },
        "2024-03-14": {
          total: 16,
          "Claiming Issue": 3,
          "Liquidity Issue": 2,
          "Staking Issue": 4,
          "Partnership Inquiry": 6,
          Others: 1,
        },
        "2024-03-15": {
          total: 16,
          General: 4,
          "Staking Issue": 5,
          "Liquidity Issue": 2,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
          "Fee Related": 1,
          Others: 1,
        },
        "2024-03-16": {
          total: 12,
          Others: 1,
          "Partnership Inquiry": 4,
          "Staking Issue": 5,
          General: 1,
          "Claiming Issue": 1,
        },
        "2024-03-17": {
          total: 14,
          General: 2,
          "Liquidity Issue": 1,
          Others: 1,
          "Staking Issue": 6,
          "Partnership Inquiry": 1,
          "Claiming Issue": 3,
        },
        "2024-03-18": {
          total: 13,
          General: 5,
          "Staking Issue": 2,
          "Claiming Issue": 2,
          "Partnership Inquiry": 2,
          Others: 2,
        },
        "2024-03-19": {
          total: 9,
          General: 3,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          "Staking Issue": 3,
        },
        "2024-03-20": {
          total: 14,
          "Staking Issue": 3,
          General: 4,
          "Partnership Inquiry": 3,
          Others: 2,
          "Liquidity Issue": 2,
        },
        "2024-03-21": {
          total: 11,
          Others: 3,
          General: 2,
          "Claiming Issue": 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
        },
        "2024-03-22": {
          total: 18,
          General: 4,
          Others: 4,
          "Partnership Inquiry": 1,
          "Staking Issue": 6,
          "Claiming Issue": 3,
        },
        "2024-03-23": {
          total: 17,
          "Partnership Inquiry": 1,
          "Claiming Issue": 4,
          "Staking Issue": 5,
          General: 6,
          "Liquidity Issue": 1,
        },
        "2024-03-24": {
          total: 14,
          "Claiming Issue": 6,
          "Staking Issue": 6,
          Others: 1,
          General: 1,
        },
        "2024-03-25": {
          total: 17,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
          General: 5,
          Others: 3,
          "Staking Issue": 7,
        },
        "2024-03-26": {
          total: 11,
          "Staking Issue": 4,
          Others: 1,
          "Partnership Inquiry": 4,
          General: 2,
        },
        "2024-03-27": {
          total: 19,
          Others: 2,
          "Claiming Issue": 1,
          "Partnership Inquiry": 4,
          "Staking Issue": 7,
          General: 3,
          "Liquidity Issue": 2,
        },
        "2024-03-28": {
          total: 15,
          Others: 4,
          General: 6,
          "Staking Issue": 2,
          "Claiming Issue": 3,
        },
        "2024-03-29": {
          total: 10,
          "Claiming Issue": 1,
          General: 2,
          Others: 1,
          "Staking Issue": 4,
          "Partnership Inquiry": 1,
          "Liquidity Issue": 1,
        },
        "2024-03-30": {
          total: 9,
          "Claiming Issue": 2,
          General: 4,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
          Others: 1,
        },
        "2024-03-31": {
          total: 13,
          "Staking Issue": 6,
          Others: 2,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
          General: 2,
        },
        "2024-04-01": {
          total: 16,
          Others: 3,
          "Claiming Issue": 3,
          "Partnership Inquiry": 2,
          General: 3,
          "Staking Issue": 4,
          "Liquidity Issue": 1,
        },
        "2024-04-02": {
          total: 12,
          Others: 3,
          General: 1,
          "Liquidity Issue": 2,
          "Partnership Inquiry": 2,
          "Staking Issue": 4,
        },
        "2024-04-03": {
          total: 8,
          General: 1,
          Others: 1,
          "Staking Issue": 3,
          "Claiming Issue": 3,
        },
        "2024-04-04": {
          total: 11,
          General: 3,
          Others: 3,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
          "Claiming Issue": 2,
        },
        "2024-04-05": {
          total: 11,
          "Staking Issue": 1,
          General: 5,
          "Claiming Issue": 3,
          Others: 2,
        },
        "2024-04-06": {
          total: 9,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
          "Staking Issue": 4,
          General: 1,
          Others: 1,
        },
        "2024-04-07": {
          total: 13,
          "Partnership Inquiry": 1,
          General: 6,
          "Staking Issue": 2,
          "Claiming Issue": 4,
        },
        "2024-04-08": {
          total: 14,
          General: 4,
          Others: 2,
          "Partnership Inquiry": 4,
          "Staking Issue": 4,
        },
        "2024-04-09": {
          total: 10,
          "Staking Issue": 2,
          "Partnership Inquiry": 1,
          Others: 1,
          General: 6,
        },
        "2024-04-10": {
          total: 10,
          Others: 2,
          "Staking Issue": 4,
          General: 1,
          "Partnership Inquiry": 3,
        },
        "2024-04-11": {
          total: 10,
          Others: 2,
          General: 3,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
          "Claiming Issue": 1,
        },
        "2024-04-12": {
          total: 5,
          "Staking Issue": 2,
          Others: 1,
          General: 1,
          "Claiming Issue": 1,
        },
        "2024-04-13": {
          total: 8,
          "Liquidity Issue": 1,
          "Staking Issue": 3,
          General: 2,
          Others: 1,
          "Partnership Inquiry": 1,
        },
        "2024-04-14": {
          total: 7,
          "Claiming Issue": 1,
          Others: 1,
          General: 1,
          "Staking Issue": 4,
        },
        "2024-04-15": {
          total: 9,
          "Staking Issue": 4,
          General: 2,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
        },
        "2024-04-16": {
          total: 5,
          "Claiming Issue": 1,
          "Staking Issue": 1,
          "Partnership Inquiry": 2,
          General: 1,
        },
        "2024-04-17": {
          total: 11,
          Others: 1,
          "Staking Issue": 2,
          General: 3,
          "Partnership Inquiry": 5,
        },
        "2024-04-18": {
          total: 6,
          "Partnership Inquiry": 2,
          General: 3,
          "Liquidity Issue": 1,
        },
        "2024-04-19": {
          total: 15,
          Others: 1,
          "Staking Issue": 1,
          General: 1,
          "Partnership Inquiry": 8,
          "Claiming Issue": 4,
        },
        "2024-04-20": {
          total: 14,
          "Claiming Issue": 3,
          "Staking Issue": 2,
          Others: 1,
          General: 2,
          "Partnership Inquiry": 6,
        },
        "2024-04-21": {
          total: 10,
          "Partnership Inquiry": 3,
          "Staking Issue": 2,
          General: 2,
          "Claiming Issue": 1,
          Others: 2,
        },
        "2024-04-22": {
          total: 25,
          "Partnership Inquiry": 5,
          "Staking Issue": 9,
          Others: 5,
          General: 3,
          "Claiming Issue": 3,
        },
        "2024-04-23": {
          total: 15,
          "Partnership Inquiry": 3,
          General: 4,
          "Staking Issue": 3,
          Others: 2,
          "Claiming Issue": 3,
        },
        "2024-04-24": {
          total: 25,
          "Staking Issue": 7,
          "Liquidity Issue": 4,
          Others: 2,
          "Claiming Issue": 10,
          General: 2,
        },
        "2024-04-25": {
          total: 16,
          "Liquidity Issue": 1,
          General: 1,
          "Claiming Issue": 4,
          Others: 4,
          "Staking Issue": 6,
        },
        "2024-04-26": {
          total: 16,
          "Partnership Inquiry": 3,
          "Staking Issue": 8,
          General: 1,
          "Claiming Issue": 4,
        },
        "2024-04-27": {
          total: 12,
          General: 2,
          "Partnership Inquiry": 1,
          "Staking Issue": 4,
          Others: 1,
          "Claiming Issue": 4,
        },
        "2024-04-28": {
          total: 9,
          Others: 2,
          General: 3,
          "Partnership Inquiry": 2,
          "Staking Issue": 2,
        },
        "2024-04-29": {
          total: 18,
          "Liquidity Issue": 1,
          "Claiming Issue": 6,
          "Partnership Inquiry": 4,
          "Staking Issue": 4,
          General: 2,
          Others: 1,
        },
        "2024-04-30": {
          total: 15,
          "Liquidity Issue": 2,
          "Staking Issue": 5,
          "Partnership Inquiry": 2,
          "Claiming Issue": 4,
          General: 2,
        },
        "2024-05-01": {
          total: 9,
          "Claiming Issue": 1,
          "Staking Issue": 4,
          Others: 2,
          "Partnership Inquiry": 2,
        },
        "2024-05-02": {
          total: 9,
          General: 2,
          Others: 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 4,
          "Staking Issue": 1,
        },
        "2024-05-03": {
          total: 22,
          "Partnership Inquiry": 3,
          "Liquidity Issue": 2,
          "Staking Issue": 9,
          Others: 1,
          General: 2,
          "Claiming Issue": 5,
        },
        "2024-05-04": {
          total: 13,
          "Staking Issue": 3,
          "Liquidity Issue": 3,
          "Claiming Issue": 5,
          General: 1,
          Others: 1,
        },
        "2024-05-05": {
          total: 9,
          General: 3,
          "Staking Issue": 4,
          "Claiming Issue": 2,
        },
        "2024-05-06": {
          total: 8,
          "Staking Issue": 1,
          "Partnership Inquiry": 4,
          "Claiming Issue": 2,
          General: 1,
        },
        "2024-05-07": {
          total: 16,
          "Staking Issue": 5,
          "Claiming Issue": 5,
          Others: 2,
          General: 1,
          "Partnership Inquiry": 3,
        },
        "2024-05-08": {
          total: 11,
          "Fee Related": 1,
          "Claiming Issue": 3,
          "Staking Issue": 3,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
          Others: 1,
          General: 1,
        },
        "2024-05-09": {
          total: 25,
          Others: 2,
          "Partnership Inquiry": 2,
          General: 5,
          "Staking Issue": 8,
          "Claiming Issue": 8,
        },
        "2024-05-10": {
          total: 20,
          "Staking Issue": 5,
          General: 4,
          "Claiming Issue": 10,
          "Liquidity Issue": 1,
        },
        "2024-05-11": {
          total: 29,
          "Staking Issue": 11,
          Others: 6,
          General: 4,
          "Claiming Issue": 8,
        },
        "2024-05-12": {
          total: 20,
          "Claiming Issue": 5,
          "Staking Issue": 9,
          Others: 1,
          General: 4,
          "Partnership Inquiry": 1,
        },
        "2024-05-13": {
          total: 15,
          General: 3,
          "Staking Issue": 5,
          "Partnership Inquiry": 2,
          Others: 3,
          "Claiming Issue": 2,
        },
        "2024-05-14": {
          total: 13,
          Others: 1,
          "Claiming Issue": 4,
          "Staking Issue": 4,
          General: 3,
          "Partnership Inquiry": 1,
        },
        "2024-05-15": {
          total: 11,
          General: 4,
          "Partnership Inquiry": 3,
          "Staking Issue": 4,
        },
        "2024-05-16": {
          total: 12,
          General: 3,
          "Claiming Issue": 2,
          "Partnership Inquiry": 3,
          "Staking Issue": 4,
        },
        "2024-05-17": {
          total: 17,
          Others: 2,
          "Staking Issue": 5,
          "Claiming Issue": 4,
          General: 2,
          "Partnership Inquiry": 3,
          "Fee Related": 1,
        },
        "2024-05-18": {
          total: 21,
          "Staking Issue": 9,
          "Liquidity Issue": 1,
          "Claiming Issue": 7,
          General: 4,
        },
        "2024-05-19": {
          total: 18,
          Others: 2,
          General: 2,
          "Staking Issue": 10,
          "Claiming Issue": 4,
        },
        "2024-05-20": {
          total: 16,
          General: 3,
          "Partnership Inquiry": 2,
          Others: 2,
          "Claiming Issue": 6,
          "Staking Issue": 3,
        },
        "2024-05-21": {
          total: 24,
          General: 6,
          "Claiming Issue": 1,
          "Staking Issue": 10,
          Others: 4,
          "Partnership Inquiry": 3,
        },
        "2024-05-22": {
          total: 13,
          General: 5,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
          "Claiming Issue": 2,
          Others: 2,
        },
        "2024-05-23": {
          total: 18,
          "Claiming Issue": 6,
          General: 5,
          "Partnership Inquiry": 2,
          Others: 3,
          "Staking Issue": 2,
        },
        "2024-05-24": {
          total: 10,
          Others: 1,
          "Partnership Inquiry": 2,
          "Staking Issue": 4,
          "Claiming Issue": 3,
        },
        "2024-05-25": {
          total: 8,
          Others: 1,
          General: 2,
          "Claiming Issue": 2,
          "Staking Issue": 2,
          "Liquidity Issue": 1,
        },
        "2024-05-26": {
          total: 9,
          General: 3,
          "Staking Issue": 3,
          "Claiming Issue": 1,
          Others: 2,
        },
        "2024-05-27": {
          total: 9,
          Others: 3,
          General: 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 4,
        },
        "2024-05-28": {
          total: 7,
          Others: 1,
          "Claiming Issue": 2,
          "Fee Related": 1,
          General: 2,
          "Staking Issue": 1,
        },
        "2024-05-29": {
          total: 13,
          "Claiming Issue": 1,
          "Partnership Inquiry": 4,
          "Staking Issue": 5,
          General: 3,
        },
        "2024-05-30": {
          total: 7,
          General: 1,
          "Partnership Inquiry": 6,
        },
        "2024-05-31": {
          total: 9,
          General: 5,
          Others: 1,
          "Staking Issue": 3,
        },
        "2024-06-01": {
          total: 12,
          "Claiming Issue": 6,
          "Staking Issue": 3,
          Others: 2,
          "Liquidity Issue": 1,
        },
        "2024-06-02": {
          total: 6,
          "Partnership Inquiry": 2,
          Others: 1,
          "Claiming Issue": 1,
          "Staking Issue": 2,
        },
        "2024-06-03": {
          total: 11,
          General: 3,
          Others: 1,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 5,
          "Staking Issue": 1,
        },
        "2024-06-04": {
          total: 6,
          "Staking Issue": 3,
          Others: 1,
          "Claiming Issue": 2,
        },
        "2024-06-05": {
          total: 11,
          "Claiming Issue": 3,
          General: 3,
          "Partnership Inquiry": 5,
        },
        "2024-06-06": {
          total: 16,
          General: 6,
          "Claiming Issue": 2,
          Others: 2,
          "Staking Issue": 5,
          "Partnership Inquiry": 1,
        },
        "2024-06-07": {
          total: 12,
          General: 2,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
          "Claiming Issue": 7,
        },
        "2024-06-08": {
          total: 8,
          "Claiming Issue": 2,
          "Staking Issue": 5,
          General: 1,
        },
        "2024-06-09": {
          total: 9,
          "Staking Issue": 4,
          Others: 1,
          General: 1,
          "Claiming Issue": 3,
        },
        "2024-06-10": {
          total: 11,
          "Claiming Issue": 3,
          General: 2,
          "Staking Issue": 3,
          "Partnership Inquiry": 2,
          Others: 1,
        },
        "2024-06-11": {
          total: 7,
          Others: 1,
          "Staking Issue": 2,
          General: 3,
          "Claiming Issue": 1,
        },
        "2024-06-12": {
          total: 11,
          "Claiming Issue": 4,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
          Others: 1,
          General: 3,
        },
        "2024-06-13": {
          total: 13,
          "Staking Issue": 3,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          General: 7,
          "Partnership Inquiry": 1,
        },
        "2024-06-14": {
          total: 17,
          "Liquidity Issue": 1,
          General: 2,
          "Claiming Issue": 2,
          Others: 1,
          "Staking Issue": 9,
          "Partnership Inquiry": 2,
        },
        "2024-06-15": {
          total: 10,
          "Claiming Issue": 3,
          General: 1,
          "Staking Issue": 3,
          "Partnership Inquiry": 2,
          Others: 1,
        },
        "2024-06-16": {
          total: 9,
          General: 1,
          "Claiming Issue": 1,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
          Others: 2,
          "Staking Issue": 3,
        },
        "2024-06-17": {
          total: 4,
          "Staking Issue": 1,
          Others: 1,
          "Claiming Issue": 2,
        },
        "2024-06-18": {
          total: 8,
          "Liquidity Issue": 1,
          "Claiming Issue": 1,
          Others: 1,
          General: 1,
          "Partnership Inquiry": 4,
        },
        "2024-06-19": {
          total: 12,
          "Liquidity Issue": 1,
          "Claiming Issue": 3,
          "Staking Issue": 1,
          Others: 1,
          General: 6,
        },
        "2024-06-20": {
          total: 10,
          Others: 2,
          "Partnership Inquiry": 1,
          General: 1,
          "Staking Issue": 3,
          "Claiming Issue": 3,
        },
        "2024-06-21": {
          total: 13,
          "Claiming Issue": 4,
          Others: 1,
          "Partnership Inquiry": 2,
          "Staking Issue": 2,
          General: 4,
        },
        "2024-06-22": {
          total: 16,
          "Staking Issue": 6,
          "Liquidity Issue": 1,
          General: 4,
          "Claiming Issue": 5,
        },
        "2024-06-23": {
          total: 10,
          "Liquidity Issue": 2,
          Others: 2,
          "Staking Issue": 3,
          General: 1,
          "Claiming Issue": 2,
        },
        "2024-06-24": {
          total: 5,
          "Staking Issue": 3,
          "Partnership Inquiry": 1,
          General: 1,
        },
        "2024-06-25": {
          total: 26,
          "Claiming Issue": 2,
          General: 5,
          "Partnership Inquiry": 5,
          Others: 3,
          "Staking Issue": 11,
        },
        "2024-06-26": {
          total: 20,
          Others: 2,
          "Liquidity Issue": 1,
          "Staking Issue": 6,
          General: 6,
          "Claiming Issue": 5,
        },
        "2024-06-27": {
          total: 13,
          "Staking Issue": 2,
          "Claiming Issue": 3,
          "Liquidity Issue": 2,
          "Partnership Inquiry": 1,
          General: 5,
        },
        "2024-06-28": {
          total: 15,
          General: 6,
          "Staking Issue": 2,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          Others: 4,
        },
        "2024-06-29": {
          total: 5,
          General: 5,
        },
        "2024-06-30": {
          total: 7,
          "Claiming Issue": 1,
          "Liquidity Issue": 1,
          General: 3,
          "Staking Issue": 1,
          Others: 1,
        },
        "2024-07-01": {
          total: 15,
          "Staking Issue": 3,
          "Partnership Inquiry": 3,
          General: 3,
          "Claiming Issue": 4,
          Others: 2,
        },
        "2024-07-02": {
          total: 4,
          "Liquidity Issue": 1,
          Others: 1,
          General: 1,
          "Staking Issue": 1,
        },
        "2024-07-03": {
          total: 5,
          "Staking Issue": 4,
          "Partnership Inquiry": 1,
        },
        "2024-07-04": {
          total: 8,
          General: 3,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
          "Staking Issue": 2,
        },
        "2024-07-05": {
          total: 15,
          "Claiming Issue": 6,
          "Staking Issue": 3,
          General: 5,
          "Partnership Inquiry": 1,
        },
        "2024-07-06": {
          total: 14,
          "Staking Issue": 5,
          "Claiming Issue": 1,
          "Partnership Inquiry": 2,
          General: 4,
          "Liquidity Issue": 1,
          Others: 1,
        },
        "2024-07-07": {
          total: 5,
          "Staking Issue": 2,
          Others: 1,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2024-07-08": {
          total: 9,
          General: 2,
          "Partnership Inquiry": 4,
          Others: 3,
        },
        "2024-07-09": {
          total: 13,
          General: 3,
          "Staking Issue": 3,
          "Partnership Inquiry": 5,
          Others: 2,
        },
        "2024-07-10": {
          total: 7,
          General: 2,
          Others: 2,
          "Staking Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2024-07-11": {
          total: 9,
          "Partnership Inquiry": 3,
          "Staking Issue": 3,
          "Claiming Issue": 3,
        },
        "2024-07-12": {
          total: 4,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          Others: 1,
        },
        "2024-07-13": {
          total: 6,
          Others: 1,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
          General: 1,
          "Claiming Issue": 1,
        },
        "2024-07-14": {
          total: 4,
          General: 1,
          "Liquidity Issue": 2,
          "Claiming Issue": 1,
        },
        "2024-07-15": {
          total: 6,
          "Staking Issue": 2,
          General: 1,
          "Claiming Issue": 3,
        },
        "2024-07-16": {
          total: 13,
          "Liquidity Issue": 1,
          "Staking Issue": 3,
          "Partnership Inquiry": 1,
          Others: 2,
          "Claiming Issue": 3,
          General: 3,
        },
        "2024-07-17": {
          total: 7,
          Others: 1,
          General: 1,
          "Staking Issue": 2,
          "Claiming Issue": 3,
        },
        "2024-07-18": {
          total: 3,
          "Partnership Inquiry": 1,
          "Claiming Issue": 1,
          "Staking Issue": 1,
        },
        "2024-07-19": {
          total: 10,
          "Claiming Issue": 3,
          "Partnership Inquiry": 3,
          General: 1,
          Others: 1,
          "Staking Issue": 2,
        },
        "2024-07-20": {
          total: 11,
          "Claiming Issue": 1,
          Others: 2,
          General: 2,
          "Staking Issue": 5,
          "Partnership Inquiry": 1,
        },
        "2024-07-21": {
          total: 9,
          "Fee Related": 1,
          "Claiming Issue": 1,
          "Liquidity Issue": 1,
          Others: 1,
          General: 4,
          "Staking Issue": 1,
        },
        "2024-07-22": {
          total: 7,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
          "Claiming Issue": 3,
          "Partnership Inquiry": 2,
        },
        "2024-07-23": {
          total: 9,
          Others: 1,
          General: 1,
          "Claiming Issue": 3,
          "Staking Issue": 3,
          "Partnership Inquiry": 1,
        },
        "2024-07-24": {
          total: 7,
          "Partnership Inquiry": 1,
          General: 2,
          "Claiming Issue": 1,
          "Staking Issue": 2,
          Others: 1,
        },
        "2024-07-25": {
          total: 12,
          Others: 1,
          General: 4,
          "Claiming Issue": 2,
          "Staking Issue": 3,
          "Partnership Inquiry": 1,
          "Liquidity Issue": 1,
        },
        "2024-07-26": {
          total: 3,
          "Liquidity Issue": 1,
          Others: 1,
          "Staking Issue": 1,
        },
        "2024-07-27": {
          total: 4,
          "Claiming Issue": 1,
          "Staking Issue": 2,
          General: 1,
        },
        "2024-07-28": {
          total: 5,
          "Staking Issue": 1,
          General: 2,
          "Claiming Issue": 2,
        },
        "2024-07-29": {
          total: 10,
          General: 3,
          "Partnership Inquiry": 1,
          Others: 3,
          "Claiming Issue": 2,
          "Staking Issue": 1,
        },
        "2024-07-30": {
          total: 14,
          "Claiming Issue": 2,
          Others: 3,
          "Staking Issue": 1,
          General: 8,
        },
        "2024-07-31": {
          total: 5,
          "Partnership Inquiry": 1,
          "Liquidity Issue": 1,
          "Staking Issue": 1,
          General: 2,
        },
        "2024-08-01": {
          total: 6,
          "Staking Issue": 2,
          General: 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
        },
        "2024-08-02": {
          total: 12,
          "Staking Issue": 2,
          General: 4,
          Others: 2,
          "Claiming Issue": 4,
        },
        "2024-08-03": {
          total: 8,
          "Partnership Inquiry": 1,
          "Liquidity Issue": 1,
          "Claiming Issue": 3,
          "Staking Issue": 3,
        },
        "2024-08-04": {
          total: 5,
          Others: 1,
          General: 1,
          "Claiming Issue": 2,
          "Staking Issue": 1,
        },
        "2024-08-05": {
          total: 8,
          "Fee Related": 1,
          "Partnership Inquiry": 2,
          "Staking Issue": 1,
          "Claiming Issue": 4,
        },
        "2024-08-06": {
          total: 9,
          "Claiming Issue": 2,
          General: 3,
          "Liquidity Issue": 2,
          "Partnership Inquiry": 2,
        },
        "2024-08-07": {
          total: 6,
          "Staking Issue": 2,
          "Partnership Inquiry": 2,
          General: 2,
        },
        "2024-08-08": {
          total: 14,
          "Fee Related": 1,
          "Claiming Issue": 2,
          "Staking Issue": 3,
          General: 3,
          "Liquidity Issue": 4,
          "Partnership Inquiry": 1,
        },
        "2024-08-09": {
          total: 10,
          General: 2,
          "Staking Issue": 4,
          "Claiming Issue": 2,
          Others: 1,
          "Liquidity Issue": 1,
        },
        "2024-08-10": {
          total: 2,
          General: 1,
          "Liquidity Issue": 1,
        },
        "2024-08-11": {
          total: 2,
          "Staking Issue": 1,
          "Claiming Issue": 1,
        },
        "2024-08-12": {
          total: 7,
          "Partnership Inquiry": 3,
          "Claiming Issue": 2,
          Others: 1,
          General: 1,
        },
        "2024-08-13": {
          total: 5,
          General: 2,
          "Liquidity Issue": 1,
          "Staking Issue": 2,
        },
        "2024-08-14": {
          total: 11,
          "Partnership Inquiry": 1,
          General: 2,
          "Claiming Issue": 2,
          Others: 1,
          "Staking Issue": 5,
        },
        "2024-08-15": {
          total: 6,
          "Staking Issue": 2,
          Others: 1,
          General: 1,
          "Partnership Inquiry": 2,
        },
        "2024-08-16": {
          total: 6,
          "Staking Issue": 1,
          "Partnership Inquiry": 2,
          "Claiming Issue": 1,
          General: 2,
        },
        "2024-08-17": {
          total: 4,
          "Claiming Issue": 1,
          "Staking Issue": 1,
          "Partnership Inquiry": 2,
        },
        "2024-08-18": {
          total: 6,
          "Claiming Issue": 1,
          "Liquidity Issue": 3,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
        },
        "2024-08-19": {
          total: 9,
          Others: 1,
          "Staking Issue": 2,
          General: 2,
          "Partnership Inquiry": 1,
          "Claiming Issue": 3,
        },
        "2024-08-20": {
          total: 4,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
          Others: 1,
          "Claiming Issue": 1,
        },
        "2024-08-21": {
          total: 5,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
          General: 2,
        },
        "2024-08-22": {
          total: 6,
          "Claiming Issue": 3,
          "Staking Issue": 3,
        },
        "2024-08-23": {
          total: 7,
          "Claiming Issue": 1,
          "Partnership Inquiry": 1,
          Others: 1,
          "Liquidity Issue": 1,
          "Staking Issue": 3,
        },
        "2024-08-24": {
          total: 9,
          "Staking Issue": 3,
          General: 1,
          "Claiming Issue": 2,
          "Partnership Inquiry": 2,
          Others: 1,
        },
        "2024-08-25": {
          total: 5,
          Others: 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
          General: 2,
        },
        "2024-08-26": {
          total: 5,
          "Staking Issue": 1,
          General: 1,
          "Claiming Issue": 3,
        },
        "2024-08-27": {
          total: 5,
          General: 1,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
          "Claiming Issue": 2,
        },
        "2024-08-28": {
          total: 6,
          "Claiming Issue": 3,
          Others: 3,
        },
        "2024-08-29": {
          total: 6,
          "Partnership Inquiry": 1,
          General: 1,
          Others: 2,
          "Staking Issue": 2,
        },
        "2024-08-30": {
          total: 4,
          "Claiming Issue": 1,
          "Staking Issue": 1,
          General: 1,
          "Partnership Inquiry": 1,
        },
        "2024-08-31": {
          total: 5,
          General: 3,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2024-09-01": {
          total: 9,
          "Claiming Issue": 1,
          General: 3,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
        },
        "2024-09-02": {
          total: 7,
          "Staking Issue": 5,
          General: 1,
          Others: 1,
        },
        "2024-09-03": {
          total: 9,
          "Claiming Issue": 2,
          "Partnership Inquiry": 1,
          Others: 2,
          General: 2,
          "Staking Issue": 2,
        },
        "2024-09-04": {
          total: 18,
          General: 3,
          "Partnership Inquiry": 4,
          "Claiming Issue": 2,
          "Liquidity Issue": 1,
          "Staking Issue": 4,
          Others: 4,
        },
        "2024-09-05": {
          total: 12,
          "Claiming Issue": 4,
          "Staking Issue": 3,
          "Partnership Inquiry": 2,
          General: 3,
        },
        "2024-09-06": {
          total: 10,
          "Liquidity Issue": 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
          "Claiming Issue": 4,
          Others: 1,
        },
        "2024-09-07": {
          total: 8,
          Others: 2,
          "Staking Issue": 1,
          General: 5,
        },
        "2024-09-08": {
          total: 9,
          "Liquidity Issue": 1,
          General: 3,
          "Claiming Issue": 1,
          Others: 4,
        },
        "2024-09-09": {
          total: 8,
          Others: 2,
          "Claiming Issue": 2,
          General: 2,
          "Staking Issue": 1,
          "Partnership Inquiry": 1,
        },
        "2024-09-10": {
          total: 7,
          Others: 3,
          "Staking Issue": 4,
        },
        "2024-09-11": {
          total: 10,
          "Staking Issue": 6,
          General: 2,
          "Partnership Inquiry": 2,
        },
        "2024-09-12": {
          total: 6,
          "Partnership Inquiry": 1,
          "Staking Issue": 1,
          General: 2,
          "Claiming Issue": 2,
        },
        "2024-09-13": {
          total: 15,
          "Partnership Inquiry": 1,
          Others: 1,
          "Claiming Issue": 9,
          "Staking Issue": 1,
          General: 3,
        },
        "2024-09-14": {
          total: 13,
          "Staking Issue": 5,
          General: 3,
          "Partnership Inquiry": 3,
          "Claiming Issue": 1,
          Others: 1,
        },
        "2024-09-15": {
          total: 6,
          Others: 1,
          "Liquidity Issue": 1,
          "Staking Issue": 3,
          General: 1,
        },
        "2024-09-16": {
          total: 13,
          Others: 1,
          General: 5,
          "Partnership Inquiry": 5,
          "Claiming Issue": 1,
          "Liquidity Issue": 1,
        },
        "2024-09-17": {
          total: 25,
          "Partnership Inquiry": 4,
          "Claiming Issue": 6,
          "Staking Issue": 3,
          "Liquidity Issue": 1,
          Others: 5,
          General: 6,
        },
        "2024-09-18": {
          total: 32,
          "Liquidity Issue": 1,
          Others: 7,
          General: 6,
          "Staking Issue": 6,
          "Partnership Inquiry": 7,
          "Claiming Issue": 5,
        },
        "2024-09-19": {
          total: 23,
          Others: 3,
          "Staking Issue": 4,
          "Partnership Inquiry": 5,
          General: 5,
          "Claiming Issue": 6,
        },
        "2024-09-20": {
          total: 21,
          "Claiming Issue": 8,
          "Staking Issue": 3,
          General: 7,
          Others: 1,
          "Partnership Inquiry": 2,
        },
        "2024-09-21": {
          total: 13,
          General: 5,
          "Claiming Issue": 3,
          Others: 2,
          "Staking Issue": 3,
        },
        "2024-09-22": {
          total: 18,
          "Staking Issue": 3,
          "Liquidity Issue": 1,
          "Claiming Issue": 6,
          General: 4,
          Others: 4,
        },
        "2024-09-23": {
          total: 19,
          General: 6,
          "Liquidity Issue": 1,
          "Claiming Issue": 3,
          "Partnership Inquiry": 3,
          Others: 4,
          "Staking Issue": 2,
        },
        "2024-09-24": {
          total: 17,
          Others: 2,
          "Partnership Inquiry": 4,
          General: 6,
          "Claiming Issue": 5,
        },
        "2024-09-25": {
          total: 48,
          "Liquidity Issue": 2,
          "Staking Issue": 5,
          "Partnership Inquiry": 7,
          General: 9,
          "Claiming Issue": 21,
          Others: 4,
        },
        "2024-09-26": {
          total: 32,
          "Partnership Inquiry": 3,
          "Staking Issue": 2,
          General: 4,
          "Liquidity Issue": 2,
          "Claiming Issue": 21,
        },
        "2024-09-27": {
          total: 28,
          Others: 4,
          "Staking Issue": 3,
          "Partnership Inquiry": 2,
          "Claiming Issue": 16,
          General: 3,
        },
        "2024-09-28": {
          total: 22,
          "Partnership Inquiry": 2,
          Others: 5,
          "Claiming Issue": 9,
          General: 4,
          "Staking Issue": 2,
        },
        "2024-09-29": {
          total: 9,
          "Claiming Issue": 4,
          General: 1,
          Others: 3,
          "Liquidity Issue": 1,
        },
        "2024-09-30": {
          total: 21,
          "Liquidity Issue": 2,
          "Claiming Issue": 8,
          Others: 1,
          "Partnership Inquiry": 5,
          "Staking Issue": 1,
          General: 4,
        },
        "2024-10-01": {
          total: 28,
          Others: 3,
          "Staking Issue": 6,
          "Claiming Issue": 9,
          General: 3,
          "Partnership Inquiry": 7,
        },
        "2024-10-02": {
          total: 18,
          "Partnership Inquiry": 3,
          General: 4,
          "Staking Issue": 2,
          "Claiming Issue": 3,
          Others: 4,
          "Liquidity Issue": 2,
        },
        "2024-10-03": {
          total: 9,
          Others: 1,
          "Partnership Inquiry": 1,
          General: 3,
          "Staking Issue": 1,
          "Claiming Issue": 3,
        },
        "2024-10-04": {
          total: 8,
          "Partnership Inquiry": 1,
          Others: 2,
          "Claiming Issue": 3,
          "Staking Issue": 1,
          "Liquidity Issue": 1,
        },
        "2024-10-05": {
          total: 9,
          "Liquidity Issue": 2,
          "Claiming Issue": 3,
          General: 4,
        },
        "2024-10-06": {
          total: 12,
          "Partnership Inquiry": 2,
          Others: 1,
          General: 3,
          "Staking Issue": 2,
          "Claiming Issue": 4,
        },
        "2024-10-07": {
          total: 22,
          "Liquidity Issue": 1,
          Others: 1,
          "Partnership Inquiry": 2,
          "Claiming Issue": 8,
          "Staking Issue": 3,
          General: 7,
        },
        "2024-10-08": {
          total: 8,
          General: 2,
          "Staking Issue": 2,
          "Liquidity Issue": 3,
          "Partnership Inquiry": 1,
        },
        "2024-10-09": {
          total: 14,
          "Partnership Inquiry": 1,
          Others: 2,
          "Staking Issue": 4,
          "Liquidity Issue": 2,
          "Claiming Issue": 4,
          General: 1,
        },
        "2024-10-10": {
          total: 9,
          "Claiming Issue": 2,
          General: 1,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
          Others: 2,
        },
        "2024-10-11": {
          total: 15,
          "Partnership Inquiry": 1,
          "Staking Issue": 4,
          Others: 1,
          "Claiming Issue": 5,
          General: 4,
        },
        "2024-10-12": {
          total: 12,
          "Liquidity Issue": 1,
          "Staking Issue": 5,
          "Claiming Issue": 1,
          Others: 2,
          "Partnership Inquiry": 1,
          General: 2,
        },
        "2024-10-13": {
          total: 10,
          General: 2,
          "Staking Issue": 4,
          Others: 1,
          "Partnership Inquiry": 2,
          "Liquidity Issue": 1,
        },
        "2024-10-14": {
          total: 6,
          "Partnership Inquiry": 1,
          "Staking Issue": 3,
          General: 1,
          "Claiming Issue": 1,
        },
      },
    },
    ticketSourceStats: {
      distribution: {
        widget: 1650,
        discord: 2257,
        supportCenter: 194,
      },
      distributionByDate: {
        "2023-04-13": {
          widget: 0,
          supportCenter: 3,
          discord: 0,
          total: 3,
        },
        "2023-04-14": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-04-19": {
          widget: 0,
          supportCenter: 3,
          discord: 0,
          total: 3,
        },
        "2023-04-21": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-04-22": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-04-23": {
          widget: 0,
          supportCenter: 3,
          discord: 0,
          total: 3,
        },
        "2023-04-25": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-04-28": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-05-01": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-05-03": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-04": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-07": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-09": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-10": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-05-11": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-13": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-16": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-22": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-23": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-24": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-26": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-05-29": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-05-30": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-07": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-09": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-06-12": {
          widget: 0,
          supportCenter: 3,
          discord: 0,
          total: 3,
        },
        "2023-06-13": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-15": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-18": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-20": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-21": {
          widget: 0,
          supportCenter: 4,
          discord: 0,
          total: 4,
        },
        "2023-06-27": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-28": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-06-29": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-06-30": {
          widget: 0,
          supportCenter: 4,
          discord: 0,
          total: 4,
        },
        "2023-07-02": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-03": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-04": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-06": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-07-08": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-09": {
          widget: 0,
          supportCenter: 3,
          discord: 0,
          total: 3,
        },
        "2023-07-11": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-12": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-13": {
          widget: 0,
          supportCenter: 0,
          discord: 20,
          total: 20,
        },
        "2023-07-14": {
          widget: 0,
          supportCenter: 2,
          discord: 6,
          total: 8,
        },
        "2023-07-15": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-07-17": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-07-19": {
          widget: 0,
          supportCenter: 1,
          discord: 10,
          total: 11,
        },
        "2023-07-20": {
          widget: 0,
          supportCenter: 0,
          discord: 9,
          total: 9,
        },
        "2023-07-21": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2023-07-22": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-07-23": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-07-24": {
          widget: 0,
          supportCenter: 1,
          discord: 5,
          total: 6,
        },
        "2023-07-25": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-07-26": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-07-27": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-07-28": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-07-30": {
          widget: 0,
          supportCenter: 2,
          discord: 5,
          total: 7,
        },
        "2023-07-31": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-08-01": {
          widget: 0,
          supportCenter: 0,
          discord: 9,
          total: 9,
        },
        "2023-08-02": {
          widget: 0,
          supportCenter: 2,
          discord: 2,
          total: 4,
        },
        "2023-08-03": {
          widget: 0,
          supportCenter: 1,
          discord: 8,
          total: 9,
        },
        "2023-08-04": {
          widget: 0,
          supportCenter: 1,
          discord: 2,
          total: 3,
        },
        "2023-08-05": {
          widget: 0,
          supportCenter: 1,
          discord: 2,
          total: 3,
        },
        "2023-08-06": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-08-07": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-08-08": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2023-08-09": {
          widget: 0,
          supportCenter: 1,
          discord: 4,
          total: 5,
        },
        "2023-08-10": {
          widget: 0,
          supportCenter: 4,
          discord: 3,
          total: 7,
        },
        "2023-08-13": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-08-14": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-08-15": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-08-16": {
          widget: 0,
          supportCenter: 1,
          discord: 6,
          total: 7,
        },
        "2023-08-17": {
          widget: 0,
          supportCenter: 2,
          discord: 3,
          total: 5,
        },
        "2023-08-18": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2023-08-19": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-08-20": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-08-21": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-08-22": {
          widget: 0,
          supportCenter: 1,
          discord: 4,
          total: 5,
        },
        "2023-08-23": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-08-24": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-08-25": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-08-27": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-08-28": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-08-29": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-08-30": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-08-31": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-09-01": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-09-02": {
          widget: 0,
          supportCenter: 2,
          discord: 1,
          total: 3,
        },
        "2023-09-03": {
          widget: 0,
          supportCenter: 2,
          discord: 0,
          total: 2,
        },
        "2023-09-04": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2023-09-05": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-09-06": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-09-07": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-09-08": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-09-10": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-09-11": {
          widget: 0,
          supportCenter: 2,
          discord: 1,
          total: 3,
        },
        "2023-09-12": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-09-13": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-09-14": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-09-15": {
          widget: 0,
          supportCenter: 3,
          discord: 2,
          total: 5,
        },
        "2023-09-16": {
          widget: 0,
          supportCenter: 1,
          discord: 2,
          total: 3,
        },
        "2023-09-17": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-09-18": {
          widget: 0,
          supportCenter: 1,
          discord: 4,
          total: 5,
        },
        "2023-09-19": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-09-20": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-09-21": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-09-22": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-09-23": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-09-24": {
          widget: 0,
          supportCenter: 0,
          discord: 8,
          total: 8,
        },
        "2023-09-25": {
          widget: 0,
          supportCenter: 1,
          discord: 3,
          total: 4,
        },
        "2023-09-26": {
          widget: 0,
          supportCenter: 3,
          discord: 3,
          total: 6,
        },
        "2023-09-27": {
          widget: 0,
          supportCenter: 1,
          discord: 3,
          total: 4,
        },
        "2023-09-28": {
          widget: 0,
          supportCenter: 3,
          discord: 7,
          total: 10,
        },
        "2023-09-29": {
          widget: 0,
          supportCenter: 2,
          discord: 5,
          total: 7,
        },
        "2023-09-30": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-10-01": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-10-02": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-10-03": {
          widget: 0,
          supportCenter: 1,
          discord: 2,
          total: 3,
        },
        "2023-10-04": {
          widget: 0,
          supportCenter: 5,
          discord: 4,
          total: 9,
        },
        "2023-10-05": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-10-06": {
          widget: 0,
          supportCenter: 1,
          discord: 6,
          total: 7,
        },
        "2023-10-07": {
          widget: 0,
          supportCenter: 2,
          discord: 4,
          total: 6,
        },
        "2023-10-08": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-10-09": {
          widget: 0,
          supportCenter: 3,
          discord: 2,
          total: 5,
        },
        "2023-10-10": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-10-11": {
          widget: 0,
          supportCenter: 1,
          discord: 3,
          total: 4,
        },
        "2023-10-12": {
          widget: 0,
          supportCenter: 0,
          discord: 8,
          total: 8,
        },
        "2023-10-13": {
          widget: 0,
          supportCenter: 1,
          discord: 0,
          total: 1,
        },
        "2023-10-14": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-10-15": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-10-16": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-10-17": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-10-18": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-10-19": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-10-20": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-10-21": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-10-22": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-10-23": {
          widget: 0,
          supportCenter: 1,
          discord: 6,
          total: 7,
        },
        "2023-10-24": {
          widget: 0,
          supportCenter: 1,
          discord: 2,
          total: 3,
        },
        "2023-10-25": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-10-26": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-10-27": {
          widget: 0,
          supportCenter: 1,
          discord: 5,
          total: 6,
        },
        "2023-10-28": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-10-29": {
          widget: 0,
          supportCenter: 1,
          discord: 2,
          total: 3,
        },
        "2023-10-30": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-10-31": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-11-01": {
          widget: 0,
          supportCenter: 4,
          discord: 5,
          total: 9,
        },
        "2023-11-02": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-11-03": {
          widget: 0,
          supportCenter: 3,
          discord: 4,
          total: 7,
        },
        "2023-11-04": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-11-05": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-11-06": {
          widget: 0,
          supportCenter: 1,
          discord: 6,
          total: 7,
        },
        "2023-11-07": {
          widget: 0,
          supportCenter: 0,
          discord: 6,
          total: 6,
        },
        "2023-11-08": {
          widget: 0,
          supportCenter: 3,
          discord: 1,
          total: 4,
        },
        "2023-11-09": {
          widget: 0,
          supportCenter: 4,
          discord: 12,
          total: 16,
        },
        "2023-11-10": {
          widget: 0,
          supportCenter: 1,
          discord: 5,
          total: 6,
        },
        "2023-11-11": {
          widget: 0,
          supportCenter: 3,
          discord: 1,
          total: 4,
        },
        "2023-11-12": {
          widget: 0,
          supportCenter: 2,
          discord: 2,
          total: 4,
        },
        "2023-11-14": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-11-15": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-11-16": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-11-17": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-11-18": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-11-19": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-11-20": {
          widget: 0,
          supportCenter: 3,
          discord: 1,
          total: 4,
        },
        "2023-11-21": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-11-22": {
          widget: 0,
          supportCenter: 1,
          discord: 1,
          total: 2,
        },
        "2023-11-23": {
          widget: 0,
          supportCenter: 6,
          discord: 6,
          total: 12,
        },
        "2023-11-24": {
          widget: 0,
          supportCenter: 2,
          discord: 1,
          total: 3,
        },
        "2023-11-25": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-11-26": {
          widget: 0,
          supportCenter: 0,
          discord: 6,
          total: 6,
        },
        "2023-11-27": {
          widget: 0,
          supportCenter: 2,
          discord: 1,
          total: 3,
        },
        "2023-11-28": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-11-29": {
          widget: 0,
          supportCenter: 3,
          discord: 4,
          total: 7,
        },
        "2023-11-30": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-12-01": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-12-02": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2023-12-03": {
          widget: 0,
          supportCenter: 1,
          discord: 3,
          total: 4,
        },
        "2023-12-04": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-12-05": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-12-06": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2023-12-07": {
          widget: 2,
          supportCenter: 0,
          discord: 3,
          total: 5,
        },
        "2023-12-08": {
          widget: 3,
          supportCenter: 0,
          discord: 7,
          total: 10,
        },
        "2023-12-09": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-12-10": {
          widget: 4,
          supportCenter: 0,
          discord: 0,
          total: 4,
        },
        "2023-12-11": {
          widget: 4,
          supportCenter: 0,
          discord: 4,
          total: 8,
        },
        "2023-12-12": {
          widget: 2,
          supportCenter: 0,
          discord: 6,
          total: 8,
        },
        "2023-12-13": {
          widget: 2,
          supportCenter: 0,
          discord: 5,
          total: 7,
        },
        "2023-12-14": {
          widget: 4,
          supportCenter: 0,
          discord: 4,
          total: 8,
        },
        "2023-12-15": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-12-16": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-12-17": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-12-18": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-12-19": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-12-20": {
          widget: 1,
          supportCenter: 0,
          discord: 0,
          total: 1,
        },
        "2023-12-21": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-12-22": {
          widget: 8,
          supportCenter: 0,
          discord: 1,
          total: 9,
        },
        "2023-12-23": {
          widget: 1,
          supportCenter: 0,
          discord: 3,
          total: 4,
        },
        "2023-12-24": {
          widget: 2,
          supportCenter: 0,
          discord: 4,
          total: 6,
        },
        "2023-12-25": {
          widget: 0,
          supportCenter: 0,
          discord: 1,
          total: 1,
        },
        "2023-12-26": {
          widget: 1,
          supportCenter: 0,
          discord: 0,
          total: 1,
        },
        "2023-12-27": {
          widget: 4,
          supportCenter: 0,
          discord: 2,
          total: 6,
        },
        "2023-12-28": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2023-12-29": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2023-12-30": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2023-12-31": {
          widget: 1,
          supportCenter: 0,
          discord: 1,
          total: 2,
        },
        "2024-01-01": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2024-01-02": {
          widget: 2,
          supportCenter: 0,
          discord: 6,
          total: 8,
        },
        "2024-01-03": {
          widget: 4,
          supportCenter: 0,
          discord: 4,
          total: 8,
        },
        "2024-01-04": {
          widget: 4,
          supportCenter: 0,
          discord: 4,
          total: 8,
        },
        "2024-01-05": {
          widget: 9,
          supportCenter: 0,
          discord: 5,
          total: 14,
        },
        "2024-01-06": {
          widget: 1,
          supportCenter: 0,
          discord: 10,
          total: 11,
        },
        "2024-01-07": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2024-01-08": {
          widget: 0,
          supportCenter: 0,
          discord: 12,
          total: 12,
        },
        "2024-01-09": {
          widget: 0,
          supportCenter: 0,
          discord: 5,
          total: 5,
        },
        "2024-01-10": {
          widget: 1,
          supportCenter: 0,
          discord: 4,
          total: 5,
        },
        "2024-01-11": {
          widget: 11,
          supportCenter: 0,
          discord: 3,
          total: 14,
        },
        "2024-01-12": {
          widget: 4,
          supportCenter: 0,
          discord: 4,
          total: 8,
        },
        "2024-01-13": {
          widget: 3,
          supportCenter: 0,
          discord: 5,
          total: 8,
        },
        "2024-01-14": {
          widget: 4,
          supportCenter: 0,
          discord: 1,
          total: 5,
        },
        "2024-01-15": {
          widget: 4,
          supportCenter: 0,
          discord: 3,
          total: 7,
        },
        "2024-01-16": {
          widget: 2,
          supportCenter: 0,
          discord: 2,
          total: 4,
        },
        "2024-01-17": {
          widget: 5,
          supportCenter: 0,
          discord: 5,
          total: 10,
        },
        "2024-01-18": {
          widget: 0,
          supportCenter: 0,
          discord: 2,
          total: 2,
        },
        "2024-01-19": {
          widget: 0,
          supportCenter: 0,
          discord: 6,
          total: 6,
        },
        "2024-01-20": {
          widget: 6,
          supportCenter: 0,
          discord: 0,
          total: 6,
        },
        "2024-01-21": {
          widget: 2,
          supportCenter: 0,
          discord: 2,
          total: 4,
        },
        "2024-01-22": {
          widget: 2,
          supportCenter: 0,
          discord: 4,
          total: 6,
        },
        "2024-01-23": {
          widget: 1,
          supportCenter: 0,
          discord: 6,
          total: 7,
        },
        "2024-01-24": {
          widget: 1,
          supportCenter: 0,
          discord: 7,
          total: 8,
        },
        "2024-01-25": {
          widget: 3,
          supportCenter: 0,
          discord: 12,
          total: 15,
        },
        "2024-01-26": {
          widget: 2,
          supportCenter: 0,
          discord: 4,
          total: 6,
        },
        "2024-01-27": {
          widget: 1,
          supportCenter: 0,
          discord: 3,
          total: 4,
        },
        "2024-01-28": {
          widget: 7,
          supportCenter: 0,
          discord: 11,
          total: 18,
        },
        "2024-01-29": {
          widget: 15,
          supportCenter: 0,
          discord: 10,
          total: 25,
        },
        "2024-01-30": {
          widget: 5,
          supportCenter: 0,
          discord: 6,
          total: 11,
        },
        "2024-01-31": {
          widget: 11,
          supportCenter: 0,
          discord: 10,
          total: 21,
        },
        "2024-02-01": {
          widget: 10,
          supportCenter: 0,
          discord: 11,
          total: 21,
        },
        "2024-02-02": {
          widget: 3,
          supportCenter: 0,
          discord: 10,
          total: 13,
        },
        "2024-02-03": {
          widget: 8,
          supportCenter: 0,
          discord: 6,
          total: 14,
        },
        "2024-02-04": {
          widget: 17,
          supportCenter: 0,
          discord: 8,
          total: 25,
        },
        "2024-02-05": {
          widget: 13,
          supportCenter: 0,
          discord: 16,
          total: 29,
        },
        "2024-02-06": {
          widget: 18,
          supportCenter: 0,
          discord: 7,
          total: 25,
        },
        "2024-02-07": {
          widget: 9,
          supportCenter: 0,
          discord: 10,
          total: 19,
        },
        "2024-02-08": {
          widget: 15,
          supportCenter: 0,
          discord: 10,
          total: 25,
        },
        "2024-02-09": {
          widget: 18,
          supportCenter: 0,
          discord: 2,
          total: 20,
        },
        "2024-02-10": {
          widget: 15,
          supportCenter: 0,
          discord: 4,
          total: 19,
        },
        "2024-02-11": {
          widget: 9,
          supportCenter: 0,
          discord: 3,
          total: 12,
        },
        "2024-02-12": {
          widget: 8,
          supportCenter: 0,
          discord: 3,
          total: 11,
        },
        "2024-02-13": {
          widget: 5,
          supportCenter: 0,
          discord: 3,
          total: 8,
        },
        "2024-02-14": {
          widget: 5,
          supportCenter: 0,
          discord: 10,
          total: 15,
        },
        "2024-02-15": {
          widget: 14,
          supportCenter: 0,
          discord: 2,
          total: 16,
        },
        "2024-02-16": {
          widget: 7,
          supportCenter: 0,
          discord: 9,
          total: 16,
        },
        "2024-02-17": {
          widget: 3,
          supportCenter: 0,
          discord: 2,
          total: 5,
        },
        "2024-02-18": {
          widget: 9,
          supportCenter: 0,
          discord: 3,
          total: 12,
        },
        "2024-02-19": {
          widget: 5,
          supportCenter: 0,
          discord: 5,
          total: 10,
        },
        "2024-02-20": {
          widget: 10,
          supportCenter: 0,
          discord: 6,
          total: 16,
        },
        "2024-02-21": {
          widget: 4,
          supportCenter: 0,
          discord: 8,
          total: 12,
        },
        "2024-02-22": {
          widget: 5,
          supportCenter: 0,
          discord: 8,
          total: 13,
        },
        "2024-02-23": {
          widget: 8,
          supportCenter: 0,
          discord: 2,
          total: 10,
        },
        "2024-02-24": {
          widget: 4,
          supportCenter: 0,
          discord: 5,
          total: 9,
        },
        "2024-02-25": {
          widget: 3,
          supportCenter: 0,
          discord: 5,
          total: 8,
        },
        "2024-02-26": {
          widget: 6,
          supportCenter: 0,
          discord: 11,
          total: 17,
        },
        "2024-02-27": {
          widget: 8,
          supportCenter: 0,
          discord: 5,
          total: 13,
        },
        "2024-02-28": {
          widget: 3,
          supportCenter: 0,
          discord: 9,
          total: 12,
        },
        "2024-02-29": {
          widget: 8,
          supportCenter: 0,
          discord: 7,
          total: 15,
        },
        "2024-03-01": {
          widget: 6,
          supportCenter: 0,
          discord: 8,
          total: 14,
        },
        "2024-03-02": {
          widget: 3,
          supportCenter: 0,
          discord: 1,
          total: 4,
        },
        "2024-03-03": {
          widget: 4,
          supportCenter: 0,
          discord: 7,
          total: 11,
        },
        "2024-03-04": {
          widget: 11,
          supportCenter: 0,
          discord: 11,
          total: 22,
        },
        "2024-03-05": {
          widget: 4,
          supportCenter: 0,
          discord: 7,
          total: 11,
        },
        "2024-03-06": {
          widget: 2,
          supportCenter: 0,
          discord: 6,
          total: 8,
        },
        "2024-03-07": {
          widget: 8,
          supportCenter: 0,
          discord: 4,
          total: 12,
        },
        "2024-03-08": {
          widget: 10,
          supportCenter: 0,
          discord: 7,
          total: 17,
        },
        "2024-03-09": {
          widget: 5,
          supportCenter: 0,
          discord: 1,
          total: 6,
        },
        "2024-03-10": {
          widget: 6,
          supportCenter: 0,
          discord: 4,
          total: 10,
        },
        "2024-03-11": {
          widget: 14,
          supportCenter: 0,
          discord: 7,
          total: 21,
        },
        "2024-03-12": {
          widget: 4,
          supportCenter: 1,
          discord: 2,
          total: 7,
        },
        "2024-03-13": {
          widget: 9,
          supportCenter: 0,
          discord: 5,
          total: 14,
        },
        "2024-03-14": {
          widget: 7,
          supportCenter: 0,
          discord: 9,
          total: 16,
        },
        "2024-03-15": {
          widget: 9,
          supportCenter: 0,
          discord: 7,
          total: 16,
        },
        "2024-03-16": {
          widget: 2,
          supportCenter: 1,
          discord: 9,
          total: 12,
        },
        "2024-03-17": {
          widget: 5,
          supportCenter: 0,
          discord: 9,
          total: 14,
        },
        "2024-03-18": {
          widget: 7,
          supportCenter: 0,
          discord: 6,
          total: 13,
        },
        "2024-03-19": {
          widget: 3,
          supportCenter: 0,
          discord: 6,
          total: 9,
        },
        "2024-03-20": {
          widget: 5,
          supportCenter: 0,
          discord: 9,
          total: 14,
        },
        "2024-03-21": {
          widget: 4,
          supportCenter: 0,
          discord: 7,
          total: 11,
        },
        "2024-03-22": {
          widget: 10,
          supportCenter: 0,
          discord: 8,
          total: 18,
        },
        "2024-03-23": {
          widget: 12,
          supportCenter: 0,
          discord: 5,
          total: 17,
        },
        "2024-03-24": {
          widget: 10,
          supportCenter: 0,
          discord: 4,
          total: 14,
        },
        "2024-03-25": {
          widget: 13,
          supportCenter: 1,
          discord: 3,
          total: 17,
        },
        "2024-03-26": {
          widget: 3,
          supportCenter: 2,
          discord: 6,
          total: 11,
        },
        "2024-03-27": {
          widget: 8,
          supportCenter: 0,
          discord: 11,
          total: 19,
        },
        "2024-03-28": {
          widget: 9,
          supportCenter: 1,
          discord: 5,
          total: 15,
        },
        "2024-03-29": {
          widget: 7,
          supportCenter: 0,
          discord: 3,
          total: 10,
        },
        "2024-03-30": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-03-31": {
          widget: 11,
          supportCenter: 0,
          discord: 2,
          total: 13,
        },
        "2024-04-01": {
          widget: 5,
          supportCenter: 1,
          discord: 10,
          total: 16,
        },
        "2024-04-02": {
          widget: 3,
          supportCenter: 0,
          discord: 9,
          total: 12,
        },
        "2024-04-03": {
          widget: 5,
          supportCenter: 0,
          discord: 3,
          total: 8,
        },
        "2024-04-04": {
          widget: 5,
          supportCenter: 1,
          discord: 5,
          total: 11,
        },
        "2024-04-05": {
          widget: 6,
          supportCenter: 0,
          discord: 5,
          total: 11,
        },
        "2024-04-06": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-04-07": {
          widget: 4,
          supportCenter: 0,
          discord: 9,
          total: 13,
        },
        "2024-04-08": {
          widget: 2,
          supportCenter: 0,
          discord: 12,
          total: 14,
        },
        "2024-04-09": {
          widget: 4,
          supportCenter: 0,
          discord: 6,
          total: 10,
        },
        "2024-04-10": {
          widget: 3,
          supportCenter: 1,
          discord: 6,
          total: 10,
        },
        "2024-04-11": {
          widget: 4,
          supportCenter: 0,
          discord: 6,
          total: 10,
        },
        "2024-04-12": {
          widget: 3,
          supportCenter: 0,
          discord: 2,
          total: 5,
        },
        "2024-04-13": {
          widget: 6,
          supportCenter: 0,
          discord: 2,
          total: 8,
        },
        "2024-04-14": {
          widget: 4,
          supportCenter: 1,
          discord: 2,
          total: 7,
        },
        "2024-04-15": {
          widget: 5,
          supportCenter: 1,
          discord: 3,
          total: 9,
        },
        "2024-04-16": {
          widget: 2,
          supportCenter: 0,
          discord: 3,
          total: 5,
        },
        "2024-04-17": {
          widget: 2,
          supportCenter: 0,
          discord: 9,
          total: 11,
        },
        "2024-04-18": {
          widget: 1,
          supportCenter: 0,
          discord: 5,
          total: 6,
        },
        "2024-04-19": {
          widget: 3,
          supportCenter: 0,
          discord: 12,
          total: 15,
        },
        "2024-04-20": {
          widget: 5,
          supportCenter: 0,
          discord: 9,
          total: 14,
        },
        "2024-04-21": {
          widget: 6,
          supportCenter: 0,
          discord: 4,
          total: 10,
        },
        "2024-04-22": {
          widget: 12,
          supportCenter: 0,
          discord: 13,
          total: 25,
        },
        "2024-04-23": {
          widget: 5,
          supportCenter: 0,
          discord: 10,
          total: 15,
        },
        "2024-04-24": {
          widget: 13,
          supportCenter: 0,
          discord: 12,
          total: 25,
        },
        "2024-04-25": {
          widget: 5,
          supportCenter: 0,
          discord: 11,
          total: 16,
        },
        "2024-04-26": {
          widget: 6,
          supportCenter: 0,
          discord: 10,
          total: 16,
        },
        "2024-04-27": {
          widget: 9,
          supportCenter: 0,
          discord: 3,
          total: 12,
        },
        "2024-04-28": {
          widget: 0,
          supportCenter: 0,
          discord: 9,
          total: 9,
        },
        "2024-04-29": {
          widget: 7,
          supportCenter: 0,
          discord: 11,
          total: 18,
        },
        "2024-04-30": {
          widget: 3,
          supportCenter: 0,
          discord: 12,
          total: 15,
        },
        "2024-05-01": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-05-02": {
          widget: 4,
          supportCenter: 0,
          discord: 5,
          total: 9,
        },
        "2024-05-03": {
          widget: 6,
          supportCenter: 0,
          discord: 16,
          total: 22,
        },
        "2024-05-04": {
          widget: 8,
          supportCenter: 0,
          discord: 5,
          total: 13,
        },
        "2024-05-05": {
          widget: 2,
          supportCenter: 0,
          discord: 7,
          total: 9,
        },
        "2024-05-06": {
          widget: 3,
          supportCenter: 0,
          discord: 5,
          total: 8,
        },
        "2024-05-07": {
          widget: 6,
          supportCenter: 0,
          discord: 10,
          total: 16,
        },
        "2024-05-08": {
          widget: 5,
          supportCenter: 0,
          discord: 6,
          total: 11,
        },
        "2024-05-09": {
          widget: 11,
          supportCenter: 0,
          discord: 14,
          total: 25,
        },
        "2024-05-10": {
          widget: 10,
          supportCenter: 0,
          discord: 10,
          total: 20,
        },
        "2024-05-11": {
          widget: 14,
          supportCenter: 0,
          discord: 15,
          total: 29,
        },
        "2024-05-12": {
          widget: 6,
          supportCenter: 0,
          discord: 14,
          total: 20,
        },
        "2024-05-13": {
          widget: 5,
          supportCenter: 0,
          discord: 10,
          total: 15,
        },
        "2024-05-14": {
          widget: 9,
          supportCenter: 0,
          discord: 4,
          total: 13,
        },
        "2024-05-15": {
          widget: 2,
          supportCenter: 0,
          discord: 9,
          total: 11,
        },
        "2024-05-16": {
          widget: 5,
          supportCenter: 0,
          discord: 7,
          total: 12,
        },
        "2024-05-17": {
          widget: 5,
          supportCenter: 0,
          discord: 12,
          total: 17,
        },
        "2024-05-18": {
          widget: 12,
          supportCenter: 0,
          discord: 9,
          total: 21,
        },
        "2024-05-19": {
          widget: 10,
          supportCenter: 0,
          discord: 8,
          total: 18,
        },
        "2024-05-20": {
          widget: 10,
          supportCenter: 0,
          discord: 6,
          total: 16,
        },
        "2024-05-21": {
          widget: 9,
          supportCenter: 0,
          discord: 15,
          total: 24,
        },
        "2024-05-22": {
          widget: 7,
          supportCenter: 0,
          discord: 6,
          total: 13,
        },
        "2024-05-23": {
          widget: 9,
          supportCenter: 0,
          discord: 9,
          total: 18,
        },
        "2024-05-24": {
          widget: 3,
          supportCenter: 0,
          discord: 7,
          total: 10,
        },
        "2024-05-25": {
          widget: 6,
          supportCenter: 0,
          discord: 2,
          total: 8,
        },
        "2024-05-26": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-05-27": {
          widget: 3,
          supportCenter: 0,
          discord: 6,
          total: 9,
        },
        "2024-05-28": {
          widget: 4,
          supportCenter: 0,
          discord: 3,
          total: 7,
        },
        "2024-05-29": {
          widget: 5,
          supportCenter: 0,
          discord: 8,
          total: 13,
        },
        "2024-05-30": {
          widget: 2,
          supportCenter: 0,
          discord: 5,
          total: 7,
        },
        "2024-05-31": {
          widget: 6,
          supportCenter: 0,
          discord: 3,
          total: 9,
        },
        "2024-06-01": {
          widget: 5,
          supportCenter: 0,
          discord: 7,
          total: 12,
        },
        "2024-06-02": {
          widget: 2,
          supportCenter: 0,
          discord: 4,
          total: 6,
        },
        "2024-06-03": {
          widget: 3,
          supportCenter: 0,
          discord: 8,
          total: 11,
        },
        "2024-06-04": {
          widget: 5,
          supportCenter: 0,
          discord: 1,
          total: 6,
        },
        "2024-06-05": {
          widget: 4,
          supportCenter: 0,
          discord: 7,
          total: 11,
        },
        "2024-06-06": {
          widget: 6,
          supportCenter: 0,
          discord: 10,
          total: 16,
        },
        "2024-06-07": {
          widget: 5,
          supportCenter: 0,
          discord: 7,
          total: 12,
        },
        "2024-06-08": {
          widget: 3,
          supportCenter: 0,
          discord: 5,
          total: 8,
        },
        "2024-06-09": {
          widget: 6,
          supportCenter: 0,
          discord: 3,
          total: 9,
        },
        "2024-06-10": {
          widget: 3,
          supportCenter: 0,
          discord: 8,
          total: 11,
        },
        "2024-06-11": {
          widget: 4,
          supportCenter: 0,
          discord: 3,
          total: 7,
        },
        "2024-06-12": {
          widget: 4,
          supportCenter: 0,
          discord: 7,
          total: 11,
        },
        "2024-06-13": {
          widget: 8,
          supportCenter: 0,
          discord: 5,
          total: 13,
        },
        "2024-06-14": {
          widget: 8,
          supportCenter: 0,
          discord: 9,
          total: 17,
        },
        "2024-06-15": {
          widget: 4,
          supportCenter: 0,
          discord: 6,
          total: 10,
        },
        "2024-06-16": {
          widget: 3,
          supportCenter: 0,
          discord: 6,
          total: 9,
        },
        "2024-06-17": {
          widget: 2,
          supportCenter: 0,
          discord: 2,
          total: 4,
        },
        "2024-06-18": {
          widget: 2,
          supportCenter: 0,
          discord: 6,
          total: 8,
        },
        "2024-06-19": {
          widget: 6,
          supportCenter: 0,
          discord: 6,
          total: 12,
        },
        "2024-06-20": {
          widget: 7,
          supportCenter: 0,
          discord: 3,
          total: 10,
        },
        "2024-06-21": {
          widget: 5,
          supportCenter: 0,
          discord: 8,
          total: 13,
        },
        "2024-06-22": {
          widget: 10,
          supportCenter: 0,
          discord: 6,
          total: 16,
        },
        "2024-06-23": {
          widget: 8,
          supportCenter: 0,
          discord: 2,
          total: 10,
        },
        "2024-06-24": {
          widget: 2,
          supportCenter: 0,
          discord: 3,
          total: 5,
        },
        "2024-06-25": {
          widget: 10,
          supportCenter: 0,
          discord: 16,
          total: 26,
        },
        "2024-06-26": {
          widget: 10,
          supportCenter: 0,
          discord: 10,
          total: 20,
        },
        "2024-06-27": {
          widget: 5,
          supportCenter: 0,
          discord: 8,
          total: 13,
        },
        "2024-06-28": {
          widget: 5,
          supportCenter: 0,
          discord: 10,
          total: 15,
        },
        "2024-06-29": {
          widget: 1,
          supportCenter: 0,
          discord: 4,
          total: 5,
        },
        "2024-06-30": {
          widget: 1,
          supportCenter: 0,
          discord: 6,
          total: 7,
        },
        "2024-07-01": {
          widget: 2,
          supportCenter: 0,
          discord: 13,
          total: 15,
        },
        "2024-07-02": {
          widget: 2,
          supportCenter: 0,
          discord: 2,
          total: 4,
        },
        "2024-07-03": {
          widget: 3,
          supportCenter: 0,
          discord: 2,
          total: 5,
        },
        "2024-07-04": {
          widget: 5,
          supportCenter: 0,
          discord: 3,
          total: 8,
        },
        "2024-07-05": {
          widget: 6,
          supportCenter: 0,
          discord: 9,
          total: 15,
        },
        "2024-07-06": {
          widget: 4,
          supportCenter: 0,
          discord: 10,
          total: 14,
        },
        "2024-07-07": {
          widget: 2,
          supportCenter: 0,
          discord: 3,
          total: 5,
        },
        "2024-07-08": {
          widget: 2,
          supportCenter: 0,
          discord: 7,
          total: 9,
        },
        "2024-07-09": {
          widget: 6,
          supportCenter: 0,
          discord: 7,
          total: 13,
        },
        "2024-07-10": {
          widget: 1,
          supportCenter: 0,
          discord: 6,
          total: 7,
        },
        "2024-07-11": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-07-12": {
          widget: 1,
          supportCenter: 0,
          discord: 3,
          total: 4,
        },
        "2024-07-13": {
          widget: 2,
          supportCenter: 0,
          discord: 4,
          total: 6,
        },
        "2024-07-14": {
          widget: 0,
          supportCenter: 0,
          discord: 4,
          total: 4,
        },
        "2024-07-15": {
          widget: 4,
          supportCenter: 0,
          discord: 2,
          total: 6,
        },
        "2024-07-16": {
          widget: 7,
          supportCenter: 0,
          discord: 6,
          total: 13,
        },
        "2024-07-17": {
          widget: 4,
          supportCenter: 0,
          discord: 3,
          total: 7,
        },
        "2024-07-18": {
          widget: 0,
          supportCenter: 0,
          discord: 3,
          total: 3,
        },
        "2024-07-19": {
          widget: 4,
          supportCenter: 0,
          discord: 6,
          total: 10,
        },
        "2024-07-20": {
          widget: 6,
          supportCenter: 0,
          discord: 5,
          total: 11,
        },
        "2024-07-21": {
          widget: 7,
          supportCenter: 0,
          discord: 2,
          total: 9,
        },
        "2024-07-22": {
          widget: 2,
          supportCenter: 0,
          discord: 5,
          total: 7,
        },
        "2024-07-23": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-07-24": {
          widget: 5,
          supportCenter: 0,
          discord: 2,
          total: 7,
        },
        "2024-07-25": {
          widget: 5,
          supportCenter: 0,
          discord: 7,
          total: 12,
        },
        "2024-07-26": {
          widget: 1,
          supportCenter: 0,
          discord: 2,
          total: 3,
        },
        "2024-07-27": {
          widget: 1,
          supportCenter: 0,
          discord: 3,
          total: 4,
        },
        "2024-07-28": {
          widget: 3,
          supportCenter: 0,
          discord: 2,
          total: 5,
        },
        "2024-07-29": {
          widget: 6,
          supportCenter: 0,
          discord: 4,
          total: 10,
        },
        "2024-07-30": {
          widget: 2,
          supportCenter: 0,
          discord: 12,
          total: 14,
        },
        "2024-07-31": {
          widget: 4,
          supportCenter: 0,
          discord: 1,
          total: 5,
        },
        "2024-08-01": {
          widget: 1,
          supportCenter: 0,
          discord: 5,
          total: 6,
        },
        "2024-08-02": {
          widget: 4,
          supportCenter: 0,
          discord: 8,
          total: 12,
        },
        "2024-08-03": {
          widget: 4,
          supportCenter: 0,
          discord: 4,
          total: 8,
        },
        "2024-08-04": {
          widget: 1,
          supportCenter: 0,
          discord: 4,
          total: 5,
        },
        "2024-08-05": {
          widget: 3,
          supportCenter: 0,
          discord: 5,
          total: 8,
        },
        "2024-08-06": {
          widget: 4,
          supportCenter: 0,
          discord: 5,
          total: 9,
        },
        "2024-08-07": {
          widget: 2,
          supportCenter: 0,
          discord: 4,
          total: 6,
        },
        "2024-08-08": {
          widget: 3,
          supportCenter: 0,
          discord: 11,
          total: 14,
        },
        "2024-08-09": {
          widget: 5,
          supportCenter: 0,
          discord: 5,
          total: 10,
        },
        "2024-08-10": {
          widget: 1,
          supportCenter: 0,
          discord: 1,
          total: 2,
        },
        "2024-08-11": {
          widget: 1,
          supportCenter: 0,
          discord: 1,
          total: 2,
        },
        "2024-08-12": {
          widget: 2,
          supportCenter: 0,
          discord: 5,
          total: 7,
        },
        "2024-08-13": {
          widget: 1,
          supportCenter: 0,
          discord: 4,
          total: 5,
        },
        "2024-08-14": {
          widget: 4,
          supportCenter: 0,
          discord: 7,
          total: 11,
        },
        "2024-08-15": {
          widget: 1,
          supportCenter: 0,
          discord: 5,
          total: 6,
        },
        "2024-08-16": {
          widget: 1,
          supportCenter: 0,
          discord: 5,
          total: 6,
        },
        "2024-08-17": {
          widget: 2,
          supportCenter: 0,
          discord: 2,
          total: 4,
        },
        "2024-08-18": {
          widget: 4,
          supportCenter: 0,
          discord: 2,
          total: 6,
        },
        "2024-08-19": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-08-20": {
          widget: 2,
          supportCenter: 0,
          discord: 2,
          total: 4,
        },
        "2024-08-21": {
          widget: 2,
          supportCenter: 0,
          discord: 3,
          total: 5,
        },
        "2024-08-22": {
          widget: 3,
          supportCenter: 0,
          discord: 3,
          total: 6,
        },
        "2024-08-23": {
          widget: 1,
          supportCenter: 0,
          discord: 6,
          total: 7,
        },
        "2024-08-24": {
          widget: 3,
          supportCenter: 0,
          discord: 6,
          total: 9,
        },
        "2024-08-25": {
          widget: 4,
          supportCenter: 0,
          discord: 1,
          total: 5,
        },
        "2024-08-26": {
          widget: 4,
          supportCenter: 0,
          discord: 1,
          total: 5,
        },
        "2024-08-27": {
          widget: 1,
          supportCenter: 0,
          discord: 4,
          total: 5,
        },
        "2024-08-28": {
          widget: 4,
          supportCenter: 0,
          discord: 2,
          total: 6,
        },
        "2024-08-29": {
          widget: 5,
          supportCenter: 0,
          discord: 1,
          total: 6,
        },
        "2024-08-30": {
          widget: 1,
          supportCenter: 0,
          discord: 3,
          total: 4,
        },
        "2024-08-31": {
          widget: 2,
          supportCenter: 0,
          discord: 3,
          total: 5,
        },
        "2024-09-01": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-09-02": {
          widget: 3,
          supportCenter: 0,
          discord: 4,
          total: 7,
        },
        "2024-09-03": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-09-04": {
          widget: 7,
          supportCenter: 0,
          discord: 11,
          total: 18,
        },
        "2024-09-05": {
          widget: 8,
          supportCenter: 0,
          discord: 4,
          total: 12,
        },
        "2024-09-06": {
          widget: 4,
          supportCenter: 0,
          discord: 6,
          total: 10,
        },
        "2024-09-07": {
          widget: 1,
          supportCenter: 0,
          discord: 7,
          total: 8,
        },
        "2024-09-08": {
          widget: 4,
          supportCenter: 0,
          discord: 5,
          total: 9,
        },
        "2024-09-09": {
          widget: 1,
          supportCenter: 0,
          discord: 7,
          total: 8,
        },
        "2024-09-10": {
          widget: 6,
          supportCenter: 0,
          discord: 1,
          total: 7,
        },
        "2024-09-11": {
          widget: 6,
          supportCenter: 0,
          discord: 4,
          total: 10,
        },
        "2024-09-12": {
          widget: 3,
          supportCenter: 0,
          discord: 3,
          total: 6,
        },
        "2024-09-13": {
          widget: 7,
          supportCenter: 0,
          discord: 8,
          total: 15,
        },
        "2024-09-14": {
          widget: 7,
          supportCenter: 0,
          discord: 6,
          total: 13,
        },
        "2024-09-15": {
          widget: 3,
          supportCenter: 0,
          discord: 3,
          total: 6,
        },
        "2024-09-16": {
          widget: 6,
          supportCenter: 0,
          discord: 7,
          total: 13,
        },
        "2024-09-17": {
          widget: 13,
          supportCenter: 0,
          discord: 12,
          total: 25,
        },
        "2024-09-18": {
          widget: 8,
          supportCenter: 0,
          discord: 24,
          total: 32,
        },
        "2024-09-19": {
          widget: 7,
          supportCenter: 0,
          discord: 16,
          total: 23,
        },
        "2024-09-20": {
          widget: 15,
          supportCenter: 0,
          discord: 6,
          total: 21,
        },
        "2024-09-21": {
          widget: 13,
          supportCenter: 0,
          discord: 0,
          total: 13,
        },
        "2024-09-22": {
          widget: 18,
          supportCenter: 0,
          discord: 0,
          total: 18,
        },
        "2024-09-23": {
          widget: 9,
          supportCenter: 0,
          discord: 10,
          total: 19,
        },
        "2024-09-24": {
          widget: 8,
          supportCenter: 0,
          discord: 9,
          total: 17,
        },
        "2024-09-25": {
          widget: 25,
          supportCenter: 0,
          discord: 23,
          total: 48,
        },
        "2024-09-26": {
          widget: 21,
          supportCenter: 0,
          discord: 11,
          total: 32,
        },
        "2024-09-27": {
          widget: 16,
          supportCenter: 0,
          discord: 12,
          total: 28,
        },
        "2024-09-28": {
          widget: 15,
          supportCenter: 0,
          discord: 7,
          total: 22,
        },
        "2024-09-29": {
          widget: 5,
          supportCenter: 0,
          discord: 4,
          total: 9,
        },
        "2024-09-30": {
          widget: 10,
          supportCenter: 0,
          discord: 11,
          total: 21,
        },
        "2024-10-01": {
          widget: 18,
          supportCenter: 0,
          discord: 10,
          total: 28,
        },
        "2024-10-02": {
          widget: 8,
          supportCenter: 0,
          discord: 10,
          total: 18,
        },
        "2024-10-03": {
          widget: 4,
          supportCenter: 0,
          discord: 5,
          total: 9,
        },
        "2024-10-04": {
          widget: 5,
          supportCenter: 0,
          discord: 3,
          total: 8,
        },
        "2024-10-05": {
          widget: 8,
          supportCenter: 0,
          discord: 1,
          total: 9,
        },
        "2024-10-06": {
          widget: 7,
          supportCenter: 0,
          discord: 5,
          total: 12,
        },
        "2024-10-07": {
          widget: 11,
          supportCenter: 0,
          discord: 11,
          total: 22,
        },
        "2024-10-08": {
          widget: 3,
          supportCenter: 0,
          discord: 5,
          total: 8,
        },
        "2024-10-09": {
          widget: 10,
          supportCenter: 0,
          discord: 4,
          total: 14,
        },
        "2024-10-10": {
          widget: 2,
          supportCenter: 0,
          discord: 7,
          total: 9,
        },
        "2024-10-11": {
          widget: 9,
          supportCenter: 0,
          discord: 6,
          total: 15,
        },
        "2024-10-12": {
          widget: 7,
          supportCenter: 0,
          discord: 5,
          total: 12,
        },
        "2024-10-13": {
          widget: 6,
          supportCenter: 0,
          discord: 4,
          total: 10,
        },
        "2024-10-14": {
          widget: 3,
          supportCenter: 0,
          discord: 3,
          total: 6,
        },
      },
    },
  },
};
export const agentMetrics = {
  agentMetrics: [
    {
      agentId: "643f9a457a4127b42f649925",
      agentName: "Demo1",
      agentType: null,
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 121,
        done: 2370,
      },
      avgFirstResponseTime: 62721142.66800482,
      avgResolveTime: 4839484448.143038,
      agentCsat: 3.64,
      csatRatingPercent: 0.09,
    },
    {
      agentId: null,
      agentType: null,
      platform: "all",
      ticketCount: {
        new: 461,
        ongoing: 0,
        done: 537,
      },
      avgFirstResponseTime: 0,
      avgResolveTime: 2445010980.802607,
      agentCsat: 3.15,
      csatRatingPercent: 0.16,
    },
    {
      agentId: "66065cefeb3ead2390cd64ee",
      agentName: "Demo2",
      agentType: null,
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 45,
        done: 177,
      },
      avgFirstResponseTime: 64101218.9054054,
      avgResolveTime: 4606954858.519774,
      agentCsat: 4,
      csatRatingPercent: 0.08,
    },
    {
      agentId: "970881151495454792",
      agentName: "discordAgent0#0",
      agentType: "discord",
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 44,
        done: 176,
      },
      avgFirstResponseTime: 363449322.2681818,
      avgResolveTime: 3633126822.6022725,
      agentCsat: 5,
      csatRatingPercent: 0.03,
    },
    {
      agentId: "6438b0d67e87a964a83cfd76",
      agentName: "Demo3",
      agentType: null,
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 3,
        done: 74,
      },
      avgFirstResponseTime: 409068002.35064936,
      avgResolveTime: 4953713932.2972975,
      agentCsat: 4,
      csatRatingPercent: 0.03,
    },
    {
      agentId: "66b9976dc57163c9acee0def",
      agentName: "Demo4",
      agentType: null,
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 28,
        done: 18,
      },
      avgFirstResponseTime: 113372448.95652173,
      avgResolveTime: 1392908874.3333333,
      agentCsat: 4,
      csatRatingPercent: 0.11,
    },
    {
      agentId: "643f9a457a4127b42f649928",
      agentName: "Demo5",
      agentType: null,
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 0,
        done: 13,
      },
      avgFirstResponseTime: 72659288,
      avgResolveTime: 274383583.38461536,
      agentCsat: null,
      csatRatingPercent: null,
    },
    {
      agentId: "1048463918370127902",
      agentName: "discordAgent#0",
      agentType: "discord",
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 1,
        done: 13,
      },
      avgFirstResponseTime: 21477175.714285713,
      avgResolveTime: 2435776322.3846154,
      agentCsat: 3.88,
      csatRatingPercent: 0.8,
    },
    {
      agentId: "802461102653833216",
      agentName: "discordAgent1#0",
      agentType: "discord",
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 3,
        done: 7,
      },
      avgFirstResponseTime: 14772208.2,
      avgResolveTime: 81190118.85714285,
      agentCsat: 5,
      csatRatingPercent: 0.5,
    },
    {
      agentId: "844993298757189714",
      agentName: "discordAgent2#0",
      agentType: "discord",
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 3,
        done: 2,
      },
      avgFirstResponseTime: 7325236,
      avgResolveTime: 12764272,
      agentCsat: 5,
      csatRatingPercent: 1,
    },
    {
      agentId: "885334835932057620",
      agentName: "discordAgent3#0",
      agentType: "discord",
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 0,
        done: 1,
      },
      avgFirstResponseTime: 64696694,
      avgResolveTime: 1318849634,
      agentCsat: null,
      csatRatingPercent: null,
    },
    {
      agentId: "852111966045470721",
      agentName: "discordAgent4#0",
      agentType: "discord",
      platform: "all",
      ticketCount: {
        new: 0,
        ongoing: 0,
        done: 1,
      },
      avgFirstResponseTime: 444929,
      avgResolveTime: 6019585,
      agentCsat: null,
      csatRatingPercent: null,
    },
  ],
};
