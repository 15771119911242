import React from "react";
import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useIntl } from "react-intl";
import Button, {
  COLOR,
  VARIANT,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { th } from "date-fns/locale";
import { StyledStep1ListItem } from "./TelegramSetting.styles";
import { useTheme } from "@mui/styles";
import useNavigateEntity from "hooks/useNavigateEntity";

const TelegramSettingStep1 = ({ onNextStep }) => {
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const handleBack = () => {
    navigateEntity("/settings/general/integration");
  };

  const handleOpenTelegramBotFather = () => {
    window.open(
      "https://telegram.me/botfather",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Box>
      <Box component={Grid} container>
        <Box component={Grid} item xs={12} sm={6}>
          <Box>
            <Box
              fontSize={"24px"}
              fontWeight={"700"}
              color={(theme) => theme.customColors.grey[700]}
              mb="8px"
            >
              {formatMessage({ id: "telegramBot.integrationWizard.title" })}
            </Box>

            <Box color={(theme) => theme.customColors.grey[600]} mb="32px">
              {formatMessage({
                id: "telegramBot.integrationWizard.description",
              })}
            </Box>

            <Box
              color={(theme) => theme.customColors.grey[700]}
              fontSize={"16px"}
              mb="24px"
            >
              {formatMessage({
                id: "telegramBot.integrationWizard.step1.subtitle",
              })}
            </Box>

            <StyledStep1ListItem>
              1.
              {formatMessage({
                id: "telegramBot.integrationWizard.step1.instruction1",
              })}
              <Box mt="8px" mb="12px">
                <Button
                  variant={VARIANT.OUTLINED}
                  size={SIZE.SMALL}
                  onClick={handleOpenTelegramBotFather}
                >
                  BotFather
                </Button>
              </Box>
            </StyledStep1ListItem>

            <StyledStep1ListItem>
              2.
              {formatMessage({
                id: "telegramBot.integrationWizard.step1.instruction2",
              })}
            </StyledStep1ListItem>

            <StyledStep1ListItem>
              3.
              {formatMessage({
                id: "telegramBot.integrationWizard.step1.instruction3",
              })}
            </StyledStep1ListItem>

            <StyledStep1ListItem>
              4.
              {formatMessage({
                id: "telegramBot.integrationWizard.step1.instruction4",
              })}
            </StyledStep1ListItem>

            <StyledStep1ListItem>
              5.
              {formatMessage({
                id: "telegramBot.integrationWizard.step1.instruction5",
              })}
            </StyledStep1ListItem>
          </Box>
          <Box mt="50px">
            <Button
              variant={VARIANT.OUTLINED}
              sx={{ width: "120px", marginRight: "8px" }}
              onClick={handleBack}
            >
              {formatMessage({ id: "global.btn.back" })}
            </Button>
            <Button sx={{ width: "120px" }} onClick={onNextStep}>
              {formatMessage({ id: "global.btn.next" })}
            </Button>
          </Box>
        </Box>
        <Box component={Grid} item xs={12} sm={6}>
          <Box sx={{ backgroundColor: "#FAFAFD", padding: "17px 13px" }}>
            <Box
              sx={{
                fontWeight: "700",
                color: theme.customColors.grey[700],
                marginBottom: "32px",
              }}
            >
              <Box
                sx={{
                  color: theme.customColors.purple[500],
                  display: "inline-block",
                }}
              >
                {" "}
                img 01
              </Box>{" "}
              . Hit the /Start button within Telegram：
            </Box>
            <Box
              sx={{ margin: "0 auto", display: "block" }}
              component={"img"}
              src={require("assets/img/tg-setting-step1.png")}
              width={"279px"}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TelegramSettingStep1;
