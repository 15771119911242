import React, { useState } from "react";
import useOutsideClick from "../../../campaign/campaignDetailPerformance/hook/useOutsideClick";
import { Box } from "@mui/material";
import {
  StyledDropDownContainer,
  StyledDropDownBtn,
  StyledDropDownText,
} from "./integrationComponent.styles";

export default function SortDropDown({ setSortCondition }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  const sortList = ["Name", "Status", "Last Updated"];
  const orderList = ["Ascending", "Descending"];

  const [sortKey, setSortKey] = useState("");
  const [order, setOrder] = useState(orderList[0]);

  const handleReset = () => {
    setSortKey("");
    setOrder(orderList[0]);
    setSortCondition("", orderList[0]);
  };

  const handleSortChange = (key) => {
    setSortKey(key);
    setSortCondition(key, order);
  };

  const handleOrderChange = (orderKey) => {
    setOrder(orderKey);
    setSortCondition(sortKey, orderKey);
  };

  return (
    <Box ref={ref} position="relative">
      <StyledDropDownBtn
        onClick={() => setIsOpen(true)}
        active={isOpen || sortKey}
      >
        <i className="meta-crm-icon-ic_sort font-size-16" />
        Sort
      </StyledDropDownBtn>
      {isOpen && (
        <StyledDropDownContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            height="24px"
            alignItems="center"
            fontSize="10px"
          >
            <Box color="#A5A5A5">Sort by</Box>
            <Box
              color="#7B61FF"
              sx={{ cursor: "pointer" }}
              onClick={handleReset}
            >
              Reset
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "#E5E5E5",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <Box fontSize={12} color="#555">
            {sortList.map((item, index) => (
              <StyledDropDownText
                key={index}
                onClick={() => handleSortChange(item)}
              >
                {item}
                {sortKey === item && (
                  <i
                    className="meta-crm-icon-ic_check font-size-24"
                    style={{ color: "#7B61FF" }}
                  />
                )}
              </StyledDropDownText>
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "#E5E5E5",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <Box fontSize={12} color="#555">
            {orderList.map((item, index) => (
              <StyledDropDownText
                key={index}
                onClick={() => handleOrderChange(item)}
              >
                <Box>{item}</Box>
                {order === item && (
                  <i
                    className="meta-crm-icon-ic_check font-size-24"
                    style={{ color: "#7B61FF" }}
                  />
                )}
              </StyledDropDownText>
            ))}
          </Box>
        </StyledDropDownContainer>
      )}
    </Box>
  );
}
