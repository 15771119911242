import * as React from "react";
import { Draggable } from "react-beautiful-dnd";

import { makeStyles } from "@mui/styles";
import _ from "lodash";
import {
  StyledMenuItem,
  StyledDragHandlerBarArea,
} from "./DraggableList.styles";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import { Box } from "@mui/material";
import IconIcPin from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcPin";

const useStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

const DraggableListItem = ({
  item,
  index,
  children,
  onShowOrHide,
  ...otherProps
}) => {
  const classes = useStyles();

  const handleHide = (event) => {
    event.stopPropagation();
    onShowOrHide({ ...item, show: false });
  };

  const alwaysShow = [
    "Wallet Value",
    "NFT Amount",
    "Tags",
    "Sources",
    "Last Engagement",
  ];

  return (
    <>
      {item.fixed ? (
        <StyledMenuItem
          {...otherProps}
          className="shown-item"
          isFixed={item.fixed}
        >
          {item.title}
          <IconIcPin
            className="smIcon"
            style={{
              borderRadius: 20,
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
          />
        </StyledMenuItem>
      ) : (
        <Draggable draggableId={item.id} index={index}>
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={snapshot.isDragging ? classes.draggingListItem : ""}
              >
                <StyledMenuItem {...otherProps} isFixed={item.fixed}>
                  {alwaysShow.includes(item.title) ? (
                    <>{item.title}</>
                  ) : (
                    <Checkbox
                      value={item}
                      checked={true}
                      onChange={(event) => handleHide(event)}
                      label={
                        <Box ml="4px">
                          {_.truncate(item.title, { length: 20 })}
                        </Box>
                      }
                      controlStyle={{ width: "100%" }}
                    />
                  )}
                  <StyledDragHandlerBarArea>
                    <i
                      {...provided.dragHandleProps}
                      className="meta-crm-icon-ic_columns font-size-16"
                    />
                  </StyledDragHandlerBarArea>
                </StyledMenuItem>
              </div>
            );
          }}
        </Draggable>
      )}
    </>
  );
};

export default DraggableListItem;
