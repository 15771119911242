import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";

const GithubCallback = () => {
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const [authPending, setAuthPending] = useState(true);
  const [msg, setMsg] = useState("");
  useEffect(async () => {
    const query = queryString.parse(_.get(location, "search"));
    const status = _.get(query, "status");
    const errorMessage = _.get(query, "errorMessage");
    if (status == "failed") {
      setMsg(errorMessage);
      setAuthPending(false);
    } else if (status == "success") {
      setIsAuth(true);
      setAuthPending(false);
    }
  }, [location]);

  useEffect(() => {
    if (!authPending && isAuth) {
      window.close();
    }
  }, [authPending, isAuth]);

  if (authPending) {
    return (
      <div className="column" style={{ height: "100vh" }}>
        <LoadingIcon className="lgIcon" />
      </div>
    );
  } else if (isAuth) {
    return (
      <div className="column" style={{ height: "100vh" }}>
        <img
          src={require("assets/img/check2.svg").default}
          className="lgIcon"
        />
        <div className="title">Success!</div>
        <div className="description"> Now you can close this tab.</div>
      </div>
    );
  } else {
    return (
      <div className="column" style={{ height: "100vh" }}>
        <div className="title">Fail!</div>
        <div className="description">{msg}</div>
      </div>
    );
  }
};

export default GithubCallback;
