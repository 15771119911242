import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Popover from "@mui/material/Popover";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

export const StyledPopover = styled(Popover, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "120px",
  },
}));

export const StyledInputArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "5px",
}));

export const StyledInputField = styled(InputField, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "20px",

    "& .MuiInputBase-input": {
      fontSize: "12px",
    },
  },
}));

export const StyledDeleteArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "7px 5px",
  color: theme.customColors.grey[700],
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 700,

  "& i": {
    color: theme.customColors.grey[500],
    marginRight: "5px",
  },
}));

export const StyledLine = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "1px",
  backgroundColor: theme.customColors.grey[700],
  marginBottom: "3px",
}));

export const StyledPickColorArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledPickColorAreaTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[500],
  fontSize: "12px",
  padding: "5px",
  fontWeight: 700,
}));

export const StyledPalette = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingLeft: "7px",
  paddingRight: "7px",
  paddingBottom: "7px",
}));

export const StyledSingleColor = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color }) => ({
  width: "20px",
  height: "20px",
  backgroundColor: color,
  cursor: "pointer",
}));
