import React, { useState, useEffect, useRef, forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import _ from "lodash";
import Box from "@mui/material/Box";

import MultipleValueTag from "../Tag/MultipleValueTag";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  StyledRoot,
  StyledInputWrapper,
  StyledOptionLabel,
  StyledSelectItemMoreBtn,
  StyledListbox,
  StyledArrowDropDownIcon,
  StyledExplain,
  StyledAddNewOptionInputContainer,
  StyleRemainNumber,
  StyledInput,
  StyledPopper,
  StyledPaper,
} from "./ComplexDropdown.styles";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import EditPopover from "./EditPopover/EditPopover";

const ComplexDropdown = forwardRef(
  (
    {
      multiple,
      onEnter,
      onClose,
      dropdownOptions,
      onEditOptionItem,
      onDeleteItem,
      onPickOptionColor,
      onChange,
      onOpen,
      onClick,
      onValue,
      onDefaultValue,
      limitTags,
      selectAreaHeight,
      sx,
      placeholder,
    },
    ref
  ) => {
    const {
      getRootProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      value,
      focused,
      anchorEl,
      setAnchorEl,
    } = useAutocomplete({
      id: "customized-hook-demo",
      multiple: multiple,
      options: dropdownOptions,
      getOptionLabel: (option) => (!_.isEmpty(option) ? option.label : ""),
      open: onOpen,
      value: onValue,
      defaultValue: onDefaultValue,
      onChange: (event, value) => onChange(value),
      isOptionEqualToValue: (option, value) => option.label === value.label,
    });

    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);

    const handleOpenEditPopover = (event, option) => {
      event.stopPropagation();
      setEditAnchorEl(event.currentTarget);
      setSelectedTag({ target: event.currentTarget, option: option });
    };

    const handleCloseEditPopover = () => {
      setEditAnchorEl(null);
      setSelectedTag(null);
    };

    const editPopoverOpen = Boolean(editAnchorEl);
    const id = editPopoverOpen ? "simple-popover" : undefined;

    const handleKeyDown = (event) => {
      const { keyCode, target } = event;
      if (keyCode === 13) {
        handleSetForNewOption(target.value);
      }
    };

    const handleSetForNewOption = (value) => {
      if (value.trim() && onEnter) {
        onEnter(value);
        // setCreateNewOptionValue("");
      }
    };

    const renderTags = (getTagProps) => {
      const numTags = value.length;

      if (onOpen) {
        return (
          <>
            {value.map((option, index) => (
              <MultipleValueTag
                option={option}
                label={_.get(option, "label")}
                {...getTagProps({ index })}
              />
            ))}
          </>
        );
      } else {
        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <MultipleValueTag
                option={option}
                label={_.get(option, "label")}
                {...getTagProps({ index })}
              />
            ))}

            {numTags > limitTags && (
              <StyleRemainNumber>{`+${numTags - limitTags}`}</StyleRemainNumber>
            )}
          </>
        );
      }
    };

    return (
      <ClickAwayListener onClickAway={onClose}>
        <StyledRoot onClick={onClick} sx={sx} ref={ref}>
          <div {...getRootProps()}>
            <StyledInputWrapper
              ref={setAnchorEl}
              className={focused ? "focused" : ""}
            >
              <Box display="flex" flexWrap="wrap" alignItems="center" flex="1">
                {multiple && renderTags(getTagProps)}

                <StyledInput
                  placeholder={_.isEmpty(value) ? placeholder : ""}
                  {...getInputProps()}
                  onKeyDown={handleKeyDown}
                />
              </Box>
              <IconButton size={20}>
                <StyledArrowDropDownIcon
                  open={onOpen}
                ></StyledArrowDropDownIcon>
              </IconButton>
            </StyledInputWrapper>
          </div>
          <>
            {onOpen && (
              <StyledPopper
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                placement="bottom-start"
                style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
              >
                <StyledPaper elevation={1}>
                  <StyledListbox
                    selectAreaHeight={selectAreaHeight}
                    {...getListboxProps()}
                  >
                    <StyledExplain>
                      Select an option or create one
                    </StyledExplain>
                    {groupedOptions.length > 0
                      ? groupedOptions.map((option, index) => (
                          <li {...getOptionProps({ option, index })}>
                            <StyledOptionLabel backgroundColor={option.color}>
                              {option.label}
                            </StyledOptionLabel>
                            <StyledSelectItemMoreBtn
                              onClick={(event) =>
                                handleOpenEditPopover(event, option)
                              }
                            >
                              <MoreHorizIcon />
                            </StyledSelectItemMoreBtn>
                          </li>
                        ))
                      : null}
                  </StyledListbox>
                </StyledPaper>
              </StyledPopper>
            )}

            <EditPopover
              id={id}
              open={editPopoverOpen}
              selectedTag={selectedTag}
              anchorEl={editAnchorEl}
              onClose={handleCloseEditPopover}
              onEditOptionItem={onEditOptionItem}
              onDeleteItem={onDeleteItem}
              onPickOptionColor={onPickOptionColor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            ></EditPopover>
          </>
        </StyledRoot>
      </ClickAwayListener>
    );
  }
);

ComplexDropdown.propTypes = {
  multiple: PropTypes.bool,
  onClose: PropTypes.func,
  selectAreaHeight: PropTypes.number,
};
ComplexDropdown.defaultProps = {
  multiple: true,
  onClose: () => {},
  selectAreaHeight: 250,
};

export default ComplexDropdown;
