import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  StyledDatePickerContainer,
  StyledDatePicker,
} from "./DateTimePicker.styles";
import Box from "@mui/material/Box";
import { isString } from "lodash-es";
import BasicDialog from "@metacrm/metacrm-material-ui/dist/Dialog";

const DateTimePicker = ({ value, onChange, confirm = true, ...otherProps }) => {
  // value不為空值 或是 不是 string 就直接回傳,  要是是字串 就要變成時間格式
  const formatValue = !value || !isString(value) ? value : new Date(value);
  const [isOpen, setIsOpen] = useState(false);
  const [tempDate, setTempDate] = useState(null); // 這個是用來暫存時間的
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChangeDate = (date) => {
    onChange(date);
    // if (!confirm) return onChange(date);
    // if (value) {
    //   setIsOpen(true);
    //   setTempDate(date);
    // } else {
    //   onChange(date);
    // }
  };

  const handleConfirm = () => {
    setIsOpen(false);
    onChange(tempDate);
    setTempDate(null);
  };

  return (
    <>
      <StyledDatePickerContainer>
        <StyledDatePicker
          selected={formatValue}
          onChange={handleChangeDate}
          {...otherProps}
        />
      </StyledDatePickerContainer>
      {/* <ConfirmChangeTimeDialog
        isOpen={isOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      /> */}
    </>
  );
};

const ConfirmChangeTimeDialog = ({ isOpen, handleClose, handleConfirm }) => {
  const { formatMessage } = useIntl();
  return (
    <BasicDialog
      dialogTitle={
        <p style={{ textAlign: "center" }}>
          {formatMessage({ id: "popup.confirm.changeSchedule" })}
        </p>
      }
      confirmText={formatMessage({ id: "global.btn.confirm" })}
      cancelText={formatMessage({ id: "global.btn.cancel" })}
      open={isOpen}
      onClose={handleClose}
      closeBtnShow={false}
      onHandleConfirm={handleConfirm}
      dialogRootStyle={{ maxWidth: "350px" }}
    />
  );
};

export default DateTimePicker;
