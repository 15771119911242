import React, { memo, useContext, useEffect, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import { Box, Grid, useTheme } from "@mui/material";
import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";

import useMarketModule from "hooks/useMarketModule";
import _ from "lodash";
import MetaCRMDateRange from "components/DateRange/MetaCRMDateRange";
import { useSelector } from "react-redux";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import moment from "moment";

function PerformanceHeader({ setOpenAudience }) {
  const { startDate, setStartDate, endDate, setEndDate, setSearch } =
    useContext(PerformanceContext);

  const { attributionConnect } = useMarketModule();
  const { formatMessage } = useIntl();
  const isDemoSite = useSelector(selectDemoSite);

  return (
    <>
      <div className={"title"}>Website Traffic</div>
      <div className={"description"}>
        You can observe and analyze the performance of any inbound traffic on
        the analytics page.
      </div>
      <div className="startRow" style={{ color: "#A5A5A5", margin: "8px 0" }}>
        <i className="meta-crm-icon-ic_info font-size-14 mgr4" />
        Data is displayed based on UTC+0 time zone.
      </div>

      <Box
        sx={{ alignItems: "center", bgcolor: "#fff", mt: 2, p: "6px 12px" }}
        className="betweenRow"
      >
        <Box></Box>
        <Box className="endRow">
          <MetaCRMDateRange
            custom
            isAllText
            minDate={moment().utc().startOf("day").subtract(89, "day").toDate()}
            defaultRange={isDemoSite ? "custom" : 0}
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
          {attributionConnect && (
            <Button
              size={SIZE.MEDIUM}
              onClick={() => {
                setOpenAudience(true);
              }}
              style={{ marginLeft: "8px" }}
            >
              {formatMessage({
                id: `global.btn.createAudience`,
              })}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

export default memo(PerformanceHeader);
