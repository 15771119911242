import React, { useState, useCallback } from "react";
import useGetPagePermission from "hooks/useGetPagePermission";
import AutoCompleteDropdown, {
  EditTagOptionPopover,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTagsAsyncAction,
  editTagsAsyncAction,
  postTagsAsyncAction,
} from "../redux/macro/macro.action";
import { useOutletContext } from "react-router";
import { Box } from "@mui/material";
import { selectMacroTags } from "../redux/macro/macro.selector";
import { parseToOptionFormat } from "../settings/Macros/CreateMacroModal/dropdown.utils";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";

export default function TicketTag({
  handleChooseTag,
  tags = [],
  canEdit,
  ...props
}) {
  const dispatch = useDispatch();
  const [entityName] = useOutletContext();
  const tagOptions = useSelector(selectMacroTags);

  const handleNewTag = (value) => {
    dispatch(
      postTagsAsyncAction({
        entityName: entityName,
        value: value,
        color: "#E6E6E6",
        selectedValue: tags,
        cb: handleChooseTag,
      })
    );
  };

  const handleEditTag = ({ value, target }) => {
    console.log(value, target);
    dispatch(
      editTagsAsyncAction({
        entityName: entityName,
        id: target._id,
        value: value,
        color: target.color,
        selectedValue: tags,
        cb: handleChooseTag,
      })
    );
    setEditAnchorEl(null);
  };

  const handleDeleteTagOption = ({ target }) => {
    dispatch(
      deleteTagsAsyncAction({
        entityName: entityName,
        id: target._id,
        selectedValue: tags,
        cb: handleChooseTag,
      })
    );
    setEditAnchorEl(null);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const editPopoverOpen = Boolean(editAnchorEl);
  const id = editPopoverOpen ? "simple-popover" : undefined;

  // const pagePermission = useGetPagePermission();
  // const { canEdit } = pagePermission("tickets");

  const handleOpenEditPopover = useCallback((event, option) => {
    setEditAnchorEl(event.currentTarget);
    setSelectedTag(option);
  }, []);

  const handleCloseEditPopover = () => {
    setEditAnchorEl(null);
    setSelectedTag(null);
  };

  function handleChange(event, value) {
    handleChooseTag(value);
  }

  return (
    <>
      <Box display="flex">
        <AutoCompleteDropdown
          multiple
          isTag
          placeholder="Add tags"
          value={tags}
          onHandleOption={canEdit ? handleOpenEditPopover : null}
          onChange={handleChange}
          open={dropdownOpen}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          onClose={() => setDropdownOpen(false)}
          options={parseToOptionFormat(tagOptions)}
          onEnter={(value, event) => {
            handleNewTag(value);
          }}
          {...props}
        />
        {canEdit && (
          <EditTagOptionPopover
            id={id}
            open={editPopoverOpen}
            selectedTag={selectedTag}
            anchorEl={editAnchorEl}
            onClose={handleCloseEditPopover}
            onEditOptionItem={handleEditTag}
            onDeleteItem={handleDeleteTagOption}
            onPickOptionColor={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          />
        )}
      </Box>
    </>
  );
}
