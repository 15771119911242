import { CONSTANTS } from "@pushprotocol/restapi";

const configs = {
  local: {
    apiUrl: "http://localhost:21667",
    socketIOUrl: "http://localhost:21667",
    supportCenterBaseUrl: "http://localhost:3001",
    portalUrl: "http://localhost:3000",
    widgetUrl: "https://dev.widget.metacrm.inc/static/js/",
    trackingUrl: "https://dev.attribution.metacrm.inc/",
    adamUrl: "http://localhost:8000",
    sessionNumOfHours: 24,
    allowedEntities: {
      billing: ["jeff"],
      userInfo: {
        radiant: ["radiant", "LendingDemo60", "jeff"],
        curve: ["curve", "demo139"],
        magpie: ["magpie"],
        azuki: ["azuki", "demo9", "demo257"],
      },
    },
    xmtpEnv: "dev",
    pushEnv: CONSTANTS.ENV.STAGING,
  },
  dev: {
    apiUrl: "https://api-dev.metacrm.inc",
    socketIOUrl: "https://api-dev.metacrm.inc",
    supportCenterBaseUrl: "https://dev.support.metacrm.inc",
    portalUrl: "https://dev.metacrm.inc",
    widgetUrl: "https://dev.widget.metacrm.inc/static/js/",
    trackingUrl: "https://dev.attribution.metacrm.inc/",
    adamUrl: "https://api.ai.metacrm.inc",
    sessionNumOfHours: 24,
    allowedEntities: {
      billing: ["standard"],
      userInfo: {
        radiant: ["radiant"],
        curve: ["curve"],
        magpie: ["magpie"],
        azuki: ["azuki"],
      },
    },
    xmtpEnv: "dev",
    pushEnv: CONSTANTS.ENV.STAGING,
  },

  demo: {
    apiUrl: "https://api-demo.metacrm.inc",
    socketIOUrl: "https://api-demo.metacrm.inc",
    supportCenterBaseUrl: "https://beta.support.metacrm.inc",
    portalUrl: "https://beta.metacrm.inc",
    widgetUrl: "https://beta.widget.metacrm.inc/static/js/",
    trackingUrl: "https://beta.attribution.metacrm.inc/",
    adamUrl: "https://api.ai.metacrm.inc",
    sessionNumOfHours: 24,
    allowedEntities: {
      billing: [],
      userInfo: {
        radiant: [
          "radiant",
          "LendingDemo60",
          "demo140",
          "demo141",
          "demo142",
          "demo143",
          "demo144",
          "demo145",
          "demo146",
          "demo147",
          "demo148",
          "demo149",
          "demo150",
          "demo151",
          "demo152",
          "demo153",
          "demo154",
          "demo155",
          "demo156",
          "demo157",
          "demo158",
          "demo159",
          "gigabyte",
          "demoJustin1",
          "demoJustin3",
          "demoJames1",
          "demoDeep1",
          "demoPeter1",
          "demoEnid1",
          "demoClient1",
          "movementdemo",
          "demodeep3",
          "demoenid3",
          "demomarketing3",
        ],
        curve: ["curve", "demo139"],
        magpie: ["magpie"],
        azuki: ["azuki", "demo9", "demo257"],
      },
    },
    xmtpEnv: "dev",
    pushEnv: CONSTANTS.ENV.STAGING,
  },
  production: {
    apiUrl: "https://api-desk.metacrm.inc",
    socketIOUrl: "https://api-desk.metacrm.inc",
    supportCenterBaseUrl: "https://support.metacrm.inc",
    portalUrl: "https://app.metacrm.inc",
    widgetUrl: "https://widget.metacrm.inc/static/js/",
    trackingUrl: "https://attribution.metacrm.inc/",
    adamUrl: "https://api.ai.metacrm.inc",
    sessionNumOfHours: 1,
    allowedEntities: {
      billing: ["metacrm"],
      userInfo: {
        radiant: ["radiant"],
        curve: ["curve"],
        magpie: ["magpie"],
        azuki: ["azuki"],
      },
    },
    xmtpEnv: "production",
    pushEnv: CONSTANTS.ENV.PROD,
  },
};

const environment = process.env.REACT_APP_ENV || "local";

export default configs[environment];
