import BottomFixedButton from "features/metadesk/campaign/createCampaign/BottomFixedButton";
import CampaignStep1 from "features/metadesk/campaign/createCampaign/CampaignStep1";
import CampaignStep2 from "features/metadesk/campaign/createCampaign/CampaignStep2";
import { CampaignCreationProvider } from "features/metadesk/campaign/createCampaign/context/CampaignCreationProvider";
import Back from "features/metadesk/components/Back";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";

import Box from "@mui/material/Box";
import { TrackingOptionsProvider } from "features/metadesk/campaign/createCampaign/component/SetOptions/OptionsProvider";

const CreateCampaignTracker = ({ campaignType }) => {
  const { formatMessage } = useIntl();
  const { fetchEntityConfig } = useFetchEntityConfig();
  const [entityName] = useOutletContext();
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, []);

  const stepName = [
    formatMessage({ id: "wizard.attributionLinkSettings.firstStep.title" }),
    formatMessage({ id: "wizard.attributionLinkSettings.secondStep.title" }),
  ];

  return (
    <CampaignCreationProvider campaignType={campaignType}>
      <TrackingOptionsProvider>
        <Box display="flex">
          <Box width={"100%"} margin="0 auto">
            <Back></Back>
            {step === 1 && (
              <CampaignStep1
                step={step}
                setStep={setStep}
                setIsOpen={setIsOpen}
                stepName={stepName}
              />
            )}
            {step === 2 && <CampaignStep2 step={step} stepName={stepName} />}
            <BottomFixedButton
              step={step}
              setStep={setStep}
              setIsOpen={setIsOpen}
            />
          </Box>
        </Box>
      </TrackingOptionsProvider>
    </CampaignCreationProvider>
  );
};

export default CreateCampaignTracker;
