import { AccordionDetails, Box } from "@mui/material";
import Back from "features/metadesk/components/Back";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import {
  StyledContainerTitle,
  StyledInputContainer,
  StyledObjectiveDescription,
  StyledObjectiveTitle,
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
} from "../createCampaign/component/CampaignCreation.styles";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import EngagementEdit from "./EngagementEdit";
import CustomEdit from "./CustomEdit";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import EditBottomFixedButton from "./EditBottomFixedButton";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, find, filter, isArray } from "lodash-es";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useIntl } from "react-intl";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import useGetPagePermission from "hooks/useGetPagePermission";
import { objectiveMappingName } from "../createCampaign/component/Objective";

const defaultData = {
  name: "",
  description: "",
  campaignContentSets: [],
  objectives: [],
  startFrom: "",
  endAt: "",
};

export default function CampaignEdit({ campaignType }) {
  const isTracker = campaignType === "tracker";

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const { campaignId } = useParams();

  const [campaignData, setCampaignData] = useState(defaultData);

  const methods = useForm({
    defaultValues: defaultData,
    mode: "onChange",
  });

  const {
    getValues,
    reset,
    setValue,
    formState: { isValid, isDirty },
  } = methods;

  const handleGetEditDetail = async () => {
    try {
      setIsLoading(true);
      let { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/${campaignId}`
      );
      setCampaignData(data);
      reset(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/marketing/campaign/draft`
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (campaignData.status === "scheduled") {
      try {
        const { data } = await axios.put(
          `${apiUrl}/api/campaign/${entityName}/updateScheduled/${campaignId}`,
          {
            description: campaignData.description,
            startFrom: campaignData.startFrom,
            endAt: campaignData.endAt,
            campaignContentSets: campaignData.campaignContentSets,
          }
        );
        dispatch(
          enqueueSnackbar({
            message: "updated successfully",
            options: {
              variant: "success",
            },
          })
        );
        if (isTracker) {
          navigate(
            parseCustomDomainUrl(
              isCustomDomain,
              entityName,
              `/analytics/settings/attribution-link`
            )
          );
          return;
        }
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            `/marketing/campaign/scheduled`
          )
        );
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    handleGetEditDetail();
  }, [campaignId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCampaignData((prev) => ({ ...prev, [name]: value }));
    setValue(name, value, { shouldDirty: true });
  };

  const handleChangeStartFrom = (value) => {
    setCampaignData((prev) => ({ ...prev, startFrom: value, endAt: "" }));
    setValue("startFrom", value, { shouldDirty: true });
    setValue("endAt", "", { shouldDirty: true });
  };

  const handleChangeEndAt = (value) => {
    setCampaignData((prev) => ({ ...prev, endAt: value }));
    setValue("endAt", value, { shouldDirty: true });
  };

  const handleFieldChange = ({ tabIndex, name, value }) => {
    const campaignContentSets = getValues("campaignContentSets");
    const temp = [...campaignContentSets];
    temp[tabIndex] = { ...temp[tabIndex], [name]: value };
    setCampaignData((prev) => ({ ...prev, campaignContentSets: temp }));
    setValue("campaignContentSets", temp, { shouldDirty: true });
  };

  const pagePermission = useGetPagePermission();
  const { readonly } = pagePermission("campaigns");

  return (
    <div style={{ paddingBottom: "60px" }}>
      <FormProvider {...methods}>
        <Back />
        <Box className="title">
          {formatMessage({ id: "campaignBuild.edit.title" })}
        </Box>

        {isLoading ? (
          <Box sx={{ position: "relative", width: "100%", height: "70vh" }}>
            <LoadingComponent isAbsolute backgroundColor="unset" />
          </Box>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ mb: "20px" }} />
                <Box sx={{ mb: "20px" }}>
                  <StyledWhiteAccordion
                    defaultExpanded={false}
                    disableGutters={true}
                  >
                    <StyledWhiteAccordionSummary
                      expandIcon={
                        <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      style={{ padding: 0, paddingRight: "20px", margin: 0 }}
                    >
                      <StyledContainerTitle className="wordBreak">
                        {campaignData.name}
                      </StyledContainerTitle>
                    </StyledWhiteAccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <div className="mhr" style={{ margin: 0 }}></div>
                      <StyledInputContainer>
                        {campaignData.status === "ongoing" || readonly ? (
                          <Box>
                            <Box
                              fontSize="12px"
                              color={(theme) => theme.customColors.grey[600]}
                              mb={1}
                            >
                              {formatMessage({
                                id: "campaigns.header.campaignDescription",
                              })}
                            </Box>

                            <Box>{campaignData.description || "-"}</Box>
                          </Box>
                        ) : (
                          <InputField
                            title="Campaign Description"
                            placeholder="Description"
                            multiline={true}
                            rows={8}
                            name="description"
                            value={campaignData.description}
                            onChange={handleChange}
                          />
                        )}
                      </StyledInputContainer>
                      <Box p="0px 20px 20px">
                        {campaignData.objectives?.length > 0 && (
                          <div className="mhr"></div>
                        )}
                        {campaignData.objectives.map((objective, index) => (
                          <Grid
                            container
                            spacing={2}
                            key={objective._id}
                            mb="24px"
                          >
                            <Grid item sm={12} md={4}>
                              <StyledObjectiveTitle>
                                Objective {index + 1}
                              </StyledObjectiveTitle>
                              <StyledObjectiveDescription>
                                {objectiveMappingName[objective.type]}
                              </StyledObjectiveDescription>
                            </Grid>
                            <Grid item sm={12} md={5}>
                              <StyledObjectiveTitle>
                                Contract Address
                              </StyledObjectiveTitle>
                              <StyledObjectiveDescription>
                                {objective.smartContractAddress}
                              </StyledObjectiveDescription>
                            </Grid>
                            <Grid item sm={12} md={3}>
                              {objective.method && (
                                <>
                                  {" "}
                                  <StyledObjectiveTitle>
                                    Function Name
                                  </StyledObjectiveTitle>
                                  <StyledObjectiveDescription>
                                    {objective.method}
                                  </StyledObjectiveDescription>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </StyledWhiteAccordion>
                </Box>

                <div className="mhr" style={{ margin: 0 }} />
                {campaignData?.campaignContentSets.map(
                  (campaignContentItem, tabIndex) => {
                    return campaignContentItem.type === "tracker" ? (
                      <CustomEdit
                        tabIndex={tabIndex}
                        key={campaignContentItem._id}
                        campaignContentItem={campaignContentItem}
                        onChangeStartFrom={handleChangeStartFrom}
                        onChangeEndAt={handleChangeEndAt}
                        startFrom={campaignData.startFrom}
                        endAt={campaignData.endAt}
                        status={campaignData.status}
                        name={campaignData.name}
                      />
                    ) : (
                      <EngagementEdit
                        tabIndex={tabIndex}
                        campaignContentItem={campaignContentItem}
                        key={campaignContentItem._id}
                        onFieldChange={handleFieldChange}
                        onChangeStartFrom={handleChangeStartFrom}
                        onChangeEndAt={handleChangeEndAt}
                        startFrom={campaignData.startFrom}
                        endAt={campaignData.endAt}
                        status={campaignData.status}
                        name={campaignData.name}
                      />
                    );
                  }
                )}
              </Grid>
            </Grid>
            <EditBottomFixedButton
              campaignContentSets={campaignData.campaignContentSets}
              onSubmit={handleUpdate}
              status={campaignData.status}
              isValid={isValid && isDirty}
            />
          </Box>
        )}
      </FormProvider>
    </div>
  );
}
