import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DimensionDropDown from "../component/DimensionDropDown";
import { PerformanceContext } from "../context/PerformanceProvider";
import { useNavigate, useOutletContext, useParams } from "react-router";
import axios from "axios";
import { apiUrl } from "features/configure";
import { get, lowerCase, isEmpty } from "lodash-es";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import moment from "moment";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import ChannelDropDown from "../component/ChannelDropDown";

export default function useGetPerformanceTable() {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("startFrom");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const navigate = useNavigate();
  const [entityName] = useOutletContext();
  const { formatMessage } = useIntl();
  const {
    search,
    dimension,
    setDimension,
    startDate,
    endDate,
    setIsLoading,
    promotype,
    channel,
    setChannel,
    isAdvanced,
    setIsAdvanced,
  } = useContext(PerformanceContext);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState({
    data: [],
    total: {
      openCount: 0,
      linkClicked: 0,
      ctr: 0,
      walletConnected: 0,
      objectiveAchieved: 0,
      conversionRate: 0,
    },
  });
  const [page, setPage] = useState(1);
  const [isAttributionLinkSet, setIsAttributionLinkSet] = useState(false);

  const [isDimensionOpen, setIsDimensionOpen] = useState(true);
  const [isChannelOpen, setIsChannelOpen] = useState(true);

  const dispatchSnackbar = useDispatchSnackbar();
  const [needUpdate, setNeedUpdate] = useState(false);
  // const [isAdvanced, setIsAdvanced] = useState(false);

  const PromoUrl = useMemo(() => {
    if (promotype === "flyer") {
      if (!channel) return `promotype=${promotype}`;
      return `promotype=${promotype}&channel=${channel}`;
    }
    if (promotype === "widget") return `promotype=${promotype}`;
    if (promotype === "tracker")
      return `promotype=${promotype}&dimension=${lowerCase(dimension)}`;

    return "";
  }, [promotype, channel, dimension]);

  async function getPerformanceData() {
    try {
      setIsLoading((prev) => ({ ...prev, table: true }));

      const { data, headers } = await axios.get(
        `${apiUrl}/api/campaignPerformance/${entityName}?search=${search}&page=${page}&sort=${orderBy}&order=${
          order === "asc" ? 1 : -1
        }&` +
          `${startDate && "from=" + moment(startDate).valueOf() + "&"}` +
          `${endDate && "to=" + moment(endDate).valueOf() + "&"}` +
          PromoUrl
      );
      setData(data);
      if (promotype === "tracker") {
        const { data: trackerData } = await axios.get(
          `${apiUrl}/api/campaignPerformance/${entityName}?promotype=tracker`
        );

        if (!isEmpty(trackerData.data)) {
          setIsAttributionLinkSet(true);
        }
      }
      setTotalCount(get(headers, "x-total-count", 0));
    } catch (error) {
      dispatchSnackbar({
        message: error,
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, table: false }));
    }
  }

  useEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      setNeedUpdate(true);
    }
  }, [
    search,
    dimension,
    channel,
    promotype,
    startDate,
    endDate,
    orderBy,
    order,
    isAdvanced,
  ]);

  // 當切換頁數時，重新取得資料
  useEffect(() => {
    setNeedUpdate(true);
  }, [page]);

  // 用 needUpdate 來判斷是否需要更新
  useEffect(() => {
    if (!needUpdate) return;
    getPerformanceData();
    setNeedUpdate(false);
  }, [needUpdate]);

  const handleOpenDimension = useCallback(() => {
    if (isDimensionOpen) {
      setDimension("");
    }
    setIsDimensionOpen(!isDimensionOpen);
  }, [isDimensionOpen]);

  const handleOpenChannel = useCallback(() => {
    if (!isChannelOpen) {
      setChannel("all");
    } else {
      setChannel("");
    }
    setIsChannelOpen(!isChannelOpen);
  }, [isChannelOpen]);

  const {
    openCount,
    linkClicked,
    ctr,
    walletConnected,
    objectiveAchieved,
    conversionRate,
  } = data?.total || {
    openCount: 0,
    linkClicked: 0,
    ctr: 0,
    walletConnected: 0,
    objectiveAchieved: 0,
    conversionRate: 0,
  };

  const headerContent = ({ content, type }) => (
    <Box height="30px" my="8px" display="flex" alignItems="center">
      {type === "number" ? (
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            color: "#383538",
          }}
        >
          {content}
        </Box>
      ) : (
        content
      )}
    </Box>
  );

  const headCells = useMemo(
    () =>
      [
        {
          fixed: true,
          show: true,
          width: 150,
          disabledSort: true,
          id: promotype === "tracker" ? "mainDimensions" : "Campaign",
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              {promotype === "tracker"
                ? formatMessage({ id: "filter.utmFiltering.title" })
                : formatMessage({ id: "global.campaign.title" })}
              {promotype === "tracker" && !isDimensionOpen ? (
                <IconButton
                  style={{ marginLeft: "10px" }}
                  size="large"
                  onClick={handleOpenDimension}
                >
                  <i className="meta-crm-icon-ic_plusCircle font-size-10" />
                </IconButton>
              ) : null}
              {promotype === "flyer" && !isChannelOpen ? (
                <IconButton
                  style={{ marginLeft: "10px" }}
                  size="large"
                  onClick={handleOpenChannel}
                >
                  <i className="meta-crm-icon-ic_plusCircle font-size-10" />
                </IconButton>
              ) : null}
            </div>
          ),
          title:
            promotype === "tracker"
              ? formatMessage({ id: "filter.utmFiltering.title" })
              : formatMessage({ id: "global.campaign.title" }),
        },
        promotype === "flyer" && {
          fixed: true,
          show: isChannelOpen,
          disabledSort: true,
          width: 160,
          id: "channel",
          label: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              Channel
              {headerContent({
                content: (
                  <ChannelDropDown handleOpenChannel={handleOpenChannel} />
                ),
              })}
            </div>
          ),
          title: "Channel",
        },
        promotype === "tracker" &&
          isAdvanced && {
            fixed: true,
            show: isDimensionOpen,
            disabledSort: true,
            width: 160,
            id: "dimensionValue",
            label: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {formatMessage({ id: "chart.mainDimension.title" })}
                {headerContent({
                  content: (
                    <DimensionDropDown
                      handleOpenDimension={handleOpenDimension}
                    />
                  ),
                })}
              </div>
            ),
            title: formatMessage({ id: "chart.mainDimension.title" }),
          },
        promotype === "widget" && {
          show: true,
          disabledSort: false,
          width: 120,
          id: "openCount",
          label: (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box className="startRow">
                <Box className="mgr4">
                  {formatMessage({ id: "analysis.columnName.open.title" })}
                </Box>
                <IconTooltip
                  content={formatMessage({
                    id: "campaigns.header.open.tooltip",
                  })}
                />
              </Box>
              {headerContent({ type: "number", content: openCount || 0 })}
            </Box>
          ),
          title: formatMessage({ id: "analysis.columnName.open.title" }),
        },
        {
          show: true,
          disabledSort: false,
          width: 125,
          id: "linkClicked",
          label: (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box className="startRow">
                <Box className="mgr4">
                  {formatMessage({
                    id: "analysis.columnName.linkClicked.title",
                  })}
                </Box>
                <IconTooltip
                  content={formatMessage({
                    id: "analysis.columnName.clicks.tooltip",
                  })}
                />
              </Box>
              {headerContent({ type: "number", content: linkClicked || 0 })}
            </Box>
          ),
          title: formatMessage({ id: "analysis.columnName.linkClicked.title" }),
        },
        promotype === "widget" && {
          show: true,
          disabledSort: false,
          width: 110,
          id: "ctr",
          label: (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box className="startRow">
                <Box className="mgr4">
                  {formatMessage({ id: "analysis.columnName.ctr.title" })}
                </Box>
                <IconTooltip
                  content={formatMessage({
                    id: "analysis.columnName.ctr.tooltip",
                  })}
                />
              </Box>
              {headerContent({
                type: "number",
                content: ctr + "%",
              })}
            </Box>
          ),
          title: "CTR",
        },
        {
          show: true,
          disabledSort: false,
          width: 185,
          id: "walletConnected",
          label: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {formatMessage({
                id: "analysis.columnName.walletsConnected.title",
              })}
              {headerContent({ type: "number", content: walletConnected || 0 })}
            </div>
          ),
          title: formatMessage({
            id: "analysis.columnName.walletsConnected.title",
          }),
        },
        {
          show: true,
          disabledSort: false,
          id: "objectiveAchieved",
          width: 185,
          label: (
            <div>
              <Box>
                {formatMessage({
                  id: "analysis.columnName.objectiveAchieved.title",
                })}
              </Box>
              {headerContent({
                type: "number",
                content: objectiveAchieved || 0,
              })}
            </div>
          ),
          title: formatMessage({
            id: "analysis.columnName.objectiveAchieved.title",
          }),
        },
        {
          show: true,
          disabledSort: false,
          width: 160,
          id: "conversionRate",
          label: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {formatMessage({
                id: "analysis.columnName.conversionRate.title",
              })}
              {headerContent({
                type: "number",
                content: conversionRate.toFixed(2) + "%",
              })}
            </div>
          ),
          title: formatMessage({
            id: "analysis.columnName.conversionRate.title",
          }),
        },
        {
          show: true,
          disabledSort: false,
          id: "startFrom",
          width: 185,
          label: (
            <Box>
              {formatMessage({
                id: "campaigns.header.startDate",
              })}
            </Box>
          ),
          title: formatMessage({
            id: "campaigns.header.startDate",
          }),
        },
      ].filter(Boolean),
    [
      conversionRate,
      ctr,
      handleOpenChannel,
      handleOpenDimension,
      isChannelOpen,
      isDimensionOpen,
      linkClicked,
      objectiveAchieved,
      openCount,
      promotype,
      walletConnected,
      isAdvanced,
    ]
  );

  const handleRowClick = (targetId, row) => {
    if (isAdvanced || promotype !== "tracker") {
      navigate(
        targetId +
          `?dimension=${dimension}&dimensionValue=${row?.dimensionValue}`
      );
    } else {
      navigate(`utmLinks/` + targetId);
    }
  };

  return {
    headerContent,
    headCells,
    data,
    totalCount,
    page,
    setPage,
    handleRowClick,
    handleRequestSort,
    order,
    orderBy,
    isAdvanced,
    setIsAdvanced,
    promotype,
    isAttributionLinkSet,
  };
}
