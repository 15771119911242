/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Linkify from "linkify-react";
import { useIntl } from "react-intl";
import {
  StyledMsgItemContainerOuter,
  StyledChatAvatar,
  StyledMsgItemContainer,
  StyledMsgItemMsgText,
  StyledTimeStamp,
} from "./MessageItem.styles";

import { formatSendingTime, linkifyOptions } from "../utils";
import MessageType from "./MessageType";

function MessageItem({ msgInfo, handleSubmit, active }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  // eslint-disable-next-line react/prop-types
  const { isAdamResponse, created, message, _id } = msgInfo;

  return (
    <StyledMsgItemContainerOuter isAdamResponse={isAdamResponse}>
      <Box display={"flex"} width={"100%"} alignItems={"flex-start"}>
        {isAdamResponse && (
          <Box display="flex" alignItems="center" mb="5px">
            <StyledChatAvatar>
              <img src={require("assets/img/adam.svg").default} alt="" />
            </StyledChatAvatar>
          </Box>
        )}
        <Box marginLeft={isAdamResponse ? "0" : "auto"} width={"100%"}>
          <StyledMsgItemContainer isAdamResponse={isAdamResponse}>
            {/* message area */}
            <StyledMsgItemMsgText isAdamResponse={isAdamResponse}>
              {/* adam response or user message */}
              {isAdamResponse ? (
                <>
                  {msgInfo?.contents?.map((item, index) => (
                    <Box key={index} my={1}>
                      <MessageType
                        msgInfo={{ ...item, _id: _id }}
                        handleSubmit={handleSubmit}
                        active={active}
                      />
                    </Box>
                  ))}
                </>
              ) : (
                <Linkify options={linkifyOptions}>{message}</Linkify>
              )}
            </StyledMsgItemMsgText>
          </StyledMsgItemContainer>
          {/* time stamp */}
          <StyledTimeStamp isAdamResponse={isAdamResponse}>
            <Box>{formatSendingTime(created)}</Box>
          </StyledTimeStamp>
        </Box>
      </Box>
    </StyledMsgItemContainerOuter>
  );
}

export default MessageItem;
