import React from "react";
import ServiceOnboardingSetStepContainer from "../ServiceOnboarding/ServiceOnboardingSetStepContainer";
import MarketingOnboardingSetStepContainer from "../MarketingOnboarding/MarketingOnboardingSetStepContainer";
import { selectTrialEntityName } from "features/metadesk/redux/trial/trial.selector";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

export default function TrialStep5({ stepData }) {
  const { module } = stepData;
  const selectorEntityName = useSelector(selectTrialEntityName);

  if (module.value === "service") return <Navigate to="service" />;
  if (module.value === "marketing") return <Navigate to="marketing" />;
  return <></>;
}
