import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { StyledEngineProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTheme } from "@emotion/react";

const MetaTabs = styled(Tabs)({
  marginBottom: "5px",
  "& .MuiTabs-indicator": {
    background: "transparent",
  },
});

const MetaTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontSize: "13px",
    padding: "3px",
    minWidth: "70px",
    fontWeight: 600,
    minHeight: 20,
    opacity: 1,
    background: "#F7F1FE",
    textTransform: "capitalize",

    "&:first-child": {
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    "&:last-child": {
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    "& .MuiTab-wrapper": {
      padding: "10px 10px",
      borderRadius: 3,
    },
    color: "#777777",
    "&:hover": {
      color: "#777777",
    },
    "&.Mui-selected": {
      fontWeight: 700,
      color: "#5C5C5C",
      "& .MuiTab-wrapper": {
        backgroundColor: "#ffffff",
      },
    },
    "&:focus": {
      color: "#5C5C5C",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, tabPanelStyles, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      sx={tabPanelStyles}
      {...other}
    >
      {value === index && <div style={{ overflow: "visible" }}>{children}</div>}
    </Box>
  );
}

export default function CustomTabs(props) {
  const {
    tabs,
    initIndex = 0,
    tabIndex,
    handleChange,
    sm,
    centered,
    tabStyle,
    borderBottom,
    extra,
    disabled,
    tabPanelStyles,
    ...other
  } = props;
  const [value, setValue] = useState(parseInt(initIndex));
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  const renderTabs = () => {
    return (
      <div style={{ height: "60px", position: "relative" }}>
        <div
          style={{
            overflow: "hidden",
            padding: "8px 12px",
            width: "100%",
            position: "absolute",
          }}
        >
          <MetaTabs
            {...other}
            value={typeof tabIndex === "number" ? tabIndex : value}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            {tabs.map((tab, index) => {
              return (
                <MetaTab
                  key={index}
                  label={<span className="MuiTab-wrapper">{tab.label}</span>}
                  disabled={tab.disabled}
                />
              );
            })}
          </MetaTabs>
        </div>
        {extra && (
          <div
            style={{
              position: "absolute",
              right: 5,
              top: computer ? 5 : "-50px",
            }}
          >
            {extra}
          </div>
        )}
      </div>
    );
  };

  return (
    <StyledEngineProvider injectFirst={false}>
      <div>
        {renderTabs()}

        {tabs.map((tab, index) => {
          return (
            <TabPanel
              key={index}
              value={typeof tabIndex === "number" ? tabIndex : value}
              index={index}
              tabPanelStyles={tabPanelStyles}
            >
              {tab.content}
            </TabPanel>
          );
        })}
      </div>
    </StyledEngineProvider>
  );
}
