import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledRightImageContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "relative",
}));

export const StyledDropdownPreview = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  border: `1px solid ${theme.customColors.grey[400]}`,
  borderRadius: "3px",
  color: theme.customColors.grey[700],
  top: "135px",
  left: "20px",
  minWidth: "155px",
  padding: "4px",
  background: theme.customColors.white,
}));

export const StyledDropdownItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "6px 8px",
}));

export const StyledRightImage = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
}));
