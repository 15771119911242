import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router";

export default function useGetRoleList() {
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${apiUrl}/api/role/${entityName}`);
      setRoleList(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  };

  const allRoles = useMemo(() => {
    if (roleList.length === 0) return [];
    return roleList.map((role) => ({
      value: role._id,
      label: role.name,
    }));
  }, [roleList]);


  useEffect(() => {
    fetchRoles();
  }, []);

  return { allRoles, roleList, fetchRoles, loading };
}
