import { Box } from "@mui/material";
import ReactECharts from "echarts-for-react";
import {
  analysisColors,
  marketingColors,
} from "features/configure/chartColors";
import { get } from "lodash-es";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

export default function PerformanceLeftChart({
  timeRangeStats,
  startDate,
  endDate,
  actionValue,
}) {
  const { pathname } = useLocation();
  const color = pathname.includes("analytics")
    ? analysisColors.middle
    : marketingColors.middle;

  const renderChartTitle = () => {
    if (actionValue.value === "clicks") {
      return "Clicked";
    }
    if (actionValue.value === "walletConnected") {
      return "Wallets Connected";
    }

    if (actionValue.value === "objectiveAchieved") {
      return "Objective Achieved";
    }

    if (actionValue.value === "open") {
      return "Opened";
    }
  };
  const [chartData, setChartData] = useState([]);
  const { formatMessage } = useIntl();

  const legendList = timeRangeStats?.map((item) => item.name);
  const getMinAndMaxTimestamps = (data) => {
    let minTimestamp = Infinity;
    let maxTimestamp = -Infinity;
    if (startDate) {
      minTimestamp = moment(startDate).startOf("day").utc().valueOf();
    }
    if (endDate) {
      maxTimestamp = moment(endDate).endOf("day").utc().valueOf();
    }
    data.forEach((item) => {
      item.dataPoints.forEach((point) => {
        const timestamp = moment(parseInt(point.timestamp))
          .startOf("day")
          .utc()
          .valueOf();
        if (timestamp < minTimestamp) minTimestamp = timestamp;
        if (timestamp > maxTimestamp) maxTimestamp = timestamp;
      });
    });
    return { minTimestamp, maxTimestamp };
  };

  const fillData = (data, start, end, step) => {
    const timestampMap = new Map(data.map((item) => [item[0], item[1]]));
    const filledData = [];
    for (let ts = start; ts <= end; ts += step) {
      const value = timestampMap.get(ts) || 0;
      filledData.push([ts, value]);
    }
    return filledData;
  };

  const getOptions = (newSeries) => {
    return {
      grid: {
        left: "10%",
        // top: "100px",
      },
      legend: {
        data: legendList,
        // top: "30px",
        right: "60px",
        width: "90%",
        icon: "path://M3,1 h4 a2,2 0 0 1 2,2 v4 a2,2 0 0 1 -2,2 h-4 a2,2 0 0 1 -2,-2 v-4 a2,2 0 0 1 2,-2", // SVG路径，定义了一个有圆角的方形
        textStyle: {
          color: "#333",
          fontSize: 12,
        },
        formatter: function (name) {
          return name?.length > 30 ? name.slice(0, 20) + "..." : name;
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            return params.name; // 在 tooltip 中顯示完整的 name
          },
          position: [30, 20],
        },
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
        minInterval: 1,
      },
      series: newSeries || chartData,
      tooltip: {
        trigger: "axis",
        position: [30, 20],
      },
      // grid: { containLabel: true },
    };
  };

  useEffect(() => {
    if (get(timeRangeStats, "length", 0) === 0) {
      setChartData([]);
      return;
    }
    const { minTimestamp, maxTimestamp } =
      getMinAndMaxTimestamps(timeRangeStats);

    const newSeries = timeRangeStats
      ?.filter((item) => item.dataPoints.length > 0)
      .map((item, index) => {
        const sortedData = item.dataPoints.sort(
          (a, b) => parseInt(a.timestamp) - parseInt(b.timestamp)
        );
        const filledData = fillData(
          sortedData.map((point) => [
            moment(parseInt(point.timestamp)).startOf("day").utc().valueOf(),
            point.count,
          ]),
          minTimestamp,
          maxTimestamp,
          3600000 * 24 // 1 hour in milliseconds
        );
        return {
          ...item,
          type: "line",
          data: filledData,
          itemStyle: { color: color[index % color.length] },
        };
      });

    setChartData(newSeries);
  }, [timeRangeStats, startDate, endDate]);

  const isEmpty = timeRangeStats?.length === 0;

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "60%",
        height: "380px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: "#555555",
          margin: "6px 16px",
        }}
      >
        {renderChartTitle()} Over Time
      </Box>
      {isEmpty ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("assets/img/chartNoData.svg").default} />
          <Box color="#A5A5A5" mt="15px">
            {formatMessage({ id: "global.status.chartNoData" })}
          </Box>
        </Box>
      ) : (
        <ReactECharts
          option={getOptions()}
          autoResize={true}
          style={{ height: "100%" }}
          notMerge={true}
        />
      )}
    </div>
  );
}
