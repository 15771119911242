import { Box } from "@mui/material";
import { isEmpty } from "lodash-es";
import { useContext, useEffect, useRef } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import CampaignContentPreview from "./component/CampaignContentPreview";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";

export default function CampaignStep3({ step, stepName }) {
  const { formatMessage } = useIntl();
  const { campaignId } = useParams();
  const isMounted = useRef(false);

  const { stepData, handleSetIsValid, isTracker } = useContext(
    CampaignCreationContext
  );

  const { campaignContentSets } = stepData;
  const methods = useForm({
    defaultValues: {
      campaignContentSets: [],
    },
    mode: "onChange",
  });
  const { getValues } = methods;
  const { append, replace, remove } = useFieldArray({
    control: methods.control,
    name: "campaignContentSets",
  });

  const {
    formState: { isValid: isFormValid },
  } = methods;

  const mapReactHookFormValidateItem = (targetData) => {
    return targetData.map((item) => {
      return {
        engagementDescription: item.engagementDescription,
        engagementTitle: item.engagementTitle,
        engagementDistributionSettings: item.engagementDistributionSettings,
      };
    });
  };

  useEffect(() => {
    if (campaignContentSets[0]?.type === "tracker") return;
    if (!isEmpty(campaignContentSets)) {
      const filterDataForReactHookForm =
        mapReactHookFormValidateItem(campaignContentSets);
      replace(filterDataForReactHookForm);
      if (campaignId) {
        if (!isMounted.current) {
          methods.trigger();
          isMounted.current = true;
        }
      }
    }
  }, [campaignContentSets]);

  useEffect(() => {
    const isNextValid = isFormValid;

    handleSetIsValid(isNextValid);
  }, [isFormValid]);

  return (
    <div style={{ paddingBottom: "60px" }}>
      <FormProvider {...methods}>
        <Box>
          {isTracker ? (
            <Box className="title">
              {formatMessage({
                id: "analytics.attributionLink.settingsFirstStep.headerTitle",
              })}
            </Box>
          ) : (
            <Box className="title" mb={1}>
              {formatMessage({ id: "campaign.creation.title" })}
            </Box>
          )}
          {isTracker ? (
            <Box className="description" mb={"32px"}>
              {formatMessage({
                id: "analytics.attributionLink.settingsFirstStep.headerDescription",
              })}
            </Box>
          ) : (
            <Box className="description" mb={"32px"}>
              {formatMessage({ id: "campaign.creation.description" })}
            </Box>
          )}
        </Box>

        {campaignContentSets.map((item, index) => {
          return (
            <div key={index}>
              <CampaignContentPreview
                key={index}
                tabIndex={index}
                step={step}
                stepName={stepName}
              ></CampaignContentPreview>
            </div>
          );
        })}
      </FormProvider>
    </div>
  );
}
