import React, { useEffect, useState } from "react";
import { get } from "lodash";
import _ from "lodash";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { Box, Grid } from "@mui/material";
import useNavigateEntity from "hooks/useNavigateEntity";
import useMarketModule from "hooks/useMarketModule";
import useGetPagePermission from "hooks/useGetPagePermission";
import {
  StyledContainerTitle,
  StyledContentContainer,
  StyledReferralValue,
  StyledSubTitle,
  StyledWhiteContainer,
} from "./Referral.styles";
import { useDispatch, useSelector } from "react-redux";
import ReferralRules from "./ReferralRules";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { apiUrl, objectiveList } from "features/configure";
import axios from "axios";
import { Loading } from "features/common";
import { useOutletContext } from "react-router";
import moment from "moment";
import HoverImage from "../campaign/settings/component/hoverImage";
import { formatDate } from "features/helpers/utils";
export default function Referral() {
  const { formatMessage } = useIntl();
  const { navigateEntity } = useNavigateEntity();
  const [entityName, isCustomDomain] = useOutletContext();
  const dispatch = useDispatch();
  const ecosystemName = useSelector(selectEcosystemName);
  const handleToCreateReferralPage = () => {
    navigateEntity(`/analytics/settings/referral/create`);
  };
  const { attributionConnect, referralEnabled } = useMarketModule();
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState();
  const objective = get(referralData, "objectives[0]");
  const pagePermission = useGetPagePermission();
  const { canEdit, show } = pagePermission("campaigns");

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${apiUrl}/api/referral/${entityName}/program`
      );
      const data = result?.data;
      setReferralData(data);
      return data;
    } catch (err) {
      console.log("err: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    getAPIData();
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Loading open={isLoading} fullScreen={false} white />

      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Box className="title" mb={1}>
            {formatMessage({ id: "global.function.referral.title" })}
          </Box>
          <Box color={(theme) => theme.customColors.grey[600]} mb="13px">
            {formatMessage({ id: "global.function.referral.description" })}
          </Box>
        </Box>
        {canEdit && (
          <Button
            onClick={handleToCreateReferralPage}
            disabled={
              !referralEnabled ||
              !attributionConnect ||
              !_.isEmpty(referralData)
            }
          >
            {formatMessage({ id: "global.btn.createNew" })}{" "}
          </Button>
        )}
      </Box>
      <Box>
        {!_.isEmpty(referralData) ? (
          <Box>
            <StyledWhiteContainer
              sx={{ cursor: show ? "pointer" : "none" }}
              onClick={() => {
                if (show) {
                  navigateEntity("/analytics/referral");
                }
              }}
            >
              <StyledContainerTitle>
                {referralData?.referralUrl}
              </StyledContainerTitle>
              <StyledContentContainer>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={3}>
                    <StyledSubTitle>
                      {formatMessage({
                        id: "referralsProgram.list.transactionRefferred",
                      })}
                    </StyledSubTitle>
                    <StyledReferralValue
                      style={{ color: "#7B61FF", fontWeight: 700 }}
                    >
                      {referralData?.referralCount || "--"}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledSubTitle>
                      {formatMessage({
                        id: "referralsProgram.list.programDescription",
                      })}
                    </StyledSubTitle>
                    <StyledReferralValue>
                      {referralData?.description}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {ecosystemName == "EVM" && (
                      <>
                        <StyledSubTitle>
                          {formatMessage({
                            id: "campaignBuild.thirdStep.field.chain.title",
                          })}
                        </StyledSubTitle>
                        <StyledReferralValue>
                          {objective?.chainId}
                        </StyledReferralValue>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <StyledSubTitle>
                      {formatMessage({
                        id: "referralsProgram.list.objective",
                      })}
                    </StyledSubTitle>
                    <StyledReferralValue>
                      {_.find(objectiveList, { value: objective?.type })?.label}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledSubTitle>
                      {ecosystemName == "Solana"
                        ? formatMessage({
                            id: "referralsProgram.list.programId",
                          })
                        : formatMessage({
                            id: "campaignBuild.thirdStep.field.contractAddress.title",
                          })}
                    </StyledSubTitle>
                    <StyledReferralValue>
                      {objective?.smartContractAddress}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {objective?.type === "contract" && (
                      <>
                        <StyledSubTitle>
                          {formatMessage({
                            id: "referralsProgram.list.targetInstruction",
                          })}
                        </StyledSubTitle>
                        <StyledReferralValue>
                          {objective?.method}
                        </StyledReferralValue>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledSubTitle className="startRow">
                      <Box style={{ fontSize: 14, marginRight: 5 }}>
                        Created:{" "}
                      </Box>
                      {formatDate(referralData?.updated)}
                    </StyledSubTitle>
                  </Grid>
                </Grid>
              </StyledContentContainer>
            </StyledWhiteContainer>
          </Box>
        ) : (
          <HoverImage
            originalImg={require("assets/img/referralPlaceholder.jpg")}
            hoverShowImg={
              attributionConnect
                ? require("assets/img/img_customization.png")
                : require("assets/img/img_customization2.svg").default
            }
            hoverShowText={formatMessage({
              id: attributionConnect
                ? "analytics.referral.settings.enableState"
                : "analytics.referral.performance.disableState",
            })}
            showButton={!attributionConnect}
            onClick={() => {
              navigateEntity("/analytics/settings/referral/create");
            }}
          ></HoverImage>
        )}
      </Box>
      {(!attributionConnect || !_.isEmpty(referralData)) && (
        <Box
          sx={{
            background: "#F7F1FE",
            p: "12px",
            mt: "12px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              fontSize: "16px",
              color: "#7B61FF",
              mb: "8px",
              fontWeight: 500,
            }}
            className="startRow"
          >
            <i className="meta-crm-icon-ic_info font-size-16 mgr4" />
            {formatMessage({
              id: "referralsProgram.list.note.title",
            })}
          </Box>

          <ReferralRules hasReferral={!_.isEmpty(referralData)} />
        </Box>
      )}
    </Box>
  );
}
