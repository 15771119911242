import styled from "styled-components";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
export const StyledImportPopoverContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 520px;
  position: fixed;
  padding-bottom: 0px;
  top: 0px;
  right: 0px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
`;

export const StyledImportPopoverTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #383538;
  margin-bottom: 24px;
`;

export const StyledImportPopoverHintText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #777;
  margin-bottom: 23px;
`;

export const StyledImportPopoverErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #fc5555;
  margin-bottom: 16px;
  padding: 15px 21px 15px 21px;
  background: #ffded7;
  border-radius: 3px;
`;

export const StyledImportPopoverDownloadAndUploadRow = styled.div`
  display: flex;
  justifycontent: space-between;
  margin-bottom: 20px;
`;

export const StyledImportPopoverUpload = styled.div`
  position: relative;
  padding: 56px 0;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e6e6e6;
  border-radius: 3px;
  text-align: center;
  min-height: 125px;
  margin-bottom: 16px;
  & input {
    cursor: pointer;
    background: #7b61ff;
    padding: 4px 24px;
    color: #ffffff;
    border-radius: 3px;
  }

  & .import-over-upload-text {
    font-weight: 300;
    font-size: 12px;
  }
`;
export const StyledImportPopoverDownload = styled.div`
  padding: 18px 0px 18px 0;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  & img {
    width: 32px;
    margin-right: 8px;
  }
  & .import-over-download-text {
    font-weight: 400;
    font-size: 12px;
    color: #383538;
    white-space: nowrap;
  }
`;
export const StyledImportPopoverLine = styled.div`
  border-top: 1px solid #d6d6d6;
  margin-bottom: 16px;
`;

export const StyledImportPopoverFileName = styled.div`
  color: #383538;
  font-size: 14px;
  padding: 15px 21px;
  font-weight: 500;
  margin-bottom: 16px;
  background: #f8f8f8;
  border-radius: 3px;
  position: relative;
`;

export const StyledPreview = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 16px;
  overflow: hidden;
  padding-inline: 16px;
  padding-block: 4px;
  border-radius: 4px;
  background-color: #f7f1fe;

  & > p {
    color: #7b61ff;
  }
`;

export const StyledSearchBar = styled.div`
  position: absolute;
  top: 38px;
  left: 0px;
  width: 425px;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  z-index: 10000;
  &:hover {
    background-color: #f7f1fe;
  }
`;

export const StyledImportPopoverNameArea = styled.div`
  padding-bottom: 22px;
  padding-top: 6px;
`;
export const StyledNavBar = styled.div`
  background: #fff;
  padding-bottom: 22px;
`;

export const StyledImportPopoverInput = styled(CustomOutlinedInput)`
  border: #a5a5a5;
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  margin-bottom: 8px;

  ${(props) => ({
    ...(props.disabled && {
      opacity: 0.5,
    }),
  })};

  &:focus-within {
    border: 1px solid #5643cc;
  }

  & .MuiInputBase-input {
    padding: 11px 8px;
    font-weight: 500;
    font-size: 12px;
  }
`;

export const StyledBadge = styled.div`
  background-color: #fff;
  border: 1px solid #777777;
  border-radius: 20px;
  color: #777777;
  padding-inline: 8px;
  padding-block: 4px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    border-color: #7b61ff;
    color: #7b61ff;
  }

  &.disabled {
    background-color: #f8f8f8;
    border-color: #e6e6e6;
    color: rgba(0, 0, 0, 0.36);

    &:hover {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.36);
    }
  }

  &.active {
    border-color: #7b61ff;
    background-color: #7b61ff;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
`;

export const StyledImportWayBtn = styled(Button)`
  ${(props) => ({
    height: 60,
    border: "1px solid #D6D6D6",
    fontSize: 14,
    fontWeight: 500,
    padding: "16px",
    boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
    "&:hover": {
      border: "2px solid #7B61FF",
    },
  })};
`;

export const StyledImportPopoverImportBtn = styled(
  <Button variant={VARIANT.SHADOW} />
)`
  ${(props) => ({})};
`;
