import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root.js";

import * as serviceWorker from "./serviceWorker.js";

import "./assets/fonts/Prompt-Regular.ttf";
import "./styles/index.scss";
import "./styles/funnel.css";
import "@metacrm/metacrm-svg/dist/public/fonts/font-icon.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

// 要先等待msw的實例成功掛載後,再去掛載所有的React DOM
const renderReactDom = async () => {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_MOCK === "true"
  ) {
    const { worker } = require("./mocks/browser.js");
    await worker.start();
    ReactDOM.render(<Root />, document.getElementById("root"));
    return;
  }
  ReactDOM.render(<Root />, document.getElementById("root"));
};

renderReactDom();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
