import { createTheme } from "@mui/material/styles";

const createAppTheme = (mode) =>
  createTheme({
    palette: { primary: { main: "#7B61FF" } },
    headerBackground: mode == "light" ? "#ffffff" : "#333247",
    headerColor: mode == "light" ? "#383538" : "#ffffff",
    headerMetaDesk:
      mode == "light"
        ? require("assets/img/header.svg")
        : require("assets/img/darkHeader.svg"),
  });

export default createAppTheme;
