import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { Box } from "@mui/material";

export const StyledAssignmentContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, serviceName }) => ({
  ...(serviceName === "discord" && {
    flex: 0.3,
    textAlign: "right",
  }),
  ...(serviceName === "telegram" && {
    flex: 0.2,
  }),
}));
