import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";


export const StyledSettingAreaContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSettingAreaTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "8px",
}));

export const StyledSettingAreaHint = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  marginBottom: "32px",
  lineHeight: "20px",
}));

export const StyledSettingListContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
