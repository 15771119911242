import React, { useState, useEffect } from "react";
import {
  StyledModalTableItem,
  StyledModalTableRow,
  StyledModalTitle,
} from "./TrackingModal.styles";
import { Box } from "@mui/material";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PureModal from "./PureModal";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "features/common/redux/snackbar";
import { useIntl } from "react-intl";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import useNavigateEntity from "hooks/useNavigateEntity";

const TrackingModal = ({
  isTracker,
  open,
  onClose,
  data,
  campaignName,
  campaignId,
  promotype,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { navigateEntity } = useNavigateEntity();

  function downloadAsCsv() {
    const header =
      "Website URL,Campaign,Source,Medium,Content,Term,A UTM-tagged URL,Shortened URL";
    const csv = data.map((item) => {
      return `${item.trackingLink.split("?")[0]},${campaignName},${
        item.utm_source
      },${item.utm_medium},${item.utm_content},${item.utm_term},${
        item.trackingLink
      },${item.shortLink}`;
    });
    csv.unshift(header);
    const csvContent = "data:text/csv;charset=utf-8," + csv.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "tracking_links.csv");
    document.body.appendChild(link);
    link.click();
  }

  function CopySuccess() {
    dispatch(
      enqueueSnackbar({
        message: "Copied to clipboard",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      })
    );
  }

  const renderDialogTitle = () => {
    return (
      <StyledModalTitle width={"100%"}>
        {formatMessage({ id: "engagementCampaign.contentSetting.title" })}:{" "}
        <span style={{ color: "#7B61FF" }}>{data.length} Link</span>
      </StyledModalTitle>
    );
  };

  const renderDialogContent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #e6e6e6",
          padding: "12px 17px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="betweenRow" sx={{ flexWrap: "wrap" }}>
          <Box flex={1} mr={1} my={1}>
            <Box sx={{ color: "#555555", mb: "12px" }}>
              {formatMessage({ id: "campaignBuild.info.campaignName" })}
            </Box>
            <Box
              sx={{
                width: "66%",
                color: "#555555",
                fontWeight: 700,
                wordBreak: "break-word",
              }}
            >
              {campaignName}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              onClick={() => {
                if (isTracker) {
                  navigateEntity(
                    `/analytics/attribution/utmLinks/${campaignId}`
                  );
                  return;
                }
                navigateEntity(
                  `/marketing/dashboard/campaign?search=${campaignName}&promotype=${promotype}`
                );
              }}
              style={{ margin: "4px 0", marginRight: "8px" }}
            >
              Go To Analysis
            </Button>
            <Button
              variant="outlined"
              onClick={downloadAsCsv}
              style={{ margin: "4px 0" }}
            >
              {formatMessage({ id: "global.btn.downloadCSV" })}
            </Button>
          </Box>
        </Box>
        <div className="mhr" />
        <Box sx={{ width: "100%", overflowY: "auto" }}>
          <StyledModalTableRow>
            <div style={{ width: "15%", textAlign: "left", fontWeight: 500 }}>
              {formatMessage({ id: "campaign.type.source" })}
            </div>
            <div style={{ width: "25%", textAlign: "left", fontWeight: 500 }}>
              {formatMessage({ id: "campaign.type.medium" })}
            </div>
            <div style={{ width: "30%", textAlign: "left", fontWeight: 500 }}>
              {formatMessage({ id: "campaign.type.utmTaggedURL" })}
            </div>
            <div style={{ width: "30%", textAlign: "left", fontWeight: 500 }}>
              {formatMessage({ id: "campaign.type.shorten" })}
            </div>
          </StyledModalTableRow>
          <Box sx={{ overflowY: "auto" }}>
            {data.length === 0 ? (
              <StyledModalTableRow>
                <StyledModalTableItem style={{ width: "15%" }}>
                  -
                </StyledModalTableItem>
                <StyledModalTableItem style={{ width: "25%" }}>
                  -
                </StyledModalTableItem>
                <StyledModalTableItem
                  style={{ width: "30%" }}
                  className="betweenRow"
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    -
                  </span>
                </StyledModalTableItem>
                <StyledModalTableItem
                  style={{
                    width: "30%",
                    border: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>-</span>
                </StyledModalTableItem>
              </StyledModalTableRow>
            ) : (
              data.map((item) => (
                <StyledModalTableRow>
                  <StyledModalTableItem style={{ width: "15%" }}>
                    {item.utm_source}
                  </StyledModalTableItem>
                  <StyledModalTableItem style={{ width: "25%" }}>
                    {item.utm_medium}
                  </StyledModalTableItem>
                  <StyledModalTableItem
                    style={{ width: "30%" }}
                    className="betweenRow"
                  >
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.trackingLink || "-"}
                    </span>
                    {item.trackingLink && (
                      <CopyToClipboard
                        text={item.trackingLink}
                        onCopy={CopySuccess}
                      >
                        <i
                          className="meta-crm-icon-ic_copy font-size-16"
                          style={{ cursor: "pointer" }}
                        />
                      </CopyToClipboard>
                    )}
                  </StyledModalTableItem>
                  <StyledModalTableItem
                    style={{
                      width: "30%",
                      border: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{item.shortLink || "-"}</span>
                    {item.shortLink && (
                      <CopyToClipboard
                        text={item.shortLink}
                        onCopy={CopySuccess}
                      >
                        <i
                          className="meta-crm-icon-ic_copy font-size-16"
                          style={{ cursor: "pointer" }}
                        />
                      </CopyToClipboard>
                    )}
                  </StyledModalTableItem>
                </StyledModalTableRow>
              ))
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      dialogRootStyle={{ maxWidth: "800px", overflow: "hidden" }}
    >
      <Box
        padding={"24px 40px 48px"}
        flexDirection={"column"}
        sx={{ overflow: "hidden", flex: 1, display: "flex", width: "800px" }}
        maxWidth={"800px"}
      >
        {renderDialogTitle()}
        {renderDialogContent()}
      </Box>
    </BasicDialog>
  );
};

export default TrackingModal;
