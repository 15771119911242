import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const StyledRecentObjectiveContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  border: `1px dashed ${theme.customColors.grey[300]}`,
  borderRadius: "3px",

  color: theme.customColors.grey[700],
}));

export const StyledRecentObjectiveButtonArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, expanded }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 9.5px",
  cursor: "pointer",
  "& .meta-crm-icon-ic_arrowLineDown": {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
  },
}));

export const StyledObjectiveListContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledRecentObjectiveItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "16px",
  backgroundColor: theme.customColors.white,
  border: `1px dashed ${theme.customColors.grey[300]}`,
  borderRadius: "3px",
  cursor: "pointer",
  display: "block",
  background: theme.customColors.white,
  textAlign: "left",
  lineHeight: 1,
  width: "100%",
  textTransform: "none",
  "&:hover": {
    border: `1px dashed ${theme.customColors.purple[500]}`,
    background: theme.customColors.white,
  },
  "&.Mui-selected": {
    background: theme.customColors.white,
    border: `2px solid ${theme.customColors.purple[500]}`,
    "&:hover": {
      background: theme.customColors.white,
    },
  },
}));

export const StyledRecentObjectiveItemTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  marginBottom: "4px",
}));

export const StyledRecentObjectiveItemName = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 700,
  color: theme.customColors.grey[700],
}));

export const StyledRecentObjectiveItemContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  wordBreak: "break-all",
  color: theme.customColors.grey[700],
  fontSize: "14px",
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "block",
}));
