import {
  Button,
  IconButton,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { IconFileUploader } from "@metacrm/metacrm-material-ui/dist/FileUploader";
import PreviewFile from "@metacrm/metacrm-material-ui/dist/PreviewFile";
import IconIcDiscord2 from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscord2";
import IconIcMagicStick from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMagicStick";
import IconIcTelegram from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcTelegram";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { Box, Modal } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import axios from "axios";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "components/MultiLevelDropdown/MultiLevelDropdown";
import { Loading } from "features/common";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl, entityConfig } from "features/configure";
import {
  alertServerError,
  formatDiscordUsername,
  getShortAddress,
  uploadFiles,
} from "features/helpers/utils";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { selectMacroList } from "features/metadesk/redux/macro/macro.selector";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  AiReplyLoader,
  AiReplyPreference,
  StyledMacroButton,
  StyledMagicWandButton,
  StyledRetryIcButton,
} from "./Chat.styles";
import BasicModal from "../chat/BasicModal";
import MacroModal from "../chat/MacroModal/MacroModal";
import ChatItem from "./components/ChatItem";
import UserTag from "../components/UserTag";
import GroupLabel from "../group/GroupLabel";
import { selectMagicWandConfig } from "../redux/aiSupport/aiSupport.selector";
import {
  selectServiceModule,
  selectSurveyConfig,
} from "../redux/entityConfig/entityConfig.selector";
import { useFetchUserInfo } from "../redux/fetchUserInfo";
import { useFetchAiSupportConfig, useFetchTicketList } from "../redux/hooks";
import SurveyMsg from "./components/SurveyMsg";
import { getUserAvatarIcon } from "../utils";
import Prompt from "features/common/Prompt";

const useStyles = makeStyles((theme) => ({
  inboxHeader: {
    padding: "12px 10px",
    left: "0px",
    top: "0px",
    background: "#ffffff",
    borderBottom: "1px solid #e6e6e6",
  },

  address: {
    padding: "2px 0px",
  },
}));
const AI_TONE_PREFERENCES = ["Professional", "Casual", "Friendly"];
const AI_MSG_LEN_PREFERENCES = ["Long", "Medium", "Short"];
const Chat = ({
  selectedTicket,
  entityName,
  setSelectedTicket,
  discordId,
  telegramId,
  userCanEdit,
  fetchTicketDetailById,
  message,
  setMessage,
}) => {
  const classes = useStyles();
  const { userData, socket } = useConnectWallet();
  const { formatMessage } = useIntl();

  const [openImg, setOpenImg] = useState("");
  const [selectedMacro, setSelectedMacro] = useState("");
  const [files, setFiles] = useState([]);
  const [pastMsgs, setPastMessages] = useState([]);
  const [newMsg, setNewMsg] = useState("");
  const messagesEndRef = useRef(null);
  const messageFieldRef = useRef(null);
  const { userInfos } = useFetchUserInfo();
  const [loading, setLoading] = useState(true);
  const [isMagicHappening, setIsMagicHappening] = useState(false);
  const [sending, setSending] = useState(false);
  const [macroModalOpen, setMacroModalOpen] = useState(false);
  const [noMacroModalOpen, setNoMacroModalOpen] = useState(false);
  const [isMagicWandReply, setIsMagicWandReply] = useState(false);
  const [tonePreference, setTonePreference] = useState("");
  const [lengthPreference, setLengthPreference] = useState("");
  const macroList = useSelector(selectMacroList);
  const navigate = useNavigate();
  const selectedUserAddress = _.get(selectedTicket, "userWallet.address");
  const userId = _.get(selectedTicket, "user._id");
  const userInfo = _.get(userInfos, `[${userId}]`, {});
  const address = _.get(selectedTicket, "userWallet.address", "");
  const theme = useTheme();
  const groups = _.get(selectedTicket, "groups", []);
  const dispatch = useDispatch();
  const surveyConfig = useSelector(selectSurveyConfig);
  const serviceModule = useSelector(selectServiceModule);
  const magicWandConfig = useSelector(selectMagicWandConfig);
  const { fetchTicketList, ticketListFilter } = useFetchTicketList();
  const { fetchAiSupportConfig } = useFetchAiSupportConfig();

  const options = {
    target: (href) => {
      const { hostname } = window.location;
      if (hostname && href.includes(hostname)) {
        return "";
      }
      return "_blank";
    },
    attributes: {
      rel: "noreferrer noopener",
    },
  };

  const handleRecallMsg = (newMsgItem) => {
    const newArr = pastMsgs.map((pastMsg) =>
      pastMsg._id === newMsgItem._id ? newMsgItem : pastMsg
    );

    setPastMessages(newArr);
  };

  const isShowMagicWand = () => {
    const isMagicWandEnabled = _.get(
      serviceModule?.aiSupport?.magicWand,
      "enabled"
    );
    const isMagicWandConnected = _.get(
      serviceModule?.aiSupport?.magicWand,
      "connected"
    );
    return isMagicWandEnabled && isMagicWandConnected;
  };

  useEffect(() => {
    if (selectedTicket) {
      let selectedUserId = _.get(selectedTicket, "user._id");

      if (!socket) return;
      socket.emit("init", {
        userId: selectedUserId,
      });

      socket.on("message", (data) => {
        setNewMsg(data);
        handleReadMessage(socket);
      });

      socket.on("refreshTicket", () => {
        fetchTicketDetailById();
      });

      socket.on("userRecallMessage", (data) => {
        setPastMessages((currentMessages) =>
          currentMessages.map((msg) => (msg._id === data._id ? data : msg))
        );
      });
      socket.on("userUpdateMessage", (data) => {
        setPastMessages((currentMessages) =>
          currentMessages.map((msg) => (msg._id === data._id ? data : msg))
        );
      });
      socket.on("closeTicket", (data) => {
        if (data == selectedTicket._id) {
          setSelectedTicket({ ...selectedTicket, status: "Done" });
          let rawMessage = {
            ticket: data,
            message: "--- Ticket is closed by user ---",
            created: new Date(),
          };
          setNewMsg(rawMessage);
        }
      });

      return () => {
        socket.off("connect");
        socket.off("message");
        socket.off("userRecallMessage");
        socket.off("userUpdateMessage");
        socket.off("refreshTicket");
        socket.off("clientCreateTicket");
        socket.off("closeTicket");
      };
    }
  }, [socket, selectedTicket]);

  useEffect(() => {
    let newMsgTicketId = newMsg && newMsg.ticket;
    let currentTicketId = selectedTicket && selectedTicket._id;
    if (newMsgTicketId == currentTicketId) {
      setPastMessages([...pastMsgs, newMsg]);
    }
  }, [newMsg]);

  useEffect(() => {
    scrollToBottom();
  }, [pastMsgs.length]);

  useEffect(() => {
    let selectedUserID = _.get(selectedTicket, "user._id");
    if (selectedUserID) {
      getMessageListByTicket(selectedUserID, selectedTicket._id);
    }
    fetchAiSupportConfig({ entityName });
  }, [selectedTicket._id]);

  useEffect(() => {
    const tone = _.get(magicWandConfig, "tone", "Professional");
    const len = _.get(magicWandConfig, "length", "Short");
    setTonePreference(tone);
    setLengthPreference(len);
  }, [magicWandConfig]);

  const getMessageListByTicket = async (userId, ticketId) => {
    setLoading(true);
    try {
      let messages = await axios.get(
        `${apiUrl}/api/messages/ticket?id=${ticketId}&userId=${userId}&entityName=${entityName}`
      );
      setPastMessages(messages.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleReadMessage = (socket) => {
    if (
      socket &&
      _.get(userData, "address") == _.get(selectedTicket, "team.address", "N/A")
    ) {
      socket.emit("adminReadTicket", {
        adminAddress: _.get(userData, "address"),
        ticketId: selectedTicket._id,
        entityName,
      });
    }
  };

  const scrollToBottom = async () => {
    const offsetBottom = messagesEndRef.current?.scrollHeight;
    messagesEndRef.current?.scrollTo({ top: offsetBottom });
  };
  const setMessageData = (value) => {
    if (!value || value.trim().length == 0) {
      value = value.trim();
    }
    setMessage(value);
  };

  const doSendMessage = async ({ isSendSurvey = false } = {}) => {
    if (sending) return;
    if (selectedTicket.status == "Done" || !userData) return;
    try {
      if (
        (message && message.trim().length !== 0) ||
        files.length > 0 ||
        isSendSurvey
      ) {
        setSending(true);
        let selectedUserId = _.get(selectedTicket, "user._id");
        const msgId = uuidv4();
        let data = {
          isAdminResponse: true,
          userId: selectedUserId,
          message: message,
          entityName,
          team: userData.team,
          ticketId: selectedTicket._id,
          macroId: _.get(selectedMacro, "_id"),
          adminAddress: userData.address,
          status: "sending",
          created: new Date(),
          msgId,
        };
        data["imagesUrl"] = await uploadFiles(files, entityName);

        setPastMessages([...pastMsgs, data]);
        setMessage("");
        setSelectedMacro("");
        setFiles([]);
        setIsMagicWandReply(false);

        setTimeout(() => {
          setPastMessages((currentMessages) =>
            currentMessages.map((msg) =>
              msg.msgId === msgId && msg.status === "sending"
                ? { ...msg, status: "failed" }
                : msg
            )
          );
        }, 3000);

        socket.emit("adminResponse", data, (result) => {
          let hasUpdate = false;
          let newSelectedTicket = selectedTicket;
          if (!selectedTicket.team) {
            hasUpdate = true;
            newSelectedTicket = { ...newSelectedTicket, team: userData.team };
          }
          if (selectedTicket.status == "New") {
            hasUpdate = true;
            newSelectedTicket = { ...newSelectedTicket, status: "Ongoing" };
            fetchTicketList({
              entityName,
              filter: ticketListFilter,
            });
          }

          if (selectedMacro && selectedMacro.tags) {
            const newTags = _.unionBy(
              newSelectedTicket.tags,
              selectedMacro.tags,
              "_id"
            );
            newSelectedTicket = {
              ...newSelectedTicket,
              tags: newTags,
            };
            hasUpdate = true;
          }
          if (hasUpdate) {
            setSelectedTicket(newSelectedTicket);
          }
          setPastMessages((currentMessages) =>
            currentMessages.map((msg) =>
              msg.msgId === result.msgId && msg.status === "sending"
                ? { ...msg, _id: result._id, status: "success" }
                : msg
            )
          );
          scrollToBottom();
        });
      }
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setSending(false);
    }
  };

  const handleKey = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      doSendMessage();
    }
  };

  const handleMagicWandClick = async () => {
    const userMessages = getAllMessagesAfterLastAdminResponse();

    if (!userMessages.length) {
      dispatch(
        enqueueSnackbar({
          message: "No user message for AI to respond to.",
          options: {
            variant: "info",
          },
        })
      );
      return;
    }

    let f = `I have ${userMessages.length} queries:`;
    let combinedMessage = `${f}\n`;
    for (let i = 0; i < userMessages.length; i++) {
      let m = userMessages[i];
      combinedMessage += `- ${m.message}\n`;
    }

    await new Promise(async (resolve, reject) => {
      try {
        setIsMagicHappening(true);
        let result = await axios.post(
          apiUrl + `/api/aiSupport/gitbook/${entityName}/query`,
          {
            query: combinedMessage,
            tone: tonePreference,
            length: lengthPreference,
          }
        );
        setMessage(result.data.answer);
        setIsMagicWandReply(true);
      } catch (error) {
        console.log(error);
        dispatch(
          enqueueSnackbar({
            message: _.get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
        setIsMagicHappening(false);
      }
    });
  };

  const cancelMagicWandReply = () => {
    setIsMagicWandReply(false);
    setMessage("");
  };

  const getAllMessagesAfterLastAdminResponse = () => {
    // Sort by created date ascending
    const sortedData = _.sortBy(pastMsgs, [(o) => new Date(o.created)]);

    // Find the latest admin response
    const latestAdminResponseIndex = _.findLastIndex(
      sortedData,
      (msg) => msg.isAdminResponse === true && msg.isRecalled !== true
    );

    // Get all user messages after the latest admin response, or all messages if no admin response
    const userMessages =
      latestAdminResponseIndex === -1
        ? sortedData
        : _.filter(
            sortedData.slice(latestAdminResponseIndex + 1).map((msgObj) => ({
              ...msgObj,
              isAdminResponse: msgObj.isAdminResponse || false,
            })),
            ["isAdminResponse", false]
          );

    return userMessages.length ? userMessages : [];
  };

  const renderStatusSwitch = (ticket) => {
    if (!ticket) return;
    return (
      <CustomDropdown
        border
        sm
        data={[
          { label: "NEW", value: "New" },
          { label: "ONGOING", value: "Ongoing" },
          { label: "DONE", value: "Done" },
        ]}
        value={ticket.status}
        disabled={ticket.status == "Done"}
        onSelect={(e) => {
          setTicketStatusData(ticket._id, e.target.value);
        }}
      />
    );
  };

  const setTicketStatusData = async (index, value) => {
    try {
      let result = await axios.post(apiUrl + "/api/ticket/update", {
        ticketId: index,
        status: value,
        entityName,
      });
      fetchTicketList({
        entityName,
        filter: ticketListFilter,
      });
      if (result) {
        let updatedTkt = {
          ...selectedTicket,
          status: result.data.status,
        };

        if (value === "Done") {
          updatedTkt.isSurveySent = _.get(result.data, "isSurveySent", false);
          updatedTkt.surveyScore = _.get(result.data, "surveyScore", null);
          updatedTkt.surveySendingTime = _.get(
            result.data,
            "surveySendingTime",
            null
          );
        }

        setSelectedTicket(updatedTkt);
        scrollToBottom();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const renderMessageBox = () => {
    let adminIndex = -1;
    let userIndex = -1;

    return (
      <div
        ref={messagesEndRef}
        style={{
          fontWeight: 700,
          flexGrow: 1,
          overflow: "auto",
          backgroundColor: "#FAFAFD",
          position: "relative",
        }}
      >
        <Loading open={loading} fullScreen={false} white />
        <div style={{ padding: "0 15px" }}>
          {pastMsgs &&
            pastMsgs.map((data, index) => {
              if (!data) return <div key={index} />;
              let lastOne = index - 1 == adminIndex ? "admin" : "user";
              let lastData = pastMsgs[index - 1];
              if (index == 0)
                lastOne = !data.isAdminResponse ? "admin" : "user";
              if (data.isAdminResponse) {
                adminIndex = index;
              } else {
                userIndex = index;
              }

              return (
                <ChatItem
                  data={data}
                  discordId={discordId}
                  telegramId={telegramId}
                  selectedTicket={selectedTicket}
                  setOpenImg={setOpenImg}
                  index={index}
                  lastOne={lastOne}
                  lastData={lastData}
                  entityName={entityName}
                  onRecallMsg={handleRecallMsg}
                />
              );
            })}
          {SurveyMsg.shouldRender({ selectedTicket, surveyConfig }) && (
            <SurveyMsg
              selectedTicket={selectedTicket}
              surveyConfig={surveyConfig}
            />
          )}
        </div>
      </div>
    );
  };

  const selectMacro = (data) => {
    setMessage(_.get(data, "answer.text"));
    setFiles(_.get(data, "answer.image"));
    setSelectedMacro(data);
    handleCloseMacroModal();
  };

  const getAddress = (hasCopy = false) => {
    return (
      <div className={`${classes.address} startRow`}>
        {address &&
          getShortAddress(_.get(selectedTicket, "userWallet.address", ""))}
        {telegramId && (
          <div
            className="startRow"
            style={{
              color: "#555555",
              fontWeight: 500,
              marginLeft: address ? 15 : 0,
            }}
          >
            <IconIcTelegram width={20} height={20} className="mgr4" />
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "#777777",
              }}
            >
              {formatMessage({ id: "ticketDetail.chat.id" })}: {telegramId}
              {hasCopy && (
                <IconButton
                  style={{ marginLeft: 4 }}
                  size={16}
                  onClick={(e) => {
                    navigator.clipboard.writeText(telegramId);
                    e.stopPropagation();
                  }}
                >
                  <i className="meta-crm-icon-ic_copy2 font-size-18" />
                </IconButton>
              )}
            </span>
          </div>
        )}
        {discordId && (
          <div
            className="startRow"
            style={{
              color: "#555555",
              fontWeight: 500,
              marginLeft: address ? 15 : 0,
            }}
          >
            <IconIcDiscord2 width={20} height={20} className="mgr4" />
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "#777777",
              }}
            >
              {formatMessage({ id: "ticketDetail.chat.id" })}:{" "}
              {formatDiscordUsername(discordId)}
              {hasCopy && (
                <IconButton
                  style={{ marginLeft: 4 }}
                  size={16}
                  onClick={(e) => {
                    navigator.clipboard.writeText(
                      formatDiscordUsername(discordId)
                    );
                    e.stopPropagation();
                  }}
                >
                  <i className="meta-crm-icon-ic_copy2 font-size-18" />
                </IconButton>
              )}
            </span>
          </div>
        )}
      </div>
    );
  };
  const renderTopBar = () => {
    const userId = _.get(selectedTicket, "user._id");
    const didName = _.get(selectedTicket, "userWallet.didNames[0].name", "");
    const icon = getUserAvatarIcon(userId, theme, didName);
    return (
      <div className={classes.inboxHeader}>
        <div className="startRow">
          <img
            src={require(`assets/img/${icon}`)}
            style={{
              width: 50,
              height: 50,
              borderRadius: 50,
            }}
          />
          <div
            style={{
              paddingLeft: 8,
            }}
          >
            <div
              style={{
                color: "#555555",
                fontSize: 16,
                fontWeight: 500,
                paddingBottom: 4,
              }}
              className="startRow"
            >
              {getAddress(true)}
            </div>

            <div className="startRow" style={{ flexWrap: "wrap" }}>
              {formatMessage({ id: "ticketList.filter.Tag" })}:
              <UserTag
                width={"auto"}
                showNumber={2}
                entityName={entityName}
                userInfo={{
                  _id: userInfo._id,
                  address: selectedUserAddress,
                  tags: userInfo.tags,
                }}
              />
              <span style={{ margin: "0 4px" }}>
                {formatMessage({ id: "ticketDetail.chat.segment" })}:{" "}
              </span>
              <GroupLabel entityName={entityName} groups={groups} all={true} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMagicHappening = () => {
    return (
      <AiReplyLoader>
        <IconIcMagicStick width={16} height={16} />
        <div style={{ fontSize: 14, fontWeight: theme.fontWeight.BOLD }}>
          {formatMessage({ id: "ticketChat.magicWand.loading" })}
        </div>
        <div>
          <LoadingIcon style={{ width: 25, height: 25 }} />
        </div>
      </AiReplyLoader>
    );
  };

  const renderMagicAfterAction = () => {
    if (!isMagicWandReply) return false;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0px 16px 10px",
        }}
      >
        <StyledRetryIcButton
          size={SIZE.XS}
          onClick={() => {
            setIsMagicWandReply(false);
            handleMagicWandClick();
          }}
          style={{ padding: 0 }}
          variant="text"
        >
          <i className="meta-crm-icon-ic_refresh font-size-24" />
          {formatMessage({ id: "global.btn.retry" })}
        </StyledRetryIcButton>
        <IconButton size={16} onClick={cancelMagicWandReply}>
          <i className="meta-crm-icon-ic_cancel font-size-24" />
        </IconButton>
      </div>
    );
  };

  const renderMagicAction = () => {
    const isRender =
      isShowMagicWand() && !isMagicHappening && !isMagicWandReply;

    if (!isRender) return false;

    const toneMenuItems = AI_TONE_PREFERENCES.map((tone, index) => (
      <DropdownMenuItem
        key={index}
        selected={tonePreference == tone}
        onClick={() => {
          setTonePreference(tone);
        }}
      >
        {tone}
      </DropdownMenuItem>
    ));

    const lengthMenuItems = AI_MSG_LEN_PREFERENCES.map((tone, index) => (
      <DropdownMenuItem
        key={index}
        selected={lengthPreference == tone}
        onClick={() => {
          setLengthPreference(tone);
        }}
      >
        {tone}
      </DropdownMenuItem>
    ));

    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <StyledMagicWandButton
          onClick={handleMagicWandClick}
          style={{ padding: 0 }}
          variant="text"
        >
          <i className="meta-crm-icon-ic_magicWand font-size-14" />
        </StyledMagicWandButton>
        <div style={{ display: "inline-flex" }}>
          <Dropdown
            trigger={
              <AiReplyPreference>
                {formatMessage({ id: "global.selection.preferences" })}
                <i className="meta-crm-icon-ic_arrowdownDesk font-size-20" />
              </AiReplyPreference>
            }
            menu={[
              <DropdownNestedMenuItem
                label={`Tone-${tonePreference}`}
                rightIcon={<ArrowRight />}
                menu={toneMenuItems}
              />,
              <DropdownNestedMenuItem
                label={`Length-${lengthPreference}`}
                rightIcon={<ArrowRight />}
                menu={lengthMenuItems}
              />,
            ]}
          />
        </div>
      </div>
    );
  };

  const renderMessageInput = () => {
    if (selectedTicket.status == "Done") return;
    return (
      <div
        ref={messageFieldRef}
        style={{
          width: "100%",
          backgroundColor: "#FAFAFD",
          position: "relative",
        }}
      >
        <Loading open={sending} text={"loading..."} fullScreen={false} />
        <div
          style={{
            background: "#FFFFFF",
            borderTop: "1px solid #e6e6e6",
          }}
        >
          <Box position={"relative"}>
            <div
              style={{
                ...((isMagicWandReply || isMagicHappening) && {
                  background: "#FAF6FF",
                  margin: 10,
                  borderRadius: 5,
                }),
                padding: "0 10px",
              }}
            >
              {!isMagicHappening ? (
                <CustomOutlinedInput
                  fullWidth
                  multiline
                  noLine
                  placeholder={formatMessage({
                    id: "ticketDetail.chat.replyPlaceholder",
                  })}
                  onKeyDown={handleKey}
                  style={{
                    margin: "10px 0",
                  }}
                  value={message}
                  onChange={(e) => {
                    if (sending) return;
                    const value = e.target.value;
                    if (!value) setSelectedMacro("");
                    setMessageData(value);
                  }}
                />
              ) : (
                renderMagicHappening()
              )}
              {renderMagicAfterAction()}
              <IconButton
                size="24px"
                disabled={(!message && files.length == 0) || sending}
                onClick={doSendMessage}
                style={{ position: "absolute", right: "8px", bottom: "8px" }}
              >
                <i className="meta-crm-icon-ic_send" />
              </IconButton>
            </div>
            <Box px={1}>
              <PreviewFile
                files={files}
                onDelete={(file, index) => {
                  setFiles((prevFile) => {
                    const newFile = [...prevFile];
                    newFile.splice(index, 1);
                    return newFile;
                  });
                }}
                videoProps={{ width: 375, height: 200 }}
              />
            </Box>
            {renderMagicAction()}
          </Box>
          <div
            style={{
              borderTop: "1px solid #e6e6e6",
              padding: 10,
            }}
          >
            <Box className="betweenRow">
              <div className="startRow">
                <StyledMacroButton
                  onClick={handleOpenMacroModal}
                  style={{ padding: 0 }}
                  variant="text"
                >
                  <i className="meta-crm-icon-ic_macro font-size-24" />
                </StyledMacroButton>

                <IconFileUploader
                  uploadFile={(files) =>
                    setFiles((prev) => [...prev, ...files])
                  }
                  multiple={true}
                  inputProps={{ accept: "image/*,application/pdf" }}
                  style={{ border: "none", padding: 0 }}
                  renderLook={
                    <i className="meta-crm-icon-ic_img font-size-20" />
                  }
                />
              </div>
              <div className="endRow">
                <div style={{ marginRight: 4, fontSize: 10, color: "#777777" }}>
                  {formatMessage({ id: "ticketDetail.chat.status" })}:
                </div>
                {renderStatusSwitch(selectedTicket)}
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  };

  const handleOpenMacroModal = () => {
    // 這裡去判斷是否有macro 然後開啟不同的modal
    !_.isEmpty(macroList) ? setMacroModalOpen(true) : setNoMacroModalOpen(true);
  };

  const handleCloseMacroModal = () => {
    setMacroModalOpen(false);
  };

  const handleCloseNoMacroModal = () => {
    setNoMacroModalOpen(false);
  };

  const handleRouteToMacroPage = () => {
    navigate(`/${entityName}/settings/macros`);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
          height: "auto",
        },
      }}
    >
      {renderTopBar()}
      {renderMessageBox()}
      {userCanEdit && (
        <>
          {renderMessageInput()}
          <BasicModal
            open={noMacroModalOpen}
            onClose={handleCloseNoMacroModal}
            modalTitle={formatMessage({
              id: "ticketDetail.chat.notMacroModalTitle",
            })}
            modalContent={formatMessage({
              id: "ticketDetail.chat.notMacroModalContent",
            })}
            confirmText={formatMessage({ id: "global.btn.start" })}
            cancelText={formatMessage({ id: "global.btn.cancel" })}
            onHandleConfirm={handleRouteToMacroPage}
          />
          <MacroModal
            channel={selectedTicket.from}
            open={macroModalOpen}
            onClose={handleCloseMacroModal}
            onSelectMacro={selectMacro}
            onLinkifyOptions={options}
          />
          <Modal
            open={Boolean(openImg)}
            className={"modal"}
            onClose={() => setOpenImg("")}
          >
            <div style={{ position: "relative", outline: "none" }}>
              <IconButton
                onClick={() => setOpenImg("")}
                style={{
                  position: "absolute",
                  right: 0,
                  top: "-50px",
                  padding: 0,
                }}
              >
                <img src={require("assets/img/close.svg").default} />
              </IconButton>
              <img
                src={openImg}
                style={{
                  width: "80vw",
                  height: "80vh",
                  objectFit: "contain",
                }}
              />
              <div
                style={{
                  textAlign: "right",
                  maxWidth: "80vw",
                  margin: "0 auto",
                }}
              >
                <a href={openImg} download target={"_blank"} rel="noreferrer">
                  <img src={require("assets/img/download.svg").default} />
                </a>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Chat;
