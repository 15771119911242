import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useContext, useState } from "react";
import { useOutletContext } from "react-router";
import { SinglePerformanceContext } from "../../context/SinglePerformanceProvider";
import CustomUnassignModal from "components/CustomModals/CustomUnassignModal";

export default function UnassignModal({ handleClose, selected }) {
  // find the same tags in selected customers
  const tags = selected.reduce((acc, item) => {
    const tagIds = item.tags.map((tag) => ({
      ...tag,
      label: tag.value,
    }));
    return acc.length === 0
      ? tagIds
      : acc.filter((tag) => tagIds.some((t) => t.id === tag.id));
  }, []);
  const { setUpdate } = useContext(SinglePerformanceContext);
  const dispatchSnackbar = useDispatchSnackbar();
  const [entityName] = useOutletContext();
  const [unassignTags, setUnassignTags] = useState([]);

  function handleClickCheckbox(e, tag) {
    if (e.target.checked) {
      setUnassignTags([...unassignTags, tag._id]);
    } else {
      setUnassignTags(unassignTags.filter((id) => id !== tag._id));
    }
  }

  async function handleUnAssign() {
    try {
      const data = {
        type: "user",
        tagIds: unassignTags,
        targetIds: selected.map((item) => item._id),
      };

      const result = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/batchRemove/user`,
        data
      );
      setUpdate((prev) => prev + 1);

      dispatchSnackbar({
        message: "Batch unassign tags success.",
        variant: "info",
      });
    } catch (err) {
      dispatchSnackbar({
        message: "Batch unassign tags failed." + err,
        variant: "error",
      });
    } finally {
      handleClose();
    }
  }

  return (
    <CustomUnassignModal
      open={true}
      tags={tags}
      unassignTags={unassignTags}
      handleClose={handleClose}
      handleClickCheckbox={handleClickCheckbox}
      handleUnAssign={handleUnAssign}
    />
  );
}
