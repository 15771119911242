import React from "react";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Box,
} from "@mui/material";
import _ from "lodash";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/styles";

export default function CustomRadioGroup({
  data,
  value,
  onChange,
  disabled,
  alignItems = "center",
  row,
  readonly = false,
  ...props
}) {
  const theme = useTheme();

  if (readonly) {
    const selectedOption = data && data?.find((r) => r.value === value);
    return <Box mb={1}>{selectedOption ? selectedOption.label : ""}</Box>;
  }

  return (
    <FormControl fullWidth>
      <RadioGroup row={row} value={value} onChange={onChange} {...props}>
        {_.map(data, (r, i) => {
          return (
            <FormControlLabel
              value={r.value}
              sx={{
                alignItems,
                ...r.style,
              }}
              key={i}
              disabled={r.disabled || disabled}
              control={
                <Radio
                  checkedIcon={
                    <CheckCircleIcon
                      style={{ color: theme.customColors.purple[500] }}
                    />
                  }
                  icon={
                    <RadioButtonUncheckedIcon
                      style={{ color: theme.customColors.purple[500] }}
                    />
                  }
                />
              }
              label={r.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

CustomRadioGroup.defaultProps = {};
