import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { Box } from "@mui/material";

export const StyledCustomizationContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontFamily: "Inter",
  "& *": {
    fontFamily: "Inter",
  },
}));

export const StyledGeneralSettingsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({ maxWidth: 780 }));

export const StyledSettingTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[700],
  fontSize: "16px",
  marginBottom: "10px",
}));

export const StyledDescription = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  lineHeight: "15px",
  fontSize: 12,
  marginBottom: "24px",
  color: theme.customColors.grey[600],
}));

export const StyledDraggableListContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "fit-content",
}));

export const StyledTicketNameTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[700],
  paddingLeft: "45px",
  marginBottom: "8px",
}));

export const StyledAddTicketTypeButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "500px",
  cursor: "pointer",
  backgroundColor: "transparent",
  border: `1px dashed ${theme.customColors.grey[300]}`,
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "8px",
  color: theme.customColors.grey[500],
  height: "40px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  "& .plus": {
    marginRight: "15px",
    fontWeight: 900,
    display: "flex",
  },
}));

export const StyledAlertText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.pink[600],
  display: "flex",
}));

export const StyledLine = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.customColors.grey[300],
  marginTop: "32px",
  marginBottom: "32px",
}));

export const StyledDiscordSettingContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: 712,
}));

export const StyledTagsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  marginRight: "8px",
  flexWrap: "wrap",
  marginBottom: "12px",
}));

export const StyledDiscordTag = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color = "#707070" }) => ({
  color: color,
  border: `1px solid ${color}`,
  borderRadius: "20px",
  padding: "4px 8px",
  marginBottom: "12px",
  fontWeight: "500",
  marginRight: "8px",
}));

export const StyledDiscordTicketContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginTop: "16px",
}));

export const StyledDiscordTicketTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  color: theme.customColors.grey[700],
}));

export const StyledFixButtonContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "fixed",
  zIndex: "1300",
  left: "200px",
  right: 0,
  bottom: 0,
  padding: "12px",
  boxShadow: `0px -5px 20px 0px #0000000D`,
  display: "flex",
  justifyContent: "center",
  background: theme.customColors.white,
  [theme.breakpoints.down("md")]: {
    left: 0,
  },
}));
