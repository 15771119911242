import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useIntl } from "react-intl";
import axios from "axios";
import { useTheme } from "@mui/styles";

import {
  Button,
  SIZE,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";

import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { apiUrl } from "features/configure";
import { selectAiSupportConfig } from "features/metadesk/redux/aiSupport/aiSupport.selector";

import IconIcAlpacaMagicWand from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcAlpacaMagicWand";
import IconIcMagicWandRoundFilled from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMagicWandRoundFilled";
import IconIcMagicStick from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMagicStick";

import {
  ChatDisplayContainer,
  ChatHeaderArea,
  ChatInputArea,
  ChatSimulationWrapper,
  MsgBody,
  MsgHeader,
  MsgRow,
  MsgWrapper,
  SendActionArea,
  TrainingMsgContainer,
} from "./ChatSimulation.styles";
import useGetPagePermission from "hooks/useGetPagePermission";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";

const CHAT_DISABLE_CONDITIONS = ["inprogress", "ready", "failed"];

const ChatSimulation = ({ tone, length }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [chats, setChats] = useState([]);
  const chatAreaRef = useRef(null);
  const inputRef = useRef(null);
  const selectedAiSupport = useSelector(selectAiSupportConfig);

  const documentSyncStatus = _.get(
    selectedAiSupport,
    "config.aiConfig.documentSyncStatus"
  );

  const isChatWindowDisabled = _.includes(
    CHAT_DISABLE_CONDITIONS,
    documentSyncStatus
  );

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  useEffect(() => {
    setChats([]);
  }, [tone, length]);

  const handleKey = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      askQuestion(question);
    }
  };

  const askQuestion = async (q) => {
    const newAdminQuestion = {
      type: "Admin",
      msg: q,
    };
    let newChats = [...chats, newAdminQuestion];
    setChats(newChats);
    setQuestion("");

    try {
      setIsLoading(true);
      const answer = await fetchAnswer(q);
      const newReply = {
        type: "MagicWand",
        msg: answer,
      };
      newChats = [...newChats, newReply];
      setChats(newChats);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAnswer = (query) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(
          `${apiUrl}/api/aiSupport//gitbook/${entityName}/query`,
          { query, tone, length }
        );
        resolve(result.data.answer);
      } catch (err) {
        reject(err);
      }
    });
    return promise;
  };

  const scrollToBottom = () => {
    const offsetBottom = chatAreaRef.current?.scrollHeight;
    chatAreaRef.current?.scrollTo({ top: offsetBottom });
  };

  const renderAdminQuestion = (chat, isFirstMsg = false) => {
    return (
      <MsgRow
        style={{
          justifyContent: "flex-end",
          marginTop: !isFirstMsg ? 30 : "none",
        }}
      >
        <MsgWrapper
          style={{
            alignItems: "flex-end",
          }}
        >
          <MsgHeader>{"You"}</MsgHeader>
          <MsgBody
            style={{
              borderRadius: "10px 0 10px 10px",
              background: "white",
              border: `1px solid ${theme.customColors.grey[400]}`,
            }}
          >
            {chat.msg}
          </MsgBody>
        </MsgWrapper>
        <IconIcAlpacaMagicWand width={25} height={25} />
      </MsgRow>
    );
  };

  const renderMagicWandReply = (chat) => {
    return (
      <MsgRow
        style={{
          marginTop: 30,
        }}
      >
        <IconIcMagicWandRoundFilled width={25} height={25} />
        <MsgWrapper>
          <MsgHeader>{"Magic Wand"}</MsgHeader>
          <MsgBody
            style={{
              borderRadius: "0 10px 10px 10px",
              background: "#FAF6FF",
            }}
          >
            {chat.msg}
          </MsgBody>
        </MsgWrapper>
      </MsgRow>
    );
  };

  const renderMagicHappening = () => {
    return (
      <MsgRow
        style={{
          marginTop: 30,
        }}
      >
        <IconIcMagicWandRoundFilled width={25} height={25} />
        <MsgWrapper
          style={{
            rowGap: 12,
          }}
        >
          <MsgHeader>{"Magic Wand"}</MsgHeader>
          <MsgBody
            style={{
              borderRadius: "0 10px 10px 10px",
              background: "#FAF6FF",
              display: "flex",
              columnGap: 4,
              alignItems: "center",
            }}
          >
            <IconIcMagicStick width={14} height={14} />
            <div style={{ fontSize: theme.fontSize.TINY }}>
              {formatMessage({ id: "ticketChat.magicWand.loading" })}
            </div>
            <div>
              <LoadingIcon style={{ width: 25, height: 25 }} />
            </div>
          </MsgBody>
        </MsgWrapper>
      </MsgRow>
    );
  };

  const renderIsTrainingMsg = () => {
    return (
      <TrainingMsgContainer>
        <div>
          <LoadingIcon style={{ width: 25, height: 25 }} />
        </div>
        <div>
          {formatMessage({ id: "aiManagement.dataSource.status.training" })}
        </div>
      </TrainingMsgContainer>
    );
  };

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("aiSupport");

  return (
    <ChatSimulationWrapper
      style={{
        ...(isChatWindowDisabled && {
          opacity: 0.5,
          pointerEvents: "none",
          position: "relative",
        }),
      }}
    >
      {isChatWindowDisabled && renderIsTrainingMsg()}
      <ChatHeaderArea>
        <div>
          <div
            style={{
              fontWeight: theme.fontWeight.BOLD,
              fontSize: 16,
            }}
          >
            Support Centre
          </div>
          <span style={{ fontSize: theme.fontSize.TINY }}>
            {formatMessage({
              id: "tickets.header.ticketId",
            })}{" "}
            0x057...51057 <i className="meta-crm-icon-ic_copy2 font-size-14" />
          </span>
        </div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <div style={{ fontSize: 14 }}>Status:</div>
          <div
            style={{
              marginTop: 4,
              padding: "4px 6px",
              border: `1px solid ${theme.customColors.grey[400]}`,
              borderRadius: 3,
            }}
          >
            Ongoing
          </div>
        </div>
      </ChatHeaderArea>
      <ChatDisplayContainer ref={chatAreaRef}>
        {chats.map((chat, idx) => {
          let isFirstMsg = idx === 0;
          if (chat.type === "Admin")
            return renderAdminQuestion(chat, isFirstMsg);
          else return renderMagicWandReply(chat);
        })}
        {isLoading && renderMagicHappening()}
      </ChatDisplayContainer>
      {canEdit && (
        <div>
          <ChatInputArea>
            <CustomOutlinedInput
              ref={inputRef}
              fullWidth
              multiline
              noLine
              placeholder={formatMessage({
                id: "aiManagement.magicWand.simulation.placeholder",
              })}
              onKeyDown={handleKey}
              style={{ width: "100%" }}
              value={question}
              onChange={(e) => {
                const { value } = e.target;
                setQuestion(value);
              }}
            />
          </ChatInputArea>
          <SendActionArea>
            <Button
              color={COLOR.SECONDARY}
              size={SIZE.LARGE}
              variant={VARIANT.CONTAINED}
              style={{
                width: 70,
              }}
              onClick={() => {
                if (!question.length) return;
                askQuestion(question);
              }}
            >
              {formatMessage({
                id: "global.btn.send",
              })}
            </Button>
          </SendActionArea>
        </div>
      )}
    </ChatSimulationWrapper>
  );
};

export default ChatSimulation;
