import React, { useState, useEffect, useMemo } from "react";
import _, { set } from "lodash";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { Box, Grid, Modal } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useNavigate } from "react-router-dom";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import IntegrationSuccess from "./IntegrationSuccess";
import IntegrationSettings from "./IntegrationSettings";
import axios from "axios";
import { apiUrl } from "features/configure";
import { Loading } from "features/common";
import { alertServerError, parseCustomDomainUrl } from "features/helpers/utils";
import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { useIntl } from "react-intl";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import BasicModal from "features/metadesk/chat/BasicModal";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import {
  StyledActionButtonContainer,
  StyledDiscordTicketTypePreviewImg,
  StyledStepsContainer,
  StyledSettingStepNumber,
  StyledStepSectionHeader,
  StyledHintContainer,
} from "./IntegrationDiscord.styles";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import {
  StyledGeneralSettingsContainer,
  StyledDescription,
  StyledDiscordSettingContainer,
  StyledDiscordTicketContainer,
  StyledDiscordTicketTitle,
} from "../Customization/DiscordBot/DiscordBot.styles";
import AssignTicketType from "../Customization/AssignTicketType/AssignTicketType";
import useGetPagePermission from "hooks/useGetPagePermission";
import useAssignTicket from "./hook/useAssignTicket";
const HorizontalLine = ({ theme, isSetting }) => {
  if (!isSetting) return null;

  return (
    <hr
      style={{
        borderColor: theme.customColors.grey[300],
        opacity: 0.2,
        marginBottom: 20,
        width: "100%",
      }}
    />
  );
};

const IntegrationDiscord = ({ isSetting }) => {
  const [entityName, isCustomDomain] = useOutletContext();
  const [searchParams] = useSearchParams();
  const serverId = searchParams.get("serverId");
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [channel, setChannel] = useState("");
  const [format, setFormat] = useState("channel");
  const [initData, setInitData] = useState({});
  const [botIntro, setBotIntro] = useState({
    title: "",
    description: "",
  });
  const [threadAutoArchiveDuration, setThreadAutoArchiveDuration] =
    useState(10080);

  const { formatMessage } = useIntl();

  const { fetchEntityConfig, ticketTypeList } = useFetchEntityConfig();

  const {
    ticketList,
    chooseTicketType,
    setChooseTicketType,
    getTicketTypeList,
    hasChanges,
    handleSaveTicket,
  } = useAssignTicket({ ticketTypeList, type: "Discord" });

  useEffect(() => {
    // 在組件載入時取得 Ticket 列表
    getTicketTypeList();
  }, [ticketTypeList]);

  const [openDisconnect, setOpenDisconnect] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const computer = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(async () => {
    if (!isSetting) return refreshServerData();

    const result = await axios.get(
      apiUrl + "/api/discord/ticketBot/" + entityName
    );
    const data = _.get(result, "data", {});
    const roles = _.get(data, "roles", []);
    const ticketFormat = _.get(data, "ticketFormat", "thread");
    const channels = _.get(data, "guild.textChannels", []);
    const channelOptions = _.map(channels, (c) => {
      return { label: c.channelName, value: c.channelId };
    });
    const newChannel = _.find(
      channelOptions,
      (c) => c.value == data.mainChannel
    );
    setInitData({
      selectedRoles: roles,
      channel: data.mainChannel,
      ticketFormat,
      threadAutoArchiveDuration: _.get(
        data,
        "threadAutoArchiveDuration",
        10080
      ),
      introTitle: _.get(data, "introTitle", ""),
      introDescription: _.get(data, "introDescription", ""),
      chooseTicketType: data.ticketTypes,
    });

    setRoleList(_.get(data, "guild.roles", []));
    setChannelList(channelOptions);
    setSelectedRoles(roles);
    setChannel(newChannel);
    setFormat(ticketFormat);
    setBotIntro({
      title: _.get(data, "introTitle", ""),
      description: _.get(data, "introDescription", ""),
    });
    setThreadAutoArchiveDuration(
      _.get(data, "threadAutoArchiveDuration", 10080)
    );
    if (roles.length == 0 || !data.guild) {
      //old version
      refreshServerData();
    }
  }, []);

  const roleListFiltered = useMemo(() => {
    return _.map(
      _.filter(roleList, (o) => o.name !== "@everyone"),
      (role) => {
        return { label: role.name, value: role.id };
      }
    );
  }, [roleList]);

  const refreshServerData = async () => {
    try {
      setFetchLoading(true);
      const result = await axios.get(
        apiUrl + `/api/discord/ticketBot/${entityName}/refreshServerData`
      );

      const { channelList, roleList } = result.data;
      setChannelList(
        _.map(channelList, (c) => {
          return { label: c.channelName, value: c.channelId };
        })
      );
      setRoleList(roleList);
      if (
        channel &&
        !_.find(channelList, (o) => o.channelId == channel.value)
      ) {
        setChannel("");
      }
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setFetchLoading(false);
    }
  };

  const alertError = (error) => {
    let errMsg = _.get(error, "response.data.error", "Error");
    if (errMsg == "Missing Access" || errMsg == "Missing Permissions") {
      dispatch(
        enqueueSnackbar({
          message: isSetting
            ? "Our bot is unable to set the selected roles' permissions for the ticket channel due to limited access.\n Please follow the video instructions to manually add and adjust the bot’s permissions to complete the integration."
            : `Our bot is unable to access the selected channel. \nPlease follow the video instructions to manually add\nand adjust the bot’s permissions to complete the integration.`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            action: (
              <Button
                color={COLOR.ERROR}
                variant={VARIANT.CONTAINED}
                onClick={() => {
                  setOpen(true);
                }}
              >
                See the video
              </Button>
            ),
          },
        })
      );
    } else {
      dispatch(alertServerError(error));
    }
  };

  const activateDiscord = async () => {
    if (!serverId || !channel) {
      return;
    }
    try {
      setLoading(true);
      const data = {
        discordServerId: serverId,
        mainChannel: channel?.value,
        roles: selectedRoles,
        ticketFormat: format,
        introTitle: botIntro.title,
        introDescription: botIntro.description,
      };
      const result = await axios.post(
        apiUrl + `/api/discord/ticketBot/${entityName}/connect`,
        data
      );
      await handleSaveTicket();
      fetchEntityConfig({ entityName });
      setIsSuccess(true);
    } catch (error) {
      alertError(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateInfo = async () => {
    try {
      setLoading(true);
      await axios.put(apiUrl + `/api/discord/ticketBot/${entityName}/update`, {
        roles: selectedRoles,
        mainChannel: channel?.value,
        ticketFormat: format,
        introTitle: botIntro.title,
        introDescription: botIntro.description,
        threadAutoArchiveDuration:
          format === "thread" ? threadAutoArchiveDuration : null,
      });
      await handleSaveTicket();
      setInitData({
        selectedRoles,
        channel: channel?.value,
        introTitle: botIntro.title,
        introDescription: botIntro.description,
        threadAutoArchiveDuration:
          format === "thread" ? threadAutoArchiveDuration : null,
      });
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/settings/general/integration"
        )
      );
    } catch (error) {
      alertError(error);
    } finally {
      setLoading(false);
      setOpenDisconnect(false);
      fetchEntityConfig({
        entityName,
      });
    }
  };

  const onDisconnect = async () => {
    try {
      setLoading(true);
      await axios.post(
        apiUrl + `/api/discord/ticketBot/${entityName}/disconnect`
      );
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/settings/general/integration"
        )
      );
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setLoading(false);
      setOpenDisconnect(false);
      fetchEntityConfig({
        entityName,
      });
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };

  const getTicketFormatPreviewImg = (tktFormat) => {
    switch (tktFormat) {
      case "channel":
        return require("assets/img/ticketFormatPreviewChannel.svg").default;
      case "thread":
        return require("assets/img/ticketFormatPreviewThread.svg").default;
      default:
        return require("assets/img/ticketFormatPreviewChannel.svg").default;
    }
  };
  const pagePermission = useGetPagePermission();
  const integrationPermission = pagePermission("integration");

  const DiscordChannelSettingSteps = [
    {
      stepNumber: 1,
      optional: false,
      title: formatMessage({ id: "discordBotSettings.selectChannel.subtitle" }),
      description: formatMessage({
        id: "discordBotSettings.selectChannel.description",
      }),
      topActions: (
        <Button
          color={COLOR.INFO}
          size={SIZE.XS}
          variant={VARIANT.OUTLINED}
          onClick={refreshServerData}
        >
          <i className="meta-crm-icon-ic_refresh font-size-24" />
          {"Refetch"}
        </Button>
      ),
      content: (
        <>
          <AutoCompleteDropdown
            options={channelList}
            value={channel}
            onChange={(e, newValue) => {
              setChannel(newValue);
            }}
          />
        </>
      ),
      imageContent: null,
      fullWidth: false,
      hr: true,
    },
    {
      stepNumber: 2,
      optional: false,
      title: formatMessage({ id: "discordBotSettings.ticketFormat.subtitle" }),
      description: formatMessage({
        id: "discordBotSettings.ticketFormat.description",
      }),
      topActions: null,
      content: (
        <>
          <Box mb={2}>
            <CustomRadioGroup
              alignItems="flex-start"
              value={format}
              onChange={(e) => {
                const currFormat = e.target.value;
                if (format !== currFormat) {
                  setFormat(currFormat);
                }
              }}
              data={[
                {
                  label: (
                    <div style={{ padding: "6px 0" }}>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: theme.fontWeight.BOLD,
                        }}
                      >
                        {formatMessage({
                          id: "discordBotSettings.ticketFormat.channel.subtitle",
                        })}
                      </div>
                      <p
                        style={{
                          fontSize: 14,
                          color: theme.customColors.grey[600],
                        }}
                      >
                        {formatMessage(
                          {
                            id: "discordBotSettings.ticketFormat.channel.description",
                          },
                          {
                            br: <br />,
                          }
                        )}
                      </p>
                    </div>
                  ),
                  value: "channel",
                },
                {
                  label: (
                    <div style={{ padding: "6px 0" }}>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: theme.fontWeight.BOLD,
                        }}
                      >
                        {formatMessage({
                          id: "discordBotSettings.ticketFormat.thread.subtitle",
                        })}
                      </div>
                      <p
                        style={{
                          fontSize: 14,
                          color: theme.customColors.grey[600],
                        }}
                      >
                        {formatMessage({
                          id: "discordBotSettings.ticketFormat.thread.description",
                        })}
                      </p>
                    </div>
                  ),
                  value: "thread",
                },
              ]}
            />
          </Box>
        </>
      ),
      imageContent: isSetting ? (
        <div style={{ display: "flex", width: 410 }}>
          <img
            src={getTicketFormatPreviewImg(format)}
            style={{ width: "100%" }}
          />
        </div>
      ) : null,
      fullWidth: true,
      columnGap: 120,
      hr: isSetting,
    },
    {
      stepNumber: 3,
      optional: true,
      title: formatMessage({ id: "DiscordBotCustomization.intro.title" }),

      topActions: null,
      content: (
        <Box width="100%">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <StyledDescription style={{ marginTop: "4px" }}>
                {formatMessage({
                  id: "DiscordBotCustomization.intro.description",
                })}
              </StyledDescription>
            </Grid>
            <Grid item xs={12} md={8}>
              <InputField
                // readonly={readonly}
                fullWidth
                size={SIZE.LARGE}
                variant={VARIANT.OUTLINED}
                title={"Title"}
                placeholder="Open a ticket"
                value={botIntro.title || ""}
                maxLength={30}
                mb={"20px"}
                onChange={(e) => {
                  setBotIntro((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
              <InputField
                // readonly={readonly}
                fullWidth
                size={SIZE.LARGE}
                multiline
                rows={5}
                variant={VARIANT.OUTLINED}
                title={"Description"}
                maxLength={500}
                placeholder="Please select the type of ticket you would like to open"
                value={botIntro.description || ""}
                onChange={(e) => {
                  setBotIntro((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>{" "}
        </Box>
      ),
      imageContent: null,
      hr: true,
      fullWidth: true,
    },
    format === "thread" && integrationPermission.canEdit
      ? {
          stepNumber: 4,
          optional: true,
          title: formatMessage({
            id: "DiscordBotCustomization.archivePeriod.title",
          }),

          topActions: null,
          content: (
            <Box width="100%" display="flex">
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <StyledDescription style={{ marginTop: "4px" }}>
                    {formatMessage({
                      id: "DiscordBotCustomization.archivePeriod.description",
                    })}
                  </StyledDescription>
                </Grid>
                <Grid item xs={12} md={8}>
                  <CustomDropdown
                    // readonly={readonly}
                    border
                    fullWidth
                    size={SIZE.LARGE}
                    value={threadAutoArchiveDuration}
                    onSelect={(e) => {
                      setThreadAutoArchiveDuration(e.target.value);
                    }}
                    data={[
                      {
                        label: formatMessage({
                          id: "DiscordBotCustomization.archivePeriod.oneHour",
                        }),
                        value: 60,
                      },
                      {
                        label: formatMessage({
                          id: "DiscordBotCustomization.archivePeriod.oneDay",
                        }),
                        value: 1440,
                      },
                      {
                        label: formatMessage({
                          id: "DiscordBotCustomization.archivePeriod.threeDays",
                        }),
                        value: 4320,
                      },
                      {
                        label: formatMessage({
                          id: "DiscordBotCustomization.archivePeriod.oneWeek",
                        }),
                        value: 10080,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
          ),
          imageContent: null,
          hr: true,
          fullWidth: true,
          sectionFullWidth: true,
        }
      : null,
    {
      stepNumber: format !== "thread" ? 4 : 5,
      optional: true,
      title: formatMessage({
        id: "DiscordBotCustomization.ticketType.title",
      }),
      description: formatMessage({
        id: "DiscordBotCustomization.ticketType.description",
      }),

      topActions: null,
      content: (
        <StyledGeneralSettingsContainer style={{ overflowX: "auto" }}>
          <StyledDiscordSettingContainer>
            <StyledDiscordTicketContainer>
              <StyledDiscordTicketTitle>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  pl={"55px"}
                >
                  <Box>
                    {formatMessage({
                      id: "DiscordBotCustomization.ticketType.name",
                    })}
                  </Box>

                  <Box>
                    <Box pl={"28px"}>
                      {formatMessage({
                        id: "DiscordBotCustomization.ticketType.color",
                      })}
                    </Box>
                  </Box>

                  <Box>
                    {formatMessage({
                      id: "DiscordBotCustomization.ticketType.requireAddress",
                    })}
                  </Box>
                  <Box width={"125px"}></Box>
                </Box>
              </StyledDiscordTicketTitle>

              <AssignTicketType
                serviceName={"discord"}
                canEdit={integrationPermission.canEdit}
                ticketList={ticketList}
                chooseTicketType={chooseTicketType}
                setChooseTicketType={setChooseTicketType}
              />
            </StyledDiscordTicketContainer>
          </StyledDiscordSettingContainer>
        </StyledGeneralSettingsContainer>
      ),
      imageContent: null,
      hr: true,
      fullWidth: true,
      sectionFullWidth: true,
    },
    {
      stepNumber: format !== "thread" ? 5 : 6,
      optional: true,
      title: formatMessage({
        id: "discordBotSettings.supportRole.subtitle",
      }),
      description: null,
      topActions: null,
      content: (
        <Box style={{ maxWidth: "fit-content", margin: "8px 0 0 32px" }}>
          <div
            style={{
              color: theme.customColors.grey[600],
            }}
          >
            <p>
              {formatMessage({
                id: "discordBotSettings.supportRole.description",
              })}
            </p>
          </div>
          <StyledHintContainer>
            <i className="meta-crm-icon-ic_info font-size-14" />
            <p style={{ fontSize: theme.fontSize.TINY }}>
              {formatMessage({
                id: "discordBotSettings.supportRole.explanation",
              })}
            </p>
          </StyledHintContainer>
          {!_.isEmpty(roleListFiltered) ? (
            <Box overflow={"auto"} mt={2}>
              <AutoCompleteDropdown
                options={roleListFiltered}
                isTag
                multiple
                maxHeightDropdownMenu={"150px"}
                value={_.filter(roleListFiltered, (option) =>
                  selectedRoles.includes(option.value)
                )}
                onChange={(e, newValue) => {
                  setSelectedRoles(newValue.map((item) => item.value));
                }}
              />
            </Box>
          ) : (
            <p
              style={{
                marginTop: 20,
                color: theme.customColors.grey[500],
              }}
            >{`“You don't have any role.”`}</p>
          )}
        </Box>
      ),
      imageContent: null,
      fullWidth: false,
      hr: true,
    },
  ];

  const filteredSteps = DiscordChannelSettingSteps.filter(Boolean);
  const renderDiscordChannelSetting = () => {
    return (
      <Box position={"relative"}>
        <Loading open={fetchLoading} fullScreen={false} />
        {filteredSteps.map((config, index) => (
          <>
            <div
              key={index}
              style={{
                marginBottom: 20,
                ...(config.fullWidth
                  ? {
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: config.columnGap,
                    }
                  : { maxWidth: 576 }),
              }}
            >
              <section
                style={{ width: config.sectionFullWidth ? "100%" : "auto" }}
              >
                <div style={{ display: "flex" }}>
                  <StyledStepSectionHeader>
                    <Box
                      className="subTitle"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isSetting && config.stepNumber && (
                        <StyledSettingStepNumber style={{ marginRight: 8 }}>
                          {config.stepNumber}
                        </StyledSettingStepNumber>
                      )}
                      {config.title}
                      {!config.optional && <span className="required">*</span>}
                    </Box>
                    {config.description && (
                      <Box mb={"16px"} className="description">
                        {config.description}
                      </Box>
                    )}
                  </StyledStepSectionHeader>
                  {config.topActions}
                </div>
                {config.content}
              </section>
              {config.imageContent}
            </div>
            {config.hr && <HorizontalLine theme={theme} isSetting />}
          </>
        ))}
      </Box>
    );
  };

  const renderFailedModal = () => {
    return (
      <BasicModal
        open={open}
        maxWidth="1100px"
        onClose={() => setOpen(false)}
        modalTitle={"How to manually configure bot channel permissions"}
        textAlign="left"
        modalContent={
          <Box mt={"24px"} position={"relative"}>
            <iframe
              width="1024"
              height="576"
              src={
                "https://www.youtube.com/embed/6k1iHRaIIgM?si=yKnphsDV1lVZ1Hnf"
              }
              title="Discord Bot Auth Error"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        }
      />
    );
  };

  const disabledSaved =
    _.isEqual(_.sortBy(initData.selectedRoles), _.sortBy(selectedRoles)) && // 角色列表相同
    initData.channel == channel?.value && // 主頻道相同
    initData.ticketFormat == format && // Ticket 格式相同
    initData.introTitle == botIntro.title && // 機器人介紹標題相同
    initData.introDescription == botIntro.description && // 機器人介紹描述相同
    initData.threadAutoArchiveDuration == threadAutoArchiveDuration && // Auto Archive Period 相同
    !hasChanges;

  if (isSetting) {
    return (
      <IntegrationSettings
        controlHeaderView={true}
        headerContent={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div>
              <div className="lgTitle" style={{ marginTop: 18 }}>
                {formatMessage({ id: "integration.discordBotSettings.title" })}
              </div>
              <div className="description" style={{ lineHeight: "20px" }}>
                {formatMessage({
                  id: "integration.discordBotSettings.description",
                })}
              </div>
            </div>
            <div>
              <Button
                color={COLOR.SECONDARY}
                size={SIZE.LARGE}
                onClick={() => {
                  setOpenDisconnect(true);
                }}
              >
                {formatMessage({ id: "global.btn.disconnect" })}
              </Button>
            </div>
          </div>
        }
        actions={
          <Button
            color={COLOR.SECONDARY}
            size={SIZE.XL}
            disabled={disabledSaved}
            onClick={onUpdateInfo}
            style={{ width: 120 }}
          >
            {disabledSaved
              ? formatMessage({ id: "global.btn.saved" })
              : formatMessage({ id: "global.btn.save" })}
          </Button>
        }
        content={
          <div>
            <Loading open={loading} />
            {renderFailedModal()}
            <BasicDialog
              open={openDisconnect}
              onClose={() => setOpenDisconnect(false)}
              onHandleConfirm={onDisconnect}
              dialogTitle="Disconnect Discord"
              dialogContent="Disconnecting the Discord integration will cease its functions on your Discord server and close all active tickets originating from Discord, while your historical data remains preserved."
              confirmText="Confirm"
              cancelText="Cancel"
            />

            <div style={{ background: "white", padding: 20 }}>
              <Grid item xs={12}>
                {renderDiscordChannelSetting()}
              </Grid>
            </div>
          </div>
        }
      />
    );
  }

  const renderConnectDiscordSteps = () => {
    return (
      <StyledStepsContainer>
        <div style={{ maxWidth: 574 }}>
          <Box style={{ ...(computer && { marginBottom: 90 }) }}>
            <div className="lgTitle">
              {formatMessage({
                id: "discordBotIntegration.installation.title",
              })}
            </div>
            <div className="description">
              {formatMessage({
                id: "discordBotIntegration.installation.description",
              })}
            </div>
          </Box>
          <div style={{ marginTop: 40 }}>{renderDiscordChannelSetting()}</div>
          <StyledDiscordTicketTypePreviewImg>
            <img
              src={getTicketFormatPreviewImg(format)}
              style={{ width: "100%" }}
            />
          </StyledDiscordTicketTypePreviewImg>
          <StyledActionButtonContainer>
            <Button
              color={COLOR.SECONDARY}
              variant={VARIANT.OUTLINED}
              onClick={() => navigate(-1)}
              size={SIZE.LARGE}
              style={{ width: 120 }}
            >
              {formatMessage({ id: "global.btn.back" })}
            </Button>
            <Button
              color={COLOR.SECONDARY}
              disabled={!channel || loading}
              onClick={activateDiscord}
              size={SIZE.LARGE}
              style={{ width: 120 }}
            >
              {formatMessage({ id: "global.btn.save" })}
            </Button>
          </StyledActionButtonContainer>
          <StyledHintContainer>
            <i className="meta-crm-icon-ic_info font-size-14" />
            <p style={{ fontSize: theme.fontSize.TINY }}>
              {formatMessage({
                id: "discordBotInstallation.ending.explanation",
              })}
            </p>
          </StyledHintContainer>
        </div>
      </StyledStepsContainer>
    );
  };

  const renderIntegrationScreen = () => {
    if (isSuccess) {
      return <IntegrationSuccess />;
    }
    return renderConnectDiscordSteps();
  };

  return (
    <div style={{ minHeight: "calc(100vh - 30px)", background: "white" }}>
      <Loading open={loading} white />
      {renderFailedModal()}
      {renderIntegrationScreen()}
    </div>
  );
};

export default IntegrationDiscord;
