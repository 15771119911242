import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { alpha } from "@mui/material/styles";

export const StyledSelectCampaignTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 900,
  textTransform: "capitalize",
  marginBottom: "16px",
}));

export const StyledSelectCampaignSubtitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "130%",
  color: theme.customColors.grey[600],
  marginBottom: "36px",
}));

export const StyledSelectCampaignCard = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isActive }) => ({
  "&:hover": {
    borderColor: isActive ? theme.customColors.purple[500] : "#b0a0ff",
    // [StyledSelectCampaignCardTitle]: {
    //   color: theme.customColors.purple[500],
    // },
  },
  border: !isActive
    ? "2px solid white"
    : `2px solid ${theme.customColors.purple[500]}`,
  cursor: "pointer",
  minWidth: "320px",
  margin: "5px",
  borderRadius: "10px",
  padding: "26px 20px",
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  alignItems: "flex-start",
  columnGap: 16,
  ...(isActive && {
    [StyledSelectCampaignCardTitle]: {
      color: theme.customColors.purple[500],
    },
  }),
}));

export const StyledSelectCampaignCardCover = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  background: `${alpha(theme.customColors.grey[300], 0.5)}`,
  backdropFilter: "blur(5px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  zIndex: 2,
  borderRadius: 10,
}));

export const StyledSelectCampaignCardTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  margin: "0px 0px 4px",
}));

export const StyledSelectCampaignColumnHr = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  margin: "0 12px",
  borderLeft: `1px solid ${theme.customColors.grey[300]}`,
}));

export const StyledGoalLabelWrapper = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: 4,
  fontSize: 12,
  fontWeight: 700,
  padding: "4px 0px",
}));

export const StyledPromotypeLabelWrapper = styled(StyledGoalLabelWrapper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  columnGap: 8,
}));
