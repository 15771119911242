import React from "react";
import _ from "lodash";
import { Checkbox } from "@mui/material";
import checkboxIcon from "assets/img/checkbox.svg";
import checkedboxIcon from "assets/img/checkedbox.svg";
import checkboxDisabled from "assets/img/ic_checkboxDisabled.svg";
export default function CustomCheckbox(props) {
  return (
    <Checkbox
      icon={<img src={checkboxIcon} />}
      indeterminateIcon={<img src={checkboxDisabled} />}
      checkedIcon={<img src={checkedboxIcon} />}
      {...props}
    />
  );
}

CustomCheckbox.defaultProps = {};
