import _, { startCase } from "lodash";
import { useEffect, useState } from "react";
import { apiUrl } from "features/configure";
import axios from "axios";
import { useOutletContext } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

// 初始entity有哪些權限，並且顯示在organization role 上方權限選單
export default function useGetRolesHeader() {
  const [header, setHeader] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  useEffect(() => {
    async function getHeader() {
      try {
        setLoading(true);
        const { data } = await axios.get(
          apiUrl + `/api/role/${entityName}/features`
        );
        const headerGrouped = _.groupBy(data, "category");
        const disableRead = ["integration"];

        let headerArr = [];
        Object.keys(headerGrouped).forEach((key) => {
          headerArr.push({
            title: key,
            children: headerGrouped[key].map((item) => {
              const disabledOptions = [];
              if (item.onlyAdmin) disabledOptions.push("edit");
              if (disableRead.includes(item.name)) disabledOptions.push("read");
              return {
                title: startCase(item.name),
                value: item.name,
                disabled: disabledOptions,
              };
            }),
          });
        });
        setHeader(headerArr);
      } catch (err) {
        console.log(err);
        dispatchSnackbar({ err });
      } finally {
        setLoading(false);
      }
    }
    getHeader();
  }, []);

  return { header, loading };
}
