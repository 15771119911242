import { Box } from "@mui/material";
import React from "react";

export default function WhiteCard({
  label = "Wallet Value :",
  value = "2345514",
  width = "fit-content",
  ...props
}) {
  return (
    <Box
      p="18px 24px"
      bgcolor={"#FFFFFF"}
      borderRadius={"8px"}
      my="16px"
      mr="8px"
      width={width}
      {...props}
    >
      <Box mb="10px" fontSize="14px" fontWeight={700} color={"#777"}>
        {label}
      </Box>
      <Box color={"#555555"} fontWeight={700} fontSize={"18px"}>
        {label === "Total Customer Value"
          ? `$${value.toLocaleString()}`
          : value.toLocaleString()}
      </Box>
    </Box>
  );
}
