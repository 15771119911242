import React, { useState, useEffect } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";

import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { ChromePicker } from "react-color";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

import {
  StyledSettingItem,
  StyledSettingItemTitle,
  StyledSettingItemContent,
  StyledSettingItemChooseColorArea,
  StyledColorPickerPopover,
  StyledColorPickerCover,
  StyledColorDemoCycle,
  StyledSettingItemContentHint,
} from "./SettingItem.styles";
import useGetPagePermission from "hooks/useGetPagePermission";

const convertString = (str) => {
  // 使用正則表達式將大寫字母前面加上一個空格，然後轉換整個字符串為小寫
  const convertedStr = str.replace(/([A-Z])/g, " $1").toLowerCase();

  // 將首字母轉換為大寫
  const finalStr = convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1);

  return finalStr;
};

const SettingItem = ({
  colorSetting,
  onChange,
  control,
  errorsInfo,
  index,
}) => {
  const { name, color, hint, title } = colorSetting;
  const [open, setOpen] = useState(false);

  const handleChangeColor = (color) => {
    const e = { target: { value: color.hex, name: name } };
    onChange(e);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  return (
    <StyledSettingItem>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {index && (
          <Box
            width="24px"
            height="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#F7F1FE"
            color="#7B61FF"
            mr="12px"
            borderRadius={"50%"}
          >
            {index}
          </Box>
        )}

        <Box>
          <Box color="$555" fontSize={14} mb="4px">
            {_.get(colorSetting, "title", convertString(name))}
          </Box>
          <Box fontSize={12} color="#777" fontWeight={400}>
            {hint}
          </Box>
        </Box>
      </Box>

      <StyledSettingItemContent>
        <StyledSettingItemChooseColorArea>
          <Box position="relative">
            <StyledColorDemoCycle
              readonly={readonly}
              onClick={canEdit ? handleClick : null}
              color={color}
            />
            {open && canEdit && (
              <StyledColorPickerPopover>
                <StyledColorPickerCover onClick={handleClose} />
                <ChromePicker
                  color={color}
                  onChange={handleChangeColor}
                  name={name}
                  disableAlpha
                />
              </StyledColorPickerPopover>
            )}
          </Box>
          <InputField
            readonly={readonly}
            value={color}
            size={SIZE.MEDIUM}
            name={name}
            onChange={onChange}
            errors={_.get(errorsInfo, `${name}.message`)}
            rules={{
              required: {
                value: true,
                message: `${name} is required`,
              },
              pattern: {
                value: new RegExp(`^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$`),
                message: "Not valid color",
              },
            }}
            control={control}
          ></InputField>
        </StyledSettingItemChooseColorArea>
      </StyledSettingItemContent>
    </StyledSettingItem>
  );
};

export default SettingItem;
