import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import { StyledLayoutItem } from "./MagicWand.styles";

export const SubTitleText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[800],
  fontWeight: theme.fontWeight.BOLD,
  fontSize: 16,
  margin: "16px 0",
}));

export const ApplicationItemWrapper = styled(StyledLayoutItem, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  minHeight: 0,
  maxWidth: 791,
  marginTop: 0,
  marginBottom: 16,
  display: "flex",
  alignItems: "center",
  columnGap: 8,
}));

export const AppTitleText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[700],
  fontSize: 16,
  fontWeight: theme.fontWeight.BOLD,
}));

export const AppDescriptionText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: theme.fontSize.TINY,
  color: theme.customColors.grey[600],
}));
