import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

const TabPanel = (props) => {
  const { children, value, index, open, ...other } = props;
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    if (open && value === index) {
      const timer = setTimeout(() => {
        setContentVisible(true);
      }, 150); // 150ms 是容器展開的時間，可以根據實際情況調整

      return () => clearTimeout(timer);
    } else {
      setContentVisible(false);
    }
  }, [index, open, value]);

  return (
    <Box
      role="tabpanel"
      hidden={!contentVisible}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box sx={{ height: "100%" }}>{children}</Box>
    </Box>
  );
};

export default TabPanel;
