import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import TrialMarketingSetStep1 from "features/metadesk/Onboarding/MarketingOnboarding/TrialMarketingSetStep/TrialMarketingSetStep1/TrialMarketingSetStep1";
import TrialServiceSetStep2 from "./TrialServiceSetStep/TrialServiceSetStep2/TrialServiceSetStep2";
import TrialServiceSetStep3 from "./TrialServiceSetStep/TrialServiceSetStep3/TrialServiceSetStep3";
import StepProvider from "contexts/stepContext/Step.context";
import TrialHeader from "../../../home/pages/Trial/TrialHeader/TrialHeader";
import { StyledLinearProgress } from "./TrialServiceSetStepContainer.js.styles";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmFalse } from "features/metadesk/redux/trial/trial.action";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { StyledTrialHeaderFixedContainer } from "features/home/pages/Trial/TrialHeader/TrialHeader.styles";
import IntegrationSuccess from "features/metadesk/Onboarding/ServiceOnboarding/AttributionLinkSetup/IntegrationSuccess/IntegrationSuccess";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import config from "../../../../config";

const ServiceOnboardingSetStepContainer = ({}) => {
  // 最後一頁成功頁面不算進去
  const totalStep = 3;
  const [entityName] = useOutletContext();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [step, setStep] = useState(0);
  const nextFormStep = () => setStep((currentStep) => currentStep + 1);
  const prevFormStep = () => setStep((currentStep) => currentStep - 1);
  const { fetchEntityConfig } = useFetchEntityConfig();

  useEffect(() => {
    if (entityName) {
      fetchEntityConfig({ entityName });
    }
  }, [entityName]);

  const linerValue = (100 / totalStep) * (step + 1);

  return (
    <>
      <StyledTrialHeaderFixedContainer>
        <TrialHeader />
        {step < 3 && (
          <StyledLinearProgress
            variant="determinate"
            value={linerValue}
            color="secondary"
          />
        )}
      </StyledTrialHeaderFixedContainer>

      <StepProvider>
        {step === 0 && (
          <TrialMarketingSetStep1
            entityName={entityName}
            moduleName={"service"}
            step={step}
            onNextStep={nextFormStep}
          />
        )}
        {step === 1 && (
          <TrialServiceSetStep2
            entityName={entityName}
            moduleName={"service"}
            step={step}
            onNextStep={nextFormStep}
            onPrevStep={prevFormStep}
          />
        )}

        {step === 2 && (
          <TrialServiceSetStep3
            entityName={entityName}
            step={step}
            onNextStep={nextFormStep}
            onPrevStep={prevFormStep}
          />
        )}

        {step === 3 && (
          <IntegrationSuccess
            onSuccess={() => {
              dispatch(setConfirmFalse());
            }}
            title={formatMessage({
              id: "service.onboarding.congrats.title",
            })}
            contentText={
              <Box>
                {formatMessage({
                  id: "service.onboarding.congrats.description",
                })}
                <Box mt={2}>
                  website:{" "}
                  <a
                    className="link"
                    href={`${config.portalUrl}/${entityName}`}
                  >
                    {config.portalUrl}/{entityName}
                  </a>
                </Box>
              </Box>
            }
            navigatePage="/"
            linkImg={require(`assets/img/service-link-success.svg`).default}
          />
        )}
      </StepProvider>
    </>
  );
};

export default ServiceOnboardingSetStepContainer;
