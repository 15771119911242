import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledImgContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "60px",
  "& img": {
    height: "100%",
  },
}));

export const StyledUploadIconSmall = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  bottom: "8px",
  right: "8px",
  display: "flex",
  color: theme.customColors.grey[500],
}));

export const StyledUploadImageAreaContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, img, imgErrorText }) => ({
  border: `1px dashed ${theme.customColors.grey[300]}`,
  ...(img && { border: `1px solid ${theme.customColors.grey[300]}` }),
  position: "relative",
  borderRadius: "10px",
  height: "190px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  color: theme.customColors.grey[300],
  "& .big-upload": {
    fontSize: "80px",
  },
  ...(imgErrorText && {
    borderColor: theme.customColors.pink[600],
  }),
}));

export const StyledUploadInput = styled("input", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  opacity: "0",
  cursor: "pointer",
  width: "100%",
  zIndex: 1,
}));

export const StyledUploadLimit = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  marginTop: "16px",
}));

export const StyledRightImageContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "relative",
}));

export const StyledPreviewLogo = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  height: "18px",
  left: "20px",
  top: "7px",
}));

export const StyledRightImage = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledImgErrorText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, img }) => ({
  "& i": {
    marginRight: "4px",
  },
  marginTop: "16px",
  display: "flex",
  color: theme.customColors.pink[600],
}));
