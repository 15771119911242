import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import { Grid, Modal } from "@mui/material";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";

export const StyledChangelogBox = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
}));

export const StyledChangelogTag = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, bgcolor }) => ({
  bgcolor,
  display: "flex",
  width: "85px",
  padding: "4px 6px",
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "13px",
  color: "white",
  marginRight: "16px",
}));

export const StyledChangelogContent = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
}));
