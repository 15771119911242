import React, { useContext } from "react";
import _ from "lodash";
import { IconButton, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
  StyledAddTicketTypeButton,
} from "./OnChainDataSource.styles";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Grid } from "@mui/material";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import useGetPagePermission from "hooks/useGetPagePermission";
import { Radio } from "@metacrm/metacrm-material-ui/dist/Radio";
import { Box } from "@mui/material";
import { OnChainDataSourceContext } from "./OnChainDataSource.context";

const TxDisplayPreference = ({
  fetchGeneralTx,
  setFetchGeneralTx,
  txDisplayList,
  setTxDisplayList,
}) => {
  const { chainList, newAddItem } = useContext(OnChainDataSourceContext);

  const updateTxList = (key, value) => {
    const newList = _.cloneDeep(txDisplayList);
    _.set(newList, key, value);
    setTxDisplayList(newList);
  };
  const handleAddNewTx = (e) => {
    setTxDisplayList([...txDisplayList, newAddItem]);
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  const renderTxDisplayList = () => {
    return (
      <>
        <StyledSettingTitle>
          Select the number of transactions you want to display
        </StyledSettingTitle>

        {readonly ? (
          <>
            {fetchGeneralTx ? (
              <Radio
                checked={fetchGeneralTx}
                label={"Latest 50 Transactions"}
                controlStyle={{ display: "block" }}
              />
            ) : (
              <Radio
                checked={!fetchGeneralTx}
                label={"Specific Contracts"}
                controlStyle={{ display: "block" }}
              />
            )}
          </>
        ) : (
          <>
            <Radio
              checked={fetchGeneralTx}
              onChange={() => setFetchGeneralTx(true)}
              label={"Latest 50 Transactions"}
              controlStyle={{ display: "block" }}
            />
            <Radio
              checked={!fetchGeneralTx}
              onChange={() => setFetchGeneralTx(false)}
              label={"Specific Contracts"}
              controlStyle={{ display: "block" }}
            />
          </>
        )}
        {readonly && fetchGeneralTx ? null : (
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ marginLeft: 28, matginTop: 8 }}
          >
            <Grid item xs={5}>
              Chain
            </Grid>
            <Grid item xs={6}>
              Contract Address
            </Grid>
            {_.map(txDisplayList, (tx, i) => {
              return (
                <React.Fragment key={i}>
                  <Grid item xs={5}>
                    <CustomDropdown
                      readonly={readonly}
                      border
                      fullWidth
                      size={SIZE.LARGE}
                      placeholder="Select"
                      data={chainList}
                      name={`txDisplayList[${i}].chainId`}
                      value={tx.chainId}
                      onSelect={(e) => {
                        updateTxList(`[${i}].chainId`, e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      readonly={readonly}
                      size={SIZE.LARGE}
                      placeholder="Enter Contract Address Here"
                      name={`txDisplayList[${i}].address`}
                      onChange={(e) =>
                        updateTxList(`[${i}].address`, e.target.value)
                      }
                      value={tx.address}
                    />
                  </Grid>
                  {canEdit && (
                    <Grid item xs={1}>
                      <IconButton
                        size={18}
                        onClick={(e) => {
                          const newList = _.filter(
                            txDisplayList,
                            (_, index) => index !== i
                          );
                          setTxDisplayList(newList);
                        }}
                      >
                        <i className="meta-crm-icon-ic_trash font-size-14" />
                      </IconButton>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            {canEdit && (
              <>
                <Grid item xs={5}>
                  <StyledAddTicketTypeButton onClick={handleAddNewTx}>
                    <span className="plus">
                      <i className="meta-crm-icon-ic_add font-size-16" />
                    </span>
                    Add
                  </StyledAddTicketTypeButton>
                </Grid>
                <Grid item xs={6}>
                  <StyledAddTicketTypeButton
                    onClick={handleAddNewTx}
                  ></StyledAddTicketTypeButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </>
    );
  };

  return (
    <StyledGeneralSettingsContainer>
      <StyledSettingTitle>Transaction Display Preference</StyledSettingTitle>
      <StyledDescription>
        Opt to show either the latest 50 transactions for the above selected
        blockchain(s) or all historical transactions for specified contracts in
        user profiles
      </StyledDescription>

      {renderTxDisplayList()}
    </StyledGeneralSettingsContainer>
  );
};

export default TxDisplayPreference;
