const parseToOptionFormat = (data) => {
  if (data && data.length > 0) {
    return data.map((item) => {
      return {
        ...item,
        label: item.name || item.value,
        value: item._id,
      };
    });
  }
  return data;
};

const parseSingleAnswerToOptionFormat = (data) => {
  if (data) {
    return {
      ...data,
      label: data.name || data.value,
      value: data._id,
    };
  }
  return data;
};

const parseToOptionFormatWithAll = (data) => {
  const transferData = parseToOptionFormat(data);
  const newData = [
    { label: "All", isAllOption: true, value: "" },
    ...transferData,
  ];
  return newData;
};

export {
  parseToOptionFormat,
  parseSingleAnswerToOptionFormat,
  parseToOptionFormatWithAll,
};
