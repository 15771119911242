import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";

const Landing = ({ isCustomDomain, entityName }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const theme = useTheme();
  useEffect(() => {
    const hostname = window.location.hostname;

    if (
      [
        "dev.metacrm.inc",
        "beta.metacrm.inc",
        "app.metacrm.inc",
        "localhost",
      ].includes(hostname)
    ) {
      window.location.href = "http://metacrm.inc";
    }
  }, []);
  return <div />;
};

export default Landing;
