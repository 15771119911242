import React from "react";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)(
  ({ theme, center, sm, bold, option, number, noLine, outline, error }) => ({
    justifyContent: "space-around",
    color: "#383538",
    backgroundColor: "transparent",
    "& .MuiInputBase-root": {
      color: "#383538",
      fontSize: 14,
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      padding: "7px 0",
    },
    "& fieldset": {
      color: "#383538",

      border: "none", // Remove default border
    },
    "& .MuiOutlinedInput-root": {
      padding: "0 8px",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "transparent",
      color: "#383538",
      "& fieldset": {
        border: `none`,
      },
    },
    outline: outline ? "1px solid #e6e6e6" : "none",
    ...(center && {
      "& .MuiInputBase-input": {
        textAlign: "center",
      },
    }),
    ...(option && {
      marginTop: 8,
      "& .MuiInputBase-root": {
        color: "#383538",
      },
    }),

    ...(noLine && {
      border: "0px",
    }),

    ...(error && {
      background: "#FFDED7",
      border: "1px solid #FC5555",
    }),
    ...(sm && {
      padding: "0px 5px",
      "& .MuiInputBase-root": {
        fontSize: 13,
      },
    }),
    ...(bold && {
      "& .MuiInputBase-root": {
        fontSize: 15,
        fontWeight: 500,
      },
    }),
  })
);

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  // Add any specific styles for InputAdornment if needed
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={true}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const {
    disabled,
    outline,
    value,
    placeholder,
    center,
    option,
    onChange,
    startAdornment,
    sm,
    bold,
    endAdornment,
    onBlur,
    noLine,
    number,
    max,
    error,
    ...rest
  } = props;

  if (disabled) {
    return (
      <div style={{ textAlign: center ? "center" : "left" }}>
        <div
          style={{
            fontSize: sm ? 14 : 18,
            paddingLeft: 5,
            paddingTop: 5,
            fontWeight: 500,
            color: option ? "#383538" : "#383538",
          }}
        >
          {value}
        </div>
      </div>
    );
  }

  let inputProps = {
    startAdornment: props.startAdornment,
    disableUnderline: true,
  };
  if (startAdornment) inputProps["startAdornment"] = startAdornment;
  if (endAdornment)
    inputProps["endAdornment"] = (
      <StyledInputAdornment position="end">{endAdornment}</StyledInputAdornment>
    );
  if (number) inputProps["inputComponent"] = NumberFormatCustom;

  return (
    <>
      <StyledTextField
        {...props}
        placeholder={placeholder || ""}
        onBlur={(e) => {
          onChange({ target: { value: _.trim(e.target.value) } });
          onBlur && onBlur();
        }}
        color="secondary"
        InputProps={inputProps}
        center={center}
        sm={sm}
        bold={bold}
        option={option}
        number={number}
        noLine={noLine}
        outline={outline}
        error={error}
        {...rest}
      />
      {max && (
        <span style={{ position: "absolute", right: 0, color: "#383538" }}>
          {_.get(value, "length", 0)}/{max}
        </span>
      )}
      {error && <div className="error">{error}</div>}
    </>
  );
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};
