export const magpieContracts = [
  //Pool Helpers
  '"0xC4a2B6de728B1E76D2F7178bF8AB3df458dF4C92"', //MGP_BUSD_WOMBAT_LP
  '"0x1aa1E18FAFAe4391FF33dFBE6198dc84B9541D77"', //MGP_USDT_WOMBAT_LP
  '"0xB8fcc8aC3aB0eb77ab43aA3C5483234CB10e5a73"', //MGP_DAI_WOMBAT_LP
  '"0xb68f5247f31fe28fde0b0f7543f635a4d6edbd7f"', //MGP_USDC_WOMBAT_LP
  '"0xD3e3d4C7e6e41f9b8Ec6eF34fC133287102AEf3A"', //MGP_WBNB_WOMBAT_LP
  '"0x9b5Ecb7b3f311955B2f3F32f23187d18149f05A2"', //MGP_BNBX_WOMBAT_LP
  '"0xbf925c91f3AB02aDAcB707643acFae287d593be1"', //MGP_stkBNB_WOMBAT_LP
  '"0xC2fb19640fC90Ce84410EcD203e4EA2C2AD1291b"', //MGP_BUSD_WOMBAT_SIDE_LP
  '"0xe61eBb17b11Cd995f0564A8Ff70f17d10D850872"', //MGP_HAY_WOMBAT_SIDE_LP
  '"0xa3B615667CBd33cfc69843Bf11Fbb2A1D926BD46"', //masterMagpie
  '"0xdB46cbb3c349E3391DCF71156c4CfE289fA365cF"', //Compounder
  '"0x9B69b06272980FA6BAd9D88680a71e3c3BeB32c6"', //vlMGP
  '"0x90b9548Cf0C514B097CDFf411fD192e229F9377B"', //SmartWomConvert
];

export const magpieContractNameMapping = {
  "0XC4A2B6DE728B1E76D2F7178BF8AB3DF458DF4C92": "BUSD Pool Helper",
  "0X1AA1E18FAFAE4391FF33DFBE6198DC84B9541D77": "USDT Pool Helper",
  "0XB8FCC8AC3AB0EB77AB43AA3C5483234CB10E5A73": "DAI Pool Helper",
  "0XB68F5247F31FE28FDE0B0F7543F635A4D6EDBD7F": "USDC Pool Helper",
  "0XD3E3D4C7E6E41F9B8EC6EF34FC133287102AEF3A": "WBNB Pool Helper",
  "0X9B5ECB7B3F311955B2F3F32F23187D18149F05A2": "BNBX Pool Helper",
  "0XBF925C91F3AB02ADACB707643ACFAE287D593BE1": "stkBNB Pool Helper",
  "0XC2FB19640FC90CE84410ECD203E4EA2C2AD1291B": "BUSD Side Pool Helper",
  "0XE61EBB17B11CD995F0564A8FF70F17D10D850872": "HAY Side Pool Helper",
  "0XA3B615667CBD33CFC69843BF11FBB2A1D926BD46": "Master Magpie",
  "0XDB46CBB3C349E3391DCF71156C4CFE289FA365CF": "Compounder",
  "0X9B69B06272980FA6BAD9D88680A71E3C3BEB32C6": "Vote Lock MGP",
  "0X90B9548CF0C514B097CDFF411FD192E229F9377B": "Smart WOM Converter",
};

export const tokenMapping = [
  {
    name: "WBTC",
    address: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    decimals: 8,
    img_url: require("assets/img/WBTC.svg"),
  },
  {
    name: "BTCB",
    address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    decimals: 18,
    img_url: require("assets/img/BTCB.svg"),
  },
  {
    name: "USDT",
    address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    decimals: 6,
    img_url: require("assets/img/USDT.png"),
  },
  {
    name: "USDT",
    address: "0x55d398326f99059ff775485246999027b3197955",
    decimals: 18,
    img_url: require("assets/img/USDT.png"),
  },
  {
    name: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    decimals: 18,
    img_url: require("assets/img/BUSD.png"),
  },
  {
    name: "USDC",
    address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    decimals: 6,
    img_url: require("assets/img/USDC.png"),
  },
  {
    name: "USDC",
    address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    decimals: 18,
    img_url: require("assets/img/USDC.png"),
  },

  {
    name: "DAI",
    address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    decimals: 18,
    img_url: require("assets/img/DAI.png"),
  },
  {
    name: "DAI",
    address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    decimals: 18,
    img_url: require("assets/img/DAI.png"),
  },

  {
    name: "WETH",
    address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    decimals: 18,
    img_url: require("assets/img/WETH.svg"),
  },
  {
    name: "ETH",
    address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    decimals: 18,
    img_url: require("assets/img/ETH.png"),
  },
  {
    name: "HAY",
    address: "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5",
    decimals: 18,
    img_url: require("assets/img/HAY.png"),
  },
  {
    name: "stkBNB",
    address: "0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16",
    decimals: 18,
    img_url: require("assets/img/stkBNB.png"),
  },
  {
    name: "BNBx",
    address: "0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275",
    img_url: require("assets/img/BNBx.png"),
    decimals: 18,
  },
  {
    name: "aBNBc",
    address: "0xe85afccdafbe7f2b096f268e31cce3da8da2990a",
    decimals: 18,
    img_url: require("assets/img/aBNBc.png"),
  },
  {
    name: "BOB",
    address: "0xb0b195aefa3650a6908f15cdac7d92f8a5791B0b",
    decimals: 18,
    img_url: require("assets/img/BOB.png"),
  },
  {
    name: "BOB",
    address: "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    decimals: 18,
    img_url: require("assets/img/BOB.png"),
  },
  {
    name: "TUSD",
    address: "0x14016e85a25aeb13065688cafb43044c2ef86784",
    decimals: 18,
    img_url: require("assets/img/TUSD.png"),
  },
  {
    name: "USDD",
    address: "0xd17479997f34dd9156deef8f95a52d81d265be9c",
    decimals: 18,
    img_url: require("assets/img/USDD.png"),
  },

  {
    name: "FRAX",
    address: "0x90c97f71e18723b0cf0dfa30ee176ab653e89f40",
    decimals: 18,
    img_url: require("assets/img/FRAX.png"),
  },

  {
    name: "FRAX",
    address: "0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    decimals: 18,
    img_url: require("assets/img/FRAX.png"),
  },

  {
    name: "WSTETH",
    address: "0x5979d7b546e38e414f7e9822514be443a4800529",
    decimals: 18,
    img_url: require("assets/img/WSTETH.svg"),
  },
  {
    name: "ARB",
    address: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    decimals: 18,
    img_url: require("assets/img/ARB.svg"),
  },
  {
    name: "BNB",
    address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    decimals: 18,
    img_url: require("assets/img/BNB.svg"),
  },
  {
    name: "mWOM",
    address: "0x509fd25ee2ac7833a017f17ee8a6fb4aaf947876",
    decimals: 18,
    img_url: require("assets/img/mWOM.png"),
  },
  {
    name: "MGP",
    address: "0xd06716e1ff2e492cc5034c2e81805562dd3b45fa",
    decimals: 18,
    img_url: require("assets/img/MGP.svg"),
  },
  {
    name: "MGP",
    address: "0xa61f74247455a40b01b0559ff6274441fafa22a3",
    decimals: 18,
    img_url: require("assets/img/MGP.svg"),
  },
  {
    name: "VLMGP",
    address: "0x9b69b06272980fa6bad9d88680a71e3c3beb32c6",
    decimals: 18,
    img_url: require("assets/img/VLMGP.png"),
  },
  {
    name: "VLMGP",
    address: "0x536599497ce6a35fc65c7503232fec71a84786b9",
    decimals: 18,
    img_url: require("assets/img/VLMGP.png"),
  },
  {
    name: "mWOM",
    address: "0x027a9d301fb747cd972cfb29a63f3bda551dfc5c",
    decimals: 18,
    img_url: require("assets/img/mWOM.png"),
  },
  {
    name: "mWOM",
    address: "0x21804fb90593458630298f10a85094cb6d3b07db",
    decimals: 18,
    img_url: require("assets/img/mWOM.png"),
  },
  {
    name: "WOM",
    address: "0xad6742a35fb341a9cc6ad674738dd8da98b94fb1",
    decimals: 18,
    img_url: require("assets/img/WOM.svg"),
  },
  {
    name: "LP-mWOM",
    address: "0x1f502ff26db12f8e41b373f36dc0abf2d7f6723e",
    decimals: 18,
    img_url: require("assets/img/WOM.svg"),
  },
  {
    name: "LP-WOM",
    address: "0xeaba290b154af45de72fdf2a40e56349e4e68ac2",
    decimals: 18,
    img_url: require("assets/img/WOM.svg"),
  },
  {
    name: "frxETH",
    address: "0x178412e79c25968a32e89b11f63b33f733770c2a",
    decimals: 18,
    img_url: require("assets/img/fraxeth.png"),
  },
  {
    name: "frxETH",
    address: "0x64048a7eecf3a2f1ba9e144aac3d7db6e58f555e",
    decimals: 18,
    img_url: require("assets/img/fraxeth.png"),
  },
  {
    name: "iUSD",
    address: "0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d",
    decimals: 18,
    img_url: require("assets/img/iUSD.png"),
  },
  {
    name: "CUSD",
    address: "0xfa4ba88cf97e282c505bea095297786c16070129",
    decimals: 18,
    img_url: require("assets/img/CUSD.png"),
  },
  {
    name: "axlUSDC",
    address: "0x4268b8f0b87b6eae5d897996e6b845ddbd99adf3",
    decimals: 6,
    img_url: require("assets/img/USDC.png"),
  },
  {
    name: "USD+",
    address: "0xe80772eaf6e2e18b651f160bc9158b2a5cafca65",
    decimals: 6,
    img_url: require("assets/img/USD+.png"),
  },
  {
    name: "USDT+",
    address: "0x5335e87930b410b8c5bb4d43c3360aca15ec0c8c",
    decimals: 18,
    img_url: require("assets/img/USDT.png"),
  },
  {
    name: "DAI+",
    address: "0xeb8e93a0c7504bffd8a8ffa56cd754c63aaebfe8",
    decimals: 18,
    img_url: require("assets/img/dai+.png"),
  },
  {
    name: "MIM",
    address: "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    decimals: 18,
    img_url: require("assets/img/MIM.png"),
  },
  {
    name: "WOM",
    address: "0x7b5eb3940021ec0e8e463d5dbb4b7b09a89ddf96",
    decimals: 18,
    img_url: require("assets/img/WOM.png"),
  },
  {
    name: "MAI",
    address: "0x3f56e0c36d275367b8c502090edf38289b3dea0d",
    decimals: 18,
    img_url: require("assets/img/MAI.png"),
  },
];

export const pools = {
  56: [
    {
      name: "mWOM Staking",
      stakingTokenAddress: "0x027a9d301FB747cd972CFB29A63f3BDA551DFc5c",
      coingeckoId: "wombat-exchange",
      helperAddress: "0x2829f9b0D5e95B6bDBF4E1324ae9c94852714aF5",
      type: "normal",
    },
    {
      name: "VLMGP",
      stakingTokenAddress: "0x9B69b06272980FA6BAd9D88680a71e3c3BeB32c6",
      coingeckoId: "magpie",
      helperAddress: "0x9B69b06272980FA6BAd9D88680a71e3c3BeB32c6",
      type: "normal",
    },
    {
      name: "BUSD",
      stakingTokenAddress: "0xcC9cAf0c84514D01cDa62C9E0FC7B3e21e45f25D",
      coingeckoId: "binance-usd",
      helperAddress: "0xC4a2B6de728B1E76D2F7178bF8AB3df458dF4C92",
      type: "normal",
    },
    {
      name: "USDT",
      stakingTokenAddress: "0x1773072c932854291F844b67CFFE65216e6400D4",
      coingeckoId: "tether",
      helperAddress: "0x1aa1E18FAFAe4391FF33dFBE6198dc84B9541D77",
      type: "normal",
    },
    {
      name: "DAI",
      stakingTokenAddress: "0x1Bfaf418C13e36958b0b6736B27C3E8bC1F9bB91",
      coingeckoId: "dai",
      helperAddress: "0xB8fcc8aC3aB0eb77ab43aA3C5483234CB10e5a73",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x8044494b2e0790f56445aA167F59D1D1d843E67c",
      coingeckoId: "usd-coin",
      helperAddress: "0xb68F5247f31fe28FDe0b0F7543F635a4d6EDbD7F",
      type: "normal",
    },
    {
      name: "BUSD",
      stakingTokenAddress: "0x59DF1bC9c8Ac7b205F2F9717F970E69804B644b7",
      coingeckoId: "binance-usd",
      helperAddress: "0xC2fb19640fC90Ce84410EcD203e4EA2C2AD1291b",
      type: "Side",
    },
    {
      name: "HAY",
      stakingTokenAddress: "0xaB22C452308eAe8C0CDdad0Ec4E31aa21f1d6c42",
      coingeckoId: "destablecoin-hay",
      helperAddress: "0xe61eBb17b11Cd995f0564A8Ff70f17d10D850872",
      type: "normal",
    },
    {
      name: "BUSD",
      stakingTokenAddress: "0x17CE4D6DE3309576601e74fc28D9EDFe9E33F082",
      coingeckoId: "binance-usd",
      helperAddress: "0x2027e9647439db9f05a7FE55d1F4DABDF70EaE28",
      type: "Innovation",
    },
    {
      name: "FRAX",
      stakingTokenAddress: "0xC1763dc6C259e1349aB29E45b558a2884c84b6eB",
      coingeckoId: "frax",
      helperAddress: "0xfE14fBDfF1A123A52b1C7F692802aDC6eECE83D9",
      type: "normal",
    },
    {
      name: "TUSD",
      stakingTokenAddress: "0xe1ec23574d3fF2519Aa4f0dc16f624fAad63f9b4",
      coingeckoId: "true-usd",
      helperAddress: "0x73971D29822b1Fe3e27293Db6Ab713836D8973D7",
      type: "normal",
    },
    {
      name: "BNBx",
      stakingTokenAddress: "0xf73F044c5d67F825670EdEA7A4a3fA29c5f71A50",
      coingeckoId: "stader-bnbx",
      helperAddress: "0x23b5a1aBE5a1759e1B2d52fb10e626933ac33c99",
      type: "normal",
    },
    {
      name: "BNB",
      stakingTokenAddress: "0xE6E2B2e3F299b44c6589973CE9C90CDa12C1303c",
      coingeckoId: "binancecoin",
      helperAddress: "0xd6e7b4e287Ed52f5fbF43e7b5096a1F23857aD9c",
      type: "BNBx",
    },
    {
      name: "WOM",
      stakingTokenAddress: "0xbb960F3e5103021750cE01c586ea5758b531Cdf6",
      coingeckoId: "wombat-exchange",
      helperAddress: "0x8E030355Ed106dFA20FA62FC14669c472c4BB294",
      type: "normal",
    },
    {
      name: "mWOM",
      stakingTokenAddress: "0xa6845318FdAe84ac8A7460Bd4A029548326572Cc",
      coingeckoId: "wombat-exchange",
      helperAddress: "0xef73b070ED84F6A07fd324E00482DB473CD29Ee4",
      type: "normal",
    },
    {
      name: "stkBNB",
      stakingTokenAddress: "0xE890D15E5fdE468389B37B894FFd4c0783D8a865",
      coingeckoId: "pstake-staked-bnb",
      helperAddress: "0xc60c24C1407a7AF680c6e40e1797075e075D6Dd1",
      type: "normal",
    },
    {
      name: "BNB",
      stakingTokenAddress: "0x3423C1eF3eAbE36AA8A95CF3a9900F7ABC53C727",
      coingeckoId: "binancecoin",
      helperAddress: "0xc8552dB438Db03b1995f97F9F5970A5Afa2B30C5",
      type: "stkBNB",
    },
    {
      name: "iUSD",
      stakingTokenAddress: "0xD894250418De0C16D5B2F37eADAca89c3Dfc1520",
      coingeckoId: "izumi-bond-usd",
      helperAddress: "0x8a6c9dE24f1FFbE01302cfd39609E3c2A41b7026",
      type: "normal",
    },
    {
      name: "BUSD",
      stakingTokenAddress: "0x8C9Cfb489aA5303D01dD176080d4991D6AA61F79",
      coingeckoId: "binance-usd",
      helperAddress: "0xB5567464afD6A4B9149AE719FBbBdFf6F470Efb2",
      type: "iUSD",
    },
    {
      name: "CUSD",
      stakingTokenAddress: "0x12F30fDDE7A28b616AC422FB62d08CBdbB96A13F",
      coingeckoId: "coin98-dollar",
      helperAddress: "0x234E364f7Ba9c12aC49441EF339df59ccd2eDb60",
      type: "normal",
    },
    {
      name: "HAY",
      stakingTokenAddress: "0xB016EbAB3aC7751b88D1Eb0eDC26Ef55A5Ac07D6",
      coingeckoId: "destablecoin-hay",
      helperAddress: "0xA1ab7F2Ca118f2c3426bd999A9CD80899318e497",
      type: "CUSD",
    },
    {
      name: "axlUSDC",
      stakingTokenAddress: "0x4e33Ee8675a7ef1bd9B1cd35338AdFb7bdEd74A9",
      coingeckoId: "axelar-usdc",
      helperAddress: "0x63A2E620c3c268A464F8C837D8bf711b011195A4",
      type: "normal",
    },
    {
      name: "BUSD",
      stakingTokenAddress: "0x0023dBaCEa14bBb91D1252373B376e871314484B",
      coingeckoId: "binance-usd",
      helperAddress: "0x52748CA7912FdbB26bA7E018Cc4d5fCC3A47f916",
      type: "axlUSDC",
    },
    {
      name: "USDD",
      stakingTokenAddress: "0x7381532a880ED0EcCc164b47362A619B734b1832",
      coingeckoId: "usdd",
      helperAddress: "0xe6525C4dDC8c6650b77403Fd9869d191cf1C9963",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x75fe553CE5e542CaFBd065593236339facaD5a40",
      coingeckoId: "usd-coin",
      helperAddress: "0xA2f698e00eC6f3937F201eb13b891E01a180a6Df",
      type: "USDD",
    },
    {
      name: "BOB",
      stakingTokenAddress: "0x1EbD54aa533fF224D5b9DDb0D2B8FbEbc3008C03",
      coingeckoId: "bob",
      helperAddress: "0xE1B25D01b2Ec5FB751a47A3794f508162aa79965",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x88BE9d02424289a7C232C3F76Cc6863DDB7886B0",
      coingeckoId: "usd-coin",
      helperAddress: "0xDaC7C06E1D221450Dc1c20E0c10D35371f6216ba",
      type: "BOB",
    },
    {
      name: "frxETH",
      stakingTokenAddress: "0x156178979DddE425a9C2cbd16eD62fBFbF371808",
      coingeckoId: "frax-ether",
      helperAddress: "0x299d7864839708CAeEDe096567c29C4241C9FC85",
      type: "normal",
    },
    {
      name: "ETH",
      stakingTokenAddress: "0xA197DBB3623998132268e44E1Da350E2AA756Be0",
      coingeckoId: "ethereum",
      helperAddress: "0xca875C72eF25057c2FCACd884538F0b4f8255D70",
      type: "normal",
    },
    {
      name: "USD+",
      stakingTokenAddress: "0x99d24277412eD18cFc642770b300376c442B2b2C",
      coingeckoId: "usdplus",
      helperAddress: "0x150E6467C480bD222E260BA0e09c70a4B4C2CE4b",
      type: "normal",
    },
    {
      name: "USDT+",
      stakingTokenAddress: "0x39efc61CCfbCeB32EA45C0C1022FDC5878022Dd9",
      coingeckoId: "tether",
      helperAddress: "0x5ED9142b7C64281964CCF2e260e13a5819Adc98b",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x28D0E30C948F463d5aDA34C71ED7a1eC3F4fE316",
      coingeckoId: "usd-coin",
      helperAddress: "0x2e97E21489E2EB61960d29A6fb5C40414773B309",
      type: "Overnight",
    },
    {
      name: "MIM",
      stakingTokenAddress: "0x7e371769c0a60c6f754fa2130ad0ee0bb1c1cf43",
      coingeckoId: "magic-internet-money",
      helperAddress: "0x8e404Fa158f70810c1E35218Ec55763D3a77657f",
      type: "normal",
    },
    {
      name: "USDT",
      stakingTokenAddress: "0x6CA512C0B43F87c6606E9b60fa472794326e90b3",
      coingeckoId: "tether",
      helperAddress: "0x6C09b960CcEA2eAc0b333c4729f4C5D78af27309",
      type: "MIM",
    },
    {
      name: "ankrBNB",
      stakingTokenAddress: "0x40fe0888f3a453C05b4FdA2c97f0bfbE6Cd7165e",
      coingeckoId: "ankr-staked-bnb",
      helperAddress: "0xb5447eea8FF5a181c9615C45bDF8e8661bB2e298",
      type: "normal",
    },
    {
      name: "BNB",
      stakingTokenAddress: "0x93578ADDAE40d765aC46F6a327A244Cb7E746530",
      coingeckoId: "binancecoin",
      helperAddress: "0x5aeA8361e0Dd26522E793ddeCeB192ef98560F31",
      type: "ankrBNB",
    },
    {
      name: "BNB",
      stakingTokenAddress: "0xA67e1Ef2483522532417eEF57659C5410D24b973",
      coingeckoId: "binancecoin",
      helperAddress: "0xD3e3d4C7e6e41f9b8Ec6eF34fC133287102AEf3A",
      type: "deprecated",
    },
    {
      name: "BNBx",
      stakingTokenAddress: "0xC134D4c9C9E99F928835ba2D13E188f45Af56B3D",
      coingeckoId: "stader-bnbx",
      helperAddress: "0x9b5Ecb7b3f311955B2f3F32f23187d18149f05A2",
      type: "deprecated",
    },
    {
      name: "aBNBc",
      stakingTokenAddress: "0x57dbD8F307740c12B492A9a6333Eb84A680E6878",
      coingeckoId: "ankr-staked-bnb",
      helperAddress: "0x399cB05deb6a9C9F0172EC50A972590e760dbf71",
      type: "deprecated",
    },
    {
      name: "stkBNB",
      stakingTokenAddress: "0x7d49DeDE48CA98967E50dc10a9Dd394359956802",
      coingeckoId: "pstake-staked-bnb",
      helperAddress: "0xbf925c91f3AB02aDAcB707643acFae287d593be1",
      type: "deprecated",
    },
  ],
  42161: [
    {
      name: "mWom Stability Vault",
      stakingTokenAddress: "0x21804FB90593458630298f10a85094cb6d3B07Db",
      coingeckoId: "wombat-exchange",
      helperAddress: "0x21804FB90593458630298f10a85094cb6d3B07Db",
      type: "normal",
    },
    {
      name: "mWOM Staking",
      stakingTokenAddress: "0x509FD25EE2AC7833a017f17Ee8A6Fb4aAf947876",
      coingeckoId: "wombat-exchange",
      helperAddress: "0x176ff4b2AE3682F96C7111A52d9f9e5AbCB6e951",
    },
    {
      name: "VLMGP",
      stakingTokenAddress: "0x536599497Ce6a35FC65C7503232Fec71A84786b9",
      coingeckoId: "magpie",
      helperAddress: "0x536599497Ce6a35FC65C7503232Fec71A84786b9",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x72aA7a1b3fB43e6c3C83DC31DA0d4099B475A47A",
      coingeckoId: "usd-coin",
      helperAddress: "0x6B962d671ea7FF73C0ecED4269FC27a1e326c759",
      type: "normal",
    },
    {
      name: "USDT",
      stakingTokenAddress: "0xF563200Ff355661Bac6f190EfB6CF97831776F0f",
      coingeckoId: "tether",
      helperAddress: "0x62A41a55E7B6ae3eE1c178DaF17d72E11bA86015",
      type: "normal",
    },
    {
      name: "DAI",
      stakingTokenAddress: "0xd3a4CfbeE618F51443213a1751Aa5C6208e6be11",
      coingeckoId: "dai",
      helperAddress: "0x193F9e182700b5eA8e86a2f0a98401df3F396f39",
      type: "normal",
    },
    {
      name: "USD+",
      stakingTokenAddress: "0x788C4bB90564e62DCd830b246fcfB1aa9E23BfE5",
      coingeckoId: "usdplus",
      helperAddress: "0x22cC1fc61EfE23239D3ef6ff0d359019FB599058",
      type: "normal",
    },
    {
      name: "DAI+",
      stakingTokenAddress: "0xDccEaf232b9B78cacCde26CC0b8d3566a9cF7202",
      coingeckoId: "dai",
      helperAddress: "0x47D80F77A708df5a5d47BF254148415f9ab1BA96",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x3F629aC8ce165DEfAd238C53aE5dB4D9abA38451",
      coingeckoId: "usd-coin",
      helperAddress: "0xa2BBe73bd596d1914142196162B2AE9830e57F66",
      type: "USD+",
    },
    {
      name: "MIM",
      stakingTokenAddress: "0x099233d2F02696B53aAaa3A21311206606C9a39e",
      coingeckoId: "magic-internet-money",
      helperAddress: "0x5Ab70773Aa481b5ed238EB5BDEF165944516C1e1",
      type: "normal",
    },
    {
      name: "USDT",
      stakingTokenAddress: "0xb5d6246b13A7B8c79Cf53B0DCa994E219d8D7eaa",
      coingeckoId: "tether",
      helperAddress: "0xAd8675391bD32FcC10a669723ECE64b5538e6ed5",
      type: "MIM",
    },
    {
      name: "BOB",
      stakingTokenAddress: "0x5Aee1f667c87f5d34e2c4544B42eB94B4E0dEb3D",
      coingeckoId: "bob",
      helperAddress: "0x4b34Bde093910F87D064f07F0731F08b21C8BeBD",
      type: "normal",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0x432323bEaaDe7f03d550A36470b29BdC7e4a92AA",
      coingeckoId: "usd-coin",
      helperAddress: "0x95904b09a46De05Fe01C04c37e7e7D434040467d",
      type: "BOB",
    },
    {
      name: "mWOM",
      stakingTokenAddress: "0xC2048D538af2dFCB17d365A6AfC0e302b5D908D6",
      coingeckoId: "wombat-exchange",
      helperAddress: "0xf600256C97Bb672a5aA726d1f30edD82B8698440",
      type: "normal",
    },
    {
      name: "WOM",
      stakingTokenAddress: "0x7844e8B49cF92273B89C0091ee8FF2FEd38d6954",
      coingeckoId: "wombat-exchange",
      helperAddress: "0x30666e273AC73F4486837248712C62a097180052",
      type: "normal",
    },
    {
      name: "frxETH",
      stakingTokenAddress: "0x35b02Ec660070a4D6F7BA3252605f3555772F345",
      coingeckoId: "frax-ether",
      helperAddress: "0x735078cB6694fb147846f3578Ec1558559E152Ad",
      type: "normal",
    },
    {
      name: "WETH",
      stakingTokenAddress: "0xe96b7Abd6C856545F31b5B0640879C42F1A8582E",
      coingeckoId: "weth",
      helperAddress: "0x583f3bdbFDA95BB4218AD2CFbf1D855eC8D2Bc42",
      type: "normal",
    },
    {
      name: "FRAX",
      stakingTokenAddress: "0x5aE4972F12a1289A17aD3a6b498442ed50d001D2",
      coingeckoId: "frax",
      helperAddress: "0xDF7Ba7dE8c2aE46a986241A0A0F4a92aC7e38F4a",
      type: "normal",
    },
    {
      name: "MAI",
      stakingTokenAddress: "0xBc013b2798373f582B5843BB9821Eca4B791A8D9",
      coingeckoId: "mai",
      helperAddress: "0xd0A280CCf5dbbfe5f7ebd54288dAEFe2EB6EC17D",
      type: "normal",
    },
    {
      name: "USD+",
      stakingTokenAddress: "0x16dc32e7165b070575eE49fcbC47373d1a7905Ff",
      coingeckoId: "usdplus",
      helperAddress: "0x2e82eeF72EE1A663A6092caE537694328103bd0b",
      type: "FRAX-MAI-USD+",
    },
    {
      name: "USDC",
      stakingTokenAddress: "0xa6fDA8A2f35D86bfbC315bEC5f34C1c40Ee26c34",
      coingeckoId: "usd-coin",
      helperAddress: "0x40b4dF3A88edAd470D190FB59BFAB1dC0d011294",
      type: "FRAX-MAI-USD+",
    },
  ],
};
