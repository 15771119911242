import React, { useState } from "react";
import _ from "lodash";
import { makeStyles, useTheme } from "@mui/styles";
import { formatDate, formatDateAndTime } from "features/helpers/utils";
import { useNavigate } from "react-router-dom";
import { useFetchTeamMember, useFetchTicketList } from "../redux/hooks";
import Channel from "./Channel";
import TicketStatus from "./TicketStatus";
import { getUserAvatarIcon } from "../utils";
import { getShortAddress } from "features/helpers/utils";
import { useConnectWallet } from "features/home/redux/connectWallet";
import Comment from "./Comment";
import TicketTypeLabel from "./TicketTypeLabel";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  timestampLg: {
    fontSize: 12,
    color: "#555555",
  },
}));
const kMapping = {
  "Created Time": "created",
  "Last Updated": "message.created",
  "Last Message": "message.message",
  Assignee: "team.name",
  Status: "status",
  "Issue Tag": "type",
  "Ticket Title": "title",
  Channel: "from",
  Address: "user.address",
  Comment: "comment",
};

const invertedMapping = _.invert(kMapping);
const TicketTable = ({
  ticketList,
  setOpenTicketDetail,
  entityName,
  containerStyle = {},
  noDataComponent,
  orderData = { order: -1, sort: "created" },
  setOrderData = () => {},
}) => {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);

  const theme = useTheme();
  const classes = useStyles();
  const { socket, userData } = useConnectWallet();
  const { memberList } = useFetchTeamMember();
  const [commentMapping, setCommentMapping] = useState({});
  const isNewMessage = (ticket) => {
    let hasNewMessage = _.get(ticket, "hasNewMessage", false);
    if (hasNewMessage) {
      return true;
    }
    return false;
  };

  const handleReadMessage = (ticketId) => {
    const ticket = _.find(ticketList, { _id: ticketId });
    if (
      socket &&
      _.get(userData, "address") == _.get(ticket, "team.address", "N/A")
    ) {
      socket.emit("adminReadTicket", {
        adminAddress: _.get(userData, "address"),
        ticketId: ticketId,
        entityName,
      });
    }
  };

  const handleSort = (e, property) => {
    const by = kMapping[property];
    const dir = orderData.order > 0 ? -1 : 1;

    setOrderData({ sort: by, order: dir });
  };

  const getRowValue = {
    Status: (ticket) => (
      <Box slot="Status" style={{ padding: "15px 9px" }}>
        <TicketStatus status={ticket.status} />
      </Box>
    ),
    Channel: (ticket) => (
      <Box slot="Channel">
        <Channel ticket={ticket} />
      </Box>
    ),
    "Issue Tag": (ticket) => (
      <Box slot="Issue Tag">
        <TicketTypeLabel ticketType={ticket.type} />
      </Box>
    ),
    "Ticket Title": (ticket) => (
      <Box
        slot="Ticket Title"
        style={{
          minWidth: 150,
          fontWeight: 500,
          fontSize: 14,
          color: "#555555",
        }}
        className="startRow"
      >
        {_.truncate(_.get(ticket, "title", ""))}
      </Box>
    ),
    Address: (ticket) => {
      const userId = _.isObject(ticket.user) ? ticket.user.id : ticket.user;
      const didName = _.get(ticket, "userWallet.didNames[0].name", "");
      const icon = getUserAvatarIcon(userId, theme, didName);
      return (
        <Box
          slot="Address"
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: "#383538",
            width: 150,
          }}
          className="startRow"
        >
          {_.get(ticket, "userWallet.address", "") && (
            <img
              src={require(`assets/img/${icon}`)}
              className="smIcon"
              style={{ borderRadius: 20 }}
            />
          )}

          <span>
            {getShortAddress(_.get(ticket, "userWallet.address", ""))}
          </span>
        </Box>
      );
    },
    "Last Message": (ticket) => {
      const isNew = isNewMessage(ticket);
      return (
        <Box slot="Last Message" style={{ position: "relative" }}>
          {isNew && (
            <span
              style={{
                position: "absolute",
                right: 0,
                top: "-5px",
                width: "8px",
                height: "8px",
                background: "#FF3296",
                borderRadius: 40,
              }}
            />
          )}
          <div
            style={{
              color: isNew ? "#383538" : "#555555",
              minWidth: 120,
              fontSize: 14,
              maxHeight: 20,
              overflow: "hidden",
              fontWeight: isNew ? 500 : 400,
            }}
            className="startRow"
          >
            {_.truncate(_.get(ticket, "message.message", ""))}
          </div>
        </Box>
      );
    },
    "Created Time": (ticket) => (
      <Box
        slot="Created Time"
        className={classes.timestampLg}
        style={{ minWidth: 110, fontWeight: 400, fontSize: 12 }}
      >
        {formatDate(_.get(ticket, "created", ""), { time: true })}
      </Box>
    ),
    "Last Updated": (ticket) => (
      <Box
        slot="Last Updated"
        className={classes.timestampLg}
        style={{ minWidth: 110, fontWeight: 400, fontSize: 12 }}
      >
        {formatDate(_.get(ticket, "message.created", ""), { time: true })}{" "}
      </Box>
    ),
    Assignee: (ticket) => (
      <Box
        slot="Assignee"
        style={{ color: "#A2BFD3", textDecorationLine: "underline" }}
      >
        {_.get(ticket, "team.name", "N/A")}
      </Box>
    ),
    Collaborators: (ticket) => {
      return (
        <Box
          slot="Collaborators"
          style={{ color: "#A2BFD3", textDecorationLine: "underline" }}
        >
          {ticket.tagTeam && ticket.tagTeam.length > 0
            ? _.map(ticket.tagTeam, (team, i) => {
                return (
                  <span style={{ marginRight: 5 }} key={i}>
                    {_.get(
                      _.find(memberList, {
                        _id: team,
                      }),
                      "name",
                      "N/A"
                    )}
                  </span>
                );
              })
            : "N/A"}
        </Box>
      );
    },
    Comment: (ticket) => {
      if (!ticket.comment && !commentMapping[ticket._id])
        return (
          <Box slot="Comment">
            <Comment
              entityName={entityName}
              selectedTicket={ticket}
              row={true}
              comment={commentMapping[ticket._id]}
              setComment={(v) =>
                setCommentMapping({
                  ...commentMapping,
                  [ticket._id]: v,
                })
              }
            />
          </Box>
        );
      return (
        <Box slot="Comment">
          {_.truncate(commentMapping[ticket._id] || ticket.comment)}
        </Box>
      );
    },
  };

  const headers = [
    {
      show: true,
      disabledSort: false,
      id: "Status",
      label: "Status",
    },
    {
      show: true,
      disabledSort: true,
      id: "Channel",
      label: "Channel",
    },
    {
      show: true,
      disabledSort: true,
      id: "Issue Tag",
      label: "Issue Tag",
    },

    {
      show: true,
      disabledSort: false,
      id: "Ticket Title",
      label: "Ticket Title",
    },
    {
      show: true,
      disabledSort: false,
      width: 150,
      id: "Last Message",
      label: "Last Message",
    },
    {
      show: true,
      disabledSort: false,
      id: "Created Time",
      width: 150,
      label: "Created Time",
    },

    {
      show: true,
      disabledSort: false,
      id: "Last Updated",
      width: 150,
      label: "Last Updated",
    },
    {
      show: true,
      disabledSort: false,
      id: "Address",
      label: "Address",
    },
    {
      show: true,
      disabledSort: false,
      id: "Assignee",
      label: "Assignee",
    },
    {
      show: true,
      disabledSort: true,
      id: "Collaborators",
      label: "Collaborators",
    },
    {
      show: true,
      disabledSort: false,
      id: "Comment",
      label: "Comment",
    },
  ];

  return (
    <CRMTable
      containerStyle={containerStyle}
      headers={headers}
      onRequestSort={handleSort}
      orderBy={invertedMapping[orderData.sort]}
      order={orderData.order > 0 ? "asc" : "desc"}
      noDataComponent={noDataComponent}
      onRowClick={(ticketId) => {
        handleReadMessage(ticketId);
        setOpenTicketDetail(ticketId);
      }}
      noDataTitle="No Ticket"
      rowData={ticketList}
      contents={_.map(ticketList, (ticket) => {
        return _.map(headers, (header) => getRowValue[header.label](ticket));
      })}
    />
  );
};

export default TicketTable;
