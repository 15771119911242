import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import { getShortAddress } from "features/helpers/utils";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import React, { useEffect, useCallback } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { useOnboardingState } from "../OnboardingProvider";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export default function NoWorkspace({ prevStep }) {
  const {
    address: evmAddress,
    isConnected: evmConnected,
    isConnecting: evmConnecting,
  } = useAccount({});
  const copyFn = useCopyToClipboard(evmAddress);
  const { nextStep } = useOnboardingState();
  const { disconnect } = useDisconnect();
  const sendDataToGTM = useGTMDispatch();

  const trackEvent = useCallback(
    (eventName, eventData = {}) => {
      try {
        sendDataToGTM({
          event: eventName,
          ...eventData,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Failed to send GTM event:", error);
      }
    },
    [sendDataToGTM]
  );

  useEffect(() => {
    trackEvent("onboarding_no_workspace_view");
  }, [trackEvent]);

  const handleCreateWorkspace = useCallback(() => {
    trackEvent("onboarding_create_workspace_click");
    nextStep();
  }, [trackEvent, nextStep]);

  const handleDisconnect = useCallback(async () => {
    trackEvent("onboarding_switch_account_click");
    try {
      await disconnect();
      trackEvent("onboarding_account_switched");
      prevStep();
    } catch (error) {
      trackEvent("onboarding_account_switch_error", {
        error_message: error.message,
      });
    }
  }, [disconnect, prevStep, trackEvent]);

  const handleCopyAddress = useCallback(() => {
    trackEvent("onboarding_copy_address_click");
    copyFn();
  }, [copyFn, trackEvent]);

  return (
    <Box position="relative" sx={{ maxHeight: "100vh", overflow: "auto" }}>
      <Box
        sx={{
          height: "50vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          bgcolor: "#FAFAFD",
        }}
        pb="48px"
      >
        <Box sx={{ width: "400px", textAlign: "center" }}>
          <img
            src={require("assets/img/onboardingLogo.png")}
            style={{ width: 79, height: 79 }}
            alt="MetaCRM Logo"
          />
          <Box fontSize="24px" fontWeight={700} mt="8px" mb="4px">
            Create a new workspace
          </Box>
          <Box color="#777" fontSize="14px" mb="32px">
            Create your company workspace to streamline processes, track key
            metrics, and enhance team collaboration.
          </Box>
          <Button onClick={handleCreateWorkspace}>Create Workspace</Button>
        </Box>
      </Box>
      <Box
        position="absolute"
        top="50%"
        left={"50%"}
        sx={{ transform: "translate(-50%, -50%)" }}
        color="#777"
        fontSize="14px"
      >
        or
      </Box>
      <Box
        sx={{
          minHeight: "50vh",
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        pt="48px"
        pb="24px"
      >
        <Box fontSize="20px" fontWeight={700} mb="4px">
          Is your team already using MetaCRM?
        </Box>
        <Box fontSize="14px" mb="4px" color="#777">
          We couldn't find any workspace for the account (wallet)
        </Box>
        <Box fontSize="14px" color="#7B61FF" mb="24px">
          {getShortAddress(evmAddress)}
        </Box>
        <Box display="flex" alignItems={"stretch"} gap="12px" flexWrap={"wrap"}>
          <Box
            p="24px"
            width="280px"
            bgcolor="#FAFAFD"
            borderRadius="4px"
            m="0 auto"
            lineHeight="normal"
          >
            <Box color="#555" fontSize="14px" mb="12px" fontWeight={500}>
              Ask your teammate to invite you to the workspace.
            </Box>
            <Box color="#777" fontSize="14px" fontWeight={400}>
              1. You haven't been invited to the workspace.
            </Box>
            <Box color="#777" fontSize="14px" fontWeight={400} mb="12px">
              2. Your account (wallet) information may have been entered
              incorrectly when inviting you.{" "}
            </Box>

            <Box mb="4px">Your Account (wallet)</Box>
            <Box
              fontSize="12px"
              fontWeight={600}
              color="#7B61FF"
              display="flex"
              alignItems="center"
              gap="4px"
            >
              {getShortAddress(evmAddress)}
              <i
                className="meta-crm-icon-ic_copy font-size-16"
                style={{ cursor: "pointer", fontWeight: 300 }}
                onClick={handleCopyAddress}
              />
            </Box>
          </Box>

          <Box
            p="24px"
            width="280px"
            bgcolor="#FAFAFD"
            borderRadius="4px"
            m="0 auto"
            lineHeight="normal"
          >
            <Box color="#555" fontSize="14px" mb="12px" fontWeight={500}>
              Use another account to connect with MetaCRM.
            </Box>
            <Box color="#777" fontSize="14px" fontWeight={400} mb="12px">
              Your teammate might invite you using your other account (wallet).
            </Box>

            <Box
              fontSize="13px"
              fontWeight={700}
              color="#7B61FF"
              onClick={handleDisconnect}
              sx={{ cursor: "pointer" }}
            >
              {`Switch Account (Wallet) ->`}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
