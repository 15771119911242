import React, { useState, useEffect, useRef, useContext } from "react";
import { getInitialWidgetObject } from "./utils/index";
import Back from "features/metadesk/components/Back";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";
import CampaignStep1 from "./CampaignStep1";
import CampaignStep3 from "./CampaignStep3";
import CampaignStep2 from "./CampaignStep2";
import BottomFixedButton from "./BottomFixedButton";
import SelectCampaignModal from "./component/SelectCampaignModal";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { Loading } from "features/common";
import useCampaignSave from "./hook/useCampaignSave";

const CreateCampaign = () => {
  const { stepData, setStepValues, loading } = useContext(
    CampaignCreationContext
  );
  const { campaignId } = useParams();
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const initialType = urlSearchParams.get("promotionType");
  const defaultTailoredAudience = urlSearchParams.get("tailoredAudience");
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(true);

  const stepName = [
    formatMessage({ id: "global.property.audience" }),
    formatMessage({ id: "global.function.objective" }),
    formatMessage({ id: "global.function.detail" }),
  ];

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (initialType && initialType == "widget") {
      setStepValues({
        campaignContentSets: [
          ...stepData.campaignContentSets,
          getInitialWidgetObject("onchain", defaultTailoredAudience),
        ],
      });
      handleClose();
    }
  }, [initialType]);

  return (
    <Box display="flex" position={"relative"} minHeight={"100%"}>
      {/* <CampaignSideMenu /> */}
      <Loading open={loading} fullScreen={true} white={true} />
      <Box width={"100%"} margin="0 auto">
        <Back></Back>

        {step === 1 && <CampaignStep1 step={step} stepName={stepName} />}
        {step === 2 && <CampaignStep2 step={step} stepName={stepName} />}
        {step === 3 && <CampaignStep3 step={step} stepName={stepName} />}

        <BottomFixedButton
          step={step}
          setStep={setStep}
          setIsOpen={setIsOpen}
        />
        {!campaignId && (
          <SelectCampaignModal
            step={step}
            setStep={setStep}
            open={isOpen}
            onClose={handleClose}
            onCancel={handleCancel}
          ></SelectCampaignModal>
        )}
      </Box>
    </Box>
  );
};

export default CreateCampaign;
