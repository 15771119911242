import React, { useRef, useState } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import ImagePreview from "features/metadesk/components/ImagePreview";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import Box from "@mui/material/Box";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Navigation, Controller } from "swiper/modules";
import { StyledSwiper } from "./EditNotificationPreview.styles";
import CustomEditPreview from "./CustomEditPreview";
import EditNotificationPreviewNew from "features/metadesk/campaign/campaignEdit/EditNotificationPreviewNew";

const EditNotificationPreviewContainer = ({
  data,
  startFrom,
  imgs = [],
  name,
}) => {
  const theme = useTheme();
  return (
    <>
      {data.type === "tracker" ? (
        <CustomEditPreview data={data} imgs={imgs}></CustomEditPreview>
      ) : (
        <EditNotificationPreviewNew
          data={data}
          imgs={imgs}
          startFrom={startFrom}
          name={name}
        ></EditNotificationPreviewNew>
      )}
    </>
  );
};

export default EditNotificationPreviewContainer;
