import React from "react";
import { useTheme } from "@mui/styles";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { getUserAvatarIcon } from "features/metadesk/utils";
import { getShortAddress } from "features/helpers/utils";

export default function UserAddress({ id, address }) {
  const theme = useTheme();
  const icon = getUserAvatarIcon(id, theme);

  return (
    <div className={`startRow`} style={{ flexWrap: "nowrap" }}>
      <img
        src={require(`assets/img/${icon}`)}
        className="icon"
        style={{ borderRadius: 20, marginRight: "4px" }}
      />
      <IconTooltip
        style={{ color: "#555", fontSize: 14 }}
        icon={getShortAddress(address)}
        content={address}
      />
    </div>
  );
}
