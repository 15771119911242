import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";

import { apiUrl } from "features/configure";
import axios from "axios";
import { alertServerError } from "features/helpers/utils";
import { useDispatch } from "react-redux";
import {
  useFetchEntityConfig,
  useFetchTags,
} from "features/metadesk/redux/hooks";
import { enqueueSnackbar } from "features/common/redux/snackbar";
import CustomUnassignModal from "components/CustomModals/CustomUnassignModal";
const UnassignTagsModal = ({ open, onClose, entityName, choose, uniqTags }) => {
  const theme = useTheme();
  const [isAdding, setIsAdding] = useState(false);
  const [unassignTags, setUnassignTags] = useState([]);
  const { tags } = useFetchTags();
  const { entityConfig } = useFetchEntityConfig();
  const dispatch = useDispatch();
  const isSelectUser = open == "selected";
  useEffect(async () => {
    setUnassignTags([]);
  }, [open]);

  const closeModal = (isRefresh) => {
    setUnassignTags([]);
    onClose(isRefresh);
  };

  function handleClickCheckbox(e, tag) {
    if (e.target.checked) {
      setUnassignTags([...unassignTags, tag._id]);
    } else {
      setUnassignTags(unassignTags.filter((id) => id !== tag._id));
    }
  }

  const handleUnAssign = async () => {
    try {
      setIsAdding(true);
      const data = { type: "user", tagIds: unassignTags };
      if (isSelectUser) {
        data.targetIds = choose;
      } else {
        data.userSourceId = open;
      }

      const result = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/batchRemove/user`,
        data
      );

      dispatch(
        enqueueSnackbar({
          message: "Batch unassign tags success.",
          options: {
            variant: "info",
          },
        })
      );
      closeModal(true);
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <CustomUnassignModal
      open={open}
      unassignTags={unassignTags}
      isLoading={isAdding}
      tags={isSelectUser ? uniqTags : tags[entityName]}
      handleClose={() => closeModal(true)}
      handleClickCheckbox={handleClickCheckbox}
      handleUnAssign={handleUnAssign}
    />
  );
};

export default UnassignTagsModal;
