import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import Button, { VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";

import useNavigateEntity from "hooks/useNavigateEntity";

const TelegramSettingStep3 = ({ onPrevStep, onNextStep }) => {
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();

  const handleBackToSetting = () => {
    navigateEntity("/settings/general/integration");
  };

  const handleToCustomize = () => {
    navigateEntity("/settings/general/integration/update/telegram");
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        src={require(`assets/img/discordIntegrationDone.svg`).default}
        style={{ height: "50vh", marginTop: 60 }}
      />
      <Box className="lgTitle" mb="16px">
        Integration Completed 🎉
      </Box>
      <Box
        mb="16px"
        sx={{ width: "460px", textAlign: "center" }}
        color={(theme) => theme.customColors.grey[600]}
      >
        {formatMessage({
          id: "telegramBot.integrationWizard.success.description",
        })}
      </Box>
      <Box>
        <Button
          variant={VARIANT.OUTLINED}
          onClick={handleBackToSetting}
          sx={{ marginRight: "8px" }}
        >
          Back to Setting
        </Button>
        <Button onClick={handleToCustomize}>Go Customize</Button>
      </Box>
    </Box>
  );
};

export default TelegramSettingStep3;
