import React from "react";
import _ from "lodash";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styled from "@emotion/styled/macro";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, buttonColor, readonly }) => ({
    display: "flex",
    flexWrap: "wrap",
    cursor: readonly ? "default" : "pointer",
  })
);

export const StyledToggleButton = styled(ToggleButton)(
  ({ theme, buttonColor, readonly }) => ({
    marginRight: "8px",
    border: `0px solid ${theme.customColors.grey[400]} !important`,
    borderRadius: "4px !important",
    padding: "4px 8px",
    color: theme.customColors.grey[500],
    textTransform: "none",
    cursor: readonly ? "default" : "pointer",
    "&:hover": {
      backgroundColor: theme.customColors.purple[300],
    },

    "&.Mui-selected": {
      color: `${theme.customColors.white} !important`,
      "&:hover": {
        backgroundColor: buttonColor
          ? buttonColor
          : theme.customColors.purple[600],
      },
      backgroundColor: buttonColor || theme.customColors.purple[500],
      border: `1px solid ${
        buttonColor || theme.customColors.purple[500]
      } !important`,
    },
  })
);

export default function CustomToggle({ value, onChange, data, fullWidth }) {
  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      style={{ textAlign: "right" }}
    >
      {data.map((button, index) => (
        <StyledToggleButton key={index} value={button.value}>
          {button.text}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}

CustomToggle.defaultProps = {};
