import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import { useIntl } from "react-intl";
import Grow from "@mui/material/Grow";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { enqueueSnackbar } from "features/common/redux/actions";
import useGetPagePermission from "hooks/useGetPagePermission";
import { alertInfo, parseCustomDomainUrl } from "features/helpers/utils";
import { apiUrl } from "features/configure";
import { MetaCRMTag } from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import IntegrationSettings from "./IntegrationSettings";
import BasicModal from "features/metadesk/chat/BasicModal";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import { StyledAddTicketTypeButton } from "../Customization/OnChainDataSource/OnChainDataSource.styles";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { Loading } from "features/common";
import {
  StyledMenu,
  StyledShowMoreButton,
} from "../Macros/QuestionBlock/QuestionBlock.styles";
import {
  StyledSlackConfigContainer,
  StyledIntegrationWrapper,
} from "./IntegrationSlack.styles";

const IntegrationSlack = ({ isSetting = false }) => {
  const anchorRef = useRef(null);
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("integration");

  const [entityName, isCustomDomain] = useOutletContext();
  const { fetchEntityConfig } = useFetchEntityConfig();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  const [channelList, setChannelList] = useState([]);
  const [defaultEvents, setDefaultEvents] = useState([]);
  const [slackBotConfigData, setSlackBotConfigData] = useState({});

  const [selectedChannelName, setSelectedChannelName] = useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);

  const isValidToSave = () => {
    return (
      (!_.isEqual(selectedChannelName, channelName) ||
        !_.isEqual(selectedEvents, events)) &&
      selectedEvents.length > 0
    );
  };

  const actionMapping = {
    ticketcreated: formatMessage({
      id: "slackNotificationSettings.action.newTicket",
    }),
    messagereceived: formatMessage({
      id: "slackNotificationSettings.action.newMessage",
    }),
    ticketclosed: formatMessage({
      id: "slackNotificationSettings.action.ticketDone",
    }),
  };

  const channelName = _.get(slackBotConfigData, "config.channelName", "");
  const events = _.get(slackBotConfigData, "config.events", []);
  const publicChannelList = _.filter(
    _.get(slackBotConfigData, "config.publicChannels", []),
    "channelName"
  );

  useEffect(async () => {
    if (!isSetting) {
      await connectSlackWorkspace();
    } else {
      await handleRefreshWorkspaceData();
    }
  }, []);

  useEffect(() => {
    setSelectedChannelName(channelName);
    setSelectedEvents(events);
    setChannelList(publicChannelList);
  }, [slackBotConfigData]);

  const handleToggle = () => {
    setOpenPopover((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopover(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenPopover(false);
    } else if (event.key === "Escape") {
      setOpenPopover(false);
    }
  }

  const handleOpenEditModal = () => {
    setOpenModal(true);
  };

  const fallbackRoute = () => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/settings/general/integration"
      )
    );
  };

  const connectSlackWorkspace = async () => {
    try {
      setLoading(true);

      const tempCode = searchParams.get("code");
      const error = searchParams.get("error");

      if (error || !tempCode) {
        fallbackRoute();
      }

      const [eventResult, configData] = await Promise.all([
        await axios.get(apiUrl + `/api/webhook/${entityName}/events`),
        await axios.get(
          apiUrl + `/api/slack/${entityName}/getOAuthConfig?code=${tempCode}`
        ),
      ]);
      setDefaultEvents(eventResult.data);
      setSlackBotConfigData(configData.data);
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: _.get(err, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
      fallbackRoute();
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshWorkspaceData = async () => {
    try {
      setLoading(true);

      const [eventResult, dataResult] = await Promise.all([
        await axios.get(apiUrl + `/api/webhook/${entityName}/events`),
        await axios.get(
          apiUrl + `/api/slack/${entityName}/refreshWorkspaceData`
        ),
      ]);
      setDefaultEvents(eventResult.data);
      setSlackBotConfigData(dataResult.data);
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: _.get(err, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
      fallbackRoute();
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    confirm({
      title: "Warning",
      content: (
        <div style={{ width: 250, marginBottom: 20 }}>
          Are you sure to delete?
        </div>
      ),
      confirmText: formatMessage({
        id: "Delete",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(async () => {
        try {
          await axios.delete(apiUrl + `/api/slack/${entityName}/delete`);
          setSlackBotConfigData({});
          fetchEntityConfig({ entityName });
          dispatch(alertInfo("Delete success!"));
          fallbackRoute();
        } catch (err) {
          dispatch(
            enqueueSnackbar({
              message: _.get(err, "response.data.error", "error"),
              options: {
                variant: "error",
              },
            })
          );
        }
      })
      .catch(() => {});
  };

  const renderSlackNotificationConfigItem = () => {
    if (!events.length) return;

    return (
      <div style={{ marginRight: 40 }}>
        <div className="betweenRow" style={{ marginBottom: 12 }}>
          <StyledSlackConfigContainer className="betweenRow">
            <Box>
              <Box
                display={"flex"}
                mb={"16px"}
                alignItems={"center"}
                columnGap={"4px"}
              >
                <div className="label">{"Channel Name"}:</div>
                <div>{channelName}</div>
              </Box>
              <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
                <div className="label">
                  {formatMessage({
                    id: "discordNotificationSettings.action.title",
                  })}
                  :
                </div>
                <div className="startRow">
                  {_.map(events, (eId, i) => {
                    const matchEvent = _.find(
                      defaultEvents,
                      (de) => de._id === eId
                    );
                    const keyName = matchEvent.model + matchEvent.action;
                    return (
                      <MetaCRMTag
                        key={i}
                        option={{ label: actionMapping[keyName] }}
                        style={{
                          marginRight: 10,
                          borderRadius: 12,
                          padding: "2px 6px",
                          color: theme.customColors.grey[600],
                        }}
                      />
                    );
                  })}
                </div>
              </Box>
            </Box>
          </StyledSlackConfigContainer>
          {canEdit && (
            <>
              <StyledShowMoreButton
                ref={anchorRef}
                onClick={handleToggle}
                color={theme.customColors.purple[500]}
              >
                <MoreHorizIcon />
              </StyledShowMoreButton>

              <Popper
                open={openPopover}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <StyledMenu>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openPopover}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleOpenEditModal} disableRipple>
                            <i className="meta-crm-icon-ic_edit-1 font-size-16" />
                            {formatMessage({ id: "global.btn.edit" })}
                          </MenuItem>
                          <MenuItem onClick={handleDelete} disableRipple>
                            <i className="meta-crm-icon-ic_trash font-size-16" />
                            {formatMessage({ id: "global.btn.delete" })}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </StyledMenu>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </div>
      </div>
    );
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      let payload = {
        channelId: _.find(
          channelList,
          (c) => c.channelName === selectedChannelName
        ).channelId,
        channelName: selectedChannelName,
        events: selectedEvents,
      };

      if (!isSetting) {
        payload = {
          ...payload,
          workspaceId: _.get(slackBotConfigData, "config.workspaceId", null),
          workspaceName: _.get(
            slackBotConfigData,
            "config.workspaceName",
            null
          ),
          workspaceAccessToken: _.get(
            slackBotConfigData,
            "config.workspaceAccessToken"
          ),
        };
      }

      const { data } = await axios.post(
        apiUrl + `/api/slack/${entityName}/saveAndTest`,
        payload
      );

      if (data.success) {
        setSlackBotConfigData(data);
        setOpenModal(false);
        fetchEntityConfig({ entityName });
        dispatch(
          alertInfo(isSetting ? "Update success!" : "connection success!")
        );
        if (!isSetting) {
          fallbackRoute();
        }
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: _.get(err, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const renderSlackNotificationModal = () => {
    return (
      <BasicModal
        open={openModal}
        mode="action"
        textAlign="left"
        buttonWidth={"180px"}
        onClose={() => {
          setOpenModal(false);
        }}
        confirmDisabled={!isValidToSave() || loading}
        modalTitle={formatMessage({
          id: "slackNotificationSettings.popup.title",
        })}
        modalContent={
          <div>
            <Box className="subTitle" mb="8px" mt="20px">
              {formatMessage({
                id: "slackNotificationSettings.addNew.argetChannel.title",
              })}
            </Box>
            <CustomDropdown
              border
              data={_.compact(
                _.map(channelList, (c) => {
                  if (!c) return;
                  return { label: c.channelName, value: c.channelName };
                })
              )}
              fullWidth
              size={SIZE.XL}
              placeholder={"Select your target channel"}
              value={selectedChannelName}
              onSelect={(e) => {
                const { value } = e.target;
                setSelectedChannelName(value);
              }}
            />
            <div className="hr" />
            <Box className="subTitle" mb="8px">
              {formatMessage({
                id: "slackNotificationSettings.action.title",
              })}
            </Box>
            <Box className="description" mb="12px">
              {formatMessage({
                id: "slackNotificationSettings.action.description",
              })}
            </Box>
            {_.map(defaultEvents, (actionEvent) => {
              if (!actionEvent) return;
              const actionKey = actionEvent.model + actionEvent.action;
              return (
                <Box mb="12px" key={actionKey}>
                  <Checkbox
                    name="events"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      let newActions = [...selectedEvents];
                      if (checked) {
                        newActions.push(actionEvent._id);
                      } else {
                        newActions = _.filter(
                          newActions,
                          (item) => item !== actionEvent._id
                        );
                      }
                      setSelectedEvents(newActions);
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: "Events is required",
                      },
                    }}
                    label={actionMapping[actionKey]}
                    checked={_.includes(selectedEvents, actionEvent._id)}
                    mr={5}
                  />
                </Box>
              );
            })}

            <Box className="hr" mb="18px" />
          </div>
        }
        confirmText={
          loading
            ? "Saving..."
            : formatMessage({
                id: "global.btn.sendTest",
              })
        }
        onHandleConfirm={handleSave}
      />
    );
  };

  return (
    <>
      {renderSlackNotificationModal()}
      <StyledIntegrationWrapper isSetting={isSetting}>
        <IntegrationSettings
          maxWidth={622}
          title={formatMessage({ id: "integration.slackNotification.title" })}
          description={formatMessage({
            id: "integration.slackNotification.description",
          })}
          content={
            <div>
              <Loading open={loading} fullScreen={false} />
              <Box
                mb="18px"
                position={"relative"}
                top={"-8px"}
                sx={{ border: `0.5px solid ${theme.customColors.grey[300]}` }}
              />
              <div style={{ maxWidth: 800 }}>
                {renderSlackNotificationConfigItem()}
                {!events.length ? (
                  <StyledAddTicketTypeButton
                    onClick={() => {
                      if (readonly) return;
                      setOpenModal(true);
                    }}
                    style={{
                      padding: 12,
                      borderRadius: 8,
                      height: 40,
                      marginRight: 40,
                      cursor: "pointer",
                      color: theme.customColors.purple[500],
                    }}
                  >
                    <i
                      className="meta-crm-icon-ic_add font-size-16"
                      style={{ marginRight: 12 }}
                    />
                    {"Add a channel"}
                  </StyledAddTicketTypeButton>
                ) : (
                  false
                )}
              </div>
            </div>
          }
        />
      </StyledIntegrationWrapper>
    </>
  );
};

export default IntegrationSlack;
