import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledCustomizationContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  backgroundColor: "#ffffff",
  height: "100vh",
  width: "520px",
  position: "fixed",
  paddingBottom: "0px",
  top: "0px",
  right: "0px",
  zIndex: 10000,
  display: "flex",
  flexDirection: "column",
}));

export const StyledImportPopoverTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 700,
  fontSize: "18px",
  marginBottom: "8px",
  color: theme.customColors.grey[700],
}));

export const StyledImportPopoverDescription = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  marginBottom: "24px",
  color: theme.customColors.grey[600],
}));
