import React from "react";
import _ from "lodash";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";

export default function CustomDataFetching({ contents, hasData, loading }) {
  return (
    <div
      style={{
        borderBottom: "0px",
      }}
    >
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <LoadingIcon className="icon" />
        </div>
      ) : hasData ? (
        contents()
      ) : (
        <div className="noData">
          <div className="row">
            <img
              src={require("assets/img/boxOpen.svg").default}
              className="icon"
            />
            No Data
          </div>
        </div>
      )}
    </div>
  );
}
