import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/material";
import { useConnectWallet } from "features/home/redux/connectWallet";
import React, { useState, useEffect, useCallback } from "react";
import { useAccount, useConnect } from "wagmi";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import { StyledLoginCard, StyledWarningLink } from "./Onboarding.styles";
import { useOnboardingState } from "./OnboardingProvider";

export default function Login() {
  const { nextStep } = useOnboardingState();
  const [walletType, setWalletType] = useState("");
  const sendDataToGTM = useGTMDispatch();
  const connectorsImg = {
    metaMask: require("assets/img/MetaMask.png"),
    injected: require("assets/img/BrowserWallet.png"),
    walletConnect: require("assets/img/WalletConnect.svg").default,
  };

  const {
    address: evmAddress,
    isConnected: evmConnected,
    isConnecting: evmConnecting,
  } = useAccount({});

  const trackEvent = useCallback(
    (eventName, eventData) => {
      try {
        sendDataToGTM({
          event: eventName,
          ...eventData,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Failed to send GTM event:", error);
      }
    },
    [sendDataToGTM]
  );

  useEffect(() => {
    trackEvent("onboarding_login_page_view");
  }, []);

  useEffect(() => {
    if (evmAddress && evmConnected) {
      trackEvent("onboarding_wallet_connected", { wallet_type: walletType });
      nextStep();
    }
  }, [evmAddress, evmConnected, walletType, trackEvent, nextStep]);

  const { connect, connectors } = useConnect();
  const theme = useTheme();

  const [currentConnectors, setCurrentConnectors] = useState([]);
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    let filteredConnectors = connectors;

    if (!computer) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name !== "MetaMask";
      });
    }
    if (!window?.ethereum) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name == "WalletConnect";
      });
    }

    const orderedConnectors = _.sortBy(filteredConnectors, (connector) => {
      return ["MetaMask", "Browser Wallet", "WalletConnect"].indexOf(
        connector.name
      );
    });

    setCurrentConnectors(orderedConnectors);
  }, [computer, connectors, window]);

  const handleConnectWallet = useCallback(
    async (connector) => {
      setWalletType(connector.name);
      trackEvent("onboarding_wallet_connect_click", {
        wallet_type: connector.name,
      });
      try {
        await connect({ connector });
      } catch (error) {
        trackEvent("onboarding_wallet_connect_error", {
          wallet_type: connector.name,
          error_message: error.message,
        });
      }
    },
    [connect, trackEvent]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        pt: "125px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FAFAFD",
      }}
    >
      <img src={require("assets/img/onboardingLogo.png")} alt="MetaCRM Logo" />
      <Box color="#383538" fontSize="24px" fontWeight={700}>
        Hello! Welcome to MetaCRM.
      </Box>
      <Box color="#777" fontSize="14px" mb="32px" mt={"4px"}>
        Log in or sign up by connecting your wallet.
      </Box>

      {currentConnectors.map((connector) => (
        <StyledLoginCard
          key={connector.id}
          onClick={() => handleConnectWallet(connector)}
        >
          <img
            src={connectorsImg[connector.id]}
            style={{ width: 36, height: 36 }}
            alt={`${connector.name} logo`}
          />
          <Box ml="12px">{connector.name}</Box>
        </StyledLoginCard>
      ))}
      <Box color={"#777"} fontSize="12px" display="flex" gap="2px">
        <span>By Connecting wallet, I agree to the</span>
        <StyledWarningLink
          href="https://metacrm.inc/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </StyledWarningLink>
        &{"  "}
        <StyledWarningLink
          href="https://metacrm.inc/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </StyledWarningLink>
      </Box>
    </Box>
  );
}
