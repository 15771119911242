import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  StyledDropDownBtn,
  StyledDropDownContainer,
  StyledDropDownText,
} from "features/metadesk/settings/IntegrationPage/components/integrationComponent.styles";
import useOutsideClick from "features/metadesk/campaign/campaignDetailPerformance/hook/useOutsideClick";

export default function XSortDropDown({ sortKey, setSortKey }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  const sortList = [
    { label: "Highest impressions", value: "impressions" },
    { label: "Highest engagement", value: "engagement" },
    { label: "Most recent", value: "postDate" },
  ];

  const handleReset = () => {
    setSortKey("");
  };

  const handleSortChange = (key) => {
    setSortKey(key);
  };

  return (
    <Box ref={ref} position="relative">
      <StyledDropDownBtn
        onClick={() => setIsOpen(true)}
        active={isOpen || sortKey}
        sx={{ bgcolor: "white" }}
      >
        <i className="meta-crm-icon-ic_sort font-size-16" />
        Sort
      </StyledDropDownBtn>
      {isOpen && (
        <StyledDropDownContainer>
          <Box sx={{ p: "6px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              height="24px"
              alignItems="center"
              fontSize="10px"
            >
              <Box color="#A5A5A5">Sort by</Box>
              <Box
                color="#7B61FF"
                sx={{ cursor: "pointer" }}
                onClick={handleReset}
              >
                Reset
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "#E5E5E5",
                margin: "4px 0",
              }}
            />
          </Box>
          <Box fontSize={12} color="#555">
            {sortList.map((item, index) => (
              <StyledDropDownText
                key={index}
                onClick={() => handleSortChange(item.value)}
              >
                {item.label}
                {sortKey === item.value ? (
                  <i
                    className="meta-crm-icon-ic_check font-size-24"
                    style={{ color: "#7B61FF" }}
                  />
                ) : (
                  <Box width={24} />
                )}
              </StyledDropDownText>
            ))}
          </Box>
        </StyledDropDownContainer>
      )}
    </Box>
  );
}
