import React from "react";
import CampaignHeader from "./CampaignHeader";
import CampaignFilter from "./CampaignFilter";
import CampaignTable from "./CampaignTable";
import { CampaignListProvider } from "./context/CampaignListProvider";
import AiChat from "features/metadesk/ADAM/AiChat";
import Box from "@mui/material/Box";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { get } from "lodash";

export default function CampaignList({ campaignStatus }) {
  const { entityConfig } = useFetchEntityConfig();
  const adamEnabled = get(entityConfig, "marketingModule.adam.enabled", false);
  return (
    <CampaignListProvider campaignStatus={campaignStatus}>
      <Box sx={{ position: "relative" }}>
        <CampaignHeader />
        <CampaignFilter />
        <div className="mhr" />
        <CampaignTable />
        {adamEnabled && <AiChat />}
      </Box>
    </CampaignListProvider>
  );
}
