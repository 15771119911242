import { Box } from "@mui/material";
import React from "react";

export default function StatusBox({ status }) {
  const statusMap = {
    Active: {
      text: "Active",
      backgroundColor: "#14C864",
    },
    "Update Available": {
      text: "Update Available",
      backgroundColor: "#FFC814",
    },
    "Setup Incomplete": {
      text: "Setup Incomplete",
      backgroundColor: "#FC5555",
    },
  };

  if (status === "not-connected") return null;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} mt="2px">
      <Box
        color="#777"
        fontSize="12px"
        fontWeight={400}
        mr="5px"
        whiteSpace={"nowrap"}
      >
        {statusMap[status].text}
      </Box>
      <Box
        bgcolor={statusMap[status].backgroundColor}
        width={8}
        height={8}
        borderRadius={4}
        flexShrink={0}
      />
    </Box>
  );
}
