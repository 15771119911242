import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton, Button } from "@metacrm/metacrm-material-ui/dist/Button";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

export const StyledMacroModalContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    position: "relative",
    width: "540px",
    height: "540px",
    background: theme.customColors.white,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    overFlow: "hidden",
    fontFamily: "Inter",
    "& *": {
      fontFamily: "Inter",
    },
  };
});

export const StyledCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    marginLeft: "auto",
    marginTop: "16px",
    marginRight: "16px",
    "&:hover": {
      background: "none",
    },
  };
});

export const StyledMacroModalContentInsideContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    padding: "12px 24px 16px",
  };
});

export const StyledClearButton = styled(Button, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "block",
    padding: "0",
    marginLeft: "auto",
    height: "unset",
  };
});

export const StyledMacroListContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: "3px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };
});

export const StyledTagInputPrefix = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[800],
}));

export const StyledAutoComplete = styled(Autocomplete, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, size = SIZE.XL, background, noBorder }) => ({
  "& .MuiInputBase-root": {
    padding: 0,
    paddingLeft: "12px",
    paddingTop: "2px",
    paddingBottom: "2px",
    color: theme.customColors.grey[700],
    background: background || "transparents",
    ...(size === SIZE.XL && {
      height: "40px",
    }),
    ...(size === SIZE.LARGE && {
      height: "36px",
    }),
    ...(size === SIZE.MEDIUM && {
      height: "32px",
    }),
    ...(size === SIZE.SMALL && {
      height: "28px",
    }),
    ...(size === SIZE.XS && {
      height: "24px",
    }),
    ...(size === SIZE.XSS && {
      height: "16px",
    }),

    "& .MuiAutocomplete-input": {
      padding: "3.5px 4px 3.5px 5px",
    },

    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.customColors.purple[500],
      },
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: noBorder ? "none" : `1px solid ${theme.customColors.grey[300]}`,
  },

  "& .MuiOutlinedInput-root": {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.customColors.purple[500],
      },
    },
  },
}));
