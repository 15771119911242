import React, { useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";

import { enqueueSnackbar } from "features/common/redux/actions";
import { selectServiceModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";

import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { useFetchAiSupportConfig } from "features/metadesk/redux/hooks";
import { Loading } from "features/common";
import GitbookUrlSource from "./GitbookUrlSource";
import moment from "moment";
import IconIcMagicWandRoundFilled from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMagicWandRoundFilled";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";

import { StyledLayoutItem } from "./MagicWand.styles";
import {
  StyledCustomizationContainer,
  StyledGeneralSettingsContainer,
} from "../Customization/TicketType/TicketType.styles";
import {
  AppDescriptionText,
  AppTitleText,
  ApplicationItemWrapper,
  SubTitleText,
} from "./Setup.styles";
import useGetPagePermission from "hooks/useGetPagePermission";
import { formatDate } from "features/helpers/utils";

const Setup = ({ entityConfig, aiSupport }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();

  const { toggleMagicWand } = useFetchAiSupportConfig();
  const selectedServiceModule = useSelector(selectServiceModule);
  const isAiConnected = _.get(
    selectedServiceModule,
    "aiSupport.connected",
    false
  );
  const isEnabledMagicWand = _.get(
    selectedServiceModule,
    "aiSupport.magicWand.connected",
    false
  );
  const isShowMagicWand = _.get(
    selectedServiceModule,
    "aiSupport.magicWand.enabled",
    false
  );

  const documentSyncStatus = _.get(
    aiSupport?.aiConfig,
    "documentSyncStatus",
    null
  );

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("aiSupport");

  const handleToggleSwitch = async () => {
    try {
      // setIsLoading(true);

      await toggleMagicWand({
        entityName,
        isEnableMagicWand: !isEnabledMagicWand,
      });

      dispatch(
        enqueueSnackbar({
          message: "Update success!",
          options: {
            variant: "info",
          },
        })
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      // setIsLoading(false);
    }
  };

  const renderFailMessage = () => {
    if (documentSyncStatus !== "failed") return;
    return (
      <Box className="errorCard">
        <Box className="startRow" sx={{ mb: "4px", fontWeight: 500 }}>
          <i className="meta-crm-icon-ic_info_fill font-size-16 mgr5" />
          Last Sync Failed：
          {formatDate(_.get(aiSupport?.aiConfig, "updatedAt"), { time: true })}
        </Box>
        Please confirm whether the input source is correct and click sync again.
        If the problem persists, contact technical support.
      </Box>
    );
  };

  const renderApplications = () => {
    const applications = [
      {
        icon: <IconIcMagicWandRoundFilled />,
        title: "Magic Wand",
        enable: isShowMagicWand,
        description: formatMessage({
          id: "aiManagement.magicWand.description",
        }),
        info: formatMessage({ id: "aiManagement.chatbot.tooltip" }),
      },
    ];

    return (
      <>
        {applications.map((app) => {
          if (!app.enable) return;
          return (
            <ApplicationItemWrapper>
              <Box>{app.icon}</Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 8,
                }}
              >
                <AppTitleText>{app.title}</AppTitleText>
                <AppDescriptionText>{app.description}</AppDescriptionText>
              </Box>
              <Box
                style={{
                  height: 40,
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 7,
                }}
              >
                {!isAiConnected && (
                  <IconTooltip
                    icon={
                      <i className="meta-crm-icon-ic_info_fill font-size-16" />
                    }
                    content={app.info}
                  />
                )}
                {canEdit ? (
                  <div
                    style={{
                      ...(!isAiConnected &&
                        documentSyncStatus !== "completed" && {
                          opacity: 0.3,
                          pointerEvents: "none",
                        }),
                    }}
                  >
                    <Switch
                      sx={{
                        display: "flex",
                        columnGap: 14,
                      }}
                      label={["Disabled", "Enabled"]}
                      labelPlacement="start"
                      checked={isEnabledMagicWand}
                      onChange={handleToggleSwitch}
                    />
                  </div>
                ) : (
                  <>{isEnabledMagicWand ? "Enabled" : "Disabled"}</>
                )}
              </Box>
            </ApplicationItemWrapper>
          );
        })}
      </>
    );
  };

  return (
    <>
      <StyledLayoutItem style={{ minHeight: 0, maxWidth: 791 }}>
        <StyledCustomizationContainer>
          <Loading open={isLoading} fullScreen={false} />
          <StyledGeneralSettingsContainer>
            <GitbookUrlSource aiConfig={_.get(aiSupport, "aiConfig")} />
            {renderFailMessage()}
          </StyledGeneralSettingsContainer>
        </StyledCustomizationContainer>
      </StyledLayoutItem>
      <SubTitleText>{"Application"}</SubTitleText>
      {renderApplications()}
    </>
  );
};

export default Setup;
