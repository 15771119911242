export const aiEnhancedUserHolderMap = {
  GameFi: [
    require("assets/img/holder_gamefi_immutable.webp"),
    require("assets/img/holder_gamefi_notcoin.webp"),
    require("assets/img/holder_gamefi_floki.webp"),
    require("assets/img/holder_gamefi_axie_infinity.webp"),
    require("assets/img/holder_gamefi_gala.webp"),
    require("assets/img/holder_gamefi_beam.webp"),
    require("assets/img/holder_gamefi_sandbox.webp"),
    require("assets/img/holder_gamefi_ronin.webp"),
    require("assets/img/holder_gamefi_apecoin.jpg"),
    require("assets/img/holder_gamefi_illuvium.webp"),
  ],
  DAOVoting: [
    require("assets/img/holder_dao_aave.webp"),
    require("assets/img/holder_dao_ens.webp"),
    require("assets/img/holder_dao_apecoin.webp"),
    require("assets/img/holder_dao_balancer.webp"),
    require("assets/img/holder_dao_lido-snapshot.webp"),
    require("assets/img/holder_dao_starknet.webp"),
    require("assets/img/holder_dao_safe.webp"),
    require("assets/img/holder_dao_maviavote.webp"),
    require("assets/img/holder_dao_shutterdao0x36.webp"),
    require("assets/img/holder_dao_mixin-autonomous-organization.webp"),
    require("assets/img/holder_dao_swarmfoundation.webp"),
    require("assets/img/holder_dao_stgdao.webp"),
    require("assets/img/holder_dao_arbitrumfoundation.webp"),
    require("assets/img/holder_dao_opcollective.webp"),
    require("assets/img/holder_dao_cakevote.webp"),
  ],
  AirdropHunter: [
    require("assets/img/holder_dao_arbitrumfoundation.webp"),
    require("assets/img/holder_airdrop_eigenLayer.jpg"),
    require("assets/img/holder_airdrop_layerzero.png"),
    require("assets/img/holder_airdrop_ethena.png"),
    require("assets/img/holder_airdrop_renzo.jpg"),
    require("assets/img/holder_airdrop_eigenLayer.jpg"),
    require("assets/img/holder_airdrop_friendTech.jpg"),
    require("assets/img/holder_airdrop_celestia.jpg"),
  ],
  premium: [
    require("assets/img/holder_dao_apecoin.webp"),
    require("assets/img/holder_dao_balancer.webp"),
    require("assets/img/holder_dao_lido-snapshot.webp"),
    require("assets/img/holder_dao_starknet.webp"),
    require("assets/img/holder_dao_safe.webp"),
    require("assets/img/holder_dao_maviavote.webp"),
    require("assets/img/holder_dao_shutterdao0x36.webp"),
    require("assets/img/holder_dao_mixin-autonomous-organization.webp"),
    require("assets/img/holder_dao_swarmfoundation.webp"),
    require("assets/img/holder_dao_stgdao.webp"),
    require("assets/img/holder_dao_arbitrumfoundation.webp"),
  ],
};
