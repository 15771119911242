import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import { StyledModalTableRow } from "features/metadesk/campaign/campaignList/component/TrackingModal.styles";
import moment from "moment";
import _ from "lodash";
import {
  formatDate,
  getShortAddress,
  getTransactionUrl,
} from "features/helpers/utils";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useTheme } from "@mui/styles";
import { useOpenState } from "contexts/stepContext/Open.context";
import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { Link } from "react-router-dom";
import useNavigateEntity from "hooks/useNavigateEntity";

const ReferrerCountModal = ({ open, onClose, data, setOpenAudience }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const ecosystemName = useSelector(selectEcosystemName);
  const { setOpen } = useOpenState();
  const { navigateEntity } = useNavigateEntity();
  const renderRow = (item, index) => {
    const { txs, timestamp, value, walletAddress } = item;
    return (
      <>
        <Box
          display={"flex"}
          sx={{
            fontSize: 12,
            borderBottom: `1px solid ${theme.customColors.grey[200]}`,
            paddingBottom: "8px",
          }}
        >
          <Box sx={{ width: "30%", color: theme.customColors.grey[600] }}>
            {getShortAddress(walletAddress)}
          </Box>
          <Box sx={{ width: "70%" }}>
            {_.map(txs, (tx, i) => (
              <Box
                display="flex"
                alignItems="center"
                sx={{ pr: "32px" }}
                mt={i > 0 ? "8px" : "0px"}
              >
                {/* <Box sx={{ width: "30%" }}></Box> */}
                {renderTransaction2(tx, ecosystemName, i + 1)}
              </Box>
            ))}
          </Box>
        </Box>
      </>
    );
  };

  const renderTransaction2 = (tx, ecosystemName, index = 0) => {
    if (!tx) return null;
    return (
      <>
        <Box sx={{ width: "40%" }} display="flex" alignItems="center">
          <Box color={theme.customColors.black}>
            {_.truncate(tx.objectiveName, { length: 15 })}
          </Box>
          {tx.deleted && (
            <Box color={theme.customColors.grey[600]} ml="3px">
              (Inactive)
            </Box>
          )}
        </Box>

        <Box sx={{ width: "50%", color: "#777" }}>
          {formatDate(tx.timestamp, { time: true })}
        </Box>
        <Box sx={{ width: "10%" }}>
          <a
            href={getTransactionUrl(ecosystemName, tx.chainId, tx.txHash)}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#7B61FF",
              textDecoration: "underline",
            }}
          >
            View
          </a>
        </Box>
      </>
    );
  };

  const renderTable = (rowData) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledModalTableRow>
          <div style={{ width: "30%", textAlign: "left", fontWeight: 500 }}>
            {formatMessage({
              id: "websiteTraffic.popup.columnName.address",
            })}
          </div>

          <div style={{ width: "25%", textAlign: "left", fontWeight: 500 }}>
            {formatMessage({
              id: "websiteTraffic.popup.columnName.objective",
            })}
          </div>
          <div style={{ width: "30%", textAlign: "left", fontWeight: 500 }}>
            {formatMessage({
              id: "websiteTraffic.popup.columnName.timeStamp",
            })}
          </div>
          <div style={{ width: "15%", textAlign: "right", fontWeight: 500 }}>
            {formatMessage({
              id: "websiteTraffic.popup.columnName.txnHashs",
            })}
          </div>
        </StyledModalTableRow>
        <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
          {rowData && rowData.length > 0 ? (
            _.map(rowData, (item, index) => renderRow(item, index))
          ) : (
            <Box backgroundColor="#FAFAFD" className="column" height="100%">
              <Box
                maxWidth={"412px"}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight="14.5px"
              >
                No objectives have been achieved yet. Consider{" "}
                <span
                  className="linkWithLine"
                  onClick={() => navigateEntity("/marketing/campaign/create")}
                >
                  starting a campaign
                </span>{" "}
                or{" "}
                <span
                  className="linkWithLine"
                  onClick={() =>
                    navigateEntity("/analytics/settings/website-traffic")
                  }
                >
                  reviewing your objectives
                </span>{" "}
                to retarget your audience for better results.
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderActionDropdown = (type) => {
    return (
      <Menu
        overflow="auto"
        position="initial"
        menuButton={
          <Button variant={VARIANT.OUTLINED} size={SIZE.MEDIUM}>
            Action{" "}
            <i className="meta-crm-icon-ic_arrowLineDown font-size-14 mgl3" />
          </Button>
        }
        itemList={[
          {
            children: (
              <Box id={`btn_export${type}WalletToCustomerList`}>
                Export to customer list
              </Box>
            ),
            func: (e) => setOpen({ upgrade: true }),
          },
          {
            children: (
              <Box id={`btn_csvDownload${type}Wallet`}>Download as CSV</Box>
            ),
            func: (e) => setOpen({ upgrade: true }),
          },
        ]}
        zIndex={500}
      />
    );
  };

  const renderAchievedContent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #e6e6e6",
          height: "369px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="betweenRow">
          <Box>
            {formatMessage({
              id: "websiteTraffic.popup.title.achievedWallet",
            })}
            <Box color={"#7B61FF"} fontWeight={700} mt="8px">
              {data?.achievedWallets?.length}
            </Box>
          </Box>
          <Box>
            {renderActionDropdown("Achieved")}
            <Button
              size={SIZE.MEDIUM}
              style={{ marginLeft: "4px" }}
              onClick={() => {
                onClose();
                setOpenAudience("achieved");
              }}
            >
              {formatMessage({
                id: `global.btn.createAudience`,
              })}
            </Button>
          </Box>
        </Box>
        <Box className="smhr"></Box>
        {renderTable(data?.achievedWallets)}
      </Box>
    );
  };

  const renderUnAchievedDialogContent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #e6e6e6",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          marginTop: "24px",
        }}
      >
        <Box className="betweenRow">
          <Box>
            {formatMessage({
              id: "websiteTraffic.popup.title.unAchievedWallet",
            })}
            <Box fontWeight={700} mt="8px">
              {data?.walletsConnected - (data?.achievedWallets?.length || 0)}
            </Box>
          </Box>
          <Box>
            {renderActionDropdown("Unachieved")}
            <Button
              size={SIZE.MEDIUM}
              style={{ marginLeft: "4px" }}
              onClick={() => {
                onClose();
                setOpenAudience("unachieved");
              }}
            >
              {formatMessage({
                id: `global.btn.createAudience`,
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      dialogRootStyle={{ maxWidth: "700px", width: "100%" }}
    >
      <Box
        padding={"24px 40px 48px"}
        flexDirection={"column"}
        sx={{ flex: 1, display: "flex" }}
      >
        <Box fontSize="18px" fontWeight={700} mb={"18px"} className="startRow">
          {formatMessage({
            id: "websiteTraffic.popup.objectiveAchieved.header",
          })}
        </Box>
        {renderAchievedContent()}
        {renderUnAchievedDialogContent()}
      </Box>
    </BasicDialog>
  );
};

export default ReferrerCountModal;
