import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  CURVE_FETCH_BEGIN,
  CURVE_FETCH_SUCCESS,
  CURVE_FETCH_FAILURE,
} from './constants';
import axios from 'axios';
import { apiUrl } from 'features/configure';

export function fetchCurveDetails(input) {
  return (dispatch, getState) => {
    dispatch({
      type: CURVE_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let pools = await axios.get(
          `${apiUrl}/api/user/pool?address=` +
          input.selectedUserAddress +
          '&entityName=' +
          input.entityName
        );
        dispatch({
          type: CURVE_FETCH_SUCCESS,
          data: pools.data,
        });
      } catch (err) {
        dispatch({
          type: CURVE_FETCH_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useFetchCurveDetails() {
  const dispatch = useDispatch();

  const { curveDetails, fetchCurveDetailsPending, fetchCurveDetailsDone } =
    useSelector(
      (state) => ({
        curveDetails: state.metadesk.curveDetails,
        fetchCurveDetailsPending: state.metadesk.fetchCurveDetailsPending,
        fetchCurveDetailsDone: state.metadesk.fetchCurveDetailsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchCurveDetails(data));
    },
    [dispatch]
  );

  return {
    curveDetails,
    fetchCurveDetails: boundAction,
    fetchCurveDetailsDone,
    fetchCurveDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CURVE_FETCH_BEGIN:
      return {
        ...state,
        fetchCurveDetailsPending: true,
      };

    case CURVE_FETCH_SUCCESS:
      return {
        ...state,
        curveDetails: action.data,
        fetchCurveDetailsDone: true,
        fetchCurveDetailsPending: false,
      };

    case CURVE_FETCH_FAILURE:
      return {
        ...state,
        fetchCurveDetailsPending: false,
      };

    default:
      return state;
  }
}
