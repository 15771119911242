import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import OnlyTabs from "@metacrm/metacrm-material-ui/dist/OnlyTabs";
import { Box } from "@mui/material";
import { findIndex } from "lodash";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";

function Header() {
  const { promotype, setParams, setIsLoading, isLoading, isAnalyticsPath } =
    useContext(PerformanceContext);
  const { formatMessage } = useIntl();
  const ecosystemName = useSelector(selectEcosystemName);

  const promotypeTabs = useMemo(() => {
    const contents = [
      {
        id: "widget",
        label: formatMessage({ id: "campaignPerformance.tab.widget.title" }),
      },
    ];
    if (ecosystemName !== "Solana") {
      contents.push({
        id: "flyer",
        label: formatMessage({ id: "campaignPerformance.tab.flyer.title" }),
      });
    }
    return contents;
  }, [ecosystemName]);

  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(findIndex(promotypeTabs, { id: promotype }) || 0);
  }, [promotype]);

  const handleChange = (event, newValue) => {
    setIsLoading({ ...isLoading, switchTab: true });
    setValue(newValue);
    setParams({
      promotype: promotypeTabs[newValue]?.id,
      channel: "all",
    });
    setIsLoading({ ...isLoading, switchTab: false });
  };

  return (
    <>
      <div
        className="betweenRow"
        style={{ alignItems: "flex-start", marginBottom: "28.5px" }}
      >
        <div className={"title"}>
          {/* {formatMessage({ id: "global.taskStatus.analysis" })} */}
          {isAnalyticsPath
            ? "Attribution Link Overview"
            : "Campaign Performance"}
        </div>
      </div>
      <div
        className="betweenRow"
        style={{ alignItems: "center", marginBottom: "8px" }}
      >
        {!isAnalyticsPath && (
          <OnlyTabs
            tabs={promotypeTabs}
            value={value}
            onChange={handleChange}
          />
        )}
        <Box sx={{ width: "4px" }} />
      </div>
    </>
  );
}

export default memo(Header);
