import React, { useState, useMemo, useEffect } from "react";
import { get, isEmpty } from "lodash-es";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useForm, Controller, useFormState } from "react-hook-form";
import { enqueueSnackbar } from "features/common/redux/actions";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

import FixedButton from "features/metadesk/settings/common/FixedButton";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import {
  StyledProfileContainer,
  StyledRowItem,
  StyledRowTitleContainer,
  StyledRowTitle,
  StyledRowInputContainer,
  StyledPersonalProfileContainer,
  StyledTitleDescription,
} from "./Profile.styles";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { useTheme } from "@mui/styles";
import { Loading } from "features/common";
import _ from "lodash";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import isEmail from "validator/lib/isEmail";
import useGetPagePermission from "hooks/useGetPagePermission";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

const personalDefaultFormValue = {
  name: "",
  emailAddress: "",
  telegram: "",
  address: "",
};

const Profile = () => {
  const dispatch = useDispatch();
  const formDataInit = {
    ...personalDefaultFormValue,
  };
  const [searchParams] = useSearchParams();
  const guide = searchParams.get("guide");
  const theme = useTheme();
  const [entityName, isCustomDomain] = useOutletContext();
  const [run, setRun] = useState(false);
  const [formData, setFormData] = useState(formDataInit);
  const [isLoading, setIsLoading] = useState(true);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const { getAuthenticatedInfo } = useConnectWallet();
  const { fetchEntityConfig } = useFetchEntityConfig();
  const { formatMessage } = useIntl();

  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  useEffect(() => {
    if (guide) {
      setRun(true);
    }
  }, [guide]);

  const getPersonalData = (data) => ({
    address: get(data, "personal.address"),
    emailAddress: get(data, "personal.email"),
    name: get(data, "personal.name"),
    telegram: get(data, "personal.telegram"),
  });

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/profile/${entityName}`
      );

      setFormData({ ...getPersonalData(data) });
      reset({ ...getPersonalData(data) });
    } catch (error) {
      // dispatch(
      //   enqueueSnackbar({
      //     message: get(error, "response.data.error", "error"),
      //     options: {
      //       variant: "error",
      //     },
      //   })
      // );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const pagePermission = useGetPagePermission();
  const userPermission = pagePermission("profileUser");
  const dispatchSnackbar = useDispatchSnackbar();

  const sendInfo = async (formData) => {
    setIsPostLoading(true);

    const personal = {
      name: formData.name,
      email: formData.emailAddress,
      telegram: formData.telegram,
    };
    try {
      let profileData;

      if (userPermission.canEdit) {
        const { data } = await axios.post(
          `${apiUrl}/api/manage/profile/${entityName}/personal`,
          {
            personal,
          }
        );
        profileData = data;
      }
      reset({
        ...getPersonalData(profileData),
      });
      getAuthenticatedInfo(entityName);
      fetchEntityConfig({ entityName });
      dispatchSnackbar({
        message: "Save successfully",
        variant: "success",
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsPostLoading(false);
    }
  };

  const handleSave = () => {
    handleSubmit(sendInfo)();
  };

  const handleFieldChange = (event) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <StyledProfileContainer>
      <Box className="title" mb="20px">
        {formatMessage({ id: "profileSetting.page.title" })}
      </Box>

      <StyledWhiteContainer>
        <Loading open={isLoading} fullScreen={false} />

        {userPermission.show && (
          <StyledPersonalProfileContainer>
            <Box
              fontSize={"16px"}
              fontWeight="700"
              color={(theme) => theme.customColors.grey[700]}
            >
              {formatMessage({ id: "profileSetting.personalProfile.title" })}
            </Box>
            <StyledTitleDescription>
              {formatMessage({
                id: "profileSetting.personalProfile.description",
              })}
            </StyledTitleDescription>
            <StyledRowItem
              component={Grid}
              container
              columnSpacing={3}
              alignItems="center"
              style={{ marginTop: userPermission.readonly ? "20px" : 0 }}
            >
              <StyledRowTitleContainer component={Grid} item xs={5}>
                <StyledRowTitle isRequired>
                  {formatMessage({ id: "global.commonWord.name" })}
                </StyledRowTitle>
              </StyledRowTitleContainer>
              <StyledRowInputContainer component={Grid} item xs={7}>
                <InputField
                  readonly={userPermission.readonly}
                  name="name"
                  value={formData.name}
                  control={control}
                  errors={get(errorsInfo, "name.message")}
                  maxLength={25}
                  rules={{
                    required: !userPermission.readonly
                      ? {
                          value: true,
                          message: formatMessage({
                            id: "global.error.websiteRequired",
                          }),
                        }
                      : undefined,
                  }}
                  onChange={(e) => handleFieldChange(e)}
                  errorTextAbsolute
                />
              </StyledRowInputContainer>
            </StyledRowItem>

            <StyledRowItem
              component={Grid}
              container
              columnSpacing={3}
              alignItems="center"
            >
              <StyledRowTitleContainer component={Grid} item xs={5}>
                <StyledRowTitle>
                  {formatMessage({ id: "global.commonWord.emailAddress" })}
                </StyledRowTitle>
              </StyledRowTitleContainer>
              <StyledRowInputContainer component={Grid} item xs={7}>
                <InputField
                  name="emailAddress"
                  value={formData.emailAddress}
                  readonly={userPermission.readonly}
                  control={control}
                  errors={get(errorsInfo, "emailAddress.message")}
                  rules={{
                    validate: (value) =>
                      isEmpty(value) ||
                      isEmail(value) ||
                      formatMessage({
                        id: "global.error.emailValid",
                      }),
                  }}
                  onChange={(e) => handleFieldChange(e)}
                  errorTextAbsolute
                />
              </StyledRowInputContainer>
            </StyledRowItem>

            <StyledRowItem
              component={Grid}
              container
              columnSpacing={3}
              alignItems="center"
            >
              <StyledRowTitleContainer component={Grid} item xs={5}>
                <StyledRowTitle>
                  {formatMessage({ id: "global.commonWord.telegram" })}
                </StyledRowTitle>
              </StyledRowTitleContainer>
              <StyledRowInputContainer component={Grid} item xs={7}>
                <InputField
                  name="telegram"
                  readonly={userPermission.readonly}
                  value={formData.telegram}
                  control={control}
                  onChange={(e) => handleFieldChange(e)}
                />
              </StyledRowInputContainer>
            </StyledRowItem>

            <StyledRowItem
              component={Grid}
              container
              columnSpacing={3}
              alignItems="center"
            >
              <StyledRowTitleContainer component={Grid} item xs={5}>
                <StyledRowTitle>
                  {formatMessage({ id: "global.commonWord.address" })}
                </StyledRowTitle>
              </StyledRowTitleContainer>
              <StyledRowInputContainer component={Grid} item xs={7}>
                <InputField
                  name="address"
                  readonly={userPermission.readonly}
                  value={formData.address}
                  control={control}
                  onChange={(e) => handleFieldChange(e)}
                  disabled
                />
              </StyledRowInputContainer>
            </StyledRowItem>
          </StyledPersonalProfileContainer>
        )}
      </StyledWhiteContainer>
      {userPermission.canEdit && (
        <FixedButton>
          <Button
            sx={{ width: "120px", marginLeft: "8px" }}
            onClick={handleSave}
            disabled={!isValid || !isDirty}
            isLoading={isLoading}
          >
            {formatMessage({ id: "global.btn.save" })}
          </Button>
        </FixedButton>
      )}
    </StyledProfileContainer>
  );
};

export default Profile;
