import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledProfileContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  // maxWidth: "1000px",
  paddingBottom: "60px",
  position: "relative",
}));

export const StyledCompanyProfileContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginBottom: "40px",
}));

export const StyledListContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginTop: "24px",
}));

export const StyledRowItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  marginBottom: "28px",
}));

export const StyledRowTitleContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  // width: "250px",
  // marginRight: "34px",
}));

export const StyledRowTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isRequired }) => ({
  fontSize: "16px",
  color: theme.customColors.grey[700],

  ...(isRequired && {
    "&:after": {
      content: '"*"',
      color: theme.customColors.pink[600],
      marginLeft: "5px",
    },
  }),
}));

export const StyledUploadHint = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  lineHeight: "20px",
}));

export const StyledUploadLimit = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  marginTop: "16px",
}));

export const StyledRowInputContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "490px",
}));

export const StyledLogoDemoContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "110px",
  width: "100%",
  border: `1px solid ${theme.customColors.grey[400]}`,
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
}));

export const StyledLogoDemoHeader = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "30px",
  borderBottom: `1px solid ${theme.customColors.grey[400]}`,
  display: "flex",
  alignItems: "center",
}));

export const StyledLogoDemoLogoContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "18px",
  marginLeft: "20px",
  "& img": {
    height: "100%",
  },
}));

export const StyleLogoDemoBody = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: theme.customColors.grey[200],
  flex: 1,
  borderRadius: "5px",
  display: "flex",
}));

export const StyledLogoDemoNav = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  borderRight: `1px solid ${theme.customColors.grey[400]}`,
  width: "100px",
}));

export const StyledLogoDemoNavItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "20px",
  borderBottom: `1px solid ${theme.customColors.grey[400]}`,
  background: theme.customColors.white,
}));

export const StyledUploadImageAreaContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, img }) => ({
  border: `1px dashed ${theme.customColors.grey[300]}`,
  ...(img && { border: `1px solid ${theme.customColors.grey[300]}` }),
  position: "relative",
  borderRadius: "10px",
  height: "190px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  color: theme.customColors.grey[300],
  "& .big-upload": {
    fontSize: "80px",
  },
}));

export const StyledImgErrorText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, img }) => ({
  "& i": {
    marginRight: "4px",
  },
  marginTop: "16px",
  display: "flex",
  color: theme.customColors.pink[600],
}));

export const StyledUploadInput = styled("input", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, disabled }) => ({
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  opacity: "0",
  cursor: !disabled ? "pointer" : "default",
  width: "100%",
  zIndex: 1,
}));

export const StyledImgContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "60px",
  "& img": {
    height: "100%",
  },
}));

export const StyledUploadIconSmall = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  bottom: "8px",
  right: "8px",
  display: "flex",
  color: theme.customColors.grey[500],
}));

export const StyledPersonalProfileContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledTitleDescription = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  marginTop: "8px",
}));

export const StyledLine = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: theme.customColors.grey[300],
  height: "1px",
  width: "100%",
  margin: "24px 0",
}));
