import React, { useState, useMemo, useEffect } from "react";
import { get, isEmpty } from "lodash-es";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useForm, Controller, useFormState } from "react-hook-form";
import { enqueueSnackbar } from "features/common/redux/actions";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";

import {
  InputField,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/InputField";

import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { validateURL } from "features/helpers/utils";
import { useIntl } from "react-intl";
import {
  StyledProfileContainer,
  StyledCompanyProfileContainer,
  StyledListContainer,
  StyledRowItem,
  StyledRowTitleContainer,
  StyledRowTitle,
  StyledUploadHint,
  StyledUploadLimit,
  StyledRowInputContainer,
  StyledLogoDemoContainer,
  StyledLogoDemoHeader,
  StyledLogoDemoLogoContainer,
  StyleLogoDemoBody,
  StyledLogoDemoNav,
  StyledLogoDemoNavItem,
  StyledUploadImageAreaContainer,
  StyledImgErrorText,
  StyledUploadInput,
  StyledImgContainer,
  StyledUploadIconSmall,
  StyledLine,
} from "./Profile.styles";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { useTheme } from "@mui/styles";
import { Loading } from "features/common";
import _ from "lodash";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import useGetPagePermission from "hooks/useGetPagePermission";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

const supportExtension = [".jpg", ".jpeg", ".png", ".svg"];
const fileSizeLimit = 2 * 1024 * 1024;

const companyDefaultFormValue = {
  companyLogo: "",
  companyName: "",
  companyWebsite: "",
};

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const formDataInit = {
    ...companyDefaultFormValue,
  };
  const [searchParams] = useSearchParams();
  const guide = searchParams.get("guide");
  const theme = useTheme();
  const [entityName, isCustomDomain] = useOutletContext();
  const [run, setRun] = useState(false);
  const [uploadImgLoading, setIsUploadImgLoading] = useState(false);
  const [img, setImg] = useState("");
  const [imgErrorText, setImgErrorText] = useState("");
  const [formData, setFormData] = useState(formDataInit);
  const [isLoading, setIsLoading] = useState(true);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const { getAuthenticatedInfo } = useConnectWallet();
  const { fetchEntityConfig } = useFetchEntityConfig();
  const { formatMessage } = useIntl();

  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  useEffect(() => {
    if (guide) {
      setRun(true);
    }
  }, [guide]);

  const getCompanyData = (data) =>
    get(data, "company")
      ? {
          companyLogo: get(data, "company.logo"),
          companyName: get(data, "company.name"),
          companyWebsite: get(data, "company.website"),
        }
      : {};

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/profile/${entityName}`
      );

      setFormData({ ...getCompanyData(data) });
      reset({ ...getCompanyData(data) });
      if (!isEmpty(getCompanyData(data))) {
        setImg(get(data, "company.logo"));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const validateFile = (file) => {
    if (!supportExtension.some((extension) => file.name.endsWith(extension))) {
      setImgErrorText("File format does not match");
      return false;
    }
    if (file.size > fileSizeLimit) {
      setImgErrorText("File is oversize");
      return false;
    }
    setImgErrorText("");
    return true;
  };

  const uploadFile = async (file, onFormChange) => {
    if (validateFile(file)) {
      let postFormData = new FormData();

      postFormData.append("logo", file);
      postFormData.append("entityName", entityName);

      try {
        setIsUploadImgLoading(true);

        const {
          data: { logo },
        } = await axios.post(
          `${apiUrl}/api/gcp/uploadCompanyLogo`,
          postFormData
        );
        setImg(logo[0]);
        onFormChange(logo[0]);
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
        setIsUploadImgLoading(false);
      }
    }
  };

  const handleChooseFile = (event, onFormChange) => {
    const [file] = event.target.files;
    event.target.value = null;
    uploadFile(file, onFormChange);
  };

  const pagePermission = useGetPagePermission();
  const companyPermission = pagePermission("profileCompany");
  const dispatchSnackbar = useDispatchSnackbar();

  const sendInfo = async (formData) => {
    setIsPostLoading(true);
    const company = {
      name: formData.companyName,
      logo: formData.companyLogo,
      website: formData.companyWebsite,
    };

    try {
      let companyData;
      if (companyPermission.canEdit) {
        const { data } = await axios.post(
          `${apiUrl}/api/manage/profile/${entityName}/company`,
          {
            company,
          }
        );
        companyData = data;
      }

      reset({
        ...getCompanyData(companyData),
      });
      getAuthenticatedInfo(entityName);
      fetchEntityConfig({ entityName });
      dispatchSnackbar({
        message: "Save successfully",
        variant: "success",
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsPostLoading(false);
    }
  };

  const handleSave = () => {
    handleSubmit(sendInfo)();
  };

  const renderUploadImgContent = (readonly) => {
    if (uploadImgLoading) {
      return (
        <Box color={(theme) => theme.customColors.grey[700]}>
          <LoadingComponent />
        </Box>
      );
    } else {
      return img ? (
        <>
          <StyledImgContainer>
            <img src={img} alt="upload-logo" />
          </StyledImgContainer>
          {!readonly && (
            <StyledUploadIconSmall>
              <i className="meta-crm-icon-ic_uploadImage font-size-24" />
            </StyledUploadIconSmall>
          )}
        </>
      ) : (
        <>
          <Box>
            <i className="meta-crm-icon-ic_uploadImage big-upload" />
          </Box>
          <Box>
            {formatMessage({ id: "ticketDrawer.githubIssue.clickToUpload" })}
          </Box>
        </>
      );
    }
  };

  const handleFieldChange = (event) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <StyledProfileContainer>
      <Box className="title" mb="20px">
        Company {formatMessage({ id: "profileSetting.page.title" })}
      </Box>

      <StyledWhiteContainer>
        <Loading open={isLoading} fullScreen={false} />

        {companyPermission.show && (
          <StyledCompanyProfileContainer>
            <Box
              fontSize={"16px"}
              fontWeight="700"
              color={(theme) => theme.customColors.grey[700]}
            >
              {formatMessage({
                id: "profileSetting.companyProfile.title",
              })}
            </Box>

            <StyledListContainer>
              <div id="guideField">
                <StyledRowItem component={Grid} container columnSpacing={3}>
                  <StyledRowTitleContainer component={Grid} item xs={5}>
                    <StyledRowTitle>
                      {formatMessage({
                        id: "profileSetting.companyProfile.logo",
                      })}
                    </StyledRowTitle>
                    <StyledUploadHint mt={1}>
                      {formatMessage({
                        id: "profileSetting.companyProfile.logoHint",
                      })}
                    </StyledUploadHint>
                    <StyledUploadLimit>
                      <Box>
                        {formatMessage({
                          id: "profileSetting.companyProfile.logoLimit",
                        })}
                      </Box>
                      <Box>
                        {formatMessage({
                          id: "profileSetting.companyProfile.logoRecommend",
                        })}
                      </Box>
                    </StyledUploadLimit>

                    {imgErrorText && (
                      <StyledImgErrorText>
                        <i className="meta-crm-icon-ic_alert font-size-16" />
                        {imgErrorText}
                      </StyledImgErrorText>
                    )}
                  </StyledRowTitleContainer>
                  <StyledRowInputContainer component={Grid} item xs={7}>
                    <StyledUploadImageAreaContainer img={img}>
                      <Controller
                        name="companyLogo"
                        control={control}
                        render={({
                          field: { onChange: onFormChange, value, ...field },
                        }) => (
                          <StyledUploadInput
                            {...field}
                            type="file"
                            disabled={companyPermission.readonly}
                            accept=".jpg,.jpeg,.png,.svg"
                            onChange={(e) => {
                              handleChooseFile(e, onFormChange);
                            }}
                          />
                        )}
                      />

                      {renderUploadImgContent(companyPermission.readonly)}
                    </StyledUploadImageAreaContainer>
                  </StyledRowInputContainer>
                </StyledRowItem>
                <StyledRowItem component={Grid} container columnSpacing={3}>
                  <StyledRowTitleContainer component={Grid} item xs={5}>
                    <StyledUploadHint>
                      {formatMessage({
                        id: "profileSetting.companyProfile.preview",
                      })}
                    </StyledUploadHint>
                  </StyledRowTitleContainer>
                  <StyledRowInputContainer component={Grid} item xs={7}>
                    <StyledLogoDemoContainer>
                      <StyledLogoDemoHeader>
                        <StyledLogoDemoLogoContainer>
                          {img && <img src={img} alt="upload-logo" />}
                        </StyledLogoDemoLogoContainer>
                      </StyledLogoDemoHeader>
                      <StyleLogoDemoBody>
                        <StyledLogoDemoNav>
                          {[...Array(2)].map((_, index) => (
                            <StyledLogoDemoNavItem key={index} />
                          ))}
                        </StyledLogoDemoNav>
                      </StyleLogoDemoBody>
                    </StyledLogoDemoContainer>
                  </StyledRowInputContainer>
                </StyledRowItem>
              </div>

              <StyledRowItem
                component={Grid}
                container
                columnSpacing={3}
                alignItems="center"
              >
                <StyledRowTitleContainer component={Grid} item xs={5}>
                  <StyledRowTitle>
                    {formatMessage({
                      id: "profileSetting.companyProfile.name",
                    })}
                  </StyledRowTitle>
                </StyledRowTitleContainer>
                <StyledRowInputContainer component={Grid} item xs={7}>
                  <InputField
                    readonly={companyPermission.readonly}
                    name="companyName"
                    value={formData.companyName}
                    control={control}
                    errors={get(errorsInfo, "companyName.message")}
                    maxLength={32}
                    rules={{
                      minLength: {
                        value: 2,
                        message: formatMessage({
                          id: "profileSetting.companyProfile.minError",
                        }),
                      },
                    }}
                    onChange={(e) => handleFieldChange(e)}
                    errorTextAbsolute
                  />
                </StyledRowInputContainer>
              </StyledRowItem>

              <StyledRowItem
                component={Grid}
                container
                columnSpacing={3}
                alignItems="center"
              >
                <StyledRowTitleContainer component={Grid} item xs={5}>
                  <StyledRowTitle>
                    {formatMessage({
                      id: "profileSetting.companyProfile.website",
                    })}
                  </StyledRowTitle>
                </StyledRowTitleContainer>
                <StyledRowInputContainer component={Grid} item xs={7}>
                  <InputField
                    readonly={companyPermission.readonly}
                    name="companyWebsite"
                    value={formData.companyWebsite}
                    control={control}
                    errors={get(errorsInfo, "companyWebsite.message")}
                    rules={{
                      validate: (value) =>
                        validateURL(value) ||
                        formatMessage({ id: "global.inputCheck.invalidUrl" }),
                    }}
                    onChange={(e) => handleFieldChange(e)}
                    errorTextAbsolute
                  />
                </StyledRowInputContainer>
              </StyledRowItem>
            </StyledListContainer>
          </StyledCompanyProfileContainer>
        )}
      </StyledWhiteContainer>
      {companyPermission.canEdit && (
        <FixedButton>
          <Button
            sx={{ width: "120px", marginLeft: "8px" }}
            onClick={handleSave}
            disabled={!isValid || !isDirty}
            isLoading={isLoading}
          >
            {formatMessage({ id: "global.btn.save" })}
          </Button>
        </FixedButton>
      )}
    </StyledProfileContainer>
  );
};

export default CompanyProfile;
