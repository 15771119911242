import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import React from "react";
import StepContent from "../StepContent";
import { useIntl } from "react-intl";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import {
  StyledApiKeyCreatedTime,
  StyledFormTitle,
} from "../../IntegrationWidgetSetting.styles";
import Button, {
  IconButton,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import {
  copyToClipboard,
  formatCalendarDateAndTime,
} from "features/helpers/utils";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useDispatch } from "react-redux";

export default function ApiKey({ apiKey, apiKeyCreated, handleRegenerate }) {
  const { formatMessage } = useIntl();

  const confirm = useConfirm();

  async function onRegenerate() {
    await handleRegenerate();
    confirm({
      title: "API Key Regenerated",
      content:
        "Your API key has been regenerated. Update your website to ensure continued integration.",
      confirmText: "Got it",
      color: "secondary",
    })
      .then(() => {})
      .catch(() => {});
  }
  const dispatch = useDispatch();

  const apiSetting = {
    title: formatMessage({
      id: "widgetSettings.apiKey.title",
    }),
    describe: formatMessage({
      id: "widgetSettings.apiKey.description",
    }),
    content: (
      <>
        <StyledFormTitle center item xs={12} md={4}>
          {formatMessage({
            id: "widgetSettings.apiKey.input",
          })}
        </StyledFormTitle>
        <Grid position={"relative"} item xs={12} md={6}>
          <InputField
            disabled
            value={apiKey}
            endAdornment={
              <IconButton onClick={() => copyToClipboard(apiKey, dispatch)}>
                <i className="meta-crm-icon-ic_copy font-size-24" />
              </IconButton>
            }
          ></InputField>
          <StyledApiKeyCreatedTime>
            <Box
              color={(theme) => theme.customColors.grey[600]}
              size="12px"
              mr="2.5px"
            >
              {formatMessage({
                id: "plans&billing.usage.recountTime",
              })}{" "}
              :
            </Box>
            <Box color={(theme) => theme.customColors.purple[500]} size="12px">
              {formatCalendarDateAndTime(apiKeyCreated)}
            </Box>
          </StyledApiKeyCreatedTime>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button size={SIZE.XL} sx={{ width: "100%" }} onClick={onRegenerate}>
            {formatMessage({
              id: "global.btn.regenerate",
            })}
          </Button>
        </Grid>

        <Grid item xs={12} mb={"16px"}></Grid>
      </>
    ),
  };

  return <StepContent settingStep={apiSetting} />;
}
