import { Box } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import {
  StyledFormTitle,
  StyledGreyBlock,
} from "../../IntegrationWidgetSetting.styles";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import StepContent from "../StepContent";
import CodePannel from "components/CodePannel/CodePannel";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { trackingAddressCode } from "features/configure/appCode";

export default function WalletDetect() {
  const ecosystemName = useSelector(selectEcosystemName);
  const { formatMessage } = useIntl();

  const WalletDetectBlock = {
    title: formatMessage({
      id: "widgetSettings.addressIdentification.title",
    }),
    titleHint: `(${formatMessage({
      id: "global.annotation.recommended",
    })})`,
    describe: (
      <>
        <Box>
          {formatMessage(
            {
              id: "widgetSettings.addressIdentification.description",
            },
            {
              br: <br />,
            }
          )}
        </Box>
        <Box
          color={(theme) => theme.customColors.purple[500]}
          sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
        >
          <Box mr="4px" className="meta-crm-icon-ic_info font-size-16" />
          {formatMessage({
            id: "widgetSettings.addressIdentification.explanation",
          })}
        </Box>
      </>
    ),

    content: (
      <>
        <StyledFormTitle item xs={12} md={4}>
          <Box display={"flex"} alignItems={"center"} height={"24px"}>
            {formatMessage({
              id: "snippet.widget.addressIdentification",
            })}
          </Box>
          <Box className="description">
            {ecosystemName === "Solana" && (
              <Box>
                To ensure that you can track{" "}
                <Box
                  sx={{ display: "inline" }}
                  color={(theme) => theme.customColors.purple[500]}
                >
                  Solflare wallet,
                </Box>{" "}
                you must install this method.
              </Box>
            )}
          </Box>
        </StyledFormTitle>
        <Grid position={"relative"} item xs={12} md={8}>
          <CodePannel code={trackingAddressCode} />

          <StyledGreyBlock mt={"12px"}>
            <Box marginRight="5px">
              <i className="meta-crm-icon-ic_info font-size-16" />
            </Box>

            <Box component="span">
              {formatMessage({
                id: "metaCRMSDK.walletConnects.addressIdentification.instruction",
              })}
            </Box>
          </StyledGreyBlock>
        </Grid>
      </>
    ),
  };

  return <StepContent settingStep={WalletDetectBlock} />;
}
