import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  GITHUB_ISSUES_FETCH_BEGIN,
  GITHUB_ISSUES_FETCH_SUCCESS,
  GITHUB_ISSUES_FETCH_FAILURE,
  GITHUB_ISSUES_CREATE_BEGIN,
  GITHUB_ISSUES_CREATE_SUCCESS,
  GITHUB_ISSUES_CREATE_FAILURE,
  GITHUB_REPOS_FETCH_SUCCESS,
} from "./constants";

import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import _ from "lodash";
import { uploadFiles } from "features/helpers/utils";

export function fetchGithubRepos({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${apiUrl}/api/github/repos/${entityName}`
        );
        dispatch({
          type: GITHUB_REPOS_FETCH_SUCCESS,
          data: result.data,
        });
        resolve(result.data);
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "Error"),
            options: {
              variant: "error",
            },
          })
        );

        console.log(err);
      }
    });

    return promise;
  };
}

export function fetchGithubIssues({ entityName, ticketId }) {
  return (dispatch, getState) => {
    dispatch({
      type: GITHUB_ISSUES_FETCH_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${apiUrl}/api/github/issues/${entityName}?ticketId=${ticketId}`
        );
        dispatch({
          type: GITHUB_ISSUES_FETCH_SUCCESS,
          data: result.data,
        });
        resolve(result.data);
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "Error"),
            options: {
              variant: "error",
            },
          })
        );
        dispatch({
          type: GITHUB_ISSUES_FETCH_FAILURE,
        });
        console.log(err);
      }
    });

    return promise;
  };
}
export function createGithubIssue({
  entityName,
  ticketId,
  title,
  description,
  assignee,
  repository,
  imgs,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: GITHUB_ISSUES_CREATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const images = await uploadFiles(imgs, entityName);
        let desc = description;
        if (images.length > 0) {
          for (let image of images) {
            desc += `<img src="${image}" width="200px"/>`;
          }
        }
        let result = await axios.post(
          `${apiUrl}/api/github/issues/${entityName}`,
          {
            entityName,
            ticketId,
            title,
            desc,
            assignees: assignee,
            repo: repository,
          }
        );
        dispatch({
          type: GITHUB_ISSUES_CREATE_SUCCESS,
          data: result.data,
        });
        resolve(result.data);
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "Error"),
            options: {
              variant: "error",
            },
          })
        );
        dispatch({
          type: GITHUB_ISSUES_CREATE_FAILURE,
        });
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}

export function useFetchGithub() {
  const dispatch = useDispatch();

  const { githubIssues, fetchGithubPending, createGithubPending, githubRepos } =
    useSelector(
      (state) => ({
        githubIssues: state.metadesk.githubIssues,
        fetchGithubPending: state.metadesk.fetchGithubPending,
        createGithubPending: state.metadesk.createGithubPending,
        githubRepos: state.metadesk.githubRepos,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchGithubIssues(data));
    },
    [dispatch]
  );
  const repoAction = useCallback(
    (data) => {
      return dispatch(fetchGithubRepos(data));
    },
    [dispatch]
  );
  const updateAction = useCallback(
    (data) => {
      return dispatch(createGithubIssue(data));
    },
    [dispatch]
  );
  return {
    githubIssues,
    githubRepos,
    fetchGithubPending,
    createGithubPending,
    fetchGithubRepos: repoAction,
    fetchGithubIssues: boundAction,
    createGithubIssue: updateAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GITHUB_ISSUES_FETCH_BEGIN:
      return {
        ...state,
        fetchGithubPending: true,
      };
    case GITHUB_ISSUES_FETCH_SUCCESS:
      return {
        ...state,
        githubIssues: action.data,
        fetchGithubPending: false,
      };
    case GITHUB_ISSUES_FETCH_FAILURE:
      return {
        ...state,
        fetchGithubPending: false,
      };
    case GITHUB_ISSUES_CREATE_BEGIN:
      return {
        ...state,
        createGithubPending: true,
      };
    case GITHUB_ISSUES_CREATE_SUCCESS:
      return {
        ...state,
        githubIssues: [action.data, ...state.githubIssues],
        createGithubPending: false,
      };
    case GITHUB_ISSUES_CREATE_FAILURE:
      return {
        ...state,
        createGithubPending: false,
      };
    case GITHUB_REPOS_FETCH_SUCCESS:
      return {
        ...state,
        githubRepos: action.data,
      };
    default:
      return state;
  }
}
