import React, { useState, useEffect, useContext } from "react";
import { Grid, Slide, Box } from "@mui/material";
import _ from "lodash";
import { Button, IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  StyledCustomizationContainer,
  StyledImportPopoverTitle,
  StyledImportPopoverDescription,
} from "./CreateAudiencePopover.styles";

import { Loading } from "features/common";

import { useIntl } from "react-intl";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import { useDispatch } from "react-redux";
import { alertServerError } from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router";
import { PerformanceContext } from "../context/PerformanceProvider";
import useNavigateEntity from "hooks/useNavigateEntity";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";

const CreateAudiencePopover = ({ onClose, open, objectiveData }) => {
  const { formatMessage } = useIntl();

  const { startDate, endDate } = useContext(PerformanceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [entityName] = useOutletContext();
  const [name, setName] = useState("");
  const [source, setSource] = useState("all");
  const [count, setCount] = useState("");
  const [objectiveStatus, setObjectiveStatus] = useState("all");
  const dispatch = useDispatch();
  const { navigateEntity } = useNavigateEntity();
  const convertISOString = (date) => {
    return date == "all" ? "all" : date.toISOString();
  };

  const fetchPreviewData = async (defaultData = {}) => {
    try {
      setIsLoading(true);
      const params = {
        trafficSource: _.get(open, "trafficSource", "all"),
        objectiveStatus,
        startDate: convertISOString(startDate),
        endDate: convertISOString(endDate),
        ...defaultData,
      };

      const result = await axios.get(
        apiUrl + `/api/campaignAudience/${entityName}/preview`,
        {
          params,
        }
      );
      setCount(result?.data?.count);
    } catch (e) {
      console.log("e: ", e);
      dispatch(alertServerError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const saveAudiences = async () => {
    try {
      setIsLoading(true);
      const params = {
        name,
        trafficSource: open.trafficSource,
        objectiveStatus,
        startDate,
      };

      const result = await axios.post(
        apiUrl + `/api/campaignAudience/${entityName}`,
        params
      );
      navigateEntity(
        "/marketing/campaign/create?promotionType=widget&tailoredAudience=" +
          result.data._id
      );
    } catch (e) {
      dispatch(alertServerError(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      const trafficSource = _.get(open, "trafficSource", "all");
      const objectiveStatus = _.get(open, "objectiveStatus", "all");
      setSource(trafficSource);
      setObjectiveStatus(objectiveStatus);
      fetchPreviewData({ trafficSource, objectiveStatus });
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      fetchPreviewData();
    }
  }, [objectiveStatus]);

  const renderContent = () => {
    return (
      <div>
        <StyledImportPopoverTitle>
          {formatMessage({ id: "global.btn.createAudience" })}
        </StyledImportPopoverTitle>
        <StyledImportPopoverDescription>
          {formatMessage({ id: "createAudience.popover.description" })}
        </StyledImportPopoverDescription>
        <Box>
          <InputField
            placeholder="Naming your audience"
            title={
              <Box>
                Audience Name <span className="required"> *</span>
              </Box>
            }
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Box>
        <Box mt="20px">
          <InputField title={"Traffic Source"} disabled={true} value={source} />
        </Box>
        <Box mt="20px">
          <Box fontWeight={500} mb="8px">
            Objective Status
          </Box>
          <Box>
            <CustomRadioGroup
              row
              data={[
                { label: "All", value: "all", style: { width: 140 } },
                {
                  label: "Achieved",
                  value: "achieved",
                  disabled: _.isEmpty(objectiveData),
                  style: { width: 140 },
                },
                {
                  label: "Unachieved",
                  value: "unachieved",
                  disabled: _.isEmpty(objectiveData),
                  style: { width: 140 },
                },
              ]}
              value={objectiveStatus}
              onChange={(e) => setObjectiveStatus(e.target.value)}
            />

            {_.isEmpty(objectiveData) && (
              <Box className="startRow" mt="8px" color="#7B61FF">
                <i className="meta-crm-icon-ic_info font-size-16 mgr4" />
                Please go to create your Objective first.
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  };
  return (
    <>
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1000,
          }}
          onClick={onClose}
        />
      )}
      <Slide direction="left" in={open}>
        <StyledCustomizationContainer>
          <Box
            style={{
              position: "relative",
              flexGrow: 1,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <IconButton
              style={{ position: "absolute", top: 18, right: 18 }}
              onClick={() => {
                onClose();
              }}
            >
              <i className="meta-crm-icon-ic_cancel font-size-18" />
            </IconButton>
            <Box style={{ padding: "48px 40px" }}>{renderContent()}</Box>
          </Box>
          <Box bgcolor={"white"} p="40px" width="100%">
            <Box
              p="19px 16px"
              sx={{ borderLeft: "1px solid #7B61FF" }}
              className="startRow"
              color="#7B61FF"
              bgcolor="#FAFAFD"
              mb="24px"
            >
              Total Wallets :{" "}
              <span
                style={{ fontSize: "24px", fontWeight: 700, margin: "0 8px" }}
              >
                {count || "-"}
              </span>{" "}
              <IconTooltip
                content={`The total wallet count represents unique wallet addresses across all traffic sources. If the same wallet connects through multiple traffic sources, it is counted only once here.`}
              ></IconTooltip>
            </Box>
            <Box textAlign={"center"}>
              <Button
                disabled={!name || !count || isLoading}
                onClick={() => {
                  saveAudiences();
                }}
              >
                Save and Apply to Campaign
              </Button>
            </Box>
          </Box>
          <Loading open={isLoading} fullScreen={false} />
        </StyledCustomizationContainer>
      </Slide>
    </>
  );
};

export default CreateAudiencePopover;
