import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { get, isEmpty, isEqual } from "lodash-es";
import { useOutletContext } from "react-router-dom";
import AssignTicketType from "../../../Customization/AssignTicketType/AssignTicketType";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import axios from "axios";
import { apiUrl } from "features/configure";
import useGetPagePermission from "hooks/useGetPagePermission";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { Loading } from "features/common";
import { useIntl } from "react-intl";
const mergeTicketTypesArray = (arr1, arr2) => {
  const map = new Map(arr2.map((item) => [item._id, item]));

  return arr1.map((item) => {
    return map.has(item._id) ? { ...item, assignee: map.get(item._id) } : item;
  });
};

const WidgetTicketType = ({
  widgetItemInfo,
  chooseTicketType,
  setChooseTicketType,
}) => {
  const [entityName] = useOutletContext();
  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");
  const { ticketTypes = [], _id } = widgetItemInfo;
  const { ticketTypeList } = useFetchEntityConfig();
  const [ticketTypesArray, setTicketTypesArray] = useState([]);
  const dispatchSnackbar = useDispatchSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const handleGetTicketTypes = async (data) => {
    try {
      setIsLoading(true);
      const { data: ticketTypesData } = await axios.get(
        `${apiUrl}/api/manage/ticketTypes/${entityName}/widget/${_id}`
      );
      const cleanerTicketTypes = (ticketTypesData.ticketTypes || []).map(
        (tt) => ({
          _id: tt.ticketType,
          assignee: tt.assignee || null,
        })
      );

      const newTicketTypes = mergeTicketTypesArray(data, cleanerTicketTypes);

      setTicketTypesArray(newTicketTypes);
    } catch (err) {
      console.log(err);
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(ticketTypes)) {
      handleGetTicketTypes(ticketTypeList);
    } else {
      setTicketTypesArray(ticketTypeList);
    }
  }, [ticketTypes]);

  return (
    <div style={{ background: "white" }}>
      <Loading open={isLoading} fullScreen={false} />
      <AssignTicketType
        serviceName={"widget"}
        canEdit={canEdit}
        ticketList={ticketTypesArray}
        chooseTicketType={chooseTicketType}
        setChooseTicketType={setChooseTicketType}
      />
    </div>
  );
};

export default WidgetTicketType;
