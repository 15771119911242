import React, { useState, createContext, useContext } from "react";

export const StepContext = createContext();

export default function StepProvider({ children }) {
  const [stepData, setStepData] = useState({});

  const setStepValues = (values) => {
    setStepData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  return (
    <StepContext.Provider value={{ stepData, setStepValues }}>
      {children}
    </StepContext.Provider>
  );
}

export const useStepData = () => useContext(StepContext);
