import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledModalTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "16px",
}));

export const StyledModalTableRow = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom: "16px",
}));

export const StyledModalTableItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  borderRight: "1px solid #E6E6E6",
  marginRight: "12px",
  paddingRight: "12px",
}));

export const StyledModalTableItemWithLeftBorder = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom: "16px",
}));
