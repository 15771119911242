import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import { Modal, IconButton } from "@mui/material";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import CloseIcon from "@mui/icons-material/Close";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 700,
  },
  subTitle: {
    color: "#5C5C5C",
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 1,
    marginTop: 11,
    marginBottom: 4,
  },
}));
const COLORS = [
  "#FAA7E0",
  "#FDA19B",
  "#FEC84B",
  "#C2F0B6",
  "#7CD4FD",
  "#A4BCFD",
  "#BDB4FE",
  "#EBD9B7",
  "#D0D5DD",
  "#EABBFB",
  "#FDBE9B",
  "#FEAC4B",
  "#6CE9E2",
  "#7CB0FD",
  "#BBFDA4",
  "#FCCEEE",
];
const CreateGroupModal = ({ open, onClose, selectedGroup, onSave }) => {
  const classes = useStyles();
  const [groupName, setGroupName] = useState(_.get(selectedGroup, "name", ""));
  const [groupColor, setGroupColor] = useState(
    _.get(selectedGroup, "color", "#FAA7E0")
  );
  const theme = useTheme();

  useEffect(() => {
    setGroupName(_.get(selectedGroup, "name", ""));
    setGroupColor(_.get(selectedGroup, "color", "#FAA7E0"));
  }, [selectedGroup]);

  return (
    <Modal open={open} onClose={onClose} className={"modal"}>
      <div
        style={{
          background: "#ffffff",
          maxWidth: 376,
          padding: 48,
          color: "#383538",
          borderRadius: 10,
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: 3, top: 3, color: "#383538" }}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.title}>
          {selectedGroup ? "Edit your segment name" : "Create a new segment"}
        </div>

        <div className={classes.subTitle}>name</div>
        <CustomOutlinedInput
          bold
          noLine
          outline
          placeholder="Type Segment Name..."
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />

        <div style={{ marginTop: 24 }}>
          <Button
            onClick={() => onSave(groupName, groupColor)}
            disabled={!groupName}
          >
            {selectedGroup ? (
              "Save"
            ) : (
              <div className="row">
                <img
                  src={require("assets/img/createGroup.svg").default}
                  className="smIcon"
                />
                Create
              </div>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateGroupModal;
