import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { AgentAnalytics } from "./AgentAnalytics";
import { TicketAnalytics } from "./TicketAnalytics";

import MetaCRMDateRange from "components/DateRange/MetaCRMDateRange";

import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { StyledCard } from "./ServiceReport.styles";
const defaultRange = "all";
const ServiceReportPage = () => {
  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [platform, setPlatform] = useState("all");
  const [currentRange, setCurrentRange] = useState(defaultRange);
  const handleRangeChange = (newRange) => {
    setCurrentRange(newRange);
  };

  const ticketSourceList = [
    { label: "All", value: "all" },
    { label: "Discord", value: "discord" },
    { label: "Support Center", value: "supportCenter" },
    { label: "Widget", value: "widget" },
    { label: "Telegram", value: "telegram" },
  ];
  return (
    <Box>
      <div className="title">Reports</div>

      <StyledCard
        style={{ padding: "8px", margin: "12px 0", marginTop: "20px" }}
      >
        <Box className="betweenRow">
          <Menu
            overflow="auto"
            position="initial"
            itemList={[
              {
                children: <>Ticket Source</>,
                subMenu: ticketSourceList.map((ticketSource) => {
                  return {
                    children: ticketSource.label,
                    value: ticketSource.value,
                    type: "radio",
                    checked: platform === ticketSource.value,
                    func: (e) => setPlatform(e.value),
                  };
                }),
              },
            ]}
            isFilter
          />
          <Box className="endRow">
            <MetaCRMDateRange
              onChange={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              maxDate={new Date()}
              custom
              defaultRange={defaultRange}
              startDate={startDate}
              endDate={endDate}
              onRangeChange={handleRangeChange}
            />
          </Box>
        </Box>
      </StyledCard>
      <TicketAnalytics
        startDate={startDate}
        endDate={endDate}
        platform={platform}
        currentRange={currentRange}
      />

      <AgentAnalytics
        startDate={startDate}
        endDate={endDate}
        platform={platform}
      />
    </Box>
  );
};

export default ServiceReportPage;
