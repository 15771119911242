import { Box } from "@mui/material";
import AutoCompleteDropdown from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import React, { memo, useContext, useState } from "react";
import { PerformanceContext } from "../context/PerformanceProvider";
import { useIntl } from "react-intl";

function ChannelDropDown({ handleOpenChannel }) {
  const { channel, setChannel } = useContext(PerformanceContext);
  const { formatMessage } = useIntl();
  const options = [
    { label: "All", value: "all" },
    { label: "Push", value: "push" },
    { label: "XMTP", value: "xmtp" },
  ];

  return (
    <Box display="flex" width={"100%"}>
      <AutoCompleteDropdown
        style={{ backgroundColor: "white" }}
        size="small"
        value={options.find((option) => option.value === channel)}
        onChange={(event, newValue) => {
          setChannel(newValue.value);
        }}
        options={options}
      />
      <IconButton
        style={{ marginLeft: "10px" }}
        size="large"
        onClick={handleOpenChannel}
      >
        <i className="meta-crm-icon-ic_x_circle font-size-32" />
      </IconButton>
    </Box>
  );
}

export default memo(ChannelDropDown);
