import axios from "axios";
import { apiUrl } from "features/configure";
import { useReduxRoles } from "features/metadesk/redux/roles";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useNavigateEntity from "hooks/useNavigateEntity";
import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { useForm } from "react-hook-form";
import useGetRolesHeader from "./useGetRolesHeader";

export function transformPermissions(permissions) {
  const permissionsObject = {};
  if (permissions) {
    permissions.forEach((permission) => {
      permissionsObject[permission.name] = permission.value;
    });
  }

  return permissionsObject;
}

export default function useCreateRole() {
  const { id } = useParams();
  const dispatchSnackbar = useDispatchSnackbar();
  const [entityName] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const { navigateEntity } = useNavigateEntity();
  const { header, loading } = useGetRolesHeader();

  const [form, setForm] = useState({
    name: "",
    description: "",
    permissions: {},
  });

  const {
    control,
    formState: { errors: errorsInfo, isValid, isDirty },
    getValues,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (header && header.length > 0) {
      const newPermissions = {};
      header.forEach((section) => {
        section.children.forEach((child) => {
          newPermissions[child.value] = "none";
        });
      });

      setForm((prev) => ({
        ...prev,
        permissions: newPermissions,
      }));

      reset((prev) => ({
        ...prev,
        ...newPermissions,
      }));
    }
  }, [header, reset]);

  function handleChange(event) {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  }

  function handleClickOption(name, value) {
    setForm((prev) => ({
      ...prev,
      permissions: { ...prev.permissions, [name]: value },
    }));
  }

  async function fetchCurrentRoleSetting(id) {
    try {
      setIsLoading(true);
      // await axios call to get current role setting
      const { data } = await axios(`${apiUrl}/api/role/${entityName}/${id}`);
      const transformedPermissionsData = transformPermissions(data.permissions);
      setForm({
        name: data.name,
        description: data.description,
        permissions: transformedPermissionsData,
      });
      reset({
        name: data.name,
        description: data.description,
        ...transformedPermissionsData,
      });
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  }

  async function saveRoleSetting() {
    try {
      setIsLoading(true);
      const inputData = {
        name: form.name,
        description: form.description,
        permissions: Object.keys(form.permissions).map((key) => ({
          name: key,
          value: form.permissions[key],
        })),
      };
      if (id) {
        await updateRole(inputData);
      } else {
        await createRole(inputData);
      }
      navigateEntity(`/settings/general/organization`);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  }

  async function createRole(inputData) {
    try {
      setIsLoading(true);
      // await axios call to create role
      const { data } = await axios.post(
        `${apiUrl}/api/role/${entityName}`,
        inputData
      );
      navigateEntity(`/settings/general/organization?tab=1`);
      dispatchSnackbar({
        message: "Role created successfully",
        variant: "success",
      });
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  async function updateRole(inputData) {
    try {
      setIsLoading(true);
      // await axios call to create role
      const { data } = await axios.put(
        `${apiUrl}/api/role/${entityName}/${id}`,
        inputData
      );

      dispatchSnackbar({
        message: "Role update successfully",
        variant: "success",
      });
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      if (header && header.length > 0) {
        fetchCurrentRoleSetting(id);
      }
    }
  }, [id, header]);

  return {
    form,
    isLoading,
    handleChange,
    handleClickOption,
    saveRoleSetting,
    control,
    errorsInfo,
    isValid,
    isDirty,
    reset,
  };
}
