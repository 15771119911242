import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import Login from "./Login";
import {
  StyledOnboardingLeftContainer,
  StyledOnboardingWrapper,
} from "./Onboarding.styles";
import {
  OnboardingContextProvider,
  useOnboardingState,
} from "./OnboardingProvider";

import WorkSpace from "./WorkSpace/WorkSpace";
import Trial from "./Trial/Trial";
import { useSelector } from "react-redux";
import {
  selectTrialEntityName,
  selectTrialHasConfirm,
} from "../redux/trial/trial.selector";
import { Outlet } from "react-router";

const OnboardingContent = () => {
  const sendDataToGTM = useGTMDispatch();
  const [step, setStep] = useState(0);
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  const entityName = useSelector(selectTrialEntityName);

  const trackStep = useCallback(
    (newStep) => {
      try {
        sendDataToGTM({
          event: `onboarding_page_view`,
          step: newStep,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Failed to send GTM event:", error);
      }
    },
    [sendDataToGTM]
  );

  useEffect(() => {
    // 為了關閉左側的圖片，當到marketing 或service 設定步驟時，左側的圖片就不會再顯示
    // if (step > 2) {
    //   setLeftContainerShow(false);
    // }

    trackStep(step);
  }, [step, trackStep]);

  const handleStepChange = useCallback(
    (newStep) => {
      try {
        sendDataToGTM({
          event: "onboarding_step_change",
          from_step: step,
          to_step: newStep,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Failed to send GTM event:", error);
      }
      setStep(newStep);
    },
    [step, sendDataToGTM]
  );

  if (step > 2) return <Outlet context={[entityName, isCustomDomain]} />;
  return (
    <StyledOnboardingWrapper>
      <StyledOnboardingLeftContainer>
        <Box
          component={"img"}
          src={require("assets/img/onboardingImg.png")}
          sx={{
            position: "absolute",
            bottom: "0",
            left: "50%",
            maxHeight: "calc(100vh - 100px)",
            transform: "translateX(-50%)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "79px",
            left: "50%",
            transform: "translateX(-50%)",
            fontWeight: 400,
          }}
        >
          <Box
            sx={{
              color: "#FFF",
              fontSize: "32px",
              fontFamily: "Saira",
              lineHeight: "50px",
              background: "linear-gradient(90deg, #FFF 50%, #999 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              mb: "8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            Your 360° Next-Gen CRM Hub
          </Box>
          <Box
            className="startRow"
            fontSize={"14px"}
            color="#d6d6d6"
            width="340px"
            m="0 auto"
          >
            <Box pr="15px" mr="15px" borderRight={"1px solid #555555"}>
              Analytics
            </Box>
            <Box pr="15px" mr="15px" borderRight={"1px solid #555555"}>
              Customer
            </Box>
            <Box pr="15px" mr="15px" borderRight={"1px solid #555555"}>
              Marketing
            </Box>
            <Box>Service</Box>
          </Box>
        </Box>
      </StyledOnboardingLeftContainer>
      <OnboardingContextProvider>
        <OnboardingRightContainer onStepChange={handleStepChange} />
      </OnboardingContextProvider>
    </StyledOnboardingWrapper>
  );
};

const OnboardingRightContainer = ({ onStepChange }) => {
  const { step } = useOnboardingState();

  useEffect(() => {
    onStepChange(step);
  }, [step, onStepChange]);

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      {step === 0 && <Login />}
      {step === 1 && <WorkSpace />}
      {step >= 2 && <Trial />}
    </Box>
  );
};

export default function Onboarding() {
  const gtmParams = {
    id:
      process.env.REACT_APP_ENV === "production"
        ? "GTM-K6F52LC3"
        : "GTM-NLFZXS5B",
    dataLayer: {
      page: "onboarding",
    },
  };

  return (
    <GTMProvider state={gtmParams}>
      <OnboardingContent />
    </GTMProvider>
  );
}
