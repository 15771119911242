import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import React, { useEffect } from "react";
import StepContent from "../StepContent";
import { StyledFormTitle } from "../../IntegrationWidgetSetting.styles";
import Button, { VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Box } from "@mui/material";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import axios from "axios";
import { Spinner, COLOR } from "@metacrm/metacrm-material-ui/dist/Spinner";

export default function RunTest({ widgetId }) {
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);

  useEffect(() => {
    if (!widgetId) return;
    handleCheckInstallWidget();
  }, [widgetId]);

  const handleCheckInstallWidget = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${apiUrl}/api/manage/widgetConfig/${entityName}`
      );

      const connectStatus = data.find(
        (widgetConfigItem) => widgetConfigItem._id === widgetId
      )?.connected;

      setStatus(connectStatus);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  };
  const runTestBlock = {
    title: "Connect Status",
    describe:
      "Run the connection test to make sure the widget is successfully connected.",
    content: (
      <>
        <StyledFormTitle center item xs={12} md={4}>
          <Button variant={VARIANT.OUTLINED} onClick={handleCheckInstallWidget}>
            Run Test
          </Button>
        </StyledFormTitle>
        <Grid position={"relative"} item xs={12} md={8}>
          <Box
            bgcolor="#FAFAFD"
            p="10px 12px"
            display="flex"
            alignItems="center"
            fontSize="14px"
          >
            <Box sx={{ color: "#777" }}>Widget connection</Box>
            <Box
              sx={{
                ml: "16px",
                fontWeight: "bold",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    color: "#7B61FF",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Spinner
                    size={14}
                    style={{ marginRight: "4px" }}
                    color={COLOR.SECONDARY}
                  />
                  Checking...
                </Box>
              ) : status ? (
                <Box
                  sx={{
                    color: "#7B61FF",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="meta-crm-icon-ic_check font-size-16"
                    style={{ marginRight: "4px" }}
                  />
                  Successful
                </Box>
              ) : (
                <Box
                  sx={{
                    color: "#FC5555",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="meta-crm-icon-ic_cancel font-size-16"
                    style={{ marginRight: "4px" }}
                  />
                  Failed
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </>
    ),
  };

  return (
    <StyledWhiteContainer style={{ marginBottom: "12px" }}>
      <StepContent settingStep={runTestBlock} />
    </StyledWhiteContainer>
  );
}
