import { GLOBAL_STATE_ACTION_TYPES } from "./globalState.types";
import { createAction } from "../reducer.utils";

export const setRemindBarShow = (showValue) => {
  return createAction(
    GLOBAL_STATE_ACTION_TYPES.SET_CURRENT_REMIND_BAR_SHOW,
    showValue
  );
};

export const setBillingBarShow = (showValue) => {
  return createAction(
    GLOBAL_STATE_ACTION_TYPES.SET_CURRENT_BILLING_BAR_SHOW,
    showValue
  );
};


export const setAdvancedSorting = (isAdvanced) => {
  return createAction(
    GLOBAL_STATE_ACTION_TYPES.SET_ADVANCED_SORTING,
    isAdvanced
  );
};