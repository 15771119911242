import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  USER_LIST_FETCH_BEGIN,
  USER_LIST_FETCH_SUCCESS,
  USER_LIST_FETCH_FAILURE,
  USER_LIST_UPDATE_SUCCESS,
  USER_DISCORD_UPDATE_SUCCESS,
  USER_SOURCE_FETCH_BEGIN,
  USER_SOURCE_FETCH_SUCCESS,
  USER_SOURCE_FETCH_FAILURE,
  USER_SOURCE_DATASET_FETCH_BEGIN,
  USER_SOURCE_DATASET_FETCH_SUCCESS,
  USER_SOURCE_DATASET_FETCH_FAILURE,
  HEADERS_SET_SUCCESS,
  USER_INFO_UPDATE_SUCCESS,
  UPDATE_USER_LIST_FILTER,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";

export const USER_FILTER_INITIAL_STATE = {
  userListFilter: {
    page: 1,
    orderData: { sort: "lastEngagement.date", order: -1 },
    search: "",
    searchTags: [],
    searchSource: [],
    platform: [],
    lastEngagement: [],
  },
};

const initHeaders = [
  {
    title: "User Identity",
    showText: "User Identity",
    disabledSort: false,
    show: true,
    id: "userIdentity",
    fixed: true,
    width: 170,
  },
  {
    title: "Wallet Value",
    showText: "Wallet Value",
    disabledSort: false,
    show: true,
    id: "walletValue",
    width: 130,
    fixed: false,
  },
  {
    title: "NFT Amount",
    showText: "NFT Amount",
    disabledSort: false,
    show: true,
    id: "nftAmount",
    width: 130,
    fixed: false,
  },
  {
    title: "Tags",
    showText: "Tags",
    disabledSort: true,
    show: true,
    id: "tags",
    width: 80,
    fixed: false,
  },
  {
    title: "Last Engagement",
    showText: "Last Engagement",
    disabledSort: false,
    show: true,
    id: "lastEngagement",
    width: 150,
  },
  {
    title: "Sources",
    showText: "Sources",
    disabledSort: true,
    show: true,
    fixed: false,
    id: "sources",
    width: 180,
  },
];

export function fetchUserAdminList({
  entityName,
  from,
  userSourceId,
  filter,
  signal,
}) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_LIST_FETCH_BEGIN,
    });
    const state = getState();
    const { service, marketing } = state?.metadesk?.entityConfig?.modules;

    if (service && !initHeaders.some((item) => item.id === "tickets")) {
      initHeaders.push({
        title: "Tickets",
        showText: "Tickets",
        disabledSort: true,
        show: false,
        id: "tickets",
        width: 40,
      });
    }

    if (
      marketing &&
      !initHeaders.some((item) => item.id === "protocols") &&
      !initHeaders.some((item) => item.id === "tokens")
    ) {
      initHeaders.push(
        {
          title: "Protocol",
          showText: "Protocol",
          disabledSort: true,
          show: false,
          id: "protocols",
          width: 150,
        },
        {
          title: "Token Holding",
          showText: "Token Holding",
          disabledSort: true,
          show: false,
          id: "tokens",
          width: 150,
        }
      );
    }

    let data = {
      sourceId: userSourceId,
      from: from,
      searchSource: _.get(filter, "searchSource"),
      entityName: entityName,
      page: _.get(filter, "page", 1),
      search: _.get(filter, "search"),
      searchTags: _.get(filter, "searchTags"),
      sortBy: _.get(filter, "orderData.sort"),
      order: _.get(filter, "orderData.order"),
      platform: _.get(filter, "platform"),
      lastEngagement: _.get(filter, "lastEngagement"),
    };
    let selectedTab = "";
    if (from) {
      data["from"] = from;
      selectedTab = from;
    } else if (userSourceId) {
      data["sourceId"] = userSourceId;
      selectedTab = userSourceId;
    }

    try {
      let userAdminList = await axios.get(`${apiUrl}/api/ticket/userList`, {
        params: data,
        signal,
      });
      const csvHeaders =
        _.filter(
          userAdminList.data.csvHeaders,
          (o) =>
            o.value !== "discordHandle" &&
            o.value !== "twitterHandle" &&
            o.value !== "email" &&
            o.value !== "userIdentity" &&
            o.value !== "_id"
        ) || [];
      let headers = [];
      if (selectedTab) {
        if (_.get(userAdminList, "data.users", []).length > 0) {
          headers = [
            ...initHeaders,
            ...(_.map(csvHeaders, (item) => {
              return {
                ...item,
                show: false,
                disabledSort: true,
                id: item._id,
                showText: item.value,
                title: item.value,
                width: 100,
              };
            }) || []),
          ];
        } else {
          headers = initHeaders;
        }
      }
      dispatch({
        type: USER_LIST_FETCH_SUCCESS,
        userSourceId,
        data: userAdminList.data.users,
        csvHeaders: csvHeaders,
        userHeaderItems: headers,
        userListCount: userAdminList.headers["x-total-count"],
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        console.log(err);
        dispatch({
          type: USER_LIST_FETCH_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
      }
    }
  };
}

export function updateUserListFilter(data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER_LIST_FILTER,
      data,
    });
  };
}

export function fetchUserSource({ entityName }) {
  return async (dispatch) => {
    dispatch({
      type: USER_SOURCE_FETCH_BEGIN,
    });

    try {
      let userSource = await axios.get(
        `${apiUrl}/api/userSource/${entityName}`
      );

      userSource.data.totalCount = userSource.headers["x-total-count"];

      dispatch({
        type: USER_SOURCE_FETCH_SUCCESS,
        data: userSource.data,
      });
    } catch (err) {
      dispatch({
        type: USER_SOURCE_FETCH_FAILURE,
      });
      dispatch(
        enqueueSnackbar({
          message: _.get(
            err,
            "response.data.error",
            _.get(err, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
}

export function fetchUserSourceDataset({ entityName }) {
  return async (dispatch) => {
    dispatch({
      type: USER_SOURCE_DATASET_FETCH_BEGIN,
    });

    try {
      let dataset = await axios.get(
        `${apiUrl}/api/userSource/${entityName}?type=dataset`
      );

      dispatch({
        type: USER_SOURCE_DATASET_FETCH_SUCCESS,
        data: dataset.data,
      });
    } catch (err) {
      dispatch({
        type: USER_SOURCE_DATASET_FETCH_FAILURE,
      });
      dispatch(
        enqueueSnackbar({
          message: _.get(
            err,
            "response.data.error",
            _.get(err, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
}

export function updateUser({ entityName, userId, userTagIds, userAddress }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let users = await axios.post(
          `${apiUrl}/api/manage/tags/${entityName}/user/${userId}/apply`,
          {
            tagIds: userTagIds,
          }
        );

        dispatch({
          type: USER_LIST_UPDATE_SUCCESS,
          data: users.data,
          userId,
          userAddress,
        });
        dispatch({
          type: USER_INFO_UPDATE_SUCCESS,
          data: users.data,
          userId,
        });
        resolve(users.data);
      } catch (err) {
        console.log(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "err"),
            options: {
              variant: "error",
            },
          })
        );
        reject();
      }
    });

    return promise;
  };
}

export function updateUserDiscordId({ entityName, userId, discordName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        await axios.post(apiUrl + "/api/user/updateDiscord", {
          userId,
          entityName,
          discordName,
        });
        dispatch({
          type: USER_DISCORD_UPDATE_SUCCESS,
          data: { _id: userId, discordUserName: discordName },
        });
        resolve();
      } catch (err) {
        console.log(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", "err"),
            options: {
              variant: "error",
            },
          })
        );
        reject();
      }
    });

    return promise;
  };
}

export function setSavedHeaderItems({ selectedTab, headers }) {
  return (dispatch) => {
    dispatch({
      type: HEADERS_SET_SUCCESS,
      selectedTab,
      headers,
    });
  };
}

export function useFetchUserList() {
  const dispatch = useDispatch();

  const {
    userInfos,
    userList,
    userSource,
    userSourceDataset,
    userSourceCount,
    userAdminList,
    fetchUserListPending,
    fetchUserListDone,
    fetchUserInfoPending,
    userListCount,
    fetchUserSourcePending,
    csvHeaders,
    savedHeaderItems,
    userListFilter,
    userHeaderItems,
  } = useSelector(
    (state) => ({
      userList: state.metadesk.userList,
      userInfos: state.metadesk.userInfos,
      userSource: state.metadesk.userSource,
      userSourceDataset: state.metadesk.userSourceDataset,
      userSourceCount: state.metadesk.userSourceCount,
      userAdminList: state.metadesk.userAdminList,
      fetchUserListPending: state.metadesk.fetchUserListPending,
      fetchUserListDone: state.metadesk.fetchUserListDone,
      fetchUserInfoPending: state.metadesk.fetchUserInfoPending,
      userListCount: state.metadesk.userListCount,
      fetchUserSourcePending: state.metadesk.fetchUserSourcePending,
      csvHeaders: state.metadesk.csvHeaders,
      savedHeaderItems: state.metadesk.savedHeaderItems,
      userListFilter: state.metadesk.userListFilter,
      userHeaderItems: state.metadesk.userHeaderItems,
    }),
    shallowEqual
  );

  const fetchUserAdminListAction = useCallback(
    (data) => {
      return dispatch(fetchUserAdminList(data));
    },
    [dispatch]
  );

  const fetchUserSourceAction = useCallback(
    (data) => {
      return dispatch(fetchUserSource(data));
    },
    [dispatch]
  );

  const fetchUserSourceDatasetAction = useCallback(
    (data) => {
      return dispatch(fetchUserSourceDataset(data));
    },
    [dispatch]
  );

  const updateAction = useCallback(
    (data) => {
      return dispatch(updateUser(data));
    },
    [dispatch]
  );
  const updateDiscordAction = useCallback(
    (data) => {
      return dispatch(updateUserDiscordId(data));
    },
    [dispatch]
  );
  const setHeaderItemsAction = useCallback(
    (data) => {
      return dispatch(setSavedHeaderItems(data));
    },
    [dispatch]
  );
  const updateUserListFilterAction = useCallback(
    (data) => {
      return dispatch(updateUserListFilter(data));
    },
    [dispatch]
  );

  return {
    userList,
    userInfos,
    userSource,
    userSourceDataset,
    userSourceCount,
    userAdminList,
    setSavedHeaderItems: setHeaderItemsAction,
    fetchUserSource: fetchUserSourceAction,
    fetchUserSourceDataset: fetchUserSourceDatasetAction,
    fetchUserAdminList: fetchUserAdminListAction,
    updateUser: updateAction,
    updateDiscord: updateDiscordAction,
    updateUserListFilter: updateUserListFilterAction,
    fetchUserListDone,
    fetchUserListPending,
    userListCount,
    fetchUserSourcePending,
    csvHeaders,
    savedHeaderItems,
    userListFilter,
    userHeaderItems,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_LIST_FETCH_BEGIN:
      return {
        ...state,
        fetchUserListPending: true,
      };

    case USER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        userList: action.data,
        userListCount: action.userListCount,
        csvHeaders: action.csvHeaders,
        userHeaderItems: action.userHeaderItems,
        fetchUserListDone: true,
        fetchUserListPending: false,
      };
    case USER_DISCORD_UPDATE_SUCCESS:
      const discordIndex = _.findIndex(state.userList, {
        _id: action.data._id,
      });
      const newUserList = state.userList;
      newUserList[discordIndex] = {
        ...newUserList[discordIndex],
        discordUserName: action.data.discordUserName,
      };
      return {
        ...state,
        userList: newUserList,
      };
    case USER_LIST_UPDATE_SUCCESS:
      const newList = [...state.userList];
      const updatedIndex = _.findIndex(state.userList, {
        _id: action.data._id,
      });
      newList[updatedIndex] = { ...newList[updatedIndex], ...action.data };

      return {
        ...state,
        userList: newList,
      };

    case USER_LIST_FETCH_FAILURE:
      return {
        ...state,
        fetchUserListPending: false,
      };
    case USER_SOURCE_FETCH_BEGIN:
      return {
        ...state,
        fetchUserSourcePending: true,
      };

    case USER_SOURCE_FETCH_SUCCESS:
      return {
        ...state,
        userSource: action.data,
        userSourceCount: action.data.totalCount,
        fetchUserSourcePending: false,
      };
    case USER_SOURCE_FETCH_FAILURE:
      return {
        ...state,
        fetchUserSourcePending: false,
      };
    case USER_SOURCE_DATASET_FETCH_BEGIN:
      return {
        ...state,
        fetchUserSourceDatasetPending: true,
      };

    case USER_SOURCE_DATASET_FETCH_SUCCESS:
      return {
        ...state,
        userSourceDataset: action.data,
        fetchUserSourceDatasetPending: false,
      };
    case USER_SOURCE_DATASET_FETCH_FAILURE:
      return {
        ...state,
        fetchUserSourceDatasetPending: false,
      };
    case HEADERS_SET_SUCCESS:
      return {
        ...state,
        savedHeaderItems: {
          ...state.savedHeaderItems,
          [action.selectedTab]: action.headers,
        },
      };
    case UPDATE_USER_LIST_FILTER:
      return {
        ...state,
        userListFilter: { ...state.userListFilter, ...action.data },
      };

    default:
      return state;
  }
}
