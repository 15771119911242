const solanaTokenList = [
  {
    chainId: 101,
    name: "Drama Token",
    symbol: "DRAMA",
    address: "3ba26vEuRSLTd6GmDGQsrLWkRp1VRXdHoUeum3k4tSuF",
    decimals: 9,
    logoURI:
      "https://qdpw5kh2suzanv4jpry3jevawh7hmzqdewjfvg7ofuladhc7poaa.arweave.net/gN9uqPqVMgbXiXxxtJKgsf52ZgMlklqb7i0WAZxfe4A?ext=jpeg",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "XTAG Token (Wormhole)",
    symbol: "XTAG",
    address: "Ga15AHC3396dzHSuBkTUmnjNxBduzpBeVGrMzE5KVxX7",
    decimals: 6,
    logoURI:
      "https://assets.coingecko.com/coins/images/20912/large/xtag.png?1637922382",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Sunrise gSOL",
    symbol: "GSOL",
    address: "gso1xA56hacfgTHTF4F7wN5r4jbnJsKh99vR595uybA",
    decimals: 9,
    logoURI: "https://arweave.net/46QNhUyat533ptVC2myiNVb5BvSP7PAFzM5ILCZTrGU",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cloned Arbitrum",
    symbol: "clARB",
    address: "9cMWa1wuWcio3vgEpiFg7PqKbcoafuUw5sLYFkXJ2J8M",
    decimals: 8,
    logoURI:
      "https://assets.coingecko.com/coins/images/16547/standard/photo_2023-03-29_21.47.00.jpeg?1696516109",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cloned Optimism",
    symbol: "clOP",
    address: "4cwVHQtwiK7r9aAUsu4cetk1JtLWPymopWcpTSsihCdL",
    decimals: 8,
    logoURI:
      "https://assets.coingecko.com/coins/images/25244/standard/Optimism.png",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Edgevana Staked SOL",
    symbol: "edgeSOL",
    address: "edge86g9cVz87xcpKpy3J77vbp4wYd9idEV562CCntt",
    decimals: 9,
    logoURI: "https://arweave.net/JqUwm41OHf2jFLDognx7BKD6N9LiUDDRMTI8k2B7fHE",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Juicy Stake Staked SOL",
    symbol: "jucySOL",
    address: "jucy5XJ76pHVvtPZb5TKRcGQExkwit2P5s4vY8UzmpC",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/5ohvXVJ7f3HxnwYGnXKdECWC3KFcU95dHu7S7x69Y1sA/logo_rev.svg",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "bonkSOL",
    symbol: "bonkSOL",
    address: "BonK1YhkXEGLZzwtcvRTip3gAL9nCeQD7ppZBLXhtTs",
    decimals: 9,
    logoURI: "https://arweave.net/ms-FdIyJ8TxEJOb2SAYhfyrLop7TDrCEjD-I-oIl5u4",
    tags: [],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Infinity",
    symbol: "INF",
    address: "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
    decimals: 9,
    logoURI:
      "https://cloudflare-ipfs.com/ipfs/bafkreiflz2xxkfn33qjch2wj55bvbn33q3s4mmb6bye5pt3mpgy4t2wg4e",
    tags: [],
    verified: true,
    holders: 22654,
  },
  {
    chainId: 101,
    name: "0xAdventure",
    symbol: "ZAD",
    address: "6ZVHP3A9X7Q3UaJiUdpqGRJDfQMbHMFW5ptE1GQ77fD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38295/large/U5-K7i7S_400x400.png?1717005632",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "0xadventure",
    },
  },
  {
    chainId: 101,
    name: "1intro",
    symbol: "INTRO",
    address: "inTCqHJaLAETUxvRZ2kC45G2sThq9BFWVimfaQw7t6w",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37128/large/INTRO_Token_Logo.png?1713372576",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "1intro",
    },
  },
  {
    chainId: 101,
    name: "1SAFU",
    symbol: "SAFU",
    address: "GWgwUUrgai3BFeEJZp7bdsBSYiuDqNmHf9uRusWsf3Yi",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22792/large/145941796-8f7716f4-66bc-4a38-9ad5-441525c3b5b2.png?1696522094",
    tags: [],
    verified: true,
    holders: 16639,
    extensions: {
      coingeckoId: "1safu",
    },
  },
  {
    chainId: 101,
    name: "2080",
    symbol: "2080",
    address: "Dwri1iuy5pDFf2u2GwwsH2MxjR6dATyDv9En9Jk8Fkof",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31816/large/b_fidelion_web_logo.png?1696530630",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "2080",
    },
  },
  {
    chainId: 101,
    name: "21X",
    symbol: "21X",
    address: "6r4PCVaX4rYN9WdbXwVWAQL4djFoUaeBMsq8Cxc6NApZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37730/large/photo_2024-05-09_10-21-43.jpg?1715336089",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "21x",
    },
  },
  {
    chainId: 101,
    name: "777FuckIlluminatiWorldwid",
    symbol: "FIW",
    address: "FeKmTunVrXDKEoDJbuTwZi8vfFFw3MHzpPB79JD8ARYU",
    decimals: 6,
    logoURI:
      "https://gateway.irys.xyz/L6E7LMN7Trvj-Iv4EzvkDqL205JVxGOHoY67LUr8Ytg",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "777fuckilluminatiworldwid",
    },
  },
  {
    chainId: 101,
    name: "8Bit Chain",
    symbol: "W8BIT",
    address: "GPs2XbyDcqobdupeXpKaB2ECwgkF5ev4x8GsSr9EMRRp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35459/large/8BIT-200x200.PNG?1708675050",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "8bit-chain",
    },
  },
  {
    chainId: 101,
    name: "9to5io",
    symbol: "9TO5",
    address: "2fNexKk1XFVRqRmuak4hFcgfpTSJf3HE6BH6f6bjkxz7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37829/large/9to5.jpg?1715670753",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "9to5io",
    },
  },
  {
    chainId: 101,
    name: "Abble",
    symbol: "AABL",
    address: "ENoD8J2J6wNHkcJkvVBkwq5JMiR1oNBfBZRkoHCQogyT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36013/large/lM8keUnD_400x400.jpg?1710326029",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "abble",
    },
  },
  {
    chainId: 101,
    name: "Access Protocol",
    symbol: "ACS",
    address: "5MAYDfq5yxtudAhtfyuMBuHZjgAbaS9tbEyEQYAhDS5y",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28747/large/dR4FovX4_400x400.jpg?1696527727",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "access-protocol",
    },
  },
  {
    chainId: 101,
    name: "achi",
    symbol: "ACHI",
    address: "4rUfhWTRpjD1ECGjw1UReVhA8G63CrATuoFLRVRkkqhs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34771/large/IMG_8700.jpeg?1705993312",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "achi",
    },
  },
  {
    chainId: 101,
    name: "ACHI INU",
    symbol: "ACHI",
    address: "HvQKzBzp1YhXdqDUrUB8fqrfbJ6rU9CytzscMyG7EFAe",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36212/large/ACHI_INU_LOGO_TRANSPARENT.png?1710836306",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "achi-inu",
    },
  },
  {
    chainId: 101,
    name: "ACHMED - HEART AND SOL",
    symbol: "ACHMED",
    address: "HhBoQXVmcxs6BDrkNJeQe7etsW69tcASHwGsXSTr8UUf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38400/large/geckologo.png?1717458646",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "achmed-heart-and-sol",
    },
  },
  {
    chainId: 101,
    name: "ADA the Dog",
    symbol: "ADA",
    address: "E4Q5pLaEiejwEQHcM9GeYSQfMyGy8DJ4bPWgeYthn24v",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33673/large/IMG_6070_%281%29.png?1702702200",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ada-the-dog",
    },
  },
  {
    chainId: 101,
    name: "A DOG IN THE MATRIX",
    symbol: "MATRIX",
    address: "DjKXdLpTTJKRXmjWb2WWthUSyppHaxVbtLa3aq1XZJju",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35411/large/ADGMTX_-_200x200.png?1708502727",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "a-dog-in-the-matrix",
    },
  },
  {
    chainId: 101,
    name: "Aevum",
    symbol: "AEVUM",
    address: "EsirN3orp85uyvZyDrZnbe9cyo7N1114ynLFdwMPCQce",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30587/large/vivaion-aevum-2.png?1696529455",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "aevum-ore",
    },
  },
  {
    chainId: 101,
    name: "A Gently Used 2001 Honda",
    symbol: "USEDCAR",
    address: "9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33996/large/usecar.png?1703599678",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "a-gently-used-2001-honda",
    },
  },
  {
    chainId: 101,
    name: "A Gently Used Nokia 3310",
    symbol: "USEDPHONE",
    address: "E2x5XH8eHkZGiaA8mFicU5CoGUJxSRKiXjEW5Nybf8Nn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34197/large/-o3LcH4TAfpv0J475jrxPAonKqGxbdcVcRlGhzzZ_oE.jpeg?1705863498",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "a-gently-used-nokia-3310",
    },
  },
  {
    chainId: 101,
    name: "AiAkitaX",
    symbol: "AIX",
    address: "A9zo6y9QFLaSrB9yWdr9WgK7oAbxvi8cFmzE1DAhyNBg",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35376/large/Aix.png?1708419239",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "aiakitax",
    },
  },
  {
    chainId: 101,
    name: "AI Community",
    symbol: "AI",
    address: "99ouK5YUK3JPGCPX9joNtHsMU7NPpU7w91JN4kdQ97po",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36025/large/IMG_2023.JPG?1710387107",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ai-community",
    },
  },
  {
    chainId: 101,
    name: "AI Dragon",
    symbol: "CHATGPT",
    address: "3J6ZAHwGvqhCYkBzeMjTBRhC56QnT7zcMPxYV4GAPjxu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35333/large/200x200.png?1708272828",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ai-dragon",
    },
  },
  {
    chainId: 101,
    name: "Aittcoin",
    symbol: "AITT",
    address: "GPbNni4uWSEjSWQhJnv7ZYpjFE3znmi4594iEMndx95L",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36100/large/aittcoin.png?1710482811",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "aittcoin",
    },
  },
  {
    chainId: 101,
    name: "Akita Inu",
    symbol: "AKT",
    address: "hUoehiMy279k95UeSijKkjx7RUsb676KSDgJ2i3xYbW",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35302/large/Akita_Inu.png?1708087719",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "akita-inu-2",
    },
  },
  {
    chainId: 101,
    name: "Alaaddin.ai",
    symbol: "ALDIN",
    address: "5vJhTHZrQqThCDi8nYm767sfN4NyYsNPFNBebUuUPr8y",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38012/large/icon_150x150.png?1716274401",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alaaddin-ai",
    },
  },
  {
    chainId: 101,
    name: "Alan the Alien",
    symbol: "ALAN",
    address: "3swKY5FN4ENQYYJTYqQzGbT7iEv8yD5UngCWfk3fEVA7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36652/large/3swKY5FN4ENQYYJTYqQzGbT7iEv8yD5UngCWfk3fEVA7.png?1712040995",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alan-the-alien",
    },
  },
  {
    chainId: 101,
    name: "Albemarle Meme Token",
    symbol: "ALBEMARLE",
    address: "FEj9JuKq7JMLtvZF3EetVNd5ufU2vPA4ZDiVGaUbiRkj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35909/large/albemarle.png?1710178844",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "albemarle-meme-token",
    },
  },
  {
    chainId: 101,
    name: "Albert",
    symbol: "ALBERT",
    address: "8KSiBaJvpwqzYFU7u8qLmFqiEAHkARDRkAnetFjEznZX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36506/large/Albert_Logo.jpg?1711643124",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "albert",
    },
  },
  {
    chainId: 101,
    name: "Aldrin",
    symbol: "RIN",
    address: "E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16849/large/Aldrin.png?1696516417",
    tags: [],
    verified: true,
    holders: 17046,
    extensions: {
      coingeckoId: "aldrin",
    },
  },
  {
    chainId: 101,
    name: "Aleph.im (Wormhole)",
    symbol: "ALEPH",
    address: "3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22886/large/ALEPH_wh_small.png?1696522184",
    tags: [],
    verified: true,
    holders: 1397,
    extensions: {
      coingeckoId: "aleph-im-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Alickshundra Occasional-Cortex",
    symbol: "AOC",
    address: "GfkfESc5A4EkxCGWsymNY4ZfkCDdvtB7ajCybLfYL1Qq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37600/large/pfp-transparent.png?1714986189",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alickshundra-occasional-cortex",
    },
  },
  {
    chainId: 101,
    name: "Alien Chicken Farm",
    symbol: "ACF",
    address: "2cZv8HrgcWSvC6n1uEiS48cEQGb1d3fiowP2rpa4wBL9",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25441/large/acf-token_%281%29.png?1696524573",
    tags: [],
    verified: true,
    holders: 1619,
    extensions: {
      coingeckoId: "alien-chicken-farm",
    },
  },
  {
    chainId: 101,
    name: "ALL.ART",
    symbol: "AART",
    address: "F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22012/large/all-art.PNG?1696521359",
    tags: [],
    verified: true,
    holders: 38899,
    extensions: {
      coingeckoId: "all-art",
    },
  },
  {
    chainId: 101,
    name: "Allbridge",
    symbol: "ABR",
    address: "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18690/large/logo.png?1701737985",
    tags: [],
    verified: true,
    holders: 12691,
    extensions: {
      coingeckoId: "allbridge",
    },
  },
  {
    chainId: 101,
    name: "AllDomains",
    symbol: "ALL",
    address: "BaoawH9p2J8yUK9r5YXQs3hQwmUJgscACjmTkh8rMwYL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31747/large/ALL.png?1696530566",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alldomains",
    },
  },
  {
    chainId: 101,
    name: "ALPHA",
    symbol: "ALPHA",
    address: "2XJ4DvF97kJKjLAB195KSymFx8uXLmuT1Pw8Z2HCSytP",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36088/large/LOGO.png?1710442339",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alpha-2",
    },
  },
  {
    chainId: 101,
    name: "Alpha Ai",
    symbol: "ALPHA AI",
    address: "6HPBookTDQeTvfFiVQ7d2jXUmCt7yFcBgKPWef7uTbFC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35720/large/Alpha_AI.jpg?1709631670",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alpha-ai",
    },
  },
  {
    chainId: 101,
    name: "Alux Jownes",
    symbol: "JOWNES",
    address: "8m9fjYycXAFva1kScttQgsESVZT7yELhjZASqfHBuMa5",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37012/large/jownes.jpeg?1713144702",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "alux-jownes",
    },
  },
  {
    chainId: 101,
    name: "AMC",
    symbol: "AMC",
    address: "9jaZhJM6nMHTo4hY9DGabQ1HNuUWhJtm7js1fmKMVpkN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37822/large/AMC.png?1715661262",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "amc",
    },
  },
  {
    chainId: 101,
    name: "American Coin",
    symbol: "USA",
    address: "69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37000/large/american-removebg-preview.png?1713149642",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "american-coin",
    },
  },
  {
    chainId: 101,
    name: "Amulet Protocol",
    symbol: "AMU",
    address: "AMUwxPsqWSd1fbCGzWsrRKDcNoduuWMkdR38qPdit8G8",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34716/large/AMT200x200.png?1705904544",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "amulet-protocol",
    },
  },
  {
    chainId: 101,
    name: "Amulet Staked SOL",
    symbol: "AMTSOL",
    address: "SoLW9muuNQmEAoBws7CWfYQnXRXMVEG12cQhy6LE2Zf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27786/large/amtSOL200x200.png?1696526807",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "amulet-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "analoS",
    symbol: "ANALOS",
    address: "7iT1GRYYhEop2nV1dyCwK2MGyLmPHq47WhPGSwiqcUg5",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33855/large/image_4_%286%29.png?1703125199",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "analos",
    },
  },
  {
    chainId: 101,
    name: "Analysoor",
    symbol: "ZERO",
    address: "93RC484oMK5T9H89rzT5qiAXKHGP9jscXfFfrihNbe57",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33690/large/ZERO.jpg?1702733338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "analysoor",
    },
  },
  {
    chainId: 101,
    name: "Andrea Von Speed",
    symbol: "VONSPEED",
    address: "DVzrCErBzydh92bBzSJX1dKwVvb4omwhrvNz8CwRyxxV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34722/large/VONSPEED.png?1705917828",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "andrea-von-speed",
    },
  },
  {
    chainId: 101,
    name: "Andy",
    symbol: "ANDY",
    address: "93n51i8anR3ziS6kxqXEACjrvJoXpAxt3qbbo3eaRFRz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34477/large/andy-sol-pfp_0.5x.png?1704999205",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "andy",
    },
  },
  {
    chainId: 101,
    name: "Andy on SOL",
    symbol: "ANDY",
    address: "667w6y7eH5tQucYQXfJ2KmiuGBE8HfYnqqbjLNSw7yww",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35885/large/IMG_7674.jpeg?1712105895",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "andy-on-sol",
    },
  },
  {
    chainId: 101,
    name: "AngrySlerf",
    symbol: "ANGRYSLERF",
    address: "2QagM8UBq81JZ2yvdGsJCG1MVXUxihaVxajXCAXpFJsF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37618/large/ANGRYSLERF.png?1715062563",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "angryslerf",
    },
  },
  {
    chainId: 101,
    name: "Anita Max Wynn",
    symbol: "WYNN",
    address: "4vqYQTjmKjxrWGtbL2tVkbAU1EVAz9JwcYtd2VE3PbVU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34305/large/Anita_Max_Wynn.jpg?1705500910",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "anita-max-wynn",
    },
  },
  {
    chainId: 101,
    name: "Anonymous",
    symbol: "ANON",
    address: "8VJ51bdE3xorQ1zB7FEa8CsHdM4kw77xCFiCgbnL2qbT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38598/large/1000182499.jpg?1718094780",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "anonymous",
    },
  },
  {
    chainId: 101,
    name: "Ansem's Cat",
    symbol: "HOBBES",
    address: "6n7Janary9fqzxKaJVrhL9TG2F61VbAtwUMu1YZscaQS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36708/large/Hobbes_PFP.png?1712126732",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ansem-s-cat",
    },
  },
  {
    chainId: 101,
    name: "Ansem Wif Photographer",
    symbol: "AWP",
    address: "HAvWYZjA4eLA56gZs2iUZub8E12Pk3VYgmrW3Lmw25D",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37048/large/AWP200x200.png?1713165133",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ansem-wif-photographer",
    },
  },
  {
    chainId: 101,
    name: "Ansom",
    symbol: "ANSOM",
    address: "BfHkvKMEYjwPXnL36uiM8RnAoMFy8aqNyTJXYU3ZnZtz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36822/large/007CF235-D9EC-4C2C-9138-AE75538F5C8A_1_105_c.jpeg?1712554792",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ansom",
    },
  },
  {
    chainId: 101,
    name: "Antara Token",
    symbol: "ANTT",
    address: "ANTT2Ve8c3JC6fAksFxUGtByzEHmJs49EupP7htA5W7j",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35451/large/ANTT_Logo_7_%281%29.png?1708665182",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "antara-raiders-royals",
    },
  },
  {
    chainId: 101,
    name: "Any Inu",
    symbol: "AI",
    address: "ACeWC77UeW2DBZMe7YBsuXoxLvk4dHMnPzneApau1Au6",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34126/large/anyinulogo200.png?1704174269",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "any-inu",
    },
  },
  {
    chainId: 101,
    name: "Aped",
    symbol: "APED",
    address: "Eq4Xm7ajvUn28H5NXsy9DfvLnuNpxpqYPJDesoPRRkPf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35061/large/Aped.png?1707239323",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "aped-2",
    },
  },
  {
    chainId: 101,
    name: "Ape.lol",
    symbol: "APE",
    address: "DF5yCVTfhVwvS1VRfHETNzEeh1n6DjAqEBs3kj9frdAr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38035/large/1000009529.png?1716324996",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ape-lol",
    },
  },
  {
    chainId: 101,
    name: "ApeWifHat",
    symbol: "APEWIFHAT",
    address: "KdF3kjqSHbWXFstBLA2dccDxuNBi4o3yZqtMhB7k5dZ",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36009/large/ApeWifHat.png?1710324324",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "apewifhat",
    },
  },
  {
    chainId: 101,
    name: "ApeXit Finance",
    symbol: "APEX",
    address: "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16864/large/cg.png?1696516433",
    tags: [],
    verified: true,
    holders: 15407,
    extensions: {
      coingeckoId: "apexit-finance",
    },
  },
  {
    chainId: 101,
    name: "aping",
    symbol: "APING",
    address: "94sFWT94hg6qK9VtYwGz8VxbyEMaXf9H2U3HTTbofimy",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34340/large/aping.png?1704989857",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "aping",
    },
  },
  {
    chainId: 101,
    name: "Apple Cat",
    symbol: "$ACAT",
    address: "acatzTjUeHDT3SoufN6NMxGUmBFtoqFHnFwusdw8kYX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36349/large/acat.png?1711188328",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "apple-cat",
    },
  },
  {
    chainId: 101,
    name: "Apricot",
    symbol: "APRT",
    address: "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20636/large/hF_3FMuH_400x400.jpg?1696520039",
    tags: [],
    verified: true,
    holders: 14591,
    extensions: {
      coingeckoId: "apricot",
    },
  },
  {
    chainId: 101,
    name: "APYSwap",
    symbol: "APYS",
    address: "5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14163/large/apys.png?1696513881",
    tags: [],
    verified: true,
    holders: 2859,
    extensions: {
      coingeckoId: "apyswap",
    },
  },
  {
    chainId: 101,
    name: "Arab cat",
    symbol: "ARAB",
    address: "BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34845/large/arab_cat_cg.png?1706254583",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "arab-cat",
    },
  },
  {
    chainId: 101,
    name: "ARB Protocol",
    symbol: "ARB",
    address: "9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26046/large/IMG_3600.png?1696525126",
    tags: [],
    verified: true,
    holders: 1533,
    extensions: {
      coingeckoId: "arb-protocol",
    },
  },
  {
    chainId: 101,
    name: "Archetype⚡️",
    symbol: "ARCH⚡️",
    address: "GbgvbeKhJS9NGyRq1ZgCBzXpKw3HoeafRwzqNPQpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38569/large/pJnJ14k-_400x400_%281%29.jpg?1718008036",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "archetype",
    },
  },
  {
    chainId: 101,
    name: "ArgentinaCoin",
    symbol: "ARG",
    address: "9XRpjZjhJPeWtUymiEWn3FW7uAnMeQca14ucTWWWyP2g",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37876/large/arg_200x200.png?1715832878",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "argentinacoin",
    },
  },
  {
    chainId: 101,
    name: "Arizona Iced Tea",
    symbol: "99CENTS",
    address: "3VTXWW93L1WRfQ7QDPjvfBQgzFRVqDgHPnc8vwQmEpUi",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36955/large/IMG_0798.png?1712903082",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "arizona-iced-tea",
    },
  },
  {
    chainId: 101,
    name: "Artificial Robotic Tapestry VOLTS",
    symbol: "VOLTS",
    address: "VoLTMA8zp2vie3cqru3Ud5G7FWV3ofciEP7DFB4yAMH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36104/large/favicon.png?1710483557",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "artificial-robotic-tapestry-volts",
    },
  },
  {
    chainId: 101,
    name: "Artrade",
    symbol: "ATR",
    address: "ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24894/large/ATR_logo.png?1706954201",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "artrade",
    },
  },
  {
    chainId: 101,
    name: "Astrals GLXY",
    symbol: "GLXY",
    address: "CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25389/large/glxy.png?1696524522",
    tags: [],
    verified: true,
    holders: 7100,
    extensions: {
      coingeckoId: "astrals-glxy",
    },
  },
  {
    chainId: 101,
    name: "Atlas DEX",
    symbol: "ATS",
    address: "HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23002/large/logo.png?1696522297",
    tags: [],
    verified: true,
    holders: 4101,
    extensions: {
      coingeckoId: "atlas-dex",
    },
  },
  {
    chainId: 101,
    name: "AtPay",
    symbol: "ATPAY",
    address: "8sF1JWbZVCUvb6TyHYbsu9CkM8Qm6jnFVe6PqtAsdzCU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27254/large/atpay.png?1696526303",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "atpay",
    },
  },
  {
    chainId: 101,
    name: "Audius (Wormhole)",
    symbol: "AUDIO",
    address: "9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22936/large/AUDIO_wh_small.png?1696522232",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "audius-wormhole",
    },
  },
  {
    chainId: 101,
    name: "aura",
    symbol: "AURA",
    address: "DtR4D9FtVoTX2569gaL837ZgrB6wNjj6tkmnX9Rdk9B2",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38376/large/aura_pfp.png?1717309607",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "aura-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Aurory",
    symbol: "AURY",
    address: "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19324/large/Ico_Blanc_%281%29.png?1713464485",
    tags: [],
    verified: true,
    holders: 47153,
    extensions: {
      coingeckoId: "aurory",
    },
  },
  {
    chainId: 101,
    name: "Autonolas",
    symbol: "OLAS",
    address: "Ez3nzG9ofodYCvEmw73XhQ87LWNYVRM2s7diB5tBZPyM",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31099/large/OLAS-token.png?1696529930",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "autonolas",
    },
  },
  {
    chainId: 101,
    name: "Avalanche (Wormhole)",
    symbol: "AVAX",
    address: "KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22943/large/AVAX_wh_small.png?1696522240",
    tags: [],
    verified: true,
    holders: 2432,
    extensions: {
      coingeckoId: "avalanche-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Avenue Hamilton Token",
    symbol: "AHT",
    address: "AHT1yynTv45s3P3KrRfQCVMHckdHeMVA3fteEg34xt9y",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28086/large/7FbZmTSKMsSqtlfe8yH1n3QgdBc3RBBt0B6dsdprVBs.png?1696527096",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "avenue-hamilton-token",
    },
  },
  {
    chainId: 101,
    name: "Awkward Look Monkey Club",
    symbol: "ALMC",
    address: "7f94zk1EgfoeG57Vj5FtDDjMmPNHM4DYs7KRiyd2T4bA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36746/large/Untitled_design__16___1_-removebg-preview_%281%29.png?1712217723",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "awkward-look-monkey-club",
    },
  },
  {
    chainId: 101,
    name: "Axie Infinity Shard (Wormhole)",
    symbol: "AXSET",
    address: "HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22952/large/AXSet_wh_small.png?1696522248",
    tags: [],
    verified: true,
    holders: 1004,
    extensions: {
      coingeckoId: "axie-infinity-shard-wormhole",
    },
  },
  {
    chainId: 101,
    name: "BABA",
    symbol: "BABA",
    address: "6dEJEuFvaD8jMyotbvbMx7ixpP2frXnZCSgDexwqkE6B",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35167/large/Baba_Avatar%283%29.png?1707665351",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "baba",
    },
  },
  {
    chainId: 101,
    name: "Baby",
    symbol: "BABY",
    address: "5hmf8Jt9puwoqiFQTb3vr22732ZTKYRLRw9Vo7tN3rcz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36336/large/logo_200.png?1711101798",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "baby",
    },
  },
  {
    chainId: 101,
    name: "BABY BEERCOIN",
    symbol: "BBEER",
    address: "7okPYisaUFvpTKu96KJcPLLwGB9ZyCZJjxyKr8CdEooM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38611/large/BABY_BEERCOIN.png?1718101753",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "baby-beercoin",
    },
  },
  {
    chainId: 101,
    name: "BabyBonk",
    symbol: "BABYBONK",
    address: "Dx1Lq5FjangW5ifRMEogAiakm24LyB5AoHmQifepvNjV",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34741/large/babybonk_logo.jpg?1706018014",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "babybonk-2",
    },
  },
  {
    chainId: 101,
    name: "babydogwifhat",
    symbol: "BABYWIF",
    address: "9ceEjz32cv8jBcqsppgjrryiE2tor7PCm7j9mYk8gzTk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35813/large/u9sudQgz_400x400.jpg?1709882785",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "babydogwifhat",
    },
  },
  {
    chainId: 101,
    name: "BabyMyro",
    symbol: "BABYMYRO",
    address: "5AubNFWbb7HYMXN4dXUcPiRaUSA3GiFuF3mBz1Z3eNV6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35748/large/jSkWXDNB_400x400.jpg?1709716204",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "babymyro-2",
    },
  },
  {
    chainId: 101,
    name: "BABY OF BOMEOW",
    symbol: "BABYBOMEOW",
    address: "Fq7rR1Mrp1W7pcBftRXUWMF7KZmtbKsWM726rv1zYrhP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37017/large/Baby_of_Meow_200x200.png?1713152149",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "baby-of-bomeow",
    },
  },
  {
    chainId: 101,
    name: "Baby Samo Coin",
    symbol: "BABY",
    address: "Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20262/large/386VuTho_400x400.jpg?1696519669",
    tags: [],
    verified: true,
    holders: 13355,
    extensions: {
      coingeckoId: "baby-samo-coin",
    },
  },
  {
    chainId: 101,
    name: "Baby Slerf",
    symbol: "BABYSLERF",
    address: "3K3F9G7zrPd9hPnCDKbBGXwHM85CD1ewLyzNsH51Ry43",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37068/large/Baby_Slerf.png?1713179793",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "baby-slerf",
    },
  },
  {
    chainId: 101,
    name: "BabySOL",
    symbol: "BABYSOL",
    address: "bsAwuVJMPAazbQSVpPQND69mvwftQutZhxpWqsf2BUE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33985/large/baby-sol-200-x-200.png?1703583718",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "babysol",
    },
  },
  {
    chainId: 101,
    name: "BABYTRUMP",
    symbol: "BABYTRUMP",
    address: "6NbnHQKD2dh4jswTLmMCP7LnSh4Nh6y2cNgdQg2ny9zW",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34750/large/babytrumpNEWlogo200.png?1705954645",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "babytrump",
    },
  },
  {
    chainId: 101,
    name: "Bamboo Coin",
    symbol: "BMBO",
    address: "5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19620/large/FC0hnduacAAHYFC.png?1696519049",
    tags: [],
    verified: true,
    holders: 9536,
    extensions: {
      coingeckoId: "bamboo-coin",
    },
  },
  {
    chainId: 101,
    name: "BananaCat (Sol)",
    symbol: "BCAT",
    address: "31GUGbRSct8MsJJnditGy98xMxowq58sFsZ9CGQRMLT8",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34521/large/fMQ5my9v_400x400.jpg?1705302139",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bananacat-sol",
    },
  },
  {
    chainId: 101,
    name: "BANX",
    symbol: "BANX",
    address: "BANXbTpN8U2cU41FjPxe2Ti37PiT5cCxLUKDQZuJeMMR",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36358/large/token_logo.png?1711482179",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "banx",
    },
  },
  {
    chainId: 101,
    name: "BaoBaoSol",
    symbol: "BAOS",
    address: "UHaVCzi4ZsGrzHhAFbokkXfaugfYbkdVME8XYaR7eKu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35666/large/BAOS_Logo_200_x_200.png?1709484982",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "baobaosol",
    },
  },
  {
    chainId: 101,
    name: "BarkCoin",
    symbol: "BARK",
    address: "APkM2bqzpwQaiv5BY2eA7vR7DEB1EX5MMf6JNcBCHYrC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37347/large/photo_2024-04-06_15-42-51.jpg?1714064023",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "barkcoin",
    },
  },
  {
    chainId: 101,
    name: "Basic Attention",
    symbol: "BAT",
    address: "EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/677/large/basic-attention-token.png?1696501867",
    tags: [],
    verified: true,
    holders: 1000,
    extensions: {
      coingeckoId: "basic-attention-token",
    },
  },
  {
    chainId: 101,
    name: "basis.markets",
    symbol: "BASIS",
    address: "Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21444/large/tkQevyc.png?1696520807",
    tags: [],
    verified: true,
    holders: 9598,
    extensions: {
      coingeckoId: "basis-markets",
    },
  },
  {
    chainId: 101,
    name: "batcat",
    symbol: "BTC",
    address: "EtBc6gkCvsB9c6f5wSbwG8wPjRqXMB5euptK6bqG1R4X",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36627/large/photo_2024-03-29_21-53-44.jpg?1712015970",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "batcat",
    },
  },
  {
    chainId: 101,
    name: "Battle of Guardians Share",
    symbol: "BGS",
    address: "At7RLMbA6ZUjj7riyvFq2j5NHQ19aJabCju2VxLDAqso",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22285/large/K3hU77wS_400x400.jpg?1696521632",
    tags: [],
    verified: true,
    holders: 6901,
    extensions: {
      coingeckoId: "battle-of-guardians-share",
    },
  },
  {
    chainId: 101,
    name: "Bazinga",
    symbol: "BAZINGA",
    address: "C3JX9TWLqHKmcoTDTppaJebX2U7DcUQDEHVSmJFz6K6S",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38334/large/BAZINGA.png?1717099434",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bazinga-2",
    },
  },
  {
    chainId: 101,
    name: "BCOQ INU",
    symbol: "BCOQ",
    address: "coqRkaaKeUygDPhuS3mrmrj6DiHjeQJc2rFbT2YfxWn",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36010/large/bcoq.png?1710324434",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bcoq-inu",
    },
  },
  {
    chainId: 101,
    name: "Beercoin",
    symbol: "BEER",
    address: "AujTJJ7aMS8LDo3bFzoyXDwT3jBALUbu4VZhzZdTZLmG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38123/large/beer_%281%29.jpg?1716544500",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "beercoin-2",
    },
  },
  {
    chainId: 101,
    name: "Beluga Cat",
    symbol: "BELUGA",
    address: "E63CDwLy9Dwr3EptAzopV9RuWoQnn5ZVYEjLWnJX8dCw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36108/large/200xbeluga.png?1710484958",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "beluga-cat",
    },
  },
  {
    chainId: 101,
    name: "Ben the Dog",
    symbol: "BENDOG",
    address: "AHW5N8iqZobTcBepkSJzZ61XtAuSzBDcpxtrLG6KUKPk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36994/large/Q1DO0l9F_400x400.jpg?1713002175",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ben-the-dog",
    },
  },
  {
    chainId: 101,
    name: "Besa Gaming Company",
    symbol: "BESA",
    address: "FQMoiNiQGLngizBVdhxqD1o2k6rvcX9ComxjKucgBByH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33283/large/Besa_Gaming_Company_BESA_200.png?1701363231",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "besa-gaming-company",
    },
  },
  {
    chainId: 101,
    name: "Biao Coin",
    symbol: "BIAO",
    address: "3W52uCb8NW8ruMF9mmJX3oKiYAjdPai4633srsZFQCS6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35041/large/biaobiao.png?1707192793",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "biao-coin",
    },
  },
  {
    chainId: 101,
    name: "Bibi",
    symbol: "BIBI",
    address: "7UiTAJhzDcqc5CvVAUWS6sUyeXcQWUXuh7zsbUrNzb8K",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37629/large/bibi_resize.jpg?1715071609",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bibi-2",
    },
  },
  {
    chainId: 101,
    name: "Big Defi Energy",
    symbol: "BDE",
    address: "H5gczCNbrtso6BqGKihF97RaWaxpUEZnFuFUKK4YX3s2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17903/large/bde.png?1696517423",
    tags: [],
    verified: true,
    holders: 9285,
    extensions: {
      coingeckoId: "big-defi-energy",
    },
  },
  {
    chainId: 101,
    name: "Big Floppa",
    symbol: "$FLOPPA",
    address: "BDvE8KUfxbSqmaJQycFSLjKDV51mxX5cKypxh2b8dtHA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34580/large/logo1.png?1705420746",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "big-floppa",
    },
  },
  {
    chainId: 101,
    name: "BiLira",
    symbol: "TRYB",
    address: "A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/10119/large/JBs9jiXO_400x400.jpg?1696510144",
    tags: [],
    verified: true,
    holders: 2258,
    extensions: {
      coingeckoId: "bilira",
    },
  },
  {
    chainId: 101,
    name: "Billion Dollar Inu",
    symbol: "BINU",
    address: "DdWVqFmdQpyjdU3AeUSEhrsnJQBPH7iK9xdCFrBgpAy2",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36094/large/BINULOGO.PNG?1710481749",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "billion-dollar-inu",
    },
  },
  {
    chainId: 101,
    name: "Binance Coin (Wormhole)",
    symbol: "BNB",
    address: "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22884/large/BNB_wh_small.png?1696522182",
    tags: [],
    verified: true,
    holders: 6476,
    extensions: {
      coingeckoId: "binance-coin-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Bingus The Cat",
    symbol: "BINGUS",
    address: "AQuuQ4xktyzGBFnbKHnYsXHxsKVQetAoiPeCEG97NUJw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34391/large/NJy34Zwd_400x400.jpg?1704783819",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bingus-the-cat",
    },
  },
  {
    chainId: 101,
    name: "BiokriptX",
    symbol: "SBKPT",
    address: "BLLbAtSHFpgkSaUGmSQnjafnhebt8XPncaeYrpEgWoVk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38413/large/sbkpt2.png?1717472033",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "biokriptx",
    },
  },
  {
    chainId: 101,
    name: "birb",
    symbol: "BIRB",
    address: "2EBjqPYGLUExdWwJJRLqtGPawzb2aMjE1wTpUYKhy2UQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35762/large/birb.png?1709748416",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "birb-3",
    },
  },
  {
    chainId: 101,
    name: "Bird Dog on SOL",
    symbol: "BIRDDOG",
    address: "3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36861/large/bdogpfp.png?1716581808",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bird-dog-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Birdie",
    symbol: "$BIRDIE",
    address: "C4zNLzmCp5aDkYEpxwcLAdmGTAT3fE3YxA2kTdSRpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38511/large/png.png?1717754155",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "birdie",
    },
  },
  {
    chainId: 101,
    name: "BIRDIES",
    symbol: "BIRDS",
    address: "9m9fmqJ2s7iUXZhFMsrdes1UcAtu3JyZXK3ZXfW3KPEw",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32041/large/1000003575-removebg.png?1696530838",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "birdies",
    },
  },
  {
    chainId: 101,
    name: "Bird.Money",
    symbol: "BIRD",
    address: "FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13260/large/favicon-180x180.png?1696513035",
    tags: [],
    verified: true,
    holders: 314,
    extensions: {
      coingeckoId: "bird-money",
    },
  },
  {
    chainId: 101,
    name: "BITARD",
    symbol: "BITARD",
    address: "2ZZaE2gNQSPZLEFiNzBewgQhj3wGpx4oDzuAawh4DHBB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38045/large/IMG_3413.jpeg?1716351769",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitard",
    },
  },
  {
    chainId: 101,
    name: "BitBrawl",
    symbol: "BRAWL",
    address: "5mdBkZ4dTP94SE7PyiuWseTDAd1kYxSk6oYaWB7186s7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36576/large/200x200.png?1711943384",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitbrawl",
    },
  },
  {
    chainId: 101,
    name: "Bitcat",
    symbol: "BITCAT",
    address: "BSkz8QHwk1DaT3Dcf1L5vWsCRzgrfrLR3qYmwSt1rtUM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36733/large/IMG_0030.jpeg?1712170535",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitcat",
    },
  },
  {
    chainId: 101,
    name: "Bitci DOGE",
    symbol: "BOGE",
    address: "4aDqhS3wbWtCbFeW5mcba7xQzcUoEMS9gaGe9hSHVf9K",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38048/large/DOGE_TOKEN_200.png?1716401036",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitci-doge",
    },
  },
  {
    chainId: 101,
    name: "Bitci PEPE",
    symbol: "BEPE",
    address: "GdHhyqHEJrPzpbfzURrJi8v1uJQRpthfzURqHJtHXyPC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38047/large/bepe_token_200.png?1716400547",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitci-pepe",
    },
  },
  {
    chainId: 101,
    name: "bitcoin puppets solona",
    symbol: "PUPPET",
    address: "6icM9VTZpbBxeLpBCHbSDtoYPmDmENpc8YkyKKWmnAKH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34921/large/PUP.png?1706629926",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitcoin-puppets-solona",
    },
  },
  {
    chainId: 101,
    name: "Bitget Wallet Token",
    symbol: "BWB",
    address: "6FVyLVhQsShWVUsCq2FJRr1MrECGShc3QxBwWtgiVFwK",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37767/large/bwb.png?1715480561",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bitget-wallet-token",
    },
  },
  {
    chainId: 101,
    name: "Bitspawn",
    symbol: "SPWN",
    address: "5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16513/large/token_logo.png?1696516077",
    tags: [],
    verified: true,
    holders: 11953,
    extensions: {
      coingeckoId: "bitspawn",
    },
  },
  {
    chainId: 101,
    name: "BIU COIN",
    symbol: "BIU",
    address: "4D7kEovLBEY3VQUzJUQjXyGR8fAZwQWSxEcsP5Y9nZ6S",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28990/large/cat_%281%29.png?1696527963",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "biu-coin",
    },
  },
  {
    chainId: 101,
    name: "Blackcroc",
    symbol: "BLACKCROC",
    address: "Be6a7UZT4k6yUgQibJczPat8C2qGujEAoje7wY7Pgegr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36747/large/IMG_20240403_142612_461.jpg?1712217813",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "blackcroc",
    },
  },
  {
    chainId: 101,
    name: "BlazeStake Staked SOL",
    symbol: "BSOL",
    address: "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26636/large/blazesolana.png?1696525709",
    tags: [],
    verified: true,
    holders: 1298,
    extensions: {
      coingeckoId: "blazestake-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Block",
    symbol: "BLOCK",
    address: "2zwuFv4tzMrJMA3Gq5nNH3i4ojWPtCkjmGzTMXe4RpMc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34042/large/43770d47-683e-4218-a2e8-1d185d007a64.jpg?1703702447",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "block",
    },
  },
  {
    chainId: 101,
    name: "Blockasset",
    symbol: "BLOCK",
    address: "NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21332/large/Blockasset_Square.png?1712955829",
    tags: [],
    verified: true,
    holders: 10418,
    extensions: {
      coingeckoId: "blockasset",
    },
  },
  {
    chainId: 101,
    name: "BlockDrop",
    symbol: "BDROP",
    address: "DcJLACAUR25RujgxBVhZtcWPCTjzSw6YDM8E7oipiT3k",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37275/large/0482a481-ef1d-4640-86b6-c5457af3d473.JPG?1713894537",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "blockdrop",
    },
  },
  {
    chainId: 101,
    name: "BlockMate",
    symbol: "MATE",
    address: "BC19nju2uKroYRx9pVuPkLVxFxSwtqr8uy5tsni4p78P",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36567/large/BlockMate_Logo.jpg?1711936449",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "blockmate",
    },
  },
  {
    chainId: 101,
    name: "$FORGE",
    symbol: "$FORGE",
    address: "FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25411/large/Logo_%281%29.png?1696524542",
    tags: [],
    verified: true,
    holders: 29410,
    extensions: {
      coingeckoId: "blocksmith-labs-forge",
    },
  },
  {
    chainId: 101,
    name: "Blocto",
    symbol: "BLT",
    address: "BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18657/large/BLT_token.png?1696518127",
    tags: [],
    verified: true,
    holders: 22473,
    extensions: {
      coingeckoId: "blocto-token",
    },
  },
  {
    chainId: 101,
    name: "BLORP",
    symbol: "BLORP",
    address: "4Yssz7bDAa1p2WJH7pPGdonxcj9AHUA4QvG412pyfqN6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34008/large/blorp.png?1703641012",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "blorp",
    },
  },
  {
    chainId: 101,
    name: "BlueFloki",
    symbol: "BLUEFLOKI",
    address: "9zoG39qpxVtkXCuhgDMEV31hsMxGd4d3MfZ16pTFXw5t",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36643/large/200x200.jpg?1712034467",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bluefloki",
    },
  },
  {
    chainId: 101,
    name: "Blue Frog",
    symbol: "BLUEFROG",
    address: "A9ZjTkTXQ43kuoTZLZ3nx55nrBEjoDy1nFVwYCFRAcVk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34005/large/200.png?1703605649",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "blue-frog",
    },
  },
  {
    chainId: 101,
    name: "Blue Whale",
    symbol: "WHALE",
    address: "kub2QX17qMx6jLuyG5gR4kSmmbiRtvUHt4gxqNd4wZB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36134/large/Bluewhale_1.jpg?1710585630",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "blue-whale-2",
    },
  },
  {
    chainId: 101,
    name: "Boba Oppa",
    symbol: "BOBAOPPA",
    address: "bobaM3u8QmqZhY1HwAtnvze9DLXvkgKYk3td3t8MLva",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36548/large/photo_2024-03-30_13.26.25.jpeg?1711781233",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "boba-oppa",
    },
  },
  {
    chainId: 101,
    name: "Bobo on SOL",
    symbol: "BOBO",
    address: "ArRRiz4XsPbLXNCUWb2dqE85hPxD4MtMhec5jaJMcW5R",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35912/large/Bobo_on_SOL.png?1710183408",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bobo-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Bobuki Neko",
    symbol: "BOBUKI",
    address: "2G4i17fv5oCYbPVeEccV4ZZuM1VeJaxoRjRdmF85Lcef",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37846/large/bobuki.png?1715756816",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bobuki-neko",
    },
  },
  {
    chainId: 101,
    name: "Bodge",
    symbol: "BODGE",
    address: "Bq8Q5WZnA6eMizxUkvQRDPKMFiFU7bpp9cx5kTWmJLBd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36555/large/photo_2024-03-19_18-22-34_%281%29.jpg?1711899268",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bodge",
    },
  },
  {
    chainId: 101,
    name: "BOJACK",
    symbol: "$BOJACK",
    address: "9zxKFBkFVcyh46r6pJNjZTtFwFZMuJFqrXsTDn8Lnw7c",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33214/large/wojaklogo.jpeg?1701085200",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bojack",
    },
  },
  {
    chainId: 101,
    name: "Boryoku Dragonz",
    symbol: "BOKU",
    address: "CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20820/large/ZdgsxFPV_400x400.png?1696520213",
    tags: [],
    verified: true,
    holders: 4911,
    extensions: {
      coingeckoId: "boku",
    },
  },
  {
    chainId: 101,
    name: "Bomboclat",
    symbol: "BCLAT",
    address: "2VybYGDqyRBA6a6rgtTn4YJK7veimuZ4iJHAhRjM8Tvh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36256/large/_BCLAT.png?1710927889",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bomboclat",
    },
  },
  {
    chainId: 101,
    name: "Bonfida",
    symbol: "FIDA",
    address: "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13395/large/bonfida.png?1696513157",
    tags: [],
    verified: true,
    holders: 204692,
    extensions: {
      coingeckoId: "bonfida",
    },
  },
  {
    chainId: 101,
    name: "BONG BONK'S BROTHER",
    symbol: "BONG",
    address: "HnJ1rwyEZcSMWjXQX4XruLFWqmqquGdXn9zJsRakQFex",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34429/large/200x200.png?1704867964",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bong-bonk-s-brother",
    },
  },
  {
    chainId: 101,
    name: "BONGO CAT",
    symbol: "BONGO",
    address: "HUdqc5MR5h3FssESabPnQ1GTgTcPvnNudAuLj5J6a9sU",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bongo-cat",
    },
  },
  {
    chainId: 101,
    name: "Bonk",
    symbol: "BONK",
    address: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28600/large/bonk.jpg?1696527587",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonk",
    },
  },
  {
    chainId: 101,
    name: "Bonk 2.0",
    symbol: "BONK 2.0",
    address: "Gh9AcyeZezm5DBvVpG9eSwN1pArtFGNU9q7RzLKkkQ8n",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33741/large/ksQ6InXC_400x400.jpg?1702904601",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonk-2-0",
    },
  },
  {
    chainId: 101,
    name: "Bonk 2.0 (Sol)",
    symbol: "BONK2.0",
    address: "6yiDKPDbqWLGAEBkDvVg6UNrKsLsPVkLbA1TJo4KCdzP",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35814/large/imresizer-1709790961908.jpg?1709885046",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonk-2-0-sol",
    },
  },
  {
    chainId: 101,
    name: "BonkBaby",
    symbol: "BOBY",
    address: "3Dd4fY84cTRWbeJwPDxMNkC8bBHPdFiaNTmsPrmQRcoL",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34478/large/boby_logo200x200.png?1704999277",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonkbaby",
    },
  },
  {
    chainId: 101,
    name: "BonkCola",
    symbol: "BONKCOLA",
    address: "Corri6VKgvE6ZRamtuBpjVdfaaATNrbSpLVuZN1n8773",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33614/large/200x200.png?1702514595",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonkcola",
    },
  },
  {
    chainId: 101,
    name: "BonkEarn",
    symbol: "BERN",
    address: "CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32946/large/bonkearn.jpeg?1699927347",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonkearn",
    },
  },
  {
    chainId: 101,
    name: "BONKLANA",
    symbol: "BOK",
    address: "88tmABf1s3A6jbLD2yLR1tgbGjz68fz3EDrbeSB1JPPc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33809/large/bokLOGO200.png?1703017430",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonklana",
    },
  },
  {
    chainId: 101,
    name: "Bonk of America",
    symbol: "BONKFA",
    address: "DoxsC4PpVHiUxCKYeKSkPXVVVSJYzidZZJxW4XCFF2t",
    decimals: 6,
    logoURI:
      "https://bafybeibh32qfakyjqnxl2p3zyndqmtubjcwkbiimk3qcb6v4wyzekmr5x4.ipfs.nftstorage.link/",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonk-of-america",
    },
  },
  {
    chainId: 101,
    name: "Bonk wif glass",
    symbol: "BONG",
    address: "5n1eL39rUnN2EKbUn1otwGQMPXeQsG7HgdHopYvQifJS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37558/large/f16032a5-3c13-4a5f-a395-7981cb876055.jpeg?1714841006",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonk-wif-glass",
    },
  },
  {
    chainId: 101,
    name: "bonkwifhat",
    symbol: "BIF",
    address: "BgQ2Tj4y4YQume4guQrkK8RWB8oRQtHYtAta1tXubL1G",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35726/large/photo_2024-02-28_16-07-19_%281%29.jpg?1709635605",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bonkwifhat",
    },
  },
  {
    chainId: 101,
    name: "BOO",
    symbol: "$BOO",
    address: "booe3XGMztBMB9RhCgNodhJQeaaMmhdGehMCVPMGza2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34234/large/boo_coin_%28200%29.png?1704306085",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "boo-2",
    },
  },
  {
    chainId: 101,
    name: "BOOK OF AI MEOW",
    symbol: "BOAM",
    address: "5ShSBmDABpQb6QqskjNnyciy4rVh3rtBd8sxY8AZM3HQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38493/large/200X200_LOGO.jpg?1717689720",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-ai-meow",
    },
  },
  {
    chainId: 101,
    name: "BOOK OF BILLIONAIRES",
    symbol: "BOBE",
    address: "VvgeRQNHRHdYubKC9K2GN567LXTzSVcB7mpu7b1VF4r",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37451/large/BOOK_OF_BILLIONAIRES.png?1714448116",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-billionaires",
    },
  },
  {
    chainId: 101,
    name: "BookOfBullrun",
    symbol: "$BOOB",
    address: "BGehTcKraAndWS3B6cq7UVUXQy4uDY49tANEprsTrUo7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36951/large/boob.jpeg?1712898241",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bookofbullrun",
    },
  },
  {
    chainId: 101,
    name: "Book Of Buzz",
    symbol: "BOOBZ",
    address: "8iSrtfxSye6eiaCTRJGY9TCAy3yRUunP174ZwQrknsAj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37213/large/1000107504.png?1713773445",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-buzz",
    },
  },
  {
    chainId: 101,
    name: "Book of Derp",
    symbol: "BODE",
    address: "C7A1EZLszCbjQ8WQ2h2VgZuuqRxXj9yiLcuajbCMrS2W",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36437/large/fffe.png?1711439169",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-derp",
    },
  },
  {
    chainId: 101,
    name: "BOOK OF MEME",
    symbol: "BOME",
    address: "ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36071/large/bome.png?1710407255",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-meme",
    },
  },
  {
    chainId: 101,
    name: "Book of Meme 2.0",
    symbol: "BOME2",
    address: "A6P5p6SdKe1stM9Sv6u6YfqkLjjKZbbiskQWZ4YS72BY",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36200/large/imresizer-1710698324301.png?1710832853",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-meme-2-0",
    },
  },
  {
    chainId: 101,
    name: "Book of Meow",
    symbol: "BOMEOW",
    address: "HXUQvWPWs7BJgzTeX3PyHb8fvFmxvi9Q9hHYzcMVxVbk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36761/large/Bomeow_200_x_200_ver_2.png?1712256586",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-meow",
    },
  },
  {
    chainId: 101,
    name: "Book Of Pumpfluencers",
    symbol: "BOPI",
    address: "D1qQSEBropPHtQza4QN813WNr9pHHQzpZaxjjC7DFAeE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37571/large/AxsyrrO7_400x400.jpg?1714924826",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "book-of-pumpfluencers",
    },
  },
  {
    chainId: 101,
    name: "Boomers on Sol",
    symbol: "BOOMER",
    address: "7jmaTFBooHkaSrBJDftu3LcK85KPtqWTCaFZCDxQV7ZW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37283/large/QkG1H-DE_400x400.jpg?1713944474",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "boomers-on-sol",
    },
  },
  {
    chainId: 101,
    name: "BOOTY",
    symbol: "BOOTY",
    address: "bootyAfCh1eSQeKhFaDjN9Pu6zwPmAoQPoJWVuPasjJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32341/large/avi.png?1697564261",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "booty",
    },
  },
  {
    chainId: 101,
    name: "Bored Ape Social Club",
    symbol: "BAPE",
    address: "BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23734/large/DY4jjsMH_400x400.jpg?1696522937",
    tags: [],
    verified: true,
    holders: 1738,
    extensions: {
      coingeckoId: "bored-ape-social-club",
    },
  },
  {
    chainId: 101,
    name: "Boring Protocol",
    symbol: "BOP",
    address: "BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16828/large/imgonline-com-ua-resize-VT59gqn-Bya-WGG.jpg?1696516396",
    tags: [],
    verified: true,
    holders: 20744,
    extensions: {
      coingeckoId: "boring-protocol",
    },
  },
  {
    chainId: 101,
    name: "Bork",
    symbol: "BORK",
    address: "4jZXkSNgTQKCDb36ECZ6a2aNzcUniGcDeXgTdtM2HxAX",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34094/large/BORK.jpg?1703947837",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bork-2",
    },
  },
  {
    chainId: 101,
    name: "Borzoi",
    symbol: "BORZOI",
    address: "EzgfrTjgFyHGaU5BEBRETyfawt66bAYqJvcryWuJtQ5w",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37528/large/TOKEN.png?1714639543",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "borzoi",
    },
  },
  {
    chainId: 101,
    name: "Bouncing DVD",
    symbol: "DVD",
    address: "9CfNSbybPd36epC9KuQfZdfxVadswHpeFNkf4HBj2tG6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34652/large/1000023244.jpg?1705598702",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bouncing-dvd",
    },
  },
  {
    chainId: 101,
    name: "Bouncing Seals",
    symbol: "SEALS",
    address: "691grMwoYJK4uWV5ga4r6Q3fx8nyAdwWcr3JSsaqfYM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34352/large/IMG_8237.png?1704703833",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bouncing-seals",
    },
  },
  {
    chainId: 101,
    name: "Bozo Collective",
    symbol: "BOZO",
    address: "BoZoQQRAmYkr5iJhqo7DChAs7DPDwEZ5cv1vkYC9yzJG",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34336/large/IMG_9610-1.png?1704679690",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bozo-collective",
    },
  },
  {
    chainId: 101,
    name: "bozo Hybrid",
    symbol: "BOZO",
    address: "EJPtJEDogxzDbvM8qvAsqYbLmPj5n1vQeqoAzj9Yfv3q",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34555/large/bozo.png?1705370111",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bozo-hybrid",
    },
  },
  {
    chainId: 101,
    name: "BPINKY",
    symbol: "BPINKY",
    address: "9vBpdSN7MCP51FfU21HwY8Pwa2ZRxNzbiCP1kR2cCgEe",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36961/large/logo_bpinky_200x200.png?1712907215",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bpinky",
    },
  },
  {
    chainId: 101,
    name: "Bracelet",
    symbol: "BRC",
    address: "E69gzpKjxMF9p7u4aEpsbuHevJK9nzxXFdUDzKE5wK6z",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28866/large/bracelet-token.png?1696527839",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bracelet",
    },
  },
  {
    chainId: 101,
    name: "brainrot",
    symbol: "ROT",
    address: "APoM2sXUzdRHTkUjXSsdUheX1wPPdP4HFLotmtRNMU8P",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37885/large/IMG_1785_%281%29.png?1715841723",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "brainrot",
    },
  },
  {
    chainId: 101,
    name: "Brett",
    symbol: "BRETT",
    address: "DxtssVdyYe4wWE5f5zEgx2NqtDFbVL3ABGY62WCycHWg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33747/large/ogbretttttttt.jpg?1703454425",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "brett",
    },
  },
  {
    chainId: 101,
    name: "Bridgador",
    symbol: "GADOR",
    address: "89BPPewuSzJKLxyWHeTyadnTQURxp1MNSKjNFELpsLjz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34778/large/bridgador-new-logo-1.png?1706149728",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bridgador",
    },
  },
  {
    chainId: 101,
    name: "Bridged MAGA (Wormhole)",
    symbol: "TRUMP",
    address: "HaP8r3ksG76PhQLTqR8FYBeNiQpejcFbQmiHbg787Ut1",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37326/large/maga.jpeg?1714024436",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bridged-maga-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Brix Gaming",
    symbol: "BRIX",
    address: "BriXYhFVy3aXLiuVUfeG6ZMwHvifJLtxSuS5zgozDnAJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33792/large/1000007208.png?1702988985",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "brix-gaming",
    },
  },
  {
    chainId: 101,
    name: "Broccoli The Gangsta",
    symbol: "BROC",
    address: "7khNaohYC7sLtRWR8xE4XL2uXVovr5TNKh3PnLtLiE4v",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36159/large/Broc_logo_200.png?1710743967",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "broccoli-the-gangsta",
    },
  },
  {
    chainId: 101,
    name: "BROKIEINU",
    symbol: "BROKIE",
    address: "4neSyzJmcSWQF58DKHdo7FNzJDDKSgaaQqrzuSXS5U6g",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37827/large/BROKEINU.jpg?1715670419",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "brokieinu",
    },
  },
  {
    chainId: 101,
    name: "Brolana",
    symbol: "BROS",
    address: "4MPD7cGs9746SkZnqhfRigNmpNq17EgTt76YHKC6GEbN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34096/large/BROS.jpg?1703948101",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "brolana",
    },
  },
  {
    chainId: 101,
    name: "Bruv",
    symbol: "BRUV",
    address: "BRUV9DjBeDZRamtWjhzQEH5roosnBApPhfqS1HQ23Xgy",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30247/large/bruvv200rs.png?1696529156",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bruv",
    },
  },
  {
    chainId: 101,
    name: "Brazilian Digital",
    symbol: "BRZ",
    address: "FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/8472/large/MicrosoftTeams-image_%286%29.png?1696508657",
    tags: [],
    verified: true,
    holders: 9868,
    extensions: {
      coingeckoId: "brz",
    },
  },
  {
    chainId: 101,
    name: "Bubba",
    symbol: "BUBBA",
    address: "BMt3pq4g8ggWWBnd6DJ1jhVyTkHfWjAfJwWW6sRCbQJv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37916/large/bubbacoin.png?1715935845",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bubba",
    },
  },
  {
    chainId: 101,
    name: "bul",
    symbol: "BUL",
    address: "8RGy4BZUEkW9dc2UEZ3QHYYdas66X63vazdZJezGJw5e",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36786/large/photo_2024-04-03_18-40-07.png?1712344854",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bul",
    },
  },
  {
    chainId: 101,
    name: "Bull Run Solana",
    symbol: "$BULL",
    address: "7ZYeCVdsXRFfh7TC5JRfBaZxQ6UhF5nNNdNtRzrdsDYF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38112/large/Bull_Run_%281%29.png?1716536310",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bull-run-solana",
    },
  },
  {
    chainId: 101,
    name: "BullVerse",
    symbol: "BULL",
    address: "E3USNPRk8c2sAUjzEisGRgPSBsCDrDG5fCtcyZLHNz3f",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37841/large/BullVerse_White.png?1715743887",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bullverse",
    },
  },
  {
    chainId: 101,
    name: "Bully",
    symbol: "BULLY",
    address: "7qnTXCm7ZVMLdqNWzhfSm3RLKk8MrX3dYavB4hqJv5F9",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36407/large/IMG_5185.PNG?1711719208",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bullysoltoken",
    },
  },
  {
    chainId: 101,
    name: "Buna Games",
    symbol: "BUNA",
    address: "4tmUm2yD2fDpHDfmCvmqaMJCuoKU54uj7xnSgxQCembW",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37250/large/BunaGames.jpg?1713862570",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "buna-games",
    },
  },
  {
    chainId: 101,
    name: "bunicoin",
    symbol: "BUNI",
    address: "7y1TrdzE1cEeCgBvgdNB9DViMYdQ7UU2FKhnPDLYa7ae",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38106/large/buni_%281%29.jpg?1716532407",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bunicoin",
    },
  },
  {
    chainId: 101,
    name: "Bunkee",
    symbol: "BUNK",
    address: "2nhjjqSkA8FYCUdJvQhYjbtZdPjZbNo8VtNKTkJ3hncb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36794/large/Bun_8.png.jpg?1712433745",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bunkee",
    },
  },
  {
    chainId: 101,
    name: "BUNNY MEV BOT",
    symbol: "BUNNY",
    address: "86moLTVsCsS1C6JrYTcWT7wfH21pCff2CgU1vjPmdzHd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36998/large/bunny_logo_%282%29.png?1713032405",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "bunny-mev-bot",
    },
  },
  {
    chainId: 101,
    name: "BURRRD",
    symbol: "BURRRD",
    address: "F8qtcT3qnwQ24CHksuRrSELtm5k9ob8j64xAzj3JjsMs",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34068/large/Captura_de_ecr%C3%A3_2023-12-26_182935.png?1703821353",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "burrrd",
    },
  },
  {
    chainId: 101,
    name: "Byat",
    symbol: "BYAT",
    address: "BYATmZ7ry2pewxW3213sczJYB7ZJzPr921uvcRcJYYZQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35756/large/Untitled_design_%2897%29.png?1709723899",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "byat",
    },
  },
  {
    chainId: 101,
    name: "Byte",
    symbol: "BYTE",
    address: "ArGfk9JJ72QETcmckxVjCZ9ApuATgP1mf9ysVHnPU4ut",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33527/large/Byte200.jpeg?1702138460",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "byte",
    },
  },
  {
    chainId: 101,
    name: "Cabal",
    symbol: "CABAL",
    address: "F9TBTkUELa39d8mpVvJDdcE7VrAcEGgzqBy5xoregCke",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34044/large/dexscreeen_%281%29.png?1703704059",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cabal",
    },
  },
  {
    chainId: 101,
    name: "cadog",
    symbol: "CDG",
    address: "cadogUUCozqbM8CdLxrLE3FveE2pqvC97xtLRSzY8E2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37698/large/CADOG.jpg?1715235534",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cadog",
    },
  },
  {
    chainId: 101,
    name: "Caitlyn Jenner",
    symbol: "JENNER",
    address: "4GJ3TCt5mTgQT5BRKb14AkjddpFQqKVfphxzS3t4foZ9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38239/large/JENNER.jpg?1716868799",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "caitlyn-jenner",
    },
  },
  {
    chainId: 101,
    name: "Calm Bear on Solana",
    symbol: "CHILN",
    address: "2DfEnQrC6EVnhA3wGXiQ8UoBtEEmStsW6oNT6izn6AeH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37915/large/CHILN.png?1718002684",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "calm-bear-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Candle Cat",
    symbol: "CANDLE",
    address: "6iFUKWGDksVvmnSYJUGYnsu168xstni8xJkTF7QrpPAu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36927/large/peR8JtYy_400x400.jpg?1712802581",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "candle-cat",
    },
  },
  {
    chainId: 101,
    name: "Canwifhat",
    symbol: "CAN",
    address: "FF4dN8Qy8NNF88HRgMA3TkbRVZ8PTXWXZCZJb59X3Sbg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36390/large/Untitled_design%2871%29.png?1711356137",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "canwifhat",
    },
  },
  {
    chainId: 101,
    name: "Capo Was Right",
    symbol: "CWR",
    address: "3hkAy2qcNJaxS3KebjNdugYbgakLvUZHRGZyRmGjSsbm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35926/large/photo_2024-03-07_13-50-29.jpg?1710224871",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "capo-was-right",
    },
  },
  {
    chainId: 101,
    name: "Caprisun Monkey",
    symbol: "CAPRI",
    address: "JD3S1oqnjG5trRTZXpLmKnS7LsKsppyFa51rPBBMWogv",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37784/large/Untitled_Artwork_%281%29.png?1715585959",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "caprisun-monkey",
    },
  },
  {
    chainId: 101,
    name: "Capybara",
    symbol: "CAPY",
    address: "CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22000/large/logo_-_2021-12-27T143844.581.png?1696521347",
    tags: [],
    verified: true,
    holders: 1022,
    extensions: {
      coingeckoId: "capybara",
    },
  },
  {
    chainId: 101,
    name: "Cartel Coin",
    symbol: "CARTEL",
    address: "55paRYJNk2hcjjuAKEACiZdaCiYz1okeuWq1cWit9Ket",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34039/large/Cartel_Coin.jpg?1703693679",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cartel-coin-2",
    },
  },
  {
    chainId: 101,
    name: "Catalina Whales Index",
    symbol: "WHALES",
    address: "DSXWF79VQ3RzFBB67WeNfCzfzAQq5X6m97zi85bq1TRx",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27053/large/whales.png?1696526104",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catalina-whales-index",
    },
  },
  {
    chainId: 101,
    name: "Catcoin",
    symbol: "CAT",
    address: "7hWcHohzwtLddDUG81H2PkWq6KEkMtSDNkYXsso18Fy3",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25279/large/cat5.png?1696524419",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catcoin-cash",
    },
  },
  {
    chainId: 101,
    name: "Catfish",
    symbol: "CATFISH",
    address: "AcY6RWuWKM5NU7BeSE4c4zQxBZpyU7pTLNF3g5DTWUC2",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35157/large/CatFish_Trans.png?1707564230",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catfish",
    },
  },
  {
    chainId: 101,
    name: "Cat getting fade",
    symbol: "CGF",
    address: "CkW5tFrQiGVoKcYxxpKNLLUvTXxnpY6qc43TwyUvxYoB",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36647/large/100x100.png?1714241338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cat-getting-fade",
    },
  },
  {
    chainId: 101,
    name: "CatGPT",
    symbol: "CATGPT",
    address: "FGf1Us3kqu9AXu2x1yWKfiKE8uSx42ACvRiUrbuAodzq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37350/large/Screenshot_2024-04-26_at_9.01.48_AM.png?1714093334",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catgpt",
    },
  },
  {
    chainId: 101,
    name: "cat in a dogs world",
    symbol: "MEW",
    address: "MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36440/large/MEW.png?1711442286",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cat-in-a-dogs-world",
    },
  },
  {
    chainId: 101,
    name: "Cat in Hoodie",
    symbol: "HODI",
    address: "HodiZE88VH3SvRYYX2fE6zYE6SsxPn9xJUMUkW1Dg6A",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37971/large/1000055606.jpg?1716193123",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cat-in-hoodie",
    },
  },
  {
    chainId: 101,
    name: "Catino",
    symbol: "CATINO",
    address: "1A3CZ3b8f878LfqpT3stoiDC7C1EDMjda6A1xHakkMF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36655/large/catino-png_%28200%29.png?1712041708",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catino",
    },
  },
  {
    chainId: 101,
    name: "Catman",
    symbol: "CATMAN",
    address: "EavJDLh8cYTAnt3QDitpKGMsPL2hq1My5g9R2P6at6Lc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34730/large/Logo_-_Yellow_CATMAN.png?1705996178",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catman",
    },
  },
  {
    chainId: 101,
    name: "CATO",
    symbol: "CATO",
    address: "5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17031/large/CATO200newlogo.png?1696516594",
    tags: [],
    verified: true,
    holders: 18878,
    extensions: {
      coingeckoId: "cato",
    },
  },
  {
    chainId: 101,
    name: "Cat of ELON",
    symbol: "ELONCAT",
    address: "694eHngoKMKY5attHLsFh5M1m7zMYCERxsNPBmvMgNJp",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36872/large/Cat_of_ELON.png?1712646251",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cat-of-elon",
    },
  },
  {
    chainId: 101,
    name: "CATOUR",
    symbol: "CATOUR",
    address: "C94en1fg5A5JXQ12b6i5XK1kuoUvA3v86vve9gbEcMJs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37903/large/photo_2024-05-15_09-36-48.jpg?1715897995",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catour",
    },
  },
  {
    chainId: 101,
    name: "catownkimono",
    symbol: "COK",
    address: "Dnb9dLSXxAarXVexehzeH8W8nFmLMNJSuGoaddZSwtog",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38236/large/COK_bg200x200.png?1716862618",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catownkimono",
    },
  },
  {
    chainId: 101,
    name: "Catscoin",
    symbol: "CATS",
    address: "7dr2iYb1Xq29H13RXW1wHhrAqbYF39SDXAU5nGFTpNmU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36780/large/Catscoin_logo_200x200.png?1712311139",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catscoin-2",
    },
  },
  {
    chainId: 101,
    name: "Cats Of Sol",
    symbol: "COS",
    address: "3obNTknsf4hq5pNh3GDPEhyCB9WTKJJzM2PcqfKgLmBu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36563/large/IMG_8617.jpeg?1711906100",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cats-of-sol",
    },
  },
  {
    chainId: 101,
    name: "CatSolHat",
    symbol: "SOLCAT",
    address: "E99fN4tCRb1tQphXK1DU7prXji6hMzxETyPNJro19Fwz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38006/large/token-resized.png?1716270480",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catsolhat",
    },
  },
  {
    chainId: 101,
    name: "Catster",
    symbol: "CATSTR",
    address: "3eK9Hp78fLYrhvhAMsakPYZ38sm8NfQj5znQoGWHHzQC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37968/large/logo_200x200.png?1716191829",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catster",
    },
  },
  {
    chainId: 101,
    name: "cats wif hats in a dogs world",
    symbol: "MEWSWIFHAT",
    address: "FU91J7WqdpDC7x3XWZ8kHYvcJfvLZ5wz2CeMvyosZS8m",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37252/large/cats_wif_hats_in_a_dogs_world.png?1713863765",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cats-wif-hats-in-a-dogs-world",
    },
  },
  {
    chainId: 101,
    name: "Catvax",
    symbol: "CATVAX",
    address: "EVDoPXkWNRMc7fQirg7emNdc17KNqsCyzVi4mWPmDvni",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36589/large/Cv45Y7Ue_400x400.jpg?1711948641",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catvax",
    },
  },
  {
    chainId: 101,
    name: "catwifbag",
    symbol: "BAG",
    address: "D8r8XTuCrUhLheWeGXSwC3G92RhASficV3YA7B2XWcLv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36122/large/Safeimagekit-resized-img_%281%29.png?1710496069",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catwifbag",
    },
  },
  {
    chainId: 101,
    name: "CatwifHat",
    symbol: "CIF",
    address: "G3vWvAaXPHCnncnyAbq6yBRXqfRtEV3h7vExzasZeT6g",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33787/large/photo1702849323.jpeg?1702981844",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catwifhat",
    },
  },
  {
    chainId: 101,
    name: "catwifhat",
    symbol: "$CWIF",
    address: "7atgF8KQo4wJrD5ATGX7t1V2zVvykPJbFfNeVf1icFv1",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35267/large/download_%283%29.png?1708021220",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catwifhat-2",
    },
  },
  {
    chainId: 101,
    name: "CatWifHat",
    symbol: "CATWIF",
    address: "Avb1PBRudW7uUV9MqTUqfZ3EZTDvNkKS63W3wyPseudf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36709/large/Catwif_Logo.png?1712127006",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catwifhat-3",
    },
  },
  {
    chainId: 101,
    name: "CATWIFMELON",
    symbol: "MELON",
    address: "921a1GKnx3B4jSEc8M9dzT9j7oQAuioF8ejKQBGNzFQ4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34819/large/melon.jpg?1706177223",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "catwifmelon",
    },
  },
  {
    chainId: 101,
    name: "CaveWorld",
    symbol: "CAVE",
    address: "4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19358/large/token.png?1696518798",
    tags: [],
    verified: true,
    holders: 15170,
    extensions: {
      coingeckoId: "cave",
    },
  },
  {
    chainId: 101,
    name: "Ceiling Cat",
    symbol: "CEICAT",
    address: "8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36623/large/CEICAT_profile_pic.png?1711981608",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ceiling-cat",
    },
  },
  {
    chainId: 101,
    name: "Central Bank Digital Currency Memecoin",
    symbol: "CBDC",
    address: "2KFZCkfxj1Us8YRQZa5vktSxy3GPzFZvVhWj91n8Fv2J",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35283/large/CBDC_Logo.png?1708055112",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "central-bank-digital-currency-memecoin",
    },
  },
  {
    chainId: 101,
    name: "CetCoinSOL",
    symbol: "CET",
    address: "59Fb2SUL8YCz3d75hm5B1ibDgkUk4gj92c1nAQCdm2k8",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37242/large/cet.jpeg?1713859837",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cetcoinsol",
    },
  },
  {
    chainId: 101,
    name: "Cetes",
    symbol: "CETES",
    address: "CETES7CKqqKQizuSN6iWQwmTeFRjbJR6Vw2XRKfEDR8f",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37855/large/cetes.png?1715788161",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cetes",
    },
  },
  {
    chainId: 101,
    name: "Chadimir Putni",
    symbol: "PUTNI",
    address: "9y4zGixmVFGj47RCtbjgdjqWRASnp2HM1X8t7SHoM3HA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36186/large/Chadimir.jpeg?1710760083",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chadimir-putni",
    },
  },
  {
    chainId: 101,
    name: "Chad On Solana",
    symbol: "CHAD",
    address: "G7rwEgk8KgQ4RUTnMy2W2i7dRDq4hXHD4CSp9PSmSbRW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32738/large/chad.png?1699228434",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chad-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Chad Scanner",
    symbol: "CHAD",
    address: "EeVNHYW1JbevmjhQQnNmoakfzmjDNKZEUatCpehgVxBU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35696/large/Chad_Scanner.jpeg?1714530487",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chad-scanner",
    },
  },
  {
    chainId: 101,
    name: "ChainPulse",
    symbol: "CP",
    address: "6VXq8brmqKJ2whzpmPypMvrzitgmYYE3bvjrVdXsYLCR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36019/large/Chain_Pulse_Logo.png?1710336081",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chainpulse",
    },
  },
  {
    chainId: 101,
    name: "Chambs",
    symbol: "CHAMBS",
    address: "DHrxTdALXjj4RmyGkoEiKUmkZoDRNDGMTV2oXSQyebfv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38037/large/logo-chambs-1.png?1716328614",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chambs",
    },
  },
  {
    chainId: 101,
    name: "Charactbit",
    symbol: "CHB",
    address: "YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24863/large/200x_logo.png?1696524022",
    tags: [],
    verified: true,
    holders: 7654,
    extensions: {
      coingeckoId: "charactbit",
    },
  },
  {
    chainId: 101,
    name: "Chatni",
    symbol: "CHATNI",
    address: "6CnDgbCpgeVhHWujctEwSLpVcGTZvGzR6eWh2VvB2nNo",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chatni",
    },
  },
  {
    chainId: 101,
    name: "CheckDot",
    symbol: "CDT",
    address: "Ak3ovnWQnAxPSFoSNCoNYJLnJtQDCKRBH4HwhWkb6hFm",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20370/large/token-200x200_%281%29.png?1696519781",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "checkdot",
    },
  },
  {
    chainId: 101,
    name: "Chedda",
    symbol: "CHEDDA",
    address: "GLiB37nqnPDghvVHFS9CJ21c5BDUQxSJS3BXBWduVoPm",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36595/large/IMG_9885.jpeg?1711949819",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chedda-2",
    },
  },
  {
    chainId: 101,
    name: "CHEEKS",
    symbol: "CHEEKS",
    address: "6e6rViDzavLRv56nvZye5UofrKDg36mf6dTQrMCoPTW9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38026/large/srnMcTCa_400x400.jpg?1716317548",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cheeks",
    },
  },
  {
    chainId: 101,
    name: "Cheems",
    symbol: "CHEEMS",
    address: "3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18358/large/newlogo.png?1696517850",
    tags: [],
    verified: true,
    holders: 20285,
    extensions: {
      coingeckoId: "cheems",
    },
  },
  {
    chainId: 101,
    name: "cheepepe",
    symbol: "CHEEPEPE",
    address: "FwBixtdcmxawRFzBNeUmzhQzaFuvv6czs5wCQuLgWWsg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37578/large/cheepepe-token.png?1714962563",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cheepepe",
    },
  },
  {
    chainId: 101,
    name: "Cheese",
    symbol: "CHEESE",
    address: "AbrMJWfDVRZ2EWCQ1xSCpoVeVgZNpq1U2AoYG98oRXfn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37156/large/cheese_icon_500x500.png?1713499473",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cheese-2",
    },
  },
  {
    chainId: 101,
    name: "Cheezburger Cat",
    symbol: "CHEEZ",
    address: "9wV5ix8WSWLpdpLe5BSSwUJhYptShHfiwBxdYorBkFqi",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36653/large/cat200x200.png?1712041098",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cheezburger-cat",
    },
  },
  {
    chainId: 101,
    name: "Chengshi",
    symbol: "CHENG",
    address: "BEsnQvkfeaQ3G8qbeN5FmUnsQUqXzXW5i83Hv3syoTTc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37759/large/logo772.jpg?1715473529",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chengshi",
    },
  },
  {
    chainId: 101,
    name: "CHEXBACCA",
    symbol: "CHEXBACCA",
    address: "8J5e2FPmBJ1subEUeVkELpeBZv9aYUrMmfHys7sREeXr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37110/large/chuba_%281%29.jpg?1713327173",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chexbacca",
    },
  },
  {
    chainId: 101,
    name: "CHEX Token",
    symbol: "CHEX",
    address: "6dKCoWjpj5MFU5gWDEFdpUUeBasBLK3wLEwhUzQPAa1e",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/10349/large/logo-dark-200.png?1701121978",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chex-token",
    },
  },
  {
    chainId: 101,
    name: "chibi",
    symbol: "CHIBI",
    address: "HfXLMuSCHvsuYHRLekyW88oiz1LJvRerKh4QH1y5f8xk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37572/large/Chibi.png?1714925850",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chibi",
    },
  },
  {
    chainId: 101,
    name: "Chief Troll Grok",
    symbol: "CTG",
    address: "2XwjRameUkDEWA9RxFHSsbZDRh1H9xAJogkVUpW2LU8a",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35660/large/Untitled_design_%2827%29.png?1709448886",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chief-troll-grok",
    },
  },
  {
    chainId: 101,
    name: "ChihuahuaSol",
    symbol: "CHIH",
    address: "6xtyNYX6Rf4Kp3629X11m1jqUmkV89mf9xQakUtUQfHq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20528/large/rsz_chihuahua-token.png?1696519933",
    tags: [],
    verified: true,
    holders: 5037,
    extensions: {
      coingeckoId: "chihuahuasol",
    },
  },
  {
    chainId: 101,
    name: "Chiitan",
    symbol: "CHIITAN",
    address: "CEeeVEznH4bpZDyNPkR9BhrkrKHk8Fh3j794BKEQcRF3",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36956/large/chiitan.jpeg?1712903282",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chiitan",
    },
  },
  {
    chainId: 101,
    name: "ChikinCoin",
    symbol: "CKC",
    address: "8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24978/large/Screenshot_from_2022-04-11_15-47-44.png?1696524131",
    tags: [],
    verified: true,
    holders: 32227,
    extensions: {
      coingeckoId: "chikincoin",
    },
  },
  {
    chainId: 101,
    name: "CHILI",
    symbol: "CHILI",
    address: "GPyzPHuFFGvN4yWWixt6TYUtDG49gfMdFFi2iniTmCkh",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28668/large/CHILI_start.png?1696527652",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chili",
    },
  },
  {
    chainId: 101,
    name: "Nana",
    symbol: "NANA",
    address: "6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22407/large/200x200.png?1696521750",
    tags: [],
    verified: true,
    holders: 30081,
    extensions: {
      coingeckoId: "chimp-fight",
    },
  },
  {
    chainId: 101,
    name: "Chinu",
    symbol: "CHINU",
    address: "FLrgwxXaX8q8ECF18weDf3PLAYorXST5orpY34d8jfbm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34610/large/chinuHeadLogo200.png?1705499604",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chinu-2",
    },
  },
  {
    chainId: 101,
    name: "CHIPI",
    symbol: "CHIPI",
    address: "chiPiQTvkQ7oPtAD7YLQaEeHmPqXCa2wcRQdwFNneTe",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33936/large/607bdffb-f88b-445f-975a-d21b073aebf1-1704710196625.jpg?1705759804",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chipi",
    },
  },
  {
    chainId: 101,
    name: "CHONKY",
    symbol: "CHONKY",
    address: "H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33362/large/gGjlG9d6_400x400.jpg?1704364434",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chonky",
    },
  },
  {
    chainId: 101,
    name: "ChooseRich",
    symbol: "RICH",
    address: "4XF6arXUcBHyEBB7ZyRFSSZ4pNFfEYvJM7jg4vVwGrBD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35991/large/cr.jpeg?1710315266",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chooserich",
    },
  },
  {
    chainId: 101,
    name: "CHRISCHAN",
    symbol: "CHCH",
    address: "AwPgCUmpzvLQV2AXHSKCJuVcGWze9PfFmhjoSujtomwD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36181/large/image00001.png?1710757854",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chrischan",
    },
  },
  {
    chainId: 101,
    name: "Chuchu",
    symbol: "CHUCHU",
    address: "ELST1oAp3czkRRRWGeTgttGsEqkD2qjDRJXR4Asmkk5y",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35344/large/msg6139495445-53396.jpg?1710425614",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chuchu",
    },
  },
  {
    chainId: 101,
    name: "Chudjak",
    symbol: "CHUD",
    address: "6yjNqPzTSanBWSa6dxVEgTjePXBrZ2FoHLDQwYwEsyM6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37739/large/chud_avatar.png?1715357823",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chudjak",
    },
  },
  {
    chainId: 101,
    name: "Chump Change",
    symbol: "CHUMP",
    address: "HJ8WWpsheTMKwuoFkvEuhAzdqqUTgqdS7JVR37rxgnFS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37957/large/1000027926.jpg?1716096795",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "chump-change",
    },
  },
  {
    chainId: 101,
    name: "Cirque Du Sol",
    symbol: "CIRC",
    address: "DUnizDfS13sRVHAFBjDkRW5LAQB7KfAwCGgEGmWmQUCE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36778/large/IMG_0528.png?1712309123",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cirque-du-sol",
    },
  },
  {
    chainId: 101,
    name: "ClinTex CTi",
    symbol: "CTI",
    address: "9ET2QCQJdFkeKkuaampNbmicbA8eLYauFCWch9Ddh9p5",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13266/large/CTI.png?1696513039",
    tags: [],
    verified: true,
    holders: 168,
    extensions: {
      coingeckoId: "clintex-cti",
    },
  },
  {
    chainId: 101,
    name: "Clippy",
    symbol: "CLIPPY",
    address: "4LjqYcRbhvmXeGnx2uXA9meTvdF232KAKjNAfFDM6nuj",
    decimals: 5,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "clippy",
    },
  },
  {
    chainId: 101,
    name: "Cloned Aptos",
    symbol: "CLAPT",
    address: "HHncifGW3yJyaW2fRRfBYAawnD9ogbsWM5PccFA4GHSx",
    decimals: 8,
    logoURI: "https://markets.clone.so/images/assets/on-apt.svg",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cloned-aptos",
    },
  },
  {
    chainId: 101,
    name: "Cloned Dogecoin",
    symbol: "CLDOGE",
    address: "Dd6Pde7K4J7yB691gW8K177TWd1Huy6RkfTwrbkz8Fre",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36363/large/dogecoin-doge-logo.png?1711335928",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cloned-dogecoin",
    },
  },
  {
    chainId: 101,
    name: "Cloned Sui",
    symbol: "CLSUI",
    address: "JzwfZvJGdsqbrKZQUvzJpWhbHcZUix7CYcCaoiNpjxg",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35671/large/sui-sui-logo.png?1709487717",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cloned-sui",
    },
  },
  {
    chainId: 101,
    name: "COBAN",
    symbol: "COBAN",
    address: "7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19483/large/coban-logo-pebyyxjzxvebwa8nsvpp9t8iple69h6enp9bdknotq.png?1696518919",
    tags: [],
    verified: true,
    holders: 1683,
    extensions: {
      coingeckoId: "coban",
    },
  },
  {
    chainId: 101,
    name: "COC",
    symbol: "COC",
    address: "cocvP5K8DsDYbJkRGasSg69xWFenrVVdBLLKjJKPJSo",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36119/large/IMG_20240314_082835_584.jpg?1710494714",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "coc",
    },
  },
  {
    chainId: 101,
    name: "Cogent SOL",
    symbol: "CGNTSOL",
    address: "CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29353/large/cgntSOL.png?1696528301",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cogent-sol",
    },
  },
  {
    chainId: 101,
    name: "Coin98",
    symbol: "C98",
    address: "C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17117/large/logo.png?1696516677",
    tags: [],
    verified: true,
    holders: 9839,
    extensions: {
      coingeckoId: "coin98",
    },
  },
  {
    chainId: 101,
    name: "Coin98 Dollar",
    symbol: "CUSD",
    address: "CUSDvqAQLbt7fRofcmV2EXfPA2t36kzj7FjzdmqDiNQL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26588/large/CUSD-01.png?1696525663",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "coin98-dollar",
    },
  },
  {
    chainId: 101,
    name: "Coin In Meme World",
    symbol: "COMEW",
    address: "GETywTqcUz9n2a6gi4DrSE8aPJWESmNp1QgAbbkQhLnH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37298/large/photo_2024-04-22_22.36.14.png?1713951216",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "coin-in-meme-world",
    },
  },
  {
    chainId: 101,
    name: "Colle AI",
    symbol: "COLLE",
    address: "AFDzaLz3cQZNWjnWbyq2q81TLVTPbHTbfkj8qKqTk74e",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36676/large/E9Yq4waZ.png?1712062887",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "colle-ai",
    },
  },
  {
    chainId: 101,
    name: "Community of Meme",
    symbol: "COME",
    address: "GmW12mAzyTj897Y3pgxDQzpnNid7q58E8T7V56rmaUdD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36266/large/come.png?1710953852",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "community-of-meme",
    },
  },
  {
    chainId: 101,
    name: "Community Takeover",
    symbol: "CT",
    address: "C1kfzj6Qwn9FL8rYRQ5V7bsfATMxXcTi399j4WGD4ctC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35169/large/CT_Official_Logo_2_1400x1400.png?1707666488",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "community-takeover",
    },
  },
  {
    chainId: 101,
    name: "Compendium",
    symbol: "CMFI",
    address: "5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22269/large/CMFI-Token-Logo-Compendium-Finance.png?1696521613",
    tags: [],
    verified: true,
    holders: 4309,
    extensions: {
      coingeckoId: "compendium-fi",
    },
  },
  {
    chainId: 101,
    name: "CONAN",
    symbol: "CONAN",
    address: "7XU84evF7TH4suTuL8pCXxA6V2jrE8jKA6qsbUpQyfCY",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34800/large/CONAN.png?1706074585",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "conan",
    },
  },
  {
    chainId: 101,
    name: "ConstitutionDAO (Wormhole)",
    symbol: "PEOPLE",
    address: "CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23009/large/PEOPLE_wh_small.png?1696522304",
    tags: [],
    verified: true,
    holders: 1007,
    extensions: {
      coingeckoId: "constitutiondao-wormhole",
    },
  },
  {
    chainId: 101,
    name: "contract address (Meme)",
    symbol: "CA",
    address: "AfkUkcoJ5Yt7eU9BwnF1RjRqt4fQG5zYV1eS1ytDk7FE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33991/large/photo_2023-12-25_23-55-15.jpg?1703589583",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "contract-address-meme",
    },
  },
  {
    chainId: 101,
    name: "Contractus",
    symbol: "CTUS",
    address: "4yeK2cCyxNNz28fVqEbkLYNCb1T9qPadsuJWeZ44Evcq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31300/large/logo-200-200.png?1696530120",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "contractus",
    },
  },
  {
    chainId: 101,
    name: "$COOK",
    symbol: "COOK",
    address: "9NSn27Aq8wBpKkYJbsZFdfP4D78iU4dAwu6H2E5xz5Rn",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cook-2",
    },
  },
  {
    chainId: 101,
    name: "Cook Cat",
    symbol: "CCAT",
    address: "71qkaXcSjeNBDHzUh1X87zGW8GqgdFM4S4j5nAeuMSpZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37620/large/1000004192.jpg?1715063512",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cook-cat",
    },
  },
  {
    chainId: 101,
    name: "Cookie Cat Game",
    symbol: "CATGAME",
    address: "A5XWXdp6EKvh1HRkCh7aSY7twJskor5xWkzZ6pWHV2hN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37928/large/1000115031.png?1715938323",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cookie-cat-game",
    },
  },
  {
    chainId: 101,
    name: "CoolCoin",
    symbol: "COOL",
    address: "BMwhQ1HXuzA7ucY8NYHyVq2uwuG47GHj1dNP6xJdoPzD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37454/large/IMG_5565.jpeg?1714448890",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "coolcoin",
    },
  },
  {
    chainId: 101,
    name: "Cope",
    symbol: "COPE",
    address: "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14567/large/COPE.png?1696514249",
    tags: [],
    verified: true,
    holders: 62184,
    extensions: {
      coingeckoId: "cope",
    },
  },
  {
    chainId: 101,
    name: "Cope Token",
    symbol: "COPE",
    address: "o1Mw5Y3n68o8TakZFuGKLZMGjm72qv4JeoZvGiCLEvK",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28717/large/CX_TK_SOLANA_COLOR_%283%29.png?1696873155",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cope-token",
    },
  },
  {
    chainId: 101,
    name: "CoreStarter",
    symbol: "CSTR",
    address: "G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20209/large/cstr.png?1696519619",
    tags: [],
    verified: true,
    holders: 20137,
    extensions: {
      coingeckoId: "corestarter",
    },
  },
  {
    chainId: 101,
    name: "CorgiAI",
    symbol: "CORGIAI",
    address: "79F32BvHBE49gPsvypYTGzcpWGvt66mgvenQow3mJjXu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30933/large/Token.png?1696529775",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "corgiai",
    },
  },
  {
    chainId: 101,
    name: "CORN DOG",
    symbol: "CDOG",
    address: "FPBZ6A3YfcFXLpq9pEmwrNokexQn7yfMffyfwoxoXRiJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36665/large/IMG_0687.jpeg?1712107371",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "corn-dog",
    },
  },
  {
    chainId: 101,
    name: "Cost Hot Dog",
    symbol: "COST",
    address: "Av6qVigkb7USQyPXJkUvAEm4f599WTRvd75PUWBA9eNm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36995/large/cost.jpeg?1713007240",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "costco-hot-dog",
    },
  },
  {
    chainId: 101,
    name: "Could Be The Move",
    symbol: "CBTM",
    address: "FpZWLF31ymwJQNKwdhxAog9HwFEJGWjk6JqQUWYnuHry",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34529/large/LOGO_1000x1000.png?1705307043",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "could-be-the-move",
    },
  },
  {
    chainId: 101,
    name: "Cozy Pepe",
    symbol: "COZY",
    address: "cozyLxNaoJvQ3KB5dCJdu7MoZiBpwBWGdvc4dkMXnqA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37340/large/cozy-pepe-dp_copy.png?1714051958",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cozy-pepe",
    },
  },
  {
    chainId: 101,
    name: "CrazyBunny",
    symbol: "CRAZYBUNNY",
    address: "2ncKzZN6b2GbwWZVxQ4Q7nRQZbzqaR61MXrp4J7VE4Zj",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35656/large/Comp_122%D0%B0%D0%B0.png?1709395964",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crazybunny",
    },
  },
  {
    chainId: 101,
    name: "CrazyPepe",
    symbol: "CRAZYPEPE",
    address: "E7ENfG7EA1oGAtgZiaL8YL3THoSwu72LbsYmpN6n5ZXw",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36504/large/UqWXlutR_400x400.jpg?1711619328",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crazypepe-2",
    },
  },
  {
    chainId: 101,
    name: "Creamy",
    symbol: "CREAMY",
    address: "CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25285/large/logo200.png?1696524424",
    tags: [],
    verified: true,
    holders: 3519,
    extensions: {
      coingeckoId: "creamy",
    },
  },
  {
    chainId: 101,
    name: "Cricket Star Manager",
    symbol: "CSM",
    address: "EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25774/large/CSM_token.png?1696524861",
    tags: [],
    verified: true,
    holders: 20310,
    extensions: {
      coingeckoId: "cricket-star-manager",
    },
  },
  {
    chainId: 101,
    name: "Cris Hensan",
    symbol: "SEAT",
    address: "29ad8JW1YcVT3mxcvxJpe3EzWqXCGD7KaSRWrc3TEMWG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38300/large/Gecko.png?1717031080",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cris-hensan",
    },
  },
  {
    chainId: 101,
    name: "croc cat",
    symbol: "CROC",
    address: "5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34701/large/croc_cat_OG_200px_x_200px_png.png?1716656908",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "croc-cat",
    },
  },
  {
    chainId: 101,
    name: "Crocdog",
    symbol: "CROCDOG",
    address: "GumHxsx6Kan7R1BNAEEtRMhZJVULeeZGwMGPcjT162Sp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35915/large/16_copy.png?1710186021",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crocdog",
    },
  },
  {
    chainId: 101,
    name: "Crochet World",
    symbol: "CROCHET",
    address: "Aqjju8gCv3Uc2XsmF5x92ZarDo3hCnP7EgUeDkv1i7jK",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35528/large/Photoroom_20240226_184429.png?1709031846",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crochet-world",
    },
  },
  {
    chainId: 101,
    name: "Croco",
    symbol: "$CROCO",
    address: "G8cEYL7MhmWAUiozQbtwrZA7N224QBXu2nDN22xfMhFA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35120/large/CROCO_%283%29.png?1707410022",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "croco",
    },
  },
  {
    chainId: 101,
    name: "Crodie",
    symbol: "CRODIE",
    address: "GvcNXdSehfNSNyhDVDj27kc459LzFqWozt9CSJywMy6r",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34829/large/telegram-peer-photo-size-4-5875179807258558127-1-0-0_1.png?1706196775",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crodie",
    },
  },
  {
    chainId: 101,
    name: "CRONK",
    symbol: "CRONK",
    address: "EZgZu4D9MNyYAripEV7fXZTyymSG71Wod8MCqGbVYBVz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36228/large/photo_2024-03-17_20.42.23.png?1710879988",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cronk",
    },
  },
  {
    chainId: 101,
    name: "CropperFinance",
    symbol: "CRP",
    address: "DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17883/large/copperfinance.PNG?1696517404",
    tags: [],
    verified: true,
    holders: 37653,
    extensions: {
      coingeckoId: "cropperfinance",
    },
  },
  {
    chainId: 101,
    name: "Crown by Third Time Games",
    symbol: "CROWN",
    address: "GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30452/large/crown_200_transparent.png?1696529338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crown-by-third-time-games",
    },
  },
  {
    chainId: 101,
    name: "Crowny",
    symbol: "CRWNY",
    address: "CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14958/large/crowny-icon-rounded_2x.png?1696514619",
    tags: [],
    verified: true,
    holders: 2872,
    extensions: {
      coingeckoId: "crowny-token",
    },
  },
  {
    chainId: 101,
    name: "crow with knife",
    symbol: "CAW",
    address: "9niFQK8MsParjSxhYQ3Ys2a6zHmsEuKSB1M7wwdvZ7bj",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36067/large/200px.png?1710405601",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crow-with-knife",
    },
  },
  {
    chainId: 101,
    name: "Crunchcat",
    symbol: "CRUNCH",
    address: "9iVJR1tjDgNHw88xtXXGaU2We2fR8qEm75ZSgztqDkag",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34559/large/webclip.png?1705382738",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crunchcat",
    },
  },
  {
    chainId: 101,
    name: "Cryowar",
    symbol: "CWAR",
    address: "HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20227/large/CWAR_round_200_200.png?1696519636",
    tags: [],
    verified: true,
    holders: 25294,
    extensions: {
      coingeckoId: "cryowar-token",
    },
  },
  {
    chainId: 101,
    name: "Crypsi Coin",
    symbol: "CRYPSI",
    address: "5e6Y8yJ56i7nFwiks1Bqs5BXyN4DuvwHvdzoGYxjsHsn",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37987/large/crypsicoin_icon_%281%29.jpg?1716222845",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crypsi-coin",
    },
  },
  {
    chainId: 101,
    name: "CosaBet",
    symbol: "$CBET",
    address: "Cbet5zMSzpCUKmVfy4nSkBjGPSy7WoUEwqLcQ6HhrHVA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33238/large/Scherm%C2%ADafbeelding_2024-01-10_om_22.04.20.png?1707779142",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "crypto-bet",
    },
  },
  {
    chainId: 101,
    name: "CULT CAT",
    symbol: "CULT",
    address: "8A7NPmnpqawyqspqim4hpD18sWTJxM1gaERPHN3k87My",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37746/large/CULTCATTICKER.png?1715374369",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "cult-cat",
    },
  },
  {
    chainId: 101,
    name: "Curate",
    symbol: "XCUR",
    address: "35r2jMGKytAJ7FyKfKRHPanT8kpjg3emPy7WG6GANCNB",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13327/large/400x400_%281%29_%283%29_%282%29.png?1696513096",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "curate",
    },
  },
  {
    chainId: 101,
    name: "Cyborg Apes",
    symbol: "BORG",
    address: "CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23020/large/lgo-head-export.png?1696522315",
    tags: [],
    verified: true,
    holders: 942,
    extensions: {
      coingeckoId: "cyborg-apes",
    },
  },
  {
    chainId: 101,
    name: "Cykura",
    symbol: "CYS",
    address: "BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18139/large/cyclos-text-logo.png?1696517642",
    tags: [],
    verified: true,
    holders: 17845,
    extensions: {
      coingeckoId: "cyclos",
    },
  },
  {
    chainId: 101,
    name: "CZOL",
    symbol: "CZOL",
    address: "AUwugnCh1tFc5scRHLNqnHjfcRbHRsq7yrKFUe7Ysmgs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36093/large/FreeImageKit.com_Img-size%28200x200%29px.png?1713292883",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "czolana",
    },
  },
  {
    chainId: 101,
    name: "D2",
    symbol: "D2X",
    address: "GyuP7chtXSRB6erApifBxFvuTtz94x3zQo3JdWofBTgy",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35484/large/DD_200x200.png?1708850791",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "d2",
    },
  },
  {
    chainId: 101,
    name: "dancing triangle",
    symbol: "TRIANGLE",
    address: "8iPwRXodtvUQoJ2Nj2HoY5BEZ3ohE3AafHPr5Pi3coV1",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34329/large/logo-dancing-triangle.png?1704667791",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dancing-triangle",
    },
  },
  {
    chainId: 101,
    name: "daoSOL",
    symbol: "DAOSOL",
    address: "GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25488/large/logo_%284%29.png?1696524621",
    tags: [],
    verified: true,
    holders: 2629,
    extensions: {
      coingeckoId: "daosol",
    },
  },
  {
    chainId: 101,
    name: "Daoversal",
    symbol: "DAOT",
    address: "nQ1qgSpXWi71twnWPFjyfCtcbUXbVyQb64RfHKwRpKE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37946/large/Daoversal.jpeg?1715977076",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "daoversal",
    },
  },
  {
    chainId: 101,
    name: "Dap, the Dapper Dog!",
    symbol: "DAP",
    address: "5SR1u6BQ6nvMh4bknZX7Hf9r7DbZsvf87cFybj6mkTMq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36343/large/zbase.png?1711186438",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dap-the-dapper-dog",
    },
  },
  {
    chainId: 101,
    name: "Konstellation",
    symbol: "DARC",
    address: "CpFE715P5DnDoJj9FbCRcuyHHeTXNdRnvzNkHvq1o23U",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/2943/large/darctoken.png?1696503688",
    tags: [],
    verified: true,
    holders: 1464,
    extensions: {
      coingeckoId: "darcmatter-coin",
    },
  },
  {
    chainId: 101,
    name: "Dark Protocol",
    symbol: "DARK",
    address: "FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32959/large/Dark-Logo.png?1699973520",
    tags: [],
    verified: true,
    holders: 1016,
    extensions: {
      coingeckoId: "dark-protocol",
    },
  },
  {
    chainId: 101,
    name: "Dat Boi",
    symbol: "WADDUP",
    address: "7z5d8Je3JH5nENwgfDYt755hG3NGUnvgBgTBoka6nF2S",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35576/large/datboi.png?1709194617",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dat-boi",
    },
  },
  {
    chainId: 101,
    name: "Datsbotai",
    symbol: "DBA",
    address: "65EZMTnYFo8wwBDUcd9LZQ8u7sEp2oxwK1BLHs6JqgAB",
    decimals: 5,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "datsbotai",
    },
  },
  {
    chainId: 101,
    name: "DAWG",
    symbol: "DAWG",
    address: "3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20881/large/143299698-37b637ea-7fce-4bd6-8713-71c42e37629e.png?1696520274",
    tags: [],
    verified: true,
    holders: 7469,
    extensions: {
      coingeckoId: "dawg",
    },
  },
  {
    chainId: 101,
    name: "Dawkoins",
    symbol: "DAW",
    address: "7cb67ev3jvBKaAH1tnVM8FL8WfQ96sa2TYByEXajqx8N",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36484/large/Dawkoins_200x200.png?1713144683",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dawkoin",
    },
  },
  {
    chainId: 101,
    name: "D.BuyBot",
    symbol: "DBUY",
    address: "9H5bSR9kaUiXJ9HqzmU5gwPuDXvQMPmnmyeZxEStVDSq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35641/large/New_Project_%2824%29.png?1709363617",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "d-buybot",
    },
  },
  {
    chainId: 101,
    name: "Dean's List",
    symbol: "DEAN",
    address: "Ds52CDgqdWbTWsua1hgT3AuSSy4FNx2Ezge1br3jQ14a",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36197/large/logo_dl.png?1710811437",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dean-s-list",
    },
  },
  {
    chainId: 101,
    name: "DEAPCOIN",
    symbol: "DEP",
    address: "BgwQjVNMWvt2d8CN51CsbniwRWyZ9H9HfHkEsvikeVuZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/10970/large/DEAPcoin_01.png?1696510917",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "deapcoin",
    },
  },
  {
    chainId: 101,
    name: "Decentraland (Wormhole)",
    symbol: "MANA",
    address: "7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23050/large/MANA_wh_small.png?1696522343",
    tags: [],
    verified: true,
    holders: 1413,
    extensions: {
      coingeckoId: "decentraland-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Decimated",
    symbol: "DIO",
    address: "BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/8271/large/dio_logo_coloured_transparent.png?1696508474",
    tags: [],
    verified: true,
    holders: 9606,
    extensions: {
      coingeckoId: "decimated",
    },
  },
  {
    chainId: 101,
    name: "Dede on SOL",
    symbol: "DEDE",
    address: "338uDSAxxn1ChHq6ZRUAxJCgpLtCBZV58KUT2bbJF4cD",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37301/large/Dede.png?1713951677",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dede-on-sol",
    },
  },
  {
    chainId: 101,
    name: "DEEP",
    symbol: "DEEP",
    address: "6tfuMyZQWwHtnkjkdfQWdVMUBcQBLpN5FiooT4p2VMbV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34939/large/1000002233.png?1706722500",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "deep-fucking-value",
    },
  },
  {
    chainId: 101,
    name: "DFV",
    symbol: "DFV",
    address: "4YTyNbYrZiKYkweEPAQySXV6NbvVe34qHemDRpxRiEve",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38520/large/IMG_5661.JPG?1717757597",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "deep-fucking-value-2",
    },
  },
  {
    chainId: 101,
    name: "Deez Nuts",
    symbol: "NUTS",
    address: "DEEZgP19ZPovNeWRJZw7KuNbkLH6xjNjZ4HsUJnmZv7J",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35031/large/NUTS.jpg?1707158229",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "deez-nuts-sol",
    },
  },
  {
    chainId: 101,
    name: "DeFi",
    symbol: "DEFI",
    address: "HXwh9i8D1LKkVK8WfMjFPyiyVZ4Auh9aLD5ut4LCRqqR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34369/large/defi_logo.png?1704905360",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "de-fi",
    },
  },
  {
    chainId: 101,
    name: "DeFi Land",
    symbol: "DFL",
    address: "DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18910/large/defilend.png?1696518368",
    tags: [],
    verified: true,
    holders: 66493,
    extensions: {
      coingeckoId: "defi-land",
    },
  },
  {
    chainId: 101,
    name: "DeFi Land Gold",
    symbol: "GOLDY",
    address: "GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25647/large/GODLY.png?1696524780",
    tags: [],
    verified: true,
    holders: 1004,
    extensions: {
      coingeckoId: "defi-land-gold",
    },
  },
  {
    chainId: 101,
    name: "Degen KongZ",
    symbol: "KONGZ",
    address: "6pxT5UmTumQXBknjwSzLePRwBA5k8VGs68LiZwncC2mB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38197/large/photo_2024-05-05_00-11-14.jpg?1716791057",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "degen-kongz",
    },
  },
  {
    chainId: 101,
    name: "Degen Token",
    symbol: "DGN",
    address: "AVt5b3jqbqBcYud4sz9PXcKVgWHfW82fmnxWkeiY6kvU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34497/large/DGN-removebg-preview.png?1705265030",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "degen-token",
    },
  },
  {
    chainId: 101,
    name: "Degen Traded Fund",
    symbol: "DTF",
    address: "A7urg3gSqCv9DPa5v1A777R6RcRZ2QSXBhLYQsNYwGWb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34414/large/DTF_coin_w_b_%281%29.png?1704819306",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "degen-traded-fund",
    },
  },
  {
    chainId: 101,
    name: "degwefhat",
    symbol: "WEF",
    address: "Fago6g9b45k1TJXVAjGuvShhs8UH69WranYeHcVNHRL8",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35902/large/wef_logo.png?1710170688",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "degwefhat",
    },
  },
  {
    chainId: 101,
    name: "DeMR",
    symbol: "DMR",
    address: "4n7AbgC6WWBMBqLa92vC3FmFQti6am17KS9jHEKgirPF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37387/large/demr-logo.png?1714238440",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "demr",
    },
  },
  {
    chainId: 101,
    name: "DePay",
    symbol: "DEPAY",
    address: "DePay1miDBPWXs6PVQrdC5Vch2jemgEPaiyXLNLLa2NF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13183/large/DEPAY.png?1696512965",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "depay",
    },
  },
  {
    chainId: 101,
    name: "DePIN DAO",
    symbol: "DEPINDAO",
    address: "7FxHGcAsguJYSRTanWMbTr3JCXTdCyDSktPd4C4jWEUy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34782/large/depindao.jpg?1706018962",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "depin-dao",
    },
  },
  {
    chainId: 101,
    name: "DePlan",
    symbol: "DPLN",
    address: "J2LWsSXx4r3pYbJ1fwuX5Nqo7PPxjcGPpUb2zHNadWKa",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36429/large/deplan_token.png?1711433771",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "deplan",
    },
  },
  {
    chainId: 101,
    name: "Dermophis Donaldtrumpi",
    symbol: "DERMO",
    address: "A5GJ2a2UKqnQvmG2WN76hxfdv4foLnojT5cipoRfHRhE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38574/large/200x200.png?1718009313",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dermophis-donaldtrumpi",
    },
  },
  {
    chainId: 101,
    name: "DERPCAT",
    symbol: "DERPCAT",
    address: "FeT6vZSanHKvW64YvK359SwVDKA32zYM7hRB5YHNLqxR",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33772/large/DERPSOLANA.jpg?1702969918",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "derpcat",
    },
  },
  {
    chainId: 101,
    name: "Devin on Solana",
    symbol: "DEVIN",
    address: "7gbEP2TAy5wM3TmMp5utCrRvdJ3FFqYjgN5KDpXiWPmo",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38394/large/WhatsApp_Image_2024-06-02_at_03.04.10_ea493a6a.jpg?1717422642",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "devin-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Devour",
    symbol: "DPAY",
    address: "Gnca3UkjR4a1FFNZuGfEELmbaHkL6GteSC2swpdWRmf7",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27191/large/DPAY_-_Icon_%28200_%C3%97_200_px%29.png?1696526240",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "devour-2",
    },
  },
  {
    chainId: 101,
    name: "Dexana",
    symbol: "DEXANA",
    address: "7TSCK87jeA5A2M3g8vwWro2UacnUFL7E2TkQWjePpAZg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37489/large/blacklogo.png?1714498831",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dexana",
    },
  },
  {
    chainId: 101,
    name: "Dexlab",
    symbol: "DXL",
    address: "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17276/large/3_GradientSymbol.png?1696516830",
    tags: [],
    verified: true,
    holders: 124447,
    extensions: {
      coingeckoId: "dexlab",
    },
  },
  {
    chainId: 101,
    name: "DEX Message",
    symbol: "DEX",
    address: "GfPGfHnNbbKBvMGcupzixvgSvQAybBEe6DtdQ7sC1UWC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36634/large/Add_a_heading_%282%29.png?1712024233",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dex-message",
    },
  },
  {
    chainId: 101,
    name: "dicki",
    symbol: "$DICKI",
    address: "8EHC2gfTLDb2eGQfjm17mVNLWPGRc9YVD75bepZ2nZJa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37763/large/IMG_9372.png?1715478448",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dicki",
    },
  },
  {
    chainId: 101,
    name: "Didi Duck",
    symbol: "DIDID",
    address: "8E5pw1g1nQpmgVyeS1vZx8LokAZvuZgL6G1Uhr4iVbAB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38513/large/duck.PNG?1717754820",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "didi-duck",
    },
  },
  {
    chainId: 101,
    name: "DigiCask Token",
    symbol: "DCASK",
    address: "64sad4VPVkoSARy9juUw1stfpGg5TkRCm4TpRRLb4WEW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36591/large/DigiCask-Logo.png?1711949185",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "digicask-token",
    },
  },
  {
    chainId: 101,
    name: "Digital Files",
    symbol: "DIFI",
    address: "FZFrzDAaDSyXuWK5ibV9cAMnLmmx9DRutfMeJBqM751U",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29282/large/DIFI_logo.png?1696528234",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "digital-files",
    },
  },
  {
    chainId: 101,
    name: "dillwifit",
    symbol: "DILL",
    address: "E7dvtgzBWyuAnAHnCobW6WBbf2o76kMgedcH5fsYRUJu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37593/large/dillwifit-200.png?1714982627",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dillwifit",
    },
  },
  {
    chainId: 101,
    name: "Dingocoin",
    symbol: "DINGO",
    address: "6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15989/large/dingocoin.png?1696515602",
    tags: [],
    verified: true,
    holders: 1003,
    extensions: {
      coingeckoId: "dingocoin",
    },
  },
  {
    chainId: 101,
    name: "Dino",
    symbol: "DINO",
    address: "6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17770/large/solana_dino.png?1696517295",
    tags: [],
    verified: true,
    holders: 11042,
    extensions: {
      coingeckoId: "dino",
    },
  },
  {
    chainId: 101,
    name: "Dino Dragon",
    symbol: "DINO",
    address: "FdqkvhQNzrj2iEWBTnKvLaegWWjCxNgVkXC5bJ7guPKF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37896/large/dragon_logo_final.jpeg?1715847642",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dino-dragon",
    },
  },
  {
    chainId: 101,
    name: "DinoLFG",
    symbol: "DINO",
    address: "33eWALS9GkzSMS3EsKSdYCsrUiMdQDgX2QzGx4vA9wE8",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28239/large/DinoLFG.png?1716431744",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dinolfg",
    },
  },
  {
    chainId: 101,
    name: "Dinosol",
    symbol: "DINOSOL",
    address: "9JsLv5AH9TwyjNoZcYztH5taAz7YfJqS7wqxjGmSo6vW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36714/large/IMG_1303.jpeg?1712127615",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dinosol",
    },
  },
  {
    chainId: 101,
    name: "Dirty Street Cats",
    symbol: "DIRTY",
    address: "AuWLSEuDRJi6hVcXbeez9WVbpeRsiTvvbZG1svzeBaxf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37297/large/Hunter-small.png?1713951049",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dirty-street-cats",
    },
  },
  {
    chainId: 101,
    name: "Diskneeplus",
    symbol: "DISKNEE",
    address: "8SBvYd2YjcbFp7igkTDPvJHEPtkB8Pm79Xo2GYbTZreq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37591/large/photo_2024-05-05_16-23-49.jpg?1714982095",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "diskneeplus",
    },
  },
  {
    chainId: 101,
    name: "Dither",
    symbol: "DITH",
    address: "E1kvzJNxShvvWTrudokpzuc789vRiDXfXG3duCuY6ooE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35415/large/dithering.png?1708506777",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dither",
    },
  },
  {
    chainId: 101,
    name: "DJBONK",
    symbol: "DJBONK",
    address: "22wZhMtqGPqyFKefPBNM8T5T5zKjwrWfDnfGW46SU9N3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33997/large/circle200px_%2826%29.png?1703600189",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "djbonk",
    },
  },
  {
    chainId: 101,
    name: "DJCAT",
    symbol: "DJCAT",
    address: "A5gVj5j16U4vCTMXQYYrhkmWgx6gej4nAeZRNQMLUAfy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37959/large/photo_2024-05-17_21-22-38.jpg?1716179006",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "djcat",
    },
  },
  {
    chainId: 101,
    name: "DOBI",
    symbol: "DOBI",
    address: "GzQzkt2B4Jr6whWVBF7XqkzWvoUy1jEd5z9tczzGg1rH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33457/large/photo1701621533-modified.png?1701925135",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dobi",
    },
  },
  {
    chainId: 101,
    name: "DocuChain",
    symbol: "DCCT",
    address: "3fXCWpQaEHEsnHSYAqcxm3QLPGLxYiZzoJbqRY9wWxV2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24763/large/zf3Q-GS4_400x400.jpg?1696523924",
    tags: [],
    verified: true,
    holders: 5080,
    extensions: {
      coingeckoId: "docuchain",
    },
  },
  {
    chainId: 101,
    name: "DOGE-1",
    symbol: "DOGE-1",
    address: "9ahuLHwo7919eURfFAjTpaS6yo5TsxDyNEdvL3qo4HF5",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35002/large/IMG-20240202-WA0011-ezgif.com-resize.jpg?1707059230",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doge-1-2",
    },
  },
  {
    chainId: 101,
    name: "dogebonk on sol",
    symbol: "DOBO",
    address: "Fk4fiCavdntUSCfbzdGSeq9kvEfnXPhBoaNqgbgahovT",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34173/large/photo_2023-12-23_20-12-48.jpg?1704219173",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogebonk-on-sol",
    },
  },
  {
    chainId: 101,
    name: "DogeBoy",
    symbol: "DOGB",
    address: "CkSFj9MNEfWffXPuJxfcWRWdqHYUMAcvuUdJ7muSLCKJ",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37705/large/dogeboy.jpeg?1715249905",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogeboy-2",
    },
  },
  {
    chainId: 101,
    name: "COLANA",
    symbol: "COLANA",
    address: "B4cYZYVYeHgLc3W1pCduCYkoS75G6roPaPdPoBCFweNJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17371/large/200.png?1705687107",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogecola",
    },
  },
  {
    chainId: 101,
    name: "Doge for President",
    symbol: "VOTEDOGE",
    address: "98ZmjtNLjTr1yih6D7fdFm8ViU1jEYDcBU1wjc2k9imV",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38115/large/VoteBanner.jpg?1716536806",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doge-for-president",
    },
  },
  {
    chainId: 101,
    name: "Dogelana",
    symbol: "DGLN",
    address: "E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21133/large/logo_%285%29.png?1696520512",
    tags: [],
    verified: true,
    holders: 6552,
    extensions: {
      coingeckoId: "dogelana",
    },
  },
  {
    chainId: 101,
    name: "Dogelon Mars",
    symbol: "ELON",
    address: "7ZCm8WBN9aLa3o47SoYctU6iLdj7wkGG5SV2hE5CgtD5",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14962/large/6GxcPRo3_400x400.jpg?1696514622",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogelon-mars",
    },
  },
  {
    chainId: 101,
    name: "Dogelon Mars (Wormhole)",
    symbol: "ELON",
    address: "6nKUU36URHkewHg5GGGAgxs6szkE4VTioGUT5txQqJFU",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23041/large/ELON_wh_small.png?1696522334",
    tags: [],
    verified: true,
    holders: 550,
    extensions: {
      coingeckoId: "dogelon-mars-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Doge Marley",
    symbol: "MARLEY",
    address: "Dg1TDj72JmiTauvQxJmzRqYwzpRHv8hU54mqKWj1DBv",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36410/large/MARLEY.png?1711997730",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doge-marley",
    },
  },
  {
    chainId: 101,
    name: "DogemonGo",
    symbol: "DOGO",
    address: "5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17480/large/dogemongo.PNG?1696517022",
    tags: [],
    verified: true,
    holders: 4787,
    extensions: {
      coingeckoId: "dogemon-go",
    },
  },
  {
    chainId: 101,
    name: "Dogemoon",
    symbol: "DOGEMOON",
    address: "AjyxUXDj7TuUseh8L2K5czDE2H7i3rpeHbLcuh7eQbHx",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33738/large/IMG_20231214_131613_290.jpg?1702902922",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogemoon-2",
    },
  },
  {
    chainId: 101,
    name: "Doge Of Grok AI",
    symbol: "DOGEGROKAI",
    address: "D8bbHwcSrkBqTJyp6MCwLokbHEFLqs6zcfE9YUDiNHhW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34259/large/DogeGrokAI_%281%29.png?1704359325",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doge-of-grok-ai",
    },
  },
  {
    chainId: 101,
    name: "$Doge on Sol",
    symbol: "$DOGE",
    address: "Hk82ay38uGpYgqjdXXCiATavVQwAMaBNyxsUWbapmKuL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38265/large/1D5D3385-68A3-4E98-89FD-1DDED1FE5E0A.jpeg?1716949297",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doge-on-sol",
    },
  },
  {
    chainId: 101,
    name: "DogeVerse",
    symbol: "DOGEVERSE",
    address: "7xZCdhj7rGhnKndRpU3NHgZhbFMkPLP2ErDr1AS8Chsx",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38495/large/DogeVerse_200x200.png?1717692269",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogeverse",
    },
  },
  {
    chainId: 101,
    name: "DogeZilla",
    symbol: "ZILLA",
    address: "7x1xuoKGKxQuFcw2KjGPFtTLET6GC6LAygTRtPvYbjug",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33750/large/Untitled_%28100_x_100_px%29.png?1702945384",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogezilla-2",
    },
  },
  {
    chainId: 101,
    name: "DOGGO",
    symbol: "DOGGO",
    address: "Doggoyb1uHFJGFdHhJf8FKEBUMv58qo98CisWgeD7Ftk",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28740/large/pllp.jpeg?1696527720",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doggo",
    },
  },
  {
    chainId: 101,
    name: "doginphire",
    symbol: "FIRE",
    address: "3G8js2FjDtMbVDzh5ACaTLGHGDwBhaM5LBNuHKMeZ7Bd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34879/large/Group_74.png?1706497604",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doginphire",
    },
  },
  {
    chainId: 101,
    name: "doginthpool",
    symbol: "DIP",
    address: "3XxvmED354933DwSPJuzB7SE9uiWpD1ErydDuhmbFRMk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35705/large/89fb196e-ba96-4eeb-80a5-966aaf6e1cc4_%281%29.jpeg?1709580701",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doginthpool",
    },
  },
  {
    chainId: 101,
    name: "doginwotah",
    symbol: "WATER",
    address: "GsTmWmZozAiqD671MYCf1Whz9FtjwaeAAjLYXEWLWF1N",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35193/large/2424.png?1707822557",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doginwotah",
    },
  },
  {
    chainId: 101,
    name: "Dog Of Wisdom",
    symbol: "WISDM",
    address: "53ctv3wwFXQbXruKWsbQcCe7sefowyu96pXK6FRLTjfv",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33695/large/2C815A2C-107F-48AC-8388-49B0059322F5.jpeg?1702812248",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dog-of-wisdom",
    },
  },
  {
    chainId: 101,
    name: "Sol Vision Pro",
    symbol: "VISION",
    address: "HcwCTrqP6BEuwoXuCwgPK3zd5WmSYgvbmH1pA5v6UVrD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35074/large/VISION.png?1708378852",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dog-vision-pro",
    },
  },
  {
    chainId: 101,
    name: "Dog Walter",
    symbol: "NELSOL",
    address: "EBPpUYEGsmVGG291MXoXmwucqw6nR9dEUEfK5LJdeuve",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38233/large/nelsol-transparent200x200.png?1716860537",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dog-walter",
    },
  },
  {
    chainId: 101,
    name: "DOGWIF2.0",
    symbol: "$WIF2",
    address: "2CTtsmmsaopFkom7giQNTRtqA1SCw6rQMXTE9cmkK5GF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37404/large/1000028292.jpg?1714371672",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwif2-0",
    },
  },
  {
    chainId: 101,
    name: "dogwifhat",
    symbol: "WIF",
    address: "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33566/large/dogwifhat.jpg?1702499428",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwifcoin",
    },
  },
  {
    chainId: 101,
    name: "dogwifleg",
    symbol: "LEG",
    address: "dvvvGjdt3dzX9QK5Nb2zrJP9ULNs8aFoNBjdY4uwJmi",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34475/large/LEG200.jpg?1704998295",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwifleg",
    },
  },
  {
    chainId: 101,
    name: "DogWifNoHat",
    symbol: "NOHAT",
    address: "5BKTP1cWao5dhr8tkKcfPW9mWkKtuheMEAU6nih2jSX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37803/large/11ratio.png?1715591134",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwifnohat",
    },
  },
  {
    chainId: 101,
    name: "dogwifouthat",
    symbol: "WIFOUT",
    address: "2ioyweEeV4xJCkFJvh868X9iP3L6Q31MVCawfbJLRTHq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33959/large/cwpiFcz1_400x400.jpeg?1703567328",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwifouthat",
    },
  },
  {
    chainId: 101,
    name: "dogwifpants",
    symbol: "PANTS",
    address: "jD8gVEjhkJos8caTEuVApbBcbZfCeRovxMCGwe6rtNM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33945/large/Safeimagekit-resized-img.png?1703537416",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwifpants",
    },
  },
  {
    chainId: 101,
    name: "Dog Wif Pixels",
    symbol: "DWP",
    address: "6CEtEhtVeN3EvxqxXv9sxri8EnpHq983dnsTmqHZf2eg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37826/large/DogWifPixelspng.png?1715668328",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dog-wif-pixels",
    },
  },
  {
    chainId: 101,
    name: "dogwifscarf",
    symbol: "WIFS",
    address: "6vUQsePjhpH67Db6p7Koj1wQsQP1UtovBkWXSrC1DkaA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36074/large/IMG_8873.PNG?1710597647",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dogwifscarf",
    },
  },
  {
    chainId: 101,
    name: "dog wif spinning hat",
    symbol: "SD",
    address: "BNZTGAKi6anbbTgoVhfpGtVa89utWtZpb5WZyskqxCxE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36842/large/dog_wif_spinning_hat.png?1712582269",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dog-wif-spinning-hat",
    },
  },
  {
    chainId: 101,
    name: "Dojo",
    symbol: "DOJO",
    address: "4px8A4VFpxWWFoduF37LKw9cAdMXxjbRBCgSJHQLEH5g",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36257/large/dojo_cg.png?1710928031",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dojo-2",
    },
  },
  {
    chainId: 101,
    name: "DOKI",
    symbol: "DOKI",
    address: "8CmHC7Y2715VFaMkMZMwWDqRYQwPLAsM57Xiu3Ho4TjG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35721/large/doki.png?1709631805",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doki",
    },
  },
  {
    chainId: 101,
    name: "Dolan Duck",
    symbol: "DOLAN",
    address: "4YK1njyeCkBuXG6phNtidJWKCbBhB659iwGkUJx98P5Z",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36033/large/imresizer-1710288257698.jpg?1710395646",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dolan-duck",
    },
  },
  {
    chainId: 101,
    name: "DONALDCAT",
    symbol: "DC",
    address: "FaUKsgcuqAd9sCaFz3if7ia1DasaxVoNPTXWFs2GScWN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37757/large/jpgtopngconverter-com_28.jpg?1715447762",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "donaldcat",
    },
  },
  {
    chainId: 101,
    name: "Doland Tremp",
    symbol: "TREMP",
    address: "FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35871/large/tremp.jpeg?1709974567",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "donald-tremp",
    },
  },
  {
    chainId: 101,
    name: "DON DON DONKI",
    symbol: "DONKI",
    address: "GUVMMHMiGYMdBWSwPh8CZqxJCU96bMyzE7EmJx8jAgNg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35081/large/IMG_8831.jpeg?1707304244",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "don-don-donki",
    },
  },
  {
    chainId: 101,
    name: "DONK",
    symbol: "DONK",
    address: "xABfKiG2KCHi6keTeLycW1iK7B52wJmchSWXu3YrsDp",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33054/large/20231111_102518.jpg?1700475092",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "donk",
    },
  },
  {
    chainId: 101,
    name: "Donke",
    symbol: "DONKE",
    address: "9t31CoUG4Xb77AW5exQhQtGy3Kz2MPrNifno6tbJzRTn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38216/large/Donke.png?1716825575",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "donke",
    },
  },
  {
    chainId: 101,
    name: "Donkey King",
    symbol: "DOKY",
    address: "5Rs53fY3q4t4mLk9zBQ45cVNgF1RH7NqRNXiP6Pa5rYH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37648/large/Untitled_design_%286%29_%281%29.png?1717685279",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "donkey-king",
    },
  },
  {
    chainId: 101,
    name: "DON'T SELL YOUR BITCOIN",
    symbol: "BITCOIN",
    address: "RrUiMy3j9bzhgBJpXCqpF33vfrGD5Y9qAfbBVbRMkQv",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37965/large/bitcoin.jpg?1716184703",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "don-t-sell-your-bitcoin",
    },
  },
  {
    chainId: 101,
    name: "DooDoo",
    symbol: "DOODOO",
    address: "JDwzFSxcUvLubUb9xAuuZNvh4bbcEJcuM9TezpmRHVWF",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35033/large/doodoo.png?1707189618",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doodoo",
    },
  },
  {
    chainId: 101,
    name: "Dopamine",
    symbol: "DOPE",
    address: "8Kqmghm1mkXbKYqt2wGnHxwnRdEjD9nayNKD3EanWkcD",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36583/large/64x64.trans.png?1711945887",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dopamine",
    },
  },
  {
    chainId: 101,
    name: "DORK LORD COIN",
    symbol: "DLORD",
    address: "3krWsXrweUbpsDJ9NKiwzNJSxLQKdPJNGzeEU5MZKkrb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37472/large/DLORD.jpg?1714461975",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dork-lord-coin",
    },
  },
  {
    chainId: 101,
    name: "DORK LORD (SOL)",
    symbol: "DORKL",
    address: "8uwcmeA46XfLUc4MJ1WFQeV81rDTHTVer1B5Rc6M4iyn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37501/large/VFDYoHz__400x400_%281%29.jpg?1714636448",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dork-lord-eth",
    },
  },
  {
    chainId: 101,
    name: "DOUGHGE",
    symbol: "$DOH",
    address: "Dsad47jgGNoJ8p4LJQ8x4yCFRMHBL7kd1Js3TJj7X52C",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38371/large/Untitled_design_%2840%29.png?1717293488",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "doughge",
    },
  },
  {
    chainId: 101,
    name: "Drago",
    symbol: "DRAGO",
    address: "5D9LBmEeWjKXZs8JRaP8YdBDdkdQPwu9mATXTcMUq7YU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34133/large/IMG_0182.jpeg?1704178441",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "drago",
    },
  },
  {
    chainId: 101,
    name: "DragonCoin",
    symbol: "DRAGON",
    address: "GiBrdw1tF8nuJxWuhTp83ULEMY9uJkYUHQUBzwfEnw5R",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34063/large/photo_2023-12-26_19.33.36.png?1703754096",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dragoncoin",
    },
  },
  {
    chainId: 101,
    name: "Dragon War",
    symbol: "DRAW",
    address: "48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23100/large/logo_%284%29.png?1696522389",
    tags: [],
    verified: true,
    holders: 5752,
    extensions: {
      coingeckoId: "dragon-war",
    },
  },
  {
    chainId: 101,
    name: "Dragy",
    symbol: "DRAGY",
    address: "3JoKpqE4kowVTR3Po3gr3sxzLF6vKCvjGx48g8DRx9oN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35200/large/dragy_%281%29.png?1707841371",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dragy",
    },
  },
  {
    chainId: 101,
    name: "Drake's Dog",
    symbol: "DIAMOND",
    address: "GoCr92MAhiE1kxXyGx7yPdS9qmVxhcJaKN7aNZumgcz6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34599/large/IMG_5400.jpeg?1716004796",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "drake-s-dog",
    },
  },
  {
    chainId: 101,
    name: "Drako",
    symbol: "DRAKO",
    address: "drakduQWnTS89CdTUdgHmZzEkN6reBLYqrk8rzVhU53",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34333/large/drako_logo.png?1704668657",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "drako",
    },
  },
  {
    chainId: 101,
    name: "Driftin Cat",
    symbol: "DRIFTY",
    address: "72xMD9BZEEtT7o2C8tXaeYA5QkV53WJ5W83kZKubuCap",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37955/large/image%281%29.png?1716092000",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "driftin-cat",
    },
  },
  {
    chainId: 101,
    name: "Drift Protocol",
    symbol: "DRIFT",
    address: "DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37509/large/DRIFT.png?1715842607",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "drift-protocol",
    },
  },
  {
    chainId: 101,
    name: "Drift Staked SOL",
    symbol: "DSOL",
    address: "Dso1bDeDjCQxTrWHqUUi63oBvV7Mdm6WaobLbQ7gnPQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37479/large/solana.png?1714471411",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "drift-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Drunk",
    symbol: "DRUNK",
    address: "EaRMzBwWRwvSgus8rfdZGfdzX3ZKRKoCL7U36G7xfvcb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36395/large/ypqNCGT9_400x400.jpg?1711451558",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "drunk",
    },
  },
  {
    chainId: 101,
    name: "Dual Finance",
    symbol: "DUAL",
    address: "DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29293/large/Logo-cropped.png?1696528245",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dual-finance",
    },
  },
  {
    chainId: 101,
    name: "dub duck",
    symbol: "$DUB",
    address: "4xzJ33EtGM4UVNsmCeGLX8mEPxvsw5c6AjM3LFcDgtii",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36606/large/dub.png.jpg?1711964167",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dub-duck",
    },
  },
  {
    chainId: 101,
    name: "Duck In A Truck",
    symbol: "DIAT",
    address: "DUCK697yujJjDERVUNG21tiiqvPp28w5fuVu9TZZXqtS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38509/large/1000236195.jpg?1717753961",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "duck-in-a-truck",
    },
  },
  {
    chainId: 101,
    name: "Doug the Duck",
    symbol: "DOUG",
    address: "BavuJ8bntC79A8aHTxQi1EUhcCnqvEU8KSBE4sVCAaHc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38416/large/DOUGLOGO.png?1717478804",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "duck-the-doug",
    },
  },
  {
    chainId: 101,
    name: "Duge",
    symbol: "DUGE",
    address: "25HwajZbusQvso1XWA1T41Cd5LVVxHsv91P3Ahq3pcTK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36584/large/Ads%C4%B1z_%28200_x_200_piksel%29.png?1711946779",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "duge",
    },
  },
  {
    chainId: 101,
    name: "Duk",
    symbol: "DUK",
    address: "3cdf7UqSb38qjAE2fuW4r5kQA5SHwBA23hWbZW5E6uKx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36337/large/logo-highres_1.png?1711103260",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "duk",
    },
  },
  {
    chainId: 101,
    name: "DUKO",
    symbol: "DUKO",
    address: "HLptm5e6rTgh4EKgDpYFrnRHbjpkMyVdEeREEa2G7rf9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35301/large/WhatsApp_Image_2024-02-14_at_18.46.11.jpeg?1708084869",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "duko",
    },
  },
  {
    chainId: 101,
    name: "duk on SOL",
    symbol: "DUK",
    address: "A2khRbhRJNrAEHj95htivC4cR4VbJwfssDH5FPPbP4m9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36864/large/duk.png?1712643873",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "duk-on-sol",
    },
  },
  {
    chainId: 101,
    name: "DumbMoney",
    symbol: "GME",
    address: "556BVtBSN6kWVXm5q9KW3FLXoFjmwcMi8C4ztKrH4Xk4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32058/large/DumbMoney_-_GME_-_Official_Logo_200x200px.png?1696530855",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dumbmoney",
    },
  },
  {
    chainId: 101,
    name: "Dupe The Duck",
    symbol: "DUPE",
    address: "2K2EcwAKDjU6Dg17WRwKshhr4Qu29irvRt3GU3uphrKg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36199/large/200x200.png?1710832682",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dupe-the-duck",
    },
  },
  {
    chainId: 101,
    name: "Nectar",
    symbol: "NCTR",
    address: "AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34091/large/%D0%BF%D1%87%D0%B5%D0%BB%D0%B0-%D0%B3%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%8B2.png?1704047211",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dust-city-nectar",
    },
  },
  {
    chainId: 101,
    name: "Dust Protocol",
    symbol: "DUST",
    address: "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24289/large/6388d49d-1f00-448d-92bc-f2db1441bbce.?1696523472",
    tags: [],
    verified: true,
    holders: 87364,
    extensions: {
      coingeckoId: "dust-protocol",
    },
  },
  {
    chainId: 101,
    name: "Dwake On Sol",
    symbol: "DWAKE",
    address: "9oUXhgFmW2HWqWHds1NoV3DKLY3AAtNevA3dP7PtyEbr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37527/large/DWAKE.jpg?1714639209",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dwake-on-sol",
    },
  },
  {
    chainId: 101,
    name: "dYdX (Wormhole)",
    symbol: "DYDX",
    address: "4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23039/large/DYDX_wh_small.png?1696522333",
    tags: [],
    verified: true,
    holders: 1010,
    extensions: {
      coingeckoId: "dydx-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Dyl",
    symbol: "DYL",
    address: "DTUW2CFo71KnTNSFYX95jQ8P8aJVQVr8MEF1AGMm5WGm",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32550/large/Dyl-pfp-200x.png?1698479973",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "dyl",
    },
  },
  {
    chainId: 101,
    name: "Eddie Seal",
    symbol: "EDSE",
    address: "2Zvo6bnwJtyXhsPgbcfajTrEtB7NwrJhK8mMmtEXvHHc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38418/large/eddie_200x200.png?1717481062",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "eddie-seal",
    },
  },
  {
    chainId: 101,
    name: "Eeyor",
    symbol: "EEYOR",
    address: "5kyEDexp9ExmFUVcbKmNy5QXQWhHBsPaLt1MieBXZBYj",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31565/large/200eeyor.png?1696530378",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "eeyor",
    },
  },
  {
    chainId: 101,
    name: "EFK Token",
    symbol: "EFK",
    address: "efk1hwJ3QNV9dc5qJaLyaw9fhrRdjzDTsxbtWXBh1Xu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27876/large/E_VFDP8f_400x400.jpeg?1696526894",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "efk-token",
    },
  },
  {
    chainId: 101,
    name: "EGG",
    symbol: "EGG",
    address: "4ynyx6BzY2XGFgjjun9Cruj1bSRo8FLsAqNnPsW6jDsu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34419/large/egcggg.png?1704821338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "egg",
    },
  },
  {
    chainId: 101,
    name: "Eggdog",
    symbol: "EGG",
    address: "EXA537HSBVpsFijENbt6Muuy9AADUN8dUmYKD4oKbjJE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33754/large/Egg_%28Custom%29.png?1702946771",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "eggdog",
    },
  },
  {
    chainId: 101,
    name: "Egypt Cat",
    symbol: "SPHYNX",
    address: "5t4EVfkb5QU8NZXmTzcSK2bQsNWUr2HL64rRb8i6wpat",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38543/large/Egypt_Cat.png?1717927842",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "egypt-cat",
    },
  },
  {
    chainId: 101,
    name: "Elawn Moosk",
    symbol: "MOOSK",
    address: "3KroURETdWLyFReapbdh6nTWQgS7Y6rorLY8jb1pJXoM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37282/large/photo_2024-04-18_10-36-03.jpg?1713943688",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "elawn-moosk",
    },
  },
  {
    chainId: 101,
    name: "Elementum",
    symbol: "ELE",
    address: "8A9HYfj9WAMgjxARWVCJHAeq9i8vdN9cerBmqUamDj7U",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34744/large/Elementum.png?1705949535",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "elementum",
    },
  },
  {
    chainId: 101,
    name: "el gato",
    symbol: "ELGATO",
    address: "F47vvwFYuLioQsqEVAjqdY6Yihc8wVRiUcfHGcBR9XUs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33735/large/el_gato.png?1710475690",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "el-gato",
    },
  },
  {
    chainId: 101,
    name: "EL GATO",
    symbol: "ELGATO",
    address: "CBfdnW9BrZX4xJMc33h4dotzf7sqJr8x7wWXUVee6smn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35177/large/elcato.png?1707753363",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "el-gato-2",
    },
  },
  {
    chainId: 101,
    name: "Elixir Games",
    symbol: "ELIX",
    address: "5cbq1HriesW4zHpFEk9Gc8UT4ccmfHcBTDCa2XcBduTo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35191/large/ELIX_token.png?1707818498",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "elixir-token",
    },
  },
  {
    chainId: 101,
    name: "elizabath whoren",
    symbol: "WHOREN",
    address: "EF23Avq2cTPnMVTfHacZ3SG5Z8misHmFA2gbt2rKqiYH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35896/large/whoren.jpeg?1710162544",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "elizabath-whoren",
    },
  },
  {
    chainId: 101,
    name: "ELON CAT FINANCE",
    symbol: "ECAT",
    address: "7q7xpUFK3qEaNR1AKYdzwTRhZgYQzgGYyA4PZC9DwVZv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37564/large/Ecat_logo.png?1714877548",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "elon-cat-finance",
    },
  },
  {
    chainId: 101,
    name: "ELON DRAGON",
    symbol: "ELONDRAGON",
    address: "EYenQ4rBLrvSSDgseBz7tKSuz5Qfqne5kHiUARF8qp6X",
    decimals: 1,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34444/large/27f6a905-77eb-4851-8018-cff23d500642-25496154.jpg?1704897940",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "elon-dragon",
    },
  },
  {
    chainId: 101,
    name: "Elumia",
    symbol: "ELU",
    address: "4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24433/large/me4oOqTM_400x400.png?1696523614",
    tags: [],
    verified: true,
    holders: 6864,
    extensions: {
      coingeckoId: "elumia",
    },
  },
  {
    chainId: 101,
    name: "El Wiwi",
    symbol: "WIWI",
    address: "67VcrASAt3MPjf2tbiXJvoLf2p7kQHZUQ1UHwxFzzzCV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37594/large/elwiwi-icon.png?1714983800",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "el-wiwi",
    },
  },
  {
    chainId: 101,
    name: "Emmy",
    symbol: "EMMY",
    address: "8Qrc2pf9p24NyJVG1FagnqJXwKw6h5L5McxnMfJoUxev",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34916/large/Emmy.jpg?1706612157",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "emmy",
    },
  },
  {
    chainId: 101,
    name: "England Coin",
    symbol: "ENG",
    address: "4XQvdipJBdrb5hUgUrbZPPFmp6BCav41n55dc7KDYW3m",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37622/large/h09bgb.jpg?1715068013",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "england-coin",
    },
  },
  {
    chainId: 101,
    name: "Enrex",
    symbol: "ENRX",
    address: "5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24952/large/enrx.png?1696524106",
    tags: [],
    verified: true,
    holders: 5861,
    extensions: {
      coingeckoId: "enrex",
    },
  },
  {
    chainId: 101,
    name: "Epep",
    symbol: "EPEP",
    address: "RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36076/large/RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL.png?1710413373",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "epep",
    },
  },
  {
    chainId: 101,
    name: "Epics Token",
    symbol: "EPCT",
    address: "CvB1ztJvpYQPvdPBePtRzjL4aQidjydtUz61NWgcgQtP",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28901/large/EpicsCoin200x200.png?1696527877",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "epics-token",
    },
  },
  {
    chainId: 101,
    name: "Epiko",
    symbol: "EPIKO",
    address: "6ep1sNB9UWni3nBZUX84USsRBhKRzMAxEmcia479cWTh",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31909/large/EPIKO_Light_200x200.png?1703118804",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "epiko",
    },
  },
  {
    chainId: 101,
    name: "Eric the Goldfish",
    symbol: "ERIC",
    address: "LSTo3PdLJmcm7r5gjN1RvR5nATg3UQbDstbXGL6xqQu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37101/large/Eric_the_Goldfish.png?1713292113",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "eric-the-goldfish",
    },
  },
  {
    chainId: 101,
    name: "ETFSOL2024",
    symbol: "ETF",
    address: "2ewq5wqLH7KXFD3twSpSoW1dnYFehWKqHDAZ6C7GWNEa",
    decimals: 1,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34239/large/IMG_20240103_084637_124.jpg?1704334899",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "etfsol2024",
    },
  },
  {
    chainId: 101,
    name: "Ethereum (Wormhole)",
    symbol: "ETH",
    address: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22990/large/ETH_wh_small.png?1696522286",
    tags: [],
    verified: true,
    holders: 39360,
    extensions: {
      coingeckoId: "ethereum-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Ethrix",
    symbol: "ETX",
    address: "9DZ58i5vAfk3JaFVYezYzhrVht7j8McZsUbuTcDiSbrP",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30888/large/ETX_logo.png?1696529735",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ethrix",
    },
  },
  {
    chainId: 101,
    name: "EURC",
    symbol: "EURC",
    address: "HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26045/large/euro.png?1696525125",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "euro-coin",
    },
  },
  {
    chainId: 101,
    name: "EUROe Stablecoin",
    symbol: "EUROE",
    address: "2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28913/large/euroe-200x200-round.png?1696527889",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "euroe-stablecoin",
    },
  },
  {
    chainId: 101,
    name: "EVERMOON SOL",
    symbol: "EVERMOON",
    address: "CgzdCjj5YNH51uFfQftFbuJKMrwgdWheVjwqjU84MV8y",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35165/large/1707486882078-b486b4e503eef7868c11255c010df5cd.png?1707663935",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "evermoon-sol",
    },
  },
  {
    chainId: 101,
    name: "EverRise SOL",
    symbol: "RISE",
    address: "Pg4gfZvWZbzUbXSw4F4xoPdEg6WqiVt6M7LbMZ6ZraL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36174/large/EverRise.png?1710749493",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "everrise-sol",
    },
  },
  {
    chainId: 101,
    name: "Ever Sol",
    symbol: "EVER",
    address: "4UbbkzFuYub2Q7sFZ3dqQiVer2Wg5K5aA4BStZPcNYJA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35171/large/Eversol.png?1707743830",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ever-sol",
    },
  },
  {
    chainId: 101,
    name: "Evolve",
    symbol: "$EVOL",
    address: "EVoLsbmQXT6R3b11WjPpGEXyCjw1zmmir271XqDbKRsg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34230/large/EVLV_4-14.png?1704303837",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "evolve",
    },
  },
  {
    chainId: 101,
    name: "Exgoland",
    symbol: "EXGO",
    address: "D5ymUBHsvoVYKAGUCGknk2Cm8UYGKTntxJ62T3C46NXS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34975/large/Exgoland_logo_%28200x200%29.png?1706855442",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "exgo",
    },
  },
  {
    chainId: 101,
    name: "Smilek",
    symbol: "SMILEK",
    address: "7x4FgiFfeqzs1tiUNvJzpt47GtLXAJ8Jfn8G1Hyyu6JH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32631/large/1001138768.png?1708166634",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "eye-earn",
    },
  },
  {
    chainId: 101,
    name: "Fabric",
    symbol: "FAB",
    address: "EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16649/large/FABLOGO_TRANS200.png?1696516210",
    tags: [],
    verified: true,
    holders: 21736,
    extensions: {
      coingeckoId: "fabric",
    },
  },
  {
    chainId: 101,
    name: "Fade",
    symbol: "FADE",
    address: "AWGASnWS35me7uzq3LwTxMuWrpPAc9KbHvyBLuD6wpFB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35407/large/2024-02-14_17.55.30.jpg?1708499738",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fade",
    },
  },
  {
    chainId: 101,
    name: "FALX",
    symbol: "FALX",
    address: "Afo4NumBNHDXc7m7p6qjZ1pF3LbqYfG5k1CNrGve8rVu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38124/large/as.png?1716549773",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "falx",
    },
  },
  {
    chainId: 101,
    name: "FAME Protocol",
    symbol: "FAME",
    address: "GZtU6e32GQrEynjv9QFFZGR25ksxjMW5RufdsDQcqnQ8",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38003/large/imresizer-1716175438964.png?1716267344",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fame-protocol",
    },
  },
  {
    chainId: 101,
    name: "Family",
    symbol: "FAM",
    address: "7njsg9BA1xvXX9DNpe5fERHK4zb7MbCHKZ6zsx5k3adr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35444/large/200x200.png?1708619938",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "family-2",
    },
  },
  {
    chainId: 101,
    name: "Famous Fox Federation",
    symbol: "FOXY",
    address: "FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26191/large/uFYaQEsU_400x400.jpg?1696525278",
    tags: [],
    verified: true,
    holders: 142867,
    extensions: {
      coingeckoId: "famous-fox-federation",
    },
  },
  {
    chainId: 101,
    name: "Famous Fox Federation Floor Index",
    symbol: "FOXES",
    address: "3RCVCywxSs8pDRU1hw8fo7xTUCpNuX86w8cKTM6fgWBY",
    decimals: 2,
    logoURI:
      "https://cloudflare-ipfs.com/ipfs/Qmetu9eyf7jxE9cNV9MYMWborQBGHqurt1MvD2LjwDcumy",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "famous-fox-federation-floor-index",
    },
  },
  {
    chainId: 101,
    name: "Fastlane",
    symbol: "LANE",
    address: "JC72eeGjHJJmAruYV3GwegmaWuUdcouUvamcwfswb7yj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35932/large/1000026383.png?1710225835",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fastlane",
    },
  },
  {
    chainId: 101,
    name: "Fatality Coin",
    symbol: "FATALITY",
    address: "DXCoKQ7iLpux398fNHewQn6djfGobzFuPiR5o8hrVHAb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36415/large/Untitled_design_%283%29.png?1711420724",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fatality-coin",
    },
  },
  {
    chainId: 101,
    name: "Fat Cat",
    symbol: "FCAT",
    address: "3stmg4YsC7q1o16dzE21UuXYhctrrauV5bDcZLaxycmu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36630/large/79D6C71C-62FA-4721-B847-A8371D59DD0B.jpeg?1712021847",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fat-cat-2",
    },
  },
  {
    chainId: 101,
    name: "FCUK",
    symbol: "FCUK",
    address: "FCUKBVkK5rT9f2CK7YEc57tgbTVBZQNZAkcH2xoud8LH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33941/large/Untitled_design_%286%29.png?1703503656",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fcuk",
    },
  },
  {
    chainId: 101,
    name: "FECES",
    symbol: "FECES",
    address: "5j2shkkzascw33QpW6RRqfRkacLkn7QwwV1e7jX36nyn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37390/large/IMG_0557.jpeg?1714631192",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "feces",
    },
  },
  {
    chainId: 101,
    name: "Felicette the Space Cat",
    symbol: "FELICETTE",
    address: "BychPBYgrmUeRE38qKqs6Tx4Zo5Rh96Mcp9Lu8PUXFVv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36619/large/20240428_100746_0000.png?1715762108",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "felicette-the-space-cat",
    },
  },
  {
    chainId: 101,
    name: "Felix the lazer cat",
    symbol: "$PEOW",
    address: "7DptUgc1aRY6geK4WnauRKTTfVFWmLsBWP275sD2Bg9r",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36351/large/IMG_1702_%283%29.png?1711199244",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "felix-the-lazer-cat",
    },
  },
  {
    chainId: 101,
    name: "Fentanyl Dragon",
    symbol: "FENTANYL",
    address: "65Z76ENVtuTVohmGeSMVAPW7ArRoUByZspCN3Yb7k7p",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34711/large/FENTANYL.png?1705894493",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fentanyl-dragon",
    },
  },
  {
    chainId: 101,
    name: "FIGURE AI",
    symbol: "FAI",
    address: "6ZBPXYLJ5SBu51RB8Xd6oqqy7U6kPaXdfg36Lif99WEk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35782/large/figure_ai_logo124_final.png?1709798287",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "figure-ai",
    },
  },
  {
    chainId: 101,
    name: "FindMe",
    symbol: "FINDME",
    address: "CLFR99t87xxRQtEtkWkrGj82BiJ2228h7JPgkLWiZ45o",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37631/large/soldev.jpeg?1715073146",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "findme",
    },
  },
  {
    chainId: 101,
    name: "fink",
    symbol: "FINK",
    address: "fink4uFVrKd4HjMbqseTDSYYUkHb8kgiHNnCj28YtvK",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37311/large/Untitled_design_%2839%29.png?1713952975",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fink-different",
    },
  },
  {
    chainId: 101,
    name: "Fish N Chips",
    symbol: "CHIPPY",
    address: "Bz7Nx1F3Mti1BVS7ZAVDLSKGEaejufxvX2DPdjpf8PqT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37917/large/fisg.jpeg?1715936192",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fish-n-chips",
    },
  },
  {
    chainId: 101,
    name: "$FISHY",
    symbol: "$FISHY",
    address: "Fishy64jCaa3ooqXw7BHtKvYD8BTkSyAPh6RNE3xZpcN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34164/large/fishy.png?1711718924",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fishy",
    },
  },
  {
    chainId: 101,
    name: "Fketh",
    symbol: "FKETH",
    address: "GWkA9fwAtJDb8BfBAabRY3w7x4jQrboLZd9iWH1v7yuB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37414/large/fketh_%281%29_%281%29.jpeg?1714375147",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fketh",
    },
  },
  {
    chainId: 101,
    name: "FlipCat",
    symbol: "FLIPCAT",
    address: "5gBwPQZiw8mUSKQHq5otSxj9ubZKB8HFeYTXb7JY5rGS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37589/large/PFP.jpg?1714981918",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "flipcat",
    },
  },
  {
    chainId: 101,
    name: "Tim",
    symbol: "TIM",
    address: "DWjVPqEX4fPFQ47Xb7EegpGhgWzRviYfPoEWPnxz2CRd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38559/large/IMG_7697.jpeg?1718000905",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "floating-tim",
    },
  },
  {
    chainId: 101,
    name: "Floki Cat",
    symbol: "FCAT",
    address: "CcFfGMU9Jodk6s5fSZM1vYGF3UNng7fZvTmPgzvU1ScF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37136/large/IMG_5278.JPG?1713418075",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "floki-cat",
    },
  },
  {
    chainId: 101,
    name: "Flokis",
    symbol: "FLOKIS",
    address: "EcdKnP5PsjzWBzk4Bmqzp8VYi5L7NAGEPM6wK5K7iW2q",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33992/large/Safeimagekit-resized-img_%284%29.png?1703598864",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "flokis",
    },
  },
  {
    chainId: 101,
    name: "Flokiwifhat",
    symbol: "FLOKI",
    address: "FY4rNz7AqKEnEwyurmGVpoEBnSq7XqNn4YuXruvJhAXF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35381/large/TG_Logo.png?1708425269",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "flokiwifhat",
    },
  },
  {
    chainId: 101,
    name: "Flonk",
    symbol: "FLONK",
    address: "3VJyo1n5EkBGh6uEcnKA1Bf8EgVnvrK3XjcDZDDXsPLF",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34189/large/IMG_9974.png?1704267040",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "flonk",
    },
  },
  {
    chainId: 101,
    name: "FLOOF",
    symbol: "FLOOF",
    address: "3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw",
    decimals: 1,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19810/large/FLOOF_logo_200x200.png?1696519230",
    tags: [],
    verified: true,
    holders: 17516,
    extensions: {
      coingeckoId: "floof",
    },
  },
  {
    chainId: 101,
    name: "Flowmatic",
    symbol: "FM",
    address: "Eh1fXbAipe4k7CYR9UMb2bbWmBcpU3HcyX3LWuRVFBLz",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32580/large/Untitled_design_%281%29.png?1698558813",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "flowmatic",
    },
  },
  {
    chainId: 101,
    name: "Fluffys",
    symbol: "FLUFF",
    address: "CoRkC3r6MqYuTeMRc7D8JJF7UiUyFWurXGpYy1xQATNq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37459/large/Fluffys.png?1714449907",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fluffys",
    },
  },
  {
    chainId: 101,
    name: "Fluid USDC",
    symbol: "FUSDC",
    address: "Ez2zVjw85tZan1ycnJ5PywNNxR6Gm4jbXQtZKyQNu3Lv",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28471/large/fUSDC-200x200.png?1696527465",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fluid-usdc",
    },
  },
  {
    chainId: 101,
    name: "Fluid USDT",
    symbol: "FUSDT",
    address: "D5zHHS5tkf9zfGBRPQbDKpUiRAMVK8VvxGwTHP6tP1B8",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28472/large/fUSDT-200x200.png?1696527466",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fluid-usdt",
    },
  },
  {
    chainId: 101,
    name: "Fluxbot",
    symbol: "FLUXB",
    address: "FLUXBmPhT3Fd1EDVFdg46YREqHBeNypn1h4EbnTzWERX",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33018/large/fluxbot.jpeg?1700193761",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fluxbot",
    },
  },
  {
    chainId: 101,
    name: "Flycat",
    symbol: "FLYCAT",
    address: "7JB65ueicpzn8Jgx3WddnggvQthhAx9xGJm4Uz2y6CEg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38221/large/flycat.jpeg?1716827041",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "flycat",
    },
  },
  {
    chainId: 101,
    name: "FOC",
    symbol: "FOC",
    address: "5Ktn9qCHGmR2x1KFBxcdZd1KvB8KynhYrL74h7dHeiZN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35522/large/FOCLOGO.png?1709007417",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "foc",
    },
  },
  {
    chainId: 101,
    name: "FOMO",
    symbol: "FOMO",
    address: "Cx9oLynYgC3RrgXzin7U417hNY9D6YB1eMGw4ZMbWJgw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33104/large/photo_2023-11-19_14-43-49.jpg?1700650086",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fomo-2",
    },
  },
  {
    chainId: 101,
    name: "Food Token",
    symbol: "FOOD",
    address: "EcK2evV2cDECVsmvY2FxU51eu3fp4w48zrZxuA92AAAN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34303/large/eec1952b-12e7-49e2-81f9-a8050b8c5fee-removebg-preview_%281%29.png?1704612230",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "food-token-2",
    },
  },
  {
    chainId: 101,
    name: "ForRealOG",
    symbol: "FROG",
    address: "HZ32SiTtw3kYyaHTtTfpHVF8EyXFcy7MBQXeFpnNvQ9c",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36262/large/frog.jpg?1710940254",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "forrealog",
    },
  },
  {
    chainId: 101,
    name: "FRAKT",
    symbol: "FRKT",
    address: "ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18926/large/logo_-_2021-10-11T132203.751.png?1696518383",
    tags: [],
    verified: true,
    holders: 7027,
    extensions: {
      coingeckoId: "frakt-token",
    },
  },
  {
    chainId: 101,
    name: "France Coin",
    symbol: "FRA",
    address: "F9mv7XXbrXZb1sP2JUoswbCB3WHQM4QGMFDTVfnRZMnP",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37596/large/ezgif-5-1e3012d700.jpg?1714984506",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "france-coin",
    },
  },
  {
    chainId: 101,
    name: "Frax",
    symbol: "FRAX",
    address: "FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13422/large/FRAX_icon.png?1696513182",
    tags: [],
    verified: true,
    holders: 1468,
    extensions: {
      coingeckoId: "frax",
    },
  },
  {
    chainId: 101,
    name: "Frax Share",
    symbol: "FXS",
    address: "6LX8BhMQ4Sy2otmAWj7Y5sKd9YTVVUgfMsBzT6B9W7ct",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13423/large/Frax_Shares_icon.png?1696513183",
    tags: [],
    verified: true,
    holders: 442,
    extensions: {
      coingeckoId: "frax-share",
    },
  },
  {
    chainId: 101,
    name: "Freaky KEKE",
    symbol: "KEKE",
    address: "GgKDdEJ9n2NCHHonE5qSxVgPKGQNsbeXEkr1SHDLapPv",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37244/large/FreakyKEKE-200x200.png?1713860401",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "freaky-keke",
    },
  },
  {
    chainId: 101,
    name: "Freddy Fazbear",
    symbol: "$FRED",
    address: "DrW63uNnUuHs9n1UCbqu7xJYHg8be4mbPWcNadmr2gYw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34099/large/photo_2023-12-22_16-22-03.jpg?1703959174",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "freddy-fazbear",
    },
  },
  {
    chainId: 101,
    name: "FreeCZ",
    symbol: "FREECZ",
    address: "7YbHBageaxpyR8jBFtzCqofTetZkZjouQPhgVPKJ8Fwx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37514/large/Screenshot_2024-04-29_at_22.19.03.png?1714630486",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "freecz",
    },
  },
  {
    chainId: 101,
    name: "FreeMoon",
    symbol: "MOON",
    address: "moonskJwNmZiwkqH79S7b1UezGTaWQGEUbFkVqH3Vwq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37823/large/MoreGen_FreeMoon-logo-200.png?1715662357",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "freemoon-2",
    },
  },
  {
    chainId: 101,
    name: "Frogie",
    symbol: "FROGIE",
    address: "FWFEKXi3rj9UjiW4X32P3F4h5qipcCxqqHQCkgFLpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38580/large/frogie.png?1718087431",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "frogie",
    },
  },
  {
    chainId: 101,
    name: "Frogolana",
    symbol: "FROGO",
    address: "HWNAANRd5wXM46gBTiDHm4gKAfYMuy2afmH2Ji7s3JDT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33926/large/logo200x200.png?1703408456",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "frogolana",
    },
  },
  {
    chainId: 101,
    name: "Frogonsol",
    symbol: "FROG",
    address: "6NspJqVFceCiU5D1YgVq7waYoC394Vhqxwg7cSJdFtVE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36313/large/FROGLOGO_%284%29.png?1711091834",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "frogonsol",
    },
  },
  {
    chainId: 101,
    name: "Frog Wif Peen",
    symbol: "PEEN",
    address: "peen77qWZw4XQkvxW1QF6MUJyKNLbqvMzqhkKpB1aVo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34000/large/PEEN.jpg?1703601349",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "frog-wif-peen",
    },
  },
  {
    chainId: 101,
    name: "Fronk",
    symbol: "FRONK",
    address: "5yxNbU8DgYJZNi3mPD9rs4XLh9ckXrhPjJ5VCujUWg5H",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28679/large/_7XVXQ46_400x400.jpg?1696527663",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fronk",
    },
  },
  {
    chainId: 101,
    name: "FTX",
    symbol: "FTT",
    address: "AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/9026/large/F.png?1696509161",
    tags: [],
    verified: true,
    holders: 68265,
    extensions: {
      coingeckoId: "ftx-token",
    },
  },
  {
    chainId: 101,
    name: "FUACK",
    symbol: "FUACK",
    address: "4P6gQYnZ2AvwmAr37GCeUq1vyk1PQJmXnuA1QFRKGHkz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38038/large/FUACK_coingeko_logo_200p.png?1716329766",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "fuack",
    },
  },
  {
    chainId: 101,
    name: "FutureSPL",
    symbol: "FUTURE",
    address: "FUTURETnhzFApq2TiZiNbWLQDXMx4nWNpFtmvTf11pMy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36710/large/Official__FUTURE_Logo.png?1712127088",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "futurespl",
    },
  },
  {
    chainId: 101,
    name: "Galaxy War",
    symbol: "GWT",
    address: "GWTipxSJVPmmW2wCjBdkbnEJbCRCyrhL2x9zuHRPPTj1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22166/large/e2x7gMJ4_400x400.jpg?1696521510",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "galaxy-war",
    },
  },
  {
    chainId: 101,
    name: "GameBoy",
    symbol: "GBOY",
    address: "GKZbA2gDzw3MoxbfRsnrJTNi5uBDrnrz9bq1pNnx6kv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35839/large/IMG_5561.jpeg?1709889272",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gameboy",
    },
  },
  {
    chainId: 101,
    name: "GameFork",
    symbol: "GAMEFORK",
    address: "EkDsNLLX2sGM6gkTXETMVxPK9gyvMknBf4rc6qMFRUWj",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35468/large/GAMEFORK_.png?1708700495",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gamefork",
    },
  },
  {
    chainId: 101,
    name: "Game of Memes",
    symbol: "GOME",
    address: "8ULCkCTUa3XXrNXaDVzPcja2tdJtRdxRr8T4eZjVKqk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37394/large/gome.jpeg?1714308847",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "game-of-memes",
    },
  },
  {
    chainId: 101,
    name: "Gari Network",
    symbol: "GARI",
    address: "CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22615/large/gari.png?1696521931",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "gari-network",
    },
  },
  {
    chainId: 101,
    name: "Gary",
    symbol: "GARY",
    address: "8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26448/large/gary_logo_icon.png?1696525521",
    tags: [],
    verified: true,
    holders: 1126,
    extensions: {
      coingeckoId: "gary",
    },
  },
  {
    chainId: 101,
    name: "Gecko (Meme)",
    symbol: "GECKO",
    address: "6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33712/large/1gecko-coin-logo-compressed.png?1702867288",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gecko-meme",
    },
  },
  {
    chainId: 101,
    name: "Gemach",
    symbol: "GMAC",
    address: "5zbUuCeYLHVApzANWNSXkKwcEoZsbMghoh8z8HbWTJbJ",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32334/large/gmac-200x200.png?1697452788",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gemach",
    },
  },
  {
    chainId: 101,
    name: "Gem Finder",
    symbol: "FINDER",
    address: "Dn7mshRUg4LEq1RfMLz27ViL2P16hn8p12bGBVZsiyak",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36538/large/output-onlinepngtools_%281%29.png?1711707971",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gem-finder",
    },
  },
  {
    chainId: 101,
    name: "Generational Wealth",
    symbol: "WEALTH",
    address: "2YuSzANgyU9rkFJn5aiAPJqN1kHgtZVQb4nWs1JLjLCw",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35482/large/GW_Profile-With_Text.png?1708890048",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "generational-wealth-2",
    },
  },
  {
    chainId: 101,
    name: "Shadow Token",
    symbol: "SHDW",
    address: "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22271/large/Property_1_Color.png?1696521615",
    tags: [],
    verified: true,
    holders: 44013,
    extensions: {
      coingeckoId: "genesysgo-shadow",
    },
  },
  {
    chainId: 101,
    name: "Genopets KI",
    symbol: "KI",
    address: "kiGenopAScF8VF31Zbtx2Hg8qA5ArGqvnVtXb83sotc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26135/large/genopets_ki.png?1696525223",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "genopet-ki",
    },
  },
  {
    chainId: 101,
    name: "Genopets",
    symbol: "GENE",
    address: "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20360/large/gene-token.png?1696519768",
    tags: [],
    verified: true,
    holders: 166926,
    extensions: {
      coingeckoId: "genopets",
    },
  },
  {
    chainId: 101,
    name: "GENZ Token",
    symbol: "GENZ",
    address: "GENZexWRRGNS2Ko5rEgGG1snRXpaa3CDDGYnhTSmE3kd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28039/large/Qq_rj-aG_400x400.png?1696527054",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "genz-token",
    },
  },
  {
    chainId: 101,
    name: "Germany Coin",
    symbol: "GER",
    address: "52DfsNknorxogkjqecCTT3Vk2pUwZ3eMnsYKVm4z3yWy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37621/large/germ_flag.png?1715107895",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "germany-coin",
    },
  },
  {
    chainId: 101,
    name: "Gerta",
    symbol: "GERTA",
    address: "DLvWoNT1d5iSX2T1aUUBzRdGbSef2xW3MwJBKtewVW6h",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37733/large/IMG_20240511_174511_683.png?1715717218",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gerta",
    },
  },
  {
    chainId: 101,
    name: "gh0stc0in",
    symbol: "GHOST",
    address: "HbxiDXQxBKMNJqDsTavQE7LVwrTR36wjV2EaYEqUw6qH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35125/large/GHOST.jpg?1707412992",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gh0stc0in",
    },
  },
  {
    chainId: 101,
    name: "Ghislaine Network",
    symbol: "GHSI",
    address: "DXQwx4gwfMa2qTpeatyxve4WrxAVhHbSWLoFi7HQymsA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35573/large/GHSI_logo.jpg?1709193813",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ghislaine-network",
    },
  },
  {
    chainId: 101,
    name: "GhostKids",
    symbol: "BOO",
    address: "boooCKXQn9YTK2aqN5pWftQeb9TH7cj7iUKuVCShWQx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28814/large/logo-BOO.png?1696527790",
    tags: [],
    verified: true,
    holders: 3181,
    extensions: {
      coingeckoId: "ghostkids",
    },
  },
  {
    chainId: 101,
    name: "Ghostwifhat",
    symbol: "GIF",
    address: "Cc3voFEFTrGmnhnP8x77ncZ6pivG8Kk9ir1t8AhBBaby",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33774/large/GIF.jpg?1702974780",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ghostwifhat",
    },
  },
  {
    chainId: 101,
    name: "Gigachad",
    symbol: "GIGA",
    address: "63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34755/large/IMG_0015.png?1705957165",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gigachad-2",
    },
  },
  {
    chainId: 101,
    name: "GigaDAO",
    symbol: "GIGS",
    address: "9U8Bn6zAf6Wyp1YHdXtLyfbN7yMvdvW1qQY475iZ5ftZ",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32955/large/2023-11-12_21.24.46.jpg?1699942546",
    tags: [],
    verified: true,
    holders: 1000,
    extensions: {
      coingeckoId: "gigadao",
    },
  },
  {
    chainId: 101,
    name: "giggleched",
    symbol: "CHED",
    address: "12mcpYL84oMi8Hiinyjuv2Zq3F47tLLxjw1THLcAdKT2",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38382/large/giggleched.png?1717312164",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "giggleched",
    },
  },
  {
    chainId: 101,
    name: "Giko Cat",
    symbol: "GIKO",
    address: "3WPep4ufaToK1aS5s8BL9inzeUrt4DYaQCiic6ZkkC1U",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38088/large/giko200x200.png?1716482183",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "giko-cat",
    },
  },
  {
    chainId: 101,
    name: "Gingers Have No Sol",
    symbol: "GINGER",
    address: "Ah1zCwmz1fm4A7Xt8WCHXtgPJshS14eCqvpmXD2xjCqs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34567/large/photo_2024-01-15_09-58-29.jpg?1705407440",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gingers-have-no-sol",
    },
  },
  {
    chainId: 101,
    name: "Giveth",
    symbol: "GIV",
    address: "3Xi3EhKjnKAk2KTChzybUSWcLW6eAgTHyotHH1U6sJE1",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21792/large/GIVToken_200x200.png?1696521145",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "giveth",
    },
  },
  {
    chainId: 101,
    name: "GLEEK",
    symbol: "GLEEK",
    address: "4ACuWnJZjE1Q51589mBmmyfD82RZ4LNFVeuPdSRFPc3L",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35173/large/soyjak_gleek.png?1707748890",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gleek",
    },
  },
  {
    chainId: 101,
    name: "XGLI DAO Protocol",
    symbol: "XGLI",
    address: "FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23292/large/xgli-dao.png?1696522510",
    tags: [],
    verified: true,
    holders: 7661,
    extensions: {
      coingeckoId: "glitter-finance",
    },
  },
  {
    chainId: 101,
    name: "Glub",
    symbol: "GLUB",
    address: "2ee2GwuKBEGWUxSwQeSxT3NjG3rak3ULx8kHjVUR26Jv",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37787/large/geckoimg.png?1715586310",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "glub",
    },
  },
  {
    chainId: 101,
    name: "GME",
    symbol: "GME",
    address: "8wXtPeU6557ETkp9WHFY1n1EcU6NxDvbAggHGsMYiHsB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34930/large/GMEstop_%281%29_%281%29.png?1706689237",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gme",
    },
  },
  {
    chainId: 101,
    name: "gmeow cat",
    symbol: "GMEOW",
    address: "A5LCTQ1vJECCQWSXJYs3rfCoexctbUgTCAEKDuNye8bZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36679/large/gecko.png?1712063783",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gmeow-cat",
    },
  },
  {
    chainId: 101,
    name: "gmichi",
    symbol: "GMICHI",
    address: "9WoZqQfTmhU9VLzixaBhWnKA1QTySkSPpK3aortAHrP5",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37881/large/1000008584.png?1715840216",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gmichi",
    },
  },
  {
    chainId: 101,
    name: "GM Machine",
    symbol: "GM",
    address: "3acxNNmfdKKZj9i35P4VDBFm74Ufdt8ojKWceVGynwC5",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33530/large/_GM_logo_CoinGecko.png?1702255155",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gm-machine",
    },
  },
  {
    chainId: 101,
    name: "GnomeLand",
    symbol: "GNOME",
    address: "2D7jZLpUUAMboqzHMeyaxZfLWpf4JZUoR4i82Zva7sxU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35201/large/logopng.png?1707841763",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gnomeland",
    },
  },
  {
    chainId: 101,
    name: "goblintown",
    symbol: "GOBLINTOWN",
    address: "DuuqHsjokmeRhPYGnJmgUe1qBwteNvanAqRiZCaKptcV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36805/large/goblintown_%281%29.png?1712473486",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "goblintown",
    },
  },
  {
    chainId: 101,
    name: "gogowifcone",
    symbol: "GOGO",
    address: "Byb1W95xDYBiF6gMJoTRgpD7Z14GFna2QLHoZfEWNbfQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36419/large/IMG_8958.PNG?1711421603",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gogowifcone",
    },
  },
  {
    chainId: 101,
    name: "Golazo.world",
    symbol: "GOL",
    address: "9sjyR4GrozeV8a9xM3ykKPGPXJYASy9AuufzefCyaCnP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37541/large/main.png?1714706333",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "golazo-world",
    },
  },
  {
    chainId: 101,
    name: "Golden Celestial Ratio",
    symbol: "GCR",
    address: "7dGbPgUxKpB5qWiLRKcTQSC3om1fPzUpgGAFfwej9hXx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38132/large/IMG_20240520_232131_083.jpg?1716570195",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "golden-celestial-ratio",
    },
  },
  {
    chainId: 101,
    name: "Gomu Gator",
    symbol: "GOMU",
    address: "Bx74hpFiaiBbSonrjyqxjGfAA7gRxM2CeKiy31uN6biR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37582/large/IMG_8540.jpeg?1714966541",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gomu-gator",
    },
  },
  {
    chainId: 101,
    name: "Good Boy",
    symbol: "BOY",
    address: "GmEXg8FwpULzVb15r41CNB5qFnRcXCrEyZSmD3X7sv2e",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37608/large/CG_Listing_Logo.png?1715048735",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "good-boy",
    },
  },
  {
    chainId: 101,
    name: "GOON",
    symbol: "GOON",
    address: "8cyS5xMeWSCAKSe5QpQ5XMfvxRCcSj3mbHtbtyrqk7RA",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34187/large/IMG_9484.png?1704260985",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "goon",
    },
  },
  {
    chainId: 101,
    name: "GooseFX",
    symbol: "GOFX",
    address: "GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19793/large/0Kjm9f4.png?1696519215",
    tags: [],
    verified: true,
    holders: 11324,
    extensions: {
      coingeckoId: "goosefx",
    },
  },
  {
    chainId: 101,
    name: "Gotti Token",
    symbol: "GOTTI",
    address: "FoAnSCG6CcqTq2rsTi58yyYBNk1HgsbLzS6b1kTP2ACL",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37303/large/new_logo1.jpeg?1713951933",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gotti-token",
    },
  },
  {
    chainId: 101,
    name: "GPU Inu",
    symbol: "GPUINU",
    address: "6AjB9gafah5aBBsogmxmLyeQkrRDejKzxETxJzRRKF1H",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35663/large/GPUInu.png?1709462459",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gpu-inu",
    },
  },
  {
    chainId: 101,
    name: "Grape Protocol",
    symbol: "GRAPE",
    address: "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18149/large/fRsuAlcV_400x400.png?1696517651",
    tags: [],
    verified: true,
    holders: 43360,
    extensions: {
      coingeckoId: "grape-2",
    },
  },
  {
    chainId: 101,
    name: "Graphite Protocol",
    symbol: "GP",
    address: "31k88G5Mq7ptbRDf3AM13HAq6wRQHXHikR8hik7wPygk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30359/large/tweet_to_image_pp_%284%29.jpeg?1696529258",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "graphite-protocol",
    },
  },
  {
    chainId: 101,
    name: "STEPN Green Satoshi Token on Solana",
    symbol: "GST-SOL",
    address: "AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21841/large/gst.png?1696521196",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "green-satoshi-token",
    },
  },
  {
    chainId: 101,
    name: "greg16676935420",
    symbol: "GREG",
    address: "zZRRHGndBuUsbn4VM47RuagdYt57hBbskQ2Ba6K5775",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37583/large/photo_5816561157299881863_c.jpg?1714967974",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "greg16676935420",
    },
  },
  {
    chainId: 101,
    name: "Grimreaper",
    symbol: "GRIM",
    address: "5yfDdiW65gVXzrwt88AiWfXuVnCk3PPmzG7SXLDhs6pS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36214/large/grim.png?1710836600",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "grimreaper",
    },
  },
  {
    chainId: 101,
    name: "GROK",
    symbol: "GROK",
    address: "BQ3F72yt9FVRgYrqCVCG3YohyBesDZ9bTuhGdmQ7GNEF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33213/large/IMG_20231125_111610_823.jpg?1701085144",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "grok-6",
    },
  },
  {
    chainId: 101,
    name: "GrowSol",
    symbol: "GRW",
    address: "FPymkKgpg1sLFbVao4JMk4ip8xb8C8uKqfMdARMobHaw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38129/large/IMG_2969.png?1716569771",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "growsol",
    },
  },
  {
    chainId: 101,
    name: "GRUG",
    symbol: "GRUG",
    address: "7LtgzCmSpGocBajm84WghiWbu1NcvQxdqLQtUXFSXgvU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37907/large/7LtgzCmSpGocBajm84WghiWbu1NcvQxdqLQtUXFSXgvU_%281%29.png?1715934336",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "grug",
    },
  },
  {
    chainId: 101,
    name: "Kugle GU",
    symbol: "GU",
    address: "5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17532/large/Logo_GU_512.png?1696517069",
    tags: [],
    verified: true,
    holders: 897,
    extensions: {
      coingeckoId: "gu",
    },
  },
  {
    chainId: 101,
    name: "Guacamole",
    symbol: "GUAC",
    address: "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30119/large/Guacamole_Twitter_Icon.png?1715041813",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "guacamole",
    },
  },
  {
    chainId: 101,
    name: "GUMMY",
    symbol: "GUMMY",
    address: "H7bTHGb5Cvo5fGe5jBDNDPUv8KykQnzyZA3qZ8sH7yxw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37191/large/GUMMY_UPDATE_LOGO_ORI.png?1713672760",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gummy",
    },
  },
  {
    chainId: 101,
    name: "Guufy",
    symbol: "GUUFY",
    address: "inLbkByUvP9WGsLfMy1w9QX5pvaab5mkvLcz48DbkmF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37063/large/200_x_200_%281%29.png?1713176017",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "guufy",
    },
  },
  {
    chainId: 101,
    name: "GYEN",
    symbol: "GYEN",
    address: "Crn4x1Y2HUKko7ox2EZMT6N2t2ZyH7eKtwkBGVnhEq1g",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14191/large/icon_gyen_200_200.png?1696513909",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "gyen",
    },
  },
  {
    chainId: 101,
    name: "Habbolana",
    symbol: "HABBO",
    address: "5mQgm6tLdnFxVGEGFE87h7BVBmVNpoWDazmEzAkUpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38571/large/Logo_H-04-200x200.png?1718008882",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "habbolana",
    },
  },
  {
    chainId: 101,
    name: "Habibi (Sol)",
    symbol: "HABIBI",
    address: "864YJRb3JAVARC4FNuDtPKFxdEsYRbB39Nwxkzudxy46",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37155/large/t.mehabibixyz_sol.png?1713499221",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "habibi-sol",
    },
  },
  {
    chainId: 101,
    name: "HachikoSolana",
    symbol: "HACHI",
    address: "6bzBfcyqmf8SqvtGN4SoLsT3knNhNycseuyZzKzjQnSa",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36518/large/l1.png?1711694888",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hachikosolana",
    },
  },
  {
    chainId: 101,
    name: "Hades",
    symbol: "HADES",
    address: "BWXrrYFhT7bMHmNBFoQFWdsSgA3yXoAnMhDK6Fn1eSEn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28819/large/hadeswap.jpeg?1696527795",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hades",
    },
  },
  {
    chainId: 101,
    name: "HAGGORD",
    symbol: "HAGGORD",
    address: "AYyYgh3i43s1QSpvG4vwhJ6s3gewfN7uteFwYrswgMGw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35412/large/PX8z5fs.png?1708503583",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "haggord",
    },
  },
  {
    chainId: 101,
    name: "HAIRYPOTHEADTREMPSANIC69INU",
    symbol: "SOLANA",
    address: "6kF8f6nH1QH9yV3gFy9PACh2pNH1s48FTEmo79S9rxZN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36272/large/IMG_9266.jpeg?1711003843",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hairypotheadtrempsanic69inu",
    },
  },
  {
    chainId: 101,
    name: "Haku Ryujin",
    symbol: "HAKU",
    address: "CXrdC7JiLKvpa6CshEskorEDqkbZJjb3LJV3KUVVEiMs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36319/large/%D9%A2%D9%A0%D9%A2%D9%A4%D9%A0%D9%A3%D9%A1%D9%A9_%D9%A0%D9%A5%D9%A1%D9%A8%D9%A3%D9%A7.jpg?1711092717",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "haku-ryujin",
    },
  },
  {
    chainId: 101,
    name: "Halvi Solana",
    symbol: "HALVI",
    address: "6hL1q5stWQyNz5KZcrzxqPsS6tm3a7MmwQ3wFtAqGr1e",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36945/large/halvi_400x400_%281%29.jpg?1712893281",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "halvi-solana",
    },
  },
  {
    chainId: 101,
    name: "HAMI",
    symbol: "$HAMI",
    address: "4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36491/large/hamipng.png?1711570068",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hami",
    },
  },
  {
    chainId: 101,
    name: "HAMSTER WIF HAT",
    symbol: "WIF",
    address: "GLXkHLX4jiVRAYBVwx2UAvKRBu2YDCQudNiR3k9q9THH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38562/large/Hamster_Wif_Hat.png?1718002628",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hamster-wif-hat",
    },
  },
  {
    chainId: 101,
    name: "Hank",
    symbol: "HANK",
    address: "231Qq1ZzBRXeSmwa1HJCFrm7v5cNUhyYuz8va42vtdQY",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37646/large/IMG_0545.jpeg?1715143118",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hank",
    },
  },
  {
    chainId: 101,
    name: "HAPI",
    symbol: "HAPI",
    address: "6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14298/large/Hapi_logo_square_%281%29.png?1696513995",
    tags: [],
    verified: true,
    holders: 3600,
    extensions: {
      coingeckoId: "hapi",
    },
  },
  {
    chainId: 101,
    name: "happi cat",
    symbol: "HAPPI",
    address: "aBvs3Zv9JYmvUCKqRJvGctDQSCt6R7NAMELid3FeqsQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36022/large/happi.png?1710425717",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "happi-cat",
    },
  },
  {
    chainId: 101,
    name: "Harambe on Solana",
    symbol: "HARAMBE",
    address: "Fch1oixTPri8zxBnmdCEADoJW2toyFHxqDZacQkwdvSP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34668/large/haram_%281%29.jpg?1705655145",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "harambe-2",
    },
  },
  {
    chainId: 101,
    name: "HarambeCoin",
    symbol: "HARAMBE",
    address: "CNL2opdqaQDY7yudm5V4MAaTwuuY32LTKqNP93jZM8X6",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36043/large/HarambeCoin_full_cameo_200.png?1710400612",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "harambecoin",
    },
  },
  {
    chainId: 101,
    name: "Harold",
    symbol: "HAROLD",
    address: "EjmDTt8G3T725eFSV7oWmGD8J848guo3LZ1EB3RfwGSw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36698/large/2024-03-27_20.34.08.jpg?1712117457",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "harold",
    },
  },
  {
    chainId: 101,
    name: "HarryPotterWifHatMyroWynn",
    symbol: "SOLANA",
    address: "4acvcTQhR2Z3TWauJZHUFaubyyEBtoybrp5QLobuCkcH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34695/large/db2.png?1705857587",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "harrypotterwifhatmyrowynn",
    },
  },
  {
    chainId: 101,
    name: "HashBit",
    symbol: "HBIT",
    address: "Hd8crL1e3KnYEWvHBx7B2TSsadkQuFr52CwXXxZJyCv1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36599/large/hbit.png?1712165088",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hashbit-2",
    },
  },
  {
    chainId: 101,
    name: "HAT Solana",
    symbol: "HAT",
    address: "HrqgFZipMFHXvN5nKvTUaCwuA3Tp2UGqcQzArRGAyQ22",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35687/large/jpgtopngconverter-com_4.jpg?1709532187",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hat-solana",
    },
  },
  {
    chainId: 101,
    name: "Hawksight",
    symbol: "HAWK",
    address: "BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24459/large/3CnlKM0x_400x400.jpg?1696523639",
    tags: [],
    verified: true,
    holders: 38880,
    extensions: {
      coingeckoId: "hawksight",
    },
  },
  {
    chainId: 101,
    name: "HDOKI",
    symbol: "OKI",
    address: "GJQpf6Zjvokd3YK5EprXqZUah9jxkn8aG4pTeWL7Gkju",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32581/large/image_2024-02-16_153309401.png?1708069010",
    tags: [],
    verified: true,
    holders: 5864,
    extensions: {
      coingeckoId: "hdoki",
    },
  },
  {
    chainId: 101,
    name: "Heavenland HTO",
    symbol: "HTO",
    address: "htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25553/large/rTFh6BD.png?1696524686",
    tags: [],
    verified: true,
    holders: 4968,
    extensions: {
      coingeckoId: "heavenland-hto",
    },
  },
  {
    chainId: 101,
    name: "HEDGE on Sol",
    symbol: "HEDGE",
    address: "99fp2z9TANArLJR4hcwx8fJSzjz7GQwUn7huGf32nDdv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34224/large/HEDGE_LOGO_WITH_BG.png?1704298657",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hedge-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Hedge USD",
    symbol: "USH",
    address: "9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25481/large/ush.png?1696524615",
    tags: [],
    verified: true,
    holders: 1119,
    extensions: {
      coingeckoId: "hedge-usd",
    },
  },
  {
    chainId: 101,
    name: "HeeeHeee",
    symbol: "HEEHEE",
    address: "9dLuVbJMd4ZpTpFgmaFHAGSsFwVjtcnzFWaLAA1expHg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37129/large/MHehlsrE_400x400.jpg?1713384529",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "heeeheee",
    },
  },
  {
    chainId: 101,
    name: "Hege",
    symbol: "$HEGE",
    address: "ULwSJmmpxmnRfpu6BjnK6rprKXqD5jXUmPpS1FxHXFy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36873/large/download.png?1712646256",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hege",
    },
  },
  {
    chainId: 101,
    name: "HeHe",
    symbol: "HEHE",
    address: "v8XouYEkprZS2SQvJk88RiZiYKvEGRGkD7c6ZM5nzEz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36227/large/logo.jpg?1710877750",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hehe",
    },
  },
  {
    chainId: 101,
    name: "Helium",
    symbol: "HNT",
    address: "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/4284/large/Helium_HNT.png?1696504894",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "helium",
    },
  },
  {
    chainId: 101,
    name: "Helium IOT",
    symbol: "IOT",
    address: "iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29880/large/Iot_Icon.png?1696528805",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "helium-iot",
    },
  },
  {
    chainId: 101,
    name: "Helium Mobile",
    symbol: "MOBILE",
    address: "mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29879/large/Mobile_Icon.png?1696528804",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "helium-mobile",
    },
  },
  {
    chainId: 101,
    name: "Helius Staked SOL",
    symbol: "HSOL",
    address: "he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37480/large/solana.png?1714472253",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "helius-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Her.AI",
    symbol: "HER",
    address: "5WieRrJ5oFthgxaH4nCEkwJiBTxuRpEn9qmFRkVzkc98",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33382/large/200.png?1701670378",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "her-ai",
    },
  },
  {
    chainId: 101,
    name: "High Roller Hippo Clique",
    symbol: "ROLL",
    address: "76aYNHbDfHemxSS7vmh6eJGfjodK8m7srCxiYCrKxzY1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23458/large/OslGsgzd_400x400.jpg?1696522669",
    tags: [],
    verified: true,
    holders: 6350,
    extensions: {
      coingeckoId: "high-roller-hippo-clique",
    },
  },
  {
    chainId: 101,
    name: "Hiram",
    symbol: "HIRAM",
    address: "GDsVXtyt2CBwieKSYMEsjjZXXvqz2G2VwudD7EvXzoEU",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23457/large/logo_%281%29.png?1696522668",
    tags: [],
    verified: true,
    holders: 544,
    extensions: {
      coingeckoId: "hiram",
    },
  },
  {
    chainId: 101,
    name: "Hive Game Token",
    symbol: "HGT",
    address: "HGTxJQKzuMjzKkHVpRLcFHwE6ngVCJbcP35FDQtmRzhx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34780/large/icon_watermark.png?1706007941",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hive-game-token",
    },
  },
  {
    chainId: 101,
    name: "Hivemapper",
    symbol: "HONEY",
    address: "4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28388/large/honey.png?1696527388",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hivemapper",
    },
  },
  {
    chainId: 101,
    name: "HmmOnSOL",
    symbol: "HMM",
    address: "BWhsvkyrUJqVvrAKjGYLpnTuUCG4SPEh6xVKcjnYCi27",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37221/large/hmm_%281%29.jpg?1713774475",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hmmonsol",
    },
  },
  {
    chainId: 101,
    name: "HODL",
    symbol: "HODL",
    address: "58UC31xFjDJhv1NnBF73mtxcsxN92SWjhYRzbfmvDREJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35049/large/HODL.png?1707207942",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hodl",
    },
  },
  {
    chainId: 101,
    name: "Hog",
    symbol: "HOG",
    address: "HogxGo1jDwvseBdYNvNBM7UYpsWJPifbH7hM5nCvBWuw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37753/large/hoghead.png?1715446184",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hog",
    },
  },
  {
    chainId: 101,
    name: "HOLD",
    symbol: "EARN",
    address: "8CuPzHKuZQUyYbear8fi3u6iuTY652WVZT2cHZ2yeNbE",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32575/large/200.png?1698554742",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hold-2",
    },
  },
  {
    chainId: 101,
    name: "HolyGrails.io",
    symbol: "HOLY",
    address: "HezGWsxSVMqEZy7HJf7TtXzQRLiDruYsheYWqoUVnWQo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28395/large/HG_Token_Design-07.png?1696527394",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "holygrails-io",
    },
  },
  {
    chainId: 101,
    name: "Homie",
    symbol: "HOMIE",
    address: "FGgiujRQMu6CUk3ybxxJ6pEZvDNVLwSGqZmFSTHGA7J",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36490/large/Twitter_Logo_%284%29.png?1711569087",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "homie",
    },
  },
  {
    chainId: 101,
    name: "Honest",
    symbol: "HNST",
    address: "hnstrzJNEeY2QoyD5D6T48kw2xYmYHwVgT61Hm5BahJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/7345/large/honest-mining.png?1696507629",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "honest-mining",
    },
  },
  {
    chainId: 101,
    name: "Honey Finance",
    symbol: "HONEY",
    address: "HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24781/large/honey.png?1696523941",
    tags: [],
    verified: true,
    holders: 9637,
    extensions: {
      coingeckoId: "honey-finance",
    },
  },
  {
    chainId: 101,
    name: "Honeyland",
    symbol: "HXD",
    address: "3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29256/large/hxd-200x200.png?1696528210",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "honeyland-honey",
    },
  },
  {
    chainId: 101,
    name: "Honk",
    symbol: "HONK",
    address: "3ag1Mj9AKz9FAkCQ6gAEhpLSX8B2pUbPdkb9iBsDLZNB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34532/large/200_goose.png?1705323721",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "honk",
    },
  },
  {
    chainId: 101,
    name: "Hornt",
    symbol: "HORNT",
    address: "7nbJrQMK3FwJDMia93EpESHqqJiEdQqvuwnGMiwnWMJ5",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37422/large/pepebee.png?1714377415",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hornt",
    },
  },
  {
    chainId: 101,
    name: "HowdySol",
    symbol: "HOWDY",
    address: "ppVT3Vqb323UBEW3QuTvdNCpQm1spEZM8Bt1tv9WALW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36511/large/1000058087.jpg?1711691919",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "howdysol",
    },
  },
  {
    chainId: 101,
    name: "Hubble",
    symbol: "HBB",
    address: "HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22070/large/hubble.PNG?1696521414",
    tags: [],
    verified: true,
    holders: 23749,
    extensions: {
      coingeckoId: "hubble",
    },
  },
  {
    chainId: 101,
    name: "Huh cat",
    symbol: "HUHCAT",
    address: "6Ra49aqZTbEurJB1UQgAj1TjvbqajGP5um7gsTym8tWm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37845/large/LOGO1.png?1715756307",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "huh-cat",
    },
  },
  {
    chainId: 101,
    name: "HULVIN",
    symbol: "HULVIN",
    address: "BTWvZsjwgnGT7qSM3AAXxjZohCLsMHpdAhaiQMJbBvcF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37056/large/hulvin_logo.png?1713171767",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hulvin",
    },
  },
  {
    chainId: 101,
    name: "Hump",
    symbol: "HUMP",
    address: "CUsEVhFGfjr2wwqjQFd7LrowYy6UhXY2HfAppUzTsihN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36561/large/photo_2024-03-27_15.45.06.png?1711903136",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hump",
    },
  },
  {
    chainId: 101,
    name: "Hund",
    symbol: "HUND",
    address: "2XPqoKfJitk8YcMDGBKy7CMzRRyF2X9PniZeCykDUZev",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36663/large/hundlogo.png?1712043350",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hund",
    },
  },
  {
    chainId: 101,
    name: "Hunter Biden's Laptop",
    symbol: "LAPTOP",
    address: "HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38535/large/HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7.png?1717829252",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hunter-biden-s-laptop",
    },
  },
  {
    chainId: 101,
    name: "Hunter Boden",
    symbol: "HUNTBODEN",
    address: "22513u2QwiY6xaJn7nVFWGKy3aBdw6WfZsRPW2RRtCKj",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37335/large/rsz_439555773_266838546453388_7823738945499639295_n.jpg?1714276409",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "hunter-boden",
    },
  },
  {
    chainId: 101,
    name: "Huobi BTC (Wormhole)",
    symbol: "HBTC",
    address: "7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23069/large/HBTC_wh_small.png?1696522360",
    tags: [],
    verified: true,
    holders: 222,
    extensions: {
      coingeckoId: "huobi-btc-wormhole",
    },
  },
  {
    chainId: 101,
    name: "HXRO",
    symbol: "HXRO",
    address: "HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/7805/large/X_3D_Black_%2810%29.png?1696508044",
    tags: [],
    verified: true,
    holders: 1069,
    extensions: {
      coingeckoId: "hxro",
    },
  },
  {
    chainId: 101,
    name: "I Choose Rich Everytime",
    symbol: "NICK",
    address: "G3q2zUkuxDCXMnhdBPujjPHPw9UTMDbXqzcc2UHM3jiy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37805/large/I_CHOOSE_RICH_EVERYTIME.png?1715591394",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "i-choose-rich-everytime",
    },
  },
  {
    chainId: 101,
    name: "i dont know",
    symbol: "IDK",
    address: "CymqTrLSVZ97v87Z4W3dkF4ipZE1kYyeasmN2VckUL4J",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36216/large/CymqTrLSVZ97v87Z4W3dkF4ipZE1kYyeasmN2VckUL4J.png?1710837647",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "i-dont-know",
    },
  },
  {
    chainId: 101,
    name: "IDRX",
    symbol: "IDRX",
    address: "idrxTdNftk6tYedPv2M7tCFHBVCpk5rkiNRd8yUArhr",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34883/large/LOGO_COIN_2_4x.png?1706522103",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "idrx",
    },
  },
  {
    chainId: 101,
    name: "IMARO",
    symbol: "IMARO",
    address: "3Gjckk5jXnJffBruUS2EEYhpiDEN6z5TPXLkFVHkSkkg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38277/large/200x200_IMARO.png?1716977043",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "imaro",
    },
  },
  {
    chainId: 101,
    name: "Im Not A Ket",
    symbol: "NOTKET",
    address: "7ohSGbjL8tQmaq4Cz9ZqD3J3yo4XeuGpt2DsU9cBA1X6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37901/large/Notket_Logo_4_4.png?1715879776",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "im-not-a-ket",
    },
  },
  {
    chainId: 101,
    name: "impactMarket",
    symbol: "PACT",
    address: "HPuAFkJ3TqPHYF3BMzp8ps8RgrseTmUTDgJGUSfXfw2w",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21907/large/PACT_Token_Ticker_Blue_2x.png?1696521258",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "impactmarket",
    },
  },
  {
    chainId: 101,
    name: "Inbred Cat",
    symbol: "INBRED",
    address: "EjzzyCSiLqjFDprpZj8e1zjXmcTG5HPGFRSEoWcJWHh9",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37605/large/inbred_cat2.jpg?1714986694",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "inbred-cat",
    },
  },
  {
    chainId: 101,
    name: "Kennedy Memecoin",
    symbol: "BOBBY",
    address: "4geJykZY92d2mZk8zgWDrKoz4BDcSjp7DJdNvH8GoH5f",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37105/large/bobby.jpeg?1717750141",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "independence-token",
    },
  },
  {
    chainId: 101,
    name: "Indian Call Center",
    symbol: "ICC",
    address: "EQGG5muKhviWmWJwy4Fi9TeeNpJUX7RpHAwkQMnTAyAj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36768/large/logopng.png?1712284918",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "indian-call-center",
    },
  },
  {
    chainId: 101,
    name: "Insane Labz",
    symbol: "LABZ",
    address: "4VC7UYqBo9Siw8ZnkPXfw9D3dzYCiVrPDzs9XRtyRJMH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37636/large/200_x200.png?1715104902",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "insane-labz",
    },
  },
  {
    chainId: 101,
    name: "inSOLvent",
    symbol: "INSOLVENT",
    address: "A2keC3iPQkCdQxfDu4iMPMRbLWQLpMUSgTDh5GcZf9Mm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33865/large/Untitled_design.png?1703130400",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "insolvent",
    },
  },
  {
    chainId: 101,
    name: "International Stable Currency",
    symbol: "ISC",
    address: "J9BcrQfX4p9D1bvLzRNCbMDv8f44a9LFdeqNE4Yk2WMD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31087/large/coin_flat.png?1696529920",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "international-stable-currency",
    },
  },
  {
    chainId: 101,
    name: "Internet",
    symbol: "NET",
    address: "CcdBdzNBXc8GgKqy9DA8YnXmA1HFmaRTtdfF2uPH3Lkf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35394/large/DEX_LOGO.png?1708454101",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "internet",
    },
  },
  {
    chainId: 101,
    name: "Investin",
    symbol: "IVN",
    address: "iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15588/large/ivn_logo.png?1696515223",
    tags: [],
    verified: true,
    holders: 4847,
    extensions: {
      coingeckoId: "investin",
    },
  },
  {
    chainId: 101,
    name: "Invoker",
    symbol: "IV",
    address: "invSTFnhB1779dyku9vKSmGPxeBNKhdf7ZfGL1vTH3u",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23396/large/inv.png?1696522610",
    tags: [],
    verified: true,
    holders: 2357,
    extensions: {
      coingeckoId: "invoke",
    },
  },
  {
    chainId: 101,
    name: "Ionet",
    symbol: "IO",
    address: "BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37754/large/io_blackbg.png?1715446363",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "io",
    },
  },
  {
    chainId: 101,
    name: "IQ50",
    symbol: "IQ50",
    address: "21rweMLGYeMNonHW7H3xa5py17X6ZFRcHirCp9inRBQA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36184/large/AbVsOUMX_400x400.jpg?1710758729",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "iq50",
    },
  },
  {
    chainId: 101,
    name: "Italian Coin",
    symbol: "ITA",
    address: "GuVoE2qAS3DHaAGSeuZfBkbLjFXfP46DFbogbrVJNHfN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38276/large/bandiera_ita.jpg?1716976817",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "italian-coin",
    },
  },
  {
    chainId: 101,
    name: "its as shrimple as that",
    symbol: "SHRIMPLE",
    address: "25vHoSeyJeroPYaTf72o97rCKmyEoGK7iNNHDAv6zYy6",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34282/large/lol3.png?1704453076",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "its-as-shrimple-as-that",
    },
  },
  {
    chainId: 101,
    name: "Rock",
    symbol: "ROCK",
    address: "rockaPXZqTqtt9LvznNopikNQn3YSTMwTC4swr7HAiy",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33881/large/NEuKIdUd_400x400.jpg?1703147247",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "it-s-just-a-rock",
    },
  },
  {
    chainId: 101,
    name: "Jack The Goat",
    symbol: "JACK",
    address: "68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37765/large/AXE2PtcD_400x400.jpg?1715480001",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jack-the-goat",
    },
  },
  {
    chainId: 101,
    name: "Jail Cat",
    symbol: "CUFF",
    address: "2VYVwrwSNM8WxbFdPU4KQpZUB9FWCenFFoDqvpHQ7rZE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38257/large/CUFF_TICKER_IMAGE.jpg?1716927962",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jail-cat",
    },
  },
  {
    chainId: 101,
    name: "Jalapeno Finance",
    symbol: "JALA",
    address: "4UQgPwwcyTfELvJViUUJEa229K7RQEzCrc4Cnuxa7zxs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36372/large/jl.png?1711339746",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jalapeno-finance",
    },
  },
  {
    chainId: 101,
    name: "Japan Coin",
    symbol: "JAPAN",
    address: "EHCwJQi8dSpZfKm4LJypzozEj5vAN7pESRXJGpESKMfJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37637/large/IMG_2215.jpeg?1715105408",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "japan-coin",
    },
  },
  {
    chainId: 101,
    name: "Jason (Sol)",
    symbol: "JASON",
    address: "HLQR9Rc7rzfiydRs5qaJGoKniu64zCNN75v5ju4HF2ap",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36233/large/jason_pfpf_200_x_200.png?1710906729",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jason-sol",
    },
  },
  {
    chainId: 101,
    name: "Jeeter on solana",
    symbol: "$JEET",
    address: "3sWrLT3PTrJarvykwhGhLATMzuDBkbh15aquy1Q2QwYc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38011/large/photo_2024-05-11_20-08-17.jpg?1716273921",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jeeter-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Jefe",
    symbol: "JEFE",
    address: "9RyCXca1aMBknh5285ybDgr9xxQhHxzXfghLq4HS2wUx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35379/large/1000027133.jpg?1708421044",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jefe-2",
    },
  },
  {
    chainId: 101,
    name: "Jensen Huang (Meme)",
    symbol: "JENSEN",
    address: "HfQuaLjMguh7vyZqqrWuVqpsjQd7tAPrzBWKqA3pDTH3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38339/large/Jensen_coin_gecko_img.jpg?1717129612",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jensen-huang-meme",
    },
  },
  {
    chainId: 101,
    name: "Jeo Boden",
    symbol: "BODEN",
    address: "3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35872/large/boden.jpeg?1709974700",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jeo-boden",
    },
  },
  {
    chainId: 101,
    name: "JESUS ON SOL",
    symbol: "JESUS",
    address: "2c8TQe4TUf2doRXd3yQ1XEn7zhT1vWw1y41SXz6G4j7S",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33912/large/IMG-20231221-WA0002.jpg?1703310688",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jesus-on-sol",
    },
  },
  {
    chainId: 101,
    name: "JET",
    symbol: "JET",
    address: "JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18437/large/jet_logomark_color.png?1696517925",
    tags: [],
    verified: true,
    holders: 3558,
    extensions: {
      coingeckoId: "jet",
    },
  },
  {
    chainId: 101,
    name: "Jill Boden",
    symbol: "JILLBODEN",
    address: "BidenWjdX6XTBNdhQNbD3hXTAHVQD8wtFXvhLCqsVMV7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36814/large/200x200_jill_pfp.png?1712548893",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jill-boden",
    },
  },
  {
    chainId: 101,
    name: "Jimmy on Solana",
    symbol: "JIMMY",
    address: "94XduSfSnyas7jAEFSJSXiCi1xQ4mENWcii1aCvjVuqu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33884/large/jimmylogo200.png?1703147940",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jimmy-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Jito",
    symbol: "JTO",
    address: "jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33228/large/jto.png?1701137022",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jito-governance-token",
    },
  },
  {
    chainId: 101,
    name: "Jito Staked SOL",
    symbol: "JITOSOL",
    address: "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28046/large/JitoSOL-200.png?1696527060",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jito-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "JJmoji (Sol)",
    symbol: "JJ",
    address: "8eAUrugF8ToBmkg4CpJjTY9AcPx1UBMdExw2Ju84MCG4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38084/large/photo_2024-05-22_12.01.35.jpeg?1716476920",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jjmoji-2",
    },
  },
  {
    chainId: 101,
    name: "Joeing737",
    symbol: "JEOING737",
    address: "H2ZpBXtzk1DaDTzsoXqMhjN6Bd3qzgefHjUZ1e2zuwAe",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38411/large/joeing737_200x200.jpg?1717471522",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "joeing737",
    },
  },
  {
    chainId: 101,
    name: "Joel",
    symbol: "JOEL",
    address: "JACSU5f2fCsQSCDNz1VX2Se4vmQyj8k5EYigD4RppvGV",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33935/large/Untitled_Artwork_214_%281%29.png?1703501010",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "joel",
    },
  },
  {
    chainId: 101,
    name: "john pork",
    symbol: "PORK",
    address: "2kSmCB5PrswNvN5vrN4ayb2DnVbeFmNhX7QuHReeGKYy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34229/large/download_%281%29.jpeg?1704301497",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "john-pork",
    },
  },
  {
    chainId: 101,
    name: "John the Coin",
    symbol: "JOHN",
    address: "BuxH23osRyFFLbWG3czrTsfBQYbxzVZ8f7QV4cjTHN5x",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37450/large/john_200.png?1714447947",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "john-the-coin",
    },
  },
  {
    chainId: 101,
    name: "JORAM POOWEL",
    symbol: "POOWEL",
    address: "BHcPVARUJEV3rCAmbLgRm7QPmZotsCcHcKWwzvCSAHJi",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36834/large/povel.jpg?1712560220",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "joram-poowel",
    },
  },
  {
    chainId: 101,
    name: "JPool Staked SOL",
    symbol: "JSOL",
    address: "7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20664/large/jsol.png?1696520065",
    tags: [],
    verified: true,
    holders: 13497,
    extensions: {
      coingeckoId: "jpool",
    },
  },
  {
    chainId: 101,
    name: "Jungle",
    symbol: "JUNGLE",
    address: "Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20893/large/O9Usopi.png?1696520285",
    tags: [],
    verified: true,
    holders: 8208,
    extensions: {
      coingeckoId: "jungle",
    },
  },
  {
    chainId: 101,
    name: "Jungle DeFi",
    symbol: "JFI",
    address: "GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23679/large/logo.png?1696522881",
    tags: [],
    verified: true,
    holders: 1002,
    extensions: {
      coingeckoId: "jungle-defi",
    },
  },
  {
    chainId: 101,
    name: "JungleDoge",
    symbol: "JUNGLE",
    address: "9P32yqucXfZnDoXmmJNzBpqXQgfou4tA4sB1RaQh9cYw",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37918/large/IMG_20240516_170126_320.jpg?1715935974",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jungledoge",
    },
  },
  {
    chainId: 101,
    name: "JupBot",
    symbol: "JUPBOT",
    address: "JUPaSj9wfaCFJoyLHVQGujk5RELvn5aTNjZNc4yxfeL",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33616/large/jupbot.png?1702514958",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jupbot",
    },
  },
  {
    chainId: 101,
    name: "Jupiter",
    symbol: "JUP",
    address: "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34188/large/jup.png?1704266489",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jupiter-exchange-solana",
    },
  },
  {
    chainId: 101,
    name: "Jupiter Perpetuals Liquidity Provider Token",
    symbol: "JLP",
    address: "27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33094/large/jlp.png?1700631386",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jupiter-perpetuals-liquidity-provider-token",
    },
  },
  {
    chainId: 101,
    name: "Jupiter Staked SOL",
    symbol: "JUPSOL",
    address: "jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37482/large/jupsol.png?1714473916",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jupiter-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Jupu",
    symbol: "JUPU",
    address: "4QaUgfV66YZh2hwoFLaucFfxedYJognmmgdifjT84nY7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34221/large/smirk_copy.png?1704297558",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "jupu",
    },
  },
  {
    chainId: 101,
    name: "ju rugan",
    symbol: "JU",
    address: "AbBEjBdz31yCb9gTFfWgEi7uprCzjTEKL58xqHVbbjhu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37709/large/coingecko.png?1715274880",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ju-rugan",
    },
  },
  {
    chainId: 101,
    name: "JustAnEgg",
    symbol: "EGG",
    address: "wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36425/large/Untitled_design_%2821%29.png?1712253875",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "justanegg-2",
    },
  },
  {
    chainId: 101,
    name: "Just The Tip",
    symbol: "TIPS",
    address: "AK87oZM8ovFU14bq8mu4ufi5zsCPDbqqVvh5b6WHbUdL",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35784/large/IMG_3942_copypng_%281%29_%281%29.png?1709798418",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "just-the-tip",
    },
  },
  {
    chainId: 101,
    name: "KABAL",
    symbol: "KABAL",
    address: "EyVn9ZW86b7FMVThMPjswEjac3YqrLWkzeVZn3nRwt5c",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37690/large/Kabal_Profile_%283%29.png?1715231073",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kabal",
    },
  },
  {
    chainId: 101,
    name: "Kabosu",
    symbol: "$KABOSU",
    address: "3h4DGQd9k5gsiMnNzDuGXAcjkMm5G7iC3knykdvBBnxU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33704/large/100x100_%281%29.png?1702826635",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kabosu-3",
    },
  },
  {
    chainId: 101,
    name: "Kabosu on SOL",
    symbol: "KABOSU",
    address: "FJup6BbEBoCeFJZtqW4qcaqABLco5SkV8683do38P9tu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38187/large/input-onlinepngtools.png?1716788705",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kabosu-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Kaizen.Finance",
    symbol: "KZEN",
    address: "kZEn3aDxEzcFADPe2VQ6WcJRbS1hVGjUcgCw4HiuYSU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24396/large/PKl5OVRv_400x400.png?1696523579",
    tags: [],
    verified: true,
    holders: 1097,
    extensions: {
      coingeckoId: "kaizen",
    },
  },
  {
    chainId: 101,
    name: "Kalisten",
    symbol: "KS",
    address: "3swraHsc77KMg1tFvwH3tfYcd8SWr5fcUhtmRxjavG7H",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25486/large/kalisten_token.png?1696524619",
    tags: [],
    verified: true,
    holders: 575,
    extensions: {
      coingeckoId: "kalisten",
    },
  },
  {
    chainId: 101,
    name: "Kamala Horris",
    symbol: "KAMA",
    address: "HnKkzR1YtFbUUxM6g3iVRS2RY68KHhGV7bNdfF1GCsJB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38310/large/IMG_20240423_144352_440.jpg?1717051879",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kamala-horris",
    },
  },
  {
    chainId: 101,
    name: "Kamino",
    symbol: "KMNO",
    address: "KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35801/large/tP0Lcgwp_400x400.jpg?1709824189",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kamino",
    },
  },
  {
    chainId: 101,
    name: "Kanye",
    symbol: "YE",
    address: "CQSPU12VppGZB7LeRkEaKzrRBzvo1LyA9mR2ydiwKHaL",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34676/large/icon_large.png?1705683150",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kanye",
    },
  },
  {
    chainId: 101,
    name: "Karen",
    symbol: "KAREN",
    address: "3NMRp22ARChFUd2xPtgVLzvf1gCfCjMwb1yjra3P3SB1",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37131/large/karen_listing.png?1713406339",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "karen",
    },
  },
  {
    chainId: 101,
    name: "Karen Hates You",
    symbol: "KAREN",
    address: "DHsmQJQZP3KjMX1qeFWxHt9xexHNAejwFTQAfi91R44b",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36398/large/karen512.png?1711367794",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "karen-hates-you",
    },
  },
  {
    chainId: 101,
    name: "KatchuSol",
    symbol: "KATCHU",
    address: "CKiW2P4zngHa6fiPhMJGGhgDZm638dWotxkzgi8PsDxf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37794/large/COIN_200.png?1715589648",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "katchusol",
    },
  },
  {
    chainId: 101,
    name: "Keyboard Cat",
    symbol: "KEYCAT",
    address: "9pPE1q9EW1bMQWbHmffrzUCfRr7S82UoxNUFfA6mAZC6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34163/large/hahaha.png?1704214565",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "keyboard-cat",
    },
  },
  {
    chainId: 101,
    name: "KEYDOG",
    symbol: "$KEYDOG",
    address: "3fGoNjPohYRVPk9iJuwEW5WuURU5gmAdSRgNgeaanexQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36810/large/photo_2024-04-05_09-07-10.jpg?1712547378",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "keydog",
    },
  },
  {
    chainId: 101,
    name: "kiki (SOL)",
    symbol: "KIKI",
    address: "FePbYijSZfdHvswUhfBqztJ7kzUs5AEBMDi71xQhTtWC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38059/large/KIKI_OFFICIAL_PFP.png?1716438676",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kiki-sol",
    },
  },
  {
    chainId: 101,
    name: "Killer Bean",
    symbol: "BEAN",
    address: "4yUe8D4cG88gZzpvKJ4y9rCxF9HEa8DN7YUm5B26DHBZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34968/large/killerbean.png?1706850671",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "killer-bean",
    },
  },
  {
    chainId: 101,
    name: "Kin",
    symbol: "KIN",
    address: "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/959/large/Kin_Logo.png?1696502073",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "kin",
    },
  },
  {
    chainId: 101,
    name: "Kineko",
    symbol: "KNK",
    address: "kNkT1RDnexWqYP3EYGyWv5ZtazB8CfgGAfJtv9AQ3kz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26597/large/knk-cmc-logo.png?1696525672",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kineko-knk",
    },
  },
  {
    chainId: 101,
    name: "KING Coin",
    symbol: "KING",
    address: "9noXzpXnkyEcKF3AeXqUHTdR59V5uvrRBUZ9bwfQwxeq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30183/large/king.png?1696529102",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "king-2",
    },
  },
  {
    chainId: 101,
    name: "Kingdom of ANTs ANT Coins",
    symbol: "ANTC",
    address: "4WRST6Q2yyDP1p7Bqszg8PAAoj8j69cj7QY4QLn5Aq3o",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33494/large/antcoinlogoo200x200.png?1713989401",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kingdom-of-ants-ant-coins",
    },
  },
  {
    chainId: 101,
    name: "Kini",
    symbol: "KINI",
    address: "GV4pBbdAp1Apvf6PPFR4VvL6BtNg4E7VY91GmkHqpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38603/large/coin_image.jpg?1718098364",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kini",
    },
  },
  {
    chainId: 101,
    name: "Kith Gil",
    symbol: "GIL",
    address: "CyUgNnKPQLqFcheyGV8wmypnJqojA7NzsdJjTS4nUT2j",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37986/large/xpUgHJ5o_400x400.jpg?1716222470",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kith-gil",
    },
  },
  {
    chainId: 101,
    name: "Kitsune",
    symbol: "KIT",
    address: "AUgdt7wjBifF9vZpde7BjU6HLroCYh4SUHYc7yhheECW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36115/large/kitsunecmc.png?1710489386",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kitsune",
    },
  },
  {
    chainId: 101,
    name: "Kitten Haimer",
    symbol: "KHAI",
    address: "3TWgDvYBL2YPET2LxnWAwsMeoA8aL4DutNuwat2pKCjC",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35900/large/LOGO_200.jpg?1710165599",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kitten-haimer",
    },
  },
  {
    chainId: 101,
    name: "KittenWifHat",
    symbol: "KITTENWIF",
    address: "9a8AmDfQxFJ44RrVhKdiXnkwCWT99igWp5QrRuC73Pin",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37107/large/200x200.png?1717274000",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kittenwifhat",
    },
  },
  {
    chainId: 101,
    name: "KITTI",
    symbol: "KITTI",
    address: "B5Fvzd2RL5ctrmFsvDafXiNGbBqbxapiryJo8JfoSEcA",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29149/large/kittt.jpeg?1696528109",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kitti",
    },
  },
  {
    chainId: 101,
    name: "Kitty AI",
    symbol: "KITTY",
    address: "8yJ15ee2AUQmwbWPxXLTTeBTzyMGn4MtSRKMqVHw1J1G",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34137/large/Untitled_design%2813%29.png?1704289320",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kitty-ai",
    },
  },
  {
    chainId: 101,
    name: "Kitty Coin Solana",
    symbol: "KITTY",
    address: "6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20819/large/brand-1.png?1696520211",
    tags: [],
    verified: true,
    holders: 5078,
    extensions: {
      coingeckoId: "kitty-coin-solana",
    },
  },
  {
    chainId: 101,
    name: "Kittywifhat",
    symbol: "KWH",
    address: "9yYyPaSyrXcqZJQtiHRqvuWtuUAfsT1UZR3bzcPWjMzu",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37991/large/KITTYWIFHAT.png?1716223862",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kittywifhat",
    },
  },
  {
    chainId: 101,
    name: "KIWI Token",
    symbol: "KIWI",
    address: "66Qq2qS67K4L5xQ3xUTinCyxzdPeZQG1R1ipK8jrY7gc",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35131/large/kiwi.jpeg?1707442051",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kiwi-token-2",
    },
  },
  {
    chainId: 101,
    name: "KNOB$",
    symbol: "KNOB",
    address: "5ritAPtFPqQtEFHcHVqNjR5oFNUJqcmgKtZyPd2AyLLy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33615/large/AD703A54-9F21-4B0A-814E-626C7B680CE3.jpeg?1702514765",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "knob",
    },
  },
  {
    chainId: 101,
    name: "KOALA AI",
    symbol: "KOKO",
    address: "FsA54yL49WKs7rWoGv9sUcbSGWCWV756jTD349e6H2yW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36342/large/KOALA_AI.png?1711181247",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "koala-ai",
    },
  },
  {
    chainId: 101,
    name: "Kohler",
    symbol: "KOHLER",
    address: "HmfNGq7kxE6ppMDGW87xPuMU6wnKbeYBZf76K7t33w3s",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38312/large/58c.png?1717052451",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kohler",
    },
  },
  {
    chainId: 101,
    name: "KOI",
    symbol: "KOI",
    address: "6fdCC8xfrXNy6PmNaVcxdEY5XNCTAha2V54zYYnmBCey",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33923/large/Untitled_design.png?1703374437",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "koi",
    },
  },
  {
    chainId: 101,
    name: "Konke",
    symbol: "KONKE",
    address: "Ava2hJmCPhgZTbAaMDBM1M9A1P5VtvuVw5YLXV83ZTHQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33989/large/2d301b02-8db4-41b9-9015-4f8701fe42d3.jpeg?1703586749",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "konke",
    },
  },
  {
    chainId: 101,
    name: "KORRA",
    symbol: "KORRA",
    address: "t5cSTUSZzUAQXQKzQvhieFG4Hz4xC23z9du1Chp8gES",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34682/large/Untitled_design_-_2024-01-18T165034.536.png?1705762231",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "korra",
    },
  },
  {
    chainId: 101,
    name: "K-Pop on Solana",
    symbol: "KPOP",
    address: "DcUoGUeNTLhhzyrcz49LE7z3MEFwca2N9uSw1xbVi1gm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33733/large/IMG_20240227_163946_829.jpg?1709810268",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "k-pop-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Krill",
    symbol: "KRILL",
    address: "EP2aYBDD4WvdhnwWLUMyqU69g1ePtEjgYK6qyEAFCHTx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23917/large/Krill_towen.png?1696523116",
    tags: [],
    verified: true,
    holders: 4834,
    extensions: {
      coingeckoId: "krill",
    },
  },
  {
    chainId: 101,
    name: "KROWN",
    symbol: "KRW",
    address: "Gw7M5dqZJ6B6a8dYkDry6z9t9FuUA2xPUokjV2cortoq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16530/large/KRW_token_logo_200x200.png?1696516093",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "krown",
    },
  },
  {
    chainId: 101,
    name: "Kurobi",
    symbol: "KURO",
    address: "2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18612/large/logo_-_2021-09-26T233232.947.png?1696518085",
    tags: [],
    verified: true,
    holders: 5950,
    extensions: {
      coingeckoId: "kurobi",
    },
  },
  {
    chainId: 101,
    name: "kushcoin.sol",
    symbol: "KUSH",
    address: "E9D79Vh2mdniczdpAzAyi3CGbKJxWnstaeDJ9C3Nca4B",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34098/large/KUSH.png?1703948701",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "kushcoin-sol",
    },
  },
  {
    chainId: 101,
    name: "LabradorBitcoin",
    symbol: "LABI",
    address: "E5MBirnq71DthjqwsAsgvCtgCAzShoWqnrzkmVQKviJc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38165/large/IMG_20240524_193702_458.jpg?1716691767",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "labradorbitcoin",
    },
  },
  {
    chainId: 101,
    name: "LABS Protocol",
    symbol: "LABS",
    address: "LABSfApdYpC5Ek1tQiCFAoQP5K8CvADe2GgdYrA2QHh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26913/large/monsta-scientist.jpg?1696525970",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "labs-protocol",
    },
  },
  {
    chainId: 101,
    name: "Laine Staked SOL",
    symbol: "LAINESOL",
    address: "LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28184/large/laineSOL.png?1696527187",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "laine-stake",
    },
  },
  {
    chainId: 101,
    name: "Lamas Finance",
    symbol: "LMF",
    address: "LMFzmYL6y1FX8HsEmZ6yNKNzercBmtmpg2ZoLwuUboU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32831/large/token-LMF.png?1699585799",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lamas-finance",
    },
  },
  {
    chainId: 101,
    name: "Lambda Markets",
    symbol: "LMDA",
    address: "LMDAmLNduiDmSiMxgae1gW7ubArfEGdAfTpKohqE5gn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28653/large/XFAIIV1f_400x400.jpg?1696527637",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lambda-markets",
    },
  },
  {
    chainId: 101,
    name: "Lambo",
    symbol: "LAMBO",
    address: "FhAdp55ngkaRCC3TGAMsJdDCUHQDPTMcxQDJ4HCfnxMf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35003/large/photo_2024-02-03_11.11.02.jpeg?1707061650",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lambo-2",
    },
  },
  {
    chainId: 101,
    name: "Lantern Staked SOL",
    symbol: "LANTERNSOL",
    address: "LnTRntk2kTfWEY6cVB8K9649pgJbt6dJLS1Ns1GZCWg",
    decimals: 9,
    logoURI: "https://arweave.net/juI8rxciTff6pljaDiXMEq5klSOngQol_8lvyokGGwY",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lantern-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Lampapuy",
    symbol: "LPP",
    address: "J7dsB6BAoBR6cMeT2Pxt1MtB2t4EJ24pRbT1uxkUBsKP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36896/large/Lampapuy_-_Logo_original.jpg?1712676333",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lapapuy",
    },
  },
  {
    chainId: 101,
    name: "Larix",
    symbol: "LARIX",
    address: "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18450/large/larix.PNG?1696517937",
    tags: [],
    verified: true,
    holders: 35718,
    extensions: {
      coingeckoId: "larix",
    },
  },
  {
    chainId: 101,
    name: "Laughcoin",
    symbol: "LAUGHCOIN",
    address: "aRw6joPbDnb6fi14xuQHfUJP1Hev6Mtmip1WUxS8ANW",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34937/large/IMG_2895.jpeg?1706720907",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "laughcoin",
    },
  },
  {
    chainId: 101,
    name: "Leancoin",
    symbol: "LEAN",
    address: "7297kX7SEZ1do223VsjTAC2MS9gLxPJoxFs9UMwiG4oS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30538/large/leancoin.jpg?1696529410",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "leancoin",
    },
  },
  {
    chainId: 101,
    name: "Learning Star",
    symbol: "LSTAR",
    address: "C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25986/large/20581.png?1696525064",
    tags: [],
    verified: true,
    holders: 31407,
    extensions: {
      coingeckoId: "learning-star",
    },
  },
  {
    chainId: 101,
    name: "Legends Of SOL",
    symbol: "LEGEND",
    address: "LGNDeXXXaDDeRerwwHfUtPBNz5s6vrn1NMSt9hdaCwx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35561/large/LoS_Logo.png?1709171870",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "legends-of-sol",
    },
  },
  {
    chainId: 101,
    name: "Lehman Brothers",
    symbol: "LEH",
    address: "7obg932wg2A1oTZhrPzR4DSdhKbjXzKSfHTBo2Hu5EbP",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35255/large/leh.png?1708000632",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lehman-brothers",
    },
  },
  {
    chainId: 101,
    name: "Leia",
    symbol: "LEIA",
    address: "9EPYaNqzJLSPSFH9aKSxn2EaJoxHS1oqt7pVBR3yivt",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35754/large/IMG_4602.jpeg?1710233568",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "leia",
    },
  },
  {
    chainId: 101,
    name: "Leia the Cat",
    symbol: "LEIA",
    address: "7usVzynPTUJ9czdS96ezm9C6Z3hCsjb7j6TMKipURyyQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33245/large/cg_logo_leia.png?1701171947",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "leia-the-cat",
    },
  },
  {
    chainId: 101,
    name: "Le Meow",
    symbol: "LEMEOW",
    address: "mjoVGdUXE6oyehyoZN53cCze4qmfyqUXbbJ8MEX4pe6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36618/large/Screenshot_2024-03-28_at_3.57.46%E2%80%AFAM.png?1711979099",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "le-meow",
    },
  },
  {
    chainId: 101,
    name: "Lenard",
    symbol: "LENARD",
    address: "54Lk35D58JCLXPbbPt2nMuMSspc2XFogrhZbGx3WESbb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37640/large/i3IwxUUk_400x400.jpg?1715106010",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lenard",
    },
  },
  {
    chainId: 101,
    name: "Leonard The Lizard",
    symbol: "LENNI",
    address: "DK5grLstXHhL2zyzEXyb8ZvS6bbZrc82VZTUQE786c5i",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38217/large/lenniog.jpg?1716825888",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "leonard-the-lizard",
    },
  },
  {
    chainId: 101,
    name: "Leonidas Token",
    symbol: "LEONIDAS",
    address: "7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20496/large/cropped-logo_%281%29.png?1696519903",
    tags: [],
    verified: true,
    holders: 25450,
    extensions: {
      coingeckoId: "leonidas-token",
    },
  },
  {
    chainId: 101,
    name: "LessFnGas",
    symbol: "LFG",
    address: "LFG1ezantSY2LPX8jRz2qa31pPEhpwN9msFDzZw4T9Q",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34141/large/gpjWSfjo_400x400.jpg?1704180211",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lessfngas",
    },
  },
  {
    chainId: 101,
    name: "Liberty Square Filth",
    symbol: "FLTH",
    address: "FLTHudk5B5zag7JmGXqrYrFfey6otevLQA6jm1UHHLEE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29037/large/FLTH.png?1696528006",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "liberty-square-filth",
    },
  },
  {
    chainId: 101,
    name: "Libra Protocol",
    symbol: "LIBRA",
    address: "Hz1XePA2vukqFBcf9P7VJ3AsMKoTXyPn3s21dNvGrHnd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35480/large/1EsnMTLE_400x400.jpg?1708849262",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "libra-protocol-2",
    },
  },
  {
    chainId: 101,
    name: "LICKGOAT",
    symbol: "LICK",
    address: "AKKasEPSAXaf9BRwyJTuU2TczYq6yUwj2FAk7U5b8cPZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35988/large/NNKcNI1v_400x400.jpg?1710313663",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lickgoat",
    },
  },
  {
    chainId: 101,
    name: "Lido DAO (Wormhole)",
    symbol: "LDO",
    address: "HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22995/large/LDO_wh_small.png?1696522291",
    tags: [],
    verified: true,
    holders: 7721,
    extensions: {
      coingeckoId: "lido-dao-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Lido Staked SOL",
    symbol: "STSOL",
    address: "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18369/large/logo_-_2021-09-15T100934.765.png?1696517862",
    tags: [],
    verified: true,
    holders: 50466,
    extensions: {
      coingeckoId: "lido-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Lifinity",
    symbol: "LFNTY",
    address: "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25406/large/LFNTY_s.png?1696524537",
    tags: [],
    verified: true,
    holders: 3009,
    extensions: {
      coingeckoId: "lifinity",
    },
  },
  {
    chainId: 101,
    name: "Ligma Node",
    symbol: "LIGMA",
    address: "node3SHFNF7h6N9jbztfVcXrZcvAJdns1xAV8CbYFLG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36460/large/token-image.png?1711509361",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ligma-node",
    },
  },
  {
    chainId: 101,
    name: "lilcat",
    symbol: "LILCAT",
    address: "B69ANaWUH9Zm2sPaeDe2Ba3FwxhD17XBGoY3hp4duLKH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37632/large/IMG_20240506_205244_241.jpg?1715101150",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lilcat",
    },
  },
  {
    chainId: 101,
    name: "Lime Cat",
    symbol: "LIME",
    address: "H3QMCaMh5LxtS9oGDwaMaRXPSPSiXVqnY4YsfrQMRjqD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38007/large/Lime_Cat_Logo_200_x_200.png?1716270726",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lime-cat",
    },
  },
  {
    chainId: 101,
    name: "LIQ Protocol",
    symbol: "LIQ",
    address: "4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16534/large/85853665.png?1696516096",
    tags: [],
    verified: true,
    holders: 26134,
    extensions: {
      coingeckoId: "liq-protocol",
    },
  },
  {
    chainId: 101,
    name: "Liquid Solana Derivative",
    symbol: "LSD",
    address: "DDti34vnkrCehR8fih6dTGpPuc3w8tL4XQ4QLQhc3xPa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33209/large/IMG_3480.jpeg?1701083888",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "liquid-solana-derivative",
    },
  },
  {
    chainId: 101,
    name: "Liquid Staking Token",
    symbol: "LST",
    address: "LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32977/large/lst_%281%29.png?1700070841",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "liquid-staking-token",
    },
  },
  {
    chainId: 101,
    name: "LittleInu",
    symbol: "LINU",
    address: "41TwwURtuv4k8TuFxp1vfFYP9noMbHXqtscse8xLM26V",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22029/large/unknown_%281%29.png?1696521374",
    tags: [],
    verified: true,
    holders: 3556,
    extensions: {
      coingeckoId: "littleinu",
    },
  },
  {
    chainId: 101,
    name: "lmeow",
    symbol: "LMEOW",
    address: "9KMk8Gfm5hX86rhGKMqVWCmud2yJTMaExr84ZN5PrwFV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34024/large/lmeow_png.png?1703673674",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lmeow",
    },
  },
  {
    chainId: 101,
    name: "LOAF CAT",
    symbol: "LOAF",
    address: "3de2yRhtD4VbJBb8EQAQffYMPLU4EnSHT1eveBwiL3tn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34106/large/Fi19x9G-_400x400.png?1703976276",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "loaf-cat",
    },
  },
  {
    chainId: 101,
    name: "Locust Pocus",
    symbol: "CICADA",
    address: "HdnUzyXVD64afcdti3asmtWnsSP9TDrRs16UAqoJp9xS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37175/large/Main.png?1713512035",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "locust-pocus",
    },
  },
  {
    chainId: 101,
    name: "LOL",
    symbol: "LOL",
    address: "674fYBELAKFVbFGcjq7XVzyg8Lfbz43mSQbSye5woKux",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36428/large/xxsa.png?1711433398",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lol-2",
    },
  },
  {
    chainId: 101,
    name: "LOLA",
    symbol: "LOLA",
    address: "6fvys6PkSCgZcrDCCkYp56BjLMKZ41ySZK6qtgzX49Hg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34586/large/IMAGE_2024-01-13_15_43_59.png?1705424068",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lola",
    },
  },
  {
    chainId: 101,
    name: "Lola",
    symbol: "LOLA",
    address: "2uvch6aviS6xE3yhWjVZnFrDw7skUtf6ubc7xYJEPpwj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36522/large/lola_%28200%29.jpg?1711699411",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lola-2",
    },
  },
  {
    chainId: 101,
    name: "Lola Cat",
    symbol: "$LOLA",
    address: "FjBSZAeo7NsgUdgXRA63VuGA3ySfVi5yu2bhCfuZgxJY",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37769/large/LOLA.png?1715565676",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lola-cat",
    },
  },
  {
    chainId: 101,
    name: "LondonOnSol",
    symbol: "LONDON",
    address: "AfcvNFud8cQPKpCZtW8GBsJqi2LJNztFPu8d4vciveL3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37224/large/GK9m8QYWIAEnNIO.png?1713776301",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "londononsol",
    },
  },
  {
    chainId: 101,
    name: "Long 龙",
    symbol: "LONG",
    address: "gxNuJSHRScR7WkzQEYqvxVditaKkcc9V32KXPJLCbcT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34155/large/01E056E0-94F4-4C1D-A1E6-EA48A9826412.png?1704210511",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "long-2",
    },
  },
  {
    chainId: 101,
    name: "long boi",
    symbol: "LONG",
    address: "BCzBBz5c6fCcRU7UjdJxszaECh7FdnU551MoQewpQv7L",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36412/large/p-x_w4cg_400x400.jpg?1711424655",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "long-boi",
    },
  },
  {
    chainId: 101,
    name: "Long Nose Dog",
    symbol: "LONG",
    address: "AuYzLSUHCKiX4MTK21J2vDAqj6JX5Wa6BPSwugw7CoJA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34541/large/IMG_4779.jpeg?1705338195",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "long-nose-dog",
    },
  },
  {
    chainId: 101,
    name: "Loom",
    symbol: "LOOM",
    address: "BshVPBvgqSZ9UAsgEaLVnSteGqUR7nLfxfXn6URM4MMb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36316/large/Loom.png?1711092337",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "loom",
    },
  },
  {
    chainId: 101,
    name: "Loong Chenchen",
    symbol: "LOONG",
    address: "DaqzXLEATLcLYbXBF7xkWiCfvB7seieMkYpK5UCkkcgM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35145/large/LOONG200_.jpg?1707480785",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "loong-chenchen",
    },
  },
  {
    chainId: 101,
    name: "Loopy",
    symbol: "LOOPY",
    address: "2XGmcY1oupbEE32JDREDvkjjFvSU91zfWhCwqxV5GtDk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36592/large/IMG_20240328_224301_568.jpg?1711949231",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "loopy",
    },
  },
  {
    chainId: 101,
    name: "Lord Of SOL",
    symbol: "LOS",
    address: "44bzge9EZJGPJRYNmsA64mdKZ1eeLdDcDiczRmoyAtez",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37722/large/a1.jpg?1715332977",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lord-of-sol",
    },
  },
  {
    chainId: 101,
    name: "Lost",
    symbol: "LOST",
    address: "4riQPy1yXQ2d1FfzgLy9eJy6yoUdZuQkMj7JsVKr4bGu",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35116/large/Black_Green_Minimalist_Modern_Woman_Twitter_Profile_Picture_%28100_x_100_px%29_%282%29.png?1707397173",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lost",
    },
  },
  {
    chainId: 101,
    name: "Love Monster",
    symbol: "LOVE",
    address: "4QQV4LQUUXAn1eN1XQGrfY65TfLe5STJcfsCQozqyb8T",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37764/large/LOVE_Token_%281%29.png?1715478507",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "love-monster",
    },
  },
  {
    chainId: 101,
    name: "Love Token",
    symbol: "LOVE",
    address: "HHpibiTmkdaEYAgeV1ZygGoR6Gi1RYtfSVeDCRsn1GRM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35000/large/Frame_3%282%29.png?1706956068",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "love-token-2",
    },
  },
  {
    chainId: 101,
    name: "LowQ",
    symbol: "LOWQ",
    address: "7UAzn8R4mBfG1foqyL1169Lzd6cSWXYSLQFXYHzsiNQG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36248/large/LowQ.png?1710923980",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lowq",
    },
  },
  {
    chainId: 101,
    name: "Lumi Finance",
    symbol: "LUA",
    address: "5Mp8mZuRpWn1t6MkZJJcpj2AyMS7kv9mvBecg6t33oH3",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33502/large/LUA.png?1702038548",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lumi-finance",
    },
  },
  {
    chainId: 101,
    name: "Terra Classic (Wormhole)",
    symbol: "LUNC",
    address: "F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22951/large/LUNA_wh_small.png?1696522247",
    tags: [],
    verified: true,
    holders: 12263,
    extensions: {
      coingeckoId: "luna-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Lyra",
    symbol: "LYRA",
    address: "D6NDaFajzv9c7qijLn3FVDr5SqQnYH5HM1XW6FbzsZuQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34793/large/lyra.jpg?1706031598",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lyra-2",
    },
  },
  {
    chainId: 101,
    name: "Lyte Finance",
    symbol: "LYTE",
    address: "3ZW7vx54RC4KVUwa8dGGe3fJuWB9Xb9ppk2cxryWB5T4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36648/large/Nouveau_projet_-_2024-04-02T003427.677.png?1712038478",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "lyte-finance",
    },
  },
  {
    chainId: 101,
    name: "Mad Bears Club",
    symbol: "MBC",
    address: "Ee1pKgTQmP5xjYQs76HmRM2c2YkqEdc9tk5mQbiGFigT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32160/large/BEAR.jpg?1696660341",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mad-bears-club-2",
    },
  },
  {
    chainId: 101,
    name: "MADLAD",
    symbol: "MAD",
    address: "8H1qfZSZjmfCeRvh1AdUX5LqXw6Wuem1KCWUf8t4wivu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36146/large/8H1qfZSZjmfCeRvh1AdUX5LqXw6Wuem1KCWUf8t4wivu.png?1710734382",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "madlad",
    },
  },
  {
    chainId: 101,
    name: "Maek Amuraca Graet Agun",
    symbol: "MAGA",
    address: "TrumptpNNBEgVjDc8bnemRTNYZKhdsst9ujNAnTSHqp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38278/large/1000009990.jpg?1716978064",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maek-amuraca-graet-agun",
    },
  },
  {
    chainId: 101,
    name: "MAGAIBA",
    symbol: "MAGAIBA",
    address: "A6rSPi9JmJgVkW6BatsA6MjFYLseizPM2Fnt92coFjf4",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36418/large/MAGAIBA.png?1711421396",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "magaiba",
    },
  },
  {
    chainId: 101,
    name: "MAGA PEPE",
    symbol: "MAGAPEPE",
    address: "2Xn4cBcrksaBMMxw8EbwuxY7iG8HUiZx1XEiCXQsGeSo",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38211/large/25496154_272f007f-09b0-442a-8c60-fc39a6c2e454_%281%29.png?1716799032",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maga-pepe",
    },
  },
  {
    chainId: 101,
    name: "MAGA SHIBA",
    symbol: "MAGASHIB",
    address: "2ZRsj42U3Pt5dSfKbizp3HVygCEsLijwYBepKUMqvCxW",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38528/large/1000007697.jpg?1717762253",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maga-shiba",
    },
  },
  {
    chainId: 101,
    name: "MAGA VP",
    symbol: "MVP",
    address: "55qMv1HtV8fqRjnFwDb9yDi9tBCeV8xwfgrPKgiJk5DN",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37462/large/1000017296.jpg?1714451792",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maga-vp",
    },
  },
  {
    chainId: 101,
    name: "Magic Internet Money (Meme)",
    symbol: "MIM",
    address: "G33s1LiUADEBLzN5jL6ocSXqrT2wsUq9W6nZ8o4k1b4L",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35386/large/pfp_800x800_%281%29.png?1716433478",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "magic-internet-money-meme",
    },
  },
  {
    chainId: 101,
    name: "Mahabibi Bin Solman",
    symbol: "MBS",
    address: "6EUYNEV1WxomGn2D8jWnkbmQLCo6Hon2FfKSEWZMeJFx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37641/large/Screenshot_2024-04-11_at_21.37.48_200x200.png?1715111940",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mahabibi-bin-solman",
    },
  },
  {
    chainId: 101,
    name: "MAI (Solana)",
    symbol: "MIMATIC",
    address: "9mWRABuz2x6koTPCWiCPM49WUbcrNqGTHBV9T9k7y1o7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37337/large/mimatic-red.png?1714033868",
    tags: [],
    verified: true,
    holders: 2147,
    extensions: {
      coingeckoId: "mai-solana",
    },
  },
  {
    chainId: 101,
    name: "Make Solana Great Again",
    symbol: "$TRUMP",
    address: "A1gAnCfwfKpoqFoAAxDLuwxSGQZfNo1eSRi8TfyrbV28",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33768/large/QJOl6blJ_400x400.jpg?1702968571",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "make-solana-great-again",
    },
  },
  {
    chainId: 101,
    name: "Mami",
    symbol: "MAMI",
    address: "62mALBEzUQWS3r8EzjnX1C2ricdTy9hkv8gs7mLtpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38612/large/IMG_0183_2.jpeg?1718103244",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mami",
    },
  },
  {
    chainId: 101,
    name: "MANEKI",
    symbol: "MANEKI",
    address: "25hAyBQfoDhfWx9ay6rarbgvWGwDdNqcHsXS3jQ3mTDJ",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37292/large/image.png?1713947985",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maneki",
    },
  },
  {
    chainId: 101,
    name: "Maneki-neko",
    symbol: "NEKI",
    address: "ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21321/large/logo.png?1696520689",
    tags: [],
    verified: true,
    holders: 3421,
    extensions: {
      coingeckoId: "maneki-neko",
    },
  },
  {
    chainId: 101,
    name: "Maneko Pet",
    symbol: "MP",
    address: "5zYbnE6UXTn6HMTPXCNPW61iA1vyCNTZLVQdUUnoazB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38385/large/logo.png?1717396341",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maneko-pet",
    },
  },
  {
    chainId: 101,
    name: "Mango",
    symbol: "MNGO",
    address: "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14773/large/token-mango.png?1696514442",
    tags: [],
    verified: true,
    holders: 30826,
    extensions: {
      coingeckoId: "mango-markets",
    },
  },
  {
    chainId: 101,
    name: "MAPS",
    symbol: "MAPS",
    address: "MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13556/large/Copy_of_image_%28139%29.png?1696513311",
    tags: [],
    verified: true,
    holders: 14955,
    extensions: {
      coingeckoId: "maps",
    },
  },
  {
    chainId: 101,
    name: "Marinade",
    symbol: "MNDE",
    address: "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18867/large/MNDE.png?1696518327",
    tags: [],
    verified: true,
    holders: 40121,
    extensions: {
      coingeckoId: "marinade",
    },
  },
  {
    chainId: 101,
    name: "Marty Inu",
    symbol: "MARTY",
    address: "5KhJ3fvCoQ4ybq237jLSgshSWsy942CpQPiPfrDPz2NM",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35007/large/MARTYLogo200.png?1707067553",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "marty-inu",
    },
  },
  {
    chainId: 101,
    name: "Maru Dog",
    symbol: "MARU",
    address: "maru4DtHRrPytGBgRk81qBLby8f72t23u4Xi3EgPPgJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37720/large/IMG_20240509_161511_984.png?1715332700",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maru-dog",
    },
  },
  {
    chainId: 101,
    name: "Marvin",
    symbol: "MARVIN",
    address: "GmcjrrHrJ9v2dAYzPYVFBm2CrwLbX2ZvYzJC8Khg9E13",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35027/large/editable-logo-ezgif.com-resize_%281%29.png?1707148991",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "marvin-2",
    },
  },
  {
    chainId: 101,
    name: "Masters Of The Memes",
    symbol: "MOM",
    address: "6CmrM4f9R4Cfh2PeHCJh7YMVzXyeCDU82v5pKD7v5GcP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34215/large/photo_2024-01-02_08-50-23_%281%29_%281%29.jpg?1704277986",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "masters-of-the-memes",
    },
  },
  {
    chainId: 101,
    name: "MATIC (Wormhole)",
    symbol: "MATICPO",
    address: "Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22947/large/MATICpo_wh_small.png?1696522243",
    tags: [],
    verified: true,
    holders: 1685,
    extensions: {
      coingeckoId: "matic-wormhole",
    },
  },
  {
    chainId: 101,
    name: "MatrixETF",
    symbol: "MDF",
    address: "ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18538/large/MDF.png?1696518018",
    tags: [],
    verified: true,
    holders: 4559,
    extensions: {
      coingeckoId: "matrixetf",
    },
  },
  {
    chainId: 101,
    name: "MaxCat",
    symbol: "$MAX",
    address: "5hh9G4yyHAkgRoBhAuypg3njckEULUmV197LWycugyJc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37508/large/maxmeme_Kopie_2.png?1714618907",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "maxcat",
    },
  },
  {
    chainId: 101,
    name: "McBROKEN",
    symbol: "MCBROKEN",
    address: "8R47CZYmHbwXMnsVLXzTCnyLyR8iofkN5WxFgmnE3NHT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34317/large/IMAGE_2024-01-06_17_07_21.jpg?1704621110",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mcbroken",
    },
  },
  {
    chainId: 101,
    name: "Mean DAO",
    symbol: "MEAN",
    address: "MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21557/large/89934951.png?1696520918",
    tags: [],
    verified: true,
    holders: 38071,
    extensions: {
      coingeckoId: "meanfi",
    },
  },
  {
    chainId: 101,
    name: "Media Network",
    symbol: "MEDIA",
    address: "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15142/large/media50x50.png?1696514798",
    tags: [],
    verified: true,
    holders: 143555,
    extensions: {
      coingeckoId: "media-network",
    },
  },
  {
    chainId: 101,
    name: "Megapix",
    symbol: "MPIX",
    address: "GEdBv2DnES89DvasmZ35TaxP9kBibznYKbacXpoGTEBU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35194/large/Megapix_Token_Logo_200.png?1707824125",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "megapix",
    },
  },
  {
    chainId: 101,
    name: "Mellivora",
    symbol: "MELL",
    address: "MELLd8PyFoeNW3D5VaUe7L96eZeihtrzgLWrbKz5DR2",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32461/large/Mellivora-logo.png?1698242243",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mellivora",
    },
  },
  {
    chainId: 101,
    name: "Melon Dog",
    symbol: "MELON",
    address: "7DGJnYfJrYiP5CKBx6wpbu8F5Ya1swdFoesuCrAKCzZc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33933/large/Melon_dog_logo.png?1705865915",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "melon-dog",
    },
  },
  {
    chainId: 101,
    name: "Memecoindao",
    symbol: "$MEMES",
    address: "8W4qpyLx74vwBRewa3rVEPPVMnJ8VWMkCTWCTSYPQTDu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34457/large/photo_2024-01-02_14.17.04.jpeg?1704933526",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "memecoindao",
    },
  },
  {
    chainId: 101,
    name: "Meme Cult",
    symbol: "MCULT",
    address: "EH9QxCx4WP9Lt43s5h6BQJHFSzFWUSYaaxEipFVRbsEN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36279/large/imresizer-1710896230300.jpg?1711006044",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "meme-cult",
    },
  },
  {
    chainId: 101,
    name: "MemeFi",
    symbol: "MEMEFI",
    address: "6BhEBfR5YugSbeSyiCZzsf2v2adwUeKzTgfiG2hePhto",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37338/large/e6277efd-dc89-4c46-b7c5-66509bc2fc71-2.png?1714042242",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "memefi",
    },
  },
  {
    chainId: 101,
    name: "Memehub",
    symbol: "MEMEHUB",
    address: "GwEMhbH1eL5y5tu3oF2w41trQVEg1mybkYB3r98XzSE2",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34865/large/89mgvx.jpg?1706363784",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "memehub",
    },
  },
  {
    chainId: 101,
    name: "Memes Street AI",
    symbol: "MST",
    address: "ejj5q82deaLN2L5hTphsBYb6HPRTeuYdUd6PYTdFRo2",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33897/large/MST_Logo.png?1703236338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "memes-street-ai",
    },
  },
  {
    chainId: 101,
    name: "Mend",
    symbol: "MEND",
    address: "Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25917/large/logo-e1637099222337.png?1696524998",
    tags: [],
    verified: true,
    holders: 1001,
    extensions: {
      coingeckoId: "mend",
    },
  },
  {
    chainId: 101,
    name: "Meow Casino",
    symbol: "MEOW",
    address: "Fpqws7PURUikzV29GkS682Scg4gZVYCwsC5VeEWMiGaR",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "meow-casino",
    },
  },
  {
    chainId: 101,
    name: "MeowGangs",
    symbol: "MEOWG",
    address: "25s39CdGn45f9MyH2js68jQPuQ8xm4UZ7a9LvEVzcw5j",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37113/large/photo_2024-04-11_17.12.26.png?1713329759",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "meowgangs",
    },
  },
  {
    chainId: 101,
    name: "Meowifhat",
    symbol: "MEOWIF",
    address: "GKs967qCDgJQNRjzG476XKWSLLZSN99e9zHHR8caidp4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37198/large/MeowWif_logo.jpeg?1713678012",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "meowifhat",
    },
  },
  {
    chainId: 101,
    name: "Mercurial",
    symbol: "MER",
    address: "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15527/large/mer_logo.png?1696515169",
    tags: [],
    verified: true,
    holders: 89403,
    extensions: {
      coingeckoId: "mercurial",
    },
  },
  {
    chainId: 101,
    name: "Mercury Protocol 404",
    symbol: "M404",
    address: "3jpV3xs68ZoZMFKiM3zRdmenHjTyc4kzfXMgxiGGjHbC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35558/large/1000120982.png?1716581895",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mercury-protocol-404",
    },
  },
  {
    chainId: 101,
    name: "Mesh Protocol",
    symbol: "MESH",
    address: "MESHwqmXvAmKpDYSgRZkm9D5H8xYSCVixeyZoePHn4G",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36155/large/MESH-token.png?1710742979",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mesh-protocol",
    },
  },
  {
    chainId: 101,
    name: "META",
    symbol: "META",
    address: "METADDFL6wWMWEoKTFJwcThTbUmtarRJZjRpzUvkxhr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35393/large/8cwEAxSLrrLFuxpeH7L8cKyCLM7TqvTxQOV59Mrp0_Q.png?1708453400",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "meta-2",
    },
  },
  {
    chainId: 101,
    name: "MetaMall",
    symbol: "MALL",
    address: "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24183/large/gRlsG1JA_400x400.jpg?1696523371",
    tags: [],
    verified: true,
    holders: 15421,
    extensions: {
      coingeckoId: "metamall",
    },
  },
  {
    chainId: 101,
    name: "Metaplex",
    symbol: "MPLX",
    address: "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27344/large/mplx.png?1696526391",
    tags: [],
    verified: true,
    holders: 1016,
    extensions: {
      coingeckoId: "metaplex",
    },
  },
  {
    chainId: 101,
    name: "Mewing Coin",
    symbol: "MEWING",
    address: "9bxaVJmUwSc71j8Z2pvUL3UAr1s5fCnwUpvYhqV9jtmw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37490/large/f0953001-c4df-4233-a431-bd04c9f1002e.jpeg?1714499577",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mewing-coin",
    },
  },
  {
    chainId: 101,
    name: "Mexico Chingon",
    symbol: "CHINGON",
    address: "BpFmEt9efz3ekb7g35DuWiywX3o9nfsRANX76D1g6fNk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37992/large/logo200.png?1716593424",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mexico-chingon",
    },
  },
  {
    chainId: 101,
    name: "MICHA",
    symbol: "MICHA",
    address: "43MvZht7GGYEuCg3bgiauh3N7zVe73yyGRSC1aUBXLHT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38527/large/IMG_1828.jpeg?1717762078",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "micha",
    },
  },
  {
    chainId: 101,
    name: "michi",
    symbol: "$MICHI",
    address: "5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37309/large/5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp.jpeg?1713952829",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "michicoin",
    },
  },
  {
    chainId: 101,
    name: "MIDAS Token",
    symbol: "MDS",
    address: "9TVjnzpF3X8DHsfVqYWoCGphJxtGYh1PDCFN5QmsHW5t",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29983/large/Logomark_White_copy.png?1696528908",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "midas-token",
    },
  },
  {
    chainId: 101,
    name: "MIKI",
    symbol: "MIKI",
    address: "7qtnComv6JkaT9Ey2LUzXGGENnCv7kJTfTihGg9s27FD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37716/large/logo_%289%29.png?1715331300",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "miki",
    },
  },
  {
    chainId: 101,
    name: "Milady Wif Hat",
    symbol: "LADYF",
    address: "3X8GcLiH2HttjyqePg7MazpMbwbgq5URUMTyDz5tkmdE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36993/large/avt.jpg?1713000085",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "milady-wif-hat",
    },
  },
  {
    chainId: 101,
    name: "MILEI Solana",
    symbol: "MILEI",
    address: "43N5UGr3mnfhJFzpBPNM7ZdnobghiYBtHTaQfZQv65mh",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37619/large/1000038050.jpg?1715063293",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "milei-solana",
    },
  },
  {
    chainId: 101,
    name: "Million Monke",
    symbol: "MIMO",
    address: "9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21071/large/200xMIMO.png?1696520454",
    tags: [],
    verified: true,
    holders: 9720,
    extensions: {
      coingeckoId: "million-monke",
    },
  },
  {
    chainId: 101,
    name: "MIMANY",
    symbol: "MIMANY",
    address: "3Rcc6tMyS7ZEa29dxV4g3J5StorS9J1dn98gd42pZTk1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36880/large/WhatsApp_Image_2024-04-07_at_18.28.00_b3b694de.jpg?1712647410",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mimany",
    },
  },
  {
    chainId: 101,
    name: "mini",
    symbol: "MINI",
    address: "2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37777/large/image_%284%29.png?1715581636",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "minimini",
    },
  },
  {
    chainId: 101,
    name: "MixMob",
    symbol: "MXM",
    address: "H53UGEyBrB9easo9ego8yYk7o4Zq1G5cCtkxD3E3hZav",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34708/large/MXM_Token.png?1705893936",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mixmob",
    },
  },
  {
    chainId: 101,
    name: "MOAI",
    symbol: "MOAI",
    address: "7NQSHjuEGENZDWfSvPZz7oP2D6c5Jc3LjFC6uh179ufr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34871/large/unga.png?1706452815",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moai",
    },
  },
  {
    chainId: 101,
    name: "MOCHICAT",
    symbol: "MOCHICAT",
    address: "EVDQN4P1YTTD1Y3eLBDBoALWnR4aMBC9y2xUYCrbGbuy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36687/large/MochiCat.png?1712075738",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mochicat",
    },
  },
  {
    chainId: 101,
    name: "mockJUP",
    symbol: "MOCKJUP",
    address: "JxxWsvm9jHt4ah7DT9NuLyVLYZcZLUdPD93PcPQ71Ka",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34705/large/IMG_5655.png?1705892038",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mockjup",
    },
  },
  {
    chainId: 101,
    name: "MOG",
    symbol: "MOG",
    address: "BR4qrJbhGMoGSaF7QtUMvSZRtWhzvMUk5dkgkRrnrn8A",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34807/large/IMG_20240123_195239_983.PNG?1706120661",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mog",
    },
  },
  {
    chainId: 101,
    name: "Mog",
    symbol: "MOG",
    address: "GKvFocz56H58xVv2Yh6hHdp3sD5NdQST7NsnMMjC6A8q",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35223/large/mog200x.png?1707919954",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mog-2",
    },
  },
  {
    chainId: 101,
    name: "MOGGO",
    symbol: "MOGGO",
    address: "FbtRb4zF2u52FSnjLB79yRg73hbMVgSw9rC897shqzb4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36728/large/MONGGO.jpg?1712149955",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moggo",
    },
  },
  {
    chainId: 101,
    name: "Mogul Trumps Code Name",
    symbol: "MOGUL",
    address: "2ZtSjJep5TPJq2o4GcTDANy1wn9dHjn3vtB6MNgWEQcA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38373/large/mogul-200.png?1717307365",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mogul-trumps-code-name",
    },
  },
  {
    chainId: 101,
    name: "MOMO 2.0",
    symbol: "MOMO",
    address: "GkFhW5MAr7gyxQpG8bR4ZqaZHS5cHMx1NwzHJydk3BmU",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37334/large/MOMO_2.0.png?1714031357",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "momo-2-0",
    },
  },
  {
    chainId: 101,
    name: "MONKE",
    symbol: "MONKE",
    address: "5PRAVHPXrPJgM4uP1k9ZN2vnPe2epZRRJ8r6ETXTZs8r",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37737/large/IMG_20240508_165314_905.jpg?1715339039",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "monke-3",
    },
  },
  {
    chainId: 101,
    name: "MONKEY",
    symbol: "MONKEY",
    address: "92d5AncUVELq79xqLWNQ5Zoxz7fn9XHwijYswYWFrKtR",
    decimals: 1,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34574/large/LOGOmonkeySOL_%283%29.png?1705411235",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "monkey",
    },
  },
  {
    chainId: 101,
    name: "Monkey",
    symbol: "MONKEY",
    address: "921MoB1U7VprQfWw5D37a38LCBgB3nareT7rNffk66BG",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36296/large/photo_2024-03-13_23.03.03.png?1711042660",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "monkey-2",
    },
  },
  {
    chainId: 101,
    name: "UNKJD",
    symbol: "MBS",
    address: "Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20841/large/monkeyball.png?1696520233",
    tags: [],
    verified: true,
    holders: 69984,
    extensions: {
      coingeckoId: "monkeyball",
    },
  },
  {
    chainId: 101,
    name: "monkeyhaircut",
    symbol: "MONK",
    address: "FYa25XnBsXQXAdTnsyKBKd5gZ1VZhChBRF57CqfRxJZX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33915/large/monk_logo.png?1704136516",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "monkeyhaircut",
    },
  },
  {
    chainId: 101,
    name: "MONKIE",
    symbol: "MONKIE",
    address: "twxaKd7dcUbTYcnQftpAugoQJn3fLfxJqwBWhSZtExb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35804/large/monkie.png?1709840600",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "monkie",
    },
  },
  {
    chainId: 101,
    name: "Monku",
    symbol: "MONKU",
    address: "FJ1DHFFHvW4UFNELFu6VonuAvaMK3Ch58LUMqsNqLnwk",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37045/large/1000018832.jpg?1713163560",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "monku",
    },
  },
  {
    chainId: 101,
    name: "MooCat",
    symbol: "MOOCAT",
    address: "FuLH3f9TPxiLNojPBZxfaT5Rb5VFfia8hMzLo9e9c6CG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37701/large/1000034966.jpg?1715245272",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moocat",
    },
  },
  {
    chainId: 101,
    name: "MoonAI",
    symbol: "MOONAI",
    address: "J4VLMBo8DMdzBBhs593oSpzKH2o3tQCTPqXTRAdij37q",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34463/large/-o48eto.jpg?1704965227",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moonai-2",
    },
  },
  {
    chainId: 101,
    name: "Moonke",
    symbol: "MOONKE",
    address: "A7GJgPaRgLR9M7DjXnX78Ab2PWQ5rZhtLdj2qGAnZnZa",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moonke",
    },
  },
  {
    chainId: 101,
    name: "MoonKize",
    symbol: "MOONKIZE",
    address: "yM3AbxK4B4iTLddjGGW7JeQaVLzMiF5y81vK83fowTn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35387/large/logo.png?1708450143",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moonkize",
    },
  },
  {
    chainId: 101,
    name: "MoonLana",
    symbol: "MOLA",
    address: "AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16278/large/NW8RySX.png?1696515876",
    tags: [],
    verified: true,
    holders: 18229,
    extensions: {
      coingeckoId: "moonlana",
    },
  },
  {
    chainId: 101,
    name: "MoonMan",
    symbol: "MM",
    address: "7ybKUMNTuK9it2mFd7y5P4RMk7dUU3EqSffRm24Vpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38397/large/moonman_icon.jpg?1717458250",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moonman",
    },
  },
  {
    chainId: 101,
    name: "Moon Market",
    symbol: "MOON",
    address: "CcPYxgVbSmP9VgNRLdT7KkznZJQ7DyFJ2ZvSPwgdN62e",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38232/large/MOON.jpg?1716860603",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moon-market",
    },
  },
  {
    chainId: 101,
    name: "MoonTrump",
    symbol: "TRUMP",
    address: "BqhNdGtS1Nqtzi2MvZ7G8NN1vRuHZ12UpHGJKe71e1JT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37344/large/trump.jpg?1714059656",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moontrump",
    },
  },
  {
    chainId: 101,
    name: "MORPH",
    symbol: "MORPH",
    address: "ACu1RKQWdXAyoXvKzfSDsFKCqGfyBxngXq8YnmBPGomj",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36040/large/morphtokencmc.png?1710400345",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "morph",
    },
  },
  {
    chainId: 101,
    name: "Most Wanted",
    symbol: "$WANTED",
    address: "AjpS8szZmYczHzDqJNM37hP3HmKSCAATStH31fVTE6aB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37762/large/1000007109.jpg?1715478206",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "most-wanted",
    },
  },
  {
    chainId: 101,
    name: "MOTH",
    symbol: "MØTH",
    address: "EfRr6xyFYAwVLSV2sYt68gXwPFfUYayGpEyRoSLGRS5L",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38366/large/aaacac.png?1717274227",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moth",
    },
  },
  {
    chainId: 101,
    name: "Mother Iggy",
    symbol: "MOTHER",
    address: "3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38269/large/mother.png?1716954537",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mother-iggy",
    },
  },
  {
    chainId: 101,
    name: "Motion Coin",
    symbol: "MOTION",
    address: "Uiupt55owCqBJdunYQxyYf48KRMU4F53xQAyf31dpzx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37642/large/images_%285%29_%281%29.png?1715120475",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "motion-coin",
    },
  },
  {
    chainId: 101,
    name: "MOTO DOG",
    symbol: "TOBI",
    address: "6h4ZKWqb9dDW8jaB4d76LNfP8PoRgiSvQLBHnkuih1Ty",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37944/large/mdog_%281%29.png?1715970990",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moto-dog",
    },
  },
  {
    chainId: 101,
    name: "mouse in a cats world",
    symbol: "MOW",
    address: "mowrpaoGe5muGSN2ZV3pFtydHnFgda7y1grFVLFsKUH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37223/large/200x200.png?1713775951",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mouse-in-a-cats-world",
    },
  },
  {
    chainId: 101,
    name: "Mout",
    symbol: "MOUT",
    address: "45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34752/large/Screenshot_2024-05-17_at_4.02.34%E2%80%AFPM.png?1715932964",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "moutai",
    },
  },
  {
    chainId: 101,
    name: "Mr.Beast Dog",
    symbol: "PINKY",
    address: "EfDZUYHbBUVVWsa9HEeaSXrha5rzfBrjTxZDhc9P2xWB",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mr-beast-dog",
    },
  },
  {
    chainId: 101,
    name: "Mr West",
    symbol: "YE",
    address: "J7R19whpey6T85QervChYzhGwLsBKM9RdMDHfMZHs3qS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38104/large/IMG_2468.jpeg?1716530376",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mr-west",
    },
  },
  {
    chainId: 101,
    name: "Marinade Staked SOL",
    symbol: "MSOL",
    address: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17752/large/mSOL.png?1696517278",
    tags: [],
    verified: true,
    holders: 175770,
    extensions: {
      coingeckoId: "msol",
    },
  },
  {
    chainId: 101,
    name: "MS Paint",
    symbol: "PAINT",
    address: "8x9c5qa4nvakKo5wHPbPa5xvTVMKmS26w4DRpCQLCLk3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34582/large/logo_final_final.png?1705421332",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ms-paint",
    },
  },
  {
    chainId: 101,
    name: "MUMU THE BULL",
    symbol: "MUMU",
    address: "5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35966/large/24581.png?1710261982",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mumu-the-bull-3",
    },
  },
  {
    chainId: 101,
    name: "Munch",
    symbol: "MUNCH",
    address: "Ctaoh8SvvHMRkHSjFRLEv1iZnbYuWXcGyXx1s4Emoshh",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36304/large/munch-coin.png?1711089857",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "munch",
    },
  },
  {
    chainId: 101,
    name: "Mur Cat",
    symbol: "MUR",
    address: "DY8G8hTg4mnTmDpzArw5NyzhJAvbjRWr8ZikCyS2XdiM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37751/large/logo_200.png?1715445427",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mur-cat",
    },
  },
  {
    chainId: 101,
    name: "Museum Of Influencers",
    symbol: "MOFI",
    address: "A9cAMKwYw2kxaFEaJT8hegiMrCcj6zUHzBArckiL6zbZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37623/large/pfp.jpg?1715068272",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "museum-of-influencers",
    },
  },
  {
    chainId: 101,
    name: "Mustafa",
    symbol: "MUST",
    address: "97yCGSEmRxGairNREp1dPhJUsU1d87wphxEpjNbs8A8C",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36278/large/Component_11.png?1711005854",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mustafa",
    },
  },
  {
    chainId: 101,
    name: "Muzki",
    symbol: "MUZKI",
    address: "C1kzNkFfgdtP8VF1pFYA4S32RLPqk5KPaurCaQJwxfWb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37834/large/muzki_resized_cg.jpg?1715719477",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "muzki",
    },
  },
  {
    chainId: 101,
    name: "myBID",
    symbol: "MBID",
    address: "6Kic8C8WF5gXXRaDGjxPCKogXyew89dvUZg5asTDwNwt",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35555/large/MBID_Token_logo.png?1709125908",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "mybid",
    },
  },
  {
    chainId: 101,
    name: "My MOM",
    symbol: "MOM",
    address: "EKgZUC95KNv9T4DYJDfcC4dmmWhiKRTR9cBQDWWGGrtX",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36654/large/MY_MOM_Logo_01.44.50.jpg?1712041348",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "my-mom",
    },
  },
  {
    chainId: 101,
    name: "My Pronouns Are High/er",
    symbol: "HIGHER",
    address: "5KCGJJpRMtbydUDTAoc5MoRPnqmJNNDaFwvBDdSG6daC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38356/large/high_er.jpg?1717174385",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "my-pronouns-are-high-er",
    },
  },
  {
    chainId: 101,
    name: "Myra",
    symbol: "MYRA",
    address: "H1aN3vcvB68eaFPbMkoAss3vnfi4AhP5C2dpnrZzdBc7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34531/large/IMG_20240113_180227_311.jpg?1705323398",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "myra",
    },
  },
  {
    chainId: 101,
    name: "Myre The Dog",
    symbol: "$MYRE",
    address: "F7vhF9bCeqJJthWugQBsSB1wSs8kJJWerDHu6RFTp2eS",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "myre-the-dog",
    },
  },
  {
    chainId: 101,
    name: "Myro",
    symbol: "$MYRO",
    address: "HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32878/large/MYRO_200x200.png?1699709942",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "myro",
    },
  },
  {
    chainId: 101,
    name: "MyroWifHat",
    symbol: "MIF",
    address: "FLKtRpDyRpSbswWUUxG1HCU5674cRAK96SX7h67jfPSu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35654/large/444.jpg?1709381116",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "myrowifhat",
    },
  },
  {
    chainId: 101,
    name: "NANA Token",
    symbol: "NANA",
    address: "HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30017/large/nanavector.png?1696528941",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nana-token",
    },
  },
  {
    chainId: 101,
    name: "Naxar",
    symbol: "NAXAR",
    address: "Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16946/large/logo.png?1696516515",
    tags: [],
    verified: true,
    holders: 9436,
    extensions: {
      coingeckoId: "naxar",
    },
  },
  {
    chainId: 101,
    name: "Nekoverse: City of Greed Anima Spirit Gem",
    symbol: "ASG",
    address: "JfFeJjXo65oar4jUDiweLxirKVAfJUbqtN7ePSWoBKh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32655/large/asg.png?1698895535",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nekoverse-city-of-greed-anima-spirit-gem",
    },
  },
  {
    chainId: 101,
    name: "NemGame",
    symbol: "NEM",
    address: "9L1hpsP3cqVeZjHcp9fXLWCTYeofeP52j9eEBQUFzbR8",
    decimals: 8,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nemgame",
    },
  },
  {
    chainId: 101,
    name: "Neon",
    symbol: "NEON",
    address: "NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28331/large/neon_%281%29.png?1696527338",
    tags: [],
    verified: true,
    holders: 6132,
    extensions: {
      coingeckoId: "neon",
    },
  },
  {
    chainId: 101,
    name: "Nerds",
    symbol: "NERDS",
    address: "BxxM6J2EpnCZWgB6BAsetCGD4HDgmrNP3k2XY5PBny24",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37365/large/IMG_20240423_032434_384.png?1714110824",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nerds",
    },
  },
  {
    chainId: 101,
    name: "Nest Arcade",
    symbol: "NESTA",
    address: "Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24182/large/mPFhh9sZ_400x400.jpg?1696523370",
    tags: [],
    verified: true,
    holders: 19802,
    extensions: {
      coingeckoId: "nest-arcade",
    },
  },
  {
    chainId: 101,
    name: "Netherlands Coin",
    symbol: "NED",
    address: "DH7HuYR54FNp49isrus6AdqREuQfWYV6qaVPg8xVnZQ9",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38228/large/ezgif-5-adeac75822.png?1716828551",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "netherlands-coin",
    },
  },
  {
    chainId: 101,
    name: "neversol",
    symbol: "NEVER",
    address: "CxrhHSqyW8YTDWc4csJMMgo7uBUJSXzNzrWhtw9ULdru",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35077/large/sxMrdihH_400x400_%281%29.jpg?1707301310",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "neversol",
    },
  },
  {
    chainId: 101,
    name: "New World Order",
    symbol: "STATE",
    address: "2Ar2n5DJSiQuMhY4XeSzg7C5AGMezZPoLUt98VmmjsAR",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27387/large/icon.png?1696526428",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "new-world-order",
    },
  },
  {
    chainId: 101,
    name: "Next Gen PEPE",
    symbol: "PEPE",
    address: "2Zaq3WyDJj2ZkMcuqUiTBfAXCjqZtqihxcwUdwv9dyHA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38013/large/Next_Gen_PEPE.png?1716274775",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "next-gen-pepe",
    },
  },
  {
    chainId: 101,
    name: "NftEyez",
    symbol: "EYE",
    address: "G7eETAaUzmsBPKhokZyfbaT4tD9igdZSmfQGEYWem8Sw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22079/large/eye-coin.png?1696521423",
    tags: [],
    verified: true,
    holders: 1544,
    extensions: {
      coingeckoId: "nfteyez",
    },
  },
  {
    chainId: 101,
    name: "Nigi",
    symbol: "NIGI",
    address: "3boRKAxWR6weV6kufr9ykdLcm9cL5q2p469tCqeCAnHy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37958/large/photo_2024-05-18_00-19-57_%281%29.jpg?1716097475",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nigi",
    },
  },
  {
    chainId: 101,
    name: "Ninja Protocol",
    symbol: "NINJA",
    address: "FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18442/large/ninja.PNG?1696517930",
    tags: [],
    verified: true,
    holders: 8399,
    extensions: {
      coingeckoId: "ninja-protocol",
    },
  },
  {
    chainId: 101,
    name: "Ninja Squad Token",
    symbol: "NST",
    address: "G7iK3prSzAA4vzcJWvsLUEsdCqzR7PnMzJV61vSdFSNW",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22248/large/nst-logo-200x200.png?1711559985",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ninja-squad",
    },
  },
  {
    chainId: 101,
    name: "NINJA TURTLES",
    symbol: "$NINJA",
    address: "DFrJxDoLMYt6bNYeNe8Wrjzj2UPUSLZLEMMYBLuTKcTk",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32123/large/logo01.png?1696586958",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ninja-turtles",
    },
  },
  {
    chainId: 101,
    name: "nioctiB",
    symbol: "NIOCTIB",
    address: "FatXR5s5qjNYkZDdYY6YgyCfhDyDCJnQZKEFucHTHSke",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34034/large/Untitled_design_%2835%29.png?1703679111",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nioctib",
    },
  },
  {
    chainId: 101,
    name: "Nirvana prANA",
    symbol: "PRANA",
    address: "PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26176/large/prANA.png?1696525264",
    tags: [],
    verified: true,
    holders: 24924,
    extensions: {
      coingeckoId: "nirvana-prana",
    },
  },
  {
    chainId: 101,
    name: "Nobiko Coin",
    symbol: "LONG",
    address: "AYABiqKuTh9Va5Aqc6AujFevHwDGmECGQiFmKW5g3K4Z",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36651/large/AYABiqKuTh9Va5Aqc6AujFevHwDGmECGQiFmKW5g3K4Z.jpg?1712040734",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nobiko-coin",
    },
  },
  {
    chainId: 101,
    name: "Node420",
    symbol: "NODE",
    address: "CTF2MKveazEiKWbbKGhXFuNPcmuxBTwevLbiDvkU7dYN",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36715/large/Screenshot_from_2024-03-29_05-07-39-removebg-preview.png?1712127830",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "node420",
    },
  },
  {
    chainId: 101,
    name: "Noike",
    symbol: "WOOSH",
    address: "29377Uz8eu6CeXmnMXJJge3gdaycyCbrubnLiUva75AA",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36874/large/IMG_0011.jpeg?1712646263",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "noike",
    },
  },
  {
    chainId: 101,
    name: "Noka Solana A",
    symbol: "NOKA",
    address: "CTAbXBR9hYFzS2YgW2AuiKJk5YPwvx3WY7fbwA63Sw2x",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34270/large/22hmuat__400x400%281%29.png?1704389829",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "noka-solana-a",
    },
  },
  {
    chainId: 101,
    name: "NOLE",
    symbol: "NOLE",
    address: "DiyrCedc3giCrBtGG7wZPZu6TvGy7bijeiE2PKmMvAdK",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37910/large/tokk.png?1715935181",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nole",
    },
  },
  {
    chainId: 101,
    name: "Noot Sol",
    symbol: "NOOT",
    address: "3h9cz2cmTCJJa3W4oqA7pJVFJ79wEgp1gR15waXxEMPy",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35150/large/Noot_Sol.png?1707556004",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "noot-sol",
    },
  },
  {
    chainId: 101,
    name: "Nosana",
    symbol: "NOS",
    address: "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22553/large/POfb_I4u_400x400.jpg?1696521873",
    tags: [],
    verified: true,
    holders: 5787,
    extensions: {
      coingeckoId: "nosana",
    },
  },
  {
    chainId: 101,
    name: "Nose Bud",
    symbol: "NOSEBUD",
    address: "D8wLsSr9xFa8vS96tMK1rvckQRoEbH5XLRhqMRoXTbUr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36400/large/Nose_Bud.png?1711368005",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nose-bud",
    },
  },
  {
    chainId: 101,
    name: "NOSTALGIA",
    symbol: "NOS",
    address: "24gG4br5xFBRmxdqpgirtxgcr7BaWoErQfc2uyDp2Qhh",
    decimals: 6,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nostalgia",
    },
  },
  {
    chainId: 101,
    name: "NovaDEX",
    symbol: "NVX",
    address: "GtMtXoJiqSf8Gfp83cuunnDTiJTeTmv7cniVtJ6UAMWH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33558/large/NVX_Token_logo.png?1702800258",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "novadex",
    },
  },
  {
    chainId: 101,
    name: "Nova Finance",
    symbol: "NOVA",
    address: "BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21672/large/bGVB8h2Q_400x400.jpg?1696521029",
    tags: [],
    verified: true,
    holders: 7431,
    extensions: {
      coingeckoId: "nova-finance",
    },
  },
  {
    chainId: 101,
    name: "Nukey",
    symbol: "NUKEY",
    address: "BbciExji4fhSbqcEWRvqrvNpumJy2y3kCKajc2XGDvLi",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37990/large/Nukey_PNG_200x200.png?1716223712",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nukey",
    },
  },
  {
    chainId: 101,
    name: "Nuncy Paloosi",
    symbol: "PALOOSI",
    address: "FoVeWwe6H6hWEa1cQfZeNSGsGSDyKz57CZT49BusdshW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37947/large/Untitled_design-7.png?1715977840",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nuncy-paloosi",
    },
  },
  {
    chainId: 101,
    name: "Nutflex",
    symbol: "NUT",
    address: "4pb6mymm9hYQN6og9uF24eyZ2qwXCWCwGvcR1DkCgeEr",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmPC2j7zQaeW1u8HDXWh1uWD26Q12GxVgc8nNATzk8ZLFJ",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nutflex",
    },
  },
  {
    chainId: 101,
    name: "Nyan Heroes",
    symbol: "NYAN",
    address: "NYANpAp9Cr7YarBNrby7Xx4xU6No6JKTBuohNA3yscP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37860/large/nyan.jpeg?1715792715",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nyan",
    },
  },
  {
    chainId: 101,
    name: "Nyro",
    symbol: "NYRO",
    address: "HHW4Pjg2WkivrgpdxG7beevrrW6XsAw4VU4N4UDCKGww",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34818/large/photo_2024-01-24_17-19-29__1_-removebg-preview.png?1706177041",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nyro",
    },
  },
  {
    chainId: 101,
    name: "Nyxia AI",
    symbol: "NYXC",
    address: "HrLx8MLKegpbmbmWePZiuvf3AbJNx1CJyjBwUHwicEgW",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34209/large/NYXC_coin.png?1704276550",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "nyxia-ai",
    },
  },
  {
    chainId: 101,
    name: "obema",
    symbol: "OBEMA",
    address: "CzkM8bzWFdXsjtZQnz2piTxJKPtJ5mfTL8S6sNZg7n7S",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37980/large/2024-04-26_07.37.09.jpg?1716203116",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "obema",
    },
  },
  {
    chainId: 101,
    name: "Octokn",
    symbol: "OTK",
    address: "octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29154/large/icon-otk-big-filled.png?1696528113",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "octo-gaming",
    },
  },
  {
    chainId: 101,
    name: "Odie",
    symbol: "ODIE",
    address: "DEhDXhtUFuz6Uodhde3rznGbVbdiECahp1kTHnFpsna3",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37551/large/1000006923.jpg?1714734683",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "odie-on-sol",
    },
  },
  {
    chainId: 101,
    name: "oDung",
    symbol: "DERP",
    address: "BBASTWwAZE7PVwuAqXWUUmoFeKxzbSmiKfqsYusbruRG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33913/large/Derp_coingeko.png?1703311421",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "odung",
    },
  },
  {
    chainId: 101,
    name: "Okami Lana",
    symbol: "OKANA",
    address: "okaxuoDnpewHyCoYykiNX4pmJwKodhWxVxb2tCuGxMy",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28754/large/OkamiLana_Logo_Update.png?1696527733",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "okami-lana",
    },
  },
  {
    chainId: 101,
    name: "Okcash",
    symbol: "OK",
    address: "HvPrYvgJiBtwUC7H4bi8L5bqnirFPWc2J9NdVy6UFiZm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/274/large/ok-logo-200px.png?1696501624",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "okcash",
    },
  },
  {
    chainId: 101,
    name: "OLAF vs OLØF",
    symbol: "OVSO",
    address: "6TL1yMhrwoKNGW173bpyaxUyyJhrt7AVhV2EyLnqzDNv",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37574/large/OVSO_1_1.png?1714929012",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "olaf-vs-olof",
    },
  },
  {
    chainId: 101,
    name: "Olen Mosk",
    symbol: "OLEN",
    address: "BYDLp89u3d2DxD5GFzHiP7KkHwzHpmvwsmZhJgaY29r3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36189/large/IMG_8645.jpeg?1710760514",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "olen-mosk",
    },
  },
  {
    chainId: 101,
    name: "Olumpec Terch",
    symbol: "OLUMPC",
    address: "5WjrhXBcYsTcn2KvaJv4cuBA5WDuprQVA9YoHYTC2wbi",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38248/large/200x200OLUMPC.png?1716913468",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "olumpec-terch",
    },
  },
  {
    chainId: 101,
    name: "OmniCat",
    symbol: "OMNI",
    address: "7mmXL6Et4SbpDs2iXoZQ3oPEeXeAiyETxh1QjDNi5qnV",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33917/large/omnicatlogo.png?1717544778",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "omnicat",
    },
  },
  {
    chainId: 101,
    name: "Ondo US Dollar Yield",
    symbol: "USDY",
    address: "A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31700/large/usdy_%281%29.png?1696530524",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ondo-us-dollar-yield",
    },
  },
  {
    chainId: 101,
    name: "OneFinity",
    symbol: "ONE",
    address: "CEm2Fg6k1o9GKFnfzXqZyTE1UcfUw6dGt3Dsn37UHBwJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29579/large/OneDex_new_Logo.png?1704776259",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "onedex",
    },
  },
  {
    chainId: 101,
    name: "Onespace",
    symbol: "1SP",
    address: "2zzC22UBgJGCYPdFyo7GDwz7YHq5SozJc1nnBqLU8oZb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26474/large/1SP_logo.png?1696525547",
    tags: [],
    verified: true,
    holders: 1091,
    extensions: {
      coingeckoId: "onespace",
    },
  },
  {
    chainId: 101,
    name: "Only1",
    symbol: "LIKE",
    address: "3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17501/large/like-token.png?1696517041",
    tags: [],
    verified: true,
    holders: 65648,
    extensions: {
      coingeckoId: "only1",
    },
  },
  {
    chainId: 101,
    name: "Only Possible On Solana",
    symbol: "OPOS",
    address: "BqVHWpwUDgMik5gbTciFfozadpE2oZth5bxCDrgbDt52",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32151/large/8e94bc23-f4c1-4c11-9c23-d063953a0d03.png?1696598035",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "only-possible-on-solana",
    },
  },
  {
    chainId: 101,
    name: "oof",
    symbol: "OOF",
    address: "FSM8TmWxGFUjM7Rs6Wt9DHx3ZQAMX6vC4bN8r5PjSvE8",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33836/large/oof.jpeg?1703107752",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "oof-2",
    },
  },
  {
    chainId: 101,
    name: "OOGI",
    symbol: "OOGI",
    address: "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19714/large/oogi.PNG?1696519140",
    tags: [],
    verified: true,
    holders: 29225,
    extensions: {
      coingeckoId: "oogi",
    },
  },
  {
    chainId: 101,
    name: "OPEN Ticketing Ecosystem",
    symbol: "OPN",
    address: "J1YnyKzmxBwFkPftvZexnHTm4Am7JnmWydhxtXdwEmMv",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36612/large/TOKEN.png?1711975511",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "open-ticketing-ecosystem",
    },
  },
  {
    chainId: 101,
    name: "OPPA",
    symbol: "OPPA",
    address: "9K4uNquZjVSBBN6fBsp62gtYLropyAxAbdZC7D9XErih",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20359/large/oppa.png?1696519767",
    tags: [],
    verified: true,
    holders: 2429,
    extensions: {
      coingeckoId: "oppa",
    },
  },
  {
    chainId: 101,
    name: "Oracle.Bot",
    symbol: "ORACLE",
    address: "2NZPnL4rQt54W4vyHsqjTg68C7nDVC5aWTL9pGKkBih1",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35908/large/webclip.png?1710178690",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "oracle-bot",
    },
  },
  {
    chainId: 101,
    name: "Orbitt Pro",
    symbol: "ORBT",
    address: "BGyjasmSzYM9hHiZ1LBU4EJ7KCtRjMSpbN4zTru3W5vf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34412/large/Logo_Orbitt_200x200_1_%282%29.png?1704819045",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "orbitt-pro",
    },
  },
  {
    chainId: 101,
    name: "Orca",
    symbol: "ORCA",
    address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17547/large/Orca_Logo.png?1696517083",
    tags: [],
    verified: true,
    holders: 111001,
    extensions: {
      coingeckoId: "orca",
    },
  },
  {
    chainId: 101,
    name: "Wrapped OrdBridge",
    symbol: "WBRGE",
    address: "4cCjHnKqLFMMNYQD2NYuRMrw7nXkxVsD85G2MkMA44vM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33682/large/ologo.jpg?1702719798",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ordbridge",
    },
  },
  {
    chainId: 101,
    name: "Ore",
    symbol: "ORE",
    address: "oreoN2tQbHXVaZsr3pf66A48miqcBXCDJozganhEJgz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36854/large/IMG_7340.png?1712633659",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ore",
    },
  },
  {
    chainId: 101,
    name: "Orym",
    symbol: "ORYM",
    address: "4vcRuMfpmfvsPVhmsKT8w4Ythnpst7ZofJDrVQFVboWv",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34523/large/orym.jpg?1705302507",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "orym",
    },
  },
  {
    chainId: 101,
    name: "OUSG",
    symbol: "OUSG",
    address: "i7u4r16TcsJTgq1kAG8opmVZyVnAKBwLKu6ZPMwzxNc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29023/large/OUSG.png?1696527993",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ousg",
    },
  },
  {
    chainId: 101,
    name: "Overclock Staked SOL",
    symbol: "CLOCKSOL",
    address: "GRJQtWwdJmp5LLpy8JWjPgn5FnLyqSJGNhn5ZnCTFUwM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37474/large/solana.png?1714463830",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "overclock-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Ovols Floor Index",
    symbol: "$OVOL",
    address: "4v3UTV9jibkhPfHi5amevropw6vFKVWo7BmxwQzwEwq6",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29255/large/Screenshot_2023-02-28_at_2.03.10_PM.png?1696528209",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ovols-floor-index",
    },
  },
  {
    chainId: 101,
    name: "OX Coin",
    symbol: "OX",
    address: "DV3845GEAVXfwpyVGGgWbqBVCtzHdCXNCGfcdboSEuZz",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35365/large/logo.png?1708395976",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ox-fun",
    },
  },
  {
    chainId: 101,
    name: "Oxygen",
    symbol: "OXY",
    address: "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13509/large/8DjBZ79V_400x400.jpg?1696513271",
    tags: [],
    verified: true,
    holders: 62520,
    extensions: {
      coingeckoId: "oxygen",
    },
  },
  {
    chainId: 101,
    name: "Paidwork Worken",
    symbol: "WORK",
    address: "9tnkusLJaycWpkzojAk5jmxkdkxBHRkFNVSsa7tPUgLb",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37266/large/2000xvcv2000-s.png?1713867741",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "paidwork-worken",
    },
  },
  {
    chainId: 101,
    name: "Pajamas Cat",
    symbol: "PAJAMAS",
    address: "FvER7SsvY5GqAMawf7Qfb5MnUUmDdbPNPg4nCa4zHoLw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36007/large/pajamas_logo-100x100.png?1713379929",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pajamas-cat",
    },
  },
  {
    chainId: 101,
    name: "BOLLY.DEV",
    symbol: "BOLLY",
    address: "CPxE3TB2fjoxf3ip2DkepUMje8eCAucHHavS17g3VER1",
    decimals: 6,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "paje-etdev-company",
    },
  },
  {
    chainId: 101,
    name: "Panda Coin",
    symbol: "PANDA",
    address: "Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20500/large/panda.png?1696519907",
    tags: [],
    verified: true,
    holders: 15073,
    extensions: {
      coingeckoId: "panda-coin",
    },
  },
  {
    chainId: 101,
    name: "Panda Swap",
    symbol: "PANDA",
    address: "9m21fukdvCPBgEjmeeshbskPwbaa5RH8HTHQGeTnGd2t",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36353/large/Panda_Swap.png?1711287365",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "panda-swap",
    },
  },
  {
    chainId: 101,
    name: "PANTIES",
    symbol: "PANTIES",
    address: "35Y3VgNdaJ6AxBqifh8wCemR7x8PLd4xibB2C3JUZ9P7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36892/large/panties_logo_200x200.png?1712660504",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "panties",
    },
  },
  {
    chainId: 101,
    name: "PAPA on SOL",
    symbol: "PAPA",
    address: "35CthCqzK5fewT4Wi8jYyHYNDca1aNQeVoz2JH2Q8HkR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35478/large/photo_2024-02-23_13-57-18.png?1708774280",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "papa-on-sol",
    },
  },
  {
    chainId: 101,
    name: "PapoCoin",
    symbol: "PAPO",
    address: "2adFTbyzVwMwPtgV68DUnrfdwB6tTAJiiVxJ6D8nwiEG",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35341/large/Papo.jpeg?1708317205",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "papocoin",
    },
  },
  {
    chainId: 101,
    name: "Parasol Finance",
    symbol: "PSOL",
    address: "Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21551/large/icon.png?1696520909",
    tags: [],
    verified: true,
    holders: 4958,
    extensions: {
      coingeckoId: "parasol-finance",
    },
  },
  {
    chainId: 101,
    name: "Parcl",
    symbol: "PRCL",
    address: "4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37078/large/PRCL_2024-04-12_00-06-16.jpg?1713249389",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "parcl",
    },
  },
  {
    chainId: 101,
    name: "Parrot Protocol",
    symbol: "PRT",
    address: "PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18443/large/PRT.png?1696517931",
    tags: [],
    verified: true,
    holders: 40392,
    extensions: {
      coingeckoId: "parrot-protocol",
    },
  },
  {
    chainId: 101,
    name: "Parrot USD",
    symbol: "PAI",
    address: "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18439/large/pai.png?1696517927",
    tags: [],
    verified: true,
    holders: 30051,
    extensions: {
      coingeckoId: "parrot-usd",
    },
  },
  {
    chainId: 101,
    name: "Party",
    symbol: "PARTY",
    address: "PARcZnHixQXo126iE7LxEbyjmmJwwwSVXk6JJdo3zrZ",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34286/large/IMG_9066.jpeg?1704453749",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "party-2",
    },
  },
  {
    chainId: 101,
    name: "partyhat (Meme)",
    symbol: "PHAT",
    address: "7k6cZGzuBjstHqaY2RLZ2h33jWF5oKWR6zHbFpfkt3Re",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34307/large/blue-phat_original_coing.png?1704614163",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "partyhat-meme",
    },
  },
  {
    chainId: 101,
    name: "Pathfinders Staked SOL",
    symbol: "PATHSOL",
    address: "pathdXw4He1Xk3eX84pDdDZnGKEme3GivBamGCVPZ5a",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/igneous-labs/lst-offchain-metadata/master/pathSOL/pathSOL.png",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pathfinders-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Pats",
    symbol: "PATS",
    address: "35fN6LMYt6cKsemgbR28nFooiJtcnvaKPCeRXyuMKfoF",
    decimals: 6,
    logoURI:
      "https://lavender-gentle-primate-223.mypinata.cloud/ipfs/QmPUtgdVaYqPRUvaygwN8dPd8eqyReZDMUdiqusXWHMwBy?pinataGatewayToken=M45Jh03NicrVqTZJJhQIwDtl7G6fGS90bjJiIQrmyaQXC_xXj4BgRqjjBNyGV7q2",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pats",
    },
  },
  {
    chainId: 101,
    name: "Pax Dollar",
    symbol: "USDP",
    address: "HVbpJAQGNpkgBaYBZQBR1t7yFdvaYVp2vCQQfKKEN4tM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/6013/large/Pax_Dollar.png?1696506427",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "paxos-standard",
    },
  },
  {
    chainId: 101,
    name: "PayPal USD",
    symbol: "PYUSD",
    address: "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31212/large/PYUSD_Logo_%282%29.png?1696530039",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "paypal-usd",
    },
  },
  {
    chainId: 101,
    name: "PayPaw",
    symbol: "PAW",
    address: "pawSXHWsonrTey4SX7tz1fM9ksuLpE13Y54K57ym4Rg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36123/large/ppaw.png?1710502728",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "paypaw",
    },
  },
  {
    chainId: 101,
    name: "peanie",
    symbol: "PEANIE",
    address: "dekNoN3D8mXa4JHLwTbVXz8aPAyJUkk443UjcSpJKi4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37577/large/peanie.png?1714958785",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "peanie",
    },
  },
  {
    chainId: 101,
    name: "PEDRO MEME",
    symbol: "PEDRO",
    address: "6M1xpu6BTxbmxejFvDn73Ba8tGVMgXUnioEkVbtsGa1z",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38480/large/cg200.png?1717656441",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pedro-meme",
    },
  },
  {
    chainId: 101,
    name: "Pedro the Raccoon",
    symbol: "PEDRO",
    address: "9SiKU8vnRiBYQSBff84K5zwG7habzwYVzn7KrtgCzNfg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37522/large/image_2024-04-23_23-20-38_%281%29_%281%29.png?1714637707",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pedro-the-raccoon",
    },
  },
  {
    chainId: 101,
    name: "peep",
    symbol: "PEEP",
    address: "HuRJw1FxaCxdMhwNjpJNaM7rrQ8rN3W7ssUP1qRwbKh6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37687/large/coingeckoPEEP.png?1715229859",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "peep",
    },
  },
  {
    chainId: 101,
    name: "Peepo (SOL)",
    symbol: "$PEEP",
    address: "n54ZwXEcLnc3o7zK48nhrLV4KTU5wWD4iq7Gvdt5tik",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32849/large/channels4_profile.jpg?1699663563",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "peepo-sol",
    },
  },
  {
    chainId: 101,
    name: "PELFORT",
    symbol: "PELF",
    address: "BgJW7U1u2RY5XJk9uYb5AqFRzjMtqE7pw3kaf9iw9Ntz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34576/large/download.jpg?1705412170",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pelfort",
    },
  },
  {
    chainId: 101,
    name: "Peng",
    symbol: "PENG",
    address: "A3eME5CetyZPBoWbRUwY3tSe25S6tb18ba9ZPbWk9eFJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35800/large/peng200x200.png?1709813288",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "peng",
    },
  },
  {
    chainId: 101,
    name: "Penguiana",
    symbol: "PENGU",
    address: "PENGEKyPYXYDnbXGKcjXaSfMsovhcrtPT8S7127tKcg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38539/large/pengu.png?1717834577",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "penguiana",
    },
  },
  {
    chainId: 101,
    name: "PengyOS",
    symbol: "POS",
    address: "B8vV6An7xFF3bARB1cmU7TMfKNjjes2WvY7jWqiRc6K6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36323/large/sol1_200x200.png?1711094698",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pengyos",
    },
  },
  {
    chainId: 101,
    name: "Penose",
    symbol: "PENOSE",
    address: "GQpczRwMqsKovhk9Cc9oCCY637emtQzPm8MTVbUWmmM7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37824/large/penose_200x200.png?1715667424",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "penose",
    },
  },
  {
    chainId: 101,
    name: "pepcat",
    symbol: "PEPCAT",
    address: "AbDm7PoYahHX12Huuq5jcg6ezpBF6nFKgLwgHTPTgeZs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36688/large/1000146490.jpg?1712083933",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepcat",
    },
  },
  {
    chainId: 101,
    name: "PEPE BLACK",
    symbol: "PEPE",
    address: "BWKyFgvQSfjNRbidRJ5CtuphashZNr3ba7oe9cjZpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38404/large/pepe_dark_grey.jpg?1717460988",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-black",
    },
  },
  {
    chainId: 101,
    name: "PEPECAT",
    symbol: "PEPECAT",
    address: "FKmk7kpCEnB6UApMNY8kBdxSkSwq2XzgAAttEs1TJPCw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37679/large/photo_2024-05-06_11-23-29.jpg?1715194313",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepecat",
    },
  },
  {
    chainId: 101,
    name: "PEPECOIN on SOL",
    symbol: "PEPE",
    address: "3tS6fbLh2P8tzxXuqCiHZpZhsxJpmrR3Xb9psmypnp69",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34026/large/pepecoin-on-solana-coingecko-logo-200x200px.jpg?1716734361",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepecoin-on-sol",
    },
  },
  {
    chainId: 101,
    name: "PepeCoin on Solana",
    symbol: "PEPE",
    address: "8jzRznet3cR3Y1KhciwUxKEqPku3BLcfiJLpVjMU3NF7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35507/large/pepeonsol200x200.png?1708936206",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepecoin-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Pepe CTO",
    symbol: "PEPE",
    address: "DHsPzhYsz9219sQDj6vYtQMBBHeuyXAM1RA4mjv2V9AT",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38095/large/IPvPiKG5_400x400.jpg?1716495589",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-cto",
    },
  },
  {
    chainId: 101,
    name: "PEPE GOLD",
    symbol: "PEPE",
    address: "846XGqAnEhKFwc3QL2e5PQhSRvEbn9KspuxJ3nhY92kh",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38398/large/IMG_0393.png?1717458300",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-gold",
    },
  },
  {
    chainId: 101,
    name: "Pepeinatux",
    symbol: "$INA",
    address: "2yd2Suus3YY4Sa7LHhn1PSHkjXj3XKrars4cCog2tGU8",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38222/large/pepeinatux3.jpeg?1716827153",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepeinatux",
    },
  },
  {
    chainId: 101,
    name: "PEPE MAGA",
    symbol: "PEPEMAGA",
    address: "F6EzAYsWQb53Zr2VEiU3EefCwydmhkJ5pn2RNxUJ6mGf",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38455/large/pepemaga.jpeg?1717562330",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-maga",
    },
  },
  {
    chainId: 101,
    name: "Pepe Mining Company",
    symbol: "PPMC",
    address: "3JQhFv7CdTKjg9hQZR5YJ7PmDmeqa6pdykofhPRksa1H",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34592/large/200.200.png?1705430131",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-mining-company",
    },
  },
  {
    chainId: 101,
    name: "Pepe On Fire",
    symbol: "PFIRE",
    address: "PFireKhT5WG7axMSLBmMRpvYH7cgHx9CRWHU8F8HNbr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37188/large/Pepe.png?1713667355",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-on-fire",
    },
  },
  {
    chainId: 101,
    name: "Pepe on Solana",
    symbol: "PEPE",
    address: "EdNiGWbkBwmmz7uhtdY3DqKZn1n5m1c61uLVgvXYZWGS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33319/large/pepesol.jpg?1701436092",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-on-solana",
    },
  },
  {
    chainId: 101,
    name: "PepeSol",
    symbol: "PEPE",
    address: "F9CpWoyeBJfoRB8f2pBe2ZNPbPsEE76mWZWme3StsvHK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28996/large/pepe2.png?1710858708",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepesol",
    },
  },
  {
    chainId: 101,
    name: "Pepe (SOL)",
    symbol: "PEPE",
    address: "B5WTLaRwaUQpKk7ir1wniNB6m5o8GgMrimhKMYan2R6B",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38218/large/photo_5949643725443547660_m_%283%29.jpg?1716825989",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-sol",
    },
  },
  {
    chainId: 101,
    name: "Pepe (Solana)",
    symbol: "PEPE",
    address: "FDqp7ioPenKRzQqseFv84kxDMUT83CX1qZxgDTQDkwT2",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35195/large/jpgtopngconverter-com.jpg?1707829922",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-solana",
    },
  },
  {
    chainId: 101,
    name: "Pepe Sora AI",
    symbol: "PEPESORA",
    address: "8yes8ybDikwgFkEko7ZDPLkXK6PLQ6XQyNsr435KaQzL",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-sora-ai",
    },
  },
  {
    chainId: 101,
    name: "Pepe Trump",
    symbol: "PTRUMP",
    address: "Gk2kRrwNMBU4Dn9JhC1Dks8G5X9nqi4ZE5jMvK6bdgEd",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37560/large/Icon_2.png?1714872973",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-trump",
    },
  },
  {
    chainId: 101,
    name: "Pepe Undead",
    symbol: "PEPEZ",
    address: "HgackCATyfKcosD652W3y42p5smLrydGMjGF2wSJLkDe",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37884/large/IMG_20240515_133802_547.jpg?1715841516",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-undead",
    },
  },
  {
    chainId: 101,
    name: "PEPE UWU",
    symbol: "CUTE",
    address: "6zmrmNUGdRwrqcchshVkBgAyLhLwZ7QvJRQiKktqVQLV",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33691/large/Resized_AvEWEWA5_200x200_%281%29.png?1702796454",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepe-uwu",
    },
  },
  {
    chainId: 101,
    name: "pepewifhat",
    symbol: "PIF",
    address: "8vRnnknwERunJEEDtEFoxzaxbxGnEY61FPbx8uTVKsUD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34028/large/IMG_7230.jpeg?1703674929",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepewifhat",
    },
  },
  {
    chainId: 101,
    name: "pepewifhat",
    symbol: "PWH",
    address: "7So8nmhmAMNz7Sc618DPkpJaAy9VnfFvdy6WiBA27PnD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35904/large/FN-Qd8BG_400x400.jpg?1710172751",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepewifhat-2",
    },
  },
  {
    chainId: 101,
    name: "Pepewifhat",
    symbol: "PEPEWIFHAT",
    address: "EAvKa9kGRMcQ44Eg3MPbXUP8ZEpfxgd5dToHBRF8m8ui",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36944/large/IMG_1200.jpeg?1712892956",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepewifhat-3",
    },
  },
  {
    chainId: 101,
    name: "PepeWifPork",
    symbol: "PEPEWFPORK",
    address: "5CA8ja6ZrQswkgNH4yoC8BdhcSwSwH3fR1WF8gDHVb3s",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37102/large/pepewifporkclear.png?1713310912",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepewifpork",
    },
  },
  {
    chainId: 101,
    name: "Pepinu (SOL)",
    symbol: "PEPINU",
    address: "Fo9T5Rrj6zj7Nh98ac6efQNVwJ26FU7Jr5dCNJQyhqAR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38525/large/PEPINU_200_x_200.png?1717759994",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pepinu-sol",
    },
  },
  {
    chainId: 101,
    name: "PESHI",
    symbol: "PESHI",
    address: "5LwseQRo8fsz4S3y7jbqqe5C7tZTz5PwhXNCHj13jLBi",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38200/large/PESHI-HEAD200x200.png?1716794781",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "peshi",
    },
  },
  {
    chainId: 101,
    name: "Petur Shiff",
    symbol: "$GOLD",
    address: "5mbMpdFn3eSUQd4mAJc9VAahpbVAMdm1JynbA8HMgyWc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37226/large/IMG_6972.png?1713776390",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "petur-shiff",
    },
  },
  {
    chainId: 101,
    name: "Pew4Sol",
    symbol: "PEW",
    address: "5zgTYTDK836G2Fc4ZLQp4rsyi78pAbuDr4qaQUE1pump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38456/large/IMG_3188.jpeg?1717562907",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pew4sol",
    },
  },
  {
    chainId: 101,
    name: "Phase Labs Staked SOL",
    symbol: "PHASESOL",
    address: "phaseZSfPxTDBpiVb96H4XFSD8xHeHxZre5HerehBJG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37483/large/phaseSOL.png?1714475320",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "phase-labs-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Phauntem",
    symbol: "PHAUNTEM",
    address: "5veVHPNDsmSxDW4Abpb368wLHpo32LP3fqhfJHnkSSwo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36069/large/mIgbxZi3_400x400_%281%29.jpg?1710405783",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "phauntem",
    },
  },
  {
    chainId: 101,
    name: "Phoenix AI",
    symbol: "PXAI",
    address: "6ShUgWF9QndhbShHtkuXfbfmx1hS7dMdSsWGJyQRUxRq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36258/large/2_%282%29.png?1715885517",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "phoneix-ai",
    },
  },
  {
    chainId: 101,
    name: "Phteven",
    symbol: "PHTEVE",
    address: "DhRQWsiP53gVXnG8KQwq63Uo1FvouRpVNL8536tsVD5H",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34120/large/Phteve.jpeg?1704046341",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "phteven",
    },
  },
  {
    chainId: 101,
    name: "Physis",
    symbol: "PHY",
    address: "EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23087/large/PHY-icon-200x200.png?1696522377",
    tags: [],
    verified: true,
    holders: 1007,
    extensions: {
      coingeckoId: "physis",
    },
  },
  {
    chainId: 101,
    name: "Picasso",
    symbol: "PICA",
    address: "966vsqwoS3ZBrHesTyAvE7esFV2kaHaDFLLXs4asPdLJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28286/large/PICA.png?1696527286",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "picasso",
    },
  },
  {
    chainId: 101,
    name: "Pico Staked SOL",
    symbol: "PICOSOL",
    address: "picobAEvs6w7QEknPce34wAE4gknZA9v5tTonnmHYdX",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37484/large/picosol.png?1714475578",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pico-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "PigCat",
    symbol: "PC",
    address: "F5WPg7xdZczNg5pynWjPK8TZLT52WmAiT1ZuKRbaLnEM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36512/large/imresizer-1711498283100.png?1711692352",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pigcatsol",
    },
  },
  {
    chainId: 101,
    name: "Pigeon In Yellow Boots",
    symbol: "PIGEON",
    address: "Gv7sW9pe9khuPjCYmRUTmHNAFm8yWDJVtDVyA1DExqr4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35971/large/logopigeon.jpg?1710269274",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pigeon-in-yellow-boots",
    },
  },
  {
    chainId: 101,
    name: "Pigga",
    symbol: "PIGGA",
    address: "AeH2QVrVgpZzkEXH7PVNaGVZF4d8Qn1RtToUzCEB3UnG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37995/large/icon.png?1716225759",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pigga",
    },
  },
  {
    chainId: 101,
    name: "Pineapple Cat",
    symbol: "PCAT",
    address: "2Dk3XsYZf4jedqSkFgFpVrQYdVMmRB4AgQRaMRPo4sPo",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37562/large/PCAT-LOGO.png?1714876305",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pineapple-cat",
    },
  },
  {
    chainId: 101,
    name: "Pingu on SOL",
    symbol: "PINGU",
    address: "Ffjrfw9phxYYpQc9fyYq5uRV3K6943Wzo3t9a1L4vfoW",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37415/large/pingu_%28200%29.jpg?1714375321",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pingu-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Pink Elements",
    symbol: "PINK",
    address: "CfUysiZv5tYdtAKxNcK8VP5vz9uRBLNv6NQfKe2hMfbb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37510/large/PINK.jpg?1714620335",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pink-elements",
    },
  },
  {
    chainId: 101,
    name: "PIP",
    symbol: "PIP",
    address: "HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22384/large/qE35Vvc4_400x400.jpg?1696521728",
    tags: [],
    verified: true,
    holders: 38554,
    extensions: {
      coingeckoId: "pip",
    },
  },
  {
    chainId: 101,
    name: "PIP",
    symbol: "PIP",
    address: "EEfuxw7vmtoqG3EWzJAo6Txb5z1ci5wvMw2wrHwdQSq1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35799/large/pip.jpg?1709893997",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pip-2",
    },
  },
  {
    chainId: 101,
    name: "PIPI",
    symbol: "PIPI",
    address: "HmTZ1SFRhgp63kgoT64iAwKXZez9Wf3df8r3MFywN8mp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38568/large/PIPI_LOGO_200.png?1718007792",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pipi",
    },
  },
  {
    chainId: 101,
    name: "pipi the cat",
    symbol: "PIPI",
    address: "BZKuDqzD4rb2puUV2cbSNd2kZzJ5JzKiQKwBzCk8hrLu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34498/large/Design_uten_navn_%2865%29.png?1705265190",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pipi-the-cat",
    },
  },
  {
    chainId: 101,
    name: "Pixi",
    symbol: "PIXI",
    address: "FtHCi9cxJSSizrzMzsPjAfTfJi32V1CGRDM5Skqn4QBF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38189/large/PIXI-ICON.png?1716789648",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pixi",
    },
  },
  {
    chainId: 101,
    name: "Pizza Cat",
    symbol: "PIZA",
    address: "ED1ReyTGiXJcWryN3XxyGJwhdTrCUtGqu52Vwcopf2Mq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37962/large/WhatsApp_Image_2024-05-18_at_10.06.55.jpeg?1716180082",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pizza-cat",
    },
  },
  {
    chainId: 101,
    name: "Planktos",
    symbol: "PLANK",
    address: "DVuaDuQdPZ6H49inC2Xoyx7BpLAAJTPPChSfHuGpy8X4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35216/large/photo_2024-02-05_01-03-59.jpg?1707903140",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "planktos",
    },
  },
  {
    chainId: 101,
    name: "Player 2",
    symbol: "DEO",
    address: "DeoP2swMNa9d4SGcQkR82j4RYYeNhDjcTCwyzEhKwfAf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28811/large/logo_deo_small_200x200.png?1696527788",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "player-2",
    },
  },
  {
    chainId: 101,
    name: "Playermon",
    symbol: "PYM",
    address: "7yF97k6jrBkb7BXJYXzmwQLQyxVirLtCSSGb75qLqAN8",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20682/large/eALRa3rZ_400x400.png?1696520082",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "playermon",
    },
  },
  {
    chainId: 101,
    name: "Playground Waves Floor Index",
    symbol: "WAVES",
    address: "4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24388/large/playgorund_waves.PNG?1696523571",
    tags: [],
    verified: true,
    holders: 336,
    extensions: {
      coingeckoId: "playground-waves-floor-index",
    },
  },
  {
    chainId: 101,
    name: "PlayZap",
    symbol: "PZP",
    address: "2CnfY3wZu715Mdbj1CeWfMNcsKNqkREPjTmW4o1Psj9J",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24727/large/200X200-111.png?1696523890",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "playzap",
    },
  },
  {
    chainId: 101,
    name: "Plink Cat",
    symbol: "PLINK",
    address: "9kCuaGMx6GNBcTfZHEJquzjZaayVThVLPzoQ16SYNj2c",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35941/large/cmc.png?1710233160",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "plink-cat",
    },
  },
  {
    chainId: 101,
    name: "Plutonian DAO",
    symbol: "PLD",
    address: "2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25092/large/logo-pld_200.png?1696524243",
    tags: [],
    verified: true,
    holders: 17428,
    extensions: {
      coingeckoId: "plutonian-dao",
    },
  },
  {
    chainId: 101,
    name: "PLZ COME BACK TO ETH",
    symbol: "PLZ",
    address: "JAmcJfhcJXrAP2neAHwBhajjmqUqPQt8Nyc42WH4LpUx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34488/large/PLZ_ETH.jpg?1705045935",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "plz-come-back-to-eth",
    },
  },
  {
    chainId: 101,
    name: "Pnut",
    symbol: "PNUT",
    address: "7jYfnjn3jHWmUhhgfkZ9uUEKroH3Zz1BvF8RmVQHm86D",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36301/large/IMG_7923.JPG?1711051913",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pnut",
    },
  },
  {
    chainId: 101,
    name: "POGAI (SOL)",
    symbol: "POGAI",
    address: "2miHpQbYLPvXxC2V234jrrrtnJdejd5xW883a5ToNW3g",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38577/large/POGAI.jpg?1718011153",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pogai-sol",
    },
  },
  {
    chainId: 101,
    name: "Poglana",
    symbol: "POG",
    address: "DfCqzB3mV3LuF2FWZ1GyXer4U5X118g8oUBExeUqzPss",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28742/large/pog-logo.png?1696527722",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "poglana",
    },
  },
  {
    chainId: 101,
    name: "Points on Solana",
    symbol: "POINTS",
    address: "9cX8hMxZ2vW7pxYEPf2G5UHrcmMx83iTgGcxwwRKdarq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35819/large/points.png?1709885790",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "points-on-solana",
    },
  },
  {
    chainId: 101,
    name: "PokkyCat",
    symbol: "POKKY",
    address: "aXN31wcf1ZNFBg7svh34vdV3VhtTmAw6MKNEujRwUgC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37653/large/pokkycat.png?1715159554",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pokkycat",
    },
  },
  {
    chainId: 101,
    name: "POKO",
    symbol: "POKO",
    address: "HMm8M1PnnFKSUFUQW7fNwjXbUM6L1YX6Lw8XboPHncvR",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36802/large/poko_logo.png?1712469618",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "poko",
    },
  },
  {
    chainId: 101,
    name: "Pomcoin",
    symbol: "POM",
    address: "Dkm51nnSbJeet5fCVKgCCMiXGveLH2k6scGVh5DNTxQN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37071/large/Logo_500x500.png?1713239582",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pomcoin",
    },
  },
  {
    chainId: 101,
    name: "Ponk",
    symbol: "PONK",
    address: "HeqCcMjmuV5s25J49YiJyT6bD5qWLkP88YPajBySniaV",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33065/large/PONK_%284%29.png?1700498498",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ponk",
    },
  },
  {
    chainId: 101,
    name: "PONKE",
    symbol: "PONKE",
    address: "5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33929/large/ponke.png?1703415326",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ponke",
    },
  },
  {
    chainId: 101,
    name: "PONYHAWK",
    symbol: "SKATE",
    address: "HmjyFCA8enFGpSFCqqufCtFnsxqTJRGwfGYWFfFbmAxU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34487/large/oFFICIAL_logo.png?1706096403",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ponyhawk",
    },
  },
  {
    chainId: 101,
    name: "Ponzi",
    symbol: "PONZI",
    address: "9ZoH3ssTQBJNVidsLo49q3buDidreiZc9g3NeETP3F2s",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37328/large/photo_2024-04-22_22.38.33.jpeg?1714025278",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ponzi",
    },
  },
  {
    chainId: 101,
    name: "Ponzy",
    symbol: "PONZY",
    address: "7m5Mz5PRMAEUGdGrX4aGGcF2Bpy17D5Aye6xD4hy5o78",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35970/large/N01_3y1o_400x400.jpg?1710269081",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ponzy",
    },
  },
  {
    chainId: 101,
    name: "POOH",
    symbol: "POOH",
    address: "J8XFfJgS1cs5WVGxGwZb9Rt5rJZizdyzaLhidtvDnYjC",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30091/large/Pooh-icon.png?1696529015",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pooh",
    },
  },
  {
    chainId: 101,
    name: "POOTI (RELAUNCH)",
    symbol: "POOTI",
    address: "2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38021/large/pooti-icon.png?1716314085",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pooti-relaunch",
    },
  },
  {
    chainId: 101,
    name: "Popcat",
    symbol: "POPCAT",
    address: "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33760/large/image.jpg?1702964227",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "popcat",
    },
  },
  {
    chainId: 101,
    name: "POPDOG",
    symbol: "POPDOG",
    address: "EATGZHJViJsk7nEKkrdJicwNbfpkJfAtmrEmrjXR8NBj",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34572/large/Popdog_Logo_200.png?1705527906",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "popdog",
    },
  },
  {
    chainId: 101,
    name: "Popo, Pepe's Dog",
    symbol: "$POPO",
    address: "96QoNkZLTKH7Gecf6dKMRXBQXW1wqh378uF9pnTejgAw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33898/large/msg-4035486419-2265.jpg?1703236543",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "popo-pepe-s-dog",
    },
  },
  {
    chainId: 101,
    name: "Pork",
    symbol: "PORK",
    address: "iTjspr7XtVmCkS7mLD3U7LvyJsoBBzpabsJmsWbZo2w",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34470/large/Pork.jpg?1704983395",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pork",
    },
  },
  {
    chainId: 101,
    name: "Port3 Network",
    symbol: "PORT3",
    address: "Bo5T8zkE6Xen9ZirEqoNqczuGcsopwNnxbBcW9HqCLGJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33383/large/port3-bc-200x200.png?1710075114",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "port3-network",
    },
  },
  {
    chainId: 101,
    name: "Port Finance",
    symbol: "PORT",
    address: "PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17607/large/d-k0Ezts_400x400.jpg?1696517139",
    tags: [],
    verified: true,
    holders: 37364,
    extensions: {
      coingeckoId: "port-finance",
    },
  },
  {
    chainId: 101,
    name: "Potato",
    symbol: "POTATO",
    address: "GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18254/large/logocircle.png?1696517749",
    tags: [],
    verified: true,
    holders: 15161,
    extensions: {
      coingeckoId: "potato",
    },
  },
  {
    chainId: 101,
    name: "POTATO",
    symbol: "TATO",
    address: "9RQWJyXPF8CC4RFxU6LQjs3wr8tosRWx7xYAHgoR1keE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34100/large/tpAOK8Bu_400x400.jpg?1703959364",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "potato-2",
    },
  },
  {
    chainId: 101,
    name: "Potato",
    symbol: "POTATO",
    address: "9gr84rGyLvVasmqXvj65njjUgKyBemKWSJsvijTPmcQz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36153/large/potate.jpeg?1710740911",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "potato-3",
    },
  },
  {
    chainId: 101,
    name: "POTDOG",
    symbol: "POTDOG",
    address: "4fBpDwhKYmzvNMZrZnwWjqpvrmfoMtvQJZ98TiHWFGvK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37797/large/photo_2024-05-12_21-35-10.png?1715589866",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "potdog",
    },
  },
  {
    chainId: 101,
    name: "Pou",
    symbol: "POU",
    address: "PouWaap6bHZv1oT7T7rzRgQHTBD4nsWQRHGbB5hfDA6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35833/large/IMG_1404.jpeg?1709888248",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pou",
    },
  },
  {
    chainId: 101,
    name: "Power Staked SOL",
    symbol: "PWRSOL",
    address: "pWrSoLAhue6jUxUkbWgmEy5rD9VJzkFmvfTDV5KgNuu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37495/large/pwr_sol.jpg?1714536251",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "power-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Pre",
    symbol: "PRE",
    address: "G6mc7tiVSym3zrmmxekF3HYSc9c2hiKnGk7idoHqHTUK",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34657/large/pre.png?1705632635",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pre",
    },
  },
  {
    chainId: 101,
    name: "Prick",
    symbol: "PRICK",
    address: "6zoshtkmyX4kRFg3p152yV2bPssxeYdNvW3c6EVCE4UP",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38552/large/Pickle_Rick.png?1717985574",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "prick",
    },
  },
  {
    chainId: 101,
    name: "Print Protocol",
    symbol: "PRINT",
    address: "HKYX2jvwkdjbkbSdirAiQHqTCPQa3jD2DVRkAFHgFXXT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34884/large/Print_Protocol.png?1706530541",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "print-protocol",
    },
  },
  {
    chainId: 101,
    name: "Prism",
    symbol: "PRISM",
    address: "PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21475/large/7KzeGb1.png?1696520836",
    tags: [],
    verified: true,
    holders: 13940,
    extensions: {
      coingeckoId: "prism",
    },
  },
  {
    chainId: 101,
    name: "PRM Token",
    symbol: "PRM",
    address: "prmmgF5GJCSDNEcwZe2HWb5DsDsFngxTetZ95C4VKxX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35477/large/PRM_200x200.png?1708773724",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "prm-token",
    },
  },
  {
    chainId: 101,
    name: "PRNT",
    symbol: "PRNT",
    address: "4TUNzcgp2fPD48fcW4seRjyqyDZMrPj4ZubnXFEsKeYk",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30592/large/691D296E-3515-4758-9391-A8BBFC4A096B.png?1696529460",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "prnt",
    },
  },
  {
    chainId: 101,
    name: "PsyFi",
    symbol: "PSY",
    address: "PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22784/large/download.png?1696522087",
    tags: [],
    verified: true,
    holders: 1992,
    extensions: {
      coingeckoId: "psyoptions",
    },
  },
  {
    chainId: 101,
    name: "PUFF",
    symbol: "PUFF",
    address: "G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22049/large/PUFF_Logo.jpg?1717686669",
    tags: [],
    verified: true,
    holders: 17660,
    extensions: {
      coingeckoId: "puff",
    },
  },
  {
    chainId: 101,
    name: "PUG AI",
    symbol: "PUGAI",
    address: "PugAiDuaQ5hzicBHAW9qrQQ8qi4B6sh3n7PknKhyjeX",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30283/large/_wq.jpeg?1696529188",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pug-ai",
    },
  },
  {
    chainId: 101,
    name: "PuggleVerse",
    symbol: "PUGGLE",
    address: "oTkwQzNPYtRBMrxj9jxBgX95YfgHdFTfRU3mehRAT5p",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36744/large/pugle.jpg?1712217569",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "puggleverse",
    },
  },
  {
    chainId: 101,
    name: "Puma",
    symbol: "PUMA",
    address: "66SrpYxptaesrxWMGgv5aWKFxeZbH7egHoJ3g7E8ZXpU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34584/large/ava.png?1705422515",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "puma",
    },
  },
  {
    chainId: 101,
    name: "Pumpkin Staked SOL",
    symbol: "PUMPKINSOL",
    address: "pumpkinsEq8xENVZE6QgTS93EN4r9iKvNxNALS1ooyp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37494/large/pumpkinSOL.jpg?1714534634",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pumpkin-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Pumpr",
    symbol: "PUMPR",
    address: "PumPRGmZ56t3Vngxo6fCP7ZJQ14oUg3biKxXrEyQBSf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33361/large/Logo.png?1701604133",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pumpr",
    },
  },
  {
    chainId: 101,
    name: "Pundu",
    symbol: "PUNDU",
    address: "WskzsKqEW3ZsmrhPAevfVZb6PuuLzWov9mJWZsfDePC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36990/large/CTMV1dZU_400x400.jpg?1712998068",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pundu",
    },
  },
  {
    chainId: 101,
    name: "PunkAI",
    symbol: "PUNKAI",
    address: "12UP9cSwe1tDzQg3KSEx1BpSS9nkpT5VkmDe4fSz4Hso",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36270/large/PunkAI.jpg?1711003252",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "punkai",
    },
  },
  {
    chainId: 101,
    name: "Punkko",
    symbol: "PUN",
    address: "FaSJ3PKDjaHatJMgpC92cmhjcJxnc8sbTkpaPuTF3hW1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36918/large/punkko.png?1712788492",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "punkko",
    },
  },
  {
    chainId: 101,
    name: "PuppaCoin",
    symbol: "$PUPPA",
    address: "8shTEDxbwvM2C9ELXNHsh8pmkoWovzJ3xgazQFWFapMx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37914/large/PUPPACOIN_transparent_200x200.png?1715935698",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "puppacoin",
    },
  },
  {
    chainId: 101,
    name: "Puppy",
    symbol: "PAPI",
    address: "Bgmpks9Cx5nPZorpXCNJbsYHA5oVwDBybcqeXTAr5LKA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37368/large/PAPI_LOGO.png?1714117245",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "puppy",
    },
  },
  {
    chainId: 101,
    name: "PUPS (Ordinals)",
    symbol: "PUPS",
    address: "PUPS8ZgJ5po4UmNDfqtDMCPP6M1KP3EEzG9Zufcwzrg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34390/large/pups.jpg?1711528073",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pups-ordinals",
    },
  },
  {
    chainId: 101,
    name: "Pusscat",
    symbol: "PUSS",
    address: "BvqHcuGMDDuyTfrMLGef9E7UhfEbmBkhxEVnnADwuGJP",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37975/large/Screenshot_2024-05-20_at_01.41.26.png?1716195960",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pusscat",
    },
  },
  {
    chainId: 101,
    name: "Puss Cat",
    symbol: "PUCA",
    address: "3rWUivXsgzqmgijmShk2bsRTHBaWSLe4CQaY2xsP8hQ9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37643/large/IMG_20240416_102407_960.jpg?1715141520",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "puss-cat",
    },
  },
  {
    chainId: 101,
    name: "Pyth Network",
    symbol: "PYTH",
    address: "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31924/large/pyth.png?1701245725",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "pyth-network",
    },
  },
  {
    chainId: 101,
    name: "Q Coin",
    symbol: "QKC",
    address: "8RjE2Wws8wjQ4HJoS1PUoCu1TUN3VdJpZAgEnrSEGNJC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38343/large/logo.png?1717142121",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "q-coin",
    },
  },
  {
    chainId: 101,
    name: "Quack Capital",
    symbol: "QUACK",
    address: "5GddexuCGfWx1PgQ5tuHXvqqQ1t24Jwv3mPWeSxw73ys",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37493/large/Quack_Capital.png?1714513019",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "quack-capital",
    },
  },
  {
    chainId: 101,
    name: "QUBY AI",
    symbol: "QYAI",
    address: "A6jb9PSPxhmy4hegFV2eSTk3WeVsnYVRxWTpMWKmUe21",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37398/large/qybyai.png?1714319101",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "quby-ai",
    },
  },
  {
    chainId: 101,
    name: "Quilson",
    symbol: "QUIL",
    address: "BzW7Gnm32KTn82a1yCiYFooBLzi37MvEXFF8soQVpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38521/large/logo_s.png?1717758608",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "quilson",
    },
  },
  {
    chainId: 101,
    name: "Quiztok",
    symbol: "QTCON",
    address: "DkNihsQs1hqEwf9TgKP8FmGv7dmMQ7hnKjS2ZSmMZZBE",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/8208/large/QTCON.png?1696508415",
    tags: [],
    verified: true,
    holders: 5120,
    extensions: {
      coingeckoId: "quiztok",
    },
  },
  {
    chainId: 101,
    name: "RabbitPad",
    symbol: "RABBIT",
    address: "RPADrhAh25uduoVh4eC4A1nK3TwvwSuxve5vwryRYvp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37585/large/Rabbit-Pad-Ava-1-1.jpg?1714968456",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rabbitpad",
    },
  },
  {
    chainId: 101,
    name: "RaceFi",
    symbol: "RACEFI",
    address: "AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21873/large/XIF9z8Z6_400x400.jpg?1696521227",
    tags: [],
    verified: true,
    holders: 9487,
    extensions: {
      coingeckoId: "racefi",
    },
  },
  {
    chainId: 101,
    name: "Racket",
    symbol: "$RKT",
    address: "RKT69NZHN5uovcan3q5hRbZzfJuXiGEuPmGANoBJLLz",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33387/large/IMG_6443.jpeg?1701675238",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "racket",
    },
  },
  {
    chainId: 101,
    name: "RAD",
    symbol: "RAD",
    address: "B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22854/large/unknown.png?1696522155",
    tags: [],
    verified: true,
    holders: 5121,
    extensions: {
      coingeckoId: "rad",
    },
  },
  {
    chainId: 101,
    name: "RapCat",
    symbol: "$RAPCAT",
    address: "BmBbWGWgiLgHLzVCy47PkQo9E1hMPTwoKb6hETVnpuN3",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38348/large/IMG_2754.jpeg?1717144889",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rapcat",
    },
  },
  {
    chainId: 101,
    name: "Raphael",
    symbol: "RAPHAEL",
    address: "4cbtcz3NyTVAGfRtieEQVt6YSjWpJ3dwNYAvytrsNyQx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34095/large/RAPHAEL.jpg?1703947972",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "raphael",
    },
  },
  {
    chainId: 101,
    name: "Ratio Protocol",
    symbol: "RATIO",
    address: "ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24543/large/ratio.png?1696523720",
    tags: [],
    verified: true,
    holders: 1861,
    extensions: {
      coingeckoId: "ratio-finance",
    },
  },
  {
    chainId: 101,
    name: "RatWifHat",
    symbol: "RATWIF",
    address: "6hsQFV7DsSyyVmu6sm4UkvYbpHTJhiUyB8fr4DdhSmkg",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37117/large/RatWif_logo.png?1713336842",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ratwifhat",
    },
  },
  {
    chainId: 101,
    name: "Raydium",
    symbol: "RAY",
    address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/13928/large/PSigc4ie_400x400.jpg?1696513668",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "raydium",
    },
  },
  {
    chainId: 101,
    name: "ReadySwap",
    symbol: "RS",
    address: "GmHKxCyXdkM1a7cGugYtkbUGsLRR4z1rXgLYiULJ5oco",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38057/large/photo_2024-05-21_23-46-30.jpg?1716407703",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "readyswap",
    },
  },
  {
    chainId: 101,
    name: "real fast",
    symbol: "SPEED",
    address: "5Wd2ALxQfnpgQKCyH4WL9giBiiuuLuJs84CJxfQccvmN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37888/large/photo1715294514.jpeg?1715842472",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "real-fast",
    },
  },
  {
    chainId: 101,
    name: "RNT",
    symbol: "RNT",
    address: "2fUFhZyd47Mapv9wcfXh5gnQwFXtqcYu9xAN4THBpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38534/large/tate.png?1717785682",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "real-nigger-tate",
    },
  },
  {
    chainId: 101,
    name: "Real Smurf Cat-шайлушай",
    symbol: "SMURF",
    address: "EArkn8uVf8YLfpF2eCdkCvDaPYpQuJzKXxaCnyxXc2P7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35355/large/2024-03-01_10.22.57.jpg?1709395204",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "real-smurf-cat-2",
    },
  },
  {
    chainId: 101,
    name: "Realy Metaverse",
    symbol: "REAL",
    address: "AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21448/large/2SIFWp0b_400x400.jpg?1696520811",
    tags: [],
    verified: true,
    holders: 54010,
    extensions: {
      coingeckoId: "realy-metaverse",
    },
  },
  {
    chainId: 101,
    name: "Rebase GG IRL",
    symbol: "$IRL",
    address: "HEmgGwgn6naqNatkU9ST2wHa4X9HRMmxE7qE8vWuV7L2",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33556/large/200_no_BG.png?1702429370",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rebase-gg-irl",
    },
  },
  {
    chainId: 101,
    name: "Red The Mal",
    symbol: "RED",
    address: "7zfnQC53eGtn2H6AxW16sGCmKnnUC8ajCwBVZwd7wtY3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35868/large/Red_The_Mal.png?1709972699",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "red-the-mal",
    },
  },
  {
    chainId: 101,
    name: "reeeeeeeeeeeeeeeeeeeee",
    symbol: "REEE",
    address: "4ABXJEK62bfKqPiCbSsUtmb4nfkCNPDGtvLhwQcAWNjc",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38049/large/1000007581.jpg?1716402214",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "reeeeeeeeeeeeeeeeeeeee",
    },
  },
  {
    chainId: 101,
    name: "remilio",
    symbol: "REMILIO",
    address: "remiG7sGaHWgrY7o6SXJW5CYi5A7kmKutyJz6x6hUsp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37464/large/REMILIO.jpg?1714455040",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "remilio",
    },
  },
  {
    chainId: 101,
    name: "Render",
    symbol: "RNDR",
    address: "rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/11636/large/rndr.png?1696511529",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "render-token",
    },
  },
  {
    chainId: 101,
    name: "Rendy AI",
    symbol: "RENDY",
    address: "Bw7rU9vQVMhvgAtcdFYxXm2Ya2JCDzruifD5GE5WZvUb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38369/large/1000017119.jpg?1717292797",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rendy-ai",
    },
  },
  {
    chainId: 101,
    name: "Republic Credits",
    symbol: "RPC",
    address: "EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25094/large/logo-rpc_200.png?1696524245",
    tags: [],
    verified: true,
    holders: 15000,
    extensions: {
      coingeckoId: "republic-credits",
    },
  },
  {
    chainId: 101,
    name: "RETARDIO",
    symbol: "RETARDIO",
    address: "6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35759/large/RETARDIO_LOGO-200x200.png?1709726267",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "retardio",
    },
  },
  {
    chainId: 101,
    name: "Retire on Sol",
    symbol: "$RETIRE",
    address: "HXkbUADfocGyz2WrzJpjEfry8qyNDm5Kwiiq3Mz3tTi1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35254/large/2024-02-13_00.10.53_%281%29.png?1707996734",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "retire-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Reward Protocol",
    symbol: "REWD",
    address: "2eu1K3wvfPC7gVj1CK8ohv4ggusdN6qxyxpjHyTCkjZT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35410/large/2024-02-12_13.01.49.jpg?1708502447",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "reward-protocol",
    },
  },
  {
    chainId: 101,
    name: "Rex",
    symbol: "REX",
    address: "CcTxKZHqU4E2Tek5gh1GZoPZPHncGFkZ36EVMsgDn6sK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36485/large/200.jpg?1711547282",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rex-2",
    },
  },
  {
    chainId: 101,
    name: "Rexwifhat",
    symbol: "REXHAT",
    address: "AcV2T3mwLUqMiiqcsafVm35zwPQkmLrfRtaW3716Fzvi",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38597/large/token.png?1718094701",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rexwifhat",
    },
  },
  {
    chainId: 101,
    name: "RHO",
    symbol: "RHO",
    address: "Trhor7npQLca4DFiUWR9vJCAw1je2zghSbwh37nW81i",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35830/large/rho.jpeg?1709888026",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rho",
    },
  },
  {
    chainId: 101,
    name: "RIBBIT",
    symbol: "RBT",
    address: "65nTNuJGHme4PQvKQyJykKp1bJAkK4A8Q66sd2yBWugf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33042/large/download_%281%29.png?1700428625",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ribbit-2",
    },
  },
  {
    chainId: 101,
    name: "RICH on SOL",
    symbol: "RICH",
    address: "A2PVd9wmEk9Ek9MFbF6VVBm4UiGYK24TCmE5oR2WDWGH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38444/large/1000020387.jpg?1717554403",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rich-on-sol",
    },
  },
  {
    chainId: 101,
    name: "Rich Quack",
    symbol: "QUACK",
    address: "9HGziACBczM3VorD3B6ZYtj6BA595jq8erzsSVtUNo6N",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16356/large/57198446-0-Get-Rich-Quick-Gober.png?1696515955",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "richquack",
    },
  },
  {
    chainId: 101,
    name: "Rich Rabbit",
    symbol: "RABBIT",
    address: "BCpa4PVRovJv9EszgYvLRh1WqHq1PjE6LCr9EBdWGSa9",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34758/large/southeast.jpeg?1705982316",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rich-rabbit",
    },
  },
  {
    chainId: 101,
    name: "RIZZ Solana",
    symbol: "RIZZ",
    address: "6Fb84TUdMNAVgwRinLeTgLov8dJnk5yhNt41Xq2a6s4c",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37717/large/rizz.png?1715331348",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rizz-solana",
    },
  },
  {
    chainId: 101,
    name: "Rkey",
    symbol: "RKEY",
    address: "F4YXmo8CDLRvWGnXyxVZ62wwc7oWcBam7tu4G5kGSaKZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37457/large/rkey_logo_white.png?1714449300",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rkey",
    },
  },
  {
    chainId: 101,
    name: "ROACORE",
    symbol: "ROA",
    address: "5tB5D6DGJMxxHYmNkfJNG237x6pZGEwTzGpUUh62yQJ7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29153/large/roa.png?1696528112",
    tags: [],
    verified: true,
    holders: 4402,
    extensions: {
      coingeckoId: "roaland-core",
    },
  },
  {
    chainId: 101,
    name: "Roaring Kitty (Sol)",
    symbol: "STONKS",
    address: "8SZ4EsABY82RMNm3Ce2RtKFdA9BPfb4NaoMyCQ7Y3cYT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36662/large/IMG_7922.jpeg?1712043120",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "roaring-kitty-sol",
    },
  },
  {
    chainId: 101,
    name: "Roaring Kitty",
    symbol: "KITTY",
    address: "EKEWAk7hfnwfR8DBb1cTayPPambqyC7pwNiYkaYQKQHp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37853/large/KITTY.jpg?1715778802",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "roaring-kitty-solana",
    },
  },
  {
    chainId: 101,
    name: "Rock",
    symbol: "ROCK",
    address: "5KxnfDmsXVBNkVHYhW4kztV7ZCCCbrkYxBVrqLWF3G7J",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34375/large/m0x31ZCuqG640Dvteo-GmiKaLvD7YvmLnV7WrT7Ugmo.png?1704762840",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rock-2",
    },
  },
  {
    chainId: 101,
    name: "Rocky",
    symbol: "ROCKY",
    address: "DhTNFBp4NjaxWqf6LwG57GrJQZfXrso1qK9FcMZt9sv7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33099/large/l4jBGhzS_400x400.jpg?1712720413",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rocky",
    },
  },
  {
    chainId: 101,
    name: "Rocky the dog",
    symbol: "ROCKY",
    address: "4icEZCrEYNop2ZaMMCkRHaNzkt6xG9BpijMCQV7mpw6Z",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33482/large/ROCKY.png?1702020138",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rocky-the-dog",
    },
  },
  {
    chainId: 101,
    name: "ROD.AI",
    symbol: "RODAI",
    address: "GdbyLsNKHKLXTZVEo8QrGKVmrexEeZUYvhpSfuZ9TdkC",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35948/large/logo.png?1710234425",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rod-ai",
    },
  },
  {
    chainId: 101,
    name: "Roger",
    symbol: "ROGER",
    address: "8hN217wBBgRkDiyzabLmSuS95pxX5PFcv3HEM5Ha4ZEv",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37584/large/ROGER.jpg?1714968518",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "roger",
    },
  },
  {
    chainId: 101,
    name: "RonWeasleyTrumpToadN64Inu",
    symbol: "BNB",
    address: "J6JqsRUskYK9kA7kgkvMrZxn5dvWoGvjfhRJnTV5ayiA",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34233/large/Untitled.png?1704305945",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ronweasleytrumptoadn64inu",
    },
  },
  {
    chainId: 101,
    name: "Rope Token",
    symbol: "ROPE",
    address: "8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14661/large/rope-v6.png?1696514336",
    tags: [],
    verified: true,
    holders: 64661,
    extensions: {
      coingeckoId: "rope-token",
    },
  },
  {
    chainId: 101,
    name: "Rosa Inu",
    symbol: "ROSA",
    address: "6FDJbSkiySbetXaELZYaL9im8mAyCkMfHB7rtDt9p3qp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37963/large/ROSA_TOKEN_3.png?1716183016",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rosa-inu",
    },
  },
  {
    chainId: 101,
    name: "ROYAL",
    symbol: "ROYAL",
    address: "D7rcV8SPxbv94s3kJETkrfMrWqHFs6qrmtbiu6saaany",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32361/large/_ROYALCOIN_SPACE.png?1698028506",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "royal",
    },
  },
  {
    chainId: 101,
    name: "Ruburt F Kenidy Jr",
    symbol: "KENIDY",
    address: "Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37426/large/IMG_3706_%282%29.png?1714378744",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ruburt-f-kenidy-jr",
    },
  },
  {
    chainId: 101,
    name: "Rug",
    symbol: "RUG",
    address: "BJRfDU8abGXmPiAj72kuc8AtXfxV8KFZAvdG3GTuB5Qc",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35096/large/photo_2024-02-06_19-27-44.jpg?1707372728",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rug-rugged-art",
    },
  },
  {
    chainId: 101,
    name: "Run",
    symbol: "RUN",
    address: "6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21374/large/cZoMwlyl_400x400.jpg?1696520739",
    tags: [],
    verified: true,
    holders: 52025,
    extensions: {
      coingeckoId: "run",
    },
  },
  {
    chainId: 101,
    name: "RUSH",
    symbol: "RUSH",
    address: "RUSHazfGVYTM4fvKmeQeTYSg1sk49yaJn3kL3gTcbpF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34161/large/rush_logo_circle-2.png?1704213653",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "rush-2",
    },
  },
  {
    chainId: 101,
    name: "Saber",
    symbol: "SBR",
    address: "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17162/large/oYs_YFz8_400x400.jpg?1696516721",
    tags: [],
    verified: true,
    holders: 113550,
    extensions: {
      coingeckoId: "saber",
    },
  },
  {
    chainId: 101,
    name: "Sad Ape",
    symbol: "SAPE",
    address: "4cxFF2iHmgkhWqjjbvGvqrCwjSGLTQV1CEZJfgCyrRQw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37791/large/ed0ddd58-526a-4e8a-8d6e-a37983e49443.jpeg?1715589273",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sad-ape",
    },
  },
  {
    chainId: 101,
    name: "SAD HAMSTER",
    symbol: "HAMMY",
    address: "26KMQVgDUoB6rEfnJ51yAABWWJND8uMtpnQgsHQ64Udr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35878/large/Photo_March_8_2024.jpg?1709982693",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sad-hamster",
    },
  },
  {
    chainId: 101,
    name: "SafeBonk",
    symbol: "SBONK",
    address: "5VDVcG1c8EFGug3yz4zDE3a2BNxczVoYtRQDySTChx17",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33887/large/safebonklogo.jpg?1703149054",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "safebonk",
    },
  },
  {
    chainId: 101,
    name: "Safemuun",
    symbol: "SAFEMUUN",
    address: "9WBNLGfQUX3sEWHLCbgCjinsbjWujd37pMpFZHbLPco5",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36203/large/Safemuun.png?1710833208",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "safemuun",
    },
  },
  {
    chainId: 101,
    name: "Saitama (SOLTAMA)",
    symbol: "SOLTAMA",
    address: "BpD8kZ2DdKpUej5LFhC2TRDGB2cG9wPgDyF4VN4SabBz",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34554/large/IMG_20240115_001910_281_200x200.jpg?1705369821",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "saitama-soltama",
    },
  },
  {
    chainId: 101,
    name: "Salt N Vinegar",
    symbol: "SNV",
    address: "AkUSLHPFizs7iBcycKVriyqifgahwGvt5zof9v7c2upB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37815/large/dp.png?1715654473",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "salt-n-vinegar",
    },
  },
  {
    chainId: 101,
    name: "Sam Bankmeme Fried",
    symbol: "SBF",
    address: "FkbWN4dcFQym2PgCELfThghQqLuA2e2jThMJyhZjfG4M",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35895/large/sbf_logo_transparent.png?1715223796",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sam-bankmeme-fried",
    },
  },
  {
    chainId: 101,
    name: "Samoyedcoin",
    symbol: "SAMO",
    address: "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15051/large/IXeEj5e.png?1696514710",
    tags: [],
    verified: true,
    holders: 118456,
    extensions: {
      coingeckoId: "samoyedcoin",
    },
  },
  {
    chainId: 101,
    name: "Samurai Cat",
    symbol: "YUKI",
    address: "53yANribNp1WzRsciY6upAN2VPY85waZEtADTeJhtQGN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37251/large/Samurai_cat.jpeg?1713863682",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "samurai-cat",
    },
  },
  {
    chainId: 101,
    name: "Saros",
    symbol: "SAROS",
    address: "SarosY6Vscao718M4A778z4CGtvcwcGef5M9MEH1LGL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34594/large/saros-token-logo.png?1705476813",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "saros-finance",
    },
  },
  {
    chainId: 101,
    name: "Sator",
    symbol: "SAO",
    address: "2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19410/large/sator-logo-CMC.png?1696518849",
    tags: [],
    verified: true,
    holders: 58614,
    extensions: {
      coingeckoId: "sator",
    },
  },
  {
    chainId: 101,
    name: "SAUCE",
    symbol: "SAUCE",
    address: "427xvZVKbFj7ZyfFoYS9iFpNuNsrijm6T9VP8znfko9j",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35667/large/Sauce.png?1709485183",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sauce",
    },
  },
  {
    chainId: 101,
    name: "SAUDI PEPE",
    symbol: "SAUDIPEPE",
    address: "GxihB7J6xfiLDq7iMdgj39jKugt69QoHGDzXqFYPk2zQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30318/large/undefined_-_Imgur.png?1696529219",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "saudi-pepe",
    },
  },
  {
    chainId: 101,
    name: "Save Elon Coin",
    symbol: "SEC",
    address: "JA9AZqUjCgdAqL71D6XbSd9vpA1pecmM9UnZyUgVc4wy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34386/large/sec_logo_200.png?1705688473",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "save-elon-coin",
    },
  },
  {
    chainId: 101,
    name: "SCALES",
    symbol: "SCALES",
    address: "8FA3TPf84h9gEZAxDhiLkMN4vvZcdEUZxmGadbo12auh",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38452/large/SCALES.jpg?1717559305",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "scales",
    },
  },
  {
    chainId: 101,
    name: "School Hack Coin",
    symbol: "SHC",
    address: "2kGHZEg3oGT8ane65bDBdsBdF98ng2nmqFfPRj1hK8LX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37634/large/UkuZotbq_400x400.png?1715101722",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "school-hack-coin",
    },
  },
  {
    chainId: 101,
    name: "Scottish",
    symbol: "$SCOT",
    address: "EoJEyppNNpycP1ZoPnWq6cxwA7mSYpr9T1WXPFyjEzEy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37801/large/IMG_20240507_070744_124.jpg?1715590254",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "scottish",
    },
  },
  {
    chainId: 101,
    name: "Scrap",
    symbol: "SCRAP",
    address: "6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23086/large/bd1b1275fdc0ac1.png?1696522376",
    tags: [],
    verified: true,
    holders: 5971,
    extensions: {
      coingeckoId: "scrap",
    },
  },
  {
    chainId: 101,
    name: "SDOGE",
    symbol: "SDOGE",
    address: "E2WYCGJJtWBodVLy1NKcN8ve4UAtsJJBU2mdErbXxP8h",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34119/large/photo_2023-12-25_16-14-47_%281%29_%281%29_%282%29.jpg?1704021566",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sdoge",
    },
  },
  {
    chainId: 101,
    name: "SCOIN",
    symbol: "SCOIN",
    address: "5qKDWkBejLtRh1UGFV7e58QEkdn2fRyH5ehVXqUYujNW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32851/large/scoin_bw_200x200.png?1708680778",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sdrive-app",
    },
  },
  {
    chainId: 101,
    name: "Seal",
    symbol: "SEAL",
    address: "3B3Zfs7eb46Re9GHWv6ccYRSBGy5EvQF2i2VXMD6tge6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37235/large/1000003649.png?1713795596",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "seal-sol",
    },
  },
  {
    chainId: 101,
    name: "sealwifhat",
    symbol: "SI",
    address: "Fxgdfsy1Z5Mvh53o69s2Ev6TGxtAJ1RQ5RJ5moCpKmQZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35186/large/-SI.png?1707813689",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sealwifhat",
    },
  },
  {
    chainId: 101,
    name: "Secret Skellies Society",
    symbol: "$CRYPT",
    address: "CRYPTi2V87Tu6aLc9gSwXM1wSLc6rjZh3TGC4GDRCecq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28865/large/plain-skull_Large_200x200.png?1696527839",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "secret-skellies-society",
    },
  },
  {
    chainId: 101,
    name: "Secretum",
    symbol: "SER",
    address: "HNpdP2rL6FR6jM3bDxFX2Zo32D1YG2ZCztf9zzCrKMEX",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25378/large/ser.png?1696524511",
    tags: [],
    verified: true,
    holders: 5932,
    extensions: {
      coingeckoId: "secretum",
    },
  },
  {
    chainId: 101,
    name: "Seeded Network",
    symbol: "SEEDED",
    address: "seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23618/large/seeded.png?1696522824",
    tags: [],
    verified: true,
    holders: 1938,
    extensions: {
      coingeckoId: "seeded-network",
    },
  },
  {
    chainId: 101,
    name: "Sekai DAO",
    symbol: "SEKAI",
    address: "12fhde7Sur7DBXwCjKRbfR9H3fAsc1MKHMdqaEnqa1kf",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35881/large/06BA02A3-16F8-4D23-BBD3-235286E0E0BE.jpeg?1709995317",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sekai-dao",
    },
  },
  {
    chainId: 101,
    name: "SelfieDogCoin",
    symbol: "SELFIE",
    address: "9WPTUkh8fKuCnepRWoPYLH3aK9gSjPHFDenBq2X1Czdp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37796/large/2024-05-13_02.57.47.jpg?1715589832",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "selfiedogcoin",
    },
  },
  {
    chainId: 101,
    name: "SelfieSteve",
    symbol: "SSE",
    address: "CAa3j9oD6nDn5AeRmwZ6fcR78TAVXv9kumoihWvSbXsB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38314/large/F100035E-88A7-40F4-88FF-C386E2AC743A.jpeg?1717053198",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "selfiesteve",
    },
  },
  {
    chainId: 101,
    name: "SENK",
    symbol: "SENK",
    address: "FeBg9Utf5wFa2PsT6KnJ6uitvWtfc87R38wmRnxrNMiW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38326/large/photo_2024-04-24_18-14-17.jpg?1717074014",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "senk",
    },
  },
  {
    chainId: 101,
    name: "Sentre",
    symbol: "SNTR",
    address: "SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19268/large/sentre.PNG?1696518712",
    tags: [],
    verified: true,
    holders: 3916,
    extensions: {
      coingeckoId: "sentre",
    },
  },
  {
    chainId: 101,
    name: "Serum",
    symbol: "SRM",
    address: "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/11970/large/serum-logo.png?1696511829",
    tags: [],
    verified: true,
    holders: 246927,
    extensions: {
      coingeckoId: "serum",
    },
  },
  {
    chainId: 101,
    name: "Sharbi",
    symbol: "$SHARBI",
    address: "8D1nUMJQam54o34Kj2knFhSTaWoehEr4mBc7LfiDdCqq",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29075/large/Sharbi_New_Logo_200x200.png?1712686416",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sharbi",
    },
  },
  {
    chainId: 101,
    name: "SHARE",
    symbol: "SHARE",
    address: "E6Eg7Esj5tfSwkbDGdrzhrotqptv7ghJNarLZ9rbHDSG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38041/large/SHARE_logo_new_round_200px.png?1716336562",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "share-on-crypto",
    },
  },
  {
    chainId: 101,
    name: "Shark",
    symbol: "SHARK",
    address: "3U8cZTbq4QkT14Kvb8Rj8CxfcyQx5V2XJHicMYE7QDmQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36224/large/Avatar_-_Sharko.png?1710870547",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shark-2",
    },
  },
  {
    chainId: 101,
    name: "Shark Cat",
    symbol: "SC",
    address: "6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36562/large/shark.jpeg?1715148291",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shark-cat",
    },
  },
  {
    chainId: 101,
    name: "Sharki",
    symbol: "SHARKI",
    address: "6U48jtR53ZK3E1MozLrpwJDTrtj74uuFhMGNzGY18YPu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38476/large/SharkiTemplateNoBg.png?1717651764",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sharki",
    },
  },
  {
    chainId: 101,
    name: "Sharky",
    symbol: "SHARK",
    address: "SHARKSYJjqaNyxVfrpnBN9pjgkhwDhatnMyicWPnr1s",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36804/large/_SHARK_logo_-_no_background.png?1712470197",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sharky-fi",
    },
  },
  {
    chainId: 101,
    name: "SheeshSPL",
    symbol: "SHEESH",
    address: "ShEEsukacNfbBpULD1xtCZKjeMMzvc78xufMDuE3jvB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34087/large/__1.png?1703911361",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sheeshin-on-solana",
    },
  },
  {
    chainId: 101,
    name: "SHELL",
    symbol: "SS20",
    address: "6YsbefsCddFqUGyMxd788LQHbCGsGgmzjr5cowpYqRhk",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34568/large/Frame_%282%29.png?1705680817",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shell",
    },
  },
  {
    chainId: 101,
    name: "SHEPE",
    symbol: "$SHEPE",
    address: "AFipALAxPsYgN14NEPZAWqNvtSedeNvPLRE43uZSd33e",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36505/large/coin.png?1711622039",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shepe",
    },
  },
  {
    chainId: 101,
    name: "SHIBAAI",
    symbol: "SHIBAAI",
    address: "836AY1nD2RrtbYYUqoj39JPTzCwcehrq4M5pvFiPnKEH",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35485/large/hero-shiba_%281%29.png?1708851072",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shibaai",
    },
  },
  {
    chainId: 101,
    name: "Shiba Inu (Wormhole)",
    symbol: "SHIB",
    address: "CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22983/large/SHIB_wh_small.png?1696522280",
    tags: [],
    verified: true,
    holders: 1575,
    extensions: {
      coingeckoId: "shiba-inu-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Shibana",
    symbol: "BANA",
    address: "BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21234/large/solshib.png?1696520608",
    tags: [],
    verified: true,
    holders: 7522,
    extensions: {
      coingeckoId: "shibana",
    },
  },
  {
    chainId: 101,
    name: "Shibasso",
    symbol: "SHIBASSO",
    address: "3rcwsZ86w1npjDBmXBL3XSxxK6TcwSPzXFSuCx2kTFP4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36462/large/IMG_4894.jpeg?1711510552",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shibasso",
    },
  },
  {
    chainId: 101,
    name: "shibawifhat",
    symbol: "$WIF",
    address: "BuxHW3tBhHv9ofrQ8Dok2F5Dk24BH8MrHH9WpGiQ859b",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33811/large/shibawifhat.png?1703017648",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shibawifhat",
    },
  },
  {
    chainId: 101,
    name: "SHIBONK",
    symbol: "SBONK",
    address: "H1G6sZ1WDoMmMCFqBKAbg9gkQPCo1sKQtaJWz9dHmqZr",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28675/large/bonklogo2.png?1696527659",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shibonk-311f81df-a4ea-4f31-9e61-df0af8211bd7",
    },
  },
  {
    chainId: 101,
    name: "SHIB ON SOLANA",
    symbol: "SHIB",
    address: "7wz31sC5z979UMtiqrtxsYYQ6bJzpjUTgPsZvXuZhso",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38259/large/200x200_SHIB.png?1716928714",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shib-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Shibwifhatcoin",
    symbol: "SHIB",
    address: "F6qoefQq4iCBLoNZ34RjEqHjHkD8vtmoRSdw9Nd55J1k",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35115/large/IMG_20240206_134805_970.png?1707397125",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shibwifhatcoin",
    },
  },
  {
    chainId: 101,
    name: "SHILL Token",
    symbol: "SHILL",
    address: "6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18176/large/SHILL_Logo.png?1696517676",
    tags: [],
    verified: true,
    holders: 9901,
    extensions: {
      coingeckoId: "shill-token",
    },
  },
  {
    chainId: 101,
    name: "Shinobi",
    symbol: "NINJA",
    address: "2xP43MawHfU7pwPUmvkc6AUWg4GX8xPQLTGMkSZfCEJT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35469/large/shinobi.png?1708758662",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shinobi-2",
    },
  },
  {
    chainId: 101,
    name: "Shiro the FrogDog",
    symbol: "FROGDOG",
    address: "5Xf2LX3aVf1EAwZzEp848qox1PRpjALpYuVNar4KK3zG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35867/large/qGyFk4b6sdf_400x400.png?1709972507",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shiro-the-frogdog",
    },
  },
  {
    chainId: 101,
    name: "Shoe",
    symbol: "SHOE",
    address: "8XVXzmsMMw7ufa8RC21fHcDP6TGti5y3ZidQinnYurqr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38044/large/IMG_20240521_101935_012.jpg?1716350912",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shoe",
    },
  },
  {
    chainId: 101,
    name: "Shredn Dog",
    symbol: "SHREDN",
    address: "7YFs3oLBEqJffu5VjByokYUTJYRdFrpEFtRngji4LyxS",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36112/large/2024-03-13_19.19.37.png?1710487697",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shredn-dog",
    },
  },
  {
    chainId: 101,
    name: "Shrimp",
    symbol: "SHRIMP",
    address: "9QMwCyTFDRvPZF14hFHUP7fdiewf1a3PQeP4bewYCLJV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37703/large/shrimp_no_background.png?1715246584",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shrimp-2",
    },
  },
  {
    chainId: 101,
    name: "Shroom",
    symbol: "SHROOM",
    address: "xyzR4s6H724bUq6q7MTqWxUnhi8LM5fiKKUq38h8M1P",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36229/large/GhVzgklJ_400x400.jpg?1710945332",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "shroom",
    },
  },
  {
    chainId: 101,
    name: "Side Eye Cat",
    symbol: "SEC",
    address: "7c1GCNc23CmoYVGcETxR2UN6F4dfsHh2WmCEBLUTdNrp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34354/large/photo_2024-01-07_21.44.19.jpeg?1704703981",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "side-eye-cat",
    },
  },
  {
    chainId: 101,
    name: "Sillybird",
    symbol: "SIB",
    address: "Ei71196o8MpDsVdXyZEewEdxP4A8n3KMZKTFE7KE2xTR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36658/large/logo.png?1712042570",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sillybird",
    },
  },
  {
    chainId: 101,
    name: "Sillycat",
    symbol: "SILLYCAT",
    address: "AXMmTn2bGH6RWdos2fUmXsFLhN4LkPjGv9V4BdJdAync",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35156/large/Sillycat.png?1707563872",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sillycat",
    },
  },
  {
    chainId: 101,
    name: "Silly Dragon",
    symbol: "SILLY",
    address: "7EYnhQoR9YM3N7UoaKRoA44Uy8JeaZV3qyouov87awMs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33698/large/image.png?1702821846",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "silly-dragon",
    },
  },
  {
    chainId: 101,
    name: "Silly Goose",
    symbol: "GOO",
    address: "GRFKaABC518SqXMvBpAVYUZtVT3Nj4mYk7E7xU4gA5Rg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35832/large/A3QjTsho_400x400.jpg?1709888121",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "silly-goose",
    },
  },
  {
    chainId: 101,
    name: "Sillynubcat",
    symbol: "NUB",
    address: "GtDZKAqvMZMnti46ZewMiXCa4oXF4bZxwQPoKzXPFxZn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36168/large/nub.jpeg?1710746127",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sillynubcat",
    },
  },
  {
    chainId: 101,
    name: "sinDAO",
    symbol: "SIN",
    address: "sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35155/large/logo200x200.png?1707590486",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sin",
    },
  },
  {
    chainId: 101,
    name: "SIZE",
    symbol: "SIZE",
    address: "HqLRjru6pD6GFGnQ7TwSSGQRuPhF8UZNey9T4yCsZzuq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33971/large/SIZE.png?1703577079",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "size",
    },
  },
  {
    chainId: 101,
    name: "SkateCat",
    symbol: "SKATECAT",
    address: "8NKJze6oUVRpbpk3TaYHv2se57fv54qMS38bRtnEZqYM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37945/large/asdfasdfasdf.jpg?1715973663",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "skatecat",
    },
  },
  {
    chainId: 101,
    name: "Skol",
    symbol: "$SKOL",
    address: "3F4aTvvQBdtWj672kNYRenMtMG4jwQE63redg2cN4tCm",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35934/large/skollogo_200x200.png?1710264034",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "skol",
    },
  },
  {
    chainId: 101,
    name: "SKOLANA",
    symbol: "SKOL",
    address: "91vdRVBTGm7trfYWHjkvz1qeZpsJN3NLyNXMG8FWB6bZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34019/large/rsz_1rsz_skolana.png?1703669796",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "skolana",
    },
  },
  {
    chainId: 101,
    name: "Sky Hause",
    symbol: "SKYH",
    address: "J9hBnna1TMySX9hA3FgiC5xYph5pYHmcLVq2Sp36hDkz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35496/large/sky_house_logo_fix.png?1708922407",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sky-hause",
    },
  },
  {
    chainId: 101,
    name: "STACKS",
    symbol: "STACKS",
    address: "8npbXTu4oTNw3xrMGY1aTm4CtB7cMPVuRUCh3Wqj3fSW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38164/large/Stacks-logo.png?1716690779",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "slap-city",
    },
  },
  {
    chainId: 101,
    name: "Slerf",
    symbol: "SLERF",
    address: "7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36178/large/slerf.jpeg?1710753228",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "slerf",
    },
  },
  {
    chainId: 101,
    name: "Slothana",
    symbol: "SLOTH",
    address: "HQ7DaoiUxzC2K1Dr7KXRHccNtXvEYgNvoUextXe8dmBh",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38036/large/Sloth_logo.jpeg?1716328366",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "slothana",
    },
  },
  {
    chainId: 101,
    name: "SmarDex",
    symbol: "SDEX",
    address: "9dzSzFvPsKDoY2gdWErsuz2H1o4tbzvgBhrNZ9cvkD2j",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29470/large/SDEX_logo_transparent_outside_240x240.png?1696930070",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smardex",
    },
  },
  {
    chainId: 101,
    name: "Smog",
    symbol: "SMOG",
    address: "FS66v5XYtJAFo14LiPz5HT93EUMAHmYipCfQhLpU4ss8",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35117/large/SMOG_TOKEN__2.png?1707398947",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smog",
    },
  },
  {
    chainId: 101,
    name: "Smoking Giraffe",
    symbol: "GRAF",
    address: "9EL3CHVQS3nwUFhyVT7AGbttRsbJ5UE8Qjnw5ZAtkUhr",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38029/large/Fzm02gJE_400x400.jpg?1716322311",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smoking-giraffe",
    },
  },
  {
    chainId: 101,
    name: "SmoLanO",
    symbol: "SLO",
    address: "E43qU77tnWDwN11o7TtaGMNpxCAqz8RZEZ7PcTCUXSim",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33899/large/smolanoicon.png?1703236686",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smolano",
    },
  },
  {
    chainId: 101,
    name: "smolecoin",
    symbol: "SMOLE",
    address: "9Ttyez3xiruyj6cqaR495hbBkJU6SUWdV6AmQ9MvbyyS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36274/large/smole.jpg?1711005125",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smolecoin",
    },
  },
  {
    chainId: 101,
    name: "smorf",
    symbol: "SMORF",
    address: "4cLX1cHWEEGr9iUg7gkNcheHuoAZfW1n6QkPnJNb6XTt",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34863/large/smorf.jpg?1706355310",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smorf",
    },
  },
  {
    chainId: 101,
    name: "Smudge Cat Solana",
    symbol: "SMUDGE",
    address: "9YtudLX9P6BtEihwpg66YKQ3qGBr2KxdXMgTbgcJzxAN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37654/large/1000105297.png?1715160325",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "smudge-cat-solana",
    },
  },
  {
    chainId: 101,
    name: "Snakes Game",
    symbol: "SNAKES",
    address: "FuvFLtx68uGjhr4GWET8JTskC7s3R7MM4Feg8toyzFvf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34123/large/Logo.png?1704046816",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "snakes-game",
    },
  },
  {
    chainId: 101,
    name: "Snapcat",
    symbol: "SNAPCAT",
    address: "ELnAjNJJRBaG9MnnZqUXLsbBjkVA19szcduNeD5RCqdo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36678/large/SNAPCAT.png?1715233731",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "snapcat",
    },
  },
  {
    chainId: 101,
    name: "SNAP",
    symbol: "$NAP",
    address: "4G86CMxGsMdLETrYnavMFKPhQzKTvDBYGMRAdVtr72nu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36137/large/snap.jpg?1710653195",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "snap-kero",
    },
  },
  {
    chainId: 101,
    name: "SNEEL",
    symbol: "SNEEL",
    address: "4cA3bGXtX5nBcmvQ1qN6idAgs2a5PEGb33BPP7V1eyos",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36340/large/SNEEL.png?1711179151",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sneel",
    },
  },
  {
    chainId: 101,
    name: "SNIFF",
    symbol: "$SNIFF",
    address: "4oaV94McVveRosSgdZTn3jkMpr1ge7SQZjwBZ7xG6oA7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37729/large/IMG_0369.jpeg?1715335189",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sniff",
    },
  },
  {
    chainId: 101,
    name: "SNOOPYBABE",
    symbol: "SBABE",
    address: "D9mFkgnZHnQGRtZKvnJ44yvoLtJmfBZRahXiUKcAzRE4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36239/large/1000004914.png?1710919052",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "snoopybabe",
    },
  },
  {
    chainId: 101,
    name: "SoBit Bridge",
    symbol: "SOBB",
    address: "KDriegomzHbJDmGkVWFuXrZWfpiruauhMSTg7N6WZvD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34225/large/photo_2023-12-30_13-54-36.jpg?1704298952",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sobit-bridge",
    },
  },
  {
    chainId: 101,
    name: "SOCKS",
    symbol: "SOCKS",
    address: "3DFU4Z2N2Uyh5KxU9xgKM2u5LsPYU7m2DVKDoPYuFvEL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38405/large/SOCKS.jpg?1717462593",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "socks",
    },
  },
  {
    chainId: 101,
    name: "SOCOMFY",
    symbol: "COMFY",
    address: "FbJpd8yhrGGkWVL1Ujf7qFvTw4uD4675k8CYk82LEKvZ",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34322/large/comfy_round_logo.png?1704646404",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "socomfy",
    },
  },
  {
    chainId: 101,
    name: "Sodi Protocol",
    symbol: "SODI",
    address: "21zDpSK5dxkybjMpp5VwAx5CRSEuVqHZLxXBrq29bFRa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36856/large/Sodi_logo_200x200.png?1712640844",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sodi-protocol",
    },
  },
  {
    chainId: 101,
    name: "SOFT COQ INU",
    symbol: "SOFTCO",
    address: "6wYRTqoERmtRrWmsSCsTcLjNavdTMgxjmVhTEuM3S2tW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36908/large/XsGv8r0L_400x400.jpg?1712732060",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soft-coq-inu",
    },
  },
  {
    chainId: 101,
    name: "Sojak",
    symbol: "SOJAK",
    address: "G5PCBgyUXFjVWEEP35QkCPPUtfiYPWctqFWrpBQYCNj2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34766/large/mcdonalds%283%29.png?1705992039",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sojak",
    },
  },
  {
    chainId: 101,
    name: "Solabrador",
    symbol: "SOBER",
    address: "8agCopCHWdpj7mHk3JUWrzt8pHAxMiPX5hLVDJh9TXWv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33222/large/sober_logo_1.jpg?1701122360",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solabrador-2",
    },
  },
  {
    chainId: 101,
    name: "Solala",
    symbol: "SOLALA",
    address: "HTPHknF5rBNCuZ1qK3muJA6Yo1HEhZj722oSESJnPBiw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36807/large/coin2small.png?1712474606",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solala",
    },
  },
  {
    chainId: 101,
    name: "Solama",
    symbol: "SOLAMA",
    address: "AVLhahDcDQ4m4vHM4ug63oh7xc8Jtk49Dm5hoe9Sazqr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34218/large/IMG_20240101_195943_869.jpg?1704278901",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solama",
    },
  },
  {
    chainId: 101,
    name: "Solamander",
    symbol: "SOLY",
    address: "CUwif1FiX5b3bwwb2n5Bm35AixvnR8LJjGUVmEwNZNgR",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34734/large/soly_200x200_center.png?1710078854",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solamander",
    },
  },
  {
    chainId: 101,
    name: "Solamb",
    symbol: "SOLAMB",
    address: "B4iCbe1rhMWE5VhH8nEEfgfyn3FFiNmnGahPVqw9ZBpp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36035/large/SOLAMB.jpg?1710396884",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solamb",
    },
  },
  {
    chainId: 101,
    name: "SolanaApe",
    symbol: "SAPE",
    address: "EjBFPSVA5c1bRPYAebDEPcVC8Y5p54KX4vsWXw63woQf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34199/large/SAPE.png?1704274488",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solanaape",
    },
  },
  {
    chainId: 101,
    name: "Solana Arcade",
    symbol: "SOLCADE",
    address: "7vuhsRQ2gE4WPv37qegBKu8PcWHxDb5rQ6fQKkDfUghF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35805/large/logo1_%284%29.png?1709842666",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-arcade",
    },
  },
  {
    chainId: 101,
    name: "Solana Beach",
    symbol: "SOLANA",
    address: "Ho2FQgg65oM1zpYuEnC8fULpBnWtqRCTrXRP56AeyCci",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33925/large/newLogo.png?1703861223",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-beach",
    },
  },
  {
    chainId: 101,
    name: "Solana Compass Staked SOL",
    symbol: "COMPASSSOL",
    address: "Comp4ssDzXcLeu2MnLuGNNFC4cmLPMng8qWHPvzAMU1h",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37478/large/solana.png?1714469554",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-compass-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Solanaconda",
    symbol: "SONDA",
    address: "2BeGjx5eYHbGqT2kUZ7K3TvsNGBc65xvorcMqL6kgefQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36034/large/200x200.png?1710396174",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solanaconda",
    },
  },
  {
    chainId: 101,
    name: "Solanacorn",
    symbol: "CORN",
    address: "6DSqVXg9WLTWgz6LACqxN757QdHe1sCqkUfojWmxWtok",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33100/large/soly_logo_final.jpg?1707405222",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solanacorn",
    },
  },
  {
    chainId: 101,
    name: "Solana Ecosystem Index",
    symbol: "SOLI",
    address: "8JnNWJ46yfdq8sKgT1Lk4G7VWkAA8Rhh7LhqgJ6WY41G",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23550/large/Token-SOLI-200x200.png?1696522757",
    tags: [],
    verified: true,
    holders: 2709,
    extensions: {
      coingeckoId: "solana-ecosystem-index",
    },
  },
  {
    chainId: 101,
    name: "Solana Gun",
    symbol: "SOLGUN",
    address: "GunZTF1tjip5SxjE2jdeNGDi1HXHrcx9sLEJZHwv3fEp",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33871/large/IMG_7795.jpeg?1703131885",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-gun",
    },
  },
  {
    chainId: 101,
    name: "SolanaHub staked SOL",
    symbol: "HUBSOL",
    address: "HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36204/large/hub.png?1710833541",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solanahub-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Solana Inu",
    symbol: "INU",
    address: "5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20020/large/YGid9wMv_400x400.jpg?1696519441",
    tags: [],
    verified: true,
    holders: 89282,
    extensions: {
      coingeckoId: "solana-inu",
    },
  },
  {
    chainId: 101,
    name: "Solana Kit",
    symbol: "SOLKIT",
    address: "kit3zVeWba8xxV3DB4oFGsfFfTv8qadvRbbtXihuA3g",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37088/large/IMG_20240415_163718_176.png?1713270533",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-kit",
    },
  },
  {
    chainId: 101,
    name: "SOLANA MEME TOKEN",
    symbol: "SOL10",
    address: "ELm7r7k1ZAZX3up6gus1p2s3mon845rdVP1aJoN9HfEP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34076/large/SOL10__2_-removebg-preview.png?1703907273",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-meme-token",
    },
  },
  {
    chainId: 101,
    name: "Solana Nut",
    symbol: "SOLNUT",
    address: "Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21961/large/20211210_004320.png?1696521310",
    tags: [],
    verified: true,
    holders: 7381,
    extensions: {
      coingeckoId: "solana-nut",
    },
  },
  {
    chainId: 101,
    name: "SolanaPepe",
    symbol: "SPEPE",
    address: "96fxUxwiZm9rkCdDaP2qmB73eA8FcD3wTeuczTtmkdk3",
    decimals: 2,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solanapepe",
    },
  },
  {
    chainId: 101,
    name: "SolanaPrime",
    symbol: "PRIME",
    address: "PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25911/large/solana_black200x200.png?1696524992",
    tags: [],
    verified: true,
    holders: 1002,
    extensions: {
      coingeckoId: "solanaprime",
    },
  },
  {
    chainId: 101,
    name: "Solana Shib ",
    symbol: "SSHIB",
    address: "6VHL2vMKgrF1YQFSv29Rs1pj9VCRK29bD11NtDqerqHA",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33564/large/6VHL2vMKgrF1YQFSv29Rs1pj9VCRK29bD11NtDqerqHA.jpg?1702445806",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-shib",
    },
  },
  {
    chainId: 101,
    name: "Solana Street Bets",
    symbol: "SSB",
    address: "C2KG3gB395WfE1P8ZhUvoT5ZUXzQEJst4FCgLAYTGACz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34907/large/IMG_8742.jpeg?1706595434",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-street-bets",
    },
  },
  {
    chainId: 101,
    name: "Solana Summer",
    symbol: "SUMMER",
    address: "6BQjp7nPMZNkstxdw2UFH1gf76cGmNuR3bTmSbgxpump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38506/large/FDS_1.png?1717753350",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-summer",
    },
  },
  {
    chainId: 101,
    name: "Solana Wars",
    symbol: "SOLWARS",
    address: "BHDkUqS5cdmjnXeGPWvbsfMoRbqPeDr9gPf1yK8xyLiG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36080/large/photo_2024-03-09_15-44-36.jpg?1710415090",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solana-wars",
    },
  },
  {
    chainId: 101,
    name: "Solanium",
    symbol: "SLIM",
    address: "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15816/large/logo_cg.png?1696515435",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "solanium",
    },
  },
  {
    chainId: 101,
    name: "SOLAPE",
    symbol: "SOLAPE",
    address: "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16930/large/128px-coin.png?1696516501",
    tags: [],
    verified: true,
    holders: 28399,
    extensions: {
      coingeckoId: "solape-token",
    },
  },
  {
    chainId: 101,
    name: "Solar Bear",
    symbol: "SOLBEAR",
    address: "DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20778/large/logo.png?1696520172",
    tags: [],
    verified: true,
    holders: 4859,
    extensions: {
      coingeckoId: "solar-bear",
    },
  },
  {
    chainId: 101,
    name: "SOLAREUM",
    symbol: "SOLAREUM",
    address: "7rhhtc2DTTAsPr9P3ZRqUjYZESRj2B29L6qrEXG4VLkh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34043/large/IMG_20231226_201035_994.jpg?1703703521",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solareum-3",
    },
  },
  {
    chainId: 101,
    name: "Solareum Wallet",
    symbol: "XSB",
    address: "4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20958/large/xsb-200.png?1696520345",
    tags: [],
    verified: true,
    holders: 87127,
    extensions: {
      coingeckoId: "solareum-wallet",
    },
  },
  {
    chainId: 101,
    name: "Solar Swap",
    symbol: "SOLAR",
    address: "GNJrBUuUnExsQYBAFy22Hw1qSmSJdWEgy8VawAn3uzUb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35997/large/solar.jpg?1710316038",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solar-swap",
    },
  },
  {
    chainId: 101,
    name: "SOLA",
    symbol: "SOLA",
    address: "FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18263/large/sola-new.png?1696517758",
    tags: [],
    verified: true,
    holders: 6736,
    extensions: {
      coingeckoId: "sola-token",
    },
  },
  {
    chainId: 101,
    name: "Solawave",
    symbol: "SOLAWAVE",
    address: "DpXgThazbyfa3raEjm52hgiN8LEMjdd8PsAwVistoN1i",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34749/large/Photo-1704557544447-1.jpg?1705954373",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solawave",
    },
  },
  {
    chainId: 101,
    name: "SOLA-X",
    symbol: "SAX",
    address: "SAX2cChnuhnKfUDERWVHyd8CoeDNR4NjoxwjuW8uiqa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28319/large/20211216_SOLA-X_Logo_Colored_RGB-06.png?1696527326",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sola-x",
    },
  },
  {
    chainId: 101,
    name: "SOL Baby Doge",
    symbol: "SBABYDOGE",
    address: "BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21436/large/logo_%282%29.png?1696520800",
    tags: [],
    verified: true,
    holders: 3737,
    extensions: {
      coingeckoId: "sol-baby-doge",
    },
  },
  {
    chainId: 101,
    name: "Solbank",
    symbol: "SB",
    address: "8twuNzMszqWeFbDErwtf4gw13E6MUS4Hsdx5mi3aqXAM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35831/large/sb-logo-200x200-transparent.png?1709887991",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solbank",
    },
  },
  {
    chainId: 101,
    name: "Sol Bastard",
    symbol: "SOBA",
    address: "25p2BoNp6qrJH5As6ek6H7Ei495oSkyZd3tGb97sqFmH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37890/large/Untitled_design_%282%29.png?1715886792",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sol-bastard",
    },
  },
  {
    chainId: 101,
    name: "Solberg",
    symbol: "SLB",
    address: "2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18918/large/logo_%2822%29.png?1696518375",
    tags: [],
    verified: true,
    holders: 3004,
    extensions: {
      coingeckoId: "solberg",
    },
  },
  {
    chainId: 101,
    name: "Blaze",
    symbol: "BLZE",
    address: "BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28392/large/blze.png?1696527391",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solblaze",
    },
  },
  {
    chainId: 101,
    name: "SolBlock AI",
    symbol: "SOLBLOCK",
    address: "3gxEC9N9SGgoaRiey3AkbynbHcHsdmgrKDQf31zsiJvm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37397/large/upscaled_cube_logo_200x200.png?1714843476",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solblock-ai",
    },
  },
  {
    chainId: 101,
    name: "SolBook",
    symbol: "BOOK",
    address: "4nFwuKievw5wcpcXtUDdfxWLyXsEdvgkpENzC9M9Y5me",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36585/large/200x200.png?1711946997",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solbook",
    },
  },
  {
    chainId: 101,
    name: "Solbull",
    symbol: "SOLBULL",
    address: "AsL6AyPSG8EQz6KCgqViu2nA7G3E7ZbxK6zpVytqHxxB",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36587/large/Untitled_design_%281%29.png?1711948164",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solbull",
    },
  },
  {
    chainId: 101,
    name: "SolCard",
    symbol: "SOLC",
    address: "DLUNTKRQt7CrpqSX1naHUYoBznJ9pvMP65uCeWQgYnRK",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36158/large/solcard-logo-256.png?1710743770",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solcard",
    },
  },
  {
    chainId: 101,
    name: "Solcasino Token",
    symbol: "SCS",
    address: "SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30228/large/logo_%282%29.png?1696529138",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solcasino-token",
    },
  },
  {
    chainId: 101,
    name: "CAT",
    symbol: "CAT",
    address: "3WKzqdh3ZW3tP2PhAtAuDu4e1XsEzFhk7qnN8mApm3S2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38190/large/image.png?1716789650",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sol-cat",
    },
  },
  {
    chainId: 101,
    name: "SolCex",
    symbol: "SOLCEX",
    address: "AMjzRn1TBQwQfNAjHFeBb7uGbbqbJB7FzXAnGgdFPk6K",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37290/large/IMG_20240420_231258_206.png?1713947143",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solcex",
    },
  },
  {
    chainId: 101,
    name: "Solchat",
    symbol: "CHAT",
    address: "947tEoG318GUmyjVYhraNRvWpMX7fpBTDQFBoJvSkSG3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35197/large/CHAT1.png?1709812468",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solchat",
    },
  },
  {
    chainId: 101,
    name: "SolChicks Shards",
    symbol: "SHARDS",
    address: "8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23728/large/logo_%282%29.png?1696522927",
    tags: [],
    verified: true,
    holders: 1509,
    extensions: {
      coingeckoId: "solchicks-shards",
    },
  },
  {
    chainId: 101,
    name: "SolChicks",
    symbol: "CHICKS",
    address: "cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20978/large/chicks.png?1696520364",
    tags: [],
    verified: true,
    holders: 159042,
    extensions: {
      coingeckoId: "solchicks-token",
    },
  },
  {
    chainId: 101,
    name: "Solcial",
    symbol: "SLCL",
    address: "SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24583/large/1_N9szP0_400x400.jpg?1696523758",
    tags: [],
    verified: true,
    holders: 11393,
    extensions: {
      coingeckoId: "solcial",
    },
  },
  {
    chainId: 101,
    name: "SolClout",
    symbol: "SCT",
    address: "4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21638/large/solclout.jpg?1696520997",
    tags: [],
    verified: true,
    holders: 1840,
    extensions: {
      coingeckoId: "solclout",
    },
  },
  {
    chainId: 101,
    name: "Soldex",
    symbol: "SOLX",
    address: "CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21362/large/output-onlinepngtools_%2811%29.png?1696520728",
    tags: [],
    verified: true,
    holders: 14834,
    extensions: {
      coingeckoId: "soldex",
    },
  },
  {
    chainId: 101,
    name: "SolDocs",
    symbol: "DOCS",
    address: "gr1qPTo3tpMAxt59BftQo2uSfRHRuUZJaWLhR8ADtwz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36868/large/Untitled_design_%286%29_copy_3.png?1712644765",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soldocs",
    },
  },
  {
    chainId: 101,
    name: "SolDoge",
    symbol: "SDOGE",
    address: "8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19746/large/2L4aX1r.png?1696519169",
    tags: [],
    verified: true,
    holders: 17608,
    extensions: {
      coingeckoId: "soldoge",
    },
  },
  {
    chainId: 101,
    name: "SolDragon",
    symbol: "DRAGON",
    address: "3FG9DAv3CDBEh1CQJhRM9BYe5thUMvP5GmW46524Qahv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34254/large/logo_%283%29_%282%29.png?1704343451",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soldragon",
    },
  },
  {
    chainId: 101,
    name: "Solend",
    symbol: "SLND",
    address: "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19573/large/i6AMOwun_400x400.jpg?1696519005",
    tags: [],
    verified: true,
    holders: 35813,
    extensions: {
      coingeckoId: "solend",
    },
  },
  {
    chainId: 101,
    name: "Solex Finance",
    symbol: "SLX",
    address: "AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19392/large/logo_-_2021-10-25T161153.717.png?1696518832",
    tags: [],
    verified: true,
    holders: 6291,
    extensions: {
      coingeckoId: "solex-finance",
    },
  },
  {
    chainId: 101,
    name: "SolEye - OFFCHAIN TRACKER",
    symbol: "EYE",
    address: "74Eyos32V2B6ineYgAcRMZsiDpz65z7sXHq7D5MSMYgF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38436/large/userpic_%281%29.png?1717536406",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soleye-offchain-tracker",
    },
  },
  {
    chainId: 101,
    name: "Tulip Protocol",
    symbol: "TULIP",
    address: "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15764/large/TqrUdBG.png?1696515389",
    tags: [],
    verified: true,
    holders: 152258,
    extensions: {
      coingeckoId: "solfarm",
    },
  },
  {
    chainId: 101,
    name: "SolFarm",
    symbol: "SFARM",
    address: "BjBzvw6VX7UJtrC7BaYLG1dHBiwrXP1T9j2YfDEdP4zU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34550/large/solfarm_100x100.jpg?1705342386",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solfarm-2",
    },
  },
  {
    chainId: 101,
    name: "Solfiles",
    symbol: "FILES",
    address: "948H4FquubpPQxgeYxoo8kiKU12qtRuon8ccnD9r222a",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36365/large/1000017203.jpg?1711336298",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solfiles",
    },
  },
  {
    chainId: 101,
    name: "SolFriends",
    symbol: "FRIENDS",
    address: "GYqW2rfbUT7YX6za3g28ALWcbVH1WHRtwS6gYPgZVfyE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36195/large/SolFriends.png?1710784979",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solfriends",
    },
  },
  {
    chainId: 101,
    name: "Solge",
    symbol: "SOLGE",
    address: "FM8ai6JTyf6HWAgPbnsdonqCeWfjomPMFfnBgPSf23W2",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28678/large/solge_%281%29.png?1696527662",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solge",
    },
  },
  {
    chainId: 101,
    name: "SolGoat",
    symbol: "SOLGOAT",
    address: "9HXewqNjkHz22AKds3JUzFBNguzhoH4cJ4h1EksZyPGH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36392/large/photo_2024-03-23_00-03-21.jpg?1711356483",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solgoat",
    },
  },
  {
    chainId: 101,
    name: "SOLGRAM",
    symbol: "GRAM",
    address: "Hf5gAgohzfUyjytaF5aUSMDwsPAbdThQJNnqw97reGMw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36135/large/solgram.jpg?1710598984",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solgram",
    },
  },
  {
    chainId: 101,
    name: "SolGraph",
    symbol: "GRAPH",
    address: "9uXvdyPLknsSHnx6csn5wTLjxYXhPbe9Q7Ewzys7buNx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36423/large/SolGraphLogo.jpg?1711429651",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solgraph",
    },
  },
  {
    chainId: 101,
    name: "Solgun Sniper",
    symbol: "SOLGUN",
    address: "CyJRzN4iavA2b9tQB2YMCMKYBcT9L5LSijLb5TeyqvLA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37274/large/photo_2024-04-17_14.55.07.jpeg?1713891913",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solgun-sniper",
    },
  },
  {
    chainId: 101,
    name: "Solice",
    symbol: "SLC",
    address: "METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22115/large/solice_logo_200x200.png?1696521457",
    tags: [],
    verified: true,
    holders: 20282,
    extensions: {
      coingeckoId: "solice",
    },
  },
  {
    chainId: 101,
    name: "SoliDefi",
    symbol: "SOLFI",
    address: "CLaSKXbuMp1BXTya62WDyZoPvgmfcqsdA18rAjBcn9Vw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36030/large/logo-200x200.png?1710393703",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solidefi",
    },
  },
  {
    chainId: 101,
    name: "Solido Finance",
    symbol: "SIDO",
    address: "HkGcN7njkSnFsT4X2TMXv3fbjESEB7Vq3EQsTshWHkKa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36311/large/LOGO200.png?1711090452",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solido-finance",
    },
  },
  {
    chainId: 101,
    name: "SOLLABS",
    symbol: "$SOLLABS",
    address: "EM7JsXCjn9evEtdS2T2UYfoZ5vqQ2SshRa78cEfxNicH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36680/large/favicon.png?1712064106",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sollabs",
    },
  },
  {
    chainId: 101,
    name: "Solland",
    symbol: "SLN",
    address: "8RZnGj4hdcai1HB9KMr1ZEUwPpWRPKQDduUxLbnnVnmq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37245/large/200x200.png?1713860494",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solland",
    },
  },
  {
    chainId: 101,
    name: "SolMail",
    symbol: "MAIL",
    address: "C8cNX2D1y3jqKpMFkQhP1gGbfvTEdeckZXLBKSN5z5KF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36467/large/IMG_8604.jpeg?1711517635",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solmail",
    },
  },
  {
    chainId: 101,
    name: "SolMaker",
    symbol: "SOLMAKER",
    address: "CntqbF9zzzpw3ESd9riiPgg7Fqq194T6a1BYCjWHCRzj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37727/large/avatar.png?1715334726",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solmaker",
    },
  },
  {
    chainId: 101,
    name: "SolMash",
    symbol: "MASH",
    address: "A1AaPuUirJaCkidfntEPBSgYzg91mPBFeKjrrx342Yux",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34500/large/photo_2023-12-28_14-43-06.png?1705267779",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solmash",
    },
  },
  {
    chainId: 101,
    name: "Solmedia",
    symbol: "MEDIA",
    address: "BNT4uhSStq1beFADv3cq4wQAVfWB392PjAaxTBpNeWxu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36330/large/new_3d.png?1711098500",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solmedia",
    },
  },
  {
    chainId: 101,
    name: "SolMix",
    symbol: "MIXER",
    address: "7ftqtVJsWNho9zCswLHkN1w9GuVyWqLdp93rPYuuPB7p",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38494/large/Solmix.jpeg?1717707822",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solmix",
    },
  },
  {
    chainId: 101,
    name: "SolMoon",
    symbol: "SMOON",
    address: "AphS2kReZsxCHjUtsuwrBwwJDptCAAQWDmoQFgrd6pkD",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34454/large/SolMoon.png?1704932293",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solmoon-2",
    },
  },
  {
    chainId: 101,
    name: "SolNote",
    symbol: "NOTE",
    address: "CgbcLNUZP7jWmFeXtaKmABRFXDhxejkeUH94JLu3Lcvk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38587/large/1712198199126-d88f47e7da4981f1370974ff2b602a2b.png?1718089121",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solnote",
    },
  },
  {
    chainId: 101,
    name: "SolnyFans",
    symbol: "SOLNYFANS",
    address: "9WvxtFoHD397HZwVsGSA5FXSMhPhqQKANbjivDXvFzK",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34655/large/imresizer-1705516338576.png?1705631749",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solnyfans",
    },
  },
  {
    chainId: 101,
    name: "Solomon (Defina)",
    symbol: "SOLO",
    address: "B5PucY1LAe9WA6Tsp8ACnqw2ofWbDxyKZxiin11ha1QU",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36154/large/token_.png?1710740870",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solomon-defina",
    },
  },
  {
    chainId: 101,
    name: "SOLONG The Dragon",
    symbol: "SOLONG",
    address: "4MBEqrtgabZ9G5EmKm7XTrcknZ1nWg3TrvFHZMrENgrd",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34046/large/solong_logo.png?1703731682",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solong-the-dragon",
    },
  },
  {
    chainId: 101,
    name: "Solordi",
    symbol: "SOLO",
    address: "J8cKU4pD2NTSovvV5XghWHQiJy5TTEzgSyozorxz6ax8",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33994/large/IMAGE_2024-03-17_15_56_13.jpg?1713056828",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solordi",
    },
  },
  {
    chainId: 101,
    name: "Solpaca",
    symbol: "SOLPAC",
    address: "29xJEmh5sMo2DqapmcK7UardZP4B88qJZCZ2BGwa3mJ7",
    decimals: 1,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35967/large/200x200xxx.png?1710264509",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solpaca",
    },
  },
  {
    chainId: 101,
    name: "Solpad Finance",
    symbol: "SOLPAD",
    address: "GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16368/large/solpad.PNG?1696515967",
    tags: [],
    verified: true,
    holders: 27081,
    extensions: {
      coingeckoId: "solpad-finance",
    },
  },
  {
    chainId: 101,
    name: "Solpaka",
    symbol: "SOLPAKA",
    address: "BDHqX9YfJE3M6caox3obUX5YpWHz2cjnGFiZJtRghdCo",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36151/large/solpaka-logo.png?1710740227",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solpaka",
    },
  },
  {
    chainId: 101,
    name: "SolPay Finance",
    symbol: "SOLPAY",
    address: "zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22766/large/1_wTkhKXbxhBQxR1BW5WKSNA.png?1696522069",
    tags: [],
    verified: true,
    holders: 1314,
    extensions: {
      coingeckoId: "solpay-finance",
    },
  },
  {
    chainId: 101,
    name: "SolPets",
    symbol: "PETS",
    address: "5BthRwBakCdXVYq7KQwgAauD5RLW8qLoEyyghh1btypx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36081/large/SOlpets.jpeg?1710415489",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solpets",
    },
  },
  {
    chainId: 101,
    name: "Solphin",
    symbol: "SOLPHIN",
    address: "EAfDXdgSAAkLXbV5L7vaygHrxfJs4Y4Yu6hbP7raBZVT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36236/large/resized_image.png?1710918069",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solphin",
    },
  },
  {
    chainId: 101,
    name: "SolPod",
    symbol: "SOLPOD",
    address: "HvJXRKYaHW53mUJNrhGNrMFCUfSJneMxkBkybFBaBsaS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36910/large/200200.png?1712732857",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solpod",
    },
  },
  {
    chainId: 101,
    name: "SolRagon",
    symbol: "SRGN",
    address: "CnEa7XPJLA3VYrqXnRU5FbvJhCQMCFFmaqt4f26N5YqH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34359/large/SolRagon.png?1704708159",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solragon",
    },
  },
  {
    chainId: 101,
    name: "RazrFi",
    symbol: "SOLR",
    address: "7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18390/large/download.png?1696517881",
    tags: [],
    verified: true,
    holders: 73103,
    extensions: {
      coingeckoId: "solrazr",
    },
  },
  {
    chainId: 101,
    name: "SolRibbit",
    symbol: "RIBBIT",
    address: "ACrrUpnd9uahMBenmHUosXLKKqBMgdQP7we3yqrui9Zx",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38354/large/ribbit-700x700_%282%29.png?1717146699",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solribbit",
    },
  },
  {
    chainId: 101,
    name: "Solrise Finance",
    symbol: "SLRS",
    address: "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15762/large/9989.png?1696515387",
    tags: [],
    verified: true,
    holders: 40663,
    extensions: {
      coingeckoId: "solrise-finance",
    },
  },
  {
    chainId: 101,
    name: "sols",
    symbol: "SOLS",
    address: "2wme8EVkw8qsfSk2B3QeX4S64ac6wxHPXb3GrdckEkio",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33464/large/DjDIitc-424x1UlvJLElsmhfIK3QMuFlPnJKT3CVkWY.png?1701928604",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sols",
    },
  },
  {
    chainId: 101,
    name: "SolSnap",
    symbol: "SNAP",
    address: "SNApmcWQqj3Ny2YFkQmkELQnNgaXRu6KmnYSPiFZcLn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36547/large/1H_9cPtx_400x400.jpg?1711734458",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solsnap",
    },
  },
  {
    chainId: 101,
    name: "SolSpend",
    symbol: "SPEND",
    address: "3mp1MN5v7zdGXTvvcC9zUMoszMrh9pNdaCDkAQKc7Fec",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36570/large/solspend.jpeg?1711951013",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solspend",
    },
  },
  {
    chainId: 101,
    name: "SolSrch",
    symbol: "SRCH",
    address: "FjhUTic9fAYRQMqdGRVQs6SNdVQEX388Q6qQiuvppwxM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37821/large/solsrch_logo_200.png?1715661183",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solsrch",
    },
  },
  {
    chainId: 101,
    name: "Solster",
    symbol: "STR",
    address: "9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18621/large/log-350.png?1696518093",
    tags: [],
    verified: true,
    holders: 17502,
    extensions: {
      coingeckoId: "solster",
    },
  },
  {
    chainId: 101,
    name: "Solstream",
    symbol: "STREAM",
    address: "54jVZGHyWURX5evBtZqUsJjwoKzcZJbVokDU93AUZf2h",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36325/large/200.png?1711097245",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solstream",
    },
  },
  {
    chainId: 101,
    name: "Soltalk AI",
    symbol: "SOLTALK",
    address: "3Ce4PdWfdGjp2F5gn2iyxz7CDMG7TpwunbKHkF67itqf",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38091/large/200x200.jpg?1716483916",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soltalk-ai",
    },
  },
  {
    chainId: 101,
    name: "Soltato FRIES",
    symbol: "FRIES",
    address: "FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19533/large/soltato.png?1696518966",
    tags: [],
    verified: true,
    holders: 5845,
    extensions: {
      coingeckoId: "soltato-fries",
    },
  },
  {
    chainId: 101,
    name: "SolTradingBot",
    symbol: "STBOT",
    address: "2x8o3hA5S5fBxCSE9hzVTf3RohcMWHqkDNKNEPuzprD5",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33046/large/SolTradingBot_100x100.png?1700472007",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soltradingbot",
    },
  },
  {
    chainId: 101,
    name: "Solum",
    symbol: "SOLUM",
    address: "9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18945/large/logo_-_2021-10-12T103445.624.png?1696518401",
    tags: [],
    verified: true,
    holders: 5118,
    extensions: {
      coingeckoId: "solum",
    },
  },
  {
    chainId: 101,
    name: "SolVegas",
    symbol: "SOLVEGAS",
    address: "ApxJeC9Pv5YbS3Ataq2kAfNeFLafEADVmC49czvFAP93",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35771/large/solvegaslogo.png?1709791529",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solvegas",
    },
  },
  {
    chainId: 101,
    name: "Sol X",
    symbol: "SOLX",
    address: "7aWQoyfAxXUD5Not1Fc48TJAWs876NUvKh6Vp8WSXcNR",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36604/large/Sol_X.png?1711962938",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sol-x",
    },
  },
  {
    chainId: 101,
    name: "SolXdex",
    symbol: "SOLX",
    address: "36PWqKTHo8tCXu8NZ9cP7acLsceTQnpkz2ZdPE4Xish8",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solxdex",
    },
  },
  {
    chainId: 101,
    name: "SolXenCat",
    symbol: "XENCAT",
    address: "7UN8WkBumTUCofVPXCPjNWQ6msQhzrg9tFQRP48Nmw5V",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38316/large/Logo_200x200.png?1717053487",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solxencat",
    },
  },
  {
    chainId: 101,
    name: "SolX Gaming Guild",
    symbol: "SGG",
    address: "3eLpKZBgu6pKG2TSpvTfTeeimT294yxV2AEiBKZdY2ai",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22673/large/YhhMhwOn_400x400.jpg?1696521985",
    tags: [],
    verified: true,
    holders: 2605,
    extensions: {
      coingeckoId: "solx-gaming-guild",
    },
  },
  {
    chainId: 101,
    name: "Solyard Finance",
    symbol: "YARD",
    address: "8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18400/large/IMG_7306.JPG?1696517891",
    tags: [],
    verified: true,
    holders: 4790,
    extensions: {
      coingeckoId: "solyard-finance",
    },
  },
  {
    chainId: 101,
    name: "Solzilla",
    symbol: "SOLZILLA",
    address: "31iQsahfa4CMiirU7REygBzuAWg4R4ah7Y4aDu9ZfXJP",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33944/large/1000007729.png?1703537344",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "solzilla",
    },
  },
  {
    chainId: 101,
    name: "Som Bonkmon Fraud",
    symbol: "SBF",
    address: "B582oxHHy41ijxaGem3GksSWAVBfr2fJxNKrL7mpC1z1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37967/large/SBF.jpg?1716192313",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "som-bonkmon-fraud",
    },
  },
  {
    chainId: 101,
    name: "SonarWatch",
    symbol: "SONAR",
    address: "sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20494/large/S_7gaWIC_400x400.png?1696519901",
    tags: [],
    verified: true,
    holders: 13142,
    extensions: {
      coingeckoId: "sonarwatch",
    },
  },
  {
    chainId: 101,
    name: "Sonic Sniper BOT",
    symbol: "SONIC",
    address: "7EW2NTuQFYKVxF3WTA1L1v62pxB7RFYmVC7veGxNDFis",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33396/large/1000004973.jpg?1701697229",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sonic-sniper-bot",
    },
  },
  {
    chainId: 101,
    name: "Sonic The Goat",
    symbol: "GOAT",
    address: "59u8qAD2S2gEtsY5Vs7dJ95YspnHavyHEtRfzCEb9F7G",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37096/large/Goat.png?1713748992",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sonic-the-goat",
    },
  },
  {
    chainId: 101,
    name: "SonicWifHat",
    symbol: "SONICWIF",
    address: "9YYoJ6NaCm1kY76oYNF2uAWTRnJ6nFc4Q5MujeyNo28D",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37051/large/photo_2024-04-03_14.32.55.png?1713166850",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sonicwifhat",
    },
  },
  {
    chainId: 101,
    name: "Soperme",
    symbol: "S",
    address: "8qnZbvQ6PvmuFrB7bUaQPybMX8JVybnoyYfMhfFaY4nV",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37130/large/yXVgyqPH_400x400.jpg?1713386371",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soperme",
    },
  },
  {
    chainId: 101,
    name: "Sopermen",
    symbol: "SOOPY",
    address: "GjaH8mj1yjf7uDnZ4sKPn9my7cMQtZoXWPUeYyonMrEG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34839/large/Sopermen.jpg?1706240829",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sopermen",
    },
  },
  {
    chainId: 101,
    name: "Sora Solana",
    symbol: "SORA",
    address: "32ga4pCjWS5Z12FJYipJFCZvVPfDy2F7QAQGgdBTUFdJ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35433/large/photo_2024-02-21_08.50.14.jpeg?1708589349",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sora-solana",
    },
  },
  {
    chainId: 101,
    name: "Soul Dogs City Bones",
    symbol: "BONES",
    address: "bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25025/large/coin_tails_%282%29.png?1696524177",
    tags: [],
    verified: true,
    holders: 5041,
    extensions: {
      coingeckoId: "soul-dog-city-bones",
    },
  },
  {
    chainId: 101,
    name: "Soulja Coin",
    symbol: "SBOY",
    address: "G3ukjeHBrDJ1zUFr6KandnW4bPcjmvK3qL2uATRb3F63",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38553/large/1xGD6644_400x400.jpg?1717986416",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soulja-coin",
    },
  },
  {
    chainId: 101,
    name: "SouloCoin",
    symbol: "SOULO",
    address: "Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22082/large/logo.png?1696521426",
    tags: [],
    verified: true,
    holders: 3220,
    extensions: {
      coingeckoId: "soulocoin",
    },
  },
  {
    chainId: 101,
    name: "Soul Scanner",
    symbol: "SOUL",
    address: "J4ywFdm8H7hjwKzCaEQujhkDRfCnRviVnHMvFNDAoLNQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34471/large/IMG_20240101_091349_872.jpg?1704997031",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "soul-scanner",
    },
  },
  {
    chainId: 101,
    name: "South Korea Coin",
    symbol: "KOREA",
    address: "6tWuipcDv4CiHtXid7JctDbtLLA1VdkTwSDupK6UxzJL",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37580/large/download.jpeg?1714965779",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "south-korea-coin",
    },
  },
  {
    chainId: 101,
    name: "Space Dog Solana",
    symbol: "LAIKA",
    address: "LAikaEyw7w4R4Tidue68Fs4N9ZsTTRSza8XR7bhCrxa",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38299/large/IMG_20240525_120832_250.jpg?1717030581",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "space-dog-solana",
    },
  },
  {
    chainId: 101,
    name: "SpaceFalcon",
    symbol: "FCON",
    address: "HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21760/large/Space_Falcon_Logo.png?1702499870",
    tags: [],
    verified: true,
    holders: 48628,
    extensions: {
      coingeckoId: "spacefalcon",
    },
  },
  {
    chainId: 101,
    name: "Space Hamster",
    symbol: "HAMSTER",
    address: "2HUWeimSrRYQrAoVGnAnufRV4KRJRHPtJzaBzmrejQCz",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38227/large/1000007368.jpg?1716828305",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "space-hamster-2",
    },
  },
  {
    chainId: 101,
    name: "Spain Coin",
    symbol: "ESP",
    address: "8uz7r3yQq8xnwBfWCoQGAftyYKgUz8wMQWod884nerf4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38290/large/Spain2.png?1716998574",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spain-coin",
    },
  },
  {
    chainId: 101,
    name: "Speero",
    symbol: "SPEERO",
    address: "C7K4Tot6fnnNwhWpqw9H277QPcP56vHAEeXubRHDyCo9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34252/large/speeroLOGO.png?1704339603",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "speero",
    },
  },
  {
    chainId: 101,
    name: "SpiderSwap",
    symbol: "SPDR",
    address: "AT79ReYU9XtHUTF5vM6Q4oa9K8w7918Fp5SU7G1MDMQY",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34054/large/spdr.png?1703746623",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spiderswap",
    },
  },
  {
    chainId: 101,
    name: "Spike",
    symbol: "SPIKE",
    address: "BX9yEgW8WkoWV8SvqTMMCynkQWreRTJ9ZS81dRXYnnR9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38526/large/Spike.png?1717760431",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spike",
    },
  },
  {
    chainId: 101,
    name: "Spodermen",
    symbol: "SPOODY",
    address: "8Nd3TZJfxt9yYKiPiPmYp6S5DhLftG3bwSqdW3KJwArb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34851/large/Spoody_200x200.png?1706262464",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spodermen",
    },
  },
  {
    chainId: 101,
    name: "Spoofify",
    symbol: "SPOOF",
    address: "BLU6Aiq3hcRkwvXeq7V61XwDzqm2zYxYAYLVghWVYp6Q",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36719/large/LOGO_Design.png?1712910240",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spoofify",
    },
  },
  {
    chainId: 101,
    name: "Spooky The Phantom",
    symbol: "SPOOKY",
    address: "FdGoS1Dok5CLnS8fVSmj5A92uY1yhzdTC2ZxuLJdkwgs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36210/large/IMG_0091.jpeg?1710835493",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spooky-the-phantom",
    },
  },
  {
    chainId: 101,
    name: "Spottie WiFi",
    symbol: "WIFI",
    address: "AcfKwAf4UQLA5DLRQD2eQxW6pLv79VKzVso38WMfTxGZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38517/large/PiGtlYtj9t23-WnNao3tWY4HXlvENBnWRrBbhHZ4_F_DMG3Xuo1ul3MIPGi3JGtB8yU4GHdfwy2VDe6xC0ODBzf93fbrnHohC1HiFJeb1FAO6mr8XoFmqHiBsEP5WuMRinvkQn7F9IcUX4k_m1PgsNr6Xeh76oZWuMixlDHVwcKAGX4mAkshoe0pFQcHGqatx5uwbOZYQSqugQbha-6dwEh.jpg?1717755345",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spottie-wifi",
    },
  },
  {
    chainId: 101,
    name: "Spurdo Spärde",
    symbol: "SPURDO",
    address: "8HJ81sHVsmJMzm6XBfTgywWQXwQTmVdmXaQHm7htGPH2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34743/large/spurdo-new.png?1705949354",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spurdo-sparde",
    },
  },
  {
    chainId: 101,
    name: "SPX6900",
    symbol: "SPX",
    address: "J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31401/large/sticker_%281%29.jpg?1702371083",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spx6900",
    },
  },
  {
    chainId: 101,
    name: "Spyrolana",
    symbol: "SPYRO",
    address: "Dh8MrqBup5fVGuBkNujFveZUdLZ6Y4RBsvQyDyTSnbBY",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34275/large/logo_200x200.jpg?1704393005",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "spyrolana",
    },
  },
  {
    chainId: 101,
    name: "StaFi Staked SOL",
    symbol: "RSOL",
    address: "7hUdUTkJLwdcmt3jSEeqx4ep91sm1XwBxMDaJae6bD5D",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29612/large/rSOL.png?1696528548",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stafi-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "ACC",
    symbol: "ACC",
    address: "CFTyvm52gRGRRx2LZK22XivaYgm3xKCw7PUpNDvmXhhV",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38268/large/Frame_1244830912_1.png?1716951257",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stage-0",
    },
  },
  {
    chainId: 101,
    name: "Staika",
    symbol: "STIK",
    address: "8BMzMi2XxZn9afRaMx5Z6fauk9foHXqV5cLTCYWRcVje",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29063/large/STIK.png?1696528030",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "staika",
    },
  },
  {
    chainId: 101,
    name: "Stake City Staked SOL",
    symbol: "STAKESOL",
    address: "st8QujHLPsX3d6HG9uQg9kJ91jFxUgruwsb1hyYXSNd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37496/large/stakeSOL.jpg?1714537305",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stake-city-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Stanley Cup Coin",
    symbol: "STAN",
    address: "CQSzJzwW5H1oyWrp6QhfUKYYwyovbSiVDKnAxNfb1tJC",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37401/large/Mask_group_8.png?1714359031",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stanley-cup-coin",
    },
  },
  {
    chainId: 101,
    name: "Star Atlas",
    symbol: "ATLAS",
    address: "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17659/large/Icon_Reverse.png?1696517190",
    tags: [],
    verified: true,
    holders: 232276,
    extensions: {
      coingeckoId: "star-atlas",
    },
  },
  {
    chainId: 101,
    name: "Star Atlas DAO",
    symbol: "POLIS",
    address: "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17789/large/POLIS.jpg?1696517312",
    tags: [],
    verified: true,
    holders: 116127,
    extensions: {
      coingeckoId: "star-atlas-dao",
    },
  },
  {
    chainId: 101,
    name: "Starbots",
    symbol: "BOT",
    address: "AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21823/large/coin_%286%29.png?1696521174",
    tags: [],
    verified: true,
    holders: 10254,
    extensions: {
      coingeckoId: "starbots",
    },
  },
  {
    chainId: 101,
    name: "StarLaunch",
    symbol: "STARS",
    address: "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20109/large/OP3eksDQ_400x400.png?1696519524",
    tags: [],
    verified: true,
    holders: 39961,
    extensions: {
      coingeckoId: "starlaunch",
    },
  },
  {
    chainId: 101,
    name: "Starri",
    symbol: "STARRI",
    address: "2GZcmRHmKFWPqkJ9Wm1XAf5kLwFxcYG5cTiTGkH4VZht",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37770/large/STARRI.jpg?1715568092",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "starri",
    },
  },
  {
    chainId: 101,
    name: "StarShip🚀",
    symbol: "STSHIP",
    address: "GHtLF7drbYXGTHX73uSxqPKkJUzDqcBNe2M9fzjJzr3j",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34033/large/logo200x200.png?1703677549",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "starship-4",
    },
  },
  {
    chainId: 101,
    name: "Stash Inu",
    symbol: "STASH",
    address: "EWMfSJgDCE7CXDAYz3hbCaA7NsFHTnddySXx3shco2Hs",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36107/large/stash-inu.png?1710483938",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stash-inu",
    },
  },
  {
    chainId: 101,
    name: "steep jubs",
    symbol: "OPPLE",
    address: "BG5Dp9gU5WbkHEaz6y95apb5NVPw3jC17M4ro27sgSXP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37645/large/download.png?1715142338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "steep-jubs",
    },
  },
  {
    chainId: 101,
    name: "Step Finance",
    symbol: "STEP",
    address: "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14988/large/step.png?1696514652",
    tags: [],
    verified: true,
    holders: 87406,
    extensions: {
      coingeckoId: "step-finance",
    },
  },
  {
    chainId: 101,
    name: "GMT",
    symbol: "GMT",
    address: "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23597/large/token-gmt-200x200.png?1703153841",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "stepn",
    },
  },
  {
    chainId: 101,
    name: "Stooges",
    symbol: "STOG",
    address: "AHnZ7VyyQ5jHXbitQL8tuN7ciGG66EvCnU7eKoKX99fz",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37974/large/rsz_img_20240518_003502_514.jpg?1716195543",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stooges",
    },
  },
  {
    chainId: 101,
    name: "Stronghold Staked SOL",
    symbol: "STRONGSOL",
    address: "strng7mqqc1MBJJV6vMzYbEqnwVGvKKGKedeCvtktWA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37497/large/strongSOL.png?1714536536",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "stronghold-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Study",
    symbol: "STUDY",
    address: "GWdFcYSbpQLywCe5qQkBPeojHGQHmX3L16SQM9mq9fCt",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35479/large/logo-small.png?1708788511",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "study",
    },
  },
  {
    chainId: 101,
    name: "Style",
    symbol: "STYLE",
    address: "3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28060/large/78oZvtZW_400x400.jpeg?1696527072",
    tags: [],
    verified: true,
    holders: 1095,
    extensions: {
      coingeckoId: "style",
    },
  },
  {
    chainId: 101,
    name: "Success Kid",
    symbol: "SKID",
    address: "9X2RHtKrBzw3SLYe9E88cBd1kz5RfU1f4JTSn4aRH43d",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36072/large/rZLRfrn5_400x400.jpg?1710411047",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "success-kid",
    },
  },
  {
    chainId: 101,
    name: "Suicidal Pepe",
    symbol: "SPEPE",
    address: "E3vKp2ApcD7Ydn8DNrtsawnj2wjdCDEbovV4WoZS1kAg",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38507/large/spepe.jpeg?1717754189",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "suicidal-pepe",
    },
  },
  {
    chainId: 101,
    name: "SUMO KITTY",
    symbol: "SUKI",
    address: "Ezk1higaKuTQWD5Tx8ozG7SfZX1pVDPfCEdu8kpby8Np",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37713/large/SUKI-logo-v14.png?1715276768",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sumo-kitty",
    },
  },
  {
    chainId: 101,
    name: "Sunala",
    symbol: "SUN",
    address: "FgpsYoewbo7fAnFjgPE88dfxATLhANxHCFwrjkpbMpdQ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33395/large/Sunala_pfp_nobg.png?1701684270",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sunala",
    },
  },
  {
    chainId: 101,
    name: "Sunny Aggregator",
    symbol: "SUNNY",
    address: "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18039/large/90dbe787-8e5f-473c-b923-fe138a7a30ea.png?1696517554",
    tags: [],
    verified: true,
    holders: 55940,
    extensions: {
      coingeckoId: "sunny-aggregator",
    },
  },
  {
    chainId: 101,
    name: "SunnySideUp",
    symbol: "SSU",
    address: "AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23369/large/logo.png?1696522584",
    tags: [],
    verified: true,
    holders: 1115,
    extensions: {
      coingeckoId: "sunnysideup",
    },
  },
  {
    chainId: 101,
    name: "SuperFans.Tech",
    symbol: "FAN",
    address: "FANoyuAQZx7AHCnxqsLeWq6te63F6zs6ENkbncCyYUZu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34504/large/SuperFans-logo-200x200.png?1705287167",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "superfans-tech",
    },
  },
  {
    chainId: 101,
    name: "Superfast Staked SOL",
    symbol: "SUPERSOL",
    address: "suPer8CPwxoJPQ7zksGMwFvjBQhjAHwUMmPV4FVatBw",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37498/large/superSOL.jpg?1714537414",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "superfast-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "SuperRareBears RARE",
    symbol: "RARE",
    address: "HmLspvjpQtQEnArUyJoBSFGS38gNJwBuxAeqSV9SZ66K",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28993/large/logo.RARe.png?1696527965",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "superrarebears-rare",
    },
  },
  {
    chainId: 101,
    name: "Sushi",
    symbol: "SUSHI",
    address: "ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png?1696512101",
    tags: [],
    verified: true,
    holders: 1530,
    extensions: {
      coingeckoId: "sushi",
    },
  },
  {
    chainId: 101,
    name: "swag coin",
    symbol: "SWAG",
    address: "FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34691/large/coinlogosmall.png?1705853501",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "swag-coin",
    },
  },
  {
    chainId: 101,
    name: "Swana Solana",
    symbol: "SWANA",
    address: "GG6vXeXdXLgbGMsfnYe2nUqfJpXpiNwYqWUezbuU4zo1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37960/large/Swana_Head_Main_200x200.png?1716325227",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "swana-solana",
    },
  },
  {
    chainId: 101,
    name: "Sweeper",
    symbol: "SWEEP",
    address: "SWEEPPcXTbTmSpbXHGg4g8tvofqCukefut6s5hHUnba",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37002/large/token_icon_%281%29.png?1713070770",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sweeper",
    },
  },
  {
    chainId: 101,
    name: "SWEETS",
    symbol: "$SWTS",
    address: "5SwxhEunuUUcWe4ojybdDwky6dpLxAehNmF4AA71STNh",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30294/large/CG_SWEETS.png?1696529199",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sweets",
    },
  },
  {
    chainId: 101,
    name: "SWERVE Protocol",
    symbol: "SWERVE",
    address: "45ojchnvC3agGNvs86MWBq8N4miiTY6X8ECQzgQNDE4v",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20858/large/94614403.png?1696520253",
    tags: [],
    verified: true,
    holders: 7844,
    extensions: {
      coingeckoId: "swerve-protocol",
    },
  },
  {
    chainId: 101,
    name: "SwissBorg",
    symbol: "BORG",
    address: "3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/2117/large/YJUrRy7r_400x400.png?1696503083",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "swissborg",
    },
  },
  {
    chainId: 101,
    name: "SYLCM",
    symbol: "SYLCM",
    address: "4ToUtix192VLhXMNu1zBtWwjLa65127sWBfxkH1tzfUW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36999/large/DEF_LOGO.png?1713033500",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "sylcm",
    },
  },
  {
    chainId: 101,
    name: "Synatra Staked SOL",
    symbol: "YSOL",
    address: "yso11zxLbHA3wBJ9HAtVu6wnesqz9A2qxnhxanasZ4N",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34683/large/ysol-icon.png?1705837949",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "synatra-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "Synchrony",
    symbol: "SCY",
    address: "SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19308/large/SYNCHRONY-LOGO.png?1696518750",
    tags: [],
    verified: true,
    holders: 11497,
    extensions: {
      coingeckoId: "synchrony",
    },
  },
  {
    chainId: 101,
    name: "Synesis One",
    symbol: "SNS",
    address: "SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23289/large/S_LOGO_2.png?1717239404",
    tags: [],
    verified: true,
    holders: 11120,
    extensions: {
      coingeckoId: "synesis-one",
    },
  },
  {
    chainId: 101,
    name: "Synthetify",
    symbol: "SNY",
    address: "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14835/large/synthetify_token.png?1696514502",
    tags: [],
    verified: true,
    holders: 26205,
    extensions: {
      coingeckoId: "synthetify-token",
    },
  },
  {
    chainId: 101,
    name: "Sypool",
    symbol: "SYP",
    address: "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18518/large/sypool.PNG?1696517999",
    tags: [],
    verified: true,
    holders: 24545,
    extensions: {
      coingeckoId: "sypool",
    },
  },
  {
    chainId: 101,
    name: "TabTrader",
    symbol: "TTT",
    address: "FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21060/large/xFYsZV9U_400x400.jpg?1696520443",
    tags: [],
    verified: true,
    holders: 39393,
    extensions: {
      coingeckoId: "tabtrader",
    },
  },
  {
    chainId: 101,
    name: "TACOCAT",
    symbol: "TACOCAT",
    address: "89ANUnyMToTe56hCf3726SaiUwNrbbppaU5fT2YGUmVy",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36105/large/tacocat.png?1710483779",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tacocat",
    },
  },
  {
    chainId: 101,
    name: "Taki Games",
    symbol: "TAKI",
    address: "Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25271/large/Social_Icon_1_200x200.png?1707905836",
    tags: [],
    verified: true,
    holders: 25366,
    extensions: {
      coingeckoId: "taki",
    },
  },
  {
    chainId: 101,
    name: "Tang Ping Cat",
    symbol: "TPCAT",
    address: "2WRA42G2WNKrVKyiCuATMCFvmJw6qwQGMyEhuc4ugL9E",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37749/large/tpcat-logo-200x200.png?1715443626",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tang-ping-cat",
    },
  },
  {
    chainId: 101,
    name: "TAOlie Coin",
    symbol: "TAOLIE",
    address: "2zE5rJ2ctXMz9hVbk1AvJa78X7mh3kuR728SNzGXTEeu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37393/large/IMG_0522.JPG?1714289443",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "taolie-coin",
    },
  },
  {
    chainId: 101,
    name: "TAOSHI",
    symbol: "TAOSHI",
    address: "6MMdrc39L3rMZkshRP9WSKe3trSdcbx5ywJmzJBanzqw",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35509/large/_taoshi.jpeg?1708936512",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "taoshi",
    },
  },
  {
    chainId: 101,
    name: "Tapp Coin",
    symbol: "TPX",
    address: "BvAZ2ay2KjBcGi49KspWtY3DAatBN7enmqaon1TuR8ES",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33526/large/200_by_200_tapp_logo.png?1702138352",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tapp-coin",
    },
  },
  {
    chainId: 101,
    name: "TARS Protocol",
    symbol: "TAI",
    address: "Hax9LTgsQkze1YFychnBLtFH8gYbQKtKfWKKg2SP6gdD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37538/large/tars.jpeg?1714701907",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tars-protocol",
    },
  },
  {
    chainId: 101,
    name: "Taylor Swift's Cat Benji",
    symbol: "BENJI",
    address: "CPjDhhBpwEsLygNuczKcNwBPgMeni1xLomD48x51MyYU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34693/large/BENJI.jpg?1705855114",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "taylor-swift-s-cat",
    },
  },
  {
    chainId: 101,
    name: "tBTC",
    symbol: "TBTC",
    address: "6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/11224/large/0x18084fba666a33d37592fa2633fd49a74dd93a88.png?1696511155",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tbtc",
    },
  },
  {
    chainId: 101,
    name: "Tech Deck Turtle",
    symbol: "TDT",
    address: "a1U2dq7dTEMa3u6DhR6PWAjEWboEwNF12WbiBFfitLx",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38519/large/TDT_Logo.png?1717756360",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tech-deck-turtle",
    },
  },
  {
    chainId: 101,
    name: "TEH EPIK DUCK",
    symbol: "EPIK",
    address: "3BgwJ8b7b9hHX4sgfZ2KJhv9496CoVfsMK2YePevsBRw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36823/large/tehjp_%281%29.jpeg?1712555027",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "teh-epik-duck",
    },
  },
  {
    chainId: 101,
    name: "Tensor",
    symbol: "TNSR",
    address: "TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35972/large/tnsr.png?1712687367",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tensor",
    },
  },
  {
    chainId: 101,
    name: "Tepeport",
    symbol: "TP",
    address: "M5sJ7XaEsGF5M3FtidFEBE7Wghqz4iocs85yQBNUPX2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36534/large/200x20tepeport_logo_%281%29.png?1711706003",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tepeport",
    },
  },
  {
    chainId: 101,
    name: "TerraUSD (Wormhole)",
    symbol: "UST",
    address: "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21150/large/UST_wh_small.png?1696520528",
    tags: [],
    verified: true,
    holders: 43239,
    extensions: {
      coingeckoId: "terrausd-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Tert",
    symbol: "TERT",
    address: "4bBxhRezDJDu3uuh1KM3bWetYiAZSauTeUGixn9rmiX9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36968/large/photo_2024-04-04_20-11-45_%281%29.jpg?1712909654",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tert",
    },
  },
  {
    chainId: 101,
    name: "test token please ignore",
    symbol: "TEST",
    address: "A5nvoNHLmJ4ydL5KroxphCX4umFwcn8VWb4kXm7L6sXn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37724/large/eugene.jpg?1715333716",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "test-token-please-ignore",
    },
  },
  {
    chainId: 101,
    name: "Tether",
    symbol: "USDT",
    address: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/325/large/Tether.png?1696501661",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "tether",
    },
  },
  {
    chainId: 101,
    name: "Bridged Tether (Wormhole POS)",
    symbol: "USDTPO",
    address: "5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22944/large/USDTpo_wh_small.png?1696522241",
    tags: [],
    verified: true,
    holders: 2406,
    extensions: {
      coingeckoId: "tether-usd-pos-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Bridged Tether (Wormhole Ethereum)",
    symbol: "USDTET",
    address: "Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23025/large/USDTet_wh_small.png?1696522320",
    tags: [],
    verified: true,
    holders: 8080,
    extensions: {
      coingeckoId: "tether-usd-wormhole-from-ethereum",
    },
  },
  {
    chainId: 101,
    name: "Textopia",
    symbol: "TXT",
    address: "7XzYCSB3QCh4Qb7d8cX6G9ZhEjtQPAZnvX6S7k9Q5DUD",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36736/large/txt.jpg?1712210012",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "textopia",
    },
  },
  {
    chainId: 101,
    name: "THANKS FOR THE INVITE",
    symbol: "TFTI",
    address: "6aicu4EBvFtkuxTEmS9hGh4ZAphodiPD4WwueU1nEZDf",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "thanks-for-the-invite",
    },
  },
  {
    chainId: 101,
    name: "FOUR",
    symbol: "FOUR",
    address: "DAtU322C23YpoZyWBm8szk12QyqHa9rUQe1EYXzbm1JE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/3434/large/FOUR.png?1696504127",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-4th-pillar",
    },
  },
  {
    chainId: 101,
    name: "The Bitcoin Killa",
    symbol: "KILLA",
    address: "BPUDuDxG2xyeKyDQ3bqDyPvTmCjXado5ETcGKV1wKhhC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37994/large/Killa.png?1716225600",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-bitcoin-killa",
    },
  },
  {
    chainId: 101,
    name: "Theca",
    symbol: "THECA",
    address: "THECAtfEe5nVsgKsnQxXka41ujZqeCemHTMqECCZkrZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21782/large/logo_%281%29.png?1696521136",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "theca",
    },
  },
  {
    chainId: 101,
    name: "The Cat Is Blue",
    symbol: "BLUE",
    address: "Fzo4d3toWjH7aFioUaMWWDNkmGh1BbHKSWAgXpHb2pf4",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36568/large/blue_logo_200_x_200.jpg?1711936599",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-cat-is-blue",
    },
  },
  {
    chainId: 101,
    name: "The Fooker",
    symbol: "FOOKER",
    address: "8ocqWbUQFeNB5zjvKvuyQXdP1Sy2NyTT9uZknuCwrukW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37723/large/imresizer-1715266594881.jpg?1715333044",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-fooker",
    },
  },
  {
    chainId: 101,
    name: "The Jupiter Cat",
    symbol: "JUPCAT",
    address: "4hqxNjEcyaPfyjQQCtc9JDWaGQ4M1nDScCMZ9TTwY4AX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34940/large/JUPCAT-LOGO---2024.png?1706727089",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-jupiter-cat",
    },
  },
  {
    chainId: 101,
    name: "The OG Cheems Inu",
    symbol: "OGCINU",
    address: "5doZSgpsKVJk9u58hsmDsq8N6oNtELvsycoFJ42P327p",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35843/large/OGCINU_LOGO.png?1709890480",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-og-cheems-inu",
    },
  },
  {
    chainId: 101,
    name: "The Professor",
    symbol: "LAB",
    address: "2Pp6ebUvEL9YRTauUTmGTwYZKRfyQXGM9jE4S8WPDtEy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37428/large/LOGO.png?1714379015",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-professor",
    },
  },
  {
    chainId: 101,
    name: "The Sandbox (Wormhole)",
    symbol: "SAND",
    address: "49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22984/large/SAND_wh_small.png?1696522281",
    tags: [],
    verified: true,
    holders: 1537,
    extensions: {
      coingeckoId: "the-sandbox-wormhole",
    },
  },
  {
    chainId: 101,
    name: "TheSolanDAO",
    symbol: "SDO",
    address: "7SZUnH7H9KptyJkUhJ5L4Kee5fFAbqVgCHvt7B6wg4Xc",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22427/large/sdo-new_osvgbz.jpg?1696521768",
    tags: [],
    verified: true,
    holders: 11835,
    extensions: {
      coingeckoId: "thesolandao",
    },
  },
  {
    chainId: 101,
    name: "Theory Of Gravity",
    symbol: "THOG",
    address: "5CqfXex1knfRiozwDtgFFNaiGR9TsmSUcWDNUTUGZQru",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36838/large/THOG.png?1712561135",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-theory-of-gravity",
    },
  },
  {
    chainId: 101,
    name: "The Vault Staked SOL",
    symbol: "VSOL",
    address: "vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37499/large/vsol.png?1714537525",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "the-vault-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "The Xenobots Project",
    symbol: "XENO",
    address: "Bwfe7DwmEDvjEBZGbQnDU8CrwZsuvYaed1VuQ8KDTGsS",
    decimals: 0,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24054/large/W3OlCRoK_400x400.jpg?1696523244",
    tags: [],
    verified: true,
    holders: 1852,
    extensions: {
      coingeckoId: "the-xenobots-project",
    },
  },
  {
    chainId: 101,
    name: "Thief Cat",
    symbol: "NAMI",
    address: "Em9zr2tgSmGgRbz3kxyQeRXjRi9oc13wMu6cKam4zWFW",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38135/large/ThiefCat_Logo_200x200.jpg?1716570678",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "thief",
    },
  },
  {
    chainId: 101,
    name: "This Is The One",
    symbol: "THEONE",
    address: "6ftboqS5nKKz2VPCwdRmC6VEe6fZBESwitmU7ncnEpn3",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35161/large/THEONE_200x200.png?1707571189",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "this-is-the-one",
    },
  },
  {
    chainId: 101,
    name: "Tholana",
    symbol: "THOL",
    address: "EKCW975DWdt1roK1NVQDf4QGfaGTcQPU5tFD1DMcMe9Q",
    decimals: 7,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34097/large/THOLANA.jpeg?1714202966",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tholana",
    },
  },
  {
    chainId: 101,
    name: "TiccleCat",
    symbol: "TICCL",
    address: "27ifjVpHkCeDEA7abHTC4k6GMbkN4oe9N3RrCW7jgNMu",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38065/large/IMG_20240519_193449_084.jpg?1716441772",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ticclecat",
    },
  },
  {
    chainId: 101,
    name: "Tilly The Killer Whale",
    symbol: "TILLY",
    address: "tiLYBRtBSfhwXe82nfRAYd5EVaqfpV74WsH75we8pki",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37675/large/tilly.png?1715192503",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tilly-the-killer-whale",
    },
  },
  {
    chainId: 101,
    name: "Timeless Davido",
    symbol: "DAVIDO",
    address: "9LAjk5F4rFetELE4CygcBbZ5hYc2QhRrbJjfm5Q26jWM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38303/large/davido.png?1717043326",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "timeless-davido",
    },
  },
  {
    chainId: 101,
    name: "TimePocket",
    symbol: "TIMEPOCKET",
    address: "AdfrN2rK9dJmd4E9vwkf2ThpBxKFfT1o31gXSPpupYtT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37682/large/hNhbfWWD_400x400.jpg?1715209176",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "timepocket",
    },
  },
  {
    chainId: 101,
    name: "TIMMI",
    symbol: "TIMMI",
    address: "BxXmDhM8sTF3QG4foaVM2v1EUvg9DLSVUsDRTjR8tMyS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36917/large/192.png?1712771605",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "timmi",
    },
  },
  {
    chainId: 101,
    name: "TINU",
    symbol: "TINU",
    address: "EJqpy1noqMjvdJBSLy3CbjZLCPnsG9PZbM2FnBHhNqwM",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38459/large/TINU.jpg?1717588104",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "t-inu",
    },
  },
  {
    chainId: 101,
    name: "Tiny Colony",
    symbol: "TINY",
    address: "HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22264/large/Kq4gAMJi_400x400.png?1696521608",
    tags: [],
    verified: true,
    holders: 7619,
    extensions: {
      coingeckoId: "tiny-colony",
    },
  },
  {
    chainId: 101,
    name: "TMANIA",
    symbol: "TMANIA",
    address: "HuPspkki5Qdnf5WAU7jtEThkeMhni6XQ23tunZRkZWUi",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38352/large/Untitled_design-16.png?1717179658",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "t-mania-sol",
    },
  },
  {
    chainId: 101,
    name: "Toadie Meme Coin",
    symbol: "TOAD",
    address: "CxRppbsvfhrZJdnqSo5VDkrAYQMucN8Gbj5J1F5qkrNs",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36261/large/TOAD_logo.png?1710932937",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "toadie-meme-coin",
    },
  },
  {
    chainId: 101,
    name: "Toad (SOL)",
    symbol: "$TOAD",
    address: "FViMp5phQH2bX81S7Yyn1yXjj3BRddFBNcMCbTH8FCze",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38446/large/photo_2024-06-03_00.06.28.png?1717556439",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "toad-sol",
    },
  },
  {
    chainId: 101,
    name: "Toly",
    symbol: "TOLY",
    address: "GgJJRwLg9NzFQ97o1CJLGLp1KLSUMBwFc6eQNVEr4fbW",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33191/large/tolySq.png?1700969475",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "toly",
    },
  },
  {
    chainId: 101,
    name: "Toly's Cat",
    symbol: "TOLYCAT",
    address: "96rnTLypVAsokszVWjK8NpixPDa1xWs7ScTECyPEJMcT",
    decimals: 6,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "toly-s-cat",
    },
  },
  {
    chainId: 101,
    name: "TOLYS CAT",
    symbol: "OPPIE",
    address: "GwyxednWbrhgT2K6iPUsbtadErA7TBGqsJjyzAody2mv",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38379/large/TOLYS_CAT.png?1717311454",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tolys-cat",
    },
  },
  {
    chainId: 101,
    name: "Tombili the Fat Cat",
    symbol: "FATCAT",
    address: "55USDUivJLe4L1kY64XK1nDPAqbphG6nRpTjk2iZ5PwG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35957/large/fatcat_200x200.png?1710254517",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tombili-the-fat-cat",
    },
  },
  {
    chainId: 101,
    name: "Tongue Cat",
    symbol: "LUIS",
    address: "5wU4tUcAbds7d5cmnGK2otHa9gbayYsD2mhz1reR6c91",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36767/large/500%282%29.png?1712284795",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tongue-cat",
    },
  },
  {
    chainId: 101,
    name: "tooker kurlson",
    symbol: "TOOKER",
    address: "9EYScpiysGnEimnQPzazr7Jn9GVfxFYzgTEj85hV9L6U",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36887/large/IMG_7753.jpeg?1712650736",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tooker-kurlson",
    },
  },
  {
    chainId: 101,
    name: "TOPCAT in SOL",
    symbol: "TOPCAT",
    address: "FQZLcv6iC7UVBrFWyKzsrGZdxvffz9xqshDdzUbd3qq1",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38497/large/TOPCAT.png?1717708825",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "topcat-in-sol",
    },
  },
  {
    chainId: 101,
    name: "Top G",
    symbol: "TOPG",
    address: "8NH3AfwkizHmbVd83SSxc2YbsFmFL4m2BeepvL6upump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38533/large/topg.png?1717784814",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "top-g",
    },
  },
  {
    chainId: 101,
    name: "Top Jeet",
    symbol: "TOPJ",
    address: "ApCc2K7Bfe75UJ9CcymmzjwnnrKTgDpiYnXqFricVsjF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34808/large/Top_Jeet.jpg?1706120740",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "top-jeet",
    },
  },
  {
    chainId: 101,
    name: "TORG",
    symbol: "TORG",
    address: "AbnTggpTujbdAiJtyhH9WtK2CqXk44W7GipyJXkopBDd",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/17596/large/TORG_Logo_200x200.png?1696517129",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "torg",
    },
  },
  {
    chainId: 101,
    name: "Tori the Cat",
    symbol: "TORI",
    address: "D8F1FvrUhwg8WBjMqABopFYo13WwymGnVhsL3d7dRexP",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37434/large/image.jpg?1714380005",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tori-the-cat",
    },
  },
  {
    chainId: 101,
    name: "Tradfi Bro",
    symbol: "CFA",
    address: "F1n2Tn7Eb9jTbSQiqy2Z7G4VTbkreHGQqcRKKmwZv726",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36557/large/2.png?1711900661",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tradfi-bro",
    },
  },
  {
    chainId: 101,
    name: "TREN",
    symbol: "TREN",
    address: "HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36413/large/trencoinLOGOFINALPRODUCT.png?1711414662",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tren",
    },
  },
  {
    chainId: 101,
    name: "TrendingTool.io",
    symbol: "SMM",
    address: "SMMzJzseLTFb6pxacL8r5mZMEqjTTGWjNPk4q3JQHTu",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36526/large/TrendingTool.io.png?1711700451",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "trendingtool-io",
    },
  },
  {
    chainId: 101,
    name: "trollbox",
    symbol: "TOX",
    address: "Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16903/large/trollbox-200.png?1696516474",
    tags: [],
    verified: true,
    holders: 11921,
    extensions: {
      coingeckoId: "trollbox",
    },
  },
  {
    chainId: 101,
    name: "Troll Inu",
    symbol: "TROLLINU",
    address: "56Nu9TdvUwpNafZVmEftsFAL5kRTdsFQT7ti5yVTAA1L",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34638/large/o3038q.jpg?1705556240",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "troll-inu",
    },
  },
  {
    chainId: 101,
    name: "Trumpie",
    symbol: "TRUMPIE",
    address: "HC2KyVkPK9Mc9NEFPUi43okhTYPa5fStk6a3Ci3cDbQS",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38409/large/trumpie.jpg?1717470810",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "trumpie",
    },
  },
  {
    chainId: 101,
    name: "Trump's Tender Tabby",
    symbol: "TABBY",
    address: "Eq83UwKh5P7RLFhwEg3DRT8ZxNybSCPu7NVHW27E7Xmx",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38188/large/1000004424.jpg?1716789596",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "trump-s-tender-tabby",
    },
  },
  {
    chainId: 101,
    name: "Tsuki",
    symbol: "TSUKI",
    address: "463SK47VkB7uE7XenTHKiVcMtxRsfNE2X4Q9wByaURVA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38556/large/IMG_6043.jpeg?1717999602",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tsuki",
    },
  },
  {
    chainId: 101,
    name: "TurboMoon",
    symbol: "TMOON",
    address: "7Cx1fMabGWgm86xvv73WzSAcwtLppKf39Ywe8e6Qq4T6",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35086/large/tmoon.jpg?1707319820",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "turbomoon",
    },
  },
  {
    chainId: 101,
    name: "TwoTalkingCats",
    symbol: "TWOCAT",
    address: "AB1e1rTGF8xSoYzXEwNWohuMHLCMrBoaSxT6AARmQksd",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36581/large/twocats_cg.png?1711944539",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "twotalkingcats",
    },
  },
  {
    chainId: 101,
    name: "TWTR.fun",
    symbol: "TWTR",
    address: "93HkTBvMHt7V9H7k4NC4DTGNWM9EpByjAoE7wwoCQJCc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36394/large/ContractIcon.png?1711359934",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "twtr-fun",
    },
  },
  {
    chainId: 101,
    name: "Tyrel Derpden",
    symbol: "TYREL",
    address: "6tHCkrCTyqZQfyG9B73HFKcjixQSi3ezWW5E4sfc7UxT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36106/large/derpden_png.png?1710483885",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "tyrel-derpden",
    },
  },
  {
    chainId: 101,
    name: "UCIT",
    symbol: "UCIT",
    address: "HH8bchogQD71iuLghP4cuvSU7vsGJoMJDBxvWTFu7MpA",
    decimals: 2,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36931/large/photo_2024-03-19_03-34-30.jpg?1712803660",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "ucit",
    },
  },
  {
    chainId: 101,
    name: "MILK",
    symbol: "MILK",
    address: "MLKmUCaj1dpBY881aFsrBwR9RUMoKic8SWT3u1q5Nkj",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27168/large/UClogoMLKtoken.png?1696526218",
    tags: [],
    verified: true,
    holders: 3036,
    extensions: {
      coingeckoId: "udder-chaos-milk",
    },
  },
  {
    chainId: 101,
    name: "UncleMine",
    symbol: "UM",
    address: "DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22871/large/unclemine_icon_400.png?1696522171",
    tags: [],
    verified: true,
    holders: 7005,
    extensions: {
      coingeckoId: "unclemine",
    },
  },
  {
    chainId: 101,
    name: "Uniswap (Wormhole)",
    symbol: "UNI",
    address: "8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22969/large/UNI_wh_small.png?1696522263",
    tags: [],
    verified: true,
    holders: 1462,
    extensions: {
      coingeckoId: "uniswap-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Unique Venture clubs",
    symbol: "UNQ",
    address: "UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21730/large/unq.png?1696521084",
    tags: [],
    verified: true,
    holders: 7229,
    extensions: {
      coingeckoId: "unq",
    },
  },
  {
    chainId: 101,
    name: "Unvaxxed Sperm",
    symbol: "UNVAXSPERM",
    address: "9iQBkyLKtqRXvb6ARGvQydt1LYgGcTtVe3PTDpWhdPze",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38128/large/sperm.jpeg?1716569748",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "unvaxxed-sperm-2",
    },
  },
  {
    chainId: 101,
    name: "UPFI Network",
    symbol: "UPS",
    address: "EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20179/large/logo_-_2021-11-11T122041.439.png?1696519591",
    tags: [],
    verified: true,
    holders: 6822,
    extensions: {
      coingeckoId: "upfi-network",
    },
  },
  {
    chainId: 101,
    name: "UpRock",
    symbol: "UPT",
    address: "UPTx1d24aBWuRgwxVnFmX4gNraj3QGFzL3QqBgxtWQG",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38566/large/uprock_color.png?1718007266",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "uprock",
    },
  },
  {
    chainId: 101,
    name: "UpSideDownCat",
    symbol: "USDC",
    address: "F23fFqpRNsmWjuUrFpfM1pvoVvMSpLuN6hY978Y1JXLt",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33981/large/usdcprofile.jpeg?1709638997",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "upsidedowncat",
    },
  },
  {
    chainId: 101,
    name: "URANUS (SOL)",
    symbol: "ANUS",
    address: "9hjZ8UTNrNWt3YUTHVpvzdQjNbp64NbKSDsbLqKR6BZc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34923/large/ANUS.png?1706811500",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "uranus-sol",
    },
  },
  {
    chainId: 101,
    name: "URMOM",
    symbol: "URMOM",
    address: "GACxkcy9WDWHhB15oKLHp3PPzQV4zLt2m7dcGPoZLkZY",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34327/large/image_123650291_%282%29.JPG?1704650727",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "urmom",
    },
  },
  {
    chainId: 101,
    name: "USDC",
    symbol: "USDC",
    address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/6319/large/usdc.png?1696506694",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "usd-coin",
    },
  },
  {
    chainId: 101,
    name: "Bridged USD Coin (Wormhole POS)",
    symbol: "USDCPO",
    address: "E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22945/large/USDCpo_wh_small.png?1696522242",
    tags: [],
    verified: true,
    holders: 9226,
    extensions: {
      coingeckoId: "usd-coin-pos-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Bridged USD Coin (Wormhole Ethereum)",
    symbol: "USDCET",
    address: "A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23019/large/USDCet_wh_small.png?1696522314",
    tags: [],
    verified: true,
    holders: 9678,
    extensions: {
      coingeckoId: "usd-coin-wormhole-from-ethereum",
    },
  },
  {
    chainId: 101,
    name: "USDH",
    symbol: "USDH",
    address: "USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22941/large/USDH_icon.png?1696522237",
    tags: [],
    verified: true,
    holders: 11972,
    extensions: {
      coingeckoId: "usdh",
    },
  },
  {
    chainId: 101,
    name: "UXD Protocol",
    symbol: "UXP",
    address: "UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20319/large/UXP.jpg?1696519722",
    tags: [],
    verified: true,
    holders: 9583,
    extensions: {
      coingeckoId: "uxd-protocol-token",
    },
  },
  {
    chainId: 101,
    name: "UXD Stablecoin",
    symbol: "UXD",
    address: "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22850/large/UXD-White.png?1696522151",
    tags: [],
    verified: true,
    holders: 7313,
    extensions: {
      coingeckoId: "uxd-stablecoin",
    },
  },
  {
    chainId: 101,
    name: "VAPE",
    symbol: "VAPE",
    address: "2qmcZfyPpiFFx9kJPY9i7dM7Da6J5rVUvXzTsx1FJtfq",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36142/large/_VAPElogo.jpg?1710686483",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vape",
    },
  },
  {
    chainId: 101,
    name: "Venture Coin",
    symbol: "VC",
    address: "AebrVZPfSH7KPAxPwnuqTZB9QNepdktk7HSSY4SNj7BM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30944/large/6HvW8RLbLhBbw09QDRpPd6HX-N3Sk9kF2vvdaZGHExU.png?1696529783",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "venture-coin-2",
    },
  },
  {
    chainId: 101,
    name: "Vibe AI",
    symbol: "VAI",
    address: "7FhLiAXYaJX1HbpJPqASucasxdRYRUbvR25QVXfxua3G",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38267/large/VibeAI_coingecko.png?1716949874",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vibe-ai",
    },
  },
  {
    chainId: 101,
    name: "Vibe Cat",
    symbol: "MINETTE",
    address: "Gv9oiZwKMHyVtcUhHRDoEqF2C6jWuCfgGdang78FUh6h",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37807/large/Vibe_Cat.png?1715591676",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vibe-cat",
    },
  },
  {
    chainId: 101,
    name: "Vibing Cat",
    symbol: "VCAT",
    address: "VP9UaBXLM4KYRvyjisu65rz8BU5xNAbewU7LVmyU2x4",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35951/large/photo_2024-03-10_17-50-40.jpg?1710242193",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vibing-cat",
    },
  },
  {
    chainId: 101,
    name: "Viral Inu",
    symbol: "VINU",
    address: "CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20698/large/vinu.png?1696520098",
    tags: [],
    verified: true,
    holders: 4867,
    extensions: {
      coingeckoId: "viral-inu",
    },
  },
  {
    chainId: 101,
    name: "Vizion Protocol",
    symbol: "VIZION",
    address: "4giiLHQPdcuFnVcuBF7wpmfU88EXDdToJqBP8dfpSjtA",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36374/large/IMG_2116.png?1711340409",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vizion-protocol",
    },
  },
  {
    chainId: 101,
    name: "VNX EURO",
    symbol: "VEUR",
    address: "C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29351/large/VNXEUR_%281%29.png?1696528300",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vnx-euro",
    },
  },
  {
    chainId: 101,
    name: "VNX Gold",
    symbol: "VNXAU",
    address: "9TPL8droGJ7jThsq4momaoz6uhTcvX2SeMqipoPmNa8R",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/28019/large/VNX_Token_logo_%283%29.png?1696527035",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vnx-gold",
    },
  },
  {
    chainId: 101,
    name: "VNX Swiss Franc",
    symbol: "VCHF",
    address: "AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29547/large/VNXCHF_%282%29.png?1696528488",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "vnx-swiss-franc",
    },
  },
  {
    chainId: 101,
    name: "Void Games",
    symbol: "VOID",
    address: "DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21307/large/h5lkasZH_400x400.jpg?1696520676",
    tags: [],
    verified: true,
    holders: 25863,
    extensions: {
      coingeckoId: "void-games",
    },
  },
  {
    chainId: 101,
    name: "VoldemortTrumpRobotnik-10Neko",
    symbol: "ETHEREUM",
    address: "2kj1MLqBPnhCTyjxT3MtJhcktJHxgR7s4EDu18khsvgj",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31061/large/icon_medium.jpg?1696529895",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "voldemorttrumprobotnik-10neko",
    },
  },
  {
    chainId: 101,
    name: "VolumeX",
    symbol: "VOLX",
    address: "9HGjB1sTXbG3uDtSLXyN4QpvaJApRy73gDBfk1gNLcp9",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37861/large/IMG_0048.jpeg?1715793027",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "volumex",
    },
  },
  {
    chainId: 101,
    name: "Voodoo",
    symbol: "LDZ",
    address: "E5ZVeBMazQAYq4UEiSNRLxfMeRds9SKL31yPan7j5GJK",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30660/large/ACD05DC9-3427-4C15-8451-34519CBC04BB.jpeg?1696529530",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "voodoo",
    },
  },
  {
    chainId: 101,
    name: "Waffles",
    symbol: "WAFFLES",
    address: "8doS8nzmgVZEaACxALkbK5fZtw4UuoRp4Yt8NEaXfDMb",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37349/large/result.jpeg?1714074413",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "waffles",
    },
  },
  {
    chainId: 101,
    name: "Waggle Network",
    symbol: "WAG",
    address: "5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18696/large/waggle.PNG?1696518164",
    tags: [],
    verified: true,
    holders: 14428,
    extensions: {
      coingeckoId: "waggle-network",
    },
  },
  {
    chainId: 101,
    name: "wagmicatgirlkanye420etfmoon1000x",
    symbol: "HOOD",
    address: "AGyUuFvYGnUUXWG6GUKga4B3MGmBuEZ9hGqY73n76XpJ",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33843/large/Hood.png?1703121051",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wagmicatgirlkanye420etfmoon1000x",
    },
  },
  {
    chainId: 101,
    name: "WAGMI On Solana",
    symbol: "WAGMI",
    address: "3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21069/large/logo.png?1696520452",
    tags: [],
    verified: true,
    holders: 32048,
    extensions: {
      coingeckoId: "wagmi-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Walken",
    symbol: "WLKN",
    address: "EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/25566/large/wlkn.jpg?1696524699",
    tags: [],
    verified: true,
    holders: 397831,
    extensions: {
      coingeckoId: "walken",
    },
  },
  {
    chainId: 101,
    name: "Wallet Sniffer",
    symbol: "BO",
    address: "Bwdg9Md8JV9Da3gESe79YJk9Aeg5M7jVqvRDdPNhbjP2",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34737/large/download.png?1705945264",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wallet-sniffer",
    },
  },
  {
    chainId: 101,
    name: "Wall Street Baby On Solana",
    symbol: "WSB",
    address: "7zBbQAPGgoKvqcK74Yua8qGwEkEjAZxUPb5m3kKvvHyF",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36900/large/C76682D7-D7B2-4097-B18D-ACEC2723C7AE.png?1712677777",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wall-street-baby-on-solana",
    },
  },
  {
    chainId: 101,
    name: "Wally The Whale",
    symbol: "WALLY",
    address: "Fo6tfAkXj74X6j8hati8SxtwZHHcdGeqXVUPLP9Abvqu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36732/large/WALLYPFP.png?1712162533",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wally-the-whale",
    },
  },
  {
    chainId: 101,
    name: "Walter",
    symbol: "WALTER",
    address: "FV56CmR7fhEyPkymKfmviKV48uPo51ti9kAxssQqTDLu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38344/large/coingecko.png?1717142572",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "walter-dog-solana",
    },
  },
  {
    chainId: 101,
    name: "War Of Meme",
    symbol: "WOME",
    address: "2JpwA1PFLMzJjP7bsREK2AuYGwRH6WZ4xZ2hMrTyNFT6",
    decimals: 9,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "war-of-meme",
    },
  },
  {
    chainId: 101,
    name: "wawacat",
    symbol: "WAWA",
    address: "AmKdcXReYTwmxtekQ5SiPTbnLtnwkzJpFbZ5XucBJPzK",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34350/large/Wawa.jpeg?1704701648",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wawacat",
    },
  },
  {
    chainId: 101,
    name: "Web3Camp",
    symbol: "3P",
    address: "3Dk2k4FZgKNe2yGXSfJYKkYD7ApLAf15nCbmSvEy8iQM",
    decimals: 2,
    logoURI: null,
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "web3camp-2",
    },
  },
  {
    chainId: 101,
    name: "Welsh Corgi",
    symbol: "CORGI",
    address: "GDisrnbVWjKJuAVqeWfYd9zMyV9gP7gXb3FQfd8xbKuM",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35738/large/ava.png?1709694336",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "welsh-corgi",
    },
  },
  {
    chainId: 101,
    name: "WEN",
    symbol: "$WEN",
    address: "3J5QaP1zJN9yXE7jr5XJa3Lq2TyGHSHu2wssK7N1Aw4p",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32570/large/wen.png?1698550122",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wen-2",
    },
  },
  {
    chainId: 101,
    name: "Wen",
    symbol: "$WEN",
    address: "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34856/large/wen-solana.png?1707872119",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wen-4",
    },
  },
  {
    chainId: 101,
    name: "WenisCoin",
    symbol: "WENIS",
    address: "BycodkJgwAA4PTygbQQ35LUB1KRLoCofKz5CmC8BK6dU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38079/large/200pxLogo.png?1716462748",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "weniscoin",
    },
  },
  {
    chainId: 101,
    name: "WenWifHat",
    symbol: "WHY",
    address: "WHYoaBumcmxCqw38y2mjs4cVkCBgwiDizbMVvcejmGT",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36482/large/imresizer-1710511720486.jpg?1711536959",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wenwifhat",
    },
  },
  {
    chainId: 101,
    name: "Whalebert",
    symbol: "WHALE",
    address: "9uhHjyfc5tKdaZnjstLLKoLGcF889ub8zX9wtwhtzgK6",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36848/large/whalebert.jpeg?1712584495",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "whalebert",
    },
  },
  {
    chainId: 101,
    name: "Whales Club",
    symbol: "WHC",
    address: "9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36453/large/IMG_9206.jpeg?1713896038",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "whales-club",
    },
  },
  {
    chainId: 101,
    name: "Whales Market",
    symbol: "WHALES",
    address: "GTH3wG3NErjwcf7VGCoXEXkgXSHvYhx5gtATeeM5JAS1",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34366/large/whale_logo.jpeg?1706505535",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "whales-market",
    },
  },
  {
    chainId: 101,
    name: "What in Tarnation?",
    symbol: "WIT",
    address: "Adq3wnAvtaXBNfy63xGV1YNkDiPKadDT469xF9uZPrqE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36577/large/WIT_token.png?1711943916",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "what-in-tarnation",
    },
  },
  {
    chainId: 101,
    name: "What’s Updog?",
    symbol: "$UPDOG",
    address: "HJ39rRZ6ys22KdB3USxDgNsL7RKiQmsC3yL8AS3Suuku",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35706/large/updog.png?1709581846",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "what-s-updog",
    },
  },
  {
    chainId: 101,
    name: "Shredded Apes Whey",
    symbol: "WHEY",
    address: "Ue4yjkPjA4QGis37eWbBsnqfzyK83BtY4AioDETp3Ab",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23256/large/whey-coin-2048x2048.png?1696522475",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "whey-token",
    },
  },
  {
    chainId: 101,
    name: "Whine Coin",
    symbol: "WHINE",
    address: "ATeTQcUkWGs7AZ15mCiFUWCW9EUL7KpDZEHCN1Y8pump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38523/large/IMG_20240606_032303_401.jpg?1717759605",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "whine-coin",
    },
  },
  {
    chainId: 101,
    name: "White Boy Summer",
    symbol: "WBS",
    address: "GJgHsc1HU4ibmzW6oWQr8L2RRT95ATc1BoNuLkp94AwU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38069/large/wbs_logo_just_hat_200x200.jpg?1716446030",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "white-boy-summer",
    },
  },
  {
    chainId: 101,
    name: "White Coffee Cat",
    symbol: "WCC",
    address: "BykkD9369RvXuEDbR7pTRz49b7cfLRTzHgSVoqK8gc15",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36700/large/IMG_0757.jpeg?1712118462",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "white-coffee-cat",
    },
  },
  {
    chainId: 101,
    name: "Whole Earth Coin",
    symbol: "WEC",
    address: "6y8W5YwAuzostqrS4YDJufBvksosfSi47Pd8U4A5vrBC",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/16313/large/WEC_logo.png?1696515914",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "whole-earth-coin",
    },
  },
  {
    chainId: 101,
    name: "Wife Changing Money",
    symbol: "WIFE",
    address: "6R7iukfSN8fMwJhtz3mmcSjV4yAmVikCHSs3hSdbuLqY",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33835/large/WifeChangingMoneySOLtrans.png?1703107569",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wife-changing-money",
    },
  },
  {
    chainId: 101,
    name: "Wifejak",
    symbol: "WIFE",
    address: "4y3oUrsJfSp431R3wJrWiaLxRPsnYtpkVJmoV2bYpBiy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37066/large/wifejakcoversquaregecko.jpg?1713179609",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wifejak",
    },
  },
  {
    chainId: 101,
    name: "WIFLAMA COIN",
    symbol: "WFLM",
    address: "DKtX6xzAjzkUFmXF9ewTARSsmjR57CisFqM8QmPQMtxH",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36934/large/1000005558.jpg?1712805427",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wiflama-coin",
    },
  },
  {
    chainId: 101,
    name: "Wif - SecondChance",
    symbol: "WIF",
    address: "21AErpiB8uSb94oQKRcwuHqyHF93njAxBSbdUrpupump",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38567/large/2024-06-07_16.20.09.jpg?1718007546",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wif-secondchance",
    },
  },
  {
    chainId: 101,
    name: "Wild Goat Coin [OLD]",
    symbol: "GCV1",
    address: "AfPeB1BDUotBeNoLv82XRDCNQcdAA1mqis3YC5SMTe7a",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34938/large/Black_and_White_Old_Logo_2.png?1716581933",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wild-goat-coin",
    },
  },
  {
    chainId: 101,
    name: "Wild Goat Coin",
    symbol: "WGC",
    address: "4Eb7ReeT936hX25KMBfYtyP1RWDdd9iixEwGJroACeLC",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37966/large/COIN_200x200.png?1716778831",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wild-goat-coin-2",
    },
  },
  {
    chainId: 101,
    name: "WINAMP",
    symbol: "WINAMP",
    address: "ftRShbNY7TPquis7h2hDziPbFtbTnk9Fttxh3ZMyuK6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34543/large/gYrXOvD0vZxaOBDdJnM7QO0u5j_guadh3aI3Ne5y9fI.jpg?1705339625",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "winamp",
    },
  },
  {
    chainId: 101,
    name: "WINBIT CASINO",
    symbol: "WIN",
    address: "FF71MVJYgMeGcJRjvdLUkThHupPFNvXyVPCWdq7VPsXd",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38602/large/win_%281%29.jpg?1718097132",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "winbit-casino",
    },
  },
  {
    chainId: 101,
    name: "Winerz",
    symbol: "$WNZ",
    address: "WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24786/large/wnz.png?1696523946",
    tags: [],
    verified: true,
    holders: 6192,
    extensions: {
      coingeckoId: "winerz",
    },
  },
  {
    chainId: 101,
    name: "WINR Protocol",
    symbol: "WINR",
    address: "CsxCtA8usvWKdRhe7KhLU5GgwzYaLkoHsz1MKBVZ4W3M",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29340/large/WINR.png?1696528290",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "winr-protocol",
    },
  },
  {
    chainId: 101,
    name: "WipeMyAss",
    symbol: "WIPE",
    address: "9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/19967/large/wipemyass.jpeg?1696519383",
    tags: [],
    verified: true,
    holders: 14534,
    extensions: {
      coingeckoId: "wipemyass",
    },
  },
  {
    chainId: 101,
    name: "Woke",
    symbol: "WOKE",
    address: "HszwaGZYhhRvmZHtsKioheHim9mp9cw3MJZA4eX67kZH",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35168/large/Woke.png?1707665558",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "woke",
    },
  },
  {
    chainId: 101,
    name: "Woke Frens",
    symbol: "WOKE",
    address: "AmghZTmRCWooJS8wHodqGcZnLzvxgsdegmF3GCTb7YQw",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34186/large/photo_2023-12-27_09.17.00.jpeg?1704260032",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "woke-frens",
    },
  },
  {
    chainId: 101,
    name: "Wolf Of Solana",
    symbol: "WOS",
    address: "8TfYk26pFxnaCmZbjoSMCzktDU16H5CgZ1Z9eTnB12MR",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36989/large/wos.jpg?1712951010",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wolf-of-solana",
    },
  },
  {
    chainId: 101,
    name: "Wolf On Solana",
    symbol: "WOLF",
    address: "3EagcZ66c8z2hZdFSd5412yEW9U48AAb9P6d1yhjuGmY",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33714/large/20231123_211619.jpg?1702879547",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wolf-on-solana",
    },
  },
  {
    chainId: 101,
    name: "WOLF SOLANA",
    symbol: "WOLF",
    address: "HmKqChBkZEvqFnH8sxja694n77ziYMBWaucZRKfJDRr2",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/32121/large/LOGO_WOLF.png?1696586680",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wolf-solana",
    },
  },
  {
    chainId: 101,
    name: "WolfWifBallz",
    symbol: "BALLZ",
    address: "9ZMEz6nmr4RWs92ASFKxFGfKpWw8cbUp2ZP3EhdqwWPr",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35903/large/ballz-logo.png?1710171305",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wolfwifballz",
    },
  },
  {
    chainId: 101,
    name: "This is Fine (SOL)",
    symbol: "FINE",
    address: "WARcU61sECeEK5DEbkY3wcjGxSGr4W8bVUpKbBNbgbu",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35270/large/200X200.png?1710078595",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "woof",
    },
  },
  {
    chainId: 101,
    name: "WOOF",
    symbol: "WOOF",
    address: "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/18319/large/woof-logo-svg-true-solana-radient-blackinside.png?1696517810",
    tags: [],
    verified: true,
    holders: 54803,
    extensions: {
      coingeckoId: "woof-token",
    },
  },
  {
    chainId: 101,
    name: "WOO",
    symbol: "WOO",
    address: "E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/12921/large/WOO_Logos_2023_Profile_Pic_WOO.png?1696512709",
    tags: [],
    verified: true,
    holders: 1702,
    extensions: {
      coingeckoId: "woo-network",
    },
  },
  {
    chainId: 101,
    name: "WOOP",
    symbol: "WOOP",
    address: "A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24904/large/coin-gecko.png?1696524062",
    tags: [],
    verified: true,
    holders: 5425,
    extensions: {
      coingeckoId: "woop",
    },
  },
  {
    chainId: 101,
    name: "woosh",
    symbol: "WOOSH",
    address: "5LTt4seLcTy43zRA94LMQ4n7reaFsrcSXCxNbkmpmU9o",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34810/large/1qi98d_%282%29.jpg?1706122163",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "woosh",
    },
  },
  {
    chainId: 101,
    name: "WORK FOR YOUR BAGS",
    symbol: "WORK",
    address: "G612EiJV79Fr68xrC8oyMwDxXN9eZzjfzc8k5yachBAQ",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37445/large/2f77b6e9-53e9-46a1-bf38-09812bac8106.jpeg?1714446756",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "work-for-your-bags",
    },
  },
  {
    chainId: 101,
    name: "World Record Banana",
    symbol: "BANANA",
    address: "Hg675ypQpBUwP3wiWjq8pFQxr6rjnT2QRH4Vi519jdiP",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36513/large/Banana.png?1711692460",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "world-record-banana",
    },
  },
  {
    chainId: 101,
    name: "Worldwide USD",
    symbol: "WUSD",
    address: "EczfGov7Mia54FLP6Stz3kttWsUA2fv4vtDTKLuyAPDN",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35358/large/WUSD%282%29.png?1715042139",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "worldwide-usd",
    },
  },
  {
    chainId: 101,
    name: "Wormhole",
    symbol: "W",
    address: "85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35087/large/womrhole_logo_full_color_rgb_2000px_72ppi_fb766ac85a.png?1708688954",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wormhole",
    },
  },
  {
    chainId: 101,
    name: "Wrapped ALGO",
    symbol: "XALGO",
    address: "xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26335/large/xALGO.png?1696525412",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wrapped-algo",
    },
  },
  {
    chainId: 101,
    name: "Wrapped Bitcoin (Sollet)",
    symbol: "SOBTC",
    address: "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24917/large/opengraph.png?1696524074",
    tags: [],
    verified: true,
    holders: 126861,
    extensions: {
      coingeckoId: "wrapped-bitcoin-sollet",
    },
  },
  {
    chainId: 101,
    name: "21.co Wrapped BTC",
    symbol: "21BTC",
    address: "21BTCo9hWHjGYYUQQLqjLgDBxjcn8vDt4Zic7TB3UbNE",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34616/large/64ffa735ee23dbcd35498914_21co_W_Token_v2_21BTC-4.png?1705503435",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wrapped-btc-21-co",
    },
  },
  {
    chainId: 101,
    name: "Wrapped BTC (Wormhole)",
    symbol: "WBTC",
    address: "3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23004/large/WBTC_wh_small.png?1696522299",
    tags: [],
    verified: true,
    holders: 1092,
    extensions: {
      coingeckoId: "wrapped-btc-wormhole",
    },
  },
  {
    chainId: 101,
    name: "Wrapped CUSD (Allbridge from Celo)",
    symbol: "ACUSD",
    address: "EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23057/large/7236.png?1696522349",
    tags: [],
    verified: true,
    holders: 6688,
    extensions: {
      coingeckoId: "wrapped-cusd-allbridge-from-celo",
    },
  },
  {
    chainId: 101,
    name: "Wrapped Ethereum (Sollet)",
    symbol: "SOETH",
    address: "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24918/large/6250754.png?1696524074",
    tags: [],
    verified: true,
    holders: 165472,
    extensions: {
      coingeckoId: "wrapped-ethereum-sollet",
    },
  },
  {
    chainId: 101,
    name: "Wrapped NYBC",
    symbol: "WNYBC",
    address: "Aqi9zw2YcC3ps5Mzib8G5NnhuhR2GVZ6V29i2MpnGxeE",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36487/large/NBYC.jpg?1711556764",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wrapped-nybc",
    },
  },
  {
    chainId: 101,
    name: "Wrapped Solana",
    symbol: "SOL",
    address: "So11111111111111111111111111111111111111112",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/21629/large/solana.jpg?1696520989",
    tags: [],
    verified: true,
    holders: 100000,
    extensions: {
      coingeckoId: "wrapped-solana",
    },
  },
  {
    chainId: 101,
    name: "Bridged Tether (Allbridge)",
    symbol: "APUSDT",
    address: "DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/23060/large/logo_-_2022-01-26T084912.902.png?1696522352",
    tags: [],
    verified: true,
    holders: 6452,
    extensions: {
      coingeckoId: "wrapped-usdt-allbridge-from-polygon",
    },
  },
  {
    chainId: 101,
    name: "Wrapped USTC",
    symbol: "USTC",
    address: "CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/15462/large/ust.png?1696515108",
    tags: [],
    verified: true,
    holders: 11946,
    extensions: {
      coingeckoId: "wrapped-ust",
    },
  },
  {
    chainId: 101,
    name: "Wrinkle The Duck",
    symbol: "WRINKLE",
    address: "C46fj9922NZWFsjePXNiwAJFWTmhDRrAotVCyjtDxJCc",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37547/large/wrinkle_logo.png?1714734304",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wrinkle-the-duck",
    },
  },
  {
    chainId: 101,
    name: "WUFFI",
    symbol: "WUF",
    address: "73xsLcBnLnc9bh81cqVKqj8uEyiarXng5ZwJuTbnVebG",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36933/large/WUFFI_Ticker_Animation_200x200_v2.gif?1715047002",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wuffi",
    },
  },
  {
    chainId: 101,
    name: "WUT",
    symbol: "WUT",
    address: "DG5bH1BnfjB5YL7Vt3GjykkUKf6maDUW3jYvdNa9eEVa",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34583/large/Dex_WUT.png?1715371669",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "wut",
    },
  },
  {
    chainId: 101,
    name: "XBANKING",
    symbol: "XB",
    address: "2uAuGwYH22SJJtaTqMJ2AGEL2rBdiRKkuak2QCCSaFCA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/31650/large/Web_1920_%E2%80%93_1.png?1696530465",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "xbanking",
    },
  },
  {
    chainId: 101,
    name: "Xcoinmeme",
    symbol: "X",
    address: "BmtmfGfvF23sqKh5YCAVxgf9AWYpJc5zqyySxHi8SLom",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34870/large/IMG_20240127_104500_528.png?1706451906",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "xcoinmeme",
    },
  },
  {
    chainId: 101,
    name: "xHashtag",
    symbol: "XTAG",
    address: "5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/20912/large/xtag.png?1696520303",
    tags: [],
    verified: true,
    holders: 9999,
    extensions: {
      coingeckoId: "xhashtag",
    },
  },
  {
    chainId: 101,
    name: "XL BULLY",
    symbol: "XLBULLY",
    address: "5v19sm86F6mqEtPJZAoNEcQfFziBbbVBnmyN59w1N4Y5",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34117/large/XLBULLYCOIN1.png?1704021278",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "xl-bully",
    },
  },
  {
    chainId: 101,
    name: "Y2K",
    symbol: "Y2K",
    address: "CQ6qQ6hkT3kYvCJ9hwFR4CT9kSrzUuW9VHByXqpbviTm",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35694/large/IMG_6110.jpeg?1709543337",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "y2k-2",
    },
  },
  {
    chainId: 101,
    name: "Yaku",
    symbol: "YAKU",
    address: "AqEHVh8J2nXH9saV2ciZyYwPpqWFRfD2ffcq5Z8xxqm5",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/26785/large/yaku_logo.png?1706636424",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yaku",
    },
  },
  {
    chainId: 101,
    name: "Yawww",
    symbol: "YAW",
    address: "YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24233/large/yaw.PNG?1696523418",
    tags: [],
    verified: true,
    holders: 38197,
    extensions: {
      coingeckoId: "yawww",
    },
  },
  {
    chainId: 101,
    name: "Yelo Cat",
    symbol: "YELO",
    address: "H5n9BQfULHEEtvLVf2WErUHsXHhRaUkYqVhNzxUkWJGf",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34178/large/YELO.jpg?1704221509",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yelo-cat",
    },
  },
  {
    chainId: 101,
    name: "Yes, But",
    symbol: "YESBUT",
    address: "H3njuDnM7d3NW1Lvh7zK8r2uynNBzLSFH1GBsET4531z",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37423/large/Yes__But.png?1714377729",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yes-but",
    },
  },
  {
    chainId: 101,
    name: "Yikes Dog",
    symbol: "YIKES",
    address: "Ce3dRaePi2PrcsHb45i8qcaeCpHacvjXbbzo2DTPfX8z",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36923/large/Yikes_Dog.png?1712797819",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yikes-dog",
    },
  },
  {
    chainId: 101,
    name: "Yotoshi",
    symbol: "YOTO",
    address: "7SdFACfxmg2eetZEhEYZhsNMVAu84USVtfJ64jFDCg9Y",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/33965/large/Yotoshi_logo.png?1703571338",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yotoshi",
    },
  },
  {
    chainId: 101,
    name: "You Looked",
    symbol: "CIRCLE",
    address: "EkHr62PC6Y1axrLS7cR8YC4BZeW19mtHxQLCLMrf9vnq",
    decimals: 3,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34436/large/youlooked_%281%29.jpg?1704871395",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "you-looked",
    },
  },
  {
    chainId: 101,
    name: "YOUR AI",
    symbol: "YOURAI",
    address: "FjK6rqU6QzUeNtmK6QQ78cCuS5AHAhcm4HgJkdsvXaep",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36260/large/yourai-ticker_%281%29.png?1710929542",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "your-ai",
    },
  },
  {
    chainId: 101,
    name: "YourMom",
    symbol: "YOURMOM",
    address: "By5XddQNE4SB2XbgvreFeMNHL5zqgGqypiN3Xor3XmvM",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34427/large/TTdCKL46.png?1704867630",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yourmom",
    },
  },
  {
    chainId: 101,
    name: "YOM",
    symbol: "YOM",
    address: "yomFPUqz1wJwYSfD5tZJUtS3bNb8xs8mx9XzBv8RL39",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24462/large/yom.png?1696523642",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "your-open-metaverse",
    },
  },
  {
    chainId: 101,
    name: "Yuge Meme",
    symbol: "YUGE",
    address: "5ve8Fw3gDFpdipxtk4UptKbrtimRLDy97KRKuLbQnECa",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34311/large/Yuge.png?1704618805",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yuge-meme",
    },
  },
  {
    chainId: 101,
    name: "Yunki",
    symbol: "YUNKI",
    address: "yunki1EK5VWXRaMufpjwsbBBX6SRuMwxAncsv9iPZQi",
    decimals: 5,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/34276/large/yunki-pfp.png?1705368766",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yunki",
    },
  },
  {
    chainId: 101,
    name: "Yuro 2024",
    symbol: "YURO",
    address: "wh2rGDto5Xcqw1nh8LMiFHDUuaz2q1VcEy4gzG7uvTw",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38223/large/LOGO-1.png?1716827246",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "yuro-2024",
    },
  },
  {
    chainId: 101,
    name: "Zack Morris",
    symbol: "ZACK",
    address: "8vCAUbxejdtaxn6jnX5uaQTyTZLmXALg9u1bvFCAjtx7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37982/large/1000000298.jpg?1716204057",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zack-morris",
    },
  },
  {
    chainId: 101,
    name: "Zap",
    symbol: "ZAP",
    address: "HxPoEHMt1vKeqjKCePcqTj6yYgn6Xqq1fKTY3Pjx4YrX",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/2180/large/zap.png?1696503138",
    tags: [],
    verified: true,
    holders: 582,
    extensions: {
      coingeckoId: "zap",
    },
  },
  {
    chainId: 101,
    name: "ZARP Stablecoin",
    symbol: "ZARP",
    address: "8v8aBHR7EXFZDwaqaRjAStEcmCj6VZi5iGq1YDtyTok6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/27333/large/zarp_coin.png?1696526381",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zarp-stablecoin",
    },
  },
  {
    chainId: 101,
    name: "ZAZU",
    symbol: "ZAZU",
    address: "n7EksMkvk3WT5FjQ3HBMLm9XYU3EnoXUSJ7PoWpxsoG",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37607/large/ZAZU_%281%29.jpg?1715046006",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zazu",
    },
  },
  {
    chainId: 101,
    name: "Zebec Network",
    symbol: "ZBCN",
    address: "ZBCNpuD7YMXzTHB2fhGkGi78MNsHGLRXUhRewNRm9RU",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37052/large/zbcn.jpeg?1713168241",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zebec-network",
    },
  },
  {
    chainId: 101,
    name: "Zebec Protocol",
    symbol: "ZBC",
    address: "zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/24342/large/zebec.jpeg?1696523526",
    tags: [],
    verified: true,
    holders: 58185,
    extensions: {
      coingeckoId: "zebec-protocol",
    },
  },
  {
    chainId: 101,
    name: "ZEBU",
    symbol: "ZEBU",
    address: "7unYePWUHcpB28cnS65TpqT2qqmZaftRz9QABkdR8yN7",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35905/large/hellopipo.sol-modified.png?1710173575",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zebu",
    },
  },
  {
    chainId: 101,
    name: "Zeck Murris",
    symbol: "ZECK",
    address: "5xUaXguNxNGd2JGibodttqYwUcymSuNZMHLE6v14Dd2i",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38125/large/photo_2024-05-23_19-31-45.jpg?1716568558",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zeck-murris",
    },
  },
  {
    chainId: 101,
    name: "Zeepr",
    symbol: "ZEEP",
    address: "FHAeF63cELMnm9HAHPLEWJGrGpBEYkdcvN3cLQGTCA6j",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36659/large/200.png?1712042698",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zeepr",
    },
  },
  {
    chainId: 101,
    name: "Zeus",
    symbol: "ZEUS",
    address: "GM3BR5yjG5A5cxPukjBj2FPjLsuvCYrXZzVWQkP6DQWQ",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36705/large/IMG_7036.jpeg?1712125836",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zeus-2",
    },
  },
  {
    chainId: 101,
    name: "Zeus Network",
    symbol: "ZEUS",
    address: "ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36692/large/logo-v1.png?1712427948",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zeus-network",
    },
  },
  {
    chainId: 101,
    name: "Zignaly",
    symbol: "ZIG",
    address: "26f12PmBk77wQV1TzLe8XKkNBvMFggbuypxdtMLzNLzz",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/14796/large/zignaly.jpg?1704292004",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zignaly",
    },
  },
  {
    chainId: 101,
    name: "ZilPepe",
    symbol: "ZILPEPE",
    address: "ZPEPEuSDb7DKQtM7SMaZpLC2ggHjvP8VmsBfhfgfqQt",
    decimals: 4,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30166/large/logo.png?1710858721",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zilpepe",
    },
  },
  {
    chainId: 101,
    name: "Zion",
    symbol: "ZION",
    address: "A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/22150/large/Ziontoken_2_84.png?1696521495",
    tags: [],
    verified: true,
    holders: 13952,
    extensions: {
      coingeckoId: "zion",
    },
  },
  {
    chainId: 101,
    name: "Zippy Staked SOL",
    symbol: "ZIPPYSOL",
    address: "Zippybh3S5xYYam2nvL6hVJKz1got6ShgV4DyD1XQYF",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/37085/large/mint-200x200.png?1713253358",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zippy-staked-sol",
    },
  },
  {
    chainId: 101,
    name: "ZombieCoin",
    symbol: "ZMB",
    address: "DS4QiZfkp39PsHXYCRV3NkyDUKV9SpTczp2qnAUg6Nt6",
    decimals: 6,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38593/large/ZMB.png?1718093936",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zombiecoin",
    },
  },
  {
    chainId: 101,
    name: "Zoomer",
    symbol: "ZOOMER",
    address: "nBZEcHSG771mRbi4y2sSgKjfDUH8jsM2Eo5fNcASLeU",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/30894/large/zoooooooooomer.jpg?1696529740",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zoomer",
    },
  },
  {
    chainId: 101,
    name: "Zoomer (Sol)",
    symbol: "ZOOMER",
    address: "9MBzpyMRkj2r5nTQZMMnxnCm5j1MAAFSYUtbSKjAF3WU",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/38215/large/zoomer-pfp.jpeg?1716823833",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zoomer-sol",
    },
  },
  {
    chainId: 101,
    name: "Zorksees",
    symbol: "ZORKSEES",
    address: "9kq1VuGCCKeTgtRmzUTjLVi6LpmBD38im2hPRaqusPkE",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/36787/large/zorksees.jpeg?1712367300",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zorksees",
    },
  },
  {
    chainId: 101,
    name: "zSOL",
    symbol: "ZSOL",
    address: "So111DzVTTNpDq81EbeyKZMi4SkhU9yekqB8xmMpqzA",
    decimals: 9,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/29076/large/zSOL.png?1696528042",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zsol",
    },
  },
  {
    chainId: 101,
    name: "ZynCoin",
    symbol: "ZYN",
    address: "PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd",
    decimals: 8,
    logoURI:
      "https://coin-images.coingecko.com/coins/images/35749/large/zyn_logo_new_200_x_200.png?1710219535",
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: "zyncoin-2",
    },
  },
  {
    chainId: 101,
    name: "Yaku",
    symbol: "YAKU",
    address: "NGK3iHqqQkyRZUj4uhJDQqEyKKcZ7mdawWpqwMffM3s",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EK58dp4mxsKwwuySWQW826i3fwcvUK69jPph22VUcd2H/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 8382,
  },
  {
    chainId: 101,
    name: "agEUR (Portal)",
    symbol: "agEUR",
    address: "CbNYA9n3927uXUukee2Hf4tm3xxkffJPPZvGazc2EAH1",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CbNYA9n3927uXUukee2Hf4tm3xxkffJPPZvGazc2EAH1/logo.png",
    tags: ["ethereum", "wrapped", "wormhole"],
    verified: true,
    holders: 890,
  },
  {
    chainId: 101,
    name: "ANGLE (Portal)",
    symbol: "ANGLE",
    address: "31GpPxe1SW8pn7GXimM73paD8PZyCsmVSGTLkwUAJvZ8",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/31GpPxe1SW8pn7GXimM73paD8PZyCsmVSGTLkwUAJvZ8/logo.svg",
    tags: ["ethereum", "wrapped", "wormhole"],
    verified: true,
    holders: 278,
  },
  {
    chainId: 101,
    name: "KITTY SOLANA",
    symbol: "KITTY",
    address: "6JdcMdhqgCtcP4U9tieRqmKLhPLxRMLC67QfmdXAJBvZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JdcMdhqgCtcP4U9tieRqmKLhPLxRMLC67QfmdXAJBvZ/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 7134,
  },
  {
    chainId: 101,
    name: "KING SAMO",
    symbol: "KSAMO",
    address: "HDiA4quoMibAGeJQzvxajp3Z9cvnkNng99oVrnuNj6px",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDiA4quoMibAGeJQzvxajp3Z9cvnkNng99oVrnuNj6px/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 1722,
  },
  {
    chainId: 101,
    name: "Otter Finance",
    symbol: "OTR",
    address: "6TgvYd7eApfcZ7K5Mur7MaUQ2xT7THB4cLHWuMkQdU5Z",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TgvYd7eApfcZ7K5Mur7MaUQ2xT7THB4cLHWuMkQdU5Z.png",
    tags: ["meme-token"],
    verified: true,
    holders: 1946,
  },
  {
    chainId: 101,
    name: "Rainc",
    symbol: "RAC",
    address: "EH49ziLeKhJtzUzdys5238pSKpvrgJvmi3EStrZ9QaY7",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EH49ziLeKhJtzUzdys5238pSKpvrgJvmi3EStrZ9QaY7/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 319,
  },
  {
    chainId: 101,
    name: "TokenBook",
    symbol: "TBK",
    address: "2mDJPcvv7vigZo9ZPxhHLpKQSixCkbohVY35eX6NkN6m",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mDJPcvv7vigZo9ZPxhHLpKQSixCkbohVY35eX6NkN6m/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 1893,
  },
  {
    chainId: 101,
    name: "BABY OOGI",
    symbol: "BOOGI",
    address: "DcvJP16Cw5oqTbtHmpJ4JGXaqBvV5m6eMZj5rGsFLpwU",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcvJP16Cw5oqTbtHmpJ4JGXaqBvV5m6eMZj5rGsFLpwU/logo.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 505,
  },
  {
    chainId: 101,
    name: "Solana Paws",
    symbol: "Paws",
    address: "6bLp99VoqKU1C3Qp6VTNvSoCoc78jMGxPkGSSopq8wHB",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6bLp99VoqKU1C3Qp6VTNvSoCoc78jMGxPkGSSopq8wHB/logo.png",
    tags: ["meme-token", "community", "doge", "Paws"],
    verified: true,
    holders: 5949,
  },
  {
    chainId: 101,
    name: "Dogecoin Cash",
    symbol: "DOGEC",
    address: "GGupQCMnyEmHKcqFu72qCTm6yEYpVyhouY9dSAMEXLsC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGupQCMnyEmHKcqFu72qCTm6yEYpVyhouY9dSAMEXLsC/logo.png",
    tags: ["community-token", "meme-token", "doge", "dogecoin"],
    verified: true,
    holders: 265,
  },
  {
    chainId: 101,
    name: "DogeMoonxSOL",
    symbol: "DGMOON",
    address: "8sMa1Jfcpt2eSkKDtcd6rurX27gqxkrEvXn5jHt3suGB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8sMa1Jfcpt2eSkKDtcd6rurX27gqxkrEvXn5jHt3suGB/logo.png",
    tags: ["community-token", "doge-token", "doge", "nfts"],
    verified: true,
    holders: 1136,
  },
  {
    chainId: 101,
    name: "RockDeFi",
    symbol: "ROCK",
    address: "roCKojKezC7HhPxph5qb4UBasvmZJWgegCF57PvaV2f",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/roCKojKezC7HhPxph5qb4UBasvmZJWgegCF57PvaV2f/logo.png",
    tags: ["stablecoin", "asset"],
    verified: true,
    holders: 167,
  },
  {
    chainId: 101,
    name: "Parm Coin",
    symbol: "PARM",
    address: "9cU8yLEAidMNVGEq6QHPe2ktN7SV2qqvLABth8YiSwYx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9cU8yLEAidMNVGEq6QHPe2ktN7SV2qqvLABth8YiSwYx/logo.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 11015,
  },
  {
    chainId: 101,
    name: "AstraPad",
    symbol: "ASTRA",
    address: "AMp8Jo18ZjK2tuQGfjKAkkWnVP4NWX5sav4NJH6pXF2D",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMp8Jo18ZjK2tuQGfjKAkkWnVP4NWX5sav4NJH6pXF2D/logo.png",
    tags: ["Launchpad", "NFT"],
    verified: true,
    holders: 2069,
  },
  {
    chainId: 101,
    name: "theBULL Coin",
    symbol: "BULL",
    address: "FaiPGacTM7YBmacumbg4ZnDx7sKtGcG3LkcVoqfddEA7",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FaiPGacTM7YBmacumbg4ZnDx7sKtGcG3LkcVoqfddEA7/logo.png",
    tags: ["community-token", "meme-token", "NFT"],
    verified: true,
    holders: 2928,
  },
  {
    chainId: 101,
    name: "BUFF SAMO",
    symbol: "BSAMO",
    address: "2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 13139,
  },
  {
    chainId: 101,
    name: "SolRaca",
    symbol: "SOLRC",
    address: "Bjgh4YsLdicr8WArz9ftdSmpWNcQjsZ9KV3w9fkjiLG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Solraca/token-list/main/assets/mainnet/Bjgh4YsLdicr8WArz9ftdSmpWNcQjsZ9KV3w9fkjiLG/solraca.png",
    tags: ["meme-token"],
    verified: true,
    holders: 259,
  },
  {
    chainId: 101,
    name: "Ass Coin",
    symbol: "$ASS",
    address: "4JEaBv49a4KdSrMduKZS3PcBCcPmPEmaY3uP7kXv6cj6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/daddydonke/gem-farm-ui/main/public/images/icon.png",
    tags: ["MEMES-TOKEN", "NFTS", "DEX"],
    verified: true,
    holders: 2340,
  },
  {
    chainId: 101,
    name: "NSPACE Supporter",
    symbol: "SNS",
    address: "X71v8NH6dbLwPsn4TR1Tx38K4uWgGZ78mC599XSPJox",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/X71v8NH6dbLwPsn4TR1Tx38K4uWgGZ78mC599XSPJox/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 490,
  },
  {
    chainId: 101,
    name: "My NFT Space",
    symbol: "NSPACE",
    address: "NpgsBSfavf5hmUeGQAbMz5pHDtXhn9ZFNRQypTr8Tfv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NpgsBSfavf5hmUeGQAbMz5pHDtXhn9ZFNRQypTr8Tfv/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1230,
  },
  {
    chainId: 101,
    name: "AKELA",
    symbol: "AKELA",
    address: "7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495/logo.svg",
    tags: ["Akela", "Meme"],
    verified: true,
    holders: 3552,
  },
  {
    chainId: 101,
    name: "Hippo Coin",
    symbol: "HIPPO",
    address: "3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 5884,
  },
  {
    chainId: 101,
    name: "BIP",
    symbol: "BIP",
    address: "FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 2330,
  },
  {
    chainId: 101,
    name: "INUGAMI Coin",
    symbol: "INUGAMI",
    address: "F9tytWqLUAPXQTy6dejGtSgvJQZWYC71naD5bCi6caGX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/INVESTOR888/content/main/F9tytWqLUAPXQTy6dejGtSgvJQZWYC71naD5bCi6caGX.png",
    tags: ["meme-token"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "Solana Weed",
    symbol: "WEED",
    address: "7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 4601,
  },
  {
    chainId: 101,
    name: "Bark o Finance",
    symbol: "BARK",
    address: "GaAzf7jwEKTouDXJExH9TKfvX3Ae7fLaGwNuEajq7KsE",
    decimals: 1,
    logoURI: "https://i.imgur.com/X90vi6d.png",
    tags: ["meme-token", "utility-token"],
    verified: true,
    holders: 537,
  },
  {
    chainId: 101,
    name: "Solana Breakpoint",
    symbol: "SBreakpoint",
    address: "7mNihWEjzWv9yCZc8capE4mS8v5Xvp5YH2yQhtZrQV5B",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/7mNihWEjzWv9yCZc8capE4mS8v5Xvp5YH2yQhtZrQV5B/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 661,
  },
  {
    chainId: 101,
    name: "Synex Coin",
    symbol: "MINECRAFT",
    address: "FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 6866,
  },
  {
    chainId: 101,
    name: "SolaLambo",
    symbol: "SOB",
    address: "EkDf4Nt89x4Usnxkj4sGHX7sWxkmmpiBzA4qdDkgEN6b",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkDf4Nt89x4Usnxkj4sGHX7sWxkmmpiBzA4qdDkgEN6b/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 4841,
  },
  {
    chainId: 101,
    name: "Sola Ninja",
    symbol: "SNJ",
    address: "Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolaToken/token-list/ninja/assets/mainnet/Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1797,
  },
  {
    chainId: 101,
    name: "Akamaru Inu",
    symbol: "AINU",
    address: "6H87YFkp5LHyN3KzDXa5r3QYce7WTcwYJi9SqwH3TXkQ",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6H87YFkp5LHyN3KzDXa5r3QYce7WTcwYJi9SqwH3TXkQ/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 636,
  },
  {
    chainId: 101,
    name: "WHALEBE",
    symbol: "WLB",
    address: "6Vg88xUHUPU9MfddHpu2cgx6CdodReiU8eGLPJgyhyVZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Vg88xUHUPU9MfddHpu2cgx6CdodReiU8eGLPJgyhyVZ/logo.png",
    tags: ["token-games", "nft-token"],
    verified: true,
    holders: 2613,
  },
  {
    chainId: 101,
    name: "Shih Tzu",
    symbol: "SHIHT",
    address: "AeTzmX6QcL6tWKMRTKVW9ee8fPmCieKzgfmiU7ZaY4gu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AeTzmX6QcL6tWKMRTKVW9ee8fPmCieKzgfmiU7ZaY4gu/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 174,
  },
  {
    chainId: 101,
    name: "Almond",
    symbol: "ALM",
    address: "ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw/logo.png",
    tags: [],
    verified: true,
    holders: 5260,
  },
  {
    chainId: 101,
    name: "Swole Doge",
    symbol: "SWOLE",
    address: "4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z/logo.png",
    tags: [],
    verified: true,
    holders: 12703,
  },
  {
    chainId: 101,
    name: "NUTS",
    symbol: "NUTS",
    address: "GCxgQbbvJc4UyqGCsUAUa38npzZX27EMxZwckLuWeEkt",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/93886730/140664862-6dd80bff-be30-4c68-a978-fcb205011d61.png",
    tags: [],
    verified: true,
    holders: 6444,
  },
  {
    chainId: 101,
    name: "Spookeletons Token",
    symbol: "SPKL",
    address: "31tCNEE6LiL9yW4Bu153Dq4vi2GuorXxCA9pW9aA6ecU",
    decimals: 9,
    logoURI: "https://www.spookeletons.com/assets/spookeletons_tokenv2.png",
    tags: [],
    verified: true,
    holders: 5536,
  },
  {
    chainId: 101,
    name: "Turtles Token",
    symbol: "TRTLS",
    address: "q4bpaRKw3fJB1AJBeeBaKv3TjYzWsmntLgnSB275YUb",
    decimals: 9,
    logoURI: "https://www.turtles.com/turtles.png",
    tags: [],
    verified: true,
    holders: 5078,
  },
  {
    chainId: 101,
    name: "MapMetrics",
    symbol: "MMaps",
    address: "EFqYVEitSUpUTj2d9LSqun4eZ4BzouFuTPqQzU4hNtsS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EFqYVEitSUpUTj2d9LSqun4eZ4BzouFuTPqQzU4hNtsS/logo.png",
    tags: ["utility-token", "Community", "NFT"],
    verified: true,
    holders: 1009,
  },
  {
    chainId: 101,
    name: "SOLFI",
    symbol: "SOLFI",
    address: "3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1/logo.png",
    tags: [],
    verified: true,
    holders: 1290,
  },
  {
    chainId: 101,
    name: "Bitsol Finance",
    symbol: "BTSL",
    address: "DdUm3gHS5nnB3XKnxEufnazb5ERa9vHjK6S3DgZi7QR5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DdUm3gHS5nnB3XKnxEufnazb5ERa9vHjK6S3DgZi7QR5/logo.png",
    tags: ["DEX"],
    verified: true,
    holders: 4521,
  },
  {
    chainId: 101,
    name: "Banana Bucks",
    symbol: "BAB",
    address: "2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 751,
  },
  {
    chainId: 101,
    name: "SOLife",
    symbol: "SLFT",
    address: "GdRNeX9mbzCt4AnfiUFLbYZmxRuW7pGHfjbbAM59ZybR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdRNeX9mbzCt4AnfiUFLbYZmxRuW7pGHfjbbAM59ZybR/logo.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 1505,
  },
  {
    chainId: 101,
    name: "FUMoney",
    symbol: "FUM",
    address: "EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/FUMoney801/token-list/main/assets/mainnet/EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 19756,
  },
  {
    chainId: 101,
    name: "MILLIONSY",
    symbol: "MILLI",
    address: "HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/millionsy/token-list/main/assets/mainnet/HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr/logo.png",
    tags: [],
    verified: true,
    holders: 12535,
  },
  {
    chainId: 101,
    name: "Aiko",
    symbol: "AIKO",
    address: "7J2cEFFhMCsdUBWssnYfUG5z6zYJYCUaTNPTxH3k9aVY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7J2cEFFhMCsdUBWssnYfUG5z6zYJYCUaTNPTxH3k9aVY/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 402,
  },
  {
    chainId: 101,
    name: "Everest ID (Wormhole v1)",
    symbol: "wID_v1",
    address: "5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 121,
  },
  {
    chainId: 101,
    name: "SOL100",
    symbol: "SOL100",
    address: "GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE/logo.png",
    tags: [],
    verified: true,
    holders: 259,
  },
  {
    chainId: 101,
    name: "Wrapped YFI (Sollet)",
    symbol: "soYFI",
    address: "3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 4832,
  },
  {
    chainId: 101,
    name: "Wrapped Chainlink (Sollet)",
    symbol: "soLINK",
    address: "CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 12527,
  },
  {
    chainId: 101,
    name: "Wrapped XRP (Sollet)",
    symbol: "XRP",
    address: "Ga2AXHpfAF6mv2ekZwcsJFqu7wB4NV331qNH7fW9Nst8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ga2AXHpfAF6mv2ekZwcsJFqu7wB4NV331qNH7fW9Nst8/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 27451,
  },
  {
    chainId: 101,
    name: "Wrapped USDT (Sollet)",
    symbol: "soUSDT",
    address: "BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png",
    tags: ["stablecoin", "wrapped-sollet", "ethereum"],
    verified: true,
    holders: 173830,
  },
  {
    chainId: 101,
    name: "Wrapped SUSHI (Sollet)",
    symbol: "soSUSHI",
    address: "AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 8986,
  },
  {
    chainId: 101,
    name: "Wrapped ALEPH (Sollet)",
    symbol: "soALEPH",
    address: "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 11297,
  },
  {
    chainId: 101,
    name: "Wrapped SXP (Sollet)",
    symbol: "soSXP",
    address: "SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 3298,
  },
  {
    chainId: 101,
    name: "Wrapped Cream Finance (Sollet)",
    symbol: "soCREAM",
    address: "5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 1863,
  },
  {
    chainId: 101,
    name: "Wrapped Upbots (Sollet)",
    symbol: "soUBXT",
    address: "873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 3522,
  },
  {
    chainId: 101,
    name: "Wrapped Helium (Sollet)",
    symbol: "soHNT",
    address: "HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 2277,
  },
  {
    chainId: 101,
    name: "Wrapped FRONT (Sollet)",
    symbol: "soFRONT",
    address: "9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 2443,
  },
  {
    chainId: 101,
    name: "Wrapped AKRO (Sollet)",
    symbol: "soAKRO",
    address: "6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 2444,
  },
  {
    chainId: 101,
    name: "Wrapped UNI (Sollet)",
    symbol: "soUNI",
    address: "DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 3964,
  },
  {
    chainId: 101,
    name: "MegaSerum",
    symbol: "MSRM",
    address: "MSRMcoVyrFxnSgo5uXwone5SKcGhT1KEJMFEkMEWf9L",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MSRMcoVyrFxnSgo5uXwone5SKcGhT1KEJMFEkMEWf9L/logo.png",
    tags: [],
    verified: true,
    holders: 3849,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Sollet)",
    symbol: "soUSDC",
    address: "BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
    tags: ["stablecoin", "wrapped-sollet", "ethereum"],
    verified: true,
    holders: 40887,
  },
  {
    chainId: 101,
    name: "Wrapped TOMO (Sollet)",
    symbol: "soTOMO",
    address: "GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 3163,
  },
  {
    chainId: 101,
    name: "Wrapped LUA (Sollet)",
    symbol: "soLUA",
    address: "EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 1263,
  },
  {
    chainId: 101,
    name: "Wrapped KEEP (Sollet)",
    symbol: "soKEEP",
    address: "GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 747,
  },
  {
    chainId: 101,
    name: "Wrapped Celsius (Sollet)",
    symbol: "soCEL",
    address: "DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 524,
  },
  {
    chainId: 101,
    name: "Wrapped Reserve Rights (Sollet)",
    symbol: "soRSR",
    address: "7ncCLJpP3MNww17LW8bRvx8odQQnubNtfNZBL5BgAEHW",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ncCLJpP3MNww17LW8bRvx8odQQnubNtfNZBL5BgAEHW/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 347,
  },
  {
    chainId: 101,
    name: "Wrapped GRT (Sollet)",
    symbol: "soGRT",
    address: "38i2NQxjp5rt5B3KogqrxmBxgrAwaB3W1f1GmiKqh9MS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38i2NQxjp5rt5B3KogqrxmBxgrAwaB3W1f1GmiKqh9MS/logo.png",
    tags: ["wrapped-sollet", "ethereum"],
    verified: true,
    holders: 321,
  },
  {
    chainId: 101,
    name: "BNBBULL",
    symbol: "BNBBULL",
    address: "AfjHjdLibuXyvmz7PyTSc5KEcGBh43Kcu8Sr2tyDaJyt",
    decimals: 6,
    logoURI: null,
    tags: ["leveraged", "bull"],
    verified: true,
    holders: 255,
  },
  {
    chainId: 101,
    name: "BULL",
    symbol: "BULL",
    address: "GkSPaHdY2raetuYzsJYacHtrAtQUfWt64bpd1VzxJgSD",
    decimals: 6,
    logoURI: null,
    tags: ["leveraged", "bull"],
    verified: true,
    holders: 311,
  },
  {
    chainId: 101,
    name: "ETHBULL",
    symbol: "ETHBULL",
    address: "CwChm6p9Q3yFrjzVeiLTTbsoJkooscof5SJYZc2CrNqG",
    decimals: 6,
    logoURI: null,
    tags: ["leveraged", "bull"],
    verified: true,
    holders: 250,
  },
  {
    chainId: 101,
    name: "IBlive",
    symbol: "IBVOL",
    address: "5TY71D29Cyuk9UrsSxLXw2quJBpS7xDDFuFu2K9W7Wf9",
    decimals: 6,
    logoURI: null,
    tags: [],
    verified: true,
    holders: 168,
  },
  {
    chainId: 101,
    name: "Wrapped Aave (Sollet)",
    symbol: "soAAVE",
    address: "dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc/logo.png",
    tags: [],
    verified: true,
    holders: 862,
  },
  {
    chainId: 101,
    name: "PERP",
    symbol: "PERP",
    address: "D68NB5JkzvyNCZAvi6EGtEcGvSoRNPanU9heYTAUFFRa",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D68NB5JkzvyNCZAvi6EGtEcGvSoRNPanU9heYTAUFFRa/logo.png",
    tags: [],
    verified: true,
    holders: 198,
  },
  {
    chainId: 101,
    name: "Bonfida Ray Pool",
    symbol: "RAYPOOL",
    address: "4fiysjiegD8yoqHX75YXBvhBzmaUEQhyuAVRX8fGL3F1",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/93a1L7xaEV7vZGzNXCcb9ztZedbpKgUiTHYxmFKJwKvc/logo.png",
    tags: [],
    verified: true,
    holders: 381,
  },
  {
    chainId: 101,
    name: "Wrapped Ether (Wormhole v1)",
    symbol: "wWETH_v1",
    address: "FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 1249,
  },
  {
    chainId: 101,
    name: "Wrapped Capsule Coin (Wormhole v1)",
    symbol: "wCAPS_v1",
    address: "FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T/logo.svg",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 1636,
  },
  {
    chainId: 101,
    name: "FTT (Wormhole v1)",
    symbol: "wFTT_v1",
    address: "GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 3710,
  },
  {
    chainId: 101,
    name: "1INCH Token (Portal)",
    symbol: "1INCH",
    address: "AjkPkq3nsyDe1yKcbyZT7N4aK4Evv9om9tzhQD3wsRC",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AjkPkq3nsyDe1yKcbyZT7N4aK4Evv9om9tzhQD3wsRC/logo.png",
    tags: ["wrapped", "ethereum"],
    verified: true,
    holders: 308,
  },
  {
    chainId: 101,
    name: "HEX (Wormhole v1)",
    symbol: "wHEX_v1",
    address: "ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 443,
  },
  {
    chainId: 101,
    name: "Dai Stablecoin (Wormhole v1)",
    symbol: "wDAI_v1",
    address: "FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 1248,
  },
  {
    chainId: 101,
    name: "yPRT (Parrot Yield Token)",
    symbol: "yPRT",
    address: "yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM/logo.svg",
    tags: [],
    verified: true,
    holders: 5887,
  },
  {
    chainId: 101,
    name: "Frax (Wormhole v1)",
    symbol: "wFRAX_v1",
    address: "8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 737,
  },
  {
    chainId: 101,
    name: "USD Coin (Wormhole v1)",
    symbol: "wUSDC_v1",
    address: "FVsXUnbhifqJ4LiXQEbpUtXVdB8T5ADLKqSs5t1oc54F",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FVsXUnbhifqJ4LiXQEbpUtXVdB8T5ADLKqSs5t1oc54F/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 249,
  },
  {
    chainId: 101,
    name: "Wrapped LUNA Token (Wormhole v1)",
    symbol: "wLUNA_v1",
    address: "2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 1919,
  },
  {
    chainId: 101,
    name: "Tether USD (Wormhole v1)",
    symbol: "wUSDT_v1",
    address: "9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 280,
  },
  {
    chainId: 101,
    name: "Binance USD (Wormhole v1)",
    symbol: "wBUSD_v1",
    address: "AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 1250,
  },
  {
    chainId: 101,
    name: "Dominican Pesos",
    symbol: "oDOP",
    address: "4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 847,
  },
  {
    chainId: 101,
    name: "XCOPE",
    symbol: "XCOPE",
    address: "3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE/logo.png",
    tags: ["trading", "index", "Algos"],
    verified: true,
    holders: 39063,
  },
  {
    chainId: 101,
    name: "Mango Market Caps",
    symbol: "MCAPS",
    address: "2prC8tcVsXwVJAinhxd2zeMeWMWaVyzPoQeLKyDZRFKd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2prC8tcVsXwVJAinhxd2zeMeWMWaVyzPoQeLKyDZRFKd/logo.png",
    tags: ["mango"],
    verified: true,
    holders: 1539,
  },
  {
    chainId: 101,
    name: "NoGoalToken",
    symbol: "INO",
    address: "E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA/logo.png",
    tags: [],
    verified: true,
    holders: 27509,
  },
  {
    chainId: 101,
    name: "Staked Step",
    symbol: "xSTEP",
    address: "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 3562,
  },
  {
    chainId: 101,
    name: "QUEST",
    symbol: "QUEST",
    address: "6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1/logo.png",
    tags: ["DeFi", "GameFi", "NFT", "Metaverse"],
    verified: true,
    holders: 735,
  },
  {
    chainId: 101,
    name: "Gangs of Solana",
    symbol: "GANGS",
    address: "HcJCPYck2UsTMgiPfjn6CS1wrC5iBXtuqPSjt8Qy8Sou",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HcJCPYck2UsTMgiPfjn6CS1wrC5iBXtuqPSjt8Qy8Sou/logo.svg",
    tags: [],
    verified: true,
    holders: 1389,
  },
  {
    chainId: 101,
    name: "Pom Token",
    symbol: "POM",
    address: "ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG/logo.png",
    tags: [],
    verified: true,
    holders: 2517,
  },
  {
    chainId: 101,
    name: "Discovery of Iris",
    symbol: "DOI",
    address: "HAWy8kV3bD4gaN6yy6iK2619x2dyzLUBj1PfJiihTisE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAWy8kV3bD4gaN6yy6iK2619x2dyzLUBj1PfJiihTisE/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 11340,
  },
  {
    chainId: 101,
    name: "The Heart of Star Atlas",
    symbol: "HOSA",
    address: "ATSPo9f9TJ3Atx8SuoTYdzSMh4ctQBzYzDiNukQDmoF7",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATSPo9f9TJ3Atx8SuoTYdzSMh4ctQBzYzDiNukQDmoF7/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 8989,
  },
  {
    chainId: 101,
    name: "The Convergence War",
    symbol: "TCW",
    address: "36s6AFRXzE9KVdUyoJQ5y6mwxXw21LawYqqwNiQUMD8s",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36s6AFRXzE9KVdUyoJQ5y6mwxXw21LawYqqwNiQUMD8s/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 5996,
  },
  {
    chainId: 101,
    name: "Short Story of a Lost Astronaut",
    symbol: "LOST",
    address: "BgiTVxW9uLuHHoafTd2qjYB5xjCc5Y1EnUuYNfmTwhvp",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BgiTVxW9uLuHHoafTd2qjYB5xjCc5Y1EnUuYNfmTwhvp/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 2224,
  },
  {
    chainId: 101,
    name: "B ❤ P",
    symbol: "LOVE",
    address: "4G85c5aUsRTrRPqE5VjY7ebD9b2ktTF6NEVGiCddRBDX",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4G85c5aUsRTrRPqE5VjY7ebD9b2ktTF6NEVGiCddRBDX/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 1720,
  },
  {
    chainId: 101,
    name: "The Assassination of Paizul",
    symbol: "MRDR",
    address: "7dr7jVyXf1KUnYq5FTpV2vCZjKRR4MV94jzerb8Fi16Q",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dr7jVyXf1KUnYq5FTpV2vCZjKRR4MV94jzerb8Fi16Q/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 446,
  },
  {
    chainId: 101,
    name: "Paizul Funeral Procession",
    symbol: "PFP",
    address: "G1bE9ge8Yoq43hv7QLcumxTFhHqFMdcL4y2d6ZdzMG4b",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1bE9ge8Yoq43hv7QLcumxTFhHqFMdcL4y2d6ZdzMG4b/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 310,
  },
  {
    chainId: 101,
    name: "Ahr Visits Earth",
    symbol: "AVE",
    address: "6bD8mr8DyuVqN5dXd1jnqmCL66b5KUV14jYY1HSmnxTE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6bD8mr8DyuVqN5dXd1jnqmCL66b5KUV14jYY1HSmnxTE/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 308,
  },
  {
    chainId: 101,
    name: "Armstrong Forever",
    symbol: "ASF",
    address: "9vi6PTKBFHR2hXgyjoTZx6h7WXNkFAA5dCsZRSi4higK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vi6PTKBFHR2hXgyjoTZx6h7WXNkFAA5dCsZRSi4higK/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 3426,
  },
  {
    chainId: 101,
    name: "The Last Stand",
    symbol: "TLS",
    address: "Hfjgcs9ix17EwgXVVbKjo6NfMm2CXfr34cwty3xWARUm",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hfjgcs9ix17EwgXVVbKjo6NfMm2CXfr34cwty3xWARUm/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 133,
  },
  {
    chainId: 101,
    name: "The Signing of the Peace Treaty",
    symbol: "SPT",
    address: "8EXX5kG7qWTjgpNSGX7PnB6hJZ8xhXUcCafVJaBEJo32",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EXX5kG7qWTjgpNSGX7PnB6hJZ8xhXUcCafVJaBEJo32/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 147,
  },
  {
    chainId: 101,
    name: "The Peacebringers Archive",
    symbol: "PBA",
    address: "CAjoJeGCCRae9oDwHYXzkeUDonp3dZLWV5GKHysLwjnx",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAjoJeGCCRae9oDwHYXzkeUDonp3dZLWV5GKHysLwjnx/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 131,
  },
  {
    chainId: 101,
    name: "Ustur Wod.bod",
    symbol: "UWB",
    address: "FPnwwNiL1tXqd4ZbGjFYsCw5qsQw91VN79SNcU4Bc732",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FPnwwNiL1tXqd4ZbGjFYsCw5qsQw91VN79SNcU4Bc732/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 122,
  },
  {
    chainId: 101,
    name: "Om Photoli",
    symbol: "OMPH",
    address: "DB76aiNQeLzHPwvFhzgwfpe6HGHCDTQ6snW6UD7AnHid",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DB76aiNQeLzHPwvFhzgwfpe6HGHCDTQ6snW6UD7AnHid/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 171,
  },
  {
    chainId: 101,
    name: "Star Atlas - The Movie",
    symbol: "SATM",
    address: "BrzwWsG845VttbTsacZMLKhyc2jAZU12MaPkTYrJHoqm",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BrzwWsG845VttbTsacZMLKhyc2jAZU12MaPkTYrJHoqm/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 177,
  },
  {
    chainId: 101,
    name: "NOPE FINANCE",
    symbol: "NOPE",
    address: "nope9HWCJcXVFkG49CDk7oYFtgGsUzsRvHdcJeL2aCL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nope9HWCJcXVFkG49CDk7oYFtgGsUzsRvHdcJeL2aCL/logo.png",
    tags: [],
    verified: true,
    holders: 3908,
  },
  {
    chainId: 101,
    name: "Stonks",
    symbol: "STNK",
    address: "43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We/logo.png",
    tags: [],
    verified: true,
    holders: 26324,
  },
  {
    chainId: 101,
    name: "Holdana",
    symbol: "HOLD",
    address: "8GQsW3f7mdwfjqJon2myADcBsSsRjpXmxHYDG8q1pvV6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GQsW3f7mdwfjqJon2myADcBsSsRjpXmxHYDG8q1pvV6/logo.png",
    tags: [],
    verified: true,
    holders: 6075,
  },
  {
    chainId: 101,
    name: "wen-token",
    symbol: "wen-token",
    address: "64SqEfHtu4bZ6jr1mAxaWrLFdMngbKbru9AyaG2Dyk5T",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64SqEfHtu4bZ6jr1mAxaWrLFdMngbKbru9AyaG2Dyk5T/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 1185,
  },
  {
    chainId: 101,
    name: "FROG",
    symbol: "FROG",
    address: "Amt5wUJREJQC5pX7Z48YSK812xmu4j3sQVupNhtsEuY8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Amt5wUJREJQC5pX7Z48YSK812xmu4j3sQVupNhtsEuY8/logo.png",
    tags: [],
    verified: true,
    holders: 16875,
  },
  {
    chainId: 101,
    name: "CARROT",
    symbol: "CRT",
    address: "9Y8NT5HT9z2EsmCbYMgKXPRq3h3aa6tycEqfFiXjfZM7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Y8NT5HT9z2EsmCbYMgKXPRq3h3aa6tycEqfFiXjfZM7/logo.png",
    tags: [],
    verified: true,
    holders: 5815,
  },
  {
    chainId: 101,
    name: "SKEM",
    symbol: "SKEM",
    address: "3x7UeXDF4imKSKnizK9mYyx1M5bTNzpeALfPeB8S6XT9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3x7UeXDF4imKSKnizK9mYyx1M5bTNzpeALfPeB8S6XT9/logo.svg",
    tags: [],
    verified: true,
    holders: 4543,
  },
  {
    chainId: 101,
    name: "FOMO",
    symbol: "FOMO",
    address: "92ibHPyfcwQnsscwkutSP7f9yWzz6GaVHu78gyQ37L1i",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/92ibHPyfcwQnsscwkutSP7f9yWzz6GaVHu78gyQ37L1i/logo.png",
    tags: [],
    verified: true,
    holders: 152,
  },
  {
    chainId: 101,
    name: "GLUB Token",
    symbol: "GLUB",
    address: "Rs3Mrx9ZSNwsTM3gtJhEbeHQdTfzcvUWHg8T8Ena3pD",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Rs3Mrx9ZSNwsTM3gtJhEbeHQdTfzcvUWHg8T8Ena3pD/logo.png",
    tags: [],
    verified: true,
    holders: 1653,
  },
  {
    chainId: 101,
    name: "ACUMEN",
    symbol: "ACMN",
    address: "9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp/logo.png",
    tags: [],
    verified: true,
    holders: 1224,
  },
  {
    chainId: 101,
    name: "Crazy Ape Coin",
    symbol: "CAPE",
    address: "GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco/logo.png",
    tags: [],
    verified: true,
    holders: 4928,
  },
  {
    chainId: 101,
    name: "Bole Token",
    symbol: "BOLE",
    address: "7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt/logo.png",
    tags: [],
    verified: true,
    holders: 8231,
  },
  {
    chainId: 101,
    name: "SolMiner Bronze",
    symbol: "mBRZ",
    address: "Bxp46xCB6CLjiqE99QaTcJAaY1hYF1o63DUUrXAS7QFu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bxp46xCB6CLjiqE99QaTcJAaY1hYF1o63DUUrXAS7QFu/logo.png",
    tags: [],
    verified: true,
    holders: 2164,
  },
  {
    chainId: 101,
    name: "SolMiner Platinum",
    symbol: "mPLAT",
    address: "GZNrMEdrt6Vg428JzvJYRGGPpVxgjUPsg6WLqKBvmNLw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZNrMEdrt6Vg428JzvJYRGGPpVxgjUPsg6WLqKBvmNLw/logo.png",
    tags: [],
    verified: true,
    holders: 1069,
  },
  {
    chainId: 101,
    name: "SolMiner Diamond",
    symbol: "mDIAM",
    address: "Er7a3ugS6kkAqj6sp3UmXEFAFrDdLMRQEkV9QH2fwRYA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Er7a3ugS6kkAqj6sp3UmXEFAFrDdLMRQEkV9QH2fwRYA/logo.png",
    tags: [],
    verified: true,
    holders: 658,
  },
  {
    chainId: 101,
    name: "Shibaverse",
    symbol: "$SHIVER",
    address: "A3iozx9T9wgrtybnecQ9rv56y9RF8ThUrwRGWiF7hsmZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3iozx9T9wgrtybnecQ9rv56y9RF8ThUrwRGWiF7hsmZ/logo.png",
    tags: [],
    verified: true,
    holders: 609,
  },
  {
    chainId: 101,
    name: "Muskimum Impact Token",
    symbol: "MIT",
    address: "ERPueLaiBW48uBhqX1CvCYBv2ApHN6ZFuME1MeQGTdAi",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ERPueLaiBW48uBhqX1CvCYBv2ApHN6ZFuME1MeQGTdAi/logo.png",
    tags: ["mit", "musk"],
    verified: true,
    holders: 2475,
  },
  {
    chainId: 101,
    name: "SolaPAD Token (deprecated)",
    symbol: "SOLA",
    address: "BsDrXiQaFd147Fxq1fQYbJQ77P6tmPkRJQJzkKvspDKo",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BsDrXiQaFd147Fxq1fQYbJQ77P6tmPkRJQJzkKvspDKo/logo.png",
    tags: ["SOLA", "LaunchPAD"],
    verified: true,
    holders: 13389,
  },
  {
    chainId: 101,
    name: "Shoebill Coin",
    symbol: "SHBL",
    address: "7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E/logo.png",
    tags: [],
    verified: true,
    holders: 6202,
  },
  {
    chainId: 101,
    name: "Ausshole",
    symbol: "AUSS",
    address: "Djoz8btdR7p6xWHoVtPYF3zyN9LU5BBfMoDk4HczSDqc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Djoz8btdR7p6xWHoVtPYF3zyN9LU5BBfMoDk4HczSDqc/logo.svg",
    tags: [],
    verified: true,
    holders: 4046,
  },
  {
    chainId: 101,
    name: "wTYNA",
    symbol: "TYNA",
    address: "3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs/logo.png",
    tags: ["ERC20", "ethereum"],
    verified: true,
    holders: 142,
  },
  {
    chainId: 101,
    name: "SolShib",
    symbol: "SSHIB",
    address: "7zphtJVjKyECvQkdfxJNPx83MNpPT6ZJyujQL8jyvKcC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zphtJVjKyECvQkdfxJNPx83MNpPT6ZJyujQL8jyvKcC/logo.png",
    tags: [],
    verified: true,
    holders: 3413,
  },
  {
    chainId: 101,
    name: "SolGift",
    symbol: "SGI",
    address: "HoSWnZ6MZzqFruS1uoU69bU7megzHUv6MFPQ5nqC6Pj2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HoSWnZ6MZzqFruS1uoU69bU7megzHUv6MFPQ5nqC6Pj2/logo.png",
    tags: [],
    verified: true,
    holders: 214,
  },
  {
    chainId: 101,
    name: "Soltriever",
    symbol: "SOLT",
    address: "GpS9AavHtSUspaBnL1Tu26FWbUAdW8tm3MbacsNvwtGu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpS9AavHtSUspaBnL1Tu26FWbUAdW8tm3MbacsNvwtGu/logo.png",
    tags: [],
    verified: true,
    holders: 2066,
  },
  {
    chainId: 101,
    name: "kekwcoin",
    symbol: "KEKW",
    address: "2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG/logo.png",
    tags: [],
    verified: true,
    holders: 15013,
  },
  {
    chainId: 101,
    name: "POWER COIN",
    symbol: "POCO",
    address: "qs9Scx8YwNXS6zHYPCnDnyHQcRHg3QwXxpyCXs5tdM8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qs9Scx8YwNXS6zHYPCnDnyHQcRHg3QwXxpyCXs5tdM8/logo.png",
    tags: ["social-token", "poco"],
    verified: true,
    holders: 429,
  },
  {
    chainId: 101,
    name: "MemeMarketplace",
    symbol: "MEME",
    address: "6DNkUoMa6vNo3CsxAw5XMJhjmdPbPBENHJ6w35eMXESo",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DNkUoMa6vNo3CsxAw5XMJhjmdPbPBENHJ6w35eMXESo/logo.png",
    tags: [],
    verified: true,
    holders: 8717,
  },
  {
    chainId: 101,
    name: "Cute Baby Cat Coin",
    symbol: "CCAT",
    address: "95GBBtPvFotNSLDuM4DYTukZhxptRbJjW7dMH45WMS2N",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95GBBtPvFotNSLDuM4DYTukZhxptRbJjW7dMH45WMS2N/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 6509,
  },
  {
    chainId: 101,
    name: "Dogana",
    symbol: "DOGA",
    address: "EdGAZ8JyFTFbmVedVTbaAEQRb6bxrvi3AW3kz8gABz2E",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdGAZ8JyFTFbmVedVTbaAEQRb6bxrvi3AW3kz8gABz2E/logo.png",
    tags: [],
    verified: true,
    holders: 7783,
  },
  {
    chainId: 101,
    name: "SBF Coin",
    symbol: "SBFC",
    address: "AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx/logo.png",
    tags: ["utility-token", "SBF", "sbfcoin", "SBFC"],
    verified: true,
    holders: 1745,
  },
  {
    chainId: 101,
    name: "EcoPoo",
    symbol: "ECOP",
    address: "FRbqQnbuLoMbUG4gtQMeULgCDHyY6YWF9NRUuLa98qmq",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRbqQnbuLoMbUG4gtQMeULgCDHyY6YWF9NRUuLa98qmq/logo.png",
    tags: ["meme"],
    verified: true,
    holders: 21350,
  },
  {
    chainId: 101,
    name: "Solanadon",
    symbol: "SLNDN",
    address: "HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s/logo.png",
    tags: [],
    verified: true,
    holders: 4678,
  },
  {
    chainId: 101,
    name: "KEKW In Solana Tripping",
    symbol: "KEKN1",
    address: "5WUab7TCvth43Au5vk6wKjchTzWFeyPEUSJE1MPJtTZE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WUab7TCvth43Au5vk6wKjchTzWFeyPEUSJE1MPJtTZE/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 1925,
  },
  {
    chainId: 101,
    name: "OLDNINJA",
    symbol: "OLDNINJA",
    address: "Ae1aeYK9WrB2kP29jJU4aUUK7Y1vzsGNZFKoe4BG2h6P",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ae1aeYK9WrB2kP29jJU4aUUK7Y1vzsGNZFKoe4BG2h6P/logo.png",
    tags: [],
    verified: true,
    holders: 2288,
  },
  {
    chainId: 101,
    name: "SOLLAMA",
    symbol: "LLAMA",
    address: "9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv/logo.png",
    tags: [],
    verified: true,
    holders: 11759,
  },
  {
    chainId: 101,
    name: "ChadTrader Token",
    symbol: "CHAD",
    address: "5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1996,
  },
  {
    chainId: 101,
    name: "Plutonium",
    symbol: "PLUTES",
    address: "BYNHheaKFX2WRGQTpMZNsM6vAyJXvkeMoMcixKfVKxY9",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYNHheaKFX2WRGQTpMZNsM6vAyJXvkeMoMcixKfVKxY9/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 274,
  },
  {
    chainId: 101,
    name: "Kermit",
    symbol: "KERMIT",
    address: "7xzovRepzLvXbbpVZLYKzEBhCNgStEv1xpDqf1rMFFKX",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xzovRepzLvXbbpVZLYKzEBhCNgStEv1xpDqf1rMFFKX/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 8371,
  },
  {
    chainId: 101,
    name: "TurtleTraders",
    symbol: "TUTL",
    address: "3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH/logo.png",
    tags: ["social-token", "Turtles"],
    verified: true,
    holders: 1133,
  },
  {
    chainId: 101,
    name: "Pipana",
    symbol: "PIPANA",
    address: "8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe/logo.png",
    tags: [],
    verified: true,
    holders: 251,
  },
  {
    chainId: 101,
    name: "NORA",
    symbol: "NRA",
    address: "1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 6121,
  },
  {
    chainId: 101,
    name: "Gamestonks",
    symbol: "GSTONKS",
    address: "BrwgXmUtNd32dTKdP5teie68EmBnjGq8Wp3MukHehUBY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BrwgXmUtNd32dTKdP5teie68EmBnjGq8Wp3MukHehUBY/logo.png",
    tags: [],
    verified: true,
    holders: 1273,
  },
  {
    chainId: 101,
    name: "SOL-CATS",
    symbol: "MEOW",
    address: "HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8/logo.png",
    tags: [],
    verified: true,
    holders: 17572,
  },
  {
    chainId: 101,
    name: "SolMoon",
    symbol: "SOLMO",
    address: "Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983/logo.png",
    tags: [],
    verified: true,
    holders: 12825,
  },
  {
    chainId: 101,
    name: "MasterCoin",
    symbol: "MSC",
    address: "2wBXHm4oxmed7ZoDkPL4DU8BuRfMYkubVu8T4N38vXdb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wBXHm4oxmed7ZoDkPL4DU8BuRfMYkubVu8T4N38vXdb/logo.png",
    tags: [],
    verified: true,
    holders: 1765,
  },
  {
    chainId: 101,
    name: "Wolfecoin",
    symbol: "WOLFE",
    address: "3KnVxWhoYdc9UwDr5WMVkZp2LpF7gnojg7We7MUd6ixQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KnVxWhoYdc9UwDr5WMVkZp2LpF7gnojg7We7MUd6ixQ/logo.png",
    tags: [],
    verified: true,
    holders: 708,
  },
  {
    chainId: 101,
    name: "PigeonSol Token",
    symbol: "PGNT",
    address: "BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG/logo.png",
    tags: [],
    verified: true,
    holders: 6405,
  },
  {
    chainId: 101,
    name: "Black Label",
    symbol: "KLB",
    address: "4NPzwMK2gfgQ6rTv8x4EE1ZvKW6MYyYTSrAZCx7zxyaX",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4NPzwMK2gfgQ6rTv8x4EE1ZvKW6MYyYTSrAZCx7zxyaX/logo.svg",
    tags: [],
    verified: true,
    holders: 1290,
  },
  {
    chainId: 101,
    name: "Solanax",
    symbol: "SOLD",
    address: "5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61/logo.png",
    tags: [],
    verified: true,
    holders: 6709,
  },
  {
    chainId: 101,
    name: "Orca Guppy Collectible",
    symbol: "GUPPY",
    address: "guppyrZyEX9iTPSu92pi8T71Zka7xd6PrsTJrXRW6u1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/guppyrZyEX9iTPSu92pi8T71Zka7xd6PrsTJrXRW6u1/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 11756,
  },
  {
    chainId: 101,
    name: "Orca Starfish Collectible",
    symbol: "STARFISH",
    address: "star2pH7rVWscs743JGdCAL8Lc9nyJeqx7YQXkGUnWf",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/star2pH7rVWscs743JGdCAL8Lc9nyJeqx7YQXkGUnWf/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 8702,
  },
  {
    chainId: 101,
    name: "Orca Porpoise Collectible",
    symbol: "PORPOISE",
    address: "porpKs9ZZERXKkg55f1GRXCiXZK89Uz6VKS8Bv9qWqM",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/porpKs9ZZERXKkg55f1GRXCiXZK89Uz6VKS8Bv9qWqM/logo.svg",
    tags: ["nft"],
    verified: true,
    holders: 1455,
  },
  {
    chainId: 101,
    name: "SolNinja-go",
    symbol: "SNG",
    address: "BiJyWQr1Gpke3ouevgGCjtd9sSwSiUxdpnpGvJaoGQNL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiJyWQr1Gpke3ouevgGCjtd9sSwSiUxdpnpGvJaoGQNL/logo.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 390,
  },
  {
    chainId: 101,
    name: "Future",
    symbol: "FTR",
    address: "HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s/logo.png",
    tags: [],
    verified: true,
    holders: 22203,
  },
  {
    chainId: 101,
    name: "renBTC",
    symbol: "renBTC",
    address: "CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5/logo.png",
    tags: [],
    verified: true,
    holders: 5859,
  },
  {
    chainId: 101,
    name: "renDOGE",
    symbol: "renDOGE",
    address: "ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU/logo.png",
    tags: [],
    verified: true,
    holders: 1201,
  },
  {
    chainId: 101,
    name: "renLUNA",
    symbol: "renLUNA",
    address: "8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE/logo.png",
    tags: [],
    verified: true,
    holders: 5625,
  },
  {
    chainId: 101,
    name: "SAIL",
    symbol: "SAIL",
    address: "6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 9847,
  },
  {
    chainId: 101,
    name: "Snek Coin",
    symbol: "SNEK",
    address: "7LmGzEgnQZTxxeCThgxsv3xe4JQmiy9hxEGBPCF66KgH",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7LmGzEgnQZTxxeCThgxsv3xe4JQmiy9hxEGBPCF66KgH/logo.png",
    tags: [],
    verified: true,
    holders: 6707,
  },
  {
    chainId: 101,
    name: "PizzaPugCoin",
    symbol: "PPUG",
    address: "E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT/logo.png",
    tags: [],
    verified: true,
    holders: 5104,
  },
  {
    chainId: 101,
    name: "VentureCapital",
    symbol: "VCC",
    address: "FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb/logo.svg",
    tags: ["liquidator", "IDO", "incubator"],
    verified: true,
    holders: 9113,
  },
  {
    chainId: 101,
    name: "Oxbull Sol",
    symbol: "OXS",
    address: "4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 46246,
  },
  {
    chainId: 101,
    name: "Twirl Governance Token",
    symbol: "TGT",
    address: "FciGvHj9FjgSGgCBF1b9HY814FM9D28NijDd5SJrKvPo",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FciGvHj9FjgSGgCBF1b9HY814FM9D28NijDd5SJrKvPo/logo.png",
    tags: [],
    verified: true,
    holders: 2624,
  },
  {
    chainId: 101,
    name: "JOKESMEMES",
    symbol: "JOKE",
    address: "8NGgmXzBzhsXz46pTC3ioSBxeE3w2EXpc741N3EQ8E6r",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NGgmXzBzhsXz46pTC3ioSBxeE3w2EXpc741N3EQ8E6r/logo.png",
    tags: [],
    verified: true,
    holders: 8400,
  },
  {
    chainId: 101,
    name: "Gachapon",
    symbol: "GACHA",
    address: "GACHAfpmbpk4FLfZcGkT2NUmaEqMygssAknhqnn8DVHP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GACHAfpmbpk4FLfZcGkT2NUmaEqMygssAknhqnn8DVHP/logo.png",
    tags: [],
    verified: true,
    holders: 280,
  },
  {
    chainId: 101,
    name: "Space Hamster",
    symbol: "HAMS",
    address: "A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb/logo.png",
    tags: [],
    verified: true,
    holders: 1567,
  },
  {
    chainId: 101,
    name: "Solcubator",
    symbol: "SOLC",
    address: "Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT/logo.png",
    tags: [],
    verified: true,
    holders: 9518,
  },
  {
    chainId: 101,
    name: "BladesToken",
    symbol: "BLD",
    address: "DrcPRJPBiakQcWqon3gZms7sviAqdQS5zS5wvaG5v6wu",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DrcPRJPBiakQcWqon3gZms7sviAqdQS5zS5wvaG5v6wu/logo.png",
    tags: [],
    verified: true,
    holders: 1862,
  },
  {
    chainId: 101,
    name: "DefiXBet Token",
    symbol: "DXB",
    address: "8mgeCL7k2cB2KTN8NhD5biqvcbkfrmBogDpYoHmn5cKQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5GG1LbgY4EEvPR51YQPNr65QKcZemrHWPooTqC5gRPBA/logo.png",
    tags: [],
    verified: true,
    holders: 267,
  },
  {
    chainId: 101,
    name: "Luna Yield",
    symbol: "LUNY",
    address: "7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf/logo.png",
    tags: [],
    verified: true,
    holders: 1907,
  },
  {
    chainId: 101,
    name: "SolYard Finance Beta",
    symbol: "YARDv1",
    address: "AP58G14hoy4GGgZS4L8TzZgqXnk3hBvciFKW2Cb1RQ2J",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AP58G14hoy4GGgZS4L8TzZgqXnk3hBvciFKW2Cb1RQ2J/logo.png",
    tags: [],
    verified: true,
    holders: 3349,
  },
  {
    chainId: 101,
    name: "PARTI",
    symbol: "PARTI",
    address: "B3Ggjjj3QargPkFTAJiR6BaD8CWKFUaWRXGcDQ1nyeeD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3Ggjjj3QargPkFTAJiR6BaD8CWKFUaWRXGcDQ1nyeeD/logo.png",
    tags: [],
    verified: true,
    holders: 7840,
  },
  {
    chainId: 101,
    name: "Coinmeca",
    symbol: "MECA",
    address: "8kRacWW5qZ34anyH8s9gu2gC4FpXtncqBDPpd2a6DnZE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kRacWW5qZ34anyH8s9gu2gC4FpXtncqBDPpd2a6DnZE/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 4234,
  },
  {
    chainId: 101,
    name: "gSAIL DEPRECATED",
    symbol: "XgSAIL",
    address: "GSaiLQxREzaxUcE3v28HxBacoUQPZNtXx1eQsCFsX9Bg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GSaiLQxREzaxUcE3v28HxBacoUQPZNtXx1eQsCFsX9Bg/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2337,
  },
  {
    chainId: 101,
    name: "METASEER",
    symbol: "METAS",
    address: "3Ztt53vwGhQGoEp3n1RjSu4CFnGRfqzwo6L8KN8gmXfd",
    decimals: 9,
    logoURI: "https://metaseer.io/img/home-one/logo256.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2554,
  },
  {
    chainId: 101,
    name: "Orbs",
    symbol: "Orbs",
    address: "7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 700,
  },
  {
    chainId: 101,
    name: "SOLBERRY",
    symbol: "SOLBERRY",
    address: "EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz/logo.png",
    tags: [],
    verified: true,
    holders: 4902,
  },
  {
    chainId: 101,
    name: "Care Coin Token",
    symbol: "CRC",
    address: "z9WZXekbCtwoxyfAwEJn1euXybvqLzPVv3NDzJzkq7C",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z9WZXekbCtwoxyfAwEJn1euXybvqLzPVv3NDzJzkq7C/logo.png",
    tags: [],
    verified: true,
    holders: 1128,
  },
  {
    chainId: 101,
    name: "Viloid Coin",
    symbol: "VLDC",
    address: "FqJE1neoCJrRwxfC9mRL6FduuZ1gCX2FUbya5hi8EQgA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FqJE1neoCJrRwxfC9mRL6FduuZ1gCX2FUbya5hi8EQgA/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1115,
  },
  {
    chainId: 101,
    name: "Lumos Rewards",
    symbol: "LRA",
    address: "FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 5503,
  },
  {
    chainId: 101,
    name: "HBTC (Wormhole v1)",
    symbol: "wHBTC_v1",
    address: "8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 408,
  },
  {
    chainId: 101,
    name: "HUSD Stablecoin (Wormhole v1)",
    symbol: "wHUSD_v1",
    address: "BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 713,
  },
  {
    chainId: 101,
    name: "DGX",
    symbol: "DGX",
    address: "32uwQKZibFm5C9EjY6raGC1ZjAAQQWy1LvJxeriJEzEt",
    decimals: 9,
    logoURI: "https://i.ibb.co/YBw0zVc/LOGO-new.png",
    tags: [],
    verified: true,
    holders: 5872,
  },
  {
    chainId: 101,
    name: "SunnySideUp staked SOL (sSOL)",
    symbol: "sSOL",
    address: "8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS/logo.png",
    tags: [],
    verified: true,
    holders: 316,
  },
  {
    chainId: 101,
    name: "SOLBET",
    symbol: "BET",
    address: "3UMYcByZNQVHHyyqVfXMKr8XWP64omYBFVvf7bD6wBiA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3UMYcByZNQVHHyyqVfXMKr8XWP64omYBFVvf7bD6wBiA/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 2002,
  },
  {
    chainId: 101,
    name: "MIM",
    symbol: "SWARM",
    address: "4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu/logo.png",
    tags: [],
    verified: true,
    holders: 12543,
  },
  {
    chainId: 101,
    name: "SolanaSail Governance Token V2",
    symbol: "gSAIL",
    address: "Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solanasail/token-list/main/assets/mainnet/Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6943,
  },
  {
    chainId: 101,
    name: "pBTC (Parrot BTC)",
    symbol: "pBTC",
    address: "DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun/logo.svg",
    tags: ["stablecoin"],
    verified: true,
    holders: 1484,
  },
  {
    chainId: 101,
    name: "pSOL (Parrot SOL)",
    symbol: "pSOL",
    address: "9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX/logo.svg",
    tags: ["stablecoin"],
    verified: true,
    holders: 9214,
  },
  {
    chainId: 101,
    name: "Solana Mobile App UlaPay Token",
    symbol: "ULA",
    address: "EwHqbMUMX33JjWAhxSg9vsX3miWqncsgpnAbqn9nhJwZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwHqbMUMX33JjWAhxSg9vsX3miWqncsgpnAbqn9nhJwZ/logo.png",
    tags: [],
    verified: true,
    holders: 241,
  },
  {
    chainId: 101,
    name: "Lido Staked ETH",
    symbol: "stETH",
    address: "H2mf9QNdU2Niq6QR7367Ua2trBsvscLyX5bz7R3Pw5sE",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H2mf9QNdU2Niq6QR7367Ua2trBsvscLyX5bz7R3Pw5sE/logo.png",
    tags: ["stake", "wrapped"],
    verified: true,
    holders: 159,
  },
  {
    chainId: 101,
    name: "Lido Wrapped Staked ETH",
    symbol: "wstETH",
    address: "ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo/logo.png",
    tags: ["stake", "wrapped"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Million",
    symbol: "MM",
    address: "2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC/logo.svg",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 140,
  },
  {
    chainId: 101,
    name: "HottoShotto",
    symbol: "HOTTO",
    address: "Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 546,
  },
  {
    chainId: 101,
    name: "Scallop Fossil Decorations",
    symbol: "Fossil",
    address: "FossiLkXJZ1rePN8jWBqHDZZ3F7ET8p1dRGhYKHbQcZR",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FossiLkXJZ1rePN8jWBqHDZZ3F7ET8p1dRGhYKHbQcZR/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 366,
  },
  {
    chainId: 101,
    name: "Scallop Seagrass Decorations",
    symbol: "Seagrass",
    address: "SeawdHf3NHG6gxCrezQxr5oJAHTLJd6JsQxxd144yaz",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SeawdHf3NHG6gxCrezQxr5oJAHTLJd6JsQxxd144yaz/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 4682,
  },
  {
    chainId: 101,
    name: "Crystal",
    symbol: "CRY",
    address: "HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi/logo.png",
    tags: ["crystal-token"],
    verified: true,
    holders: 1581,
  },
  {
    chainId: 101,
    name: "DINOEGG",
    symbol: "DINOEGG",
    address: "2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij/logo.png",
    tags: [],
    verified: true,
    holders: 1914,
  },
  {
    chainId: 101,
    name: "Ashera",
    symbol: "ASH",
    address: "FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX/logo.png",
    tags: [],
    verified: true,
    holders: 5157,
  },
  {
    chainId: 101,
    name: "Intersola",
    symbol: "ISOLA",
    address: "333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6524,
  },
  {
    chainId: 101,
    name: "SOLJAV",
    symbol: "SOLJAV",
    address: "Dypr2gWcVuqt3z6Uh31YD8Wm2V2ZCqWVBYEWhZNF9odk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dypr2gWcVuqt3z6Uh31YD8Wm2V2ZCqWVBYEWhZNF9odk/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 198,
  },
  {
    chainId: 101,
    name: "Solminter",
    symbol: "SMRT",
    address: "95KN8q3qubEVjPf9trgyur2nHx8T5RCmztRbLuQ5E5i",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95KN8q3qubEVjPf9trgyur2nHx8T5RCmztRbLuQ5E5i/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3260,
  },
  {
    chainId: 101,
    name: "SolDate(DATE) Token",
    symbol: "DATE",
    address: "Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9/DATE.svg",
    tags: ["social-token", "dating-token", "metaverse"],
    verified: true,
    holders: 5564,
  },
  {
    chainId: 101,
    name: "Swanlana",
    symbol: "SWAN",
    address: "SWANaZUGxF82KyVsbxeeNsMaVECtimze5VyCdywkvkH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SwanLana/logo/main/SWANLANA_PNG.png",
    tags: ["utility-token"],
    verified: true,
    holders: 11362,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Allbridge from Polygon)",
    symbol: "apUSDC",
    address: "eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 16691,
  },
  {
    chainId: 101,
    name: "Suplar",
    symbol: "SUPL",
    address: "DqRNwrvGUffB1j9tEYHcpw1DLMoc2QfwZ25nkBHkvRmr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqRNwrvGUffB1j9tEYHcpw1DLMoc2QfwZ25nkBHkvRmr/token.png",
    tags: [],
    verified: true,
    holders: 1025,
  },
  {
    chainId: 101,
    name: "Xverse Token",
    symbol: "XVC",
    address: "25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1/logo.png",
    tags: [],
    verified: true,
    holders: 6553,
  },
  {
    chainId: 101,
    name: "DecentSol",
    symbol: "DSOL",
    address: "5y1YcGVPFy8bEiCJi79kegF9igahmvDe5UrqswFvnpMJ",
    decimals: 4,
    logoURI:
      "https://gateway.pinata.cloud/ipfs/QmfV1LNrqefadJQ7PzMvrTnio9GzsFLcbwRXAgVQad3ykt/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3637,
  },
  {
    chainId: 101,
    name: "Agronomist coin",
    symbol: "AGTE",
    address: "4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1661,
  },
  {
    chainId: 101,
    name: "tuUSDC",
    symbol: "tuUSDC",
    address: "Amig8TisuLpzun8XyGfC5HJHHGUQEscjLgoTWsCCKihg",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuUSDC.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 8983,
  },
  {
    chainId: 101,
    name: "tuTULIP",
    symbol: "tuTULIP",
    address: "Am2kxXzFH84biqbswGWq2zieWqsX2ANnFDyiZr9Fh7zc",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuTULIP.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 2446,
  },
  {
    chainId: 101,
    name: "tuSOL",
    symbol: "tuSOL",
    address: "H4Q3hDbuMUw8Bu72Ph8oV2xMQ7BFNbekpfQZKS2xF7jW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuSOL.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 9898,
  },
  {
    chainId: 101,
    name: "tuSNY",
    symbol: "tuSNY",
    address: "2yQJdxJy4tGeeXK2u8Lwdy9oY6Ks5shVH9gYtRH9zdDw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuSNY.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 489,
  },
  {
    chainId: 101,
    name: "tuRAY",
    symbol: "tuRAY",
    address: "8Lg7TowFuMQoGiTsLE6qV9x3czRgDmVy8f8Vv8KS4uW",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuRAY.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 10457,
  },
  {
    chainId: 101,
    name: "tuPOLIS",
    symbol: "tuPOLIS",
    address: "658FZo9B4HgKxsKsM7cUHN7jfNFgC7YftusWWYWc4piD",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuPOLIS.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 7386,
  },
  {
    chainId: 101,
    name: "tuMEDIA",
    symbol: "tuMEDIA",
    address: "6fGTc455JK3bsiSrvyLkEymQasPDXdrw2jJR16UkPnT5",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuMEDIA.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 334,
  },
  {
    chainId: 101,
    name: "tuLIKE",
    symbol: "tuLIKE",
    address: "DRu91PV94sb6kX6HwMGnGM8TuHrjycS4FmJNRWEgyw6n",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuLIKE.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 783,
  },
  {
    chainId: 101,
    name: "tuETH",
    symbol: "tuETH",
    address: "C1gwRSpKFu4Vjrg9MhNfRjg65SV4CNLsb3C6d7kWFEyV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuETH.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 1177,
  },
  {
    chainId: 101,
    name: "tuATLAS",
    symbol: "tuATLAS",
    address: "9eGNc4BZCAgpTSEjbu7ACCLjpnZh1WSdts3y4nMik4e7",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuATLAS.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 11457,
  },
  {
    chainId: 101,
    name: "Wrapped BUSD (Allbridge from BSC)",
    symbol: "abBUSD",
    address: "6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 8849,
  },
  {
    chainId: 101,
    name: "Wrapped ETH (Allbridge from Ethereum)",
    symbol: "aeWETH",
    address: "AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 1261,
  },
  {
    chainId: 101,
    name: "Wrapped USDT (Allbridge from Ethereum)",
    symbol: "aeUSDT",
    address: "Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
    tags: ["stablecoin"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Allbridge from Ethereum)",
    symbol: "aeUSDC",
    address: "DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 2448,
  },
  {
    chainId: 101,
    name: "Wrapped DAI (Allbridge from Ethereum)",
    symbol: "aeDAI",
    address: "9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 628,
  },
  {
    chainId: 101,
    name: "Solend SOL",
    symbol: "cSOL",
    address: "5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 69372,
  },
  {
    chainId: 101,
    name: "Solend USDC",
    symbol: "cUSDC",
    address: "993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 54899,
  },
  {
    chainId: 101,
    name: "Solend soETH",
    symbol: "csoETH",
    address: "AppJPZka33cu4DyUenFe9Dc1ZmZ3oQju6mBn9k37bNAa",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AppJPZka33cu4DyUenFe9Dc1ZmZ3oQju6mBn9k37bNAa/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 2880,
  },
  {
    chainId: 101,
    name: "Solend BTC",
    symbol: "cBTC",
    address: "Gqu3TFmJXfnfSX84kqbZ5u9JjSBVoesaHjfTsaPjRSnZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gqu3TFmJXfnfSX84kqbZ5u9JjSBVoesaHjfTsaPjRSnZ/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 3063,
  },
  {
    chainId: 101,
    name: "Solend USDT",
    symbol: "cUSDT",
    address: "BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 16992,
  },
  {
    chainId: 101,
    name: "Solend RAY",
    symbol: "cRAY",
    address: "2d95ZC8L5XP6xCnaKx8D5U5eX6rKbboBBAwuBLxaFmmJ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2d95ZC8L5XP6xCnaKx8D5U5eX6rKbboBBAwuBLxaFmmJ/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 6209,
  },
  {
    chainId: 101,
    name: "Solend SBR",
    symbol: "cSBR",
    address: "Bpm2aBL57uqVhgxutfRVrbtnjDpZLV8PZrRrytV1LgeT",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bpm2aBL57uqVhgxutfRVrbtnjDpZLV8PZrRrytV1LgeT/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 1112,
  },
  {
    chainId: 101,
    name: "Solend MER",
    symbol: "cMER",
    address: "BsWLxf6hRJnyytKR52kKBiz7qU7BB3SH77mrBxNnYU1G",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BsWLxf6hRJnyytKR52kKBiz7qU7BB3SH77mrBxNnYU1G/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 579,
  },
  {
    chainId: 101,
    name: "Circlepod Protocol Token",
    symbol: "CPX",
    address: "CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq/logo.png",
    tags: [],
    verified: true,
    holders: 3012,
  },
  {
    chainId: 101,
    name: "Jackie Chan Fan Token",
    symbol: "JACKIE",
    address: "9xkb4MSeD2WkJuio3EdGhEjNP5MuAp56scwKpiDNLtHc",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xkb4MSeD2WkJuio3EdGhEjNP5MuAp56scwKpiDNLtHc/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 320,
  },
  {
    chainId: 101,
    name: "Wrapped FTT (Allbridge from Ethereum)",
    symbol: "aeFTT",
    address: "BFsCwfk8VsEbSfLkkgmoKsAPk2N6FMJjeTsuxfGa9VEf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png",
    tags: [],
    verified: true,
    holders: 398,
  },
  {
    chainId: 101,
    name: "Solbank",
    symbol: "SBNK",
    address: "uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc/logo.png",
    tags: [],
    verified: true,
    holders: 4022,
  },
  {
    chainId: 101,
    name: "White SolBull DNA",
    symbol: "BDNA2",
    address: "BDNA2oi3W3TpMfbPMRoEzM55WdSajtyWnADkhsCW9p5f",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA2oi3W3TpMfbPMRoEzM55WdSajtyWnADkhsCW9p5f/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 109,
  },
  {
    chainId: 101,
    name: "Grey SolBull DNA",
    symbol: "BDNA3",
    address: "BDNA345whxSjPj1xBk7wobHfnv35qe7rJwX2zUnRMZMT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA345whxSjPj1xBk7wobHfnv35qe7rJwX2zUnRMZMT/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 172,
  },
  {
    chainId: 101,
    name: "Brown SolBull DNA",
    symbol: "BDNA4",
    address: "BDNA4xTPk6iVe2iuQe8931quH55XsZo3R97VwsgfUgK5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA4xTPk6iVe2iuQe8931quH55XsZo3R97VwsgfUgK5/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 266,
  },
  {
    chainId: 101,
    name: "DODG the Elephant Shrew",
    symbol: "DODG",
    address: "FaYTnfmPK8uP4dvtECypG3ugCC3wQrG27pwkB1YkhXsG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FaYTnfmPK8uP4dvtECypG3ugCC3wQrG27pwkB1YkhXsG/logo.png",
    tags: [],
    verified: true,
    holders: 374,
  },
  {
    chainId: 101,
    name: "Synthetic USD",
    symbol: "xUSD",
    address: "83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y",
    decimals: 6,
    logoURI: "https://www.synthetify.io/icons/xusd.svg",
    tags: [],
    verified: true,
    holders: 4839,
  },
  {
    chainId: 101,
    name: "Synthetic BTC",
    symbol: "xBTC",
    address: "HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D",
    decimals: 10,
    logoURI: "https://www.synthetify.io/icons/xbtc.svg",
    tags: [],
    verified: true,
    holders: 622,
  },
  {
    chainId: 101,
    name: "Synthetic SOL",
    symbol: "xSOL",
    address: "BdUJucPJyjkHxLMv6ipKNUhSeY3DWrVtgxAES1iSBAov",
    decimals: 9,
    logoURI: "https://www.synthetify.io/icons/xsol.svg",
    tags: [],
    verified: true,
    holders: 1720,
  },
  {
    chainId: 101,
    name: "Synthetic FTT",
    symbol: "xFTT",
    address: "Fr3W7NPVvdVbwMcHgA7Gx2wUxP43txdsn3iULJGFbKz9",
    decimals: 8,
    logoURI: "https://www.synthetify.io/icons/xftt.svg",
    tags: [],
    verified: true,
    holders: 668,
  },
  {
    chainId: 101,
    name: "Synthetic ETH",
    symbol: "xETH",
    address: "8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK",
    decimals: 9,
    logoURI: "https://www.synthetify.io/icons/xeth.svg",
    tags: [],
    verified: true,
    holders: 626,
  },
  {
    chainId: 101,
    name: "Synthetic LUNA",
    symbol: "xLUNA",
    address: "6MeoZEcUMhAB788YXTQN4x7K8MnwSt6RHWsLkuq9GJb2",
    decimals: 6,
    logoURI: "https://www.synthetify.io/icons/xluna.svg",
    tags: [],
    verified: true,
    holders: 260,
  },
  {
    chainId: 101,
    name: "Dai Stablecoin (Portal)",
    symbol: "DAI",
    address: "EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o/logo.png",
    tags: ["wrapped"],
    verified: true,
    holders: 3412,
  },
  {
    chainId: 101,
    name: "Dai Stablecoin (Portal from Polygon)",
    symbol: "DAIpo",
    address: "4Fo67MYQpVhZj9R7jQTd63FPAnWbPpaafAUxsMGX2geP",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Fo67MYQpVhZj9R7jQTd63FPAnWbPpaafAUxsMGX2geP/logo.png",
    tags: ["wrapped"],
    verified: true,
    holders: 146,
  },
  {
    chainId: 101,
    name: "Himalayan Cat Coin",
    symbol: "HIMA",
    address: "72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ/logo.png",
    tags: [],
    verified: true,
    holders: 8567,
  },
  {
    chainId: 101,
    name: "Fraktionalized Triumphant",
    symbol: "JRDN",
    address: "9jWgVR3Q3QjfmaXNiZ6jht2K43W7sqkn6tZFeoK9B48t",
    decimals: 3,
    logoURI:
      "https://www.arweave.net/B1rrktxxGta7w34MkEuFoJQxDzKLPXlYtBcR_1TkQPQ?ext=png",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 8741,
  },
  {
    chainId: 101,
    name: "GOATS",
    symbol: "GOATS",
    address: "DVPWKGLFHK73PwgKgTtW28iCZGewQdva2N5HeBLDorVJ",
    decimals: 4,
    logoURI: "https://www.solgoats.io/logo_full.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 723,
  },
  {
    chainId: 101,
    name: "SOL FOX",
    symbol: "SFOX",
    address: "3TMdBbnXKASdx9rBcZ5HQsyqCky7Gt2ea44gYB6Ro15A",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/Cryptodoggo97/Looptoken/logo.png",
    tags: ["meme", "community", "gaming"],
    verified: true,
    holders: 555,
  },
  {
    chainId: 101,
    name: "WGMI NFT Collector's Club",
    symbol: "WGMI",
    address: "1YDQ35V8g68FGvcT85haHwAXv1U7XMzuc4mZeEXfrjE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1YDQ35V8g68FGvcT85haHwAXv1U7XMzuc4mZeEXfrjE/logo.png",
    tags: ["community", "NFT", "club", "wgmi", "wagmi"],
    verified: true,
    holders: 296,
  },
  {
    chainId: 101,
    name: "Wrapped USDT (Allbridge from Avalanche)",
    symbol: "aaUSDT",
    address: "FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
    tags: ["stablecoin"],
    verified: true,
    holders: 1669,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Allbridge from Avalanche)",
    symbol: "aaUSDC",
    address: "8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 5350,
  },
  {
    chainId: 101,
    name: "Wrapped DAI (Allbridge from Avalanche)",
    symbol: "aaDAI",
    address: "EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 719,
  },
  {
    chainId: 101,
    name: "Wrapped BTC (Allbridge from Avalanche)",
    symbol: "aaWBTC",
    address: "Fd8xyHHRjTvxfZrBirb6MaxSmrZYw99gRSqFUKdFwFvw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
    tags: [],
    verified: true,
    holders: 430,
  },
  {
    chainId: 101,
    name: "AVAX (Allbridge from Avalanche)",
    symbol: "AVAX",
    address: "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
    decimals: 9,
    logoURI:
      "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png",
    tags: [],
    verified: true,
    holders: 3963,
  },
  {
    chainId: 101,
    name: "Honey Pot",
    symbol: "HP",
    address: "BmLvq52WKMb5MYKLScay5V9C4Sh4E67zxvwLbU6i2vTR",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmLvq52WKMb5MYKLScay5V9C4Sh4E67zxvwLbU6i2vTR/logo.png",
    tags: ["BugBear", "NFT"],
    verified: true,
    holders: 362,
  },
  {
    chainId: 101,
    name: "AbstergoAI",
    symbol: "ATG",
    address: "6X4jtyjKQmNx9zEPjzt1A3hcKEX7fi6BX3ruQ79sLa75",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/ATGxlabs/logo/main/ATGlogo.jpg",
    tags: ["ATG", "NFT"],
    verified: true,
    holders: 482,
  },
  {
    chainId: 101,
    name: "CODI",
    symbol: "CODI",
    address: "yvbrxE6zjrA8SxxSpL7oojDBB5QDmF5CVqJWea8JcQE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yvbrxE6zjrA8SxxSpL7oojDBB5QDmF5CVqJWea8JcQE/logo.png",
    tags: ["Infrastructure", "DeFi"],
    verified: true,
    holders: 2239,
  },
  {
    chainId: 101,
    name: "Final Frontier",
    symbol: "FRNT",
    address: "3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1071,
  },
  {
    chainId: 101,
    name: "Alpha NFT Token",
    symbol: "$ALPHA",
    address: "9ubSPNJoTjbvo1aVArBBXHqoTXxdHKvNGiDnHWmskdv7",
    decimals: 0,
    logoURI:
      "https://github.com/alpha-sol/alpha-frontend/blob/main/resources/logo.png",
    tags: [
      "art",
      "art-token",
      "generative-art",
      "community",
      "community-token",
      "utility-token",
      "meta-verse",
      "dao",
      "governance",
    ],
    verified: true,
    holders: 149,
  },
  {
    chainId: 101,
    name: "Aurory",
    symbol: "xAURY",
    address: "xAURp5XmAG7772mfkSy6vRAjGK9JofYjc3dmQDWdVDP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xAURp5XmAG7772mfkSy6vRAjGK9JofYjc3dmQDWdVDP/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 14222,
  },
  {
    chainId: 101,
    name: "GreenGoldCoin",
    symbol: "GGLD",
    address: "4mkDXzvwMoP11MtKkgMfZW28xbdwY1gsMBz9N6pxdLbU",
    decimals: 4,
    logoURI: "https://www.greengoldcoin.com/public/GreenGoldCoin-logo-128.png",
    tags: ["utility-token", "payment-token"],
    verified: true,
    holders: 118,
  },
  {
    chainId: 101,
    name: "AvocadoCoin",
    symbol: "AVDO",
    address: "EE5L8cMU4itTsCSuor7NLK6RZx6JhsBe8GGV3oaAHm3P",
    decimals: 8,
    logoURI: "http://avocadocoin.com/public/AvocadoCoin-logo-128.png",
    tags: [
      "utility-token",
      "payment-token",
      "hybrid-token",
      "agro-token",
      "IoT-token",
    ],
    verified: true,
    holders: 5743,
  },
  {
    chainId: 101,
    name: "Matrix Solana Index",
    symbol: "MSI",
    address: "2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 583,
  },
  {
    chainId: 101,
    name: "CATPUNK TOKEN",
    symbol: "FISH",
    address: "2fNw2cs23CV2X6JFA4CprbNbk3GcctFH8FcXSDPBkd7X",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/minh-git/docusaurus-2/main/catpunk.png",
    tags: ["NFT", "dao", "utility-token", "meta-verse", "governance"],
    verified: true,
    holders: 2494,
  },
  {
    chainId: 101,
    name: "Cheese",
    symbol: "CHEESE",
    address: "3Cm1DDX9K9emkjjHoJJKXwwcDpREGxRzLyNgMSCy49zB",
    decimals: 9,
    logoURI: "https://assets.solmice.com/file/solmice/cheese/images/757105.png",
    tags: ["utility-token"],
    verified: true,
    holders: 556,
  },
  {
    chainId: 101,
    name: "Lizard Token",
    symbol: "LIZARD",
    address: "5ENUvV3Ur3o3Fg6LVRfHL4sowidiVTMHHsEFqNJXRz6o",
    decimals: 4,
    logoURI: "http://lizardtoken.xyz/gallery/lizard_logo-ts1634859660.jpg",
    tags: [],
    verified: true,
    holders: 1754,
  },
  {
    chainId: 101,
    name: "FTX Token (Portal)",
    symbol: "FTT",
    address: "EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 6058,
  },
  {
    chainId: 101,
    name: "Serum (Portal from Ethereum)",
    symbol: "SRMet",
    address: "xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 955,
  },
  {
    chainId: 101,
    name: "HUSD (Portal)",
    symbol: "HUSD",
    address: "7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 346,
  },
  {
    chainId: 101,
    name: "Binance USD (Portal from Ethereum)",
    symbol: "BUSDet",
    address: "33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 822,
  },
  {
    chainId: 101,
    name: "USDK (Portal)",
    symbol: "USDK",
    address: "43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 751,
  },
  {
    chainId: 101,
    name: "ChainLink Token (Portal)",
    symbol: "LINK",
    address: "2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 1017,
  },
  {
    chainId: 101,
    name: "Paxos Gold (Portal)",
    symbol: "PAXG",
    address: "C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 192,
  },
  {
    chainId: 101,
    name: "SolGold",
    symbol: "GOLD",
    address: "E1zxRweqCWzviAraKjNjqupuyYTzm1bukJgb8KiBN1sN",
    decimals: 5,
    logoURI: "https://lizardtoken.xyz/gallery/gold-ts1635027612.jpg",
    tags: [],
    verified: true,
    holders: 282,
  },
  {
    chainId: 101,
    name: "BLD",
    symbol: "BOLELD",
    address: "9bQXrgpNYf9V2QgH6z8diP5e96c18REcDQXd87XCPkZw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/9bQXrgpNYf9V2QgH6z8diP5e96c18REcDQXd87XCPkZw/logo.png",
    tags: [],
    verified: true,
    holders: 1763,
  },
  {
    chainId: 101,
    name: "Tribeland Governance Token",
    symbol: "TRBL",
    address: "3CKQgrcvwhvFqVXXxLTb1u262nh26SJ3uutkSCTtbZxH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/3CKQgrcvwhvFqVXXxLTb1u262nh26SJ3uutkSCTtbZxH/logo.png",
    tags: ["game", "utility-token", "nft", "gaming", "metaverse"],
    verified: true,
    holders: 6403,
  },
  {
    chainId: 101,
    name: "FUS Token",
    symbol: "FUS",
    address: "Cf6rda8prASJemo9w4Q3NeBEM2pcYKK8aNZ5zLk4Dmzh",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cf6rda8prASJemo9w4Q3NeBEM2pcYKK8aNZ5zLk4Dmzh/logo.png",
    tags: ["FUS-Token"],
    verified: true,
    holders: 26210,
  },
  {
    chainId: 101,
    name: "Oasis",
    symbol: "OASIS",
    address: "8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt/logo.png",
    tags: ["Utility-token", "game", "NFTs"],
    verified: true,
    holders: 620,
  },
  {
    chainId: 101,
    name: "Valknut Gold",
    symbol: "Ticket",
    address: "HHXMCAQGw4SNfwJ3FqTJdFgt2M8GqggFk9cRm4jLYPDB",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHXMCAQGw4SNfwJ3FqTJdFgt2M8GqggFk9cRm4jLYPDB/logo.svg",
    tags: ["utility-token", "gift", "game-coin", "gaming"],
    verified: true,
    holders: 1105,
  },
  {
    chainId: 101,
    name: "Cubecoin",
    symbol: "CUBE",
    address: "KUPoVbJmipJb1M7xzQEND5w7u1BbmBytu9wZ2QPjQx4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUPoVbJmipJb1M7xzQEND5w7u1BbmBytu9wZ2QPjQx4/logo.png",
    tags: ["meme", "community", "cube"],
    verified: true,
    holders: 282,
  },
  {
    chainId: 101,
    name: "Samusky Token",
    symbol: "SAMU",
    address: "SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 5234,
  },
  {
    chainId: 101,
    name: "SolWorms",
    symbol: "DIRT",
    address: "7hMNi9EVTTRyuUH7FybEcPLa7uG6amLWW8xAmV8qi47",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solanadevv/token-list/3f415a0fbe16c25e699842c8dfed327a0fbfc727/assets/mainnet/7hMNi9EVTTRyuUH7FybEcPLa7uG6amLWW8xAmV8qi47/logo.png",
    tags: ["utility-token", "nft"],
    verified: true,
    holders: 156,
  },
  {
    chainId: 101,
    name: "Bored Rockstar Club",
    symbol: "VIP",
    address: "8z1jFyg9heBFvKVvqMHJQ4UXQqomNpYZHWCsEJhQYaBd",
    decimals: 9,
    logoURI:
      "https://github.com/solanadevv/token-list/blob/f73febc241c52ad0b977cd12fcf15d706b49a211/assets/mainnet/8z1jFyg9heBFvKVvqMHJQ4UXQqomNpYZHWCsEJhQYaBd/logo.png?raw=true",
    tags: ["utility-token", "nft"],
    verified: true,
    holders: 598,
  },
  {
    chainId: 101,
    name: "Allbridge Staking",
    symbol: "xABR",
    address: "xAx6d1sjmBvpWkVZQEqgUvPmGBNndEXPxYpr3QVp61H",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/allbridge-io/media/main/token.svg",
    tags: [],
    verified: true,
    holders: 2897,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Allbridge from Celo)",
    symbol: "acUSDC",
    address: "DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 681,
  },
  {
    chainId: 101,
    name: "Wrapped BTC (Allbridge from HECO)",
    symbol: "ahBTC",
    address: "Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 167,
  },
  {
    chainId: 101,
    name: "Wrapped USDT (Allbridge from HECO)",
    symbol: "ahUSDT",
    address: "GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 329,
  },
  {
    chainId: 101,
    name: "Donkey Hee Haw",
    symbol: "DONKEY",
    address: "58yYYVT5FoVx2jtvD9xtX4JxE8jogtA5tjMkJudgERMS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58yYYVT5FoVx2jtvD9xtX4JxE8jogtA5tjMkJudgERMS/logo.png",
    tags: [],
    verified: true,
    holders: 1015,
  },
  {
    chainId: 101,
    name: "Shibalana Inu",
    symbol: "SHIBL",
    address: "AsVNhq2nnoUgMWciCvePRyHk7xAv6i4ruV6oRHFWBcwF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsVNhq2nnoUgMWciCvePRyHk7xAv6i4ruV6oRHFWBcwF/logo.png",
    tags: ["community-token", "meme-token", "doge", "dogecoin"],
    verified: true,
    holders: 285,
  },
  {
    chainId: 101,
    name: "Kishu Inu",
    symbol: "Kishu",
    address: "6wFgUMohoSavTuEneDYcrb9qF3JsYVVXyB8jb3PaXCJ4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wFgUMohoSavTuEneDYcrb9qF3JsYVVXyB8jb3PaXCJ4/logo.png",
    tags: ["community-token", "meme-token", "doge", "dogecoin"],
    verified: true,
    holders: 225,
  },
  {
    chainId: 101,
    name: "Solana CUM",
    symbol: "SCUM",
    address: "cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 39479,
  },
  {
    chainId: 101,
    name: "Apple Fruit",
    symbol: "APPLE",
    address: "8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h/logo.png",
    tags: ["community-token", "meme-coin", "fruit", "apple-coin"],
    verified: true,
    holders: 2434,
  },
  {
    chainId: 101,
    name: "Samolana NFT",
    symbol: "SAMOL",
    address: "2qRHKgE9k7doshwy7ZfENuSHW256pDhcbyspDgU3Ek8C",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qRHKgE9k7doshwy7ZfENuSHW256pDhcbyspDgU3Ek8C/logo.png",
    tags: ["meme", "community", "NFTs", "DEFI", "Dog"],
    verified: true,
    holders: 205,
  },
  {
    chainId: 101,
    name: "ShibSol",
    symbol: "SHIBS",
    address: "CDxwZo3ayxvTmxin7F6o9xg6SjdE4qWEDXV6MZFBevqw",
    decimals: 9,
    logoURI:
      "https://discordapp.com/channels/@me/903775821704073247/903777435231846420",
    tags: [],
    verified: true,
    holders: 5156,
  },
  {
    chainId: 101,
    name: "Degen",
    symbol: "DEGN",
    address: "A9UhP1xfQHWUhSd54NgKPub2XB3ZuQMdPEvf9aMTHxGT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/flazewang/degencoinsolana/main/degen.png",
    tags: [],
    verified: true,
    holders: 12087,
  },
  {
    chainId: 101,
    name: "Sphinxel",
    symbol: "SPX",
    address: "H6JocWxg5g1Lcs4oPnBecmjQ4Y1bkZhGJHtjMunmjyrp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H6JocWxg5g1Lcs4oPnBecmjQ4Y1bkZhGJHtjMunmjyrp/logo.png",
    tags: ["meme-token", "community-token", "trading-token"],
    verified: true,
    holders: 4134,
  },
  {
    chainId: 101,
    name: "D Coin",
    symbol: "DCN",
    address: "FsAXvJ5wrCoSh3cQvdkuceUsQUjLtRcqgoikR9jQ9FBW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FsAXvJ5wrCoSh3cQvdkuceUsQUjLtRcqgoikR9jQ9FBW/logo.png",
    tags: [],
    verified: true,
    holders: 337,
  },
  {
    chainId: 101,
    name: "Solana Boobs",
    symbol: "SBOOBS",
    address: "4evENxfLeUDk24nrqzMp4gkR3kPxCMeQuCeftjgd66BD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4evENxfLeUDk24nrqzMp4gkR3kPxCMeQuCeftjgd66BD/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 13371,
  },
  {
    chainId: 101,
    name: "universe",
    symbol: "UNIVERSE",
    address: "9k27FY1wmxKEyoMGqK4zJMT2Y8dvkiYRGM2ijjLLTrjq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9k27FY1wmxKEyoMGqK4zJMT2Y8dvkiYRGM2ijjLLTrjq/logo.png",
    tags: ["Metaverse", "Universe"],
    verified: true,
    holders: 283,
  },
  {
    chainId: 101,
    name: "Samoy Meme Dog",
    symbol: "SAMOY",
    address: "3mXx1bNiB5bhgwznk4eeqM9eoy6DU3CeCkm1LPabeoEh",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mXx1bNiB5bhgwznk4eeqM9eoy6DU3CeCkm1LPabeoEh/logo.png",
    tags: ["Meme-Token", "Community-Token"],
    verified: true,
    holders: 438,
  },
  {
    chainId: 101,
    name: "Artists Coin",
    symbol: "ARS",
    address: "9aj5LDmjjwBXk5ijfoyQej3X6waV1rnk7QN4KVqjX8wH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9aj5LDmjjwBXk5ijfoyQej3X6waV1rnk7QN4KVqjX8wH/logo.png",
    tags: ["social-token", "utility-token", "nft"],
    verified: true,
    holders: 401,
  },
  {
    chainId: 101,
    name: "Shibalana",
    symbol: "SHIBA",
    address: "Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa/logo.png",
    tags: [],
    verified: true,
    holders: 9734,
  },
  {
    chainId: 101,
    name: "SolBull - GoPromotedToken",
    symbol: "sBull",
    address: "3xVf2hPbkE5TuZNUPLQXFgFLD4LpvCM45BodbPmnpSSV",
    decimals: 9,
    logoURI: "https://gopromoted.com/sbull200.png",
    tags: ["Utility-Token"],
    verified: true,
    holders: 289,
  },
  {
    chainId: 101,
    name: "Pornlana",
    symbol: "PORN",
    address: "8gWEnKqB4qVQgC8yAorMxhiEKqsDcxZSVKFVbQ8g1fzB",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/Agaveis/pornlana/main/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 201,
  },
  {
    chainId: 101,
    name: "Valknut",
    symbol: "VAL",
    address: "2uEb2S4JCxZSzMxRfEdmj6grtZkgEe6XrfTwgkpswuvS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2uEb2S4JCxZSzMxRfEdmj6grtZkgEe6XrfTwgkpswuvS/Valknut.png",
    tags: ["utility-token", "game-coin", "gaming"],
    verified: true,
    holders: 1344,
  },
  {
    chainId: 101,
    name: "Dickcoin",
    symbol: "DICK",
    address: "E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3997,
  },
  {
    chainId: 101,
    name: "FlokiSol",
    symbol: "FLOKIS",
    address: "AvB7Ffmt3H16bhq7ToXb839ynKzFgJxu2WDHsR1S9Yft",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AvB7Ffmt3H16bhq7ToXb839ynKzFgJxu2WDHsR1S9Yft/Flokisol.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 865,
  },
  {
    chainId: 101,
    name: "Samo INU",
    symbol: "SINU",
    address: "Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 4591,
  },
  {
    chainId: 101,
    name: "BORK",
    symbol: "BORK",
    address: "2LxZrcJJhzcAju1FBHuGvw929EVkX7R7Q8yA2cdp8q7b",
    decimals: 9,
    logoURI: "https://arweave.net/VtJL5kdepu6AyVHnHi4GImgyWxmcb2XMPN7jURW_yXQ",
    tags: ["meme-token"],
    verified: true,
    holders: 2591,
  },
  {
    chainId: 101,
    name: "LGBR - LETS GO BRANDON",
    symbol: "LGBR",
    address: "53dqN1unCex98QWzLZtk1ssJptEcRwZapTrv8pakcgNB",
    decimals: 9,
    logoURI: "http://gopromoted.com/brandon.jpg",
    tags: ["Meme-coin"],
    verified: true,
    holders: 257,
  },
  {
    chainId: 101,
    name: "PUMP",
    symbol: "PUMP",
    address: "4dzDhawz7bHfDDfBpbDrLeDu6T7vZEtmmyQtn9Df2PRa",
    decimals: 9,
    logoURI:
      "https://wtcaeyantzogvbrzbwcgqfesfnz7mekovkyybs3ae54fptigq5za.arweave.net/tMQCYA2eXGqGOQ2EaBSSK3P2EU6qsYDLYCd4V80Gh3I",
    tags: ["meme-token"],
    verified: true,
    holders: 1072,
  },
  {
    chainId: 101,
    name: "DO YOUR OWN RESEARCH",
    symbol: "DYOR",
    address: "BTSPdFLQJ9R3JXAgjVx2JtLq4sNSjiVSGh4tQi4oRUi8",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTSPdFLQJ9R3JXAgjVx2JtLq4sNSjiVSGh4tQi4oRUi8/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1815,
  },
  {
    chainId: 101,
    name: "Ariadne Solana",
    symbol: "ARDN",
    address: "4wfCtMp8KQ7r61V4qH2VtHxFjVjUdsWhgAmZgQi33UkT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wfCtMp8KQ7r61V4qH2VtHxFjVjUdsWhgAmZgQi33UkT/logo.svg",
    tags: ["utility-token", "DeFi", "marketplace", "ariadne"],
    verified: true,
    holders: 1225,
  },
  {
    chainId: 101,
    name: "ACE",
    symbol: "ACE",
    address: "5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3/logo.png",
    tags: [],
    verified: true,
    holders: 122,
  },
  {
    chainId: 101,
    name: "VIBE",
    symbol: "VIBE",
    address: "8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu/VIBE-logo.png",
    tags: ["utility-token", "nft-token"],
    verified: true,
    holders: 4629,
  },
  {
    chainId: 101,
    name: "Marijuana Joint",
    symbol: "MARIJUANA",
    address: "DYbRXaQcnj44SH9woxvyFdtcKkSoPoCEshRTQDZSjsBm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYbRXaQcnj44SH9woxvyFdtcKkSoPoCEshRTQDZSjsBm/logo.png",
    tags: ["Meme", "Social"],
    verified: true,
    holders: 233,
  },
  {
    chainId: 101,
    name: "USD Coin (Portal from BSC)",
    symbol: "USDCbs",
    address: "FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 7220,
  },
  {
    chainId: 101,
    name: "aSOL Aggregate Solana Stake Pool",
    symbol: "aSOL",
    address: "ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond/logo.svg",
    tags: [],
    verified: true,
    holders: 2316,
  },
  {
    chainId: 101,
    name: "Tether USD (Portal from BSC)",
    symbol: "USDTbs",
    address: "8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 13570,
  },
  {
    chainId: 101,
    name: "Asgard Army DAO Token",
    symbol: "ASGARD",
    address: "BQTN97PwrQGkbNepQxjvcYfRPYbPNgd5PqoioYwBt4qX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQTN97PwrQGkbNepQxjvcYfRPYbPNgd5PqoioYwBt4qX/logo.png",
    tags: ["DOA", "asgard-army", "nft"],
    verified: true,
    holders: 10007,
  },
  {
    chainId: 101,
    name: "DoggyStyle Coin",
    symbol: "DSC",
    address: "DogscQVvNVj7ndEnhWiCXPVPKKwNy9fJd4ATF7mVi5J",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Doggystylecoin/DSC/main/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 5077,
  },
  {
    chainId: 101,
    name: "Squid Game",
    symbol: "SQUIDGAME",
    address: "3NcCuwvTMnnf7TU2UEVhp6v2nzbLXQiDgzQySS6m8A7P",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NcCuwvTMnnf7TU2UEVhp6v2nzbLXQiDgzQySS6m8A7P/logo.png",
    tags: ["Meme", "community"],
    verified: true,
    holders: 305,
  },
  {
    chainId: 101,
    name: "betaFANT",
    symbol: "betaFANT",
    address: "7S5QMfpwnai8nF8RmHnwfGDQwGa2TURDvkHXH17tMjdn",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7S5QMfpwnai8nF8RmHnwfGDQwGa2TURDvkHXH17tMjdn/logo.png",
    tags: ["dumby-token"],
    verified: true,
    holders: 546,
  },
  {
    chainId: 101,
    name: "Doge Thug",
    symbol: "DOGETH",
    address: "ArhMyF2N8XpaujYUxTTDt9EuaBCaGaccxfwaZmkm9XeF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArhMyF2N8XpaujYUxTTDt9EuaBCaGaccxfwaZmkm9XeF/logo.png",
    tags: ["Meme", "Social", "Metaverse"],
    verified: true,
    holders: 240,
  },
  {
    chainId: 101,
    name: "Pussy",
    symbol: "PUSSY",
    address: "BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa/logo.png",
    tags: ["Meme", "Social", "Metaverse"],
    verified: true,
    holders: 514,
  },
  {
    chainId: 101,
    name: "JESUS",
    symbol: "JESUS",
    address: "5xq71UHmPSZ5s68DkXL8wrBVsWCh4zXgcn4wTWkqFdxa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xq71UHmPSZ5s68DkXL8wrBVsWCh4zXgcn4wTWkqFdxa/logo.png",
    tags: ["Meme", "Social", "Community"],
    verified: true,
    holders: 845,
  },
  {
    chainId: 101,
    name: "Cato Miaouss",
    symbol: "CATOMIAOU",
    address: "4ZEDNmqoLbzwJVAJZNhRgz31Da8DauDkpSfH9iU2vXA4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZEDNmqoLbzwJVAJZNhRgz31Da8DauDkpSfH9iU2vXA4/logo.png",
    tags: ["Meme", "community"],
    verified: true,
    holders: 273,
  },
  {
    chainId: 101,
    name: "Bitch Of Solana",
    symbol: "BITCH",
    address: "AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3/logo.png",
    tags: ["Meme", "community", "utility-token"],
    verified: true,
    holders: 263,
  },
  {
    chainId: 101,
    name: "Shibetoshi Nakamoto",
    symbol: "SHIBETOSHI",
    address: "HdjMPYYKaAgHr6Son56hGaSP3CEkDvD67bVzGuVgfz8S",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HdjMPYYKaAgHr6Son56hGaSP3CEkDvD67bVzGuVgfz8S/logo.png",
    tags: ["meme", "community", "NFTs", "DEFI", "Dog"],
    verified: true,
    holders: 162,
  },
  {
    chainId: 101,
    name: "APEMOON",
    symbol: "APEM",
    address: "4ZwWddrPzfgMxyEgQ7kzVrqoqX5D9BQJPwduQUBMmePs",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZwWddrPzfgMxyEgQ7kzVrqoqX5D9BQJPwduQUBMmePs/logo.png",
    tags: ["Meme-token", "DEFI", "Community"],
    verified: true,
    holders: 211,
  },
  {
    chainId: 101,
    name: "Doggy Style",
    symbol: "DoggyStyle",
    address: "3in9a9yHtdjDFRjDyGTTpGUwJpT9zZBcyjQ8J7nqqNtq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3in9a9yHtdjDFRjDyGTTpGUwJpT9zZBcyjQ8J7nqqNtq/logo.png",
    tags: ["Meme", "community", "utility-token"],
    verified: true,
    holders: 286,
  },
  {
    chainId: 101,
    name: "Baby Floki Doge",
    symbol: "BABYFLOKISOL",
    address: "F5f9hLQ6FNHwuU3dS8CUCRy9r2deJXYCinDL6RAxsPeX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5f9hLQ6FNHwuU3dS8CUCRy9r2deJXYCinDL6RAxsPeX/logo.png",
    tags: ["meme-token", "DOGE", "NFT", "community"],
    verified: true,
    holders: 192,
  },
  {
    chainId: 101,
    name: "Floki Viking",
    symbol: "FLOKI",
    address: "3fFHsncY59ue2HPduo1KhbZRWYRd8iek5tj88sPXMgFk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fFHsncY59ue2HPduo1KhbZRWYRd8iek5tj88sPXMgFk/logo.png",
    tags: ["community-token", "meme-token", "doge", "Floki"],
    verified: true,
    holders: 280,
  },
  {
    chainId: 101,
    name: "Dogelon Mars",
    symbol: "ELON",
    address: "37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2/logo.png",
    tags: ["community-token", "meme-token", "doge", "MUSK"],
    verified: true,
    holders: 213,
  },
  {
    chainId: 101,
    name: "King Shiba",
    symbol: "KINGSHIB",
    address: "6cH34XtzNgCDwb7NFbiji1a1N8F3FgmXTrFxvzBZNVui",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cH34XtzNgCDwb7NFbiji1a1N8F3FgmXTrFxvzBZNVui/logo.png",
    tags: ["meme", "community", "NFTs", "DEFI", "Dog"],
    verified: true,
    holders: 303,
  },
  {
    chainId: 101,
    name: "META SOL",
    symbol: "METASOL",
    address: "B8NrYG3ZGbmDS6Xv5PUSdpJmXor9VvtxibvDRKNq3rnc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B8NrYG3ZGbmDS6Xv5PUSdpJmXor9VvtxibvDRKNq3rnc/logo.png",
    tags: ["community", "NFTs", "DEFI", "Facebook"],
    verified: true,
    holders: 373,
  },
  {
    chainId: 101,
    name: "ZEE",
    symbol: "ZEE",
    address: "ZEExktbqMM5ZMS569pCNbzky92KeEmiFeVwR3exfBNn",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZEExktbqMM5ZMS569pCNbzky92KeEmiFeVwR3exfBNn/logo.png",
    tags: ["zoints"],
    verified: true,
    holders: 13691,
  },
  {
    chainId: 101,
    name: "The Ticket Finance",
    symbol: "TICKET",
    address: "AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer/logo.png",
    tags: ["launchpad", "dex", "defi", "exchange", "utility-token"],
    verified: true,
    holders: 1831,
  },
  {
    chainId: 101,
    name: "Particle",
    symbol: "PART",
    address: "AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh/logo.png",
    tags: ["DeFi", "NFT", "Gaming"],
    verified: true,
    holders: 4191,
  },
  {
    chainId: 101,
    name: "DOGELON SOLANA",
    symbol: "DOGELON",
    address: "HYoGYzMcbYq3tAvpg15d8VFYVHw6jWEVuGgpNTrG8hps",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYoGYzMcbYq3tAvpg15d8VFYVHw6jWEVuGgpNTrG8hps/logo.png",
    tags: ["meme"],
    verified: true,
    holders: 947,
  },
  {
    chainId: 101,
    name: "nom",
    symbol: "NOM",
    address: "7zhbkbKpGaUsJW7AD4yyAfGGoy53Xx2H3Ai5BKcwGKHw",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zhbkbKpGaUsJW7AD4yyAfGGoy53Xx2H3Ai5BKcwGKHw/logo.png",
    tags: ["dao", "meme-token", "Defi", "Gamefi"],
    verified: true,
    holders: 304,
  },
  {
    chainId: 101,
    name: "DoodleCoin",
    symbol: "DCN",
    address: "FZMcCAq9U65mymBjUxKXPUJehDJMLg54Ud5bTrmbaHYS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZMcCAq9U65mymBjUxKXPUJehDJMLg54Ud5bTrmbaHYS/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 4511,
  },
  {
    chainId: 101,
    name: "Sol Invictus",
    symbol: "IN",
    address: "inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY/logo-owl.png",
    tags: ["decentralizedreserve", "utility-token", "DeFi", "community-token"],
    verified: true,
    holders: 32657,
  },
  {
    chainId: 101,
    name: "tuBTC",
    symbol: "tuBTC",
    address: "G5V7t3ZHTUGi6xGfk5nc42P1iRkNN3JhaFwFXkvyDmz5",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuBTC.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 526,
  },
  {
    chainId: 101,
    name: "tuORCA",
    symbol: "tuORCA",
    address: "HRYfSMc1gYEvxo9zsh14jYHKxAcfJfoVakKtRtJEomb8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuORCA.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 3566,
  },
  {
    chainId: 101,
    name: "tuSAMO",
    symbol: "tuSAMO",
    address: "CEqYVZCL7sHQ8gChh1yL3uajc2UDs6DXuYjPZyRox6MC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuSAMO.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 3805,
  },
  {
    chainId: 101,
    name: "tuSRM",
    symbol: "tuSRM",
    address: "4QSK13NTKxTBExbMjHFsj3QfHBn4Hfp3DGLSba8GvFvh",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuSRM.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 3123,
  },
  {
    chainId: 101,
    name: "tumSOL",
    symbol: "tumSOL",
    address: "8cn7JcYVjDZesLa3RTt3NXne4WcDw9PdUneQWuByehwW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tumSOL.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 1607,
  },
  {
    chainId: 101,
    name: "tuwhETH",
    symbol: "tuwhETH",
    address: "85SyKDLXZPC1sqdFqasywQojutGhDCobbPHM6zczD1Li",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuwhETH.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 592,
  },
  {
    chainId: 101,
    name: "BUSD Token (Portal from BSC)",
    symbol: "BUSDbs",
    address: "5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 11824,
  },
  {
    chainId: 101,
    name: "Saber USDT-USDC LP",
    symbol: "USDT-USDC",
    address: "2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 53799,
  },
  {
    chainId: 101,
    name: "Saber pBTC-renBTC LP",
    symbol: "pBTC-renBTC",
    address: "pBTCmyG7FaZx4uk3Q2pT5jHKWmWDn84npdc7gZXpQ1x",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pBTCmyG7FaZx4uk3Q2pT5jHKWmWDn84npdc7gZXpQ1x/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 5282,
  },
  {
    chainId: 101,
    name: "Saber weUSDT-USDT LP",
    symbol: "weUSDT-USDT",
    address: "USDTJZL2vH92K5QeCvQTTzvMXUYAdvk3v46CwZyfsue",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDTJZL2vH92K5QeCvQTTzvMXUYAdvk3v46CwZyfsue/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-wormhole-v2"],
    verified: true,
    holders: 812,
  },
  {
    chainId: 101,
    name: "Saber aeUSDC-USDC LP",
    symbol: "aeUSDC-USDC",
    address: "AECpyKJWfXVyWnk2d9md5dUj3RuzHRKfQra8MakjuVRz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AECpyKJWfXVyWnk2d9md5dUj3RuzHRKfQra8MakjuVRz/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-allbridge"],
    verified: true,
    holders: 1427,
  },
  {
    chainId: 101,
    name: "Saber aaUSDC-USDC LP",
    symbol: "aaUSDC-USDC",
    address: "AVC7uVb6R9B34T8zWxQMEK8twvYk26U71gworsujxFNv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVC7uVb6R9B34T8zWxQMEK8twvYk26U71gworsujxFNv/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-allbridge"],
    verified: true,
    holders: 1450,
  },
  {
    chainId: 101,
    name: "Saber mSOL-SOL LP",
    symbol: "mSOL-SOL",
    address: "SoLEao8wTzSfqhuou8rcYsVoLjthVmiXuEjzdNPMnCz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLEao8wTzSfqhuou8rcYsVoLjthVmiXuEjzdNPMnCz/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 46377,
  },
  {
    chainId: 101,
    name: "Saber aSOL-SOL LP",
    symbol: "aSOL-SOL",
    address: "ALP89a89ASo1h5VosTSABtQBKLBgeoaWQexYQrRCMNfV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALP89a89ASo1h5VosTSABtQBKLBgeoaWQexYQrRCMNfV/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 3273,
  },
  {
    chainId: 101,
    name: "Saber prtSOL-SOL LP",
    symbol: "prtSOL-SOL",
    address: "PrsVdKtXDDf6kJQu5Ff6YqmjfE4TZXtBgHM4bjuvRnR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PrsVdKtXDDf6kJQu5Ff6YqmjfE4TZXtBgHM4bjuvRnR/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 9587,
  },
  {
    chainId: 101,
    name: "Saber stSOL-SOL LP",
    symbol: "stSOL-SOL",
    address: "stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 12811,
  },
  {
    chainId: 101,
    name: "Saber scnSOL-SOL LP",
    symbol: "scnSOL-SOL",
    address: "SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 3431,
  },
  {
    chainId: 101,
    name: "Kineko",
    symbol: "KKO",
    address: "kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh",
    decimals: 9,
    logoURI: "https://kineko.io/img/cat.jpg",
    tags: ["DeFi", "Gaming", "Gambling"],
    verified: true,
    holders: 1142,
  },
  {
    chainId: 101,
    name: "Ottercoin",
    symbol: "Otter",
    address: "3oLpKntC8W9AxiFhafRGBeALGuKdimduUXVPo1GQNHuX",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/ottercoin11/ottercoin/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 118,
  },
  {
    chainId: 101,
    name: "Solit",
    symbol: "SLT",
    address: "SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 6159,
  },
  {
    chainId: 101,
    name: "Fraktionalized THUG 2856",
    symbol: "THUG",
    address: "7osS84AkAG2TCrUvrE1wfKwfAqWTCrHnaCsrsyVJd5pY",
    decimals: 3,
    logoURI: "https://arweave.net/q4h6GvG6MQfhXXNJTbLILbZY1OIgLqkJNHzNLClHDiw",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 1783,
  },
  {
    chainId: 101,
    name: "GoodMorning Token",
    symbol: "MORN",
    address: "3LCSAo9Hf64cxtPbArLog3PKkwGkZFN7Ttz1zLdPWPTS",
    decimals: 9,
    logoURI: "https://i.ibb.co/8dYwfYw/MORN-Circle.png",
    tags: ["meme-token", "community-token", "discord", "gm"],
    verified: true,
    holders: 1847,
  },
  {
    chainId: 101,
    name: "Modul",
    symbol: "MODUL",
    address: "A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/parametrise/ParametriseLogo/main/Modul_Logomark_White_Pinky_Background.png",
    tags: ["utility-token"],
    verified: true,
    holders: 12692,
  },
  {
    chainId: 101,
    name: "PEACHO TOKEN",
    symbol: "PEACH",
    address: "6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm/peach.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 105,
  },
  {
    chainId: 101,
    name: "Clash Of Cars",
    symbol: "CLASH",
    address: "3aAYh35n81F8HPG2QBdE48aYdzGFj2fsLccg91X4AcRc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aAYh35n81F8HPG2QBdE48aYdzGFj2fsLccg91X4AcRc/logo.png",
    tags: ["solana-token", "utility-token", "community-token"],
    verified: true,
    holders: 9153,
  },
  {
    chainId: 101,
    name: "PSY Coin",
    symbol: "PSY",
    address: "49jpm8SpyTwaGaJfUa4AmU28hmW1HoKuqzXkgykysowU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/charliebakerr/psy-token/main/psycoinlogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1207,
  },
  {
    chainId: 101,
    name: "TURNT",
    symbol: "TURNT",
    address: "Foea9rMuUk58xn414yB4PfWcP2VweR2mr3SU3ZUmSEa7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Foea9rMuUk58xn414yB4PfWcP2VweR2mr3SU3ZUmSEa7/logo.png",
    tags: [],
    verified: true,
    holders: 1207,
  },
  {
    chainId: 101,
    name: "SOLVIEW",
    symbol: "SOLV",
    address: "7q3AdgKuMeDRnjaMQs7ppXjaw4HUxjsdyMrrfiSZraiN",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Solviewapp/solviewlogo/main/icon.png",
    tags: [],
    verified: true,
    holders: 3078,
  },
  {
    chainId: 101,
    name: "CRAT",
    symbol: "CRAT",
    address: "7aohFKctcbgaSzNTtfL1999hUy4tgDeUC7wLU1ozNmxc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7aohFKctcbgaSzNTtfL1999hUy4tgDeUC7wLU1ozNmxc/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 1217,
  },
  {
    chainId: 101,
    name: "Space Vizsla",
    symbol: "SVIZ",
    address: "GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ/VizslaMoonLogo100x100.png",
    tags: ["meme-token", "community-token", "space-vizsla", "vizsla"],
    verified: true,
    holders: 1432,
  },
  {
    chainId: 101,
    name: "SOLKUMAINU",
    symbol: "SKUMA",
    address: "2oR1D21bNczbpgH6jY4DEtMbGD49boZ7DPVVUXnTV1iL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2oR1D21bNczbpgH6jY4DEtMbGD49boZ7DPVVUXnTV1iL/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 654,
  },
  {
    chainId: 101,
    name: "Degen Trash Panda Token",
    symbol: "DTPT",
    address: "ALQ6fjG5vSp9ou9PZHZES2cgu5E6QghL37gLNM2seZNh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ6fjG5vSp9ou9PZHZES2cgu5E6QghL37gLNM2seZNh/logo.png",
    tags: ["NFT"],
    verified: true,
    holders: 13969,
  },
  {
    chainId: 101,
    name: "MIX",
    symbol: "MIX",
    address: "4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 1136,
  },
  {
    chainId: 101,
    name: "Deez Nuts",
    symbol: "DEEZ",
    address: "97d6kj4itu6cqCNbxwmxjHxUD4QD1wicEi2jFw2Fm5vi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97d6kj4itu6cqCNbxwmxjHxUD4QD1wicEi2jFw2Fm5vi/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 198,
  },
  {
    chainId: 101,
    name: "Tiddie Token",
    symbol: "TDX",
    address: "5yUX1XpjLSTDyNBTQ3N3oYpu6RH4gckqnSS6Ecg79fAL",
    decimals: 9,
    logoURI:
      "https://cdn.jsdelivr.net/gh/tiddietoken/Icon2/Screenshot_20211109-102934_WhatsApp.jpg",
    tags: ["meme-token", "social-token"],
    verified: true,
    holders: 441,
  },
  {
    chainId: 101,
    name: "The Metaverse Museum",
    symbol: "MNM",
    address: "375hSNDx3yvHbZCPP7g3Cw85c5nWYLntvUZw7kkkJMRf",
    decimals: 9,
    logoURI:
      "https://themetaversemuseum.s3.eu-west-3.amazonaws.com/baselogo.png",
    tags: ["meta-verse", "utility-token", "community-token"],
    verified: true,
    holders: 160,
  },
  {
    chainId: 101,
    name: "SOLPAPA",
    symbol: "PAPA",
    address: "4oS3LJTPVr8UW9g2gJMEADotTF3omitQMxvXqgRTm8Mw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oS3LJTPVr8UW9g2gJMEADotTF3omitQMxvXqgRTm8Mw/logo.png",
    tags: ["SOLPAPA", "NFTs", "TOKEN", "meme-token"],
    verified: true,
    holders: 1792,
  },
  {
    chainId: 101,
    name: "Mingo Token",
    symbol: "MINGO",
    address: "GXm9UzbAERvZsfsM8CB6sWrn74BJ6ZAfDoNdeNRCmy2E",
    decimals: 9,
    logoURI: "https://s3.amazonaws.com/flashyflamingos.com/token.png",
    tags: ["meme-token", "nft"],
    verified: true,
    holders: 162,
  },
  {
    chainId: 101,
    name: "BITXBIT",
    symbol: "BITXBIT",
    address: "DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5/logo.png",
    tags: ["social-token", "technology", "utility-token"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "Geometry Governance token",
    symbol: "GXG",
    address: "AGrCEkCgXeTuD8GzHJmw2PEx4DKqmrnNiV6BYCsZK2Vg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGrCEkCgXeTuD8GzHJmw2PEx4DKqmrnNiV6BYCsZK2Vg/logo.png",
    tags: ["geometry", "geometryfi", "geobots", "governance"],
    verified: true,
    holders: 1108,
  },
  {
    chainId: 101,
    name: "SOLChamies",
    symbol: "CHAM",
    address: "ASTkbpe5ckW1mx6WDLE9zPScWsYjSh6h5zQ89UYoUQ7D",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASTkbpe5ckW1mx6WDLE9zPScWsYjSh6h5zQ89UYoUQ7D/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 4837,
  },
  {
    chainId: 101,
    name: "CondorCoin",
    symbol: "CNDR",
    address: "8XkS7ZDPR9zXcNcYR884tBScnQRyFcWRb7WcLtCR6zEZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8XkS7ZDPR9zXcNcYR884tBScnQRyFcWRb7WcLtCR6zEZ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 109,
  },
  {
    chainId: 101,
    name: "Potion",
    symbol: "Potion",
    address: "GtHxqAqbaZB8eo8R8pGXUhWxs6X8WQWMWTUWKTgSFbHo",
    decimals: 6,
    logoURI: "https://i.imgur.com/zURNQAX.png",
    tags: ["community-token", "DAO", "Staking"],
    verified: true,
    holders: 1312,
  },
  {
    chainId: 101,
    name: "Silver Access MIMO",
    symbol: "SMIMO",
    address: "GkSMSvjjcd8AXqbkzo4CiFXrsVB5JEhqLvd48HoVhJDA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkSMSvjjcd8AXqbkzo4CiFXrsVB5JEhqLvd48HoVhJDA/logo.png",
    tags: [],
    verified: true,
    holders: 5520,
  },
  {
    chainId: 101,
    name: "We Say gm",
    symbol: "GMSOL",
    address: "gmdu3snwW28DmmxCseChp9owWLUhamH9eS3hWfHG8Vg",
    decimals: 9,
    logoURI: "https://github.com/icnivadmai/gm-logo/blob/master/logo.png",
    tags: ["community-token", "meme-token", "social-token"],
    verified: true,
    holders: 1737,
  },
  {
    chainId: 101,
    name: "RUG",
    symbol: "Soltopia",
    address: "BpZ93AYjkYgGtpMf15CVqFogtbeRmgyoevpwDcPq2vNv",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmbpkaK4EZxe31HDMso4CK8nN54pstpHYewxDvRSZUSPjw",
    tags: ["meme-token"],
    verified: true,
    holders: 1082,
  },
  {
    chainId: 101,
    name: "KITTYCOIN SOL",
    symbol: "KSOL",
    address: "CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6548,
  },
  {
    chainId: 101,
    name: "SHIBU Token",
    symbol: "SHIBU",
    address: "3iBZV8gvUFxp333FFogUPVi6MP9dEZ74xUxVzEQvNPii",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/shibuio/token-list/main/assets/mainnet/3iBZV8gvUFxp333FFogUPVi6MP9dEZ74xUxVzEQvNPii/logo.png",
    tags: ["meme-token", "NFT"],
    verified: true,
    holders: 274,
  },
  {
    chainId: 101,
    name: "Solcatcoin",
    symbol: "MEW",
    address: "9BiqBycZWkWH21vYqCbu2bL1PjZbR5GxWGA8LQkShbyt",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9BiqBycZWkWH21vYqCbu2bL1PjZbR5GxWGA8LQkShbyt/logo.png",
    tags: ["meme", "cat", "community-token"],
    verified: true,
    holders: 2015,
  },
  {
    chainId: 101,
    name: "Solabrador",
    symbol: "SOLAB",
    address: "GLmaRDRmYd4u3YLfnj9eq1mrwxa1YfSweZYYZXZLTRdK",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLmaRDRmYd4u3YLfnj9eq1mrwxa1YfSweZYYZXZLTRdK/solabradon250x250.png",
    tags: ["social-token"],
    verified: true,
    holders: 13118,
  },
  {
    chainId: 101,
    name: "NinjaDoge",
    symbol: "NinjaDoge",
    address: "9dwrdifAVWZsyEPxi15D8LcLsdrvTbpabrGw5EVzc7pp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9dwrdifAVWZsyEPxi15D8LcLsdrvTbpabrGw5EVzc7pp/logo.png",
    tags: ["Meme", "community", "utility-token", "shib", "nft"],
    verified: true,
    holders: 157,
  },
  {
    chainId: 101,
    name: "Chairman Chow",
    symbol: "CCC",
    address: "E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6/logo.png",
    tags: ["meme-token", "community-token", "doge-token", "doge"],
    verified: true,
    holders: 1504,
  },
  {
    chainId: 101,
    name: "Solana Drink",
    symbol: "DRINK",
    address: "3cAzWmrfovbkb3pU9mktcEzCiAXrVWRf2yp3T8PdJ2Zt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cAzWmrfovbkb3pU9mktcEzCiAXrVWRf2yp3T8PdJ2Zt/logo.jpg",
    tags: ["drink", "NFT"],
    verified: true,
    holders: 1193,
  },
  {
    chainId: 101,
    name: "VI",
    symbol: "VI",
    address: "7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC/logo.png",
    tags: [
      "social-token",
      "community-token",
      "utility-token",
      "governance-token",
    ],
    verified: true,
    holders: 1641,
  },
  {
    chainId: 101,
    name: "SolanaBullDog",
    symbol: "SolBullDog",
    address: "DiJWJ6hgV7Vm5JP6SU7xvo7nULR14UvrGoWmSu34fEvZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiJWJ6hgV7Vm5JP6SU7xvo7nULR14UvrGoWmSu34fEvZ/logo.png",
    tags: ["meme-token", "NFT"],
    verified: true,
    holders: 211,
  },
  {
    chainId: 101,
    name: "Solana Condoms",
    symbol: "CONDOMS",
    address: "EzL6LLmv4vgfF7irkjG7ZxM92bTJ9f6nFopDXJTow7zj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzL6LLmv4vgfF7irkjG7ZxM92bTJ9f6nFopDXJTow7zj/logo.png",
    tags: ["Meme", "community", "utility-token", "nft"],
    verified: true,
    holders: 4673,
  },
  {
    chainId: 101,
    name: "GM Solana",
    symbol: "GM",
    address: "CmSryDa4mnDYUicq7qSESsTKAdgBP26jSYcg8zavVoJd",
    decimals: 7,
    logoURI: "https://cdn.jsdelivr.net/gh/brendanhernou/gm/GMSolana.jpg",
    tags: ["social-token", "GM-Solana", "Goodmorning", "GM"],
    verified: true,
    holders: 413,
  },
  {
    chainId: 101,
    name: "Fancy Diamond ICE",
    symbol: "ICE",
    address: "icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF/logo.png",
    tags: ["utility-token", "community-token", "NFTs"],
    verified: true,
    holders: 2791,
  },
  {
    chainId: 101,
    name: "Solar",
    symbol: "Solar",
    address: "2wmKXX1xsxLfrvjEPrt2UHiqj8Gbzwxvffr9qmNjsw8g",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolarDexOfficial/solar_token_icon/main/token-icon.jpeg",
    tags: ["community-token"],
    verified: true,
    holders: 4449,
  },
  {
    chainId: 101,
    name: "WEN Token",
    symbol: "WEN",
    address: "6o4f6tuvVQTa9PTrHN9pvUeXEPusN6RLgMam1Zc7tYbm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6o4f6tuvVQTa9PTrHN9pvUeXEPusN6RLgMam1Zc7tYbm/logo.png",
    tags: ["community-token", "meme-token", "social-token"],
    verified: true,
    holders: 217,
  },
  {
    chainId: 101,
    name: "NeXt-DeFi Protocol",
    symbol: "NXDF",
    address: "Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV/logo.png",
    tags: [],
    verified: true,
    holders: 2486,
  },
  {
    chainId: 101,
    name: "Roar Token",
    symbol: "ROAR",
    address: "DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 2076,
  },
  {
    chainId: 101,
    name: "Solfire Finance",
    symbol: "FIRE",
    address: "AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 2389,
  },
  {
    chainId: 101,
    name: "Theca",
    symbol: "THECA",
    address: "D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q/logo.png",
    tags: ["utility-token", "Application"],
    verified: true,
    holders: 1754,
  },
  {
    chainId: 101,
    name: "SOLANA KING BALL",
    symbol: "SBALL",
    address: "8JSMcHAXLjmdcxXre3Cb6uccbMUSRvLw4NF3WNDP7UVD",
    decimals: 9,
    logoURI: "https://i.ibb.co/DQ0HmQp/logos.png",
    tags: ["meme-token"],
    verified: true,
    holders: 293,
  },
  {
    chainId: 101,
    name: "UNQ Neutrino",
    symbol: "NTRN",
    address: "NTRNt4MmibcfkRHww3Y4WXRwFkXWxLvFXhBJ27YUbVN",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NTRNt4MmibcfkRHww3Y4WXRwFkXWxLvFXhBJ27YUbVN/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 38402,
  },
  {
    chainId: 101,
    name: "UNQ Dark Matter",
    symbol: "DMTR",
    address: "DMTRqrUy2MVRTLZN7wv54sboHZDiiVsbeqXAiCUpDyNZ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMTRqrUy2MVRTLZN7wv54sboHZDiiVsbeqXAiCUpDyNZ/logo.png",
    tags: [],
    verified: true,
    holders: 10009,
  },
  {
    chainId: 101,
    name: "Floki Shiba Solana",
    symbol: "FLOKIS",
    address: "6zQwyDe541Ys4Q85vkhvHMMRW7ypA4PyHCSF1doWZ4tw",
    decimals: 9,
    logoURI: "https://i.imgur.com/aBLrIE0.png",
    tags: ["meme-token"],
    verified: true,
    holders: 187,
  },
  {
    chainId: 101,
    name: "Tipzcoin",
    symbol: "TIPZ",
    address: "DhfF81uahANRYQ4rn8VZKSGVmLE4k2nzccgfZMazmLJ2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhfF81uahANRYQ4rn8VZKSGVmLE4k2nzccgfZMazmLJ2/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1887,
  },
  {
    chainId: 101,
    name: "1sol.io Token",
    symbol: "1SOL",
    address: "4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF/logo.png",
    tags: ["wrapped", "utility-token", "aggregator"],
    verified: true,
    holders: 53287,
  },
  {
    chainId: 101,
    name: "Ween",
    symbol: "WEENS",
    address: "DmXfDUeyRJqnpvdjssGgUXwZrRFPXvu2DfMq4jfTTC9C",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DmXfDUeyRJqnpvdjssGgUXwZrRFPXvu2DfMq4jfTTC9C/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 4824,
  },
  {
    chainId: 101,
    name: "Million Coin Solana",
    symbol: "MCS",
    address: "DAZbw2FG5PCssV24SsP1E3m1whDn8paXZMwBdfVHRt5w",
    decimals: 9,
    logoURI:
      "https://cdn.discordapp.com/attachments/908414498933735525/908414576838733854/Square-512.jpg",
    tags: ["meme-token"],
    verified: true,
    holders: 1734,
  },
  {
    chainId: 101,
    name: "Wensircoin",
    symbol: "WENSIR",
    address: "c8JyuF2fD84G6Vk4AmeVfTUoseNRmv2A9JpymAXi4B6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/c8JyuF2fD84G6Vk4AmeVfTUoseNRmv2A9JpymAXi4B6/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 147,
  },
  {
    chainId: 101,
    name: "ATWORLD",
    symbol: "ATWORLD",
    address: "7yyTQNQdi45pRVCkDXB8yLRcPw9KFvd9P2Bw3u22HUj9",
    decimals: 3,
    logoURI:
      "https://cdn.jsdelivr.net/gh/AbdallaMalik/ATWORLD/ATWORLDtoken/ATWORLD-LOGO.png",
    tags: ["community-token"],
    verified: true,
    holders: 703,
  },
  {
    chainId: 101,
    name: "SHIBA LIGHT",
    symbol: "SHIBT",
    address: "2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp/logo.png",
    tags: ["DEX", "MEME"],
    verified: true,
    holders: 1002,
  },
  {
    chainId: 101,
    name: "Pole Aggregator Governance Token",
    symbol: "POLE",
    address: "PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3/logo.svg",
    tags: [],
    verified: true,
    holders: 1678,
  },
  {
    chainId: 101,
    name: "VITAL Token",
    symbol: "VITAL",
    address: "2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 5033,
  },
  {
    chainId: 101,
    name: "Fluffy Heads Token",
    symbol: "FLUFFY",
    address: "FLuFFY16jePbVTrJ68SY3YbwyZvne2w2ktAVkArpBufG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLuFFY16jePbVTrJ68SY3YbwyZvne2w2ktAVkArpBufG/logo.png",
    tags: ["social-token", "Fluffy"],
    verified: true,
    holders: 4596,
  },
  {
    chainId: 101,
    name: "Space Heads Coin",
    symbol: "HEADS",
    address: "4HecfhYXWiRhfJyjfRAufrxkRocBgwDzRmYAzCLS3VDA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4HecfhYXWiRhfJyjfRAufrxkRocBgwDzRmYAzCLS3VDA/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 642,
  },
  {
    chainId: 101,
    name: "Great Legendary",
    symbol: "GREAT",
    address: "J4D6LT4YqaZBF4snQskq8sYbxjx5P9pXHyXKBcaGbq98",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J4D6LT4YqaZBF4snQskq8sYbxjx5P9pXHyXKBcaGbq98/logo.png",
    tags: ["community-token", "game-token"],
    verified: true,
    holders: 517,
  },
  {
    chainId: 101,
    name: "Cashio Dollar",
    symbol: "CASH",
    address: "CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT/icon.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 10918,
  },
  {
    chainId: 101,
    name: "Cash Cow",
    symbol: "COW",
    address: "CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP/icon.png",
    tags: ["governance-token"],
    verified: true,
    holders: 7441,
  },
  {
    chainId: 101,
    name: "Fenix Danjon",
    symbol: "DJN",
    address: "GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 2665,
  },
  {
    chainId: 101,
    name: "Molar Finance",
    symbol: "MOF",
    address: "6QxNGjZsLaKa35pKnxY2sa7GcUCnWSni9LPfw7KDLqYr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6QxNGjZsLaKa35pKnxY2sa7GcUCnWSni9LPfw7KDLqYr/logo.png",
    tags: ["utility-token", "aggregator", "DEX", "tool", "Exchange"],
    verified: true,
    holders: 3023,
  },
  {
    chainId: 101,
    name: "Frog Fork",
    symbol: "FROGG",
    address: "CXVZgdAGahKyZaTegfJLq5TwjbJkJdSjM3xJHgnSfH26",
    decimals: 6,
    logoURI: "https://i.imgur.com/4S8wvMS.png",
    tags: ["community-token"],
    verified: true,
    holders: 6219,
  },
  {
    chainId: 101,
    name: "edeXa Service Token",
    symbol: "EDX",
    address: "3Ysmnbdddpxv9xK8FUKXexdhRzEA4yrCz8WaE6Za5sjV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Ysmnbdddpxv9xK8FUKXexdhRzEA4yrCz8WaE6Za5sjV/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 420,
  },
  {
    chainId: 101,
    name: "MetaGameVerse",
    symbol: "MTGVERSE",
    address: "AbJ2e39BhfPpvpnrf5gupAL9W9yZW3zXxd8hsi2ZjQb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AbJ2e39BhfPpvpnrf5gupAL9W9yZW3zXxd8hsi2ZjQb/logo.png",
    tags: ["NFTs", "Gaming", "Ecosystem"],
    verified: true,
    holders: 427,
  },
  {
    chainId: 101,
    name: "Shrimp Coin",
    symbol: "SHRIMP",
    address: "4AHDENUSystAUR3VEgcUFLYAVL4BGNhLgq8uaAaKoQKq",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4AHDENUSystAUR3VEgcUFLYAVL4BGNhLgq8uaAaKoQKq/logo.png",
    tags: ["community-token", "utility-token", "flamingo-party", "shrimp-coin"],
    verified: true,
    holders: 688,
  },
  {
    chainId: 101,
    name: "Saber Wrapped USD Coin (8 decimals)",
    symbol: "sUSDC-8",
    address: "88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy/icon.png",
    tags: ["stablecoin", "saber-market-usd", "saber-dec-wrapped"],
    verified: true,
    holders: 7195,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Bitcoin (Sollet) (9 decimals)",
    symbol: "sBTC-9",
    address: "9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H/icon.png",
    tags: [
      "wrapped-sollet",
      "ethereum",
      "saber-market-btc",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 142,
  },
  {
    chainId: 101,
    name: "Saber Wrapped USDT (9 decimals)",
    symbol: "sUSDT-9",
    address: "AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV/icon.png",
    tags: ["stablecoin", "saber-market-usd", "saber-dec-wrapped"],
    verified: true,
    holders: 4627,
  },
  {
    chainId: 101,
    name: "Saber Wrapped renBTC (10 decimals)",
    symbol: "srenBTC-10",
    address: "BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi",
    decimals: 10,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi/icon.png",
    tags: ["saber-market-btc", "saber-dec-wrapped"],
    verified: true,
    holders: 401,
  },
  {
    chainId: 101,
    name: "Saber Wrapped CASH (9 decimals)",
    symbol: "sCASH-9",
    address: "C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi/icon.png",
    tags: ["stablecoin", "saber-market-usd", "saber-dec-wrapped"],
    verified: true,
    holders: 771,
  },
  {
    chainId: 101,
    name: "Saber Wrapped renBTC (9 decimals)",
    symbol: "srenBTC-9",
    address: "FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5/icon.png",
    tags: ["saber-market-btc", "saber-dec-wrapped"],
    verified: true,
    holders: 1333,
  },
  {
    chainId: 101,
    name: "Saber Wrapped FTT (Portal) (9 decimals)",
    symbol: "swFTT-9",
    address: "FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx/icon.png",
    tags: ["saber-market-ftt", "wormhole-v2", "saber-dec-wrapped"],
    verified: true,
    holders: 437,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Wrapped FTT (Sollet) (9 decimals)",
    symbol: "sFTT-9",
    address: "FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt/icon.png",
    tags: [
      "wrapped-sollet",
      "ethereum",
      "saber-market-ftt",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 3656,
  },
  {
    chainId: 101,
    name: "Saber Wrapped USD Coin (9 decimals)",
    symbol: "sUSDC-9",
    address: "JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1/icon.png",
    tags: ["stablecoin", "saber-market-usd", "saber-dec-wrapped"],
    verified: true,
    holders: 29864,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Ether (Portal) (9 decimals)",
    symbol: "swhETH-9",
    address: "KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma/icon.png",
    tags: ["saber-market-eth", "wormhole-v2", "saber-dec-wrapped"],
    verified: true,
    holders: 1734,
  },
  {
    chainId: 101,
    name: "Saber Wrapped renLUNA (9 decimals)",
    symbol: "srenLUNA-9",
    address: "KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT/icon.png",
    tags: ["saber-market-luna", "saber-dec-wrapped"],
    verified: true,
    holders: 1817,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Bitcoin (Sollet) (8 decimals)",
    symbol: "sBTC-8",
    address: "SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv/icon.png",
    tags: [
      "wrapped-sollet",
      "ethereum",
      "saber-market-btc",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 4410,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Ethereum (Sollet) (8 decimals)",
    symbol: "sETH-8",
    address: "SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj/icon.png",
    tags: [
      "wrapped-sollet",
      "ethereum",
      "saber-market-eth",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 2969,
  },
  {
    chainId: 101,
    name: "Crypto Mushroomz",
    symbol: "SHROOMZ",
    address: "2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL/logo.png",
    tags: ["community-token", "utility-token", "social-token", "NFTs", "DeFi"],
    verified: true,
    holders: 3095,
  },
  {
    chainId: 101,
    name: "tudaBirds Token",
    symbol: "BURD",
    address: "Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 5324,
  },
  {
    chainId: 101,
    name: "BLOCKS",
    symbol: "BLOCKS",
    address: "8nBNn2TcXnBFzc8TjoafWxLWw2BYHyNebZQ1w9PrWUs2",
    decimals: 3,
    logoURI: "https://i.imgur.com/Hex1oqB.png",
    tags: ["community", "NFTs"],
    verified: true,
    holders: 489,
  },
  {
    chainId: 101,
    name: "Wrapped USDT (Allbridge from BSC)",
    symbol: "abUSDT",
    address: "E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 3635,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Allbridge from BSC)",
    symbol: "abUSDC",
    address: "8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 6124,
  },
  {
    chainId: 101,
    name: "Wrapped BTC (Allbridge from BSC)",
    symbol: "abBTCB",
    address: "3os2M3bX9qta154PRbU9rzaPUYAKAqVpaMMS8u2hoUQu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
    tags: [],
    verified: true,
    holders: 380,
  },
  {
    chainId: 101,
    name: "Wrapped ETH (Allbridge from BSC)",
    symbol: "abETH",
    address: "EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
    tags: [],
    verified: true,
    holders: 577,
  },
  {
    chainId: 101,
    name: "LIQNFT.com",
    symbol: "LQN",
    address: "GD8B6KhufThbCGmNodrTE5feVxU2kK5TRzwxqjahv12G",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GD8B6KhufThbCGmNodrTE5feVxU2kK5TRzwxqjahv12G/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2154,
  },
  {
    chainId: 101,
    name: "Rollbit Coin",
    symbol: "RLB",
    address: "RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png",
    tags: ["utility-token", "community-token", "stake-pool-token", "lottery"],
    verified: true,
    holders: 138580,
  },
  {
    chainId: 101,
    name: "Spark",
    symbol: "SPARK",
    address: "SparkXuAqXDH1zhJGdkCRLtg6xHqJkNzRY1PCnLrV9v",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/codeholic/token-list/main/assets/mainnet/SparkXuAqXDH1zhJGdkCRLtg6xHqJkNzRY1PCnLrV9v.svg",
    tags: [],
    verified: true,
    holders: 184,
  },
  {
    chainId: 101,
    name: "APEC",
    symbol: "APEC",
    address: "5MoDJmq3JqsNnottRdot3WHmhmD6h8VRodqsBKVu9LPk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5MoDJmq3JqsNnottRdot3WHmhmD6h8VRodqsBKVu9LPk/logo.png",
    tags: ["DeFi", "meme-token"],
    verified: true,
    holders: 133,
  },
  {
    chainId: 101,
    name: "Saber abUSDC-USDC LP",
    symbol: "abUSDC-USDC",
    address: "ALPX6x8FkkdQyn9YuoVZjPAapL4nUC7vjJ3AtwStmj9P",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPX6x8FkkdQyn9YuoVZjPAapL4nUC7vjJ3AtwStmj9P/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-allbridge"],
    verified: true,
    holders: 695,
  },
  {
    chainId: 101,
    name: "Saber abETH-weWETH LP",
    symbol: "abETH-weWETH",
    address: "ALPi51sXwH9kNcQuneDwz7kPzJs3hJ5xV6SmnuyqodQP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPi51sXwH9kNcQuneDwz7kPzJs3hJ5xV6SmnuyqodQP/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-allbridge"],
    verified: true,
    holders: 286,
  },
  {
    chainId: 101,
    name: "Saber wbUSDT-USDT LP",
    symbol: "wbUSDT-USDT",
    address: "WLPAEUgB95YrU7Vk1FVPeSP5C3e66bf63frHRgsyxHv",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPAEUgB95YrU7Vk1FVPeSP5C3e66bf63frHRgsyxHv/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-wormhole-v2"],
    verified: true,
    holders: 522,
  },
  {
    chainId: 101,
    name: "Flock",
    symbol: "FLOCK",
    address: "Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ/logo.png",
    tags: ["utlity-token"],
    verified: true,
    holders: 1801,
  },
  {
    chainId: 101,
    name: "SolanaCash",
    symbol: "SOLCH",
    address: "EKSM2sjtptnvkqq79kwfAaSfVudNAtFYZSBdPe5jeRSt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKSM2sjtptnvkqq79kwfAaSfVudNAtFYZSBdPe5jeRSt/logo.png",
    tags: ["DeFi"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "Port Finance USDC",
    symbol: "pUSDC",
    address: "FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
    tags: ["port", "lending", "collateral-tokens"],
    verified: true,
    holders: 6897,
  },
  {
    chainId: 101,
    name: "Port Finance SOL",
    symbol: "pSOL",
    address: "8ezDtNNhX91t1NbSLe8xV2PcCEfoQjEm2qDVGjt3rjhg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ezDtNNhX91t1NbSLe8xV2PcCEfoQjEm2qDVGjt3rjhg/SOL.svg",
    tags: ["port", "lending", "collateral-tokens"],
    verified: true,
    holders: 3817,
  },
  {
    chainId: 101,
    name: "Port Finance USDT",
    symbol: "pUSDT",
    address: "3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ/USDT.svg",
    tags: ["port", "lending", "collateral-tokens"],
    verified: true,
    holders: 2026,
  },
  {
    chainId: 101,
    name: "Port Finance mSOL",
    symbol: "pmSOL",
    address: "Dt1Cuau5m5CSmun8hZstjEh9RszxAmejnq7ZaHNcuXfA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dt1Cuau5m5CSmun8hZstjEh9RszxAmejnq7ZaHNcuXfA/mSOL.svg",
    tags: ["port", "lending", "collateral-tokens"],
    verified: true,
    holders: 4019,
  },
  {
    chainId: 101,
    name: "Samo Shib",
    symbol: "SamoShib",
    address: "BDCyYhw4zvf8wx3e8rXcrcYnmsX5BxF7R6g5ooXqu3uL",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDCyYhw4zvf8wx3e8rXcrcYnmsX5BxF7R6g5ooXqu3uL/logo.png",
    tags: ["Meme-Token", "NFTs", "Meme-NFTs"],
    verified: true,
    holders: 5577,
  },
  {
    chainId: 101,
    name: "Garlic",
    symbol: "GRLC",
    address: "88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2/logo.png",
    tags: ["utility-token", "nfts"],
    verified: true,
    holders: 655,
  },
  {
    chainId: 101,
    name: "Saber Wrapped USDT (8 decimals)",
    symbol: "sUSDT-8",
    address: "T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw/icon.png",
    tags: ["stablecoin", "saber-mkt-usd", "saber-dec-wrapped"],
    verified: true,
    holders: 1167,
  },
  {
    chainId: 101,
    name: "Solana King Empire",
    symbol: "SKE",
    address: "Hgvs5aM4SXy4YHsZZXUBcnvNXFmop6XCzAFGnkamQdRq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hgvs5aM4SXy4YHsZZXUBcnvNXFmop6XCzAFGnkamQdRq/logo.png",
    tags: [],
    verified: true,
    holders: 1985,
  },
  {
    chainId: 101,
    name: "Yorkipoo",
    symbol: "YORK",
    address: "5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg/logo.png",
    tags: ["community-token", "meme-token", "social-token"],
    verified: true,
    holders: 3758,
  },
  {
    chainId: 101,
    name: "winter token",
    symbol: "winter",
    address: "A2yu4dj4VCbXmV9Z58Vk6TEP5MwoHCZF22HpY9rjSTvx",
    decimals: 9,
    logoURI:
      "https://cdn.jsdelivr.net/gh/Samodoge/solwinterlogo@master/solwinter.png",
    tags: ["community-token"],
    verified: true,
    holders: 468,
  },
  {
    chainId: 101,
    name: "Trust Wallet (Portal)",
    symbol: "TWT",
    address: "HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 770,
  },
  {
    chainId: 101,
    name: "Serious Internet Money",
    symbol: "SIM",
    address: "w71tgPPw37F7sCxxq1bcT63D3dtV6bJ7MUD3q7fpLqg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/w71tgPPw37F7sCxxq1bcT63D3dtV6bJ7MUD3q7fpLqg/logo.png",
    tags: ["utility-token", "dao", "nfts", "staking", "nft-token"],
    verified: true,
    holders: 1932,
  },
  {
    chainId: 101,
    name: "BONK",
    symbol: "BONK",
    address: "6dhTynDkYsVM7cbF7TKfC9DWB636TcEM935fq7JzL2ES",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6dhTynDkYsVM7cbF7TKfC9DWB636TcEM935fq7JzL2ES/logo.png",
    tags: [],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "CRY Coin",
    symbol: "CRYY",
    address: "56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 13426,
  },
  {
    chainId: 101,
    name: "Moonrace",
    symbol: "MOONRACE",
    address: "vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn/logo.png",
    tags: ["meme-token", "metaverse", "gaming"],
    verified: true,
    holders: 251,
  },
  {
    chainId: 101,
    name: "Infinity Solana",
    symbol: "ISOL",
    address: "9gVyinZd945tJddQBP7Lwo97kUpos7zNw6VkBA55VyPd",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gVyinZd945tJddQBP7Lwo97kUpos7zNw6VkBA55VyPd/logo.png",
    tags: ["stake-pool-token"],
    verified: true,
    holders: 194,
  },
  {
    chainId: 101,
    name: "Fuel",
    symbol: "FUEL",
    address: "9SqXkCCpcRGrimjMfPdof9awceRHUEwy5qvmTgW779QD",
    decimals: 9,
    logoURI:
      "https://arweave.net/8shYrOoCPffqqQTwuGm2BXbzAllV0OtRCNZ_SMyvg7c?ext=png",
    tags: [],
    verified: true,
    holders: 136,
  },
  {
    chainId: 101,
    name: "BofB",
    symbol: "BOFB",
    address: "45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq/logo.svg",
    tags: [
      "utility-token",
      "social-token",
      "community-token",
      "BankOfBitcoin",
      "bofb",
      "metaverse",
    ],
    verified: true,
    holders: 435,
  },
  {
    chainId: 101,
    name: "Wrapped MIM (Allbridge from Ethereum)",
    symbol: "aeMIM",
    address: "2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj/logo.png",
    tags: [],
    verified: true,
    holders: 434,
  },
  {
    chainId: 101,
    name: "Wrapped FEI (Allbridge from Ethereum)",
    symbol: "aeFEI",
    address: "BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2/logo.png",
    tags: [],
    verified: true,
    holders: 286,
  },
  {
    chainId: 101,
    name: "Wrapped BTC (Allbridge from Fantom)",
    symbol: "afBTC",
    address: "FdvkkCbCgYht1xTR1W9MBJhEF1JEPVhHtW1NXBYRzZts",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
    tags: [],
    verified: true,
    holders: 242,
  },
  {
    chainId: 101,
    name: "Wrapped ETH (Allbridge from Fantom)",
    symbol: "afETH",
    address: "BiryxNvVTABRs3pEE4fvVuu4d17aAYEsNmcPnJ8E8WeT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
    tags: [],
    verified: true,
    holders: 306,
  },
  {
    chainId: 101,
    name: "Wrapped USDC (Allbridge from Fantom)",
    symbol: "afUSDC",
    address: "Grk6b4UMRWkgyq4Y6S1BnNRF4hRgtnMFp7Sorkv6Ez4u",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 2228,
  },
  {
    chainId: 101,
    name: "Wrapped DAI (Allbridge from Fantom)",
    symbol: "afDAI",
    address: "HjUhUzi6fVkY1BndaSc4Dcg2mCzvnqzXjVJtXsj78ver",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 319,
  },
  {
    chainId: 101,
    name: "TRUMPXSOL",
    symbol: "TrumpcoinXSOL",
    address: "B6NyNs3k2DZm6XYNL5wyC8sWEkEL4S8eekonRaSjjD7B",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B6NyNs3k2DZm6XYNL5wyC8sWEkEL4S8eekonRaSjjD7B/logo.png",
    tags: [],
    verified: true,
    holders: 244,
  },
  {
    chainId: 101,
    name: "Pole Aggregator USDC",
    symbol: "poUSDC",
    address: "C9PXceoq8uho2RZDGdtxYmyGYXeJUABaUP4qn37wQCgB",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9PXceoq8uho2RZDGdtxYmyGYXeJUABaUP4qn37wQCgB/USDC.svg",
    tags: ["pole", "aggregator"],
    verified: true,
    holders: 2561,
  },
  {
    chainId: 101,
    name: "Cheeze",
    symbol: "CHEEZE",
    address: "3oePHsi4fhoyuLAjqXEgBUPB1cs4bP9A8cZpc1dATS9c",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3oePHsi4fhoyuLAjqXEgBUPB1cs4bP9A8cZpc1dATS9c/logo.png",
    tags: [],
    verified: true,
    holders: 1522,
  },
  {
    chainId: 101,
    name: "Culture Coin",
    symbol: "$CULT",
    address: "9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne/logo.png",
    tags: ["nft-token"],
    verified: true,
    holders: 2063,
  },
  {
    chainId: 101,
    name: "Frog",
    symbol: "$FROG",
    address: "Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD/logo.png",
    tags: [],
    verified: true,
    holders: 1260,
  },
  {
    chainId: 101,
    name: "Dominus",
    symbol: "DOM",
    address: "7H4Co5vUfRGuYCHFitwCr2iCvKpv7QiRA8hFfwa1y4x3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7H4Co5vUfRGuYCHFitwCr2iCvKpv7QiRA8hFfwa1y4x3/logo.png",
    tags: [],
    verified: true,
    holders: 182,
  },
  {
    chainId: 101,
    name: "Keeshond Coin",
    symbol: "$KSH",
    address: "6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8/logo.png",
    tags: ["Meme-Token", "NFT"],
    verified: true,
    holders: 5331,
  },
  {
    chainId: 101,
    name: "Pixels.so Token",
    symbol: "PIXL",
    address: "5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE/logo.png",
    tags: ["utility-token", "NFT", "DeFi"],
    verified: true,
    holders: 1532,
  },
  {
    chainId: 101,
    name: "SOL Frens",
    symbol: "FREN",
    address: "7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt/logo.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 2249,
  },
  {
    chainId: 101,
    name: "Flower Solana",
    symbol: "FLOW",
    address: "GqvM4xqeegtVgzY5mbEoq8Z9Wf8XpHuAxpg7gXN2dQ37",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GqvM4xqeegtVgzY5mbEoq8Z9Wf8XpHuAxpg7gXN2dQ37/logo.png",
    tags: ["community-token", "meme-token", "MetaVerse"],
    verified: true,
    holders: 2865,
  },
  {
    chainId: 101,
    name: "Super Mario",
    symbol: "MARIO",
    address: "7rmV64vLfbrbS5rTFvojYWzCVEn8dnJ9RfSRx3nD6C5E",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rmV64vLfbrbS5rTFvojYWzCVEn8dnJ9RfSRx3nD6C5E/logo.png",
    tags: ["Community-token", "Meme", "NFT"],
    verified: true,
    holders: 421,
  },
  {
    chainId: 101,
    name: "Bitcoin NFT Coin",
    symbol: "BTCNFT",
    address: "iobVLW6ePUrcX7Y4DzW69QDpQt2CkJ6c3SZmaLozibj",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iobVLW6ePUrcX7Y4DzW69QDpQt2CkJ6c3SZmaLozibj/logo.png",
    tags: ["nft-token", "btc-nft"],
    verified: true,
    holders: 17932,
  },
  {
    chainId: 101,
    name: "Banana Solana Token",
    symbol: "BNANA",
    address: "D4NXEPTGUcwntfyY3P1w8F7kpLbs2vn4G5LmwDF9ERPY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4NXEPTGUcwntfyY3P1w8F7kpLbs2vn4G5LmwDF9ERPY/logo.png",
    tags: [],
    verified: true,
    holders: 793,
  },
  {
    chainId: 101,
    name: "MetaDrago Token",
    symbol: "GON",
    address: "v7bs339b8oqXgYGJu4mX9cB8tZrFVSozBjW6QZZKE3m",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/v7bs339b8oqXgYGJu4mX9cB8tZrFVSozBjW6QZZKE3m/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1935,
  },
  {
    chainId: 101,
    name: "Wrapped Staked Sol Invictus",
    symbol: "sIN",
    address: "sinjBMHhAuvywW3o87uXHswuRXb3c7TfqgAdocedtDj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sinjBMHhAuvywW3o87uXHswuRXb3c7TfqgAdocedtDj/logo.png",
    tags: ["decentralizedreserve", "utility-token", "DeFi", "community-token"],
    verified: true,
    holders: 29347,
  },
  {
    chainId: 101,
    name: "Dogelana Token",
    symbol: "DLANA",
    address: "DogeLZECE9CthXasBLFxgeA2umEyt8CcV7Jsf6P5ZTFo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DogeLZECE9CthXasBLFxgeA2umEyt8CcV7Jsf6P5ZTFo/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 2083,
  },
  {
    chainId: 101,
    name: "VooVoo",
    symbol: "VOO",
    address: "55t1PfJngPgMS4c4HeSHPy54VWfkMEk7XBQhSkdz6Cm6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55t1PfJngPgMS4c4HeSHPy54VWfkMEk7XBQhSkdz6Cm6/logo.png",
    tags: ["VOO-token"],
    verified: true,
    holders: 308,
  },
  {
    chainId: 101,
    name: "Culture of Women Token",
    symbol: "CoW",
    address: "CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/cultureofwomen/CoW-token/CoW.jpeg",
    tags: ["utility-token", "meme-token", "nfts"],
    verified: true,
    holders: 1917,
  },
  {
    chainId: 101,
    name: "Such Shiba",
    symbol: "SUCH",
    address: "HnZiKrSKYQkEfzjQs6qkvuGbBmrBP9YzjB1SMM7tiGZ1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnZiKrSKYQkEfzjQs6qkvuGbBmrBP9YzjB1SMM7tiGZ1/logo.png",
    tags: ["community-token", "social-token", "meme-token"],
    verified: true,
    holders: 3033,
  },
  {
    chainId: 101,
    name: "NetworkChuck Coin",
    symbol: "NTCK",
    address: "A4zyBooAFkpfy7osonRJMQ8a6zArGxN5fNXjXo1ZTZK2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/NetworkChuckCoin/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 1833,
  },
  {
    chainId: 101,
    name: "KYO",
    symbol: "KYO",
    address: "BFJ6rAVroCPaYbvgcT11ku4NG4KbfX3gWDGrbEUJ7r34",
    decimals: 9,
    logoURI: "https://i.imgur.com/BLTZykc.png",
    tags: ["utility-token"],
    verified: true,
    holders: 265,
  },
  {
    chainId: 101,
    name: "SOLsquid",
    symbol: "SOLsquid",
    address: "AtgKBiAwAmAbSNaZCSfvvCCuypsqbHgry2MmaE1PUh2Z",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtgKBiAwAmAbSNaZCSfvvCCuypsqbHgry2MmaE1PUh2Z/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 3976,
  },
  {
    chainId: 101,
    name: "GUM ARABIC",
    symbol: "GUMA",
    address: "BXVR8wqs8ixPMHnuUq65buJQmimwG9WG5pNKKKBRd2S4",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/AbdallaMalik/GUMA/logo/GUMA.jpg",
    tags: ["Trading-token"],
    verified: true,
    holders: 610,
  },
  {
    chainId: 101,
    name: "Nebula Stars Anthill",
    symbol: "NSA",
    address: "4CuBdvKbwy1wo8o9sDs7mUQZTL1weKitpBA6X2D5a1nX",
    decimals: 9,
    logoURI:
      "https://cdn.jsdelivr.net/gh/nonconforme/nebula_stars_anthill/nebula_logo_mini.png",
    tags: ["social-token", "farming", "nebula"],
    verified: true,
    holders: 560,
  },
  {
    chainId: 101,
    name: "Fora",
    symbol: "FORA",
    address: "ForaXiBD8K3a7C1PwxV1xqDHs5aV8y8nWRmHebafdkes",
    decimals: 9,
    logoURI: "https://www.fora.co/images/Fora+Logo+Solana/icon.png",
    tags: ["fora", "utility-token"],
    verified: true,
    holders: 15854,
  },
  {
    chainId: 101,
    name: "FACT Token",
    symbol: "FACT",
    address: "GiaaHW6H1gYM6ygzug2gSpGMAy7NAMCQGHsT4LgXZxwY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiaaHW6H1gYM6ygzug2gSpGMAy7NAMCQGHsT4LgXZxwY/logo.png",
    tags: ["FACTToken"],
    verified: true,
    holders: 1813,
  },
  {
    chainId: 101,
    name: "TOYPOODLE COIN",
    symbol: "TPC",
    address: "5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 2492,
  },
  {
    chainId: 101,
    name: "SolFortune Token",
    symbol: "SFT",
    address: "7EpBKD7A6F8dpms4ZAC9mkKSnzHeEwx4sHi82G65rMh2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7EpBKD7A6F8dpms4ZAC9mkKSnzHeEwx4sHi82G65rMh2/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2743,
  },
  {
    chainId: 101,
    name: "WAMO",
    symbol: "WAMO",
    address: "4nQqJkBx3Dnovc6ueEdkJfFr2zi2fc77834czmoymR1b",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nQqJkBx3Dnovc6ueEdkJfFr2zi2fc77834czmoymR1b/symbol.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 747,
  },
  {
    chainId: 101,
    name: "Apes On Dragons",
    symbol: "AoD",
    address: "AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca/logo.png",
    tags: [],
    verified: true,
    holders: 370,
  },
  {
    chainId: 101,
    name: "Flow Coin",
    symbol: "FLW",
    address: "7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 486,
  },
  {
    chainId: 101,
    name: "FCKU",
    symbol: "FCKU",
    address: "BHcuncUCUxsBw1yyENizoseEAH2Qrt4UxbcukPhQEGPQ",
    decimals: 9,
    logoURI: "https://i.imgur.com/KG422cB.jpeg",
    tags: ["utility-token", "NFTS", "Community-Token"],
    verified: true,
    holders: 570,
  },
  {
    chainId: 101,
    name: "TUT",
    symbol: "TUT",
    address: "6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 4005,
  },
  {
    chainId: 101,
    name: "PARTNER COIN",
    symbol: "PTR",
    address: "3UcBMHnSTCXaxUbP6B96kHcED98DgEnNa9rGKzwXKMf4",
    decimals: 0,
    logoURI:
      "https://user-images.githubusercontent.com/10572843/146142464-373cc98d-d147-4e68-84fc-f5e80bb67de6.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 9780,
  },
  {
    chainId: 101,
    name: "RII TOKEN",
    symbol: "RII",
    address: "X2y9KtLFWCRomutronoPoXDmcGrBoyYgMajTFH51EFG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/X2y9KtLFWCRomutronoPoXDmcGrBoyYgMajTFH51EFG/logo.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 1469,
  },
  {
    chainId: 101,
    name: "Super Cat Sol",
    symbol: "SCS",
    address: "Cm3dsFkMtydExWib9pAxueaWhzMaZxJ5CWjMNT9tq2Eu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cm3dsFkMtydExWib9pAxueaWhzMaZxJ5CWjMNT9tq2Eu/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1183,
  },
  {
    chainId: 101,
    name: "Steps Token",
    symbol: "TEPS",
    address: "6r9ThcybVr3pbdyZHxUuABHNZJWtmcqZAYyK1HCs62fN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6r9ThcybVr3pbdyZHxUuABHNZJWtmcqZAYyK1HCs62fN/logo.png",
    tags: ["walk-token", "NFT", "utility-token"],
    verified: true,
    holders: 383,
  },
  {
    chainId: 101,
    name: "Pollen Coin",
    symbol: "PCN",
    address: "GWsZd8k85q2ie9SNycVSLeKkX7HLZfSsgx6Jdat9cjY1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWsZd8k85q2ie9SNycVSLeKkX7HLZfSsgx6Jdat9cjY1/logo.svg",
    tags: [
      "utility-token",
      "gaming",
      "play-to-earn",
      "solana-ecosystem",
      "network",
    ],
    verified: true,
    holders: 1104,
  },
  {
    chainId: 101,
    name: "PHASE",
    symbol: "PHASE",
    address: "FZxUbyQ9oeFiSDaabw8KfAWACsDMQhbuxR9vm2Rh7Ewm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZxUbyQ9oeFiSDaabw8KfAWACsDMQhbuxR9vm2Rh7Ewm/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1619,
  },
  {
    chainId: 101,
    name: "Salad Cat Coin",
    symbol: "SLCAT",
    address: "5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 4773,
  },
  {
    chainId: 101,
    name: "YodaCoin",
    symbol: "YODA",
    address: "YodaXmvJfRMEecpYacvcvDEM3TCom6dVdFik4x8HyFe",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YodaXmvJfRMEecpYacvcvDEM3TCom6dVdFik4x8HyFe/logo.jpg",
    tags: [],
    verified: true,
    holders: 1370,
  },
  {
    chainId: 101,
    name: "FOSSIL",
    symbol: "FOSSIL",
    address: "6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1432,
  },
  {
    chainId: 101,
    name: "The Suites Token",
    symbol: "SUITE",
    address: "4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh/logo.png",
    tags: ["NFT", "GAME"],
    verified: true,
    holders: 1002,
  },
  {
    chainId: 101,
    name: "Fractionalized SMB-2367",
    symbol: "DAOJONES",
    address: "r8nuuzXCchjtqsmQZVZDPXXq928tuk7KVH479GsKVpy",
    decimals: 2,
    logoURI: "https://arweave.net/ncRp795w-ca_Xb5zkUBtBmxSQ_bcYA49E03NtMoHJMg",
    tags: ["bridgesplit-fraction", "smb", "fnft"],
    verified: true,
    holders: 1888,
  },
  {
    chainId: 101,
    name: "Mickey",
    symbol: "MCK",
    address: "E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 735,
  },
  {
    chainId: 101,
    name: "BACON tokens",
    symbol: "BACON",
    address: "FMBfAxhiTwDmujiEGbexFtExHR9q7nqnRF1Rjd5UmhS7",
    decimals: 9,
    logoURI:
      "https://zplphm7g437efyuli2psx6tmh4nf7ux6zf3iw65xnk5zjltaomca.arweave.net/y9bzs-bm_kLii0afK_psPxpf0v7Jdot7t2q7lK5gcwQ",
    tags: ["utility-token"],
    verified: true,
    holders: 1297,
  },
  {
    chainId: 101,
    name: "Flokiverse",
    symbol: "FLVR",
    address: "3vDfXEw3MZQgjYpLbjoZDYmgVci16CsC6ZDLgUzmcKR2",
    decimals: 9,
    logoURI:
      "https://cdn.jsdelivr.net/gh/DaveBane/Flokiverse/Flokiverselogo.png",
    tags: ["meme-token", "game-token"],
    verified: true,
    holders: 217,
  },
  {
    chainId: 101,
    name: "ABP token",
    symbol: "ABP",
    address: "JB9sPerGhfdwCDajmd8x4y2gkC4EtXVevoAoPwni39ik",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JB9sPerGhfdwCDajmd8x4y2gkC4EtXVevoAoPwni39ik/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2544,
  },
  {
    chainId: 101,
    name: "SOLANA NUT",
    symbol: "SOLNUT",
    address: "AdEnwi15gK4TsPncsyU8evgySt8tZ5vkugG29kz52GLS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AdEnwi15gK4TsPncsyU8evgySt8tZ5vkugG29kz52GLS/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 300,
  },
  {
    chainId: 101,
    name: "Solana Bitcoin",
    symbol: "sBTC",
    address: "k5Ybbtmnd1eAtBZoTqB9uzd24bVPz8Aip5EGVCJXQCM",
    decimals: 8,
    logoURI: "https://i.ibb.co/SmxfhQT/sbtc.jpg",
    tags: ["general"],
    verified: true,
    holders: 326,
  },
  {
    chainId: 101,
    name: "Hound Tokens",
    symbol: "CORP",
    address: "J1inBU6NJahWE6NdSrR3cKr161ZmGdPsM7QTvHfNsD4J",
    decimals: 9,
    logoURI:
      "https://arweave.net/oVaTC19OqJlQvO8KR78ilL1XqTn29BSCisyvyHGrjSg?ext=png",
    tags: [],
    verified: true,
    holders: 480,
  },
  {
    chainId: 101,
    name: "Hydrazine",
    symbol: "N2H4",
    address: "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda/logo.png",
    tags: [],
    verified: true,
    holders: 21761,
  },
  {
    chainId: 101,
    name: "GOD",
    symbol: "GOD",
    address: "9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6/logo.png",
    tags: [],
    verified: true,
    holders: 1218,
  },
  {
    chainId: 101,
    name: "Mortuary Inc Ashes",
    symbol: "$ASH",
    address: "ASHTTPcMddo7RsYHEyTv3nutMWvK8S4wgFUy3seAohja",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASHTTPcMddo7RsYHEyTv3nutMWvK8S4wgFUy3seAohja/logo.svg",
    tags: ["NFT", "social-token", "community-token", "utility-token"],
    verified: true,
    holders: 13068,
  },
  {
    chainId: 101,
    name: "PLUS NFT",
    symbol: "PNFT",
    address: "AUTF8zA3uUMvaK18QAAwrfchzRHK9WdMnaJ2pu5GY68E",
    decimals: 2,
    logoURI: "https://cdn.jsdelivr.net/gh/AbdallaMalik/PNFT/PNFT/PNTF.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6008,
  },
  {
    chainId: 101,
    name: "FANITRADE",
    symbol: "FANI",
    address: "6c4L5nTH2sBKkfeuP3WhGp6Vq1tE4Suh4ezRp5KSu8Z7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6c4L5nTH2sBKkfeuP3WhGp6Vq1tE4Suh4ezRp5KSu8Z7/logo.svg",
    tags: ["fanitrade", "faniswap", "fanipool", "fanidex", "faniverse"],
    verified: true,
    holders: 382,
  },
  {
    chainId: 101,
    name: "NINJA-PIXEL Token",
    symbol: "PIXEL",
    address: "4tVnbp4KY8Ur6A9YQtZfs43r91LkFhdfAjot8wxw3uCF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tVnbp4KY8Ur6A9YQtZfs43r91LkFhdfAjot8wxw3uCF/logo.png",
    tags: ["nft-token"],
    verified: true,
    holders: 2250,
  },
  {
    chainId: 101,
    name: "ONI",
    symbol: "ONI",
    address: "rg3SH72WW9szTMVLUXamk4cNmLxRwdd5KM73vDMrtvK",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rg3SH72WW9szTMVLUXamk4cNmLxRwdd5KM73vDMrtvK/logo.png",
    tags: ["GAMING", "NFT", "UTILITY"],
    verified: true,
    holders: 1035,
  },
  {
    chainId: 101,
    name: "Hono",
    symbol: "Hono",
    address: "BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK/hono.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1868,
  },
  {
    chainId: 101,
    name: "Phantasia",
    symbol: "FANT",
    address: "FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r/logo.png",
    tags: ["utility-token", "governance-token"],
    verified: true,
    holders: 7596,
  },
  {
    chainId: 101,
    name: "BAMBOO (Gorillaz Club)",
    symbol: "BAMBOO",
    address: "54J1tQbQVVPvEcnDqCYNfMe2pEyWEWmix2cb3t1ENim8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54J1tQbQVVPvEcnDqCYNfMe2pEyWEWmix2cb3t1ENim8/logo.png",
    tags: ["utility-token", "community-token", "nfts"],
    verified: true,
    holders: 1540,
  },
  {
    chainId: 101,
    name: "CopyCats token",
    symbol: "PAW",
    address: "3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9",
    decimals: 9,
    logoURI:
      "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/98c9b68b-1494-43d1-0c2c-31e5d0129a00/public",
    tags: [],
    verified: true,
    holders: 996,
  },
  {
    chainId: 101,
    name: "Kayac",
    symbol: "KAYAC",
    address: "AxXoJZhSfeVUe3qgFZTt4NwQRJB61pBQAHTdWTN9PNms",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AxXoJZhSfeVUe3qgFZTt4NwQRJB61pBQAHTdWTN9PNms/KAYAC_LOGO.png",
    tags: ["utility-token", "governance-token", "game-token"],
    verified: true,
    holders: 1348,
  },
  {
    chainId: 101,
    name: "Solidarity Solutions",
    symbol: "$SOLID",
    address: "soL6gkvtJa7wtiqHXPZ2G6g2V9ZdT7GzFRJJPxTRS67",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soL6gkvtJa7wtiqHXPZ2G6g2V9ZdT7GzFRJJPxTRS67/logo.png",
    tags: ["DeFi", "DEX", "Farm", "Stake", "AMM", "ECO"],
    verified: true,
    holders: 1013,
  },
  {
    chainId: 101,
    name: "Squarecoin",
    symbol: "SQRC",
    address: "Bn94VJuH9LtBfNsjWffGzfjfMewbQFL2B26493Evv6zz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bn94VJuH9LtBfNsjWffGzfjfMewbQFL2B26493Evv6zz/logo.png",
    tags: ["meme-token", "nft-token"],
    verified: true,
    holders: 106,
  },
  {
    chainId: 101,
    name: "Altar",
    symbol: "ALTAR",
    address: "GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT/logo.png",
    tags: ["Exchange", "DApp"],
    verified: true,
    holders: 522,
  },
  {
    chainId: 101,
    name: "GNAR",
    symbol: "GNAR",
    address: "74YedyBSKbjYzWMhwuBQz3mwsN6vuSSdAfzX9WLZQUtq",
    decimals: 2,
    logoURI: "https://cdn.jsdelivr.net/gh/AbdallaMalik/GNAR/logo/GNAR.png",
    tags: ["Utility-Token"],
    verified: true,
    holders: 656,
  },
  {
    chainId: 101,
    name: "CLUBB",
    symbol: "CLB",
    address: "EfiqH74NTt8U3PE5gDXWAwF8JHsYWsTzTeskH31D2Qon",
    decimals: 2,
    logoURI: "https://cdn.jsdelivr.net/gh/AbdallaMalik/CLB/logo/CLB.png",
    tags: ["community-token"],
    verified: true,
    holders: 568,
  },
  {
    chainId: 101,
    name: "Saber Wrapped UST (Portal) (9 decimals)",
    symbol: "swtUST-9",
    address: "UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW/icon.png",
    tags: [
      "wrapped",
      "wormhole",
      "saber-mkt-usd",
      "wormhole-v2",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 576,
  },
  {
    chainId: 101,
    name: "Port Finance 2022 March Principal USDC",
    symbol: "ppUSDC",
    address: "6cKnRJskSTonD6kZiWt2Fy3NB6ZND6CbgA3vHiZ1kHEU",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
    tags: ["port", "lending"],
    verified: true,
    holders: 4985,
  },
  {
    chainId: 101,
    name: "Mongoose Coin",
    symbol: "MONGOOSE",
    address: "J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL/logo.png",
    tags: ["MEME", "Community-token", "Mongoose"],
    verified: true,
    holders: 5260,
  },
  {
    chainId: 101,
    name: "ARAB MONEY",
    symbol: "ARAB",
    address: "AHGXtqajWiph31bh5y3MfSK4Bo6JGyt3xpveEZ41ZLaX",
    decimals: 9,
    logoURI:
      "https://rawcdn.githack.com/arabpunkz/oillogo/8f141fb5d74220e3c8a5261508847b716a1b5b4b/arab.png",
    tags: ["community-token"],
    verified: true,
    holders: 1763,
  },
  {
    chainId: 101,
    name: "Infamous Coin",
    symbol: "BUTTER",
    address: "kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa/logo.jpg",
    tags: ["utility-token", "NFTs", "Infamous-Thugbirdz"],
    verified: true,
    holders: 1027,
  },
  {
    chainId: 101,
    name: "AMBROSIA",
    symbol: "AMBR",
    address: "5fibbme9FE1ttgaMHjKnt529va9Pf9oYEjJLU9F3G3s4",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fibbme9FE1ttgaMHjKnt529va9Pf9oYEjJLU9F3G3s4/logo.png",
    tags: ["community-token", "utility-token", "Ambrosians", "AMBROSIA"],
    verified: true,
    holders: 301,
  },
  {
    chainId: 101,
    name: "Koala Klubhouse",
    symbol: "KLUB",
    address: "GD57bW3qQaqk8HkbWrFV3t4tSAeJsjngFRtYwbF1wZhZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GD57bW3qQaqk8HkbWrFV3t4tSAeJsjngFRtYwbF1wZhZ/logo.png",
    tags: ["NFT-token"],
    verified: true,
    holders: 470,
  },
  {
    chainId: 101,
    name: "Hot Air Finance",
    symbol: "HAF",
    address: "T4iPCH1Fo6fGdebxrTnwM79t82DhS4uJJ4xesykUThw",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T4iPCH1Fo6fGdebxrTnwM79t82DhS4uJJ4xesykUThw/logo.png",
    tags: [],
    verified: true,
    holders: 8761,
  },
  {
    chainId: 101,
    name: "LNFT Solsteads-448",
    symbol: "LNFTxBHD448",
    address: "3cVsanUhbo91Qcuzrzx7qZAutpXYcaMr64jfq5Cs17es",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cVsanUhbo91Qcuzrzx7qZAutpXYcaMr64jfq5Cs17es/logo.png",
    tags: ["liqnft-owner-token"],
    verified: true,
    holders: 129,
  },
  {
    chainId: 101,
    name: "Krook Coin",
    symbol: "KROOK",
    address: "AfARcLLqRHsZc4xPWHE9nXZAswZaW294Ff1xcYQbjkLq",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/1009138/143821720-d9c6f5fd-96d7-424f-9b1f-b185451cbb31.png",
    tags: ["social-token"],
    verified: true,
    holders: 1011,
  },
  {
    chainId: 101,
    name: "Kaliza",
    symbol: "AZA",
    address: "ax7EjwgRaerUacfCAptcMnkckmf8Wiee5T9KLqSzsF6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ax7EjwgRaerUacfCAptcMnkckmf8Wiee5T9KLqSzsF6/logo.png",
    tags: [
      "utility-token",
      "secutiry-token",
      "game-currency",
      "launchpad",
      "tokenization",
    ],
    verified: true,
    holders: 1080,
  },
  {
    chainId: 101,
    name: "Breezy Bunnies Carrot",
    symbol: "CRRT",
    address: "HkNokfCXG33eu5vCcS49mq3jZcKZeQSQCyta964YxxYg",
    decimals: 0,
    logoURI: "https://breezybunnies.com/images/3-p-500.png",
    tags: ["nft"],
    verified: true,
    holders: 515,
  },
  {
    chainId: 101,
    name: "AstralApes",
    symbol: "AAP",
    address: "6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/sungminkim01/token-list/main/assets/mainnet/6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M/logo.png",
    tags: [
      "AAP",
      "ASTRALAPES",
      "ASTRALBABYAPECLUB",
      "ASTRALHOUSE",
      "ASTRALJUNGLE",
      "ASTRALMARKET",
    ],
    verified: true,
    holders: 1474,
  },
  {
    chainId: 101,
    name: "WRO NETWORK",
    symbol: "WRO",
    address: "Em9L4m8VZUpRUiajnwmkDMXeJ7yvGfVNZraaGknnsB4A",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Em9L4m8VZUpRUiajnwmkDMXeJ7yvGfVNZraaGknnsB4A/logo.png",
    tags: ["social-token", "fun-token", "meme-token"],
    verified: true,
    holders: 3019,
  },
  {
    chainId: 101,
    name: "LNFT Solana Monkey Business-3719",
    symbol: "LNFTxSMB3719",
    address: "Duch2MmgCar9UGt76smK5HcJ7anBRa31uNZZvNJt3b5S",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Duch2MmgCar9UGt76smK5HcJ7anBRa31uNZZvNJt3b5S/logo.png",
    tags: ["liqnft-owner-token"],
    verified: true,
    holders: 131,
  },
  {
    chainId: 101,
    name: "Venus Protocol",
    symbol: "VENUS",
    address: "GfmUGCL2kY21tqpDoeVYnjP4c57wyQZzamyHjbqU4TKd",
    decimals: 9,
    logoURI:
      "https://cdn.discordapp.com/attachments/904210282719621120/919989957827919932/256x256_2.png",
    tags: ["trading-token", "utility-token"],
    verified: true,
    holders: 1425,
  },
  {
    chainId: 101,
    name: "KIN INU",
    symbol: "KINU",
    address: "inuXXDC6Tw1Fu6qUAfRbELG5x5pSA4afTskf7MvwjhN",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inuXXDC6Tw1Fu6qUAfRbELG5x5pSA4afTskf7MvwjhN/logo.svg",
    tags: ["meme-token"],
    verified: true,
    holders: 3015,
  },
  {
    chainId: 101,
    name: "Force",
    symbol: "$FORCE",
    address: "9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 1278,
  },
  {
    chainId: 101,
    name: "Ant Coin",
    symbol: "ANT",
    address: "BhxPoUPkVASfT9y4ipJ6t4sN8uEuThz9voKaPrkv2sFQ",
    decimals: 9,
    logoURI:
      "https://arweave.net/BchXu9WIA85Zxf1fntkKxDG7UzfsY_IFyVufswXWEMY/ants-logo-black.png",
    tags: ["community-token", "social-token"],
    verified: true,
    holders: 163,
  },
  {
    chainId: 101,
    name: "GOOB",
    symbol: "GOOB",
    address: "2bdHb59xYNtJNf1FuK8Tt96CsZyTTXFMNEDxTgXMCkdq",
    decimals: 9,
    logoURI:
      "https://arweave.net/h1AbbOh8hEcm7pXm9hi_Yk_WaYoihquY7ZWzAvWWIc4?ext=png",
    tags: [],
    verified: true,
    holders: 357,
  },
  {
    chainId: 101,
    name: "VeraSaw",
    symbol: "VRSW",
    address: "ApgNFHXMsY9qM8yaaSVzqX7xtbKkGjgo64NLGuf2hQjW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApgNFHXMsY9qM8yaaSVzqX7xtbKkGjgo64NLGuf2hQjW/logo.png",
    tags: [],
    verified: true,
    holders: 21327,
  },
  {
    chainId: 101,
    name: "Santaclaus",
    symbol: "SANTA",
    address: "EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG/logo.png",
    tags: ["community-token", "utility-token", "meme-token"],
    verified: true,
    holders: 759,
  },
  {
    chainId: 101,
    name: "Medusa",
    symbol: "DUSA",
    address: "GENW9qVvxHMVmRTVokgHRPqSr3syV2AmKoehqrRWiYFu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENW9qVvxHMVmRTVokgHRPqSr3syV2AmKoehqrRWiYFu/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 501,
  },
  {
    chainId: 101,
    name: "Captain Shiba",
    symbol: "CSC",
    address: "3PNqq4kEqgRSkV5dYdcc6mtoaoXdaun9ytoCr4BgX5yA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PNqq4kEqgRSkV5dYdcc6mtoaoXdaun9ytoCr4BgX5yA/logo.png",
    tags: ["community", "NFT"],
    verified: true,
    holders: 205,
  },
  {
    chainId: 101,
    name: "Cyber Ape",
    symbol: "APE",
    address: "E3RN9omoTNdqKXGj988X8JuCXwNZ6ZHXbfpaZ1nVZbrA",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/CyberApeAge/PFPs/main/Cyber%20Ape%20Concept%2043.png",
    tags: ["utility-token", "gaming-token", "gaming", "play-to-earn", "p2e"],
    verified: true,
    holders: 1191,
  },
  {
    chainId: 101,
    name: "Koreapunkz-Won",
    symbol: "Korea-Won",
    address: "DcNJWM1YdAu79kT5fNKxjGDX2SQDMoJwskMj1AaXd92a",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcNJWM1YdAu79kT5fNKxjGDX2SQDMoJwskMj1AaXd92a/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 223,
  },
  {
    chainId: 101,
    name: "SOLASTAR",
    symbol: "STAR",
    address: "2eZ45YBgQEftoxEVyCTWEULLNkDxSVSMrwv7LXiZn4sk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2eZ45YBgQEftoxEVyCTWEULLNkDxSVSMrwv7LXiZn4sk/STAR.jpg",
    tags: ["social-token", "community-token", "meme-token"],
    verified: true,
    holders: 6254,
  },
  {
    chainId: 101,
    name: "Whalemap",
    symbol: "WMP",
    address: "BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/ssi91/crypto/main/logo.svg",
    tags: ["social-token"],
    verified: true,
    holders: 1919,
  },
  {
    chainId: 101,
    name: "Musk Doge",
    symbol: "MKD",
    address: "FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV/logo.png",
    tags: ["utility-token", "meme"],
    verified: true,
    holders: 1232,
  },
  {
    chainId: 101,
    name: "TIGER COIN",
    symbol: "TIGER",
    address: "8z4ghJPp3ccvEtuXZbceGRfoX7AZHhcdwiapYzmsxmyC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8z4ghJPp3ccvEtuXZbceGRfoX7AZHhcdwiapYzmsxmyC/logo.png",
    tags: ["TIGER", "Community-token", "MEME"],
    verified: true,
    holders: 1325,
  },
  {
    chainId: 101,
    name: "Synapses",
    symbol: "Synapses",
    address: "7HGJwFyKC5wPLZ9ctgVSgjBARJ4dZrGCjMRQmzWwe277",
    decimals: 9,
    logoURI:
      "https://arweave.net/h5NOMoLgFKGBZGOkipic6XFRBdCi7PKWLf5qtm-urzg?ext=png",
    tags: [],
    verified: true,
    holders: 147,
  },
  {
    chainId: 101,
    name: "Sendai Tokens",
    symbol: "TALON",
    address: "BjdhCgJcdvvprhY3pQfJgsLfKJCyPFAW6Mt1gB8hExGH",
    decimals: 6,
    logoURI:
      "https://toc24s2sbu3nkr65vejwjh2bmer3x3vo4xbhzcacaxr55ant3o2q.arweave.net/m4WuS1INNtVH3akTZJ9BYSO77q7lwnyIAgXj3oGz27U/?ext=png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 3150,
  },
  {
    chainId: 101,
    name: "CHKRA",
    symbol: "CHKRA",
    address: "FAigNii6yJEraz5LX9JQ9NUieUnk3ucY1eHKjXBGmd4d",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAigNii6yJEraz5LX9JQ9NUieUnk3ucY1eHKjXBGmd4d/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 438,
  },
  {
    chainId: 101,
    name: "-PDS- Golden Token",
    symbol: "$PDSGT",
    address: "C7MnWhwB4JyA21bo6wnomQA5jJtd31WoaiCSKbVTHq5n",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7MnWhwB4JyA21bo6wnomQA5jJtd31WoaiCSKbVTHq5n/PDS_TOKEN.png",
    tags: ["Pirates", "Metaverse", "NFT", "Community-Token"],
    verified: true,
    holders: 199,
  },
  {
    chainId: 101,
    name: "Flower Token",
    symbol: "FLWR",
    address: "FLWRna1gxehQ9pSyZMzxfp4UhewvLPwuKfdUTgdZuMBY",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/Neurologist/brains-token/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2076,
  },
  {
    chainId: 101,
    name: "Solanium Ecosystem Index",
    symbol: "SEI",
    address: "CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Photon",
    symbol: "PTN",
    address: "2ZLYEWypSrQhruqsTDqWNWuzFXe5G75dX9PoHQWtKZ31",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ZLYEWypSrQhruqsTDqWNWuzFXe5G75dX9PoHQWtKZ31/logo.png",
    tags: ["DeFi"],
    verified: true,
    holders: 1013,
  },
  {
    chainId: 101,
    name: "SolanaFi",
    symbol: "SFI",
    address: "8udZmv2RrHpU8rPZhphUGhHpmyAqc9UzV4UihpThKvYh",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8udZmv2RrHpU8rPZhphUGhHpmyAqc9UzV4UihpThKvYh/logo.png",
    tags: [],
    verified: true,
    holders: 308,
  },
  {
    chainId: 101,
    name: "STEPN - Run To Earn",
    symbol: "STEPN",
    address: "6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 567,
  },
  {
    chainId: 101,
    name: "BAIL COIN",
    symbol: "BAIL",
    address: "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/ApeLabsNFT/solpatrol/main/hammer.png",
    tags: ["NFT", "Utility-Token"],
    verified: true,
    holders: 6788,
  },
  {
    chainId: 101,
    name: "OFFSPRING",
    symbol: "SPRING",
    address: "FdLycDiJH5se75GZyxEdEdo6c3z7WHkoHZmvbkibUUrS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdLycDiJH5se75GZyxEdEdo6c3z7WHkoHZmvbkibUUrS/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 842,
  },
  {
    chainId: 101,
    name: "HOLY SHIT",
    symbol: "HOLYSHIT",
    address: "7VsUAYX7fvkGo2sfXCSmoQK8ceEarQYQDDeTBZRrgAw9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Crypto-Never-Sleeps/ShitToken/main/logo.png",
    tags: ["meme-token", "community-token", "social-token"],
    verified: true,
    holders: 106,
  },
  {
    chainId: 101,
    name: "SolChrysalis",
    symbol: "SCHRY",
    address: "77A8ycvZQfwYb3h2Rc4f9masYfug1wKVRRJUPeMA7b6o",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/77A8ycvZQfwYb3h2Rc4f9masYfug1wKVRRJUPeMA7b6o/logo.png",
    tags: ["Games", "NFT"],
    verified: true,
    holders: 3816,
  },
  {
    chainId: 101,
    name: "Crypto Health Plus",
    symbol: "CHP",
    address: "DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn/logo.png",
    tags: ["health", "utility-token"],
    verified: true,
    holders: 295,
  },
  {
    chainId: 101,
    name: "PAC Coin",
    symbol: "PAC",
    address: "7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF/logo.png",
    tags: [],
    verified: true,
    holders: 2930,
  },
  {
    chainId: 101,
    name: "Wrapped UST (Allbridge from Terra)",
    symbol: "atUST",
    address: "A96PoNcxa9LMxcF9HhKAfA1p3M1dGbubPMWf19gHAkgJ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 1787,
  },
  {
    chainId: 101,
    name: "Wrapped CEUR (Allbridge from Celo)",
    symbol: "acEUR",
    address: "7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 130,
  },
  {
    chainId: 101,
    name: "SKIDDY",
    symbol: "SKIDDY",
    address: "7uS8FP88CTrrtwNpzwcJsH7BfSGorn4L9wsfrb1knzKo",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/jaxeaxe/a/main/SKIDDY.png",
    tags: ["social-token", "meme-token"],
    verified: true,
    holders: 159,
  },
  {
    chainId: 101,
    name: "Mineral Token",
    symbol: "$MINERAL",
    address: "FkBRohZpqx2c7zxe5cDhNq3AoFo2nPJus3xtdz9CvQmQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FkBRohZpqx2c7zxe5cDhNq3AoFo2nPJus3xtdz9CvQmQ/Mineral.png",
    tags: ["Energy", "social-token"],
    verified: true,
    holders: 1044,
  },
  {
    chainId: 101,
    name: "Solana Monkey Business Droplet",
    symbol: "SMBD",
    address: "smbdJcLBrtKPhjrWCpDv5ABdJwz2vYo3mm6ojmePL3t",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/smbdJcLBrtKPhjrWCpDv5ABdJwz2vYo3mm6ojmePL3t/logo.png",
    tags: ["utility-token", "solvent-droplet"],
    verified: true,
    holders: 264,
  },
  {
    chainId: 101,
    name: "Elixir",
    symbol: "ELXR",
    address: "JAYDueSTMuhLYZEHZVXJrbSaYtgMfg78zGMpeiPRhxXA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/CryptoMoon8/token-list/main/assets/mainnet/JAYDueSTMuhLYZEHZVXJrbSaYtgMfg78zGMpeiPRhxXA/symbol.png",
    tags: ["general-token", "game-token"],
    verified: true,
    holders: 511,
  },
  {
    chainId: 101,
    name: "Bonfida Governance Token",
    symbol: "gFIDA",
    address: "5vUBtmmHjSfpY1h24XhzEjRKjDyK5jNL9gT2BfM3wcnb",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5vUBtmmHjSfpY1h24XhzEjRKjDyK5jNL9gT2BfM3wcnb/logo.svg",
    tags: ["governance-token"],
    verified: true,
    holders: 803,
  },
  {
    chainId: 101,
    name: "South Park Memes",
    symbol: "SOUTHPARK",
    address: "GUAXo4yYqY335t9esybM4wDPcDeAc7m2mW2xQ2svXzy1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUAXo4yYqY335t9esybM4wDPcDeAc7m2mW2xQ2svXzy1/logo.png",
    tags: ["meme-token", "community-token", "southpark"],
    verified: true,
    holders: 1148,
  },
  {
    chainId: 101,
    name: "Baby Tiger",
    symbol: "BABYTIGER",
    address: "8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD/logo.png",
    tags: ["meme-token", "community-token", "babytiger"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Dook Token",
    symbol: "DOOK",
    address: "FLoD6AwcJCnbznnWfV6HkBHh5FYtr8wJYj3mBnwNdLLg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLoD6AwcJCnbznnWfV6HkBHh5FYtr8wJYj3mBnwNdLLg/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 1430,
  },
  {
    chainId: 101,
    name: "Dreamland Currency",
    symbol: "DREAM",
    address: "eG7L3PRqzNZLrrzoei93zHK2oDmq1dtyVwjFu83RBki",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/dreamlandmonkeys/assets/main/assets/dream.png",
    tags: ["metaverse-token", "community-token", "Dreamland-token"],
    verified: true,
    holders: 627,
  },
  {
    chainId: 101,
    name: "Inanna",
    symbol: "INA",
    address: "HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/781d71f6cb76d2921c7f101fac3aa3010c627cdc/assets/mainnet/HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ/logo.png",
    tags: [],
    verified: true,
    holders: 1131,
  },
  {
    chainId: 101,
    name: "Mighty Sol Wolves",
    symbol: "MSWS",
    address: "ArGrm5N6Sv9d79bosoH7jjteRDVLfX1J2HinPFoBFXz6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArGrm5N6Sv9d79bosoH7jjteRDVLfX1J2HinPFoBFXz6/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 8493,
  },
  {
    chainId: 101,
    name: "PETE Token",
    symbol: "$PETE",
    address: "3S3a5vVR244mrDZWidChYk1wYM6L2Y1nG58EDP4Hdpbx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3S3a5vVR244mrDZWidChYk1wYM6L2Y1nG58EDP4Hdpbx/logo.png",
    tags: ["nft-token"],
    verified: true,
    holders: 1961,
  },
  {
    chainId: 101,
    name: "BRAWNDO",
    symbol: "BRWNDO",
    address: "At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 364,
  },
  {
    chainId: 101,
    name: "BULB Token",
    symbol: "BULB",
    address: "A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Flux",
    symbol: "FLUX",
    address: "FLUX1wa2GmbtSB6ZGi2pTNbVCw3zEeKnaPCkPtFXxqXe",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLUX1wa2GmbtSB6ZGi2pTNbVCw3zEeKnaPCkPtFXxqXe/logo.svg",
    tags: [
      "web3",
      "decentralized-cloud",
      "masternodes",
      "mineable",
      "proof-of-work",
    ],
    verified: true,
    holders: 3827,
  },
  {
    chainId: 101,
    name: "Meme Cake",
    symbol: "MCAKE",
    address: "mCaKentpBgRwisk5zUtx6dfRYkCqMyTKDjjqSDp97mk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mCaKentpBgRwisk5zUtx6dfRYkCqMyTKDjjqSDp97mk/logo.png",
    tags: ["utility-token", "memecake", "social-token"],
    verified: true,
    holders: 936,
  },
  {
    chainId: 101,
    name: "NPC DAO",
    symbol: "NPC",
    address: "EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r/logo.png",
    tags: ["meme-token", "community-token", "fair-launch", "dao-token"],
    verified: true,
    holders: 1154,
  },
  {
    chainId: 101,
    name: "GRUNT Token",
    symbol: "GRUNT",
    address: "J4tV8qjZyzwsYhGmPREDEyehCusPwa7uYm3UssQ6X4A8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/derysendiry/orang/main/logo.png",
    tags: ["community-token", "meme-token", "social-token", "DeFi"],
    verified: true,
    holders: 244,
  },
  {
    chainId: 101,
    name: "Solid Protocol",
    symbol: "SOLID",
    address: "A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1095,
  },
  {
    chainId: 101,
    name: "Neon Game Credits",
    symbol: "$CREDITS",
    address: "4NUoCXBsCVUXPyQL3UmMU3dRUZ3WNQgY1USC7eAY8zSG",
    decimals: 5,
    logoURI:
      "https://rxvwsth6k2wkjbpgqixi4db3ekvlailhvm2xa4pcmqzl6dtctqwa.arweave.net/jetpTP5WrKSF5oIujgw7IqqwIWerNXBx4mQyvw5inCw",
    tags: ["utility-token"],
    verified: true,
    holders: 435,
  },
  {
    chainId: 101,
    name: "RUM",
    symbol: "RUM",
    address: "ADehsNVsuXwdQ5L6JT4YpZrmLumDQ3f1ar2VxBaR3TaL",
    decimals: 9,
    logoURI:
      "https://github.com/charliebakerr/psy-token/blob/f8d05bf0865582bc9d131cb77ba0e51f953f26ab/RUM.png",
    tags: [],
    verified: true,
    holders: 631,
  },
  {
    chainId: 101,
    name: "Cyber Flyz",
    symbol: "FLYZ",
    address: "GqiHtXj18aaWs82UBz7D72x934EQLZNhpeUpfJwS3Scm",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/93989494/146803309-9886040f-2e3c-46c0-bd57-69e15f088ac5.png",
    tags: [],
    verified: true,
    holders: 2698,
  },
  {
    chainId: 101,
    name: "AlfProtocol",
    symbol: "ALF",
    address: "36s9cAKFqea4gGFwAjG92Aoo3Zx5L3AbqBB519QeKsZU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36s9cAKFqea4gGFwAjG92Aoo3Zx5L3AbqBB519QeKsZU/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1333,
  },
  {
    chainId: 101,
    name: "Meeb Coin",
    symbol: "MEEB",
    address: "meebAU3nZrU5PbUt3dVK6ExgbNWCUAkV7C3DaJKMZZ4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/meebAU3nZrU5PbUt3dVK6ExgbNWCUAkV7C3DaJKMZZ4/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 729,
  },
  {
    chainId: 101,
    name: "QuietFire",
    symbol: "QF",
    address: "76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 486,
  },
  {
    chainId: 101,
    name: "Goekdln",
    symbol: "DLN",
    address: "8fd5eUPMNHuyKRshFbfmKRAm2gowJ75m8WjT7tLio6J3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8fd5eUPMNHuyKRshFbfmKRAm2gowJ75m8WjT7tLio6J3/logo.svg",
    tags: ["community-token", "meme-token", "social-token", "utility-token"],
    verified: true,
    holders: 309,
  },
  {
    chainId: 101,
    name: "MetaToken",
    symbol: "MTK",
    address: "ANXqXpSkTEuCnR27YK2AoHNH2CCbiSaKYAKcDQVMi6ar",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANXqXpSkTEuCnR27YK2AoHNH2CCbiSaKYAKcDQVMi6ar/logo.png",
    tags: [],
    verified: true,
    holders: 12273,
  },
  {
    chainId: 101,
    name: "Magic Square Token",
    symbol: "SQR",
    address: "SQRNmMb9mKjjkihQS7fCmAwo3gVs1FSQBVeDZzA7CP3",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SQRNmMb9mKjjkihQS7fCmAwo3gVs1FSQBVeDZzA7CP3/logo.svg",
    tags: [],
    verified: true,
    holders: 326,
  },
  {
    chainId: 101,
    name: "MOONSTONE",
    symbol: "MOON",
    address: "2VdJqfGzW9JzBZSpDQLAYMBjwD4YoYtMAyBxg6S9AXL5",
    decimals: 9,
    logoURI: "https://i.ibb.co/W6fcMVj/MOON.png",
    tags: ["social-token"],
    verified: true,
    holders: 231,
  },
  {
    chainId: 101,
    name: "Gold Coin",
    symbol: "GDC",
    address: "AmvYBdYBF9WFy6oprdnzjsJTgNraeAPP9v3cexBTzgb3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/rickregien/gdc-logo/main/gold-coin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 391,
  },
  {
    chainId: 101,
    name: "MekaMounts",
    symbol: "MEKA",
    address: "BtPMb5Fm21XcS8fNPMZM9FsmZrZQZyWFzLbztvdB1kTE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtPMb5Fm21XcS8fNPMZM9FsmZrZQZyWFzLbztvdB1kTE/meka.png",
    tags: ["MekaMounts"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "STEALTH COIN",
    symbol: "$STEALTH",
    address: "4t6hdasqbriik3svqbhBpZmAbYGiJUPds1bCB6rRbLzN",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/93989494/146991721-19c34433-72fe-460b-82e5-455e886fb27a.png",
    tags: [],
    verified: true,
    holders: 683,
  },
  {
    chainId: 101,
    name: "XIN Dragon Token",
    symbol: "XIN",
    address: "FBdRvc9CmHUf8ib2sV8PDv2oiFAmyxoftjid3Uv9e4kK",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBdRvc9CmHUf8ib2sV8PDv2oiFAmyxoftjid3Uv9e4kK/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1741,
  },
  {
    chainId: 101,
    name: "Solprize",
    symbol: "SPZ",
    address: "HkNK7BL5pSUUzc6ns1mHW5JnzbSG4S9u2QdR3cUuyzSa",
    decimals: 5,
    logoURI: "https://www.solprize.io/static/images/logo.png",
    tags: ["social-token", "meme-token", "community-token"],
    verified: true,
    holders: 1834,
  },
  {
    chainId: 101,
    name: "Kartazion",
    symbol: "KZN",
    address: "EjSwAfwi4F6uYtoi2WuCSYSWPVUPJCdemmShZ9tdy65P",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjSwAfwi4F6uYtoi2WuCSYSWPVUPJCdemmShZ9tdy65P/logo.png",
    tags: ["kartazion", "kzn", "science", "quantum", "physics"],
    verified: true,
    holders: 2433,
  },
  {
    chainId: 101,
    name: "YETI",
    symbol: "$YETI",
    address: "FDKBUXKxCdNQnDrqP7DLe8Kri3hzFRxcXyoskoPa74rk",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/yet777/tokenlogo/main/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 5541,
  },
  {
    chainId: 101,
    name: "Yield Mercurial LP Token (wUSD-4Pool)",
    symbol: "y-wUSD-4Pool",
    address: "yLP4agSHVPv7oxDFQ38fuwJGQA4MV1kJJy4SuNbDEHj",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yLP4agSHVPv7oxDFQ38fuwJGQA4MV1kJJy4SuNbDEHj/logo.svg",
    tags: ["yield-token"],
    verified: true,
    holders: 471,
  },
  {
    chainId: 101,
    name: "QMC Token",
    symbol: "QMC",
    address: "FXKM9Ziz9kTTRbpUxxS2rUipSnJmeyNAkLDbabr5RTpb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXKM9Ziz9kTTRbpUxxS2rUipSnJmeyNAkLDbabr5RTpb/logo.jpg",
    tags: ["QMC-Token"],
    verified: true,
    holders: 1565,
  },
  {
    chainId: 101,
    name: "Fractionalized WAVE-999",
    symbol: "WAV",
    address: "4NGNdLiQ1KG8GgqZimKku4WCLdXbNw6UQJvqax3fE6CJ",
    decimals: 2,
    logoURI:
      "https://www.arweave.net/qV0rFkGG-cTdtgRu7le-k8DstT8_-pzG2MWsceSlXjQ?ext=png",
    tags: ["bridgesplit-fraction", "playground-wave", "wave-999", "fnft"],
    verified: true,
    holders: 1245,
  },
  {
    chainId: 101,
    name: "Solace Token",
    symbol: "SOLACE",
    address: "xZsp3D2JhBvgjdQzd5nobU26CALm916nYwZys3gRPux",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xZsp3D2JhBvgjdQzd5nobU26CALm916nYwZys3gRPux/logo.png",
    tags: ["solmergeaicom"],
    verified: true,
    holders: 9161,
  },
  {
    chainId: 101,
    name: "Starlight",
    symbol: "NEUM",
    address: "EWvdioY8AY4btFj1EaK2wug9fc9WZaab52cpHFmoqMV3",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/Qme78a6ciPwSia6znyZzNaoe1NC3e8Cmo7xbSTgVU1Nren",
    tags: ["utility-token"],
    verified: true,
    holders: 1560,
  },
  {
    chainId: 101,
    name: "Aave Token (Portal)",
    symbol: "AAVE",
    address: "3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 247,
  },
  {
    chainId: 101,
    name: "Akropolis (Portal)",
    symbol: "AKRO",
    address: "12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG/logo.png",
    tags: ["wrapped", "ethereum"],
    verified: true,
    holders: 128,
  },
  {
    chainId: 101,
    name: "Compound (Portal)",
    symbol: "COMP",
    address: "AwEauVaTMQRB71WeDnwf1DWSBxaMKjEPuxyLr1uixFom",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwEauVaTMQRB71WeDnwf1DWSBxaMKjEPuxyLr1uixFom/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 128,
  },
  {
    chainId: 101,
    name: "Graph Token (Portal)",
    symbol: "GRT",
    address: "HGsLG4PnZ28L8A4R5nPqKgZd86zUUdmfnkTRnuFJ5dAX",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HGsLG4PnZ28L8A4R5nPqKgZd86zUUdmfnkTRnuFJ5dAX/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 339,
  },
  {
    chainId: 101,
    name: "yearn.finance (Portal)",
    symbol: "YFI",
    address: "BXZX2JRJFjvKazM1ibeDFxgAngKExb74MRXzXKvgikxX",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXZX2JRJFjvKazM1ibeDFxgAngKExb74MRXzXKvgikxX/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 146,
  },
  {
    chainId: 101,
    name: "SOS (Portal)",
    symbol: "SOS",
    address: "6Q5fvsJ6kgAFmisgDqqyaFd9FURYzHf8MCUbpAUaGZnE",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Q5fvsJ6kgAFmisgDqqyaFd9FURYzHf8MCUbpAUaGZnE/logo.png",
    tags: ["wrapped", "ethereum"],
    verified: true,
    holders: 6401,
  },
  {
    chainId: 101,
    name: "Mutant Penguins Token",
    symbol: "$ZOOT",
    address: "5FNqjw7HjJBLtfcA92AABPVNmRJzw3BhGZjrnjUptrc4",
    decimals: 9,
    logoURI:
      "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/93c507bc-83ac-4905-15ca-f26ca12f1100/public",
    tags: [],
    verified: true,
    holders: 128,
  },
  {
    chainId: 101,
    name: "INFAPE",
    symbol: "INFAPE",
    address: "56TH45QefFZYBUggparQ4GN1Komo8vg7XfFZfAryJTa2",
    decimals: 9,
    logoURI:
      "https://arweave.net/zUWlZrU_4ob7zJ5m5Jv39QSZRvoCC9hF_T4NUXkFK-8?ext=png",
    tags: [],
    verified: true,
    holders: 255,
  },
  {
    chainId: 101,
    name: "SPACE MILK",
    symbol: "PACE",
    address: "9g7MFEqPLtoDe3vyJx1JsqCFyKogHXxf6dDkuRxUUd9f",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9g7MFEqPLtoDe3vyJx1JsqCFyKogHXxf6dDkuRxUUd9f/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 2924,
  },
  {
    chainId: 101,
    name: "HypeProxy",
    symbol: "HYPE",
    address: "8TQdiAzdZZEtkWUR8Zj1tqDYGPv9TR1XjPqrew39Vq9V",
    decimals: 7,
    logoURI:
      "https://raw.githubusercontent.com/hypeproxy/hypeproxy/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 139,
  },
  {
    chainId: 101,
    name: "HashTiki",
    symbol: "TIKI",
    address: "ySumbV2nRTFDpiEyetwpz8Pr3JqUNBi7hK2s7jyyji3",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ySumbV2nRTFDpiEyetwpz8Pr3JqUNBi7hK2s7jyyji3/logo.png",
    tags: ["metaverse", "nft", "community-token"],
    verified: true,
    holders: 3537,
  },
  {
    chainId: 101,
    name: "The Mark of Kamohoalii",
    symbol: "SHRKDAO",
    address: "3K6SeyAYC6E5n39DZ25Jp2EsWSgb4JBjSQYS86jziNMy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K6SeyAYC6E5n39DZ25Jp2EsWSgb4JBjSQYS86jziNMy/logo.png",
    tags: ["community-token", "utility-token", "governance-token", "nft"],
    verified: true,
    holders: 668,
  },
  {
    chainId: 101,
    name: "ElonCash",
    symbol: "ELONCASH",
    address: "3HaDnJ2PEt7v7RE8dPaSzSCgBL5dWvWtTkGaEGs5ap3N",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HaDnJ2PEt7v7RE8dPaSzSCgBL5dWvWtTkGaEGs5ap3N/logo.png",
    tags: [],
    verified: true,
    holders: 372,
  },
  {
    chainId: 101,
    name: "Nerv",
    symbol: "NRV",
    address: "BtnUizMTmDdKxP3hZhQhZMUXF9RZZaZJvcNDFLiRbZ5e",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Mr4x3/emoji-cheat-sheet.com/master/logo.jpeg",
    tags: ["utility-token", "DeFi", "governance-token"],
    verified: true,
    holders: 277,
  },
  {
    chainId: 101,
    name: "Orion Coin",
    symbol: "ORNC",
    address: "665t3SYTfoVtaRPP7QRbBG3V7ePVtWVKQXYkSaUfxS7u",
    decimals: 9,
    logoURI: "https://github.com/neptuncoin/orion-coin/raw/main/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 1800,
  },
  {
    chainId: 101,
    name: "Yolo Otter Token",
    symbol: "YLTR",
    address: "YLTrJGCqZpwZZbCN2D3KTg4PuGBofto8ciqYdPoQEgd",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YLTrJGCqZpwZZbCN2D3KTg4PuGBofto8ciqYdPoQEgd/logo.png",
    tags: [],
    verified: true,
    holders: 8903,
  },
  {
    chainId: 101,
    name: "Fraktionalized SMB 3394",
    symbol: "BRERO",
    address: "6j28waP2NyoCBJrrVNHZuEzLDL25DXdNxMFsMNMxYht7",
    decimals: 3,
    logoURI: "https://arweave.net/2OINffkTFvUwtPFfNKkNzQ7h8m-1UZsqQkr6bXKIH70",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 144,
  },
  {
    chainId: 101,
    name: "Fraktionalized Solana White Paper",
    symbol: "BMINUS",
    address: "GM4CTEsNsU5Kg22JNKkANTannrBU9Ah6SNa3BcyBA6Kj",
    decimals: 3,
    logoURI:
      "https://www.arweave.net/z6v5oNC5uL9fCPhn-BQEONgBdxXrFNhT3ZP5tF-ZesE?ext=png",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 1521,
  },
  {
    chainId: 101,
    name: "SOLANA CRYPT TOKEN",
    symbol: "SCTT",
    address: "GGFNWQ2oEzYVPu1kGSjXZWfyTibnCGJfqZ7uPx8Jkj7B",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGFNWQ2oEzYVPu1kGSjXZWfyTibnCGJfqZ7uPx8Jkj7B/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 1090,
  },
  {
    chainId: 101,
    name: "Solootbox DAO",
    symbol: "BOX",
    address: "DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn/logo.png",
    tags: ["utility-token", "governance-token"],
    verified: true,
    holders: 398,
  },
  {
    chainId: 101,
    name: "MoneyBoy Coin",
    symbol: "MBC",
    address: "AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8/logo.png",
    tags: ["utility-token", "community-token", "meme-token"],
    verified: true,
    holders: 4908,
  },
  {
    chainId: 101,
    name: "Shear Ego Coin",
    symbol: "EGO",
    address: "2zjmVX4KDWtNWQyJhbNXgpMmsAoXwftB7sGV4qvV18xa",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/jcards26/sol/main/new-new.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 202,
  },
  {
    chainId: 101,
    name: "Digi Coin",
    symbol: "$DIGI",
    address: "Digi7SnUD9ddiitEqkNfby1c4BfkgokFKWu9KScbizes",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Digi7SnUD9ddiitEqkNfby1c4BfkgokFKWu9KScbizes/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 770,
  },
  {
    chainId: 101,
    name: "WagmiX",
    symbol: "WAGMIX",
    address: "cet4t25o8KbxxphkR2jiS3vqE2HJiy4rZTsKRMYrrnj",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cet4t25o8KbxxphkR2jiS3vqE2HJiy4rZTsKRMYrrnj/logo.png",
    tags: ["defi", "community"],
    verified: true,
    holders: 690,
  },
  {
    chainId: 101,
    name: "The WAGMI VIP Club",
    symbol: "WVIP",
    address: "CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg/logo.png",
    tags: ["utility-token", "Defi-Token"],
    verified: true,
    holders: 19197,
  },
  {
    chainId: 101,
    name: "LOOT",
    symbol: "LOOT",
    address: "GzpRsvnKXKz586kRLkjdppR4dUCFwHa2qaszKkPUQx6g",
    decimals: 6,
    logoURI:
      "https://arttest12312321.s3.us-west-1.amazonaws.com/pics/Bag_Open+(3).png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 8832,
  },
  {
    chainId: 101,
    name: "Bounty",
    symbol: "BNTY",
    address: "BNTY5DaMP9CZhEtmQfMLHfUwwkXropHuCz4m96YqpqKm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BNTY5DaMP9CZhEtmQfMLHfUwwkXropHuCz4m96YqpqKm/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2482,
  },
  {
    chainId: 101,
    name: "Catalina Whale Mixer Droplet",
    symbol: "CWM",
    address: "cwmkTPCxDkYpBjLQRNhcCKxuxnAQW6ahS7JQLeXrsXt",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cwmkTPCxDkYpBjLQRNhcCKxuxnAQW6ahS7JQLeXrsXt/logo.png",
    tags: ["utility-token", "solvent-droplet"],
    verified: true,
    holders: 136,
  },
  {
    chainId: 101,
    name: "SuperBonds Token",
    symbol: "SB",
    address: "SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx/logo.svg",
    tags: ["defi-token"],
    verified: true,
    holders: 1530,
  },
  {
    chainId: 101,
    name: "Boopieverse",
    symbol: "BOOP",
    address: "75Brdir35vFLheHSoiEX3oWnTuHfAscYpGSBgfhRbmbk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75Brdir35vFLheHSoiEX3oWnTuHfAscYpGSBgfhRbmbk/logo.png",
    tags: ["utility-token", "nft-token"],
    verified: true,
    holders: 3918,
  },
  {
    chainId: 101,
    name: "Guano",
    symbol: "GUANO",
    address: "H69DNtbZZtk92hLLbMrHYqL8HiSHFVGcf6USHU39oRMW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H69DNtbZZtk92hLLbMrHYqL8HiSHFVGcf6USHU39oRMW/logo.png",
    tags: ["community-token", "utility-token", "governance-token"],
    verified: true,
    holders: 735,
  },
  {
    chainId: 101,
    name: "Solana Puppy Pound Treats",
    symbol: "TREATS",
    address: "14r8dWfzmUUBpw59w5swNRb5F1YWqmUnSPgD6djUs1Jj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/14r8dWfzmUUBpw59w5swNRb5F1YWqmUnSPgD6djUs1Jj/logo.png",
    tags: ["community-token", "nfts"],
    verified: true,
    holders: 4024,
  },
  {
    chainId: 101,
    name: "Camp Coin",
    symbol: "CMP",
    address: "FnVPD3fRutXcibEHDYP1gv8kuYJx2SNCi2EizcAsR4wN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FnVPD3fRutXcibEHDYP1gv8kuYJx2SNCi2EizcAsR4wN/logo.png",
    tags: [],
    verified: true,
    holders: 5291,
  },
  {
    chainId: 101,
    name: "Boom Army",
    symbol: "BMA",
    address: "boomh1LQnwDnHtKxWTFgxcbdRjPypRSjdwxkAEJkFSH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/boomh1LQnwDnHtKxWTFgxcbdRjPypRSjdwxkAEJkFSH/logo.svg",
    tags: ["utility-token", "community-token", "social-token"],
    verified: true,
    holders: 640,
  },
  {
    chainId: 101,
    name: "SolanyxToken",
    symbol: "SYXT",
    address: "94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1389,
  },
  {
    chainId: 101,
    name: "LNFT Degen Ape-9989",
    symbol: "LNFTxDAPE9989",
    address: "jAi1KmJZrrDVSroQXXAqASpES4qLvZcW5p9s5a5ksG4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/jAi1KmJZrrDVSroQXXAqASpES4qLvZcW5p9s5a5ksG4/logo.png",
    tags: ["liqnft-owner-token"],
    verified: true,
    holders: 124,
  },
  {
    chainId: 101,
    name: "JPEDU Coin",
    symbol: "JPEDU",
    address: "4ws4ZmDmNQwG1boaDmKtmPzEHJ5UUEaeBKtHzeFDwYyo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ws4ZmDmNQwG1boaDmKtmPzEHJ5UUEaeBKtHzeFDwYyo/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 126,
  },
  {
    chainId: 101,
    name: "Fraktionalized Blockasset Legend Ali",
    symbol: "DALI",
    address: "ESrrTHaNKiC9saxaudX2j9iCM9r8qHRcLSrW5ypQ3W64",
    decimals: 3,
    logoURI:
      "https://www.arweave.net/2Y3f2SeoT7HKHTQ_nUPfsLPyc59mPvNYSAsjKZuWNOk?ext=png",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 865,
  },
  {
    chainId: 101,
    name: "NCLR",
    symbol: "NCLR",
    address: "2MStv16MMiSTGu917stHLsC1ZTUW83tmrfVjUxfx2Ev1",
    decimals: 9,
    logoURI:
      "https://arweave.net/jiZBnPHQCAhdQc-9_9j3OXsw8rWtgIL2ryvR_5fz4p4?ext=png",
    tags: [],
    verified: true,
    holders: 1498,
  },
  {
    chainId: 101,
    name: "BoozCoin",
    symbol: "BOOZ",
    address: "6hYoQQjd54a1qkmhDRSprgrbMKkRKiEH5Z44xw5DZzso",
    decimals: 9,
    logoURI: "https://avatars.githubusercontent.com/u/95369650",
    tags: [],
    verified: true,
    holders: 1003,
  },
  {
    chainId: 101,
    name: "Floki Baby",
    symbol: "FLB",
    address: "7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN/logo.png",
    tags: ["utility-token", "meme"],
    verified: true,
    holders: 419,
  },
  {
    chainId: 101,
    name: "Neron",
    symbol: "NERON",
    address: "CcM2KGSi9XSkbg1mR1szTee2BDazbnW5Qy4prw9zJHFn",
    decimals: 9,
    logoURI: "https://github.com/neronproject/token/raw/main/logo.png",
    tags: ["utility-token", "social-token", "nft", "swiss", "stake"],
    verified: true,
    holders: 102,
  },
  {
    chainId: 101,
    name: "Pesky Coin",
    symbol: "PESKY",
    address: "nooot44pqeM88dcU8XpbexrmHjK7PapV2qEVnQ9LJ14",
    decimals: 9,
    logoURI: "https://arweave.net/58GmA531NCbAhLPsW5sOLSyzuS1lrveuzqr6nbHLzRc",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 3887,
  },
  {
    chainId: 101,
    name: "Irish Setter Sol",
    symbol: "ISS",
    address: "1SSBwC8hxB4GXzKKU4ENNpST7zgpTmucDe9NoAopWXX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1SSBwC8hxB4GXzKKU4ENNpST7zgpTmucDe9NoAopWXX/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 434,
  },
  {
    chainId: 101,
    name: "Dumb Ape Yacht Club Coin",
    symbol: "DAYC",
    address: "E6s1cJi6Qs8EE14FNkF9pbNu1pwhLWGH2jMRZeStWsAk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6s1cJi6Qs8EE14FNkF9pbNu1pwhLWGH2jMRZeStWsAk/logo.png",
    tags: ["game-token", "meme-token", "nft", "gaming"],
    verified: true,
    holders: 2828,
  },
  {
    chainId: 101,
    name: "Wet Fish Aquarium",
    symbol: "WETFISH",
    address: "3XL4fXxANNvcspBhaLHvPYbfjR9deKyMtT8HP71RwKwu",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3XL4fXxANNvcspBhaLHvPYbfjR9deKyMtT8HP71RwKwu/logo.png",
    tags: [],
    verified: true,
    holders: 193,
  },
  {
    chainId: 101,
    name: "Friktion SOL (Volt 01 Call)",
    symbol: "fcSOL",
    address: "4Hnh1UCC6HLzx9NaGKnTVHR2bANcRrhydumdHCnrT3i2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Hnh1UCC6HLzx9NaGKnTVHR2bANcRrhydumdHCnrT3i2/logo.png",
    tags: ["friktion", "friktion-share", "volt-01", "volt-01-call"],
    verified: true,
    holders: 7705,
  },
  {
    chainId: 101,
    name: "Friktion mSOL (Volt 01 Call)",
    symbol: "fcmSOL",
    address: "6UA3yn28XecAHLTwoCtjfzy3WcyQj1x13bxnH8urUiKt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6UA3yn28XecAHLTwoCtjfzy3WcyQj1x13bxnH8urUiKt/logo.png",
    tags: ["friktion", "friktion-share", "volt-01", "volt-01-call"],
    verified: true,
    holders: 4052,
  },
  {
    chainId: 101,
    name: "Friktion SRM (Volt 01 Call)",
    symbol: "fcSRM",
    address: "5SLqZSywodLS8ih6U2AAioZrxpgR149hR8SApmCB7r5X",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SLqZSywodLS8ih6U2AAioZrxpgR149hR8SApmCB7r5X/logo.png",
    tags: ["friktion", "friktion-share", "volt-01", "volt-01-call"],
    verified: true,
    holders: 627,
  },
  {
    chainId: 101,
    name: "Friktion LUNA (Volt 01 Call)",
    symbol: "fcLUNA",
    address: "95sn4kgeJnnBfRCD8S2quu4HS9Y6vb7JDuXrarnmEjYE",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95sn4kgeJnnBfRCD8S2quu4HS9Y6vb7JDuXrarnmEjYE/logo.png",
    tags: ["friktion", "friktion-share", "volt-01", "volt-01-call"],
    verified: true,
    holders: 1208,
  },
  {
    chainId: 101,
    name: "Friktion SOL (Volt 02 Put)",
    symbol: "fpSOL",
    address: "EBPM7fvPN8EuA65Uc7DT9eGyDUZ1sqMLM8Rb8y2YxBYU",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EBPM7fvPN8EuA65Uc7DT9eGyDUZ1sqMLM8Rb8y2YxBYU/logo.png",
    tags: ["friktion", "friktion-share", "volt-02", "volt-02-put"],
    verified: true,
    holders: 2261,
  },
  {
    chainId: 101,
    name: "Friktion BTC (Volt 02 Put)",
    symbol: "fpBTC",
    address: "THjfJ7GUeW6aMU6dzYYFVs5LnKNvmPzgk2wbh3bWagC",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/THjfJ7GUeW6aMU6dzYYFVs5LnKNvmPzgk2wbh3bWagC/logo.png",
    tags: ["friktion", "friktion-share", "volt-02", "volt-02-put"],
    verified: true,
    holders: 499,
  },
  {
    chainId: 101,
    name: "Kaidos Token",
    symbol: "$KAIDO",
    address: "D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt/Kaido.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 640,
  },
  {
    chainId: 101,
    name: "pre-IDO HONEY",
    symbol: "pHONEY",
    address: "PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe/logo.svg",
    tags: ["utility-token", "DeFi", "Governance"],
    verified: true,
    holders: 31103,
  },
  {
    chainId: 101,
    name: "Mindfolk Wood",
    symbol: "$WOOD",
    address: "674PmuiDtgKx3uKuJ1B16f9m5L84eFvNwj3xDMvHcbo7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Alfred-builder/Alfred-builder/main/wood.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3165,
  },
  {
    chainId: 101,
    name: "SolRider",
    symbol: "SRD",
    address: "C7gENDPyayjE2iw6p55J5ELtcDYzQbe91JEhLjH2yxAR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7gENDPyayjE2iw6p55J5ELtcDYzQbe91JEhLjH2yxAR/logo.png",
    tags: [
      "game",
      "play2earn",
      "community-token",
      "utility-token",
      "governance",
    ],
    verified: true,
    holders: 1979,
  },
  {
    chainId: 101,
    name: "Snowflakes",
    symbol: "FLAKE",
    address: "8Cs8n1mVr4nvzYdpwth72nCpWyWsrviapJHFAnUnP8iH",
    decimals: 4,
    logoURI:
      "https://cdn.discordapp.com/attachments/902244343174287391/907420388882014218/snowflake_1.png",
    tags: ["community-token", "utility-token", "IcyBearz", "Snowflakes"],
    verified: true,
    holders: 293,
  },
  {
    chainId: 101,
    name: "Legend Of PooShi",
    symbol: "POOS",
    address: "FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/ba3893b7f7822992a645bc130f78fa433baff2d3/assets/mainnet/FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH/logo.png",
    tags: [
      "community-token",
      "meme-token",
      "utility-token",
      "game-token",
      "game-currency",
      "GameFi",
      "Gaming",
      "metaverse",
      "play-to-earn",
      "play-to-contribute",
    ],
    verified: true,
    holders: 1406,
  },
  {
    chainId: 101,
    name: "doggos",
    symbol: "TREAT",
    address: "FhuY6rZoeQH3WKEXFs1NitsrvHf9axPvzKVcXHrNh94E",
    decimals: 9,
    logoURI: "https://arweave.net/inoGzbyUQa7_9fQzDTSY5f5b4g4XUTGNa-oepqXo9qg",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 660,
  },
  {
    chainId: 101,
    name: "Baby Elephant",
    symbol: "BELE",
    address: "BELEkfxRkTdNexHtsJ8sk6RBx1ZuNcQmfpkKSNgq8S7N",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BELEkfxRkTdNexHtsJ8sk6RBx1ZuNcQmfpkKSNgq8S7N/logo.png",
    tags: ["NFT", "general-token", "meme-token", "community-token"],
    verified: true,
    holders: 2697,
  },
  {
    chainId: 101,
    name: "SSC NFT Stake Token",
    symbol: "SSCSTK",
    address: "5kMBto3Eu1sUKM6h9E5x4Lj6QBqaJcpr9V5GqP44EbU3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kMBto3Eu1sUKM6h9E5x4Lj6QBqaJcpr9V5GqP44EbU3/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3327,
  },
  {
    chainId: 101,
    name: "CHUG Token",
    symbol: "CHUG",
    address: "CbDwU8JrTYv3GzU7msni8qtfFkAGpcyFAzuhuGq5SVqp",
    decimals: 9,
    logoURI: "https://solthirsty.io/images/chug.png",
    tags: [],
    verified: true,
    holders: 2558,
  },
  {
    chainId: 101,
    name: "Metaversol Credit",
    symbol: "CREDIT",
    address: "dDTRVvDVJfuELbuEmNqve32pkbc95raxBf2s2wAVjq5",
    decimals: 9,
    logoURI: "https://metaversol.com/coinlogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1846,
  },
  {
    chainId: 101,
    name: "ForgeBots Mint Pass",
    symbol: "FBMP",
    address: "5xEbwdxKaB8T2fNatZi8CGg99EjJoTFnnBssJXqEU6SZ",
    decimals: 0,
    logoURI: "https://i.ibb.co/NWYJs2W/forgebots-mintpass.gif",
    tags: [],
    verified: true,
    holders: 1238,
  },
  {
    chainId: 101,
    name: "Dexlab Stake Season 1",
    symbol: "DXLSTAKE_V1",
    address: "AXYEG84MRYcJoYhJh7Z9btYXdw4G4pdQGYaCubwMkksg",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg",
    tags: ["stake-pool-token"],
    verified: true,
    holders: 5798,
  },
  {
    chainId: 101,
    name: "FOREST FINANCE",
    symbol: "FFT",
    address: "5YFaApo5XYgJfyJ72dF5oBtv2d1VNay3ajuPxwbK2jX",
    decimals: 6,
    logoURI: "https://raw.githubusercontent.com/ForestNFT/logo/main/LOGO.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1812,
  },
  {
    chainId: 101,
    name: "BitWhips S1 Whitelist Token",
    symbol: "BWS1WL",
    address: "F2QDS2TaWnfEZVmWs5wRemdWUxtXErHkjDM1yEQF4LJV",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F2QDS2TaWnfEZVmWs5wRemdWUxtXErHkjDM1yEQF4LJV/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 162,
  },
  {
    chainId: 101,
    name: "Katana SOL Covered Call Vault Share",
    symbol: "kcSOL",
    address: "J58rNqBQTQQJ7oMftec8bLWKVF6iujcH3Ck81f2a4zCu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/J58rNqBQTQQJ7oMftec8bLWKVF6iujcH3Ck81f2a4zCu/logo.png",
    tags: ["Katana", "share-token", "utility-token"],
    verified: true,
    holders: 17160,
  },
  {
    chainId: 101,
    name: "Katana MSOL Covered Call Vault Share",
    symbol: "kcMSOL",
    address: "E2eevzN2TBWAL1LiALAfEYFLRBzSDLugLjiNuZg9ehwT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/E2eevzN2TBWAL1LiALAfEYFLRBzSDLugLjiNuZg9ehwT/logo.png",
    tags: ["Katana", "share-token", "utility-token"],
    verified: true,
    holders: 1003,
  },
  {
    chainId: 101,
    name: "Katana STSOL Covered Call Vault Share",
    symbol: "kcSTSOL",
    address: "Br4WZq7N5gFK3WX8Ls1U15ePnQ1DnSKwx5k62X51LeLB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/Br4WZq7N5gFK3WX8Ls1U15ePnQ1DnSKwx5k62X51LeLB/logo.png",
    tags: ["Katana", "share-token", "utility-token"],
    verified: true,
    holders: 145,
  },
  {
    chainId: 101,
    name: "Tiddy Juice Coin",
    symbol: "MILK",
    address: "CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2",
    decimals: 9,
    logoURI: "https://arweave.net/S71DNASA5TYlrK9giRy7QgqFNw1m4mxqY6OdtgY1E7k",
    tags: ["meme-coin"],
    verified: true,
    holders: 1014,
  },
  {
    chainId: 101,
    name: "DarleyGo Essence",
    symbol: "DGE",
    address: "AAXng5czWLNtTXHdWEn9Ef7kXMXEaraHj2JQKo7ZoLux",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/DarleyGo/token-list/main/assets/mainnet/AAXng5czWLNtTXHdWEn9Ef7kXMXEaraHj2JQKo7ZoLux/icon.png",
    tags: ["game-token", "game-currency", "Gaming"],
    verified: true,
    holders: 5751,
  },
  {
    chainId: 101,
    name: "Galaxy Essential",
    symbol: "GXE",
    address: "DsVPH4mAppxKrmdzcizGfPtLYEBAkQGK4eUch32wgaHY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/DarleyGo/token-list/main/assets/mainnet/DsVPH4mAppxKrmdzcizGfPtLYEBAkQGK4eUch32wgaHY/icon.png",
    tags: ["game-token", "game-currency", "Gaming"],
    verified: true,
    holders: 1917,
  },
  {
    chainId: 101,
    name: "Fone",
    symbol: "FONE",
    address: "ATZERmcPfopS9vGqw9kxqRj9Bmdi3Z268nHXkGsMa3Pf",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATZERmcPfopS9vGqw9kxqRj9Bmdi3Z268nHXkGsMa3Pf/fonelogo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 24018,
  },
  {
    chainId: 101,
    name: "High Society WL Mint Pass",
    symbol: "HSMINT",
    address: "G8naNPQvUYHT4r93WjNJAjefPAyQk94FbkPkzucxycE1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G8naNPQvUYHT4r93WjNJAjefPAyQk94FbkPkzucxycE1/logo.png",
    tags: ["NFT", "general-token", "community-token", "mint-token"],
    verified: true,
    holders: 1078,
  },
  {
    chainId: 101,
    name: "Cosmic Ape Coin",
    symbol: "CAC",
    address: "E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz/logo.png",
    tags: [],
    verified: true,
    holders: 1499,
  },
  {
    chainId: 101,
    name: "Topia Token",
    symbol: "TOPIA",
    address: "GBDsHu7SMev6Q2DdFyU6YPkjRBiPVqWuFmsJyrsLLrUX",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/apetopiadev/logo/main/logo.png",
    tags: ["social-token", "utility-token", "currency"],
    verified: true,
    holders: 679,
  },
  {
    chainId: 101,
    name: "Wrapped Luna (Allbridge from Terra)",
    symbol: "atLUNA",
    address: "3LKZU3iQx9KM94S4uYRdYwAHTm6odDyzGQqTBNj7J27z",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png",
    tags: [],
    verified: true,
    holders: 385,
  },
  {
    chainId: 101,
    name: "Wassie World Whitelist",
    symbol: "WWW",
    address: "2aFZVztpssp5WjZuucrYVB8gQbR83KH3htsgL8NH4K4s",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2aFZVztpssp5WjZuucrYVB8gQbR83KH3htsgL8NH4K4s/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 205,
  },
  {
    chainId: 101,
    name: "TFBK",
    symbol: "TFBK",
    address: "HMYxhA2ZeSpFjitzP58K7MMJKbKkBuj4P5PHbsmo3rTF",
    decimals: 9,
    logoURI:
      "http://youngtech.fr/wp-content/uploads/2022/01/logofineblock.jpeg",
    tags: [],
    verified: true,
    holders: 234,
  },
  {
    chainId: 101,
    name: "Xenium",
    symbol: "XEN",
    address: "AfGaVaWuGdKcbdzTTFvh1Haatw8DpaHQnouY3fRJEXyz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/TheXeniumToken/Xenium/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 842,
  },
  {
    chainId: 101,
    name: "Cerebro",
    symbol: "CRB",
    address: "9A6dgXm79ASFyG42tui86F4gQTC56Ydw3mrNL61xhdr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9A6dgXm79ASFyG42tui86F4gQTC56Ydw3mrNL61xhdr/logo.png",
    tags: [],
    verified: true,
    holders: 180,
  },
  {
    chainId: 101,
    name: "MORPH",
    symbol: "MORPH",
    address: "HysJKMMQ4G6oEffMhf55ZPRc28zwCmpmZr63vAfUrrBq",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HysJKMMQ4G6oEffMhf55ZPRc28zwCmpmZr63vAfUrrBq/logo.png",
    tags: [],
    verified: true,
    holders: 511,
  },
  {
    chainId: 101,
    name: "SQUEAK Token",
    symbol: "$SQUEAK",
    address: "DJWYzyVsuzfprR5hkmW98nmXgQ8CK6rJWhxswj4CmQEb",
    decimals: 6,
    logoURI:
      "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/8a60559c-fe41-4a52-3210-6685125f1800/public",
    tags: [],
    verified: true,
    holders: 354,
  },
  {
    chainId: 101,
    name: "IZUNO Token",
    symbol: "IZU",
    address: "F9ZsJAyX4SaqMbFqiixDk1RXRUkJ9d9zeC5tYjyn4v5z",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F9ZsJAyX4SaqMbFqiixDk1RXRUkJ9d9zeC5tYjyn4v5z/logo.png",
    tags: ["NFT", "utility-token", "game-token", "game-currency", "GameFi"],
    verified: true,
    holders: 5161,
  },
  {
    chainId: 101,
    name: "Designer Coin",
    symbol: "DSNR",
    address: "CgAJ7HJwntz738DngHUEFHFggHWhfYG6m6a4udHCJnds",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CgAJ7HJwntz738DngHUEFHFggHWhfYG6m6a4udHCJnds/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 304,
  },
  {
    chainId: 101,
    name: "Guardian Remnants",
    symbol: "$REM",
    address: "CNMvWLKc8r1wRrQ1Xws6v43x5ttTnaf2pccLkUqsfXVf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CNMvWLKc8r1wRrQ1Xws6v43x5ttTnaf2pccLkUqsfXVf/logo.png",
    tags: [
      "utility-token",
      "company-token",
      "meta-token",
      "social-token",
      "nft-token",
    ],
    verified: true,
    holders: 712,
  },
  {
    chainId: 101,
    name: "CORE",
    symbol: "CORE",
    address: "2maDvG9nXGVstjdnsCZoSsNtjoda1SsZTLrHBVRgLR5F",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2x4fH7kWNgSLpBjCuFBKKKiNHMcJb6fHs8ahQZUWJj1p/Core.png",
    tags: ["utility"],
    verified: true,
    holders: 1618,
  },
  {
    chainId: 101,
    name: "ILoveU Token",
    symbol: "ILU",
    address: "Gm6szibJfB1ZzUxNYf85nXwFchugqTqNyE5fDwWfBc7K",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/rjdrar/crypto/main/symbol-1.png",
    tags: ["social-token"],
    verified: true,
    holders: 171,
  },
  {
    chainId: 101,
    name: "AstraDEX",
    symbol: "ASDEX",
    address: "7xfKgh8vtX2RrZn21wFTQSP9jsjh7Fqo8P4igYfmxxD3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/ASTRADEX123/crypto/main/Logo.png.png",
    tags: ["social-token"],
    verified: true,
    holders: 3187,
  },
  {
    chainId: 101,
    name: "Hog Lottery Token",
    symbol: "$HOGLOT",
    address: "HoGbvSustgSvZMyjbMKE3iNBbfrcATEYbbsaPwA9xxMp",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HoGbvSustgSvZMyjbMKE3iNBbfrcATEYbbsaPwA9xxMp/logo.png",
    tags: [],
    verified: true,
    holders: 1662,
  },
  {
    chainId: 101,
    name: "Pixel Drone Ticket",
    symbol: "PIDT",
    address: "78uaHEgTUELYBUbNX2FgijH64FudDJERGZHzrArSV3Le",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78uaHEgTUELYBUbNX2FgijH64FudDJERGZHzrArSV3Le/logo.png",
    tags: [
      "game",
      "nft",
      "mint",
      "mint-ticket",
      "ticket",
      "pixel",
      "pixelinvaderz",
    ],
    verified: true,
    holders: 671,
  },
  {
    chainId: 101,
    name: "Serenity Shield",
    symbol: "SERSH",
    address: "9pkudFiG5qhiSQQ8XatPbxZj8zBQ5m4XzHsKHMAGkkCR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9pkudFiG5qhiSQQ8XatPbxZj8zBQ5m4XzHsKHMAGkkCR/logo.png",
    tags: ["token"],
    verified: true,
    holders: 684,
  },
  {
    chainId: 101,
    name: "Dope Token",
    symbol: "DOPET",
    address: "Ei8cio2iKfBErSCyHuGzvSqPQpDPd9qaxdE8nJYroX1B",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/1009138/148701526-1836e263-2628-4264-a572-23b093324bbd.png",
    tags: ["social-token"],
    verified: true,
    holders: 1273,
  },
  {
    chainId: 101,
    name: "MOONBURN",
    symbol: "MOONBURN",
    address: "F14Cp89oAXMrNnaC4mKMNKHPWw2p2R4DRFAZEdJhUBkD",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Joblo197/Crypto/main/Shroom%20Logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 260,
  },
  {
    chainId: 101,
    name: "Great Legendary LT",
    symbol: "GREATLT",
    address: "2QyXrDw26mq3Pw3C2Fswz22qPMVLSLg9BfqPy6PqmsMt",
    decimals: 6,
    logoURI: "https://github.com/thanathos9558/token-logo/blob/main/logo.PNG",
    tags: ["utility-token", "game-currency"],
    verified: true,
    holders: 499,
  },
  {
    chainId: 101,
    name: "Collectors Club Governance",
    symbol: "CCG",
    address: "3FYzcJvLeQubLuAgacV6sDu9Ye8Eg1vFYpCxD3ogp74M",
    decimals: 6,
    logoURI: "https://arweave.net/gBhj9k-4TGhwDwsnZxRQKMg_3ZQsvL1oeL0SUcrroPs",
    tags: ["utility-token"],
    verified: true,
    holders: 263,
  },
  {
    chainId: 101,
    name: "LNFT Thugbirdz-0160",
    symbol: "LNFTxTHUG0160",
    address: "HCBPNDD9iz683PszPBZzoy7xEub4GQ7rHBJxM57cLQpf",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCBPNDD9iz683PszPBZzoy7xEub4GQ7rHBJxM57cLQpf/logo.png",
    tags: ["liqnft-owner-token"],
    verified: true,
    holders: 112,
  },
  {
    chainId: 101,
    name: "SolSamos",
    symbol: "FLUFF",
    address: "Dd7pji6EruuFPuAxuZG5LwZUdPSzYCTN6NsttrkHeedS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solsamos/token-list/main/assets/mainnet/Dd7pji6EruuFPuAxuZG5LwZUdPSzYCTN6NsttrkHeedS/FluffIcon.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 486,
  },
  {
    chainId: 101,
    name: "INTERSTELLAR WHALES TOKEN",
    symbol: "$WHALES",
    address: "8ssNv1qPnN1UAfGzLjT9gDTPed2eqXsJgKq5wMB8ESan",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ssNv1qPnN1UAfGzLjT9gDTPed2eqXsJgKq5wMB8ESan/logo.png",
    tags: [
      "community-token",
      "utility-token",
      "social-token",
      "metaverse-token",
      "play-2-earn",
      "NFTs",
      "DeFi",
    ],
    verified: true,
    holders: 240,
  },
  {
    chainId: 101,
    name: "Solvent",
    symbol: "SVT",
    address: "svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 4771,
  },
  {
    chainId: 101,
    name: "Wild West Verse Token",
    symbol: "WWV",
    address: "CvzmN4HEMt2R9tsLyCV26yV2sT5tgD6nAHd7TNtWvHXq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Wild-westverse/logo500/main/www_500.png",
    tags: ["utility-token", "social-token", "play-2-earn"],
    verified: true,
    holders: 1478,
  },
  {
    chainId: 101,
    name: "Sockmas",
    symbol: "SXMAS",
    address: "9RxfJAtr4T6s7uvj1zdjvwviKRwnDCJdKQqUZmX5FYiC",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9RxfJAtr4T6s7uvj1zdjvwviKRwnDCJdKQqUZmX5FYiC/logo.png",
    tags: ["utility"],
    verified: true,
    holders: 4728,
  },
  {
    chainId: 101,
    name: "Ammo",
    symbol: "AMMO",
    address: "EEhosSQvC2yVDRXRGpkonGFF2WNjtUdzb48GV8TSmhfA",
    decimals: 9,
    logoURI: "https://arweave.net/rjP_BdMqFsXBWoInFYuVNDdqLzW1xo82egb74WRl3Hc",
    tags: ["nft-token", "staking"],
    verified: true,
    holders: 1817,
  },
  {
    chainId: 101,
    name: "Intergalactic Monkey Space Odyssey",
    symbol: "ImSo",
    address: "iMSoEKeFPsxXe493ouVREU6Aq7aamY2uADqBHtJFhza",
    decimals: 6,
    logoURI:
      "https://pscwbp7c3jss2hzt34p5y2atycb2vopk2x43iapvbr367pezd2nq.arweave.net/fIVgv-LaZS0fM98f3GgTwIOquerV-bQB9Qx377yZHps",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 334,
  },
  {
    chainId: 101,
    name: "Wurklo",
    symbol: "WURK",
    address: "GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV/logo.png",
    tags: ["utility-token", "community-token", "employment-agency"],
    verified: true,
    holders: 6204,
  },
  {
    chainId: 101,
    name: "Vivaion Token",
    symbol: "VIVAION",
    address: "2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn",
    decimals: 9,
    logoURI: "https://arweave.net/Xz7ErS3TZYmBnoh3OPkIQM_O6TPotyEJb6H5WFOe_qc",
    tags: [
      "community-token",
      "utility-token",
      "social-token",
      "metaverse-token",
    ],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "EverSOL staked SOL (eSOL)",
    symbol: "eSOL",
    address: "Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM/logo.png",
    tags: [],
    verified: true,
    holders: 501,
  },
  {
    chainId: 101,
    name: "Gatsby Club Currency",
    symbol: "GCC",
    address: "99Q3AfFWX3rdidoQCnAPPoZFjsaXr2AAk65RUgxiwfUi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/benzsteamNFT/gat/main/logotoken.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 556,
  },
  {
    chainId: 101,
    name: "Acumen Governance Token",
    symbol: "ACM",
    address: "ACUMENkbnxQPAsN8XrNA11sY3NmXDNKVCqS82EiDqMYB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACUMENkbnxQPAsN8XrNA11sY3NmXDNKVCqS82EiDqMYB/logo3.svg",
    tags: [],
    verified: true,
    holders: 1477,
  },
  {
    chainId: 101,
    name: "ACES Mint Token",
    symbol: "ACESMINT",
    address: "Gj9GCwBoVwR2wqaKHCkrAYQtTpyPSfwLU4WjsoJMqQ8m",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gj9GCwBoVwR2wqaKHCkrAYQtTpyPSfwLU4WjsoJMqQ8m/logo.png",
    tags: [],
    verified: true,
    holders: 2239,
  },
  {
    chainId: 101,
    name: "Alpha Blue Whitelist Token",
    symbol: "ABWT",
    address: "BQHdWCL8WTkxBsKKLvFxgThmCX9EK4zorTzpznabMrZY",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQHdWCL8WTkxBsKKLvFxgThmCX9EK4zorTzpznabMrZY/logo.png",
    tags: ["Zoolana"],
    verified: true,
    holders: 1393,
  },
  {
    chainId: 101,
    name: "Scapes Whitelist",
    symbol: "SCAPESWL",
    address: "EV1Fz9GycC5Yr19Ye6h6ct1m3NQMwmadrKiWHUDuXdnr",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EV1Fz9GycC5Yr19Ye6h6ct1m3NQMwmadrKiWHUDuXdnr/logo.png",
    tags: ["scapes-whitelist"],
    verified: true,
    holders: 365,
  },
  {
    chainId: 101,
    name: "Fetti",
    symbol: "FETTI",
    address: "837zQBUJDs6JvMsVZkWdMaCTXsPkSfUyPEVEnuJZgMQt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/837zQBUJDs6JvMsVZkWdMaCTXsPkSfUyPEVEnuJZgMQt/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 4709,
  },
  {
    chainId: 101,
    name: "Meta Paradise Island",
    symbol: "MPI",
    address: "ArdsPHY5LsCjvxSxZz8f3vTkv5qoYihmCCPMYvr6aQza",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArdsPHY5LsCjvxSxZz8f3vTkv5qoYihmCCPMYvr6aQza/Mpi-new.png",
    tags: ["social-token", "solana", "utility-token"],
    verified: true,
    holders: 5003,
  },
  {
    chainId: 101,
    name: "Metaversol WL Token",
    symbol: "MvrsWL",
    address: "CLoTdqgpqcvCQDipcGDkJxBPJVyZMP8v8e3iCFP5tFfc",
    decimals: 0,
    logoURI: "https://metaversol.com/coinlogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3433,
  },
  {
    chainId: 101,
    name: "Deer Forest",
    symbol: "DFOREST",
    address: "22WH22zjhczZU5Rc8jjteqNmM2hiv8VnJb8eha1B4eoE",
    decimals: 0,
    logoURI: "https://raw.githubusercontent.com/TheDeerNFT/logo/main/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 515,
  },
  {
    chainId: 101,
    name: "Bancambios AX",
    symbol: "BXS",
    address: "pH5wWJc3KhdeVQSt86DU31pdcL9c8P88x2FQoKEJVHC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pH5wWJc3KhdeVQSt86DU31pdcL9c8P88x2FQoKEJVHC/logo.jpg",
    tags: [
      "impact-defi",
      "bancambios-token",
      "solana",
      "impact-dex",
      "defi-for-good",
      "multi-chain",
    ],
    verified: true,
    holders: 1607,
  },
  {
    chainId: 101,
    name: "b4c meme coin",
    symbol: "B4C",
    address: "CkD9GDPqME11zrFt7Lk51jdQSuPmP1nRULUWbBYj2Ngq",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkD9GDPqME11zrFt7Lk51jdQSuPmP1nRULUWbBYj2Ngq/logo.png",
    tags: ["meme-token"],
    verified: true,
    holders: 1051,
  },
  {
    chainId: 101,
    name: "Bounty Points",
    symbol: "BPTS",
    address: "3iH4LXRDMicfqb4TPR99QYzz1d6zyDvuuCWsQZxMH2bi",
    decimals: 9,
    logoURI:
      "https://gateway.pinata.cloud/ipfs/Qmf5gyuFsYg7gsHgaXxTebkMGCMKen7tz4Y6pqKLyfFycK?preview=1",
    tags: ["utility-token"],
    verified: true,
    holders: 147,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Cashio Dollar (8 decimals)",
    symbol: "sCASH-8",
    address: "CASHedBw9NfhsLBXq1WNVfueVznx255j8LLTScto3S6s",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHedBw9NfhsLBXq1WNVfueVznx255j8LLTScto3S6s/icon.png",
    tags: ["stablecoin", "saber-mkt-usd", "saber-dec-wrapped"],
    verified: true,
    holders: 212,
  },
  {
    chainId: 101,
    name: "Saber Wrapped agEUR (Portal) (9 decimals)",
    symbol: "sagEUR-9",
    address: "EU9aLffrTckFCs16da6CppHy63fAxMPF9ih1erQTuuRt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EU9aLffrTckFCs16da6CppHy63fAxMPF9ih1erQTuuRt/icon.png",
    tags: [
      "ethereum",
      "wrapped",
      "wormhole",
      "saber-mkt-eur",
      "wormhole-v2",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 133,
  },
  {
    chainId: 101,
    name: "Saber Wrapped UST (Portal) (8 decimals)",
    symbol: "sUST-8",
    address: "UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7/icon.png",
    tags: [
      "wrapped",
      "wormhole",
      "saber-mkt-usd",
      "wormhole-v2",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 895,
  },
  {
    chainId: 101,
    name: "Saber JSOL-SOL LP",
    symbol: "JSOL-SOL",
    address: "son4WQ39xri8sqMqNQZAEtEEPTuUcCRHg7t1ZcYdkSw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/son4WQ39xri8sqMqNQZAEtEEPTuUcCRHg7t1ZcYdkSw/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 954,
  },
  {
    chainId: 101,
    name: "Saber IOU Token (Liquidity Mining Rewards)",
    symbol: "SBR",
    address: "iouQcQBAiEXe6cKLS85zmZxUqaCqBdeHFpqKoSz615u",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg",
    tags: [],
    verified: true,
    holders: 61812,
  },
  {
    chainId: 101,
    name: "OODA",
    symbol: "OODA",
    address: "2VFkmFpyFm9DMKch4UdrdyY7WHLGrWgwJLAdKwHd5ekY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/OODA-X/ooda/main/OODA%20Coin%20Logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 111,
  },
  {
    chainId: 101,
    name: "Akuma Serpent",
    symbol: "ATL",
    address: "2V1AVjDVM2gZn72ZufG2HfFHDKXzS5XaCupNeKrdcruT",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/onuraladi/assets/main/assets/trident.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 139,
  },
  {
    chainId: 101,
    name: "ANKH",
    symbol: "ANKH",
    address: "ankhim7kPXxLKVbW1Tn7vH4mLTuvCAqHjhkKuvwWJ7b",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ankhim7kPXxLKVbW1Tn7vH4mLTuvCAqHjhkKuvwWJ7b/logo.png",
    tags: [],
    verified: true,
    holders: 1908,
  },
  {
    chainId: 101,
    name: "Rogue Panda Token",
    symbol: "RP",
    address: "5mcRqyofvBiFm2Dt9AG2DRvJq1BkM6D3VX93u2DWMhsJ",
    decimals: 6,
    logoURI:
      "https://c5kh5ocamc7ov5pchbjdj5pikijb7toenkhpy345svfir6d7naoa.arweave.net/F1R-uEBgvur14jhSNPXoUhIfzcRqjvxvnZVKiPh_aBw",
    tags: ["utility-token", "governance-token", "gaming", "NFTs"],
    verified: true,
    holders: 1780,
  },
  {
    chainId: 101,
    name: "South Park Degens Whitelist Token",
    symbol: "SPD",
    address: "SPD2Ha9rusWCvzKN1NNhbmsFBrQvPQTKGMWL1onmyKJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPD2Ha9rusWCvzKN1NNhbmsFBrQvPQTKGMWL1onmyKJ/logo.png",
    tags: [],
    verified: true,
    holders: 210,
  },
  {
    chainId: 101,
    name: "ARTE",
    symbol: "ARTE",
    address: "6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/ArtPunks/ARTE-token/main/assets/token_512.png",
    tags: ["utility-token"],
    verified: true,
    holders: 7283,
  },
  {
    chainId: 101,
    name: "NURP Coin",
    symbol: "NURP",
    address: "938xXsPKhBAXDLUfWNYuZdsnj57oGF6fCz8P6yLom19f",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/938xXsPKhBAXDLUfWNYuZdsnj57oGF6fCz8P6yLom19f/logo.png",
    tags: [],
    verified: true,
    holders: 445,
  },
  {
    chainId: 101,
    name: "RED PANDA COIN",
    symbol: "RPC",
    address: "8oJ4nGEJmGCq8RikM3R8wdAN8d8ZxrhMYpbUPYzCMgFq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oJ4nGEJmGCq8RikM3R8wdAN8d8ZxrhMYpbUPYzCMgFq/logo.png",
    tags: ["meme-coin"],
    verified: true,
    holders: 1054,
  },
  {
    chainId: 101,
    name: "Ox Squad Token",
    symbol: "OXSQ",
    address: "6fMoTH7Bad61GPzvqfq1XmRGFGv84MrvZfjDKGNuVwBz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/juandelia03/logo/main/icon400x400.jpg",
    tags: ["utility-token", "social-token", "nft-token", "community"],
    verified: true,
    holders: 505,
  },
  {
    chainId: 101,
    name: "New Zoolana Whitelist Token",
    symbol: "NZWT",
    address: "GoAwpsL4SjGMd7BAMq2QKbHvEjgPcbmAwAjg1wpT7UDv",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoAwpsL4SjGMd7BAMq2QKbHvEjgPcbmAwAjg1wpT7UDv/logo.png",
    tags: ["Zoolana"],
    verified: true,
    holders: 3435,
  },
  {
    chainId: 101,
    name: "SoldierXSolvivor Coin",
    symbol: "SXS",
    address: "Ct7mbdwLmdFC6zgVRXFidvvgYbtGo2icsntNSSgzxoLs",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ct7mbdwLmdFC6zgVRXFidvvgYbtGo2icsntNSSgzxoLs/logo.png",
    tags: ["utility-token", "game-token", "game-currency"],
    verified: true,
    holders: 2310,
  },
  {
    chainId: 101,
    name: "BunnyDucky Token",
    symbol: "BUD",
    address: "BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 620,
  },
  {
    chainId: 101,
    name: "The Alliance of Eragard",
    symbol: "ERA",
    address: "Dg46mekhjFiKhCEG94wZyV1fsKLTyM98LeGfXPHGta7w",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dg46mekhjFiKhCEG94wZyV1fsKLTyM98LeGfXPHGta7w/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 247,
  },
  {
    chainId: 101,
    name: "Metavillage ARK Token",
    symbol: "ARK",
    address: "4Qd7t3wG1ea5Zm7NSuEocMrc6NfA8GyGp3s3nv4kgvgj",
    decimals: 0,
    logoURI: "https://metavillage.app/icon.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6898,
  },
  {
    chainId: 101,
    name: "Bitmon Presale Token",
    symbol: "BT",
    address: "Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62/BT-logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6005,
  },
  {
    chainId: 101,
    name: "Crypto Tradiez WL Token",
    symbol: "CTZWL",
    address: "4fRea2p9hpWEMddLraYAaMr7rcQHAAVkk2cQXxUN4yrh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4fRea2p9hpWEMddLraYAaMr7rcQHAAVkk2cQXxUN4yrh/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1241,
  },
  {
    chainId: 101,
    name: "SCARAB",
    symbol: "SCARAB",
    address: "FgGSSyWTof35tPwZi8CZ7uoH2tD6y1ezWcMiw38Cmoat",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgGSSyWTof35tPwZi8CZ7uoH2tD6y1ezWcMiw38Cmoat/logo.png",
    tags: [],
    verified: true,
    holders: 2133,
  },
  {
    chainId: 101,
    name: "Triton",
    symbol: "TRTN",
    address: "7FSG3cy7SjR5mpjoRMbdj8ux5KYh3v8M7n1p7Sb3swE6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FSG3cy7SjR5mpjoRMbdj8ux5KYh3v8M7n1p7Sb3swE6/logo.png",
    tags: ["TRTN-token"],
    verified: true,
    holders: 227,
  },
  {
    chainId: 101,
    name: "CELO (Allbridge from Celo)",
    symbol: "CELO",
    address: "GNCjk3FmPPgZTkbQRSxr6nCvLtYMbXKMnRxg8BgJs62e",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNCjk3FmPPgZTkbQRSxr6nCvLtYMbXKMnRxg8BgJs62e/logo.png",
    tags: [],
    verified: true,
    holders: 660,
  },
  {
    chainId: 101,
    name: "FTM (Allbridge from Fantom)",
    symbol: "FTM",
    address: "EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4/logo.png",
    tags: [],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "WL Squirrels Nation",
    symbol: "WLSN",
    address: "64dTMpe6o3nxRm8jnnxwxpKqinH7ioEZoxB7gBe48nnT",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64dTMpe6o3nxRm8jnnxwxpKqinH7ioEZoxB7gBe48nnT/logo.png",
    tags: [],
    verified: true,
    holders: 357,
  },
  {
    chainId: 101,
    name: "SLAY",
    symbol: "$SLAY",
    address: "6LcERGBPSv7nhoA6Gr8nvke1xcAmbarVsCMHVvQ3NCbt",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/Don-73/-lay/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 311,
  },
  {
    chainId: 101,
    name: "ChumpChange",
    symbol: "CHGE",
    address: "DVWbqNnW9btNcYJassHT8a9eon6Mhi7YbaZEdgBDNie7",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DVWbqNnW9btNcYJassHT8a9eon6Mhi7YbaZEdgBDNie7/logo.png",
    tags: ["utility-token", "community-token", "meme-token"],
    verified: true,
    holders: 307,
  },
  {
    chainId: 101,
    name: "Tesla Inu",
    symbol: "TENU",
    address: "J2beWpqSSoFXzzotd9uWQe9xSHZkszAntjYD7xCdnu1K",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J2beWpqSSoFXzzotd9uWQe9xSHZkszAntjYD7xCdnu1K/logo.png",
    tags: ["utility-token", "meme-token"],
    verified: true,
    holders: 193,
  },
  {
    chainId: 101,
    name: "Sunny IOU Token (Quarry Rewards)",
    symbol: "SUNNY",
    address: "SRYWvj5Xw1UoivpdfJN4hFZU1qbtceMvfM5nBc3PsRC",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag/logo.svg",
    tags: [],
    verified: true,
    holders: 48857,
  },
  {
    chainId: 101,
    name: "HUMAN",
    symbol: "HUMAN",
    address: "HuManQDs2YtrRkQu4www48fFc6mz39gG6u2vUT2U9B9X",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HuManQDs2YtrRkQu4www48fFc6mz39gG6u2vUT2U9B9X/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1906,
  },
  {
    chainId: 101,
    name: "Punkz",
    symbol: "Punkz",
    address: "3wScesVuo8sAb8DwheMBq6amGCKuvWPD54Yg8aGQM4Eq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3wScesVuo8sAb8DwheMBq6amGCKuvWPD54Yg8aGQM4Eq/logo.jpg",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 142,
  },
  {
    chainId: 101,
    name: "RetroBit",
    symbol: "RTRO",
    address: "H7NbVyqLPHWC7Da49F4CLNTgfMfYq8dF9ktJW5wwH3Ck",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H7NbVyqLPHWC7Da49F4CLNTgfMfYq8dF9ktJW5wwH3Ck/logo.png",
    tags: ["utility-token", "retro-token", "retro-trades"],
    verified: true,
    holders: 756,
  },
  {
    chainId: 101,
    name: "Subayai Snakes VENOM",
    symbol: "VENOM",
    address: "GQVAdB2mYSNG1amewdSmTheoFGggy99Hjv6fDjTZrnFo",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/abrarmusa/files/master/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 368,
  },
  {
    chainId: 101,
    name: "Sol Gems",
    symbol: "SGEM",
    address: "2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3102,
  },
  {
    chainId: 101,
    name: "BALLZ",
    symbol: "BLZ",
    address: "DkYtowSvwrkp9G3PBKDwtfLbTxCJhuj92XcrnzxNhp3G",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/93989494/149791451-1b1c77bc-bf0e-4942-90a5-a2793f365034.png",
    tags: ["social-token"],
    verified: true,
    holders: 888,
  },
  {
    chainId: 101,
    name: "Djib",
    symbol: "DJIB",
    address: "3Hi27r9kUtSmiPYtpRJNYCCGTGosnqC5Dhz812TrjpaY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Hi27r9kUtSmiPYtpRJNYCCGTGosnqC5Dhz812TrjpaY/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 37544,
  },
  {
    chainId: 101,
    name: "Key 2 SolanaLand",
    symbol: "LNFTxK2SOLAND",
    address: "G4iSJWW63sU8yRWtV4AkcQ5JJmEdbUHEG68aDyHbaqr3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G4iSJWW63sU8yRWtV4AkcQ5JJmEdbUHEG68aDyHbaqr3/logo.png",
    tags: ["liqnft-wl-token"],
    verified: true,
    holders: 502,
  },
  {
    chainId: 101,
    name: "Flipper Therapy",
    symbol: "FLIPR",
    address: "FLiPR1BvcJCvR94NqKrmmQ2tngLeYXPu4UXGA1i7cNR3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLiPR1BvcJCvR94NqKrmmQ2tngLeYXPu4UXGA1i7cNR3/logo.png",
    tags: [],
    verified: true,
    holders: 155,
  },
  {
    chainId: 101,
    name: "SKREEE",
    symbol: "SKREEE",
    address: "56rYxFVCb4EfQNdRYrBtG8ENT9x3pRSHKuSCuAG9NfFs",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56rYxFVCb4EfQNdRYrBtG8ENT9x3pRSHKuSCuAG9NfFs/logo.png",
    tags: ["community-token", "utility-token", "social-token", "NFTs", "DeFi"],
    verified: true,
    holders: 153,
  },
  {
    chainId: 101,
    name: "Slambert Coupons",
    symbol: "SLAM",
    address: "s1ams7bwzHCG2qQf2yMF5CWASV37YotDQsjRppVkX9n",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/s1ams7bwzHCG2qQf2yMF5CWASV37YotDQsjRppVkX9n/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1913,
  },
  {
    chainId: 101,
    name: "PozzCoin",
    symbol: "POZZ",
    address: "27nqFZqb2iPBeVA7bbE4KPZrJgi3dJdKV9VzhCguSy6Y",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/dc215007/pozz1/main/logo1.png",
    tags: ["social-token", "meme-token"],
    verified: true,
    holders: 119,
  },
  {
    chainId: 101,
    name: "Shill City Triton",
    symbol: "TRTN",
    address: "8rDACnycUMGFvndX74ZM9sxjEbR3gUpVHDjDbL4qW6Zf",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8rDACnycUMGFvndX74ZM9sxjEbR3gUpVHDjDbL4qW6Zf/logo.png",
    tags: [],
    verified: true,
    holders: 6032,
  },
  {
    chainId: 101,
    name: "GameYoo Token",
    symbol: "GYC",
    address: "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png",
    tags: [
      "social-token",
      "community-token",
      "utility-token",
      "game-token",
      "game-currency",
      "GameFi",
      "Gaming",
      "metaverse",
      "play-to-earn",
      "play-to-contribute",
    ],
    verified: true,
    holders: 3581,
  },
  {
    chainId: 101,
    name: "Rally (Solana)",
    symbol: "sRLY",
    address: "RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq/logo.png",
    tags: [],
    verified: true,
    holders: 94020,
  },
  {
    chainId: 101,
    name: "Nashorn Access",
    symbol: "Nashorn",
    address: "CMa2oZ98xdsDFVdQpKKzgAJLJUq2HBiUCMi9y3u5S3uo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CMa2oZ98xdsDFVdQpKKzgAJLJUq2HBiUCMi9y3u5S3uo/nash.png",
    tags: [],
    verified: true,
    holders: 267,
  },
  {
    chainId: 101,
    name: "LAVA Token",
    symbol: "LAVA",
    address: "4o67Pazc9fNqEfQM66xqWngw7WdAUzsccdpPmKsANDg1",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4o67Pazc9fNqEfQM66xqWngw7WdAUzsccdpPmKsANDg1/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 516,
  },
  {
    chainId: 101,
    name: "Basic Tokens",
    symbol: "BASIC",
    address: "76DThuyLHagfkm2ssYw6Lv7k3MYHx6tXcwPBLU9tXm4D",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76DThuyLHagfkm2ssYw6Lv7k3MYHx6tXcwPBLU9tXm4D/logo.png",
    tags: [],
    verified: true,
    holders: 1002,
  },
  {
    chainId: 101,
    name: "XAL Coin",
    symbol: "XAL",
    address: "9GnYb1ukBUKHobqpmNdzBE7VkYn7wWqianpKaYFPBChk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9GnYb1ukBUKHobqpmNdzBE7VkYn7wWqianpKaYFPBChk/logo.png",
    tags: [],
    verified: true,
    holders: 1885,
  },
  {
    chainId: 101,
    name: "Godz Token",
    symbol: "GODZ",
    address: "BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca/logo.jpg",
    tags: ["utility-token", "nft-token"],
    verified: true,
    holders: 805,
  },
  {
    chainId: 101,
    name: "Astral House Whitelist Token",
    symbol: "AHWL",
    address: "CVsL7JFa2BHfckDcc4PTxX7ARCUGuFYav5zRDVK2JFRe",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CVsL7JFa2BHfckDcc4PTxX7ARCUGuFYav5zRDVK2JFRe/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2980,
  },
  {
    chainId: 101,
    name: "BunnyDucky Staking Token",
    symbol: "sBUD",
    address: "sBUDzitkrHNZAR2gAoRorwSo6nqBEsHfRmdybHCsw8o",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sBUDzitkrHNZAR2gAoRorwSo6nqBEsHfRmdybHCsw8o/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 922,
  },
  {
    chainId: 101,
    name: "WHEY",
    symbol: "WHEY",
    address: "5fTwKZP2AK39LtFN9Ayppu6hdCVKfMGVm79F2EgHCtsi",
    decimals: 6,
    logoURI:
      "https://secureservercdn.net/160.153.138.53/cjm.06a.myftpupload.com/wp-content/uploads/2021/12/whey-coin-2048x2048.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3068,
  },
  {
    chainId: 101,
    name: "gmornn",
    symbol: "GMORNN",
    address: "DiWunPY8GfsFthdDAwiRRtCgKCB5AEcFx9edDpxZoTyo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiWunPY8GfsFthdDAwiRRtCgKCB5AEcFx9edDpxZoTyo/logo.png",
    tags: [],
    verified: true,
    holders: 355,
  },
  {
    chainId: 101,
    name: "611Coin",
    symbol: "SIXY",
    address: "GKNr1Gwf7AMvEMEyMzBoEALVBvCpKJue9Lzn9HfrYYhg",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/dc215007/611/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 270,
  },
  {
    chainId: 101,
    name: "Dino DAO",
    symbol: "Dino",
    address: "FUNHP94B46a486h5RSv46aeWUkeM5goTUuM5mVh4L6Qr",
    decimals: 3,
    logoURI:
      "https://hv4gxzchk24cqfezebn3ujjz6oy2kbtztv5vghn6kpbkjc3vg4rq.arweave.net/tVA2tvv9z8z5Si0aggHFXBmoNWBFupfn5Jjv7Faq8cs",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 464,
  },
  {
    chainId: 101,
    name: "LNFT Pudgy Penguin-5445",
    symbol: "LNFTxPP5445",
    address: "9QCTc8SEomtoYPBAZ5uU5G42buLBZKr8ic54jPMSZ8U8",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QCTc8SEomtoYPBAZ5uU5G42buLBZKr8ic54jPMSZ8U8/logo.png",
    tags: ["liqnft-owner-token"],
    verified: true,
    holders: 145,
  },
  {
    chainId: 101,
    name: "Tesla Shiba Inu",
    symbol: "TSI",
    address: "5cKFNtooCQSkLhdFukk8R3PTdT4Rvm9cJr8Et49TxchR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5cKFNtooCQSkLhdFukk8R3PTdT4Rvm9cJr8Et49TxchR/logo.png",
    tags: ["utility-token", "meme"],
    verified: true,
    holders: 299,
  },
  {
    chainId: 101,
    name: "Dasch Coin",
    symbol: "DASCH",
    address: "GTuDe5yneFKaWSsPqqKgu413KTk8WyDnUZcZUGxuNYsT",
    decimals: 9,
    logoURI: "https://github.com/kennethoqs/dasch/blob/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 109,
  },
  {
    chainId: 101,
    name: "Tiki Tix",
    symbol: "TIT",
    address: "7xZgeMkxKocj8wBBKdEFTaF25j4kUHVJbUZjvRqkdrsE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xZgeMkxKocj8wBBKdEFTaF25j4kUHVJbUZjvRqkdrsE/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 199,
  },
  {
    chainId: 101,
    name: "FBCMINT",
    symbol: "BLSHMNT",
    address: "86erHPHmYKXxkQSKVuRUazUrG62tZg1FdYr5oJPaS4tQ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/NFTBrickLayer/assets/main/FBC-logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 5562,
  },
  {
    chainId: 101,
    name: "LIT",
    symbol: "LIT",
    address: "8iey5bqBcqejJWcS7Two1ZffeJcxx28rfBMeSAqCF9nf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LIT-token/uploads/main/logo.png",
    tags: ["social-token", "utility-token", "web3"],
    verified: true,
    holders: 618,
  },
  {
    chainId: 101,
    name: "Chiwa Bones",
    symbol: "CWB",
    address: "2S3hhwCnDkFN2zHYc7SBzhLuHUevMwmgUCzq3ShDsUb5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2S3hhwCnDkFN2zHYc7SBzhLuHUevMwmgUCzq3ShDsUb5/logo.png",
    tags: [],
    verified: true,
    holders: 468,
  },
  {
    chainId: 101,
    name: "Astral House Mint Ticket",
    symbol: "AHMT",
    address: "2Tnc6ngSfJS4QNXzDU5daR2G7JH5uox2C6Z6B3y1SZ6i",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Tnc6ngSfJS4QNXzDU5daR2G7JH5uox2C6Z6B3y1SZ6i/logo.png",
    tags: ["whitelist-ticket"],
    verified: true,
    holders: 2888,
  },
  {
    chainId: 101,
    name: "TUSK (WATW)",
    symbol: "TUSK",
    address: "AG1wkgfXJNkS2xWsbDePVsVDsdiZofcm2JpYiMfvPnHg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AG1wkgfXJNkS2xWsbDePVsVDsdiZofcm2JpYiMfvPnHg/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 636,
  },
  {
    chainId: 101,
    name: "Airdrop Utility Token",
    symbol: "AUT",
    address: "DAhyNwD9bsMkcgXHzCvVCVimkpeRwc6bv9i47UuuQAo3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAhyNwD9bsMkcgXHzCvVCVimkpeRwc6bv9i47UuuQAo3/logo.png",
    tags: [],
    verified: true,
    holders: 429,
  },
  {
    chainId: 101,
    name: "Sol Force Whitelist Token",
    symbol: "SFWL",
    address: "EKe7rakfHZGhjPc7xtJ5RbjdPrydjxKrsoWZoEGatUvD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKe7rakfHZGhjPc7xtJ5RbjdPrydjxKrsoWZoEGatUvD/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1552,
  },
  {
    chainId: 101,
    name: "Hockey Heroes Whitelist",
    symbol: "HHWL",
    address: "6fDeCCoZLWRZporJtRBZ8toMs7BBn2wWQapdaKsTigpF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6fDeCCoZLWRZporJtRBZ8toMs7BBn2wWQapdaKsTigpF/logo.png",
    tags: [
      "social-token",
      "nft",
      "whitelist-token",
      "utility-token",
      "HockeyHeroes",
      "HHWL",
    ],
    verified: true,
    holders: 501,
  },
  {
    chainId: 101,
    name: "Doodle Bunny Whitelist",
    symbol: "DOODLEBUNNYWL",
    address: "GkJdRCiKg9oJK4ZJjsQAUQiTtFT7BzRtMCPms4neShVP",
    decimals: 0,
    logoURI:
      "https://nova-host.nyc3.cdn.digitaloceanspaces.com/doodlebunny/doodlebunny-icon.png",
    tags: ["community-token"],
    verified: true,
    holders: 148,
  },
  {
    chainId: 101,
    name: "Invoke",
    symbol: "INV",
    address: "invYVY53mcmBtf2RbVudoqKDyAgZGofkLYodvnQwQep",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/invYVY53mcmBtf2RbVudoqKDyAgZGofkLYodvnQwQep/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1135,
  },
  {
    chainId: 101,
    name: "crypt",
    symbol: "ENC",
    address: "F2CcdH4uXVL6vwutkFMtHWFaj87dYSh9WMWNqzsMmTUG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/encryptedsolana/crypt/main/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 1429,
  },
  {
    chainId: 101,
    name: "SolMaps Token",
    symbol: "SM",
    address: "ToeF3rovM5R7wMomNc8CZ2Pw78VyioUtrTrJs88MFh9",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToeF3rovM5R7wMomNc8CZ2Pw78VyioUtrTrJs88MFh9/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 288,
  },
  {
    chainId: 101,
    name: "Dark Terminal Access Coin",
    symbol: "DTAC",
    address: "GsBJWMp5UDJqBiaAvgQRoC5RyJhm125jZVo3oFV6zoa5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GsBJWMp5UDJqBiaAvgQRoC5RyJhm125jZVo3oFV6zoa5/dtac-logo.png",
    tags: [
      "social-token",
      "NFT",
      "dark-terminal",
      "DTAC",
      "hacking",
      "game",
      "P2E",
      "play-to-earn",
    ],
    verified: true,
    holders: 530,
  },
  {
    chainId: 101,
    name: "ANYBODIES Fashion Token",
    symbol: "LOCO",
    address: "33j6cnQUmef2tSbSvxn2U4uSBdKYSbRvzmJcPAvDEHoW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33j6cnQUmef2tSbSvxn2U4uSBdKYSbRvzmJcPAvDEHoW/loco.png",
    tags: ["utility-token", "fashion", "wte", "wear-to-earn"],
    verified: true,
    holders: 1149,
  },
  {
    chainId: 101,
    name: "Rum Token",
    symbol: "RUMT",
    address: "6CQuweCcPRWAaQNvnkvwWjrbxRe6MuVh5eFFVk8DVyM7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SeaRovers/rum_icon/main/Rum_Token.png",
    tags: [],
    verified: true,
    holders: 1152,
  },
  {
    chainId: 101,
    name: "Zombieverse Whitelist",
    symbol: "WLZVC",
    address: "6RUV3rfx3q4n7UEJcARs9gCibB3TD5pgrxha1LmQwptV",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cvzwk5dvHu67n2b4BDMNQ7VTQ4G2XRQ6hN3uDBPmD9vt/logo.png",
    tags: ["WriteToEarn", "Zombieverse", "Whitelist", "MintPass"],
    verified: true,
    holders: 250,
  },
  {
    chainId: 101,
    name: "Chilled Apes Token",
    symbol: "CHILL",
    address: "9krdcPB9gzGZk3QxqJi6AEAdyVt1RiDmQKin3im29DCJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9krdcPB9gzGZk3QxqJi6AEAdyVt1RiDmQKin3im29DCJ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 700,
  },
  {
    chainId: 101,
    name: "BIT Token",
    symbol: "BIT",
    address: "EGiWZhNk3vUNJr35MbL2tY5YD6D81VVZghR2LgEFyXZh",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62/BT-logo.png",
    tags: ["utility-token", "Game-Token"],
    verified: true,
    holders: 3696,
  },
  {
    chainId: 101,
    name: "DagoDAO Whitelist Token",
    symbol: "DAGO-WL",
    address: "E7vVVWcdUFxGJ494C2W1AnbcYLzf5GJTWJzetCjzA7A7",
    decimals: 0,
    logoURI: "https://arweave.net/juXE_DoTwZng2BK9QN6XdypWq5RscNKoxcKFUEQQ2Ho",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 186,
  },
  {
    chainId: 101,
    name: "CAPRA Token",
    symbol: "CAPRA",
    address: "GzMYkPvEccCxn7bvZh8Jhz87GhrASKhVadn9LHE4zVDy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GzMYkPvEccCxn7bvZh8Jhz87GhrASKhVadn9LHE4zVDy/logo.png",
    tags: ["whitelist-token", "governance-token", "community-token"],
    verified: true,
    holders: 1003,
  },
  {
    chainId: 101,
    name: "Atom Solana Token",
    symbol: "ATOS",
    address: "FowCNsVNypPgQqSGu8ZQ4GLxMMsZTsciSFYbSURHcBdz",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Mickyfly/assets/f54a2c841882bce3cb4839166d47ef087bae8f22/logo.jpg",
    tags: ["social-token"],
    verified: true,
    holders: 361,
  },
  {
    chainId: 101,
    name: "Lazy",
    symbol: "LAZY",
    address: "CVoDGyg8cUebjpHUb7N7r32sg2gawXRG7JNQNqJFTAGR",
    decimals: 9,
    logoURI: "https://arweave.net/-eAPfd93Q3-JeZ5dLyr5PfXo0TgC5n0ufI-LuFwxpcQ",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 221,
  },
  {
    chainId: 101,
    name: "Primordials Token",
    symbol: "PRMD",
    address: "Ghj7ib4VJC592ybwpMK75vaX3fRu1SqtkXYMd2pNywtK",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/84945202/152918221-72a0d9a4-374b-4b30-b686-e371502b0978.png",
    tags: ["social-token", "gaming-token"],
    verified: true,
    holders: 1061,
  },
  {
    chainId: 101,
    name: "KMC FINANCE",
    symbol: "KMC",
    address: "Ajnx5aVELiDbir3du6c9pbuJPJNmpqoEJBhND2oswmKz",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/mum33n/kmc/main/logo.eac9db95.jpg",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 1417,
  },
  {
    chainId: 101,
    name: "AotM - Bronze WL Token",
    symbol: "AotMBronze",
    address: "brnzEEdWQ5pBd2Qfec4Tquw5xL1tLkL85qFuBsMGjfm",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/brnzEEdWQ5pBd2Qfec4Tquw5xL1tLkL85qFuBsMGjfm/bronze.png",
    tags: [],
    verified: true,
    holders: 564,
  },
  {
    chainId: 101,
    name: "ApesAgainst WL Token",
    symbol: "ALBINO",
    address: "4Z9UMELGy3tU4pD4PvnQCiTVGPDpNK6e25XYSWYH9MrG",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Z9UMELGy3tU4pD4PvnQCiTVGPDpNK6e25XYSWYH9MrG/logo.png",
    tags: [],
    verified: true,
    holders: 2986,
  },
  {
    chainId: 101,
    name: "0xDRIP Token",
    symbol: "DRIP",
    address: "AA6LkBM7m5wmhrrTopNfEeMrLupsRDZVQzFSfL2rr5iQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AA6LkBM7m5wmhrrTopNfEeMrLupsRDZVQzFSfL2rr5iQ/logo.png",
    tags: ["NFT", "utility-token", "community-token", "altcoin"],
    verified: true,
    holders: 1054,
  },
  {
    chainId: 101,
    name: "ASSET",
    symbol: "ASSET",
    address: "9861az8m8LiuEeSgsmUaNVzVcq6XGDG6YCj3Jq7MRKHq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9861az8m8LiuEeSgsmUaNVzVcq6XGDG6YCj3Jq7MRKHq/logo.png",
    tags: [],
    verified: true,
    holders: 1347,
  },
  {
    chainId: 101,
    name: "Pika Coin",
    symbol: "PIKA",
    address: "5J6C29Rz2m3MfRk4CEwnVTAAiAoii8Vbrvecc6aKRJYv",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5J6C29Rz2m3MfRk4CEwnVTAAiAoii8Vbrvecc6aKRJYv/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 392,
  },
  {
    chainId: 101,
    name: "LoadedCrates WLs1",
    symbol: "LCWL",
    address: "To1az33ZSY6GUueytiXUysBputPeU6zQdz6zc9HsCLE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/setaf007/lc-token-cli/main/logo.png",
    tags: [],
    verified: true,
    holders: 378,
  },
  {
    chainId: 101,
    name: "Syndicate Mint Token",
    symbol: "VMNT",
    address: "35PTcAHcQYrRS5QW8fgHx163rWV9ffXU9Y66Hio853P3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/benpazzo/VMNT/main/assets/VMNT.png",
    tags: ["social-token"],
    verified: true,
    holders: 292,
  },
  {
    chainId: 101,
    name: "Graphic Design",
    symbol: "GRAFO",
    address: "4Pti79GJaprhqqkhSWxdyhE6MHKTLTEKfcyfMXiZFp5M",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/SegFun01/EIC/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 121,
  },
  {
    chainId: 101,
    name: "Naga Kingdom",
    symbol: "NAGA",
    address: "NaFJTgvemQFfTTGAq2PR1uBny3NENWMur5k6eBsG5ii",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NaFJTgvemQFfTTGAq2PR1uBny3NENWMur5k6eBsG5ii/logo.png",
    tags: ["utility-token", "game-token", "io-game", "GameFi", "Gaming"],
    verified: true,
    holders: 1502,
  },
  {
    chainId: 101,
    name: "Magic Eggs",
    symbol: "MAGA",
    address: "Ma4dse7fmzXLQYymNsDDjq6VgRXtEFTJw1CvmRrBoKN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ma4dse7fmzXLQYymNsDDjq6VgRXtEFTJw1CvmRrBoKN/logo.png",
    tags: ["utility-token", "game-token", "io-game", "GameFi", "Gaming"],
    verified: true,
    holders: 427,
  },
  {
    chainId: 101,
    name: "Lost Cat FUD Club VIP List",
    symbol: "LCVIP",
    address: "7aAQDS1p6cKDe5W7MJwHAaDQTec2FETbWj87P4jK6xik",
    decimals: 0,
    logoURI:
      "https://gateway.pinata.cloud/ipfs/QmQkFU34BMBVXU5ZTxfXKGgjuyW9PGLhiDjjf2iVTgNz2S",
    tags: ["nft-presale"],
    verified: true,
    holders: 215,
  },
  {
    chainId: 101,
    name: "Particles",
    symbol: "OOO",
    address: "BDNRJZ6MA3YRhHcewYMjRDEc7oWQCxHknXU98wwTsSxu",
    decimals: 2,
    logoURI: "https://i.imgur.com/mCP5vxP.jpg",
    tags: ["particles", "one-of-ones", "bridgesplit-fraction", "curated-index"],
    verified: true,
    holders: 2069,
  },
  {
    chainId: 101,
    name: "Feisty Doge NFT",
    symbol: "NFD",
    address: "CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu/logo.svg",
    tags: ["ethereum", "wrapped", "wormhole", "fractionalized-nft"],
    verified: true,
    holders: 662,
  },
  {
    chainId: 101,
    name: "Draygon Guarantee Mint Token",
    symbol: "DRYGN",
    address: "DdLynXMq4hURnMMNNVt2CRi1WbnbV2NmuEb8skhWZUAh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/benpazzo/draygontoken/main/assets/guaranteemint.png",
    tags: ["social-token", "community-token", "utility-token"],
    verified: true,
    holders: 465,
  },
  {
    chainId: 101,
    name: "Draygon WL FCFS Mint Token",
    symbol: "DRYGNWL",
    address: "sYQeqp87wBhQyZMo2TfCVNrFiX4HrLAWUkr2rmqbRoN",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/benpazzo/draygontoken/main/assets/wlfcfs.png",
    tags: ["social-token", "community-token", "utility-token"],
    verified: true,
    holders: 1168,
  },
  {
    chainId: 101,
    name: "CREATiP",
    symbol: "TiP",
    address: "CGBxTQNXMmmCXAPm4dC9MQJ1q8JDDxApwFwW188SdtBu",
    decimals: 2,
    logoURI: "https://arweave.net/bZGwLPTI6aay84W9TF3R190dezSGf8jpC7VBTXljd2g",
    tags: ["community-token", "fund-your-creator", "tip-token"],
    verified: true,
    holders: 631,
  },
  {
    chainId: 101,
    name: "NFTABS Whitelist Token",
    symbol: "NFTABSWL",
    address: "ErbJzzRAEM8cVRMXHTftVDFbUVM7TiFWSfaZx4zQRJhS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/jenvillaganas/crypto/main/assets/ErbJzzRAEM8cVRMXHTftVDFbUVM7TiFWSfaZx4zQRJhS/logo.jpg",
    tags: ["whitelist-token"],
    verified: true,
    holders: 6755,
  },
  {
    chainId: 101,
    name: "Historians WL",
    symbol: "HIST",
    address: "9C7ipMbr5b2YhgGzFy1cxUotnCHcPhbp6wdQWD4EeggA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9C7ipMbr5b2YhgGzFy1cxUotnCHcPhbp6wdQWD4EeggA/logo.png",
    tags: ["mint-token"],
    verified: true,
    holders: 578,
  },
  {
    chainId: 101,
    name: "ICON",
    symbol: "ICON",
    address: "HAfTjdSjZiquZiAkmsYBmcFR5NM7cP8HtMqjQRk8eVTX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAfTjdSjZiquZiAkmsYBmcFR5NM7cP8HtMqjQRk8eVTX/logo.png",
    tags: [],
    verified: true,
    holders: 1672,
  },
  {
    chainId: 101,
    name: "Positron",
    symbol: "POT",
    address: "9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj/logo.png",
    tags: ["utility-token", "social-token", "governance-token"],
    verified: true,
    holders: 2270,
  },
  {
    chainId: 101,
    name: "Negatron",
    symbol: "NET",
    address: "AsiP4tPh6tWfgKMjTibhA5H6ge3EnHSyh3iPPDEFSqSu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsiP4tPh6tWfgKMjTibhA5H6ge3EnHSyh3iPPDEFSqSu/logo.png",
    tags: ["utility-token", "social-token", "governance-token"],
    verified: true,
    holders: 8288,
  },
  {
    chainId: 101,
    name: "BUDS - PotHeads",
    symbol: "BUDS",
    address: "CEUQNkt3tXqakiXurUbCMVVq9RRaPFsaw8fUKfTNgpup",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/PotHeadsNFT/marketing-assets/main/9YjkcXTNKn3f2NmgO67w8P2kFC_LfCNvPdyG3OaSy24%207.57.15%20AM.png",
    tags: ["utility-token", "nft-token"],
    verified: true,
    holders: 601,
  },
  {
    chainId: 101,
    name: "AOS Mint Token",
    symbol: "AOSM",
    address: "T1AJW2EJLkDeEFEurHCJH9vBde23tVKD4pgDHEFCGUK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/zenturing/token-list/aos_t/assets/mainnet/T1AJW2EJLkDeEFEurHCJH9vBde23tVKD4pgDHEFCGUK/aos.png",
    tags: ["mint-token"],
    verified: true,
    holders: 268,
  },
  {
    chainId: 101,
    name: "ToadBoys Mint Pass",
    symbol: "TBMP",
    address: "9tdCBZd9ZtpAS4KjBhfx4VsA9svC7ZQD8UR7FXHyLPaP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9tdCBZd9ZtpAS4KjBhfx4VsA9svC7ZQD8UR7FXHyLPaP/toad_logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1233,
  },
  {
    chainId: 101,
    name: "Mint Discount Team Tuskers",
    symbol: "MINTTUSKER",
    address: "CNgARsLNnudL5UqbqkwA2mQ1aah2U9kfHFyoppZTeTKx",
    decimals: 0,
    logoURI:
      "https://www.teamtuskers.com/wp-content/uploads/2021/12/tuskers-logo-circle-01.png",
    tags: ["discount-token"],
    verified: true,
    holders: 4323,
  },
  {
    chainId: 101,
    name: "Ultron",
    symbol: "ULTRON",
    address: "3zD9zassLocyH6Tdj2NYXw6ym1jtgaM3QDkmeJNwNjer",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3zD9zassLocyH6Tdj2NYXw6ym1jtgaM3QDkmeJNwNjer/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 496,
  },
  {
    chainId: 101,
    name: "Foxy",
    symbol: "FOXY",
    address: "Foxy7Df6VEc1dUCr1ExZfRAqqHaifNFDd8ccvAs59DNr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/foxytoken/foxytoken/main/foxy.png",
    tags: [],
    verified: true,
    holders: 1938,
  },
  {
    chainId: 101,
    name: "HAPPY",
    symbol: "HAPPY",
    address: "CHzPHnY84VZdgzEnpPJZBTCXiSSsiMEah1qeLGQgD2q8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHzPHnY84VZdgzEnpPJZBTCXiSSsiMEah1qeLGQgD2q8/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 531,
  },
  {
    chainId: 101,
    name: "DNMM WL Token",
    symbol: "$DNWL",
    address: "vYDNTpXUjidPNewzHxcCC7NRT2i97J82LeFkZyEZ3HM",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vYDNTpXUjidPNewzHxcCC7NRT2i97J82LeFkZyEZ3HM/logo.png",
    tags: [],
    verified: true,
    holders: 181,
  },
  {
    chainId: 101,
    name: "Please don't tell Mask token",
    symbol: "MASK",
    address: "4SVyZ9e8EJFLiHeoys7Bsm4EH8bkfCRMcL4TzEEfsyXE",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SVyZ9e8EJFLiHeoys7Bsm4EH8bkfCRMcL4TzEEfsyXE/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 306,
  },
  {
    chainId: 101,
    name: "Green Impact NFT Token",
    symbol: "GIN",
    address: "32Eh585dMTSAPHF28DEG8i8vNcS9iwoVRdaJkBucfqG1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32Eh585dMTSAPHF28DEG8i8vNcS9iwoVRdaJkBucfqG1/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2343,
  },
  {
    chainId: 101,
    name: "Chimpanions WL Token",
    symbol: "ChimpWL",
    address: "BjFceVzhHSADeV9udJnS73sxdGFQ2VcFdi1meXhS1EzF",
    decimals: 0,
    logoURI:
      "https://cdn.discordapp.com/attachments/935006072266162177/936269290955485214/unknown.png",
    tags: [],
    verified: true,
    holders: 1012,
  },
  {
    chainId: 101,
    name: "ATA",
    symbol: "ATA",
    address: "9cyEStsrZF7LzqLzbNcuUeuat1NM4eHrBVApvkPBCQk4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puppetmonkester/img-repo/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3054,
  },
  {
    chainId: 101,
    name: "Mushroom Racers Token",
    symbol: "VROOM",
    address: "KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx/logo.png",
    tags: [],
    verified: true,
    holders: 1067,
  },
  {
    chainId: 101,
    name: "PANZR Credits Token",
    symbol: "cPANZR",
    address: "Eo5YeDSgnH1qa4tL5iWhyk7iKdohWWpv9iDtym3Kgb7t",
    decimals: 4,
    logoURI: "https://www.panzerdogs.io/img/cPANZR.gif",
    tags: ["utility-token", "game-token", "staking"],
    verified: true,
    holders: 1694,
  },
  {
    chainId: 101,
    name: "Solarmy AMMO",
    symbol: "$AMMO",
    address: "H1X2quMc8ZesCE4afDQhWAmFssENprmmz9a6SXvHWPoN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/UniversolSoldier/UniversolSoldier/main/ammo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 407,
  },
  {
    chainId: 101,
    name: "'Plan B' Token",
    symbol: "PBT",
    address: "ToQaYfKWvHtHpmPsCo4awxavoEQJjguBZzGiZBM2vYT",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToQaYfKWvHtHpmPsCo4awxavoEQJjguBZzGiZBM2vYT/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2365,
  },
  {
    chainId: 101,
    name: "LUX Token",
    symbol: "LUX",
    address: "3gb3iUAgTCtXq7cFiDZTi2GFPgQdg1zFVUubYAtLJfWU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3gb3iUAgTCtXq7cFiDZTi2GFPgQdg1zFVUubYAtLJfWU/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 5934,
  },
  {
    chainId: 101,
    name: "BAPC Native Token",
    symbol: "UGA",
    address: "282Weh31UZ1yw99dYV8JqxPLtFBNbN1UfZhjBiKNk9Zb",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7aENbEssDsZZ7T1HP7LLgoHJF8q4JDKfxArkmdLReUZc/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 629,
  },
  {
    chainId: 101,
    name: "Solana Monke Rejects Whitelist Token",
    symbol: "SMR",
    address: "2JqUDmTAGdebvbvjWXgSVq1bj1mDhjhX2q531AmPB9Vk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/milorue/token-assets/master/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1950,
  },
  {
    chainId: 101,
    name: "STOMP",
    symbol: "STOMP",
    address: "6cMZs2PRafVnMHkXiDu5SLvomqLHCS11bxwi9KBJTtzZ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cMZs2PRafVnMHkXiDu5SLvomqLHCS11bxwi9KBJTtzZ/logo.jpg",
    tags: [],
    verified: true,
    holders: 121,
  },
  {
    chainId: 101,
    name: "The Lion Cats Token",
    symbol: "IMBA",
    address: "imbaePRPNVxBhTLdSWpdjYbXiPWc9spNTz4xKVkZfBJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/nf0x/the-lion-cats-logo/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1205,
  },
  {
    chainId: 101,
    name: "Luve Factory",
    symbol: "LUVE",
    address: "FE7ZpFUeHrWdcxga7yKV2WnGvGNRKv63EDzkLzMbgyRT",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/LuveFactory/assets/main/LUVE-520x520.png",
    tags: ["nft-token", "finance-token"],
    verified: true,
    holders: 1256,
  },
  {
    chainId: 101,
    name: "Giraffe",
    symbol: "GIF",
    address: "CWUUV3ym4Uphw4CVgkpNxrR7FsttF7h7mLggEUJ1J1aV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWUUV3ym4Uphw4CVgkpNxrR7FsttF7h7mLggEUJ1J1aV/logo.jpg",
    tags: ["social-token", "community-token", "meme-token"],
    verified: true,
    holders: 2491,
  },
  {
    chainId: 101,
    name: "CHALK",
    symbol: "CHALK",
    address: "CmAgr6XtAZsR1BGrxTrbVKXsVvvC4Y69GXR2zP8XxK3X",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CmAgr6XtAZsR1BGrxTrbVKXsVvvC4Y69GXR2zP8XxK3X/logo.png",
    tags: [],
    verified: true,
    holders: 705,
  },
  {
    chainId: 101,
    name: "Fellowship WL Token",
    symbol: "FWL",
    address: "D1MQUD2QKg4HaMbasBq4gk8LWjaogBiGV2vdLkrodBkx",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1MQUD2QKg4HaMbasBq4gk8LWjaogBiGV2vdLkrodBkx/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 3127,
  },
  {
    chainId: 101,
    name: "Secret Syren Pass",
    symbol: "SSP",
    address: "BV2AJmBUFnEXqAjXxhds5st6hoHnCfbCLzqnGY87ciWQ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BV2AJmBUFnEXqAjXxhds5st6hoHnCfbCLzqnGY87ciWQ/logo.png",
    tags: [],
    verified: true,
    holders: 578,
  },
  {
    chainId: 101,
    name: "SAMI",
    symbol: "SAMI",
    address: "61m2xv1m6zTEAS86VfjFmNKG1ZGemNu19hzMmgstowLZ",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/tund1/images/main/SAMI.png",
    tags: ["utility-token"],
    verified: true,
    holders: 521,
  },
  {
    chainId: 101,
    name: "Citizens Mint Key",
    symbol: "SCMK",
    address: "4n91Cp8ufhotnvvNj7zMZemYhCdfaJhSAQifGMGrDXNR",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4n91Cp8ufhotnvvNj7zMZemYhCdfaJhSAQifGMGrDXNR/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 1701,
  },
  {
    chainId: 101,
    name: "Dino Coin",
    symbol: "DINO",
    address: "8J7yrjW4JsZYiLUMWxyHu5V1bStvFQ7yD3jHrkTk88wk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/vinceeverton/everton/main/daring%20dinos%20%23111131.jpg",
    tags: [],
    verified: true,
    holders: 127,
  },
  {
    chainId: 101,
    name: "Universe Pass",
    symbol: "UPASS",
    address: "5Wddj4FevPALWn655YTC5wGktWM7vViQTzLJLr2dhGFF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Wddj4FevPALWn655YTC5wGktWM7vViQTzLJLr2dhGFF/logo.png",
    tags: ["utiliy-token"],
    verified: true,
    holders: 4919,
  },
  {
    chainId: 101,
    name: "BACH Token",
    symbol: "BACH",
    address: "CTQBjyrX8pYyqbNa8vAhQfnRXfu9cUxnvrxj5PvbzTmf",
    decimals: 12,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/badd1dbe8c2d1e38c4f77b77f1d5fd5c60d3cccb/assets/mainnet/CTQBjyrX8pYyqbNa8vAhQfnRXfu9cUxnvrxj5PvbzTmf/bach-token-logo-Est.2022.png",
    tags: ["music-token", "social-token", "utility-token"],
    verified: true,
    holders: 225,
  },
  {
    chainId: 101,
    name: "PSYn",
    symbol: "$PSYn",
    address: "2y7wUCJdtqTbjnKBNEB3DpAYCuwA1atCtdfkGt9VR4sM",
    decimals: 9,
    logoURI:
      "https://github.com/charliebakerr/psy-token/blob/main/photo_2022-01-30%2012.23.01.jpeg",
    tags: ["social-token"],
    verified: true,
    holders: 1350,
  },
  {
    chainId: 101,
    name: "AlienUniverse Token",
    symbol: "ALIEN",
    address: "4mJ6N65rD9w6sFPQ17UDWot2H64UtzR31biVLaKpZT6J",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/alien-universe/assets/main/logo.png",
    tags: ["game-token", "community-token"],
    verified: true,
    holders: 830,
  },
  {
    chainId: 101,
    name: "ARTIFAKT Mint Pass",
    symbol: "ARTIFAKT",
    address: "EcSXCrLsB6nZ3ccr2VR6mrXXsXsCAWtG2a9cWMKn4CJt",
    decimals: 0,
    logoURI: "https://arweave.net/1lU3cQ15_5Wq3khZO_d_Jr9_xIlLhDTh_vbJrMtug0k",
    tags: [],
    verified: true,
    holders: 647,
  },
  {
    chainId: 101,
    name: "SKOL",
    symbol: "SKOL",
    address: "6xUECSMxSjPGPMzauuCjJGkYgyLYC5UJWpv5en8twhPN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xUECSMxSjPGPMzauuCjJGkYgyLYC5UJWpv5en8twhPN/logo.png",
    tags: [],
    verified: true,
    holders: 220,
  },
  {
    chainId: 101,
    name: "Mooney Token",
    symbol: "MONY",
    address: "MonYu4GQb1dpoMs4DG1FpJt5F9nXtUy6JRyvANZFxZu",
    decimals: 6,
    logoURI: "https://arweave.net/b-zptDkkRt3xz9dNflpUxRMJMqFq40tPVS5wjuicDd4",
    tags: ["social-token"],
    verified: true,
    holders: 1097,
  },
  {
    chainId: 101,
    name: "Alpha",
    symbol: "ALPHA",
    address: "3xbqJgCsf2PjKQsjACGUvH2Eo8ZyGxW2hWGY9TZW2q8D",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3xbqJgCsf2PjKQsjACGUvH2Eo8ZyGxW2hWGY9TZW2q8D/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 2141,
  },
  {
    chainId: 101,
    name: "DevCoin",
    symbol: "DEV",
    address: "H5euuuZXAuFak2NVTMu53fckdkHFWuJzXXb3TfKTrLWK",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/thedevdojo/devcoin/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 209,
  },
  {
    chainId: 101,
    name: "Bluechip Mint",
    symbol: "BLCWL",
    address: "2dsWvsHBE5biqZtNDYDUfxGVWKC7xSfwTArwic8MJAWy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/NFTBrickLayer/assets/main/BLC-logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 452,
  },
  {
    chainId: 101,
    name: "BCBY: Land WL",
    symbol: "BCBY",
    address: "MR4LmLsWNyWZXzo3jHq1abv1ZwPwjGewrPoHsNSHVJk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/SupCa/token-list/main/assets/mainnet/MR4LmLsWNyWZXzo3jHq1abv1ZwPwjGewrPoHsNSHVJk/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 388,
  },
  {
    chainId: 101,
    name: "Myth",
    symbol: "$MYTH",
    address: "8t2JQrLWDN8HJ5jTNKzVYgJasmxpaqQW6yo6dx9M3BTK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8t2JQrLWDN8HJ5jTNKzVYgJasmxpaqQW6yo6dx9M3BTK/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1396,
  },
  {
    chainId: 101,
    name: "Rich Apes Mint Token",
    symbol: "RAMT",
    address: "APEbcwKE1yriVQVtmkgFKdyr7pn7Bj8q1KDgc1nMdXaA",
    decimals: 0,
    logoURI: "https://imgur.com/4mlZg5G.png",
    tags: ["social-token"],
    verified: true,
    holders: 108,
  },
  {
    chainId: 101,
    name: "Dolphin",
    symbol: "DPN",
    address: "7iV5gpBxv5kerSmu6qwPym2eHKiBrURS4RL3sHaq7vUq",
    decimals: 4,
    logoURI: "https://raw.githubusercontent.com/zia999/logo1/main/logo.png",
    tags: ["utility-token", "community-token", "meme-token"],
    verified: true,
    holders: 1407,
  },
  {
    chainId: 101,
    name: "rBasis",
    symbol: "rBASIS",
    address: "rBsH9ME52axhqSjAVXY3t1xcCrmntVNvP3X16pRjVdM",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rBsH9ME52axhqSjAVXY3t1xcCrmntVNvP3X16pRjVdM/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3098,
  },
  {
    chainId: 101,
    name: "BROCCOLI",
    symbol: "BROCCOLI",
    address: "2Z21GPCZSyrieTN3tYtPHUmuLWBffW9L86wqyZBj84Fv",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Z21GPCZSyrieTN3tYtPHUmuLWBffW9L86wqyZBj84Fv/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1923,
  },
  {
    chainId: 101,
    name: "Arcryptian WL Token",
    symbol: "Arcryptian",
    address: "AWLDWua47fVu5f8uxiCCLJPXBJAdsyRM11fESt8jRceo",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWLDWua47fVu5f8uxiCCLJPXBJAdsyRM11fESt8jRceo/logo.png",
    tags: [],
    verified: true,
    holders: 369,
  },
  {
    chainId: 101,
    name: "Shuttlecoin",
    symbol: "SHUT",
    address: "9e6nnqbsTjWx3ss6a3x7Q9ZvpupLNYLb8cTbVmm6UD2K",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Mikkel76543/crypto9/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 128,
  },
  {
    chainId: 101,
    name: "KinnyCoin",
    symbol: "KINY",
    address: "DKrjaRTyhRozuKS1euhsmdLNc2K1fTYrrRXBjFdeec1o",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/kinnycoin/kinnycoin/main/logo.jpg",
    tags: ["media", "social-token", "payments", "solana-ecosystem", "web3"],
    verified: true,
    holders: 3969,
  },
  {
    chainId: 101,
    name: "Ras",
    symbol: "Ras",
    address: "5TPYH5sMvFMitzsjzM9xrxq5igpVuGugVu8CLzCrFdFW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/proninkirill/Rastar/main/rastar.png",
    tags: [],
    verified: true,
    holders: 34278,
  },
  {
    chainId: 101,
    name: "Maars",
    symbol: "MRX",
    address: "8yQuj5v4s72UqZi3sYZL5rAD4NPV4ueUwBKzChBDWMVf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/maarstoken/crypto/main/maars-800x800.png",
    tags: [
      "social-token",
      "community-token",
      "nft-token",
      "gaming-token",
      "web3-token",
    ],
    verified: true,
    holders: 165,
  },
  {
    chainId: 101,
    name: "CatchCoin",
    symbol: "CatchCoin",
    address: "CCoin6VDphET1YsAgTGsXwThEUWetGNo4WiTPhGgR6US",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CCoin6VDphET1YsAgTGsXwThEUWetGNo4WiTPhGgR6US/icon.png",
    tags: ["CatchCoin", "game-token", "utility-token"],
    verified: true,
    holders: 1305,
  },
  {
    chainId: 101,
    name: "Amoeba Friends",
    symbol: "AF",
    address: "4eYYeaFqjscucKTUBHfgQe5zf8xF8YXqTA55EfXFYxB6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4eYYeaFqjscucKTUBHfgQe5zf8xF8YXqTA55EfXFYxB6/logo.png",
    tags: [],
    verified: true,
    holders: 480,
  },
  {
    chainId: 101,
    name: "WL Moondusa",
    symbol: "MONYWL",
    address: "MywLpWzJ9AxM1psuDNJmSTiLhYnuGtChUXc4WEfGvAB",
    decimals: 0,
    logoURI: "https://arweave.net/HZlX45BxqQqJ5KHwpMsz9Kg0EsjzjmNOGjWxqUzMyGs",
    tags: ["whitelist-ticket"],
    verified: true,
    holders: 1854,
  },
  {
    chainId: 101,
    name: "HORDE",
    symbol: "HORDE",
    address: "AF3BcDnqaJhdpKQ9jskZjohnTYRwqrv8H6Yyv4zUvA5g",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/ORCNFT/horde/main/logo.png",
    tags: ["social-token", "Orc-Racing-Club"],
    verified: true,
    holders: 356,
  },
  {
    chainId: 101,
    name: "SolanaLite",
    symbol: "SLITE",
    address: "7eJCLyW5KkvzdzkVXs1ukA1WfFjCcocXjVit64tYcown",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolanaLite/SolanaLite-Brand-Kit/main/SolanaLite%20(SLITE)%20Logo%20Solana%20Blockchain.svg",
    tags: ["SolanaLite"],
    verified: true,
    holders: 1112,
  },
  {
    chainId: 101,
    name: "Monsterz Token",
    symbol: "MNSTR",
    address: "2sf6MtQgAwthVrXZmTUKH1myuP8z1N7vXT87t34bw3Gj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2sf6MtQgAwthVrXZmTUKH1myuP8z1N7vXT87t34bw3Gj/logo.png",
    tags: [
      "NFT",
      "art-token",
      "artists-token",
      "community-token",
      "utility-token",
    ],
    verified: true,
    holders: 1991,
  },
  {
    chainId: 101,
    name: "EDO",
    symbol: "EDO",
    address: "7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 855,
  },
  {
    chainId: 101,
    name: "Maho Genies",
    symbol: "MGM",
    address: "MGM57zGF6ghF9Aax7FfoPHfxuoLKuEGM6twJALyCFqx",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MGM57zGF6ghF9Aax7FfoPHfxuoLKuEGM6twJALyCFqx/logo.png",
    tags: ["Utility-token"],
    verified: true,
    holders: 1305,
  },
  {
    chainId: 101,
    name: "9LIVES",
    symbol: "9LIVES",
    address: "KAT2oYwjN2uVj9gubM9VutCFMoX1Wq9eLiwJJEpBEX3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KAT2oYwjN2uVj9gubM9VutCFMoX1Wq9eLiwJJEpBEX3/9lives.png",
    tags: ["utility-token", "community"],
    verified: true,
    holders: 1478,
  },
  {
    chainId: 101,
    name: "Laohu Chi",
    symbol: "CHI",
    address: "4goT5SLzfoeFx3YwbPF3g6j8whtqnwDJ7BdKC6YVDeKc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/logo.png",
    tags: ["community-token", "social-token"],
    verified: true,
    holders: 1400,
  },
  {
    chainId: 101,
    name: "BUGS",
    symbol: "BUGS",
    address: "H1QvHLhmk4rL36FBphnFUaQszf6RHGU8RLptPSuPcQwX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H1QvHLhmk4rL36FBphnFUaQszf6RHGU8RLptPSuPcQwX/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1036,
  },
  {
    chainId: 101,
    name: "DoughDAO Token",
    symbol: "SD",
    address: "GH9urVNhVzEvQFAz4NGWrjc3raGMKSmU96GV7s3QwSfq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GH9urVNhVzEvQFAz4NGWrjc3raGMKSmU96GV7s3QwSfq/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 273,
  },
  {
    chainId: 101,
    name: "P-Token",
    symbol: "P",
    address: "35HAnnLpne8Kppd7JtpDJ9dWwgrW8f8yUmHwymjGZth4",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/FloorPricePusher/P-Token/main/P-%20Coin%20Symbol.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 146,
  },
  {
    chainId: 101,
    name: "KILO",
    symbol: "KILO",
    address: "FZJYgGhQGJggRuSBjkhYx44o2CLuAR1WbihBq6TdCMj9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/lpbcmain/kilo/main/kilotoken.png",
    tags: ["social-token"],
    verified: true,
    holders: 140,
  },
  {
    chainId: 101,
    name: "Cyber Tribe Whitelist Token",
    symbol: "CTWL",
    address: "FGseEvVtXnbx5M9M2w4s67wKArVL4CUM48xMaoGxtaGi",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGseEvVtXnbx5M9M2w4s67wKArVL4CUM48xMaoGxtaGi/logo.png",
    tags: ["community"],
    verified: true,
    holders: 320,
  },
  {
    chainId: 101,
    name: "FUDAI Token",
    symbol: "FUDAI",
    address: "D6xt2imesfZ1zL57o72BBzjaDs91Q72kgoSGC6qpXY9T",
    decimals: 6,
    logoURI: "https://fortunenekos.io/images/fudai.png",
    tags: [],
    verified: true,
    holders: 225,
  },
  {
    chainId: 101,
    name: "gulp",
    symbol: "$",
    address: "8gsccGREpFttkH813odWMfHGtDRu8MjjyKvTN782WFaP",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/farhan067/Gulp/main/gulpLogo.png",
    tags: [],
    verified: true,
    holders: 376,
  },
  {
    chainId: 101,
    name: "Ryo",
    symbol: "RYO",
    address: "7pDCeQ7PmB98GKuNeobrhF7mnVteLEWTxWjikZxVg7h6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pDCeQ7PmB98GKuNeobrhF7mnVteLEWTxWjikZxVg7h6/logo.png",
    tags: ["utlity-token"],
    verified: true,
    holders: 108,
  },
  {
    chainId: 101,
    name: "FUJI",
    symbol: "FUJI",
    address: "fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/MarkSackerberg/token-list/main/assets/mainnet/fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1596,
  },
  {
    chainId: 101,
    name: "Cubist Coin",
    symbol: "CUBIST",
    address: "CBSTwQzAXeGiNvM5aDdkMD6wkSyuVXqsu8u3ZQmQLFa1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBSTwQzAXeGiNvM5aDdkMD6wkSyuVXqsu8u3ZQmQLFa1/CC.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 4449,
  },
  {
    chainId: 101,
    name: "Solana V2",
    symbol: "SOLV2",
    address: "Aaiw5kZjZcKXRLezY8PonRMfTZ84jgovh51WYSVrSHXG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aaiw5kZjZcKXRLezY8PonRMfTZ84jgovh51WYSVrSHXG/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 207,
  },
  {
    chainId: 101,
    name: "The Islanders",
    symbol: "ISL",
    address: "75XracgnqjPeuexHKWQU3bBcXMZG6XLDF867tKB1T9e6",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/koudydev/crypto/main/islIcon.png",
    tags: ["game-token", "gaming"],
    verified: true,
    holders: 138,
  },
  {
    chainId: 101,
    name: "tsUSDC",
    symbol: "tsUSDC",
    address: "Cvvh8nsKZet59nsDDo3orMa3rZnPWQhpgrMCVcRDRgip",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/152620671-249ada62-ced7-4959-bc92-f16837ca83b1.png",
    tags: ["solfarm", "tulip", "lending", "strategy"],
    verified: true,
    holders: 2145,
  },
  {
    chainId: 101,
    name: "SLNT",
    symbol: "SLNT",
    address: "78ZnfsncDVyhE2HVPe5LscUrgKsJpwP3wJDHRF2TuC1v",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78ZnfsncDVyhE2HVPe5LscUrgKsJpwP3wJDHRF2TuC1v/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 418,
  },
  {
    chainId: 101,
    name: "Gamify Club",
    symbol: "GMFC",
    address: "42Y3CgJQLnHjdScYMu8VS4TbeZMUNVdBMKYbf7hz7aum",
    decimals: 6,
    logoURI:
      "https://pbs.twimg.com/profile_images/1444964749897175046/wdz26CzM_400x400.jpg",
    tags: ["gamefi", "utility-token"],
    verified: true,
    holders: 2314,
  },
  {
    chainId: 101,
    name: "Simians Potion",
    symbol: "$POT",
    address: "BkbqZY7Bhg4TV1fjL9zBLs1xLUpjXj16mmPT6MAMfpQH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BkbqZY7Bhg4TV1fjL9zBLs1xLUpjXj16mmPT6MAMfpQH/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2271,
  },
  {
    chainId: 101,
    name: "Solderland",
    symbol: "SLDR",
    address: "9ZLBKPCzkvDv85hojKofsogsESkJMN164QCVUtxvBxEQ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ZLBKPCzkvDv85hojKofsogsESkJMN164QCVUtxvBxEQ/logo.jpg",
    tags: [],
    verified: true,
    holders: 5258,
  },
  {
    chainId: 101,
    name: "SolRockers",
    symbol: "IROC",
    address: "iRoCxFdj5ZpaGZv5zfbme6CkMtRYc6rLTWbxzuxdMGQ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iRoCxFdj5ZpaGZv5zfbme6CkMtRYc6rLTWbxzuxdMGQ/logo.png",
    tags: ["utility-token", "game-token", "game-currency", "GameFi", "Gaming"],
    verified: true,
    holders: 188,
  },
  {
    chainId: 101,
    name: "GnomToken",
    symbol: "GNOM",
    address: "Et3k45YA6ZMur4GcjuYJiiZSdnr78Jt2AhMbWs4cs3B",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/onuraladi/CoinGnom/main/gnomcoin.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 120,
  },
  {
    chainId: 101,
    name: "Golden Banana",
    symbol: "GNANA",
    address: "E6HdfEAvaY8Fz9avWHvP9iJLWhVrdvmRjvXNc2v3vzb7",
    decimals: 0,
    logoURI:
      "https://github.com/2beyondWRLD/token-list/blob/main/assets/mainnet/E6HdfEAvaY8Fz9avWHvP9iJLWhVrdvmRjvXNc2v3vzb7/logo.png?raw=true",
    tags: ["social-token"],
    verified: true,
    holders: 187,
  },
  {
    chainId: 101,
    name: "USDCav (Portal from Avalanche)",
    symbol: "USDCav",
    address: "AGqKX7F4mqJ8x2mUQVangJb5pWQJApaKoUfe5gXM53CV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGqKX7F4mqJ8x2mUQVangJb5pWQJApaKoUfe5gXM53CV/logo.png",
    tags: ["wrapped", "wormhole"],
    verified: true,
    holders: 392,
  },
  {
    chainId: 101,
    name: "VANGUARD",
    symbol: "GUARD",
    address: "CBV12y1pehFbhdnDpUfgPe88SbUZ5G2s1kLA449Yu3Ad",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBV12y1pehFbhdnDpUfgPe88SbUZ5G2s1kLA449Yu3Ad/logo.png",
    tags: ["VANGUARD"],
    verified: true,
    holders: 2064,
  },
  {
    chainId: 101,
    name: "Draygon Investment",
    symbol: "DRAY",
    address: "EK1rBdnucX4yf8JDCFQEC7rTejXEUqsjazDxHZaHSKT7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/benpazzo/dray/main/assets/draylogo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1117,
  },
  {
    chainId: 101,
    name: "Bohemian Mint Pass",
    symbol: "BMP",
    address: "BwyQD7Kvn5ciu7D6B1S382vhfXmsneVZnuiH7LfjtFA6",
    decimals: 0,
    logoURI:
      "https://chiiepdxwskdrdtt3mqrnde3xh7hib4go22qacb5myxdj34xwwqq.arweave.net/EdCCPHe0lDiOc9shFoybuf50B4Z2tQAIPWYuNO-XtaE",
    tags: ["mint-token"],
    verified: true,
    holders: 6558,
  },
  {
    chainId: 101,
    name: "Refined Land Metaverse HOMES",
    symbol: "RHLM",
    address: "36pVTMos1K66AHSk8N3R27dPCzvMMziUpU8bHBHn2Qky",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36pVTMos1K66AHSk8N3R27dPCzvMMziUpU8bHBHn2Qky/logo.jpg",
    tags: [],
    verified: true,
    holders: 609,
  },
  {
    chainId: 101,
    name: "Konoha",
    symbol: "Konoha",
    address: "7xGviwPk9Fj2YFLLoZdXZzj64TwQe9ysVS7JawHKaePD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xGviwPk9Fj2YFLLoZdXZzj64TwQe9ysVS7JawHKaePD/konoha.png",
    tags: ["utility-token"],
    verified: true,
    holders: 100,
  },
  {
    chainId: 101,
    name: "Zio",
    symbol: "Zio",
    address: "E1XbcaMheKrvRbaM8rMVnJkHJ9EYpHZCX6xctsDQ73Pp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1XbcaMheKrvRbaM8rMVnJkHJ9EYpHZCX6xctsDQ73Pp/zio.png",
    tags: ["utility-token"],
    verified: true,
    holders: 248,
  },
  {
    chainId: 101,
    name: "Kotaru",
    symbol: "KTR",
    address: "Gq5xdBxA39rRN5GsnJtodAnkqvFDQ4YxkEG3N7rS8XHw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gq5xdBxA39rRN5GsnJtodAnkqvFDQ4YxkEG3N7rS8XHw/logo.png",
    tags: ["NFT", "GAMEFI", "Staking"],
    verified: true,
    holders: 569,
  },
  {
    chainId: 101,
    name: "CACTI",
    symbol: "CACTI",
    address: "8G1SG7q8VyqCrjH7VjG9fouDDmHYJaYBMzBomdcUZ1qX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8G1SG7q8VyqCrjH7VjG9fouDDmHYJaYBMzBomdcUZ1qX/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 558,
  },
  {
    chainId: 101,
    name: "Cursed Coin",
    symbol: "CURSE",
    address: "3hWBSqyHrJMDkSuAQtBYHwgKmMbJ666we5xegfDmMzGd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hWBSqyHrJMDkSuAQtBYHwgKmMbJ666we5xegfDmMzGd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1275,
  },
  {
    chainId: 101,
    name: "FUM",
    symbol: "FUM",
    address: "Ed5nxXh2TT1Ry7MuX4Hi5YuCAnjmXEcpJ9LhdmDbCFQS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ed5nxXh2TT1Ry7MuX4Hi5YuCAnjmXEcpJ9LhdmDbCFQS/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1006,
  },
  {
    chainId: 101,
    name: "TABBY",
    symbol: "TABBY",
    address: "tABbYiZsg2msMsPx9wZeJVJpBdCBdGBKDMTuy3XnH2V",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tABbYiZsg2msMsPx9wZeJVJpBdCBdGBKDMTuy3XnH2V/logo.png",
    tags: [],
    verified: true,
    holders: 1549,
  },
  {
    chainId: 101,
    name: "Sovana - Guard",
    symbol: "SOVG",
    address: "5qjK1py1gFa1XSEpBjgXZtUE2JKYxNLNm3MvQqMAuCN3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5qjK1py1gFa1XSEpBjgXZtUE2JKYxNLNm3MvQqMAuCN3/logo.png",
    tags: [],
    verified: true,
    holders: 1109,
  },
  {
    chainId: 101,
    name: "Sovana - Oracle",
    symbol: "SOVO",
    address: "Gqu3e6x4iN5rGJwAEeCwQFsuuYP5dpvmB9ise2sufrcs",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gqu3e6x4iN5rGJwAEeCwQFsuuYP5dpvmB9ise2sufrcs/logo.png",
    tags: [],
    verified: true,
    holders: 1183,
  },
  {
    chainId: 101,
    name: "Sovana - Degen",
    symbol: "SOVD",
    address: "D63gfFT3YE7GXUShznPXEVPJwoKhdWw2psHmrxjBTt1M",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D63gfFT3YE7GXUShznPXEVPJwoKhdWw2psHmrxjBTt1M/logo.png",
    tags: [],
    verified: true,
    holders: 1162,
  },
  {
    chainId: 101,
    name: "Moscovium",
    symbol: "MOSC",
    address: "MoscKLpTs9EV4ADTCGYATt1cHLdmybZ7215gKTdWAq7",
    decimals: 9,
    logoURI: "https://arweave.net/i1bb-mYGWpJyDivUpnyExbYfvefgH-NcsLhsCMGeAjc",
    tags: ["utility-token"],
    verified: true,
    holders: 254,
  },
  {
    chainId: 101,
    name: "Solend SLND",
    symbol: "cSLND",
    address: "D3Cu5urZJhkKyNZQQq2ne6xSfzbXLU4RrywVErMA2vf8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3Cu5urZJhkKyNZQQq2ne6xSfzbXLU4RrywVErMA2vf8/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 4557,
  },
  {
    chainId: 101,
    name: "Solend stSOL",
    symbol: "cstSOL",
    address: "QQ6WK86aUCBvNPkGeYBKikk15sUg6aMUEi5PTL6eB4i",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QQ6WK86aUCBvNPkGeYBKikk15sUg6aMUEi5PTL6eB4i/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 2106,
  },
  {
    chainId: 101,
    name: "Solend scnSOL",
    symbol: "cscnSOL",
    address: "AFq1sSdevxfqWGcmcz7XpPbfjHevcJY7baZf9RkyrzoR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFq1sSdevxfqWGcmcz7XpPbfjHevcJY7baZf9RkyrzoR/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 487,
  },
  {
    chainId: 101,
    name: "Solend mSOL",
    symbol: "cmSOL",
    address: "3JFC4cB56Er45nWVe29Bhnn5GnwQzSmHVf6eUq9ac91h",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JFC4cB56Er45nWVe29Bhnn5GnwQzSmHVf6eUq9ac91h/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 5453,
  },
  {
    chainId: 101,
    name: "Solend ORCA",
    symbol: "cORCA",
    address: "E9LAZYxBVhJr9Cdfi9Tn4GSiJHDWSZDsew5tfgJja6Cu",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9LAZYxBVhJr9Cdfi9Tn4GSiJHDWSZDsew5tfgJja6Cu/logo.png",
    tags: ["solend", "lending", "collateral-tokens"],
    verified: true,
    holders: 341,
  },
  {
    chainId: 101,
    name: "CuseTheJuice",
    symbol: "CUSE",
    address: "AZci9R148CU6hfnnE8ffm2K5mkxagbnTAZNQF5fLbvHb",
    decimals: 9,
    logoURI:
      "https://github.com/CuseTheJuice/token-list/blob/main/assets/mainnet/AZci9R148CU6hfnnE8ffm2K5mkxagbnTAZNQF5fLbvHb/logo.png?raw=true",
    tags: ["social-token"],
    verified: true,
    holders: 106,
  },
  {
    chainId: 101,
    name: "Upbring Token",
    symbol: "UPB",
    address: "B9LtfDZWWRrihYu8jDN57thcqqi7xfWAvj8yq4o2YJxw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SOLreaper88/Upbring-Logo/main/UpbringLogo.PNG",
    tags: ["social-token"],
    verified: true,
    holders: 5227,
  },
  {
    chainId: 101,
    name: "Happy Bunnies Token",
    symbol: "HBWT",
    address: "HBSPEVBvSJbRXmeyf7gYSZbPeLjZQTBeNotjsSrtqHTe",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBSPEVBvSJbRXmeyf7gYSZbPeLjZQTBeNotjsSrtqHTe/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1074,
  },
  {
    chainId: 101,
    name: "vBLSH",
    symbol: "vBLSH",
    address: "FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 4311,
  },
  {
    chainId: 101,
    name: "Locked Staked Sol Invictus",
    symbol: "lsIN",
    address: "LsinpBtQH68hzHqrvWw4PYbH7wMoAobQAzcvxVHwTLv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LsinpBtQH68hzHqrvWw4PYbH7wMoAobQAzcvxVHwTLv/logo.png",
    tags: ["decentralizedreserve", "utility-token", "DeFi", "community-token"],
    verified: true,
    holders: 6901,
  },
  {
    chainId: 101,
    name: "Herd Token",
    symbol: "HERD",
    address: "B4pZJFQm9VDdscJzoR4TTuj8YwexY4kYMfmRCxbZwbcV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/TheChillElephantsClub/assets/main/HERD_COIN_LOGO.png",
    tags: ["social-token", "nft"],
    verified: true,
    holders: 690,
  },
  {
    chainId: 101,
    name: "Degen Competitions Whitelist Token",
    symbol: "DCOMPWL",
    address: "WLvnutUvmM44YrqHN7y7ow5EJWW9GGmeijLubQbyy3J",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLvnutUvmM44YrqHN7y7ow5EJWW9GGmeijLubQbyy3J/icon.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 348,
  },
  {
    chainId: 101,
    name: "prtSOL (Parrot Staked SOL)",
    symbol: "prtSOL",
    address: "BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3/logo.svg",
    tags: [],
    verified: true,
    holders: 8199,
  },
  {
    chainId: 101,
    name: "Moonland WL",
    symbol: "pMOON",
    address: "GZ1BzNsCkV7kd1cYuWHQRHmVSTLqGm71uJiPWep9nh7r",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZ1BzNsCkV7kd1cYuWHQRHmVSTLqGm71uJiPWep9nh7r/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 1990,
  },
  {
    chainId: 101,
    name: "Solcasino USD",
    symbol: "sUSD",
    address: "21jZ1ESEFYh9SBwjT5gqE3jHv3JnaGhgvypLTR96QXW9",
    decimals: 9,
    logoURI: "https://files.solcasino.io/susd.png",
    tags: ["stablecoin", "web3", "lottery", "casino", "bet"],
    verified: true,
    holders: 86335,
  },
  {
    chainId: 101,
    name: "SPACEGOLD",
    symbol: "SPACEGOLD",
    address: "9mXZ54YnJJRmUN2MaMEtWCfFyoncP4ZhKz7U9DZ4JY2X",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/cosmic-condos/SPACEGOLD/main/logo-icon.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1819,
  },
  {
    chainId: 101,
    name: "SOL Playground Coin",
    symbol: "SOLP",
    address: "45eBLJUCQf1acXdBG8daBfUudy8T7V5gDTBpiE1iezsN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45eBLJUCQf1acXdBG8daBfUudy8T7V5gDTBpiE1iezsN/logo.png",
    tags: ["utility-token", "blockchain-developer", "social-token"],
    verified: true,
    holders: 190,
  },
  {
    chainId: 101,
    name: "Lania",
    symbol: "LNA",
    address: "49PGdswRzWT3yUP13r6oysMv1B1WeQ4w87H4M8zxWrTN",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/lania22/crypto/main/logo.png",
    tags: ["Defi", "NFT"],
    verified: true,
    holders: 1559,
  },
  {
    chainId: 101,
    name: "Momobear Taro Pot",
    symbol: "Momobear",
    address: "6BnVgighj8VKqCS2ymUmcvLUguHrPHjZfDZ9oZqqFp7j",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/wei1769/nft_storage/main/art/momoyaTaroPot.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 108,
  },
  {
    chainId: 101,
    name: "PACES Token",
    symbol: "$PACES",
    address: "CdQseFmnPh2JBiz5747dJ6oYXK9NKnbdFRfiXTcZuaXT",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CdQseFmnPh2JBiz5747dJ6oYXK9NKnbdFRfiXTcZuaXT/logo.png",
    tags: [],
    verified: true,
    holders: 1402,
  },
  {
    chainId: 101,
    name: "GRILLAZ",
    symbol: "GRILLAZ",
    address: "2DH1PxfM8d8bkrQM3WFX1HySohcv1rvMLU9KmycmzLp1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DH1PxfM8d8bkrQM3WFX1HySohcv1rvMLU9KmycmzLp1/logo.png",
    tags: [],
    verified: true,
    holders: 419,
  },
  {
    chainId: 101,
    name: "Backistan BACK",
    symbol: "BACK",
    address: "AQGncY1hA22JATnj8aEuCAaPQsoe9wwmn4QBKzvV96yS",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/backdao/backdao/f64c256ffb811018d88e18c804a60a6127f991d3/SPL-image.png",
    tags: [
      "social-token",
      "community-token",
      "nft-backed-currency",
      "nft-basket",
    ],
    verified: true,
    holders: 214,
  },
  {
    chainId: 101,
    name: "Mercanti NFT Token",
    symbol: "MERC",
    address: "AH1KE4h9nnKfqYRDynDsB3nFwT8ohXvw9rX81uaxrhAg",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AH1KE4h9nnKfqYRDynDsB3nFwT8ohXvw9rX81uaxrhAg/logo.png",
    tags: ["whitelist-token", "mercanti", "sale"],
    verified: true,
    holders: 6810,
  },
  {
    chainId: 101,
    name: "EliteCoin",
    symbol: "EC",
    address: "AhDt1FnEt759Tmxwa61E9FQhFnerPwMDTTt7CJjvog7L",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhDt1FnEt759Tmxwa61E9FQhFnerPwMDTTt7CJjvog7L/logo.png",
    tags: [],
    verified: true,
    holders: 1982,
  },
  {
    chainId: 101,
    name: "Kawaii Zoo",
    symbol: "KZOO",
    address: "14AnHZYk1CvtTCq5jvYMX7Fx7pnWDmgQJvADxP9Q4jYN",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/97711508/152678665-9e923dbd-8190-40a8-a910-634881fe3530.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 228,
  },
  {
    chainId: 101,
    name: "FROGZ",
    symbol: "FROGZ",
    address: "GuJWiZBox3tZZwiV9NCJsRUaULv8tmNcK9UWNV25msND",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GuJWiZBox3tZZwiV9NCJsRUaULv8tmNcK9UWNV25msND/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 406,
  },
  {
    chainId: 101,
    name: "THE VOID GENESIS",
    symbol: "VOID",
    address: "9DSDuJxgQouaYBLComUfGTGyaGLjVKktTYuojUs7o3r3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9DSDuJxgQouaYBLComUfGTGyaGLjVKktTYuojUs7o3r3/genesis.jpg",
    tags: ["game-token", "game-currency", "GameFi", "Gaming", "NFT"],
    verified: true,
    holders: 5541,
  },
  {
    chainId: 101,
    name: "WLPrototype",
    symbol: "WLPRT",
    address: "WLiHGZSFg3E17ZBymfQG3NzhUjgQPKeLzTdYW3PhchQ",
    decimals: 0,
    logoURI:
      "https://bafybeie4ijaerva5fjqa4ojdy356jzf4xhhggybpwxf2ekn5tyg7xiedzu.ipfs.infura-ipfs.io/",
    tags: ["utility-token"],
    verified: true,
    holders: 296,
  },
  {
    chainId: 101,
    name: "Solcrystol",
    symbol: "SOC",
    address: "CJze5X3G3V6nqqrfeALTpb1HbkKvspjiUGR12rVchL3T",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJze5X3G3V6nqqrfeALTpb1HbkKvspjiUGR12rVchL3T/SOC.png",
    tags: ["social-token", "Metaverses", "community-token", "game"],
    verified: true,
    holders: 590,
  },
  {
    chainId: 101,
    name: "Peppy Platypus Posse Whitelist Token",
    symbol: "PPPWL",
    address: "CwPp4q7C23oXNh12Z8ZGx1vTL7gnqT4iWtLjg7Ta2vBF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwPp4q7C23oXNh12Z8ZGx1vTL7gnqT4iWtLjg7Ta2vBF/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 127,
  },
  {
    chainId: 101,
    name: "Aiko ART",
    symbol: "ART",
    address: "63cL4hHbkRStqMvhAudXkPsydRASp3yr7iPQemqmAcD2",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63cL4hHbkRStqMvhAudXkPsydRASp3yr7iPQemqmAcD2/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2688,
  },
  {
    chainId: 101,
    name: "RRAM",
    symbol: "RRAM",
    address: "BS9HQKdgY6DUq6HCgNz8xpDyoERJD4GnwGRHebFasjZS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BS9HQKdgY6DUq6HCgNz8xpDyoERJD4GnwGRHebFasjZS/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 559,
  },
  {
    chainId: 101,
    name: "Narwhal Coin",
    symbol: "NARWHAL",
    address: "8TjgDMv2Esb7YRKu1ESZv5vtgD1WqFKmzhPBgsLqwEGG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TjgDMv2Esb7YRKu1ESZv5vtgD1WqFKmzhPBgsLqwEGG/logo.png",
    tags: ["NFTs", "DeFi"],
    verified: true,
    holders: 7296,
  },
  {
    chainId: 101,
    name: "TOME",
    symbol: "TOME",
    address: "3echLX8Qyz4hB694BFsSNWj6ytdStVQKVKFiFCJ1McpQ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/onuraladi/AlderToken/main/tome.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 2780,
  },
  {
    chainId: 101,
    name: "SXD Whitelist Token",
    symbol: "SXD",
    address: "JApyjFvMard5g1WmgSGC6vF61ZsofSEHHQMYk6hNVUXk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SaintxDmon/WL-sxd/main/logo.jpg",
    tags: ["social-token", "mint-token"],
    verified: true,
    holders: 1254,
  },
  {
    chainId: 101,
    name: "Vivendell Whitelist Token",
    symbol: "VWT",
    address: "wLYsBuL6HH5Pxzh5sxdzHfQHmWTJMRMyP8WCUvnoKte",
    decimals: 0,
    logoURI: "https://www.vivendell.com/images/icon-whitelist.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1468,
  },
  {
    chainId: 101,
    name: "SER",
    symbol: "SER",
    address: "StiMWryqUXqFfQUBX51bbxhMJqS9RqJqsAvrsuh2aYi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StiMWryqUXqFfQUBX51bbxhMJqS9RqJqsAvrsuh2aYi/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 262,
  },
  {
    chainId: 101,
    name: "Dragon Souls Whitelist",
    symbol: "DSOUL-WL",
    address: "5RnsebX6ZamLv8YAXDNhgv513SNFbUto9Yz1a9LC4tBZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Soulsdragon/icon/main/token-wl.png",
    tags: [],
    verified: true,
    holders: 442,
  },
  {
    chainId: 101,
    name: "Solleo",
    symbol: "Leo",
    address: "GmEvUrfkmWzJSqua5UkK3KZMUWjLieDK6iatBd7QzEAN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GmEvUrfkmWzJSqua5UkK3KZMUWjLieDK6iatBd7QzEAN/logo.png",
    tags: [],
    verified: true,
    holders: 1700,
  },
  {
    chainId: 101,
    name: "PEEL",
    symbol: "PEEL",
    address: "GVsdtSe3E2fQoP3TzNT2M1VUchJ7sBmDBuvBZmGDGvmB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVsdtSe3E2fQoP3TzNT2M1VUchJ7sBmDBuvBZmGDGvmB/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1827,
  },
  {
    chainId: 101,
    name: "HONSHU",
    symbol: "HONSHU",
    address: "4vXidyArpT8fyQTmfXfMNHitj5ay1Fjcnbw9fJLL2zBa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4vXidyArpT8fyQTmfXfMNHitj5ay1Fjcnbw9fJLL2zBa/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1896,
  },
  {
    chainId: 101,
    name: "Particles Presale",
    symbol: "PWL",
    address: "35v9KktjoyRWbACbtVcEFjGbmhAN2QFb4hJvB2MnSyTd",
    decimals: 9,
    logoURI: "https://i.imgur.com/xVznBiJ.jpg",
    tags: ["particles-nft", "feb11-9pm-utc"],
    verified: true,
    holders: 1846,
  },
  {
    chainId: 101,
    name: "Beach Bum Billionaire",
    symbol: "BUM",
    address: "G2PQTxEooYBG6TcrJ97tFrBzyYoFiRQwwTwiAkTycpwd",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/bumbeach/crypto/main/logo.png",
    tags: ["Defi-token"],
    verified: true,
    holders: 545,
  },
  {
    chainId: 101,
    name: "Net Gala",
    symbol: "NETG",
    address: "netGLdgjzwsr3zfX1YH59yMGNjX4nK8VtZGJvq7G35Z",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/netGLdgjzwsr3zfX1YH59yMGNjX4nK8VtZGJvq7G35Z/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 186,
  },
  {
    chainId: 101,
    name: "Fortune",
    symbol: "FRTN",
    address: "frtnaScfGPuo56uyPGmij1QTc64SBdjnXC3RXmcVmxw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/cesar128/token-list/3b60aecbb7e2565151a9dc3c2e4dab1a96acb520/assets/mainnet/frtnaScfGPuo56uyPGmij1QTc64SBdjnXC3RXmcVmxw/logo.png",
    tags: [],
    verified: true,
    holders: 491,
  },
  {
    chainId: 101,
    name: "NGN Coin",
    symbol: "NGNC",
    address: "52GzcLDMfBveMRnWXKX7U3Pa5Lf7QLkWWvsJRDjWDBSk",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/52GzcLDMfBveMRnWXKX7U3Pa5Lf7QLkWWvsJRDjWDBSk/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 147,
  },
  {
    chainId: 101,
    name: "ARTEM Coin",
    symbol: "ARTEM",
    address: "ARApgmD9DyUo72TcfDpRomb13YZkqZKoLroRdeez8UeQ",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ARApgmD9DyUo72TcfDpRomb13YZkqZKoLroRdeez8UeQ/logo.png",
    tags: ["ARTEM", "ART", "NFT"],
    verified: true,
    holders: 3070,
  },
  {
    chainId: 101,
    name: "Prison Worlds Whitelist",
    symbol: "WPWD",
    address: "D4niwCVzNKtt7dGw5X6TAJrLNBLvv8fZveh3bnNK7dcu",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/prisonworlds/brands/main/whitelist.png",
    tags: ["whitelist-token", "NFT"],
    verified: true,
    holders: 111,
  },
  {
    chainId: 101,
    name: "NOIA",
    symbol: "NOIA",
    address: "BnV3XcZUbNsuonNKqkQrZSvCN8tVYTJtDgfUx6DJ9riy",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BnV3XcZUbNsuonNKqkQrZSvCN8tVYTJtDgfUx6DJ9riy/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 679,
  },
  {
    chainId: 101,
    name: "BIJU",
    symbol: "BIJU",
    address: "4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1082,
  },
  {
    chainId: 101,
    name: "BAMB",
    symbol: "BAMB",
    address: "DHbGBhZc1yLLgpPqAzr7KGs47oCMfbg2q6Fmg5NCSM1C",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHbGBhZc1yLLgpPqAzr7KGs47oCMfbg2q6Fmg5NCSM1C/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 552,
  },
  {
    chainId: 101,
    name: "MEK",
    symbol: "MEK",
    address: "DhYTJPmUa5kQZfLgHb1soubgaK4VLZMxb8CTNY1vZ93S",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhYTJPmUa5kQZfLgHb1soubgaK4VLZMxb8CTNY1vZ93S/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 664,
  },
  {
    chainId: 101,
    name: "Hollow Eyes WL",
    symbol: "HEYESWL",
    address: "2zRiTfY2mmkUcDtj7Rp8pZuB15D5fupxoTaNEDewomTj",
    decimals: 0,
    logoURI:
      "https://cdn.jsdelivr.net/gh/viniciuskloppel/hollow-eyes-token-image/hollow-eyes.jpg",
    tags: ["Whitelist", "NFT"],
    verified: true,
    holders: 417,
  },
  {
    chainId: 101,
    name: "GORE COIN",
    symbol: "GORE",
    address: "6wJYjYRtEMVsGXKzTuhLmWt6hfHX8qCa6VXn4E4nGoyj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/gore-coin.png",
    tags: ["NFT"],
    verified: true,
    holders: 1077,
  },
  {
    chainId: 101,
    name: "FIN COIN",
    symbol: "FIN",
    address: "DnYLfTsnLMYVdDhnZuzekdGf8AMQ3crDR2qRfpHRe47i",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/malibu-coin.png",
    tags: ["NFT"],
    verified: true,
    holders: 264,
  },
  {
    chainId: 101,
    name: "DAB COIN",
    symbol: "DAB",
    address: "32CHtMAuGaCAZx8Rgp54jSFG3ihbpN5brSvRAWpwEHPv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/basc-coin.png",
    tags: ["NFT"],
    verified: true,
    holders: 3324,
  },
  {
    chainId: 101,
    name: "HODL Whales White List Token",
    symbol: "HODLWhales",
    address: "ToTxDbCvSKrTozuTLyTpiUMPMuyNtSohiXxcxbGv5wL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solanahodlwhales/whitelist/main/whitelistToken.png",
    tags: ["NFT"],
    verified: true,
    holders: 1791,
  },
  {
    chainId: 101,
    name: "Neon Rocket Coin",
    symbol: "NRC",
    address: "Gh1jKzmxf95cT5PQabNbfJskkQU8kQ5UugfpbHSnPq9z",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/neontechanoah/neon_rocket_coin/main/asset/NRC_Logo_100px.png",
    tags: ["social-token"],
    verified: true,
    holders: 422,
  },
  {
    chainId: 101,
    name: "VIDI",
    symbol: "VIDI",
    address: "ELADrKrvyv7mtQ5DbCvPDGogn4fcApH3jDeTy2qpfhsA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELADrKrvyv7mtQ5DbCvPDGogn4fcApH3jDeTy2qpfhsA/logo.png",
    tags: [],
    verified: true,
    holders: 2709,
  },
  {
    chainId: 101,
    name: "JJC",
    symbol: "JJJJC",
    address: "6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8/logo.png",
    tags: ["social-token", "nft-token", "game-token", "jjcverse"],
    verified: true,
    holders: 459,
  },
  {
    chainId: 101,
    name: "ROBO Coin",
    symbol: "$ROBO",
    address: "8ZepSXp47WFyDK21QbvMiiKVWRHnGrAegiwDr71PfGi3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/CMHayden/crypto/master/robocoin.png",
    tags: ["social-token"],
    verified: true,
    holders: 586,
  },
  {
    chainId: 101,
    name: "SNIFF",
    symbol: "SNIFF",
    address: "GmBwNscpLsjctXS4yEt43b1sBzeboUJythhAXw23deg1",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/1735634/153721371-69bd5db6-1b6e-433d-a682-5cae6a00e517.png",
    tags: ["social-token", "nfts"],
    verified: true,
    holders: 162,
  },
  {
    chainId: 101,
    name: "Social Sauna Club",
    symbol: "SOSA",
    address: "DYXYVkCk4Xk7RpWdsnqZEkt2S1UBYHsSbj5ZWTWAHvHM",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYXYVkCk4Xk7RpWdsnqZEkt2S1UBYHsSbj5ZWTWAHvHM/logo.png",
    tags: ["NFT"],
    verified: true,
    holders: 5902,
  },
  {
    chainId: 101,
    name: "ROL",
    symbol: "ROL",
    address: "RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU",
    decimals: 6,
    logoURI: "https://assets.blockstars.gg/static/ROL.svg",
    tags: ["utility-token", "game-token"],
    verified: true,
    holders: 2500,
  },
  {
    chainId: 101,
    name: "Sealz",
    symbol: "ARF",
    address: "72NBPHtsUkLvk4KpPcDCiDnaHPoP8XvBGZsjcsSSkyi9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72NBPHtsUkLvk4KpPcDCiDnaHPoP8XvBGZsjcsSSkyi9/logo.png",
    tags: [],
    verified: true,
    holders: 113,
  },
  {
    chainId: 101,
    name: "SolTee Balls",
    symbol: "STB",
    address: "25CNQuwBrHNkCHUMbLUjfUcfGBrepGB6gw53LNsTGGZ9",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25CNQuwBrHNkCHUMbLUjfUcfGBrepGB6gw53LNsTGGZ9/logo.png",
    tags: [],
    verified: true,
    holders: 2264,
  },
  {
    chainId: 101,
    name: "Mikuko Token",
    symbol: "Miku",
    address: "7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2058,
  },
  {
    chainId: 101,
    name: "Tiny Bonez",
    symbol: "T1NY",
    address: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/coin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1484,
  },
  {
    chainId: 101,
    name: "Dapper Ducks Eggs",
    symbol: "EGGZ",
    address: "9XczSh86n8XKkDuL4yK93afRHgSifui9j9sFPHQLfFUT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XczSh86n8XKkDuL4yK93afRHgSifui9j9sFPHQLfFUT/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 5181,
  },
  {
    chainId: 101,
    name: "DPD",
    symbol: "DPD",
    address: "GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 437,
  },
  {
    chainId: 101,
    name: "BONE",
    symbol: "BONE",
    address: "D3eyBjfgJMPHZyYDRtbf1cSxeLiNwKumwHzQK3h3TRRq",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/95661911/153796714-8061210f-ebef-4db9-a646-ab8dc6b57e0f.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1671,
  },
  {
    chainId: 101,
    name: "Bolt Token",
    symbol: "BOLT",
    address: "4xDPH7DVtDXA2eU6wp9BjhryfXEdxBuhe4hnEc9yz1pJ",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xDPH7DVtDXA2eU6wp9BjhryfXEdxBuhe4hnEc9yz1pJ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2191,
  },
  {
    chainId: 101,
    name: "HONEYZ",
    symbol: "HNZ",
    address: "LbrZyD19NVCKQo2C7M6Nq2pfck1spMwE5FUxThcwDWt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LbrZyD19NVCKQo2C7M6Nq2pfck1spMwE5FUxThcwDWt/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1002,
  },
  {
    chainId: 101,
    name: "RIBBET",
    symbol: "RIBBET",
    address: "32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3/logo.png",
    tags: ["social-token", "meme-token", "community-token"],
    verified: true,
    holders: 737,
  },
  {
    chainId: 101,
    name: "RICE",
    symbol: "RICE",
    address: "5yw793FZPCaPcuUN4F61VJh2ehsFX87zvHbCA4oRebfn",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/kakasonz112/robocock-token/main/assets/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1960,
  },
  {
    chainId: 101,
    name: "Magic Carrot",
    symbol: "MC",
    address: "7gsvY4yjAVrP7fwBBMjyVCJNBRMRHgotjFfqrNPDKJec",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gsvY4yjAVrP7fwBBMjyVCJNBRMRHgotjFfqrNPDKJec/logo.jpg",
    tags: [],
    verified: true,
    holders: 1476,
  },
  {
    chainId: 101,
    name: "MMAC Whitelist token",
    symbol: "MMACWL",
    address: "FNqmjdUXyXGjAnKj9Kwi61qp6nVBXCFAm46cU4pP14aN",
    decimals: 0,
    logoURI: "https://arweave.net/aU0UWqYpoQx9o3tWxqAdNdvZUa1_THlUDSUwDQuACyA",
    tags: ["whitelist-token"],
    verified: true,
    holders: 270,
  },
  {
    chainId: 101,
    name: "Otaku Origins WL Token",
    symbol: "OOWL",
    address: "3cUGVa1wVg9XiCSi4dhwjLR8A5T8FYnEyhNPBPkMbYDq",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/matlirman/token-list/main/assets/mainnet/3CXVYitUaSMxifZKyiBRDT1TMcGrL1nJ2WfwdgnnvrKS/logo.png",
    tags: ["mint-token"],
    verified: true,
    holders: 1761,
  },
  {
    chainId: 101,
    name: "Arx Coin",
    symbol: "ARX",
    address: "HhoEAbSPui6EncKntxdToz8cPPLtacgshfKXUjzRYBUr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HhoEAbSPui6EncKntxdToz8cPPLtacgshfKXUjzRYBUr/logo.png",
    tags: ["Arxolotl"],
    verified: true,
    holders: 692,
  },
  {
    chainId: 101,
    name: "Yokoito Crew Hone",
    symbol: "HONE",
    address: "hone3CJTYjczb5nJh45KCNMkjrKMt7SCnHkWGWsVfVu",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hone3CJTYjczb5nJh45KCNMkjrKMt7SCnHkWGWsVfVu/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 596,
  },
  {
    chainId: 101,
    name: "Kingdom Crown",
    symbol: "KCRWN",
    address: "56bFarytGNPXnA34WbghfHoHQJ7NqKSKyTSnfmACdYtk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56bFarytGNPXnA34WbghfHoHQJ7NqKSKyTSnfmACdYtk/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 344,
  },
  {
    chainId: 101,
    name: "KaijuVerse",
    symbol: "KAIJU",
    address: "kai6Dt6612rNn9MaW1eXHAWngwfoqYKFEMyniuWmoWv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kai6Dt6612rNn9MaW1eXHAWngwfoqYKFEMyniuWmoWv/logo.png",
    tags: ["utility-token", "community-token", "NFT"],
    verified: true,
    holders: 707,
  },
  {
    chainId: 101,
    name: "Juvot Mint Token",
    symbol: "JOWL",
    address: "8qEvmjgVub3fEaxaEr6YkyPnWv5ZT2XQkFQTXaYSvJzN",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/juvenileotters/juvotlogo/main/juvot.png",
    tags: ["stake-pool-token"],
    verified: true,
    holders: 1093,
  },
  {
    chainId: 101,
    name: "SLACC Mint Tokens",
    symbol: "SLACCMINT",
    address: "5vfLU3vxRQj6v474imwFvoeWxqo4gKmXmRpQ5N1Qgpcy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/SlaccMint/uploads/main/logo.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 4061,
  },
  {
    chainId: 101,
    name: "ANGRY",
    symbol: "ANGRY",
    address: "BgjhevUKQDZ28usZMEairXPctoFA3nqKM8A1j5YhHv7w",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/matthewrx/token/main/angry_token_logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 607,
  },
  {
    chainId: 101,
    name: "Alleycats Collective Token",
    symbol: "PURR",
    address: "h4XNYWyefydaNiEZmECeutJtxQbyopZUSeWPFDJZtaL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/h4XNYWyefydaNiEZmECeutJtxQbyopZUSeWPFDJZtaL/purr_coin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 526,
  },
  {
    chainId: 101,
    name: "Solana Faction Cards Battery Pack",
    symbol: "SFC-BATTERY",
    address: "6GK4nhV3h2RDKmjY4u43N3HJWNs6nYSB1qSBnsj6a2px",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/faction-cards/assets/main/images/coins/SFC-BATTERY/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 191,
  },
  {
    chainId: 101,
    name: "Solana Faction Cards Pre-Sale Token",
    symbol: "SFC-PRESALE",
    address: "AoYS2EzUPNKWSm4BH4bvzL16hA76XSmCkxgftxojVgLA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/faction-cards/assets/main/images/coins/SFC-PRESALE/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 378,
  },
  {
    chainId: 101,
    name: "Palm Token",
    symbol: "PALM",
    address: "H6Jz41jUjNV7kNDBQuvJqNnpEYhGF6wJ5DR8bMLZDqvR",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Alsabbagh16/PALM/main/2%20colors%20512px.png",
    tags: [],
    verified: true,
    holders: 432,
  },
  {
    chainId: 101,
    name: "The Swamp Token",
    symbol: "SWAMP",
    address: "DbV44ix6fnBsR7Uf7uinmHqkMcEG81PjxP2ofCbBrUPa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/papacroc/swamptokenofficial/main/Logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 241,
  },
  {
    chainId: 101,
    name: "Otaku Origins WL 2",
    symbol: "OtakuOriginsWL",
    address: "HVtfDhSpbuUQwXxEL6RmVEfEeWbpmfUt6Tbh8iRrosZP",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/matlirman/token-list/main/assets/mainnet/HVtfDhSpbuUQwXxEL6RmVEfEeWbpmfUt6Tbh8iRrosZP/logo.png",
    tags: ["mint-token"],
    verified: true,
    holders: 2067,
  },
  {
    chainId: 101,
    name: "Shizen Orochi - ORO",
    symbol: "ORO",
    address: "3GfdtDnQC6mjkdr9cEaSr9mjS2VnSYoVypQiT2PPRBch",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GfdtDnQC6mjkdr9cEaSr9mjS2VnSYoVypQiT2PPRBch/logo.png",
    tags: [],
    verified: true,
    holders: 1894,
  },
  {
    chainId: 101,
    name: "SAINTxDMON - GEMS",
    symbol: "GEMS",
    address: "CS5tMuXYGR1cd2XnVQjoZ42Z1jWoW1Bu8ySdRBT99PDc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CS5tMuXYGR1cd2XnVQjoZ42Z1jWoW1Bu8ySdRBT99PDc/logo.png",
    tags: [],
    verified: true,
    holders: 457,
  },
  {
    chainId: 101,
    name: "SOUL Coin",
    symbol: "DR",
    address: "CfzXjG5VCQqZ7H7hxnoZZpA1MdcGSThcm6aaipU3M46K",
    decimals: 3,
    logoURI: "https://raw.githubusercontent.com/GokhanCerk/asset/main/Daku.png",
    tags: ["community-token"],
    verified: true,
    holders: 362,
  },
  {
    chainId: 101,
    name: "FakeBiz",
    symbol: "FBZ",
    address: "BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7/logo.png",
    tags: ["social-token", "util-token", "web3"],
    verified: true,
    holders: 1999,
  },
  {
    chainId: 101,
    name: "Playground",
    symbol: "PLAYA",
    address: "E6oCGvmSYW7qhy7oeDfiNZLX6hEmPCVxBC8AknwAj82B",
    decimals: 2,
    logoURI: "https://i.imgur.com/ZkjtZNt.jpg",
    tags: ["bridgesplit-fraction", "playground-dao", "curated-index", "fnft"],
    verified: true,
    holders: 908,
  },
  {
    chainId: 101,
    name: "HONEYBEAR",
    symbol: "HONEYBEAR",
    address: "CdcRwbFuj3YNJYdfUqh3hnxFz1fuF6he1Wgz7JvZMHda",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CdcRwbFuj3YNJYdfUqh3hnxFz1fuF6he1Wgz7JvZMHda/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 492,
  },
  {
    chainId: 101,
    name: "RING",
    symbol: "RING",
    address: "7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3271,
  },
  {
    chainId: 101,
    name: "MHAC MILEZ",
    symbol: "MILEZ",
    address: "FDrvNJHhLPMCrRe3oPzkXi3ug3S5dQ84hYAGUTjyjrqQ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDrvNJHhLPMCrRe3oPzkXi3ug3S5dQ84hYAGUTjyjrqQ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3113,
  },
  {
    chainId: 101,
    name: "SolKongz WL 1",
    symbol: "SKWL1",
    address: "hKnVPhEEWBbge4vuNZxFe8dVstpsXVUv6wJDwfTLvhW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hKnVPhEEWBbge4vuNZxFe8dVstpsXVUv6wJDwfTLvhW/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1620,
  },
  {
    chainId: 101,
    name: "SolKongz WL 2",
    symbol: "SKW2L",
    address: "B2GcHcXR4Na3ry38dSKXpybacaW4xYmt4TZvCoXHmqki",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B2GcHcXR4Na3ry38dSKXpybacaW4xYmt4TZvCoXHmqki/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1950,
  },
  {
    chainId: 101,
    name: "Nasi Lemak",
    symbol: "NLTK",
    address: "3uNAevHamuZKKQdtdLzmHNvqD8r14tXUUXx5PN48UbYC",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/avaelon/nltk/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 545,
  },
  {
    chainId: 101,
    name: "SolWolf Game Token",
    symbol: "SHEEP",
    address: "52WyZe1pfobyq6v1t7KAKZWePcq9Aj2Aa5kJHuF2KHDM",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/8sunyuan/token-list/main/assets/mainnet/52WyZe1pfobyq6v1t7KAKZWePcq9Aj2Aa5kJHuF2KHDM/wolflogo.jpg",
    tags: [],
    verified: true,
    holders: 267,
  },
  {
    chainId: 101,
    name: "GorillaeMACHA",
    symbol: "GMACHA",
    address: "Gorizmp5uZz2VC8dg6L58Hhf9L1onCfBH8SoDBHnyJ2C",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gorizmp5uZz2VC8dg6L58Hhf9L1onCfBH8SoDBHnyJ2C/logo.png",
    tags: ["NFT", "mint-token", "whitelist"],
    verified: true,
    holders: 1069,
  },
  {
    chainId: 101,
    name: "mma",
    symbol: "MMA",
    address: "MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2503,
  },
  {
    chainId: 101,
    name: "Moon WL Token",
    symbol: "LNFTxMOON345",
    address: "AUNHT5DSvHqgaQCeB7bs9XQMjyx2gfVv8nzmUf5jX3Et",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AUNHT5DSvHqgaQCeB7bs9XQMjyx2gfVv8nzmUf5jX3Et/logo.png",
    tags: ["liqnft-wl-token"],
    verified: true,
    holders: 1665,
  },
  {
    chainId: 101,
    name: "Big Brain Clique Alpha Whitelist Token",
    symbol: "BBCA",
    address: "WLjDmpFkBe2eUunAit8h4pohYNQ243xYS1bv4wHz9Hi",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLjDmpFkBe2eUunAit8h4pohYNQ243xYS1bv4wHz9Hi/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 180,
  },
  {
    chainId: 101,
    name: "ProLeague FE Whitelist Token",
    symbol: "PL-FE-WL",
    address: "prwjbiQ3EQf8WqTqheZwr4Fa7gHfnFYYgPJJuC3CB7B",
    decimals: 0,
    logoURI:
      "https://proleague.gg/wp-content/uploads/2020/12/cropped-favicon-512px.png",
    tags: [],
    verified: true,
    holders: 440,
  },
  {
    chainId: 101,
    name: "BRBB - Burger Token",
    symbol: "$BURGER",
    address: "FXSLj3g5u9SEm62bErBs8895YgrfJPyn5q36V4KpZQFc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXSLj3g5u9SEm62bErBs8895YgrfJPyn5q36V4KpZQFc/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 293,
  },
  {
    chainId: 101,
    name: "Solien DAO",
    symbol: "SDUST",
    address: "C5EefTmWXHJWFkN3Dh7QyFUnBG3UXSu8h6qVs6xtaLxy",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C5EefTmWXHJWFkN3Dh7QyFUnBG3UXSu8h6qVs6xtaLxy/daologo.png",
    tags: ["nft"],
    verified: true,
    holders: 451,
  },
  {
    chainId: 101,
    name: "Proof-of-Handshake",
    symbol: "PoHS",
    address: "HAndCoG9GWS4TPaBFhbbtVMDevpUkvRti6TweV7d4q1L",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/KKQanT/token-list/main/assets/mainnet/HAndCoG9GWS4TPaBFhbbtVMDevpUkvRti6TweV7d4q1L/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1413,
  },
  {
    chainId: 101,
    name: "Staked Atlas Dex",
    symbol: "xATS",
    address: "reZNfhu7z32WzecdjjswvZQMFrkXyUX4mbMh4NZwPNt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png",
    tags: ["utility-token", "dex", "swap"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "Secret Kongz Cheers",
    symbol: "CHEERS",
    address: "UXRj3sUsJsQ6mkDz8xmXQxnY7DHoyZzX1UtEZriqbmC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXRj3sUsJsQ6mkDz8xmXQxnY7DHoyZzX1UtEZriqbmC/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 158,
  },
  {
    chainId: 101,
    name: "Sol Stone Free Mint Token",
    symbol: "SSFM",
    address: "BW2CtuLxPzvVcV1DEomHfmL8zWiDD4fvk11F2SxqEaUB",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BW2CtuLxPzvVcV1DEomHfmL8zWiDD4fvk11F2SxqEaUB/logo.jpg",
    tags: [],
    verified: true,
    holders: 377,
  },
  {
    chainId: 101,
    name: "CHAN Token",
    symbol: "CHAN",
    address: "EZkW8HcjwkSSdGdjW4R2pkkLrc58W5mBbditxdj7XG2N",
    decimals: 9,
    logoURI: "https://solchan.io/chan-token-logo.png",
    tags: ["utility-token", "social-token", "community-token"],
    verified: true,
    holders: 171,
  },
  {
    chainId: 101,
    name: "Flappyzcoin",
    symbol: "FLPZ",
    address: "E5TT39UcytqDkHy4xq24JjjWhQrWjhT8yf4XeCfz1v2F",
    decimals: 6,
    logoURI:
      "https://solnetwebapistorage.blob.core.windows.net/images/flappyzcoin.png",
    tags: ["utility-token", "community-token", "game-token", "gaming"],
    verified: true,
    holders: 391,
  },
  {
    chainId: 101,
    name: "Stray Ape WL",
    symbol: "STRAYAPE-WL",
    address: "3E4YuX7XdEVCF54KtimWVBhBpeN5P2dehGnCff65q559",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3E4YuX7XdEVCF54KtimWVBhBpeN5P2dehGnCff65q559/Logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1499,
  },
  {
    chainId: 101,
    name: "SNOW Token",
    symbol: "$NOW",
    address: "ayVMWBtmzPM48HAP1f5BSyvVWnqJWs4y2TEDbKNUGMo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ayVMWBtmzPM48HAP1f5BSyvVWnqJWs4y2TEDbKNUGMo/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 605,
  },
  {
    chainId: 101,
    name: "NAC WL Token",
    symbol: "NAC-WL",
    address: "D9hidBDDauvAYWY9jkNt6YfPxtcC7HgWm5sNNHAobC3A",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D9hidBDDauvAYWY9jkNt6YfPxtcC7HgWm5sNNHAobC3A/logo.jpg",
    tags: ["whitelist-token"],
    verified: true,
    holders: 3054,
  },
  {
    chainId: 101,
    name: "Francium Contribution Point",
    symbol: "FRANCIUM-CP",
    address: "Hjibp1cn2bSk1dkTdpbxez3YAiBGTLjzc8xZ8LbCCUHS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hjibp1cn2bSk1dkTdpbxez3YAiBGTLjzc8xZ8LbCCUHS/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 35662,
  },
  {
    chainId: 101,
    name: "CAROT COIN",
    symbol: "$CAROT",
    address: "CARoTGvYPajELZsoLQSovLY8fZmBkrrUoyJVJN3zGwQT",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CARoTGvYPajELZsoLQSovLY8fZmBkrrUoyJVJN3zGwQT/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 285,
  },
  {
    chainId: 101,
    name: "The Roundies Whitelist",
    symbol: "RNDIEWL",
    address: "5io93Fx24MDPMybCUYxjLmfK1WaW33bWRqfvBnNgX6Ug",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5io93Fx24MDPMybCUYxjLmfK1WaW33bWRqfvBnNgX6Ug/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 506,
  },
  {
    chainId: 101,
    name: "OOAA",
    symbol: "OOAA",
    address: "6dqaoHQNsmbTd11JGqDKhyoNQ36rnRTRmWtxaAqA9fBV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6dqaoHQNsmbTd11JGqDKhyoNQ36rnRTRmWtxaAqA9fBV/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 671,
  },
  {
    chainId: 101,
    name: "MICROCOCK",
    symbol: "MICROCOCK",
    address: "3Py5nZKoNuotXBcYTiDrLVQeWJzgryHWyiED4JRckEEs",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/kakasonz112/robocock-token/main/assets/wl_logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 3271,
  },
  {
    chainId: 101,
    name: "MARS WL Token",
    symbol: "MarsWL",
    address: "FYmiFBiUTkmFoY7duWgGE21VHxyUazZN15HJLaHBKEKq",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYmiFBiUTkmFoY7duWgGE21VHxyUazZN15HJLaHBKEKq/logo.png",
    tags: ["currency", "game-token", "social-token", "community-token"],
    verified: true,
    holders: 330,
  },
  {
    chainId: 101,
    name: "Brickers WL Token",
    symbol: "BRCRS",
    address: "ssr1CpV54AngxBAeeTpPNgmAACf3VpdTQJtCdgMRFSC",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ssr1CpV54AngxBAeeTpPNgmAACf3VpdTQJtCdgMRFSC/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1616,
  },
  {
    chainId: 101,
    name: "Code",
    symbol: "CODE",
    address: "Code7hV6DaK5Werof8c7vPwBxLvhmEWVUbU2AfhBZArB",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Code7hV6DaK5Werof8c7vPwBxLvhmEWVUbU2AfhBZArB/logo.png",
    tags: ["defi", "currency", "social-token", "community-token"],
    verified: true,
    holders: 1019,
  },
  {
    chainId: 101,
    name: "BHSG Jetpack Claim Token",
    symbol: "JET",
    address: "JetNnLZpbECCfyeZSvJdgg5gf37QfCkPrp2X8XfmNWn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JetNnLZpbECCfyeZSvJdgg5gf37QfCkPrp2X8XfmNWn/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 828,
  },
  {
    chainId: 101,
    name: "CoC OG token",
    symbol: "OG",
    address: "CoCrfJfccDLXxdd4HSVynLGKavvBPPHjJEGphwXZTFyn",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CoCrfJfccDLXxdd4HSVynLGKavvBPPHjJEGphwXZTFyn/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3165,
  },
  {
    chainId: 101,
    name: "DeltaFi Token",
    symbol: "DELFI",
    address: "de1QJkP1qDCk5JYCCXCeq27bQQUdCaiv7xVKFrhPSzF",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/de1QJkP1qDCk5JYCCXCeq27bQQUdCaiv7xVKFrhPSzF/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3921,
  },
  {
    chainId: 101,
    name: "Whitelist Drogo",
    symbol: "WLD",
    address: "WLDu1fuZ2dswJAizQg5BrN26oAKeCUiJ42x7uaep5WV",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLDu1fuZ2dswJAizQg5BrN26oAKeCUiJ42x7uaep5WV/logo.png",
    tags: [],
    verified: true,
    holders: 1061,
  },
  {
    chainId: 101,
    name: "Dreamcity",
    symbol: "DC",
    address: "BJv2UCtM48qKqmkcTegLg696vXwhBoqfkNjueys4TN8X",
    decimals: 0,
    logoURI: "https://dreamcitynft.com/apple-touch-icon.png",
    tags: [],
    verified: true,
    holders: 214,
  },
  {
    chainId: 101,
    name: "Sundays Lab Golden Token",
    symbol: "SLGT",
    address: "GLapb5CUwQ5pbDy9RLuEctFEjZuoNKsRvc95MGpKimCf",
    decimals: 9,
    logoURI: "https://arweave.net/pHrzP-Q4FhYr-nZRLnNLl0v1fUTbwnsIhxAERvoNf7Y",
    tags: ["utility-token"],
    verified: true,
    holders: 170,
  },
  {
    chainId: 101,
    name: "BLEEP",
    symbol: "BLEEP",
    address: "GmY2Rp9t5S4yD5jhgJrc47VSAa6hQiikkYi3sr9HLNZr",
    decimals: 4,
    logoURI:
      "https://gateway.pinata.cloud/ipfs/QmYjaWwcDx8gaf5rnkzGYLy8qhjjvuuvQXK8RttvXFWUWd?preview=1",
    tags: ["social-token"],
    verified: true,
    holders: 640,
  },
  {
    chainId: 101,
    name: "Dokan Apes WL Token",
    symbol: "DAWL",
    address: "DoKnmLSCe81mkvga7Vxn82F8NtvBdW3Qas4Rk22Wd3UG",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/NFTBrickLayer/assets/main/dokan-logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 329,
  },
  {
    chainId: 101,
    name: "DTH WLTicket",
    symbol: "DTH",
    address: "6Mt8jADDUPckFf3hQfsxrrZGyov7sdfLAiD9oeLVcBkk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Mt8jADDUPckFf3hQfsxrrZGyov7sdfLAiD9oeLVcBkk/logo.png",
    tags: [],
    verified: true,
    holders: 2361,
  },
  {
    chainId: 101,
    name: "The Pit Bosses",
    symbol: "PIT",
    address: "9zwqWdo8hyQS3Zjx87AtviD9ooD38XWKtd5FTaLAK9Js",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/pitbuli.png",
    tags: ["community-token", "social-token"],
    verified: true,
    holders: 271,
  },
  {
    chainId: 101,
    name: "DREAM TOKEN",
    symbol: "DREAM",
    address: "HuMShjViKhcfihmHkgvctcFAyeyxAk8hK5K58zWpuRKf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HuMShjViKhcfihmHkgvctcFAyeyxAk8hK5K58zWpuRKf/logo.png",
    tags: ["Dreamapes"],
    verified: true,
    holders: 1375,
  },
  {
    chainId: 101,
    name: "BHSG Blaster Claim Token",
    symbol: "BLAST",
    address: "BLSTrhpP73RnVocgRu3te1Z5bBy6kL78Cpg2WuACqFkS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLSTrhpP73RnVocgRu3te1Z5bBy6kL78Cpg2WuACqFkS/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1735,
  },
  {
    chainId: 101,
    name: "Zebra Barcelona Design",
    symbol: "ZEBRA",
    address: "9FvJe9t8E771DJuQmJQcqygsUHgYUnXMEbEoYqiNj9ky",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9FvJe9t8E771DJuQmJQcqygsUHgYUnXMEbEoYqiNj9ky/logo.png",
    tags: [],
    verified: true,
    holders: 262,
  },
  {
    chainId: 101,
    name: "GigaHub",
    symbol: "GHC",
    address: "3QxMtHFY6x35Rmuh7SPSiP6EPNpFT1AEPz268KF3JH1V",
    decimals: 9,
    logoURI: "https://arweave.net/uHznKxrFiAG1QC2LgRhGf08ySUHLmRT3Yne4yAndXmg",
    tags: ["utility-token"],
    verified: true,
    holders: 340,
  },
  {
    chainId: 101,
    name: "Doryudragonz",
    symbol: "DDZ",
    address: "Fp1ZyU4NTDvrPfBUtKFPMmSCS3Yp4KvPsTX2RscMCD5g",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp1ZyU4NTDvrPfBUtKFPMmSCS3Yp4KvPsTX2RscMCD5g/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1368,
  },
  {
    chainId: 101,
    name: "BlueDiamonds",
    symbol: "BD",
    address: "9PYyJoGv5WVht6X7i4znDnPjRKinRhFdiA27yFRRxXqB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9PYyJoGv5WVht6X7i4znDnPjRKinRhFdiA27yFRRxXqB/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 108,
  },
  {
    chainId: 101,
    name: "Businessdogs Token",
    symbol: "BD$",
    address: "jWWi8vp5q8hcNdxQpqbJNMJ2aDpz5t8SoB1dkzYS7CL",
    decimals: 6,
    logoURI: "https://i.redd.it/pap3i9ua8g421.png",
    tags: ["utility-token"],
    verified: true,
    holders: 245,
  },
  {
    chainId: 101,
    name: "Sol Visitors Beacon",
    symbol: "BEACON",
    address: "24GFfEuzkowwZTZubpATywEhNYBqvPyAwmgRjBesEM4q",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/gserafini/solvisitors/main/coin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 153,
  },
  {
    chainId: 101,
    name: "MateysDAO 2/22 Whitelist token",
    symbol: "matE",
    address: "WLmzvH6aA31vA8hZ1bptVemSoiBc8fm6J4LRXzHZmCF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLmzvH6aA31vA8hZ1bptVemSoiBc8fm6J4LRXzHZmCF/icon.png",
    tags: ["social-token", "whitelist"],
    verified: true,
    holders: 1487,
  },
  {
    chainId: 101,
    name: "Corrupted Token",
    symbol: "CRPT",
    address: "5AHzHDUiamGAwiVs8pXJuJmigqJWopwtcp2voieL1oWP",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/thefalseshad0w/crpt-token-/main/5AHzHDUiamGAwiVs8pXJuJmigqJWopwtcp2voieL1oWP/crpt.png",
    tags: ["Community-token", "utility-token"],
    verified: true,
    holders: 220,
  },
  {
    chainId: 101,
    name: "NEO-NINJA MINT TICKET",
    symbol: "NNTICKET",
    address: "CkTT6bUw9DWtnrkhJmUPTgWB9TvVy4sHgX3mmFNw1v1C",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkTT6bUw9DWtnrkhJmUPTgWB9TvVy4sHgX3mmFNw1v1C/logo.png",
    tags: ["community-token", "utility-token", "social-token", "NFTs", "DeFi"],
    verified: true,
    holders: 5876,
  },
  {
    chainId: 101,
    name: "May 1 JFI Call",
    symbol: "JFICALL",
    address: "etCPUaMghWobVwxug5Vgxb6r3A7DEBdLKUywrnDrJeZ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/etCPUaMghWobVwxug5Vgxb6r3A7DEBdLKUywrnDrJeZ/logo.png",
    tags: ["option-contract"],
    verified: true,
    holders: 4205,
  },
  {
    chainId: 101,
    name: "Skeleton Crew",
    symbol: "SKULL",
    address: "SKu11EypaFU3gvr8VSAbi13zEC2CPvqbz9s83N3tWHM",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SKu11EypaFU3gvr8VSAbi13zEC2CPvqbz9s83N3tWHM/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 2706,
  },
  {
    chainId: 101,
    name: "SPOT",
    symbol: "$SPOT",
    address: "BzwERW2s5brUYtt3jvobVQn64uxXYRrz81Yx6vYpJorE",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/ViralCC/Spot/main/spot.jpg",
    tags: ["community-token", "dog-token", "dog", "nfts", "meta"],
    verified: true,
    holders: 2671,
  },
  {
    chainId: 101,
    name: "Zezam Whitelist",
    symbol: "ZWL",
    address: "HQAxChVaeiESe9JN3LiRfLh1DM4g5MtGrbQTu3MwjjGa",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HQAxChVaeiESe9JN3LiRfLh1DM4g5MtGrbQTu3MwjjGa/zezam.png",
    tags: ["whitelist"],
    verified: true,
    holders: 154,
  },
  {
    chainId: 101,
    name: "ROAR",
    symbol: "ROAR",
    address: "ES6xz8FR8a5fP31ePeeKGR7HcgtzjQAQdHfGmcR3Uer7",
    decimals: 9,
    logoURI: "https://arweave.net/WPbvoC2Wx2obrPtJtMYLpenZqz31KJClHTSxGpcqesw",
    tags: ["utility-token"],
    verified: true,
    holders: 212,
  },
  {
    chainId: 101,
    name: "MetaHeaven Coin",
    symbol: "MHV",
    address: "FvBZo7dEFasSB9vottYnuLvLy3br5pkbC1NXDdCVa4wQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FvBZo7dEFasSB9vottYnuLvLy3br5pkbC1NXDdCVa4wQ/logo.png",
    tags: [
      "community-token",
      "metaheaven",
      "NFT",
      "metagod",
      "metaheaven-coin",
      "crypto",
    ],
    verified: true,
    holders: 529,
  },
  {
    chainId: 101,
    name: "CRAFT COIN",
    symbol: "CRAFT",
    address: "EeLpRX4pgS4zPTQQfoHqqhBvoLzL83wPExmQoG5e1hnb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/craft-token.png",
    tags: ["NFT"],
    verified: true,
    holders: 164,
  },
  {
    chainId: 101,
    name: "BRAP",
    symbol: "$BRAP",
    address: "6JiPyJdjh8HdA4G3qHVD7oxrUUdaqBSR833DD46nFDyF",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JiPyJdjh8HdA4G3qHVD7oxrUUdaqBSR833DD46nFDyF/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 709,
  },
  {
    chainId: 101,
    name: "QUACK",
    symbol: "QUACK",
    address: "6frkvZf72wiz3uqRWhBqLftNU4PS6XXYCoNrW9P4CFdK",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 650,
  },
  {
    chainId: 101,
    name: "Blobbolution WL Token",
    symbol: "BLOBB",
    address: "BLoBgdr2W7YEgzPioUbThYQwSFsaU7nPtFAgCQByM1gC",
    decimals: 0,
    logoURI:
      "https://cdn.discordapp.com/attachments/754741118566137929/946037034269671444/Twitter_Discord_Profile_Picture_small.png",
    tags: ["nft"],
    verified: true,
    holders: 240,
  },
  {
    chainId: 101,
    name: "Booty Farm",
    symbol: "BOOTY",
    address: "7mT2XbK3iqQrhzDFoy87r61ewkrDWYLHrm94iMiTf8xJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mT2XbK3iqQrhzDFoy87r61ewkrDWYLHrm94iMiTf8xJ/booty-logo.png",
    tags: ["social-token", "utility-token", "NSFW", "yield-farming", "web3"],
    verified: true,
    holders: 454,
  },
  {
    chainId: 101,
    name: "AF Silver Ticket",
    symbol: "AFST",
    address: "SWLmRezJbWb8SJKkHHAbGDfXneCWprXxc8iDNXBhM5q",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SWLmRezJbWb8SJKkHHAbGDfXneCWprXxc8iDNXBhM5q/logo.png",
    tags: [],
    verified: true,
    holders: 205,
  },
  {
    chainId: 101,
    name: "Sexy Kiwi Coin",
    symbol: "EXY",
    address: "SexyzTVcKsB7m9CHDt2FgkJbhQK58pm7M73RynPWhCz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SexyzTVcKsB7m9CHDt2FgkJbhQK58pm7M73RynPWhCz/goldkiwi.png",
    tags: ["utility-token"],
    verified: true,
    holders: 291,
  },
  {
    chainId: 101,
    name: "staked MMA",
    symbol: "sMMA",
    address: "sMMAMLinw5aeQ1WzGp1RuJsufrEKKttMudt2e3SGgKE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sMMAMLinw5aeQ1WzGp1RuJsufrEKKttMudt2e3SGgKE/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1006,
  },
  {
    chainId: 101,
    name: "Bizarre Platypus Token Whitelist",
    symbol: "BPWT",
    address: "5TLBtAsJyGrxWyFhgyYr9R9KEkec9zrMQjfCKegdfax8",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5TLBtAsJyGrxWyFhgyYr9R9KEkec9zrMQjfCKegdfax8/logo.png",
    tags: ["utility-token", "mint-token", "whitelist"],
    verified: true,
    holders: 2587,
  },
  {
    chainId: 101,
    name: "PillSyndromeOG",
    symbol: "$PILLOG",
    address: "GGASkW5PZWvNbHr4F8BVXYPbMWLdfhP96QmVbQpoEbX9",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 123,
  },
  {
    chainId: 101,
    name: "PillSyndromeWL",
    symbol: "$WLPILL",
    address: "WLiu98oqYLrM1rkbew41QSPrqVteVn3h2wS4rNurv9v",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 581,
  },
  {
    chainId: 101,
    name: "Diamond Hands Mint Token",
    symbol: "DHMT",
    address: "84CGueN92MKdNRhpgciRmWZhnD8xv65k6K6zFX5e4Lwq",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/84CGueN92MKdNRhpgciRmWZhnD8xv65k6K6zFX5e4Lwq/logo.png",
    tags: ["mint-token"],
    verified: true,
    holders: 2993,
  },
  {
    chainId: 101,
    name: "Alleycats Collective Tokens",
    symbol: "PURR",
    address: "Cbi6U8C8mC1ENvskYBqyYzmy1Q5wB5bk9YzUcF6ZVomQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/h4XNYWyefydaNiEZmECeutJtxQbyopZUSeWPFDJZtaL/purr_coin.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 1020,
  },
  {
    chainId: 101,
    name: "FISH Token",
    symbol: "FI$H",
    address: "fishXewD9Muc6xenzn3JevquHq3A5WVERMLLUrZMTfh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/fishXewD9Muc6xenzn3JevquHq3A5WVERMLLUrZMTfh/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 314,
  },
  {
    chainId: 101,
    name: "CHUMPS Token",
    symbol: "CHUMP",
    address: "JSmhhX58q5Jxk7xBGh2sLgLsfiEhqCEdYRN7Rb6ov8h",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/loosebucks/token/main/Untitled%204%20(1).png",
    tags: ["social-token"],
    verified: true,
    holders: 127,
  },
  {
    chainId: 101,
    name: "TOXIN",
    symbol: "$TOXIN",
    address: "BEf5BZxd5w2paiX6GnyBDBHUihfNMRUy8Dpek8X4rjBy",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/CMHayden/crypto/master/toxin.png",
    tags: ["social-token"],
    verified: true,
    holders: 1683,
  },
  {
    chainId: 101,
    name: "PitBossesMint",
    symbol: "PITM",
    address: "2tXb5LRDnPo2WxfvrvMgCXP1vtGxn9ErpBAW1w4D7483",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/pitbuli.png",
    tags: ["mint-token"],
    verified: true,
    holders: 173,
  },
  {
    chainId: 101,
    name: "Pew",
    symbol: "PEW",
    address: "AvYRFjk4imoGSAYxcwfm4dM6qQigR6DZJt3vDatdgPeP",
    decimals: 9,
    logoURI: "https://solgats.io/wp-content/uploads/2022/02/pew-1.png",
    tags: ["social-token"],
    verified: true,
    holders: 144,
  },
  {
    chainId: 101,
    name: "KAI",
    symbol: "KAI",
    address: "4tUUpzPsSCzSasdRueA3J2phAvLD9cqRbzFCCNQAJpt4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tUUpzPsSCzSasdRueA3J2phAvLD9cqRbzFCCNQAJpt4/logo.png",
    tags: ["utility-token", "game-token", "staking"],
    verified: true,
    holders: 9949,
  },
  {
    chainId: 101,
    name: "Skull Invasion WL",
    symbol: "SKULLWL",
    address: "F3YadVupQT3rxBpXxZJCdmzhpyb5NEPsCr18DHKXvsmb",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3YadVupQT3rxBpXxZJCdmzhpyb5NEPsCr18DHKXvsmb/logo.png",
    tags: ["utility-token", "community-token", "whitelist-token"],
    verified: true,
    holders: 1369,
  },
  {
    chainId: 101,
    name: "SL Bath WL Token",
    symbol: "LNFTxSLSKIN1",
    address: "HMWEz1fMBSaCEFVqM7ZFvpNKm4kbPpEYamkt27eQX51U",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMWEz1fMBSaCEFVqM7ZFvpNKm4kbPpEYamkt27eQX51U/logo.png",
    tags: ["liqnft-wl-token"],
    verified: true,
    holders: 263,
  },
  {
    chainId: 101,
    name: "Good Vibes",
    symbol: "GV",
    address: "7TQTpG1qBvE9ui7J9yQWKFAYpQahkaKPKqGTsqSm1wUv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TQTpG1qBvE9ui7J9yQWKFAYpQahkaKPKqGTsqSm1wUv/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1182,
  },
  {
    chainId: 101,
    name: "Boryoku Genesis Dragonz Index",
    symbol: "DRGNZ",
    address: "CzXF8oUJSsB9ADKV99WAi2TgytqAyKvQw6EihwiL9em4",
    decimals: 2,
    logoURI: "https://i.imgur.com/AzJI7FQ.png",
    tags: ["bridgesplit-fraction", "boryoku-genesis", "fraction-index", "fnft"],
    verified: true,
    holders: 307,
  },
  {
    chainId: 101,
    name: "MARS Coin",
    symbol: "$MARS",
    address: "67jSkrp4SEhk4k5E6oXjprX9SXnRb1x2M3obke7BemL1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67jSkrp4SEhk4k5E6oXjprX9SXnRb1x2M3obke7BemL1/logo.png",
    tags: ["currency", "game-token", "social-token", "community-token"],
    verified: true,
    holders: 1291,
  },
  {
    chainId: 101,
    name: "Succuverse",
    symbol: "LUST",
    address: "LUSTdLASZy86pR6V5VjMpXxW9oVtCQt8q3fJ9iHZtPY",
    decimals: 3,
    logoURI: "https://raw.githubusercontent.com/dynaris/lust/main/icon.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1208,
  },
  {
    chainId: 101,
    name: "NUT",
    symbol: "$NUT",
    address: "4bgRUBC4gPoTs38TytDwujhcdn7reRoKynecvK7fJ5VW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4bgRUBC4gPoTs38TytDwujhcdn7reRoKynecvK7fJ5VW/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 292,
  },
  {
    chainId: 101,
    name: "MONKE CREW",
    symbol: "$MCREW",
    address: "Exz2u9EhSXzGDef4v8bfXVjcUbsFm4kMKoXxn58fDUSa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Exz2u9EhSXzGDef4v8bfXVjcUbsFm4kMKoXxn58fDUSa/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 578,
  },
  {
    chainId: 101,
    name: "Majin Genies Whitelist Token",
    symbol: "MGWL",
    address: "MGTt6Cj6Brg3mHmDSe6NnJhmAvwqoibNBt7xrj92RNu",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MGTt6Cj6Brg3mHmDSe6NnJhmAvwqoibNBt7xrj92RNu/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 454,
  },
  {
    chainId: 101,
    name: "STYX",
    symbol: "STYX",
    address: "GiLAFSEGwJB3pmMkpAAznS9YBSPe82GtWugzwkBNvJ5v",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiLAFSEGwJB3pmMkpAAznS9YBSPe82GtWugzwkBNvJ5v/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 612,
  },
  {
    chainId: 101,
    name: "Official Blue Terra WL Token",
    symbol: "BTWL",
    address: "AyDDpSg7Q6icGx7RAntSDAuwxdMkEsRWkL4QkeqoQF1H",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AyDDpSg7Q6icGx7RAntSDAuwxdMkEsRWkL4QkeqoQF1H/logo.png",
    tags: [],
    verified: true,
    holders: 6651,
  },
  {
    chainId: 101,
    name: "Bizarre Platypus Cyrus",
    symbol: "CYRUS",
    address: "4RBJRwEAnNEe664LgSECkkfeT4fc6ZjtCMgL7rtUs8g1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4RBJRwEAnNEe664LgSECkkfeT4fc6ZjtCMgL7rtUs8g1/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1874,
  },
  {
    chainId: 101,
    name: "Schola",
    symbol: "SCHOLA",
    address: "4J4XAtCWWVrb4FBM4JySPWX3YWix2bTpZNtAAHH4UEba",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4J4XAtCWWVrb4FBM4JySPWX3YWix2bTpZNtAAHH4UEba/logo.png",
    tags: [],
    verified: true,
    holders: 104,
  },
  {
    chainId: 101,
    name: "Ehecatl Dragon Souls Token",
    symbol: "EDST",
    address: "CN4bGnSdNDD3A6kLTBKffF4ocN3AMg8ZQCrzoRyEZ4Yw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Chirryrth/EDST/main/Dragon_Soul.png",
    tags: ["social-tokens"],
    verified: true,
    holders: 315,
  },
  {
    chainId: 101,
    name: "Brawl Shard",
    symbol: "bSHARD",
    address: "AEcFA1cq4Mtz568HL8sd7tQ9EyTYFNV6EHw6KrqYT785",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/strongestbrawler/brawlshard/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2863,
  },
  {
    chainId: 101,
    name: "Solana Storm",
    symbol: "SST",
    address: "DuxDz1K3npjMYT6ZoH628pK3zJYjUzDMGT3ZQHKz1VYb",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DuxDz1K3npjMYT6ZoH628pK3zJYjUzDMGT3ZQHKz1VYb/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 4213,
  },
  {
    chainId: 101,
    name: "Haughty Monkee Token",
    symbol: "HMKS",
    address: "HMKSrb4Nb894wEmwYveACs3y7wHy7TKzf3kqPeiHjaqa",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMKSrb4Nb894wEmwYveACs3y7wHy7TKzf3kqPeiHjaqa/logo.png",
    tags: [],
    verified: true,
    holders: 339,
  },
  {
    chainId: 101,
    name: "LSDC (419LABS)",
    symbol: "LSDC",
    address: "4pzpksQjzXcnWXciTY8Q1rmsWsywaaEZxP8YzJdYGzHU",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pzpksQjzXcnWXciTY8Q1rmsWsywaaEZxP8YzJdYGzHU/logo.svg",
    tags: ["community", "staking"],
    verified: true,
    holders: 174,
  },
  {
    chainId: 101,
    name: "King's token",
    symbol: "KING",
    address: "5VQnKaTu522jRQyaawDNBKZjBa5SZoeetyDXEwocYxXN",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5VQnKaTu522jRQyaawDNBKZjBa5SZoeetyDXEwocYxXN/logo.png",
    tags: ["meme-coin"],
    verified: true,
    holders: 714,
  },
  {
    chainId: 101,
    name: "CRECK",
    symbol: "$CRECK",
    address: "Ao94rg8D6oK2TAq3nm8YEQxfS73vZ2GWYw2AKaUihDEY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ao94rg8D6oK2TAq3nm8YEQxfS73vZ2GWYw2AKaUihDEY/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 12061,
  },
  {
    chainId: 101,
    name: "Vladimir VS Volodymyr",
    symbol: "VVV",
    address: "Fg3NLKzwfnhtiEjnbn86wcZiGckuL5bzf61JGSqFi4ot",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/VladVSVolod/Assets/main/VladVSVolod.png",
    tags: ["Vlad-VS-Volod", "Charity", "NFT", "P2E"],
    verified: true,
    holders: 119,
  },
  {
    chainId: 101,
    name: "BOUNCER",
    symbol: "BNCE",
    address: "4NJ1L4LHSbJpk4h4rHQnJNKZbRSYticS8sQVPbGHsj33",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4NJ1L4LHSbJpk4h4rHQnJNKZbRSYticS8sQVPbGHsj33/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 2410,
  },
  {
    chainId: 101,
    name: "BARMY",
    symbol: "BARMY",
    address: "HWXWUXUNuBd6euKDxsL3FrCZ6P9RwmVmbXHKSd4MgxoA",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWXWUXUNuBd6euKDxsL3FrCZ6P9RwmVmbXHKSd4MgxoA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 175,
  },
  {
    chainId: 101,
    name: "Saber Wrapped Wrapped FTT (Sollet) (8 decimals)",
    symbol: "ssoFTT-8",
    address: "FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr/icon.png",
    tags: ["wrapped-sollet", "ethereum", "saber-mkt-ftt", "saber-dec-wrapped"],
    verified: true,
    holders: 774,
  },
  {
    chainId: 101,
    name: "Saber USDH-USDC LP",
    symbol: "USDH-USDC",
    address: "HUBBGekfLpdZhZcqjLeecLVz39o1ysDkicZpgMgZgPFS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUBBGekfLpdZhZcqjLeecLVz39o1ysDkicZpgMgZgPFS/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 1699,
  },
  {
    chainId: 101,
    name: "Saber atLUNA-LUNA LP",
    symbol: "atLUNA-LUNA",
    address: "KWAMdUrCdQ2j1t9S1HD29Z4RxXymXkwSh2c94598amY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KWAMdUrCdQ2j1t9S1HD29Z4RxXymXkwSh2c94598amY/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-allbridge", "saber-lp-wormhole-v2"],
    verified: true,
    holders: 278,
  },
  {
    chainId: 101,
    name: "Saber Wrapped LUNA (Portal) (9 decimals)",
    symbol: "sLUNA-9",
    address: "LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB/icon.png",
    tags: [
      "wrapped",
      "wormhole",
      "saber-mkt-luna",
      "wormhole-v2",
      "saber-dec-wrapped",
    ],
    verified: true,
    holders: 465,
  },
  {
    chainId: 101,
    name: "Saber cUSDC-cUSDT LP",
    symbol: "cUSDC-cUSDT",
    address: "SUSeGZEV69Xy7rQfhDffyTysHgEP3nJUDMxEZJSvJr1",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUSeGZEV69Xy7rQfhDffyTysHgEP3nJUDMxEZJSvJr1/icon.png",
    tags: ["saber-stableswap-lp", "saber-lp-solend"],
    verified: true,
    holders: 471,
  },
  {
    chainId: 101,
    name: "Saber mSOL-JSOL LP",
    symbol: "mSOL-JSOL",
    address: "SoLWs9Av7ss1jHXqVLDVCBiDaAYuT4o5uRNMMuBFhB9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLWs9Av7ss1jHXqVLDVCBiDaAYuT4o5uRNMMuBFhB9/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 318,
  },
  {
    chainId: 101,
    name: "Saber scnSOL-JSOL LP",
    symbol: "scnSOL-JSOL",
    address: "SoLaUA1vvCaidtQLYwt4yszBv1LtbYJDjEbwC4bzxca",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLaUA1vvCaidtQLYwt4yszBv1LtbYJDjEbwC4bzxca/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 100,
  },
  {
    chainId: 101,
    name: "Saber solUST-USDH LP",
    symbol: "solUST-USDH",
    address: "USTRHDiTkhjE1oDydHqNkPJNDeBKTTbz9crTmiYc2w9",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USTRHDiTkhjE1oDydHqNkPJNDeBKTTbz9crTmiYc2w9/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 624,
  },
  {
    chainId: 101,
    name: "Saber UXD-USDC LP",
    symbol: "UXD-USDC",
    address: "UXDgmqLd1roNYkC4TmJzok61qcM9oKs5foDADiFoCiJ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXDgmqLd1roNYkC4TmJzok61qcM9oKs5foDADiFoCiJ/icon.png",
    tags: ["saber-stableswap-lp"],
    verified: true,
    holders: 2291,
  },
  {
    chainId: 101,
    name: "ProtoReality Games Token",
    symbol: "PRGC",
    address: "66edZnAPEJSxnAK4SckuupssXpbu5doV57FUcghaqPsY",
    decimals: 9,
    logoURI: "https://www.protorealitygames.com/images/PRGC_Token.png",
    tags: ["utility-token", "game-token", "gaming"],
    verified: true,
    holders: 3475,
  },
  {
    chainId: 101,
    name: "Message Bottle",
    symbol: "BOTTLE",
    address: "EYZMoSHaVmULt3AnujorGweJ6NPqzDaUbLrTiCwZeJYu",
    decimals: 0,
    logoURI:
      "https://dd6jvny7gn5elomvxk6uqibfvsdtpfiid7rxvparqrqaq4kz.arweave.net/GPyatx8zekW5lbq9SCAlrI_c3lQgf43q8EYRgCHFZ_U?ext=png",
    tags: ["utility-token"],
    verified: true,
    holders: 5490,
  },
  {
    chainId: 101,
    name: "Neon Game CREDZ",
    symbol: "$CREDZ",
    address: "9nT91mYnAjdJYmgDBqa4jY4p6RpHXJKNj7Yz7KyqmZjv",
    decimals: 0,
    logoURI:
      "https://rxvwsth6k2wkjbpgqixi4db3ekvlailhvm2xa4pcmqzl6dtctqwa.arweave.net/jetpTP5WrKSF5oIujgw7IqqwIWerNXBx4mQyvw5inCw",
    tags: ["utility-token"],
    verified: true,
    holders: 219,
  },
  {
    chainId: 101,
    name: "vBOW",
    symbol: "vBOW",
    address: "6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9/logo.png",
    tags: [],
    verified: true,
    holders: 1804,
  },
  {
    chainId: 101,
    name: "Crazy Monkeys Club",
    symbol: "CRM",
    address: "3pVGSD3L4n35kcC6m6Z8rmG5j4q7Uq8NvEVPmc9CwrxZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/crazymonkeysclub/CRM-LOGO/main/CRM_TOKEN_LOGO.png",
    tags: ["social-token"],
    verified: true,
    holders: 3042,
  },
  {
    chainId: 101,
    name: "Racer",
    symbol: "RACER",
    address: "RACEKYC7jTn98bEyG8xWFyMJZj9XDXHPfYRsCMoaM33",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RACEKYC7jTn98bEyG8xWFyMJZj9XDXHPfYRsCMoaM33/logo.png",
    tags: ["currency", "Utility-Token", "NFT-token"],
    verified: true,
    holders: 2931,
  },
  {
    chainId: 101,
    name: "Photon Share",
    symbol: "PTNS",
    address: "7vTiyNxmJeWPQjWEChCHhrSZdewidiDWFdF4qy91ZthC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vTiyNxmJeWPQjWEChCHhrSZdewidiDWFdF4qy91ZthC/logo.png",
    tags: ["DeFi"],
    verified: true,
    holders: 371,
  },
  {
    chainId: 101,
    name: "Guinea Pigs Club",
    symbol: "GPC",
    address: "9xvPQbGmUWwhhaRmersVKGvFCYTHQfkiuWgiuzv68YB4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xvPQbGmUWwhhaRmersVKGvFCYTHQfkiuWgiuzv68YB4/gpctokenlogo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 416,
  },
  {
    chainId: 101,
    name: "OSARU Coin",
    symbol: "OSARU",
    address: "osaRUfE6ccwNMWfdY8X29auM8W4LtyDzaEU4EAHKBhR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/osaRUfE6ccwNMWfdY8X29auM8W4LtyDzaEU4EAHKBhR/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 412,
  },
  {
    chainId: 101,
    name: "LDAC Gemz",
    symbol: "GEMZ",
    address: "GEMz5TfaV54E6wmGJ1wtArysP99U5pftMExgeCZ6hwoo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEMz5TfaV54E6wmGJ1wtArysP99U5pftMExgeCZ6hwoo/logo.png",
    tags: ["utility-token", "game-token"],
    verified: true,
    holders: 330,
  },
  {
    chainId: 101,
    name: "v2CryptoStrapsWL",
    symbol: "GUNWL2",
    address: "HjLtBjuh1vMd3N1Hsi2V6akvoziTMrbpnoWnkyXpb9tA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HjLtBjuh1vMd3N1Hsi2V6akvoziTMrbpnoWnkyXpb9tA/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 5015,
  },
  {
    chainId: 101,
    name: "SNAPSHOTS",
    symbol: "SNAP",
    address: "AAoJ5eYd61QsUBRBxjCcAdsQZpQYxrob2wS4Hzoaeoas",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/ZvsK4/snapshotslogo/main/logo.png",
    tags: [],
    verified: true,
    holders: 1685,
  },
  {
    chainId: 101,
    name: "Kurai Bats WL",
    symbol: "KBWL",
    address: "FBXoa1cW6h4QYaM15dXjmDQXqjsg3ZqqsZr1qAJNESQS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBXoa1cW6h4QYaM15dXjmDQXqjsg3ZqqsZr1qAJNESQS/logo.png",
    tags: ["White-List", "Utility-Token", "NFT"],
    verified: true,
    holders: 275,
  },
  {
    chainId: 101,
    name: "Glitched Gorilla WL Token 1",
    symbol: "GGWL1",
    address: "GGWL1v6aRnjRFAxi7j2ArQBDRxb1maZz4XGqMsxkdYbt",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGWL1v6aRnjRFAxi7j2ArQBDRxb1maZz4XGqMsxkdYbt/GGToken.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 151,
  },
  {
    chainId: 101,
    name: "JU",
    symbol: "JU",
    address: "judTED8D9sPVX87UUajnDh2bwdG9MDFUVPzJdGnFYkS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/judTED8D9sPVX87UUajnDh2bwdG9MDFUVPzJdGnFYkS/logo.png",
    tags: [
      "utility-token",
      "game-token",
      "game-currency",
      "staking",
      "staking-reward",
      "Gaming",
    ],
    verified: true,
    holders: 164,
  },
  {
    chainId: 101,
    name: "Metaversol Loyalty Token",
    symbol: "METAL",
    address: "8kWgPei6KkkcnoDZGtXGXxAvUkNmug7qbeNnm2zx3xgp",
    decimals: 0,
    logoURI: "https://metaversol.com/coinlogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1560,
  },
  {
    chainId: 101,
    name: "SOLautos Whitelist",
    symbol: "SAWL",
    address: "GtMieQ5MpEqKWEJEWjp4HbGxDnYCRAps3W8tQ55YVRyj",
    decimals: 0,
    logoURI: "https://solautos.io/images/SolLogo-768x484.png",
    tags: ["utility-token"],
    verified: true,
    holders: 196,
  },
  {
    chainId: 101,
    name: "Awful Waffles Presale Token",
    symbol: "AWWL",
    address: "AWWL88eXNREJaT1KFvdTWmGnVSSXqxjbYhFUjJ978eFn",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWWL88eXNREJaT1KFvdTWmGnVSSXqxjbYhFUjJ978eFn/awwl.png",
    tags: ["utility-token"],
    verified: true,
    holders: 358,
  },
  {
    chainId: 101,
    name: "JUNK",
    symbol: "JUNKz",
    address: "AMzmwvDRKdt5AQ3m1m28tWjzBxmQNe1PsmHnYitVZwzp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/XAGBack/JUNKToken1/main/JunkToken.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1441,
  },
  {
    chainId: 101,
    name: "CHIQUITA Token",
    symbol: "CHIQUITA",
    address: "CtvD16P3aziZZaamHEyLHVnFN7UpXD9rcziShg2JAuQJ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/onuraladi/HonkugaToken/main/honkuga.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 317,
  },
  {
    chainId: 101,
    name: "Vegas Token",
    symbol: "VEGAS",
    address: "FHeU7e7Tyw5bNcCNiM1jNVoT5UUims7zRi3o76Kdz4f6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FHeU7e7Tyw5bNcCNiM1jNVoT5UUims7zRi3o76Kdz4f6/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 101,
  },
  {
    chainId: 101,
    name: "Valley22 Whitelist",
    symbol: "VLW",
    address: "v22HTG593HPztGA9gKqEw1KRJVFUECk61Xg2LnugJ9M",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Justin-hw-Tseng/assets/main/logo.png",
    tags: ["social-token", "member-token"],
    verified: true,
    holders: 229,
  },
  {
    chainId: 101,
    name: "TownCoin",
    symbol: "TOCO",
    address: "7FntsntzGjK9PzPBbHLDJAFcKQVU14d2SbQZhgMUf2KA",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FntsntzGjK9PzPBbHLDJAFcKQVU14d2SbQZhgMUf2KA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 436,
  },
  {
    chainId: 101,
    name: "24K Gorilla Club Token",
    symbol: "LFGOLD",
    address: "LFGqoEMMPUDkeWHRHeZucsiAtgudgDw9DaLjvxXMEmn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LFGqoEMMPUDkeWHRHeZucsiAtgudgDw9DaLjvxXMEmn/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 345,
  },
  {
    chainId: 101,
    name: "Rave Review Pre Mint Token",
    symbol: "RR-PRE",
    address: "RRpPtGKTBRuawbvqGqAPPtu81Gmhhg4LZww4BDarXZv",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RRpPtGKTBRuawbvqGqAPPtu81Gmhhg4LZww4BDarXZv/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1100,
  },
  {
    chainId: 101,
    name: "Solana Cyclopes",
    symbol: "SHEEP",
    address: "4P4jjC6KjwVTCxhpfj4pQGBC1ohUaNin8QaWwPyge9ML",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/cyclopes.png",
    tags: ["utility-token"],
    verified: true,
    holders: 282,
  },
  {
    chainId: 101,
    name: "OG Atadian RWL",
    symbol: "ATRWL",
    address: "rwLH899ZJpjdWb2si3213HMHSvH4yMJdCj6Ne3HrkN4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/KKQanT/token-list/main/assets/mainnet/rwLH899ZJpjdWb2si3213HMHSvH4yMJdCj6Ne3HrkN4/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1360,
  },
  {
    chainId: 101,
    name: "Club 420 Token",
    symbol: "THC",
    address: "THCBB791xLFhRhxPHMDVN9m5KDeBBjwh6dfBbnTuPe9",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/THCBB791xLFhRhxPHMDVN9m5KDeBBjwh6dfBbnTuPe9/Logo.png",
    tags: ["community-token", "utility-token", "nft-staking"],
    verified: true,
    holders: 399,
  },
  {
    chainId: 101,
    name: "Dino Jump WL Token",
    symbol: "DJWLT",
    address: "DJTMxBZDpjFv27o4QzTSkSBb2TZPFTsAnXaAWPrJept6",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJTE14SD24SKmPv6pLt84173srwbBgt7YGyfHZ5oVhwp/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 2825,
  },
  {
    chainId: 101,
    name: "Oblivion City X Whitelist Token",
    symbol: "OCXWL",
    address: "obtF8YwHn2g7uhmtziqVn4wvD2r99e3DPcQo4rFaN1G",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/obtF8YwHn2g7uhmtziqVn4wvD2r99e3DPcQo4rFaN1G/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1169,
  },
  {
    chainId: 101,
    name: "Honey Badges Presale Token",
    symbol: "HB",
    address: "CfNkADyvwg66yLtctbuDo381s6VqVC8583ur7uvooE43",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CfNkADyvwg66yLtctbuDo381s6VqVC8583ur7uvooE43/logo.png",
    tags: ["presale-token"],
    verified: true,
    holders: 1055,
  },
  {
    chainId: 101,
    name: "Lost At Sea WAVE",
    symbol: "WAVE",
    address: "GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8/logo.png",
    tags: ["community-token", "utility-token", "nft-staking"],
    verified: true,
    holders: 1077,
  },
  {
    chainId: 101,
    name: "Jelly",
    symbol: "JELLY",
    address: "9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2577,
  },
  {
    chainId: 101,
    name: "Seven Deadly Sins Token",
    symbol: "SDS",
    address: "Fkfi6USVjwkY47qaSK4i4WQTKtE6tFLRrkMc8C5VPzhv",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fkfi6USVjwkY47qaSK4i4WQTKtE6tFLRrkMc8C5VPzhv/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 236,
  },
  {
    chainId: 101,
    name: "Starry Insiders",
    symbol: "STAR",
    address: "Cx7Rswv6MNyaBk354BohVvBP6mCPFo7FDjTwCYDXkajG",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cx7Rswv6MNyaBk354BohVvBP6mCPFo7FDjTwCYDXkajG/logo.png",
    tags: ["gambling", "nft"],
    verified: true,
    holders: 256,
  },
  {
    chainId: 101,
    name: "DegenAlley",
    symbol: "DGNA",
    address: "8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn/DGNA.png",
    tags: ["social-token"],
    verified: true,
    holders: 163,
  },
  {
    chainId: 101,
    name: "CHA CHING",
    symbol: "CHING",
    address: "Bfsrs1qLiuooamkSuG9tcF66SXXFefWbur86RkHDuxQS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bfsrs1qLiuooamkSuG9tcF66SXXFefWbur86RkHDuxQS/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 362,
  },
  {
    chainId: 101,
    name: "Gear Token",
    symbol: "GEAR",
    address: "7s6NLX42eURZfpyuKkVLrr9ED9hJE8718cyXFsYKqq5g",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/7s6NLX42eURZfpyuKkVLrr9ED9hJE8718cyXFsYKqq5g/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 4875,
  },
  {
    chainId: 101,
    name: "The Syndicate Token",
    symbol: "TST",
    address: "TSTeJQaJ68jmS9vvooq9iDCmQETggC8ibb3ofeDkQUa",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TSTeJQaJ68jmS9vvooq9iDCmQETggC8ibb3ofeDkQUa/logo.png",
    tags: [],
    verified: true,
    holders: 1306,
  },
  {
    chainId: 101,
    name: "DBS",
    symbol: "DBS",
    address: "DBsDkTdAxQEiSDeqfE48eMmc1vGXWjcum7hjRAf6zxza",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DBsDkTdAxQEiSDeqfE48eMmc1vGXWjcum7hjRAf6zxza/logo.jpg",
    tags: [],
    verified: true,
    holders: 411,
  },
  {
    chainId: 101,
    name: "FamilySOL",
    symbol: "FamSOL",
    address: "3rH1toffQAELHo5vyRKdwEFxhPTZA7ocfRdJK2c8txoJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solodevo/FamilySOL/main/LOGOFamilySOL.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "FLY",
    symbol: "$FLY",
    address: "9oCf3dx1PoSP1tnhNS6LBQXzixU1vkzNHvFwY1oFCD8M",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oCf3dx1PoSP1tnhNS6LBQXzixU1vkzNHvFwY1oFCD8M/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1058,
  },
  {
    chainId: 101,
    name: "DRACULA GAME BLOOD",
    symbol: "BLOOD",
    address: "BLAAD2QLUgRSbQ9AB9jqAoHh55cGVcSBaCH9JGBh2zDX",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLAAD2QLUgRSbQ9AB9jqAoHh55cGVcSBaCH9JGBh2zDX/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1845,
  },
  {
    chainId: 101,
    name: "Wisecat Token",
    symbol: "WISECAT",
    address: "WisehRG5uPTHFJHrTmfm7dBMRvLZ6Lm9mivgYB2Pd3A",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WisehRG5uPTHFJHrTmfm7dBMRvLZ6Lm9mivgYB2Pd3A/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 500,
  },
  {
    chainId: 101,
    name: "Portals Ivory Index",
    symbol: "IVRY",
    address: "2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu",
    decimals: 2,
    logoURI:
      "https://dl.airtable.com/.attachmentThumbnails/102af36cc30fec0b05ebb45406f20971/126d0320",
    tags: ["bridgesplit-fraction", "portals-ivory", "fraction-index", "fnft"],
    verified: true,
    holders: 215,
  },
  {
    chainId: 101,
    name: "Stoned Ape Crew Index",
    symbol: "SAC",
    address: "GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7",
    decimals: 2,
    logoURI: "https://i.imgur.com/sxmJbDr.jpg",
    tags: [
      "bridgesplit-fraction",
      "stonedd-ape-crew",
      "fraction-index",
      "fnft",
    ],
    verified: true,
    holders: 286,
  },
  {
    chainId: 101,
    name: "MAEC WL",
    symbol: "MAECWL",
    address: "MeTaRpcZ6XDvFrmPsNFqhehqV7MgY6obHrsxJLG372E",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MeTaRpcZ6XDvFrmPsNFqhehqV7MgY6obHrsxJLG372E/logo.png",
    tags: ["Whitelist"],
    verified: true,
    holders: 7327,
  },
  {
    chainId: 101,
    name: "TruSwap",
    symbol: "TRS",
    address: "2TocBpcDms9xWB9oGBo1Pek3bZDhbBtFRNUkSYbQ9N5P",
    decimals: 9,
    logoURI: "https://github.com/Msaqib0786/crypto/blob/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1150,
  },
  {
    chainId: 101,
    name: "Heatmob",
    symbol: "HEMOB",
    address: "Hxk1ns5V8Lq41wzLjvq8pvNEhGh3FcCTWbawbj5SL4jj",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hxk1ns5V8Lq41wzLjvq8pvNEhGh3FcCTWbawbj5SL4jj/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 235,
  },
  {
    chainId: 101,
    name: "PerpeTraders",
    symbol: "PERP",
    address: "EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3/logo.jpg",
    tags: ["stablecoin", "security-token", "utility-token", "tokenized-stock"],
    verified: true,
    holders: 197,
  },
  {
    chainId: 101,
    name: "Neutrino",
    symbol: "NEUT",
    address: "Dc8mEshVhb6e4dxi1yWm2gBDxUDVfYBKrYMGR1hz8Ku7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dc8mEshVhb6e4dxi1yWm2gBDxUDVfYBKrYMGR1hz8Ku7/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3369,
  },
  {
    chainId: 101,
    name: "Finrend Token",
    symbol: "FNRD",
    address: "FNRDrrinmYQ4TMEbAppmLFp92bGi6FfNLBCp5Xkc2PSw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNRDrrinmYQ4TMEbAppmLFp92bGi6FfNLBCp5Xkc2PSw/logo.png",
    tags: ["defi", "fund-management", "nft", "finance", "exchange"],
    verified: true,
    holders: 9337,
  },
  {
    chainId: 101,
    name: "Gangsta Gators",
    symbol: "GGAT",
    address: "GGATzpTfEwkq8BDotzBFTUSeKu36XRtsFhncd83UDwEu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGATzpTfEwkq8BDotzBFTUSeKu36XRtsFhncd83UDwEu/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 122,
  },
  {
    chainId: 101,
    name: "Faith",
    symbol: "FAITH",
    address: "8RSyhCxFKYVnr6PGTgKC9o86AjbQjdmLRZjYJjhdTdYH",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RSyhCxFKYVnr6PGTgKC9o86AjbQjdmLRZjYJjhdTdYH/logo.png",
    tags: [],
    verified: true,
    holders: 190,
  },
  {
    chainId: 101,
    name: "solUST",
    symbol: "solUST",
    address: "JAa3gQySiTi8tH3dpkvgztJWHQC1vGXr5m6SQ9LEM55T",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAa3gQySiTi8tH3dpkvgztJWHQC1vGXr5m6SQ9LEM55T/solust.svg",
    tags: ["stablecoin"],
    verified: true,
    holders: 1986,
  },
  {
    chainId: 101,
    name: "Village Building Site Tokens",
    symbol: "VLGKEY",
    address: "F7hd3fj1nFrSDmDrYkANSFkFagnfYSjHUG2i2NxE3Jaz",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F7hd3fj1nFrSDmDrYkANSFkFagnfYSjHUG2i2NxE3Jaz/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 207,
  },
  {
    chainId: 101,
    name: "SolChippie WL Token",
    symbol: "SCWL",
    address: "BPfgTWYrsBZJAxrjLxp9cMbtKTswsL5wwW3SHqPCnFh5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BPfgTWYrsBZJAxrjLxp9cMbtKTswsL5wwW3SHqPCnFh5/logo.png",
    tags: ["NFT", "SOLCHIPPIE"],
    verified: true,
    holders: 470,
  },
  {
    chainId: 101,
    name: "TRIPPY token",
    symbol: "TRIPPY",
    address: "3MNjcDDJSmvNqvwWZ2RqSo8dA863jGrDNohvCCMkodZJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/d3ebe4df93d7ee04dd3b132579181a5fef90631e/assets/mainnet/3MNjcDDJSmvNqvwWZ2RqSo8dA863jGrDNohvCCMkodZJ/logo.png",
    tags: [],
    verified: true,
    holders: 154,
  },
  {
    chainId: 101,
    name: "SXA Token",
    symbol: "SXA",
    address: "2thvTG87oytuwp6Egp1jqMAKTyQ5B1aWk46pLsa7Zo2P",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/ArthurPaivaT/token-list/main/assets/mainnet/2thvTG87oytuwp6Egp1jqMAKTyQ5B1aWk46pLsa7Zo2P/logo.png",
    tags: [],
    verified: true,
    holders: 665,
  },
  {
    chainId: 101,
    name: "OddKey",
    symbol: "ODKY",
    address: "FJvkpSpR4y73Hd6yNDvd11ie3kBkHAtNCv922gcYYM3w",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FJvkpSpR4y73Hd6yNDvd11ie3kBkHAtNCv922gcYYM3w/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6643,
  },
  {
    chainId: 101,
    name: "World of Solana Mint",
    symbol: "WOSMINT",
    address: "WoSgyFWVmmEdvfY9Bp5Zrhqu6uas7mUjkiZpEUoBeQH",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WoSgyFWVmmEdvfY9Bp5Zrhqu6uas7mUjkiZpEUoBeQH/logo.png",
    tags: ["wl-token", "whitelist"],
    verified: true,
    holders: 3105,
  },
  {
    chainId: 101,
    name: "Pen Frens: WL Mint Token",
    symbol: "WLPENFRENS",
    address: "PFjQE5mYPTCCDuHz8buxFppyQoL8q32wfTmZbBF5BWL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/penfrens/token-list/main/assets/mainnet/PFjQE5mYPTCCDuHz8buxFppyQoL8q32wfTmZbBF5BWL/logo.png",
    tags: ["community-token", "wl-token", "NFT"],
    verified: true,
    holders: 3039,
  },
  {
    chainId: 101,
    name: "Keroverse WL Token",
    symbol: "KVWL",
    address: "RKwwb3SLUnipcDJaXsaVJPn8dzrcyNNdUwpAUDcRZAT",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RKwwb3SLUnipcDJaXsaVJPn8dzrcyNNdUwpAUDcRZAT/logo.png",
    tags: ["wl-token", "whitelist"],
    verified: true,
    holders: 225,
  },
  {
    chainId: 101,
    name: "Bluffcoin",
    symbol: "BLUFF",
    address: "9CzmA137fzLtdyfaBY63Sa85uY8ZvBiX3MrAJypzsDGR",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9CzmA137fzLtdyfaBY63Sa85uY8ZvBiX3MrAJypzsDGR/logo.png",
    tags: [],
    verified: true,
    holders: 1339,
  },
  {
    chainId: 101,
    name: "KNIVES",
    symbol: "KNIVES",
    address: "sotka91WnAeWUbmyBgGbHjhYFML1AX1Nk3xYdvQTMth",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sotka91WnAeWUbmyBgGbHjhYFML1AX1Nk3xYdvQTMth/logo.png",
    tags: ["KNIVES"],
    verified: true,
    holders: 446,
  },
  {
    chainId: 101,
    name: "PoSB-WindToken",
    symbol: "PoSBWind",
    address: "3MfUyK8FbwbEhPsGqi9pJirKLgQevH27AvH2mhbUGxtj",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3MfUyK8FbwbEhPsGqi9pJirKLgQevH27AvH2mhbUGxtj/logo.png",
    tags: ["posb-token", "whitelist"],
    verified: true,
    holders: 258,
  },
  {
    chainId: 101,
    name: "VisionGame",
    symbol: "VISION",
    address: "HVkFqcMHevVPb4XKrf4XowjEaVVsBoqJ2U1EG59Dfk5j",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HVkFqcMHevVPb4XKrf4XowjEaVVsBoqJ2U1EG59Dfk5j/logo.png",
    tags: ["game-publishing"],
    verified: true,
    holders: 7192,
  },
  {
    chainId: 101,
    name: "The Monkey Design Club",
    symbol: "TMDC",
    address: "MCDsTXbZe87sWezWJ3B8Sw63PUjq3BXzRmY7zptoYr2",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/namu42/ImageRepo/main/MCL9utLXFvUMdWLou4jk13BBgTu2XRGM983eZ24RsTP/logo.png",
    tags: ["TMDC", "THEMONKEYDESIGNCLUB", "metaverse"],
    verified: true,
    holders: 1831,
  },
  {
    chainId: 101,
    name: "Agave Zwolf",
    symbol: "AGVZ",
    address: "CRSzWoeyfR8sJxB2d6LLEre92Uc59TCPX2gZidp4t3eE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/GrupoZwolf/cryptozw/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 516,
  },
  {
    chainId: 101,
    name: "Solana Hills Whitelist-1 Token",
    symbol: "SHWL1",
    address: "WL12AGjU2zs5myyrUzBmSDF5cMG9xMAcZ8kMYnre2ns",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL12AGjU2zs5myyrUzBmSDF5cMG9xMAcZ8kMYnre2ns/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 3725,
  },
  {
    chainId: 101,
    name: "Solana Hills Whitelist-2 Token",
    symbol: "SHWL2",
    address: "WL2jnbxfHqQ6SoHrfA4QXEx9vw8gNyJwSTfgtvvuoKD",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL2jnbxfHqQ6SoHrfA4QXEx9vw8gNyJwSTfgtvvuoKD/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 5037,
  },
  {
    chainId: 101,
    name: "Solana Monkey University Token",
    symbol: "SMU",
    address: "Eqekt4QF8zy9X6MZn5JUa6YYAz5MMgo7ZV5ZyX7YtjJW",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/Tagzie/crypto/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 789,
  },
  {
    chainId: 101,
    name: "BENZ Token",
    symbol: "BENZ",
    address: "7xBLAJQ9TDnBXZ6cEcLiq7XtwneiCU5b94b569ryBEqP",
    decimals: 0,
    logoURI:
      "https://gateway.pinata.cloud/ipfs/QmR5FQVrGg7ySX3BXxPsMzPrBR2j2RWnDKVavReKbKV79W",
    tags: ["utility-token"],
    verified: true,
    holders: 118,
  },
  {
    chainId: 101,
    name: "PREY",
    symbol: "PREY",
    address: "nYDqQVEaQxLYLh8B8oAFXziMT1bcGrAVigZPL1s3dKc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nYDqQVEaQxLYLh8B8oAFXziMT1bcGrAVigZPL1s3dKc/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 332,
  },
  {
    chainId: 101,
    name: "NEAR (Allbridge from Near)",
    symbol: "NEAR",
    address: "BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z/logo.png",
    tags: [],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "CoC public sale token",
    symbol: "PB",
    address: "9oM4Bg2E8y8LkGbaJhd27tcbto4KpjvXHubG2LEoRbQL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oM4Bg2E8y8LkGbaJhd27tcbto4KpjvXHubG2LEoRbQL/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 9515,
  },
  {
    chainId: 101,
    name: "Hashrate Servers Whitelist Token",
    symbol: "HSWL",
    address: "BUduUSJDmTpEUs3di9NMPf38zRz1HMHqEtWAebpYwyVz",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUduUSJDmTpEUs3di9NMPf38zRz1HMHqEtWAebpYwyVz/logo.png",
    tags: [],
    verified: true,
    holders: 1211,
  },
  {
    chainId: 101,
    name: "CS Stake Token",
    symbol: "CSST",
    address: "8uD8yx7pXCvX1J1AmdN7jLZh7rAGUE4zNtwT7UtY4rvm",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8uD8yx7pXCvX1J1AmdN7jLZh7rAGUE4zNtwT7UtY4rvm/logo.jpg",
    tags: ["stake-token"],
    verified: true,
    holders: 1462,
  },
  {
    chainId: 101,
    name: "ASTRALS GLXY",
    symbol: "GLXY",
    address: "5BY9wMqpvmTJrzUHt9BAmaQwave833DyHZh33rCXFEQ2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5BY9wMqpvmTJrzUHt9BAmaQwave833DyHZh33rCXFEQ2/logo.png",
    tags: ["NFT-token"],
    verified: true,
    holders: 4045,
  },
  {
    chainId: 101,
    name: "Staked Yawww",
    symbol: "sYAW",
    address: "4qn6FjsBPpVrGcPmvTgfwpCEQv7VxEAZX6WqmekWt5S7",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qn6FjsBPpVrGcPmvTgfwpCEQv7VxEAZX6WqmekWt5S7/syaw.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2721,
  },
  {
    chainId: 101,
    name: "SOL Game Coin",
    symbol: "GAMECOIN",
    address: "8tc8SFPUjJcuwZC3JPZMvTCnad9gsCZE94y2EE6i7gjk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tc8SFPUjJcuwZC3JPZMvTCnad9gsCZE94y2EE6i7gjk/logo.png",
    tags: ["utility-token", "game-token", "social-token"],
    verified: true,
    holders: 106,
  },
  {
    chainId: 101,
    name: "ALL",
    symbol: "$ALL",
    address: "7ScYHk4VDgSRnQngAUtQk4Eyf7fGat8P4wXq6e2dkzLj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ScYHk4VDgSRnQngAUtQk4Eyf7fGat8P4wXq6e2dkzLj/logo.png",
    tags: ["utility-token", "nft", "commodity-token"],
    verified: true,
    holders: 9187,
  },
  {
    chainId: 101,
    name: "Skyscraper",
    symbol: "SSCR",
    address: "FT2jC2W3kNxY1xDArtBzhPyAygFrB6HE8YH9Jbxdgpsy",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/NarrativeDev/token/main/LOGO.png",
    tags: ["utility-token"],
    verified: true,
    holders: 130,
  },
  {
    chainId: 101,
    name: "Boogle Koin",
    symbol: "BOOG",
    address: "4mjbG7eFXriY6enhFfuFT4QAEFfuoY5e9KGC4eAp8Zv6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Yamer9772/boogles/main/Logo.jpeg",
    tags: ["social-token"],
    verified: true,
    holders: 195,
  },
  {
    chainId: 101,
    name: "BM",
    symbol: "BM",
    address: "m6XGr58ATHSS7BvThRCDkqnsAeXLhpammhCrDo1amxq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/m6XGr58ATHSS7BvThRCDkqnsAeXLhpammhCrDo1amxq/logo.png",
    tags: ["utility-token", "community-token", "exchange-token"],
    verified: true,
    holders: 121,
  },
  {
    chainId: 101,
    name: "Scrap City",
    symbol: "SC",
    address: "Hv55zwSKWfGBBY8bHMJ1fRxAbHHzuVGirJrpRKABV4mV",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hv55zwSKWfGBBY8bHMJ1fRxAbHHzuVGirJrpRKABV4mV/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 136,
  },
  {
    chainId: 101,
    name: "Female HODL Whitelist Token",
    symbol: "SHFW",
    address: "WLhJmBK713bYZUpe9Hvx6CdCDMFRLLGDTJtaME7ywfP",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solanahodlwhales/whitelist/main/FemaleWhiteList.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1966,
  },
  {
    chainId: 101,
    name: "Monkeh Moneh",
    symbol: "DAHS",
    address: "DAHStbBEHcp7ooofA1TV3oayoRGM9i1v6TJSnoa2ZAWh",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAHStbBEHcp7ooofA1TV3oayoRGM9i1v6TJSnoa2ZAWh/logo.svg",
    tags: ["nft-token"],
    verified: true,
    holders: 629,
  },
  {
    chainId: 101,
    name: "Seven Deadly Sins VIP Token",
    symbol: "SDSV",
    address: "7mQ7A7YPzRfkQnTwgwCMJknQ38EBBuppf1UEHYhBXMYB",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mQ7A7YPzRfkQnTwgwCMJknQ38EBBuppf1UEHYhBXMYB/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1435,
  },
  {
    chainId: 101,
    name: "Soluna Governance Token",
    symbol: "SLNA",
    address: "SLNAAQ8VT6DRDc3W9UPDjFyRt7u4mzh8Z4WYMDjJc35",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNAAQ8VT6DRDc3W9UPDjFyRt7u4mzh8Z4WYMDjJc35/slna.svg",
    tags: [],
    verified: true,
    holders: 1807,
  },
  {
    chainId: 101,
    name: "Priceless Token",
    symbol: "PRTK",
    address: "7AK7bdmA2GZioA4Z6WdKms4oBa9xmScYzdRRjPnV6iPM",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/PriceFB/PixelBabes-website/main/assets/images/Empty-Gold-Coin-PNG-Image.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 195,
  },
  {
    chainId: 101,
    name: "BMT",
    symbol: "BMT",
    address: "FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF/logo.png",
    tags: ["utility-token", "community-token", "exchange-token"],
    verified: true,
    holders: 170,
  },
  {
    chainId: 101,
    name: "Solaris Whitelist Token",
    symbol: "SOLARISWL",
    address: "Gso9VgvACphPJerk32vTmsvCPN6H79pKqdUMZVCGfRnf",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gso9VgvACphPJerk32vTmsvCPN6H79pKqdUMZVCGfRnf/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2738,
  },
  {
    chainId: 101,
    name: "Balloonsville AIR",
    symbol: "AIR",
    address: "E6eCEE3KqjRD5UxcBYQTdV8Z535hyaBuFin9Udm6s6bz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6eCEE3KqjRD5UxcBYQTdV8Z535hyaBuFin9Udm6s6bz/logo.png",
    tags: ["NFT-token"],
    verified: true,
    holders: 4535,
  },
  {
    chainId: 101,
    name: "SolPhant WL",
    symbol: "SolPhant",
    address: "Eq6ipM2YvENyuFQmxy1zMAqfdm2dHsd45rDoKteMSRgR",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eq6ipM2YvENyuFQmxy1zMAqfdm2dHsd45rDoKteMSRgR/logo.jpg",
    tags: ["whitelist-token"],
    verified: true,
    holders: 156,
  },
  {
    chainId: 101,
    name: "Grepper Contributor Coin",
    symbol: "GREPCC",
    address: "6tAmokk5fqrjm4ho2JerziBsiV3hYzgJZnG6sFXZNXZs",
    decimals: 2,
    logoURI: "https://www.codegrepper.com/images/grepper_coin.png",
    tags: [
      "grepper",
      "grepper-coin",
      "grepper-contributor",
      "grepcc",
      "community-token",
      "developer",
    ],
    verified: true,
    holders: 1715,
  },
  {
    chainId: 101,
    name: "Paradox World",
    symbol: "$PRDX",
    address: "PRDXoZD3WCjgr6xzvTfWo3qQJmmVsR616cX2NM1wnaX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRDXoZD3WCjgr6xzvTfWo3qQJmmVsR616cX2NM1wnaX/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1287,
  },
  {
    chainId: 101,
    name: "Project Paradise - CHI Token",
    symbol: "CHI",
    address: "ARg9wfeLN4qZTxgYTYeuGtGFMmYdk5zFhBuSnTfXXUvb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/benthebape/projekt-paradise-chi-token/main/Moneda_1.png",
    tags: ["utility-token"],
    verified: true,
    holders: 641,
  },
  {
    chainId: 101,
    name: "everwave",
    symbol: "WVE",
    address: "33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F/logo.jpg",
    tags: [],
    verified: true,
    holders: 281,
  },
  {
    chainId: 101,
    name: "MAHO",
    symbol: "MAHO",
    address: "MAHGKVNJzmGKUA2oHc4QaQtkfdoeKuHgrVGpjdxHhss",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAHGKVNJzmGKUA2oHc4QaQtkfdoeKuHgrVGpjdxHhss/logo.png",
    tags: ["Utility-token"],
    verified: true,
    holders: 339,
  },
  {
    chainId: 101,
    name: "Odysseus Coin",
    symbol: "ODYSSEUS",
    address: "gJtYNevehYkg9VeEaWWKztWeFt4WXdQFihMXrUMeKd4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gJtYNevehYkg9VeEaWWKztWeFt4WXdQFihMXrUMeKd4/logo.png",
    tags: ["DeFi"],
    verified: true,
    holders: 8674,
  },
  {
    chainId: 101,
    name: "Miner Monkeys Whitelist Token",
    symbol: "MMWLT",
    address: "MMTGAktYpgHiUnj1jdMvCSiap84X9bsfWbw11yHWAEm",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMTGAktYpgHiUnj1jdMvCSiap84X9bsfWbw11yHWAEm/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 583,
  },
  {
    chainId: 101,
    name: "Ape Energy Labs Whitelist Token",
    symbol: "AELWL",
    address: "WLQg4eA6duP7mNhcRtquVr5mQoReaTGZiCbR9hYSfvu",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLQg4eA6duP7mNhcRtquVr5mQoReaTGZiCbR9hYSfvu/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 4907,
  },
  {
    chainId: 101,
    name: "World of Solana",
    symbol: "WOS",
    address: "WoSZYtctzp48xcdsSfGNKUGhjNdPx2qm5J2TUNfd1a1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WoSZYtctzp48xcdsSfGNKUGhjNdPx2qm5J2TUNfd1a1/logo.png",
    tags: ["utility-token", "social-token", "community-token"],
    verified: true,
    holders: 721,
  },
  {
    chainId: 101,
    name: "Zoo",
    symbol: "ZOO",
    address: "CbjRKEPmw6akAdmhp5ZcCYRRWQf4bHrd3z8XXMCFmWvZ",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/97711508/152678665-9e923dbd-8190-40a8-a910-634881fe3530.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 159,
  },
  {
    chainId: 101,
    name: "BigShotFoxes Whitelist",
    symbol: "WLBSF",
    address: "THN7o5vR5ELDY1HeJmDd1ezSuu3mwpmMapzKEuRGrMs",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/ramonv926/Big-Shot-WL-Token/main/wlToken.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2275,
  },
  {
    chainId: 101,
    name: "DAHS Whitelist",
    symbol: "WLDAHS",
    address: "DAHSwp5jFX9fzgHLWCxXZAjkqm3w4guTkWKA9rNJHx1q",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAHSwp5jFX9fzgHLWCxXZAjkqm3w4guTkWKA9rNJHx1q/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 5637,
  },
  {
    chainId: 101,
    name: "Whales Nation Token",
    symbol: "PLKTN",
    address: "9N6pAzykk711HvBMsBkZ3KDsQMAo5oMrqrj7VpuwwmQT",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Ishi727/WN_Whale_House_01/master/Content/Test_01/Whales_Nation_Token_02.png",
    tags: ["social-token"],
    verified: true,
    holders: 417,
  },
  {
    chainId: 101,
    name: "Infinity Girls WL Token",
    symbol: "IG",
    address: "HphmyPf32KgoMaGeEnLrndtcJuejxDMRFj4r1EzNvWSM",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HphmyPf32KgoMaGeEnLrndtcJuejxDMRFj4r1EzNvWSM/logo.png",
    tags: ["social-token", "whitelist-token"],
    verified: true,
    holders: 611,
  },
  {
    chainId: 101,
    name: "Panthers in the Metaverse - MEAT Token",
    symbol: "MEAT",
    address: "HQaECmN3d5tKMf94jFPquWLr41fRdfrfWEHs8fazqf7A",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LinYu1992/pitm-meat-token/main/meat.png",
    tags: ["utility-token"],
    verified: true,
    holders: 320,
  },
  {
    chainId: 101,
    name: "Nova Pass",
    symbol: "NOVAWL",
    address: "TodKG4UuPVbuRmQg4byCDuTVq4kuFBXp4oPE66okeB1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TodKG4UuPVbuRmQg4byCDuTVq4kuFBXp4oPE66okeB1/novapass.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 7768,
  },
  {
    chainId: 101,
    name: "GOG Token",
    symbol: "GOG",
    address: "GoGwgMnVsRqLUf2vPKxTG6DSyRRSrZCEKQBUCFDUFneU",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoGwgMnVsRqLUf2vPKxTG6DSyRRSrZCEKQBUCFDUFneU/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 338,
  },
  {
    chainId: 101,
    name: "PROJECT SKU11 Platinum Whitelist Token",
    symbol: "PLATSKU11",
    address: "9tshp8vPyZZW3wnQmNCDRF5vMggeGSWY291pR7W45KcR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9tshp8vPyZZW3wnQmNCDRF5vMggeGSWY291pR7W45KcR/logo.png",
    tags: ["wl-token"],
    verified: true,
    holders: 198,
  },
  {
    chainId: 101,
    name: "Degenz Token",
    symbol: "DGNZ",
    address: "2hnR5wSf2FQmXdX8titYFpHxDBie6chcm2D2wZz2mJb8",
    decimals: 0,
    logoURI: "https://raw.githubusercontent.com/mr365k/DGNZ/main/dgnztoken.png",
    tags: ["social-token"],
    verified: true,
    holders: 119,
  },
  {
    chainId: 101,
    name: "Degen Islands NUGGET Token",
    symbol: "NUGGET",
    address: "BBZbqM7RPKzncqaC26gtq6Z4dkm5ksZXib4nJYjk8L3X",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BBZbqM7RPKzncqaC26gtq6Z4dkm5ksZXib4nJYjk8L3X/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1003,
  },
  {
    chainId: 101,
    name: "Puppy Bullies WL Token",
    symbol: "PBWL",
    address: "HudUgoEa986hRAgJWPj3cB1c9WoXEmBVw7gwBKTMwikd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/MetaBully/puppy-bullies/main/logo.png",
    tags: [],
    verified: true,
    holders: 221,
  },
  {
    chainId: 101,
    name: "Penguin BUD_PAI LP",
    symbol: "BUD_PAI",
    address: "GQzf8qF7sG1eLyYqk7scFy8HfShhyfqZa4NVwRrDrGcY",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQzf8qF7sG1eLyYqk7scFy8HfShhyfqZa4NVwRrDrGcY/logo.png",
    tags: [],
    verified: true,
    holders: 150,
  },
  {
    chainId: 101,
    name: "The Big Five",
    symbol: "TBF",
    address: "2ZamLCGLPSpP2MRbeM2wXRWzTEDhr669cFycVWgzBixi",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/thebigfivenft/asset/main/the-big-fiv.png",
    tags: [],
    verified: true,
    holders: 1821,
  },
  {
    chainId: 101,
    name: "Doge Track",
    symbol: "DTRK",
    address: "DTRK1XRNaL6CxfFWwVLZMxyhiCZnwdP32CgzVDXWy5Td",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTRK1XRNaL6CxfFWwVLZMxyhiCZnwdP32CgzVDXWy5Td/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1084,
  },
  {
    chainId: 101,
    name: "Thank You Token",
    symbol: "ThxU",
    address: "jbyi8caTyxtzw6vPRpZCwRv9k1rZNfxTK9yRDKFgj7P",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/jbyi8caTyxtzw6vPRpZCwRv9k1rZNfxTK9yRDKFgj7P/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 2045,
  },
  {
    chainId: 101,
    name: "Gooney Toons",
    symbol: "NASTY",
    address: "NASTYAhPiC1LnBr8dikyd9KWBMhrzhQQ5Ro8TD1tqf7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NASTYAhPiC1LnBr8dikyd9KWBMhrzhQQ5Ro8TD1tqf7/logo.png",
    tags: [],
    verified: true,
    holders: 1177,
  },
  {
    chainId: 101,
    name: "Zorg app",
    symbol: "ZORG",
    address: "7MKpy8PeNjQM3i4xWzGiZjDd97mq6m4QH6Q8jrXnsQ9L",
    decimals: 9,
    logoURI: "https://i.ibb.co/ySjGr4K/zorg-removebg.png",
    tags: ["utility-token"],
    verified: true,
    holders: 8911,
  },
  {
    chainId: 101,
    name: "Eternium",
    symbol: "ETERNIUM",
    address: "ETER9jTizmKFKLtW55jMd9MaBWhR2UZpUx48yR3Jixrx",
    decimals: 9,
    logoURI: "https://cdn.eternaldragons.com/assets/eternium.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1018,
  },
  {
    chainId: 101,
    name: "SOLhouse Match Bet",
    symbol: "SHMB",
    address: "Hie7HKx2JDEnQg8Vo4zBAaYrZiWhgrgNDo7m1X4k99qi",
    decimals: 9,
    logoURI:
      "https://cdn.discordapp.com/attachments/939045807359918090/953961775928340521/solhouse_logo_500.png",
    tags: ["utility-token"],
    verified: true,
    holders: 797,
  },
  {
    chainId: 101,
    name: "Acacia",
    symbol: "ACA",
    address: "8BLiujyxu5gJajWBXoZQkwSsamdeHNKWQbu1ApAao8Ps",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/qubelabsllc/ACALogo/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1786,
  },
  {
    chainId: 101,
    name: "CHATTABOXES WL Token",
    symbol: "CTBX",
    address: "ToxmH7qHy53ccSACqyyVyzFZGSM3n74YUdYs3wJWTuk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToxmH7qHy53ccSACqyyVyzFZGSM3n74YUdYs3wJWTuk/logo.jpg",
    tags: ["whitelist-token"],
    verified: true,
    holders: 325,
  },
  {
    chainId: 101,
    name: "TMDC SPRAY BOMBS WL",
    symbol: "TMDCSB",
    address: "WLDWnYmLNcCGV6r6z27QZDEeUaKbCiKuhyaLqm49B8j",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/namu42/ImageRepo/b9306c4130c66abf1fd2d0e2a1ca48c7e487a879/WLDWnYmLNcCGV6r6z27QZDEeUaKbCiKuhyaLqm49B8j/wl_logo.png",
    tags: ["Whitelist-Token", "NFT"],
    verified: true,
    holders: 1665,
  },
  {
    chainId: 101,
    name: "VIC",
    symbol: "VIC",
    address: "9uxWwcEB62YeY3NaLVMUnsh5wD3rDkMZq3qfU5HxTkPV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9uxWwcEB62YeY3NaLVMUnsh5wD3rDkMZq3qfU5HxTkPV/logo.png",
    tags: ["vic", "social-token", "nft-token", "game-token", "jjcverse"],
    verified: true,
    holders: 180,
  },
  {
    chainId: 101,
    name: "Catliens Whitelist Token",
    symbol: "MEOWL",
    address: "CLwUm2ZHJW8DrrDo1ZtprXR5w5Rxndbgc72vysaDVeBj",
    decimals: 0,
    logoURI:
      "https://cdn.discordapp.com/attachments/941805628354093118/954157558724190248/Silver_Platinum_Coin_Queen_Jubilee_Instagram_Post_1.png",
    tags: ["utility-token"],
    verified: true,
    holders: 294,
  },
  {
    chainId: 101,
    name: "SLA Hay Token",
    symbol: "HAY",
    address: "CcaKx6adqp8wDeksiR15x8HMgBzjz8QY6pJCmRZQPXAB",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CcaKx6adqp8wDeksiR15x8HMgBzjz8QY6pJCmRZQPXAB/Hay.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1056,
  },
  {
    chainId: 101,
    name: "DYORNERD WL",
    symbol: "NWL",
    address: "HDutUmTAGspwyw9EqNMgDc8XQVC41iT9Yd3a5JXBa5JY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDutUmTAGspwyw9EqNMgDc8XQVC41iT9Yd3a5JXBa5JY/logo.png",
    tags: [],
    verified: true,
    holders: 7440,
  },
  {
    chainId: 101,
    name: "TACOS",
    symbol: "TACOS",
    address: "3yjCHAThuRTU8vFctU51ept4esSra5aneN9ZqZmQwjWr",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yjCHAThuRTU8vFctU51ept4esSra5aneN9ZqZmQwjWr/logo.png",
    tags: ["social-token", "DeFi", "Latinx", "Metaverse", "Tacos"],
    verified: true,
    holders: 442,
  },
  {
    chainId: 101,
    name: "TOKAMAK ON SOLANA",
    symbol: "TKMK",
    address: "TKMKgSh3aADsmjr4yFWG52tkCQvmDxsQC1he1aBsi65",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TKMKgSh3aADsmjr4yFWG52tkCQvmDxsQC1he1aBsi65/logo.png",
    tags: ["community-token", "nft", "dao"],
    verified: true,
    holders: 1564,
  },
  {
    chainId: 101,
    name: "Cursed Labs Micro Token",
    symbol: "CLMT",
    address: "2ejweV8NPa1u29cKU8FERiBwCMMNaPxVzhj5Zu9PFK2H",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ejweV8NPa1u29cKU8FERiBwCMMNaPxVzhj5Zu9PFK2H/img.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 3639,
  },
  {
    chainId: 101,
    name: "Cogent Cog Whitelist Token",
    symbol: "CCWL",
    address: "CogFXXLHaTPwZMid55TaRLeC8FXyfVaREbNCoVVMCoin",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CogFXXLHaTPwZMid55TaRLeC8FXyfVaREbNCoVVMCoin/logo.png",
    tags: [],
    verified: true,
    holders: 1491,
  },
  {
    chainId: 101,
    name: "Meka X WL Token",
    symbol: "MKWL",
    address: "MXTzb9Pns2oJNHYcsvKqp9ybZnvF4XZDETzuBnBmitp",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MXTzb9Pns2oJNHYcsvKqp9ybZnvF4XZDETzuBnBmitp/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 4065,
  },
  {
    chainId: 101,
    name: "Wasted Token",
    symbol: "$WLZ",
    address: "WLZna3K4SaDxf7ouEweCpXwEEt7g3qJ9ims1bm4CrYs",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLZna3K4SaDxf7ouEweCpXwEEt7g3qJ9ims1bm4CrYs/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 1004,
  },
  {
    chainId: 101,
    name: "Baby Presale Non-Guarantee Token",
    symbol: "BADRAY",
    address: "6tC7i5aVU2Gw2Bdj3WdC4WKj2DXUt31DdHz3AEbBCxp5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/benpazzo/babydraygon/main/img/presalemint2.png",
    tags: ["social-token"],
    verified: true,
    holders: 2205,
  },
  {
    chainId: 101,
    name: "DeadKnight Token",
    symbol: "DKM",
    address: "HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W/dkmlogo.png",
    tags: ["Utility-Token", "Stake-Token"],
    verified: true,
    holders: 1703,
  },
  {
    chainId: 101,
    name: "Hexagon Coin",
    symbol: "HEX",
    address: "2K56JYgSEMaUvh1RRWGeXJcU44rWGtVwDXzbBHu8sKXD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2K56JYgSEMaUvh1RRWGeXJcU44rWGtVwDXzbBHu8sKXD/hexlogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 355,
  },
  {
    chainId: 101,
    name: "RadRugs",
    symbol: "SAFE",
    address: "E4DRAz5D9iMND9PJ7tq1HQbZPxavDWHxmeizUtk68o8S",
    decimals: 6,
    logoURI: "https://radrugs.io/assets/images/token_logo.png",
    tags: ["utility-token", "stake-token"],
    verified: true,
    holders: 2154,
  },
  {
    chainId: 101,
    name: "sunSBR",
    symbol: "sunSBR",
    address: "sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2/logo.svg",
    tags: [],
    verified: true,
    holders: 1813,
  },
  {
    chainId: 101,
    name: "yiSunSBR",
    symbol: "yiSunSBR",
    address: "sunxSBRH867pEGc5D4UFR7D6RQULsmjkXZT3SQ8XqwS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sunxSBRH867pEGc5D4UFR7D6RQULsmjkXZT3SQ8XqwS/logo.svg",
    tags: [],
    verified: true,
    holders: 3646,
  },
  {
    chainId: 101,
    name: "Bacon SBR (sunSBR Token of Appreciation)",
    symbol: "baconSBR",
    address: "BaconSBRjNckjeQCi6E2Ndg8wSmAwPgmfmZnF6ZmyFhz",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BaconSBRjNckjeQCi6E2Ndg8wSmAwPgmfmZnF6ZmyFhz/logo.svg",
    tags: [],
    verified: true,
    holders: 1859,
  },
  {
    chainId: 101,
    name: "REJECT Token",
    symbol: "REJECT",
    address: "4F2yutcbkabE5MJoDvrDLa5U2re5HPABSCVKA7vqrKcH",
    decimals: 2,
    logoURI:
      "https://ri6fdpqu6asrle3koykcepmdyd6m5eu2sfwb34vjfoxl2cvb.arweave.net/ijxRvhTwJRWTanYUI-j2DwPzOkpqRbB3y-qSuuvQqhQ",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 4043,
  },
  {
    chainId: 101,
    name: "Underworld Cash",
    symbol: "UWC",
    address: "Ga1FE6vviZcUhnYWv1pdQHYt5geVq299hdcjfxigDBcJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ga1FE6vviZcUhnYWv1pdQHYt5geVq299hdcjfxigDBcJ/logo.png",
    tags: ["utility-token", "social-token", "community-token", "nft-token"],
    verified: true,
    holders: 3224,
  },
  {
    chainId: 101,
    name: "Most Hyped Crypto",
    symbol: "MHC",
    address: "52Y1RGnRFvRFUQq5r2AWpFLLFvokiiqxRocsWVpmPTU4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/52Y1RGnRFvRFUQq5r2AWpFLLFvokiiqxRocsWVpmPTU4/logo.png",
    tags: ["governing-token"],
    verified: true,
    holders: 307,
  },
  {
    chainId: 101,
    name: "Dark Tome",
    symbol: "DTome",
    address: "D8VVNvc6EmjHLJE1vgzZSyMEAH6CZvD3wVaeY6RZHuj8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/onuraladi/DarkTome/main/darktome.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 1163,
  },
  {
    chainId: 101,
    name: "Vex Cheetahs WL Token",
    symbol: "VEXWL",
    address: "8btg1akyvVNqkDjftALYMRDqG9QfWvS7sy96KpLAMZny",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/rabindrarajawat/arr-solana/main/vex.png",
    tags: ["nft"],
    verified: true,
    holders: 1359,
  },
  {
    chainId: 101,
    name: "WL (Solana Devices)",
    symbol: "SDEVWL",
    address: "5bFvtLN3kP34sas77XvWgoY4b5CFjQ7qAUJsKUSomLUJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bFvtLN3kP34sas77XvWgoY4b5CFjQ7qAUJsKUSomLUJ/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 122,
  },
  {
    chainId: 101,
    name: "SolanaPrime Index Token",
    symbol: "SPIX",
    address: "SPiXWTmxrrUAe8ukZ79Bz9zjY5L8kR1bQ4S51M4C83V",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPiXWTmxrrUAe8ukZ79Bz9zjY5L8kR1bQ4S51M4C83V/logo.png",
    tags: ["launchpad", "ido", "rewards", "index-pool"],
    verified: true,
    holders: 362,
  },
  {
    chainId: 101,
    name: "NOVAX",
    symbol: "NOVAX",
    address: "NovNrxPNjmLVFscH5rjMbec7C4BdAHms9WK21xjsP3p",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NovNrxPNjmLVFscH5rjMbec7C4BdAHms9WK21xjsP3p/clogo.png",
    tags: [],
    verified: true,
    holders: 3689,
  },
  {
    chainId: 101,
    name: "Star Chain",
    symbol: "STAR",
    address: "EXGqHqvKBs4Z1mCwhiGE7kT2TXGFirAjvQzPSQP8nvuw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EXGqHqvKBs4Z1mCwhiGE7kT2TXGFirAjvQzPSQP8nvuw/logo.png",
    tags: [],
    verified: true,
    holders: 17318,
  },
  {
    chainId: 101,
    name: "QUACKOIN",
    symbol: "QUACK",
    address: "ENNuzbdv7MiT9fjg7ApC2Fyf2F67PWww5Trm6A7W1BSn",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENNuzbdv7MiT9fjg7ApC2Fyf2F67PWww5Trm6A7W1BSn/logo.png",
    tags: ["community", "token"],
    verified: true,
    holders: 400,
  },
  {
    chainId: 101,
    name: "Jacobs",
    symbol: "JCBS",
    address: "JCBSxd9TAecuG6JN68kSFgvUoLLWqpXaJH8VqFXrvjtj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JCBSxd9TAecuG6JN68kSFgvUoLLWqpXaJH8VqFXrvjtj/logo.png",
    tags: [],
    verified: true,
    holders: 344,
  },
  {
    chainId: 101,
    name: "Metawares WL Token",
    symbol: "METAWL",
    address: "FZiMRKG25BUgZQjgzizy775duZLKiEoPhsyYQaktvEed",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZiMRKG25BUgZQjgzizy775duZLKiEoPhsyYQaktvEed/logo.png",
    tags: ["whitelist", "nft"],
    verified: true,
    holders: 141,
  },
  {
    chainId: 101,
    name: "Doomed Free Mint",
    symbol: "DAFM",
    address: "FCP14kyGeg9BYfBUZFtcEAt5sLe7BfyjLvBoqnL2WcQV",
    decimals: 0,
    logoURI: "https://mint.thearmadillos.io/da-freemint-token.jpg",
    tags: ["whitelist", "nft"],
    verified: true,
    holders: 608,
  },
  {
    chainId: 101,
    name: "Doomed WL Token",
    symbol: "DWLT",
    address: "PSPdfYZVDrrYaWh5F7XDdQPeMPsLpisnE5VD4E3JGtp",
    decimals: 0,
    logoURI: "https://mint.thearmadillos.io/da-presale-token.jpg",
    tags: ["whitelist", "nft"],
    verified: true,
    holders: 2124,
  },
  {
    chainId: 101,
    name: "Pixxel Parrots Whitelist Token",
    symbol: "PPWLT",
    address: "PPTfK8sQwH5485Fvjg5edAFG3aoQdEhxaTe9kthhTy6",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PPTfK8sQwH5485Fvjg5edAFG3aoQdEhxaTe9kthhTy6/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 475,
  },
  {
    chainId: 101,
    name: "Solana Hills",
    symbol: "$SHILLS",
    address: "4SsBoLmKGWdVWfuJ5DU1TmM2BxxWBx44eMDuh6nhYgHf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/CMHayden/crypto/master/solanahills.png",
    tags: ["social-token"],
    verified: true,
    holders: 1465,
  },
  {
    chainId: 101,
    name: "Zoker",
    symbol: "ZOKER",
    address: "GdVYQYnYV8zNFuQcMMEb1RQFBR14mdJUVVWHVTzU3AHq",
    decimals: 9,
    logoURI: "https://media.zoker.com/media/logo.png",
    tags: ["game-token", "meta-verse", "nft-token"],
    verified: true,
    holders: 4327,
  },
  {
    chainId: 101,
    name: "Magnum Token",
    symbol: "MAGAI",
    address: "MAGf4MnUUkkAUUdiYbNFcDnE4EBGHJYLk9foJ2ae7BV",
    decimals: 0,
    logoURI:
      "https://github.com/tomekregulski/magnum-token-logo/blob/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 2175,
  },
  {
    chainId: 101,
    name: "High Voltage Worlds Whitelist Token",
    symbol: "HVWWL",
    address: "HVWUENskPh5bYq4Fdpv4hTwkbVbS4XAbCJ49Gev3WYEX",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HVWUENskPh5bYq4Fdpv4hTwkbVbS4XAbCJ49Gev3WYEX/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 1140,
  },
  {
    chainId: 101,
    name: "Moonland Stardust",
    symbol: "MLS",
    address: "GxbSCU2go1MsLPM9ZJFSWuvsYCN4ZBRtCFSmcy5VehmX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GxbSCU2go1MsLPM9ZJFSWuvsYCN4ZBRtCFSmcy5VehmX/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 1019,
  },
  {
    chainId: 101,
    name: "Baked Bots WL Token",
    symbol: "BBW",
    address: "Bots8GcuhxrZ1XBGHx3qvzMEq3xfBZHSCxHX5EukGuis",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bots8GcuhxrZ1XBGHx3qvzMEq3xfBZHSCxHX5EukGuis/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 10063,
  },
  {
    chainId: 101,
    name: "ELIXIR",
    symbol: "ELIXIR",
    address: "ELXRYrf8wd4DcyXDU9QPnMdD8jn2twg7o7qEtf5z2GBW",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/zaptors/image/main/elixir.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3425,
  },
  {
    chainId: 101,
    name: "Towers Of Astraa Whitelist Token",
    symbol: "TOAWL",
    address: "TWL3qkjRXomK4fFViXA86csuYAfphTQiKCfxXTYjB7K",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TWL3qkjRXomK4fFViXA86csuYAfphTQiKCfxXTYjB7K/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 338,
  },
  {
    chainId: 101,
    name: "Multidimensional Funky Mirian",
    symbol: "FUNK",
    address: "FFTwhd4pEZoGCbocV1PMqkb2MCebskz8MoKiNugyCpAd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFTwhd4pEZoGCbocV1PMqkb2MCebskz8MoKiNugyCpAd/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 386,
  },
  {
    chainId: 101,
    name: "BIG CANDY",
    symbol: "BIGCANDY",
    address: "AW9bgLubnd4jjmfsidHwSJnDutWhZBcj3yYcY4weXvSP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/ramonv926/-BigCandy/main/bigcandy.png",
    tags: ["utility-token", "community-token", "nft-token"],
    verified: true,
    holders: 497,
  },
  {
    chainId: 101,
    name: "Jungle Pass WL Token",
    symbol: "JPWLT",
    address: "J1fuSfLo46UTQbVsySmaCed5YoU8KzpTg9GjZhBQEJAf",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J1fuSfLo46UTQbVsySmaCed5YoU8KzpTg9GjZhBQEJAf/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 262,
  },
  {
    chainId: 101,
    name: "SOLANITE",
    symbol: "SLN",
    address: "GLrFNhFr9kmAi2maE4nPAd3AEc5BPakgDz78ogQKYxEF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLrFNhFr9kmAi2maE4nPAd3AEc5BPakgDz78ogQKYxEF/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 691,
  },
  {
    chainId: 101,
    name: "Wizardia Token",
    symbol: "WZRD",
    address: "GuLCixNS3St1PgBRYesQUX1M7oANL3APK2LFEv1a7dWA",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GuLCixNS3St1PgBRYesQUX1M7oANL3APK2LFEv1a7dWA/logo.png",
    tags: [],
    verified: true,
    holders: 370,
  },
  {
    chainId: 101,
    name: "Baked Bots Official WL Token",
    symbol: "BABO",
    address: "BaKEdMuqQduNkc3enqMfsbQaDnYmWChobiBFciZsQwui",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BaKEdMuqQduNkc3enqMfsbQaDnYmWChobiBFciZsQwui/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 112,
  },
  {
    chainId: 101,
    name: "VIP Access Solana Zoo Mint",
    symbol: "LVIP",
    address: "5bp6Ke3rtTnUJST9n3Ejf5GwysjxC1yA7FzxKoT7AcqE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bp6Ke3rtTnUJST9n3Ejf5GwysjxC1yA7FzxKoT7AcqE/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 257,
  },
  {
    chainId: 101,
    name: "Whitelist Token",
    symbol: "WARI-WL",
    address: "HyUD3Vkfp4zsVXVbAfSrpSZqvEuMysF1zLSFyCcHybkt",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HyUD3Vkfp4zsVXVbAfSrpSZqvEuMysF1zLSFyCcHybkt/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2328,
  },
  {
    chainId: 101,
    name: "Kingdom Pass",
    symbol: "KPM",
    address: "9qcLbAanoAo461yf19epr6GXGyAwHQn8asKGUaoRRB47",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/pass.png",
    tags: ["mint-token"],
    verified: true,
    holders: 467,
  },
  {
    chainId: 101,
    name: "Ducks Token",
    symbol: "DUCKS",
    address: "2UgW5e86z3wHYRT4jnXRLgJheSKM8yCz3sn9rMpbWdCy",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2UgW5e86z3wHYRT4jnXRLgJheSKM8yCz3sn9rMpbWdCy/logo.jpg",
    tags: ["nft-token"],
    verified: true,
    holders: 1905,
  },
  {
    chainId: 101,
    name: "FunGuyz",
    symbol: "FUNZ",
    address: "8zqeAcLQKv9qqovonj9exEei4mUNKhnFUAfAHQhuj7Ea",
    decimals: 9,
    logoURI: "https://arweave.net/LSrYJsws79XdkrVfCkIINQmRUs5mtvnQJ5uj3xWZY60",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 4044,
  },
  {
    chainId: 101,
    name: "Sphinx Lab WL",
    symbol: "SPHXWL",
    address: "SWLXCGbDk7PkwU9b4GzRgQMy1HgPHwcfe2vTWCXRqSy",
    decimals: 0,
    logoURI: "https://github.com/yazidox/SPHXBETAWL/blob/main/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1175,
  },
  {
    chainId: 101,
    name: "Myths of Myrien",
    symbol: "MYRIEN",
    address: "FDqVe2wP8WPJ2aWR1uUXWJsyMeB72mNkvDRjQPbmuyHE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDqVe2wP8WPJ2aWR1uUXWJsyMeB72mNkvDRjQPbmuyHE/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 1285,
  },
  {
    chainId: 101,
    name: "Millionaire Mafia Club Whitelist",
    symbol: "MMC",
    address: "26nPfD9S6Eiqm6f27ob74uQ5u6BDmMWFKbRoMPZKgNjF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/nftmmc/MMCTOKEN/main/logowl.png",
    tags: ["NFT"],
    verified: true,
    holders: 4782,
  },
  {
    chainId: 101,
    name: "Ramen Feast Token",
    symbol: "RAMENF",
    address: "9yM42HMJnN69rhMGr8nCYSRtFxjWTWm5Z6GeucyLBEHg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9yM42HMJnN69rhMGr8nCYSRtFxjWTWm5Z6GeucyLBEHg/logo.png",
    tags: ["utility-token", "nfts"],
    verified: true,
    holders: 2213,
  },
  {
    chainId: 101,
    name: "Nest Token",
    symbol: "NEST",
    address: "MM7s2bggZvq2DBFyBVKBBHb9DYAo3A2WGkP6L5cPzxC",
    decimals: 6,
    logoURI: "https://raw.githubusercontent.com/Beario/crypto/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 568,
  },
  {
    chainId: 101,
    name: "SPP Mint Pass",
    symbol: "SPPWL",
    address: "6DzRG51nRXkD5JmTj7P8ELTwBgExMGUoa9cyvi9vEPUK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/virdagreat147/SPP-Dog/main/kj.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1635,
  },
  {
    chainId: 101,
    name: "3D Apes Club WL Token",
    symbol: "3DACWL",
    address: "3D8mQf27DQbo1GWJJPsXwFas5u4cewKzCNMH5VpUcgFX",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3D8mQf27DQbo1GWJJPsXwFas5u4cewKzCNMH5VpUcgFX/logo.png",
    tags: [],
    verified: true,
    holders: 202,
  },
  {
    chainId: 101,
    name: "Skull Club Whitelist Token",
    symbol: "SKCLWL",
    address: "SC3o3s72Ecpr8Dovt6zzakUZ4ewesAFz8Hyj119hLER",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SC3o3s72Ecpr8Dovt6zzakUZ4ewesAFz8Hyj119hLER/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1877,
  },
  {
    chainId: 101,
    name: "REAP",
    symbol: "REAP",
    address: "2Dm1zu8ERJGBs3NLXt8s8Vor3YHwJye5E2pYhLiMHU4L",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dm1zu8ERJGBs3NLXt8s8Vor3YHwJye5E2pYhLiMHU4L/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 570,
  },
  {
    chainId: 101,
    name: "SMRAI",
    symbol: "SMRAI",
    address: "rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 299,
  },
  {
    chainId: 101,
    name: "XEANU",
    symbol: "XNU",
    address: "EKuYvkDkNxkvGgpnmDJtFyp7bpaeKffMPp5DoTSJpHjs",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKuYvkDkNxkvGgpnmDJtFyp7bpaeKffMPp5DoTSJpHjs/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 210,
  },
  {
    chainId: 101,
    name: "RUGZ",
    symbol: "RUGZ",
    address: "8XUTstViEpLfhxaA88A6oWKraHm8V444bnSq6hm79vYh",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8XUTstViEpLfhxaA88A6oWKraHm8V444bnSq6hm79vYh/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 599,
  },
  {
    chainId: 101,
    name: "HAIR",
    symbol: "HAIR",
    address: "Dk1HXZkE5tUn8EvNeT8GoB1JqcYioFp5LCPxGq8tTa7s",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dk1HXZkE5tUn8EvNeT8GoB1JqcYioFp5LCPxGq8tTa7s/logo.png",
    tags: [],
    verified: true,
    holders: 2718,
  },
  {
    chainId: 101,
    name: "OOU Meta V1 Whitelist Token",
    symbol: "OOUM",
    address: "XeomDrjzsFuJLSSTDzrxbSkfYJHLeLVgmLUgA4cWArx",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/XeomDrjzsFuJLSSTDzrxbSkfYJHLeLVgmLUgA4cWArx/oou.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 391,
  },
  {
    chainId: 101,
    name: "API Coin",
    symbol: "API",
    address: "cPQm9UM1ekMeAgRcYGLfNcBC65SLA8o2BVTaBUA7kTg",
    decimals: 9,
    logoURI: "https://theblockchainapi-website.s3.amazonaws.com/logo512.png",
    tags: ["community-token", "platform"],
    verified: true,
    holders: 298,
  },
  {
    chainId: 101,
    name: "Luffy Inu",
    symbol: "LFI",
    address: "HkCrU2Vk5kGvvPUUR2dirjgAx3TbyCPYXRZbefwzCyCp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HkCrU2Vk5kGvvPUUR2dirjgAx3TbyCPYXRZbefwzCyCp/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2632,
  },
  {
    chainId: 101,
    name: "OddKey V2",
    symbol: "ODKYV2",
    address: "EYV1w4tZDqC7TkBKTmLwx2r5y8no2W6EFnNFmedsugWa",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EYV1w4tZDqC7TkBKTmLwx2r5y8no2W6EFnNFmedsugWa/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2399,
  },
  {
    chainId: 101,
    name: "Lazy Villains WL Token",
    symbol: "LVWL",
    address: "3wZ9hVw26xRkBcDjgNfUGaQgFsRhqVaZh19ZU2uyg6eo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/KingpinFisk/Lazy-Villains-WL-Token/main/logo.png",
    tags: ["Whitelist-Token"],
    verified: true,
    holders: 421,
  },
  {
    chainId: 101,
    name: "ChefCoin",
    symbol: "CHEF",
    address: "7BPCwgL97UMWcSuyUmDdNTzGnDvruyfGKTmUaSbLzohP",
    decimals: 0,
    logoURI: "https://metakitchen.io/static/media/mk.66f4827037442397afe6.jpeg",
    tags: ["utility-token", "game-token", "game-currency", "GameFi", "Gaming"],
    verified: true,
    holders: 140,
  },
  {
    chainId: 101,
    name: "Bitcoin UBI",
    symbol: "BTCUBI",
    address: "HZBxUY5Hu9HNm6HSXrPQ77ABf9ZLqzUigCbTQiarBR2v",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZBxUY5Hu9HNm6HSXrPQ77ABf9ZLqzUigCbTQiarBR2v/robot-logo.jpg",
    tags: [],
    verified: true,
    holders: 1298,
  },
  {
    chainId: 101,
    name: "Blackspot coin",
    symbol: "BKSP",
    address: "27oGLMCDP57DE77rfVfe35oJaAnimFasJs3HyuaDUuuU",
    decimals: 7,
    logoURI:
      "https://raw.githubusercontent.com/BahamaGov/assets/main/gold_PNG11020.png",
    tags: ["community-token"],
    verified: true,
    holders: 154,
  },
  {
    chainId: 101,
    name: "Apes Planet",
    symbol: "ApesPlanet",
    address: "Fuj1gZHrGu7x3W5uab7CrSXuy9cZ3qqc1me5LjGykt4d",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/affankhan43/crypto/master/apes-logo.png",
    tags: ["nft", "metaverse", "P2e", "Gaming"],
    verified: true,
    holders: 3378,
  },
  {
    chainId: 101,
    name: "Project Wisdom",
    symbol: "WIZE",
    address: "7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z/logo.png",
    tags: [],
    verified: true,
    holders: 534,
  },
  {
    chainId: 101,
    name: "Verdant Pass",
    symbol: "VPASS",
    address: "VVKNtVuJJ1fYPAh2tRbZWVssiRVY6Z8hari5DbR9omy",
    decimals: 0,
    logoURI: "https://i.ibb.co/hgQmnRj/icon-fix.png",
    tags: ["wl-token"],
    verified: true,
    holders: 2519,
  },
  {
    chainId: 101,
    name: "AmalThea Protocol",
    symbol: "AML",
    address: "GTFBBcnRCT6Pa7rxDQHyYW4KMMebvkhB17dKBRkcHw78",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GTFBBcnRCT6Pa7rxDQHyYW4KMMebvkhB17dKBRkcHw78/logo.jpg",
    tags: ["Game-Token", "AML"],
    verified: true,
    holders: 165,
  },
  {
    chainId: 101,
    name: "Gangs of New Pork White List Ticket",
    symbol: "GoNPWL",
    address: "7Cn6jmWSDZguFDizQURcLVGJf6Ct7i2RSo5d11A7Egix",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Cn6jmWSDZguFDizQURcLVGJf6Ct7i2RSo5d11A7Egix/logo.png",
    tags: ["utility-token", "whitelist-ticket"],
    verified: true,
    holders: 245,
  },
  {
    chainId: 101,
    name: "GEEXO Whitelist",
    symbol: "GEEXOWL",
    address: "AefuNcVikKudanCHGRB5KkjdUoen9QBL4fFX6q1kGsRe",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AefuNcVikKudanCHGRB5KkjdUoen9QBL4fFX6q1kGsRe/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1196,
  },
  {
    chainId: 101,
    name: "CITRUS Token",
    symbol: "CITRUS",
    address: "HBfHbAuCFCP34rSkPMXEt5UuLf8HH3SbuvH7DPA6aX8Q",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBfHbAuCFCP34rSkPMXEt5UuLf8HH3SbuvH7DPA6aX8Q/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 364,
  },
  {
    chainId: 101,
    name: "DILLO",
    symbol: "DILLO",
    address: "7Nz4gAXydZcWHPN1EBxM1Rqzoaz7btyRVFhLN7PkfoJR",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/JaxSolana/dillo/Logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 597,
  },
  {
    chainId: 101,
    name: "Bonez",
    symbol: "BONEZ",
    address: "6xnUqAfxvDTZepHNWK1NsxXkjhFdTvuaGVXh3z29wRhx",
    decimals: 6,
    logoURI: "https://i.ibb.co/Wk2BhWS/lastskull.png",
    tags: ["utility-token"],
    verified: true,
    holders: 570,
  },
  {
    chainId: 101,
    name: "Lottery",
    symbol: "LOT",
    address: "LoTrcDXVtmu7CPaxnsVifJD8wwcEr4fhxKykrAQDKWE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LoTrcDXVtmu7CPaxnsVifJD8wwcEr4fhxKykrAQDKWE/logo.png",
    tags: ["utility-token", "lottery", "casino"],
    verified: true,
    holders: 19751,
  },
  {
    chainId: 101,
    name: "MOTS Game",
    symbol: "MOTS",
    address: "4hiMPLMDGkCRgKnDsXwhVZPZhESgp34ZZqymVHuBu1z2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hiMPLMDGkCRgKnDsXwhVZPZhESgp34ZZqymVHuBu1z2/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 4821,
  },
  {
    chainId: 101,
    name: "GODz WL Token",
    symbol: "$GODzWL",
    address: "G7JY5Ui9YghpXCjCAh9bEf3QgZ2HKKmys8JggNmhzH6k",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G7JY5Ui9YghpXCjCAh9bEf3QgZ2HKKmys8JggNmhzH6k/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3616,
  },
  {
    chainId: 101,
    name: "Soland Token",
    symbol: "SOLAND",
    address: "CVatR2DSHovFfFvJqeiuhgbjsHD9NpN9iAaqbVEZRxdW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CVatR2DSHovFfFvJqeiuhgbjsHD9NpN9iAaqbVEZRxdW/logo520x520.png",
    tags: ["metaverse-token"],
    verified: true,
    holders: 1878,
  },
  {
    chainId: 101,
    name: "Ratio stable Token",
    symbol: "USDr",
    address: "USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2/logo.png",
    tags: [],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "DIC punch",
    symbol: "DICP",
    address: "6pQnfdrZVm7mASQoRnDfAAi98ngS1eFNij2koW4kUZFS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6pQnfdrZVm7mASQoRnDfAAi98ngS1eFNij2koW4kUZFS/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1645,
  },
  {
    chainId: 101,
    name: "CatchKing Winter Coupon",
    symbol: "CWint",
    address: "CWintzgasUVMK5YVbnwrNjMEV3Uen6rYYqbXNdXxKqnQ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWintzgasUVMK5YVbnwrNjMEV3Uen6rYYqbXNdXxKqnQ/icon.png",
    tags: ["winter-coupon", "game-token", "utility-token"],
    verified: true,
    holders: 1576,
  },
  {
    chainId: 101,
    name: "Glow Coin",
    symbol: "GLOW",
    address: "GCHK2hkKoQBfoiyChty5kddQNGqodwKwxdr6MYaYVmSn",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GCHK2hkKoQBfoiyChty5kddQNGqodwKwxdr6MYaYVmSn/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2032,
  },
  {
    chainId: 101,
    name: "Glow Vault Token",
    symbol: "GVAULT",
    address: "Em3mRfTT8ZXp1U8eGZKCbrHW6ciBJn5UqaWfHemXnbCd",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Em3mRfTT8ZXp1U8eGZKCbrHW6ciBJn5UqaWfHemXnbCd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 336,
  },
  {
    chainId: 101,
    name: "Loot Heroes' Mint Token Closed WL",
    symbol: "CLOSEDLHMINT",
    address: "2KKhXL6Ajkhedp9rRNHXen1CzhZnYfonkBno7iVcHpsD",
    decimals: 0,
    logoURI: "https://lootheroes.io/lhminttokencwl.png",
    tags: [],
    verified: true,
    holders: 1190,
  },
  {
    chainId: 101,
    name: "Loot Heroes' Mint Token Open WL",
    symbol: "OPENLHMINT",
    address: "46G5mPufPTxteTBqr98yjpePtTrSYdF1jjvR71Ju1mM1",
    decimals: 0,
    logoURI: "https://lootheroes.io/lhminttokenowl.png",
    tags: [],
    verified: true,
    holders: 2052,
  },
  {
    chainId: 101,
    name: "FloppyLabs token",
    symbol: "DISK",
    address: "4bwMPPipiPafcxxtSBPMUgCkv8hF4xAyWsUzxr3DLYPZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/floppy-labs/disk-logo/master/disk_logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 472,
  },
  {
    chainId: 101,
    name: "SolCity Coin",
    symbol: "POKER",
    address: "8kub6BkBGf3Xd1GhtYvZZoh8KS88gauMSrSBy6iuDw8A",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kub6BkBGf3Xd1GhtYvZZoh8KS88gauMSrSBy6iuDw8A/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 476,
  },
  {
    chainId: 101,
    name: "SAKE",
    symbol: "$SAKE",
    address: "Ed1934BYVPQQ2KXbzCLxBMtNVZN3SjanTewfR9upbb7L",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ed1934BYVPQQ2KXbzCLxBMtNVZN3SjanTewfR9upbb7L/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 297,
  },
  {
    chainId: 101,
    name: "Wcdolar",
    symbol: "WCDL",
    address: "Gns4DFoM98sib6XJSojU2hm7C6jU9qYWLzYu1Z7HAb8E",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/wcdolar.png",
    tags: ["utility-token"],
    verified: true,
    holders: 332,
  },
  {
    chainId: 101,
    name: "Qorbi Coin",
    symbol: "QORBI",
    address: "7zSTEvkJ21NJzi4FJwVvg3VuSX38B6cWVAzRkDjHbzwJ",
    decimals: 9,
    logoURI: "https://qbaby-web-assets.s3.amazonaws.com/qorbicoin.png",
    tags: ["community-token", "game-token"],
    verified: true,
    holders: 178,
  },
  {
    chainId: 101,
    name: "The Big Five Official WHITELIST Token",
    symbol: "TBFWL",
    address: "Cornwa1ifRukcEgGmipzrcDDqdksQ34hh9Ca4ukn9tet",
    decimals: 0,
    logoURI:
      "https://user-images.githubusercontent.com/89742297/160547178-746b2b27-85ff-4ccc-b766-f34a0e012a9d.jpg",
    tags: ["whitelist-token"],
    verified: true,
    holders: 517,
  },
  {
    chainId: 101,
    name: "DDAC Bread",
    symbol: "BREAD",
    address: "FNpCzhQy5NZgEbmcJLedQebpRKRvw8sBGizhYj1uifGX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/TreeBurgers/DDAC/main/Token.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1027,
  },
  {
    chainId: 101,
    name: "Domination Token",
    symbol: "DOM",
    address: "Domn87XEqaE31Sq2piAGvUxg79vCdp3RPmW2WSQohird",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Domn87XEqaE31Sq2piAGvUxg79vCdp3RPmW2WSQohird/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1966,
  },
  {
    chainId: 101,
    name: "FUNKYCOINWL",
    symbol: "FKCWL",
    address: "7SK32s1JahGje7ETn8WejpByJzoBZfSgeMnQTE6Kzb6u",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SK32s1JahGje7ETn8WejpByJzoBZfSgeMnQTE6Kzb6u/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 302,
  },
  {
    chainId: 101,
    name: "ACES Card Sharks Minting Token",
    symbol: "CARDSHARKSMINT",
    address: "2JBr8X9zABjCbh21t2ZrrWFAT58VRe8jRtaHMPcXqPvo",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2JBr8X9zABjCbh21t2ZrrWFAT58VRe8jRtaHMPcXqPvo/logo.png",
    tags: [],
    verified: true,
    holders: 8627,
  },
  {
    chainId: 101,
    name: "Atlaria Mint Token",
    symbol: "ATMT",
    address: "ATTF58JfFUHZktZXyV6aCTwHVCYzPuZBg2hma7J7Ao7n",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATTF58JfFUHZktZXyV6aCTwHVCYzPuZBg2hma7J7Ao7n/logo.png",
    tags: ["NFT"],
    verified: true,
    holders: 164,
  },
  {
    chainId: 101,
    name: "Goofiez Mint Token",
    symbol: "GOMT",
    address: "GFTYCJzCuQXuzPoZCbVkqVtFBwDdRhQHEe2w6QA36z4Y",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFTYCJzCuQXuzPoZCbVkqVtFBwDdRhQHEe2w6QA36z4Y/logo.png",
    tags: ["NFT"],
    verified: true,
    holders: 1577,
  },
  {
    chainId: 101,
    name: "Dope Pirates RUM",
    symbol: "RUM",
    address: "sFA2de5kRsAmCev2WAoPCXbCKEd1ZwkvJ3MxPMojw9h",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sFA2de5kRsAmCev2WAoPCXbCKEd1ZwkvJ3MxPMojw9h/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1015,
  },
  {
    chainId: 101,
    name: "NovaMindNFT",
    symbol: "NovaMindNFT",
    address: "NovaAMdJA1MCNWG3TiY8C37ezHd8GEpLVE4mTGXjetQ",
    decimals: 0,
    logoURI:
      "https://cdn.discordapp.com/attachments/915400341992923246/957740284249264189/Screen.png",
    tags: [],
    verified: true,
    holders: 314,
  },
  {
    chainId: 101,
    name: "Space Stone",
    symbol: "SS",
    address: "9THzNrZiF3XNsuuqb9T8gsCGaYnek2Lsg5YKdqMnf8TW",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/9THzNrZiF3XNsuuqb9T8gsCGaYnek2Lsg5YKdqMnf8TW/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 536,
  },
  {
    chainId: 101,
    name: "Mongo",
    symbol: "MONGO",
    address: "mongopjRpUgnQQpQFiasgFLyo69YXUwFcw3hyqaN8RL",
    decimals: 9,
    logoURI: "https://mongomons.com/logo-MONGO.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3009,
  },
  {
    chainId: 101,
    name: "Infomo Token",
    symbol: "INFOMO",
    address: "GMeqxdPtZvwj9rmrCLqdB2MAeJXjmRPnRrgpDLpVqKCh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GMeqxdPtZvwj9rmrCLqdB2MAeJXjmRPnRrgpDLpVqKCh/logo.png",
    tags: [],
    verified: true,
    holders: 570,
  },
  {
    chainId: 101,
    name: "Skullbots",
    symbol: "SUMMON",
    address: "skuLLY6NHFrjL3oGpWMrrwjCfWMSvwp6AD7resMF9m7",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Neurologist/assets/main/summon.png",
    tags: [],
    verified: true,
    holders: 573,
  },
  {
    chainId: 101,
    name: "Caffeine",
    symbol: "CAF",
    address: "CAFTP2Yof8bJuwSScigqnZaLQKiBzECgJPxvEDzfivzw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAFTP2Yof8bJuwSScigqnZaLQKiBzECgJPxvEDzfivzw/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 1363,
  },
  {
    chainId: 101,
    name: "IDOLZ Token",
    symbol: "IDOLZ",
    address: "idoLztG5ZGMVEjjoQWjeSu2eVkVsp3YnrFZKf7dNi4j",
    decimals: 9,
    logoURI:
      "https://arweave.net/mdvmPYEgiX_uPGRtiIZA3HrpilDK5JfW2bXkDBRuzVM?ext=png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 2288,
  },
  {
    chainId: 101,
    name: "Blue Chips Ninjas Token",
    symbol: "NINJAS",
    address: "4KwJBSGtpoxGY8pUUEsjpModoYgRGgaqNEZHrd11DzsV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4KwJBSGtpoxGY8pUUEsjpModoYgRGgaqNEZHrd11DzsV/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 430,
  },
  {
    chainId: 101,
    name: "Balance Token",
    symbol: "BALANCE",
    address: "DgT6BQqmmzaaHo7KjoxdswpoC6CoTBNcyD9q3jywEa8w",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/livebyv/token-art/main/balance/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 486,
  },
  {
    chainId: 101,
    name: "Zuki",
    symbol: "ZUKI",
    address: "BA7khZ2ai6zR8kh3eH996HYk2BkLMo2hwCe7p5nps1qW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/SnowballedNoot/SPLAkatzuki/main/tokenlogo520.png",
    tags: ["utility-token", "social-token", "community-token"],
    verified: true,
    holders: 113,
  },
  {
    chainId: 101,
    name: "SolCity Poker Club Phase 3",
    symbol: "SOLCITYPH3",
    address: "8UCRqj24VEtwsqCm4k2UHsp8STy2yUqRg6xD4p6g55p6",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UCRqj24VEtwsqCm4k2UHsp8STy2yUqRg6xD4p6g55p6/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 590,
  },
  {
    chainId: 101,
    name: "EGG",
    symbol: "$EGG",
    address: "3rkCq2ZAxoDGa3KWGebeiEMN92H5AV9HBLC9eVKDoPv8",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/IamDtosh/egg/main/egg.png",
    tags: ["defi", "general", "nft"],
    verified: true,
    holders: 871,
  },
  {
    chainId: 101,
    name: "Mikoverse Token",
    symbol: "MIKO",
    address: "DcJTzgLtV2Y2b6HQFTkSv3Wsck5uqXCGrAKVa4JiV5zZ",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcJTzgLtV2Y2b6HQFTkSv3Wsck5uqXCGrAKVa4JiV5zZ/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 577,
  },
  {
    chainId: 101,
    name: "Staked MEAN",
    symbol: "sMEAN",
    address: "sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z/logo.svg",
    tags: ["stake"],
    verified: true,
    holders: 1105,
  },
  {
    chainId: 101,
    name: "FEED Token on Alien Chicken Farm",
    symbol: "FEED",
    address: "3LDAW7enNUZ4DjE1jCi1cDpXvXLrJ1rPiECPbcHpMgG2",
    decimals: 2,
    logoURI: "https://shop.alienchickenfarm.com/logos/feed-token.png",
    tags: ["social-token"],
    verified: true,
    holders: 1270,
  },
  {
    chainId: 101,
    name: "NeonGame Credits",
    symbol: "$NEON",
    address: "DuSyBCGuhPvyGu6cSvbZonvQvh8JLyGvXJn1TmkJh6Zn",
    decimals: 0,
    logoURI: "https://arweave.net/VPS4BrOgXT4rg-mwGfCLqXL8vtbII0ianMBsw_L2CeE",
    tags: ["utility-token"],
    verified: true,
    holders: 979,
  },
  {
    chainId: 101,
    name: "Pup Filthy Token",
    symbol: "DIRT",
    address: "5iet2XLprsR1ACxC46p6RzPbhu5u7Kcw6gHTYbaLnUWo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5iet2XLprsR1ACxC46p6RzPbhu5u7Kcw6gHTYbaLnUWo/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 276,
  },
  {
    chainId: 101,
    name: "MekaX Token",
    symbol: "MKX",
    address: "FpNYrNZeNyskdcLK1saZW4iiGTfAE3BbDBv2tCGvAgjD",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/102857757/161379695-491afb4f-d004-4902-b422-d930c4e1ccb1.png",
    tags: ["NFT"],
    verified: true,
    holders: 228,
  },
  {
    chainId: 101,
    name: "HELLIEZ DEATHLIST (WL)",
    symbol: "HELLIEZ",
    address: "HELZsSZmdzjsh4yP36cxG9R1z9fenennZtP35FbV6EdF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HELZsSZmdzjsh4yP36cxG9R1z9fenennZtP35FbV6EdF/logo.png",
    tags: [],
    verified: true,
    holders: 377,
  },
  {
    chainId: 101,
    name: "MINT PASS WL",
    symbol: "WLSEED",
    address: "sEEDMvgbMAxXmXLDaYWrT8bhCbjrWSMs2EehJbQsopJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Neurologist/assets/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 589,
  },
  {
    chainId: 101,
    name: "Wintoken",
    symbol: "WINTO",
    address: "4kHnCyYn4D6kquPMrQSZTHrVPuUjRom6CvffRr8i5MBG",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kHnCyYn4D6kquPMrQSZTHrVPuUjRom6CvffRr8i5MBG/logo.png",
    tags: ["utility-token", "nft"],
    verified: true,
    holders: 199,
  },
  {
    chainId: 101,
    name: "LABS",
    symbol: "LABS",
    address: "LABSwpcfDjvRRMmEs87Y9yrj4pS9eofVS6cSbJm2zCW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LABSwpcfDjvRRMmEs87Y9yrj4pS9eofVS6cSbJm2zCW/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 1353,
  },
  {
    chainId: 101,
    name: "Toad",
    symbol: "TOAD",
    address: "toaduF8Ay7udXP77Hi1EysXsRYuutL35vZ6wqMBtVvD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/toaduF8Ay7udXP77Hi1EysXsRYuutL35vZ6wqMBtVvD/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 138,
  },
  {
    chainId: 101,
    name: "CryptoGraf Canvas II",
    symbol: "CNVS",
    address: "EFjGYw3f9VrkqDXWKo3dPCaiobDnWRcCmgEtKUQrNoUo",
    decimals: 3,
    logoURI: "https://arweave.net/D6TOE8xwzJ4aTQMUbgatYednNR6cY5HEiHD5VNync7Q",
    tags: ["fractionalized-nft"],
    verified: true,
    holders: 1803,
  },
  {
    chainId: 101,
    name: "Meta Ape Gang Token",
    symbol: "MAG",
    address: "FbNGhqrw9Wm1y2mdkBRuhXkBfrh6fTd1gXS7d4oov88b",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/metaape.jpeg",
    tags: [],
    verified: true,
    holders: 1359,
  },
  {
    chainId: 101,
    name: "Happy Hippos",
    symbol: "HIPPO",
    address: "2377XYwns6GBJziPbBBg9EzJWq9TPiunZPiuKB4DdQCR",
    decimals: 9,
    logoURI:
      "https://static.wixstatic.com/media/628d2a_d22ee80f7d574e39b9ae672911d41f7d~mv2.gif",
    tags: ["utility-token", "NFT", "social-token"],
    verified: true,
    holders: 291,
  },
  {
    chainId: 101,
    name: "OOINK",
    symbol: "OOINK",
    address: "KRTapyUMe5fW92KZkYoXToFtc6Cn7UG6seaKz646oGu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/oink.png",
    tags: ["utility-token"],
    verified: true,
    holders: 437,
  },
  {
    chainId: 101,
    name: "THE VAULT-X DAO",
    symbol: "VAULT",
    address: "2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj/logo.png",
    tags: ["NFT", "GOVERNANCE", "ESCROW", "PROTECTION-PROTOCOL"],
    verified: true,
    holders: 3155,
  },
  {
    chainId: 101,
    name: "Platinum Token",
    symbol: "PT",
    address: "MHMFaJCu8wWabFghBV759ZaKqhCaqJ1jWpxFzbgmUia",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MHMFaJCu8wWabFghBV759ZaKqhCaqJ1jWpxFzbgmUia/PtToken.png",
    tags: ["utility-token"],
    verified: true,
    holders: 125,
  },
  {
    chainId: 101,
    name: "MOSHI",
    symbol: "MOSHI",
    address: "MoshMwLkVu4iwrPBaWpYkh43qJiSXsnyzNLuMXFv5F4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MoshMwLkVu4iwrPBaWpYkh43qJiSXsnyzNLuMXFv5F4/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1045,
  },
  {
    chainId: 101,
    name: "XLVA",
    symbol: "XLVA",
    address: "xLvawiUDbXmaLdNi71s4Ry8y4ykXJetwZ9LZ124jMpi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xLvawiUDbXmaLdNi71s4Ry8y4ykXJetwZ9LZ124jMpi/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1255,
  },
  {
    chainId: 101,
    name: "ANA",
    symbol: "ANA",
    address: "ANAxByE6G2WjFp7A4NqtWYXb3mgruyzZYg3spfxe6Lbo",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANAxByE6G2WjFp7A4NqtWYXb3mgruyzZYg3spfxe6Lbo/logo.png",
    tags: ["currency"],
    verified: true,
    holders: 5443,
  },
  {
    chainId: 101,
    name: "CrazyHuskies Token",
    symbol: "RUFF",
    address: "4gC2RFo69c8oGqzqAcrhtQgJCu13EEMWUVtkpVbDjQx2",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/DEAN3lr/Crazyhuskies/main/pixil-frame-0%20(4).png",
    tags: ["utility-token"],
    verified: true,
    holders: 202,
  },
  {
    chainId: 101,
    name: "FELLOW",
    symbol: "FELLOW",
    address: "FLLW9PtziXxtin4bvcVKbw1roFPENi5HPADzXdcjGbY1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLLW9PtziXxtin4bvcVKbw1roFPENi5HPADzXdcjGbY1/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 3484,
  },
  {
    chainId: 101,
    name: "The Lost Legion WL Token",
    symbol: "TLLWL",
    address: "qRvWKRP2hdbVi8aCw5f9V2JarxtV6W51dVnm1tyXWXk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qRvWKRP2hdbVi8aCw5f9V2JarxtV6W51dVnm1tyXWXk/Logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 2073,
  },
  {
    chainId: 101,
    name: "Dropout WL",
    symbol: "DUWL",
    address: "FFAfrApKHskMMsnHs8P8WGqDweXCf2AbeMVjz9vbVY4C",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFAfrApKHskMMsnHs8P8WGqDweXCf2AbeMVjz9vbVY4C/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1680,
  },
  {
    chainId: 101,
    name: "NCC-GEN1-WL",
    symbol: "NCC-WL",
    address: "Frb3DbBKeja7SwhevYaK7x6zxJRnjH6KF6ciVHBSoNu5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Frb3DbBKeja7SwhevYaK7x6zxJRnjH6KF6ciVHBSoNu5/NCCWL.png",
    tags: ["utility-token"],
    verified: true,
    holders: 376,
  },
  {
    chainId: 101,
    name: "Cartoon Cartel Token",
    symbol: "CARTEL",
    address: "DfgYfvfW8cWumofEgRZsAYHhZVDgQbu9z8sGwcKahSho",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/ArthurPaivaT/token-list/main/assets/mainnet/DfgYfvfW8cWumofEgRZsAYHhZVDgQbu9z8sGwcKahSho/logo.png",
    tags: [],
    verified: true,
    holders: 853,
  },
  {
    chainId: 101,
    name: "Silver box whitelist",
    symbol: "SBOX",
    address: "4eFU1TAigNZtv4nyDcAsUTZfUUgK6bTdpFSQEgn2H2u6",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/JohnnyGoodpick/my-token-repo/main/boxnfttoken.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 1337,
  },
  {
    chainId: 101,
    name: "Hope Blooms",
    symbol: "HBLM",
    address: "DwC83VkKYbqChRtxr14apQtQGYeuoicuvbqCirxyY8kQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/matt-cooper-github/hope-blooms/main/Hope%20Blooms%20Icon.png",
    tags: ["social-token"],
    verified: true,
    holders: 287,
  },
  {
    chainId: 101,
    name: "DUBBIEZZ",
    symbol: "DUBZ",
    address: "9C2Ht3Cd8uNKsxiLQ27qxv9mnQ1iqMPFW2FUwB9DkxFP",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/103150881/162079915-03e783ea-6fd3-4572-893e-30a55cd7f05a.png",
    tags: ["utility-token"],
    verified: true,
    holders: 465,
  },
  {
    chainId: 101,
    name: "FADE",
    symbol: "FADE",
    address: "FDWmQxD9hQruYKtFK8wt6UhKGv6frPvU8EhjKiEPQoyD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/yazidox/fadedfoxlogo/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2090,
  },
  {
    chainId: 101,
    name: "TMU V1 Whitelist",
    symbol: "TMUWL",
    address: "6HUvsVDECatJaAiQ7bY26TUKaDoyekuovJ1j1oyvRYQS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HUvsVDECatJaAiQ7bY26TUKaDoyekuovJ1j1oyvRYQS/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 258,
  },
  {
    chainId: 101,
    name: "ROUNDIE Coin",
    symbol: "ROUNDIE",
    address: "DKzkt1r6QctnQFx5hMnGomcMimXqVfAyBhMcoKHcBiNK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DKzkt1r6QctnQFx5hMnGomcMimXqVfAyBhMcoKHcBiNK/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 252,
  },
  {
    chainId: 101,
    name: "Verb",
    symbol: "VERB",
    address: "2hyRbCMr8ZuYN99MnY1PKRCk55stWw1zYHnChTSjFM44",
    decimals: 6,
    logoURI: "https://i.ibb.co/H7BB1VL/VERB-Logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 185,
  },
  {
    chainId: 101,
    name: "Mighty Token",
    symbol: "MTKN",
    address: "2dfUDFaYGy7Kkp2TiUXHsY9myq2Ws9hjSyb5pPDRVEzY",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2dfUDFaYGy7Kkp2TiUXHsY9myq2Ws9hjSyb5pPDRVEzY/logo.png",
    tags: ["Gaming", "NFT"],
    verified: true,
    holders: 304,
  },
  {
    chainId: 101,
    name: "Uhu Token",
    symbol: "UHU",
    address: "EvLyT6PC2UyRUZyhMjvZy1eey6E5jrYqq8fqqvSxD12K",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EvLyT6PC2UyRUZyhMjvZy1eey6E5jrYqq8fqqvSxD12K/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 218,
  },
  {
    chainId: 101,
    name: "LADA Token",
    symbol: "LADA",
    address: "95bzgMCtKw2dwaWufV9iZyu64DQo1eqw6QWnFMUSnsuF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95bzgMCtKw2dwaWufV9iZyu64DQo1eqw6QWnFMUSnsuF/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2533,
  },
  {
    chainId: 101,
    name: "Rug Pass WL",
    symbol: "RugWL",
    address: "DigVMJmcV9pozuQBkdYkzGvW293DXbFVMVAWLz1Fhmkd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DigVMJmcV9pozuQBkdYkzGvW293DXbFVMVAWLz1Fhmkd/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 219,
  },
  {
    chainId: 101,
    name: "Wulfs Frens Club Whitelist Token",
    symbol: "WFCWT",
    address: "A6n5SnM2G8ZsTdpd85bUn5byvFAcEfqYHcMvQNNZ8tTR",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6n5SnM2G8ZsTdpd85bUn5byvFAcEfqYHcMvQNNZ8tTR/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3250,
  },
  {
    chainId: 101,
    name: "TSS WL",
    symbol: "TSSWL",
    address: "MkM7taZ6sV5UenNX1HcKFJifr4iN4vWwSevdXecrzBj",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MkM7taZ6sV5UenNX1HcKFJifr4iN4vWwSevdXecrzBj/logo512.png",
    tags: ["utility-token"],
    verified: true,
    holders: 444,
  },
  {
    chainId: 101,
    name: "NIRV",
    symbol: "NIRV",
    address: "NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa/NIRV.png",
    tags: ["currency"],
    verified: true,
    holders: 3876,
  },
  {
    chainId: 101,
    name: "GFT Goofiez Token",
    symbol: "GFT",
    address: "3HDyDDvRTmSMa5QxhUod5gEgVKmTujncwKomxrWxSb8j",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HDyDDvRTmSMa5QxhUod5gEgVKmTujncwKomxrWxSb8j/logo.png",
    tags: ["GFT", "Goofiez"],
    verified: true,
    holders: 132,
  },
  {
    chainId: 101,
    name: "KUSH COIN",
    symbol: "$KUSH",
    address: "KUSHs1xRrv7U3jMUd8g3LjrEc4ZhUx9EBm7Yc6cgCsL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUSHs1xRrv7U3jMUd8g3LjrEc4ZhUx9EBm7Yc6cgCsL/logo.png",
    tags: [],
    verified: true,
    holders: 253,
  },
  {
    chainId: 101,
    name: "NeoNomad",
    symbol: "NNI",
    address: "buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW/logo.png",
    tags: ["utility-token", "nft-token"],
    verified: true,
    holders: 789,
  },
  {
    chainId: 101,
    name: "UNKN",
    symbol: "UNKN",
    address: "unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 393,
  },
  {
    chainId: 101,
    name: "Voltage Token",
    symbol: "VOLTAGE",
    address: "2g6H7aqMxsETTUoC5acFYMNX2Zk3y4ahQHzvU5spKavC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2g6H7aqMxsETTUoC5acFYMNX2Zk3y4ahQHzvU5spKavC/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 405,
  },
  {
    chainId: 101,
    name: "777CTZNS WL TOKEN",
    symbol: "CTZNS",
    address: "8J7Cir9SmRiRQfkN6QoE1kL354i3uNoyQkngqkg18i79",
    decimals: 0,
    logoURI: "https://777ctzns.com/777.png",
    tags: ["WL-token"],
    verified: true,
    holders: 373,
  },
  {
    chainId: 101,
    name: "Women Out Of Tribe",
    symbol: "WOOT",
    address: "EA9GBGUxJoNfHLZ5qzLtG6553EmXM5NgsyrMXfZcfQQE",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Awes0me0/WomenOutOfTribe/main/!OG.png",
    tags: ["whitelist"],
    verified: true,
    holders: 657,
  },
  {
    chainId: 101,
    name: "Arenum",
    symbol: "ARNM",
    address: "3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD/logo.png",
    tags: ["game-token"],
    verified: true,
    holders: 8699,
  },
  {
    chainId: 101,
    name: "Dinodawg Bone",
    symbol: "$BONE",
    address: "BoNE4kp9QQ6mBDPBz1RWPdZFwSevjiGJDBTZQM94fFLb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/ddkb/ddkb-icon.png",
    tags: ["NFT"],
    verified: true,
    holders: 1945,
  },
  {
    chainId: 101,
    name: "TombStoned JOINTS",
    symbol: "JOINTS",
    address: "7ftKSttU6yUAnWsWxpRP3LKdQNEto8V4KD9NuWttoVnV",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ftKSttU6yUAnWsWxpRP3LKdQNEto8V4KD9NuWttoVnV/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 11762,
  },
  {
    chainId: 101,
    name: "World of Wolves",
    symbol: "WOW",
    address: "12Mzc4wnxW1t27M1uJ5F8fj4V7FK921gsGG7WsBcnfSX",
    decimals: 9,
    logoURI:
      "https://bafybeiav3sa2tcy4wmnju5hf7r3nskovfffqb5ffz4g4pshuwuwkpabmsu.ipfs.dweb.link/?ext=png",
    tags: ["utility-token"],
    verified: true,
    holders: 496,
  },
  {
    chainId: 101,
    name: "MarmosetNFT",
    symbol: "MARMO",
    address: "HV8RWueWwpRue86SMzXQapxDB5ZEWw5YnVbxuDcihaF5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/MarmosetNFT/MARMO/main/mainnet/asset/logo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 149,
  },
  {
    chainId: 101,
    name: "Apes of the Gods Whitelist Token",
    symbol: "AOTGWL",
    address: "AGTWeVBaAsUqak2nX8L4auTNEkDXbfVRsYfPZpa4LNkS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGTWeVBaAsUqak2nX8L4auTNEkDXbfVRsYfPZpa4LNkS/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 1361,
  },
  {
    chainId: 101,
    name: "The Solasis Whitelist Token",
    symbol: "TSWL",
    address: "STNkp36CqWfuDx7E1jXyzDxnRZXtXhcLgXroxYJ3Qa1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/STNkp36CqWfuDx7E1jXyzDxnRZXtXhcLgXroxYJ3Qa1/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 2077,
  },
  {
    chainId: 101,
    name: "UncleSam",
    symbol: "USAM",
    address: "DXq8js1uxGNENn97SdtBhRDQ2TMETDxHsRabJPjVh2Q4",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DXq8js1uxGNENn97SdtBhRDQ2TMETDxHsRabJPjVh2Q4/logo.png",
    tags: [],
    verified: true,
    holders: 1432,
  },
  {
    chainId: 101,
    name: "MetaBards Whitelist Token",
    symbol: "MWT",
    address: "6DnZ8eU2JGmaHDALrR425xes228sFeMEcXezQ4CqdQx4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DnZ8eU2JGmaHDALrR425xes228sFeMEcXezQ4CqdQx4/logo.png",
    tags: ["NFT", "whitelist-token"],
    verified: true,
    holders: 1397,
  },
  {
    chainId: 101,
    name: "NERD",
    symbol: "DYOR",
    address: "ADcEtKSVKDxBUe3JERgSh9q458w3kRKPMHkihF13vxx2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADcEtKSVKDxBUe3JERgSh9q458w3kRKPMHkihF13vxx2/logo.png",
    tags: [],
    verified: true,
    holders: 7038,
  },
  {
    chainId: 101,
    name: "Adagio TVs Whitelist Token",
    symbol: "ATVS",
    address: "uSuyHGF93xkMU35uUNcAyXWrwLFrzS56kBZYLQVX25R",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uSuyHGF93xkMU35uUNcAyXWrwLFrzS56kBZYLQVX25R/tv.png",
    tags: ["social-token", "loop"],
    verified: true,
    holders: 661,
  },
  {
    chainId: 101,
    name: "MNRL",
    symbol: "MNRL",
    address: "MRLY2ScVMxXJTieiDi2Ywdm8VjEKeLcY4THL2UyhHRA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MRLY2ScVMxXJTieiDi2Ywdm8VjEKeLcY4THL2UyhHRA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 414,
  },
  {
    chainId: 101,
    name: "Chimp Frens",
    symbol: "FRENS",
    address: "HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX/chimp.png",
    tags: ["nfts"],
    verified: true,
    holders: 220,
  },
  {
    chainId: 101,
    name: "Rain Token",
    symbol: "RAIN",
    address: "rainH85N1vCoerCi4cQ3w6mCf7oYUdrsTFtFzpaRwjL",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rainH85N1vCoerCi4cQ3w6mCf7oYUdrsTFtFzpaRwjL/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1429,
  },
  {
    chainId: 101,
    name: "Bushido Society WL Token",
    symbol: "Bush",
    address: "BUSHiaBPmXgtuQ69KGyv24UxZqJmQHzkVpQvUyC5EchZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUSHiaBPmXgtuQ69KGyv24UxZqJmQHzkVpQvUyC5EchZ/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 103,
  },
  {
    chainId: 101,
    name: "bZt by Electons",
    symbol: "BZT",
    address: "ECPAzxsa4VBALQ4kh4i9mtUdRTBRhGwrzu7Y2YqwZjsi",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ECPAzxsa4VBALQ4kh4i9mtUdRTBRhGwrzu7Y2YqwZjsi/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 191,
  },
  {
    chainId: 101,
    name: "BotBorgs - DAO Utility Token",
    symbol: "BORGS",
    address: "36EsmEsa5rp3VrZAzLna3UFDEBFihwjsRFciUTfoZ5Qt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LinYu1992/BotBorg_DAO_Utility_Token/main/BotBorgDAOUtilityToken.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 1657,
  },
  {
    chainId: 101,
    name: "IMSO Mint Token",
    symbol: "IMSOMINT",
    address: "imsoVR7F3p9KnmAXDB38EiSjYyLqkgQS8QWj4egsgDz",
    decimals: 0,
    logoURI: "https://arweave.net/N6Y8A1lf3uPfvfwLp_09GsU1Wj_VUvI4mrlHoW6Wlbw",
    tags: ["whitelist-token"],
    verified: true,
    holders: 290,
  },
  {
    chainId: 101,
    name: "Battle Drones Wave 2 WL Token",
    symbol: "BattleWL",
    address: "DKEyAMNSVNdFCSUcunFJ4jn7TzZd8ec9Z4XUACaT37mG",
    decimals: 0,
    logoURI: "https://battledrones.io/WL_token.png",
    tags: ["WL"],
    verified: true,
    holders: 1323,
  },
  {
    chainId: 101,
    name: "Ministry of Art - Mint Token",
    symbol: "MoAToken",
    address: "moaTKHdUBnQyCTUbaetkPek8tnQkZTN4Z4ALMTHgQr9",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/moaTKHdUBnQyCTUbaetkPek8tnQkZTN4Z4ALMTHgQr9/logo.png",
    tags: ["wl-token"],
    verified: true,
    holders: 2498,
  },
  {
    chainId: 101,
    name: "Smug Pugs Whitelist Token",
    symbol: "SPWL",
    address: "Tokykm2subzeF9C3G5nqXds6YxpPeXEKkCD2Rt1jHKk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Tokykm2subzeF9C3G5nqXds6YxpPeXEKkCD2Rt1jHKk/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 489,
  },
  {
    chainId: 101,
    name: "MXM Alpha Temp Token",
    symbol: "TMP",
    address: "9WgTVodnWjVTwDyp44cEgezKhPhexkJMv1d2MkmGJEWw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WgTVodnWjVTwDyp44cEgezKhPhexkJMv1d2MkmGJEWw/logo.png",
    tags: ["GameFi", "Game"],
    verified: true,
    holders: 1800,
  },
  {
    chainId: 101,
    name: "SmokeHeads WLtoken",
    symbol: "SH",
    address: "SH1f6At4pScGi42Xfp6tvf9eKirfMd5bi2oPmxdpUAF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SH1f6At4pScGi42Xfp6tvf9eKirfMd5bi2oPmxdpUAF/logo.png",
    tags: [],
    verified: true,
    holders: 8112,
  },
  {
    chainId: 101,
    name: "Mental Protocol",
    symbol: "MEN",
    address: "2KXuiuKwSxatUSN3bYG9i4Mg9T66PYBPAHvVDtiCfoLm",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/MentalProtocol/uploads/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 392,
  },
  {
    chainId: 101,
    name: "Tamashi",
    symbol: "TAMASHI",
    address: "FXzrgwqF2rHAEiwa9qs9cdWS9QMmACWXLqzrufQFYmFu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXzrgwqF2rHAEiwa9qs9cdWS9QMmACWXLqzrufQFYmFu/logo.svg",
    tags: ["nfts", "utility-token"],
    verified: true,
    holders: 422,
  },
  {
    chainId: 101,
    name: "INCOME",
    symbol: "INCOME",
    address: "4UFiHL1FzUUQPpjsm1gpVDA9ENQQaB81WdvN4HYW24Pa",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/danielleonardomorales/incometoken/main/incometoken.png",
    tags: ["general"],
    verified: true,
    holders: 582,
  },
  {
    chainId: 101,
    name: "Theadex",
    symbol: "THEA",
    address: "6QSQ28juyiV5bkQ19AttaLPidtGDZbSKPbM2GrvRAAQA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6QSQ28juyiV5bkQ19AttaLPidtGDZbSKPbM2GrvRAAQA/theadex_logo.png",
    tags: ["social-token", "community-token", "DeFi"],
    verified: true,
    holders: 178,
  },
  {
    chainId: 101,
    name: "PussyCets Sneck",
    symbol: "SNECK",
    address: "8KoC6B7ekXKCEucie7skdH3TePx2ja3WeB14bFJKaahX",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/93284817/163309826-ae6df615-bbe7-4d24-a522-77414c27e0a4.PNG",
    tags: ["Social-Token", "collectible-token"],
    verified: true,
    holders: 278,
  },
  {
    chainId: 101,
    name: "StayFly",
    symbol: "$FLY",
    address: "4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw/logo.png",
    tags: ["utility-token", "community-token", "merchandise-token"],
    verified: true,
    holders: 2988,
  },
  {
    chainId: 101,
    name: "Heaven Dragons Empire Collection",
    symbol: "HVEWL",
    address: "CRmGfCvD3s43v2X6dGMmA9XVCMXy8jacKGQ6H451afgd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRmGfCvD3s43v2X6dGMmA9XVCMXy8jacKGQ6H451afgd/logo.png",
    tags: [],
    verified: true,
    holders: 1957,
  },
  {
    chainId: 101,
    name: "RAWR Coin",
    symbol: "$RAWR",
    address: "5LL1vedTP55UGkGF1x6ZRbefPHANveYpvqF18QSwKx2e",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LL1vedTP55UGkGF1x6ZRbefPHANveYpvqF18QSwKx2e/logo.png",
    tags: ["nft", "game-token", "utility-token"],
    verified: true,
    holders: 2473,
  },
  {
    chainId: 101,
    name: "FLUFF TOKEN",
    symbol: "$FLUFF",
    address: "HxgAGBTLCeemm4TVqvYSebpUanJKzuztXTHdhuCt4W2i",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/furrsols/assets/main/FLUFF.png",
    tags: ["utility-token"],
    verified: true,
    holders: 294,
  },
  {
    chainId: 101,
    name: "Bangsta Bear",
    symbol: "BANG",
    address: "4xFVXVK8Hy28MbF1rKGAU4F3sVLbMmzh63wQJeM8k9NW",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/4xFVXVK8Hy28MbF1rKGAU4F3sVLbMmzh63wQJeM8k9NW/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 337,
  },
  {
    chainId: 101,
    name: "Ahi Token",
    symbol: "AHI",
    address: "Ahiss7vnLA3JZW76TNLkHMHA8FKytdfMnQ5GzEwTt3Ed",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/ahiphoenixnft/crypto/main/logo.ahi.png",
    tags: ["utility-token"],
    verified: true,
    holders: 419,
  },
  {
    chainId: 101,
    name: "AGE of SAM token",
    symbol: "SOLARIUM",
    address: "6aJhpKmMpWCRw7vFLc5xxC1fJZGKXZspT5Lxjz5PnWtB",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/3magine/aos-assets/main/coin_small.png",
    tags: [
      "utility-token",
      "community-coin",
      "game-token",
      "NFT",
      "age-of-sam",
    ],
    verified: true,
    holders: 1007,
  },
  {
    chainId: 101,
    name: "Poteto",
    symbol: "TETO",
    address: "Ehbfbo2eYQYcaPeLK7LHN3gZeXfjBQyKopxTQCCKot4i",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ehbfbo2eYQYcaPeLK7LHN3gZeXfjBQyKopxTQCCKot4i/logo.svg",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 5104,
  },
  {
    chainId: 101,
    name: "NEOME-com Power to the People",
    symbol: "NEOME",
    address: "8psN3FhC8FJAD37bRPMNLAYD9PXJnA8zozBn2AfFEoih",
    decimals: 9,
    logoURI: "https://neome.com/token/logo.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 9454,
  },
  {
    chainId: 101,
    name: "Beach Bum Billionaires",
    symbol: "BUM",
    address: "woTu6cugnrEw6tZqePeXrHGbSJJVsBdHgmQwxTER4R3",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/cpwall88/bum/main/logoo.png",
    tags: ["Defi"],
    verified: true,
    holders: 301,
  },
  {
    chainId: 101,
    name: "Skeleton graveyard WL token",
    symbol: "SGYC",
    address: "WL18mMeknGmb53BWMU6DYCTCBRLpVXbo8yUSFRGXR46",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL18mMeknGmb53BWMU6DYCTCBRLpVXbo8yUSFRGXR46/logo.png",
    tags: [],
    verified: true,
    holders: 232,
  },
  {
    chainId: 101,
    name: "ARTICOIN",
    symbol: "ATC",
    address: "GXnw9YSt6DANCt84Ti6ZpbaXvrvuEJFCYqrDjygnq4R8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/mum33n/atc-logo/main/LOGO%20(256).png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 3325,
  },
  {
    chainId: 101,
    name: "Stoned Gods WL",
    symbol: "SGWL",
    address: "GodsvjpSKruMFKaPCBBWw4S66RepEzToPqjUhiheTNMZ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GodsvjpSKruMFKaPCBBWw4S66RepEzToPqjUhiheTNMZ/logo.png",
    tags: [],
    verified: true,
    holders: 1199,
  },
  {
    chainId: 101,
    name: "Plutonium 238",
    symbol: "PU238",
    address: "7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Pixels",
    symbol: "Pixels",
    address: "PXLSmSBWHU8yAqNof9Ry2LPsZxHSYB4xXsBaQWUmEzV",
    decimals: 0,
    logoURI:
      "https://arweave.net/GpuEa7JiRH0-trJ-B25Pjh-DYt0-RaXjzPTIEVGUOdk?ext=gif",
    tags: ["NFT"],
    verified: true,
    holders: 1016,
  },
  {
    chainId: 101,
    name: "TMDC-X WL",
    symbol: "TMDC-X-WL",
    address: "WLwKFNCKESMfbYU1o7hzp868AtyFHGpbC9i4jUTHpQb",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/namu42/ImageRepo/main/WLwKFNCKESMfbYU1o7hzp868AtyFHGpbC9i4jUTHpQb/wl_token_logo.png",
    tags: ["Whitelist-Token", "NFT"],
    verified: true,
    holders: 5525,
  },
  {
    chainId: 101,
    name: "xLifinity",
    symbol: "xLFNTY",
    address: "xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 1033,
  },
  {
    chainId: 101,
    name: "BakedGods WL",
    symbol: "BKD",
    address: "3sDP1g1LpiCqPCc4CsaSpqmEv4Uvf46kzg7XeRdfLsmk",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3sDP1g1LpiCqPCc4CsaSpqmEv4Uvf46kzg7XeRdfLsmk/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1948,
  },
  {
    chainId: 101,
    name: "Ielian Global Standard",
    symbol: "IGS",
    address: "igsvRjB6uyVMGcM9nbWwESxN1eTfVTPiQ1ThoCc8f2g",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/igsvRjB6uyVMGcM9nbWwESxN1eTfVTPiQ1ThoCc8f2g/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1032,
  },
  {
    chainId: 101,
    name: "MORP Alpha",
    symbol: "MORPA",
    address: "BbBa2LR1JQ7Scq993vPkYoYDpQDntduMAcRS27wWtEi1",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/MorpAlpha/crypto/main/MP.png",
    tags: ["social-token"],
    verified: true,
    holders: 1041,
  },
  {
    chainId: 101,
    name: "MYNFTGG WL",
    symbol: "MNWL",
    address: "9uBUX5YMtc3iYSmqmxHne8zyn3Hc2xZ3DazDmdZjaxwr",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9uBUX5YMtc3iYSmqmxHne8zyn3Hc2xZ3DazDmdZjaxwr/logo.png",
    tags: [],
    verified: true,
    holders: 6524,
  },
  {
    chainId: 101,
    name: "Moondusa Egg Minter",
    symbol: "EGGMINT",
    address: "Eggb3Wuuwb6S7dy2swEZC5kLWR16Tq8F9e41jXym1ZHv",
    decimals: 0,
    logoURI: "https://arweave.net/L3vjQctp6E6eRf132es8odtyTZ2FFP0grigdusXE_Ts",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1747,
  },
  {
    chainId: 101,
    name: "Cyberchips Whitelist Mint Token",
    symbol: "CCMINT",
    address: "ToDvKbmzMmy5DcMDrJr7dw6PEZR19CQzMzmo7dpQ6Wo",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToDvKbmzMmy5DcMDrJr7dw6PEZR19CQzMzmo7dpQ6Wo/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 325,
  },
  {
    chainId: 101,
    name: "LP Finance DAO",
    symbol: "LPFi",
    address: "4CHXd5uSMb9qrLfQwvyHzDMiKmNKDP8Np23dAQWTKNEy",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/LPFi.png",
    tags: ["governance-token", "LP-Finance"],
    verified: true,
    holders: 6509,
  },
  {
    chainId: 101,
    name: "VoxNinjas Loot",
    symbol: "VOXN",
    address: "VoxnYpJxkriwgBdARwsYPbheGCjXfWi8NFsDYsfP14Q",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VoxnYpJxkriwgBdARwsYPbheGCjXfWi8NFsDYsfP14Q/logo.png",
    tags: [],
    verified: true,
    holders: 338,
  },
  {
    chainId: 101,
    name: "Astral Witches Whitelist",
    symbol: "AW",
    address: "C4VW9CKc8mPBMmJsqDpTF24TwYpbLW1aTzhRevMfWUXi",
    decimals: 0,
    logoURI: "https://raw.githubusercontent.com/GokhanCerk/assets/main/wl.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1935,
  },
  {
    chainId: 101,
    name: "ZIG Coin",
    symbol: "ZIG",
    address: "ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT/logo.png",
    tags: ["ethereum", "utility-token"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "Viraverse Beta",
    symbol: "bVIRAL",
    address: "EubuCzGDjiYfC7YkLj8DgKYpMADgX8QT9nBNqpQedRy2",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EubuCzGDjiYfC7YkLj8DgKYpMADgX8QT9nBNqpQedRy2/logo.png",
    tags: [
      "stake-pool-token",
      "utility-token",
      "security-token",
      "community-token",
      "viraverse",
    ],
    verified: true,
    holders: 1259,
  },
  {
    chainId: 101,
    name: "The Donkey Richards WL Token",
    symbol: "DONKEYWL",
    address: "2nwHzfmTjWyHQCNozmmVzViYperzFc5yzLq5Xs2VV4pD",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2nwHzfmTjWyHQCNozmmVzViYperzFc5yzLq5Xs2VV4pD/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 176,
  },
  {
    chainId: 101,
    name: "BAM Token - The Sol Den",
    symbol: "BAMM",
    address: "7fPemBMNAAnCv2eyN8Y2qHbiawRFk79cQcwMWuETLdk9",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fPemBMNAAnCv2eyN8Y2qHbiawRFk79cQcwMWuETLdk9/logo.png",
    tags: ["utility-token", "game-token"],
    verified: true,
    holders: 417,
  },
  {
    chainId: 101,
    name: "Kiyo Dragon WL Token",
    symbol: "KDWL",
    address: "AxGonoNW3Qi8GBmcwpRpGE4xPV5v7rz2ug244gHBKfSr",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AxGonoNW3Qi8GBmcwpRpGE4xPV5v7rz2ug244gHBKfSr/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1431,
  },
  {
    chainId: 101,
    name: "GECKO",
    symbol: "GECKO",
    address: "GkLcXLnY5C36YKqceD3gpnQuej5xEtFBeRqQqFVGSwrf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkLcXLnY5C36YKqceD3gpnQuej5xEtFBeRqQqFVGSwrf/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 237,
  },
  {
    chainId: 101,
    name: "NEO3D TOKEN",
    symbol: "NEO",
    address: "NEo3D6MXRXf2iAfaqvZYqSmFkfutLvNjm86xmfGWNh5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/minh-git/docusaurus-2/main/static/img/neo3d-logo.jpg",
    tags: ["NFT", "gallery", "utility-token", "catpunk", "metaverse", "art"],
    verified: true,
    holders: 320,
  },
  {
    chainId: 101,
    name: "Dope Duck Club Whitelist Token",
    symbol: "DDCWL",
    address: "DDCEE8BVKbxZ6TXo8HK7g4qUBpaTocNRX4q7ppt5FKA1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DDCEE8BVKbxZ6TXo8HK7g4qUBpaTocNRX4q7ppt5FKA1/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 2534,
  },
  {
    chainId: 101,
    name: "TSF Flies",
    symbol: "FLIES",
    address: "3YUfxdwg1288BLSqzz6VnVsHwV7vqgbTBoTWqyhP4TEw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3YUfxdwg1288BLSqzz6VnVsHwV7vqgbTBoTWqyhP4TEw/logo.jpg",
    tags: ["nft-token", "community-token"],
    verified: true,
    holders: 5524,
  },
  {
    chainId: 101,
    name: "ORBWAY",
    symbol: "ORBWAY",
    address: "KVXJhoJLSSQq2EYPYMP4h2v8AJryQQGeDJcpjRLp96K",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KVXJhoJLSSQq2EYPYMP4h2v8AJryQQGeDJcpjRLp96K/logo.png",
    tags: [],
    verified: true,
    holders: 701,
  },
  {
    chainId: 101,
    name: "Legion Key",
    symbol: "LKEY",
    address: "DeViLuoFz6JbDsXk3hifyQCx4CGAgAThyyQjSM6jewKK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/SolStonez/Legion-Key-Logo/main/demon_logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 351,
  },
  {
    chainId: 101,
    name: "MBC Whitelist Mint Token",
    symbol: "MBC-WL",
    address: "ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 717,
  },
  {
    chainId: 101,
    name: "MBC Free Mint Token",
    symbol: "MBC-FR",
    address: "3hMeR4ibt9jczSbaZKkxw49p9XjFcnxcpNqtPGDcnqG6",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 449,
  },
  {
    chainId: 101,
    name: "GADO WL",
    symbol: "GADO",
    address: "TowQvaG4JUUDCY6SYrqq4xh45D6Rrv6cUdSjY86sTVb",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TowQvaG4JUUDCY6SYrqq4xh45D6Rrv6cUdSjY86sTVb/logo.png",
    tags: [],
    verified: true,
    holders: 1736,
  },
  {
    chainId: 101,
    name: "CREATE",
    symbol: "CREATE",
    address: "cr8ooTCpZUx4AQW5GquDzsfDNXEN3xxtEYEz8tAjgsw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cr8ooTCpZUx4AQW5GquDzsfDNXEN3xxtEYEz8tAjgsw/logo.png",
    tags: [],
    verified: true,
    holders: 1896,
  },
  {
    chainId: 101,
    name: "Teak",
    symbol: "$TEAK",
    address: "585mJ5PGUEMZuAEnD3G6DqhxZ6wo6hRBL4JYTtcqArqm",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/585mJ5PGUEMZuAEnD3G6DqhxZ6wo6hRBL4JYTtcqArqm/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 283,
  },
  {
    chainId: 101,
    name: "Simians Token",
    symbol: "SIMS",
    address: "8vFjsxK4SHg2XVSB6ofqNNvkFF62fx5Uq588a7f8qrjk",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/8vFjsxK4SHg2XVSB6ofqNNvkFF62fx5Uq588a7f8qrjk/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 262,
  },
  {
    chainId: 101,
    name: "DDLE",
    symbol: "DDLE",
    address: "Ebb1hE7AN82zMeLLe2JnfJCacnn2zTMP7xfDfqKPcccJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ebb1hE7AN82zMeLLe2JnfJCacnn2zTMP7xfDfqKPcccJ/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 243,
  },
  {
    chainId: 101,
    name: "ArabPunkz swap token",
    symbol: "ARABPUNKZ",
    address: "8GEdymZcgjVku6AfQuQCxqgUk8qpigy5LdP9UTRVcMxw",
    decimals: 9,
    logoURI: "https://storage.googleapis.com/arabpunkz/arab.png",
    tags: ["nft"],
    verified: true,
    holders: 552,
  },
  {
    chainId: 101,
    name: "RetroTrades WL Token",
    symbol: "RTWL",
    address: "Ac8HFm7bgEm4bNgbUj1woSqnWJDji9gWkc7uH1TosXSy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac8HFm7bgEm4bNgbUj1woSqnWJDji9gWkc7uH1TosXSy/logo.png",
    tags: ["whitelist-token", "retro-trades"],
    verified: true,
    holders: 153,
  },
  {
    chainId: 101,
    name: "High Voltage Citizens Whitelist Token",
    symbol: "HVCWL",
    address: "8xfL5CPfcDfovbBFHZpxPcHeUcYkbFwCGKm2JtrJr3q2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8xfL5CPfcDfovbBFHZpxPcHeUcYkbFwCGKm2JtrJr3q2/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 527,
  },
  {
    chainId: 101,
    name: "Forart Painter Whitelist",
    symbol: "FPW",
    address: "FPWsFrQRhJpiCZAAUotNes3XZuBTv8M5yLYU7YYGc61J",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FPWsFrQRhJpiCZAAUotNes3XZuBTv8M5yLYU7YYGc61J/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1389,
  },
  {
    chainId: 101,
    name: "Ernest Beyond Mint Token",
    symbol: "ERNESTWL",
    address: "5e5znzUAuWrM7p96XTZZyn5KWKRikLr1m8rpQESriebT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5e5znzUAuWrM7p96XTZZyn5KWKRikLr1m8rpQESriebT/logo.png",
    tags: [],
    verified: true,
    holders: 2292,
  },
  {
    chainId: 101,
    name: "Solily Protocol Coin",
    symbol: "LILY",
    address: "LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 2812,
  },
  {
    chainId: 101,
    name: "Solana Eco Index",
    symbol: "SIX",
    address: "HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD/logo.png",
    tags: ["Index", "SypoolProduct"],
    verified: true,
    holders: 126,
  },
  {
    chainId: 101,
    name: "Lido Bonded SOL",
    symbol: "bSOL-Lido",
    address: "EbMg3VYAE9Krhndw7FuogpHNcEPkXVhtXr7mGisdeaur",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EbMg3VYAE9Krhndw7FuogpHNcEPkXVhtXr7mGisdeaur/logo.svg",
    tags: [],
    verified: true,
    holders: 109,
  },
  {
    chainId: 101,
    name: "SIN Token",
    symbol: "SIN",
    address: "TsmrVzWkfBY3VJDWVRfNjqaaBgmLvT4n9qx1uqCk9Fd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TsmrVzWkfBY3VJDWVRfNjqaaBgmLvT4n9qx1uqCk9Fd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 110,
  },
  {
    chainId: 101,
    name: "Gosu",
    symbol: "GOSU",
    address: "6D7nXHAhsRbwj8KFZR2agB6GEjMLg4BM7MAqZzRT8F1j",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/jGosu/gosu/main/GOSU%20COIN.png",
    tags: ["social-token"],
    verified: true,
    holders: 133,
  },
  {
    chainId: 101,
    name: "Wonderpuff",
    symbol: "WPUFF",
    address: "14Zi6xaKrFpEdxQKyU1SnSE8dfa97Gfj1jrtuWCxWnZL",
    decimals: 2,
    logoURI:
      "https://bafkreifpmmgm3wp7e5u5hhnbxbq5djoh6zhickapb6e3yq2x473pn2w66q.ipfs.nftstorage.link/?ext=png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 7447,
  },
  {
    chainId: 101,
    name: "Cactus World H2O",
    symbol: "H2O",
    address: "78fRJMzWdKpeb2cAQ1pe25J5Fth9Mmiufzsi4KfazQdL",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/78fRJMzWdKpeb2cAQ1pe25J5Fth9Mmiufzsi4KfazQdL/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 322,
  },
  {
    chainId: 101,
    name: "Floating Apes Affiliation",
    symbol: "FLOAT",
    address: "AUnF9BxPPtZZYdm9dPXMiVeF3TJvPaXPisSYPU5jWp36",
    decimals: 1,
    logoURI:
      "https://bafybeidcqhtql7u6ppdgttwrx2e2qhb64mrvhqyilgks43msuhaisz3juu.ipfs.dweb.link/?ext=png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 1061,
  },
  {
    chainId: 101,
    name: "HOD Utility Token",
    symbol: "uHOD",
    address: "3uJmmABv4B2W7Qih1DdjrzvtdAbkuishC7vqq3UN2X1h",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/hodrbrth/assets/main/hodjpg.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 134,
  },
  {
    chainId: 101,
    name: "Vandal City Vault",
    symbol: "VAULT",
    address: "4Up16GyRmybEEDfaCsDszkzkvtWgoKDtS4cUyBEjvPBM",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Up16GyRmybEEDfaCsDszkzkvtWgoKDtS4cUyBEjvPBM/logo.png",
    tags: ["gaming-token", "social-token"],
    verified: true,
    holders: 6267,
  },
  {
    chainId: 101,
    name: "SHADES",
    symbol: "SHADE",
    address: "BAaN4r6pPHNLhm5t5FssMLpcnYFz9MpxUBc1gVox4jRF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAaN4r6pPHNLhm5t5FssMLpcnYFz9MpxUBc1gVox4jRF/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 839,
  },
  {
    chainId: 101,
    name: "SOLHELLCATS Whitelist Token",
    symbol: "WLHELLCATS",
    address: "TooaT4kKfpGQ7bD9NsZhEU81qDXoAn2GzqBLyaGY1e8",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TooaT4kKfpGQ7bD9NsZhEU81qDXoAn2GzqBLyaGY1e8/logo.png",
    tags: ["utility-token", "whitelist-token"],
    verified: true,
    holders: 1265,
  },
  {
    chainId: 101,
    name: "FLWRS Token",
    symbol: "FLWRS",
    address: "CUvVMqXAcyFJnwMhojQ9jmGuWrijGt26HfY7b99dyBeB",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/pablolefleur/flwrsimage/main/flwrs%20logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 629,
  },
  {
    chainId: 101,
    name: "Rumble Monkeys Whitelist Token",
    symbol: "RMBLWL",
    address: "BBaUcA9HFb6iJgXBzWn7pi1bptuBMETNXzge2BGti9Ts",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BBaUcA9HFb6iJgXBzWn7pi1bptuBMETNXzge2BGti9Ts/logo.png",
    tags: ["community-token", "meme-token"],
    verified: true,
    holders: 3699,
  },
  {
    chainId: 101,
    name: "BVLL-G WL Mint Pass",
    symbol: "BVLL-G-WL",
    address: "B2K7et7RDYjpuaYFcspBYxbnWP7C3v85cQif7ZsntVtz",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/noFAYZ/bvll-g/main/public/bvllgwl.png",
    tags: [],
    verified: true,
    holders: 3493,
  },
  {
    chainId: 101,
    name: "UB WL TOKEN",
    symbol: "UB",
    address: "7utH6rGUdnuji8mMmRQxqysYyzTAH7bbL11Abzpon3s1",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7utH6rGUdnuji8mMmRQxqysYyzTAH7bbL11Abzpon3s1/logo.png",
    tags: ["WL-token"],
    verified: true,
    holders: 2452,
  },
  {
    chainId: 101,
    name: "Solmon Trainer Whitelist Token",
    symbol: "ST",
    address: "E85VGYoRTojo2tYvoCqEtKUTr16Xcm6DbxcooK1T4mwJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/jwurrow/solmon/a715cead035d9182efe018ff11827ccec4fc7dd1/solmon-token.png",
    tags: [],
    verified: true,
    holders: 2253,
  },
  {
    chainId: 101,
    name: "Sky Floater Whitelist Token",
    symbol: "SFWL",
    address: "SFTZFZd9t4uCmRqdDL3WY248Bbdkdgv5Q6PUHc9bPNC",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGWLpx2iQntuhYcLfECbJcDDpQ6LW2feHJA7ZYv3F244/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 3254,
  },
  {
    chainId: 101,
    name: "Mythic Mushies WL",
    symbol: "MMWL",
    address: "756wgK33gHAZcLAaS6BTx7jkNetVX6WiMcNmcwrwLWCQ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/756wgK33gHAZcLAaS6BTx7jkNetVX6WiMcNmcwrwLWCQ/logo.png",
    tags: [],
    verified: true,
    holders: 363,
  },
  {
    chainId: 101,
    name: "Imagine Coin",
    symbol: "IMGN",
    address: "Aj7p7pCarQFvtSVv5gvRGbJ5yddpAajqpHgJmZWboTnD",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aj7p7pCarQFvtSVv5gvRGbJ5yddpAajqpHgJmZWboTnD/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1342,
  },
  {
    chainId: 101,
    name: "DRAY",
    symbol: "$DRAY",
    address: "xkqjobmo1kUgN4P7jcsWe5ud657oA3co4PnwKoQKG12",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/benpazzo/dray/main/assets/draytoken.png",
    tags: ["community-token"],
    verified: true,
    holders: 1080,
  },
  {
    chainId: 101,
    name: "Kleyne Gelt",
    symbol: "KLGT",
    address: "519W6ZZnduudeo6tp1YMgeWiNFeoq4kTVS4uMZggKE5r",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/klglt/Crypto/main/menorah.png",
    tags: ["currency"],
    verified: true,
    holders: 100,
  },
  {
    chainId: 101,
    name: "WiseCats Milk",
    symbol: "KLIM",
    address: "MiLKR8QYixQxp5wDXwfC8fLVH4AiAyb3X9YpAcXvtWe",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/wisecats/milk-icon.png",
    tags: ["NFT"],
    verified: true,
    holders: 1034,
  },
  {
    chainId: 101,
    name: "Royal Crows WL",
    symbol: "RCWL",
    address: "D4L61nbUsaNngn5rYfAQAhV7QasffCcoKqQitbMyNMtB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4L61nbUsaNngn5rYfAQAhV7QasffCcoKqQitbMyNMtB/rc.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1508,
  },
  {
    chainId: 101,
    name: "Alpha Pharaohs Whitelist",
    symbol: "APWL",
    address: "AEzCK6jhPpZgTwoVh3dpQHR8d5sTxnmSvqegbgsxyfTa",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEzCK6jhPpZgTwoVh3dpQHR8d5sTxnmSvqegbgsxyfTa/Logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 5137,
  },
  {
    chainId: 101,
    name: "Equinox",
    symbol: "ENX",
    address: "87rSGrpYdmTxfNBf8o2cpyiNcxCmNhUPBXjT8aoyfob5",
    decimals: 9,
    logoURI:
      "https://ixrjpmbgkspkjaueqzmyhb3fbca5atffgsrqyt527d2x3ycdlgqq.arweave.net/ReKXsCZUnqSChIZZg4dlCIHQTKU0owxPuvj1feBDWaE",
    tags: ["utility-token"],
    verified: true,
    holders: 1225,
  },
  {
    chainId: 101,
    name: "BELL",
    symbol: "BELL",
    address: "2qayh5Qc3BBR91KA6CemKN7vmzmexRJJw2KWFPzn53Uw",
    decimals: 6,
    logoURI:
      "https://cdn.discordapp.com/attachments/928110149388869684/967531744741957632/bell_282.png",
    tags: ["social-token", "utility-token"],
    verified: true,
    holders: 617,
  },
  {
    chainId: 101,
    name: "Staked Tulip",
    symbol: "sTulip",
    address: "STuLiPmUCUtG1hQcwdc9de9sjYhVsYoucCiWqbApbpM",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/164987734-11c00b2e-07d7-47ce-8441-63b063ec8bb2.png",
    tags: ["solfarm", "tulip", "staking"],
    verified: true,
    holders: 1093,
  },
  {
    chainId: 101,
    name: "Valt Whitelist",
    symbol: "VALT",
    address: "4Ra2qncf7F3t2z8HHtK3mdgmec7wMs9XgW9a45gS8rqR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Ra2qncf7F3t2z8HHtK3mdgmec7wMs9XgW9a45gS8rqR/logo.png",
    tags: ["ValtGym", "Valt", "Whitelist", "Metaverse"],
    verified: true,
    holders: 298,
  },
  {
    chainId: 101,
    name: "BLOOMIE",
    symbol: "BLOOMIE",
    address: "C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue/logo.svg",
    tags: ["utility-token", "game-token"],
    verified: true,
    holders: 603,
  },
  {
    chainId: 101,
    name: "Starbots GEAR",
    symbol: "GEAR",
    address: "23WuycvPjEuzJTsBPBZqnbFZFcBtBKAMTowUDHwagkuD",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/23WuycvPjEuzJTsBPBZqnbFZFcBtBKAMTowUDHwagkuD/logo.png",
    tags: ["utility-token", "play-to-earn", "game-token"],
    verified: true,
    holders: 1318,
  },
  {
    chainId: 101,
    name: "MetaOps Genesis",
    symbol: "MODS",
    address: "ExN3MaZLB5VhjGZ75qUuJ8ENkNiUvCCdA7j85vCcNicQ",
    decimals: 9,
    logoURI:
      "https://github.com/numpynewb33/token-list/blob/main/assets/mainnet/ExN3MaZLB5VhjGZ75qUuJ8ENkNiUvCCdA7j85vCcNicQ/logo.png?raw=true",
    tags: ["utility-token", "reward-token", "social-token"],
    verified: true,
    holders: 1119,
  },
  {
    chainId: 101,
    name: " Donkey Richard Coin",
    symbol: "DRICH",
    address: "GzTxngZwZ2ZcDydepix1cTMRcooDxfK32Px5ZZkcjtX6",
    decimals: 9,
    logoURI:
      "https://www.dropbox.com/s/cssc839nr1sjedt/Donkey%20Richards%20Logo.jpg?dl=0",
    tags: ["utility-token"],
    verified: true,
    holders: 474,
  },
  {
    chainId: 101,
    name: "BOSS WL Token",
    symbol: "BOSSWL",
    address: "9RbTen9wL7hTZRKZUMBPNpGGKsjRAM8LHokphuoCWK5w",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/ttarabay/token-list/main/assets/mainnet/9RbTen9wL7hTZRKZUMBPNpGGKsjRAM8LHokphuoCWK5w/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2700,
  },
  {
    chainId: 101,
    name: "SLURP",
    symbol: "$SLURP",
    address: "TomonpwmzyveJRXKCTbJxpyDuYLPgpugU3pCK5aceWi",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TomonpwmzyveJRXKCTbJxpyDuYLPgpugU3pCK5aceWi/logo.png",
    tags: [],
    verified: true,
    holders: 177,
  },
  {
    chainId: 101,
    name: "Fancy Potatoes",
    symbol: "FANCY",
    address: "8cqc6k5nwoBYJVqGk8dvxyVFHaaGvmUNP5ydpbmASeCZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/DaPrince2106/Fancy-PNG/main/0023.png",
    tags: ["NFT", "Art", "utility-token"],
    verified: true,
    holders: 1498,
  },
  {
    chainId: 101,
    name: "0xDRIP",
    symbol: "XDRIP",
    address: "4ewdr269QKV9SC1N855LUhXAe3YevASjQHoR7bzg9JKW",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ewdr269QKV9SC1N855LUhXAe3YevASjQHoR7bzg9JKW/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1017,
  },
  {
    chainId: 101,
    name: "purple squirrel",
    symbol: "PURP",
    address: "6z9oBZ84zSx2uPvPofyaAABmBaWUk1BmDkMQryiYorzk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/PurpleSquirrelMedia/SQRL/main/purp-metaverse-utility-token.png",
    tags: ["meta-verse", "utility-token"],
    verified: true,
    holders: 432,
  },
  {
    chainId: 101,
    name: "Greenies Token",
    symbol: "GRT",
    address: "CW2sMRF3JJ7q8rqamJz3iZcdPRNiv3RYKDQ4LfKTkUm7",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/drsaint1/drsaint/main/logo512.png",
    tags: ["Meme-Token", "NFT", "Utility-Token", "Social-Token"],
    verified: true,
    holders: 1758,
  },
  {
    chainId: 101,
    name: "SolarDex WL",
    symbol: "SDWL",
    address: "5tMhmodggwwcdcQQUZXJabXbU5NAbpWqMXkUjeFs9X72",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tMhmodggwwcdcQQUZXJabXbU5NAbpWqMXkUjeFs9X72/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 2808,
  },
  {
    chainId: 101,
    name: "SAI Token",
    symbol: "SAI",
    address: "AU4bT5vsa8Dqsd9BXEzsW5QSeDQ97QwKx8oEum8GMKpG",
    decimals: 9,
    logoURI: "https://github.com/peakypandas/icon/blob/main/SAI.png?raw=true",
    tags: ["utility-token"],
    verified: true,
    holders: 1143,
  },
  {
    chainId: 101,
    name: "Shkary Sharks CHOMPS",
    symbol: "CHOMPS",
    address: "3rFgy3p5u6NFRbEzDqt1NyHp1g3wX8WiWkEWZkmtxdFm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3rFgy3p5u6NFRbEzDqt1NyHp1g3wX8WiWkEWZkmtxdFm/logo.png",
    tags: ["NFT-token"],
    verified: true,
    holders: 675,
  },
  {
    chainId: 101,
    name: "Gojira WLtoken",
    symbol: "GojiraWhitelist",
    address: "Gj5CxKTz5m6vMaaq7ksgAPLbZFGTC97EpNinLggB2yax",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gj5CxKTz5m6vMaaq7ksgAPLbZFGTC97EpNinLggB2yax/logo.png",
    tags: [],
    verified: true,
    holders: 226,
  },
  {
    chainId: 101,
    name: "OTAKU Coin",
    symbol: "OTAKU",
    address: "EG9JDbEiCFpQ8a9LCi8AXskKvqZy757n3yFCnBvLrZRd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EG9JDbEiCFpQ8a9LCi8AXskKvqZy757n3yFCnBvLrZRd/logo.png",
    tags: [],
    verified: true,
    holders: 536,
  },
  {
    chainId: 101,
    name: "D3D Network",
    symbol: "D3DNETWORK",
    address: "7VEyj9ooKPLaxd4rxwRWB4J5Yo1upymWwNs7RL78i8Nj",
    decimals: 0,
    logoURI:
      "https://bafkreidhiegzjjqnikoevl4fbuuocdrnvmxyy2bnnoeoha6kwykhgscuwm.ipfs.dweb.link/?ext=png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 4670,
  },
  {
    chainId: 101,
    name: "DH Diamonds",
    symbol: "DMNDS",
    address: "FdSBbLHK8hfc6BSqjrhQZaGj7jgd5vfPcchDB2RDAQFA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdSBbLHK8hfc6BSqjrhQZaGj7jgd5vfPcchDB2RDAQFA/logo.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 1168,
  },
  {
    chainId: 101,
    name: "AsciiPunk Token",
    symbol: "APUNK",
    address: "D4KVLfryvPdjPsWRKDWGz4YpqpqZHkrjk1JzUMeBG8JJ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4KVLfryvPdjPsWRKDWGz4YpqpqZHkrjk1JzUMeBG8JJ/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 365,
  },
  {
    chainId: 101,
    name: "Good Earth Mood Stones",
    symbol: "GEMS",
    address: "GEMSkjHriJp95iSZpRgb5ym3RQeaMFig7cfWvnWTT4An",
    decimals: 8,
    logoURI: "https://media.kaijucards.io/misc/gem.png",
    tags: ["utility-token"],
    verified: true,
    holders: 670,
  },
  {
    chainId: 101,
    name: "Daupay",
    symbol: "DPAY",
    address: "AcZLBqhVjqnd4aoyRQ4fQpsBQnPtwNAFFHEmsedctmUR",
    decimals: 9,
    logoURI: "https://daupay.com/logo.png",
    tags: ["finance", "trading", "swap"],
    verified: true,
    holders: 215,
  },
  {
    chainId: 101,
    name: "DANK Token",
    symbol: "DANK",
    address: "AMNrJcZb4efKBjCg9djRmYacLgGRT7fR8LA48evGeLK7",
    decimals: 9,
    logoURI:
      "https://w7.pngwing.com/pngs/541/141/png-transparent-gold-coin-gold-coin-free-to-pull-the-year-of-the-goat-commemorative-coins-free-logo-design-template-animals-gold-coin.png",
    tags: ["DANK", "StonedFarms"],
    verified: true,
    holders: 241,
  },
  {
    chainId: 101,
    name: "Apes Of the Gods Governance Token",
    symbol: "AOTG",
    address: "AotgToH2CXpWh1m8xsCrYQ46fz4n4FdEnL3FgTYcSnt2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AotgToH2CXpWh1m8xsCrYQ46fz4n4FdEnL3FgTYcSnt2/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 128,
  },
  {
    chainId: 101,
    name: "Solnana",
    symbol: "SOLNANA",
    address: "9jcibqjEh988j7szCXB9jKgF3hH17CYbjrDYoGYGmmne",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9jcibqjEh988j7szCXB9jKgF3hH17CYbjrDYoGYGmmne/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1583,
  },
  {
    chainId: 101,
    name: "SolClones WL",
    symbol: "SCWL",
    address: "942JqBf2PipzDyHM5sQyfLh21XUVFmGAoSReMMe1Gfba",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/pablolefleur/solcloneswltoken/main/solcloneswlimg.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2584,
  },
  {
    chainId: 101,
    name: "BARN",
    symbol: "vBARN",
    address: "3i6AKKC2WGd7qgqFJmSxKUGBWAui91upsMGu82xszTL2",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/3i6AKKC2WGd7qgqFJmSxKUGBWAui91upsMGu82xszTL2/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 526,
  },
  {
    chainId: 101,
    name: "CRTR",
    symbol: "$CRTR",
    address: "CRTRFiHiddN7UMR5LR4SgDB4VuX6s4SKm2Ltms7ui7Tu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRTRFiHiddN7UMR5LR4SgDB4VuX6s4SKm2Ltms7ui7Tu/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 658,
  },
  {
    chainId: 101,
    name: "FAKE TOKEN BUY THE OFFICIAL",
    symbol: "SC1M",
    address: "CWLAETW6Pty2VqPm3MTs47FhVNdQmEPfDm6yZBkQcZz2",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWLAETW6Pty2VqPm3MTs47FhVNdQmEPfDm6yZBkQcZz2/logo.png",
    tags: ["scam-token"],
    verified: true,
    holders: 10913,
  },
  {
    chainId: 101,
    name: "Electrum",
    symbol: "ELECTRUM",
    address: "ELECtZbpWTUGcxYTvmqTu2gRH8GVMVWuE4dkULDapp8F",
    decimals: 9,
    logoURI: "https://imgur.com/a/QxWOaTj",
    tags: ["utility-token"],
    verified: true,
    holders: 392,
  },
  {
    chainId: 101,
    name: "Bitcoin Alternative Coin",
    symbol: "ALT2B",
    address: "CqoMXWaoTMfYNtTTwTadz9bMxg8DkZJVKtVRcw77nmYg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/alt2b/crypto/main/a2BTC%20logo.png",
    tags: ["community-token", "utility-token", "social-token"],
    verified: true,
    holders: 1021,
  },
  {
    chainId: 101,
    name: "SickBoys-WL-Token",
    symbol: "SBT",
    address: "SiCKr9KxWv9pA9q35qoaKu9ZhEKZngscLjcREogaobh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SiCKr9KxWv9pA9q35qoaKu9ZhEKZngscLjcREogaobh/logo.png",
    tags: [],
    verified: true,
    holders: 1480,
  },
  {
    chainId: 101,
    name: "MFC M1 Mint Confirmation",
    symbol: "MFCMC",
    address: "CyZDc8wbDmtT88YVLAfvrH7QSsGdAreXkNKjWVQDDzCn",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CyZDc8wbDmtT88YVLAfvrH7QSsGdAreXkNKjWVQDDzCn/logo.png",
    tags: ["nft"],
    verified: true,
    holders: 2066,
  },
  {
    chainId: 101,
    name: "Zombiees Whitelist Token",
    symbol: "ZWLT",
    address: "WL7fRmbPfu3U45ZWPrXyfJTjzneFahC2X2pMsBESL4g",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL7fRmbPfu3U45ZWPrXyfJTjzneFahC2X2pMsBESL4g/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 5589,
  },
  {
    chainId: 101,
    name: "(FIXED) The Wolf Den WL Token",
    symbol: "TWDNEW",
    address: "A4DpjBEAL9U8crHRNKrbtdbH1wziiAkavNKPnwaetnyS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A4DpjBEAL9U8crHRNKrbtdbH1wziiAkavNKPnwaetnyS/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1556,
  },
  {
    chainId: 101,
    name: "HALO",
    symbol: "HALO",
    address: "Do5AbqdEbj742B2Cm8BypAGg3h1skLaAVTbT2mLRcW8c",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/farhan067/HaloTokenLogo/main/logo.png",
    tags: [],
    verified: true,
    holders: 1491,
  },
  {
    chainId: 101,
    name: "BAGS",
    symbol: "$BAGS",
    address: "FAn4hcD345ugybXc6aDivPnLAnBLhjNKcRGkZt2bGiCb",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/BAGS_coin.png",
    tags: [],
    verified: true,
    holders: 324,
  },
  {
    chainId: 101,
    name: "DRIVE COIN",
    symbol: "$DRIVE",
    address: "4yoWgpCg5KciCPuA6LxDFpJHa53Jjj6XU9RLFJCgdakL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/DRIVE_coin.png",
    tags: [],
    verified: true,
    holders: 2025,
  },
  {
    chainId: 101,
    name: "Meta Drago Ember",
    symbol: "EMBER",
    address: "Emberc567ToSP9FgAMuJWHXiQnEKVWrWC6gy64zbsK8u",
    decimals: 9,
    logoURI: "https://metadrago.art/resources/images/EmberLogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1759,
  },
  {
    chainId: 101,
    name: "ELEVATE",
    symbol: "$ELEVATE",
    address: "FyXLF1fFAnKjby8f2pYXeyBWgB5hmon2DmCjytZEfkcz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/ELEVATE_coin.png",
    tags: [],
    verified: true,
    holders: 614,
  },
  {
    chainId: 101,
    name: "Krypto Kronikz Budz",
    symbol: "$BUDZ",
    address: "kronKPwYDbmtNgynPMGwyMyV6PrgJffNuvG3EvjTiVa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/KryptoKronikz/budz-icon.png",
    tags: ["NFT"],
    verified: true,
    holders: 411,
  },
  {
    chainId: 101,
    name: "TUITION",
    symbol: "$TUITON",
    address: "GzAr3oY2nZJnnbsvyRTYy7PW9Dp9hThGgiUpzs3HE8ns",
    decimals: 2,
    logoURI: "https://i.ibb.co/W0Zp6RS/Tuiton-Coin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1005,
  },
  {
    chainId: 101,
    name: "SolMonkeez WLToken",
    symbol: "SMWL",
    address: "ABFkiQcLuCjicoCddXHuiPN9S653StWuySCHvWYF5oEy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABFkiQcLuCjicoCddXHuiPN9S653StWuySCHvWYF5oEy/logo.png",
    tags: ["NFT", "dao", "utility-token"],
    verified: true,
    holders: 2577,
  },
  {
    chainId: 101,
    name: "VISN",
    symbol: "$VISN",
    address: "VisDZunhfXf29cxyZ2pGWvsrpye5FnCgScnRZ2d9WVG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VisDZunhfXf29cxyZ2pGWvsrpye5FnCgScnRZ2d9WVG/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1051,
  },
  {
    chainId: 101,
    name: "DABL",
    symbol: "$DABL",
    address: "2YmFbkbfPMiUivS5TtSnzLGPM32iapkHBUvKB7XVYuoi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/DABL_coin.png",
    tags: [],
    verified: true,
    holders: 268,
  },
  {
    chainId: 101,
    name: "CYBER",
    symbol: "$CYBER",
    address: "5mRj82qzktE2Ab7ZvoeWUYk6gnuRynTJaPs3YuFQW1gt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/CYBER_coin.png",
    tags: [],
    verified: true,
    holders: 299,
  },
  {
    chainId: 101,
    name: "APE (Synthetic)",
    symbol: "sAPE",
    address: "64Hw4Hm4WLC1Ty6p8g5vLZNCS37msb9Qq8ZFJE6UConN",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64Hw4Hm4WLC1Ty6p8g5vLZNCS37msb9Qq8ZFJE6UConN/logo.png",
    tags: ["meme-token", "wrapped", "synthetic"],
    verified: true,
    holders: 175,
  },
  {
    chainId: 101,
    name: "Oh My Girls - WL Token",
    symbol: "omgWL",
    address: "omg6vAatLFChVhicZqFvBLd7vEqJqjp7KPR5CxKjMjR",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/omg6vAatLFChVhicZqFvBLd7vEqJqjp7KPR5CxKjMjR/logo.png",
    tags: ["wl-token"],
    verified: true,
    holders: 9076,
  },
  {
    chainId: 101,
    name: "MAES",
    symbol: "$MAES",
    address: "3uKPwVQ7cjnCJWgpFLRfMDkJzAEaV6o7uMJUyZErT6V4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/MAES_coin.png",
    tags: [],
    verified: true,
    holders: 228,
  },
  {
    chainId: 101,
    name: "ACCESS",
    symbol: "$ACCESS",
    address: "FgkPeKEDfb2mfbf3at8TVcZE8YdKxpdTTyqabqXqB33Y",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/ACCESS_coin.png",
    tags: [],
    verified: true,
    holders: 585,
  },
  {
    chainId: 101,
    name: "LEGEND COIN",
    symbol: "$LEGEND",
    address: "Ab7jUsWFTdVNmdCC3ZwjbNGnpt9E4qSh9oSZ6hYMBBaw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/LEGEND_coin.png",
    tags: [],
    verified: true,
    holders: 120,
  },
  {
    chainId: 101,
    name: "Anonymous Beartoshi SRC Whitelist Token",
    symbol: "ABTWL",
    address: "AB9dE76Sf8c7mi8cxFC7e9n8xz7z4zNQQinEDpgmiHmA",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AB9dE76Sf8c7mi8cxFC7e9n8xz7z4zNQQinEDpgmiHmA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2909,
  },
  {
    chainId: 101,
    name: "Keikai WL Token",
    symbol: "KWL",
    address: "HnAq3F9ipwGXKBG3yfHyX8y19m9NiXSd1iphYDvrp1LS",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnAq3F9ipwGXKBG3yfHyX8y19m9NiXSd1iphYDvrp1LS/logo.png",
    tags: [],
    verified: true,
    holders: 283,
  },
  {
    chainId: 101,
    name: "Solaxy Token",
    symbol: "SOLA",
    address: "soLACdGKTr1PT75KTjj4RaUgA2sDBvCqAd8Zty2W1Yz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soLACdGKTr1PT75KTjj4RaUgA2sDBvCqAd8Zty2W1Yz/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 390,
  },
  {
    chainId: 101,
    name: "DEAF",
    symbol: "$DEAF",
    address: "CH4Z1fw39g2Y9d9f96qiSPUrqJ3CVjJfPWriMLMX1HKi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/DEAF_coin.png",
    tags: [],
    verified: true,
    holders: 894,
  },
  {
    chainId: 101,
    name: "Apes of Wall Street Whitelist Token",
    symbol: "AOWS",
    address: "ApeWLUZp1WGZdYnFEVubpTEzaNg2cpw182DKitLsyuRd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApeWLUZp1WGZdYnFEVubpTEzaNg2cpw182DKitLsyuRd/logo.png",
    tags: ["wl-token"],
    verified: true,
    holders: 1114,
  },
  {
    chainId: 101,
    name: "Kotaro Chips",
    symbol: "KTRC",
    address: "ChywntqwNRzaoWYUcDQ3iALqcwSAVtjM2dPshz5AETMm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChywntqwNRzaoWYUcDQ3iALqcwSAVtjM2dPshz5AETMm/logo.png",
    tags: ["NFT", "GAMEFI", "Staking"],
    verified: true,
    holders: 1080,
  },
  {
    chainId: 101,
    name: "Frog Club Token",
    symbol: "$RIBBIT",
    address: "frogmSnr9knrc6CcVMtGrh3KvixZRc67LQYSqoE3ozZ",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/frogmSnr9knrc6CcVMtGrh3KvixZRc67LQYSqoE3ozZ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 233,
  },
  {
    chainId: 101,
    name: "Tonic",
    symbol: "TNC",
    address: "CrKj3wahgHc76yUE5gL3WLbRW5g5rK88vAtUqRcsSCnX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrKj3wahgHc76yUE5gL3WLbRW5g5rK88vAtUqRcsSCnX/logo.png",
    tags: [],
    verified: true,
    holders: 1012,
  },
  {
    chainId: 101,
    name: "Fallen Angel",
    symbol: "ANGEL",
    address: "7TSMDTH15FddZfZHX1pPuDynM3N62BCHbHtLvRNvmSmL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TSMDTH15FddZfZHX1pPuDynM3N62BCHbHtLvRNvmSmL/logo.png",
    tags: ["nft-marketplace"],
    verified: true,
    holders: 1115,
  },
  {
    chainId: 101,
    name: "Sloth WL Token",
    symbol: "SWLT",
    address: "6aFtCUxRMekeFphwM9xJ526UohPJbYU6zVxXWm1X81WD",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6aFtCUxRMekeFphwM9xJ526UohPJbYU6zVxXWm1X81WD/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 898,
  },
  {
    chainId: 101,
    name: "Tenkai Token",
    symbol: "TENKAI",
    address: "TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 258,
  },
  {
    chainId: 101,
    name: "MOUS IN DA HOUS WL TOKEN",
    symbol: "MIDHWLT",
    address: "WL3JbsR9VGjFP7maGkAC6LfS9s3JFuCWeU5JAB9Gi4G",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLwanMkvjXzJCogizrQgZc8MuZ4aSXsZ2KXm8aaZ6FY/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 12738,
  },
  {
    chainId: 101,
    name: "Mrbot Coin",
    symbol: "MRBOT",
    address: "58xx9DVzXCic3GhKwBWW5mpBg7ABkjVFpLpqU2DgfrjU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58xx9DVzXCic3GhKwBWW5mpBg7ABkjVFpLpqU2DgfrjU/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 173,
  },
  {
    chainId: 101,
    name: "Solana Brains Whitelist Token",
    symbol: "SBWL",
    address: "4pLbiXeTym6XNkPWUw88V5JKYFErJa9bX9tV9hdHtdtN",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pLbiXeTym6XNkPWUw88V5JKYFErJa9bX9tV9hdHtdtN/logo.png",
    tags: ["whitelist-token", "nfts"],
    verified: true,
    holders: 496,
  },
  {
    chainId: 101,
    name: "Dongri Token",
    symbol: "DON",
    address: "5osL1DnezspfmAd2Qn1PXsQniZGrzRXrxUNCPXbbrr78",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5osL1DnezspfmAd2Qn1PXsQniZGrzRXrxUNCPXbbrr78/logo.png",
    tags: [],
    verified: true,
    holders: 464,
  },
  {
    chainId: 101,
    name: "Crypto Frogz WL",
    symbol: "CFWL",
    address: "CQz5Nauawfq5fRBu37k8h6e4TteAFj1LwXoq8UuHoQfq",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQz5Nauawfq5fRBu37k8h6e4TteAFj1LwXoq8UuHoQfq/logo.png",
    tags: ["community-token", "whitelist-token"],
    verified: true,
    holders: 154,
  },
  {
    chainId: 101,
    name: "Dead Souls - SOUL Token",
    symbol: "SOUL",
    address: "7dCG3R5sCLnEySHkkSJxHK3MfEKEbeKxgcuqRnkmTysU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LinYu1992/dead_souls_token/main/Coin.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 547,
  },
  {
    chainId: 101,
    name: "S7N Coin",
    symbol: "SIN",
    address: "2znADNrRYvai8gfej1mcV2YbNvehY6cBSALvuWnuvNPN",
    decimals: 4,
    logoURI:
      "https://bafybeib2znxf6whdtq354t6jsyejs7nlnevqd2pjxatr5ft2tgsgyrazb4.ipfs.nftstorage.link/",
    tags: ["utility-token"],
    verified: true,
    holders: 632,
  },
  {
    chainId: 101,
    name: "Mellowmen WL Token",
    symbol: "MELLOWWL",
    address: "Hj9V5W4R9BxKUJYC36X5Biayv6FwZz5mmfV3JY4LxF8z",
    decimals: 0,
    logoURI: "https://i.imgur.com/38QD4yg.png",
    tags: [],
    verified: true,
    holders: 3895,
  },
  {
    chainId: 101,
    name: "BitLegacy Token",
    symbol: "BTL",
    address: "aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK/logo.png",
    tags: [],
    verified: true,
    holders: 178,
  },
  {
    chainId: 101,
    name: "FLAME",
    symbol: "FLAME",
    address: "3hEN3maDrmuoj89EDV452RRoRqDfZeeR4auoRs5mXF9u",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hEN3maDrmuoj89EDV452RRoRqDfZeeR4auoRs5mXF9u/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 253,
  },
  {
    chainId: 101,
    name: "AQUA",
    symbol: "AQUA",
    address: "FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk/logo.png",
    tags: ["community-token", "social-token", "utility-token"],
    verified: true,
    holders: 5495,
  },
  {
    chainId: 101,
    name: "Bushido Coin",
    symbol: "BSHD",
    address: "8rgGrnETmAbMCmgAWgUA67ydf7CzMyd5pErGZiHvWxwN",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/stan14100/token-icon/main/bshd-token.png",
    tags: ["utility-token"],
    verified: true,
    holders: 725,
  },
  {
    chainId: 101,
    name: "Smokeville WL",
    symbol: "SMKWL",
    address: "SMKdxDRkGU4NVhi36cXbYMzCGhkj48VVsWHiybAQDwC",
    decimals: 0,
    logoURI: "https://shmokeville.com/logo/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1493,
  },
  {
    chainId: 101,
    name: "Pet Solciety",
    symbol: "PAWR",
    address: "A7e46NH9UMeCARWtNNoVSkR55DxEaAgQGiXLYwxLW36z",
    decimals: 2,
    logoURI:
      "https://bafkreiefof3cjzgsd2xvfcijcf3r3az2rgycad7ycpveghjlmo6r5surh4.ipfs.dweb.link/?ext=png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 357,
  },
  {
    chainId: 101,
    name: "Sugar Rush WL",
    symbol: "GMT",
    address: "ES4isqfcWbeBZwtH2eLRjk5oUzaMyZZrW2uhDM6yde6h",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ES4isqfcWbeBZwtH2eLRjk5oUzaMyZZrW2uhDM6yde6h/logo.png",
    tags: ["Whitelist-Token"],
    verified: true,
    holders: 231,
  },
  {
    chainId: 101,
    name: "Resin",
    symbol: "RSN",
    address: "6SUmHppQdVY44VXh7svyb9jhWJbiQSwvKQBW4oegmx3m",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/h3ll0x/resintokenimage/main/resin.gif",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 446,
  },
  {
    chainId: 101,
    name: "PILLZ",
    symbol: "PILLZ",
    address: "86ywhGx2vMPUQht6VbxNA6T6WxzGLSYJsmMptgGTMcy6",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/DopeHeadzNFT/Logo/main/Logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2264,
  },
  {
    chainId: 101,
    name: "NEON EVM",
    symbol: "NEON",
    address: "8ZSJTmL42LgTrC1qY7AZQkLYT1EZquQA1cA3ze4bSjvq",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ZSJTmL42LgTrC1qY7AZQkLYT1EZquQA1cA3ze4bSjvq/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 432,
  },
  {
    chainId: 101,
    name: "Spark Token",
    symbol: "SPARK",
    address: "SPSzWFd2YpwbifRqoow76N31RcnFPH17vmq6AGCXt3C",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPSzWFd2YpwbifRqoow76N31RcnFPH17vmq6AGCXt3C/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 242,
  },
  {
    chainId: 101,
    name: "Saigo Monkeys",
    symbol: "SAIGO",
    address: "34TNFU9QLVraxfnCM3MHgeS3SKQ6RYw2iBogUzXoPbLD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Tynew240/token-list/1676617702a122d2c52e1bc6302d4da2e279407c/assets/mainnet/34TNFU9QLVraxfnCM3MHgeS3SKQ6RYw2iBogUzXoPbLD/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 264,
  },
  {
    chainId: 101,
    name: "Decimus Dynamics Token",
    symbol: "DEDS",
    address: "BvEj2MNMPsUrD4vSk7NHs4TtRcCcJd75Wx5HvVbY4rbK",
    decimals: 9,
    logoURI:
      "https://github.com/DecimusDynamics/assets/blob/main/decimus-icon-1x1-black.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1087,
  },
  {
    chainId: 101,
    name: "USN (Allbridge from Near)",
    symbol: "USN",
    address: "PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 495,
  },
  {
    chainId: 101,
    name: "Animalz WL Token",
    symbol: "AWLT",
    address: "FffNDEb4jm5cp3burLawTV17PDXjexuN3MzdDahkKtaf",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FffNDEb4jm5cp3burLawTV17PDXjexuN3MzdDahkKtaf/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1994,
  },
  {
    chainId: 101,
    name: "Synthetic USD (Fabric)",
    symbol: "fUSD",
    address: "B7mXkkZgn7abwz1A3HnKkb18Y6y18WcbeSkh1DuLMkee",
    decimals: 8,
    logoURI:
      "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ec3521b6-b382-4efb-1d16-7b60c75b9100/public",
    tags: ["fabric", "synthetics"],
    verified: true,
    holders: 590,
  },
  {
    chainId: 101,
    name: "Hedge Token",
    symbol: "HDG",
    address: "5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy/logo.png",
    tags: ["community-token", "governance-token"],
    verified: true,
    holders: 5217,
  },
  {
    chainId: 101,
    name: "Datalus Reward Token",
    symbol: "DRT",
    address: "Fe9aWgTcboSvNZsE71fVDw3GRr2x9GFxtpEbu5fnKV81",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fe9aWgTcboSvNZsE71fVDw3GRr2x9GFxtpEbu5fnKV81/logo.png",
    tags: ["utility-coin"],
    verified: true,
    holders: 276,
  },
  {
    chainId: 101,
    name: "Sight",
    symbol: "SIGHT",
    address: "ADQwix6UMnhZ13iAd5xQMWFUuw8cJRGj1RioqP3GZebg",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADQwix6UMnhZ13iAd5xQMWFUuw8cJRGj1RioqP3GZebg/logo.png",
    tags: ["utility-coin"],
    verified: true,
    holders: 3948,
  },
  {
    chainId: 101,
    name: "SHINE",
    symbol: "$SHINE",
    address: "8hwHhAxzrKtuN8GfotLTUYXXLEiX9qrzPYkahzuByWGP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/SHINE_coin.png",
    tags: [],
    verified: true,
    holders: 1052,
  },
  {
    chainId: 101,
    name: "LOOP",
    symbol: "$LOOP",
    address: "3nmTsvHUoyWFRoksBWU9oHaHwGsezAp97hgrrnc8brNo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/LOOP_coin.png",
    tags: [],
    verified: true,
    holders: 754,
  },
  {
    chainId: 101,
    name: "DUNK",
    symbol: "$DUNK",
    address: "2Y1E1KgmXYgfLKR7e6o9xzwgZ53rYjLPyqmEeDPacgZi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/DUNK_coin.png",
    tags: [],
    verified: true,
    holders: 2014,
  },
  {
    chainId: 101,
    name: "DRPOWER",
    symbol: "$DRPOWER",
    address: "5De7hU2PZFsSb6nJaetgZ2aZNEMDsC9A8Bp8G54x9BX7",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/DRPOWER_coin.png",
    tags: [],
    verified: true,
    holders: 1498,
  },
  {
    chainId: 101,
    name: "EASE",
    symbol: "$EASE",
    address: "5JNGgEcU9EYAa5PoyQiTUoRfgP3fpBGHfXK33ZRggDZo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/EASE_coin.png",
    tags: [],
    verified: true,
    holders: 239,
  },
  {
    chainId: 101,
    name: "LEVERAGE",
    symbol: "$LVRG",
    address: "6GNwqhgq3saN6dX94kDrxc8dj4xAcgnwK9njqbrHjmt2",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/LVRG_coin.png",
    tags: [],
    verified: true,
    holders: 499,
  },
  {
    chainId: 101,
    name: "ANTI SOCIAL",
    symbol: "$ANTI",
    address: "27QmTVixhZP5xLM2iYsdmpfhVNsThUS2ZvtmZ3AmNhED",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/ANTI_coin.png",
    tags: [],
    verified: true,
    holders: 467,
  },
  {
    chainId: 101,
    name: "WELLNESS",
    symbol: "$WELLNESS",
    address: "Es9ogKeDecth3erJanqKFsBLgU9bQCtsjNetRUsqiJto",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/WELLNESS_coin.png",
    tags: [],
    verified: true,
    holders: 325,
  },
  {
    chainId: 101,
    name: "SHIZOKU clans",
    symbol: "SHIZOKU",
    address: "CkfKdxxSL7Zy1EDSp7Xk2NLVWE18UmauBysNCME5zvbc",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkfKdxxSL7Zy1EDSp7Xk2NLVWE18UmauBysNCME5zvbc/shizoku.png",
    tags: [],
    verified: true,
    holders: 182,
  },
  {
    chainId: 101,
    name: "UPGRADE COIN",
    symbol: "$UPGRADE",
    address: "J2SVSPEu6ZefWH16BgPHpH6vU43MeEcRZm5Npm1ycQUi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/UPGRADE_coin.png",
    tags: [],
    verified: true,
    holders: 1249,
  },
  {
    chainId: 101,
    name: "LMC Founder's Pass WL",
    symbol: "LMCFPWL",
    address: "DbreB1y7f52k5x1AHrJio18eBBNRcjb97BKLtaEKgstX",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbreB1y7f52k5x1AHrJio18eBBNRcjb97BKLtaEKgstX/logo.jpg",
    tags: ["wl-token"],
    verified: true,
    holders: 505,
  },
  {
    chainId: 101,
    name: "BOW",
    symbol: "BOW",
    address: "BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6/logo.png",
    tags: [],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "44TH",
    symbol: "44TH",
    address: "44thGfHRsdCB61NAkrDFe6djRrwWZegTTqrs1bwMeAGu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/44thGfHRsdCB61NAkrDFe6djRrwWZegTTqrs1bwMeAGu/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3634,
  },
  {
    chainId: 101,
    name: "Troop Coin",
    symbol: "TROOP",
    address: "Bic8w5K9tvHDY7XfiT7fLTdiAkQWURAKkcEs8EsXpxbY",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bic8w5K9tvHDY7XfiT7fLTdiAkQWURAKkcEs8EsXpxbY/logo.png",
    tags: ["utility-coin"],
    verified: true,
    holders: 5558,
  },
  {
    chainId: 101,
    name: "dHALO",
    symbol: "HALO",
    address: "HALoiQHVad4zaH4yhyiZg6E2Pc9hTp4Pp3Fhoo9Et12h",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HALoiQHVad4zaH4yhyiZg6E2Pc9hTp4Pp3Fhoo9Et12h/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 741,
  },
  {
    chainId: 101,
    name: "SMB Index",
    symbol: "MONKES",
    address: "FmoKY2ERGmE9NzrYphAJcqH5BPRy2Hs4VomRfu8Qgt7Y",
    decimals: 2,
    logoURI: "https://i.imgur.com/FczxrLa.png",
    tags: ["fraction-index", "bridgesplit-fraction", "smb", "fnft"],
    verified: true,
    holders: 289,
  },
  {
    chainId: 101,
    name: "Serum Surfers Floor Index",
    symbol: "SURF",
    address: "CP8CaP7GmSVUo9j3L8dwDKVR6i1kvcCUn1ubGGhc2V2M",
    decimals: 2,
    logoURI:
      "https://creator-hub-prod.s3.us-east-2.amazonaws.com/serum_surfers_pfp_1650376607466.gif",
    tags: ["fraction-index", "bridgesplit-fraction", "serum-surfers", "fnft"],
    verified: true,
    holders: 190,
  },
  {
    chainId: 101,
    name: "sRLY (Rally Solana)",
    symbol: "sRLY",
    address: "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq/logo.png",
    tags: [],
    verified: true,
    holders: 1275,
  },
  {
    chainId: 101,
    name: "WL token BCNinjas Generation 2",
    symbol: "WLNINJAS",
    address: "GLeHPn4ZqRvAqN9LVDeWrcUtNi7YtQs3oewz7aFfngwG",
    decimals: 0,
    logoURI: "https://i.ibb.co/JkT8swM/2.png",
    tags: ["utility-token"],
    verified: true,
    holders: 182,
  },
  {
    chainId: 101,
    name: "WAGBULL",
    symbol: "WAGBULL",
    address: "8u3o8FJ2YAQLWdUrGg8C7YYXgdpspjf7NLUBtUqQUFgq",
    decimals: 9,
    logoURI:
      "https://rawcdn.githack.com/okaybulls/token/fb8f19a8139c0be093815f0b9fc0ff80133c0e36/token.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1503,
  },
  {
    chainId: 101,
    name: "MBOT Mint PassToken",
    symbol: "MBOT",
    address: "FF75evgj5bFWiBuu9MfLF54imFo8ypSYwfC6xQSiKYcJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FF75evgj5bFWiBuu9MfLF54imFo8ypSYwfC6xQSiKYcJ/logo.png",
    tags: ["WL-token"],
    verified: true,
    holders: 362,
  },
  {
    chainId: 101,
    name: "calUSD Stablecoin",
    symbol: "calUSD",
    address: "CALusHembJf3tQ69BxFbLRUSpGRwKzEnLKWUPhQo5dFk",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/167318344-e9ea9147-8969-4818-877c-5c75de10aecc.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 142,
  },
  {
    chainId: 101,
    name: "Tamaki",
    symbol: "TMK",
    address: "BqZdsThwaCLRbhDkszc6dRommQtPeEyfmYgWpiALku9o",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BqZdsThwaCLRbhDkszc6dRommQtPeEyfmYgWpiALku9o/logo.png",
    tags: ["utility-token", "governance-token"],
    verified: true,
    holders: 1123,
  },
  {
    chainId: 101,
    name: "Meraki WL Token",
    symbol: "MWTK",
    address: "CurZY9UonzDNmDfB9SgjYAJz2hj6Kx4VYLLkzGozJfJB",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/merakitoken.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1689,
  },
  {
    chainId: 101,
    name: "Soland Presale Whitelist",
    symbol: "SLNDWL",
    address: "Gu2ouFDwgFzwHSWqNQmfNxu2wcKkPwaVh4yWewzD3rb6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/scarceproject/crypto/main/logo-soland.png",
    tags: ["utility-token"],
    verified: true,
    holders: 355,
  },
  {
    chainId: 101,
    name: "Trenbolone",
    symbol: "TREN",
    address: "TRENpVRAR9LiZgyYv9zWrQwYqSHa7ThCYdbpFCJixj1",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TRENpVRAR9LiZgyYv9zWrQwYqSHa7ThCYdbpFCJixj1/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1402,
  },
  {
    chainId: 101,
    name: "WyvernLabs OG Token",
    symbol: "OGTKN",
    address: "3LE7aFahiHN3rLzuLYAL9HeDgiwp1oX1Sg69ZTX8Z61b",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/pinkwyvernlabs.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 174,
  },
  {
    chainId: 101,
    name: "Drivecoin",
    symbol: "DRC",
    address: "8S2ASX2WteonH3bgzgmCpbeQ58kzNVpyeinG71xEWtbW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoyBCgc8uupc1J6v9po4zP6WEyuUWgZjJMMLS3oMmfH1/DamoovDriveCoin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 129,
  },
  {
    chainId: 101,
    name: "Boogy Token",
    symbol: "BOOGY",
    address: "EeiNMyKPDUhdkc8xDupMgMgnSos4GNc7RtLy8S1HB1tq",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/EeiNMyKPDUhdkc8xDupMgMgnSos4GNc7RtLy8S1HB1tq/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 314,
  },
  {
    chainId: 101,
    name: "NFTU",
    symbol: "NFTU",
    address: "NFTUcWc3R1cGzpsh9UzGeKnVeXDTJQjRVL6wUM9Qt98",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NFTUcWc3R1cGzpsh9UzGeKnVeXDTJQjRVL6wUM9Qt98/logo.png",
    tags: ["utility-token", "NFTU", "DAPG", "Dappio"],
    verified: true,
    holders: 970,
  },
  {
    chainId: 101,
    name: "Tear",
    symbol: "TEAR",
    address: "tEArpddkwAQKkCd4jcWqSDp2w1aejGkwSrUu7gjQRPc",
    decimals: 9,
    logoURI: "https://arweave.net/rPlrQvCvaSANJ6u6rPjo9tH5WwCRubgSmPeE9Q-zqus",
    tags: ["utility-token"],
    verified: true,
    holders: 122,
  },
  {
    chainId: 101,
    name: "Crypto Gnomes WL Token",
    symbol: "GNOWL",
    address: "F5tmDpUQaH8QKpv5LihZnfcmMoKtB1HZxyzEfBgLJeaN",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5tmDpUQaH8QKpv5LihZnfcmMoKtB1HZxyzEfBgLJeaN/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 575,
  },
  {
    chainId: 101,
    name: "AKELANS",
    symbol: "AKELANS",
    address: "DpsZg8zMTsdycpikKgYXbZ5FsztBeBiFue2ZgVFErCLs",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DpsZg8zMTsdycpikKgYXbZ5FsztBeBiFue2ZgVFErCLs/logo.png",
    tags: ["meme-token", "community-token"],
    verified: true,
    holders: 3402,
  },
  {
    chainId: 101,
    name: "Whitelist Rotten Ville Bust Sculptures",
    symbol: "WRB",
    address: "FF7rjqkufosL6kTxeXum4BxRhhDrrJXp4VcNZ3bwPjUU",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FF7rjqkufosL6kTxeXum4BxRhhDrrJXp4VcNZ3bwPjUU/LogoRottenVille_Token.png",
    tags: ["whitelist-token", "rv", "ky", "bust-claim"],
    verified: true,
    holders: 479,
  },
  {
    chainId: 101,
    name: "SPRAY - Oh My Girls",
    symbol: "SPRAY",
    address: "spr1yvmfxbPVW9Pn1Nn5uHJzunKXZGLwytSvGgHNv46",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/spr1yvmfxbPVW9Pn1Nn5uHJzunKXZGLwytSvGgHNv46/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1230,
  },
  {
    chainId: 101,
    name: "CATNIP",
    symbol: "$CATNIP",
    address: "FdviznPoMEakdJ37fikNxhoscyruUHSHNkKyvntSqbuo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdviznPoMEakdJ37fikNxhoscyruUHSHNkKyvntSqbuo/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 3955,
  },
  {
    chainId: 101,
    name: "Solana Scouts Token",
    symbol: "SST",
    address: "HawL4cqiNoYdTre8bExsEM3TqrUqigd6YV4fJsNMVEng",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HawL4cqiNoYdTre8bExsEM3TqrUqigd6YV4fJsNMVEng/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 274,
  },
  {
    chainId: 101,
    name: "litit",
    symbol: "LITIT",
    address: "3GhLNM5EuuuBPZyMRweMbGgMWaVdA5YpLWU1NRAERi6d",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GhLNM5EuuuBPZyMRweMbGgMWaVdA5YpLWU1NRAERi6d/lit.it-token-logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1267,
  },
  {
    chainId: 101,
    name: "Lion Pride NFT Whitelist Token",
    symbol: "LIONWLT",
    address: "WLTJb89azAxv6wiPM2yGR1v6CLEoEkSrC1C5JqgsF5h",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTJb89azAxv6wiPM2yGR1v6CLEoEkSrC1C5JqgsF5h/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 303,
  },
  {
    chainId: 101,
    name: "BDAWG",
    symbol: "$BDAWG",
    address: "EkhbjG14GJdnWkKSTdnPSG8xhFDmh855XK1xKAFjYmUR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/BDAWG_coin.png",
    tags: [],
    verified: true,
    holders: 530,
  },
  {
    chainId: 101,
    name: "MIND",
    symbol: "MIND",
    address: "D1pyrui915KubTU59ByWWKuNfNHc9wmJEAq23CGDsGiw",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1pyrui915KubTU59ByWWKuNfNHc9wmJEAq23CGDsGiw/logo.png",
    tags: [],
    verified: true,
    holders: 796,
  },
  {
    chainId: 101,
    name: "CHEES Mint Token",
    symbol: "CHEESMT",
    address: "WLUczF9P5wKLfq53ZSR9UGgBmwxXcELXpECnRyzS2Jp",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLUczF9P5wKLfq53ZSR9UGgBmwxXcELXpECnRyzS2Jp/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2853,
  },
  {
    chainId: 101,
    name: "FFA Whitelist Token",
    symbol: "FFA-WL",
    address: "FFAWLA1yaqYXmbYVofEQYH19mz7WZDb57V2h63BsvoKo",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFAWLA1yaqYXmbYVofEQYH19mz7WZDb57V2h63BsvoKo/logo.png",
    tags: ["utility-token", "whitelist-ticket", "FFA-whitelist", "FFA"],
    verified: true,
    holders: 690,
  },
  {
    chainId: 101,
    name: "Royal Panther Empire Coin",
    symbol: "RPE",
    address: "RPEbt7HCzbJGL24VCzr17pPspFaieFQ44skWsM2332Y",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/rpectdev/RPE-token/main/RPE-COIn.png",
    tags: ["utility-token"],
    verified: true,
    holders: 342,
  },
  {
    chainId: 101,
    name: "Shake",
    symbol: "Shake",
    address: "8dK5tfnxtbCRmqev2iJGqsizRxcPrpaNLSjuVokcbtDx",
    decimals: 2,
    logoURI: "https://i.postimg.cc/7PcVcwjG/BUDDIES.png",
    tags: [],
    verified: true,
    holders: 344,
  },
  {
    chainId: 101,
    name: "AYAKO Whitelist Mint Token",
    symbol: "AYKWL",
    address: "AXBDeu9xakvdJ1dgpnhVrmwKffbhNqSHZgNb4gM99xYC",
    decimals: 0,
    logoURI: "https://i.postimg.cc/XN1xtY1C/5ape-Sn-Ur-400x400.png",
    tags: ["whitelist-token", "Verified_QD"],
    verified: true,
    holders: 4821,
  },
  {
    chainId: 101,
    name: "Hydrogencoin",
    symbol: "HCOIN",
    address: "4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/dataalg/Hydrogen/main/4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 134,
  },
  {
    chainId: 101,
    name: "Fluffy Frens Whitelist Access Token",
    symbol: "FFn",
    address: "8S46ooYiMtmrvpJxg3P3cteEtPuhbvfM65rXj9ywdD4q",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8S46ooYiMtmrvpJxg3P3cteEtPuhbvfM65rXj9ywdD4q/logo.png",
    tags: [],
    verified: true,
    holders: 1733,
  },
  {
    chainId: 101,
    name: "BHSG Spaceship Claim Token",
    symbol: "SPCTK",
    address: "SPCc5sh97jK7bvtxjvGZy94DX6Zi7GDQuZdxDFkxe4h",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JetNnLZpbECCfyeZSvJdgg5gf37QfCkPrp2X8XfmNWn/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1739,
  },
  {
    chainId: 101,
    name: "LYFE Token",
    symbol: "LYFE",
    address: "5LwkUHbof6yxaVJcc7TTc1oMoJpoJxEouidAamvwtCsX",
    decimals: 2,
    logoURI:
      "https://media.discordapp.net/attachments/926535226820276264/974752195864068126/20220514_004708.jpg",
    tags: ["social-token"],
    verified: true,
    holders: 1389,
  },
  {
    chainId: 101,
    name: "The Invasion Lieutenant FCFS WL",
    symbol: "INVNLT",
    address: "Z1hJj8M518eMgErW8PYAQzv3u9EQjsHYvjbkgy51GtE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/theinvasionnft/invasiontoken/main/lieutenanttoken.png",
    tags: ["Whitelist-Token"],
    verified: true,
    holders: 6369,
  },
  {
    chainId: 101,
    name: "MECH - Art Mechanism",
    symbol: "MECH",
    address: "NXH66NhJZ3woe1KCYFGwSJHTtpELuv4Mf8YoVthWtce",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/theinvasionnft/invasiontoken/main/mechtoken.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1284,
  },
  {
    chainId: 101,
    name: "Neon Game Mint Pass",
    symbol: "NgMp",
    address: "8AuVD7P3WBZQcYCy8npWjF5RNgM8Sp3FcnbGVxndYUZi",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8AuVD7P3WBZQcYCy8npWjF5RNgM8Sp3FcnbGVxndYUZi/logo.png",
    tags: ["community-token", "mint-token"],
    verified: true,
    holders: 583,
  },
  {
    chainId: 101,
    name: "Ghekre Gorillaz WL token",
    symbol: "GGWL",
    address: "HrTAyM65ePyJ48uSV4zw7oP7kDqNaM7MxwAUbbGwNVP5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HrTAyM65ePyJ48uSV4zw7oP7kDqNaM7MxwAUbbGwNVP5/icon.png",
    tags: [],
    verified: true,
    holders: 237,
  },
  {
    chainId: 101,
    name: "CARTON",
    symbol: "CARTON",
    address: "FfMRKofNLF3Z38qcuvP72xr4t39kYnWUhwfgt8X7jigd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FfMRKofNLF3Z38qcuvP72xr4t39kYnWUhwfgt8X7jigd/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 1076,
  },
  {
    chainId: 101,
    name: "Ancient Meta Cats WL Access",
    symbol: "AMCWL",
    address: "Gi2AYxsjmRTDTKbNSaq7ieZ2WmWLZyvgQtnupqFPiDH7",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gi2AYxsjmRTDTKbNSaq7ieZ2WmWLZyvgQtnupqFPiDH7/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 184,
  },
  {
    chainId: 101,
    name: "Luna Wolves WL Token",
    symbol: "LW-WL",
    address: "CNLTUm3bYaF9HKQPEjvMFVH7QWnng1QWTeTXZzavLPDq",
    decimals: 0,
    logoURI:
      "https://arweave.net/TDt30NbOgs7CCG-tP-2HCfUlOACXPDEukfv26CEB2No?ext=gif",
    tags: ["utility-token"],
    verified: true,
    holders: 3207,
  },
  {
    chainId: 101,
    name: "PARENT",
    symbol: "$PARENT",
    address: "79BXbiiN5HcXH1vRHbQgs9T9Qmae9por26ULEMXuaP7L",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/PARENT_coin.png",
    tags: [],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "GSWL",
    symbol: "$GSWL",
    address: "EKh6pXWD4pp3U32j7zEe6Bx2LQtwyhuJLz5QV27Kwo4m",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/GSWL_coin.png",
    tags: [],
    verified: true,
    holders: 215,
  },
  {
    chainId: 101,
    name: "EdenSOL",
    symbol: "NSOL",
    address: "FKuNiWqyAaNpc6LpNvR84qcX411WPm3UT42jZy6wZeRf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKuNiWqyAaNpc6LpNvR84qcX411WPm3UT42jZy6wZeRf/logo.png",
    tags: [],
    verified: true,
    holders: 365,
  },
  {
    chainId: 101,
    name: "tsRAY",
    symbol: "tsRAY",
    address: "6JKtKEFiyp67VPN61nTCyUpY1cLrAZ7qpXiBsKqWZqWh",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/168856965-d216c773-a3ad-4e9c-b6f8-f64025c735a5.png",
    tags: ["solfarm", "tulip", "lending", "strategy"],
    verified: true,
    holders: 2212,
  },
  {
    chainId: 101,
    name: "tsUSDT",
    symbol: "tsUSDT",
    address: "AHV6CBjuT7M2HMMKDa5gRSEoBcJGWVjURCBhpgMbyESX",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/168857135-62593b94-1dcd-42d7-8373-ee505a006ed9.png",
    tags: ["solfarm", "tulip", "lending", "strategy"],
    verified: true,
    holders: 206,
  },
  {
    chainId: 101,
    name: "tsSOL",
    symbol: "tsSOL",
    address: "DgnaQdr5VkNbH7n8qN471Hgsw19hUbMBhUs1N3MQKgtu",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/168851932-17ddaac9-979b-4c2f-9838-4a397d961722.png",
    tags: ["solfarm", "tulip", "lending", "strategy"],
    verified: true,
    holders: 1158,
  },
  {
    chainId: 101,
    name: "PAGMI",
    symbol: "PAGMI",
    address: "2mtG2SXAUPBewyFX4evjmCBH5MZHAiA7VFmVVFTxcvQA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mtG2SXAUPBewyFX4evjmCBH5MZHAiA7VFmVVFTxcvQA/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 152,
  },
  {
    chainId: 101,
    name: "JELLYBABIES PHASE ONE WHITELIST",
    symbol: "JELLY_WL_ONE",
    address: "6G2pWrzW5SzsQbpU6LLn5AJwV9FASCWvTNfAPKyFCKHV",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 500,
  },
  {
    chainId: 101,
    name: "JELLYBABIES PHASE TWO WHITELIST",
    symbol: "JELLY_WL_TWO",
    address: "9VthVfjnNoeBzPpN4Dk8U4Nhn5g96ddLb8aAc9xJKiGv",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 549,
  },
  {
    chainId: 101,
    name: "JELLYBABIES PHASE THREE WHITELIST",
    symbol: "JELLY_WL_THREE",
    address: "73pWtZwP3bq5XUYQdPi4jkchGKJpL3pR5U93rh1oFJV3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 3630,
  },
  {
    chainId: 101,
    name: "GLUG Token",
    symbol: "GLUG",
    address: "7i7LiEhcmoqSefeWG2v9v7nXoXNywBT3aMgnt7sz5fAZ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/onuraladi/GlugToken/main/glugtoken.png",
    tags: [
      "art-token",
      "financial-token",
      "monetary-token",
      "educational-token",
      "project-token",
    ],
    verified: true,
    holders: 1007,
  },
  {
    chainId: 101,
    name: "YUMI Token",
    symbol: "$YUMI",
    address: "J64w7g38vBf6XZwie14pJJYSwdTxutEX5XB1dDcDBH4n",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J64w7g38vBf6XZwie14pJJYSwdTxutEX5XB1dDcDBH4n/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 382,
  },
  {
    chainId: 101,
    name: "Dino Jump MEAT",
    symbol: "$MEAT",
    address: "6cwW3gBpWnBU8UAsximLQVfbMySJqBG6LkFsozkr7BTg",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cwW3gBpWnBU8UAsximLQVfbMySJqBG6LkFsozkr7BTg/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 426,
  },
  {
    chainId: 101,
    name: "Fujilist Token",
    symbol: "FUJILIST",
    address: "FujiSB9NYj8bGeEh77bibsK9PnBhRNXLZ5V4t6aMBtfK",
    decimals: 0,
    logoURI: "https://i.imgur.com/Ve9wr7a_d.webp?maxwidth=760&fidelity=grand",
    tags: ["DeFi"],
    verified: true,
    holders: 4410,
  },
  {
    chainId: 101,
    name: "tuUSDT",
    symbol: "tuUSDT",
    address: "gLhY2arqFpmVGkpbBbTi3TeWbsWevA8dqrwbKacK3vJ",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/169034746-d2bef331-4829-442f-8f91-5556e7c60d28.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 2282,
  },
  {
    chainId: 101,
    name: "OATSU",
    symbol: "OATSU",
    address: "8jqjjbFtVQ9u2fDyqNWA6vCuU3wepnfafuaHTLYw8aog",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/OATSU_COIN_ICON.png",
    tags: ["utility-token"],
    verified: true,
    holders: 410,
  },
  {
    chainId: 101,
    name: "Bloodlust",
    symbol: "BLD",
    address: "7vG8c9DyEUj3k6Sf2FYZ9NsN7nUXDE4XWzvkAoUQwjCp",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vG8c9DyEUj3k6Sf2FYZ9NsN7nUXDE4XWzvkAoUQwjCp/logo.png",
    tags: [],
    verified: true,
    holders: 866,
  },
  {
    chainId: 101,
    name: "Hunter Diamond",
    symbol: "HUNT",
    address: "CTYiHf58UGShfHtpkTwx7vjPDA779dd6iVaeD281fEVx",
    decimals: 9,
    logoURI: "https://tokenhunters.app/assets/img/icons/chest-close.svg",
    tags: [],
    verified: true,
    holders: 3507,
  },
  {
    chainId: 101,
    name: "Grams",
    symbol: "Grams",
    address: "4DUQcmSn13YoXHAMfPAT3WDsQb8SmmnC7nom4hcAwRHD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DUQcmSn13YoXHAMfPAT3WDsQb8SmmnC7nom4hcAwRHD/grams_coin.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1037,
  },
  {
    chainId: 101,
    name: "AMIGO-WL",
    symbol: "AMIGO-WL",
    address: "G33nLPsvygo4qT6ocxA5TuJng7d6BGK6844DCLB3kcfC",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/TheAmigosNFT/Logo/main/Logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 5378,
  },
  {
    chainId: 101,
    name: "Bears on Crek Token",
    symbol: "CRAX",
    address: "crSLiJCoVd3UG5KKtj5Yy6c9kqW9Y7afTZefcV6c5tZ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/crSLiJCoVd3UG5KKtj5Yy6c9kqW9Y7afTZefcV6c5tZ/logo.png",
    tags: ["bears", "Crax"],
    verified: true,
    holders: 105,
  },
  {
    chainId: 101,
    name: "Gold Bars",
    symbol: "GBARS",
    address: "8oXhRzmhmf12yrhqHWuso4bKqGnsLNmBfAY2U8w7HwWW",
    decimals: 9,
    logoURI:
      "https://raw.githack.com/solanafrontier/tokenlogo/main/frontier.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 570,
  },
  {
    chainId: 101,
    name: "Kunai",
    symbol: "KUNAI",
    address: "4vasoS3ZVVEaaoMqPFJUbd7s5sbJE2WVt8a6QZNZx64u",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/floppy-labs/public-images/master/kunaitoken.png",
    tags: ["utility-token"],
    verified: true,
    holders: 646,
  },
  {
    chainId: 101,
    name: "MKTV Entertainment Token",
    symbol: "RSPT",
    address: "EkB2Z2jZDLN8YQVnKeJfAYeoUhCVXtwfmq8eS9fH6kpL",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkB2Z2jZDLN8YQVnKeJfAYeoUhCVXtwfmq8eS9fH6kpL/logo.png",
    tags: ["entertainment-token", "social-token"],
    verified: true,
    holders: 10429,
  },
  {
    chainId: 101,
    name: "THC WL TOKEN",
    symbol: "THCxWL",
    address: "3wDZqcNeNzzmrSMn5cTmGK8werqWqRPKqb25HLzHmsA9",
    decimals: 0,
    logoURI: "https://cannabiznft.io/assets/images/logo-final.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1279,
  },
  {
    chainId: 101,
    name: "Trippy Cats WL",
    symbol: "TCWT",
    address: "4f4D9hsgi2LmMG25MVKnGchybhd6nfZGM6CSuoS9Ynu3",
    decimals: 0,
    logoURI: "https://raw.githubusercontent.com/Deghf666g/catswl/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 249,
  },
  {
    chainId: 101,
    name: "BCBY Holder Pass",
    symbol: "BCBYHP",
    address: "BCBYTtmof1sApJsnrb3yqCsyXcArkUHk6LHNKK5B9poE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCBYNAjzL4xqA8KoaqftqFNoq7jKfa5J4YB2nca2VFfp/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 105,
  },
  {
    chainId: 101,
    name: "Mushie Spores",
    symbol: "PORES",
    address: "2jmhtbcbnmqpTaL7ythKYPS64fdb9VGcTNpXoHNbGPrN",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2jmhtbcbnmqpTaL7ythKYPS64fdb9VGcTNpXoHNbGPrN/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 350,
  },
  {
    chainId: 101,
    name: "Aera Token",
    symbol: "AERA",
    address: "BjZ5Hazjyp9LrzfapAHYZuceWm6zJZDqMH1QPCWtsouq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjZ5Hazjyp9LrzfapAHYZuceWm6zJZDqMH1QPCWtsouq/logo.png",
    tags: ["aera-token", "utility-token"],
    verified: true,
    holders: 146,
  },
  {
    chainId: 101,
    name: "BAM (The Sol Den)",
    symbol: "BAMMM",
    address: "BAM2LDmxSjbkRNVQC3bNYo514tGSXTKH3NrW3xQEAkwX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAM2LDmxSjbkRNVQC3bNYo514tGSXTKH3NrW3xQEAkwX/logo.png",
    tags: [],
    verified: true,
    holders: 854,
  },
  {
    chainId: 101,
    name: "tuSHDW",
    symbol: "tuSHDW",
    address: "E8oV8JywpgPxEzaugAzW4bapoN3krEFX9mCoPgvrdTXH",
    decimals: 9,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/169387149-0e34721b-616f-473b-b18d-e46ca40e38ce.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 1877,
  },
  {
    chainId: 101,
    name: "tuBASIS",
    symbol: "tuBASIS",
    address: "GNjwMCt8GmPwQHy45UDzeprHkSTsNQHddsWXADz1HE6H",
    decimals: 6,
    logoURI:
      "https://user-images.githubusercontent.com/8619106/169387491-e7e6c298-eda3-4aed-9e8e-e5e4e8c7c10f.png",
    tags: ["tulip-protocol", "lending", "collateral-tokens"],
    verified: true,
    holders: 1146,
  },
  {
    chainId: 101,
    name: "FLNT",
    symbol: "FLINT",
    address: "Ewf86g35EWcr5dyLSunQkCt5pcnR3hY6bnDAPCKcaye",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/PnW1/flintToken/main/logo.png",
    tags: [],
    verified: true,
    holders: 758,
  },
  {
    chainId: 101,
    name: "Anonymous Token",
    symbol: "ANMS",
    address: "ANMSoho4nmt5ACg4uM9EeprwvjNJAPFXieaS7sECZ3s4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANMSoho4nmt5ACg4uM9EeprwvjNJAPFXieaS7sECZ3s4/logo.png",
    tags: ["staking", "utility-token"],
    verified: true,
    holders: 827,
  },
  {
    chainId: 101,
    name: "THECLUB",
    symbol: "CLUB",
    address: "CLUbn9QKBwbX3rfFTuvQG3HgERzu7R9naLfJas3WuPgx",
    decimals: 9,
    logoURI:
      "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/CLUbn9QKBwbX3rfFTuvQG3HgERzu7R9naLfJas3WuPgx/logo.png?raw=true",
    tags: ["utility-token"],
    verified: true,
    holders: 341,
  },
  {
    chainId: 101,
    name: "Junkiez Token",
    symbol: "JUN",
    address: "71MwukaK2Hudp862BC7X7e8Ggyzqx1aMQD611nRM85Vu",
    decimals: 9,
    logoURI:
      "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/71MwukaK2Hudp862BC7X7e8Ggyzqx1aMQD611nRM85Vu/logo.png?raw=true",
    tags: ["utility-token"],
    verified: true,
    holders: 683,
  },
  {
    chainId: 101,
    name: "SolMonkeez Token",
    symbol: "CNANA",
    address: "7GptK2dfK4u8yNU7MUs8eJoFypkuNaKYTR2pG2WfYpcq",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GptK2dfK4u8yNU7MUs8eJoFypkuNaKYTR2pG2WfYpcq/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 197,
  },
  {
    chainId: 101,
    name: "PUNK",
    symbol: "PUNK",
    address: "8JgJUfALp74xF1zdvU3tsbqn2u8ZvaDBmefuU9Lp759L",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/roben-punk/roben-token/main/RP.jpeg",
    tags: ["nft"],
    verified: true,
    holders: 7470,
  },
  {
    chainId: 101,
    name: "VIBEZ",
    symbol: "VIBEZ",
    address: "FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 3285,
  },
  {
    chainId: 101,
    name: "LLAMA",
    symbol: "ALPACA",
    address: "2zwkTwiN4ABxovt2FaBQpRzVD4Kar7sHi3azBRQZfXSr",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Eziollama/llamatoken/main/llama-logo.jpeg",
    tags: ["nft"],
    verified: true,
    holders: 2611,
  },
  {
    chainId: 101,
    name: "Revolution Token",
    symbol: "RVLN",
    address: "DG2kMSpmGaFxUSVAFLHk75VzJ6iQEQx3UzGkLoGrAX4D",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/Bearzuki/token/logo%20bright.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 300,
  },
  {
    chainId: 101,
    name: "Pengu",
    symbol: "PENGUIN",
    address: "Et4Ni9HzKXByeMx6bPmJLHwLTzhLojxbZe5pZJgpwjYr",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Pawel-Pe/storage/main/logo-penguin.jpeg",
    tags: ["nft"],
    verified: true,
    holders: 3400,
  },
  {
    chainId: 101,
    name: "TREE",
    symbol: "TREE",
    address: "DeGbpw8kYC8YCiH4f6JL9ppMWdJ2nC6GZAxasFXfaqVW",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/crypto-tree/tree-of-life/main/treelogo.png",
    tags: ["nft"],
    verified: true,
    holders: 4945,
  },
  {
    chainId: 101,
    name: "Jungle Cats Token",
    symbol: "GROAR",
    address: "GroARooBMki2hcpLP6QxEAgwyNgW1zwiJf8x1TfTVkPa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GroARooBMki2hcpLP6QxEAgwyNgW1zwiJf8x1TfTVkPa/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 764,
  },
  {
    chainId: 101,
    name: "Mutant Serum",
    symbol: "OKAYMAYC",
    address: "9TeUnTLnQCRAC3erDjgXrpnVgkwcP283VEWM5o8QTNXF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TeUnTLnQCRAC3erDjgXrpnVgkwcP283VEWM5o8QTNXF/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 2030,
  },
  {
    chainId: 101,
    name: "TSF SEEDS",
    symbol: "SEEDS",
    address: "sEedsCkfvPzjnfPNWVJAeNkNZf8yWTwZF3jh42R4X25",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sEedsCkfvPzjnfPNWVJAeNkNZf8yWTwZF3jh42R4X25/logo.jpg",
    tags: ["nft-token", "community-token"],
    verified: true,
    holders: 3711,
  },
  {
    chainId: 101,
    name: "Synthetic Genopets",
    symbol: "sGENE",
    address: "GkpbHQu2zYmJxyp93p9wTX3uHjsFt8ZGeomVwZkGwXLH",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkpbHQu2zYmJxyp93p9wTX3uHjsFt8ZGeomVwZkGwXLH/logo.png",
    tags: ["genopets", "utility-token"],
    verified: true,
    holders: 8977,
  },
  {
    chainId: 101,
    name: "QuokkaCoin",
    symbol: "QKA",
    address: "43nugMCbD2J7BDiDDTxUD15jcLnexdPtftPdANYy9tc6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43nugMCbD2J7BDiDDTxUD15jcLnexdPtftPdANYy9tc6/quokka.png",
    tags: [],
    verified: true,
    holders: 2315,
  },
  {
    chainId: 101,
    name: "SNEK",
    symbol: "$SNEK",
    address: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/zen-logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1092,
  },
  {
    chainId: 101,
    name: "Profit protocol",
    symbol: "PROFT",
    address: "9Tn4dFiSokzKDoiAHVMH2L7TqaRwKbGDz4PCsMS6RkbC",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Tn4dFiSokzKDoiAHVMH2L7TqaRwKbGDz4PCsMS6RkbC/logo.png",
    tags: ["social-token", "SocialFi"],
    verified: true,
    holders: 1564,
  },
  {
    chainId: 101,
    name: "NORY Coin",
    symbol: "NORY",
    address: "HD2WMkhqBqHBeuPaJfziGwSGTaaJTVfWs9drW7yTsLtk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HD2WMkhqBqHBeuPaJfziGwSGTaaJTVfWs9drW7yTsLtk/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 578,
  },
  {
    chainId: 101,
    name: "Big Brain Clique Alpha WL Token",
    symbol: "BBCA2WLT",
    address: "BB2gejCCLsh84iJPjsoZivSEBWn3Y6zZLk6XnVKhPGCv",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BB2gejCCLsh84iJPjsoZivSEBWn3Y6zZLk6XnVKhPGCv/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1128,
  },
  {
    chainId: 101,
    name: "Black Bear",
    symbol: "BLACKB",
    address: "6N3sRS3FGZszkSYgVe4zk8W42jPpQKfcqpe7fv1eC5UF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/harper-bear/bearT/main/logo.jpeg",
    tags: ["nft"],
    verified: true,
    holders: 4831,
  },
  {
    chainId: 101,
    name: "Mitsu Bears Token",
    symbol: "MITSU",
    address: "MTSUtji2nigjKJLoqCgvpdqoDSwpjCoFo8rWRPS8uvS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MTSUtji2nigjKJLoqCgvpdqoDSwpjCoFo8rWRPS8uvS/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 539,
  },
  {
    chainId: 101,
    name: "Roadhouse Runts Whitelist Token",
    symbol: "RR",
    address: "RRTBB9xWcYD9DvaTX7R8UgYbNmxDkVsM2jo1iEXm1Et",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RRTBB9xWcYD9DvaTX7R8UgYbNmxDkVsM2jo1iEXm1Et/logo.png",
    tags: [],
    verified: true,
    holders: 1004,
  },
  {
    chainId: 101,
    name: "ReGuLaTeD Stake Token",
    symbol: "RGD",
    address: "FjA8FNXryYsPEoND6RfUoXffXhZMpy6YDA83G5pz5bwP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FjA8FNXryYsPEoND6RfUoXffXhZMpy6YDA83G5pz5bwP/logo.png",
    tags: [],
    verified: true,
    holders: 2267,
  },
  {
    chainId: 101,
    name: "OMENS SOULS CHEST",
    symbol: "OSOULS",
    address: "8PMGziWVwD7foVgX3WMpm3MkEkXLdwTTgZBUESXK1Nrp",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PMGziWVwD7foVgX3WMpm3MkEkXLdwTTgZBUESXK1Nrp/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 224,
  },
  {
    chainId: 101,
    name: "OddKey Cafe' Tokens",
    symbol: "OKCT",
    address: "HuwsCqtiosGwxiqpd51DMn8y7FBkJSvrLV2DFa2dpoHD",
    decimals: 0,
    logoURI: "https://arweave.net/PIBs4KTZANdrMV6DTzt-M75DpC67zGF1poRtOSznhhw",
    tags: [],
    verified: true,
    holders: 125,
  },
  {
    chainId: 101,
    name: "WNDO",
    symbol: "WNDO",
    address: "E8G4uo2i9d12aGnXDHXXcw6hU2fh2NytR5XR3qurTLBx",
    decimals: 9,
    logoURI:
      "https://static1.squarespace.com/static/6252f1590fac8d79fb6d772e/t/627682f25295251e0967c29c/1651933939477/wndocoin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Fire Rune",
    symbol: "FIRE",
    address: "GdTXHhRr9UoqaYj2MC9hPz4mjE3vigGbUEfjaDCU2onq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdTXHhRr9UoqaYj2MC9hPz4mjE3vigGbUEfjaDCU2onq/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 217,
  },
  {
    chainId: 101,
    name: "Earth Rune",
    symbol: "EARTH",
    address: "8hHra5o2LYCUrog9KrR39uT6WW4meTBtHKCDJbyr44ox",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8hHra5o2LYCUrog9KrR39uT6WW4meTBtHKCDJbyr44ox/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 262,
  },
  {
    chainId: 101,
    name: "Ice Rune",
    symbol: "ICE",
    address: "JAJGBFCgN8YBP1ked9yRd5j52gWfyrkJAHEPQa4UUPDr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAJGBFCgN8YBP1ked9yRd5j52gWfyrkJAHEPQa4UUPDr/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 227,
  },
  {
    chainId: 101,
    name: "Sand Rune",
    symbol: "SAND",
    address: "A1iT9r65MpvabW47nfvKwBDhwMjrja2ub6Fs9iD2k46U",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A1iT9r65MpvabW47nfvKwBDhwMjrja2ub6Fs9iD2k46U/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 240,
  },
  {
    chainId: 101,
    name: "Corn",
    symbol: "CORN",
    address: "HEqxKLV8kW2XYd8XxCH7nPxCa5AYxhF1EqpsXCMUWDpa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEqxKLV8kW2XYd8XxCH7nPxCa5AYxhF1EqpsXCMUWDpa/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 260,
  },
  {
    chainId: 101,
    name: "Prickly Pears",
    symbol: "PRICK",
    address: "4LW9ELPQg45772qU7ZT9q8BP19o3fuXcv8P15fs1MYuX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LW9ELPQg45772qU7ZT9q8BP19o3fuXcv8P15fs1MYuX/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 236,
  },
  {
    chainId: 101,
    name: "Snow Holly",
    symbol: "HOLLY",
    address: "EdWYL5Z4tCCsMtdxPXT5iV9Ycg46U6mvCJDsQV9zQkCm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdWYL5Z4tCCsMtdxPXT5iV9Ycg46U6mvCJDsQV9zQkCm/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 223,
  },
  {
    chainId: 101,
    name: "Magma Peppers",
    symbol: "MAGM",
    address: "FyctSeF2E7pcM3GRQdtfeAYsCPeHNWkgmdYGebB3y97A",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FyctSeF2E7pcM3GRQdtfeAYsCPeHNWkgmdYGebB3y97A/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 218,
  },
  {
    chainId: 101,
    name: "Pine Honey",
    symbol: "PINEH",
    address: "6EMv9MPiTL5oWzkBHr9XmHHtRpUk12nKGfqboDFyY87P",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6EMv9MPiTL5oWzkBHr9XmHHtRpUk12nKGfqboDFyY87P/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 223,
  },
  {
    chainId: 101,
    name: "Sand Scorpion",
    symbol: "SCORP",
    address: "FjJyWJfnb39fdsswQmzNbVxCsH976SQZGFPzXvN5ufwc",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FjJyWJfnb39fdsswQmzNbVxCsH976SQZGFPzXvN5ufwc/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 209,
  },
  {
    chainId: 101,
    name: "Ice Hare",
    symbol: "HARE",
    address: "CnSNBakQSv2MW7pdvdcAHpmUTmvPoChg3NfRtZ7HSaGA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnSNBakQSv2MW7pdvdcAHpmUTmvPoChg3NfRtZ7HSaGA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 184,
  },
  {
    chainId: 101,
    name: "Ash Mice",
    symbol: "MICE",
    address: "HvCbPvCoDhynUffEYP8DeNDxDQ7hetnBxqFWFM6Svsa4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HvCbPvCoDhynUffEYP8DeNDxDQ7hetnBxqFWFM6Svsa4/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 185,
  },
  {
    chainId: 101,
    name: "CRAY",
    symbol: "CRAY",
    address: "GgZGpi7ycFNNFTt33rKH8gbP5dpD3PVHKBhKa5UHqCck",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GgZGpi7ycFNNFTt33rKH8gbP5dpD3PVHKBhKa5UHqCck/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 672,
  },
  {
    chainId: 101,
    name: "CozyCub",
    symbol: "CozyC",
    address: "AXneQDz2f8QcVuPmxNNmme1EZZvKkPxnfP4maB5KHPFp",
    decimals: 0,
    logoURI: "https://i.imgur.com/LVi8Qmi.jpeg",
    tags: ["nft"],
    verified: true,
    holders: 2065,
  },
  {
    chainId: 101,
    name: "Passroom WL",
    symbol: "PR",
    address: "Ef38uckDt5igFxn9kc2tAQQKVmvVat9PMxjybh9ZvvuL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ef38uckDt5igFxn9kc2tAQQKVmvVat9PMxjybh9ZvvuL/logo.png",
    tags: ["WL-token"],
    verified: true,
    holders: 4603,
  },
  {
    chainId: 101,
    name: "BabySamoio",
    symbol: "BBSAMO",
    address: "7ViSurf5Ve2a8qDWFYsfU8GFmRttQvS5paJ8L94QZgo7",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ViSurf5Ve2a8qDWFYsfU8GFmRttQvS5paJ8L94QZgo7/bbsamo.png",
    tags: ["p2e"],
    verified: true,
    holders: 3463,
  },
  {
    chainId: 101,
    name: "Azuria Droids Whitelist Token",
    symbol: "AZDWL",
    address: "9XoLtLc3vGFocje9Jojm6nDkjz82mZQYbBPLDYvFafJL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/AzuriaDev/token-images/bacedbbe6f30a1781aa498ae7db070486acf6bba/azuria-droids-whitelist-token.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1543,
  },
  {
    chainId: 101,
    name: "Drippies Floor Index",
    symbol: "DRIPP",
    address: "uL2qhMckUAroJPt2MLHwEeppJNYE3wBAGFMCs3anwXn",
    decimals: 2,
    logoURI:
      "https://creator-hub-prod.s3.us-east-2.amazonaws.com/drippies_pfp_1648415706808.gif",
    tags: ["fraction-index", "bridgesplit-fraction", "drippies", "fnft"],
    verified: true,
    holders: 315,
  },
  {
    chainId: 101,
    name: "mK OG Chef Whitelist",
    symbol: "mKOGWL",
    address: "DoLDqhWPLrAH4mzsGo54AshM6k94T23GnaFmGVdLTR4r",
    decimals: 0,
    logoURI:
      "https://metakitchendev.onrender.com/static/media/MK-coin.4572f8b4b0d0901217de.png",
    tags: ["whitelist-token", "GameFi", "Gaming"],
    verified: true,
    holders: 2106,
  },
  {
    chainId: 101,
    name: "RCD WL Token",
    symbol: "RCDWL",
    address: "Dc3E5zi7V9MyTrrmD72NoX4pRmDh8xABsFmCVdCme82L",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dc3E5zi7V9MyTrrmD72NoX4pRmDh8xABsFmCVdCme82L/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 133,
  },
  {
    chainId: 101,
    name: "VeraSaw Plant Token",
    symbol: "VRS",
    address: "2YCQcQgy9nNhgukjAur1jCvMXgSTQ5FVDc3ae3BcspXS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2YCQcQgy9nNhgukjAur1jCvMXgSTQ5FVDc3ae3BcspXS/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 22229,
  },
  {
    chainId: 101,
    name: "ArrorDefi",
    symbol: "ARO",
    address: "aro9zmkh9pxkKN9F1FZntQDQCyfbyqv9QdqAwWc7nfJ",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aro9zmkh9pxkKN9F1FZntQDQCyfbyqv9QdqAwWc7nfJ/logo.png",
    tags: ["utility-token", "ecosystem-token"],
    verified: true,
    holders: 1059,
  },
  {
    chainId: 101,
    name: "Miner Tools",
    symbol: "MTWL",
    address: "EAUQ1HsgGX2Xgjc5kYYJ5AxxQnNmhbG4xrWUZp49NN1N",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAUQ1HsgGX2Xgjc5kYYJ5AxxQnNmhbG4xrWUZp49NN1N/logo.png",
    tags: ["social-token", "community-token"],
    verified: true,
    holders: 3974,
  },
  {
    chainId: 101,
    name: "Honest Poker WL (Founder's Pass)",
    symbol: "HPWL",
    address: "4i3285PyW8ansfoHQWH3AadVV6UP39mdxhHS8xBdMtph",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4i3285PyW8ansfoHQWH3AadVV6UP39mdxhHS8xBdMtph/logo.png",
    tags: ["honest-poker"],
    verified: true,
    holders: 1376,
  },
  {
    chainId: 101,
    name: "Planet Zloba - WL Token",
    symbol: "ZLOWL",
    address: "ToPBXCrquTpnEz24bWeYkuXUDmnXdeGGSgTgPeX1sPF",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/6827050a10738590ef327b4c761519fe01913f2d/assets/mainnet/ToPBXCrquTpnEz24bWeYkuXUDmnXdeGGSgTgPeX1sPF/logo.png",
    tags: ["Whitelist-Token"],
    verified: true,
    holders: 301,
  },
  {
    chainId: 101,
    name: "Biker",
    symbol: "FUEL",
    address: "CYbLZDG7TexKi2axdh4gQGLV3FnXvBgfJgLtixhKVytZ",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CYbLZDG7TexKi2axdh4gQGLV3FnXvBgfJgLtixhKVytZ/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Stoned Farms WL",
    symbol: "SFWLT",
    address: "FBHFo6uQheu5WNXhryDUpkDwyCQP6e1iTF56veLVJu3a",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBHFo6uQheu5WNXhryDUpkDwyCQP6e1iTF56veLVJu3a/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2976,
  },
  {
    chainId: 101,
    name: "NAKAMA WL",
    symbol: "NWL",
    address: "CB8wh1bXWugaqaqcA2KxTv1nf5zyU5oTsT6XvT3A6uyh",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/nakamawl.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 7581,
  },
  {
    chainId: 101,
    name: "Plutonium X",
    symbol: "PLMTX",
    address: "P1utjqwB7hEcgDe3u8oHeRCtfyWQY2XEhhCyPFYVhu7",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/P1utjqwB7hEcgDe3u8oHeRCtfyWQY2XEhhCyPFYVhu7/icon.png",
    tags: ["utility-token"],
    verified: true,
    holders: 263,
  },
  {
    chainId: 101,
    name: "Detown",
    symbol: "DTN",
    address: "GsZcjMnaqw6VVpTtQa4ggkjT9w3wNiupUA4AYT2WKTXC",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GsZcjMnaqw6VVpTtQa4ggkjT9w3wNiupUA4AYT2WKTXC/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 117,
  },
  {
    chainId: 101,
    name: "Random FRAKTs",
    symbol: "rFRAKT",
    address: "7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB/rFRAKT.png",
    tags: ["frakt-nft-pool"],
    verified: true,
    holders: 152,
  },
  {
    chainId: 101,
    name: "Random Blockasset Legends",
    symbol: "rLGND",
    address: "9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa/rLGND.png",
    tags: ["frakt-nft-pool"],
    verified: true,
    holders: 121,
  },
  {
    chainId: 101,
    name: "Random Zaysan Raptors",
    symbol: "rZOOM",
    address: "Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9/rZOOM.png",
    tags: ["frakt-nft-pool"],
    verified: true,
    holders: 101,
  },
  {
    chainId: 101,
    name: "UNCHAINED",
    symbol: "MEC",
    address: "GyvLD8DxSEVffv22eEWQ6NdJMbtzoYfPhue7XxCXMKqr",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GyvLD8DxSEVffv22eEWQ6NdJMbtzoYfPhue7XxCXMKqr/logo.png",
    tags: [],
    verified: true,
    holders: 371,
  },
  {
    chainId: 101,
    name: "Sugar Push Alpha Pass",
    symbol: "SPAP",
    address: "ConFQvWZxf3Qdb7yMWPw2Ja1pHPuvYVpbKKunUJ36qqo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ConFQvWZxf3Qdb7yMWPw2Ja1pHPuvYVpbKKunUJ36qqo/logo.png",
    tags: ["community-token", "nfts"],
    verified: true,
    holders: 180,
  },
  {
    chainId: 101,
    name: "Cets Pertel",
    symbol: "PERTEL",
    address: "Dhtv79Gax1gwvWNQCKFW4oUCsMLcmUbsZ6vdaCFYP2Ko",
    decimals: 9,
    logoURI: "https://i.ibb.co/5nmPVbN/Untitled-design-4.png",
    tags: ["utility-token"],
    verified: true,
    holders: 324,
  },
  {
    chainId: 101,
    name: "Rotten Coin",
    symbol: "RoT",
    address: "RoTksthszZDGGZq4uZZZxVmV7xsaz4X8kBESMyeLZkE",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RoTksthszZDGGZq4uZZZxVmV7xsaz4X8kBESMyeLZkE/RoT_Coin.png",
    tags: [
      "rottenville-project",
      "community-coin",
      "utility-token",
      "rotten",
      "kyo",
    ],
    verified: true,
    holders: 216,
  },
  {
    chainId: 101,
    name: "Razr Finance",
    symbol: "RAZR",
    address: "RAZRymwM9F2nP9ZAsojyKRoneydftoqztsx3MnRS9MC",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RAZRymwM9F2nP9ZAsojyKRoneydftoqztsx3MnRS9MC/RAZR.png",
    tags: ["utility-token", "defi-token", "staking-token"],
    verified: true,
    holders: 8512,
  },
  {
    chainId: 101,
    name: "Osmium",
    symbol: "OSM",
    address: "Cf4yCbJs2LZeNLN1jRvLk8q6tgBh7MMG1Rg1fKf5MxbL",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cf4yCbJs2LZeNLN1jRvLk8q6tgBh7MMG1Rg1fKf5MxbL/logo.png",
    tags: [],
    verified: true,
    holders: 1038,
  },
  {
    chainId: 101,
    name: "Wings WL",
    symbol: "WingsWL",
    address: "uGPpPFGMNUbiEZG4kihsqXAGQcb3Egb8mks2pUTzSkj",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uGPpPFGMNUbiEZG4kihsqXAGQcb3Egb8mks2pUTzSkj/logo.png",
    tags: [],
    verified: true,
    holders: 441,
  },
  {
    chainId: 101,
    name: "Trippy Kidz Token",
    symbol: "$KIDZ",
    address: "CDTvfESJbaBDzKV11wTLxmMiutrc5jwBZjASysLbAovF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDTvfESJbaBDzKV11wTLxmMiutrc5jwBZjASysLbAovF/kidzz.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1021,
  },
  {
    chainId: 101,
    name: "CIETY",
    symbol: "$CIETY",
    address: "BhyD7wsPdSfqfDWpbx7S99GAXqHJhX4QsviNNJ6oSzqu",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhyD7wsPdSfqfDWpbx7S99GAXqHJhX4QsviNNJ6oSzqu/Logo.png",
    tags: ["The-Solciety", "utility-token", "NFT-Project"],
    verified: true,
    holders: 117,
  },
  {
    chainId: 101,
    name: "Moonbears Token",
    symbol: "$BEARS",
    address: "7CLDJc5aKJp2WYLGVyNAwdbcnvdKdZuKggNKGo3GigYL",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Robby3082/test-token/main/token_mb.png",
    tags: ["NFT", "Staking"],
    verified: true,
    holders: 1153,
  },
  {
    chainId: 101,
    name: "FINE",
    symbol: "FINE",
    address: "DKCP3dFJrLLATpkeZ3JWAkhWuD45ruyoJndZRsPZzpvH",
    decimals: 9,
    logoURI:
      "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/DKCP3dFJrLLATpkeZ3JWAkhWuD45ruyoJndZRsPZzpvH/logo.png?raw=true",
    tags: ["utility-token"],
    verified: true,
    holders: 1899,
  },
  {
    chainId: 101,
    name: "METASIGNS Mint Token",
    symbol: "METASIGN",
    address: "9bqwh1dX5XZ8QuJWt5bAg6NCemRjg6xf7QquTEBPMNut",
    decimals: 0,
    logoURI: "https://artiftakt3d.s3.amazonaws.com/metasigns.png",
    tags: [],
    verified: true,
    holders: 232,
  },
  {
    chainId: 101,
    name: "Hidden BAGZ",
    symbol: "BAGZ",
    address: "9XxqhRpiNQJ4dViBY1A6nSaUoUgQ9m9JZjCwj3oVu3We",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/HiddenBoyz/BAGZ/main/BAGZ%20Logo.png",
    tags: [],
    verified: true,
    holders: 157,
  },
  {
    chainId: 101,
    name: "DoodleKoalas Coin",
    symbol: "DKCOIN",
    address: "AwoT6gj8cpadFabDfNKsUrrsvQTX1iAtkNiPUw2mmhsV",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwoT6gj8cpadFabDfNKsUrrsvQTX1iAtkNiPUw2mmhsV/logo.png",
    tags: ["community-token", "social-token", "nft", "charity", "dao"],
    verified: true,
    holders: 144,
  },
  {
    chainId: 101,
    name: "DNA",
    symbol: "DNA",
    address: "DNAdXVQZjBa77jHoqij52RDhkDkBq6iCLsJwCRfhzYz2",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DNAdXVQZjBa77jHoqij52RDhkDkBq6iCLsJwCRfhzYz2/logo.jpg",
    tags: [],
    verified: true,
    holders: 426,
  },
  {
    chainId: 101,
    name: "Uranus Token",
    symbol: "ANUS",
    address: "BJkKpQ1tE81msuAxguGSmL47KnxXsNjFG9EJE1e2Sixk",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/realbuckroberts/uranustoken/main/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 367,
  },
  {
    chainId: 101,
    name: "SOLBars Tier 2 Whitelist Token",
    symbol: "SOLBARSWL2",
    address: "SB2KhzJb2kqhkYC6J5DKw449wiHf7gEbGtKA9NEnbaN",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SB2KhzJb2kqhkYC6J5DKw449wiHf7gEbGtKA9NEnbaN/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1972,
  },
  {
    chainId: 101,
    name: "WL tier Moonkee",
    symbol: "WL_BNNA",
    address: "3JyJDoZJywcje4kGSWni822NsXeMNz8UXAMFdWN6Gf41",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/TheMoonkeesNFT/TheMoonkees_logo/main/assets/imgs/wl%20token%20img.png",
    tags: ["wl-token"],
    verified: true,
    holders: 210,
  },
  {
    chainId: 101,
    name: "Okay Cubs Token",
    symbol: "WAGEH",
    address: "CUBSiWEwRnyEvtbYv3ZgRUL5tqrDjXHUwiGdzcpfTud8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CUBSiWEwRnyEvtbYv3ZgRUL5tqrDjXHUwiGdzcpfTud8/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 203,
  },
  {
    chainId: 101,
    name: "Pixel Piglets Adoption Pass",
    symbol: "PIGLETS",
    address: "PigobSSPVECtDpdo1wv7Nzvvq7jKY9KfcsQWqFSitME",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PigobSSPVECtDpdo1wv7Nzvvq7jKY9KfcsQWqFSitME/logo.png",
    tags: [],
    verified: true,
    holders: 1016,
  },
  {
    chainId: 101,
    name: "3DFunGuyz Discounted WL",
    symbol: "3DFUNGUYZ",
    address: "3DFUNs4vDYJcq5suDoFEDGhpbhxFgFius5kgFXBfnNxK",
    decimals: 0,
    logoURI:
      "https://bafkreihebzouugjwf32gryfh7hqraxc5kue4rbsei3w57pks4phmpdfudq.ipfs.nftstorage.link/?ext=png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 385,
  },
  {
    chainId: 101,
    name: "Solpuff WL",
    symbol: "PUFFWL",
    address: "22Pqy9aTRXLN9oe9yEbdpJtmqf5m9mhDj7hqY7TSd2ks",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/puff.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 181,
  },
  {
    chainId: 101,
    name: "Probably Nothing Potion",
    symbol: "PNP",
    address: "CK5K8GUKSGt5u5aJ7wHfeiCTgWMhcEVgo5zxAQ42msv9",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CK5K8GUKSGt5u5aJ7wHfeiCTgWMhcEVgo5zxAQ42msv9/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 302,
  },
  {
    chainId: 101,
    name: "3DFunGuyz WL",
    symbol: "3DFGWL",
    address: "3DWLgzpD6z9PrUY7Hk9CyHHTrGk28aHwcyPhgdQmExTt",
    decimals: 0,
    logoURI:
      "https://bafkreibp4wzfh5lrqr3gqlcduk4hk64qwmosyryhwrqjaa7qqcggyrylqe.ipfs.nftstorage.link/?ext=png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 3071,
  },
  {
    chainId: 101,
    name: "Sardines Revolt WL Pass",
    symbol: "SRVWL",
    address: "ToJfCKXhxRoAjtXkYdcrRuL6L9cAy2iMpnHx1Kvm91j",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToJfCKXhxRoAjtXkYdcrRuL6L9cAy2iMpnHx1Kvm91j/logo.jpg",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 195,
  },
  {
    chainId: 101,
    name: "Akeo Whitelist Token",
    symbol: "AKEOWL",
    address: "TATsdYBQRdCGvpHVxPqcAvo3tAsyjgChhrdPGvRieg5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TATsdYBQRdCGvpHVxPqcAvo3tAsyjgChhrdPGvRieg5/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 5948,
  },
  {
    chainId: 101,
    name: "IUX",
    symbol: "IUX",
    address: "AoqPs243Hh5LrzVmRamz9pEyiemzTBi5N1b4uiVsfrgU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoqPs243Hh5LrzVmRamz9pEyiemzTBi5N1b4uiVsfrgU/logo.png",
    tags: [],
    verified: true,
    holders: 3914,
  },
  {
    chainId: 101,
    name: "BMV Diamond Token",
    symbol: "BMVDIA",
    address: "BMDL7j4okArqyWYmEkpAdWy3HBviRbaz1M3WkrbGib2e",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMDL7j4okArqyWYmEkpAdWy3HBviRbaz1M3WkrbGib2e/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 268,
  },
  {
    chainId: 101,
    name: "BMV Degen Token",
    symbol: "BMVDEG",
    address: "BMV5eEuMtpvNCKa2SHipxNxYMq3HWLWu6RrjaFYwpiRK",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMV5eEuMtpvNCKa2SHipxNxYMq3HWLWu6RrjaFYwpiRK/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 448,
  },
  {
    chainId: 101,
    name: "Burrito Boyz Floor Index",
    symbol: "BURR",
    address: "XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d",
    decimals: 2,
    logoURI: "https://i.imgur.com/EKuyqK8.png",
    tags: ["fraction-index", "bridgesplit-fraction", "Burrito-Boyz", "fnft"],
    verified: true,
    holders: 461,
  },
  {
    chainId: 101,
    name: "DripCoin",
    symbol: "DRIP",
    address: "CYqZ4FG2Jb5Z1CWgdojej9Svhvvo8ohKEAuDvzG3iQ3N",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/drip/dripcoin_small.gif",
    tags: ["NFT"],
    verified: true,
    holders: 1104,
  },
  {
    chainId: 101,
    name: "WEYU",
    symbol: "WEYU",
    address: "EHaEBhYHWA7HSphorXXosysJem6qF4agccoqDqQKCUge",
    decimals: 8,
    logoURI: "https://weyu.io/img/WEYU.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1022,
  },
  {
    chainId: 101,
    name: "PlutoniumX",
    symbol: "PLTMX",
    address: "pLtMXLgfyTsRfZyxnFkJpWqHBxMTvkr4tyMLgyj9wrY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pLtMXLgfyTsRfZyxnFkJpWqHBxMTvkr4tyMLgyj9wrY/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Suteki Sushi",
    symbol: "$USHI",
    address: "SusHi7jkadUEmE7L4CvkNyZroXMDrx1saBFfDSpq6tx",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SusHi7jkadUEmE7L4CvkNyZroXMDrx1saBFfDSpq6tx/logo.png",
    tags: ["Utility-Token"],
    verified: true,
    holders: 2677,
  },
  {
    chainId: 101,
    name: "Haunted Goats Whitelist Token",
    symbol: "HGWL",
    address: "WLtxyA4FdeCpMkZEXtr6FxasUyH4E5CEh5kwFmcYywt",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLtxyA4FdeCpMkZEXtr6FxasUyH4E5CEh5kwFmcYywt/logo.png",
    tags: [],
    verified: true,
    holders: 9332,
  },
  {
    chainId: 101,
    name: "Guild Saga Gold",
    symbol: "$GGLD",
    address: "GGLDx4LZTgz3kMcRpWv3JN2BJkRaZnU8rwG5ANfU3rXw",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGLDx4LZTgz3kMcRpWv3JN2BJkRaZnU8rwG5ANfU3rXw/guild-saga-gold-logo.png",
    tags: ["play-to-earn", "gameFi", "metaverse"],
    verified: true,
    holders: 920,
  },
  {
    chainId: 101,
    name: "Mondays Whitelist Token",
    symbol: "MONWL",
    address: "HAriirKUY6fWN9gQrDUFSsAs7drF96rdK6ov8v2hvti1",
    decimals: 0,
    logoURI: "https://arweave.net/M1r2Lpz4jiuXfqbiZkPwoYh6GP_HR2TU6KN2u5E-N2w",
    tags: ["Whitelist-token"],
    verified: true,
    holders: 1129,
  },
  {
    chainId: 101,
    name: "Solana Underdogs WL Token",
    symbol: "SUWL",
    address: "2ybz3ehQnePURs5tbxQDAo3TpKArqxdbB5yoABjC5TnP",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ybz3ehQnePURs5tbxQDAo3TpKArqxdbB5yoABjC5TnP/logo.png",
    tags: [],
    verified: true,
    holders: 510,
  },
  {
    chainId: 101,
    name: "Reels of Change Coin",
    symbol: "ROCC",
    address: "roccqR8b2s7srXwWGNpoe31zhThcjeQPKy2ghq2ccn4",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/roccqR8b2s7srXwWGNpoe31zhThcjeQPKy2ghq2ccn4/ROCC.PNG",
    tags: ["utility-token"],
    verified: true,
    holders: 1046,
  },
  {
    chainId: 101,
    name: "Trippin' Ape Tribe Token",
    symbol: "TRB",
    address: "3yeGqnYLXya7zPbTkEt2d84F489eV9mNia4WQHY3JefA",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yeGqnYLXya7zPbTkEt2d84F489eV9mNia4WQHY3JefA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6461,
  },
  {
    chainId: 101,
    name: "Sand",
    symbol: "SANN",
    address: "5Em52b2Q72hEpWAuJ44j9L79pQqrx4Ry9AGtziqTNsDi",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Em52b2Q72hEpWAuJ44j9L79pQqrx4Ry9AGtziqTNsDi/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 165,
  },
  {
    chainId: 101,
    name: "Rain",
    symbol: "RANN",
    address: "BytiEsvJAzjaKsyFBVV5BuqowM6k41v6iKYnpx2YmPpa",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BytiEsvJAzjaKsyFBVV5BuqowM6k41v6iKYnpx2YmPpa/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 164,
  },
  {
    chainId: 101,
    name: "TRANSPORTARI",
    symbol: "PORTARI",
    address: "HNohCm8oGNA2qY3MPLz16vEUNfLKV7UXhY2NrhwtJgP2",
    decimals: 0,
    logoURI:
      "https://arweave.net/7Xq547eUy3PnpnxmnGIHSgjo281DoMPsZSwyDnWDEoo?ext=png",
    tags: ["utility-token"],
    verified: true,
    holders: 2545,
  },
  {
    chainId: 101,
    name: "Doodle Bear Token",
    symbol: "DOODLE",
    address: "BSdaqrvjJZ7ekPKtyuhBZuhsGENiQSV4CRGs8Xt3nkDe",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/SolanaDoodleBears/DOODLE/main/Doodle%20Coin%20Graphic.png",
    tags: [],
    verified: true,
    holders: 146,
  },
  {
    chainId: 101,
    name: "Guild Saga Gems",
    symbol: "$GGEM",
    address: "GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF/guild-saga-gems-logo.png",
    tags: ["play-to-earn", "gameFi", "metaverse"],
    verified: true,
    holders: 1007,
  },
  {
    chainId: 101,
    name: "AIO Exchange Token",
    symbol: "AIO",
    address: "4nhQdXfoHvCCVnyZQg3awXqPrKL89Ys7Rbe77oXM47GG",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/adminesc/AIO_EXCHANGE_Token/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 430,
  },
  {
    chainId: 101,
    name: "LAVISH",
    symbol: "LVSH",
    address: "Hat4RZHuSdDGWAwYQkHRzQsKuhtdwJuqugcPz2W4yU5M",
    decimals: 6,
    logoURI: "https://raw.githubusercontent.com/PnW1/LavishToken/main/logo.png",
    tags: [],
    verified: true,
    holders: 1225,
  },
  {
    chainId: 101,
    name: "Akeo Token",
    symbol: "AKEO",
    address: "AkeoLsFzjQ3ZtKXwrGVaa6MSuU7X5wiBX9L5AKAPoFzp",
    decimals: 7,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkeoLsFzjQ3ZtKXwrGVaa6MSuU7X5wiBX9L5AKAPoFzp/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "Trippy World",
    symbol: "WRLD",
    address: "54WShMVn3GKf6xNLc55jidj4M8VW1jpmzdvV7MJPX5JK",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54WShMVn3GKf6xNLc55jidj4M8VW1jpmzdvV7MJPX5JK/logo.png",
    tags: ["utlity-token", "community-token"],
    verified: true,
    holders: 1000,
  },
  {
    chainId: 101,
    name: "XMON",
    symbol: "XMON",
    address: "14UMe2amWfXj1CrM7C9kFkTQ6PtX5aT1fdsVGqBZaXCT",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/14UMe2amWfXj1CrM7C9kFkTQ6PtX5aT1fdsVGqBZaXCT/logo.jpg?raw=true",
    tags: [],
    verified: true,
    holders: 227,
  },
  {
    chainId: 101,
    name: "Skullbots Biker Gang Floor Index",
    symbol: "BOTS",
    address: "HDEqEpFgTrBawzDgTG1eyH8Go9PX84LCEC8Qjt8T4jFN",
    decimals: 2,
    logoURI: "https://i.imgur.com/HSn9q9n.jpg",
    tags: [
      "fraction-index",
      "bridgesplit-fraction",
      "skullbots-biker-gang",
      "fnft",
    ],
    verified: true,
    holders: 175,
  },
  {
    chainId: 101,
    name: "Lis",
    symbol: "LIS",
    address: "HmKT2GJQsfmJU44Xyygzrkey8uzfmCmroFSCdZja8jRD",
    decimals: 9,
    logoURI:
      "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/HmKT2GJQsfmJU44Xyygzrkey8uzfmCmroFSCdZja8jRD/logo.png?raw=true",
    tags: ["utility-token"],
    verified: true,
    holders: 267,
  },
  {
    chainId: 101,
    name: "Legion Fire",
    symbol: "FIRE",
    address: "FiRE67XRcLmVaHnxo46VAVEtvTto9XZMPCqUvC2JSLef",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/SolStonez/Legion-Key-Logo/main/fire-token-logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1005,
  },
  {
    chainId: 101,
    name: "BANDS",
    symbol: "BANDS",
    address: "5nHt8TrjJN9U37WKaCpWftGfsKV91nXwiphJRbMo299p",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5nHt8TrjJN9U37WKaCpWftGfsKV91nXwiphJRbMo299p/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 326,
  },
  {
    chainId: 101,
    name: "GOAT",
    symbol: "$GOAT",
    address: "goatmBAgLyFoHTyyg9NNqGLV4LqTGyjAx1HBn3P1NkD",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/goatmBAgLyFoHTyyg9NNqGLV4LqTGyjAx1HBn3P1NkD/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 5089,
  },
  {
    chainId: 101,
    name: "IMPACT",
    symbol: "$IMPACT",
    address: "3WA8kxjyBTsfvxdTdmJeRtk1SKWCXYNp6QK4n4j5Zsbg",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/LexyVolpe/token/main/60kb.png",
    tags: ["social-token"],
    verified: true,
    holders: 1005,
  },
  {
    chainId: 101,
    name: "True Degenz",
    symbol: "TRUE",
    address: "8i3YUt4kw76AbtXCb6hCxR6k3vHTfAWNsuf5QD67XWwm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8i3YUt4kw76AbtXCb6hCxR6k3vHTfAWNsuf5QD67XWwm/logotrue.png",
    tags: ["community-token", "NFT"],
    verified: true,
    holders: 488,
  },
  {
    chainId: 101,
    name: "Deelerz Token",
    symbol: "DREG",
    address: "ChRdev5KUUaqySyttxDmfKUnXdcsCSCZhmaVTyL5Yn1q",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChRdev5KUUaqySyttxDmfKUnXdcsCSCZhmaVTyL5Yn1q/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 683,
  },
  {
    chainId: 101,
    name: "JUTSU",
    symbol: "JUTSU",
    address: "GKZ1pt5vbMfd3zGxpqfjku12Mw1gp7BaM9eGx9V2fc4b",
    decimals: 7,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GKZ1pt5vbMfd3zGxpqfjku12Mw1gp7BaM9eGx9V2fc4b/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1300,
  },
  {
    chainId: 101,
    name: "WHITES ELIXIR",
    symbol: "WHTE",
    address: "FE4HfAPhoJbGXdQiEbimBZYipcMBJRjGWA5CRHsy8453",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FE4HfAPhoJbGXdQiEbimBZYipcMBJRjGWA5CRHsy8453/logo.png",
    tags: ["Utility-Token"],
    verified: true,
    holders: 140,
  },
  {
    chainId: 101,
    name: "Cyber Raccoons Club WL Pass",
    symbol: "CRCWL",
    address: "To7eo6cfLM4y24hRgKmb1ZyArrPCvb2NUa4kiTZGn1L",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/To7eo6cfLM4y24hRgKmb1ZyArrPCvb2NUa4kiTZGn1L/CRCWL.png",
    tags: ["utility-token"],
    verified: true,
    holders: 2440,
  },
  {
    chainId: 101,
    name: "Battlepunk Whitelist",
    symbol: "BPWL",
    address: "c3x8f2dh7Xgs4hUiusYcE7vsFC2CgKFYdeDkS6R5FL5",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/c3x8f2dh7Xgs4hUiusYcE7vsFC2CgKFYdeDkS6R5FL5/logo.png",
    tags: ["nft", "whitelist-token"],
    verified: true,
    holders: 103,
  },
  {
    chainId: 101,
    name: "TRANSPORTARI II",
    symbol: "PORTARI",
    address: "FhkAPgX25YZBVTR57HGSbMUVGTt2kMaBPZKRgKbte1Qs",
    decimals: 0,
    logoURI:
      "https://arweave.net/4FkQpGdO7i2uFuVg_BIt1rnMKoZ0pVvx_BqBzxE5whk?ext=png",
    tags: ["utility-token"],
    verified: true,
    holders: 2536,
  },
  {
    chainId: 101,
    name: "Soul Mates' White List",
    symbol: "SlMtsWL",
    address: "SMTyWUTw9gFr9MAs8nebGT7a2L65HzGimgB3Sha4Qwa",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SMTyWUTw9gFr9MAs8nebGT7a2L65HzGimgB3Sha4Qwa/logo.png",
    tags: [],
    verified: true,
    holders: 1961,
  },
  {
    chainId: 101,
    name: "Cannaverse Token",
    symbol: "CANNAHEMP",
    address: "DPU4yN7jSA6R4bHp4nMq61AWyXufXHhvvfQaNJnnnEST",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPU4yN7jSA6R4bHp4nMq61AWyXufXHhvvfQaNJnnnEST/logo.png",
    tags: ["utility-token", "governance-token", "gaming", "NFTs"],
    verified: true,
    holders: 321,
  },
  {
    chainId: 101,
    name: "NFTU Prove Token - DappieGang Common",
    symbol: "NFTP",
    address: "EAv8t3gCJpZ2M45oZNtpURmiis1H9kgUgfod27fJGrxo",
    decimals: 0,
    logoURI:
      "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-Common.png",
    tags: ["utility-token", "NFTU", "DAPG", "Dappio"],
    verified: true,
    holders: 1155,
  },
  {
    chainId: 101,
    name: "NFTU Prove Token - DappieGang LegendaryRobot",
    symbol: "NFTP",
    address: "64gXuummCXmQpmCGyufc9zUShSaMgkjTbFyeKeXkBqu2",
    decimals: 0,
    logoURI:
      "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryRobot.png",
    tags: ["utility-token", "NFTU", "DAPG", "Dappio"],
    verified: true,
    holders: 367,
  },
  {
    chainId: 101,
    name: "NFTU Prove Token - DappieGang LegendaryPattern",
    symbol: "NFTP",
    address: "GiLh8u8dsEkhy6ovZ4wNtgQucuMR1s1mYpkH36yjTMvt",
    decimals: 0,
    logoURI:
      "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryPattern.png",
    tags: ["utility-token", "NFTU", "DAPG", "Dappio"],
    verified: true,
    holders: 791,
  },
  {
    chainId: 101,
    name: "Cryptotubbies WL Token",
    symbol: "WLTUBBY",
    address: "8a3NjzFNcXXWRUV243jBs5GwdTSppFbpVo4vDydUtsbB",
    decimals: 1,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8a3NjzFNcXXWRUV243jBs5GwdTSppFbpVo4vDydUtsbB/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1576,
  },
  {
    chainId: 101,
    name: "Blood of Yakuza Whitelist Token",
    symbol: "BOFYWL",
    address: "boyeMJs9YsLhEYHHQHcnjHLUvm6ez8dxRMD9y3ttWR4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/boyeMJs9YsLhEYHHQHcnjHLUvm6ez8dxRMD9y3ttWR4/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 3041,
  },
  {
    chainId: 101,
    name: "WOB Token",
    symbol: "WOB",
    address: "DGXtHxKMFhb82RsqihyEHksgWWe3DXBXEvKjz24JruAP",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/tradefortendies/tokens/master/wobtoken-small.gif",
    tags: ["utlity-token", "community-token"],
    verified: true,
    holders: 7078,
  },
  {
    chainId: 101,
    name: "For Then Solana",
    symbol: "FUCK",
    address: "EG55kTkxsmpJFb6p2qafVC35XJ8Xd1kyJ53xhBNGzeyn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EG55kTkxsmpJFb6p2qafVC35XJ8Xd1kyJ53xhBNGzeyn/logo.png",
    tags: ["community-token"],
    verified: true,
    holders: 1202,
  },
  {
    chainId: 101,
    name: "SOUL",
    symbol: "SOUL",
    address: "DDthcF7L3ALPZGk3WKbKEBxhXceTtVXZshD9qSFJKfGd",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/DDthcF7L3ALPZGk3WKbKEBxhXceTtVXZshD9qSFJKfGd/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 385,
  },
  {
    chainId: 101,
    name: "Extra Reality",
    symbol: "XTR",
    address: "6ABQdaTwRvmacto7aeRBGghS6Pxctd6cFGL8gDdwV1dd",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ABQdaTwRvmacto7aeRBGghS6Pxctd6cFGL8gDdwV1dd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 320,
  },
  {
    chainId: 101,
    name: "StepN Scroll",
    symbol: "SCROLL",
    address: "kshrEkxuc7zPAvLxvabxoERKxK6BfariPcjBoiHvM7B",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kshrEkxuc7zPAvLxvabxoERKxK6BfariPcjBoiHvM7B/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 423,
  },
  {
    chainId: 101,
    name: "EYEZ",
    symbol: "EYEZ",
    address: "4ihMnPfPCRqHiQHB1pjqX43ZUMoGow9GFobhWgjVa1Wm",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ihMnPfPCRqHiQHB1pjqX43ZUMoGow9GFobhWgjVa1Wm/logoeyez.svg",
    tags: ["eyez"],
    verified: true,
    holders: 1084,
  },
  {
    chainId: 101,
    name: "Mundus District Whitelist Token",
    symbol: "MDWL",
    address: "MWL8k1ESrzD4zWiar7jKfFWZwNn4VSHd9rJgcYMeaui",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MWL8k1ESrzD4zWiar7jKfFWZwNn4VSHd9rJgcYMeaui/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1878,
  },
  {
    chainId: 101,
    name: "Shin Sengoku WL Token",
    symbol: "SSGWL",
    address: "8phLV3YLRwud8QohZQnAdKvhjUbte2V75M4wB5uYwPjs",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8phLV3YLRwud8QohZQnAdKvhjUbte2V75M4wB5uYwPjs/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3640,
  },
  {
    chainId: 101,
    name: "MEKAMOUNTS WL",
    symbol: "MKM",
    address: "DEVJp3frkYy9D8oSYcgAapHGAD9pLv4c9ssfBFyNZyJ8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEVJp3frkYy9D8oSYcgAapHGAD9pLv4c9ssfBFyNZyJ8/mkm747-wl.png",
    tags: ["wl-coin-token"],
    verified: true,
    holders: 1549,
  },
  {
    chainId: 101,
    name: "Greed Coin",
    symbol: "GREED",
    address: "47R68vQesdgEAjJN84x7KCHVhabiNenAFfypudyPyemd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/47R68vQesdgEAjJN84x7KCHVhabiNenAFfypudyPyemd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 122,
  },
  {
    chainId: 101,
    name: "CACTI TOKEN",
    symbol: "$CACTI",
    address: "5TuPwHGENJ8ZyvYZB28taMyh1uFUZH4bdFQfTbSxF1vj",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5TuPwHGENJ8ZyvYZB28taMyh1uFUZH4bdFQfTbSxF1vj/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 328,
  },
  {
    chainId: 101,
    name: "Shin Sengoku RYO",
    symbol: "$RYO",
    address: "FeVooZDV8ihVhwuHVbagriw6rtonLdysXQ52VwqTaVAd",
    decimals: 9,
    logoURI:
      "https://storage.googleapis.com/shinsengoku-assets-public/ryo-coin.png",
    tags: [],
    verified: true,
    holders: 2476,
  },
  {
    chainId: 101,
    name: "ND Queen's Favour",
    symbol: "NDQF",
    address: "4ECiWwt2g7TB623UAkyKVWBmcGHGzSTg8pxxknVqhz3X",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/Kikser1214/queen/main/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1379,
  },
  {
    chainId: 101,
    name: "Alien Planet",
    symbol: "TAP",
    address: "FxbkaJPXVJNBz5MGLyPPnAAsZeAHyj6FvvrM2wq3gQfb",
    decimals: 9,
    logoURI:
      "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/FxbkaJPXVJNBz5MGLyPPnAAsZeAHyj6FvvrM2wq3gQfb/logo.png?raw=true",
    tags: [],
    verified: true,
    holders: 184,
  },
  {
    chainId: 101,
    name: "Ingenuity Whitelist Token",
    symbol: "IGTY",
    address: "ingaAW6YRGVFa4NASZFi9TEhxQcuRfGumGLMVxRSav9",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ingaAW6YRGVFa4NASZFi9TEhxQcuRfGumGLMVxRSav9/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1841,
  },
  {
    chainId: 101,
    name: "DeFrame WL Token",
    symbol: "DWT",
    address: "EZANpsohLdDWTT8xrQbYBwrVSz6bg31qRycMw6TTr3bp",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZANpsohLdDWTT8xrQbYBwrVSz6bg31qRycMw6TTr3bp/logo.png",
    tags: ["NFT", "wl-token"],
    verified: true,
    holders: 491,
  },
  {
    chainId: 101,
    name: "Degen Trash Panda Merch Token",
    symbol: "DTPM",
    address: "DTPMARh15YSqggNbMLECj8RxVoxfhtobyyCLiwEeVwZu",
    decimals: 0,
    logoURI: "https://arweave.net/jEVnuhOv79oTVfksVmlJhc7E1GsLgeXM8_7aAp1TjiY",
    tags: ["whitelist-token"],
    verified: true,
    holders: 14007,
  },
  {
    chainId: 101,
    name: "ROGUE Token",
    symbol: "ROGUE",
    address: "rogue3qZEABqmjpL9orMosdBh2a1rfmpoDKfPrztG1K",
    decimals: 7,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rogue3qZEABqmjpL9orMosdBh2a1rfmpoDKfPrztG1K/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 1005,
  },
  {
    chainId: 101,
    name: "KIEF",
    symbol: "$KIEF",
    address: "K1EFss6frvERxWBF3qBmuApG1qFBA8GVXyi2viXGqXK",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/K1EFss6frvERxWBF3qBmuApG1qFBA8GVXyi2viXGqXK/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 427,
  },
  {
    chainId: 101,
    name: "Kozy Coin",
    symbol: "KOZY",
    address: "kozyGtC1y9hRDhvwiLDyvxdREQUyjfjMjFSBTnE492T",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kozyGtC1y9hRDhvwiLDyvxdREQUyjfjMjFSBTnE492T/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 283,
  },
  {
    chainId: 101,
    name: "Mania Token",
    symbol: "MANI",
    address: "B8WQQJ5uBXqGFYbdHhX6Y4YWxXHBtAFEL7YriKtyHH8o",
    decimals: 9,
    logoURI:
      "https://resource-branding-assets.s3.us-west-2.amazonaws.com/sponsor_communi3/nft_mint_token_img.png",
    tags: ["utility-token"],
    verified: true,
    holders: 5662,
  },
  {
    chainId: 101,
    name: "MechaCats WL Token",
    symbol: "MC",
    address: "MciwQ8uyfRYZMGauY6HdvkoUtuZVfLQqq5wcwBLGFEJ",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MciwQ8uyfRYZMGauY6HdvkoUtuZVfLQqq5wcwBLGFEJ/logo.png",
    tags: [],
    verified: true,
    holders: 196,
  },
  {
    chainId: 101,
    name: "DARK COIN",
    symbol: "DMV",
    address: "4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/Shaik-Sirajuddin/RepoForUploadsNeverDelete/master/Images/darkcoin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1043,
  },
  {
    chainId: 101,
    name: "DegenBoyz WL",
    symbol: "DBDWL",
    address: "AKVYLV1cZakQ5HKGiTu9UR9uEv7TwA7VeZyvcL3mHfs3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/dbdwl.png",
    tags: ["utility-token", "whitelist-token"],
    verified: true,
    holders: 1174,
  },
  {
    chainId: 101,
    name: "Nickel",
    symbol: "NKL",
    address: "DUM5J59yrYGukU52i1hC6YGZQ8Bfg7ppVpsYwJDF41f6",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUM5J59yrYGukU52i1hC6YGZQ8Bfg7ppVpsYwJDF41f6/logo.png",
    tags: [],
    verified: true,
    holders: 596,
  },
  {
    chainId: 101,
    name: "NATU",
    symbol: "NATU",
    address: "943NSd9g9t71hZcw3AR3qbHMVCxy6sXWwYKELbzJjVms",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/943NSd9g9t71hZcw3AR3qbHMVCxy6sXWwYKELbzJjVms/natu.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 739,
  },
  {
    chainId: 101,
    name: "ICHIGO",
    symbol: "ICHIGO",
    address: "4tejdaGTug9xWg58f92zmFMrsoWqCnvt8WaVuCiP3uUy",
    decimals: 8,
    logoURI: "https://ichigo.network/logos/logo.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 129,
  },
  {
    chainId: 101,
    name: "Duck Skull",
    symbol: "DS",
    address: "9Cu2sChBnfCJGKUXXXhuvHbTDiEv9Y7KZqPAtLtVeof3",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Cu2sChBnfCJGKUXXXhuvHbTDiEv9Y7KZqPAtLtVeof3/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 360,
  },
  {
    chainId: 101,
    name: "qdot",
    symbol: "qdot",
    address: "HCpyiCXvziKByTGoQMLUmooAWBkCLvZmzB9D6TyPdACR",
    decimals: 8,
    logoURI: "https://cdn.jsdelivr.net/gh/CleverChuk/qdot@main/assets/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 153,
  },
  {
    chainId: 101,
    name: "Neo Hunters Token",
    symbol: "$NEO",
    address: "neoSCa3b4HJR6TF3pVQzph3yrcd97ZMovCVaRC8bRss",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/neoSCa3b4HJR6TF3pVQzph3yrcd97ZMovCVaRC8bRss/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1941,
  },
  {
    chainId: 101,
    name: "Pizza Headz Pielist",
    symbol: "PZHDPL",
    address: "FsHfCRt4A5aiVEgY2Daxqf24vuMCQepJvydkb4Yc8QAd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FsHfCRt4A5aiVEgY2Daxqf24vuMCQepJvydkb4Yc8QAd/logo.jpg",
    tags: ["whitelist-token"],
    verified: true,
    holders: 288,
  },
  {
    chainId: 101,
    name: "Banksea Staking Token",
    symbol: "sKSE",
    address: "SKSE1d9hHn4jJGejGzuwnpD8DMM5TBKQ18ikUFSrx1C",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SKSE1d9hHn4jJGejGzuwnpD8DMM5TBKQ18ikUFSrx1C/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 299,
  },
  {
    chainId: 101,
    name: "SEED",
    symbol: "$SEED",
    address: "2M9dSPWE8ZgryZhTrtrZv43wxuFDAJAwp9pzUPpEuc6D",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/SEED_coin.png",
    tags: [],
    verified: true,
    holders: 251,
  },
  {
    chainId: 101,
    name: "PIPER",
    symbol: "$PIPER",
    address: "8q7rfS8M8jir8U3VdKXsNk7zEitdq9p8WpbpRg7K4cMd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/puresec-ng/gary/main/PIPER_coin.png",
    tags: [],
    verified: true,
    holders: 349,
  },
  {
    chainId: 101,
    name: "TrueSight DAO - Governance",
    symbol: "TDG",
    address: "3wmsJkKWLdFT4tF4rG8zUZQ8M4hKUDtDuJW8q6i9KbgF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3wmsJkKWLdFT4tF4rG8zUZQ8M4hKUDtDuJW8q6i9KbgF/logo.png",
    tags: ["governance-token", "community-token", "social-token"],
    verified: true,
    holders: 118,
  },
  {
    chainId: 101,
    name: "WAR Token",
    symbol: "WAR",
    address: "8YPwxJ7geGS8jjtTYzKaYbgkFWBQQHKtfHS4g2nzXcHt",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8YPwxJ7geGS8jjtTYzKaYbgkFWBQQHKtfHS4g2nzXcHt/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 1016,
  },
  {
    chainId: 101,
    name: "HELL",
    symbol: "$HELL",
    address: "HELLBR3SoErMY1JkcnHMes3xTYoHaRMFJQGzWMATpQf9",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HELLBR3SoErMY1JkcnHMes3xTYoHaRMFJQGzWMATpQf9/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 225,
  },
  {
    chainId: 101,
    name: "DBank Coin",
    symbol: "DBC",
    address: "98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn/logo.png",
    tags: ["utility-token", "community-token"],
    verified: true,
    holders: 1020,
  },
  {
    chainId: 101,
    name: "GOOFY",
    symbol: "$GFY",
    address: "4i565nbEspucgEDbNFNmk47LmJaftQtWUjT9Kx2KnhLA",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4i565nbEspucgEDbNFNmk47LmJaftQtWUjT9Kx2KnhLA/logo.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 235,
  },
  {
    chainId: 101,
    name: "Degoddess Token",
    symbol: "DGD",
    address: "uU1bU1ccSXRTsUNQkDdwbWuFfqZ9iPkxfKnWQff4wrg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uU1bU1ccSXRTsUNQkDdwbWuFfqZ9iPkxfKnWQff4wrg/DGD.png",
    tags: ["utility-token"],
    verified: true,
    holders: 305,
  },
  {
    chainId: 101,
    name: "Okay Bears Solvent Droplet",
    symbol: "svtOKAY",
    address: "AG5j4hhrd1ReYi7d1JsZL8ZpcoHdjXvc8sdpWF74RaQh",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AG5j4hhrd1ReYi7d1JsZL8ZpcoHdjXvc8sdpWF74RaQh/logo.png",
    tags: ["utility-token", "solvent-droplet"],
    verified: true,
    holders: 116,
  },
  {
    chainId: 101,
    name: "Faded Foxes token",
    symbol: "FADED",
    address: "A8VuM8oJfZjB4cbUWTBdc9pPmPoMyUjqa7TtzG3fboGe",
    decimals: 9,
    logoURI: "https://cdn.jsdelivr.net/gh/NinaSnkrs/Superpals/fadedfoxes.png",
    tags: ["utility-token"],
    verified: true,
    holders: 162,
  },
  {
    chainId: 101,
    name: "Superpals Token",
    symbol: "PALS",
    address: "4cH3CvtwURFUVDnV1urH41kwnxfKsYFybonfctxwNSBS",
    decimals: 9,
    logoURI:
      "https://cdn.jsdelivr.net/gh/NinaSnkrs/Superpals/superpalslogo.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 1135,
  },
  {
    chainId: 101,
    name: "PixelPenguin",
    symbol: "PPenguin",
    address: "9esJPERfsEV8hedoXkrBJ74ZJ8BNQEU2WBRuyxomxeBN",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/BreezyBabieMint/trtrtr/main/server_icon.png",
    tags: [],
    verified: true,
    holders: 162,
  },
  {
    chainId: 101,
    name: "Learn",
    symbol: "LRN",
    address: "3UYUHHubaSehdoqADUspBsvTjW8B3LLc1jP271skhRRz",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/alexmita04/gm-logo/main/learn_logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1164,
  },
  {
    chainId: 101,
    name: "Danjon Reborn derug",
    symbol: "DJNRB",
    address: "DJ5TCEN2epuVfqMkgVozSUCTLHi71RNRhQXELCVw12L4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJ5TCEN2epuVfqMkgVozSUCTLHi71RNRhQXELCVw12L4/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 376,
  },
  {
    chainId: 101,
    name: "Crimson",
    symbol: "CRIM",
    address: "AEiGBSooswQgA1nB3AC8QVTDncpnpcqYFE163WwquTFz",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/flipthetip/test-tsc/main/W3B.png",
    tags: ["utility-token"],
    verified: true,
    holders: 113,
  },
  {
    chainId: 101,
    name: "Degen Coinz",
    symbol: "DGENZ",
    address: "4UDbU1he3FzNNQieGcoS3wjXbrm8BDLzM38U8PVzuw36",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/DGENZ.png",
    tags: ["community-token", "utility-token"],
    verified: true,
    holders: 1025,
  },
  {
    chainId: 101,
    name: "Ribh",
    symbol: "RIBH",
    address: "8UaGbxQbV9v2rsMxy2G2NEh1JYQ8T9dmggq1oesrXD9c",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UaGbxQbV9v2rsMxy2G2NEh1JYQ8T9dmggq1oesrXD9c/logo.png",
    tags: ["DeFi", "stablecoin"],
    verified: true,
    holders: 110,
  },
  {
    chainId: 101,
    name: "SHAKING",
    symbol: "SHAKING",
    address: "61bjnxUgvq332wDVe1mtQ7TjFUNTxHGjgfebuj3WaihY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/61bjnxUgvq332wDVe1mtQ7TjFUNTxHGjgfebuj3WaihY/logo.png",
    tags: ["utility-token", "social-token"],
    verified: true,
    holders: 236,
  },
  {
    chainId: 101,
    name: "Baked Cups White List Token",
    symbol: "BCWL",
    address: "586Y61CZjs2cYWJHxBkoYAWzr4Uw6tmNLnGiMspen5hL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/586Y61CZjs2cYWJHxBkoYAWzr4Uw6tmNLnGiMspen5hL/bc_wl_token.png",
    tags: ["utility-token", "whitelist-token"],
    verified: true,
    holders: 163,
  },
  {
    chainId: 101,
    name: "Kreechures Floor Index",
    symbol: "KREECHURE",
    address: "3XwZtyP5g2ku5cNzLXYcBYygvToXKdjw73GSRq73QYLQ",
    decimals: 2,
    logoURI: "https://i.imgur.com/xR8HIrL.png",
    tags: ["fraction-index", "bridgesplit-fraction", "kreechures", "fnft"],
    verified: true,
    holders: 208,
  },
  {
    chainId: 101,
    name: "Great Bulls Whitelist Token",
    symbol: "GBWT",
    address: "GBWT2DcrsgB6FpqtQQNLQp8ZX3wED4JTVHspMzYxGmbn",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GBWT2DcrsgB6FpqtQQNLQp8ZX3wED4JTVHspMzYxGmbn/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 2476,
  },
  {
    chainId: 101,
    name: "WERK",
    symbol: "$WERK",
    address: "WERKZCY6o4eYu9cSh94s1RYC9rQG1parxuvwoW6FZAa",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WERKZCY6o4eYu9cSh94s1RYC9rQG1parxuvwoW6FZAa/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1382,
  },
  {
    chainId: 101,
    name: "Baked Token",
    symbol: "$BAKE",
    address: "BAKeByLipzovqThC8bMpgEycUCTvNcMU82aKu2L5ihcY",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/CMHayden/crypto/master/baked_logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 190,
  },
  {
    chainId: 101,
    name: "TLA Token",
    symbol: "$TLA",
    address: "TLAJct3sZjNPCeu3gX2ia9XXYTGRyRgXN7c6NETQj3p",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TLAJct3sZjNPCeu3gX2ia9XXYTGRyRgXN7c6NETQj3p/logo.png",
    tags: ["NFT", "utility-token"],
    verified: true,
    holders: 1407,
  },
  {
    chainId: 101,
    name: "Art of Mob",
    symbol: "ARTMOB",
    address: "DCdMWbCEbVWQi6i4ndYBAGJkZSy3ZKSRwqJo9TyhBmxR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DCdMWbCEbVWQi6i4ndYBAGJkZSy3ZKSRwqJo9TyhBmxR/logo2.png",
    tags: ["game", "ticket", "fungible", "key", "puzzle"],
    verified: true,
    holders: 205,
  },
  {
    chainId: 101,
    name: "DCS Coin",
    symbol: "DCSC",
    address: "CERSmXza6DcU3vy61sVRHsZBXPsEaHG5edu4jx9VZPvm",
    decimals: 8,
    logoURI: "https://raw.githubusercontent.com/ikhsanhdn/dcs/main/COIN.png",
    tags: ["utility-token"],
    verified: true,
    holders: 111,
  },
  {
    chainId: 101,
    name: "Rekt",
    symbol: "Rekt",
    address: "rekTBEUuEBVz6mqbfLmE9d9JR4jrxV5r91LJfGdzufq",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rekTBEUuEBVz6mqbfLmE9d9JR4jrxV5r91LJfGdzufq/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 637,
  },
  {
    chainId: 101,
    name: "SUGAR",
    symbol: "SUGAR",
    address: "AGFNz4kCrWNrzV3E84MLPVu7qwbYdYbw3gkHBGHG2jXg",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFNz4kCrWNrzV3E84MLPVu7qwbYdYbw3gkHBGHG2jXg/logo.png",
    tags: ["social-token", "utility-token", "community-token"],
    verified: true,
    holders: 213,
  },
  {
    chainId: 101,
    name: "Outerverse Passport Pre-Sale Token",
    symbol: "OVIP",
    address: "vip3cYmE2vuBoNA92h1URVNgXWu23k31b1fbL3b9rVy",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vip3cYmE2vuBoNA92h1URVNgXWu23k31b1fbL3b9rVy/spl-passport.png",
    tags: ["utility-token"],
    verified: true,
    holders: 318,
  },
  {
    chainId: 101,
    name: "DinoGem",
    symbol: "DGEM",
    address: "C6ZkcqRDdBxWoXvgsEuPxEWbmJTSssf3stqMznfETcAT",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/RolandPixeldust/PXCoin/main/degem.gif",
    tags: ["community-token", "metaverse-token"],
    verified: true,
    holders: 211,
  },
  {
    chainId: 101,
    name: "Orbits",
    symbol: "Orbs",
    address: "3TMxuBEMAV3BQunMBrFtKf8UQT2LmJchVbnV2o2ddkZU",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3TMxuBEMAV3BQunMBrFtKf8UQT2LmJchVbnV2o2ddkZU/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 579,
  },
  {
    chainId: 101,
    name: "Sol Suites Token",
    symbol: "SUITES",
    address: "9HYUHuQPixPvRnWCaVWcwFX9o2GGDBJiPWTVveoPVViA",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9HYUHuQPixPvRnWCaVWcwFX9o2GGDBJiPWTVveoPVViA/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1947,
  },
  {
    chainId: 101,
    name: "MIDNIGHT",
    symbol: "MIDNIGHT",
    address: "MDNTEcP4wVVWcpeQvojy1VnunpwtiydMZVzPeWWZYP3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MDNTEcP4wVVWcpeQvojy1VnunpwtiydMZVzPeWWZYP3/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1969,
  },
  {
    chainId: 101,
    name: "Droid DAO Token",
    symbol: "DROID",
    address: "GkywroLpkvYQc5dmFfd2RchVYycXZdaA5Uzix42iJdNo",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LinYu1992/Droid_Capital_Token/main/Droid_coin_tiny_1.png",
    tags: ["utility-token", "community-token", "social-token"],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 101,
    name: "Nezumi Dominion WL1 Token",
    symbol: "NDWL1",
    address: "7gsBUE9ZBSWL3AAx9guwBt2maoVTqv1xo2KHzMorzftj",
    decimals: 0,
    logoURI: "https://raw.githubusercontent.com/Kikser1214/wl1/main/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 1408,
  },
  {
    chainId: 101,
    name: "Nezumi Dominion WL2 Token",
    symbol: "NDWL2",
    address: "Cw2yB2XvmmLpPz6EjxUns3F2yeuB6XCHfKa5qEfp7ddT",
    decimals: 0,
    logoURI: "https://raw.githubusercontent.com/Kikser1214/wl2/main/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 2377,
  },
  {
    chainId: 101,
    name: "PengSolT",
    symbol: "PengT",
    address: "7GnAtLAoiXYhow3mE7szXHfkmWA5QEitbiHDXE4so9Jd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GnAtLAoiXYhow3mE7szXHfkmWA5QEitbiHDXE4so9Jd/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 6793,
  },
  {
    chainId: 101,
    name: "DMSC OG-Collection WL Token",
    symbol: "DMSCOGWL",
    address: "E2ckjDzdKzLt9XJmDGryDKxX5AFZZHGuY6nuYrW7HXEb",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E2ckjDzdKzLt9XJmDGryDKxX5AFZZHGuY6nuYrW7HXEb/logo.png",
    tags: ["nft", "utility-token"],
    verified: true,
    holders: 356,
  },
  {
    chainId: 101,
    name: "GOONZ-WL",
    symbol: "GOONZ-WL",
    address: "3xNzdRumes6PKefrHYbfhCLAGJfBQQhYKTV2fgPrPat4",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/gangstagoonz/Logo/main/logo.png",
    tags: ["whitelist-token"],
    verified: true,
    holders: 4415,
  },
  {
    chainId: 101,
    name: "Piggy Token",
    symbol: "$PIGGY",
    address: "FUtxQAXuC4TgQvF7C5RTRsgqHvPqn4iHhMicRZKV775E",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUtxQAXuC4TgQvF7C5RTRsgqHvPqn4iHhMicRZKV775E/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 883,
  },
  {
    chainId: 101,
    name: "WagmiTicket Tier 3",
    symbol: "WAGMI-T3",
    address: "Bdnh7zauhuBMDfWqMwDjMpmizAsYVznxzbT2ZUpvc19v",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Afterglow421/logotier3/main/logo/logo.png",
    tags: ["utility-token", "whitelist-token"],
    verified: true,
    holders: 293,
  },
  {
    chainId: 101,
    name: "Just Bear Token",
    symbol: "JUST",
    address: "Emead5KsJBa1i53JTMmLJ4MAQrAtQ8x59JdL76jUr751",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Emead5KsJBa1i53JTMmLJ4MAQrAtQ8x59JdL76jUr751/logoJB.png",
    tags: ["utility-token"],
    verified: true,
    holders: 100,
  },
  {
    chainId: 101,
    name: "Trippin' Ape Tribe Mushroom Claim Token",
    symbol: "SHROOM",
    address: "9nhuxLhaMGwUdAcnn53vJeXBt3qtjycSFqGefYGA829n",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nhuxLhaMGwUdAcnn53vJeXBt3qtjycSFqGefYGA829n/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 3099,
  },
  {
    chainId: 101,
    name: "TRUST Token",
    symbol: "$TRUST",
    address: "6UZsQ5sCGCSJyxHbYrEjZTWvXmYsWEcKbd77AKi3oxxi",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/rabindrarajawat/arr-solana/main/trust.png",
    tags: ["utility-token"],
    verified: true,
    holders: 511,
  },
  {
    chainId: 101,
    name: "Kaizokoin",
    symbol: "KZK",
    address: "KzkscetEFcPpwVQV2vfgs7zgA8W1gwdK7w8dJpfq25V",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KzkscetEFcPpwVQV2vfgs7zgA8W1gwdK7w8dJpfq25V/logo.png",
    tags: ["NFT-token"],
    verified: true,
    holders: 3331,
  },
  {
    chainId: 101,
    name: "GOONZ",
    symbol: "GOONZ",
    address: "2E6Wt8f4rzKBtbabBNS2MSMZ2KX57TpK2bLWT8Gq9QDe",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/gangstagoonz/Logo/main/tokenLogo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1044,
  },
  {
    chainId: 101,
    name: "BunnyMask Token",
    symbol: "BMT",
    address: "6h7CpUqee5fACGNJMBc3WaRZiD9QRwWa7SNHPdWqNFPN",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/KaoruYakabe/BunnyMasks/main/logo.PNG",
    tags: ["social-token"],
    verified: true,
    holders: 100,
  },
  {
    chainId: 101,
    name: "New Degen Order WL Token",
    symbol: "NDOWL",
    address: "2PHMZHEnChKsBjRoAQSUAAkb2eUi8KAKYF9kpjbfP41i",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PHMZHEnChKsBjRoAQSUAAkb2eUi8KAKYF9kpjbfP41i/logo.png",
    tags: ["Whitelist-Token"],
    verified: true,
    holders: 4976,
  },
  {
    chainId: 101,
    name: "Genopets Ki",
    symbol: "KI",
    address: "kiTkNc7nYAu8dLKjQFYPx3BqdzwagZGBUrcb7d4nbN5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kiTkNc7nYAu8dLKjQFYPx3BqdzwagZGBUrcb7d4nbN5/logo.png",
    tags: ["genopets", "utility-token"],
    verified: true,
    holders: 5470,
  },
  {
    chainId: 101,
    name: "VRGE Capacity",
    symbol: "$VRGE",
    address: "VRGEZtRamTVKinargJ7WstJHPSzYY9oGSQDYn6awDf8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VRGEZtRamTVKinargJ7WstJHPSzYY9oGSQDYn6awDf8/logo.jpg",
    tags: ["utility-token"],
    verified: true,
    holders: 337,
  },
  {
    chainId: 101,
    name: "Make Solana Great",
    symbol: "WAGMSG",
    address: "Appn8xsma7ajLzsEADNhihNLJt2xVz8V1iqJa6zizqyU",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Appn8xsma7ajLzsEADNhihNLJt2xVz8V1iqJa6zizqyU/logowagmsg.png",
    tags: ["utility-token"],
    verified: true,
    holders: 210,
  },
  {
    chainId: 101,
    name: "WagmiTicket Tier 1",
    symbol: "WAGMI-T1",
    address: "5KNQQe9gfSfgigddMGv5cWg1Z3zFodQP1b2bLDjGuvdE",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/Afterglow421/logotier1/main/logo/logo.png",
    tags: ["utility-token", "whitelist-token"],
    verified: true,
    holders: 236,
  },
  {
    chainId: 101,
    name: "WEDAO",
    symbol: "WEDAO",
    address: "4vqAHZgaQT6e1vGVhFjZVXGEejxJsiXpxsmjmw8LPtRK",
    decimals: 9,
    logoURI: "https://website-bucket.ams3.digitaloceanspaces.com/wedao.png",
    tags: ["utility-token"],
    verified: true,
    holders: 922,
  },
  {
    chainId: 101,
    name: "Boxch",
    symbol: "BOXCH",
    address: "Boxch4vwBdKjZjE4hixCaoQFvv16P9vwRHTFHWbC5o6f",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Boxch4vwBdKjZjE4hixCaoQFvv16P9vwRHTFHWbC5o6f/logo.png",
    tags: [],
    verified: true,
    holders: 1306,
  },
  {
    chainId: 101,
    name: "Dusk",
    symbol: "DUSK",
    address: "DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf/logo.svg",
    tags: ["utility-token"],
    verified: true,
    holders: 1014,
  },
  {
    chainId: 101,
    name: "Pineal Renegades WL",
    symbol: "DMT",
    address: "DMTRrwYy8ipGxNFJewx3cj5UZYRTzh5ypn2xRGMUnKTi",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMTRrwYy8ipGxNFJewx3cj5UZYRTzh5ypn2xRGMUnKTi/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1614,
  },
  {
    chainId: 103,
    name: "Aiko",
    symbol: "Aiko",
    address: "DGGETjRbXeNyq2bpA7FLmWwqjFLtS8p5aYjzUwtAHtZd",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7YBn43cTvko7mfZ9YV1iyAUfHNuMp6aAy4rX7wehXcbU/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 159,
  },
  {
    chainId: 103,
    name: "USDT_ILT_Token_Test",
    symbol: "USDT_ILT",
    address: "3MoHgE6bJ2Ak1tEvTt5SVgSN2oXiwt6Gk5s6wbBxdmmN",
    decimals: 6,
    logoURI: null,
    tags: ["stablecoin"],
    verified: true,
    holders: 520,
  },
  {
    chainId: 103,
    name: "Wrapped SOL",
    symbol: "SOL",
    address: "So11111111111111111111111111111111111111112",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
    tags: [],
    verified: true,
    holders: 100000,
  },
  {
    chainId: 103,
    name: "LGG Dev Fan Token",
    symbol: "LGGD",
    address: "7Cab8z1Lz1bTC9bQNeY7VQoZw5a2YbZoxmvFSvPgcTEL",
    decimals: 0,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Cab8z1Lz1bTC9bQNeY7VQoZw5a2YbZoxmvFSvPgcTEL/logo.png",
    tags: ["LGG"],
    verified: true,
    holders: 115,
  },
  {
    chainId: 103,
    name: "Step",
    symbol: "STEP",
    address: "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 487,
  },
  {
    chainId: 103,
    name: "Devnet StableHog",
    symbol: "sHOG",
    address: "3aMbgP7aGsP1sVcFKc6j65zu7UiziP57SMFzf6ptiCSX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aMbgP7aGsP1sVcFKc6j65zu7UiziP57SMFzf6ptiCSX/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 11819,
  },
  {
    chainId: 103,
    name: "Project SEED Token",
    symbol: "SHILL",
    address: "G3Cb13RiPcTtdKSfZEyhHCpXkgqyTr9BdVvdUbtERHUR",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G3Cb13RiPcTtdKSfZEyhHCpXkgqyTr9BdVvdUbtERHUR/logo.png",
    tags: ["projectseedtoken"],
    verified: true,
    holders: 180,
  },
  {
    chainId: 103,
    name: "Metaplex",
    symbol: "META",
    address: "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m/logo.png",
    tags: [],
    verified: true,
    holders: 33,
  },
  {
    chainId: 103,
    name: "Bers Coin dev",
    symbol: "BERSX",
    address: "DCEXw37nxoFTRmbtbEW4gnBijrqHPSyBt2VirUQYoXfX",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DCEXw37nxoFTRmbtbEW4gnBijrqHPSyBt2VirUQYoXfX/logo.png",
    tags: [
      "mongolian-token",
      "gamefi",
      "ads-token",
      "community-token",
      "nft-token",
    ],
    verified: true,
    holders: 21393,
  },
  {
    chainId: 103,
    name: "FABRIC (devnet)",
    symbol: "FAB_d",
    address: "8RcoapffJxVRoo523cMC8Q7mCZHT5xUWQfQLSjF5Xzox",
    decimals: 8,
    logoURI: "https://dex.fsynth.io/logo.png",
    tags: ["fabric", "synthetics"],
    verified: true,
    holders: 2981,
  },
  {
    chainId: 103,
    name: "I am human (rHPj)",
    symbol: "PTWO",
    address: "rHPjzFU3dJ4zuYMhKXkbF1kKdrDtrc3ys3jZHWBuVHF",
    decimals: 4,
    logoURI:
      "https://devbeta.human.ag/api/profile/Fzmkp2zp3kN4BqAkExhqBgngQKX7ix3vYxp8im9dPh6t/picture?src=token-list",
    tags: ["utility-token", "social-token", "human"],
    verified: true,
    holders: 33,
  },
  {
    chainId: 103,
    name: "USD Coin (Saber Devnet)",
    symbol: "USDC",
    address: "2tWC4JAdL4AxEFJySziYJfsAnW2MHKRo98vbAPiRDSk8",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 49922,
  },
  {
    chainId: 103,
    name: "USDT (Saber Devnet)",
    symbol: "USDT",
    address: "EJwZgeZrdC8TXTQbQBoL6bfuAnFUUy1PVCMB4DYPzVaS",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
    tags: ["stablecoin"],
    verified: true,
    holders: 43346,
  },
  {
    chainId: 103,
    name: "USD Coin",
    symbol: "USDC",
    address: "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 53265,
  },
  {
    chainId: 103,
    name: "GameYoo Token",
    symbol: "GYC",
    address: "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
    decimals: 9,
    logoURI: "https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png",
    tags: [
      "social-token",
      "community-token",
      "utility-token",
      "game-token",
      "game-currency",
      "GameFi",
      "Gaming",
      "metaverse",
      "play-to-earn",
      "play-to-contribute",
    ],
    verified: true,
    holders: 82,
  },
  {
    chainId: 103,
    name: "USD Coin Dev",
    symbol: "USDC-Dev",
    address: "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    tags: ["stablecoin"],
    verified: true,
    holders: 24466,
  },
  {
    chainId: 103,
    name: "Test Tether (LP Finance Devnet)",
    symbol: "tUSDT",
    address: "HY6uvCfBQhKANRxBcYLBK7aUva8mT7mLP2SjrLLmipza",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/usdt.png",
    tags: ["stablecoin", "LP-Finance"],
    verified: true,
    holders: 1177,
  },
  {
    chainId: 103,
    name: "CERO Token",
    symbol: "CERO",
    address: "BepmjSonw41vWsUttUT2L5if1FryrLFXZk3c2LDjEzQn",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/vekinadmin/CO2/main/CERO-ICON.png",
    tags: ["CERO-token", "Carbon-token"],
    verified: true,
    holders: 254,
  },
  {
    chainId: 103,
    name: "KIN",
    symbol: "KIN",
    address: "KinDesK3dYWo3R2wDk6Ucaf31tvQCCSYyL8Fuqp33GX",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6/logo.png",
    tags: [],
    verified: true,
    holders: 1001,
  },
  {
    chainId: 103,
    name: "Aviabee",
    symbol: "AVBE",
    address: "9K8tMEDxk18873U7re7ksz4uYXtz6ECTCSvTcZyQUHLu",
    decimals: 9,
    logoURI: "https://nikkoagustino.my.id/aviabee-coin.png",
    tags: ["utility-token"],
    verified: true,
    holders: 209,
  },
  {
    chainId: 103,
    name: "Unloc USDC",
    symbol: "unUSDC",
    address: "ExW7Yek3vsRJcapsdRKcxF9XRRS8zigLZ8nqqdqnWgQi",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ExW7Yek3vsRJcapsdRKcxF9XRRS8zigLZ8nqqdqnWgQi/logo.png",
    tags: [],
    verified: true,
    holders: 632,
  },
  {
    chainId: 103,
    name: "Orca Dev",
    symbol: "ORCA-DEV",
    address: "orcarKHSqC5CDDsGbho8GKvwExejWHxTqGzXgcewB9L",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png",
    tags: [],
    verified: true,
    holders: 1491,
  },
  {
    chainId: 103,
    name: "Ethereum Dev",
    symbol: "ETH-DEV",
    address: "Ff5JqsAYUD4vAfQUtfRprT4nXu9e28tTBZTDFMnJNdvd",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png",
    tags: [],
    verified: true,
    holders: 167,
  },
  {
    chainId: 103,
    name: "USDC Dev",
    symbol: "USDC-DEV",
    address: "EmXq3Ni9gfudTiyNKzzYvpnQqnJEMRw2ttnVXoJXjLo1",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    tags: [],
    verified: true,
    holders: 940,
  },
  {
    chainId: 103,
    name: "Tam Token",
    symbol: "TAM",
    address: "8GzvVqMkLkFp3f24s9Gn9WeCeP65eoJVTqhxZeobZGWR",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GzvVqMkLkFp3f24s9Gn9WeCeP65eoJVTqhxZeobZGWR/logo.png",
    tags: ["social-token"],
    verified: true,
    holders: 81,
  },
  {
    chainId: 103,
    name: "BIND",
    symbol: "BIND",
    address: "2quyJixqNe3id7TY54vYzhtLYNYLYdfsbbA9nfsoxriF",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2quyJixqNe3id7TY54vYzhtLYNYLYdfsbbA9nfsoxriF/BIND.svg",
    tags: [],
    verified: true,
    holders: 1039,
  },
  {
    chainId: 103,
    name: "G3 Token",
    symbol: "G3",
    address: "GobzzzFQsFAHPvmwT42rLockfUCeV3iutEkK218BxT8K",
    decimals: 9,
    logoURI: "https://static.particle.network/g3.png",
    tags: ["community-token", "trading-token"],
    verified: true,
    holders: 777,
  },
  {
    chainId: 103,
    name: "Diamond (Ducks Casino)",
    symbol: "DCD",
    address: "DucksMNu1erAbMyLEpE8M1sJqjcjQEkyWZz5GfNhSGmK",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DucksMNu1erAbMyLEpE8M1sJqjcjQEkyWZz5GfNhSGmK/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 189,
  },
  {
    chainId: 103,
    name: "WOOD - Metaversium",
    symbol: "WOOD",
    address: "HmitieGZtQ7LRmiFBAA66Yd9uv1Wv1B4qkyLWiK2EeJ5",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/5Ns2m2oBWyj7Kz4VgGgenHZA5dUTJ224AbtjUjaDB651/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1371,
  },
  {
    chainId: 103,
    name: "STONE - Metaversium",
    symbol: "STONE",
    address: "3jczzBwwGB8EMjWLi5DKRatFLtFYCSXBKY46NpqX2fPv",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/2NzAWwLj8GcW3wT9LHSdsyeMTMPCdNp23EAg2YwrLjCz/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1370,
  },
  {
    chainId: 103,
    name: "IRON - Metaversium",
    symbol: "IRON",
    address: "6dZH7pLMqyKPMvgh8zCxPgbrK5kv2EJyqwDFn2tw5i7t",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/ExKEMV2Z9dSHdRg6AkcFMXLPUqzta7zcyK2UQk3a4soy/logo.png",
    tags: ["utility-token"],
    verified: true,
    holders: 1373,
  },
  {
    chainId: 101,
    name: "CatFrogDogShark",
    symbol: "SOLANA",
    address: "fESbUKjuMY6jzDH9VP8cy4p3pu2q5W2rK2XghVfNseP",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmVB3D2Cm19FKUL6sKwoGLxYRggwGV56p66gehhAepWewC",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Lets Fuckin Go",
    symbol: "LFGO",
    address: "2u98MM7DMtVmNG4iAKRNMtynjmkzgD6fXAzB3wVfhQvg",
    decimals: 6,
    logoURI:
      "https://bafybeihigmjodulnlbn6dve7nmmwpkdgno5beid3hx5ku5irfy4wjmredy.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BIRKIN",
    symbol: "BAG",
    address: "Dnpy7ZkE1LvhyTC1hmTb8opJ6g9rgt2oRvN8z7y35WRJ",
    decimals: 9,
    logoURI:
      "https://bafkreigd6ctqmxdljfkneqijeauaysugvfu3fk2wcm72a54n4b55ptjl7u.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Boxxy Coin",
    symbol: "BOXXY",
    address: "EzYyFvUE2AepSxbtdDkkq5rWpN7bXhYzQphZ2gF4Y24R",
    decimals: 6,
    logoURI:
      "https://bafkreigs3ktjx5x5w42c2ad2mwirh54iyfq2a7bapfae3f6jgbh4qpqxmq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Nard",
    symbol: "NARD",
    address: "F89doZeeUe9ajcU6gNR1F5RLaQWcJMdPDNSuLuvJUst9",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/bafybeie62um5xrgkk6ct7eljhxkuuejccao7br4joj2ncsv55j2oro6z7m",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "WATT token",
    symbol: "WATT",
    address: "GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf/logo.png",
    tags: ["old-registry", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cat Wif Hoodie",
    symbol: "CWH",
    address: "2biAFVhHGr13P2wBKeRjnD1HUsBGFBvbHtMyooVZii7E",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmRfHnjPPTpW168G8a5sQCtpwMT7YaxUEQa2eyVkZcP6rD",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Athens",
    symbol: "ATH",
    address: "F9BqFoWRML4Red6YPiL3xJBP7u9g8f61AKJMEYDB3wBR",
    decimals: 9,
    logoURI: "https://arweave.net/GNYZdT1icjRPLbRoivdtSU9_GqBM7LF6khrpV9qCBtM",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "blep :p",
    symbol: "$BLEP",
    address: "Bkd4k5nLA6Aq7PHLJUFnWGSyWriyT2fcKbADScaBGwJy",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmWs45aHW1DQuAYVT51qSRuKVf7naTSJoAqXZbuuCrKR7u",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "WUPHF",
    symbol: "WUPHF",
    address: "8UFSHiHmiqyecJtYUtDDuP8ZVyNfGovj7qgPtLsaP9gh",
    decimals: 9,
    logoURI:
      "https://bafybeigqvnqbugkvrtmiqsx7dvqdkd7xp3mow565kspi6rah3ctkrpfksy.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MOAR GAINZ",
    symbol: "MOAR",
    address: "76gDMbyohg6yHy6XJ2Rn9kxC22Q6v6uYpPXxkiwR5ota",
    decimals: 6,
    logoURI:
      "https://bafkreidv3a37niwg56nytrh2dijfs3vqzebedww6dt5fp2kr46pst7kyui.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "HOPE",
    symbol: "HOPE",
    address: "9Zhvjcce3kHbYQGojybM9GbtE76rvoMJM36egCcmPs2s",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmfJE2zGbSrFzopqis8B9AAfKRZHLYt6GDjcB368zBmvSB",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "KEKW",
    symbol: "KEKW",
    address: "CEYNkwuEXU1KD3MN47NaMvHznPPimR15Sjfv6Y2r1SVw",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmTSxnMHGHdFpjZVUshFSvmmcpCYom9XEkJsS63mYMiDyw",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DLP8 Coin",
    symbol: "DLP8",
    address: "9AGm8DWXiB4PwvH1V2r31ChsTE2f2TDySvDbfJmfaMBq",
    decimals: 8,
    logoURI: "https://arweave.net/T2RURwcLfB2xSjrfksI7R2SL3fKyZoqlTPiLCE3fsBo",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "NAZA",
    symbol: "NAZA",
    address: "FjtwiPH9gyffNX7mdE2ZS4s8hZRfZB2VRzyaKMNpaakS",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmRtiNkm4mrdLm6Fm1BqMX685XVxm7tSRH54dJ8FqfHFaY",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Degenerate Ape Academy",
    symbol: "DAPE",
    address: "6AarZpv8KwmPBxBEZdRmd3g1q2tUBaSgTNQ5e621qcZQ",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreie44hlb5xve225n6p2siebaevxiwha37aefyvs2ul2kx2akalnree.ipfs.dweb.link/",
    tags: ["community", "solana-fm", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "PEPESOLANA",
    symbol: "PEPE",
    address: "CYuXNHURE8cF3rQc1687DZnmsWx9ATNG3mZPFmryu22S",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/85Mup8SQSvcTXsykzNTAtypV2v3zYrYu9eFTRvUUwmXK/logopep200x200.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Crema",
    symbol: "CRM",
    address: "CRMaDAzKCWYbhUfsKYA8448vaA1qUzCETd7gNBDzQ1ks",
    decimals: 9,
    logoURI:
      "https://www.arweave.net/E6II9rfGbg3zzLugMjtbPGf5sM-JKKky2iXGA5SoYjQ?ext=png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "𝝠ndre's Fleet Share",
    symbol: "AFSEX",
    address: "FbUy1NubUtCfoif7TAr6wAtJVJFapYUVWxRGmUwvKNyS",
    decimals: 2,
    logoURI: "https://arweave.net/l5d0WrtUcpUEqwknqjvxlo2QyGryLL3SDsRbigT9HA0",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "World War 3",
    symbol: "WW3",
    address: "7m2TUkpPZCScBhPJnGjWjbh75KkDNnwAdd7i74m8awad",
    decimals: 6,
    logoURI:
      "https://bafybeibkq2ku7q3p6qsj24ac4s3dij4eu5jg47gjd4ra7ln5x77af66sty.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "White Solana",
    symbol: "WHITE",
    address: "FUCKuTfQVT9yCe3jPXdejBPhcPJpnceQy17uvcT9cLx8",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/loufly1/Birdies/main/photo1697130653.jpeg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Piers Murgan",
    symbol: "MURGAN",
    address: "63kAw6KkS7fSqJ4RADJqdYFcB8QVEZz2BjA9m81sEwpN",
    decimals: 6,
    logoURI:
      "https://bafybeiaj2vodsquphqqjrwv2wdk3qkd7tzn4blegshtdzlnkve3dlsbe7e.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Klausen Art",
    symbol: "KART",
    address: "GDzfemoYR5GkbK4YupYpyq3E8Du9fSfKXxKDpkdrqGjs",
    decimals: 6,
    logoURI: "https://arweave.net/X9ohmd49MmOEYi4dfMyOv_M24NnnyzQXZEoALTuQ0ao",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "the keng is beck",
    symbol: "CONUR",
    address: "7yqC6J7qxuRzWg1xHHBmcTLG6X1K7Y5DBYB49Ks8nGKf",
    decimals: 6,
    logoURI:
      "https://bafkreia3bcw6az6avol5rrnkvb5gf6zm4yi252lket7p5ljowmgfb4vt4e.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Garf",
    symbol: "GARF",
    address: "Pz1b7iALFqzsgdL9ca6P3NZvTXwSF1koaQqnNohVFcT",
    decimals: 6,
    logoURI:
      "https://pbs.twimg.com/profile_images/1770950513913139200/7WTpGIxH_400x400.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "The Heist",
    symbol: "HEIST",
    address: "FjBedzf7WrWmo4yd3Tc2U6indeEQXtdewZ514hYxH5X9",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeigch4m7rbec2l255powwbjmacnyj5n5o54qcboiwfgs2nnw6thimq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Twitter",
    symbol: "TWTR",
    address: "FcScaNdN3TRPMwcgqHj1E5GuEh1rNesBGVN5WtZuad1z",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmcjVdSFfWsecrqusiviGb2HJri4PKJjLs8fa3hzycv3xL",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "CHUNGHWA",
    symbol: "CIGGS",
    address: "7p6RjGNZ7HLHpfTo6nh21XYw4CZgxXLQPzKXG72pNd2y",
    decimals: 9,
    logoURI:
      "https://bafkreieagcborzxb4mkn37uvxjq7wzrxuuix76crpyym7by2bkkrik4jn4.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BANANA ZONE",
    symbol: "BZONE",
    address: "6bE23pRia6rvShfBhkFiWF5fRPWXyG6EYvoMvvLss91p",
    decimals: 6,
    logoURI:
      "https://bafybeiffi5g5uinyxlq3ce2htpeiwb77lldkt5gcac7bhgolrlroipgaqi.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "CHAI (Portal)",
    symbol: "CHAI",
    address: "3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/CHAI_wh.png",
    tags: ["wormhole", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SHEPI",
    symbol: "SHEPI",
    address: "2MMJXYvSJuY4C3sSj9eAWVroiLWnuxG7DQieB5PCMjKm",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmZTTfgNrCdTaaunQy7zJXS5MhcQSPxUEr1KiLdYWaKdKq",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "LP Finance DAO",
    symbol: "LPFI",
    address: "LPFiNAybMobY5oHfYVdy9jPozFBGKpPiEGoobK2xCe3",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/LPFiToken.png",
    tags: ["community", "solana-fm", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Solabrador",
    symbol: "SOBER",
    address: "2ZE6hSL36e44wP168YMnxrbi1CSCFuD2BJm7NoNHfsmN",
    decimals: 9,
    logoURI:
      "https://node2.irys.xyz/5xOYKlM26hYXX5wkZoQhc1yw0Bd0Xzoh_SU52TakOZQ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MusCat",
    symbol: "MUSCAT",
    address: "6PPskLwejGjiRbsrdTvPdo8Ayf6rERxvSezcPDA53TAP",
    decimals: 6,
    logoURI:
      "https://gateway.irys.xyz/G5wEXOp8IE0Dr5CCWOpN2XqsplXG3_mhTifkUk1tjOE",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Iced Out Bulls",
    symbol: "ICEDOUT",
    address: "26bedy893CHqi5bcuUFhMgD6uTLw9V9iLWKAQjjDjpEA",
    decimals: 9,
    logoURI: "https://icedoutbulls.github.io/icedoutbulls/io_logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GM Cat",
    symbol: "GMCAT",
    address: "83HDsxuNFnhanLgkTdij3dT7tP5FH3bb1TV1rbTT7atz",
    decimals: 6,
    logoURI:
      "https://bafkreihiz4wpdqhvybs6wofhf3y5grnta4v3ex2hi234obgsuszgjvtdzq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "samo wif hat",
    symbol: "SAMOWIF",
    address: "GS1VjXDZmDFsiqzBFYoACgRQBmXYuvdPJ88NQcXxg3qM",
    decimals: 6,
    logoURI:
      "https://bafybeigf4vhdjusff3au3piubpdiz2xq5lya67vt3ofp4afg5sfl3wweoe.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "YUGE COIN",
    symbol: "YUGE",
    address: "Fpc2tnmme78kjsttyuSjfUfgB14vk15a3P13P9zZYvov",
    decimals: 6,
    logoURI:
      "https://bafkreiawhwcqtxdim7guin2pyepbbgsivkphgq4w24gbnvcwpp7z2aj2cq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Jum Cromer",
    symbol: "JUM",
    address: "43eeGP8k9AZKBSyd9iahhThNtE3vcZvBWMaHNd2akM5s",
    decimals: 6,
    logoURI:
      "https://bafybeicd3jspljny22j65m3wqbx3tbkhw6gis55uygdswj4ootskckalxu.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Enrique Flair",
    symbol: "WOOOO",
    address: "JCqsyVajfHBGjNWNmLT6Cfx1Vn9f3mRibtNSdUsVRWS5",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmPjCNqjV8kEqzXp3wWhzDeN32iU9corqgXedPrcAjmvrx",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Piexeled Pepe",
    symbol: "PPP",
    address: "9HoRXnCcXdTWA1YhYJoPVpp9YByYS3Y8nVV42z1MTBoV",
    decimals: 6,
    logoURI:
      "https://bafybeiavluzjellfa3w2e4lhn2wmypqpznhmogywt3mqy3llzveg722yau.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SHIBA INU",
    symbol: "SHIB",
    address: "5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN",
    decimals: 9,
    logoURI: "https://i.ibb.co/kh4h5xd/11.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MUTANT",
    symbol: "MUTANT",
    address: "mmonechkLNdUxDbvP9Yntwo459QfUmqDdzV95DNvnr6",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/893AmBr2P9NVydpWc2TAkR3prwBtWMZH2A8RniDmduhn/mmonToken.jpg",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "smol cat",
    symbol: "SMOL",
    address: "A9jLULrDSYZkBtKfvG44w8NWzvCN6rGLpoA3hY7TQ85k",
    decimals: 6,
    logoURI:
      "https://bafybeid2q7dpd2bon6qqbhfl6ergnv77lodvzsun6hmigjpd6a3h2shz54.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GMers",
    symbol: "GMERS",
    address: "25yPcyqSpSv8T2JWyoKKRiU622Sr7LoMk9S3FmuBFt8S",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/gmers_pfp_1662860639809.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "One Punch Man",
    symbol: "SAITAMA",
    address: "CVRwdbkwSube1ifeNLFDdZSTmVEnLcyDuKXTdaZX1ua5",
    decimals: 9,
    logoURI:
      "https://static.wixstatic.com/media/eee02f_fc2caa69836949eca0e963201a863f77~mv2.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Pixel By Pixel",
    symbol: "PBP",
    address: "3f7wfg9yHLtGKvy75MmqsVT1ueTFoqyySQbusrX1YAQ4",
    decimals: 9,
    logoURI:
      "https://www.arweave.net/oM5esNEOkJrw6rElkNeg8I_ayWnf_ScNC8P9cgqNsms",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Stable Coin",
    symbol: "SBC",
    address: "DBAzBUXaLj1qANCseUPZz4sp9F8d2sc78C4vKjhbTGMA",
    decimals: 9,
    logoURI: "https://brale.xyz/assets/stablecoins/sbc-logo.svg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ORYS",
    symbol: "ORYS",
    address: "Fa96VxtHsUwGbi8DtjXFjsUjAP6rm18AWw5ezsTN4sxw",
    decimals: 9,
    logoURI: "https://arweave.net/biihR7IHkBTFIqirTaskPxbkUYfyYL0rUYMAgPhETeE",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Tanox",
    symbol: "LTX",
    address: "LTXH7nCGXz5TBZ57H8oZu7YwmDSVfSqWViW4B28yg8X",
    decimals: 8,
    logoURI:
      "https://shdw-drive.genesysgo.net/6EcEYWtMEjonup7RcqStXtfTnYYKavBizgFDGoJoVPZL/image.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Albärt",
    symbol: "ALBÄRT",
    address: "EUroSPBddnvGhRGWj56S864XWG6wgJ42yLsHSNyAWBj",
    decimals: 9,
    logoURI:
      "https://sx4dke57tosdo6nonr3ecrmgtsnzccgdtaeta3ym6wpbejgnx5ra.arweave.net/lfg1E7-bpDd5rmx2QUWGnJuRCMOYCTBvDPWeEiTNv2I",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Phoenix",
    symbol: "PHNX",
    address: "JAzesW3tU2VLDx99pwgkDMca2DTNsEhSWG8sj21B1niz",
    decimals: 6,
    logoURI:
      "https://bafybeicmwtzskjhcsnoymhsv5fmlruwoxgounmcweiei2qnxh6ayesupiq.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Rafkróna",
    symbol: "ISKT",
    address: "isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu",
    decimals: 2,
    logoURI:
      "https://raw.githubusercontent.com/rafmyntasjodur/iskt-metadata/main/logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "shork",
    symbol: "SHORK",
    address: "BJB5tHWAHboMAwti5AHbCNX17F3jL8ehkh4zuTUx99Zn",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmZ4PJT5qZ1MyMwaYz1dbDD3kpBE4bkskwmampKAQWuNRe",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "WAM (Wormhole)",
    symbol: "WAM",
    address: "7hdeo5QciUF8S2vfsx6uRJkdNVADBU3DDcXW4zjDcMin",
    decimals: 8,
    logoURI:
      "https://bafkreib4rpt2mttfg75fvvsgg3g725chzzpkqz4ci5kccryiaqusl5suei.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Rats on SOL",
    symbol: "RATSOL",
    address: "HNRrr5YBYgBz3orDGVc4pGQsc5mnLtyVtfKaMt4WNDMz",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmX5wDBzYMmFhfFbrSQ5hg2Z6HArCYi3uYMNg5hX5fqxH3",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Jorlps",
    symbol: "JORLPS",
    address: "EA4SyW5UDnntXwUyyogN9UgpLU4q71HWgjhJBFUHTqLY",
    decimals: 9,
    logoURI: "https://i.imgur.com/6T2GZ98.jpeg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Duck Punkz Universe",
    symbol: "DPUNKZ",
    address: "McpgFn2CxFYFq6JLiBxeC6viNfebLsfsf9Sv5wcwKvL",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/duckpunkzuniverse_pfp_1650542071140.gif",
    tags: ["community", "solana-fm", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Trippin' Ape Tribe Floor Index",
    symbol: "TAPES",
    address: "DxRbkqB2cEqa4rnXJwq4z3GuksgpL2U27PWBw8QBcNv2",
    decimals: 2,
    logoURI: "https://i.imgur.com/iFgvQva.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DABLOONS",
    symbol: "DABLNS",
    address: "dab15vg2k8zGJPy4xM2DH2G2BY3khrqduXapzYAV3y8",
    decimals: 8,
    logoURI: "https://arweave.net/AYbNmLsPhYrlgrVtCERbp7UysLGL-IXQQrTL826ai6Y",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Chads",
    symbol: "CHADS",
    address: "DjzG1gPn2cotdV9cvkho4KxD3Poy2VjJeghCivv8U8Wj",
    decimals: 2,
    logoURI:
      "https://pbs.twimg.com/profile_images/1644310237896114183/c0KZkqJ3_400x400.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BASC",
    symbol: "BASC",
    address: "DM3Y4R7n1HGhP9AkNT6Ex4w1qQTpgq1TyujrMDX11FMT",
    decimals: 9,
    logoURI: "https://arweave.net/7qhG_tPq_PY6xc9yRamTq-o3tceb3z9iioH9383-rBk",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "FillmorePHX",
    symbol: "FPHX",
    address: "6gx6Ph2ek73kF6EWDrG4GQ54pcLJB6CYpATuRyxKXumo",
    decimals: 9,
    logoURI:
      "https://gateway.irys.xyz/lUbE3hfwk7lOAeHT5ct_u2TfhBQMe7q9wFG4G9baElI",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Independence Token (Wormhole)",
    symbol: "RFKJ",
    address: "3Tnv2xxSpLDQ1hfRLjWWPYz7vHPWoE5fsCPoG31EwTtv",
    decimals: 8,
    logoURI:
      "https://ipfs.io/ipfs/QmT3hfVNRXY3hRQjAkqLuzczoYUHqJAZe5kk8Z3niqr6gu",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Polygone on SOL",
    symbol: "POLYGONE",
    address: "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
    decimals: 5,
    logoURI:
      "https://bafkreidlnj7ne4bnygpn45x2k464vw7xzudib3vtecqwkczo4adbcnn2sm.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Robert",
    symbol: "ROBERT",
    address: "AoN2z7w7ccQJQiWS7rjS45dcyYkVkBddXDcrzmj69tqf",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/scoops0/Robert-Logo/main/Robert%20Logo.webp",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Puri",
    symbol: "PURI",
    address: "CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB",
    decimals: 9,
    logoURI:
      "https://gateway.irys.xyz/ILXdxJTexpeuGPKMjPtP4wBmvg4x3GlI3nc0n4V9RIU",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BULLZ",
    symbol: "BULLZ",
    address: "CdkXjsEokNWMabBn2HCrPcXNmSDE59QKtseLh1j9tEwd",
    decimals: 6,
    logoURI:
      "https://img.fotofolio.xyz/?url=https%3A%2F%2Fbafkreida4wh6oubxpjxdhldhhdirjdjhiebkyvdilvdshofsxwizmw4gba.ipfs.nftstorage.link",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Dragon",
    symbol: "MUSHU",
    address: "CF97pGa5aNhXYkERsYLbAZ1wyk6cSFrfKhd1ansRD3So",
    decimals: 10,
    logoURI:
      "https://bafybeiesfkh2pusubfi4xjttjerzvuyhfp4qwjj24atubpi6zs4l3zvcoy.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Time Traveler",
    symbol: "BARRON",
    address: "HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ",
    decimals: 6,
    logoURI:
      "https://bafybeibew7y6kwsvmuictnamsabp6yjchcaflxhopimtzwkfh3dofrgwgi.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Claynosaurz",
    symbol: "CLAY",
    address: "AYtg18TFRQs1mCQ29zWY3aSwxwTcW1uTdZ65dL3WiBQu",
    decimals: 2,
    logoURI: "https://i.imgur.com/dd9tpCS.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SolarMoon",
    symbol: "MOON",
    address: "2kMpEJCZL8vEDZe7YPLMCS9Y3WKSAMedXBn7xHPvsWvi",
    decimals: 5,
    logoURI:
      "https://gateway.ipfscdn.io/ipfs/bafkreifwdwgcv6fnh5icz3wkefokatsemsojck4hftsnuf4xcfjcvagsva",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TURBO on Solana",
    symbol: "$TURBO",
    address: "42KmnheUHt3UaQFoAKnipLgBJ1Zm427heJdQcHGPtnjc",
    decimals: 7,
    logoURI: "https://arweave.net/FVrzsx6r7mwCakD64Axt59qAB_g-nvYfA0wvWydw5u0",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GOFURS Del Sol",
    symbol: "GOFURS",
    address: "4xnxNjLkeVoJEAUFjj5xTvkdTLGYHtrdyyXThGDFhwmr",
    decimals: 6,
    logoURI: "https://arweave.net/X9m1CVUfzInuKry4cjZrarZE_omTtbwPXrSeaHvkvWE",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "jeo rogen",
    symbol: "ROGEN",
    address: "DtgDZb83TqywcuBuWE89jx4k5Y7b6nQ4GYJq3Wd61JQQ",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmZ5WeZCweWhbKbJ7Ux1ewxVVMrwz5dYL6szeBdT59f4AF",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "borack omaba",
    symbol: "OMABA",
    address: "AZ7ABJtSeZoFHhNzWhMVREMgGKZVCN8nEZwQfyZdALSZ",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmXh4hdF37yyPmsDys1LzLuvBbADAVqSHPEb75GHfchdy3",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "hiji",
    symbol: "HIJI",
    address: "EjErrBoCw7eWYkMfimhPckaPSuBukyhUYwv2dLJYDWB7",
    decimals: 6,
    logoURI:
      "https://bafybeiad4uvvie6qhyuo7qajzhdmx7dawela534l2kbgo4fbift37irsxm.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "COSTCO PIZZA",
    symbol: "PIZZA",
    address: "DZvuAtqMdWzDHMGDpTkRmW2QBxstjCNTcobMDMpVuRDa",
    decimals: 8,
    logoURI:
      "https://gateway.irys.xyz/DMy8cbi1T5k-1dMUyT2TaGGmV_UHvz5D9whcItR706I",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "CoolCoin",
    symbol: "COOL",
    address: "3wNmRxyZPzDWVAydsF3VuNKCNk89A8uSo7EzTnubDJcR",
    decimals: 9,
    logoURI:
      "https://bafkreigdop6clz6yo46or5cxdqoxund5jfz2iyrrr7pfixrxgpcfcaqlau.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "FEEL",
    symbol: "FEEL",
    address: "FEELeKVxHtsHHvu1ARySjETxnJMKbLC6wHCaMD68ipiR",
    decimals: 9,
    logoURI: "https://api.feel.market/static/feel.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Milady",
    symbol: "MILADY",
    address: "HB2pjBPuquh1XGEVC1GCKepdh4daoJnkJiHFmaorfBWu",
    decimals: 9,
    logoURI:
      "https://bafkreiancqnp4k3varzynj4xuaigl3674r7ok4rkgwherzwkwqyevq23mi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Solchan.org",
    symbol: "SOLCHAN",
    address: "ChanM2vka4gJ3ob1SejJXnxoNuoAXGGJxDMJRSLD7nzW",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Kuroshades/solchan-data/master/logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ELMNT",
    symbol: "ELMNT",
    address: "9xYeZDHEwyuqJmqrTourbFRaxN2qhkYesnz3iQ3FPz4r",
    decimals: 5,
    logoURI:
      "https://pbs.twimg.com/profile_images/1656328676768620549/ynTCpCiz_400x400.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TAO INU",
    symbol: "TAONU",
    address: "6ZT7hoeMNfYua5oJ67EQJbFJHUBVLuFBbCKduRuk1rXr",
    decimals: 9,
    logoURI:
      "https://bafkreifnmhquoardsg4reiatuemvxrvwy3m4f4nde6lzkg7uegw6zz7gae.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "nonce pesoli",
    symbol: "NONCE",
    address: "3wDQAHLuaaotFajeJXpJpNMio3LBhSTscHa8Xq5UwLo2",
    decimals: 6,
    logoURI:
      "https://bafybeia4j7ydmcgorosacsow6n4ae5xxzin6liod4jxj2ppe2e5ogzcaaq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ASTRALIS",
    symbol: "ASTRALIS",
    address: "ASTRALvKjGK2xk2pamjMBU5dav5cEQa6zpKCP6FZ7BAJ",
    decimals: 6,
    logoURI:
      "https://shdw-drive.genesysgo.net/AcG1tjtJ3vR1XzSCcVArheGLrwmMsphqExa6VodLymGF/astralis_1500x1500_8bit.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Fux Nous",
    symbol: "FUX",
    address: "pz8KjM9AHRu8MvjEbxHjZ6RwxQjTgxvTtRsaQtCUyQf",
    decimals: 6,
    logoURI:
      "https://pump.mypinata.cloud/ipfs/QmZ6U95NQDey6atv55J4TCdcpTc7yZWwZmeiNwNg4LouYU",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SolGPT",
    symbol: "GPT",
    address: "Eyhi3qZCW1hNgsLtv1geaeDgZJmRFCpEtbjABWfGcGx5",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmbzeT99i7YNMAXbjKbHq6zEuiPgEj7GwDvX5ZAz9cDYCo",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "LUX Token",
    symbol: "LUX",
    address: "LUX88ZHPnte7tThA4F2nnXDCZWE3G61TqLBvFw7i8SM",
    decimals: 9,
    logoURI:
      "https://node1.irys.xyz/5SOEbPheL3FXZpm2EGo7zJrz6QHM6h6-qotrLB5HsGo",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Ham Water",
    symbol: "HAMWTR",
    address: "HSwR3tZuxQKq8ddz9YxR4BX65Znm6MNdWGURw3x5FJQi",
    decimals: 9,
    logoURI:
      "https://node1.irys.xyz/tYbgasKcMQhtUNQ-wjrpGLrD2C6ylEzkuqN-vzTJ-L4",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "First Youtube dog  - Community",
    symbol: "RUSTY",
    address: "Ayy1QvG5vR6nJ9fdijWWTrvNmjVfEhGGoQrX9nhZ6Dg3",
    decimals: 9,
    logoURI:
      "https://bafkreicizeunawx46hqnp4sprlukw5uk7bededjuiit3o6ojk3lkfnh5ty.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Jordan Belfort",
    symbol: "JORDAN",
    address: "HKmEsdtmRm9WXuEHgJbU3oHyij4EwB3YtEuUrm5qEJFZ",
    decimals: 9,
    logoURI:
      "https://bafybeih64j4xgfzm7vgu5ivy6fm6fi2gshh7mug4tzsfray73ykt7lynsm.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Magic Crystal Coin",
    symbol: "MC",
    address: "FYUkUybywqUUyrUwiAezbvhTp2DUgx1eg8tQNiKkXqJ9",
    decimals: 9,
    logoURI: "https://arweave.net/Nwami1xs2ciMI7mUcawKMkUTKf_UUrLjYvIqUSL1ShI",
    tags: ["community", "solana-fm", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Kowalski",
    symbol: "KOW",
    address: "3WoatNLfMffazu8aCSmRSdWFngpJQ4GUMFJxtSzoDqau",
    decimals: 6,
    logoURI:
      "https://bafkreihdkgipc7nho7lix35i54gupf56hpnazx6d5jbqtxtmg34iifefga.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TEST",
    symbol: "$TEST",
    address: "D8TFo4FsDF9CnejkezEZtwdhdmwaiNgLRDAQUTwWQuBU",
    decimals: 6,
    logoURI:
      "https://bafkreia7lr4higfpv6xc5owc3m2vjgcq2zzb7nqoaeut2lqcfy4pplpy5u.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cramer Coin (Wormhole)",
    symbol: "CRAMER",
    address: "E3tHHRa9Y1TWAA1nPZo8FAyVFgBDnXrS8u8nmMShL3M3",
    decimals: 8,
    logoURI: "https://i.imgur.com/6ERdMOo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "OPOS Coin",
    symbol: "OPOS",
    address: "3byG6HEKhG5gUZ5PZU9zKkFaEHYPveGEtvs68LYR8R5N",
    decimals: 4,
    logoURI: "https://arweave.net/fivrynPZdJv5ajd4d1TXW1yHArDA-RvkYYfDoh-_4EU",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SMORES",
    symbol: "SMORES",
    address: "smoEhMZMweWBnpd1QoU4ZjuVNBxMFchqy4NRMBbtW7V",
    decimals: 6,
    logoURI:
      "https://shdw-drive.genesysgo.net/2iHNWQtfUsth1F7dy6Mtjr9dHmYPZN7TZS4W2GcEwNTE/smores.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SCP",
    symbol: "SCP",
    address: "5sMyPtYRcrEVt27DW3xhGVVha3zCXLv4caVt88PXjBgV",
    decimals: 9,
    logoURI: "https://scpri.me/files/scp/logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Water Bears",
    symbol: "WTRBR",
    address: "Aymdf5Fp2URJNcsHpEF2NqEqFHiqvvhrDvYQtyZFw7zn",
    decimals: 4,
    logoURI: "https://arweave.net/GZNQl83S1kK5kvak1yvMrI8muuPVm80fdliPcgwsWfw",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DeadCoin",
    symbol: "DEADCO",
    address: "r8EXVDnCDeiw1xxbUSU7MNbLfbG1tmWTvigjvWNCiqh",
    decimals: 9,
    logoURI: "https://arweave.net/4JJ_OkspoUbBeArWjMUbD5NrfQdC2PcxDIED_PUT93Y",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BōKU Labz",
    symbol: "BOKU",
    address: "BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra",
    decimals: 9,
    logoURI:
      "https://bafybeid35kcydo75arckv6nwbpxofohxp6jbx3sq6qy4fbmv3idmiqayeq.ipfs.nftstorage.link/",
    tags: ["old-registry", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DevWifHat",
    symbol: "DWH",
    address: "DEVwHJ57QMPPArD2CyjboMbdWvjEMjXRigYpaUNDTD7o",
    decimals: 6,
    logoURI: "https://arweave.net/8axP5eu3H87THPNK3oWV0dJ_-z1zoZGhp-V8LgJ3IBU",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "FreeSpeech",
    symbol: "FREE",
    address: "Fcvn3f5BTChSH53ixtURMmF5Vqj9yGsSYrRjFkf3wCX2",
    decimals: 9,
    logoURI: "https://arweave.net/Vx8TeG4z7LEvrRDnG8OCPzZuFC0aUbHvrgSbds4og80",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Nayeb Bekele",
    symbol: "BEKELE",
    address: "4pPMX85G5t6M8hoKvdsbRDGbMkXxRg1vefcQ7MVjjMKM",
    decimals: 6,
    logoURI:
      "https://bafkreiaphyug77bsxdnx55jmzppii3ttgaenxykz6kedlgowoun7ha53ku.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Ammunition",
    symbol: "AMMO",
    address: "ammoK8AkX2wnebQb35cDAZtTkvsXQbi82cGeTnUvvfK",
    decimals: 0,
    logoURI: "https://storage.googleapis.com/nft-assets/items/AMMO.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "PULP",
    symbol: "PULP",
    address: "5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS/logo.png",
    tags: ["old-registry", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "neverSURRENDERone's",
    symbol: "NSO",
    address: "HgMfSGndLq6vgLxCw4J33nJrwV2zTh81iEJNVwK9kcHD",
    decimals: 9,
    logoURI: "https://i.imghippo.com/files/HuWkT1710906839.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Bit Harvest Studio Token",
    symbol: "BHST",
    address: "BHSTv1nsz57fsXZzcsYWXhEygyqAaQu63DUJd3Rcp5Jo",
    decimals: 9,
    logoURI:
      "https://gitlab.com/bitharveststudio/bhs_governance/-/raw/main/Luna-token.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "White Shark Coin",
    symbol: "SHARK",
    address: "8JF4ekdgw7VTdrrdCABAKAxLc55mGu9ptasNiamih4a9",
    decimals: 9,
    logoURI:
      "https://gateway.irys.xyz/t94Df4aOwJhBuAYoVqA4WFB78v8gJh9H8FRg-SBqmqQ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "vledemer puten",
    symbol: "PUTEN",
    address: "6y56NYaUtKtjT5LKrVvZ3rsLnKyQ96phU3ckSARUkNax",
    decimals: 9,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://bafkreiensk7owgg2bfywm4ecqafcnea75km65bc6oxysnkxep5v5lpuc2m.ipfs.nftstorage.link",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "dark boden",
    symbol: "DOBEN",
    address: "EaiD1TvwaFavVTi9eroMDcovp1ok1dpRpbrKJmzeHfXM",
    decimals: 4,
    logoURI:
      "https://bafybeigb654o3eagfyycscjgnwwnznpzvk6uvlt262s2yqvozpaivf5i7y.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Shark Dog",
    symbol: "SD",
    address: "8rEEAvq6kujW2ukjYHfutH5eToAwsK4aaJ8p1ApoMs9g",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmeAuc8Cf34rUnmSDq9RXm8gFUeSxwFu1hWS5RgDAfb7J6",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "USD Coin (Portal from Avalanche)",
    symbol: "USDCAV",
    address: "FHfba3ov5P3RjaiLVgh8FTv4oirxQDoVXuoUUDvHuXax",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDCav_wh.png",
    tags: ["wormhole", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "wifSOL",
    symbol: "WIFSOL",
    address: "Fi5GayacZzUrfaCRCJtBz2vSYkGF56xjgCceZx5SbXwq",
    decimals: 9,
    logoURI: "https://arweave.net/C9ISQflnq1MPpAFbN9D3MG4yhCPST1aGz97gv9zCC70",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Beast Meme",
    symbol: "XBM",
    address: "XBMuuVZKHd6u8GyX6JakhjgpCA6h7FG28bXaWX2s51P",
    decimals: 9,
    logoURI:
      "https://arweave.net/WpHohYQqfwWlp14kMnOjwlcpEhoEibuUsW-MvtDz29A?ext=png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "COINDASHBOARD",
    symbol: "CDBD",
    address: "55bhM86E8brNHWy7sNz4chqtfQtrVGDvaeWyVczJs8RC",
    decimals: 6,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://sapphire-lazy-macaw-504.mypinata.cloud/ipfs/QmdpPuYPshppAWLBSGHpWqzxQGaX2RnesTdqPeg4dE44Md",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "mechel seyler",
    symbol: "SEYLER",
    address: "5g6b3eCLHC4WvmUJRiTvE5mNCUMiaHbKxQyWKuJdmDi",
    decimals: 9,
    logoURI:
      "https://bafybeiddrvfbic7v73sruaqxkapvbz73vxzp4ebljzhywhwlugu76p44by.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Mad Lads",
    symbol: "MADX",
    address: "Cj2NAxiDDVvY79PLBdVQK3F3bjoHp7hvZv4kLL1vgtV3",
    decimals: 2,
    logoURI:
      "https://creator-hub-prod.s3.us-east-2.amazonaws.com/mad_lads_pfp_1682211343777.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Lambo Space Princess",
    symbol: "LSP",
    address: "BAy5FmGzFwcVcZq1yXaDvF1mEAChF3MPtBLrUMBsnLN9",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmawB8QaHKMCe8myQbkM15FmsZ1YzCnLnPWPHEN6GAB8a6",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BRASIL COIN",
    symbol: "BRASIL",
    address: "HhAcs9JjcveM45FG2Zi3JcWAomra3QezZ22mVvr7zPSE",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmSBvnTKer8LmVMDJSvCQ8o94NmreZJYUmzGHJ6adntwmZ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Satori",
    symbol: "SATORI",
    address: "HkSiK3rrAf4JHXN7Cf1j7GsLofX9jo8962xabT1qiff1",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreieavkjl5rchszvydi7asd3e7jzms5cw6ggixjfto6euuxd3ido7ui.ipfs.dweb.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ENG Token",
    symbol: "ENG",
    address: "GtLBsmS6Z4FC9gmnCFS2ZzCKmb9vYL3kELnjVmxHurnh",
    decimals: 2,
    logoURI:
      "https://www.arweave.net/N_pDeYTYERWexWo2E3-mzZhqMtqdcqA_ZktPva4ImnY?ext=png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Royal Crows",
    symbol: "CROWS",
    address: "6gSmbxSfgJrqeGBYwuKGuSbShhbDLjcCLWqzivvPeG3i",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/royal_crows_pfp_1654260294179.gif",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "JIZZUS CHRIST",
    symbol: "JIZZUS",
    address: "7vQdgLtR2Qo6MBotkKVmCKfw83fGZkFQLR3Cj6X3ZRF7",
    decimals: 7,
    logoURI:
      "https://bafkreiatmmrkg2jjnvp5hncjbyowfzoom7lb46fwvqafopnjazirj3qoqq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Gobi",
    symbol: "GOBI",
    address: "MarcoPaG4dV4qit3ZPGPFm4qt4KKNBKvAsm2rPGNF72",
    decimals: 6,
    logoURI:
      "https://arweave.net/uscHuZUd7GsiPIJz2zS_et6_boil-DhtF0zJdrX8hM8?ext=png",
    tags: ["community", "solana-fm", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Osaka Protocol (Wormhole)",
    symbol: "OSAK",
    address: "GoxLaNFQiqnV97p7aRGP4ghvLZ4GwJN9NUNPpozvJZCV",
    decimals: 4,
    logoURI:
      "https://raw.githubusercontent.com/osakaprotocol/assets/main/brand/OSAK_LOGO_300px.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Basilisk",
    symbol: "BSLSK",
    address: "EMAb1cexvwrMeViyYiK8941V7SGWiXsyhMREBcswggPw",
    decimals: 2,
    logoURI: "https://i.postimg.cc/d0j6rRWH/Dark-BASLSK-666x666.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BAT",
    symbol: "BATT",
    address: "BzjsQpgKjxZedFawUV9GZMExhr7VbvsasQv12v2PVxSt",
    decimals: 8,
    logoURI:
      "https://batt.finance/static/media/logo.6284c2cd472e1bacd3e5c370044f6a0e.svg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Eat Ze Bugz",
    symbol: "EZB",
    address: "9Zf6CPFt8mJ5TBWJur3x5HrKdr7yfNg9QhEx2jkNNyFc",
    decimals: 9,
    logoURI:
      "https://bafkreihultovrkkziug4pxk6xgq46llvf3j22wahvtbq47jglejpi7angm.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DED",
    symbol: "DED",
    address: "7raHqUrZXAqtxFJ2wcmtpH7SQYLeN9447vD4KhZM7tcP",
    decimals: 2,
    logoURI:
      "https://cdn.bridgesplit.com/?fit=crop&height=400&width=400&image=https://www.arweave.net/nE_JLSoMnDq5bOuQLO7-UhTvlOmC6264v90IVB7ntjQ?ext=gif",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "bon shatiro",
    symbol: "BON",
    address: "6bPFbxgpsFZg6WDruj7vrodq4GY7K7TmqUnAm1byu3PW",
    decimals: 6,
    logoURI:
      "https://bafkreidzjjgjpoghdhd4nn35rgsonvwz532kmv3d3oqcmovfxdoj7wucri.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "melenye tremp",
    symbol: "MELENYE",
    address: "Cer9R2rqUEyemrgCWu5SsJu5d52sdZfC2gCYpwBhtEbB",
    decimals: 6,
    logoURI:
      "https://bafkreifn4atgecyr2xgzmkvl32aux2zar4boizpzkeoum3boi6ipoy6fhq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "For The Culture",
    symbol: "CULTURE",
    address: "BuAL6Qt1CJbfa6wnHnYNqj572nQpKGR7C5xe4jn3icBp",
    decimals: 9,
    logoURI: "https://i.ibb.co/x6gxSQN/IMG-20240404-012003-903.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DegenerateFinance69Inu",
    symbol: "DEFI",
    address: "Fefecfi5DhahY51XqQTP2qjFGhnuoSjrVivL6k9Ercw6",
    decimals: 4,
    logoURI:
      "https://github.com/0xViva/public-assets/blob/main/assets/DefiDogLogo.png?raw=true",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BABS",
    symbol: "BABS",
    address: "Ceqwd4CSGBZnW8PHEexBQCAV2NDPkBoSqheiKR7gzELV",
    decimals: 5,
    logoURI: "https://iili.io/HaOytTu.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "HampterFi",
    symbol: "HMTR",
    address: "7JhmUcZrrfhyt5nTSu3AfsrUq2L9992a7AhwdSDxdoL2",
    decimals: 0,
    logoURI: "https://arweave.net/A6llvg1Lo9SXQYC4RQXVrTfwJ-eZpLl4_eZhJVGQfOg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "CLUB",
    symbol: "CLUB",
    address: "GTgMjfKTBF9jVBogMnaiAVnHmvnF2wyGAv3zvtk5CBHq",
    decimals: 9,
    logoURI: "https://arweave.net/XSWZOeipKasgKjDKm54O2R0S3Rrld1B6gQV_rtIjfF4",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Balloon Cat",
    symbol: "LOON",
    address: "GDjFyK4umeTvfVu9r5pkdyaxzD2KtMhmBirwnJoKA7WW",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmTbEKh5WhxfiQfwPKUsdgrNJQrQ89a5ekkDciLimwqdcL",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Procreation AI",
    symbol: "PAI",
    address: "2TybzwN2X1g7Y6GRbB4cRx4KPK7bqdK9tYCozBXxiTfr",
    decimals: 6,
    logoURI:
      "https://bafkreifcytddas5fcgeaatsxu4yx5wwu4ths7726qa7rw4c2njbyuvmhum.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Orcat",
    symbol: "ORCAT",
    address: "3iG5NRc36xaVnWKdFKw9HwDjG2Bf58YitCJmGUm7hrFJ",
    decimals: 6,
    logoURI:
      "https://bafybeiez523kblwpduyyhrtr2ne4xpmzk3fj2hiuzoalsnhu2qin23llvu.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SolGen",
    symbol: "GEN",
    address: "1F1sRpidpKBQjmCnHAu8vJpmktLVK3ZjKyxVwEy8XpC",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmVNexv8GWNZ39We31Wk9fHZN2Aeu3HLmRn6KgAXC7qJAc",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BEN",
    symbol: "$BEN",
    address: "2Ry7AGS1w488LdSxhz2XvSrU1EVUzUGJo27DBP57couh",
    decimals: 7,
    logoURI: "https://arweave.net/vvRsvtg1dtVxoUL6XNeRp-cOIYp8BRbaDigmuKvLazk",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "UPTOBER",
    symbol: "UPT",
    address: "9CPWiCFL11DWr92dsTrzLf5cKz6zKhLz3xzRcYCQ3Nd2",
    decimals: 6,
    logoURI:
      "https://bafkreihgs65erm5f45s5vzl7y6wdl3nuqqqpcvlith3sotor5jcctft2wu.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Mbapepe",
    symbol: "MBAPEPE",
    address: "5cJUt4HzoVpRfwAqeNEDfbFJLVNxSpV9rzgPyyY3XLJx",
    decimals: 9,
    logoURI: "https://i.ibb.co/hsk8QCp/IMG-20240408-191027-942-3-1.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "StareCat",
    symbol: "HELIA",
    address: "FZnSMd1hPu5MVgttmtfDZUPN5gcuexvrh3CB67UuQshb",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmWJGdJLF9GwYCsJfP81SvnCbZaso8aqokA3ZEQRigieyq",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Caty Wuud",
    symbol: "CATY",
    address: "8mY8bif63v5vAHYaHPhpob71K4uJsDKXVx7h9h1XmJ6N",
    decimals: 6,
    logoURI:
      "https://bafkreigy7tvjbbq3j2ksiyjkihtcqduxho5uuwud6bbgltlyxbbsn6leiy.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "FJB",
    symbol: "FJB",
    address: "PRZdvhsihALAYQAwCaQYCSVTbkxfT9Fme28p19Np9VD",
    decimals: 6,
    logoURI:
      "https://bafkreiheaju77mgrvkhh65owh6k3hfvponljao4gzwz6maim4fk2gqacly.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "People of Walmart",
    symbol: "POW",
    address: "wA1MArtTmL1yYtweBjyHZZdteHQRd5fgwsUKx7iiqKU",
    decimals: 6,
    logoURI: "https://arweave.net/YtN5TutCP4Y4ZDWMYs1F9qdM8fJaAMDfq37Zmxkss_E",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Jurden Peeturdson",
    symbol: "JURDEN",
    address: "HYWB4HkyfDSA1i4t4cQaK9cvLHG4nQBRzGX9tzmEmgDr",
    decimals: 6,
    logoURI:
      "https://bafybeihfqmvynbetxlwxxbc6rbypbkzohfgbkqqyohyds6fgjiyropsi6e.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Hiko The Meow",
    symbol: "HIKO",
    address: "Dr9s6hHSrSDom1zhwtJ7vp3RsJ3AX2y7ja2ZYEHQiC7f",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmQ6iHysNeKGUouXNtz78SzXby95m4Tyo13Kv7x7W48vLv",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "coynbayse",
    symbol: "$BAYSE",
    address: "9DgMYGtyeNzGchoSJWLZsueyTYa3v9bSpkzzfRhYJKDo",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmUKd7gTttnte7yMKg3QJD1qJRKhgYdqaR5CsiB3UbAKEZ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Savanna Haus",
    symbol: "SVNN",
    address: "DbM7mcJM9zitHanzKmFf7NH4SaEZZDCf5TPEgzwTmuh4",
    decimals: 6,
    logoURI: "https://elephant.money/img/logo/savanna-haus-256.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "CHURRO",
    symbol: "CHURRO",
    address: "A3t817aK6XkhqQA148bjXKWJMhBmP9t2d9caWvQNaHQR",
    decimals: 6,
    logoURI:
      "https://bafybeicdl3dbn5cr4xwvov5jnuxusaeichgfyyxnpmmukdsv7xw44qfpxi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Measurable Data Token (Wormhole)",
    symbol: "MDT",
    address: "8Wqbst4qAN2FqBCCh5gdXq2WJ7vTNWEY4oNLrpUg7Tya",
    decimals: 8,
    logoURI:
      "https://assets.coingecko.com/coins/images/2441/standard/mdt_logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "AKIRA",
    symbol: "AKIRA",
    address: "FqSkp1BxNKEaLeyZPA7REd47aQMDUzz8fsg1f3rtKrJh",
    decimals: 4,
    logoURI:
      "https://ipfs.io/ipfs/Qma1waVii5NahQHyNi1CGzhEvUtp5MAj5SiD9MKQhXL5tc?filename=photo_2024-05-04_19-23-34.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Solnic",
    symbol: "SOLNIC",
    address: "DeaKMzAeZja3Mh5okZE6WUvygLP3Lfuvm6Rg78HqXTz9",
    decimals: 6,
    logoURI:
      "https://gateway.irys.xyz/y0Hlgi1BbUfWCfJ2a407UrlViwN0Fa3cjLikgrVCpWI",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Felt Gaming Labs",
    symbol: "GMCOCK",
    address: "8CSTYs264XFrGym2WLy6QGgq6isxU8G4Und9q1gVbbbB",
    decimals: 8,
    logoURI:
      "https://cdn.discordapp.com/attachments/1026783859309809674/1188099299565908029/IMG_8491.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Gury Vee",
    symbol: "GURYVEE",
    address: "Agqc1Dm7DD7va5RRwbEZoX1CtYBKEBwASw83KbhjKBPn",
    decimals: 6,
    logoURI:
      "https://bafybeihn5yrbcngaepotamwsp2lbcgeuo437runejrhduwor3g7ebfg6zy.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Andwu Tet",
    symbol: "TOPG",
    address: "2kARUpcjqKJdSTgZQKrzABLKbjvi2hNADYwHq7z52gGq",
    decimals: 9,
    logoURI:
      "https://bafkreifktm2esm67rvurcqhnofh54ipnnqti2mzy3b2yfg65b2577yhrxi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Nelson Moondela",
    symbol: "$MOONDELA",
    address: "BSXvX9dMvedcXUvwUQ5pzYJb9G8bBXKtddQns4oAxgEo",
    decimals: 6,
    logoURI:
      "https://bafybeiebyum5ql7lhtgocj37hkesya762gt7vcno7shnykv2kvinbv6bu4.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Badger",
    symbol: "BADGER",
    address: "9V4x6ikFm9XKsnh3TiYJWPwQfFkJZDjifu7VSUqg3es1",
    decimals: 9,
    logoURI:
      "https://bafybeid32j2uhizizt77t5au2xekwprplqn2k4thirubwkrvnb2cgadije.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "catKOL",
    symbol: "CTKOL",
    address: "Fyr3bF5PatZLcq6odQ7izFyJom1uoS96WftVWdWsJYwq",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmQeBKc2Vj8unypk6KKWrxX4mN7vMXVag1ZndT67N9Yt8Z",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Lorm the Worm",
    symbol: "L",
    address: "2emYMetySwE7Xh6qrG1AwAQ3TDvrLkUSpSZ5AjcWCc7B",
    decimals: 6,
    logoURI:
      "https://bafybeihx6vk7furiw5ls3zpw2hoyg6eocrj6ztjce6z6fm3nsnqwfbcwye.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Bodega Cat",
    symbol: "BCAT",
    address: "7bQsj9DciGXs6cTkhB3D1WbcEjuMpmD7amQRWjEVBpu",
    decimals: 6,
    logoURI:
      "https://bafybeiecqv3kypfokkfggfoeiocpfktvsitknxzwktsymvcofvb7jadppy.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "dainSOL",
    symbol: "DAINSOL",
    address: "2LuXDpkn7ZWMqufwgUv7ZisggGkSE5FpeHCHBsRgLg3m",
    decimals: 9,
    logoURI:
      "https://tca2vwit4gdsuwo3evencavzatf6sdit6e3xpiw374fmid45q55q.arweave.net/mIGq2RPhhypZ2yVI0QK5BMvpDRPxN3ei2_8KxA-dh3s",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "RoaringPepe",
    symbol: "RPEPE",
    address: "8jnGePqBBq1KQfPvqA7zAZySiFTyy8tv2zA8u6nHhsdo",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmbC7tJ3EieLuAm4GeuUWsqhyCdidASrarJ6hZZTQDzWdd",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MATH Token (Portal)",
    symbol: "MATH",
    address: "CaGa7pddFXS65Gznqwp42kBhkJQdceoFVT7AQYo8Jr8Q",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/MATH_wh.png",
    tags: ["wormhole", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Doge Capital",
    symbol: "DCAP",
    address: "BQXDYWZdtXqeLXFWYeRhLrGh8gcTmDQZQc92ENMaXSry",
    decimals: 2,
    logoURI:
      "https://dl.airtable.com/.attachmentThumbnails/5e07dff05059715ef2478b80cfee0c99/41bbd847",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Exiled Dust Protocol",
    symbol: "EDUST",
    address: "21kbezaCxz9dG2NtXp7pW1hAmtKe6ykiJkeT1EiGXDyY",
    decimals: 9,
    logoURI: "https://i.imgur.com/KPPBoDB.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "YoloNolo Coin",
    symbol: "YOLO",
    address: "4jE4VuqFWZfmSXjTDD5KUoN2qkVu96nPTPj4mhs5PA1W",
    decimals: 5,
    logoURI:
      "https://nftstorage.link/ipfs/bafybeiepttutbes4mzojtmlvx3iqgwvs673czt5g7k7uoficx4vmjunmie/YOLO%20LOGO.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "PROTUGAL",
    symbol: "PROTUGAL",
    address: "BT2apS5umybEthwy6xP5PfgNDw3ykMyxirY5XxZ7H654",
    decimals: 9,
    logoURI:
      "https://bafybeidkqx32txz6d64z6zjuicj3ooteeazj3hbjqopu5j5uuvr6fiqxza.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Myke Thysen",
    symbol: "MYKE",
    address: "DV2TCTbENV9xJ14kHC89qd1w2KZB6efvZKHNRpoo5cuD",
    decimals: 6,
    logoURI:
      "https://bafkreih3gjkz3cyu42n47y5vs6kpmr4gahrhleuim2oa6rpoeho4yxpb7y.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "POWSCHE",
    symbol: "POWSCHE",
    address: "8CkiSHHJDHJV4LUoiRMLUhqG58cUkbyJRtcP4Z3mCXNf",
    decimals: 9,
    logoURI:
      "https://dd.dexscreener.com/ds-data/tokens/solana/8CkiSHHJDHJV4LUoiRMLUhqG58cUkbyJRtcP4Z3mCXNf.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Keif The Kat",
    symbol: "$KEIF",
    address: "9QgXH6RjuLx5izvgRU1ovzackRsbzQoe415mxHUZJkkH",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmetHJ1RirB7vth3yznTvUcfqfuzgyWXAFKEFSd4B9P7uu",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "XTV",
    symbol: "XTV",
    address: "GTTS8jkhmfWBdYyKNSgbDQeYqVkpALkP98YJRGbRbvUA",
    decimals: 9,
    logoURI:
      "https://dd.dexscreener.com/ds-data/tokens/solana/GTTS8jkhmfWBdYyKNSgbDQeYqVkpALkP98YJRGbRbvUA.png?size=lg&key=591e51",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GEM",
    symbol: "GEM",
    address: "E7BGDtpNXUTqPNbZxKHiLowgLddiAeuKcByD7tSnfYWD",
    decimals: 5,
    logoURI:
      "https://bafkreifpm7rbwohp4yys2guyibfzqbctu3bist6zetmyq456om2rqvk544.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Nyan Cat",
    symbol: "NYAN",
    address: "49ztDWXk7qEfz8Y1t8owmhLhewiA3ptVNPpRGAjRd8Wh",
    decimals: 2,
    logoURI:
      "https://bafkreigmutvlo3qvgkz2w4h7n6537thcubprh5dm7dyuvymga6eavapckq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "RISHI MOONAK",
    symbol: "MOONAK",
    address: "A8iqgLj9bmqb2JM8umkHj7B7AGriD1EKLZHVRGA2VcNP",
    decimals: 6,
    logoURI:
      "https://bafkreifbuaznsv5g4qu2i3aimlcn6eooeuslhhnptqt6wyjlqhq7helz7m.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TradeBot",
    symbol: "TRADEBOT",
    address: "GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR",
    decimals: 6,
    logoURI:
      "https://bafybeibnvnsqtp5l47rj3czt4wsg5uzozbvk5pokpxclgb4b7djl2xb2jq.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Habibi Come To Dubai",
    symbol: "DUBAI",
    address: "93NhryHqdN5eVz2n4Qoof7ELwnTcgHBw44tmGcLzxKXz",
    decimals: 9,
    logoURI:
      "https://cdn.discordapp.com/attachments/912336086913679371/1203082217631260753/606344e0a14fa1.65973326-original.png?ex=65cfcca0&is=65bd57a0&hm=ce80eadae9234b0f02b0edbcb2a1c5f132afa0c14281783ff39085d95807bc63&",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ClownOnSolana",
    symbol: "$CLOWN",
    address: "V7ntWk4D9Frwaec2CUjMRMMsJG28Cd31HxDKNdP1qjM",
    decimals: 9,
    logoURI: "https://arweave.net/q1EkX68UfbUnvjpA87GaRjGxVaP6f0lYMBeQXmFzN6w",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Fuel",
    symbol: "FUEL",
    address: "fueL3hBZjLLLJHiFH9cqZoozTG3XQZ53diwFPwbzNim",
    decimals: 0,
    logoURI: "https://storage.googleapis.com/nft-assets/items/FUEL.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SDrive Credit Token",
    symbol: "CREDITS",
    address: "4ptcYkUypE7sDH82oXaeykaAJunuB4yeDJeiLJwS2nQc",
    decimals: 8,
    logoURI: "https://cdn.sdrive.pro/sdrive/credits_token.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BOOP",
    symbol: "BOOP",
    address: "CCRJohp9bfQdhZCvCe7MzUQuXMJkKBi4XCjUq3A2YqN8",
    decimals: 10,
    logoURI: "https://arweave.net/2ArDrNlyF34gjZ7UdXmMVl_ET9igdcyDiAY-VFsw3ao",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "burek obema",
    symbol: "OBEMA",
    address: "BMQZzVJa1X5cBYc8fCRVbWuFC4czA1s5GE3HekWfuhjz",
    decimals: 6,
    logoURI: "https://i.imgur.com/Mu0fbpi.jpeg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Transem",
    symbol: "TRANSEM",
    address: "E4r9cN6ZooDPwCrWiRQkdP15KD9G6wq5Hzkpk7ogTpfk",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmZ9Q3ULJrD9T4epiCSi46d1ezjuNT4pMcjQnqpEWfY3cV",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "KIRA",
    symbol: "KIRA",
    address: "kiraZUmSnzgfVfhrdvNj6hxHFaPFTTUk8ioY98cbh6G",
    decimals: 9,
    logoURI:
      "https://www.arweave.net/71SbFojNuoFeQ_n4cfMYKzX87xPvsQvNr59R_ZM7ucQ?ext=png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ket",
    symbol: "KET",
    address: "2SsU91ZE2dni5aD8i7bfTR4Bvf6UcGFBbLsvDucWUdw3",
    decimals: 9,
    logoURI:
      "https://bafkreibzsa7dyrh7tdy7iiqauyzvb6bw42lpivt642i3rv7wm5enyomdca.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SoylanaManletCaptainZ",
    symbol: "ANSEM",
    address: "3Lec18q7nPM62LQwqXG2ddiBTDrFCiNw1NEA1ehBZPgB",
    decimals: 1,
    logoURI: "https://arweave.net/tJXiOAU5ZXS7PZbWGKrg5nNRCh5OUlr07iIY4FRWK0o",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Uber Jeets",
    symbol: "UBERJEETS",
    address: "G2ShfTkHaPgY1YUqZzB611coeFpbhyGirzgGmyW5fEhV",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmXoowrVjJGbJwu8PcNrRrkgaMvmRSWhtJzXgctookqrjf",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "JizzLord",
    symbol: "JIZZLORD",
    address: "69SEPKGCzFzJLoSawb3xPfcqnZdiVm21Xu28AWZB5pzk",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/Terminator0611/Jizz/main/jizzlord.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "crustieno renaldo",
    symbol: "SIUUU",
    address: "BQ74oddoJCJKz9W4QaDzLFh1JvLbmXRCvdaC65P7anex",
    decimals: 6,
    logoURI: "https://i.ibb.co/wKQYQgX/11.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "AumentoBULL",
    symbol: "ABULL",
    address: "J3hxVJtn3kGtr1tHkJSWXvMr2D4M8iBzxzB75zywxVzF",
    decimals: 9,
    logoURI:
      "https://api.degencdn.com/v1/nfts/J3hxVJtn3kGtr1tHkJSWXvMr2D4M8iBzxzB75zywxVzF/image.jpg",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Froggo",
    symbol: "FROGGO",
    address: "A12XggFFk3b5GCd6ZYxuQ55cQbxarHL4h7Jxs3GQcdC3",
    decimals: 9,
    logoURI: "https://froggotoken.com/token_logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "towl",
    symbol: "TOWL",
    address: "9j3vEmBng3JhcL7LPaAx2CoLqE46AYmGPbbFgHKZVKWZ",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmSEaM7SkjfCG6NkkPNDyHjvwWwMGX3SbFFkK8pD152hvU",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Threshold Network Token",
    symbol: "T",
    address: "4Njvi3928U3figEF5tf8xvjLC5GqUN33oe4XTJNe7xXC",
    decimals: 8,
    logoURI:
      "https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/T_wh.png",
    tags: ["wormhole", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Jinpeng",
    symbol: "JIN",
    address: "GgzJWhQWTpv2VHqvJnx5uEWQzNkfQSncDEWUdgQk4fvh",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/Qmf31Z7fTrzr7XQYAYstDhixZoG9ePWDJQCqev2JEfWUDS",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Secrot Servoce",
    symbol: "SECROT",
    address: "7VQzGuk2xbWteJ2A5yzGkuDmCQLQGAsvD9MjmW8VutTG",
    decimals: 6,
    logoURI:
      "https://bafybeigwk4iji6ggqylastjcb5ssvar7l44tdwsckqhgv32jmghyauxplu.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Lionel Messi",
    symbol: "MESSI",
    address: "HiRx4TWNmuPK6VKxGKPSw8T7dZ9NuCrakpNpRoiCEyrA",
    decimals: 6,
    logoURI:
      "https://bafybeidgb7gr3avdzs2c5zc7oi4at3bjrcw5k6c3fgzk6lw2ewpfmy43jy.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Lion Cat",
    symbol: "LC",
    address: "5HJ3fCrCXUEEraLJUBLvhbNwSYS2RNzjuuS33FLH7UjP",
    decimals: 9,
    logoURI:
      "https://bafkreiawncphofu5xtihstckvu34knehgsf4vple45dlfdupnza6xc3txq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "ᗪOᖇK ᒪOᖇᗪ",
    symbol: "DORKL",
    address: "BgqjQdnnjRtcELAgkYpfKAxWRqSReWercDdRTH6uLoer",
    decimals: 6,
    logoURI:
      "https://bafkreicufnhs5evfdochjopzpjaeuxoh5s6uhyl54dlfeb2cm4bqjfi4dq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Internal Retard Service",
    symbol: "IRS",
    address: "A3tCD8Q1yjdy3gRDJ4LFzpECnafW3sHNa3dJKHesG9Xf",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/bafkreie7ntaifs5iq2txyjmll4v4i7myivt6gpnu6fht5pd3ggc7sfv35m",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "HappyAyeSOL",
    symbol: "HAPPY",
    address: "ETBneBQ97qDUygsEBDnpiUF6e832GHV8FzsJCvbUgN1B",
    decimals: 9,
    logoURI:
      "https://gateway.irys.xyz/1O94ecCGcXdL4h5Fqw-ZpClGBsdV_VhdKia5yBIxf5g",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Mycelium McToken",
    symbol: "TOKE",
    address: "AmgUMQeqW8H74trc8UkKjzZWtxBdpS496wh4GLy2mCpo",
    decimals: 3,
    logoURI: "https://arweave.net/kVddfi0QG_NfS_cRQQ0vehEt7n25wVk3O4ilHaeidqY",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "simbawifhat",
    symbol: "SWH",
    address: "ASsnSwFhGVREnuN6YmZQKietjprv731BbETDWQsreAwj",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmTUg6JjcSzRjeZyDgguqVi484dcPk7r6DVbqwxWA4dufa",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BOJI",
    symbol: "BOJI",
    address: "2oMYg3aPHjFjxg1PRYLwvdRQayexUS8N4CjLXJ64GkLq",
    decimals: 9,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://ipfs.io/ipfs/QmejPmDXiuqJ3EG9zyNanLLjYWgycLwTso7EAWiPgFwUNF",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "REdao",
    symbol: "$RE",
    address: "REdaoGk6EcBVgXW7vHs9FnzWmkr3ba6eHRBBgEtLNWo",
    decimals: 9,
    logoURI: "https://arweave.net/mQfc83s1RVPeQumJ8O6TVS8h9QH1ToSwilJ9USNU1_E",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Anatoly Coin",
    symbol: "TOLY",
    address: "to1yVXiNRMVVgS8i54Yjj3xB51MTorFrCMz7N8cirbK",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/3YZtwGNZZisAUxKokcivJEwq2ag93APVXYqeyfaqb9Rq/toly-img.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Basket",
    symbol: "BSKT",
    address: "6gnCPhXtLnUD76HjQuSYPENLSZdG8RvDB1pTLM5aLSJA",
    decimals: 5,
    logoURI:
      "https://gateway.irys.xyz/69o2b3_ekRo4nosnyyXc9piS7d7-8u-h7MNviNzXFYA",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Frog Piss",
    symbol: "PISS",
    address: "7qULVSb7XdoKBDDa7WnuUvoTx5ye4Vrj94iPcwibyQ1F",
    decimals: 6,
    logoURI:
      "https://shdw-drive.genesysgo.net/BLFjCRyZkaF9GGwiorXPRwYJVpxD7VrrKNH7XccWUCWF/frogpiss.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Merted",
    symbol: "MERTD",
    address: "DDRmuJ77t7d6pkBDXE47ZALjSZm3udVGJMgZjZBk41LH",
    decimals: 9,
    logoURI:
      "https://bafybeibpf5nm257i63j2ttxrnzsirmmavwn5fj5y24olh5a55ydlugekpe.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MMOSH: The Stoked Token",
    symbol: "MMOSH",
    address: "FwfrwnNVLGyS8ucVjWvyoRdFDpTY8w6ACMAxJ4rqGUSS",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/7nPP797RprCMJaSXsyoTiFvMZVQ6y1dUgobvczdWGd35/MMoshCoin.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Milk",
    symbol: "$MILK",
    address: "8FktFPUvi73Ywf7ZivsbuQFyQCqZUwvqSQ51hNvRhR26",
    decimals: 8,
    logoURI:
      "https://shdw-drive.genesysgo.net/Ck7ByKk3NZfC9EFTNiGBrLkvquKGyDL7KLk3h1rdW2P4/image.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Thug Frog",
    symbol: "TROG",
    address: "2cpCdyVCpxzwYWFhFqgMJqxnMgEMDGohHUQuf4ppWuAH",
    decimals: 6,
    logoURI:
      "https://bafkreifdk5zl36v7fe5hn2ccbdbt3ot4x25l6l25hjqmb6lzfbpi23yh4q.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Fuddles Shiba Inu",
    symbol: "FUDSI",
    address: "91Fv8PztVLsf4S49RANPGW92veJ2oL6BjwsP9i666Wxu",
    decimals: 6,
    logoURI: "https://arweave.net/iv6zV6x03hbFXfnS1IJo0nSbr0HFNrjMzy_qbL3sjW8",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Moon Roll Coin",
    symbol: "MRC",
    address: "5HsZR8eG7QpQcN8Mnp8oFdENRkJMP9ZkcKhPSCKTJSWh",
    decimals: 9,
    logoURI: "https://moonroll-bucket.s3.amazonaws.com/token-image/Frame.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Davinci Jeremie",
    symbol: "DVINCI",
    address: "5jFJCvNgg8ytGWBygoquaUC6bMZyr7C5jmGHECBzrxR5",
    decimals: 6,
    logoURI:
      "https://bafkreihbx7trnvsdgd5gi7gzldasjcimedtnmuqpnhgen2c6vkxw44u7ky.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Apu Apustaja",
    symbol: "APU",
    address: "ECutGg12PNhqhkvnH1s1FcuXgCDzKDNhSf5aLtANioR7",
    decimals: 6,
    logoURI:
      "https://soldeployer.mypinata.cloud/ipfs/QmVb8aYRjzvr7hRkKLD3L3mTfDo41dECpPMpa1pzcqhD2L",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Fak Nous",
    symbol: "CNN",
    address: "DoyVgrZbkAVMbTVGqa9mFNgjThsr337vxjFtEDWQibN7",
    decimals: 6,
    logoURI:
      "https://bafkreie7jffibizw4kjbb3k5akuqqxvgi2bx7ydqq5tvbng5xnqmkdnbyu.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Flux Terminal",
    symbol: "FLUXT",
    address: "D1wUhnzTDscCDRdxDwR4h82XkesXgQR4Q2zLhSuYJA5m",
    decimals: 6,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmeGpjQhH7CbUfJHFXii2u6gek4ATU4shonqSWMVFMjZ4h",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DumbGayRetard",
    symbol: "BLOB",
    address: "85vb4pd3gyVMxQ211ZBXXWQJwJ4xZPBC5AQXvnmb1HTi",
    decimals: 8,
    logoURI:
      "https://bafkreihnoonnyxbvgl3hmwncc5jf7ibduzcbo6wgts572peipa5vlhigmi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BEAR",
    symbol: "$BEAR",
    address: "24a1k6wSDQUSwoRXJyvkr25kF5do8f6Ddff6A2fTc1pM",
    decimals: 9,
    logoURI: "https://arweave.net/7Qzb-BKL734z8w77fPNJ8lu3f6TtA87nbiw2A-qmCK0",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Hide the pain Harold",
    symbol: "HAROLD",
    address: "B8AmDZRJeHuq8CPciey6jocq9p4ivc4ZurDXPwtqs2Qg",
    decimals: 6,
    logoURI:
      "https://bafybeihtro4yen75sdqt5rc7s5fcjlfb2ts3xvsvibkm63eoe56ape32cu.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "猫",
    symbol: "MĀO",
    address: "xgWKZ2h4tnstP3NzGyVFgA6Adu3Q7uCBtiYt7cErPA9",
    decimals: 6,
    logoURI:
      "https://bafkreiezfqv3zjh5jouiaqg4ihs6hlsmxph6vulvggq2ugrimm6lxqiace.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Neural Octopus",
    symbol: "NOCT",
    address: "8HfNu2EfwFQz2srSNDvPsjaWV3G65ZWP3pDC67mcNv6J",
    decimals: 9,
    logoURI:
      "https://raw.githubusercontent.com/Neural-Octopus-Token/noct-metadata/main/logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Phil Overdrive | Youtube",
    symbol: "POVT",
    address: "PhiLR4JDZB9z92rYT5xBXKCxmq4pGB1LYjtybii7aiS",
    decimals: 5,
    logoURI:
      "https://raw.githubusercontent.com/PhilOverdrive/Token/main/Logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "AMADEUS",
    symbol: "AMADEUS",
    address: "Eq9xBLGnBc2B6wkdoZW6v1aCC4evtSaNPkSFKaDNQNFr",
    decimals: 9,
    logoURI:
      "https://bafybeify7x2y6ojvzhtiafc4ovk7eh5vzu2iksyg3p4b7lom657dyym6e4.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Snoopy",
    symbol: "$SNOOPY",
    address: "4LP5JKsyKC5pSAoodwcZnDCSK2ggsMcZvHKoo7HCPDCV",
    decimals: 9,
    logoURI:
      "https://bafkreih5phb36g46nyomg3gx6274wyhbgh4pyhax7noxpbhinirxtzcyum.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "dats dat",
    symbol: "$DAT",
    address: "DATiwd3NsbaRArGvmaFcFpc9jF8qciN7Fj4jRHAFUHFA",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/6zCgv4kJK2fyfexvXacePi5umYqFTCgN4nWjyqcpFgpY/token-logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Fuse Staked SOL",
    symbol: "FUSESOL",
    address: "fuseYvhNJbSzdDByyTCrLcogsoNwAviB1WeewhbqgFc",
    decimals: 9,
    logoURI: "https://fusesol.nyc3.digitaloceanspaces.com/fuseSOL.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Solana Meme",
    symbol: "SOLMEME",
    address: "5MRMqvLZyRQhrMn2a8vSL3Kv9vfjNhjRKRPHtTBz1VEB",
    decimals: 7,
    logoURI:
      "https://raw.githubusercontent.com/solmemetoken/solememe/main/logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Poor token",
    symbol: "POOR",
    address: "2mqi8iLJf9WaVRHd7nhRkqvCafxfb3Us7wshaJR73ykS",
    decimals: 5,
    logoURI:
      "https://shdw-drive.genesysgo.net/AZgm3Vdo1QLf8bKUjyearDC9NygpRHmmtzPaBNQcF4jt/IMG_1640.JPG",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Soosh",
    symbol: "SOOSH",
    address: "6wktcKujaFRKV9Sz455nHUNz34dEWht1gqKzR5KQ9Ljb",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeig5il3cq3c7wojc323d7kiex3pyoyxcxtufh4m7pvixarvcjiiqga.ipfs.dweb.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "anon coin",
    symbol: "ANON",
    address: "A53BzB7297SXdF6mguQQ8kzqjVYt8pUeHW5m1i8pD6hf",
    decimals: 6,
    logoURI:
      "https://bafybeihnxkkwtjf2a5jfwmetcbqkzj7zedwf24pfaeqtvtog7raaext76a.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Race Raiders Rewards",
    symbol: "RRR",
    address: "3zE4Qmh9hrC4inHynT3aSRzHcWhnxPCHLeN9WWqvoRVz",
    decimals: 9,
    logoURI: "https://arweave.net/SH106hrChudKjQ_c6e6yd0tsGUbFIScv2LL6Dp-LDiI",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "botman",
    symbol: "BOTMAN",
    address: "2adqrK24G2iu2mmEijzKPKg69DUwGk3fUWAQx7Y1kSSK",
    decimals: 6,
    logoURI: "https://thebotman.xyz/images/botman_pfp.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SOUL",
    symbol: "SOUL",
    address: "F6weWmuc1vwdL4u38Ro9jKXHEMjP9BoNdWMF5o5TvtJf",
    decimals: 9,
    logoURI: "https://arweave.net/ipj9IdEAQAXt0LS6lOt7jQY7W4iazsVFBkN60U7M4iQ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Solboard",
    symbol: "BOARD",
    address: "4xBEoJFNxRY7ZyUPEFmWwHrGzYN5uqzsAH94DTvBv3b1",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmPtD9t7XMVVAaYoa5qGgjEF6GqkfoBCXtZ7J1x5dYkLMp",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Pepe The Cat",
    symbol: "PEPECAT",
    address: "FfhArvgv8WB7eZ6qwENMouJRzcVpQVDoDtTuHrCk4Cxi",
    decimals: 6,
    logoURI:
      "https://bafkreihaltjxv2fn2niuu5odd6ib74d6qidy744ntvplb74aosr522pjg4.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BULL",
    symbol: "BULL",
    address: "BULLa6g9e5UCuTXC5Z3Cf7s7CgvJhnJfY71DwipSmF8w",
    decimals: 9,
    logoURI:
      "https://shdw-drive.genesysgo.net/GVxcsjC2PMpAS1E6aVp78YH7FqpfqxPY1ujeqNduhD1f/bull.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Barron Tremp",
    symbol: "BARRTREMP",
    address: "2CMxmFb2nsNdw351TfZoawpN1DnDuVrH4Wn6fxH2EJT6",
    decimals: 6,
    logoURI:
      "https://bafkreiaxhbgj5l2cfziu66n5fipedkylcmw2di6ijtvhnw5q4fdp3d3eme.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "iceSOL",
    symbol: "ICESOL",
    address: "iceSdwqztAQFuH6En49HWwMxwthKMnGzLFQcMN3Bqhj",
    decimals: 9,
    logoURI:
      "https://xsbf3mqssacc6xatyv27ornaiylcu5rw7qpbkciazgh6xwbpabja.arweave.net/vIJdshKQBC9cE8V190WgRhYqdjb8HhUJAMmP69gvAFI",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "KRUGs",
    symbol: "KRUG",
    address: "ECy2miAgmx3UyYoGGHt15AHBkoA3twoZDQyNLwETzAUv",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/krug_pfp_1679408420983.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Sandy",
    symbol: "SANDY",
    address: "6hBry7w55A3NXDAXxjKqrx3oXfAatNkAJUSGt66ZRsZk",
    decimals: 6,
    logoURI:
      "https://bafkreieur42wdbsrrlglcz6kp55kruavaxisd25o42fnydemcvp5qulf2i.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Olympic Games Token",
    symbol: "OGT",
    address: "DHbCBJVWGTHpiV4WNZ9AajvNkEWKRdu2mmJXYjQj847t",
    decimals: 9,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://ipfs.io/ipfs/QmTHniyPJLer4TkgNffjMZRTMUvai2Kvfwj9JYJAJnnJa2",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Tether USD (Portal from Avalanche)",
    symbol: "USDTAV",
    address: "Kz1csQA91WUGcQ2TB3o5kdGmWmMGp8eJcDEyHzNDVCX",
    decimals: 6,
    logoURI:
      "https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDTav_wh.png",
    tags: ["wormhole", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "AmigosOnSol",
    symbol: "MIGO",
    address: "8odnqwCqNVQ4UrYmYgkWPuedBr3yEMWY2ZBhtNW7jrCu",
    decimals: 6,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://bafybeiflobot335eygnhpjqlbo3u2vptijnqw6mwspl3p25habyz3k6zsq.ipfs.nftstorage.link",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DONALD",
    symbol: "DONALD",
    address: "4Yz5zByTwnVe46AXD6hsrccbq4TKLyih2xRqPyLBDT1P",
    decimals: 6,
    logoURI:
      "https://gateway.irys.xyz/LQ9YCmIWoXtDUAxNQhPjpbGbTgLjkgnLFVQJfRn73o0",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MARVIN",
    symbol: "$MARVIN",
    address: "ELuv4btje7nZNLeMSw7foptfSiRzEXpNxYuet8cM27SN",
    decimals: 6,
    logoURI:
      "https://bafkreid3syczq66zhpvdmrvktpw2aksmgk2maol2bt3i2zghwjhisvqnfe.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Gray",
    symbol: "GRAY",
    address: "6XfB1VVAxjtfKnF1kotGxLUq4p87xTDp3cCyC6Q2VHNG",
    decimals: 9,
    logoURI: "https://arweave.net/sNQ2D1R6C07UbP4mqZqR7P_hmrwje0y3T5_Vyy5lLGM",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Flying Spaghetti Monster",
    symbol: "FSM",
    address: "DMqv9nGbEVAkQoz13ncKh5XJrFg2BQ5YTwd1XrGEKkaz",
    decimals: 6,
    logoURI:
      "https://bafybeibxoen5asgzdf7kz6jlac6656s3a2xpfc2m3t77m73pfwy6keh6gu.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Piggy Sol Gang",
    symbol: "PSG",
    address: "DM8YLBujra6arvKuZ23d4ukqLdzbiGuhyWM4dUZnTnFA",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://cdn.piggygang.com/imgs/4a9e3480c4230ee0efb2a408fab74d91.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Bull Clinton",
    symbol: "CLINTON",
    address: "AF7X9tJDkLDVApfMnKSkMUUsxxPhuSGUZbDECtrbvVu3",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/Qmc2dKNHLD66TY5h48qxKempFmupjVjSFNTySU2jDU795A",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Hasuki",
    symbol: "HASUKI",
    address: "DMvsB8cjKXDQJs8cvoDtKxX7KEMVeZ31KzycszuinJUY",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/hasuki_pfp_1664008129191.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Coinfra Samurai",
    symbol: "COINFRA",
    address: "FtVugRqBcn5gakjzfDyA3Spms63fYDAbhke1YAFgLgLB",
    decimals: 2,
    logoURI:
      "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/coinfra_samurai_pfp_1662121480181.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Andrew Tate's Dog",
    symbol: "G",
    address: "FmqVMWXBESyu4g6FT1uz1GABKdJ4j6wbuuLFwPJtqpmu",
    decimals: 9,
    logoURI:
      "https://node1.irys.xyz/A0B7CXZE07qA3HgU83gd021CBPbVfaJjO8ooIDLHmC0",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cuy",
    symbol: "CUY",
    address: "8MD6dV7B8Tgwo72WyPmfsXiXpt1G1y2u9ohhNrt3LVwX",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmU62kzW5662SoGrRGu3jH6skmtDmNem4sXiFvkhEdg8hb",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "EGIRL",
    symbol: "EGIRL",
    address: "ARt4N4WY4PEdYUuBG7qENwuYSSiQUqP1RXFiahhwfzH9",
    decimals: 9,
    logoURI:
      "https://bafkreid6lv4sec5o74hm2n43cjgoqt3g4ar3qx7fc3zw3niouxzbkudz24.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Ladyboy",
    symbol: "LADYBOY",
    address: "AmCKJCFZfq7Lwy6MnDgHGcnKcqAi5ftHxAMzYRDwogTF",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmW53fqyNG3Lij8YM1VhnQPwrLSKf2BihHjKFhZKY2XHoM",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "NVIDIA",
    symbol: "NVDA",
    address: "9dwPiStDBwJJqC3QzMnjpJP7xohZbMVmHELFx3uy3KRq",
    decimals: 9,
    logoURI:
      "https://pbs.twimg.com/profile_images/1760839132958642176/nq18MRdb_400x400.jpg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "FAP",
    symbol: "FAP",
    address: "D1YaKkQRaQQg6sPUtDiWyskppzfgMV51ovcagVU436TX",
    decimals: 9,
    logoURI:
      "https://bafkreifgtuuai7s54fcejzzsyzyxlwpyinazbsxwnce5ikdmeqbar22glm.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Horse Meat",
    symbol: "HORSEMEAT",
    address: "2FprjEk4MTSY9CxpKuENbGDdy69R15GHhtHpG5Durdbq",
    decimals: 9,
    logoURI: "https://i.imgur.com/uKfGicj.jpeg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Blekrok",
    symbol: "$BLEK",
    address: "EYaJJKb2VDZuYKEWf5TjngFRc43cMgttPuRtcJwQt35z",
    decimals: 5,
    logoURI:
      "https://bafkreictirsdhaoboqtpji3wdqk6usuxgd42jd56tua5vkz72jvydzhcpu.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "PHOX",
    symbol: "PHOX",
    address: "9Pug19UEjW5wiFG5bLWYpjtz6s9K8ZGXmRvZwyfX7SNy",
    decimals: 6,
    logoURI:
      "https://image-cdn.solana.fm/images/?imageUrl=https://gateway.irys.xyz/MgqcN7mpQS3MV4Tc2fomrLKkKu_tpXtgjQplUNo5xr4",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Wine",
    symbol: "WINE",
    address: "DoVRzHXXicZucnuskdMg7DePhBHFkhvvhta5nf1deFoQ",
    decimals: 6,
    logoURI:
      "https://ipfs.io/ipfs/QmPBZhPerrYygkw8gwDgs7yhyTbi8diBQ26PwcgcTQmPuZ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DFTU",
    symbol: "DFTU",
    address: "9hD434AapfbidD6hret16hHGMqKh9z3WrkicL5Cvcsz8",
    decimals: 8,
    logoURI:
      "https://ipfs.io/ipfs/QmQnoSCfKDNs1KvUhYp2YvX2ffShwzG7M1t92mdPmXBaQR",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GRUMPY CAT",
    symbol: "GRUMP",
    address: "Ch5JJQZspiJ9MCDURZAA8nnVvbiwD1tnPcfmkCcVPiDb",
    decimals: 9,
    logoURI:
      "https://bafybeiclh3lsuq3bddebjez57c2ca3a52baxcqxhwuvscxf6j2y4dwwu2u.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Spore",
    symbol: "SPORE",
    address: "6Cw88QKMxS7LzAucXbYDcUEXPq1TvsojYPV9xWwJPt7r",
    decimals: 8,
    logoURI:
      "https://bafkreidodmcqrprsgoelphtr5vyqintuqscwixqfh6ndaqurvp2mnnx2h4.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "PRE-TOKEN",
    symbol: "PRE-",
    address: "EZGvDFqrMSAxTt3Ud2bcguASaPKwGExiDzHVLqT3STZm",
    decimals: 6,
    logoURI:
      "https://bafybeiceqp3ezfenvetlirrtqzpyeagbafzuabsr3klzfaev4bpzqbccke.ipfs.nftstorage.link/PRE-logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "PORTNOY",
    symbol: "PORTNOY",
    address: "8wzYfqeqkjBwYBHMacBVen8tSuJqXiDtsCgmjnUJDSKM",
    decimals: 6,
    logoURI: "https://i.ibb.co/K0M01Ws/image.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SOLANATIN",
    symbol: "SOLNTN",
    address: "aXNx9xd9EUAd1xci3aUX1FjyNTAxaYrX29GsL7YtBJq",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmS4CnjQ6BFNjKgXKoKDL1LRBPmXqfLTPkPA8XDT6oeMGW",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BRAH SUPPS INTL.",
    symbol: "BRAH",
    address: "6CBm8B8uoCUZWjs9n32BNy4z6LjGj4etBH2X48JWVhzf",
    decimals: 6,
    logoURI: "https://brahsupps.com/res/misc/icon.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Smol Cat",
    symbol: "SMOLCAT",
    address: "AJd5bi9wK4GupVf9XeftfrzQraQVthErLxEtdHv5qEHU",
    decimals: 9,
    logoURI:
      "https://bafkreigvfvqnfq7wvned2hbt6esd55iefkck2o7oookiyklgvmd2fmduzi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SolBird",
    symbol: "BIRD",
    address: "7y2KpMzsxmjCN8ogA3PSfwZvmYUgMuWo7NWY4Ct5jRHp",
    decimals: 6,
    logoURI: "https://arweave.net/K_IaCXwL9YQTPJnZfvi302k2lhANDvmzkiy6e0Y6Zho",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Memeolorgy",
    symbol: "LORGY",
    address: "C3Vg7ps6dNPugYCpA52KETpdCytE6ki3oe8ccTsaY63i",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/Qman1J5CQ2Z3cFshVvsBk6FxzVHavHQgVbUJF49xEFcsJ6",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Zeus Pet AI",
    symbol: "ZPET",
    address: "4AnuSHLSYc4J6yjNekrYMjknL4zJHCvRLXfUxgpmCLeQ",
    decimals: 3,
    logoURI:
      "https://raw.githubusercontent.com/ZeusPet-AI/contracts/main/zeuspet_logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "solana green",
    symbol: "GREEN",
    address: "7VUUicqKYFVmewg1U4w4e9K4FoEYdTcYyxYCPhRNf5Qy",
    decimals: 9,
    logoURI: "https://arweave.net/W68r4cNKbj0t_GRf1km_e9nzm4BLfspy0X-yX8mh4fQ",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "MILKBAG",
    symbol: "MILKBAG",
    address: "2ubuHGFS4VJVxSEpvV3kDwz6JiuXdaAoGMwrwYC87tp8",
    decimals: 9,
    logoURI:
      "https://www.dextools.io/resources/tokens/logos/solana/2ubuHGFS4VJVxSEpvV3kDwz6JiuXdaAoGMwrwYC87tp8.png?1711033406653",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "LIGMA",
    symbol: "LIGMA",
    address: "LigMAx75tZtD9NyxF3VwYQuEsf4uWPxoHgXkeiA2TPu",
    decimals: 8,
    logoURI:
      "https://bafkreiekwx6w5j4sydsk5mjrjv63mabripomxxkg24tueblk4q7567yr4i.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "BAREBEARS",
    symbol: "BAREBEARS",
    address: "AeNg6DaCAjNpK7CvkSC6c9j5g8YFSp78aTQxejaNRNcz",
    decimals: 6,
    logoURI:
      "https://bafybeigojiffozmk6hzhbyrwvdiyrq3anyp2otos7p4us7nowkanlhgirq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TIME Magazine of Solana",
    symbol: "TIME",
    address: "AG9yih1Wkunf17yucoNYUacw4LwQugr2vWBPpHA6xz6q",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmY5JBY5pVC1uWkqwRA2P7HXZV1KpBsbA8LdAywJAxkqjc",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SUPER TOKEN",
    symbol: "SUPER",
    address: "5nrAynqdsEyBc3GRc7hvM5QnDwjQVePNg9kn6bCvi4G3",
    decimals: 5,
    logoURI:
      "https://nftstorage.link/ipfs/bafkreibxcsqfuls3yiitufaid3wnjzrgx2gdswtteo33g6fojoxctob5na",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TAP Coin",
    symbol: "TAP",
    address: "CejQBkdRPN8Jyod2jVUYTfHwU9hPHvM3rD9prvQiffDU",
    decimals: 9,
    logoURI: "https://arweave.net/gHPUUFpbtWac5AnYtV10nGXa3VBgu0PgBiA7gDHJkJE",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Dead Bear Inc",
    symbol: "SCUM",
    address: "5cvj5rEEocG5Wvh3oJuY6MoYj7gVZd8aoXSLZjDY6W4W",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmasP8ZTEGAc4gqkhKfzq9Qu2tUaN8JpzQE2EcNaE9avkM",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "D/ACC",
    symbol: "D/ACC",
    address: "FabjHjc1druUQoHVtudpNiCpnf73rtLzMkRM1b5NSbb6",
    decimals: 9,
    logoURI:
      "https://img.fotofolio.xyz/?url=https%3A%2F%2Fnftstorage.link%2Fipfs%2Fbafkreiaad6yxjxad6qpkb7taodfnbfv74hmgmnorqatab27r54jpgroqci%2F",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "JEFF",
    symbol: "JEFF",
    address: "FkCaFsprX7gySagFoQPHNbe9MRkjrvh21cokJo6C1e2T",
    decimals: 6,
    logoURI: "https://arweave.net/Pi-s2vzZT8pfYVvFbNjgC0kSrW45PXb1_VPb5HoHuyI",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "VICE",
    symbol: "VICE",
    address: "AWeL8nCXxJm3GZkEVRqxeeT5KWT2KwDa1shgQFeBogkC",
    decimals: 9,
    logoURI:
      "https://bafybeieprtdo3xgvk2ezdlqd47lhcnwdqneoig7jiiiuodoy36jmu6jhte.ipfs.dweb.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Dog Enforcement Agency",
    symbol: "DEA",
    address: "HxtRWx83K61KjsNu1iCB4uhX9cbUtcSizRjLXXSZyyEm",
    decimals: 6,
    logoURI:
      "https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569656568726a776d69723272736132366e767633667633787877346935797a64706268627076376f6f79716c7a646d7235656677792e697066732e6e667473746f726167652e6c696e6b",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Elephant Money Stable (Wormhole)",
    symbol: "TRUNK",
    address: "9mV4WUukVsva5wYcYW4veo34CNDiF44sh3Ji65JNdvh5",
    decimals: 8,
    logoURI: "https://elephant.money/img/logo/trunk-logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "TRUMP",
    symbol: "TRUMP",
    address: "AwRErBEFGTnohzfLeRSBH9HddQEy2oeRxnWLrbvFFh95",
    decimals: 6,
    logoURI:
      "https://bafkreihppwmhl36ppve4xugihubmi73lalbmm752o6f5g4zkvojajl6nyi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "xiaojie",
    symbol: "XIAO",
    address: "EDavhezsuNnhdoAKPExWaMtnuhq6FVqoBYnyFEJLLBqC",
    decimals: 6,
    logoURI:
      "https://bafybeibqz4chze636s7ypnob4xogp7vobosqsbwmeu36w4x62iyqb6n6hm.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GCRClassic",
    symbol: "GCR",
    address: "791hZNiCJy1qGSGzAvqUU8X6gejiBJ2mBV8JjYoVnzBR",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmPck3YwoS8JHvxG9py11zbEQaBzgcRG4gSYzPpM9b4yjE",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Book of cartoons",
    symbol: "BOC",
    address: "47psVap1QaXYYEPhFn7pZuvR8m9GQaX8wDodZuY3fWL7",
    decimals: 6,
    logoURI:
      "https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b7265696236373368766f6e6d676f71656b6875707a616b66796668363573796575616868726267366d636f62657a656e75686b6a6578712e697066732e6e667473746f726167652e6c696e6b",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "SWOLE",
    symbol: "SWOLE",
    address: "sio28ienC3iABUKJFzkikHknbR3xyhjzhJE34tipyDP",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/Qmf4Sb9NetKYYUAVGryfAxRXZcLdzkxEpKmoxjV5EvNz9N",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "AndreDefiant",
    symbol: "GIANT",
    address: "A6YRaK4toMe2qZY7KLNCugXBrsjCG4fAjAR8uZUBeUek",
    decimals: 9,
    logoURI: "https://arweave.net/NyVAfC6oeDsD4USdwHQuEv9Q-i5jSK2SHksF6FwN-us",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Jake Paul Mike Tyson",
    symbol: "$JPMT",
    address: "7ErxzRN1hpyMZC8gps7ANZFTGgeDG7cFmVZcMfE6oGrd",
    decimals: 6,
    logoURI:
      "https://bafkreic4mm6qtl5ke3qfpniwch77ozovecaz656ytrdouls4bcizusuqoe.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Who",
    symbol: "WHO",
    address: "A8eYjpNQF6QYEE7DnM4F514MErFzV1pxVT69d7mdSsMk",
    decimals: 9,
    logoURI:
      "https://bafybeiaahcxwjk6ooyja3xzobfr7gteou6yxsxksyvb7qs7wlhp3mezxty.ipfs.nftstorage.link/",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "GME CAT",
    symbol: "GMECAT",
    address: "DcgDYp2AbHzCSsdhxTds887uk98wtTTA9fd2DLzNi6UZ",
    decimals: 9,
    logoURI:
      "https://node1.irys.xyz/cs-2kjnEtfahbF3pXI0raiwr6k1ZA94-SA_H_UdsTbU",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Burnie Senders",
    symbol: "SENDER",
    address: "9yotbA6u4g2ZZoUajkyS8FntaFUwVRFyLn2Q4PSrfNik",
    decimals: 9,
    logoURI:
      "https://bafkreiemgihzqfd7bxbxjzwps4e5wf3swh46up2ls5c5fy3cmnzte44dey.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "EURO2024",
    symbol: "EURO2024",
    address: "D5oCx51J1hgwGAaaWgn4aKqU8TvbaTDx3NtNUdkKrGgq",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmPpoqMS7a1789sdaHuN7VjAMZSSXLCC8ANwkFgste4wKb",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Saylor",
    symbol: "SAYLOR",
    address: "8bXZuG6NVuhdmGeMEjypYZGny48DgpZ68TvkvVTmFDdF",
    decimals: 9,
    logoURI:
      "https://bafybeihsvj7r5hypkjrkayfq5sgh5fz3ef6hpa5wpnfeesmbc52ujjuvue.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "milly",
    symbol: "MILLY",
    address: "6yNcxrS1Fd6Ut3c3bTuJKQLG9xzpbMZCBqTahvpeAXjk",
    decimals: 9,
    logoURI:
      "https://node1.irys.xyz/C8UX1RVmR042foWNTo3EjH1h007zSSFF0ODiURG7wJI",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Daumenfrosch",
    symbol: "$DAUMEN",
    address: "T1oYbAejEESrZLtSAjumAXhzFqZGNxQ4kVN9vPUoxMv",
    decimals: 9,
    logoURI:
      "https://bafkreia2rq6bzgsq2jw2of54xv6hflj6zvkiw3zba2unmlomo2czhquqqy.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Wojak",
    symbol: "WOJAK",
    address: "4MPA8WbyYtKiufXDSdckEoMPdN5XK1Xw9S9LSLMjK5Y4",
    decimals: 9,
    logoURI: "https://i.bb.co/PDkqhfK/wojak-logo.png",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Asvoria",
    symbol: "ASV",
    address: "AxaTJdRuuc3626FtPWdQCMcWPH6yzgxXKWbFCZN3TMgy",
    decimals: 9,
    logoURI:
      "https://bafybeiap2jgce7xki7m6ei2ctkkdwczxgllo3gnueia63c6aipbka3opmu.ipfs.nftstorage.link/asvoria.png",
    tags: ["community", "token-2022", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Dildo BagHands",
    symbol: "DILDO",
    address: "CDW5fC3Fp69Km6Kg4xTf5SiFqP3B24wt2SVK9GwG6LUs",
    decimals: 9,
    logoURI:
      "https://ipfs.io/ipfs/QmdKAViQSX5tyzJBAVJfukRWpjwrPM2UjSGeixAbryY9YD",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Wall Street Bets Solana",
    symbol: "WSBS",
    address: "5RDHUiwLChkKfusxeu9kEDUtRfxsDWV8etoopnZMwVM7",
    decimals: 6,
    logoURI:
      "https://bafybeibvap6pkovsxqf2jpkbh4faaz7cifefmvcozs3v4ev475zv5m2mjq.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Chinese Beaver",
    symbol: "BEAVER",
    address: "M9i5xQz8Z2Ua3VHuBkjBSkP5HYwdetu7N9RP5VUsW4z",
    decimals: 6,
    logoURI:
      "https://gateway.irys.xyz/vhQEI53x9msYz5gHSBofNA1oljDH9xKjGAqkQmge-1o",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "DMME.me",
    symbol: "DMME",
    address: "CG9WyPmf9EUQWGFUsaPAnFKuAi4eNZGGgCh7mrvAFm8p",
    decimals: 6,
    logoURI:
      "https://cf-ipfs.com/ipfs/QmerLhPZW1bXecBtGxFoFs7wByH3A2TCBkujjrSycu1Sys",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Pika",
    symbol: "PIKA",
    address: "42o42KH1dzEDjqijWpWHcNtpmW42Hxzg7YbMs1h6A5r1",
    decimals: 6,
    logoURI:
      "https://pyrgidxylosngvfqmn3ombwppzcxy5umsubmnfwcf3om76cfmoda.arweave.net/fiJkDvhbpNNUsGN25gbPfkV8doyVAsaWwi7cz_hFY4Y",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cloned BNB",
    symbol: "CLBNB",
    address: "6KnsFQzCrY1VdD9Qub1ZhRcFSnzQRqhMpT2Xn4X5P2cj",
    decimals: 8,
    logoURI: "https://markets.clone.so/images/assets/on-bnb.svg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Keng Boldwen",
    symbol: "KENG",
    address: "BKykA4H1s4717FhBGFhDXkKF4UzvvsWB4uxqHBWX8FUU",
    decimals: 3,
    logoURI:
      "https://ipfs.io/ipfs/QmS8cKcvSUXX4V7oYoLdn6W3tULQ66YAXhXZh8s5KEyXEg",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "FURBY",
    symbol: "FURBY",
    address: "34pxeud2ajwJaq7XZrYdvUUGHKJP2dFrAg6k8zvSsigt",
    decimals: 9,
    logoURI:
      "https://dd.dexscreener.com/ds-data/tokens/solana/34pxeud2ajwJaq7XZrYdvUUGHKJP2dFrAg6k8zvSsigt.png?size=lg&key=108564",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Howerd Sturn",
    symbol: "HOWIE",
    address: "9CQLBCa7n6sf1LTB6rZHJxEmtVWfBk9cRxEWTq6ygQHG",
    decimals: 6,
    logoURI:
      "https://bafybeigimaxgtna56kxqbkoxjktpmcvtn4skziel434z7r4y3rcumquuya.ipfs.cf-ipfs.com/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: "Cat Driving a Car",
    symbol: "CDC",
    address: "ASNR8RxZ3wK8QiNxmVEvxXtFGCXCHRD8oiAaNihHJvLq",
    decimals: 9,
    logoURI:
      "https://bafkreibckqkthhqv4kmbgpe564agczidyfncwk2wsplztizdppdnob3ipi.ipfs.nftstorage.link/",
    tags: ["community", "jupiter"],
    verified: true,
    holders: null,
  },
];

export default solanaTokenList;
