import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  StyledDropDownBtn,
  StyledDropDownContainer,
  StyledDropDownSelect,
  StyledDropDownText,
} from "features/metadesk/settings/IntegrationPage/components/integrationComponent.styles";
import useOutsideClick from "features/metadesk/campaign/campaignDetailPerformance/hook/useOutsideClick";
import { truncate } from "lodash";

export default function XLinkDropDown({ link, setLink, options }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  const handleLinkChange = (key) => {
    setLink(key);
  };

  return (
    <Box ref={ref} position="relative">
      <StyledDropDownSelect
        onClick={() => setIsOpen(true)}
        active={isOpen}
        sx={{ bgcolor: "white" }}
      >
        <Box>
          {truncate(options.find((item) => item.value === link).label, {
            length: 20,
          })}
        </Box>
        <i className="meta-crm-icon-ic_arrowLineDown font-size-12" />
      </StyledDropDownSelect>
      {isOpen && (
        <StyledDropDownContainer sx={{ zIndex: 100 }} left>
          <Box
            display="flex"
            justifyContent="space-between"
            height="24px"
            alignItems="center"
            fontSize="10px"
            p="8px 6px"
          >
            <Box color="#A5A5A5">Post Link</Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "#E5E5E5",
              margin: "4px 0",
            }}
          />
          <Box fontSize={12} color="#555">
            {options.map((item, index) => (
              <StyledDropDownText
                key={index}
                onClick={() => handleLinkChange(item.value)}
              >
                {item.label}
                <Box width="24px">
                  {link === item.value && (
                    <i
                      className="meta-crm-icon-ic_check font-size-24"
                      style={{ color: "#7B61FF" }}
                    />
                  )}
                </Box>
              </StyledDropDownText>
            ))}
          </Box>
        </StyledDropDownContainer>
      )}
    </Box>
  );
}
