import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import Box from "@mui/material/Box";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import Collapse from "@mui/material/Collapse";
import RadioGroup from "@mui/material/RadioGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useTheme } from "@mui/styles";
import axios from "axios";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl } from "features/configure";
import { convertIfNumeric, isAddress } from "features/helpers/utils";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import _ from "lodash";
import { get, isEmpty } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  StyledCollapseContent,
  StyledErrorHint,
  StyledFieldTitle,
  StyledFormControlLabel,
  StyledRadioButton,
  StyledRadioCheckedIcon,
  StyledRadioIcon,
  StyledRadioLabel,
} from "./CampaignStep3.styles";
import {
  StyledObjectiveListContainer,
  StyledRecentObjectiveButtonArea,
  StyledRecentObjectiveContainer,
  StyledRecentObjectiveItem,
  StyledRecentObjectiveItemContent,
  StyledRecentObjectiveItemName,
  StyledRecentObjectiveItemTitle,
  StyledToggleButtonGroup,
} from "./Objective.styles";

export const objectiveMappingName = {
  "": "All",
  tokenIn: "Token Transfer In",
  tokenOut: "Token Transfer Out",
  mintNFT: "Mint NFT",
  contract: "Contract Interaction",
};

const Objective = forwardRef(
  (
    {
      onDelete,
      objectiveIndex,
      latestObjectives = [],
      formData = { objectives: [] },
      setFormData = () => {},
      readonly,
      isTargetEvent,
      indexNumber,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [entityName, isCustomDomain] = useOutletContext();
    const stepData = formData;
    const setStepValues = setFormData;
    const { objectives } = stepData;
    const [isFetchingLoading, setIsFetchingLoading] = useState(false);
    const objectiveItem = _.get(formData, `objectives[${objectiveIndex}]`, {});
    const [usableABI, setUsableABI] = useState([]);
    const [fetchABICount, setFetchABICount] = useState(0);
    const [changeTypeManualCount, setChangeTypeManualCount] = useState(0);
    const [recentUsedExpand, setRecentUsedExpand] = useState(false);
    const [recentObjective, setRecentObjective] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const ecosystemName = useSelector(selectEcosystemName);
    const chainList = useSelector(selectEntityChainList);

    const chainListForDropdown = _.map( _.filter(chainList, (o) => !o.disabledMarketing), (chain) => {
      return {
        label: chain.name,
        value: chain.value,
        name: chain.name,
        imgURL: chain.logo_url,
      };
    });

    const theme = useTheme();

    const {
      control,
      formState: { errors: errorsInfo, isValid },
      clearErrors,
      getFieldState,
      trigger,
    } = useFormContext();

    const { append, replace } = useFieldArray({
      control: control,
      name: "objectiveList",
    });

    useImperativeHandle(ref, () => ({
      triggerValidation: () => {
        trigger(`objectiveList.${objectiveIndex}`);
      },
      getIsValid: () => isValid,
    }));

    useEffect(() => {
      const newItem = {
        chainId: "",
        smartContractAddress: "",
        objectiveName: "",
        method: null,
        type: objectiveItem.type,
        _id: objectiveItem._id,
        ...(isTargetEvent ? { objectiveName: "" } : {}),
      };
      const newObjectives = replaceObjectById(
        objectives,
        objectiveItem._id,
        newItem
      );

      setStepValues({ objectives: newObjectives });
      setRecentObjective(null);

      const stepValuesForReactHookForm = newObjectives.map((item) => {
        return {
          type: item.type,
          chainId: item.chainId,
          smartContractAddress: item.smartContractAddress,
          smartContractABI: item.smartContractABI,
          method: item.method,
          objectiveName: item.objectiveName,
        };
      });
      replace(stepValuesForReactHookForm);
      clearErrors([
        `objectiveList[${objectiveIndex}].chainId`,
        `objectiveList[${objectiveIndex}].smartContractAddress`,
        `objectiveList[${objectiveIndex}].smartContractABI`,
        `objectiveList[${objectiveIndex}].method`,
        `objectiveList[${objectiveIndex}].objectiveName`,
      ]);
    }, [changeTypeManualCount]);

    useEffect(() => {
      const setDataFromRecentObjective = async () => {
        if (recentObjective) {
          const targetObj = latestObjectives.find(
            (item) => item._id === recentObjective
          );

          const newItem = {
            ...objectiveItem,
            smartContractAddress: targetObj.smartContractAddress,
            chainId: targetObj.chainId,
            type: targetObj.type,
            smartContractABI: targetObj.smartContractABI,
            objectiveName: targetObj.objectiveName,
          };
          const newObjectives = replaceObjectById(
            objectives,
            objectiveItem._id,
            newItem
          );
          if (targetObj.smartContractABI) {
            // 要是有ABI 先把ABI filter出來 拿到裡面所有的method
            // 然後將targetObj.method設回newItem
            const result = filterDataABI(targetObj.smartContractABI);
            setUsableABI(result);
            newItem.method = targetObj.method;
          } else {
            newItem.method = null;
          }
          setStepValues({ objectives: newObjectives });

          const stepValuesForReactHookForm = newObjectives.map((item) => {
            return {
              type: item.type,
              chainId: item.chainId,
              smartContractAddress: item.smartContractAddress,
              smartContractABI: item.smartContractABI,
              method: item.method,
            };
          });
          replace(stepValuesForReactHookForm);
        }
      };

      setDataFromRecentObjective();
    }, [recentObjective]);

    const handleDeleteObjective = () => {
      onDelete(objectiveItem._id, objectiveIndex);
    };

    // fieldName是用來control在step的value用的,而name是用來給react-hook-form用的
    const handleChange = ({ event, fieldName }) => {
      const { value } = event.target;
      const newItem = { ...objectiveItem, [fieldName]: value };
      const newObjectives = replaceObjectById(
        objectives,
        objectiveItem._id,
        newItem
      );
      setStepValues({ objectives: newObjectives });
    };

    const replaceObjectById = (array, targetId, newObject) => {
      // 淺拷貝
      const newArray = array.slice();
      const index = newArray.findIndex((obj) => obj._id === targetId);
      if (index !== -1) {
        newArray[index] = newObject;
      }
      return newArray;
    };

    const handleTypesChange = (event) => {
      const newItem = { ...objectiveItem, type: event.target.value };
      const newObjectives = replaceObjectById(
        objectives,
        objectiveItem._id,
        newItem
      );
      setStepValues({ objectives: newObjectives });
      setChangeTypeManualCount((prev) => prev + 1);
    };

    const parseABIToOptions = (ABI) => {
      const options = ABI.map((ABIItem, key) => {
        return {
          label: ABIItem.name,
          value: ABIItem.name,
          id: ABIItem.id,
        };
      });

      return options;
    };

    const targetFunctionOptions = parseABIToOptions(usableABI);

    const handleFetchABI = async () => {
      try {
        setIsFetchingLoading(true);
        let result = await axios.get(
          `${apiUrl}/api/campaign/${entityName}/contract/fetchABI`,
          {
            params: {
              chainId: get(objectiveItem, "chainId"),
              address: get(objectiveItem, "smartContractAddress"),
            },
          }
        );
        const ABIText = JSON.stringify(result.data);
        // setFormData({ ...formData, contractABI: ABIText });
        const newItem = { ...objectiveItem, smartContractABI: ABIText };
        const newObjectives = replaceObjectById(
          objectives,
          objectiveItem._id,
          newItem
        );

        setStepValues({ objectives: newObjectives });

        const stepValuesForReactHookForm = newObjectives.map((item) => {
          return {
            type: item.type,
            chainId: item.chainId,
            smartContractAddress: item.smartContractAddress,
            smartContractABI: item.smartContractABI,
            method: item.method,
          };
        });
        replace(stepValuesForReactHookForm);
        await trigger();
        // 為了讓useEffect執行 handleFilterABIData,因為要是在這裡直接執行handleFilterABIData,那麽裡面的finally會比上面的setStepValues更早執行
        setFetchABICount((prev) => prev + 1);
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
        setIsFetchingLoading(false);
      }
    };

    const filterDataABI = (ABI) => {
      let ABIArray;
      let ABINotViewAndTypeFunction;

      if (ecosystemName === "Solana") {
        ABIArray = JSON.parse(ABI)[isTargetEvent ? "events" : "instructions"];
        ABINotViewAndTypeFunction = ABIArray;
      } else {
        ABIArray = JSON.parse(ABI);
        ABINotViewAndTypeFunction = ABIArray.filter(
          (ABIItem) =>
            ABIItem.type === (isTargetEvent ? "event" : "function") &&
            ABIItem.stateMutability !== "view"
        );
      }

      const ABINotViewAndTypeFunctionWidthId = ABINotViewAndTypeFunction.map(
        (ABIItem, index) => {
          return { ...ABIItem, id: index };
        }
      );

      return ABINotViewAndTypeFunctionWidthId;
    };

    useEffect(() => {
      // 第一次進來處理ABI資料時 不用把targetFunctionValue清空
      if (!isMounted) {
        handleFilterABIData(objectiveItem.smartContractABI, false);
      } else {
        handleFilterABIData(objectiveItem.smartContractABI);
      }
      setIsMounted(true);
    }, [fetchABICount]);

    const handleFilterABIData = async (
      ABI,
      clearTargetFunctionValue = true
    ) => {
      if (!ABI?.trim()) {
        setUsableABI([]);

        // 元件第一次掛載 倒入資料時 不用把targetFunctionValue清空
        const newItem = clearTargetFunctionValue
          ? { ...objectiveItem, method: null }
          : objectiveItem;

        const newObjectives = replaceObjectById(
          objectives,
          objectiveItem._id,
          newItem
        );
        setStepValues({ objectives: newObjectives });
        return;
      }
      let tempUsableABI = [];
      try {
        tempUsableABI = filterDataABI(ABI);
        setUsableABI(tempUsableABI);
      } catch (error) {
        setUsableABI([]);
        tempUsableABI = [];
        dispatch(
          enqueueSnackbar({
            message: "Please confirm ABI is correct",
            options: {
              variant: "error",
            },
          })
        );
      } finally {
        // 元件第一次掛載 倒入資料時 不用把targetFunctionValue清空
        const newItem = clearTargetFunctionValue
          ? { ...objectiveItem, method: null }
          : { ...objectiveItem, method: objectiveItem.method };

        const newObjectives = replaceObjectById(
          objectives,
          objectiveItem._id,
          newItem
        );
        setStepValues({ objectives: newObjectives });
        const stepValuesForReactHookForm = newObjectives.map((item) => {
          return {
            type: item.type,
            chainId: item.chainId,
            smartContractAddress: item.smartContractAddress,
            smartContractABI: item.smartContractABI,
            method: item.method,
            objectiveName: item.objectiveName,
          };
        });
        await replace(stepValuesForReactHookForm);
        trigger();
      }
    };

    const renderChainAndAddress = () => (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box position="relative">
            <StyledFieldTitle isRequired>
              {formatMessage({
                id: "campaignBuild.thirdStep.field.chain.title",
              })}
            </StyledFieldTitle>

            <AutoCompleteDropdown
              disabled={readonly}
              value={chainListForDropdown.find(
                (option) => option.value == objectiveItem.chainId
              )}
              options={chainListForDropdown}
              onChange={(event, v) => {
                handleChange({
                  event: { target: { value: v?.value } },
                  fieldName: "chainId",
                });
              }}
              control={control}
              name={`objectiveList[${objectiveIndex}].chainId`}
              errors={get(
                errorsInfo,
                `objectiveList.[${[objectiveIndex]}].chainId.message`
              )}
              size={SIZE.LARGE}
              rules={{
                required: {
                  value: true,
                  message: formatMessage({
                    id: "campaignBuild.thirdStep.field.chain.error.required",
                  }),
                },
              }}
              withIcon
            />
            <StyledErrorHint>
              {get(
                errorsInfo,
                `objectiveList.[${[objectiveIndex]}].chainId.message`
              )}
            </StyledErrorHint>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <InputField
            disabled={readonly}
            control={control}
            title={formatMessage({
              id:
                ecosystemName !== "Solana"
                  ? "campaignBuild.thirdStep.field.contractAddress.title"
                  : "campaignBuild.thirdStep.field.programID.title",
            })}
            isRequired
            size={SIZE.LARGE}
            onChange={(event) =>
              handleChange({ event, fieldName: "smartContractAddress" })
            }
            name={`objectiveList[${objectiveIndex}].smartContractAddress`}
            value={objectiveItem.smartContractAddress}
            errorTextAbsolute
            errors={get(
              errorsInfo,
              `objectiveList.[${[objectiveIndex]}].smartContractAddress.message`
            )}
            placeholder={formatMessage({
              id:
                ecosystemName !== "Solana"
                  ? "campaignBuild.thirdStep.field.contractAddress.placeholder"
                  : "campaignBuild.thirdStep.field.programID.placeholder",
            })}
            rules={{
              required: {
                value: true,
                message: formatMessage({
                  id: "campaignBuild.thirdStep.field.contractAddress.error.required",
                }),
              },
              validate: (value) =>
                isAddress(value) ||
                formatMessage({
                  id: "campaignBuild.thirdStep.field.contractAddress.error.notValid",
                }),
            }}
            endAdornment={
              ecosystemName !== "Solana" &&
              !readonly &&
              get(objectiveItem, "type") === "contract" && (
                <Box
                  onClick={handleFetchABI}
                  sx={{
                    color: theme.customColors.purple[500],
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  Fetch ABI
                </Box>
              )
            }
          />
        </Grid>
      </Grid>
    );

    const handleExpandClick = () => {
      setRecentUsedExpand(!recentUsedExpand);
    };

    const handleRecentObjective = (event, newRecentObjective) => {
      setRecentObjective(newRecentObjective);
      setRecentUsedExpand(false);
    };

    const renderCampaignContractObjective = () => {
      return (
        <>
          {isTargetEvent && (
            <Box mb={3}>
              {" "}
              <InputField
                disabled={readonly}
                control={control}
                title={formatMessage({
                  id: "global.objectiveName",
                })}
                isRequired
                size={SIZE.LARGE}
                onChange={(event) =>
                  handleChange({ event, fieldName: "objectiveName" })
                }
                name={`objectiveList[${objectiveIndex}].objectiveName`}
                value={objectiveItem.objectiveName}
                errorTextAbsolute
                errors={get(
                  errorsInfo,
                  `objectiveList.[${[objectiveIndex]}].objectiveName.message`
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Objective name is required.",
                  },
                }}
                placeholder={formatMessage({
                  id: "analytics.websiteTraffic.objectName.placeholder",
                })}
              />
            </Box>
          )}
          {renderChainAndAddress()}
          <Box position="relative">
            {isFetchingLoading && <LoadingComponent isAbsolute />}

            <Box mt={3} mb={3}>
              <InputField
                disabled={readonly}
                control={control}
                title={formatMessage({
                  id:
                    ecosystemName !== "Solana"
                      ? "campaignBuild.thirdStep.field.ContractABI.title"
                      : "campaignBuild.thirdStep.field.programIDL.title",
                })}
                isRequired
                size={SIZE.LARGE}
                onChange={(event) =>
                  handleChange({ event, fieldName: "smartContractABI" })
                }
                name={`objectiveList[${objectiveIndex}].smartContractABI`}
                multiline={true}
                rows={3}
                value={objectiveItem.smartContractABI}
                errorTextAbsolute
                onBlur={() =>
                  handleFilterABIData(objectiveItem.smartContractABI)
                }
                errors={get(
                  errorsInfo,
                  `objectiveList.[${[objectiveIndex]}].smartContractABI.message`
                )}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({
                      id:
                        ecosystemName !== "Solana"
                          ? "campaignBuild.thirdStep.field.ContractABI.error.required"
                          : "campaignBuild.thirdStep.field.programIDL.error.required",
                    }),
                  },
                }}
              />
            </Box>
          </Box>
          <Box position="relative" mb={3}>
            <StyledFieldTitle isRequired>
              {isTargetEvent
                ? formatMessage({
                    id: "campaignBuild.thirdStep.field.targetEvent.title",
                  })
                : formatMessage({
                    id: "campaignBuild.thirdStep.field.targetFunction.title",
                  })}
            </StyledFieldTitle>
            {readonly ? (
              <h3>{objectiveItem.method}</h3>
            ) : (
              <AutoCompleteDropdown
                name={`objectiveList[${objectiveIndex}].method`}
                control={control}
                value={targetFunctionOptions.find(
                  (option) => option.value == objectiveItem.method
                )}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required.",
                  },
                }}
                readonly={readonly}
                errors={get(
                  errorsInfo,
                  `objectiveList.[${[objectiveIndex]}].method.message`
                )}
                sx={{ width: "100%" }}
                disableClearable
                size={SIZE.LARGE}
                options={targetFunctionOptions}
                onChange={(event, v) => {
                  handleChange({
                    event: { target: { value: v?.value } },
                    fieldName: "method",
                  });
                }}
              />
            )}
          </Box>
        </>
      );
    };

    return (
      <>
        <Box mb={3}>
          {!isTargetEvent && (
            <Box
              fontWeight={700}
              color={(theme) => theme.customColors.grey[700]}
              display={"flex"}
              alignItems={"center"}
              mb={1}
            >
              {formatMessage({ id: "campaignBuild.thirdStep.objective.title" })}{" "}
              {indexNumber || objectiveIndex + 1}
              {onDelete && !readonly && (
                <IconButton
                  className="list-item-btn"
                  size={20}
                  onClick={handleDeleteObjective}
                  color={theme.customColors.grey[500]}
                  sx={{ marginLeft: "5px" }}
                >
                  <i className="meta-crm-icon-ic_trash font-size-20" />
                </IconButton>
              )}
            </Box>
          )}

          <Box mb={2}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={_.get(objectiveItem, "type", "")}
              onChange={(e) => handleTypesChange(e)}
            >
              <Grid container>
                {ecosystemName !== "Solana" && !isTargetEvent && (
                  <>
                    <Box component={Grid} item xs={12} sm={3}>
                      <StyledFormControlLabel
                        value="tokenIn"
                        control={
                          <StyledRadioButton
                            icon={<StyledRadioIcon />}
                            checkedIcon={<StyledRadioCheckedIcon />}
                          />
                        }
                        label={
                          <StyledRadioLabel>
                            {formatMessage({
                              id: "global.objective.tokenTransferIn",
                            })}{" "}
                            <Box ml="5.5px" display="flex">
                              <IconTooltip
                                content={formatMessage({
                                  id: "tooltip.objective.tokenTransferIn",
                                })}
                              />
                            </Box>
                          </StyledRadioLabel>
                        }
                      />
                    </Box>

                    <Box component={Grid} item xs={12} sm={3}>
                      <StyledFormControlLabel
                        value="tokenOut"
                        control={
                          <StyledRadioButton
                            icon={<StyledRadioIcon />}
                            checkedIcon={<StyledRadioCheckedIcon />}
                          />
                        }
                        label={
                          <StyledRadioLabel>
                            {formatMessage({
                              id: "global.objective.tokenTransferOut",
                            })}
                            <Box ml="5.5px" display="flex">
                              <IconTooltip
                                content={formatMessage({
                                  id: "tooltip.objective.tokenTransferOut",
                                })}
                              />
                            </Box>
                          </StyledRadioLabel>
                        }
                      />
                    </Box>

                    <Box component={Grid} item xs={12} sm={3}>
                      <StyledFormControlLabel
                        value="mintNFT"
                        control={
                          <StyledRadioButton
                            icon={<StyledRadioIcon />}
                            checkedIcon={<StyledRadioCheckedIcon />}
                          />
                        }
                        label={
                          <StyledRadioLabel>
                            {formatMessage({
                              id: "global.objective.mintNFT",
                            })}
                            <Box ml="5.5px" display="flex">
                              <IconTooltip
                                content={formatMessage({
                                  id: "tooltip.objective.mintNFT",
                                })}
                              />
                            </Box>
                          </StyledRadioLabel>
                        }
                      />
                    </Box>
                  </>
                )}
                {!isTargetEvent && (
                  <Box component={Grid} item xs={12} sm={3}>
                    <StyledFormControlLabel
                      value="contract"
                      control={
                        <StyledRadioButton
                          icon={<StyledRadioIcon />}
                          checkedIcon={<StyledRadioCheckedIcon />}
                        />
                      }
                      label={
                        <StyledRadioLabel>
                          {formatMessage({
                            id: "global.objective.contractInteraction",
                          })}
                          <Box ml="5.5px" display="flex">
                            <IconTooltip
                              content={formatMessage({
                                id: "tooltip.objective.contractInteraction",
                              })}
                            />
                          </Box>
                        </StyledRadioLabel>
                      }
                    />
                  </Box>
                )}
              </Grid>
            </RadioGroup>

            {objectiveItem.type === "tokenIn" && (
              <StyledCollapseContent>
                {renderChainAndAddress()}
              </StyledCollapseContent>
            )}

            {objectiveItem.type === "tokenOut" && (
              <StyledCollapseContent>
                {renderChainAndAddress()}
              </StyledCollapseContent>
            )}

            {objectiveItem.type === "mintNFT" && (
              <StyledCollapseContent>
                {renderChainAndAddress()}
              </StyledCollapseContent>
            )}

            {objectiveItem.type === "contract" && (
              <StyledCollapseContent>
                {renderCampaignContractObjective()}
              </StyledCollapseContent>
            )}
          </Box>
          {!isEmpty(latestObjectives) && (
            <Box>
              <StyledRecentObjectiveContainer>
                <StyledRecentObjectiveButtonArea
                  expanded={recentUsedExpand}
                  onClick={handleExpandClick}
                >
                  <Box>
                    {formatMessage({
                      id: "campaignBuild.thirdStep.recentUsedObjectives",
                    })}
                  </Box>
                  <i className="meta-crm-icon-ic_arrowLineDown font-size-20" />
                </StyledRecentObjectiveButtonArea>

                <Collapse in={recentUsedExpand} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      background: theme.customColors.grey[200],
                      padding: "16px",
                      borderTop: `1px dashed ${theme.customColors.grey[300]}`,
                    }}
                  >
                    <StyledToggleButtonGroup
                      value={recentObjective}
                      exclusive
                      onChange={handleRecentObjective}
                    >
                      <StyledObjectiveListContainer
                        component={Grid}
                        container
                        spacing={1}
                      >
                        {latestObjectives.map((latestObjectiveItem, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            key={latestObjectiveItem._id}
                          >
                            <StyledRecentObjectiveItem
                              component={ToggleButton}
                              value={latestObjectiveItem._id}
                            >
                              <Box mb={2}>
                                <StyledRecentObjectiveItemTitle>
                                  {formatMessage({
                                    id: "campaignBuild.thirdStep.recentObjectiveBlock.objective.title",
                                  })}
                                </StyledRecentObjectiveItemTitle>
                                <StyledRecentObjectiveItemName>
                                  {
                                    objectiveMappingName[
                                      latestObjectiveItem?.type
                                    ]
                                  }
                                </StyledRecentObjectiveItemName>
                              </Box>

                              <Box mb={2}>
                                <StyledRecentObjectiveItemTitle>
                                  {formatMessage({
                                    id: "campaignBuild.thirdStep.recentObjectiveBlock.chain.title",
                                  })}
                                </StyledRecentObjectiveItemTitle>
                                <StyledRecentObjectiveItemContent>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box
                                      component="img"
                                      width={"18px"}
                                      height={"18px"}
                                      marginRight={"4px"}
                                      src={
                                        _.find(chainList, {
                                          value: convertIfNumeric(
                                            latestObjectiveItem.chainId
                                          ),
                                        })?.logo_url
                                      }
                                      alt="latestObjectiveItemLogo"
                                    />

                                    <Box>
                                      {
                                        _.find(chainList, {
                                          value: convertIfNumeric(
                                            latestObjectiveItem.chainId
                                          ),
                                        })?.name
                                      }
                                    </Box>
                                  </Box>
                                </StyledRecentObjectiveItemContent>
                              </Box>

                              <Box mb={2}>
                                <StyledRecentObjectiveItemTitle>
                                  {formatMessage({
                                    id: "campaignBuild.thirdStep.recentObjectiveBlock.contractAddress.title",
                                  })}
                                </StyledRecentObjectiveItemTitle>
                                <StyledRecentObjectiveItemContent>
                                  {latestObjectiveItem.smartContractAddress}
                                </StyledRecentObjectiveItemContent>
                              </Box>
                              {latestObjectiveItem?.method ? (
                                <Box>
                                  <StyledRecentObjectiveItemTitle>
                                    {formatMessage({
                                      id: "campaignBuild.thirdStep.recentObjectiveBlock.Target Function.title",
                                    })}
                                  </StyledRecentObjectiveItemTitle>
                                  <StyledRecentObjectiveItemContent>
                                    {latestObjectiveItem.method}
                                  </StyledRecentObjectiveItemContent>
                                </Box>
                              ) : (
                                <Box height={"30px"}></Box>
                              )}
                            </StyledRecentObjectiveItem>
                          </Grid>
                        ))}
                      </StyledObjectiveListContainer>
                    </StyledToggleButtonGroup>
                  </Box>
                </Collapse>
              </StyledRecentObjectiveContainer>
            </Box>
          )}
        </Box>
      </>
    );
  }
);
export default Objective;
