import { useLocation } from "react-router-dom";

export function useGTMConfig(entityName) {
  const location = useLocation();
  const isOnboarding = location.pathname.includes("/onboarding");

  const gtmParams = {
    id:
      process.env.REACT_APP_ENV === "production"
        ? "GTM-K6F52LC3"
        : "GTM-NLFZXS5B",
    dataLayer: isOnboarding ? {} : { entityName },
  };

  return gtmParams;
}
