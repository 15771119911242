import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import IntegrationSuccess from "./IntegrationSuccess";
import IntegrationFailModal from "./IntegrationFailModal";
import { Loading } from "features/common";
import { useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { Button, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
const IntegrationGithub = () => {
  const [entityName, isCustomDomain] = useOutletContext();
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [checkStatus, setCheckStatus] = useState(true);
  const [checkCount, setCheckCount] = useState(0);
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();

  useEffect(() => {
    let id;
    if (checkStatus) {
      id = setInterval(() => {
        setCountdown((prevCounter) => {
          if (prevCounter <= 1) {
            fetchEntityConfig({
              entityName,
            });
            setCheckCount((prevCount) => prevCount + 1);
            return 10;
          } else {
            return prevCounter - 1;
          }
        });
      }, 1000);
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [checkStatus]);

  useEffect(() => {
    if (_.get(entityConfig, "serviceModule.github.connected")) {
      setIsSuccess(true);
      setCheckStatus(false);
    }
    if (checkCount > 15) {
      setOpen(true);
      setCheckStatus(false);
    }
  }, [checkCount]);

  const renderIntegrationScreen = () => {
    if (isSuccess) {
      return <IntegrationSuccess />;
    }
    return (
      <Loading
        open={true}
        text={
          <div style={{ textAlign: "center" }}>
            {"Checking Status..." + countdown}
            <div>
              <Button
                color={COLOR.SECONDARY}
                style={{ margin: 0, marginTop: "20px" }}
                onClick={() =>
                  navigate(
                    parseCustomDomainUrl(
                      isCustomDomain,
                      entityName,
                      "/settings/general/integration"
                    )
                  )
                }
              >
                Back to Settings
              </Button>
            </div>
          </div>
        }
      />
    );
  };
  const renderFailedModal = () => {
    return (
      <IntegrationFailModal
        onRetry={(e) => {
          setOpen(false);
          navigate(
            parseCustomDomainUrl(
              isCustomDomain,
              entityName,
              "/settings/general/integration"
            )
          );
        }}
        open={open}
        setOpen={setOpen}
      />
    );
  };

  return (
    <div style={{ minHeight: "calc(100vh - 30px)", background: "white" }}>
      {renderFailedModal()}
      {renderIntegrationScreen()}
    </div>
  );
};

export default IntegrationGithub;
