import Button, {
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import WidgetLauncher from "./components/WidgetLauncher";
import WidgetLogo from "./components/WidgetLogo";
import WidgetTheme from "./components/WidgetTheme";

export default function CustomizationDialog({
  configs,
  setConfigs,
  handleClose,
}) {
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState(configs);

  function handleSave() {
    setConfigs(data);
    handleClose();
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: 999,
        padding: "24px 144px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* background gray inset  */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.35)",
          zIndex: -1,
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          position: "relative",
          borderRadius: "4px",
        }}
      >
        <Box
          padding="32px 24px 24px 24px"
          borderBottom="1px solid #E5E5E5"
          fontSize={20}
          fontWeight={700}
          color="#555"
          display={"flex"}
          justifyContent={"space-between"}
          position="sticky"
          top={0}
        >
          <Box>Customize MetaCRM Widget Theme</Box>
          <i
            className="meta-crm-icon-ic_cancel font-size-24"
            style={{
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>
        <Box
          sx={{
            height: "calc(100% - 162px)",
            padding: "0 32px",
            overflowY: "auto",
          }}
        >
          <Box my="40px">
            <WidgetLauncher configs={data} setConfigs={setData} />
          </Box>
          <div className="mhr" style={{ margin: "12px 0 " }} />
          <Box my="40px">
            <WidgetLogo
              imgs={[data.logo] || []}
              setImgs={(img) => setData((prev) => ({ ...prev, logo: img }))}
            />
          </Box>
          <div className="mhr" style={{ margin: "12px 0 " }} />
          <Box my="40px">
            <WidgetTheme widgetItemInfo={data} setConfigs={setData} />
          </Box>
        </Box>
        <Box
          padding="16px 32px 24px"
          borderTop="1px solid #E5E5E5"
          fontSize={20}
          fontWeight={700}
          color="#555"
          display={"flex"}
          justifyContent={"flex-end"}
          position="sticky"
          bottom={0}
        >
          <Button
            size={SIZE.XL}
            variant={VARIANT.OUTLINED}
            onClick={handleClose}
            sx={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            size={SIZE.XL}
            sx={{ ml: "12px", width: 120 }}
            onClick={handleSave}
          >
            {formatMessage({
              id: "global.btn.save",
            })}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
