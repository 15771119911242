import { Box } from "@mui/material";
import React from "react";
import ReactECharts from "echarts-for-react";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { customerColors } from "features/configure/chartColors";

// 註解一下
// Bar Chart Component
// props: data - array - required - the data to be displayed in the circle chart.

export default function BarChart({ data, titleSx, chartName }) {
  const colors = customerColors.large;
  const totalValue = data.reduce((sum, item) => sum + parseInt(item.value), 0);
  let updateData = data.map((item) => {
    return {
      name: item.label,
      value: (100 * (parseFloat(item.value) / totalValue)).toFixed(1),
    };
  });

  const option = {
    colors,
    xAxis: {
      show: false,
      type: "category",
    },
    yAxis: {
      show: false,
      type: "value",
    },
    series: [
      {
        data: data.map((item, index) => ({
          value: item.value,
          itemStyle: { color: colors[index] },
        })),
        type: "bar",
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%", // Adjust the width as needed
        height: "250px", // Adjust the height as needed
        backgroundColor: "#FFF",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box sx={{ width: "50%", height: "100%", flexShrink: 0 }}>
        <ReactECharts
          option={option}
          autoResize={true}
          style={{ height: "100%", width: "100%" }}
          notMerge={true}
        />
      </Box>
      <Box sx={{ width: "50%", color: "#777", px: "12px" }}>
        <Box fontSize="14px" fontWeight="bold" mb="24px" sx={{ ...titleSx }}>
          {chartName}
        </Box>
        <Grid container>
          {updateData.map((item, index) => (
            <Grid
              xs={6}
              item
              sx={{
                display: "flex",
                alignItems: "center",
                mb: "12px",
                px: "4px",
              }}
            >
              <Box
                sx={{
                  height: "8px",
                  width: "13px",
                  borderRadius: "2px",
                  backgroundColor: colors[index],
                  mb: "1px",
                  mr: "8px",
                }}
              />
              <Box key={index} sx={{ fontSize: "12px" }}>
                {item.name}: {item.value}%
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
