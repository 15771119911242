// components/LottieAnimation.js
import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

const LottieAnimation = ({
  animationLink,
  speed = 1,
  className,
  width = "100%",
  height = "100%",
  ...props
}) => {
  if (!animationLink) return <></>;
  return (
    <Player
      autoplay
      loop
      speed={speed}
      src={animationLink} // 替換為你的 Lottie JSON 文件的路徑
      className={className}
      style={{ width, height }}
      {...props}
    />
  );
};

export default LottieAnimation;
