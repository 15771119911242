import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

export const StyledCreateGroupContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  position: "relative",
}));

export const StyledCreateButton = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  position: "relative",
  borderRadius: 8,
  border: "1px dashed #e6e6e6",
  cursor: "pointer",
  padding: "7.5px 12px",
  color: "#A5A5A5",
  width: 160,
}));

export const StyledLoadingContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  minHeight: "50vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "-25px",
  left: 0,
  right: 0,
  bottom: "-25px",
  background: alpha(theme.customColors.white, 0.5),
  zIndex: 1500,
}));

export const StyledCreateGroupArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: "1",
}));

export const StyledPageTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "700",
  color: theme.customColors.grey[700],
}));

export const StyledCreateFormContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  backgroundColor: theme.customColors.white,
  padding: "24px 25px 35px",
  borderRadius: "5px",
}));

export const StyledLine = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  backgroundColor: theme.customColors.grey[300],
  height: "1px",
  width: "100%",
  margin: "20px 0 15px",
}));

export const StyledAlert = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "&:before": {
    content: '""',
    backgroundColor: theme.customColors.purple[500],
    width: "6px",
    height: "100%",
    display: "block",
    marginRight: "8px",
  },
  backgroundColor: theme.customColors.purple[300],
  height: "40px",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: 400,
  color: theme.customColors.purple[500],
}));

export const StyledFilterCategory = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginBottom: "30px",
}));

export const StyledFilterCategoryTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 300,
  fontSize: "12px",
  color: theme.customColors.grey[500],
  display: "flex",
  alignItems: "center",
  marginBottom: "12px",
  "&:after": {
    content: '""',
    flex: 1,
    height: "1px",
    background: theme.customColors.grey[300],
    marginLeft: "8px",
  },
}));

export const StyledCollapseContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingLeft: "25px",
  display: "flex",
  alignItems: "center",
  paddingTop: "8px",
}));

export const StyledCollapseStartContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingLeft: "25px",
  display: "flex",
  alignItems: "flex-start",
  paddingTop: "8px",
}));

export const StyledTo = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  marginRight: "8px",
  color: theme.customColors.grey[500],
}));

export const StyledDash = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  borderBottom: `1px dashed ${theme.customColors.grey[400]}`,
  marginBottom: "12px",
}));

export const StyledPreviewContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "48px 32px",
  height: "100%",
}));

export const StyledPreviewTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 700,
  color: theme.customColors.grey[700],
  textAlign: "center",
}));

export const StyledPreviewGroupContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
