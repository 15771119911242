import React, { useState, useEffect, useCallback } from "react";
import { useOnboardingState } from "../OnboardingProvider";
import { Box } from "@mui/material";
import {
  StyledStepDot,
  StyledStepDotContainer,
  StyledStepLine,
} from "./Trial.styles";
import TrialStep1 from "./TrialStep1";
import TrialStep2 from "./TrialStep2";
import TrialStep3 from "./TrialStep3";
import TrialStep4 from "./TrialStep4";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import TrialStep5 from "./TrialStep5";

export default function Trial() {
  const { prevStep } = useOnboardingState();
  const [trialStep, setTrialStep] = useState(1);
  const [stepData, setStepData] = useState({
    registerId: null,
    name: "",
    email: "",
    workSpaceName: "",
    module: "",
  });
  const sendDataToGTM = useGTMDispatch();

  const trackEvent = useCallback(
    (eventName, eventData = {}) => {
      try {
        sendDataToGTM({
          event: eventName,
          ...eventData,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Failed to send GTM event:", error);
      }
    },
    [sendDataToGTM]
  );

  useEffect(() => {
    trackEvent("onboarding_trial_start");
  }, [trackEvent]);

  useEffect(() => {
    trackEvent(`onboarding_page_step_${trialStep}`, { trial_step: trialStep });
  }, [trialStep, trackEvent]);

  const handleNextStep = useCallback(() => {
    trackEvent("onboarding_next_step_click", { current_step: trialStep });
    setTrialStep((prevStep) => prevStep + 1);
  }, [trialStep, trackEvent]);

  const handlePrevStep = useCallback(() => {
    if (trialStep === 1) {
      trackEvent("onboarding_abandoned");
      prevStep();
    } else {
      trackEvent("onboarding_prev_step_click", { current_step: trialStep });
      setTrialStep((prevStep) => prevStep - 1);
    }
  }, [trialStep, prevStep, trackEvent]);

  const handleStepData = useCallback(
    (key, value) => {
      setStepData((prevData) => {
        const newData = { ...prevData, [key]: value };

        return newData;
      });
    },
    [trialStep, trackEvent]
  );

  useEffect(() => {
    if (trialStep === 4) {
      trackEvent("onboarding_completed", {
        user_name: stepData.name,
        user_email: stepData.email,
        workspace_name: stepData.workSpaceName,
      });
    }
  }, [trialStep, stepData, trackEvent]);

  if (trialStep === 4)
    return <TrialStep4 stepData={stepData} handleNextStep={handleNextStep} />;

  if (trialStep === 5) return <TrialStep5 stepData={stepData} />;

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "relative",
        backgroundColor: "#FAFAFD",
      }}
    >
      {trialStep !== 3 && (
        <Box
          onClick={handlePrevStep}
          sx={{
            position: "absolute",
            top: 24,
            left: 24,
            zIndex: 10,
            color: "#A5A5A5",
            cursor: "pointer",
          }}
        >
          <i className="meta-crm-icon-ic_arrowBackward font-size-36" />
        </Box>
      )}

      <Box sx={{ position: "absolute", top: 12, right: 16, zIndex: 10 }}>
        <img
          src={require("assets/img/onboardingLogo.png")}
          style={{ width: 79, height: 79 }}
          alt="MetaCRM Logo"
        />
      </Box>

      {/* step line */}
      <Box
        sx={{
          position: "relative",
          pt: "162px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {/* step  */}
        <Box
          sx={{ display: "flex", mb: "16px", position: "relative", mb: "52px" }}
        >
          <StyledStepDotContainer active={trialStep > 0} sx={{ left: 0 }}>
            <Box mb="6px" sx={{ whiteSpace: "nowrap" }}>
              Step 1
            </Box>
            <StyledStepDot overStep={trialStep > 1} active={trialStep > 0} />
          </StyledStepDotContainer>
          <StyledStepLine active={trialStep > 1} />
          <StyledStepDotContainer active={trialStep > 1} sx={{ left: "50%" }}>
            <Box mb="6px" sx={{ whiteSpace: "nowrap" }}>
              Step 2
            </Box>
            <StyledStepDot overStep={trialStep > 2} active={trialStep > 1} />
          </StyledStepDotContainer>
          <StyledStepLine active={trialStep > 2} />
          <StyledStepDotContainer active={trialStep > 2} sx={{ left: "100%" }}>
            <Box mb="6px" sx={{ whiteSpace: "nowrap" }}>
              Step 3
            </Box>
            <StyledStepDot active={trialStep > 2} />
          </StyledStepDotContainer>
        </Box>

        {trialStep === 1 && (
          <TrialStep1
            nextStep={handleNextStep}
            stepData={stepData}
            handleStepData={handleStepData}
          />
        )}
        {trialStep === 2 && (
          <TrialStep2
            nextStep={handleNextStep}
            stepData={stepData}
            handleStepData={handleStepData}
          />
        )}
        {trialStep === 3 && (
          <TrialStep3
            stepData={stepData}
            handleStepData={handleStepData}
            nextStep={handleNextStep}
          />
        )}
      </Box>
    </Box>
  );
}
