import { useDispatch, useSelector } from "react-redux";

// Action types
const SAVE_ROLES = "SAVE_ROLES";

// Action creators
export const saveRoles = (roles) => ({
  type: SAVE_ROLES,
  payload: { roles },
});

// Reducer
const initialState = {
  roles: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };
    default:
      return state;
  }
}

export function useReduxRoles() {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.metadesk.roles);
  
  function saveRoles(roles) {
    dispatch(saveRoles(roles));
  }

  return { roles, saveRoles };
}
