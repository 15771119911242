import { createSelector } from "reselect";

const selectTrialReducer = (state) => {
  return state.metadesk.trial;
};

export const selectTrialIsLoading = createSelector(
  [selectTrialReducer],
  (trial) => trial.isLoading
);

export const selectTrialHasConfirm = createSelector(
  [selectTrialReducer],
  (trial) => trial.hasConfirm
);

export const selectTrialEntityName = createSelector(
  [selectTrialReducer],
  (trial) => trial.entityName
);
