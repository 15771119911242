import React, { useRef, useState, useEffect, useMemo } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import { Box } from "@mui/material";
import {
  useFetchUserList,
  useFetchTags,
  useFetchEntityConfig,
} from "../redux/hooks";
import CustomTable from "components/CustomTable/CustomTable";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import {
  Button,
  COLOR,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import BigNumber from "bignumber.js";
import { useDispatch } from "react-redux";
import {
  alertServerError,
  formatDate,
  parseCustomDomainUrl,
} from "features/helpers/utils";
import {
  StyledStartRowTitleInsideContainer,
  StyledListFunctionRow,
} from "./ImportUserList.styles";
import CustomPagination from "components/CustomPagination/CustomPagination";
import Address from "../components/Address";
import TableMenu from "components/CustomTable/TableMenu";
import axios from "axios";
import { apiUrl } from "features/configure";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
import { StyledTableMenuButton } from "components/CustomTable/styles";
import ExportModal from "../components/ImportList/ExportModal";
import { useDebounceEffect } from "components/ConnectWallet/ConnectWallet";
import TokensWraper from "../components/Icons/TokensWraper";
import { MetaCRMTag } from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

import { USER_FILTER_INITIAL_STATE } from "../redux/fetchUserList";
import AssignTagsModal from "../components/ImportList/AssignTagsModal";
import UnassignTagsModal from "../components/ImportList/UnassignTagsModal";
import moment from "moment";
import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { isEmpty, startCase } from "lodash-es";
import UserTag from "../components/UserTag";
import useNavigateEntity from "hooks/useNavigateEntity";
import NFTsWrapper from "../components/Icons/NFTsWrapper";

const useStyles = makeStyles((theme) => ({
  selected: {
    "&.Mui-selected": {
      backgroundColor: `#CBE6FF`,
      "&:hover": {
        backgroundColor: `#CBE6FF`,
      },
    },
  },
  dropdown: {
    "&:focus": {
      borderRadius: 3,
      backgroundColor: "white",
    },
  },
  root: {
    background: "white",
    padding: "4px 8px",
    minHeight: 22,
    minWidth: 90,
    fontSize: 12,
    borderRadius: 3,
    fontWeight: 500,
    border: (props) => (props.border ? "1px solid #E6E6E6" : "1px solid white"),
    "&[aria-expanded=true]": { borderRadius: 3, border: "1px solid #7B61FF" },
  },
  searchField: {
    background: "#FFFFFF",
    padding: "2px 0px",
    alignItems: "stretch",
    borderRadius: 3,
    position: "relative",
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  searchLabel: {
    padding: 5,
    color: "#A5A5A5",
    borderRadius: "5px 0 0 5px",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    whiteSpace: "nowrap",
    marginRight: 10,
  },
}));
const initHeaders = [
  {
    title: "User Identity",
    showText: "User Identity",
    disabledSort: false,
    show: true,
    id: "userIdentity",
    fixed: true,
    width: 170,
  },
  {
    title: "Wallet Value",
    showText: "Wallet Value",
    disabledSort: false,
    show: true,
    id: "walletValue",
    width: 130,
    fixed: false,
  },
  {
    title: "NFT Amount",
    showText: "NFT Amount",
    disabledSort: false,
    show: true,
    id: "nftAmount",
    width: 130,
    fixed: false,
  },
  {
    title: "Tags",
    showText: "Tags",
    disabledSort: true,
    show: true,
    id: "tags",
    width: 120,
  },
  // {
  //   title: "Tags",
  //   showText: "Tags",
  //   disabledSort: true,
  //   show: true,
  //   id: "tags",
  //   width: 80,
  //   fixed: false,
  // },

  {
    title: "NFT Holdings",
    showText: "NFT Holdings",
    disabledSort: true,
    show: true,
    id: "nftHoldings",
    width: 180,
  },
  {
    title: "Token Holding",
    showText: "Token Holding",
    disabledSort: true,
    show: true,
    id: "tokens",
    width: 150,
  },
  {
    title: "Protocol",
    showText: "Protocol",
    disabledSort: true,
    show: true,
    id: "protocols",
    width: 150,
  },
  {
    title: "Create Time",
    showText: "Create Time",
    disabledSort: true,
    show: true,
    id: "createTime",
    width: 150,
  },
];

const kMapping = {
  "Wallet Value": "walletValue",
  "NFT Amount": "nftAmount",
  Tags: "tags.length",
};

const NewImportUserList = () => {
  const { entityConfig } = useFetchEntityConfig();
  const [entityName, isCustomDomain] = useOutletContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const [openExport, setOpenExport] = useState(false);
  const [openAssignTags, setOpenAssignTags] = useState(false);
  const [openUnassignTags, setOpenUnassignTags] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { tags } = useFetchTags();
  const tagOptions = _.get(tags, `[${entityName}]`, []);
  const [selectedRowUserData, setSelectedRowUserData] = useState([]);

  const PAGE_COUNT = 20;
  const {
    userSource,
    fetchUserListPending,
    userList,
    fetchUserAdminList,
    userListCount,
    updateUserListFilter,
    userListFilter,
    fetchUserSource,
  } = useFetchUserList();

  const page = _.get(userListFilter, "page", 1);
  const orderData = _.get(userListFilter, "orderData");
  const searchSource = _.get(userListFilter, "searchSource");
  const search = _.get(userListFilter, "search");
  const [searchText, setSearchText] = useState(search);
  const searchTags = _.get(userListFilter, "searchTags", []);

  const platFormList = ["Discord", "X", "Email"];

  const selectedCount = selectedRowUserData.length;

  const platformFilterNumber = useMemo(
    () => userListFilter.platform.length,
    [userListFilter.platform]
  );

  const searchTagsFilterNumber = useMemo(
    () =>
      userListFilter.searchTags.includes("")
        ? 0
        : userListFilter.searchTags.length,
    [userListFilter.searchTags]
  );

  const topFilterNumber = useMemo(
    () => searchTagsFilterNumber + platformFilterNumber,
    [searchTagsFilterNumber, platformFilterNumber]
  );

  const handleChangeCheckbox = (e, filterKey) => {
    e.keepOpen = true;
    if (e.checked) {
      // 要是是選中
      if (e.value === "") {
        // 選全選的話(空字串) 就只要設定全選進入結果就好
        updateUserListFilter({
          ...userListFilter,
          page: 1,
          [filterKey]: [],
        });
      } else {
        // 選全選以外的 就要把空字串過去料 並把舊的值 新的值都放進去
        const newFilter = userListFilter[filterKey].filter(
          (value) => value !== ""
        );
        updateUserListFilter({
          ...userListFilter,
          page: 1,
          [filterKey]: [...newFilter, e.value],
        });
      }
    } else {
      // 要是是取消
      const newValue = userListFilter[filterKey].filter(
        (value) => value !== e.value
      );
      updateUserListFilter({
        ...userListFilter,
        page: 1,
        [filterKey]: newValue,
      });
    }
  };

  const itemList = [
    {
      children: <>User IDs</>,
      filterNumber: platformFilterNumber,
      subMenu: [
        ...platFormList.map((item) => ({
          children: <>{item}</>,
          value: item,
          type: "checkbox",
          backgroundColor: item.color,
          checked: userListFilter.platform.some(
            (platform) => platform === item
          ),
          func: (e) => handleChangeCheckbox(e, "platform"),
        })),
      ],
    },
    {
      children: <>Tag</>,
      filterNumber: searchTagsFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "checkbox",
          checked: isEmpty(userListFilter.searchTags),
          func: (e) => handleChangeCheckbox(e, "searchTags"),
        },
        ...tagOptions.map((item) => ({
          children: <>{item.value}</>,
          value: item._id,
          type: "checkbox",
          backgroundColor: item.color,
          checked: userListFilter.searchTags.some(
            (tagValue) => tagValue === item._id
          ),
          func: (e) => handleChangeCheckbox(e, "searchTags"),
        })),
      ],
    },
  ];

  useEffect(() => {
    handleFetchUserSource();
  }, []);

  const handleFetchUserSource = () => {
    fetchUserSource({ entityName: entityName });
  };

  const onFetchUserAdminList = async () => {
    await fetchUserAdminList({
      entityName,
      from: "all",
      filter: userListFilter,
      search,
      searchSource,
      searchTags,
    });
    setLoading(false);
  };
  useEffect(() => {
    onFetchUserAdminList();
  }, [userListFilter]);

  const handleSort = (header, direction) => {
    const by = header || orderData.sort;
    const dir =
      orderData.sort != by ? orderData.order : direction || orderData.order;
    updateUserListFilter({ orderData: { sort: by, order: dir } });
  };

  const selectedUserData = selectedRowUserData;

  const deleteSomeUsers = async () => {
    try {
      if (selectedCount == 0) return;
      const selectedUserData = _.map(selectedRowUserData, "_id");
      await axios.post(`${apiUrl}/api/userSource/${entityName}/deleteUsers`, {
        userIds: selectedUserData,
      });
      setSelectedRowUserData([]);
      onFetchUserAdminList();
      handleFetchUserSource();
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  const renderContents = (user) => {
    let contents = [
      <div slot="userIdentity">
        <Address user={user} showExtra />
      </div>,
      <div slot="walletValue">
        {user?.walletValue
          ? "$" + new BigNumber(user?.walletValue).toFormat(0)
          : "-"}
      </div>,
      <div slot="nftAmount">{user?.nftAmount || "-"}</div>,
      <div slot="nftHoldings">
        <NFTsWrapper
          nfts={_.get(user, "nftHoldings", [])}
          size={"24px"}
          showNumber={5}
          marginLeft="-8px"
          nftAmounts={user?.nftAmount}
        />
      </div>,
      <div slot="tags" onClick={(e) => e.stopPropagation()}>
        <UserTag
          entityName={entityName}
          width={"fit-content"}
          minWidth={0}
          showNumber={1}
          userInfo={{
            _id: user._id,
            address: user.address,
            tags: _.get(user, "tags", []),
          }}
          disabled
        />
      </div>,
      <div slot="protocols">
        <TokensWraper
          tokens={_.get(user, "protocols", [])}
          size={"24px"}
          showNumber={5}
          marginLeft="-8px"
        />
      </div>,
      <div slot="tokens">
        <TokensWraper
          tokens={_.get(user, "tokens", [])}
          size={"24px"}
          showNumber={5}
          marginLeft="-8px"
        />
      </div>,
      <div slot="createTime">
        {user.source && (
          <Box fontSize={12} color="#A5A5A5">
            {formatDate(user.source?.date, { time: true })}
          </Box>
        )}
      </div>,
    ];
    return contents;
  };
  const selectedMenu = [
    { label: `Selected: ${selectedCount}` },
    {
      label: (
        <div className="startRow">
          <i className="meta-crm-icon-ic_tag font-size-18 mgr4" />
          Assign Tag
        </div>
      ),
      onClick: () => {
        setOpenAssignTags("selected");
      },
    },
    {
      label: (
        <div className="startRow">
          <i className="meta-crm-icon-ic_unassignTag font-size-18 mgr4" />
          Unassign Tag
        </div>
      ),
      onClick: () => {
        setOpenUnassignTags("selected");
      },
    },
    {
      label: (
        <div className="startRow">
          <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
          Export
        </div>
      ),
      onClick: () => {
        setOpenExport(true);
      },
    },
    {
      button: (
        <RemoveConfirm
          onRemove={deleteSomeUsers}
          removeComponent={
            <StyledTableMenuButton
              color={COLOR.SECONDARY}
              size={SIZE.SMALL}
              className="tabMenuButton"
              startIcon={<i className="meta-crm-icon-ic_trash font-size-14" />}
            >
              Delete
            </StyledTableMenuButton>
          }
        />
      ),
    },
  ];
  const isLoading = fetchUserListPending || loading;

  const getUsersIntersectionTag = () => {
    const data = _.intersectionBy(userList, selectedRowUserData, "_id");
    const intersectionTags = _.intersectionBy(..._.map(data, "tags"), "_id");
    return intersectionTags;
  };

  const { navigateEntity } = useNavigateEntity();

  return (
    <>
      <div className="betweenRow">
        <div>
          <div className="title">
            <StyledStartRowTitleInsideContainer>
              Customer List
            </StyledStartRowTitleInsideContainer>
          </div>
        </div>
      </div>

      <StyledListFunctionRow>
        <div style={{ marginRight: 8 }}>
          <InputField
            size={SIZE.MEDIUM}
            onEnter={(v, e) => e.target.blur()}
            placeholder="Search list by address..."
            startAdornment={
              <i
                className="meta-crm-icon-ic_search font-size-16"
                style={{ marginRight: 5, color: "#A5A5A5" }}
              />
            }
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onBlur={() => {
              updateUserListFilter({ search: searchText, page: 1 });
              setSelectedRowUserData([]);
            }}
          />
        </div>

        <Box mr={1}>
          <Menu
            overflow="auto"
            position="initial"
            itemList={itemList}
            isFilter
            topFilterNumber={topFilterNumber}
            zIndex={500}
          />
        </Box>

        <Button
          variant={VARIANT.TEXT}
          style={{ padding: 0, marginLeft: 8 }}
          size={SIZE.SMALL}
          onClick={() => {
            updateUserListFilter(USER_FILTER_INITIAL_STATE.userListFilter);
            setSelectedRowUserData([]);
            setSearchText("");
          }}
        >
          Clear all filters
        </Button>
      </StyledListFunctionRow>
      <div style={{ position: "relative" }}>
        <CustomTable
          checkbox
          containerStyle={{ height: "63vh" }}
          loading={isLoading}
          rowData={userList}
          selectedRowData={selectedRowUserData}
          setSelectedRowData={setSelectedRowUserData}
          type={"all"}
          noDataTitle="No User"
          canChangeOrder={true}
          sortDataFunction={handleSort}
          sortBy={orderData.sort}
          hideCustomize
          sortMapping={kMapping}
          headers={initHeaders}
          rowSelected={(rowIndex) => {
            navigate(
              parseCustomDomainUrl(
                isCustomDomain,
                entityName,
                `/customers/user/${rowIndex}`
              )
            );
          }}
          contents={_.map(userList, (user, i) => {
            return renderContents(user);
          })}
          noDataComponent={
            _.get(entityConfig, "modules.marketing") && (
              <div>
                <img
                  src={require("assets/img/emptyToImport.svg").default}
                  style={{ width: 254 }}
                />
                <div className="title">It seems a little empty out here!</div>
              </div>
            )
          }
        />
        <TableMenu show={selectedCount > 0} tabs={selectedMenu} />
      </div>
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={userListCount}
        page={page}
        setPage={(v) => updateUserListFilter({ page: v })}
      />
      <ExportModal
        open={openExport}
        onClose={() => setOpenExport(false)}
        dataset={_.find(userSource, { _id: "all" })}
        entityName={entityName}
        choose={_.map(selectedUserData, "_id")}
      />
      <AssignTagsModal
        open={openAssignTags}
        onClose={(isRefresh) => {
          setOpenAssignTags(false);
          if (isRefresh) onFetchUserAdminList();
        }}
        entityName={entityName}
        choose={_.map(selectedUserData, "_id")}
      />
      <UnassignTagsModal
        open={openUnassignTags}
        onClose={(isRefresh) => {
          setOpenUnassignTags(false);
          if (isRefresh) onFetchUserAdminList();
        }}
        entityName={entityName}
        choose={_.map(selectedUserData, "_id")}
        uniqTags={getUsersIntersectionTag()}
      />
    </>
  );
};

export default NewImportUserList;
