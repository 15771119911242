import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  alertServerError,
  getHexImg,
  uploadFiles,
} from "features/helpers/utils";
import {
  IconButton,
  SIZE,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useOutletContext } from "react-router-dom";
import { useHover } from "utils/useHover";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import ImageUpload from "./ImageUpload";
import { useDispatch } from "react-redux";
import { apiUrl } from "features/configure";
import { getShortAddress } from "features/helpers/utils";

const ContractLabelEditor = ({ data, editable = true, updateData }) => {
  const [entityName] = useOutletContext();
  const [editMode, setEditMode] = useState(false);
  const [hoverRef, isHovering] = useHover();
  const dispatch = useDispatch();
  const [editName, setEditName] = useState(_.get(data, "name"));
  const [editImg, setEditImg] = useState("");

  useEffect(() => {
    if (!editName) {
      setEditMode(true);
    }
  }, [editName]);

  const onUpdateName = async () => {
    try {
      await updateData({ data, editName });
      setEditMode(false);
    } catch (err) {
      console.log("err: ", err);
      dispatch(alertServerError(err));
    }
  };
  const onUpdateImg = async (list) => {
    try {
      const images = await uploadFiles([list[0]], entityName);
      setEditImg(images[0]);
      await updateData({ data, images });
    } catch (err) {
      console.log("err: ", err);
      dispatch(alertServerError(err));
    }
  };
  return (
    <div className="startRow">
      {editable ? (
        <ImageUpload
          value={[]}
          acceptType={["png", "jpg", "heic", "jpeg"]}
          onChange={onUpdateImg}
          uploadComponent={
            <div
              ref={hoverRef}
              style={{ position: "relative", cursor: "pointer" }}
            >
              {editable && (
                <div
                  className="avatar"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    background: "rgba(0, 0, 0, 0.30)",
                    width: 35,
                    height: 35,
                    opacity: isHovering ? 1 : 0,
                    color: "#FFFFFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="meta-crm-icon-ic_img font-size-18" />
                </div>
              )}
              <img
                src={
                  editImg ||
                  _.get(data, "icon") ||
                  getHexImg(_.get(data, "address"))
                }
                className="avatar"
              />
            </div>
          }
        />
      ) : (
        <img
          src={
            editImg || _.get(data, "icon") || getHexImg(_.get(data, "address"))
          }
          className="avatar"
        />
      )}

      <div>
        <div
          className="formTitle startRow"
          style={{ alignItems: "flex-start" }}
        >
          {editMode ? (
            <>
              <div>
                <InputField
                  size={SIZE.MEDIUM}
                  style={{ width: 140 }}
                  placeholder="Give a name"
                  onEnter={onUpdateName}
                  value={editName}
                  errors={!editName && " "}
                  onChange={(e) => setEditName(e.target.value)}
                />
                {!editName && <div className="error">Name is required!</div>}
              </div>
              <IconButton
                size={16}
                style={{ marginLeft: 4, marginTop: 8 }}
                color={COLOR.SECONDARY}
                onClick={onUpdateName}
              >
                <i className="meta-crm-icon-ic-checkCircle font-size-32" />
              </IconButton>
            </>
          ) : (
            <>
              {editName}
              {editable && (
                <IconButton
                  size={16}
                  style={{ marginLeft: 4 }}
                  color="#555555"
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <i className="meta-crm-icon-ic_edit-1 font-size-12" />
                </IconButton>
              )}
            </>
          )}
        </div>
        <div className="memo" style={{ fontWeight: 500 }}>
          {getShortAddress(_.get(data, "address"))}
        </div>
      </div>
    </div>
  );
};

export default ContractLabelEditor;
