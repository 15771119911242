export const radiantGovernaceToken = {
  42161: {
    name: "RDNT",
    address: "0x3082cc23568ea640225c2467653db90e9250aaa0",
    decimals: 18,
    coingeckoId: "radiant-capital",
    img_url: require("assets/img/RDNT.svg"),
  },
  56: {
    name: "RDNT",
    address: "0xf7DE7E8A6bd59ED41a4b5fe50278b3B7f31384dF",
    decimals: 18,
    coingeckoId: "radiant-capital",
    img_url: require("assets/img/RDNT.svg"),
  },
  1: {
    name: "RDNT",
    address: "0x137dDB47Ee24EaA998a535Ab00378d6BFa84F893",
    decimals: 18,
    coingeckoId: "radiant-capital",
    img_url: require("assets/img/RDNT.svg"),
  },
};
export const radiantLPToken = {
  42161: {
    name: "dLP",
    address: "0x32dF62dc3aEd2cD6224193052Ce665DC18165841",
    decimals: 18,
    img_url: require("assets/img/dLP.svg"),
  },
  56: {
    name: "dLP",
    address: "0x346575fc7f07e6994d76199e41d13dc1575322e1",
    decimals: 18,
    img_url: require("assets/img/dLP.svg"),
  },
  1: {
    name: "dLP",
    address: "0xcF7b51ce5755513d4bE016b0e28D6EDEffa1d52a",
    decimals: 18,
    img_url: require("assets/img/dLP.svg"),
  },
};

export const radiantAccountingTokens = {
  42161: [
    {
      name: "rWBTC",
      address: "0x727354712bdfcd8596a3852fd2065b3c34f4f770",
      decimals: 8,
      coingeckoId: "bitcoin",
      debt: false,
      liquidationThreshold: 0.75,
      img_url: require("assets/img/WBTC.svg"),
    },
    {
      name: "rUSDT",
      address: "0xd69d402d1bdb9a2b8c3d88d98b9ceaf9e4cd72d9",
      decimals: 6,
      coingeckoId: "tether",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDT.png"),
    },
    {
      name: "rUSDC",
      address: "0x48a29e756cc1c097388f3b2f3b570ed270423b3d",
      decimals: 6,
      coingeckoId: "usd-coin",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "rUSDCn",
      address: "0x3a2d44e354f2d88EF6DA7A5A4646fd70182A7F55",
      decimals: 6,
      coingeckoId: "usd-coin",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "rDAI",
      address: "0x0d914606f3424804fa1bbbe56ccc3416733acec6",
      decimals: 18,
      coingeckoId: "dai",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/DAI.png"),
    },
    {
      name: "rWETH",
      address: "0x0df5dfd95966753f01cb80e76dc20ea958238c46",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: false,
      liquidationThreshold: 0.825,
      img_url: require("assets/img/WETH.svg"),
    },
    {
      name: "rWSTETH",
      address: "0x42c248d137512907048021b30d9da17f48b5b7b2",
      decimals: 18,
      coingeckoId: "wrapped-steth",
      debt: false,
      liquidationThreshold: 0.8,
      img_url: require("assets/img/WSTETH.svg"),
    },
    {
      name: "rARB",
      address: "0x2dade5b7df9da3a7e1c9748d169cd6dff77e3d01",
      decimals: 18,
      coingeckoId: "arbitrum",
      debt: false,
      liquidationThreshold: 0.5,
      img_url: require("assets/img/ARB.svg"),
    },
    {
      name: "dWBTC",
      address: "0x3eeafa33625df20837ed0cb83ae4d1e34788b141",
      decimals: 8,
      coingeckoId: "bitcoin",
      debt: true,
    },
    {
      name: "dUSDT",
      address: "0xd69d402d1bdb9a2b8c3d88d98b9ceaf9e4cd72d9",
      decimals: 6,
      coingeckoId: "tether",
      debt: true,
    },
    {
      name: "dUSDC",
      address: "0x107583adaa37dfd1cc0bf577183bf91351d07413",
      decimals: 6,
      coingeckoId: "usd-coin",
      debt: true,
    },
    {
      name: "dUSDCn",
      address: "0xD69b772bfFe78165c152Fd2e370b6CaE239E3BDb",
      decimals: 6,
      coingeckoId: "usd-coin",
      debt: true,
    },
    {
      name: "dDAI",
      address: "0x04a8faed05c97290ab4d793a971adee97cd1cbbd",
      decimals: 18,
      coingeckoId: "dai",
      debt: true,
    },
    {
      name: "dWETH",
      address: "0xab04c0841f39596c9f18a981a2bd32f63ab7a817",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: true,
    },
    {
      name: "dWSTETH",
      address: "0x97b81aa985115953ba31d59781e2d8159a50f488",
      decimals: 18,
      coingeckoId: "wrapped-steth",
      debt: true,
    },
    {
      name: "dARB",
      address: "0x295b97012945bd4a1a79ec7f679e16761a437e5c",
      decimals: 18,
      coingeckoId: "arbitrum",
      debt: true,
    },
  ],
  56: [
    {
      name: "rBTCB",
      address: "0x34d4f4459c1b529bebe1c426f1e584151be2c1e5",
      decimals: 18,
      coingeckoId: "binance-bitcoin",
      debt: false,
      liquidationThreshold: 0.75,
      img_url: require("assets/img/WBTC.svg"),
    },
    {
      name: "rUSDT",
      address: "0x4ff2dd7c6435789e0bb56b0553142ad00878a004",
      decimals: 18,
      coingeckoId: "tether",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDT.png"),
    },
    {
      name: "rBUSD",
      address: "0x89d763e8532d256a3e3e60c1c218ac71e71cf664",
      decimals: 18,
      coingeckoId: "binance-usd",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/BUSD.png"),
    },
    {
      name: "rUSDC",
      address: "0x3bdcef9e656fd9d03ea98605946b4fbf362c342b",
      decimals: 18,
      coingeckoId: "usd-coin",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "rBNB",
      address: "0x58b0bb56cfdc5192989461dd43568bcfb2797db",
      decimals: 18,
      coingeckoId: "binancecoin",
      debt: false,
      liquidationThreshold: 0.825,
      img_url: require("assets/img/BNB.svg"),
    },
    {
      name: "rETH",
      address: "0x455a281D508B4e34d55b31AC2e4579BD9b77cA8E",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: false,
      liquidationThreshold: 0.825,
      img_url: require("assets/img/ETH.png"),
    },
    {
      name: "vdBTCB",
      address: "0x455a281d508b4e34d55b31ac2e4579bd9b77ca8e",
      decimals: 18,
      coingeckoId: "bitcoin",
      debt: true,
      img_url: require("assets/img/WBTC.svg"),
    },
    {
      name: "vdUSDT",
      address: "0x437f3df56ecee512e407b6ec368523c911d4a283",
      decimals: 18,
      coingeckoId: "tether",
      debt: true,
    },
    {
      name: "vdBUSD",
      address: "0x8d3308e14a48f0681cea94d7c6995ec73b3973f1",
      decimals: 18,
      coingeckoId: "binance-usd",
      debt: true,
    },
    {
      name: "vdUSDC",
      address: "0x81fda3be7f3ca6acdae20a8d2b4ce54b78d70ed0",
      decimals: 18,
      coingeckoId: "usd-coin",
      debt: true,
    },
    {
      name: "vdBNB",
      address: "0xcbb96324f77a66e276f80b843ecdb3fadc551bff",
      decimals: 18,
      coingeckoId: "binancecoin",
      debt: true,
    },
    {
      name: "vdETH",
      address: "0xdcb45a8aa72854e06c826b16fd5038f33e2cb4b0",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: true,
    },
  ],

  1: [
    {
      name: "rWBTC",
      address: "0xE57538e4075446e42907Ea48ABFa83B864F518e4",
      decimals: 8,
      coingeckoId: "bitcoin",
      debt: false,
      liquidationThreshold: 0.75,
      img_url: require("assets/img/WBTC.svg"),
    },
    {
      name: "rUSDT",
      address: "0x3c19d9F2Df0E25C077A637692DA2337D51daf8B7",
      decimals: 6,
      coingeckoId: "tether",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDT.png"),
    },
    {
      name: "rUSDC",
      address: "0x9E85DF2B42b2aE5e666D7263ED81a744a534BF1f",
      decimals: 6,
      coingeckoId: "usd-coin",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "rsDAI",
      address: "0x473693EcDAd05f5002ff5F63880CFA5901FB50E8",
      decimals: 18,
      coingeckoId: "dai",
      debt: false,
      liquidationThreshold: 0.85,
      img_url: require("assets/img/DAI.png"),
    },
    {
      name: "rWETH",
      address: "0xd10c315293872851184F484E9431dAf4dE6AA992",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: false,
      liquidationThreshold: 0.825,
      img_url: require("assets/img/WETH.svg"),
    },
    {
      name: "rWSTETH",
      address: "0x83B3896EC36cB20cFB430fCFE8Da23D450Dd09B5",
      decimals: 18,
      coingeckoId: "wrapped-steth",
      debt: false,
      liquidationThreshold: 0.8,
      img_url: require("assets/img/WSTETH.svg"),
    },
    {
      name: "rrETH",
      address: "0x03AB03DA2c5012855c743bc318c19EF3dE5Bc906",
      decimals: 18,
      coingeckoId: "arbitrum",
      debt: false,
      liquidationThreshold: 0.5,
      img_url: require("assets/img/ETH.svg"),
    },
    {
      name: "vdWBTC",
      address: "0x0184eB8A4d86ff250cB2F7F3146AeCC14ccb73A4",
      decimals: 8,
      coingeckoId: "bitcoin",
      debt: true,
    },
    {
      name: "vdUSDT",
      address: "0x2D4fc0D5421C0d37d325180477ba6e16ae3aBAA7",
      decimals: 6,
      coingeckoId: "tether",
      debt: true,
    },
    {
      name: "vdUSDC",
      address: "0x490726291F6434646FEb2eC96d2Cc566b18a122F",
      decimals: 6,
      coingeckoId: "usd-coin",
      debt: true,
    },
    {
      name: "vdsDAI",
      address: "0xE491C1A4150E9925e8427bea4CDCBD250B730e5C",
      decimals: 18,
      coingeckoId: "dai",
      debt: true,
    },
    {
      name: "vdrETH",
      address: "0x6a0e8b4D16d5271492bb151Eb4767f25cFc23f03",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: true,
    },
    {
      name: "vdWSTETH",
      address: "0xc8CBb48a0EED0e406bb52a5cC939358c0aB644A7",
      decimals: 18,
      coingeckoId: "wrapped-steth",
      debt: true,
    },
    {
      name: "vdrETH",
      address: "0x6a0e8b4D16d5271492bb151Eb4767f25cFc23f03",
      decimals: 18,
      coingeckoId: "ethereum",
      debt: true,
    },
    {
      name: "rweETH",
      overlyingToken: "0x1d25Bd8AbfEb1D6517Cc21BeCA20b5cd2df8247c",
      decimals: 18,
      coingeckoId: "wrapped-eeth",
      debt: false,
      liquidationThreshold: 0.75,
      underlyingToken: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
    },
    {
      name: "vdweETH",
      overlyingToken: "0xcDE79c767826849e30AAe7c241c369FCe54dB707",
      decimals: 18,
      coingeckoId: "wrapped-eeth",
      debt: true,
      underlyingToken: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
    },
  ],
};

export const tokenMapping = {
  1: [
    {
      name: "WBTC",
      address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      decimals: 8,
      img_url: require("assets/img/WBTC.svg").default,
    },
    {
      name: "USDT",
      address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      decimals: 18,
      img_url: require("assets/img/USDT.png"),
    },
    {
      name: "USDC",
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      decimals: 18,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "WETH",
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      decimals: 18,
      img_url: require("assets/img/ETH.png"),
    },
    {
      name: "ETH",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimals: 18,
      img_url: require("assets/img/ETH.png"),
    },
    {
      name: "WSTETH",
      address: "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
      decimals: 18,
      img_url: require("assets/img/WSTETH.svg").default,
    },
    {
      name: "sDAI",
      address: "0x83f20f44975d03b1b09e64809b757c47f942beea",
      decimals: 18,
      img_url: require("assets/img/DAI.png"),
    },
    {
      name: "rETH",
      address: "0xae78736cd615f374d3085123a210448e74fc6393",
      decimals: 18,
      img_url: require("assets/img/WETH.svg").default,
    },
    {
      name: "weETH",
      address: "0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee",
      decimals: 18,
      img_url: require("assets/img/weETH.png"),
    },
  ],
  56: [
    {
      name: "BTCB",
      address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
      decimals: 18,
      img_url: require("assets/img/BTCB.svg").default,
    },
    {
      name: "USDT",
      address: "0x55d398326f99059ff775485246999027b3197955",
      decimals: 18,
      img_url: require("assets/img/USDT.png"),
    },
    {
      name: "BUSD",
      address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      decimals: 18,
      img_url: require("assets/img/BUSD.png"),
    },
    {
      name: "USDC",
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      decimals: 18,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "WETH",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals: 18,
      img_url: require("assets/img/ETH.png"),
    },
    {
      name: "BNB",
      address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      decimals: 18,
      img_url: require("assets/img/BNB.svg").default,
    },
    {
      name: "WETH",
      address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
      decimals: 18,
      img_url: require("assets/img/ETH.svg").default,
    },
  ],
  42161: [
    {
      name: "WBTC",
      address: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      decimals: 8,
      img_url: require("assets/img/WBTC.svg").default,
    },

    {
      name: "USDT",
      address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
      decimals: 6,
      img_url: require("assets/img/USDT.png"),
    },
    {
      name: "USDC",
      address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      decimals: 6,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "USDCn",
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      decimals: 6,
      img_url: require("assets/img/USDC.png"),
    },
    {
      name: "DAI",
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      decimals: 18,
      img_url: require("assets/img/DAI.png"),
    },
    {
      name: "WETH",
      address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
      decimals: 18,
      img_url: require("assets/img/WETH.svg").default,
    },
    {
      name: "ETH",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimals: 18,
      img_url: require("assets/img/ETH.png"),
    },
    {
      name: "WSTETH",
      address: "0x5979d7b546e38e414f7e9822514be443a4800529",
      decimals: 18,
      img_url: require("assets/img/WSTETH.svg").default,
    },
    {
      name: "ARB",
      address: "0x912ce59144191c1204e64559fe8253a0e49e6548",
      decimals: 18,
      img_url: require("assets/img/ARB.svg").default,
    },
  ],
};

export const radiantV2Contracts = {
  42161: {
    multiFeeDistribution: "0x76ba3eC5f5adBf1C58c91e86502232317EeA72dE",
    chefIncentivesController: "0xebC85d44cefb1293707b11f707bd3CEc34B4D5fA",
  },
  56: {
    multiFeeDistribution: "0x4FD9F7C5ca0829A656561486baDA018505dfcB5E",
    chefIncentivesController: "0x7C16aBb090d3FB266E9d17F60174B632f4229933",
  },
  1: {
    multiFeeDistribution: "0x28E395a54a64284DBA39652921Cd99924f4e3797",
    chefIncentivesController: "0x14b0A611230Dc48E9cc048d3Ae5279847Bf30919",
  },
};

export const chefIncentivesControllerABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalSupply",
        type: "uint256",
      },
    ],
    name: "BalanceUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "_balance",
        type: "uint256",
      },
    ],
    name: "ChefReserveEmpty",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "_balance",
        type: "uint256",
      },
    ],
    name: "ChefReserveLow",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
    ],
    name: "Disqualified",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256[]",
        name: "startTimeOffsets",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "rewardsPerSeconds",
        type: "uint256[]",
      },
    ],
    name: "EmissionScheduleAppended",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "rewardsPerSecond",
        type: "uint256",
      },
      { indexed: false, internalType: "bool", name: "persist", type: "bool" },
    ],
    name: "RewardsPerSecondUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [],
    name: "accountedRewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "uint256", name: "_allocPoint", type: "uint256" },
    ],
    name: "addPool",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "afterLockUpdate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "allPendingRewards",
    outputs: [{ internalType: "uint256", name: "pending", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_tokens", type: "address[]" },
      { internalType: "uint256[]", name: "_allocPoints", type: "uint256[]" },
    ],
    name: "batchUpdateAllocPoint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "beforeLockUpdate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "bountyManager",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "address[]", name: "_tokens", type: "address[]" },
    ],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "claimAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "bool", name: "_execute", type: "bool" },
    ],
    name: "claimBounty",
    outputs: [{ internalType: "bool", name: "issueBaseBounty", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "address[]", name: "_tokens", type: "address[]" },
    ],
    name: "claimToBase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "depositedRewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "eligibilityEnabled",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "eligibilityExempt",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "eligibleDataProvider",
    outputs: [
      {
        internalType: "contract IEligibilityDataProvider",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "emissionSchedule",
    outputs: [
      { internalType: "uint128", name: "startTimeOffset", type: "uint128" },
      { internalType: "uint128", name: "rewardsPerSecond", type: "uint128" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "emissionScheduleIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "endRewardTime",
    outputs: [{ internalType: "uint256", name: "timestamp", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "uint256", name: "_balance", type: "uint256" },
      { internalType: "uint256", name: "_totalSupply", type: "uint256" },
    ],
    name: "handleActionAfter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "handleActionBefore",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_poolConfigurator", type: "address" },
      {
        internalType: "contract IEligibilityDataProvider",
        name: "_eligibleDataProvider",
        type: "address",
      },
      {
        internalType: "contract IMiddleFeeDistribution",
        name: "_rewardMinter",
        type: "address",
      },
      { internalType: "uint256", name: "_rewardsPerSecond", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "lastAllPoolUpdate",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastRPS",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "address[]", name: "_tokens", type: "address[]" },
    ],
    name: "pendingRewards",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "persistRewardsPerSecond",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "poolConfigurator",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "poolInfo",
    outputs: [
      { internalType: "uint256", name: "totalSupply", type: "uint256" },
      { internalType: "uint256", name: "allocPoint", type: "uint256" },
      { internalType: "uint256", name: "lastRewardTime", type: "uint256" },
      { internalType: "uint256", name: "accRewardPerShare", type: "uint256" },
      {
        internalType: "contract IOnwardIncentivesController",
        name: "onwardIncentives",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "poolLength",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "uint256", name: "tokenAmount", type: "uint256" },
    ],
    name: "recoverERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "registerRewardDeposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "registeredTokens",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardMinter",
    outputs: [
      {
        internalType: "contract IMiddleFeeDistribution",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardsPerSecond",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address[]", name: "_users", type: "address[]" }],
    name: "saveUserRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_bountyManager", type: "address" },
    ],
    name: "setBountyManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_newVal", type: "bool" }],
    name: "setEligibilityEnabled",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_contract", type: "address" }],
    name: "setEligibilityExempt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_startTimeOffsets",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_rewardsPerSecond",
        type: "uint256[]",
      },
    ],
    name: "setEmissionSchedule",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      {
        internalType: "contract IOnwardIncentivesController",
        name: "_incentives",
        type: "address",
      },
    ],
    name: "setOnwardIncentives",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_rewardsPerSecond", type: "uint256" },
      { internalType: "bool", name: "_persist", type: "bool" },
    ],
    name: "setRewardsPerSecond",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "start",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "startTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAllocPoint",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "userBaseClaimable",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userInfo",
    outputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "uint256", name: "rewardDebt", type: "uint256" },
      { internalType: "uint256", name: "enterTime", type: "uint256" },
      { internalType: "uint256", name: "lastClaimTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const multiFeeDistributionABI = [
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: true,
        internalType: "address",
        name: "rewardToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
    ],
    name: "IneligibleRewardRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lockedBalance",
        type: "uint256",
      },
      { indexed: false, internalType: "bool", name: "isLP", type: "bool" },
    ],
    name: "Locked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Recovered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lockIndex",
        type: "uint256",
      },
    ],
    name: "Relocked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: true,
        internalType: "address",
        name: "rewardToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
    ],
    name: "RewardPaid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newDuration",
        type: "uint256",
      },
    ],
    name: "RewardsDurationUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "receivedAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lockedBalance",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "penalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "burn",
        type: "uint256",
      },
      { indexed: false, internalType: "bool", name: "isLP", type: "bool" },
    ],
    name: "Withdrawn",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_LOCK_INDEX",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HALF",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUART",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "WHOLE",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_rewardToken", type: "address" },
    ],
    name: "addReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_rewardConverter", type: "address" },
    ],
    name: "addRewardConverter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "autoRelockDisabled",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "autocompoundEnabled",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bountyManager",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burn",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "bool", name: "_execute", type: "bool" },
    ],
    name: "claimBounty",
    outputs: [{ internalType: "bool", name: "issueBaseBounty", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "onBehalf", type: "address" }],
    name: "claimFromConverter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "claimableRewards",
    outputs: [
      {
        components: [
          { internalType: "address", name: "token", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        internalType: "struct IFeeDistribution.RewardData[]",
        name: "rewardsData",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "daoTreasury",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "defaultLockDuration",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "defaultLockIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "earnedBalances",
    outputs: [
      { internalType: "uint256", name: "total", type: "uint256" },
      { internalType: "uint256", name: "unlocked", type: "uint256" },
      {
        components: [
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "penalty", type: "uint256" },
        ],
        internalType: "struct EarnedBalance[]",
        name: "earningsData",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "claimRewards", type: "bool" }],
    name: "exit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getLockDurations",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getLockMultipliers",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getPriceProvider",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_rewardTokens", type: "address[]" },
    ],
    name: "getReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_rewardToken", type: "address" },
    ],
    name: "getRewardForDuration",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "incentivesController",
    outputs: [
      {
        internalType: "contract IChefIncentivesController",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bool", name: "claimRewards", type: "bool" },
      { internalType: "uint256", name: "unlockTime", type: "uint256" },
    ],
    name: "individualEarlyExit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_rdntToken", type: "address" },
      { internalType: "address", name: "_lockZap", type: "address" },
      { internalType: "address", name: "_dao", type: "address" },
      { internalType: "address", name: "_userlist", type: "address" },
      { internalType: "address", name: "priceProvider", type: "address" },
      { internalType: "uint256", name: "_rewardsDuration", type: "uint256" },
      { internalType: "uint256", name: "_rewardsLookback", type: "uint256" },
      { internalType: "uint256", name: "_lockDuration", type: "uint256" },
      { internalType: "uint256", name: "_burnRatio", type: "uint256" },
      { internalType: "uint256", name: "_vestDuration", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "lastAutocompound",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "lastClaimTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_rewardToken", type: "address" },
    ],
    name: "lastTimeRewardApplicable",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "lockInfo",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "multiplier", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
        ],
        internalType: "struct LockedBalance[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "lockedBalances",
    outputs: [
      { internalType: "uint256", name: "total", type: "uint256" },
      { internalType: "uint256", name: "unlockable", type: "uint256" },
      { internalType: "uint256", name: "locked", type: "uint256" },
      {
        internalType: "uint256",
        name: "lockedWithMultiplier",
        type: "uint256",
      },
      {
        components: [
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "multiplier", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
        ],
        internalType: "struct LockedBalance[]",
        name: "lockData",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lockedSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lockedSupplyWithMultiplier",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "middleFeeDistribution",
    outputs: [
      {
        internalType: "contract IMiddleFeeDistribution",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "user", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "bool", name: "withPenalty", type: "bool" },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "minters",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "mintersAreSet",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "onUpgrade",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rdntToken",
    outputs: [
      { internalType: "contract IMintableToken", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "uint256", name: "tokenAmount", type: "uint256" },
    ],
    name: "recoverERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "relock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardConverter",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "rewardData",
    outputs: [
      { internalType: "uint256", name: "periodFinish", type: "uint256" },
      { internalType: "uint256", name: "rewardPerSecond", type: "uint256" },
      { internalType: "uint256", name: "lastUpdateTime", type: "uint256" },
      {
        internalType: "uint256",
        name: "rewardPerTokenStored",
        type: "uint256",
      },
      { internalType: "uint256", name: "balance", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_rewardToken", type: "address" },
    ],
    name: "rewardPerToken",
    outputs: [{ internalType: "uint256", name: "rptStored", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "rewardTokens",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "rewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardsDuration",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardsLookback",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IChefIncentivesController",
        name: "_controller",
        type: "address",
      },
      {
        internalType: "contract IMiddleFeeDistribution",
        name: "_middleFeeDistribution",
        type: "address",
      },
      { internalType: "address", name: "_treasury", type: "address" },
    ],
    name: "setAddresses",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_status", type: "bool" }],
    name: "setAutocompound",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_bounty", type: "address" }],
    name: "setBountyManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_index", type: "uint256" }],
    name: "setDefaultRelockTypeIndex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stakingToken", type: "address" },
    ],
    name: "setLPToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256[]", name: "_lockPeriod", type: "uint256[]" },
      {
        internalType: "uint256[]",
        name: "_rewardMultipliers",
        type: "uint256[]",
      },
    ],
    name: "setLockTypeInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_lookback", type: "uint256" }],
    name: "setLookback",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_minters", type: "address[]" },
    ],
    name: "setMinters",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_status", type: "bool" }],
    name: "setRelock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "address", name: "onBehalfOf", type: "address" },
      { internalType: "uint256", name: "typeIndex", type: "uint256" },
    ],
    name: "stake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stakingToken",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "startfleetTreasury",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "totalBalance",
    outputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userRewardPerTokenPaid",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "userlist",
    outputs: [
      { internalType: "contract ILockerList", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestDuration",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_address", type: "address" }],
    name: "withdrawExpiredLocksFor",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_address", type: "address" },
      { internalType: "uint256", name: "_limit", type: "uint256" },
      { internalType: "bool", name: "_ignoreRelock", type: "bool" },
    ],
    name: "withdrawExpiredLocksForWithOptions",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "withdrawableBalance",
    outputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "uint256", name: "penaltyAmount", type: "uint256" },
      { internalType: "uint256", name: "burnAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "zapVestingToLp",
    outputs: [{ internalType: "uint256", name: "zapped", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
];
