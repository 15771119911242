import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledDropDownContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sx, left }) => ({
  boxShadow: "0px 9.348px 18.696px 0px rgba(0, 0, 0, 0.07)",
  borderRadius: "4px",
  backgroundColor: "#FFFFFF",
  padding: "4px",
  position: "absolute",
  top: "calc(100% + 10px)",
  right: left ? "none" : "0px",
  left: left ? "0px" : "none",
  minWidth: "156px",
  zIndex: "10",
  ...sx,
}));

export const StyledDropDownSelect = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sx, active }) => ({
  border: active
    ? `1px solid ${theme.customColors.purple[500]}`
    : "1px solid #E6E6E6",
  borderRadius: "4px",
  color: active ? theme.customColors.purple[500] : "#777777",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "140px",
  height: "24px",
  padding: "7px 12px 8px 12px",
  cursor: "pointer",
  fontSize: "12px",
  ...sx,
  "&:hover": {
    color: theme.customColors.purple[500],
    border: `1px solid ${theme.customColors.purple[500]}`,
  },
}));

export const StyledDropDownBtn = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sx, active }) => ({
  border: active
    ? `1px solid ${theme.customColors.purple[500]}`
    : "1px solid #A5A5A5",
  borderRadius: "4px",
  color: active ? theme.customColors.purple[500] : "#A5A5A5",
  padding: "6px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "8px",
  cursor: "pointer",
  ...sx,
  "&:hover": {
    color: theme.customColors.purple[500],
    border: `1px solid ${theme.customColors.purple[500]}`,
  },
}));

export const StyledDropDownText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sx, active }) => ({
  height: "32px",
  padding: "4px 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  whiteSpace: "nowrap",
  padding: "0 8px",
  "&:hover": {
    backgroundColor: "#FCFAFF",
  },
  ...sx,
}));

export const StyledHRLine = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sx, active }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E5E5",
  marginTop: "10px",
  marginBottom: "10px",
}));
