import { combineReducers } from "redux";
import { LOG_OUT } from "features/home/redux/constants";
import commonReducer from "features/common/redux/reducer";
import homeReducer from "features/home/redux/reducer";
import metadeskReducder from "features/metadesk/redux/reducer";
import priceRecuer from "features/price/redux/reducer";
// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  common: commonReducer,
  home: homeReducer,
  metadesk: metadeskReducder,
  price: priceRecuer,
};

const appReducer = combineReducers(reducerMap);
const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
