import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  StyledTrackingPaper,
  StyledTrackingTitle,
  StyledTrackingContainer,
  StyledDisabledOverlay,
  StyledTableHead,
  StyledTableCell,
  StyledHeadCell,
  StyledAPIURL,
} from "../settings/integrations/tracking/TrackignSetting.styles";
import {
  StyledApiKeyCreatedTime,
  StyledFormTitle,
  StyledSettingWidgetStepContainerOutSide,
  StyledSettingWidgetStepDescribe,
} from "../settings/integrations/IntegrationWidgetSetting.styles";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  Button,
  IconButton,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  alertServerError,
  formatCalendarDateAndTime,
} from "features/helpers/utils";
import { enqueueSnackbar } from "features/common/redux/actions";
import { Grid, Box, Table, TableBody, TableRow } from "@mui/material";
import { useOutletContext } from "react-router";
import { useTheme, styled } from "@mui/styles";
import axios from "axios";
import _ from "lodash";
import { apiUrl } from "features/configure";
import Back from "../components/Back";
const ReferralAPI = () => {
  const { formatMessage } = useIntl();
  const apiKeyCreated = new Date();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [apiKey, setApiKey] = useState("");
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handleRegenerateAPI = async () => {
    try {
      const result = await axios.post(
        `${apiUrl}/api/referral/${entityName}/apiKey`
      );
      setApiKey(result.data.apiKey);
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${apiUrl}/api/referral/${entityName}/apiKey`
      );
      const data = result?.data.key;
      setApiKey(data);
      return data;
    } catch (err) {
      return null;
      // dispatch(alertServerError(err));
    } finally {
      setIsLoading(false);
    }
  };

  // 要是沒有apiKey 並且以前都沒有安裝過 進來直接產生一個apiKey
  useEffect(async () => {
    const data = await getAPIData();
    if (!data) {
      handleRegenerateAPI();
    }
  }, []);

  const handleRegenerate = () => {
    confirm({
      title: formatMessage({
        id: "popup.regenerateApiKey.title",
      }),
      content: formatMessage({
        id: "popup.regenerateApiKey.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(() => {
        handleRegenerateAPI();
      })
      .catch(() => {});
  };
  const renderTable = (rowData) => {
    return (
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledHeadCell style={{ width: "25%" }}>Name</StyledHeadCell>
            <StyledHeadCell style={{ width: "25%" }}>Type</StyledHeadCell>
            <StyledHeadCell style={{ width: "50%" }}>
              Description
            </StyledHeadCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {_.map(rowData, (row, i) => (
            <TableRow key={i}>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell>{row.type}</StyledTableCell>
              <StyledTableCell>{row.description}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderApi = (data) => {
    return (
      <StyledSettingWidgetStepContainerOutSide>
        <StyledTrackingContainer>
          <StyledTrackingTitle style={{ fontWeight: 700 }}>
            {data?.title}
          </StyledTrackingTitle>
          <StyledSettingWidgetStepDescribe>
            {data?.description}
          </StyledSettingWidgetStepDescribe>
          <StyledAPIURL>
            {data?.url}
            <IconButton
              onClick={() => copyToClipboard(data?.url)}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <i className="meta-crm-icon-ic_copy font-size-24" />
            </IconButton>
          </StyledAPIURL>
        </StyledTrackingContainer>
        <StyledTrackingTitle>
          {formatMessage({
            id: "global.request.parameters",
          })}
        </StyledTrackingTitle>
        {renderTable(data?.requests)}
        <StyledTrackingTitle style={{ marginTop: 20 }}>
          {formatMessage({
            id: "global.response.parameters",
          })}
        </StyledTrackingTitle>
        {renderTable(data?.responses)}
      </StyledSettingWidgetStepContainerOutSide>
    );
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };
  return (
    <Box maxWidth={"1177px"} margin="0 auto">
      <Back />
      <Box className="title">
        {formatMessage({ id: "global.function.referralApi.title" })}
      </Box>
      <Box className="description">
        {formatMessage({ id: "global.function.referralApi.description" })}
      </Box>
      <StyledTrackingPaper>
        <StyledSettingWidgetStepContainerOutSide>
          <Box fontSize={"16px"} fontWeight={500} mb={"8px"}>
            {formatMessage({ id: "global.function.referralApi.usageTitle" })}
          </Box>
          <Box
            sx={{
              color: "#777",
              lineHeight: "20px",
              maxWidth: "1000px",
            }}
          >
            <Box>
              <span style={{ fontWeight: 600 }}>
                {formatMessage({
                  id: "marketingSettings.referralProgramApiTab.user.title",
                })}{" "}
                :{" "}
              </span>
              Use the API to automatically generate referral links and track key
              metrics, such as link clicks, wallet connections, and transactions
              referred by users.
            </Box>
            <Box>
              <span style={{ fontWeight: 600 }}>
                {formatMessage({
                  id: "marketingSettings.referralProgramApiTab.getTopReferralData.title",
                })}{" "}
                :{" "}
              </span>
              Query the API to retrieve the top referrers and monitor their
              performance in your referral program.
            </Box>
          </Box>
        </StyledSettingWidgetStepContainerOutSide>
      </StyledTrackingPaper>
      <StyledTrackingPaper>
        <StyledSettingWidgetStepContainerOutSide>
          <StyledTrackingContainer>
            <StyledTrackingTitle>
              {formatMessage({
                id: "marketingSettings.referralProgramApiTab.getYourAccessToken.title",
              })}
            </StyledTrackingTitle>
            <StyledSettingWidgetStepDescribe>
              {formatMessage({
                id: "marketingSettings.referralProgramApiTab.getYourAccessToken.description",
              })}
            </StyledSettingWidgetStepDescribe>

            <Grid container spacing={1} mt="24px">
              <StyledFormTitle center item xs={12} md={4}>
                {formatMessage({
                  id: "widgetSettings.apiKey.input",
                })}
              </StyledFormTitle>
              <Grid position={"relative"} item xs={12} md={6}>
                <InputField
                  disabled
                  value={apiKey}
                  endAdornment={
                    <Button
                      disabled={isLoading}
                      size={SIZE.XL}
                      variant={VARIANT.TEXT}
                      style={{ width: "100%", padding: "2px 8px" }}
                      onClick={handleRegenerate}
                    >
                      {formatMessage({
                        id: "global.btn.regenerate",
                      })}
                    </Button>
                  }
                ></InputField>

                <StyledApiKeyCreatedTime>
                  <Box
                    color={(theme) => theme.customColors.grey[600]}
                    size="12px"
                    mr="2.5px"
                  >
                    {formatMessage({
                      id: "plans&billing.usage.recountTime",
                    })}{" "}
                    :
                  </Box>
                  <Box
                    color={(theme) => theme.customColors.purple[500]}
                    size="12px"
                  >
                    {formatCalendarDateAndTime(apiKeyCreated)}
                  </Box>
                </StyledApiKeyCreatedTime>
              </Grid>

              <Grid item xs={12} mb={"16px"}></Grid>
            </Grid>
          </StyledTrackingContainer>
        </StyledSettingWidgetStepContainerOutSide>
      </StyledTrackingPaper>
      <StyledTrackingPaper>
        {renderApi({
          title: formatMessage({
            id: "marketingSettings.referralProgramApiTab.user.title",
          }),
          description: formatMessage({
            id: "marketingSettings.referralProgramApiTab.user.description",
          }),
          url: `https://client.metacrm.inc/user?apiKey=${apiKey}&address=`,
          requests: [
            {
              name: "apiKey",
              type: "String",
              description: "For authorization",
            },
            { name: "address", type: "String", description: "Referer address" },
          ],
          responses: [
            { name: "url", type: "String", description: "User referral link" },
            {
              name: "linkClick",
              type: "Number",
              description: "Link click count",
            },
            {
              name: "walletConnected",
              type: "Number",
              description: "Wallet connected count",
            },
            {
              name: "transactionReferred",
              type: "Number",
              description: "Referred transaction count",
            },
          ],
        })}
        {renderApi({
          title: formatMessage({
            id: "marketingSettings.referralProgramApiTab.getTopReferralData.title",
          }),
          description: formatMessage({
            id: "marketingSettings.referralProgramApiTab.getTopReferralData.description",
          }),
          url: `https://client.metacrm.inc/data?apiKey=${apiKey}`,
          requests: [
            {
              name: "apiKey",
              type: "String",
              description: "For authorization",
            },
            {
              name: "top",
              type: "Number",
              description: "default = 5, max = 100",
            },
          ],
          responses: [
            {
              name: "data",
              type: "Array",
              description: "Top referral leader board",
            },
          ],
        })}
      </StyledTrackingPaper>{" "}
    </Box>
  );
};

export default ReferralAPI;
