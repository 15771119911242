import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, buttonColor, readonly }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  cursor: readonly ? "default" : "pointer",
}));

export const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, buttonColor, readonly }) => ({
  marginRight: "8px",
  // border: `1px solid ${theme.customColors.grey[400]} `,
  borderLeft: `1px solid ${theme.customColors.grey[400]} !important`,
  borderRadius: "8px !important",
  padding: "12px 14px",
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
  color: theme.customColors.grey[700],
  marginBottom: "8px",
  cursor: readonly ? "default" : "pointer",
  "&:hover": {
    backgroundColor: theme.customColors.white,
  },
  "&.Mui-selected": {
    backgroundColor: theme.customColors.white,
    border: `1px solid ${
      buttonColor || theme.customColors.purple[600]
    } !important`,
  },
}));

export const StyledTicketTypeRow = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, selected }) => ({
  cursor: "pointer",
  position: "relative",
  backgroundColor: "transparent",
  border: `1px solid ${
    selected ? theme.customColors.purple[500] : theme.customColors.grey[300]
  }`,
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: "8px",
  color: theme.customColors.grey[700],
  height: "52px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
}));
