import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import TrialMarketingSetStep1 from "features/metadesk/Onboarding/MarketingOnboarding/TrialMarketingSetStep/TrialMarketingSetStep1/TrialMarketingSetStep1";
import TrialMarketingSetStep2 from "features/metadesk/Onboarding/MarketingOnboarding/TrialMarketingSetStep/TrialMarketingSetStep2/TrialMarketingSetStep2";
import StepProvider from "contexts/stepContext/Step.context";
import TrialHeader from "../../../home/pages/Trial/TrialHeader/TrialHeader";
import { StyledLinearProgress } from "./TrialMarketingSetStepContainer.styles";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmFalse } from "features/metadesk/redux/trial/trial.action";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import Box from "@mui/material/Box";
import { StyledTrialHeaderFixedContainer } from "features/home/pages/Trial/TrialHeader/TrialHeader.styles";

import IntegrationSuccess from "features/metadesk/Onboarding/ServiceOnboarding/AttributionLinkSetup/IntegrationSuccess/IntegrationSuccess";

const MarketingOnboardingSetStepContainer = () => {
  // 最後一頁成功頁面不算進去
  const totalStep = 2;
  const [entityName] = useOutletContext();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const nextFormStep = () => setStep((currentStep) => currentStep + 1);
  const prevFormStep = () => setStep((currentStep) => currentStep - 1);
  const { fetchEntityConfig } = useFetchEntityConfig();

  useEffect(() => {
    if (entityName) {
      fetchEntityConfig({ entityName });
    }
  }, [entityName]);

  return (
    <>
      <StyledTrialHeaderFixedContainer>
        <TrialHeader />
        {step < 2 && (
          <StyledLinearProgress
            variant="determinate"
            value={(100 / totalStep) * (step + 1)}
            color="secondary"
          />
        )}
      </StyledTrialHeaderFixedContainer>

      {/* 佔位符 撐開header60px的高度   */}
      <Box height={"60px"}></Box>

      <StepProvider>
        {step === 0 && (
          <TrialMarketingSetStep1
            entityName={entityName}
            moduleName={"marketing"}
            step={step}
            onNextStep={nextFormStep}
          />
        )}
        {step === 1 && (
          <TrialMarketingSetStep2
            entityName={entityName}
            moduleName={"marketing"}
            step={step}
            onNextStep={nextFormStep}
            onPrevStep={prevFormStep}
          />
        )}
        {step === 2 && (
          <IntegrationSuccess
            onSuccess={() => {
              dispatch(setConfirmFalse());
            }}
          />
        )}
      </StepProvider>
    </>
  );
};

export default MarketingOnboardingSetStepContainer;
