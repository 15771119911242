import React, { useMemo } from "react";
import {
  StyledContainerTitle,
  StyledInputContainer,
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
  StyledWhiteCardContainer,
} from "../createCampaign/component/CampaignCreation.styles";
import { AccordionDetails, Box } from "@mui/material";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import EditNotificationPreviewContainer from "./EditNotificationPreviewContainer";
import { countCustomTrackingLink } from "../createCampaign/utils";
import { useIntl } from "react-intl";
import DateTimePicker from "features/metadesk/campaign/createCampaign/component/DateTimePicker";
import moment from "moment";
import useGetPagePermission from "hooks/useGetPagePermission";
import Schedule from "features/metadesk/campaign/createCampaign/schedule";

export default function CustomEdit({
  campaignContentItem,
  tabIndex,
  startFrom,
  endAt,
  onChangeStartFrom,
  onChangeEndAt,
  status,
  name,
}) {
  const { customLinkSettings } = campaignContentItem;
  const { formatMessage } = useIntl();
  const customLinksAmount = useMemo(() => {
    return countCustomTrackingLink(customLinkSettings);
  }, [customLinkSettings]);

  const pagePermission = useGetPagePermission();
  const isReadOnly =
    status !== "scheduled" || pagePermission("campaigns").readonly;

  return (
    <div>
      <Box display={"flex"}>
        <Box sx={{ flex: 1 }}>
          <Box mb="20px" />
          <StyledWhiteCardContainer>
            <Box>
              <Box
                p="20px"
                pb="8px"
                sx={{ color: "#555", fontSize: "14px", fontWeight: 700 }}
              >
                Promotion Type
              </Box>
              <div className="mhr" style={{ margin: "12px 0" }} />
              <Box p="20px" pt="8px" sx={{ color: "#555", fontSize: "12px" }}>
                <Box mb="13px">Type</Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={require("assets/img/ic_promotype_tracker.svg").default}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "4px",
                      borderRadius: "50%",
                    }}
                  />
                  Tracker
                </Box>
              </Box>
            </Box>
          </StyledWhiteCardContainer>

          <StyledWhiteAccordion defaultExpanded disableGutters={true}>
            <StyledWhiteAccordionSummary
              expandIcon={
                <i className="meta-crm-icon-ic_arrowdownDesk font-size-16" />
              }
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ padding: 0, paddingRight: "20px", margin: 0 }}
            >
              <StyledContainerTitle>
                {formatMessage({ id: "campaign.type.content" })}
              </StyledContainerTitle>
            </StyledWhiteAccordionSummary>

            <AccordionDetails style={{ padding: 0 }}>
              <div className="mhr" style={{ margin: 0 }}></div>
              <StyledInputContainer>
                <Box mb="16px">
                  <Box
                    style={{
                      color: "#777777",
                      fontSize: "12px",
                      marginBottom: "8px",
                    }}
                  >
                    {formatMessage({ id: "campaign.attributes.subtotal" })}
                  </Box>
                  <Box style={{ color: "#000" }}>
                    <span style={{ color: "#7B61FF" }}>
                      {customLinksAmount}
                    </span>{" "}
                    tracking links
                  </Box>
                </Box>
                <Grid container style={{ margin: "16px 0" }}>
                  <Grid item sx={12}>
                    <Box
                      style={{
                        color: "#777777",
                        fontSize: "12px",
                        marginBottom: "8px",
                      }}
                    >
                      {formatMessage({ id: "marketingSettings.domain.title" })}
                    </Box>
                    <Box style={{ color: "#000" }}>
                      {campaignContentItem.customWebsiteUrl}
                    </Box>
                  </Grid>
                </Grid>
              </StyledInputContainer>
            </AccordionDetails>
          </StyledWhiteAccordion>

          <StyledWhiteAccordion defaultExpanded disableGutters={true}>
            <StyledWhiteAccordionSummary
              expandIcon={
                <i className="meta-crm-icon-ic_arrowdownDesk font-size-16" />
              }
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ padding: 0, paddingRight: "20px", margin: 0 }}
            >
              <StyledContainerTitle>
                {formatMessage({ id: "global.taskStatus.scheduled" })}
              </StyledContainerTitle>
            </StyledWhiteAccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <div className="mhr" style={{ margin: 0 }}></div>
              <Schedule
                noContainer
                startFrom={startFrom}
                endAt={endAt}
                onChangeStartFrom={onChangeStartFrom}
                onChangeEndAt={onChangeEndAt}
                isReadOnly={isReadOnly}
              ></Schedule>
            </AccordionDetails>
          </StyledWhiteAccordion>
        </Box>
        <Box width="380px">
          <Box mb="20px" />
          <EditNotificationPreviewContainer
            data={campaignContentItem}
            name={name}
          />
        </Box>
      </Box>
    </div>
  );
}
