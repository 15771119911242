import { useContext, useEffect, useState } from "react";
import { PerformanceContext } from "../context/PerformanceProvider";
import { useOutletContext, useParams } from "react-router";
import axios from "axios";
import { apiUrl } from "features/configure";
import moment from "moment";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

export default function useGetPerformanceChart() {
  const [entityName] = useOutletContext();
  const { search, startDate, endDate, setIsLoading, promotype, actionValue } =
    useContext(PerformanceContext);
  const [data, setData] = useState({
    timeRangeStats: [],
    totalStats: [],
  });
  const dispatchSnackbar = useDispatchSnackbar();

  async function getPerformanceData() {
    try {
      setIsLoading((prev) => ({ ...prev, chart: true }));

      const { data } = await axios.get(
        `${apiUrl}/api/campaignPerformance/${entityName}/chart?search=${search}` +
          // `dimension=${dimension}&` +
          `${startDate && "&from=" + moment(startDate).valueOf()}` +
          `${endDate && "&to=" + moment(endDate).valueOf()}` +
          `${promotype && "&promotype=" + promotype}` +
          `${actionValue && "&action=" + actionValue.value}`
      );
      setData(data.chart);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading((prev) => ({ ...prev, chart: false }));
    }
  }

  useEffect(() => {
    getPerformanceData();
  }, [search, startDate, endDate, promotype, actionValue]);

  return data;
}
