import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton, Button } from "@metacrm/metacrm-material-ui/dist/Button";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

export const StyledMacroListItem = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: theme.customColors.grey[700],
    padding: "12px 24px",
    "&:hover": {
      background: theme.customColors.purple[300],
    },
  };
});

export const StyledMacroListIcon = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    whiteSpace: "nowrap",
  };
});

export const StyledMacroListText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    marginLeft: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
});

export const StyleMacroListEndAdornment = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    marginLeft: "auto",
    color: theme.customColors.purple[500],
  };
});

export const StyleMacroTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ tooltip: "tooltip-content", popper: className }}
    />
  ),
  {
    shouldForwardProp: isStyledPropsValid,
  }
)(({ theme }) => {
  return {
    '&[data-popper-placement*="right"]': {
      "& .MuiTooltip-tooltip.tooltip-content": {
        marginLeft: "0px",
      },
    },
    "& .MuiTooltip-tooltip": {
      background: theme.customColors.purple[300],
      padding: "24px",
      borderRadius: 0,
    },
  };
});

export const StyledToolTipText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[700],
    marginBottom: "24px",
    "& a": {
      color: theme.customColors.purple[500],
    },
  };
});

export const StyledToolTipImage = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "block",
    width: "80px",
    marginBottom: "24px",
  };
});
