import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const PlanBox = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ sx, active }) => ({
  height: "100%",
  border: active ? "2px solid #7B61FF" : "2px solid #E6E6E6",
  borderRadius: "3px",
  backgroundColor: "white",
  padding: "16px",
  "&:hover": {
    backgroundColor: "#F7F1FE",
  },
  ...sx,
}));
