import { createSelector } from "reselect";
import {
  selectWidgetVersionCanUpdate,
  selectAttributionVersionCanUpdate,
} from "../entityConfig/entityConfig.selector";
import { get } from "lodash";

const selectGlobalStateReducer = (state) => state.metadesk.globalState;

// 取得使用者資料以判斷是否有integration權限
const selectUserData = (state) => {
  return state.home.userData;
};

export const selectRemindBarShow = createSelector(
  [
    selectGlobalStateReducer,
    selectUserData,
    selectWidgetVersionCanUpdate,
    selectAttributionVersionCanUpdate,
  ],
  (
    globalState,
    UserDataState,
    widgetVersionCanUpdate,
    attributionVersionCanUpdate
  ) => {
    const { canUpdate: canWidgetUpdate } = widgetVersionCanUpdate;
    const { canUpdate: canAttributionUpdate } = attributionVersionCanUpdate;
    // widget有新版本 或是 attribution有新版 並且 有localStorage有開啟提醒

    // 判斷是否有integration權限決定是否顯示提醒
    const permissions = get(UserDataState, "team.role.permissions", []);
    const integrationPermission = permissions.find(
      (permission) => permission.name === "integration"
    )?.value;
    const permissionAvailable = integrationPermission !== "none";

    return (
      (canWidgetUpdate || canAttributionUpdate) &&
      permissionAvailable &&
      globalState.remindBarShow
    );
  }
);

export const selectBillingBarShow = createSelector(
  [selectGlobalStateReducer, selectUserData],
  (globalState, UserDataState) => {
    // 判斷是否有integration權限決定是否顯示提醒
    const permissions = get(UserDataState, "team.role.permissions", []);
    const integrationPermission = permissions.find(
      (permission) => permission.name === "integration"
    )?.value;
    const permissionAvailable = integrationPermission !== "none";

    return permissionAvailable && globalState.billingBarShow;
  }
);

export const selectIsAdvancedSorting = createSelector(
	[selectGlobalStateReducer],
	(globalState) => globalState.isAdvancedSorting,
);