import { useEffect, useState } from "react";
import { MessageFormatter } from "./messageFormatter.js";
import { getSDKVersion } from "./utils";

const Methods = {
  sendTransactions: "sendTransactions",
  rpcCall: "rpcCall",
  getChainInfo: "getChainInfo",
  getSafeInfo: "getSafeInfo",
  getTxBySafeTxHash: "getTxBySafeTxHash",
  getSafeBalances: "getSafeBalances",
  signMessage: "signMessage",
  signTypedMessage: "signTypedMessage",
  getEnvironmentInfo: "getEnvironmentInfo",
  requestAddressBook: "requestAddressBook",
  wallet_getPermissions: "wallet_getPermissions",
  wallet_requestPermissions: "wallet_requestPermissions",
};

const LegacyMethods = {
  getEnvInfo: "getEnvInfo",
};

class AppCommunicator {
  constructor(iframeRef) {
    this.iframeRef = iframeRef;
    this.handlers = new Map();

    window.addEventListener("message", this.handleIncomingMessage);
  }

  on = (method, handler) => {
    this.handlers.set(method, handler);
  };

  isValidMessage = (msg) => {
    if (msg.data.hasOwnProperty("isCookieEnabled")) {
      return true;
    }

    const sentFromIframe = this.iframeRef.current?.contentWindow === msg.source;
    const knownMethod = Object.values(Methods).includes(msg.data.method);

    return sentFromIframe && knownMethod;
  };

  canHandleMessage = (msg) => {
    return Boolean(this.handlers.get(msg.data.method));
  };

  send = (data, requestId, error = false) => {
    const sdkVersion = getSDKVersion();
    const msg = error
      ? MessageFormatter.makeErrorResponse(requestId, data, sdkVersion)
      : MessageFormatter.makeResponse(requestId, data, sdkVersion);

    this.iframeRef.current?.contentWindow?.postMessage(msg, "*");
  };

  handleIncomingMessage = async (msg) => {
    const validMessage = this.isValidMessage(msg);
    const hasHandler = this.canHandleMessage(msg);

    if (validMessage && hasHandler) {
      const handler = this.handlers.get(msg.data.method);
      try {
        const response = await handler(msg);

        if (typeof response !== "undefined") {
          this.send(response, msg.data.id);
        }
      } catch (err) {
        this.send(err.message, msg.data.id, true);
      }
    }
  };

  clear = () => {
    window.removeEventListener("message", this.handleIncomingMessage);
  };
}

const useAppCommunicator = (iframeRef) => {
  const [communicator, setCommunicator] = useState(undefined);

  useEffect(() => {
    let communicatorInstance;
    const initCommunicator = (iframeRef) => {
      communicatorInstance = new AppCommunicator(iframeRef);
      setCommunicator(communicatorInstance);
    };

    initCommunicator(iframeRef);

    return () => {
      communicatorInstance?.clear();
    };
  }, [iframeRef]);

  return communicator;
};

export { useAppCommunicator };
