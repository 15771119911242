import React from "react";
import { InterceptorContextProvider } from "./InterceptorContext";
import App from "./App";
import { UpgradePlan } from "features/metadesk";

export default function AppContainer() {
  return (
    <InterceptorContextProvider>
      <App />
      <UpgradePlan />
    </InterceptorContextProvider>
  );
}
