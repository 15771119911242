import React, { useCallback, useEffect, useState } from "react";
import _, { isArray } from "lodash";
import { useFetchEntityConfig } from "./redux/hooks";
import { useConnectWallet } from "features/home/redux/hooks";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Loading } from "features/common";
import {
  containsAllProperties,
  getMenuList,
  parseCustomDomainUrl,
  redirectRoot,
} from "features/helpers/utils";
import useGetPagePermission from "hooks/useGetPagePermission";
import { selectEcosystemName } from "./redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import { useOpenState } from "contexts/stepContext/Open.context";

function PermissionControl({
  children,
  feature,
  upgrade,
  defaultPage,
  needEditPermission,
  allowedEntities,
  ecosystems,
  disabled,
  allowed,
  configs,
}) {
  const [entityName, isCustomDomain] = useOutletContext();
  const { openState, setOpen } = useOpenState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userData } = useConnectWallet();
  const { entityConfig } = useFetchEntityConfig();
  const pagePermission = useGetPagePermission();
  const ecosystemName = useSelector(selectEcosystemName);

  const permissionAllowed = useCallback(() => {
    if (typeof feature === "string") {
      if (needEditPermission) return pagePermission(feature).canEdit;
      return pagePermission(feature).show;
    }
    if (isArray(feature)) {
      return feature.some((f) => pagePermission(f).show);
    }
  }, [feature, needEditPermission, pagePermission]);

  function needUpgrade() {
    return false;
  }
  function notAllowedIn() {
    if (upgrade && !containsAllProperties(entityConfig, upgrade)) return true;
    if (allowed && !containsAllProperties(entityConfig, allowed)) return true;
    if (
      allowedEntities &&
      !_.includes(_.map(allowedEntities, _.toLower), _.toLower(entityName))
    )
      return true;
    if (disabled) {
      if (typeof disabled === Boolean) {
        return true;
      }
      if (typeof disabled === Object) {
        if (!containsAllProperties(entityConfig, disabled)) return true;
      }
    }
    if (ecosystems && !_.includes(ecosystems, ecosystemName)) return true;
    if (feature && !permissionAllowed()) return true;
    if (defaultPage) return true;

    return false;
  }

  const isNotAllowedIn = notAllowedIn();

  useEffect(() => {
    if (!userData || entityConfig.init || !entityConfig)
      return setIsLoading(true);
    const menuList = getMenuList(
      entityConfig,
      pagePermission,
      ecosystemName,
      entityName
    );
    if (needUpgrade()) {
      setOpen({ upgrade: true });
      return navigate(
        parseCustomDomainUrl(isCustomDomain, entityName, redirectRoot(menuList))
      );
    }
    if (isNotAllowedIn) {
      return navigate(
        parseCustomDomainUrl(isCustomDomain, entityName, redirectRoot(menuList))
      );
    }
    setIsLoading(false);
  }, [userData, entityConfig, isCustomDomain, entityName]);

  if (isLoading || !userData || entityConfig.init || !entityConfig) {
    return <Loading open={true} />;
  }
  if (isNotAllowedIn) return <Loading open={true} />;

  return <div>{children}</div>;
}

export default PermissionControl;
