import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";

export const StyledRoot = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)",
    fontSize: "14px",
    position: "relative",
  };
});

export const StyledInputWrapper = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    minHeight: "40px",
    paddingLeft: "12px",
    paddingRight: "8px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: `1px solid ${theme.customColors.grey[300]}`,
    backgroundColor: theme.customColors.white,
    borderRadius: "4px",
    flexWrap: "wrap",

    "&:hover": {
      borderColor: theme.customColors.purple[500],
    },

    "&.focused": {
      borderColor: theme.customColors.purple[500],
      boxShadow: `${alpha(theme.palette.secondary.main, 0.5)} 0px 0px 0px 2px`,
    },

    "& input": {
      backgroundColor: theme.customColors.white,
      color: theme.customColors.grey[700],
      height: "30px",
      boxSizing: "border-box",
      padding: "4px 6px",
      paddingLeft: 0,
      width: "0",
      minWidth: "30px",
      flexGrow: 1,
      border: "0",
      margin: "0",
      outline: "0",
    },
  };
});

export const StyledOptionLabel = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, backgroundColor }) => ({
  ...(backgroundColor && {
    backgroundColor: backgroundColor,
  }),
  padding: "2px 5px",
  borderRadius: "3px",
  display: "flex",
  alignItems: "center",
}));

export const StyledSelectItemMoreBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledListbox = styled("ul", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, selectAreaHeight }) => {
  return {
    width: "100%",
    margin: "2px 0 0",
    padding: "0",
    listStyle: "none",
    backgroundColor: theme.customColors.white,
    overflow: "auto",
    maxHeight: `${selectAreaHeight}px`,
    borderRadius: "4px",
    boxShadow: `${alpha(theme.customColors.grey[300], 0.5)} 0px 0px 0px 2px`,
    zIndex: 1,
    "& li": {
      padding: "5px 12px",
      display: "flex",
      justifyContent: "space-between",

      "& svg": {
        color: theme.customColors.grey[700],
      },
    },
    "& li[aria-selected='true']": {
      backgroundColor: theme.customColors.purple[300],
      fontWeight: 600,
    },
    // Replace `autocompleteClasses.focused` with the actual class name or value if needed.
    [`& li.${autocompleteClasses.focused}`]: {
      backgroundColor: theme.customColors.purple[300],
      cursor: "pointer",
      "& svg": {
        color: "currentColor",
      },
    },
  };
});

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => {
  return {
    transform: !open ? "rotate(0deg)" : "rotate(180deg)",
  };
});

export const StyledExplain = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "10px",
  color: theme.customColors.grey[500],
  fontWeight: 700,
}));

export const StyledAddNewOptionInputContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "0 10px 10px",
}));

export const StyleRemainNumber = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginLeft: "10px",
  fontWeight: 700,
  color: theme.customColors.grey[800],
  background: theme.customColors.purple[300],
  padding: "2px 5px",
  borderRadius: "3px",
}));

export const StyledInput = styled("input", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingLeft: 0,
  "&::placeholder": {
    color: theme.customColors.grey[700],
    fontWeight: 500,
    fontSize: "14px",
  },
}));

export const StyledPopper = styled(Popper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  zIndex: "2000",
}));

export const StyledPaper = styled(Paper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
