import React from "react";
import { StyledWhiteContainer } from "../common.styles";
import { Box } from "@mui/material";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import useNavigateEntity from "hooks/useNavigateEntity";
import RolesAction from "./RolesAction";
import useGetPagePermission from "hooks/useGetPagePermission";
import useGetRolesHeader from "./hooks/useGetRolesHeader";
import { useIntl } from "react-intl";
import useGetRoleList from "./hooks/useGetRoleList";
import { upperFirst } from "lodash";
import { Loading } from "features/common";
import { getUserAvatarIcon } from "features/metadesk/utils";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";

export default function OrganizationRoles() {
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const { header } = useGetRolesHeader();
  const pagePermission = useGetPagePermission();
  const { permission } = pagePermission("organization");
  const { roleList, fetchRoles, loading } = useGetRoleList();

  return (
    <StyledWhiteContainer
      style={{ marginTop: "20px", padding: 0, height: "100%" }}
    >
      <Loading open={loading} fullScreen={false} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px 20px",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Box sx={{ color: "#5c5c5c" }}>
          {formatMessage({ id: "organizationSetting.roleSetting.description" })}
        </Box>
        {permission === "edit" && (
          <Button
            onClick={() =>
              navigateEntity("/settings/general/organization/create")
            }
          >
            {formatMessage({ id: "global.btn.create" })}
          </Button>
        )}
      </Box>

      <Box sx={{ padding: "40px 0 5px" }} width="100%" overflow={"auto"}>
        <Box display="flex">
          <Box
            color="#777"
            width="316px"
            flexShrink={0}
            position="sticky"
            pl={"20px"}
            left={"0px"}
            sx={{
              background: "white",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #E6E6E6",
                py: "10px",
                pl: "16px",
              }}
            >
              Type
            </Box>
            <Box sx={{ py: "10px", pl: "16px" }}>Role name</Box>
            <Box
              sx={{
                position: "absolute",
                top: "0",
                right: "-42px",
                height: "100%",
                borderLeft: "1px solid #F8F8F8",
                background:
                  "linear-gradient(85deg, rgba(229, 229, 229, 0.36) 3.19%, rgba(255, 255, 255, 0.00) 108.81%)",
                minWidth: "42px",
              }}
            />
          </Box>
          {/* 上方權限欄位表頭 */}
          {header.map((role, index) => (
            <Box key={role.title} color="#777">
              <Box
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderRight:
                    index !== header.length - 1 && "1px solid #E6E6E6",
                  py: "10px",
                  px: "25px",
                }}
              >
                <Box style={{ width: "75px", textAlign: "center" }}>
                  {role.title}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRight:
                    index !== header.length - 1 && "1px solid #E6E6E6",
                }}
              >
                {role.children.map((child) => (
                  <Box
                    sx={{
                      py: "10px",
                      width: "75px",
                      mx: "25px",
                      textAlign: "center",
                    }}
                    key={child.title}
                  >
                    {child.title}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            marginBottom: "12px",
          }}
        >
          {roleList.map((role, index) => (
            <Box display="flex" mb={2} key={role.name}>
              <Box
                sx={{
                  width: "316px",
                  flexShrink: 0,
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  pl: "20px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #E6E6E6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "12px",
                    borderRight: 0,
                    borderRadius: "4px 0 0 4px",
                  }}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <img
                      src={require(`assets/img/${getUserAvatarIcon(role._id)}`)}
                      alt="roleImage"
                      className="icon"
                    />
                    <Box>
                      <IconTooltip
                        icon={
                          <Box
                            ml={1}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "160px",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            {role.name}
                          </Box>
                        }
                        content={role.name}
                      />
                    </Box>
                    <Box color={"#A5A5A5"} ml="4px">
                      ({role.count})
                    </Box>
                  </Box>
                  <Box>
                    {permission === "edit" && role.name !== "Admin" && (
                      <RolesAction
                        id={role._id}
                        fetchRoles={fetchRoles}
                        roleAmount={role.count}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      right: "-42px",
                      bottom: "0",
                      height: index === 0 ? "100%" : "72px",
                      borderLeft: "1px solid #F8F8F8",
                      background:
                        "linear-gradient(85deg, rgba(229, 229, 229, 0.36) 3.19%, rgba(255, 255, 255, 0.00) 108.81%)",
                      minWidth: "42px",
                    }}
                  />
                </Box>
              </Box>

              {role.permissions.map((permission, index) => (
                <Box
                  key={`${permission.name}${index}`}
                  sx={{
                    minWidth: "125px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTop: "1px solid #E6E6E6",
                    borderBottom: "1px solid #E6E6E6",
                    color: permission.value === "none" ? "#A5A5A5" : "#555",
                  }}
                >
                  {upperFirst(permission.value)}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        style={{
          padding: "20px",
          color: "#777",
          fontWeight: 400,
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <span className="circle" />
          {formatMessage({ id: "roleSetting.rule.exception" })}
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <span className="circle" />
          {formatMessage({ id: "roleSetting.rule.exception.line2" })}
        </Box>
      </Box>
    </StyledWhiteContainer>
  );
}
