import React from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

const Back = ({ text = "Back", to, action, noMt }) => {
  const navigate = useNavigate();
  return (
    <div
      className="startRow"
      style={{
        color: "#A5A5A5",
        cursor: "pointer",
        fontWeight: 500,
        fontSize: 14,
        marginRight: 4,
        margin: "12px 0",
        marginTop: noMt ? 0 : "-30px",
      }}
      onClick={() => {
        {
          if (action) action();
          navigate(to || -1);
        }
      }}
    >
      {text}
      <i
        className="meta-crm-icon-ic_redo font-size-14"
        style={{ marginLeft: 4 }}
      />
    </div>
  );
};

export default Back;
