import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const UrlInputWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  columnGap: 8,
  alignItems: "flex-end",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    rowGap: 16,
    columnGap: 0,
    justifyContent: "flex-end",
  },
}));

export const TrainingMsg = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  fontSize: theme.fontSize.TINY,
  height: 40,
  width: 176,
  display: "flex",
  alignItems: "center",
  columnGap: 8,
  border: `1px solid ${theme.customColors.grey[300]}`,
  padding: "5px 8px 5px 8px",
  borderRadius: 3,

  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "center",
  },
}));

export const LastUpdatedTimeStamp = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[500],
  fontSize: theme.fontSize.TINY,
  height: 40,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",

  [theme.breakpoints.down("md")]: {
    // alignItems: "flex-end",
  },
}));
