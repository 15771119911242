import axios from "axios";
import { Loading } from "features/common";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import Back from "../components/Back";
import { Box } from "@mui/material";
import XPerformanceTable from "./component/XPerformanceTable";
import XFeed from "./component/XFeed";
import XPerformanceChart from "./component/XPerformanceChart";
import useNavigateEntity from "hooks/useNavigateEntity";
import { useIntl } from "react-intl";

export default function XPerformance() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [entityName] = useOutletContext();
  const { campaignId } = useParams();
  const { navigateEntity } = useNavigateEntity();
  const dispatchSnackbar = useDispatchSnackbar();
  const { formatMessage } = useIntl();
  const getXPerformanceData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios(
        `${apiUrl}/api/x/${entityName}/campaign/${campaignId}/detail`
      );
      setData(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getXPerformanceData();
  }, []);

  const { name = "", description = "", authors = [], posts = [] } = data;

  const postLinks = useMemo(() => {
    const all = {
      label: "All",
      value: "",
    };

    if (!data?.posts) return [all];
    const getShortLink = (link) => {
      const index = link.lastIndexOf("/");
      return link.slice(0, index + 1) + "..." + link.slice(-5);
    };

    return [
      all,
      ...data.posts.map((post) => ({
        label: getShortLink(post.postLink),
        value: post.postLink,
      })),
    ];
  }, [data.posts]);

  return (
    <div>
      {/* <Loading open={isLoading} fullScreen={false} /> */}
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          maxWidth: "calc(100% - 287px)",
          "@media (max-width: 768px)": {
            maxWidth: "100%",
          },
        }}
      >
        <Box mr="12px" width="100%">
          <Back noMt />
          <Box className="item-flex">
            <div className="title">{name}</div>
            <Box
              onClick={() =>
                navigateEntity(`/marketing/xCampaign/add/${campaignId}`)
              }
              className="item-flex"
              sx={{
                cursor: "pointer",
                color: "#A5A5A5",
                gap: "4px",
                fontSize: "12px",
                ml: "12px",
              }}
            >
              <i className="meta-crm-icon-ic_edit-2 font-size-14" />
              {formatMessage({ id: "xCampaignTracking.btn.edit" })}
            </Box>
          </Box>
          <div className="description">{description}</div>
          <Box display="flex" alignItems="center" m="8px 0 24px">
            {authors.slice(0, 5)?.map((link, index) => (
              <Box
                component="img"
                src={link}
                key={index}
                width={24}
                height={24}
                borderRadius="50%"
                sx={{
                  ml: index !== 0 ? "-4px" : 0,
                  objectFit: "cover",
                  border: "2px solid #FFF",
                }}
              />
            ))}
            <Box ml="4px">
              {authors?.length > 0 ? `${authors.length} Links` : "-"}
            </Box>
          </Box>
          <XPerformanceChart postLinks={postLinks} />
          <XPerformanceTable posts={posts} isLoading={isLoading} />
        </Box>
        <Box
          sx={{
            "@media (max-width: 768px)": {
              display: "none",
            },
          }}
        >
          <XFeed />
        </Box>
      </Box>
    </div>
  );
}
