import React from "react";
import _ from "lodash";
import ImageUploading from "react-images-uploading";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import { formatSizeUnits } from "features/helpers/format";

const ImageUpload = ({
  multiple = true,
  value,
  onChange,
  max = 3,
  uploadComponent,
  editable = true,
  acceptType = ["png", "jpg", "pdf", "heic", "jpeg", "svg"],
  size = 30,
  color,
  maxFileSize = 10485760,
  hoverColor,
  replace = false,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const iconColor = color ? color : theme.customColors.grey[700];
  const iconHoverColor = hoverColor
    ? hoverColor
    : theme.customColors.purple[500];

  const errorStyle = {
    display: "flex",
    columnGap: 4,
    alignItems: "center",
    fontSize: 14,
    paddingTop: 8,
  };

  return (
    <>
      <ImageUploading
        multiple={multiple}
        allowNonImageType={true}
        maxNumber={max}
        value={value}
        maxFileSize={maxFileSize}
        acceptType={acceptType}
        onChange={(imageList, addUpdateIndex) => {
          let list = imageList;
          onChange(list, addUpdateIndex);
        }}
        dataURLKey="data_url">
        {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => {
          return (
            <div>
              <div>
                {editable && (imageList.length < max || replace) ? (
                  uploadComponent ? (
                    React.cloneElement(uploadComponent, {
                      onClick: onImageUpload,
                    })
                  ) : (
                    <IconButton
                      onClick={onImageUpload}
                      {...dragProps}
                      style={{
                        padding: 0,
                        color: "#A5A5A5",
                        margin: "10px 0",
                        width: "100%",
                      }}
                      size={size}
                      color={iconColor}
                      hoverColor={iconHoverColor}>
                      {uploadComponent ? (
                        uploadComponent
                      ) : (
                        <i className="meta-crm-icon-ic_img font-size-32" />
                      )}
                    </IconButton>
                  )
                ) : (
                  ""
                )}
              </div>
              {errors && (
                <div style={{ color: theme.customColors.pink[600] }}>
                  {errors.maxNumber && (
                    <span style={errorStyle}>
                      <i className="meta-crm-icon-ic_alert font-size-14" />
                      The number of selected images exceeds the maximum allowed
                      limit ({max})
                    </span>
                  )}
                  {errors.acceptType && (
                    <span style={errorStyle}>
                      <i className="meta-crm-icon-ic_alert font-size-14" />
                      {formatMessage({ id: "global.fileUpload.invalidFormat" })}
                    </span>
                  )}
                  {errors.maxFileSize && (
                    <span style={errorStyle}>
                      <i className="meta-crm-icon-ic_alert font-size-14" />
                      {formatMessage({ id: "global.fileUpload.tooLarge" })}
                    </span>
                  )}
                </div>
              )}
            </div>
          );
        }}
      </ImageUploading>
    </>
  );
};

export default ImageUpload;
