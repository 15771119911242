import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  MEMBER_FETCH_BEGIN,
  MEMBER_FETCH_SUCCESS,
  MEMBER_FETCH_FAILURE,
  MEMBER_TICKET_PERMISSION_FETCH_BEGIN,
  MEMBER_TICKET_PERMISSION_FETCH_SUCCESS,
  MEMBER_TICKET_PERMISSION_FETCH_FAILURE,
} from "./constants";
import axios from "axios";
import { apiUrl } from "features/configure";
export function fetchTeamMember({ entityName }) {
  return (dispatch, getState) => {
    dispatch({
      type: MEMBER_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let members = await axios.get(
          `${apiUrl}/api/manage/team?entityName=` + entityName
        );
        dispatch({
          type: MEMBER_FETCH_SUCCESS,
          data: members.data,
        });
      } catch (err) {
        dispatch({
          type: MEMBER_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function fetchTeamMemberByTicketPermission({ entityName }) {
  return (dispatch, getState) => {
    dispatch({
      type: MEMBER_TICKET_PERMISSION_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let members = await axios.get(
          `${apiUrl}/api/manage/team/ticket?entityName=` + entityName
        );
        dispatch({
          type: MEMBER_TICKET_PERMISSION_FETCH_SUCCESS,
          data: members.data,
        });
      } catch (err) {
        dispatch({
          type: MEMBER_TICKET_PERMISSION_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function useFetchTeamMember() {
  const dispatch = useDispatch();

  const {
    memberList,
    memberByTicketList,
    fetchTeamMemberPending,
    fetchTeamMemberDone,
    fetchTeamMemberByTicketDone,
    fetchTeamMemberByTicketPending,
  } = useSelector(
    (state) => ({
      memberList: state.metadesk.memberList,
      memberByTicketList: state.metadesk.memberByTicketList,
      fetchTeamMemberPending: state.metadesk.fetchTeamMemberPending,
      fetchTeamMemberDone: state.metadesk.fetchTeamMemberDone,
      fetchTeamMemberByTicketDone: state.metadesk.fetchTeamMemberByTicketDone,
      fetchTeamMemberByTicketPending:
        state.metadesk.fetchTeamMemberByTicketPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchTeamMember(data));
    },
    [dispatch]
  );
  const permissionFetchAction = useCallback((data) => {
    return dispatch(fetchTeamMemberByTicketPermission(data));
  });

  return {
    memberList,
    memberByTicketList,
    fetchTeamMember: boundAction,
    fetchTeamMemberByTicketPermission: permissionFetchAction,
    fetchTeamMemberDone,
    fetchTeamMemberPending,
    fetchTeamMemberByTicketDone,
    fetchTeamMemberByTicketPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MEMBER_FETCH_BEGIN:
      return {
        ...state,
        fetchTeamMemberPending: true,
      };

    case MEMBER_FETCH_SUCCESS:
      return {
        ...state,
        memberList: action.data,
        fetchTeamMemberDone: true,
        fetchTeamMemberPending: false,
      };

    case MEMBER_FETCH_FAILURE:
      return {
        ...state,
        fetchTeamMemberPending: false,
      };
    case MEMBER_TICKET_PERMISSION_FETCH_BEGIN: {
      return {
        ...state,
        fetchTeamMemberByTicketPending: true,
      };
    }

    case MEMBER_TICKET_PERMISSION_FETCH_SUCCESS: {
      return {
        ...state,
        memberByTicketList: action.data,
        fetchTeamMemberByTicketDone: true,
        fetchTeamMemberByTicketPending: false,
      };
    }

    case MEMBER_TICKET_PERMISSION_FETCH_FAILURE: {
      return {
        ...state,
        fetchTeamMemberByTicketPending: true,
      };
    }

    default:
      return state;
  }
}
