import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledCircleNumber = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, buttonColor, buttonTextColor }) => ({
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F7F1FE",
  color: "#7B61FF",
  marginRight: "8px",
  borderRadius: "50%",
  flexShrink: 0,
}));
