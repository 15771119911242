import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@metacrm/metacrm-material-ui/dist/Button";

import { enqueueSnackbar } from "features/common/redux/actions";
import { useFetchAiSupportConfig } from "features/metadesk/redux/hooks";
import { selectMagicWandConfig } from "features/metadesk/redux/aiSupport/aiSupport.selector";

import Prompt from "features/common/Prompt";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { Loading } from "features/common";
import {
  AutoCompleteDropdown,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import ChatSimulation from "./ChatSimulation";

import {
  MagicWandWrapper,
  SimulationWrapper,
  StyledInputHint,
  StyledLayoutItem,
} from "./MagicWand.styles";
import {
  StyledCustomizationContainer,
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
} from "../Customization/TicketType/TicketType.styles";
import useGetPagePermission from "hooks/useGetPagePermission";

const MagicWand = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTone, setSelectedTone] = useState("");
  const [selectedLength, setSelectedLength] = useState("");

  const { updateMagicWandConfig } = useFetchAiSupportConfig();

  const selectedMagicWandConfig = useSelector(selectMagicWandConfig);
  const configTone = _.get(selectedMagicWandConfig, "tone");
  const configLength = _.get(selectedMagicWandConfig, "length");

  const { formatMessage } = useIntl();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tone = [
    {
      name: formatMessage({
        id: "aiManagement.conversationPreference.tone.professional",
      }),
    },
    {
      name: formatMessage({
        id: "aiManagement.conversationPreference.tone.friendly",
      }),
    },
    {
      name: formatMessage({
        id: "aiManagement.conversationPreference.tone.casual",
      }),
    },
  ];

  const length = [
    {
      name: formatMessage({
        id: "aiManagement.conversationPreference.length.long",
      }),
    },
    {
      name: "Medium",
    },
    {
      name: formatMessage({
        id: "aiManagement.conversationPreference.length.short",
      }),
    },
  ];

  const isEqualData = () => {
    return (
      _.isEqual(configTone, selectedTone) &&
      _.isEqual(configLength, selectedLength)
    );
  };

  const isValid = !isEqualData();

  useEffect(() => {
    setSelectedTone(configTone);
    setSelectedLength(configLength);
  }, [configLength, configTone]);

  const handleSave = async () => {
    try {
      setIsLoading(true);

      await updateMagicWandConfig({
        entityName,
        tone: selectedTone,
        length: selectedLength,
      });

      dispatch(
        enqueueSnackbar({
          message: "Update success!",
          options: {
            variant: "info",
          },
        })
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("aiSupport");

  return (
    <MagicWandWrapper>
      <StyledLayoutItem style={{ width: "100%" }}>
        <StyledCustomizationContainer>
          <Loading open={isLoading} fullScreen={false} />
          <StyledGeneralSettingsContainer>
            <StyledSettingTitle>
              {formatMessage({
                id: "aiManagement.conversationPreference.subtitle",
              })}
            </StyledSettingTitle>
            <StyledDescription>
              {formatMessage({
                id: "aiManagement.conversationPreference.description",
              })}
            </StyledDescription>
            <div className="formField">
              <div className="inputTitle startRow">{"Tone"}</div>
              <AutoCompleteDropdown
                options={_.map(tone, (o) => {
                  return { label: o.name, value: o.name };
                })}
                readonly={readonly}
                multiple={false}
                value={selectedTone}
                onChange={(e) => {
                  const { label, value } = e.target.value;
                  setSelectedTone(value);
                }}
                noBorder={false}
                size={SIZE.LARGE}
                focusBorderWidth={"1px"}
              />
            </div>
            <div className="formField">
              <div className="inputTitle startRow">{"Length"}</div>
              <AutoCompleteDropdown
                options={_.map(length, (o) => {
                  return { label: o.name, value: o.name };
                })}
                readonly={readonly}
                multiple={false}
                value={selectedLength}
                onChange={(e) => {
                  const { label, value } = e.target.value;
                  setSelectedLength(value);
                }}
                noBorder={false}
                size={SIZE.LARGE}
                focusBorderWidth={"1px"}
              />
            </div>
            <StyledInputHint>
              <Box mr="3px" mt="1px" display="inline">
                <i className="meta-crm-icon-ic_info font-size-14" />
              </Box>
              {formatMessage({
                id: "aiManagement.conversationPreference.explanatory.language",
              })}
            </StyledInputHint>
          </StyledGeneralSettingsContainer>
        </StyledCustomizationContainer>
      </StyledLayoutItem>
      <SimulationWrapper style={{ paddingBottom: 100 }}>
        <div style={{ fontSize: 14, color: theme.customColors.grey[700] }}>
          {formatMessage({
            id: "aiManagement.magicWand.simulation.title",
          })}
        </div>
        <ChatSimulation tone={selectedTone} length={selectedLength} />
      </SimulationWrapper>
      {canEdit && (
        <FixedButton isShowGap={false}>
          <Button
            sx={{ width: "120px", marginRight: !isMobile ? 384 : "auto" }}
            onClick={handleSave}
            disabled={!isValid}
          >
            {formatMessage({ id: "global.btn.save" })}
          </Button>
        </FixedButton>
      )}
      <Prompt when={!isEqualData()} />
    </MagicWandWrapper>
  );
};

export default MagicWand;
