import Back from "features/metadesk/components/Back";
import { upperCase } from "lodash-es";
import React, { useContext } from "react";
import SinglePerformanceFilter from "./SinglePerformanceFilter";
import SinglePerformanceChart from "./SinglePerformanceChart";
import useGetPerformanceDetail from "./hook/useGetPerformanceDetail";
import { Box } from "@mui/material";
import useGetSinglePerformanceTableData from "./hook/useGetPerformanceDetailTable";

export default function SinglePerformanceHeader() {
  const { chartData, campaignInfo } = useGetPerformanceDetail();
  const { totalCount } = useGetSinglePerformanceTableData();
  return (
    <>
      <Back />
      <Box className={"title"} display={"flex"} alignItems={"center"}>
        {campaignInfo?.name}{" "}
        <Box display={"flex"} sx={{ fontSize: "16px", marginLeft: "8px" }}>
          (&nbsp;
          <Box color={(theme) => theme.customColors.purple[500]} mr="5px">
            {totalCount}
          </Box>
          wallet )
        </Box>
      </Box>
      <SinglePerformanceFilter campaign={campaignInfo} />
      <SinglePerformanceChart
        chartData={chartData}
        campaignInfo={campaignInfo}
      />
    </>
  );
}
