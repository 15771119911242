import { set } from "lodash";
import React, { useState, createContext, useEffect } from "react";
import { useParams } from "react-router";

export const CampaignListContext = createContext();

// 提供器组件
export const CampaignListProvider = ({ campaignStatus, children }) => {
  const [search, setSearch] = useState("");
  const status = campaignStatus === "all" ? "" : campaignStatus;
  const [promotype, setPromotype] = useState("");
  return (
    <CampaignListContext.Provider
      value={{ search, setSearch, promotype, setPromotype, status }}
    >
      {children}
    </CampaignListContext.Provider>
  );
};
