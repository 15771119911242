import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { makeStyles } from "@mui/styles";
import BigNumber from "bignumber.js";
import CustomDataFetching from "components/CustomDataFetching/CustomDataFetching";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
import Rows from "components/Rows/Rows";
import { Loading } from "features/common";
import { defaultImage, images } from "features/configure";
import {
  convertAmountFromRawNumber,
  convertHexToString,
  formatValue,
} from "features/helpers/bignumber";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import SelectChain from "../components/SelectChain";
import TicketTable from "../components/TicketTable";
import Transaction from "../components/Transaction";
import UserDetail from "../components/UserDetail";
import UserTitle from "../components/UserTitle";
import { useFetchTransactionType } from "../redux/fetchTransactionType";
import { pools, tokenMapping } from "./configure";
import { useFetchMagpieDetails } from "./redux/fetchMagpieDetails";
import { useFetchMagpieTxs } from "./redux/fetchMagpieTxs";
import { StyledUserInfoContainer } from "../ticket/TicketDetail.styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#A5A5A5",
    fontWeight: 700,
    fontSize: 14,
  },

  subTitle: {
    fontWeight: 700,
    fontSize: 12,
    color: "#777777",
    marginBottom: 5,
  },
  value: { fontWeight: 900, fontSize: 18 },

  desc: { color: "#A5A5A5", fontWeight: 500, fontSize: 9 },
  smIcon: { marginLeft: 3, width: 14, height: 14 },
  icon: { marginRight: 3, width: 20, height: 20 },
  row: {
    background: "white",
    padding: "10px",
    fontSize: 12,
    fontWeight: 700,
    borderTop: "1px solid rgba(165, 165, 165, 0.5)",
    borderLeft: "1px solid rgba(165, 165, 165, 0.5)",
    borderRight: "1px solid rgba(165, 165, 165, 0.5)",
  },
  swap: {
    width: 23,
    height: 23,
    margin: 10,
  },
  rowHeader: {
    color: "#7B61FF",
    fontSize: 10,
    fontWeight: 700,
  },
  icon: { marginRight: 5, width: 15, height: 15 },
  poolType: {
    borderRadius: 3,
    padding: "2px 6px",
    margin: 5,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 110,
    display: "inline-block",
    textOverflow: "ellipsis",
  },
  smValue: { fontWeight: 400, fontSize: 12, color: "#383538" },
  selectMethod: {
    color: "#555555",
    fontWeight: 400,
    fontSize: 12,
  },
}));

const MagpieUserInfo = ({
  selectedUserId,
  selectedUserAddress,
  entityName,
  isFetching,
  fetchUserObjs,
}) => {
  const chainList = useSelector(selectEntityChainList);
  const classes = useStyles();
  const { entityConfig } = useFetchEntityConfig();
  const { formatMessage } = useIntl();
  const chains = [
    { name: "All", id: 0 },
    ..._.filter(chainList, function (o) {
      return _.includes(entityConfig.chainIds, o.id);
    }),
  ];
  const getChainInitMapping = (init = []) => {
    return _.reduce(
      chains,
      (result, chain) => {
        result[chain.id] = init;
        return result;
      },
      {}
    );
  };
  const [magpiePoolsDetails, setMagpiePoolsDetails] = useState(
    getChainInitMapping()
  );

  const [magpieLPPoolsDetails, setMagpieLPPoolsDetails] = useState(
    getChainInitMapping()
  );
  const [magpieNetWorth, setMagpieNetWorth] = useState(0);
  const [soldMGPValue, setSoldMGPValue] = useState(getChainInitMapping(0));
  const { userWalletInfos } = fetchUserObjs();
  const [selectedChain, setSelectedChain] = useState({
    distribution: 0,
    transaction: 0,
  });
  const userInfo = _.get(userWalletInfos, `[${selectedUserAddress}]`, {});
  const { magpieDetails, fetchMagpieDetails, fetchMagpieDetailsPending } =
    useFetchMagpieDetails();
  const { magpieTxs, fetchMagpieTxs, fetchMagpieTxsPending } =
    useFetchMagpieTxs();
  const [magpieTransactions, setMagpieTransactions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchMethod, setSearchMethod] = useState([]);

  const { transactionTypeList, fetchTransactionType } =
    useFetchTransactionType();

  const bscIcon = _.find(chains, { id: 56 })?.logo_url;
  const arbIcon = _.find(chains, { id: 42161 })?.logo_url;

  useEffect(() => {
    setSelectedTab(0);
    setSearchMethod([]);
    setSelectedChain({
      distribution: 0,
      transaction: 0,
    });
    if (selectedUserAddress) {
      fetchMagpieDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchMagpieTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchTransactionType({
        entityName: entityName,
      });
    }
  }, [selectedUserAddress]);

  useEffect(() => {
    const allProtocolsDataFetched = _.every(userInfo.updatedList, {
      protocolDataFetching: false,
    });

    if (allProtocolsDataFetched && selectedUserAddress) {
      fetchMagpieDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchMagpieTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
    }
  }, [userInfo.updatedList]);

  useEffect(() => {
    let lpPoolsDetails = [];
    let magpiePoolsDetails = [];
    let netWorth = getChainInitMapping(0);
    if (magpieDetails && magpieDetails.length > 0) {
      for (let i = 0; i < magpieDetails.length; i++) {
        let detail = magpieDetails[i];
        netWorth[0] += detail["totalValue"];
        netWorth[detail.chainId] += detail["totalValue"];
        if (
          selectedChain.distribution != 0 &&
          detail.chainId != selectedChain.distribution
        ) {
          continue;
        }
        if (
          detail.name == "VLMGP" ||
          detail.name == "mWOM Staking" ||
          detail.name == "mWom Stability Vault"
        ) {
          magpiePoolsDetails.push(detail);
        } else {
          lpPoolsDetails.push(detail);
        }
      }
    }
    setMagpieLPPoolsDetails(lpPoolsDetails);
    setMagpiePoolsDetails(magpiePoolsDetails);
    setMagpieNetWorth(netWorth);
  }, [magpieDetails, selectedChain.distribution]);

  useEffect(() => {
    let filteredTxs = _.filter(magpieTxs, function (magpieTx) {
      return magpieTx["method"] !== "Sold MGP";
    });
    if (selectedChain.transaction != 0) {
      filteredTxs = filteredTxs.filter(
        (entry) => entry.chainId == selectedChain.transaction
      );
    }
    if (searchMethod && searchMethod.length != 0) {
      filteredTxs = filteredTxs.filter((entry) =>
        _.includes(searchMethod, entry.method)
      );
    }
    setMagpieTransactions(filteredTxs);
  }, [magpieTxs, selectedChain.transaction, searchMethod]);

  useEffect(() => {
    let transactions = [];
    let soldMGPValue = getChainInitMapping(0);
    if (magpieTxs && magpieTxs.length > 0) {
      let filteredMagpiTxs = _.filter(magpieTxs, function (magpieTx) {
        return magpieTx["method"] !== "Sold MGP";
      });
      _.forEach(filteredMagpiTxs, function (magpieTx) {
        let oldArguments = magpieTx && _.clone(magpieTx.arguments);
        let newArguments = oldArguments.reduce((acc, item) => {
          const existingItem = acc.find((i) => i.argument === item.argument);
          if (existingItem) {
            existingItem.value.push(item.value);
          } else {
            acc.push({ argument: item.argument, value: [item.value] });
          }
          return acc;
        }, []);
        magpieTx.arguments = newArguments;
        transactions.push(magpieTx);
      });
      let soldMGPTxs = _.filter(magpieTxs, function (magpieTx) {
        return magpieTx["method"] == "Sold MGP";
      });
      soldMGPValue = soldMGPTxs.reduce((total, soldMGPTx) => {
        const soldMGPValueObj = soldMGPTx.arguments.find(
          (arg) => arg.argument === "soldMGPValue"
        );
        const sellAmountInUSD = parseFloat(soldMGPValueObj.value);
        total[0] += sellAmountInUSD;
        total[soldMGPTx.chainId] += sellAmountInUSD;
        return total;
      }, soldMGPValue);
    }
    setSoldMGPValue(soldMGPValue);
    setMagpieTransactions(transactions);
  }, [magpieTxs]);

  const setSelectedChainByTab = (tabName, selectedChainId) => {
    const updatedSelectedChain = {
      ...selectedChain,
      [tabName]: selectedChainId,
    };
    setSelectedChain(updatedSelectedChain);
  };

  const renderSelectChain = (tabName) => {
    return (
      <SelectChain
        setSelectedChainByTab={setSelectedChainByTab}
        selectedChain={selectedChain}
        chains={chains}
        tabName={tabName}
      />
    );
  };

  const renderTxs = (data, loading) => {
    const renderTxContentWithData = () => (
      <Transaction
        data={data}
        chains={chains}
        renderTxContentBasedOnABI={renderTxContentBasedOnABI}
      />
    );
    return (
      <CustomDataFetching
        fetching={_.some(userInfo.updatedList, {
          protocolDataFetching: true,
        })}
        loading={loading}
        hasData={data && data.length > 0}
        contents={renderTxContentWithData}
      ></CustomDataFetching>
    );
  };

  const renderSelectMethod = () => {
    if (transactionTypeList && transactionTypeList.length != 0) {
      return (
        <div className={`${classes.selectMethod} startRow`}>
          <div style={{ marginRight: 5, marginLeft: 13 }}>Method:</div>
          <CustomDropdown
            border
            sm
            data={[{ values: [], label: "All" }, ...transactionTypeList]}
            fullWidth
            placeholder={"All"}
            value={searchMethod}
            onSelect={(e) => setSearchMethod(e.target.value)}
            style={{
              width: "15vw",
            }}
            multipleValues={true}
          />
        </div>
      );
    }
  };

  const renderToken = (tokenData, value) => {
    if (!tokenData) return;
    let formatValue = new BigNumber(
      convertAmountFromRawNumber(
        typeof value === "object"
          ? convertHexToString(_.get(value, "_hex", 0))
          : value,
        _.get(tokenData, "decimals", 18)
      )
    ).toFormat(3);

    if (
      typeof value === "object" &&
      _.get(value, "_hex") ==
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    ) {
      formatValue = "All";
    } else if (
      value >=
      115000000000000000000000000000000000000000000000000000000000000000000000000000
    ) {
      formatValue = "All";
    }

    return (
      <div className="startRow">
        <img
          style={{ width: 20, height: 20, marginRight: 5 }}
          src={tokenData.img_url || images[defaultImage]}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = require(`assets/img/empty-token.png`);
          }}
        />

        <div>
          <div style={{ color: "#A5A5A5", fontSize: 11 }}>
            {_.get(tokenData, "name")}
          </div>
          {value && (
            <div style={{ color: "#383538", fontSize: 12 }}>{formatValue}</div>
          )}
        </div>
      </div>
    );
  };
  const renderUserAddress = () => {
    const address = _.get(userInfo, "address", "");
    if (!address) return;
    return `${address.slice(0, 6)}....${address.slice(-4)}`;
  };

  const renderAddLiquidityLPTxContent = (row) => {
    let amount = _.get(row, "arguments[0].value[0]");

    let asset = _.get(row, "depositToken");
    const token = _.find(tokenMapping, { address: _.toLower(asset) }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        {renderUserAddress()}
        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        <div>{renderToken(token, amount)}</div>
      </div>
    );
  };

  const renderAddLiquidityTxContent = (row) => {
    const firstArg = row.arguments[0].argument;
    let amount = 0;
    let asset = "";
    if (firstArg == "_amount") {
      amount = _.get(row, "arguments[0].value[0]");
      asset = _.get(row, "depositToken");
    }
    if (firstArg == "_stakingToken") {
      amount = _.get(row, "arguments[1].value[0]");
      asset = _.get(row, "arguments[0].value[0]");
    }

    const token = _.find(tokenMapping, { address: _.toLower(asset) }) || {
      name: _.toLower(asset),
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        {renderUserAddress()}
        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        <div>{renderToken(token, amount)}</div>
      </div>
    );
  };

  const renderWithdrawTxContent = (row) => {
    const firstArg = row.arguments[0].argument;

    let amount = 0;
    let asset = "";
    if (firstArg == "_liquidity") {
      amount = _.get(row, "arguments[1].value[0]");
      asset = _.get(row, "depositToken");
    }
    if (firstArg == "_stakingToken") {
      amount = _.get(row, "arguments[1].value[0]");
      asset = _.get(row, "arguments[0].value[0]");
    }

    const token = _.find(tokenMapping, { address: _.toLower(asset) }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        {renderToken(token, amount)}
        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        {renderUserAddress()}
      </div>
    );
  };
  const renderGetRewardTxContent = (row) => {
    const tokens = _.get(row, "arguments[0].value[0]", []);

    return (
      <div className="row">
        <div>
          {_.map(tokens, (token, i) => {
            let asset = _.find(pools[row.chainId], {
              stakingTokenAddress: token,
            })?.name;

            return (
              <div
                key={i}
                className="startRow"
                style={{ marginBottom: 2, color: "#A5A5A5", fontSize: 11 }}
              >
                <img
                  src={
                    asset == "mWOM Staking" || asset == "mWom Stability Vault"
                      ? require(`assets/img/mWOM.png`)
                      : images[`${asset}.png`] || images[defaultImage]
                  }
                  className="smIcon"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require(`assets/img/empty-token.png`);
                  }}
                />
                {asset == "mWOM Staking" || asset == "mWom Stability Vault"
                  ? "mWOM"
                  : asset}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderLockTxContent = (row) => {
    let amount = _.get(row, "arguments[0].value[0]");
    let asset = _.get(row, "smartContractName");
    if (asset == "mWom Stability Vault_pool") asset = "mWOM";
    else if (asset == "vlmgp" || asset == "Vote Locked MGP") asset = "MGP";
    const token = _.find(tokenMapping, { name: asset }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return <div className="row">{renderToken(token, amount)}</div>;
  };

  const renderTxContentBasedOnABI = (row, method) => {
    switch (method) {
      case "withdraw":
        return renderWithdrawTxContent(row);
      case "deposit":
        return renderAddLiquidityTxContent(row);
      case "multiclaimSpec":
      case "multiclaim":
      case "multiclaimLegacy":
      case "compound":
        return renderGetRewardTxContent(row);
      case "depositLP":
      case "depositNative":
        return renderAddLiquidityLPTxContent(row);
      case "startUnlock":
      case "lock":
        return renderLockTxContent(row);
      default:
        return <div />;
    }
  };

  const renderPoolType = (poolType) => {
    if (poolType == "normal") return;
    else if (poolType == "deprecated")
      return (
        <span
          style={{ background: "rgb(250,236,204)" }}
          className={classes.poolType}
        >
          {formatMessage({ id: "userInfoContent.magpieUserInfo.deprecated" })}
        </span>
      );
    else {
      return (
        <span
          style={{ background: "#7B61FF", color: "white" }}
          className={classes.poolType}
        >
          {poolType} {formatMessage({ id: "userInfoContent.userInfo.pool" })}
        </span>
      );
    }
  };

  let tabs = [
    {
      label: "Overview",
      content: <UserDetail userInfo={userInfo} />,
    },
    {
      label: "Magpie Distribution",
      content: (
        <div>
          {fetchMagpieDetailsPending ? (
            <div style={{ textAlign: "center" }}>
              <LoadingIcon style={{ height: 100, width: 100 }} />
            </div>
          ) : (
            <>
              <>
                {renderSelectChain("distribution")}
                <div className={`startRow infoHeader`}>
                  {formatMessage({ id: "userInfoContent.magpieUserInfo.sold" })}
                </div>
                <CustomDataFetching
                  fetching={_.some(userInfo.updatedList, {
                    protocolDataFetching: true,
                  })}
                  loading={false}
                  hasData={true}
                  contents={() => {
                    return (
                      <div className="startRow" style={{ padding: "13px" }}>
                        <img src={images["MGP.svg"]} className="smIcon" />
                        <div
                          style={{
                            fontWeight: 700,
                            color: "#777777",
                          }}
                        >
                          MGP:
                        </div>
                        <div
                          style={{
                            fontWeight: 700,
                            fontSize: 14,
                            marginLeft: 3,
                          }}
                        >
                          $
                          {parseFloat(
                            soldMGPValue[selectedChain.distribution]
                          ).toFixed(2)}
                        </div>
                      </div>
                    );
                  }}
                ></CustomDataFetching>
              </>
              <>
                <div className={`startRow infoHeader`}>
                  Liquidity{" "}
                  {formatMessage({ id: "userInfoContent.userInfo.pools" })}
                </div>
                <CustomDataFetching
                  fetching={_.some(userInfo.updatedList, {
                    protocolDataFetching: true,
                  })}
                  loading={false}
                  hasData={
                    magpieLPPoolsDetails && magpieLPPoolsDetails.length > 0
                  }
                  contents={() => {
                    return (
                      <Rows
                        style={{ fontSize: 12 }}
                        widths={[1, 4, 2, 3, 2]}
                        headers={[
                          "Chain",
                          "Pool",
                          "Balances",
                          "Rewards",
                          "USD Value",
                        ]}
                        contents={magpieLPPoolsDetails.map((row, i) => {
                          return [
                            <div>
                              <img
                                src={
                                  _.find(chains, { id: row.chainId })?.logo_url
                                }
                                className={classes.icon}
                              />
                            </div>,
                            <div
                              className="startRow"
                              style={{ fontWeight: 700 }}
                            >
                              <img
                                src={
                                  images[
                                    `${_.truncate(_.get(row, "name", ""))}.png`
                                  ] || images[defaultImage]
                                }
                                className="icon"
                              />
                              {_.truncate(_.get(row, "name", ""))}
                              {renderPoolType(_.get(row, "type", "normal"))}
                            </div>,
                            <div>
                              <span>
                                {formatValue(
                                  _.get(row, "balances", 0),
                                  "N/A",
                                  4
                                )}
                              </span>
                              <span> {_.truncate(_.get(row, "name", ""))}</span>
                            </div>,
                            <div>
                              {row.rewards.map((reward) => {
                                return (
                                  <div>
                                    <span>{formatValue(reward.balances)}</span>
                                    <span> {reward.name}</span>
                                    <span>
                                      ($
                                      {formatValue(reward.totalValue)})
                                    </span>
                                  </div>
                                );
                              })}
                            </div>,
                            <div>
                              $
                              {_.get(row, "totalValue")
                                ? formatValue(_.get(row, "totalValue", 0))
                                : "N/A"}
                            </div>,
                          ];
                        })}
                      />
                    );
                  }}
                ></CustomDataFetching>
              </>

              <>
                <div className={`startRow infoHeader`}>
                  Magpie{" "}
                  {formatMessage({ id: "userInfoContent.userInfo.pools" })}
                </div>
                <CustomDataFetching
                  fetching={_.some(userInfo.updatedList, {
                    protocolDataFetching: true,
                  })}
                  loading={false}
                  hasData={magpiePoolsDetails && magpiePoolsDetails.length > 0}
                  contents={() => {
                    return (
                      <Rows
                        style={{ fontSize: 12 }}
                        widths={[1, 4, 2, 3, 2]}
                        headers={[
                          "Chain",
                          "Pool",
                          "Balances",
                          "Rewards",
                          "USD Value",
                        ]}
                        contents={magpiePoolsDetails.map((row, i) => {
                          return [
                            <div>
                              <img
                                src={
                                  _.find(chains, { id: row.chainId })?.logo_url
                                }
                                className={classes.icon}
                              />
                            </div>,
                            <div
                              className="startRow"
                              style={{ fontWeight: 700 }}
                            >
                              <img
                                src={
                                  row.name == "mWOM Staking" ||
                                  row.name == "mWom Stability Vault"
                                    ? require(`assets/img/mWOM.png`)
                                    : images[
                                        `${_.truncate(
                                          _.get(row, "name", "")
                                        )}.png`
                                      ] || images[defaultImage]
                                }
                                className="icon"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = require(`assets/img/spot.png`);
                                }}
                              />
                              {_.truncate(_.get(row, "name", ""))}
                            </div>,
                            <div>
                              <span>
                                {_.get(row, "balances")
                                  ? parseFloat(
                                      _.get(row, "balances", 0)
                                    ).toFixed(4)
                                  : "N/A"}
                              </span>
                              {row.name == "mWom Stability Vault" ||
                              row.name == "mWOM Staking" ? (
                                <span> mWOM</span>
                              ) : (
                                <span>
                                  {" "}
                                  {_.truncate(_.get(row, "name", ""))}
                                </span>
                              )}
                            </div>,
                            <div>
                              {row.rewards.map((reward) => {
                                return (
                                  <div>
                                    <span>
                                      {parseFloat(reward.balances).toFixed(4)}
                                    </span>
                                    <span> {reward.name}</span>
                                    <span>
                                      {" "}
                                      ($
                                      {parseFloat(reward.totalValue).toFixed(2)}
                                      )
                                    </span>
                                  </div>
                                );
                              })}
                            </div>,
                            <div>
                              $
                              {_.get(row, "totalValue")
                                ? formatValue(_.get(row, "totalValue", 0))
                                : "N/A"}
                            </div>,
                          ];
                        })}
                      />
                    );
                  }}
                ></CustomDataFetching>
              </>
            </>
          )}
        </div>
      ),
    },
    {
      label: "Transaction History",
      content: (
        <div>
          {renderSelectChain("transaction")}
          {renderSelectMethod()}
          <div className={"infoHeader"}>
            {formatMessage({ id: "global.demographic.transaction" })}
          </div>
          {renderTxs(magpieTransactions, fetchMagpieTxsPending)}
        </div>
      ),
    },
  ];

  const renderContent = () => {
    return (
      <div style={{ padding: "12px" }}>
        <Tabs
          tabLabelStyle={{ fontSize: 12, fontWeight: 500 }}
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          tabs={tabs}
        />
      </div>
    );
  };

  return (
    <StyledUserInfoContainer>
      <UserTitle
        selectedUserId={selectedUserId}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        fetchUserObjs={fetchUserObjs}
        extra={
          <div>
            <div className="startRow">
              <img
                src={images["MGP.svg"]}
                className="smIcon"
                style={{ marginRight: 8 }}
              />
              <span
                style={{
                  color: "#777777",
                  fontWeight: 700,
                  fontSize: 12,
                }}
              >
                Magpie XYZ
              </span>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  marginLeft: 3,
                }}
              >
                ${formatValue(magpieNetWorth[0])}
              </div>
            </div>
            <div
              className={`startRow ${classes.smValue}`}
              style={{ marginLeft: 28, marginTop: 5 }}
            >
              <div className="startRow" style={{ marginRight: 12 }}>
                <img src={arbIcon} className={classes.icon} />$
                {formatValue(magpieNetWorth[42161])}
              </div>
              <div className="startRow">
                <img src={bscIcon} className={classes.icon} />$
                {formatValue(magpieNetWorth[56])}
              </div>
            </div>
          </div>
        }
      />

      <div style={{ position: "relative", flex: 1 }}>
        <Loading
          open={
            fetchMagpieDetailsPending || fetchMagpieTxsPending || isFetching
          }
          text={isFetching ? "Data Fetching..." : "Loading..."}
          fullScreen={false}
          topPos="50vh"
        />
        {renderContent()}
      </div>
    </StyledUserInfoContainer>
  );
};

export default MagpieUserInfo;
