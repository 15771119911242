import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledGeneralSettingsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledDraggableListContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "fit-content",
}));

export const StyledTicketNameTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, readonly }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[700],
  paddingLeft: readonly ? "14px" : "45px",
  marginBottom: "8px",
}));

export const StyledAddTicketTypeButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "500px",
  cursor: "pointer",
  backgroundColor: "transparent",
  border: `1px dashed ${theme.customColors.grey[300]}`,
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "8px",
  color: theme.customColors.grey[500],
  height: "40px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  "& .plus": {
    marginRight: "15px",
    fontWeight: 900,
    display: "flex",
  },
}));

export const StyledAlertText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.pink[600],
  display: "flex",
}));
