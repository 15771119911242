import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    MAGPIE_TXS_FETCH_BEGIN,
    MAGPIE_TXS_FETCH_SUCCESS,
    MAGPIE_TXS_FETCH_FAILURE,
} from "./constants";
import axios from 'axios';
import { apiUrl } from 'features/configure';

export function fetchMagpieTxs(input) {
    return (dispatch, getState) => {
        dispatch({
            type: MAGPIE_TXS_FETCH_BEGIN,
        });

        const promise = new Promise(async (resolve, reject) => {
            try {
                let txs = await axios.get(
                    `${apiUrl}/api/user/txs?address=` +
                    input.selectedUserAddress +
                    '&entityName=' +
                    input.entityName
                );
                dispatch({
                    type: MAGPIE_TXS_FETCH_SUCCESS,
                    data: txs.data,
                });
            } catch (err) {
                dispatch({
                    type: MAGPIE_TXS_FETCH_FAILURE,
                });
            }
        });

        return promise;
    };
}

export function useFetchMagpieTxs() {
    const dispatch = useDispatch();

    const { magpieTxs, soldMGPTxs, fetchMagpieTxsPending, fetchMagpieTxsDone } =
        useSelector(
            (state) => ({
                magpieTxs: state.metadesk.magpieTxs,
                fetchMagpieTxsPending: state.metadesk.fetchMagpieTxsPending,
                fetchMagpieTxsDone: state.metadesk.fetchMagpieTxsDone,
            }),
            shallowEqual
        );

    const boundAction = useCallback(
        (data) => {
            return dispatch(fetchMagpieTxs(data));
        },
        [dispatch]
    );

    return {
        magpieTxs,
        fetchMagpieTxs: boundAction,
        fetchMagpieTxsDone,
        fetchMagpieTxsPending,
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case MAGPIE_TXS_FETCH_BEGIN:
            return {
                ...state,
                magpieTxs: [],
                fetchMagpieTxsPending: true,
            };

        case MAGPIE_TXS_FETCH_SUCCESS:
            return {
                ...state,
                magpieTxs: action.data,
                fetchMagpieTxsDone: true,
                fetchMagpieTxsPending: false,
            };

        case MAGPIE_TXS_FETCH_FAILURE:
            return {
                ...state,
                fetchMagpieTxsPending: false,
            };

        default:
            return state;
    }
}
