import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectDesc: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: 200,
    margin: "5px auto",
  },
  modalPaper: {
    // width: "80%",
    width: 485,
    minHeight: 285,
    padding: "48px 38px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    outline: "none",
    lineHeight: 1.4,
  },
  active: {
    "& $line": {
      borderColor: "#2B9BFF",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#2B9BFF",
    },
  },
  title: { fontSize: 20, fontWeight: 700 },
  subtitle: { fontSize: 14, fontWeight: 400 },
  button: {
    padding: 10,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.background,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.1)",
    margin: 4,
    width: "195px",
    height: "115px",

    "&:hover": {
      border: theme.border,
    },
  },
  disabled: {
    cursor: "not-allowed",
    border: "2px solid #383538",
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
    background: "#D9D9D9",
    borderRadius: 20,
  },
});

export const StyledWelcomeText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
  fontSize: "20px",
}));

export default styles;
