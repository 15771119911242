import React, { useState, useRef, useContext, createContext } from "react";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import { Box } from "@mui/material";
import Button, {
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

// Create Context
const ConfirmContext = createContext();

export const useConfirm = () => useContext(ConfirmContext);

// options 範例
// options = {
//   noNeedConfirm:false,
//   title: "",
//   content: "",
//   confirmText: "",
//   confirmButtonColor:"",
//   cancelButtonColor:"",
//   cancelText: "",
//   color: "secondary"
// };

export const ConfirmDialogProvider = ({ children }) => {
  const [options, setOptions] = useState(null);
  const awaitingPromiseRef = useRef(null);

  const confirm = (options) => {
    if (options.noNeedConfirm) {
      return Promise.resolve();
    }
    setOptions(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOptions(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    if (options.onConfirm) {
      options.onConfirm();
    }
    setOptions(null);
  };

  const handleCancel = () => {
    if (options.onCancel) {
      options.onCancel();
    }
    handleClose();
  };

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
        <BasicDialog
          dialogRootStyle={{ maxWidth: "320px", textAlign: "center" }}
          open={Boolean(options)}
          closeBtnShow={false}
          backgroundClose={false}
          onClose={handleClose}
        >
          <Box padding={"32px"}>
            {options?.title && (
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "#383838",
                  mb: "12px",
                }}
              >
                {options?.title}
              </Box>
            )}
            {options?.content && (
              <Box sx={{ color: "#5C5C5C", mb: "24px" }}>
                {options?.content}{" "}
              </Box>
            )}
            <Box mt="24px" display={"flex"} justifyContent={"space-between"}>
              {options?.cancelText && (
                <Button
                  onClick={handleCancel}
                  variant={VARIANT.OUTLINED}
                  color={options && (options.cancelColor || COLOR.INFO)}
                  sx={{
                    minWidth: "120px",
                    mr: "12px",
                    whiteSpace: "noWrap",
                  }}
                >
                  {options?.cancelText}
                </Button>
              )}

              {options?.confirmText && (
                <Button
                  onClick={handleConfirm}
                  color={options && (options.color || COLOR.ERROR)}
                  sx={{
                    minWidth: "120px",
                    ml: "12px",
                    whiteSpace: "noWrap",
                  }}
                >
                  {options?.confirmText}
                </Button>
              )}
            </Box>
          </Box>
        </BasicDialog>
      </ConfirmContext.Provider>
    </>
  );
};

export default ConfirmDialogProvider;
