import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton, Button } from "@metacrm/metacrm-material-ui/dist/Button";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& > .MuiDialog-container": {
    // display: "flex",
    justifyContent: "center",
  },
  "& > .MuiPaper-root": {
    borderRadius: "10px",
    maxWidth: "584px",
    width: "584px",
    minHeight: "710px",
    display: "inline-flex",
    flexDirection: "column",
  },
}));

export const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "relative",
  padding: "12px 24px 16px",
  flex: 1,
  display: "flex",
}));

export const StyledCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    marginLeft: "auto",
    marginTop: "16px",
    marginRight: "16px",
    "&:hover": {
      background: "none",
    },
  };
});

export const StyledAddQuestionButton = styled("button", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  cursor: "pointer",
  backgroundColor: "transparent",
  border: `1px dashed ${theme.customColors.grey[300]}`,
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "3px",
  color: theme.customColors.grey[500],
  height: "40px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
  "& .plus": {
    marginRight: "15px",
    fontWeight: 900,
    display: "flex",
  },
}));

export const StyledCustomOutlinedInputContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, error }) => ({
  border: `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: "3px",

  "&:hover, &:focus": {
    ...(!error && {
      border: `1px solid ${theme.customColors.purple[500]}`,
    }),
  },

  ...(error && {
    background: theme.customColors.pink[100],
    border: `1px solid ${theme.customColors.pink[600]}`,
  }),

  "& .MuiFormControl-root": {
    // margin: "12px",
  },

  "& .MuiTextField-root": {
    "& .MuiInputBase-multiline": {
      // padding: 0,
      paddingBottom: 0,
      "& .MuiInputBase-input": {
        padding: 0,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0 !important",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          boxShadow: "none !important",
        },
      },
    },
  },
}));

export const StyledInputTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[700],
  marginBottom: "5px",
}));

export const StyledTools = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "8px 12px",
  paddingTop: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const StyledAutoComplete = styled(Autocomplete, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiInputBase-root": {
    padding: 0,
    paddingLeft: "12px",
    color: theme.customColors.grey[700],
    height: "40px",

    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.customColors.purple[500],
      },
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.customColors.grey[300]}`,
  },

  "& .MuiOutlinedInput-root": {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.customColors.purple[500],
      },
    },
  },
}));

export const StyleHint = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  fontSize: "12px",
  marginTop: "4px",
}));

export const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "14px",
  color: theme.customColors.pink[600],
}));
