import React, { useState, createContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { get } from "lodash-es";
import axios from "axios";
import { apiUrl } from "features/configure";
import useNavigateEntity from "hooks/useNavigateEntity";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useSelector } from "react-redux";
import {
  selectMarketingModule,
  selectWidgetConnected,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { getCampaignCustomType, getInitialTrackerObject } from "../utils/index";
import _ from "lodash";
export const CampaignCreationContext = createContext();

// 提供器组件
export const CampaignCreationProvider = ({ children, campaignType }) => {
  const isTracker = campaignType === "tracker";
  const { campaignId } = useParams();
  const { navigateEntity, entityName } = useNavigateEntity();
  const dispatchSnackbar = useDispatchSnackbar();
  const [loading, setLoading] = useState(false);
  const [isFromValid, setIsFormValid] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(null);

  const marketingModule = useSelector(selectMarketingModule);
  const isWidgetActive = useSelector(selectWidgetConnected);
  const isXmtpEnabled = marketingModule?.xmtp?.enabled;
  const isPushEnabled = marketingModule?.push?.enabled;
  const isXmtpConnected = marketingModule?.xmtp?.connected;
  const isPushConnected = marketingModule?.push?.connected;
  const isTrackingActive = marketingModule?.attribution?.connected;

  const [stepData, setStepData] = useState({
    name: "",
    description: "",
    startFrom: "",
    campaignContentSets: [],
    objectives: [],
  });

  const handleSetIsValid = (value) => {
    setIsFormValid(value);
  };

  const setStepValues = (values) => {
    setStepData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  useEffect(() => {
    if (isTracker) {
      setStepValues({
        campaignContentSets: [
          ...stepData.campaignContentSets,
          getInitialTrackerObject(),
        ],
      });
    }
  }, [isTracker]);

  const handleGetCampaignById = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/${campaignId}`
      );
      const mappingData = {
        ...data,
        campaignContentSets: data?.campaignContentSets?.map((item) => ({
          ...item,
          customWebsiteUrl: item?.customWebsiteUrl?.split("https://")[1] || "",
          customLinkSettings: item?.customLinkSettings?.map((setting) => ({
            ...setting,
            isOpenTerm: setting.terms.length > 0 || setting.contents.length > 0,
          })),
          engagementDistributionSettings:
            item?.engagementDistributionSettings?.map((dist) => {
              let useOnchain = item.goal === "onchain" ? true : false;
              let useLinkFormatSetting =
                dist?.linkTitle || dist?.shortLink ? true : false;
              let linkSetting = {};
              if (useLinkFormatSetting) {
                if (dist?.linkTitle) {
                  linkSetting = {
                    type: "namingLink",
                    linkTitle: dist.linkTitle,
                  };
                }
                if (dist?.shortLink) {
                  linkSetting = {
                    type: "customSuffix",
                    shortLink:
                      dist.shortLink?.split("https://bit.ly/")[1] || "",
                  };
                }
              }
              const customType = getCampaignCustomType(dist);
              const result = {
                ...dist,
                customType,
                userGroup: dist?.userGroup?._id || "",
                tag: dist?.tag?._id || "",
                websiteUrl: dist?.websiteUrl?.split("https://")[1] || "",
                useOnchain,
                useLinkFormatSetting,
                linkSetting,
              };
              if (customType == "campaignAudiences") {
                const totalCount = _.uniqBy(
                  _.flattenDeep(_.map(dist.campaignAudiences, "addresses"))
                ).length;
                result.estimatedAmount = totalCount;
                result.reachableUsers = totalCount;
              }

              return result;
            }),
        })),
      };
      setStepData(mappingData);
    } catch (error) {
      console.log(error);
      dispatchSnackbar({
        message: get(error, "response.data.error", "error"),
      });
      navigateEntity(`/marketing/campaign/draft`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (campaignId) {
      handleGetCampaignById();
    }
  }, [campaignId]);

  return (
    <CampaignCreationContext.Provider
      value={{
        stepData,
        setStepValues,
        isFromValid,
        handleSetIsValid,
        shouldNavigate,
        setShouldNavigate,
        loading,
        setLoading,
        isWidgetActive,
        isPushEnabled,
        isPushConnected,
        isXmtpEnabled,
        isXmtpConnected,
        isTrackingActive,
        isTracker,
      }}
    >
      {children}
    </CampaignCreationContext.Provider>
  );
};
