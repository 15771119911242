import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  AUTH_CHECK_BEGIN,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  INIT_SOCKET,
  SEND_MESSAGE,
  LOG_OUT,
  LOG_OUT_START,
  GET_AUTH_INFO_BEGIN,
  GET_AUTH_INFO_SUCCESS,
  GET_AUTH_INFO_FAILURE,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import io from "socket.io-client";
import { apiUrl, socketIOUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
axios.defaults.withCredentials = true;
const options = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export function authCheck(entityName) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_CHECK_BEGIN,
    });

    try {
      if (!entityName) return;

      const res = await axios.get(
        `${apiUrl}/api/session/authCheck?entityName=` + entityName
      );

      dispatch({
        type: AUTH_CHECK_SUCCESS,
        authorized: res.data.authorized,
      });
      const state = getState();
      initSocket(dispatch, entityName, state.home.socket);
    } catch (err) {
      dispatch({
        type: AUTH_CHECK_FAILURE,
      });
      // console.log(err);
    }
  };
}

export function getAuthenticatedInfo(entityName) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_AUTH_INFO_BEGIN,
    });

    try {
      if (!entityName) return;

      const res = await axios.get(
        `${apiUrl}/api/session/admin/info?entityName=` + entityName
      );

      dispatch({
        type: GET_AUTH_INFO_SUCCESS,
        userData: res.data,
      });
      const state = getState();
      initSocket(dispatch, entityName, state.home.socket);
    } catch (err) {
      dispatch({
        type: GET_AUTH_INFO_FAILURE,
      });
      // console.log(err);
    }
  };
}

const initSocket = (dispatch, entityName, nowSocket) => {
  if (!nowSocket) {
    const socket = io(socketIOUrl + "/" + entityName.toLowerCase());
    socket.emit("init", {
      userId: "admin",
    });
    dispatch({
      type: INIT_SOCKET,
      socket: socket,
    });
  }
};

const signoutAction = async () => {
  await axios.get(`${apiUrl}/api/session/sign-out`);
  localStorage.removeItem("access_token");
  localStorage.removeItem("sessionId");
  delete axios.defaults.headers.common["Authorization"];
};

export function signout(disconnect) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOG_OUT_START });
      await signoutAction();
      disconnect();
      dispatch({ type: LOG_OUT });
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: _.get(
            err,
            "response.data.error",
            _.get(err, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
}

export function useConnectWallet() {
  const dispatch = useDispatch();
  const {
    userData,
    auth,
    checkAuth,
    authCheckPending,
    authCheckDone,
    socket,
    logoutLoading,
    getAuthenticatedInfoPending,
    getAuthenticatedInfoDone,
  } = useSelector(
    (state) => ({
      userData: state.home.userData,
      auth: state.home.auth,
      checkAuth: state.home.checkAuth,
      authCheckPending: state.home.authCheckPending,
      authCheckDone: state.home.authCheckDone,
      socket: state.home.socket,
      logoutLoading: state.home.logoutLoading,
      getAuthenticatedInfoPending: state.home.getAuthenticatedInfoPending,
      getAuthenticatedInfoDone: state.home.getAuthenticatedInfoDone,
    }),
    shallowEqual
  );
  const authAction = useCallback(
    (data) => dispatch(authCheck(data)),
    [dispatch]
  );

  const getAuthenticatedInfoAction = useCallback(
    (data) => dispatch(getAuthenticatedInfo(data)),
    [dispatch]
  );

  const signoutAction = useCallback(
    (disconnect) => dispatch(signout(disconnect)),
    [dispatch]
  );
  return {
    userData,
    auth,
    checkAuth,
    authCheckPending,
    authCheckDone,
    logoutLoading,
    socket,
    getAuthenticatedInfoPending,
    getAuthenticatedInfoDone,
    authCheck: authAction,
    getAuthenticatedInfo: getAuthenticatedInfoAction,
    signout: signoutAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_CHECK_BEGIN:
      return {
        ...state,
        authCheckPending: true,
      };
    case AUTH_CHECK_SUCCESS:
      return {
        ...state,
        auth: action.authorized,
        checkAuth: true,
        authCheckDone: true,
        authCheckPending: false,
      };
    case AUTH_CHECK_FAILURE:
      return {
        ...state,
        auth: false,
        checkAuth: true,
        authCheckPending: false,
      };
    case GET_AUTH_INFO_BEGIN:
      return {
        ...state,
        getAuthenticatedInfoPending: true,
      };
    case GET_AUTH_INFO_SUCCESS:
      const newData = {
        ...state,
        getAuthenticatedInfoDone: true,
        getAuthenticatedInfoPending: false,
      };

      // TODO 看沒有這段會不會壞掉 不會就拔掉
      // if (
      //   _.get(action, "userData.address") !==
      //   _.get(state, "userData.address", "NoAddress")
      // ) {
      //   newData.userData = action.userData;
      // }

      newData.userData = action.userData;

      return newData;

    case GET_AUTH_INFO_FAILURE:
      return {
        ...state,
        userData: undefined,
        getAuthenticatedInfoPending: false,
      };
    case INIT_SOCKET:
      return {
        ...state,
        socket: action.socket,
      };
    case LOG_OUT_START:
      return {
        ...state,
        logoutLoading: true,
      };
    case LOG_OUT:
      return {
        ...state,
        userData: undefined,
        auth: false,
        checkAuth: false,
        authCheckPending: false,
        authCheckDone: false,
        logoutLoading: false,
        getAuthenticatedInfoPending: false,
        getAuthenticatedInfoDone: false,
      };
    default:
      return state;
  }
}
