import { GLOBAL_STATE_ACTION_TYPES } from "./globalState.types";
import { getStorage } from "features/helpers/storage";

export const GLOBAL_STATE_INITIAL_STATE = {
  globalState: {
    remindBarShow: !localStorage.getItem("version_remind_not_show"),
    billingBarShow: !localStorage.getItem("billing_remind_not_show"),
    isAdvancedSorting: getStorage("isAdvancedSorting"),
  },
};

export const reducer = (state = GLOBAL_STATE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_STATE_ACTION_TYPES.SET_CURRENT_REMIND_BAR_SHOW:
      return {
        ...state,
        globalState: {
          ...state.globalState,
          remindBarShow: payload,
        },
      };
    case GLOBAL_STATE_ACTION_TYPES.SET_CURRENT_BILLING_BAR_SHOW:
      return {
        ...state,
        globalState: {
          ...state.globalState,
          billingBarShow: payload,
        },
      };
    case GLOBAL_STATE_ACTION_TYPES.SET_ADVANCED_SORTING:
      return {
        ...state,
        globalState: {
          ...state.globalState,
          isAdvancedSorting: payload,
        },
      };
    default:
      return state;
  }
};
