import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { makeStyles } from "@mui/styles";
import CustomDataFetching from "components/CustomDataFetching/CustomDataFetching";
import { Loading } from "features/common";
import { formatValue } from "features/helpers/bignumber";
import { getHexImg } from "features/helpers/utils";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import {
  useFetchEntityConfig,
  useFetchTxs,
} from "features/metadesk/redux/hooks";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import SelectChain from "../components/SelectChain";
import Transaction from "../components/Transaction";
import UserDetail from "../components/UserDetail";
import UserTitle from "../components/UserTitle";
import { StyledUserInfoContainer } from "./TicketDetail.styles";
const useStyles = makeStyles((theme) => ({
  title: {
    color: "#777777",
    fontWeight: 700,
    fontSize: 12,
    marginRight: 6,
  },

  subTitle: {
    fontWeight: 700,
    fontSize: 12,
    color: "#777777",
    marginBottom: 5,
  },
  value: { fontWeight: 700, fontSize: 14 },
  smValue: { fontWeight: 700, fontSize: 15 },

  desc: { color: "#A5A5A5", fontSize: 12, marginLeft: 3, marginTop: 1 },
  smIcon: { marginLeft: 3, width: 16, height: 16 },
  icon: { marginRight: 3, width: 20, height: 20 },

  swap: {
    width: 23,
    height: 23,
    margin: 10,
  },
  rowHeader: {
    color: "#7B61FF",
    fontSize: 10,
    fontWeight: 700,
  },
  smIcon: { marginRight: 5, width: 15, height: 15 },
  leftBorder: {
    borderLeft: "0.5px solid #A5A5A5",
    paddingLeft: 17,
    marginLeft: 17,
  },
  selectMethod: {
    paddingLeft: 13,
    color: "#555555",
    fontWeight: 400,
    fontSize: 12,
  },
}));
const UserInfo = ({
  selectedUserId,
  selectedUserAddress,
  entityName,
  isFetching,
  fetchUserObjs,
}) => {
  const classes = useStyles();
  const { userWalletInfos } = fetchUserObjs();
  const userInfo = _.get(userWalletInfos, `[${selectedUserAddress}]`, {}) || {};
  const [selectedTab, setSelectedTab] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [selectedChain, setSelectedChain] = useState({
    transaction: 0,
  });
  const { formatMessage } = useIntl();
  const { txs, fetchTxs, fetchTxsPending } = useFetchTxs();
  const { entityConfig } = useFetchEntityConfig();
  const chainList = useSelector(selectEntityChainList);

  const chains = [
    { name: "All", id: 0 },
    ..._.filter(chainList, function (o) {
      return _.includes(entityConfig.chainIds, o.id);
    }),
  ];

  useEffect(() => {
    if (selectedUserAddress) {
      fetchTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
    }
  }, [selectedUserAddress]);

  useEffect(() => {
    let filteredTxs = txs;
    if (selectedChain.transaction != 0) {
      filteredTxs = filteredTxs.filter(
        (entry) => entry.chainId == selectedChain.transaction
      );
    }

    setTransactions(filteredTxs);
  }, [txs, selectedChain.transaction]);

  const renderTxs = (data, loading) => {
    const renderTxContentWithData = () => (
      <Transaction data={data} chains={chains} />
    );
    return (
      <>
        <CustomDataFetching
          loading={loading}
          hasData={data && data.length > 0}
          contents={renderTxContentWithData}
        ></CustomDataFetching>
      </>
    );
  };
  const setSelectedChainByTab = (tabName, selectedChainId) => {
    const updatedSelectedChain = {
      ...selectedChain,
      [tabName]: selectedChainId,
    };
    setSelectedChain(updatedSelectedChain);
  };
  const renderSelectChain = (tabName) => {
    return (
      <SelectChain
        setSelectedChainByTab={setSelectedChainByTab}
        selectedChain={selectedChain}
        chains={chains}
        tabName={tabName}
      />
    );
  };

  const renderMultiChainValue = (
    values,
    subValues,
    symbol = "$",
    defaultValue = "-",
    row = true
  ) => {
    if (_.get(entityConfig, "tokenChainIds", []).length <= 1) return;
    return (
      <div className={row ? "startRow" : ""} style={{ marginTop: row ? 7 : 0 }}>
        {_.map(entityConfig.tokenChainIds, (chainId, i) => {
          if (chainId == 0 || !subValues[chainId]) return;
          return (
            <div key={i} style={{ marginRight: 12 }}>
              <div
                className="startRow"
                style={{ marginBottom: subValues ? 0 : 5 }}
              >
                <img
                  src={_.find(chains, { id: chainId })?.logo_url}
                  className={classes.smIcon}
                />
                {symbol}
                {formatValue(values[chainId], defaultValue)}
              </div>

              {subValues && (
                <div
                  className={classes.desc}
                  style={{ marginBottom: 5 }}
                >{`($${formatValue(subValues[chainId], defaultValue)})`}</div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  let tabs = [
    {
      label: "Overview",
      content: <UserDetail userInfo={userInfo} />,
    },
    {
      label: "Transaction",
      content: (
        <div>
          <div className="userInfoContainer">
            {renderSelectChain("transaction")}
          </div>
          <div className={"infoHeader"}>
            {formatMessage({ id: "global.demographic.transaction" })}
          </div>
          {renderTxs(transactions, fetchTxsPending)}
        </div>
      ),
    },
  ];

  const renderContent = () => {
    return (
      <div style={{ position: "relative", padding: "12px", flex: 1 }}>
        <Loading
          open={isFetching}
          text={isFetching ? "Data Fetching..." : "Loading..."}
          fullScreen={false}
          topPos="50vh"
        />
        <Tabs
          value={selectedTab}
          tabLabelStyle={{ fontSize: 12, fontWeight: 500 }}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          tabs={tabs}
        />
      </div>
    );
  };
  const tokenList = _.get(userInfo, "entityTokenInfo.tokens", []);
  const entityTokenInfoMap = _.reduce(
    tokenList,
    (result, item) => {
      result["balances"][item.chainId] = _.get(item, "balance", 0);
      result["value"][item.chainId] = _.get(item, "value", 0);
      return result;
    },
    { balances: {}, value: {} }
  );

  return (
    <StyledUserInfoContainer>
      <UserTitle
        selectedUserId={selectedUserId}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        fetchUserObjs={fetchUserObjs}
        extra={
          userInfo &&
          userInfo.entityTokenInfo &&
          tokenList.length > 0 && (
            <div className="startRow">
              {userInfo && userInfo.entityTokenInfo && (
                <img
                  src={
                    _.get(userInfo, "entityTokenInfo.icon") ||
                    getHexImg(_.get(tokenList, "[0].address"))
                  }
                  className={"smIcon"}
                  style={{ borderRadius: 30 }}
                />
              )}
              <span className={classes.title}>
                {userInfo &&
                  userInfo.entityTokenInfo &&
                  _.get(userInfo, "entityTokenInfo.name")}{" "}
                Balance
              </span>
              <span className={classes.value}>
                {userInfo &&
                  userInfo.entityTokenInfo &&
                  formatValue(_.sumBy(tokenList, "balance"))}
              </span>
              <span className={classes.desc}>
                ($
                {userInfo &&
                userInfo.entityTokenInfo &&
                _.every(tokenList, ["value", null])
                  ? "-"
                  : formatValue(_.sumBy(tokenList, "value"))}
                )
              </span>
              <div
                style={{
                  marginLeft: 20,
                }}
              >
                {renderMultiChainValue(
                  entityTokenInfoMap.balances,
                  entityTokenInfoMap.value,
                  "",
                  "-"
                )}
              </div>
            </div>
          )
        }
      />
      {renderContent()}
    </StyledUserInfoContainer>
  );
};

export default UserInfo;
