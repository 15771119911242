import Web3 from "web3";

export const getWeb3 = function (chainId = 1) {
  let url = getWeb3Url(chainId);
  const web3 = new Web3(url);
  return web3;
};

export const getWeb3Url = function (chainId = 1) {
  let url =
    "https://eth-mainnet.g.alchemy.com/v2/eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm";
  switch (chainId) {
    case 1: //ETH
      url =
        "https://eth-mainnet.g.alchemy.com/v2/eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm";
      break;
    case 10: //OP
      url =
        "https://opt-mainnet.g.alchemy.com/v2/6QH3MDaqZ0JeUXYQ5NFm9i7vHIYDU-Dj";
      break;
    case 56: //BSC
      url =
        "https://nd-609-228-616.p2pify.com/a345890fda1ebcac95e2fa60c4d1e60f";
      break;
    case 137: //POLYGON
      url =
        "https://polygon-mainnet.g.alchemy.com/v2/TugKnx44-9srf-2Ldp0DhEpUA1tdsLpd";
      break;
    case 1284: //moonbeam
      url = "https://rpc.api.moonbeam.network";
      break;
    case 1287: //moonbase
      url = "https://rpc.api.moonbase.moonbeam.network";
      break;
    case 1337: //local
      url = "http://127.0.0.1:8545";
      break;
    case 42161: //ARBI
      url =
        "https://arb-mainnet.g.alchemy.com/v2/JwsB8NeMcVDaWlzYdKAPiepRk0Q7mA8O";
      break;

    case 43114: //AVAX
      url =
        "https://avax-mainnet.gateway.pokt.network/v1/lb/63030c57b7dca700396842f8";
      break;

    case 169:
      url =
        "https://lb.drpc.org/ogrpc?network=manta-pacific&dkey=Are4uJKjyU5IkFS_kuFW3-_3zF_m_g8R7pXAngOF84-p";
      break;
    case 1284:
      url =
        "https://lb.drpc.org/ogrpc?network=moonbeam&dkey=Are4uJKjyU5IkFS_kuFW3-_3zF_m_g8R7pXAngOF84-p";
      break;
    case 81457:
      url =
        "https://lb.drpc.org/ogrpc?network=blast&dkey=Are4uJKjyU5IkFS_kuFW3-_3zF_m_g8R7pXAngOF84-p";
      break;
    case 8453:
      url =
        "https://base-mainnet.g.alchemy.com/v2/6BVss7lqE_td8WFb1yk3kYtTVF56EeVA";
      break;
    case 324:
      url =
        "https://nd-234-942-600.p2pify.com/b73076844e2041807ed71adfd43cc782";
      break;
    case 59144:
      url =
        "https://lb.drpc.org/ogrpc?network=linea&dkey=Are4uJKjyU5IkFS_kuFW3-_3zF_m_g8R7pXAngOF84-p";
      break;
    case 5000:
      url =
        "https://lb.drpc.org/ogrpc?network=mantle&dkey=Are4uJKjyU5IkFS_kuFW3-_3zF_m_g8R7pXAngOF84-p";
      break;
  }

  return url;
};
