import React, { useEffect, useState } from "react";
import StepProvider from "contexts/stepContext/Step.context";
import Box from "@mui/material/Box";
import TelegramSettingStep1 from "./TelegramSettingStep1";
import TelegramSettingStep2 from "./TelegramSettingStep2";
import TelegramSettingStep3 from "./TelegramSettingStep3";
import CustomStepper from "components/CustomStepper/CustomStepper";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import useNavigateEntity from "hooks/useNavigateEntity";

import { get } from "lodash-es";

const TelegramSettingContainer = () => {
  const { navigateEntity } = useNavigateEntity();
  const { entityConfig } = useFetchEntityConfig();
  const isConnected = get(entityConfig, "serviceModule.telegram.connected");

  useEffect(() => {
    if (isConnected) {
      navigateEntity("/settings/general/integration/update/telegram");
    }
  }, [isConnected]);

  const [step, setStep] = useState(1);

  const stepName = ["Step1", "Step2"];

  const handleNextStep = () => {
    setStep((currentStep) => currentStep + 1);
  };
  const handlePrevStep = () => {
    setStep((currentStep) => currentStep - 1);
  };

  return (
    <Box>
      {step < 3 && (
        <Box mb="75px" width={"50%"} mx="auto">
          <CustomStepper steps={stepName} activeStep={step - 1}></CustomStepper>
        </Box>
      )}
      <StepProvider>
        {step === 1 && <TelegramSettingStep1 onNextStep={handleNextStep} />}
        {step === 2 && (
          <TelegramSettingStep2
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
          />
        )}
        {step === 3 && <TelegramSettingStep3 onPrevStep={handlePrevStep} />}
      </StepProvider>{" "}
    </Box>
  );
};

export default TelegramSettingContainer;
