import React from "react";
import Back from "../components/Back";
import OnChainBehavior from "./behavior/onChainBehavior";
import TwitterBehavior from "./behavior/TwitterBehavior";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { Box } from "@mui/material";

export default function CustomerBehavior() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: `On-Chain Analytics`,
      content: <OnChainBehavior />,
      _id: 1,
    },
    {
      label: `X Analytics`,
      content: <TwitterBehavior />,
      _id: 2,
    },
  ];
  return (
    <div>
      <Back />
      <Box className={"title"} mb="25px">
        Customer Analytics
      </Box>
      <Tabs tabs={tabs} value={value} onChange={handleChange} />
    </div>
  );
}
