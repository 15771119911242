import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  USER_WALLET_INFO_FETCH_BEGIN,
  USER_WALLET_INFO_FETCH_SUCCESS,
  USER_WALLET_INFO_FETCH_FAILURE,
  SEARCH_USER_WALLET_INFO_FETCH_BEGIN,
  SEARCH_USER_WALLET_INFO_FETCH_SUCCESS,
  SEARCH_USER_WALLET_INFO_FETCH_FAILURE,
  DID_INFO_FETCH_BEGIN,
  DID_INFO_FETCH_SUCCESS,
  DID_INFO_FETCH_FAILURE,
  REFRESH_WALLET_INFO,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import { alertServerError } from "features/helpers/utils";

export function fetchUserWalletInfo({
  entityName,
  userAddress,
  isSearch = false,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: isSearch
        ? SEARCH_USER_WALLET_INFO_FETCH_BEGIN
        : USER_WALLET_INFO_FETCH_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const apiEndPoint = isSearch
          ? `${apiUrl}/api/user/searchAddress?address=${userAddress}&entityName=${entityName}`
          : `${apiUrl}/api/user/walletInfo?address=${userAddress}&entityName=${entityName}`;

        let result = await axios.get(apiEndPoint);
        dispatch({
          type: isSearch
            ? SEARCH_USER_WALLET_INFO_FETCH_SUCCESS
            : USER_WALLET_INFO_FETCH_SUCCESS,
          data: result.data,
          userAddress,
        });
      } catch (err) {
        dispatch({
          type: isSearch
            ? SEARCH_USER_WALLET_INFO_FETCH_FAILURE
            : USER_WALLET_INFO_FETCH_FAILURE,
        });
        dispatch(alertServerError(err));
      }
    });

    return promise;
  };
}

export function fetchDIDInfo({ entityName, address, page, chainId }) {
  return (dispatch, getState) => {
    dispatch({
      type: DID_INFO_FETCH_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const apiEndPoint = `${apiUrl}/api/user/didInfo?address=${address}&entityName=${entityName}&page=${page}&chainId=${chainId}`;
        let result = await axios.get(apiEndPoint);
        const data = {
          type: DID_INFO_FETCH_SUCCESS,
          data: result.data,
          DIDCount: result.headers["x-total-count"],
          address,
        };
        if (!chainId) {
          data.totalDID = result.headers["x-total-count"];
        }
        dispatch(data);
      } catch (err) {
        dispatch({
          type: DID_INFO_FETCH_FAILURE,
        });
        dispatch(alertServerError(err));
      }
    });

    return promise;
  };
}

export function refreshWalletInfo({ entityName, address }) {
  return (dispatch, getState) => {
    dispatch({
      type: REFRESH_WALLET_INFO,
      address,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        await axios.post(apiUrl + "/api/user/refresh", {
          address,
          entityName: entityName,
        });
      } catch (err) {
        dispatch(alertServerError(err));
      }
    });

    return promise;
  };
}

export function useFetchUserWalletInfo() {
  const dispatch = useDispatch();

  const {
    userWalletInfos,
    searchUserWalletInfos,
    fetchUserWalletInfoPending,
    fetchSearchUserWalletInfoPending,
    fetchUserWalletInfoDone,
    DIDInfo,
    fetchDIDPending,
    DIDCount,
    totalDID,
  } = useSelector(
    (state) => ({
      userWalletInfos: state.metadesk.userWalletInfos,
      searchUserWalletInfos: state.metadesk.searchUserWalletInfos,
      fetchUserWalletInfoPending: state.metadesk.fetchUserWalletInfoPending,
      fetchSearchUserWalletInfoPending:
        state.metadesk.fetchSearchUserWalletInfoPending,
      fetchUserWalletInfoDone: state.metadesk.fetchUserWalletInfoDone,
      DIDInfo: state.metadesk.DIDInfo,
      fetchDIDPending: state.metadesk.fetchDIDPending,
      DIDCount: state.metadesk.DIDCount,
      totalDID: state.metadesk.totalDID,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchUserWalletInfo(data));
    },
    [dispatch]
  );

  const fetchDIDInfoAction = useCallback(
    (data) => {
      return dispatch(fetchDIDInfo(data));
    },
    [dispatch]
  );

  const refreshWalletInfoAction = useCallback(
    (data) => {
      return dispatch(refreshWalletInfo(data));
    },
    [dispatch]
  );

  return {
    userWalletInfos,
    searchUserWalletInfos,
    DIDCount,
    fetchUserWalletInfo: boundAction,
    fetchDIDInfo: fetchDIDInfoAction,
    refreshWalletInfo: refreshWalletInfoAction,
    fetchUserWalletInfoDone,
    fetchSearchUserWalletInfoPending,
    fetchUserWalletInfoPending,
    DIDInfo,
    fetchDIDPending,
    totalDID,
  };
}
export function reducer(state, action) {
  switch (action.type) {
    case USER_WALLET_INFO_FETCH_BEGIN:
      return {
        ...state,
        fetchUserWalletInfoPending: true,
        fetchUserWalletInfoDone: false,
      };

    case USER_WALLET_INFO_FETCH_SUCCESS:
      return {
        ...state,
        userWalletInfos: {
          ...state.userWalletInfos,
          [action.userAddress]: action.data,
        },
        fetchUserWalletInfoPending: false,
        fetchUserWalletInfoDone: true,
      };
    case USER_WALLET_INFO_FETCH_FAILURE:
      return {
        ...state,
        fetchUserWalletInfoPending: false,
        fetchUserWalletInfoDone: false,
      };

    case SEARCH_USER_WALLET_INFO_FETCH_BEGIN:
      return {
        ...state,
        fetchSearchUserWalletInfoPending: true,
      };

    case SEARCH_USER_WALLET_INFO_FETCH_SUCCESS:
      return {
        ...state,
        searchUserWalletInfos: {
          ...state.searchUserWalletInfos,
          [action.userAddress]: action.data,
        },
        fetchSearchUserWalletInfoPending: false,
      };
    case SEARCH_USER_WALLET_INFO_FETCH_FAILURE:
      return {
        ...state,
        searchUserWalletInfos: {},
        fetchSearchUserWalletInfoPending: false,
      };

    case DID_INFO_FETCH_BEGIN:
      return {
        ...state,
        fetchDIDInfoPending: true,
      };

    case DID_INFO_FETCH_SUCCESS:
      const r = {
        ...state,
        DIDInfo: action.data,
        DIDCount: action.DIDCount,
        fetchDIDPending: false,
      };
      if (action.totalDID) {
        r.totalDID = action.totalDID;
      }
      return r;
    case DID_INFO_FETCH_FAILURE:
      return {
        ...state,
        fetchDIDPending: false,
      };
    case REFRESH_WALLET_INFO:
      return {
        ...state,
        userWalletInfos: {
          ...state.userWalletInfos,
          [action.address]: {
            ...state.userWalletInfos[action.address],
            updatedList: [
              { onChainDataFetching: true },
              ..._.get(
                state,
                `userWalletInfos[${action.address}].updatedList`,
                []
              ),
            ],
          },
        },
        searchUserWalletInfos: {
          ...state.userWalletInfos,
          [action.address]: {
            ...state.userWalletInfos[action.address],
            updatedList: [
              { onChainDataFetching: true },
              ..._.get(
                state,
                `userWalletInfos[${action.address}].updatedList`,
                []
              ),
            ],
          },
        },
      };

    default:
      return state;
  }
}
