import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  TAGS_FETCH_BEGIN,
  TAGS_FETCH_SUCCESS,
  TAGS_FETCH_FAILURE,
  TAG_ADD_SUCCESS,
  TAG_DELETE_SUCCESS,
  TAG_EDIT_SUCCESS,
} from "./constants";
import axios from "axios";
import _ from "lodash";
import { TAG_COLORS, apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import * as Sentry from "@sentry/react";
export function fetchTags({ entityName }) {
  return (dispatch, getState) => {
    dispatch({
      type: TAGS_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let tags = await axios.get(
          `${apiUrl}/api/manage/tags/${entityName}/user`
        );
        dispatch({
          type: TAGS_FETCH_SUCCESS,
          data: tags.data,
          entityName,
        });
      } catch (err) {
        Sentry.captureException(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: TAGS_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function addTag({ entityName, tag }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      const { metadesk } = getState();
      try {
        const newTag = {
          value: tag.value,
          color:
            TAG_COLORS[metadesk.tags[entityName].length % TAG_COLORS.length],
          type: "user",
        };
        let tags = await axios.post(
          `${apiUrl}/api/manage/tags/${entityName}/user`,
          {
            ...newTag,
          }
        );
        dispatch({
          type: TAG_ADD_SUCCESS,
          data: tags.data,
          entityName,
        });
        resolve(tags.data);
      } catch (err) {
        Sentry.captureException(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}

export function removeTag({ entityName, tag }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        await axios.delete(
          `${apiUrl}/api/manage/tags/${entityName}/${tag._id}/user`
        );
        dispatch({
          type: TAG_DELETE_SUCCESS,
          data: tag,
          entityName,
        });
        resolve();
      } catch (err) {
        Sentry.captureException(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        console.log(err);
        reject();
      }
    });

    return promise;
  };
}

export function editTag({ entityName, tag, newValue }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const newTag = {
          ...tag,
          ...newValue,
          type: "user",
        };

        let tags = await axios.put(
          `${apiUrl}/api/manage/tags/${entityName}/${tag._id}/user`,
          {
            ...newTag,
          }
        );
        dispatch({
          type: TAG_EDIT_SUCCESS,
          data: tags.data,
          entityName,
        });
        resolve();
      } catch (err) {
        Sentry.captureException(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        reject();
      }
    });

    return promise;
  };
}

export function useFetchTags() {
  const dispatch = useDispatch();

  const { tags, fetchTagsPending, fetchTagsDone } = useSelector(
    (state) => ({
      tags: state.metadesk.tags,
      fetchTagsPending: state.metadesk.fetchTagsPending,
      fetchTagsDone: state.metadesk.fetchTagsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchTags(data));
    },
    [dispatch]
  );

  const addAction = useCallback(
    (data) => {
      return dispatch(addTag(data));
    },
    [dispatch]
  );
  const removeAction = useCallback(
    (data) => {
      return dispatch(removeTag(data));
    },
    [dispatch]
  );
  const editAction = useCallback(
    (data) => {
      return dispatch(editTag(data));
    },
    [dispatch]
  );

  return {
    tags,
    fetchTags: boundAction,
    addTag: addAction,
    removeTag: removeAction,
    editTag: editAction,
    fetchTagsDone,
    fetchTagsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TAGS_FETCH_BEGIN:
      return {
        ...state,
        fetchTagsPending: true,
      };

    case TAGS_FETCH_SUCCESS:
      const newState = {
        ...state,
        tags: {
          ...state.tags,
          [action.entityName]: action.data,
        },
        fetchTagsDone: true,
        fetchTagsPending: false,
      };
      return newState;

    case TAG_ADD_SUCCESS:
      const addState = {
        ...state,
        tags: {
          ...state.tags,
          [action.entityName]: [...state.tags[action.entityName], action.data],
        },
      };
      return addState;
    case TAG_DELETE_SUCCESS:
      const removeIndex = _.findIndex(state.tags[action.entityName], {
        _id: action.data._id,
      });
      const removeList = [...state.tags[action.entityName]];
      _.pullAt(removeList, [removeIndex]);

      return {
        ...state,
        tags: {
          ...state.tags,
          [action.entityName]: removeList,
        },
      };
    case TAG_EDIT_SUCCESS:
      const updatedIndex = _.findIndex(state.tags[action.entityName], {
        _id: action.data._id,
      });
      const newList = [...state.tags[action.entityName]];
      newList[updatedIndex] = action.data;

      return {
        ...state,
        tags: {
          ...state.tags,
          [action.entityName]: newList,
        },
      };

    case TAGS_FETCH_FAILURE:
      return {
        ...state,
        fetchTagsPending: false,
      };

    default:
      return state;
  }
}
