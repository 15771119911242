import styled from "@emotion/styled/macro";
import { Checkbox, FormControlLabel } from "@mui/material";
import { alpha } from "@mui/material/styles";

import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledLoadingContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAbsolute, backgroundColor, zIndex }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  zIndex: zIndex,
  ...(isAbsolute && {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    background: backgroundColor
      ? backgroundColor
      : `${alpha(theme.customColors.white, 0.7)}`,
  }),
}));

export const StyledLoadingImg = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}));

export const StyledLoadingText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  marginTop: "15px",
}));

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginRight: 0,
  marginLeft: 0,
}));

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: 0,
  marginRight: "4px",
  color: theme.customColors.purple[500],
  "&.Mui-checked": {
    color: theme.customColors.purple[500],
  },
}));
