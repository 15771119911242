import React, { useState, useEffect, useRef, useCallback } from "react";

import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";

import { get, set } from "lodash-es";

import { StyledAdamMessage, StyledWrapper } from "./AiChat.styles";
import AdamContextProvider from "./Adam.context";
import AiChatDialog from "./AiChatDialog";

function AiChat() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <StyledWrapper onClick={handleClickOpen}>
        {!open && (
          <>
            <Box
              component="img"
              src={require("assets/img/adam.svg").default}
              height={"30px"}
              width={"30px"}
            />
            <StyledAdamMessage>
              <Box className="endRow">Hi, I’m Adam 🪄</Box>
              <>
                I can help you create your campaign and find the best users for
                you
              </>
              <Box
                sx={{
                  position: "absolute",
                  right: "-1.5px",
                  bottom: "-1.5px",
                  zIndex: -1,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M11.8384 10.5101C12.0465 11.2513 11.3742 11.9397 10.6283 11.7493L1.13416 9.32603C0.388276 9.13565 0.128192 8.20921 0.666011 7.65845L7.51174 0.647909C8.04955 0.097142 8.98191 0.335119 9.18998 1.07627L11.8384 10.5101Z"
                    fill="#A2E6E2"
                  />
                </svg>
              </Box>
            </StyledAdamMessage>
          </>
        )}
      </StyledWrapper>
      {open && (
        <AdamContextProvider>
          <AiChatDialog onClose={handleClose}></AiChatDialog>
        </AdamContextProvider>
      )}
    </>
  );
}

export default AiChat;
