import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { Grid, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { alertServerError, parseCustomDomainUrl } from "features/helpers/utils";
import { Loading } from "features/common";
import axios from "axios";
import { apiUrl } from "features/configure";
import IntegrationSettings from "./IntegrationSettings";
import { use } from "echarts";

const IntegrationMailchimp = ({ isSetting }) => {
  const [entityName, isCustomDomain] = useOutletContext();
  const [isSuccess, setIsSuccess] = useState(false);
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();
  const [apiKey, setApiKey] = useState("");
  const [orignalApiKey, setOrignalApiKey] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(async () => {
    const result = await axios.get(apiUrl + `/api/mailchimp/${entityName}/key`);
    setApiKey(_.get(result, "data.apiKey"));
    setOrignalApiKey(_.get(result, "data.apiKey"));
  }, []);

  const validate = () => {
    if (!apiKey) return false;
    if (orignalApiKey == apiKey) return false;

    return true;
  };

  const renderContent = (title) => {
    return (
      <div>
        <InputField
          fullWidth
          size={SIZE.LARGE}
          variant={VARIANT.OUTLINED}
          title={title}
          placeholder="ex : 123456789012345678901234567890-us8"
          value={apiKey}
          onChange={(e) => {
            setApiKey(e.target.value);
          }}
        />
      </div>
    );
  };
  const renderSaveButton = () => {
    return (
      <Button
        color={COLOR.SECONDARY}
        disabled={!validate()}
        onClick={async () => {
          try {
            setLoading(true);
            await axios.post(apiUrl + `/api/mailchimp/${entityName}/key`, {
              apiKey,
            });
            setOrignalApiKey(apiKey);
            setIsSuccess(true);
          } catch (err) {
            dispatch(alertServerError(err));
          } finally {
            setLoading(false);
          }
        }}
        size={SIZE.XL}
        style={{ width: 120 }}
      >
        Save
      </Button>
    );
  };

  const renderSteps = () => {
    return (
      <Grid
        container
        alignItems={"center"}
        style={{
          paddingTop: 150,
          overflowX: "hidden",
        }}
      >
        <Grid item xs={12} lg={2}></Grid>
        <Grid item xs={12} lg={4}>
          <div style={{ maxWidth: 530, margin: "0 auto" }}>
            <div className="lgTitle">Mailchimp API Settings</div>
            <div className="description">
              Engage your customers and boost your business with Mailchimp.
            </div>
            <div style={{ marginTop: 80 }}> {renderContent("API Key")}</div>

            <div style={{ paddingTop: 63 }}>
              <Button
                variant={VARIANT.OUTLINED}
                color={COLOR.SECONDARY}
                size={SIZE.XL}
                style={{ width: 120, marginRight: 8 }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              {renderSaveButton()}
              <div style={{ marginTop: 8 }} className="description">
                The API Key for connecting with your Mailchimp account.&nbsp;
                <a
                  href="https://preventdirectaccess.com/find-mailchimp-api-keys-wordpress/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get your API key Here
                </a>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ textAlign: "right" }}>
          <img
            src={require("assets/img/mailchimpIntegration.png")}
            height={555}
          />
        </Grid>
      </Grid>
    );
  };

  const renderIntegrationScreen = () => {
    return renderSteps();
  };

  if (isSuccess) {
    fetchEntityConfig({ entityName });
    return (
      <Navigate
        to={parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/settings/general/integration"
        )}
        replace={true}
      />
    );
  }

  if (isSetting) {
    return (
      <IntegrationSettings
        title="Mailchimp API Settings"
        description={
          "Engage your customers and boost your business with Mailchimp."
        }
        actions={renderSaveButton()}
        content={
          <div>
            <Loading open={loading} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="subTitle">API Key</div>
                <div
                  className="formHeader"
                  style={{ marginTop: 8, color: "#777" }}
                >
                  The API Key for connecting with your Mailchimp account.&nbsp;
                  <a
                    href="https://preventdirectaccess.com/find-mailchimp-api-keys-wordpress/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get your API key Here
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                {renderContent()}
              </Grid>
            </Grid>
          </div>
        }
      />
    );
  }

  return (
    <div style={{ minHeight: "calc(100vh - 50px)", background: "white" }}>
      <Loading open={loading} />
      {renderIntegrationScreen()}
    </div>
  );
};

export default IntegrationMailchimp;
