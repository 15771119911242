import ReactECharts from "echarts-for-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertServerError, formatDate, msToTime } from "features/helpers/utils";
import { Loading } from "features/common";
import { useOutletContext } from "react-router";
import { apiUrl } from "features/configure";
import axios from "axios";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import {
  StyledCard,
  StyledMemo,
  StyledSecondaryValue,
  StyledSubtitle,
} from "./ServiceReport.styles";
import moment from "moment";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { useIntl } from "react-intl";
import {
  AutoCompleteDropdown,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { selectDemoSite } from "../redux/entityConfig/entityConfig.selector";
import { ticketMetrics } from "./mockData/MockServiceReport";
import { serviceColors } from "features/configure/chartColors";

const channelColors = serviceColors.small;
const typeColors = serviceColors.middle;

const getDatesInRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

export function TicketAnalytics({
  startDate,
  endDate,
  platform,
  currentRange,
}) {
  const [entityName, isCustomDomain] = useOutletContext();
  const [analytics, setAnalytics] = useState();
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState({
    label: "Ticket Source",
    value: "ticketSource",
  });
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isDemoSite = useSelector(selectDemoSite);

  const renderPieChart = (pieData) => {
    if (pieData.length == 0 || _.sumBy(pieData, "value") == 0)
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          height="100%"
          padding="60px 15px"
        >
          <img src={require("assets/img/emptyPie.svg").default} />
          <Box
            color="#777777"
            fontSize={"14px"}
            fontWeight={500}
            mt="15px"
            maxWidth={"185px"}
          >
            {formatMessage({
              id: "sessionAnalysis.chart.sessionStatusDistribution.emptyStatus",
            })}
          </Box>
        </Box>
      );
    return (
      <>
        <ReactECharts
          option={{
            tooltip: {
              trigger: "item",
              formatter: "{a} {b} : {c} ({d}%)",
              position: "left",
            },
            grid: {
              bottom: "0%",
            },
            legend: {
              bottom: "0%",
              left: "right",
              icon: "circle",
            },
            color:
              chartType?.value == "ticketType" ? typeColors : channelColors,
            series: [
              {
                name: "",
                type: "pie",
                radius: ["30%", "50%"],
                center: ["50%", "45%"],

                data: pieData.map((item) => ({
                  ...item,
                  label: {
                    show: item.value !== 0,
                  },
                })),
                emphasis: {
                  itemStyle: {
                    shadowBlur: 2,
                    shadowOffsetX: 0,
                  },
                },
                labelLine: {
                  length: 2,
                  length2: 1,
                  show: false,
                },
                itemStyle: {
                  borderColor: "#fff",
                  borderWidth: 2,
                },
              },
            ],
          }}
          style={{ height: "300px" }}
          notMerge={false}
          lazyUpdate={true}
        />
      </>
    );
  };

  const renderTicketTypeChart = (data) => {
    let dates = _.filter(Object.keys(data), function (o) {
      return o !== "null";
    });
    if (dates.length == 0)
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          height="100%"
          padding="60px 35px"
        >
          <img src={require("assets/img/emptyChart.svg").default} />
          <Box color="#777777" fontSize={"14px"} fontWeight={500} mt="15px">
            {formatMessage({
              id: "sessionAnalysis.chart.sessionStatusDistribution.emptyStatus",
            })}
          </Box>
        </Box>
      );
    const firstDate = dates[0];

    if (currentRange == "all") {
      dates = getDatesInRange(firstDate, new Date());
    } else {
      dates = getDatesInRange(startDate, endDate);
    }
    const types = Array.from(
      new Set(
        Object.values(data).flatMap((dayData) =>
          Object.keys(dayData).filter((key) => key !== "total")
        )
      )
    ).sort();

    const series = types.map((type) => ({
      name: type,
      type: "bar",
      stack: "total",
      data: dates.map((date) => _.get(data, `[${date}][${type}]`, "")),
    }));
    // 輔助函数：判斷颜色是否為深色
    function isDarkColor(color) {
      const rgb = hexToRgb(color);
      const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
      return brightness < 150;
    }

    // 輔助函数：將十六進制颜色轉換為 RGB
    function hexToRgb(hex) {
      const bigint = parseInt(hex.replace("#", ""), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    }
    const option = {
      tooltip: {
        trigger: "axis",
        grid: {
          top: "0%",
          right: "0%",
          bottom: "0%",
          left: "0%",
          containLabel: true,
        },
        formatter: function (params) {
          if (params.length == 0) return;
          const date = params[0].axisValueLabel;
          let result = formatDate(date) + "<br/>";
          let total = 0;
          let list = "";

          params.forEach((param) => {
            if (param.value !== "") {
              total += param.value;
              const bgcolor = param.color;
              const textColor = isDarkColor(bgcolor) ? "#fff" : "#000";
              const markerSpan = `<span style="display:flex;alignItem:center;
            padding:2.5px 4px;border-radius:2.5px;background-color:${bgcolor}
              ;color:${textColor}">${_.upperFirst(param.seriesName)}</span>`;
              list += `<div style="color:#383538;font-weight:500;display:flex;align-items:center;justify-content:space-between;flex-wrap:nowrap;margin-top:8px">
             ${markerSpan} <span >${param.value}</span>
            </div>`;
            }
          });
          const totalString = `<div style="margin-top:8px;display:flex;align-items:center;justify-content:space-between;">
          <span>Total tickets</span> <span style="color:#383538;font-weight:500">${total}</span></div>`;
          return `<div style="width:209px;fontSize:12px;">${result}${totalString}${list}</div>`;
        },
      },
      color: chartType?.value == "ticketType" ? typeColors : channelColors,
      xAxis: {
        type: "category",
        data: dates,
      },
      yAxis: {
        type: "value",
      },
      series,
    };
    return (
      <Box sx={{ borderRight: "1px solid #ECECEC" }}>
        <ReactECharts
          option={option}
          style={{
            height: "300px",
            width: "114%",
            marginLeft: "-7%",
            marginBottom: "16px",
          }}
          notMerge={true}
          lazyUpdate={true}
        />
      </Box>
    );
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const params = { platform };
      if (startDate) params.startDate = startDate.toISOString();
      if (endDate) params.endDate = endDate.toISOString();
      if (isDemoSite) {
        setAnalytics(ticketMetrics.ticketMetrics);
      } else {
        const _analytics = await axios.get(
          apiUrl + `/api/ticket/analytics/${entityName}/ticket`,
          { params }
        );

        setAnalytics(_analytics.data.ticketMetrics);
      }
    } catch (error) {
      dispatch(alertServerError(error));
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, platform]);

  const renderDifference = ({ title, difference, fontSize = 12 }) => {
    if (currentRange == "all" || currentRange == "custom" || loading) return;
    if (difference == null) return <Box fontSize={fontSize}>-</Box>;
    if (difference == 0)
      return (
        <Box color="#d6d6d6" fontSize={fontSize}>
          0
        </Box>
      );
    const isPositive = difference > 0;
    const isReverse = title == "Avg. FRT" || title == "Avg. Resolve Time";
    const colorMap = { true: "#14C864", false: "#FC5555" };
    let valueDisplay = difference;
    if (_.includes(difference, "INFINITY")) {
      return (
        <Box fontSize={fontSize}>{difference.replace("INFINITY", "∞")}</Box>
      );
    } else {
      valueDisplay = parseFloat(difference * 100).toFixed(1);
      if (isNaN(difference)) valueDisplay = "-";
    }

    return (
      <Box
        className="endRow"
        style={{
          fontSize: fontSize,
          fontWeight: 500,
          color: colorMap[isReverse ? !isPositive : isPositive],
        }}
      >
        {isPositive ? (
          <ArrowCircleUpIcon style={{ fontSize: fontSize + 2 }} />
        ) : (
          <ArrowCircleDownIcon style={{ fontSize: fontSize + 2 }} />
        )}
        <Box sx={{ ml: "1px" }}>{valueDisplay}%</Box>
      </Box>
    );
  };
  const renderCardValue = ({
    title,
    description,
    key,
    isTime,
    extra,
    roundNumber,
    fullWidth,
    memo,
    largeValue = false,
  }) => {
    const value = _.get(analytics, key, "");
    const difference = _.get(analytics, `growthRate[${key.replace(".", "-")}]`);
    let valueDisplay = value || "-";
    if (isTime) valueDisplay = msToTime(value);
    if (roundNumber) {
      valueDisplay = value ? parseFloat(value).toFixed(roundNumber) : "-";
    }
    const content = (
      <StyledCard style={{ position: "relative" }}>
        <StyledSubtitle>
          <Box mb="10px" className="startRow">
            {title}
            {description && (
              <IconTooltip
                content={description}
                style={{ marginLeft: "4px" }}
              />
            )}
          </Box>
          <StyledMemo>{memo}</StyledMemo>
          <Box
            className="betweenRow"
            style={
              largeValue
                ? {
                    position: "absolute",
                    bottom: "30px",
                    left: "16px",
                    width: "80%",
                  }
                : {}
            }
          >
            <Box className="startRow">
              <StyledSecondaryValue
                style={{ marginRight: "4px", fontSize: largeValue ? 28 : 18 }}
              >
                {valueDisplay}
              </StyledSecondaryValue>
              {extra}
            </Box>
            <Box>
              {renderDifference({
                title,
                difference,
                fontSize: largeValue ? 18 : 12,
              })}
            </Box>
          </Box>
        </StyledSubtitle>
      </StyledCard>
    );
    return fullWidth ? (
      content
    ) : (
      <Grid item xs={6} sm={6} lg={4}>
        {content}
      </Grid>
    );
  };

  return (
    <div>
      <Grid container spacing={"12px"} alignItems="stretch">
        <Grid item xs={12} md={9}>
          <Grid container spacing={"12px"} alignItems="stretch">
            {renderCardValue({
              title: "New Tickets",
              key: "ticketCount.new",
              description:
                "The number of tickets that were created but have not received any reply during the selected time period.",
            })}
            {renderCardValue({
              title: "Ongoing Tickets",
              key: "ticketCount.ongoing",
              description:
                "The number of tickets that were created but remain unresolved during the selected time period.",
            })}
            {renderCardValue({
              title: "Done Tickets",
              key: "ticketCount.done",
              description:
                "The number of tickets that were resolved within the selected time period.",
            })}
            {renderCardValue({
              title: "Avg. FRT",
              key: "avgFrt",
              isTime: true,
              description:
                "The average time between the creation of a ticket and the first agent response, calculated for all incoming tickets.",
            })}
            {renderCardValue({
              title: "Avg. Resolve Time",
              key: "avgResolveTime",
              isTime: true,
              description:
                "The average time taken from ticket creation to its resolution, based on all resolved tickets.",
            })}
            {renderCardValue({
              title: "Avg. CSAT",
              key: "avgCsat",
              roundNumber: 1,
              extra: (
                <Box fontSize={"10px"} className="startRow">
                  <Box fontSize={"24px"} mr="4px">
                    😍
                  </Box>{" "}
                  (
                  {analytics?.csatRatingPercent
                    ? parseFloat(analytics.csatRatingPercent).toFixed(0)
                    : "-"}
                  % Rating)
                </Box>
              ),
              description:
                "The average customer satisfaction rating for all resolved tickets that received a rating.",
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          {renderCardValue({
            title: "Incoming Tickets",
            key: "ticketCount.newlyCreated",
            largeValue: true,
            memo:
              startDate == "" || endDate == ""
                ? ""
                : formatDate(startDate) + " ~ " + formatDate(endDate),
            fullWidth: true,
            description:
              "The total number of tickets that were created during the selected time period.",
          })}
        </Grid>
      </Grid>
      <StyledCard style={{ marginTop: "12px", position: "relative" }}>
        <Loading open={loading} fullScreen={false} />
        <Grid container>
          <Grid item xs={12} md={6}>
            <StyledSubtitle>
              {formatMessage({
                id: "ticketDashboard.agentTicketChart.title",
              })}
              <StyledMemo className="startRow" style={{ marginTop: "8px" }}>
                <i className="meta-crm-icon-ic_info font-size-14 mgr4" />
                {formatMessage({
                  id: "ticketDashboard.avgResolvedTime.tooltip",
                })}
              </StyledMemo>
            </StyledSubtitle>
          </Grid>
          <Grid item xs={12} md={6} className="endRow">
            <Box mr={"8px"}>
              {formatMessage({
                id: "ticketDashboard.trackSetting.append.checkbox",
              })}
            </Box>
            <Box style={{ width: "180px" }}>
              <AutoCompleteDropdown
                size={SIZE.MEDIUM}
                options={[
                  { label: "Ticket Source", value: "ticketSource" },
                  { label: "Ticket Type", value: "ticketType" },
                ]}
                value={chartType}
                onChange={(e) => {
                  setChartType(e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            {renderTicketTypeChart(
              _.get(
                analytics,
                (chartType?.value == "ticketType"
                  ? "ticketTypeStats"
                  : "ticketSourceStats") + ".distributionByDate",
                {}
              )
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {renderPieChart(
              _.toPairs(
                _.get(
                  analytics,
                  (chartType?.value == "ticketType"
                    ? "ticketTypeStats"
                    : "ticketSourceStats") + ".distribution",
                  {}
                )
              )
                .map((value, index) => {
                  return {
                    value: value[1],
                    name: _.startCase(value[0]),
                  };
                })
                .sort((a, b) => a.name.localeCompare(b.name))
            )}
          </Grid>
        </Grid>
      </StyledCard>
    </div>
  );
}
