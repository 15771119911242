import React, { useState } from "react";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useSelector } from "react-redux";
import { selectTrialEntityName } from "features/metadesk/redux/trial/trial.selector";
import { useOnboardingState } from "../OnboardingProvider";

export default function TrialStep4({ stepData, handleNextStep }) {
  const navigate = useNavigate();
  const { entityConfig } = useFetchEntityConfig();
  const { nextStep: cleanLeftBg } = useOnboardingState();

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FAFAFD",
        pt: "240px",
      }}
    >
      <Box
        maxWidth="400px"
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          sx={{
            height: "120px",
            width: "120px",
            backgroundColor: "#F7F1FE",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "48px",
            mb: "24px",
          }}
        >
          🎉
        </Box>
        <Box color="#383538" fontSize="24px" fontWeight={700} mb="16px">
          Congratulations!
        </Box>
        <Box color="#777" fontSize="16px" mb="32px">
          Your workspace{" "}
          <span style={{ fontWeight: 700, color: "#7B61FF" }}>
            {stepData.workSpaceName}
          </span>{" "}
          has been created successfully!
        </Box>

        {/* 按鈕：根據表單驗證結果啟用或禁用 */}
        <Button
          onClick={() => {
            handleNextStep();
            cleanLeftBg();
          }}
          endIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3.33341 8.66681H10.7801L7.52675 11.9201C7.26675 12.1801 7.26675 12.6068 7.52675 12.8668C7.78675 13.1268 8.20675 13.1268 8.46675 12.8668L12.8601 8.47348C13.1201 8.21348 13.1201 7.79348 12.8601 7.53348L8.47341 3.13348C8.21341 2.87348 7.79341 2.87348 7.53341 3.13348C7.27341 3.39348 7.27341 3.81348 7.53341 4.07348L10.7801 7.33348H3.33341C2.96675 7.33348 2.66675 7.63348 2.66675 8.00014C2.66675 8.36681 2.96675 8.66681 3.33341 8.66681Z"
                fill="white"
              />
            </svg>
          }
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
}
