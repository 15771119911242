export const analysisColors = {
  small: ["#FECA55", "#F6A06D", "#E27D86", "#AC557A"],
  middle: [
    "#F9E851",
    "#FECA55",
    "#F6A06D",
    "#E27D86",
    "#AC557A",
    "#8D4C7D",
    "#6B406E",
    "#40324F",
  ],
  large: [
    "#FFF2B1",
    "#FFED65",
    "#FDD751",
    "#FEC557",
    "#F6A06D",
    "#EC8B7C",
    "#E47E85",
    "#BD667D",
    "#AC557A",
    "#8D4C7D",
    "#6B406E",
    "#40324F",
  ],
};

export const marketingColors = {
  small: ["#F6C0D9", "#F36DA5", "#A95699", "#653F8E"],
  middle: [
    "#FDFDCC",
    "#FED0A0",
    "#FA9BB8",
    "#F36DA5",
    "#C55F94",
    "#9C519A",
    "#744196",
    "#333336",
  ],
  large: [
    "#FDFDCC",
    "#FDEAB9",
    "#FED7A6",
    "#FEC395",
    "#F6A5C1",
    "#F381A8",
    "#F06695",
    "#ED4B82",
    "#E04980",
    "#CD6191",
    "#BC5C96",
    "#A95699",
    "#99519B",
    "#88499A",
    "#784397",
    "#653F8E",
    "#54417B",
    "#333336",
  ],
};

export const customerColors = {
  small: ["#92DA9D", "#5299A5", "#5E7DA4", "#6B5B98"],
  middle: [
    "#DAEE85",
    "#77D185",
    "#4CB49F",
    "#5299A5",
    "#5E7DA4",
    "#6B5B98",
    "#6D4485",
    "#693476",
  ],
  large: [
    "#FFF497",
    "#E3E947",
    "#C6E554",
    "#AAE066",
    "#8FD977",
    "#77D185",
    "#63C991",
    "#54BF99",
    "#4DB59F",
    "#4CABA2",
    "#4FA1A4",
    "#5397A5",
    "#578DA5",
    "#5C82A4",
    "#6178A3",
    "#666CA0",
    "#6A5F9A",
    "#6D5192",
    "#6D4485",
    "#693476",
  ],
};

export const serviceColors = {
  small: ["#91D0CE", "#56B9D2", "#3F97C2", "#2D5E9E"],
  middle: [
    "#DCECC9",
    "#91D0CE",
    "#56B9D2",
    "#3F97C2",
    "#3371AA",
    "#274B93",
    "#1B277C",
    "#131A55",
  ],
  large: [
    "#DCECC9",
    "#B3DDCC",
    "#8ACDCE",
    "#62BED2",
    "#46AACE",
    "#3D91BE",
    "#3577AE",
    "#2D5E9E",
    "#24448E",
    "#1C2B7F",
    "#162065",
    "#11174B",
  ],
};
