import IconIcDiscordSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscordSquare";
import IconIcGitHubSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcGitHubSquare";
import IconIcMailchimp from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMailchimp";
import IconIcTracking from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcTracking";
import IconIcWidgetIcon from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcWidgetIcon";
import IconIcTelegramSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcTelegramSquare";
import IconIcSlackSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcSlackSquare";
import { isVersionLessThan } from "features/helpers/utils";
import {
  selectAttributionVersionCanUpdate,
  selectMarketingModule,
  selectServiceModule,
  selectWidgetModule,
  selectWidgetVersionCanUpdate,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";
import { openWindow } from "features/metadesk/utils";
import useNavigateEntity from "hooks/useNavigateEntity";
import _, { get } from "lodash";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";

export default function useGetIntegrationArr({ combinedWidgetConfig = false }) {
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();
  const { fetchWidgetConfig, widgetConfig, fetchWidgetConfigPending } =
    useFetchWidgetConfig();
  const hasService = get(entityConfig, "modules.service");
  const hasMarketing = get(entityConfig, "modules.marketing");
  const widgetVersion = useSelector(selectWidgetVersionCanUpdate);
  const trackingVersion = useSelector(selectAttributionVersionCanUpdate);
  const widgetModule = useSelector(selectWidgetModule);
  const serviceModule = useSelector(selectServiceModule);
  const marketingModule = useSelector(selectMarketingModule);

  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
    fetchWidgetConfig({ entityName });
  }, []);

  // 目前安裝的widget版本
  const {
    version: connectedWidgetVersion,
    latestVersion: widgetLatestVersion,
    canUpdate: widgetCanUpdate,
  } = widgetVersion || {};
  const {
    version: connectedAttributionVersion,
    latestVersion: attributionLatestVersion,
    canUpdate: attributionCanUpdate,
  } = trackingVersion || {};

  function getIntegrationStatus(link, canUpdate = false) {
    if (canUpdate) return "Update Available";
    if (
      _.get(serviceModule, `${link}.connected`) ||
      _.get(marketingModule, `${link}.connected`) ||
      (link === "widgetSupport" && _.get(widgetModule, `connected`))
    ) {
      return "Active";
    }
    if (
      _.get(serviceModule, `${link}.connecting`) ||
      _.get(marketingModule, `${link}.connecting`) ||
      (link === "widgetSupport" && _.get(widgetModule, `connecting`))
    ) {
      return "Setup Incomplete";
    }
    return "not-connected";
  }

  function getIntegrationEnabled(link) {
    if (
      _.get(serviceModule, `${link}.enabled`) ||
      _.get(marketingModule, `${link}.enabled`) ||
      (link === "widgetSupport" && _.get(widgetModule, `enabled`))
    ) {
      return true;
    }
    return false;
  }

  const integrationWayArr = [
    // supportCenter
    {
      auth: hasService && getIntegrationEnabled("webSupport"),
      icon: (
        <img
          src={require("assets/img/supportCenterIntegration.png")}
          width={40}
          height={40}
        />
      ),
      title: "Support Web Page",
      description:
        "Add a dedicated web page for users to create tickets and contact you easily",
      link: "webSupport",
      tags: ["services"],
      setting: () => {
        navigateEntity("/settings/general/integration/update/support-center");
      },
      updatedAt: get(entityConfig, "serviceModule.webSupport.updatedAt"),
      status: getIntegrationStatus("webSupport"),
    },
    // discord
    {
      auth: hasService && getIntegrationEnabled("discord"),
      icon: <IconIcDiscordSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.discordBot.name" }),
      description: formatMessage({
        id: "integration.discordBot.description",
      }),
      link: "discord",
      tags: ["services"],
      own: true,
      setting: () => {
        navigateEntity("/settings/general/integration/update/discord");
      },
      extraAction: () => {
        const url = get(entityConfig, "serviceModule.discord.url");
        openWindow(url, true);
      },
      updatedAt: get(entityConfig, "serviceModule.discord.updatedAt"),
      status: getIntegrationStatus("discord"),
    },
    // Telegram
    {
      auth: hasService && getIntegrationEnabled("telegram"),
      icon: <IconIcTelegramSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.telegramBot.name" }),
      description: formatMessage({
        id: "integration.telegramBot.description",
      }),
      link: "telegram",
      tags: ["services"],
      own: true,
      setting: () => {
        navigateEntity("/settings/general/integration/update/telegram");
      },
      extraAction: () => {
        navigateEntity("/settings/customization/telegramSetting");
      },
      updatedAt: get(entityConfig, "serviceModule.telegram.updatedAt"), // 添加 updatedAt
      status: getIntegrationStatus("telegram"),
    },
    // widget
    {
      // hasService 或 hasMarketing 都可以使用widget
      auth:
        (hasService || hasMarketing) && getIntegrationEnabled("widgetSupport"),
      icon: <IconIcWidgetIcon width={40} height={40} />,
      title: formatMessage({ id: "integration.widget.name" }),
      description: formatMessage({ id: "integration.widget.description" }),
      link: "widgetSupport",
      own: true,
      tags: ["marketing", "services", "notification"],
      setting: () => {
        navigateEntity("/settings/general/integration/update/widget");
      },
      addLink: () => navigateEntity("/settings/general/integration/add/widget"),
      currentVersion: connectedWidgetVersion,
      latestVersion: widgetLatestVersion,
      canUpdate: widgetCanUpdate,
      showTooltip:
        connectedWidgetVersion &&
        connectedWidgetVersion !== "0.0.0" &&
        isVersionLessThan(connectedWidgetVersion, "1.1.0"),
      tooltipText: formatMessage({ id: "integration.apikey.version.hint" }),
      updatedAt: get(entityConfig, "serviceModule.widgetSupport.updatedAt"), // 添加 updatedAt
      status: getIntegrationStatus("widgetSupport"),
    },
    // push
    {
      auth: hasMarketing && getIntegrationEnabled("push"),
      icon: (
        <img
          src={require("assets/img/push.svg").default}
          style={{ width: 40, height: 40 }}
        />
      ),
      title: formatMessage({ id: "integration.push.title" }),
      description: formatMessage({ id: "integration.push.description" }),
      tags: ["marketing", "broadcast"],
      link: "push",
      otherLinkToGo: `/${entityName}/settings/general/integration/update/push`,
      setting: () => {
        navigateEntity("/settings/general/integration/update/push");
      },
      updatedAt: get(entityConfig, "marketingModule.push.updatedAt"),
      status: getIntegrationStatus("push"),
    },
    // xmtp
    {
      auth: hasMarketing && getIntegrationEnabled("xmtp"),
      icon: (
        <img
          src={require("assets/img/xmtp.svg").default}
          style={{ width: 40, height: 40 }}
        />
      ),
      title: formatMessage({ id: "integration.xmtp.name" }),
      description: formatMessage({ id: "integration.xmtp.description" }),
      tags: ["marketing", "broadcast"],
      link: "xmtp",
      setting: () => {
        navigateEntity("/settings/general/integration/update/xmtp");
      },
      updatedAt: get(entityConfig, "marketingModule.xmtp.updatedAt"),
      status: getIntegrationStatus("xmtp"),
    },
    // githubIssue
    {
      auth: hasService && getIntegrationEnabled("github"),
      icon: <IconIcGitHubSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.githubIssue.name" }),
      description: formatMessage({
        id: "integration.githubIssue.description",
      }),
      link: "github",
      tags: ["Project Management"],
      setting: "",
      extraAction: () => {
        const url = get(entityConfig, "serviceModule.github.url");
        openWindow(url);
      },
      updatedAt: get(entityConfig, "serviceModule.github.updatedAt"), // 添加 updatedAt
      status: getIntegrationStatus("github"),
    },
    // mailchimp
    {
      auth: hasService && getIntegrationEnabled("mailchimp"),
      icon: <IconIcMailchimp width={40} height={40} />,
      title: formatMessage({ id: "integration.mailchimp.name" }),
      description: formatMessage({ id: "integration.mailchimp.description" }),
      link: "mailchimp",
      tags: ["marketing"],
      setting: () => {
        navigateEntity("/settings/general/integration/update/mailchimp");
      },
      updatedAt: get(entityConfig, "serviceModule.mailchimp.updatedAt"), // 添加 updatedAt
      status: getIntegrationStatus("mailchimp"),
    },
    // trackingTag
    {
      auth: hasMarketing && getIntegrationEnabled("attribution"),
      icon: <IconIcTracking width={40} height={40} />,
      title: formatMessage({ id: "integration.trackingTag.name" }),
      description: formatMessage({
        id: "integration.trackingTag.description",
      }),
      link: "attribution",
      tags: ["marketing", "analytics"],
      own: true,
      setting: () => {
        navigateEntity("/settings/general/integration/update/attribution");
      },
      currentVersion: connectedAttributionVersion,
      latestVersion: attributionLatestVersion,
      canUpdate: attributionCanUpdate,
      showTooltip:
        connectedAttributionVersion &&
        isVersionLessThan(connectedAttributionVersion, "1.1.0"),
      tooltipText: formatMessage({ id: "integration.apikey.version.hint" }),
      updatedAt: get(entityConfig, "marketingModule.attribution.updatedAt"),
      status: getIntegrationStatus("attribution", attributionCanUpdate),
    },
    // Discord Notification
    {
      auth: hasService,
      icon: (
        <img
          src={require("assets/img/discordNotification.png")}
          style={{ width: 45, height: 46 }}
        />
      ),
      title: formatMessage({ id: "integration.discord.name" }),
      description: formatMessage({ id: "integration.discord.description" }),
      link: "discordWebhook",
      tags: ["notification"],
      setting: () => {
        navigateEntity("/settings/general/integration/update/discordWebhook");
      },
      status: getIntegrationStatus("discordWebhook"),
    },
    {
      auth: hasService,
      icon: <IconIcSlackSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.slack.name" }),
      description: formatMessage({ id: "integration.slack.description" }),
      link: "slack",
      tags: ["notification"],
      extraAction: () => {
        const url = _.get(entityConfig, "serviceModule.slack.url");
        openWindow(url, true);
      },
      setting: () => {
        navigateEntity("/settings/general/integration/update/slack");
      },
      status: getIntegrationStatus("slack"),
    },
  ];

  // 將 widgetConfig 中的每個項目格式化為 integration 的標準結構
  const widgetIntegrationArr = widgetConfig?.map((widgetItem) => {
    const canUpdate = isVersionLessThan(
      widgetItem.jsVersion,
      widgetModule.latestVersion
    );
    return {
      auth: true, // 假設所有 widget 都是有效的
      icon: <IconIcWidgetIcon width={40} height={40} />,
      title: "MetaCRM Widget",
      description: `Label: ${widgetItem.name}`,
      link: `widgetSupport`,
      tags: ["widget"],
      setting: (incomplete = false) => {
        // if (incomplete)
        //   return navigateEntity(
        //     `/settings/general/integration/add/widget/${widgetItem._id}`
        //   );
        return navigateEntity(
          `/settings/general/integration/update/widget/${widgetItem._id}`
        );
      },
      currentVersion: widgetItem.jsVersion,
      latestVersion: widgetModule.latestVersion,
      canUpdate,
      updatedAt: widgetItem.created,
      status: canUpdate
        ? "Update Available"
        : widgetItem.connected
        ? "Active"
        : "Setup Incomplete",
    };
  });

  const resultArr = useMemo(() => {
    let resultArr = integrationWayArr;
    if (combinedWidgetConfig) {
      resultArr = integrationWayArr.filter(
        (item) => item?.link !== "widgetSupport"
      );
    }

    if (!widgetConfig) return resultArr;
    return combinedWidgetConfig
      ? resultArr.concat(widgetIntegrationArr)
      : resultArr;
  }, [integrationWayArr, widgetConfig, fetchWidgetConfigPending]);

  return resultArr;
}
