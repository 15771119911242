import React from "react";
import { StyledFormTitle } from "../../IntegrationWidgetSetting.styles";
import WidgetTicketType from "features/metadesk/settings/integrations/widget/components/WidgetTicketType";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import StepContent from "../StepContent";
import useGetPagePermission from "hooks/useGetPagePermission";
import AssignTicketType from "features/metadesk/settings/Customization/AssignTicketType/AssignTicketType";

export default function TicketType({
  widgetId,
  ticketList,
  chooseTicketType,
  setChooseTicketType,
}) {
  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");

  const ticketBlock = {
    title: "Choose Ticket Types for the widget",
    describe: "Choose which ticket types users can submit through the widget.",
    content: (
      <>
        <StyledFormTitle item xs={12} md={4}>
          Ticket Type
        </StyledFormTitle>
        <Grid position={"relative"} item xs={12} md={8}>
          <AssignTicketType
            serviceName={"widget"}
            widgetId={widgetId}
            canEdit={canEdit}
            ticketList={ticketList}
            chooseTicketType={chooseTicketType}
            setChooseTicketType={setChooseTicketType}
          />
        </Grid>
      </>
    ),
  };
  return (
    <StyledWhiteContainer style={{ marginBottom: "12px" }}>
      <StepContent settingStep={ticketBlock} />
    </StyledWhiteContainer>
  );
}
