import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RADIANT_FETCH_BEGIN,
  RADIANT_FETCH_SUCCESS,
  RADIANT_FETCH_FAILURE,
} from "./constants";
import axios from "axios";
import { apiUrl } from "features/configure";

export function fetchRadiantDetails(input) {
  return async (dispatch, getState) => {
    dispatch({
      type: RADIANT_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let pools = await axios.get(
          `${apiUrl}/api/user/pool?address=` +
            input.selectedUserAddress +
            "&entityName=" +
            input.entityName
        );
        dispatch({
          type: RADIANT_FETCH_SUCCESS,
          data: pools.data,
        });
      } catch (err) {
        dispatch({
          type: RADIANT_FETCH_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useFetchRadiantDetails() {
  const dispatch = useDispatch();

  const {
    radiantDetails,
    fetchRadiantDetailsPending,
    fetchRadiantDetailsDone,
  } = useSelector(
    (state) => ({
      radiantDetails: state.metadesk.radiantDetails,
      fetchRadiantDetailsPending: state.metadesk.fetchRadiantDetailsPending,
      fetchRadiantDetailsDone: state.metadesk.fetchRadiantDetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRadiantDetails(data));
    },
    [dispatch]
  );

  return {
    radiantDetails,
    fetchRadiantDetails: boundAction,
    fetchRadiantDetailsDone,
    fetchRadiantDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RADIANT_FETCH_BEGIN:
      return {
        ...state,
        fetchRadiantDetailsPending: true,
      };

    case RADIANT_FETCH_SUCCESS:
      return {
        ...state,
        radiantDetails: action.data,
        fetchRadiantDetailsDone: true,
        fetchRadiantDetailsPending: false,
      };

    case RADIANT_FETCH_FAILURE:
      return {
        ...state,
        fetchRadiantDetailsPending: false,
      };

    default:
      return state;
  }
}
