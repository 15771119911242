import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useNavigate } from "react-router-dom";
import { alertInfo, alertServerError } from "features/helpers/utils";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "axios";
import { apiUrl } from "features/configure";
import { MetaCRMTag } from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import IntegrationSettings from "./IntegrationSettings";
import { useIntl } from "react-intl";
import BasicModal from "features/metadesk/chat/BasicModal";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import { StyledAddTicketTypeButton } from "../Customization/OnChainDataSource/OnChainDataSource.styles";
import styled from "@emotion/styled/macro";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { Loading } from "features/common";

export const StyledWebhookContainer = styled("div")(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.customColors.grey[300]}`,
  padding: "12px 12px",
  flex: 1,
  ":hover": { border: `1px solid ${theme.customColors.purple[500]}` },
}));

const formDataInit = {
  url: "",
  events: [],
};

const IntegrationDiscordWebhook = () => {
  const [entityName, isCustomDomain] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const { fetchEntityConfig } = useFetchEntityConfig();
  const [hoverEl, setHoverEl] = useState(null);
  const [webhookEvents, setWebhookEvents] = useState([]);
  const [addData, setAddData] = useState(formDataInit);
  const confirm = useConfirm();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors, isValid, isDirty },
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });
  const { replace } = useFieldArray({
    control: control,
    name: "events",
  });

  const isValidToSave = () => {
    if (addData._id && !isDirty) return false;
    return isValid && _.get(addData, "events.length", 0) > 0;
  };

  const [webhookData, setWebhookData] = useState([]);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const actionMapping = {
    ticketcreated: formatMessage({
      id: "discordNotificationSettings.action.newTicket",
    }),
    messagereceived: formatMessage({
      id: "discordNotificationSettings.action.newMessage",
    }),
    ticketclosed: formatMessage({
      id: "discordNotificationSettings.action.ticketDone",
    }),
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const eventResult = await axios.get(
        apiUrl + `/api/webhook/${entityName}/events`
      );
      setWebhookEvents(eventResult.data);
      const dataResult = await axios.get(apiUrl + `/api/webhook/${entityName}`);
      setWebhookData(dataResult.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  const handleMouseEnter = (webhookId) => {
    setHoverEl(webhookId);
  };

  const handleMouseLeave = () => {
    setHoverEl(null);
  };

  const handleDelete = (webhook) => {
    confirm({
      title: "Warning",
      content: (
        <div style={{ width: 250, marginBottom: 20 }}>
          Are you sure to delete?
        </div>
      ),
      confirmText: formatMessage({
        id: "Delete",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(async () => {
        try {
          await axios.delete(
            apiUrl + `/api/webhook/${entityName}/${webhook._id}`
          );
          setWebhookData(_.filter(webhookData, (o) => o._id !== webhook._id));
          fetchEntityConfig({ entityName });
          dispatch(alertInfo("Delete success!"));
        } catch (err) {
          dispatch(alertServerError(err));
        }
      })
      .catch(() => {});
  };

  const renderWebhookItem = (webhook) => {
    return (
      <div
        className="betweenRow"
        style={{ marginBottom: 12 }}
        onMouseEnter={() => handleMouseEnter(webhook._id)}
        onMouseLeave={handleMouseLeave}
      >
        <StyledWebhookContainer key={webhook._id} className="betweenRow">
          <Grid container alignItems={"center"}>
            <Grid item xs={12} md={2} className="label" sx={{ mb: "11px" }}>
              {formatMessage({
                id: "discordNotificationSettings.webhook.title",
              })}
              :
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              sx={{ mb: "11px", wordBreak: "break-all" }}
            >
              {webhook.url}
            </Grid>
            <Grid item xs={12} md={2} className="label">
              {formatMessage({
                id: "discordNotificationSettings.action.title",
              })}
              :
            </Grid>
            <Grid item xs={12} md={10} className="startRow">
              {_.map(webhook.events, (e, i) => {
                const keyName = e.model + e.action;
                return (
                  <MetaCRMTag
                    key={i}
                    option={{ label: actionMapping[keyName] }}
                    style={{
                      marginRight: 10,
                      borderRadius: 12,
                      padding: "2px 6px",
                      color: theme.customColors.grey[600],
                    }}
                  />
                );
              })}
            </Grid>
          </Grid>
          {hoverEl == webhook._id ? (
            <IconButton
              size={18}
              padding={12}
              onClick={() => {
                const eventsIds = _.map(webhook.events, "_id");
                const copyData = {
                  ...webhook,
                  events: eventsIds,
                };
                setAddData(copyData);
                reset(copyData);
                setOpenAdd(true);
              }}
            >
              <i className="meta-crm-icon-ic_edit font-size-18" />
            </IconButton>
          ) : (
            <div style={{ width: 43 }} />
          )}
        </StyledWebhookContainer>
        <IconButton
          size={18}
          padding={12}
          onClick={() => {
            handleDelete(webhook);
          }}
        >
          <i className="meta-crm-icon-ic_trash font-size-18" />
        </IconButton>
      </div>
    );
  };

  const onSave = async () => {
    try {
      setLoading(true);
      clearErrors();
      const { data } = await axios.post(
        apiUrl + `/api/webhook/${entityName}`,
        addData
      );
      data.events = _.filter(data.events, { enabled: true });
      setAddData(formDataInit);

      reset();
      const updateIndex = _.findIndex(webhookData, { _id: data._id });
      const isUpdate = updateIndex >= 0;
      if (isUpdate) {
        const newData = [...webhookData];
        newData[updateIndex] = data;
        setWebhookData(newData);
      } else {
        setWebhookData([...webhookData, data]);
      }

      setOpenAdd(false);
      fetchEntityConfig({ entityName });
      dispatch(alertInfo(isUpdate ? "Update success!" : "Add success!"));
    } catch (err) {
      setError("url", {
        message: formatMessage({
          id: "discordNotificationSettings.webhook.errorCode",
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const renderAddNewWebhookModal = () => {
    return (
      <BasicModal
        open={openAdd}
        mode="action"
        textAlign="left"
        buttonWidth={"180px"}
        onClose={() => {
          setOpenAdd(false);
          setAddData(formDataInit);
          reset();
          clearErrors();
        }}
        confirmDisabled={!isValidToSave() || loading}
        modalTitle={formatMessage({
          id: "integration.discordNotificationSettings.title",
        })}
        modalContent={
          <div>
            <Box className="subTitle" mb="8px" mt="20px">
              {formatMessage({
                id: "discordNotificationSettings.addNew.title",
              })}
            </Box>
            <InputField
              placeholder={formatMessage({
                id: "discordNotificationSettings.webhook.placeholder",
              })}
              name="url"
              control={control}
              onChange={(e) => {
                setAddData({ ...addData, url: e.target.value });
                setValue("url", e.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              value={_.get(addData, "url", "")}
              errors={_.get(errors, "url.message")}
              rules={{
                required: {
                  value: true,
                  message: "Webhook URL is required",
                },
                pattern: {
                  value:
                    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                  message: "Please enter a valid URL",
                },
              }}
            />
            <div className="hr" />
            <Box className="subTitle" mb="8px">
              {formatMessage({
                id: "discordNotificationSettings.action.title",
              })}
            </Box>
            <Box className="description" mb="12px">
              {formatMessage({
                id: "discordNotificationSettings.action.description",
              })}
            </Box>
            {_.map(webhookEvents, (actionEvent) => {
              if (!actionEvent) return;
              const actionKey = actionEvent.model + actionEvent.action;
              return (
                <Box mb="12px" key={actionKey}>
                  <Checkbox
                    control={control}
                    name="events"
                    errors={_.get(errors, "events.message")}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      let newActions = [..._.get(addData, "events", [])];
                      if (checked) {
                        newActions.push(actionEvent._id);
                      } else {
                        newActions = _.filter(
                          newActions,
                          (item) => item !== actionEvent._id
                        );
                      }
                      setAddData({ ...addData, events: newActions });
                      replace(newActions);
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: "Webhook Events is required",
                      },
                    }}
                    label={actionMapping[actionKey]}
                    checked={_.includes(
                      _.get(addData, "events", []),
                      actionEvent._id
                    )}
                    mr={5}
                  />
                </Box>
              );
            })}

            <Box className="hr" mb="18px" />
          </div>
        }
        confirmText={
          loading
            ? "Saving..."
            : formatMessage({
                id: "global.btn.sendTest",
              })
        }
        onHandleConfirm={handleSubmit(onSave)}
      />
    );
  };
  const canNotAdd = webhookData.length > 2;

  return (
    <>
      {renderAddNewWebhookModal()}
      <IntegrationSettings
        maxWidth={622}
        title={formatMessage({
          id: "integration.discordNotificationSettings.title",
        })}
        description={formatMessage({
          id: "integration.discordNotificationSettings.description",
        })}
        content={
          <div>
            <Loading open={loading} fullScreen={false} />
            <div style={{ maxWidth: 800 }}>
              <Box className="description startRow" mb={"12px"}>
                <div className="mgr4">
                  {formatMessage({
                    id: "discordNotificationSettings.addNew.description",
                  })}
                </div>

                <a
                  href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
                  target="_blank"
                  className="link"
                >
                  {formatMessage({
                    id: "discordNotificationSettings.webhook.tutorial",
                  })}
                </a>
              </Box>
              {_.map(webhookData, (webhook) => renderWebhookItem(webhook))}
              <StyledAddTicketTypeButton
                onClick={() => {
                  if (canNotAdd) return;
                  setOpenAdd(true);
                  setAddData(formDataInit);
                  reset(formDataInit);
                }}
                style={{
                  padding: 12,
                  borderRadius: 8,
                  height: 40,
                  marginRight: 40,
                  cursor: canNotAdd ? "not-allowed" : "pointer",
                  color: canNotAdd
                    ? theme.customColors.grey[500]
                    : theme.customColors.purple[500],
                }}
              >
                <i
                  className="meta-crm-icon-ic_add font-size-16"
                  style={{ marginRight: 12 }}
                />
                {canNotAdd
                  ? formatMessage({
                      id: "discordNotificationSettings.hint.unableToAdd",
                    })
                  : formatMessage({
                      id: "discordNotificationSettings.btn.addNew",
                    })}
              </StyledAddTicketTypeButton>
            </div>
          </div>
        }
      />
    </>
  );
};

export default IntegrationDiscordWebhook;
