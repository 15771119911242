import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import _ from "lodash";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Modal from "@mui/material/Modal";
import { useOutletContext } from "react-router-dom";
import MultipleValueTag from "features/metadesk/settings/Macros/Tag/MultipleValueTag";
import { get, isEmpty } from "lodash-es";

import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  InputField,
  SIZE as InputSize,
} from "@metacrm/metacrm-material-ui/dist/InputField";

import {
  fetchMacroListAsyncAction,
  fetchCategoriesAsyncAction,
  fetchTagsAsyncAction,
} from "features/metadesk/redux/macro/macro.action";
import {
  selectMacroList,
  selectMacroCategories,
  selectMacroTags,
} from "features/metadesk/redux/macro/macro.selector";
import { parseToOptionFormatWithAll } from "features/metadesk/settings/Macros/CreateMacroModal/dropdown.utils";

import {
  StyledMacroModalContentContainer,
  StyledCloseBtn,
  StyledMacroModalContentInsideContainer,
  StyledMacroListContainer,
  StyledClearButton,
  StyledTagInputPrefix,
  StyledAutoComplete,
} from "./MacroModal.styles";
import SingleQuestion from "./SingleQuestion";
import { useIntl } from "react-intl";

const defaultFormFields = {
  searchValue: "",
  categoryValue: { label: "All", value: "" },
  tagValue: [{ label: "All", isAllOption: true, value: "" }],
};

const MacroModal = ({
  channel,
  open,
  onClose,
  onSelectMacro,
  onLinkifyOptions,
}) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { searchValue, categoryValue, tagValue } = formFields;
  const [entityName, isCustomDomain] = useOutletContext();
  const dispatch = useDispatch();
  const macroList = useSelector(selectMacroList);
  const categoryOptions = useSelector(selectMacroCategories);
  const tagOptions = useSelector(selectMacroTags);
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(fetchCategoriesAsyncAction({ entityName: entityName }));
    dispatch(fetchTagsAsyncAction({ entityName: entityName }));
  }, []);

  const handleFetchMacroList = () => {
    const categoryValueForSend = categoryValue.value;
    const tagsValueForSend = tagValue.map((item) => item.value);
    dispatch(
      fetchMacroListAsyncAction({
        channel,
        entityName: entityName,
        category: categoryValueForSend,
        search: searchValue,
        tags: tagsValueForSend,
      })
    );
  };

  useEffect(() => {
    handleFetchMacroList();
  }, [categoryValue, searchValue, tagValue]);

  const theme = useTheme();

  const handleAllOptions = (newValue) => {
    const isAllOptionInTagValue = _.isArray(tagValue)
      ? tagValue.find(({ isAllOption }) => isAllOption)
      : tagValue;

    const filterNewValue = isAllOptionInTagValue
      ? // eslint-disable-next-line no-prototype-builtins
        newValue.filter((item) => !item.hasOwnProperty("isAllOption"))
      : // eslint-disable-next-line no-prototype-builtins
        newValue.filter(
          (item) => item.hasOwnProperty("isAllOption") || item.isAllOption
        );

    setFormFields({ ...formFields, tagValue: filterNewValue });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "tagValue") {
      const isAllOptionInNewValue = value.find(
        ({ isAllOption }) => isAllOption
      );

      if (isAllOptionInNewValue) {
        handleAllOptions(value);
        return;
      }
    }

    setFormFields({ ...formFields, [name]: value });
  };

  const handleChangeDropDown = (event, newValue, name) => {
    const e = { target: { name: name, value: newValue } };
    handleChange(e);
  };

  const handleClear = () => {
    setFormFields(defaultFormFields);
  };

  const renderTagAnswer = (selectedValue) => {
    return (
      <>
        {selectedValue.map((value, index) => (
          <MultipleValueTag
            notDelete
            key={index}
            option={value}
            label={_.get(value, "label")}
          />
        ))}
      </>
    );
  };

  const renderCategoryAnswer = (selectedValue) => {
    return (
      <>
        {selectedValue.map((value, index) => (
          <MultipleValueTag
            notDelete
            key={index}
            option={value}
            label={_.get(value, "label")}
          />
        ))}
      </>
    );
  };

  const renderDropdownInput = (params, name) => {
    // 獲取原始的 startAdornment
    const originalStartAdornment = params.InputProps.startAdornment;

    // 定義一個預設的標籤
    const defaultTag = <StyledTagInputPrefix>{name}:</StyledTagInputPrefix>;

    // 組合預設標籤和任何其他的 tags
    const combinedAdornment = (
      <>
        {defaultTag}
        {originalStartAdornment}
      </>
    );

    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: combinedAdornment,
        }}
      />
    );
  };

  return (
    <Modal open={open} className={"modal"} onClose={onClose}>
      <StyledMacroModalContentContainer>
        <StyledCloseBtn
          size={24}
          color={theme.customColors.grey[700]}
          onClick={onClose}
        >
          <i className="meta-crm-icon-ic_cancel" />
        </StyledCloseBtn>

        <StyledMacroModalContentInsideContainer>
          <Box
            fontSize="18px"
            fontWeight="700"
            mb="12px"
            color={theme.customColors.grey[800]}
          >
            {formatMessage({ id: "global.header.macro" })}
          </Box>
          <Box ml="auto">
            <StyledClearButton
              sx={{ marginLeft: "auto", padding: 0 }}
              variant={VARIANT.TEXT}
              onClick={handleClear}
            >
              {formatMessage({ id: "global.filter.clean" })}
            </StyledClearButton>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputField
                name="searchValue"
                onChange={(e) => handleChange(e)}
                onClean={() =>
                  setFormFields({ ...formFields, searchValue: "" })
                }
                value={searchValue}
                variant="outlined"
                type="search"
                size={SIZE.MEDIUM}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledAutoComplete
                disableClearable
                size={InputSize.MEDIUM}
                options={parseToOptionFormatWithAll(categoryOptions)}
                getOptionLabel={(option) => option.label}
                onChange={(e, v) => handleChangeDropDown(e, v, "categoryValue")}
                value={categoryValue}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) =>
                  renderDropdownInput(params, "Category")
                }
                renderTags={renderCategoryAnswer}
              ></StyledAutoComplete>
            </Grid>
            <Grid item xs={12}>
              <StyledAutoComplete
                disableClearable
                size={InputSize.MEDIUM}
                multiple
                disableCloseOnSelect
                options={parseToOptionFormatWithAll(tagOptions)}
                getOptionLabel={(option) => option.label}
                onChange={(e, v) => handleChangeDropDown(e, v, "tagValue")}
                value={tagValue}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => renderDropdownInput(params, "Tag")}
                renderTags={renderTagAnswer}
              ></StyledAutoComplete>
            </Grid>
          </Grid>
        </StyledMacroModalContentInsideContainer>
        <StyledMacroListContainer>
          {macroList.map((macro) => (
            <SingleQuestion
              key={macro._id}
              macro={macro}
              onLinkifyOptions={onLinkifyOptions}
              onSelectMacro={onSelectMacro}
            />
          ))}
        </StyledMacroListContainer>
      </StyledMacroModalContentContainer>
    </Modal>
  );
};

export default MacroModal;
