import { Box } from "@mui/material";
import Back from "features/metadesk/components/Back";
import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash-es";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { Loading } from "features/common";
import {
  StyledWhiteContainer,
  StyledContainerTitle,
  StyledContentContainer,
  StyledContainerTitleIcon,
} from "./Referral.styles";
import Objective from "../campaign/createCampaign/component/Objective";
import {
  alertServerError,
  parseCustomDomainUrl,
  validateURL,
} from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import useMarketModule from "hooks/useMarketModule";
import CustomStepper from "components/CustomStepper/CustomStepper";
import IntegrationAttributionLink from "../settings/integrations/IntegrationAttributionLink";
import { useFetchEntityConfig } from "../redux/hooks";
import { useConfirm } from "features/home/ConfirmDialogProvider";

const defaultValues = {
  url: "",
  description: "",
  objectives: [{}],
  domainName: "",
};

export default function CreateReferral() {
  const [isLoading, setIsLoading] = useState(false);
  const { attributionConnect } = useMarketModule();
  const { fetchEntityConfig } = useFetchEntityConfig();
  const [referralData, setReferralData] = useState(defaultValues);
  const [latestObjectives, setLatestObjectives] = useState([]);
  const [twoSteps, setTwoSteps] = useState(false);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const isIntegrationAttributionLink = twoSteps && step == 0;
  useEffect(() => {
    setTwoSteps(!attributionConnect);
  }, []);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors: errorsInfo, isValid },
  } = methods;

  useEffect(() => {
    handleGetLatestObjective();
  }, []);

  const submitCreate = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${apiUrl}/api/referral/${entityName}/program`, {
        websiteURL: referralData?.url,
        description: referralData?.description,
        objective: get(referralData, "objectives[0]"),
      });
      await fetchEntityConfig({ entityName });
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/analytics/settings/referral"
        )
      );
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setIsLoading(false);
    }
  };

  const integrateTracking = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${apiUrl}/api/manage/attributionConfig/${entityName}`, {
        url: `https://${referralData.domainName}`,
      });
      await fetchEntityConfig({ entityName });
      if (attributionConnect) {
        setStep(1);
      } else {
        confirm({
          title: formatMessage({
            id: "popup.statusReturn.trackingTagUninstalled.title",
          }),
          content: formatMessage({
            id: "popup.statusReturn.trackingTagUninstalled.description",
          }),
          confirmText: "Got it",
          color: "secondary",
        })
          .then(() => {})
          .catch(() => {});
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(alertServerError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetLatestObjective = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/objective/latest`
      );
      setLatestObjectives(data);
    } catch (error) {
      dispatch(alertServerError(error));
    }
  };

  const renderContent = () => {
    if (twoSteps && step == 0) {
      return (
        <IntegrationAttributionLink
          isSetting={false}
          controlByParent={control}
          formDataByParent={referralData}
          setFormDataByParent={setReferralData}
          formStateByParent={referralData}
        />
      );
    }
    return (
      <Box>
        <StyledWhiteContainer>
          <StyledContainerTitle className="startRow">
            <StyledContainerTitleIcon>
              <i className="meta-crm-icon-ic_file_outline" />
            </StyledContainerTitleIcon>
            {formatMessage({
              id: "referralProgramBuild.firstStep.information.title",
            })}
          </StyledContainerTitle>
          <StyledContentContainer>
            <Box sx={{ marginBottom: "20px" }}>
              <InputField
                title={
                  <Box className="startRow">
                    {formatMessage({
                      id: "referralProgramBuild.firstStep.websiteURL.title",
                    })}
                    <Box className="required">*</Box>
                  </Box>
                }
                placeholder={formatMessage({
                  id: "widgetSettings.domain.placeholder",
                })}
                onChange={(e) => {
                  setReferralData({ ...referralData, url: e.target.value });
                }}
                name="url"
                control={control}
                errors={get(errorsInfo, "url.message")}
                value={referralData.url}
                prefixWord={"https://"}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({
                      id: "global.inputCheck.empty",
                    }),
                  },
                  validate: (value) =>
                    validateURL(value) ||
                    formatMessage({
                      id: "global.inputCheck.invalidUrl",
                    }),
                }}
              ></InputField>
            </Box>
            {/* description input */}
            <Box>
              <InputField
                title={formatMessage({
                  id: "referralProgramBuild.firstStep.description.title",
                })}
                multiline={true}
                rows={5}
                maxLength={200}
                placeholder={formatMessage({
                  id: "global.column.description",
                })}
                name="description"
                value={referralData.description}
                onChange={(e) => {
                  setReferralData({
                    ...referralData,
                    description: e.target.value,
                  });
                }}
              />
            </Box>
          </StyledContentContainer>
        </StyledWhiteContainer>
        <StyledWhiteContainer>
          <StyledContainerTitle className="startRow">
            <StyledContainerTitleIcon>
              <i className="meta-crm-icon-ic_referral2" />
            </StyledContainerTitleIcon>
            {formatMessage({
              id: "referralProgramBuild.firstStep.objective.title",
            })}
          </StyledContainerTitle>
          <StyledContentContainer>
            <Objective
              objectiveIndex={0}
              latestObjectives={latestObjectives}
              formData={referralData}
              setFormData={(values) => {
                setReferralData({
                  ...referralData,
                  ...values,
                });
              }}
            ></Objective>
          </StyledContentContainer>
        </StyledWhiteContainer>
      </Box>
    );
  };
  return (
    <div style={{ paddingBottom: "20px" }}>
      <FormProvider {...methods}>
        <Back />
        <Box className="lgTitle">
          {formatMessage({
            id: isIntegrationAttributionLink
              ? "marketingSettings.installation.title"
              : "referralProgramBuild.firstStep.title",
          })}
        </Box>
        <Box className="description">
          {formatMessage({
            id: isIntegrationAttributionLink
              ? "marketingSettings.installation.description"
              : "referralProgramBuild.firstStep.description",
          })}
        </Box>
        {twoSteps && (
          <StyledWhiteContainer style={{ padding: "22px" }}>
            <Box
              sx={{ maxWidth: "400px", margin: "0px auto", marginTop: "22px" }}
            >
              <CustomStepper
                steps={["Integrate Tracking Tag", "Setting Referral Campaign"]}
                activeStep={step}
              />
            </Box>
          </StyledWhiteContainer>
        )}
        <Loading open={isLoading} fullScreen={true} />
        {renderContent()}
        <FixedButton
          leftComponent={
            <Button
              sx={{ width: "144px" }}
              variant={VARIANT.OUTLINED}
              onClick={() => {
                if (step !== 0) {
                  setStep(step - 1);
                } else {
                  navigate(-1);
                }
              }}
            >
              Back
            </Button>
          }
        >
          <Button
            sx={{ width: "144px" }}
            disabled={
              isIntegrationAttributionLink ? !referralData.domainName : !isValid
            }
            onClick={() => {
              if (isIntegrationAttributionLink) {
                integrateTracking();
              } else {
                handleSubmit(submitCreate());
              }
            }}
          >
            {isIntegrationAttributionLink ? "Next" : "Create"}
          </Button>
        </FixedButton>
      </FormProvider>
    </div>
  );
}
