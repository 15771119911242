import Button, {
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import { Box } from "@mui/material";
import { Loading } from "features/common";
import PureModal from "features/metadesk/campaign/campaignList/component/PureModal";
import React from "react";

export default function CustomUnassignModal({
  open,
  tags,
  unassignTags,
  handleClose,
  handleClickCheckbox,
  handleUnAssign,
  isLoading,
  ...props
}) {
  return (
    <PureModal
      closeBtn={true}
      open={open}
      onClose={handleClose}
      maxWidth="500px"
      {...props}
    >
      <Box p="48px 40px">
        <Loading open={isLoading} fullScreen={false} />

        <Box
          color="#383538"
          fontSize="18px"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          mb="12px"
        >
          <i className="meta-crm-icon-ic_tag font-size-24 mgr4" />
          {"Unassign Tag"}
        </Box>
        <Box my="12px" color="#777">
          Simultaneously remove tags from selected customers.
        </Box>
        <Box
          borderTop={"1px solid #D6D6D6"}
          borderBottom={"1px solid #D6D6D6"}
          color={"#777"}
          py="12px"
        >
          <div>All tags on selected customers:</div>
          <Box my="30px">
            {tags.map((tag) => (
              <Box key={tag._id}>
                <Checkbox
                  checked={unassignTags.includes(tag._id)}
                  onChange={(e) => handleClickCheckbox(e, tag)}
                  label={
                    <MetaCRMTag
                      option={{ ...tag, label: tag.value || tag.label }}
                      optionId={tag._id}
                    />
                  }
                />
              </Box>
            ))}
          </Box>
          <div
            style={{
              color: "#7B61FF",
              fontSize: 12,
              fontWeight: 400,
            }}
            className="startRow"
          >
            <i className="meta-crm-icon-ic_info font-size-16" />
            This action may affect different customers
          </div>
        </Box>
        <Box className="endRow" mt="20px">
          <Button
            color={COLOR.SECONDARY}
            variant={VARIANT.TEXT}
            onClick={handleClose}
            style={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            color={COLOR.SECONDARY}
            disabled={unassignTags.length == 0}
            onClick={handleUnAssign}
            style={{ width: 150 }}
          >
            Unassign
          </Button>
        </Box>
      </Box>
    </PureModal>
  );
}
