import { useState, useEffect } from "react";
import axios from "axios";
import { generateKey, decryptToken } from "features/helpers/encrypt.js";

function useInterceptor() {
  const [isInterceptorSetup, setIsInterceptorSetup] = useState(false);

  useEffect(() => {
    let interceptorId;
    setIsInterceptorSetup(false);

    const setInterceptor = async () => {
      interceptorId = axios.interceptors.request.use(
        async (config) => {
          const encryptedToken = localStorage.getItem("access_token");
          if (!encryptedToken) return config;
          const sessionId = localStorage.getItem("session_id");
          if (!sessionId) return config;

          try {
            let key = generateKey(sessionId);
            let decryptedToken = decryptToken(encryptedToken, key);
            config.headers.Authorization = `Bearer ${decryptedToken}`;
          } catch (error) {
            delete config.headers.Authorization;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    };

    const setup = async () => {
      try {
        await setInterceptor();
        setIsInterceptorSetup(true);
      } catch (error) {
        console.error(error);
        setIsInterceptorSetup(false);
      }
    };
    setup();

    return () => {
      if (interceptorId !== undefined) {
        axios.interceptors.request.eject(interceptorId);
      }
    };
  }, []);

  return {
    isInterceptorSetup,
    setIsInterceptorSetup,
  };
}

export default useInterceptor;
