import { byDecimals } from "./bignumber";
import _, { add } from "lodash";
import axios from "axios";
import { apiUrl, chainList, supportCenterBaseUrl } from "features/configure";
import moment from "moment";
import Papa from "papaparse";
import { enqueueSnackbar } from "features/common/redux/actions";
import Identicon from "identicon.js";
import isURL from "validator/lib/isURL";
import { isAddress as isEvmAddress } from "viem";
import store from "common/store";
import { routeConfig } from "RouteComponent";

let trimReg = /(^\s*)|(\s*$)/g;

export function isEmpty(key) {
  if (key === undefined || key === "" || key === null) {
    return true;
  }
  if (typeof key === "string") {
    key = key.replace(trimReg, "");
    return (
      key === "" ||
      key === null ||
      key === "null" ||
      key === undefined ||
      key === "undefined"
    );
  } else if (typeof key === "undefined") {
    return true;
  } else if (typeof key == "object") {
    for (let i in key) {
      return false;
    }
    return true;
  } else if (typeof key == "boolean") {
    return false;
  }
}

let inputReg = /[a-z]/i;
export function inputLimitPass(value, tokenDecimals) {
  let valueArr = value.split(".");
  return !(
    inputReg.test(value) ||
    (valueArr.length === 2 && valueArr[1].length > tokenDecimals)
  );
}

export function inputFinalVal(value, total, tokenDecimals) {
  let inputVal = Number(value.replaceAll(",", ""));
  return inputVal > total
    ? byDecimals(total, 0).toFormat(tokenDecimals)
    : value;
}

export const shouldHideFromHarvest = (vaultName) => {
  // FIXME: hidden until we implement an 'advanced' toggle
  // return HarvestBlacklistVaultIds.includes(vaultName);
  return true;
};

export const converAprToApy = (apr) => {
  return Math.pow(1 + apr / 365, 365) - 1;
};

export const converAprStringToApyString = (aprString) => {
  const apr = parseFloat(aprString.replace("%", "")) / 100;
  return parseFloat(converAprToApy(apr) * 100).toFixed(2) + "%";
};

export const convertApyFloatToString = (apyFloat) => {
  return (apyFloat * 100).toFixed(2) + "%";
};

export const convertAprFloatToString = (aprFloat) => {
  return parseFloat(converAprToApy(aprFloat) * 100).toFixed(2) + "%";
};

export const formatDate = (date, options = {}) => {
  const { time = false, month = false, utc0 = false } = options; // 設置預設值
  if (!date || !moment(date).isValid()) return "-";
  const localDate = utc0 ? moment.utc(date).local() : moment(date);
  if (month) return localDate.format("DD MMM");
  return localDate.format(time ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY");
};

export const formatTime = (date, option = {}) => {
  const { utc0 = false } = option; // 設置預設值
  if (!date || !moment(date).isValid()) return "-";
  const localDate = utc0 ? moment.utc(date).local() : moment(date);
  return localDate.format("HH:mm");
};

export const formatCalendarDate = (date) => {
  if (!date || !moment(date).isValid()) return "-";
  return moment(date).format("MMM DD, YYYY");
};
export const formatCalendarDateAndTime = (date) => {
  if (!date || !moment(date).isValid()) return "-";
  return moment(date).format("MMM DD, YYYY HH:mm");
};

export const formatWidgetDateAndTime = (date) => {
  if (!date || !moment(date).isValid()) return "-";
  return moment(date).format("DD MMM, YYYY HH:mm");
};

export const formatDateTime = (date) => {
  if (!date || !moment(date).isValid()) return "-";
  return moment(date).format("YYYY/MM/DD HH:mm");
};

export const formatDateAndTime = (date, twoRows) => {
  if (!date) return "-";

  if (!moment(date).isValid()) return "-";
  if (twoRows)
    return (
      <div>
        {moment(date).format("YYYY/MM/DD")}
        <div>{moment(date).format("HH:mm")}</div>
      </div>
    );
  return moment(date).format("YYYY/MM/DD HH:mm");
};

export const parseCustomDomainUrl = (isCustomDomain, entityName, url) => {
  if (isCustomDomain) {
    return url;
  }
  if (!entityName) {
    return "/";
  }
  return `/${entityName}${url}`;
};

export const parseSupportCenterCustomDomainUrl = (
  isCustomDomain,
  entityName
) => {
  if (isCustomDomain) {
    if (process.env.REACT_APP_ENTITY == "curve") {
      return "https://curve.repair";
    } else if (process.env.REACT_APP_ENTITY == "radiant") {
      return "https://support.radiant.capital";
    }
  }
  if (!entityName) {
    return supportCenterBaseUrl;
  }
  return `${supportCenterBaseUrl}/${entityName}`;
};

export const getShortAddress = (address) => {
  if (!address) return "-";
  if (address.length < 15) return address;
  return `${address.slice(0, 6)}......${address.slice(-4)}`;
};

export const centerTruncate = (value, length = 24, start = 10, end = -8) => {
  if (!value) return "-";
  if (value.length < length) return value;
  return `${value.slice(0, start)}...${value.slice(end)}`;
};

export const convertToFile = async (dataURI, fileName) => {
  if (!dataURI || typeof dataURI != "string") return null;
  if (
    !dataURI.startsWith("data:image/") &&
    !dataURI.startsWith("data:application/pdf")
  ) {
    return null;
  }
  const res = await fetch(dataURI, { mode: "no-cors" });
  const buf = await res.arrayBuffer();
  const file = new File([buf], fileName, {
    type: "image/png",
  });
  return file;
};

export const formatDiscordUsername = (username) => {
  if (!username) return username;
  const [name, tag] = username.split("#");
  if (tag === "0") {
    return name;
  } else {
    return username;
  }
};

export const uploadFiles = async (
  images,
  entityName,
  url = "/api/gcp/uploadChat",
  key = "chat"
) => {
  if (!images || images.length == 0) {
    return [];
  }

  const uploadFiles = [];
  let postFormData = new FormData();
  for (let index = 0; index < images.length; index++) {
    if (typeof images[index] == "string") continue;
    let file;
    if (images[index] instanceof File) {
      file = images[index];
    } else {
      file = await convertToFile(
        _.get(images[index], "data_url"),
        _.get(images[index], "file.name")
      );
    }

    if (file) {
      postFormData.append(key, file);
      uploadFiles.push(index);
    }
  }
  if (uploadFiles.length == 0) {
    return images;
  }
  postFormData.append("entityName", entityName);
  const uploadResult = await axios.post(apiUrl + url, postFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const notificationImages = _.get(uploadResult.data, key);
  let newUploadImgs = [...images];
  for (let file of uploadFiles) {
    newUploadImgs[file] = notificationImages[file];
  }
  return newUploadImgs;
};

export const alertInfo = (message) => {
  return enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: "info",
    },
  });
};
export const alertServerError = (error) => {
  return enqueueSnackbar({
    message: _.get(error, "response.data.error", "Error"),
    options: {
      key: new Date().getTime() + Math.random(),
      variant: "error",
    },
  });
};

export const exportCSV = (data, name = "MetaCRM_ExportUsers") => {
  let columnsSet = new Set();

  for (let row of data) {
    for (let key in row) {
      if (row.hasOwnProperty(key)) {
        columnsSet.add(key);
      }
    }
  }

  const csv = Papa.unparse(data, {
    columns: Array.from(columnsSet),
  });
  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const csvURL = window.URL.createObjectURL(csvData);
  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  anchorElement.style.display = "none";
  anchorElement.href = csvURL;
  anchorElement.download = name;
  anchorElement.click();
  window.URL.revokeObjectURL(csvURL);
};

export const formatCSV = (data) => {
  return _.map(data, (o) => {
    let newRow = {};
    Object.keys(o).map((key) => {
      let value = o[key];
      if (key == "tags") {
        return;
      }
      if (key == "groups") {
        return;
      }
      if (key == "didNames") {
        return;
      }
      if (key == "source") {
        return;
      }
      if (key == "discordHandle") {
        value = _.get(o, `[${key}]`);
      }
      if (key == "twitterHandle") {
        value = _.get(o, `[${key}]`);
      }
      if (key == "csvFields") {
        for (let field of value) {
          const header = _.get(field, "header.value", "");
          if (!header) continue;
          newRow[header] = field.value;
        }
        return;
      }
      newRow[key] = value;
    });
    return newRow;
  });
};

export const getHexImg = (address, size = 40) => {
  if (!address || address.length < 16) return;
  let data = new Identicon(address, {
    size,
    format: "svg",
  }).toString();
  return `data:image/svg+xml;base64,${data}`;
};

export const validateURL = (value, options) => {
  if (!value) return true;
  if (value.trim() === "") {
    return true;
  }
  if (isURL(value, options)) {
    return true;
  }
  return false;
};

export const validateHttpsURL = (value) => {
  if (!value) return true;
  if (value.trim() === "") {
    return true;
  }
  if (isURL(value)) {
    return true;
  }
  return false;
};

export const getBaseUrl = (url) => {
  if (!url) {
    return null;
  }
  // 創建一個新的URL物件
  let u = new URL(url);

  // 組合協議、冒號、雙斜線與主機名稱
  return u.protocol + "//" + u.host;
};

export const parseUrlPath = (urlPath) => {
  const parts = urlPath.replace(/^\/|\/$/g, "").split("/");
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  if (isCustomDomain) {
    return {
      entityName: envEntity,
      module: parts[0],
      path: parts.slice(1).join("/"),
    };
  }
  const entityName = parts[0];
  const module = parts[1];
  const path = parts.slice(2).join("/");

  return { entityName, module, path };
};

export const containsAllProperties = (objA, objB) => {
  return Object.keys(objB).every(
    (key) =>
      _.get(objA, key) && _.isEqual(_.get(objA, key), _.get(objB, key, "Error"))
  );
};
export const getMenuList = (
  entityConfig,
  pagePermission,
  ecosystemName,
  entityName
) => {
  const createMenuList = (
    config,
    path,
    upgrade,
    parentNeedUpgrade,
    parentShow = true
  ) => {
    const pages = [];
    if (!config.children || config.children.length == 0) return [];
    for (let item of config.children) {
      const itemUpgrade = { ...item.upgrade, ...upgrade };

      let isShow = true;
      const isNeedUpgrade =
        !_.isEmpty(itemUpgrade) &&
        !containsAllProperties(entityConfig, itemUpgrade);

      if (item.feature && !pagePermission(item.feature)?.show) {
        isShow = false;
      }
      if (item.ecosystems && !_.includes(item.ecosystems, ecosystemName))
        isShow = false;
      if (item.allowed && !containsAllProperties(entityConfig, item.allowed)) {
        isShow = false;
      }
      if (!item.title) isShow = false;
      if (
        item.allowedEntities &&
        !_.includes(
          _.map(item.allowedEntities, _.toLower),
          _.toLower(entityName)
        )
      )
        isShow = false;

      const itemNeedUpgrade = parentNeedUpgrade || isNeedUpgrade;
      const itemShow = parentShow && isShow;

      const page = {
        ...item,
        isNeedUpgrade: itemNeedUpgrade,
        parentPath: path,
        upgrade: itemUpgrade,
        isShow: itemShow,
      };

      if (item.children && item.children.length > 0) {
        page.children = createMenuList(
          item,
          path + "/" + item.path,
          itemUpgrade,
          itemNeedUpgrade,
          itemShow
        );
      }
      if (page.isMenu && page.children.length == 0) continue;
      if (itemShow) pages.push(page);
    }
    return pages;
  };

  const list = {};
  for (let config of _.values(routeConfig)) {
    const module = config.path;
    if (!module) continue;
    list[module] = createMenuList(config, "/" + config.path, config.upgrade);
  }
  return list;
};

const findDeepestPath = (pages, parentPath = "") => {
  for (let page of pages) {
    if (page.isNeedUpgrade || !page.isShow) continue;
    const currentPath = `${parentPath ? parentPath : page.parentPath}/${
      page.path
    }`;

    if (page.children && page.children.length > 0) {
      const deepestChildPath = findDeepestPath(page.children, currentPath);

      if (deepestChildPath) return deepestChildPath;
    } else {
      return currentPath;
    }
  }

  return "/error";
};

export const redirectRoot = (menuList, moduleName) => {
  try {
    let pages = [];
    let values = [];
    if (moduleName) {
      values = _.get(menuList, `[${moduleName}]`, []);
    } else {
      for (let list of _.values(menuList)) {
        if (list.length == 0) continue;
        values = _.concat(values, list);
      }
    }
    for (let value of values) {
      if (!value || value.isNeedUpgrade || !value.isShow) continue;
      pages = _.concat(pages, value);
    }
    return findDeepestPath(pages);
  } catch (err) {
    return "/error";
  }
};

// 比較版本是否小於
export const isVersionLessThan = (v1, v2) => {
  if (!v1 || !v2) return false;
  if (v1 === v2) return false;
  const v1parts = v1.split(".").map(Number);
  const v2parts = v2.split(".").map(Number);

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i || v1parts[i] < v2parts[i]) {
      return true;
    } else if (v1parts[i] > v2parts[i]) {
      return false;
    }
  }

  return false;
};

// 比較版本是否大於
export const isVersionMoreThan = (v1, v2) => {
  if (!v1 || !v2) return false;
  const v1parts = v1.split(".").map(Number);
  const v2parts = v2.split(".").map(Number);

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i || v1parts[i] > v2parts[i]) {
      return true;
    } else if (v1parts[i] < v2parts[i]) {
      return false;
    }
  }

  return false;
};

export const copyToClipboard = (value, dispatch) => {
  navigator.clipboard.writeText(value);
  dispatch(
    enqueueSnackbar({
      message: (
        <div style={{ color: "#7b61ff" }} className="formTitle startRow">
          <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
          Copied
        </div>
      ),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "info",
      },
    })
  );
};

const aptosRules = /^0x[a-f0-9]{64}$/;
// ref https://solana.stackexchange.com/questions/1919/is-there-a-specific-format-of-how-a-solana-address-should-look-like
const solanaRules = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;

export const isAddress = (address) => {
  const currentState = store.getState();
  const currentEcosystem = _.get(
    currentState,
    "metadesk.entityConfig.ecosystem"
  );
  if (currentEcosystem === "evm") {
    return isEvmAddress(address);
  } else if (currentEcosystem === "aptos") {
    return aptosRules.test(address);
  } else if (currentEcosystem === "solana") {
    return solanaRules.test(address);
  } else {
    return isEvmAddress(address);
  }
};

// 將駝峰式字串轉換為首字母大寫並加空格
export const camelCaseToTitle = (camelCase) => {
  const result = camelCase
    // 在大寫字母前加上空格
    .replace(/([A-Z])/g, " $1")
    // 去除字符串開頭可能的空格
    .trim()
    // 將每個單詞的首字母轉為大寫
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/ (\w)/g, (spaceAndFirstLetter) =>
      spaceAndFirstLetter.toUpperCase()
    );

  return result;
};

// 當value是  數字字串  parseInt 是一般字串 就不用
export const convertIfNumeric = (value) => {
  if (/^\d+$/.test(value)) {
    return parseInt(value, 10);
  }
  return value;
};

// 給 React Datepicker 用 檢查是否為同一天
export const isSameDay = (date1, date2) => {
  const dateToCompare =
    typeof date1 === "string" ? new Date(date1) : date1 || new Date();
  return (
    dateToCompare.getFullYear() === date2.getFullYear() &&
    dateToCompare.getMonth() === date2.getMonth() &&
    dateToCompare.getDate() === date2.getDate()
  );
};

// 給 React Datepicker 用 當天只能選擇現在以後的時間
export const getMinTime = (selectedDate) => {
  const now = new Date();
  return isSameDay(selectedDate, now) ? now : new Date(0, 0, 0, 0, 0, 0);
};

export const getTransactionUrl = (ecosystem, chainId, tx) => {
  if (ecosystem === "Solana") return `https://solscan.io/tx/${tx}`;
  if (ecosystem === "Aptos") return `https://aptoscan.com/transaction/${tx}`;
  if (ecosystem === "EVM") {
    const explorerUrl = chainList.find((item) => item.id === chainId)?.explorer;
    return `${explorerUrl}/tx/${tx}`;
  }
};

export const msToTime = (ms) => {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + " Sec";
  else if (minutes < 60) return minutes + " Min";
  else if (hours < 24) return hours + " Hrs";
  else return days + " Days";
};
