import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-end",
    display: "flex",

    "& li .Mui-selected": {
      color: "#5643CC",
      backgroundColor: "#F1E5FF",
      "&:hover": {
        color: "#FFFFFF",
      },
    },
  },
}));

export default function CustomPagination(props) {
  const { count, page, setPage, PAGE_COUNT = 20, ...other } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div style={{ marginTop: 20 }}>
      <Pagination
        size="small"
        color="secondary"
        {...other}
        classes={{ root: classes.root }}
        renderItem={(item) => <PaginationItem {...item} />}
        shape="rounded"
        count={Math.ceil(count / PAGE_COUNT)}
        page={page}
        onChange={(event, value) => {
          setPage(value);
        }}
      />
    </div>
  );
}
