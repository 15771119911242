import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { Grid } from "@mui/material";
import CustomDataFetching from "components/CustomDataFetching/CustomDataFetching";
import { useFetchUserWalletInfo } from "../redux/fetchUserWalletInfo";
import { formatDate } from "features/helpers/utils";
import CustomTable from "components/CustomTable/CustomTable";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import UserDetail from "../components/UserDetail";
import UserTitle from "../components/UserTitle";
import { StyledUserInfoContainer } from "../ticket/TicketDetail.styles";

const getNumberWithContractAddressAndDirection = (data, contractAddress) => {
  let directions = ["mint", "buy", "transferIn", "sell", "transferOut"];

  let out = _.map(directions, (dir) => {
    return _.reduce(
      data,
      (sum, d) => {
        if (d.contractAddress == contractAddress && d.direction == dir) {
          return sum + 1;
        } else {
          return sum;
        }
      },
      0
    );
  });
  return out;
};

const getCurrentHolding = (data, contractAddress) => {
  return _.reduce(
    data,
    (sum, d) => {
      if (d.token_address == contractAddress) {
        return sum + parseInt(d.amount);
      } else {
        return sum;
      }
    },
    0
  );
};

const AzukiUserInfo = ({
  selectedUserId,
  selectedUserAddress,
  entityName,
  isFetching,
  fetchUserObjs,
}) => {
  const {
    userWalletInfos,
    fetchUserWalletInfo,
    fetchUserWalletInfoPending,
    isSearch,
  } = fetchUserObjs();
  const userInfo = _.get(userWalletInfos, `[${selectedUserAddress}]`, {});

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (userInfo && !isFetching && selectedUserAddress) {
      fetchUserWalletInfo({
        userAddress: selectedUserAddress,
        entityName,
        isSearch,
      });
    }
  }, [isFetching]);

  let tabs = [
    {
      label: "Overview",
      content: <UserDetail userInfo={userInfo} />,
    },
    {
      label: "Trading History",
      content: (
        <div>
          <CustomDataFetching
            fetching={isFetching}
            loading={fetchUserWalletInfoPending}
            hasData={userInfo.NFTTxs && userInfo.NFTTxs.length > 0}
            contents={() => {
              return (
                <>
                  <div
                    className="startRow"
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    <CustomTable
                      collapse
                      style={{ fontSize: 12 }}
                      headers={[
                        "Token",
                        "# of mint",
                        "# of purchase",
                        "# of transfer in",
                        "# of sell",
                        "# of transfer out",
                        "# of current holding",
                      ]}
                      contents={[
                        [
                          "Azuki",
                          ...getNumberWithContractAddressAndDirection(
                            userInfo.NFTTxs,
                            "0xed5af388653567af2f388e6224dc7c4b3241c544"
                          ),
                          getCurrentHolding(
                            userInfo.entitySpecificNFTList,
                            "0xed5af388653567af2f388e6224dc7c4b3241c544"
                          ),
                        ],
                        [
                          "Beanz",
                          ...getNumberWithContractAddressAndDirection(
                            userInfo.NFTTxs,
                            "0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949"
                          ),
                          getCurrentHolding(
                            userInfo.entitySpecificNFTList,
                            "0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949"
                          ),
                        ],
                        [
                          "Bobu",
                          ...getNumberWithContractAddressAndDirection(
                            userInfo.NFTTxs,
                            "0x2079812353e2c9409a788fbf5f383fa62ad85be8"
                          ),
                          getCurrentHolding(
                            userInfo.entitySpecificNFTList,
                            "0x2079812353e2c9409a788fbf5f383fa62ad85be8"
                          ),
                        ],
                        [
                          "Ambush",
                          ...getNumberWithContractAddressAndDirection(
                            userInfo.NFTTxs,
                            "0xc20ae005e1340dab2449304158f999bfdd1aac1c"
                          ),
                          getCurrentHolding(
                            userInfo.entitySpecificNFTList,
                            "0xc20ae005e1340dab2449304158f999bfdd1aac1c"
                          ),
                        ],
                      ]}
                    />
                  </div>
                </>
              );
            }}
          ></CustomDataFetching>
        </div>
      ),
    },
    {
      label: "Relevant transactions",
      content: (
        <div>
          <CustomDataFetching
            fetching={isFetching}
            loading={fetchUserWalletInfoPending}
            hasData={userInfo.NFTTxs && userInfo.NFTTxs.length > 0}
            contents={() => {
              return (
                <>
                  <div
                    className="startRow"
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  ></div>
                  {userInfo.NFTTxs.map((row, i) => {
                    return (
                      <div key={i}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            background: "white",
                            margin: 5,
                            padding: "10px",
                            borderRadius: 5,
                            fontSize: 12,
                            fontWeight: 500,
                            border: "1px solid rgba(165, 165, 165, 0.5)",
                          }}
                        >
                          <Grid item xs={2}>
                            <div style={{ color: "#A5A5A5" }}>
                              {formatDate(row.timeStamp * 1000)}
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div
                              style={{ fontWeight: 700 }}
                              className="startRow"
                            >
                              <img
                                src={require(`assets/img/approve.svg`).default}
                                className="smIcon"
                              />
                              <div>
                                <div
                                  style={{ color: "#A5A5A5", marginBottom: 3 }}
                                >
                                  {row.tokenSymbol
                                    ? row.tokenSymbol
                                    : row.tokenName}{" "}
                                  - {row.tokenID}
                                </div>
                                {row.direction}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div
                              style={{ overflow: "hidden", margin: "0 5px" }}
                            >
                              {row.marketPlace != "unknown"
                                ? row.marketPlace
                                : "-"}
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <a
                              href={`https://etherscan.io/tx/${row.hash}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Etherscan
                            </a>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </>
              );
            }}
          ></CustomDataFetching>
        </div>
      ),
    },
  ];

  const renderContent = () => {
    return (
      <Tabs
        tabLabelStyle={{ fontSize: 12, fontWeight: 500 }}
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
        tabs={tabs}
      />
    );
  };

  return (
    <StyledUserInfoContainer>
      <UserTitle
        selectedUserId={selectedUserId}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        fetchUserObjs={fetchUserObjs}
      />

      <div style={{ padding: "12px", flex: 1 }}>{renderContent()}</div>
    </StyledUserInfoContainer>
  );
};

export default AzukiUserInfo;
