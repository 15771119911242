import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledCustomizationContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontFamily: "Inter",
  maxWidth: 500,
  paddingBottom: "80px",
  "& *": {
    fontFamily: "Inter",
  },
}));

export const StyledGeneralSettingsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSettingTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[700],
  fontSize: "16px",
  marginBottom: "8px",
}));

export const StyledDescription = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  lineHeight: "24px",
  marginBottom: "24px",
  color: theme.customColors.grey[600],
}));

export const StyledTokenList = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-track": {
    background: theme.customColors.grey[300],
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.customColors.grey[500],
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: theme.customColors.grey[500],
  },
}));
export const StyledTokenRow = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "48px",
  display: "flex",
  alignItems: "center",
  fontSize: 16,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.customColors.grey[200]}`,
}));

export const StyledDraggableListContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "fit-content",
}));

export const StyledTicketNameTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  color: theme.customColors.grey[700],
  paddingLeft: "65px",
  marginBottom: "8px",
}));

export const StyledChainDataContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, selected }) => ({
  cursor: "pointer",
  position: "relative",
  backgroundColor: "transparent",
  border: `1px solid ${
    selected ? theme.customColors.purple[500] : theme.customColors.grey[300]
  }`,
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: "8px",
  color: theme.customColors.grey[700],
  height: "52px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
}));

export const StyledAddTicketTypeButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "transparent",
  border: `1px dashed ${theme.customColors.grey[300]}`,
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "3px",
  color: theme.customColors.grey[500],
  height: "36px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  "& .plus": {
    marginRight: "15px",
    fontWeight: 900,
    display: "flex",
  },
}));

export const StyledAlertText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.pink[600],
  display: "flex",
}));

export const StyledLine = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.customColors.grey[300],
  marginTop: "32px",
  marginBottom: "32px",
}));

export const StyledDiscordSettingContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  maxWidth: "500px",
}));

export const StyledTagsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  marginRight: "8px",
  flexWrap: "wrap",
  marginBottom: "12px",
}));

export const StyledDiscordTag = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color = "#707070" }) => ({
  color: color,
  border: `1px solid ${color}`,
  borderRadius: "20px",
  padding: "4px 8px",
  marginBottom: "12px",
  fontWeight: "500",
  marginRight: "8px",
}));

export const StyledTokensContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  borderRadius: 8,
  border: "1px solid #E6E6E6",
  padding: "12px 16px",
}));

export const StyledDiscordTicketTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[700],
}));
