import React, { useState, useEffect, useRef } from "react";

import { get, isEmpty, isEqual } from "lodash-es";

import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";

import {
  StyledRightImage,
  StyledRightImageContainer,
  StyledDropdownPreview,
  StyledDropdownItem,
} from "./TrialServiceSetStep3.styles";

import TicketListContainer from "features/metadesk/settings/Customization/TicketType/TicketListContainer";

import axios from "axios";
import { apiUrl } from "features/configure";

import { enqueueSnackbar } from "features/common/redux/actions";

import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";

import TrialSetStepTemplate from "features/home/pages/Trial/TrialSetStepTemplate/TrialSetStepTemplate";
import TrialSetStepButton from "features/home/pages/Trial/TrialSetStepButton/TrialSetStepButton";
import { useStepData } from "contexts/stepContext/Step.context";
import { selectTicketTypes } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useIntl } from "react-intl";

const formDataInit = {
  ticketList: [],
};

const TrialMarketingSetStep3 = ({
  step,
  onNextStep,
  onPrevStep,
  entityName,
}) => {
  const methods = useForm({
    defaultValues: formDataInit,
    mode: "onChange",
  });
  const ticketTypesDefault = useSelector(selectTicketTypes);

  const {
    formState: { isDirty, errors: errorsInfo, isValid: isFormValid },
  } = methods;

  const { formatMessage } = useIntl();
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [ticketList, setTicketList] = useState(formDataInit.ticketList);
  const { setStepValues, stepData } = useStepData();

  const dispatch = useDispatch();

  useUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const hasDuplicateName = (arr) => {
    if (isEmpty(arr)) {
      return false;
    }
    const nameSet = new Set();

    for (const item of arr) {
      const itemName = get(item, "name");
      if (!itemName) {
        return false;
      }
      if (nameSet.has(itemName.toLowerCase())) {
        return true; // 如果發現重複，則返回 true
      }
      nameSet.add(item.name.toLowerCase());
    }

    return false; // 如果迴圈完成而沒有找到重複，則返回 false
  };

  const isAnyEmptyInTicketList = () => {
    return ticketList.some((obj) => obj.name === "");
  };

  const isValid =
    !hasDuplicateName(ticketList) &&
    !isAnyEmptyInTicketList() &&
    !isEmpty(ticketList);
  const showWarning = hasDuplicateName(ticketList) || isAnyEmptyInTicketList();

  useEffect(() => {
    if (!isEmpty(stepData) && get(stepData, "ticketList")) {
      setTicketList(get(stepData, "ticketList"));
      methods.reset({ ticketList: get(stepData, "ticketList") });
    } else {
      setTicketList(ticketTypesDefault);
      methods.reset({ ticketList: ticketTypesDefault });
    }
  }, [stepData, ticketTypesDefault]);

  const handleSubmitInfo = async (sendData) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/ticketType/${entityName}`,
        {
          ticketTypes: sendData.ticketList.filter(
            (ticket) => ticket.name.trim() !== ""
          ),
        }
      );
      onNextStep();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handlePrevStep = () => {
    setStepValues({
      ticketList: ticketList,
    });

    onPrevStep();
  };

  const renderLeftArea = () => {
    return (
      <FormProvider {...methods}>
        <TicketListContainer
          ticketList={ticketList}
          setTicketList={setTicketList}
          showWarning={showWarning}
        ></TicketListContainer>
      </FormProvider>
    );
  };

  const renderRightArea = () => {
    return (
      <StyledRightImageContainer>
        {!isEmpty(ticketList) && (
          <StyledDropdownPreview>
            {ticketList.map((ticket) => (
              <StyledDropdownItem>{ticket.name}</StyledDropdownItem>
            ))}
          </StyledDropdownPreview>
        )}

        <StyledRightImage
          component="img"
          src={require("assets/img/create_ticket_preview.png")}
        ></StyledRightImage>
      </StyledRightImageContainer>
    );
  };

  const renderButtonArea = () => {
    return (
      <TrialSetStepButton
        onSave={methods.handleSubmit(handleSubmitInfo)}
        onBack={handlePrevStep}
        saveText="Next"
        isValid={isValid}
        isLoading={isPostLoading}
      />
    );
  };

  return (
    <>
      <TrialSetStepTemplate
        step={step}
        title={formatMessage({
          id: "service.onboarding.ticketSetting.title",
        })}
        titleHint={formatMessage({
          id: "service.onboarding.ticketSetting.description",
        })}
        renderLeftArea={renderLeftArea}
        renderButtonArea={renderButtonArea}
        renderRightArea={renderRightArea}
      ></TrialSetStepTemplate>
      <Prompt when={isDirty} />
    </>
  );
};

export default TrialMarketingSetStep3;
