import React, { useEffect, useState } from "react";
import { Modal, IconButton } from "@mui/material";
import _ from "lodash";
import { apiUrl } from "features/configure";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Token from "../Icons/Token";
import { Loading } from "features/common";
import { StyledTokenList, StyledSelectTokenPaper } from "./SelectToken.styles";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useSelector } from "react-redux";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";

const popular = {
  token: {
    1: [
      {
        symbol: "ETH",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
      {
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
      },
      {
        symbol: "USDC",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
      },
      {
        symbol: "DAI",
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        decimals: 18,
      },
      {
        symbol: "WBTC",
        address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
        decimals: 8,
      },
    ],
    10: [
      {
        symbol: "OP",
        address: "0x4200000000000000000000000000000000000042",
        decimals: 18,
      },
      {
        symbol: "ETH",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
      {
        symbol: "USDC",
        address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 6,
      },
      {
        symbol: "DAI",
        address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
      },
      {
        symbol: "WBTC",
        address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
        decimals: 8,
      },
    ],
    56: [
      {
        symbol: "BNB",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
      {
        symbol: "CAKE",
        address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        decimals: 18,
      },
      {
        symbol: "BUSD",
        address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        decimals: 18,
      },
      {
        symbol: "XVS",
        address: "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
        decimals: 18,
      },
      {
        symbol: "ALPACA",
        address: "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
        decimals: 18,
      },
    ],
    137: [
      {
        symbol: "Matic",
        address: "0x0000000000000000000000000000000000001010",
        decimals: 18,
      },
      {
        symbol: "QUICK",
        address: "0xB5C064F955D8e7F38fE0460C556a72987494eE17",
        decimals: 18,
      },
      {
        symbol: "QI",
        address: "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
        decimals: 18,
      },
    ],
    42161: [
      {
        symbol: "ETH",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
      {
        symbol: "DOPEX",
        address: "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55",
        decimals: 18,
      },
      {
        symbol: "GMX",
        address: "0x590020B1005b8b25f1a2C82c5f743c540dcfa24d",
        decimals: 18,
      },
      {
        symbol: "RDNT",
        address: "0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017",
        decimals: 18,
      },
    ],
    43114: [
      {
        symbol: "AVAX",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
      {
        symbol: "QI",
        address: "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
        decimals: 18,
      },
      {
        symbol: "JOE",
        address: "0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd",
        decimals: 18,
      },
    ],
    66: [],
    7001: [
      {
        symbol: "aZETA",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
    ],
    108: [
      {
        symbol: "TT",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
      },
    ],
  },
  nft: {
    1: [
      {
        symbol: "BAYC",
        address: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
        icon: "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s168",
      },
      {
        symbol: "CryptoPunks",
        address: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
        icon: "https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s168",
      },
      {
        symbol: "MAYC",
        address: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
        icon: "https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s168",
      },
      {
        symbol: "The Otherside",
        address: "0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258",
        icon: "https://lh3.googleusercontent.com/yIm-M5-BpSDdTEIJRt5D6xphizhIdozXjqSITgK4phWq7MmAU3qE7Nw7POGCiPGyhtJ3ZFP8iJ29TFl-RLcGBWX5qI4-ZcnCPcsY4zI=s168",
      },
      {
        symbol: "CloneX",
        address: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
        icon: "https://lh3.googleusercontent.com/XN0XuD8Uh3jyRWNtPTFeXJg_ht8m5ofDx6aHklOiy4amhFuWUa0JaR6It49AH8tlnYS386Q0TW_-Lmedn0UET_ko1a3CbJGeu5iHMg=s168",
      },
      {
        symbol: "Moonbirds",
        address: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
        icon: "https://lh3.googleusercontent.com/H-eyNE1MwL5ohL-tCfn_Xa1Sl9M9B4612tLYeUlQubzt4ewhr4huJIR5OLuyO3Z5PpJFSwdm7rq-TikAh7f5eUw338A2cy6HRH75=s168",
      },
      {
        symbol: "Meebits",
        address: "0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7",
        icon: "https://lh3.googleusercontent.com/d784iHHbqQFVH1XYD6HoT4u3y_Fsu_9FZUltWjnOzoYv7qqB5dLUqpGyHBd8Gq3h4mykK5Enj8pxqOUorgD2PfIWcVj9ugvu8l0=s168",
      },
      {
        symbol: "Doodles",
        address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
        icon: "https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s168",
      },
      {
        symbol: "Azuki",
        address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
        icon: "https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s168",
      },
      {
        symbol: "VeeFriends",
        address: "0xa3AEe8BcE55BEeA1951EF834b99f3Ac60d1ABeeB",
        icon: "https://lh3.googleusercontent.com/5y-UCAXiNOFXH551w5bWdZEYOCdHPwbqmcKb-xa3uVQEjQgxvih3HtZWSmzqDqd0uk7kIqFrZhw32Gt6xPBFg4t_n9BKhpou-dwnOg=s168",
      },
    ],
    10: [],
    56: [],
    137: [],
    42161: [],
    43114: [],
    66: [],
    7001: [],
    108: [],
  },
};
const SelectToken = ({
  open,
  handleClose,
  assetType,
  value,
  setValue,
  title,
  chainId,
  entityName,
}) => {
  const chainList = useSelector(selectEntityChainList);
  const [search, setSearch] = useState("");
  const [tokenSearchLoading, setTokenSearchLoading] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const tokens = _.get(tokenList, `${assetType}.${chainId}`);
  const filterList = search
    ? _.filter(tokens, function (o) {
        return (
          _.toUpper(o.symbol).includes(_.toUpper(search)) ||
          _.toUpper(o.name).includes(_.toUpper(search)) ||
          _.toUpper(search) == _.toUpper(o.address)
        );
      })
    : tokens;
  const getTokens = async (chainId, assetType) => {
    try {
      if (!tokens) {
        setTokenSearchLoading(true);
        const tokenResult = await axios.get(
          apiUrl +
            `/api/dataSource/${entityName}/assetList?chainId=${chainId}&assetType=${assetType}`
        );

        const data = _.map(tokenResult.data, (r) => {
          return {
            ...r,
            chainId,
            label: <Token data={{ ...r, chainId }} />,
            value: r.address,
          };
        });
        setTokenList({
          ...tokenList,
          [assetType]: { ...tokenList[assetType], [chainId]: data },
        });

        return data;
      }
      return tokens;
    } catch (err) {
      console.log(err);
    } finally {
      setTokenSearchLoading(false);
    }
  };

  useEffect(() => {
    setSearch("");
    if (chainId && assetType) getTokens(chainId, assetType);
  }, [chainId, assetType]);

  const changeSearchValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };

  const renderPopular = () => {
    const hasPopular = _.get(popular, `[${assetType}][${chainId}]`, []);
    if (hasPopular.length == 0) return;
    return (
      <div style={{ fontWeight: 700, fontSize: 14, padding: "0 20px" }}>
        <div>Popular</div>
        <div className="startRow">
          {_.map(hasPopular, (p, i) => (
            <div key={i} style={{ marginRight: 9, marginTop: 9 }}>
              <Token
                data={{ ...p, chainId }}
                margin={"2px 5px 2px 2px"}
                onClick={(v) => {
                  setValue(v);
                  handleClose();
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <StyledSelectTokenPaper>
        <div style={{ padding: 20, paddingBottom: 0 }}>
          <IconButton
            style={{ float: "right", padding: 0, color: "#555555" }}
            onClick={() => handleClose(false)}
          >
            <i className="meta-crm-icon-ic_cancel font-size-24" />
          </IconButton>
          <div
            style={{ fontWeight: 700, fontSize: 18, marginBottom: 18 }}
            className="startRow"
          >
            {title}
            <span
              style={{
                fontSize: 14,
                color: "#A8A8A8",
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              Chain :
            </span>
            <div
              style={{
                color: "#555555",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              {_.get(_.find(chainList, { value: chainId }), "label", "-")}
            </div>
          </div>
          <InputField
            value={search}
            placeholder={`Search Token`}
            onChange={changeSearchValue}
            fullWidth
            startAdornment={<SearchIcon style={{ color: "#A5A5A5" }} />}
          />
        </div>

        <div className="smhr"></div>
        {renderPopular()}
        <div style={{ marginTop: "10px" }} />
        <div
          style={{
            background: "#F8F8F8",
            padding: "14px 28px",
            height: 400,
            overflow: "auto",
            position: "relative",
            borderRadius: "0 0 5px 5px",
          }}
        >
          <Loading fullScreen={false} open={tokenSearchLoading} />
          <div>
            {_.map(filterList, (token, index) => {
              return (
                <StyledTokenList
                  key={index}
                  style={{
                    background:
                      value && value == token.address
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.6)",
                  }}
                  onClick={() => {
                    setValue(token);
                    handleClose();
                  }}
                >
                  <span>
                    <Token data={token} />
                  </span>
                </StyledTokenList>
              );
            })}
          </div>
        </div>
      </StyledSelectTokenPaper>
    </Modal>
  );
};

export default SelectToken;
