import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { Radio } from "@metacrm/metacrm-material-ui/dist/Radio";
import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import { exportCSV, formatCSV } from "features/helpers/utils";
import PureModal from "features/metadesk/campaign/campaignList/component/PureModal";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useState } from "react";
import { useOutletContext } from "react-router";

export default function ExportModal({ handleClose, selected }) {
  const [exportFrom, setExportFrom] = useState("CurrentChoose");
  const [exportTo, setExportTo] = useState("CSV");
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  async function handleConfirm() {
    try {
      let postData = {
        userList: selected.map((item) => item._id),
      };
      const { data } = await axios.post(
        `${apiUrl}/api/userSource/${entityName}/export`,
        postData
      );
      exportCSV(formatCSV(data));
      dispatchSnackbar({
        message: "Export success.",
        variant: "info",
      });
    } catch (err) {
      dispatchSnackbar({
        message: "Export failed.",
        variant: "error",
      });
    } finally {
      handleClose();
    }
  }

  return (
    <PureModal
      closeBtn={true}
      open={true}
      onClose={handleClose}
      maxWidth="500px"
    >
      <Box
        p={"32px 40px"}
        color="#383538"
        fontSize="18px"
        fontWeight="bold"
        borderBottom={"1px solid #D6D6D6"}
      >
        Export
      </Box>
      <Box p={"21px 40px"}>
        <Box mb="12px" color="#777">
          Export from
        </Box>
        <Box my="10px">
          <Radio
            label="Current Choose"
            checked={exportFrom === "CurrentChoose"}
            onChange={() => setExportFrom("CurrentChoose")}
          />
        </Box>
        {/* <Box my="10px">
          <Radio
            label="Current Dataset"
            checked={exportFrom === "CurrentDataset"}
            onChange={() => setExportFrom("CurrentDataset")}
          />
        </Box> */}
        <Box my="12px" color="#777">
          Export To
        </Box>
        <Box my="10px">
          <Radio
            label="CSV"
            checked={exportTo === "CSV"}
            onChange={() => setExportTo("CSV")}
          />
        </Box>
        {/* <Box my="10px">
          <Radio
            label="Mailchimp"
            checked={exportTo === "Mailchimp"}
            onChange={() => setExportTo("Mailchimp")}
          />
        </Box> */}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        p="22px 40px"
        borderTop={"1px solid #D6D6D6"}
      >
        <Button onClick={handleClose} variant="text">
          Cancel
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          disabled={selected.length === 0}
          onClick={handleConfirm}
        >
          Export
        </Button>
      </Box>
    </PureModal>
  );
}
