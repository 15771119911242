import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const MagicWandWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  columnGap: 20,

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));

export const StyledInputHint = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  marginTop: "4px",
  color: theme.customColors.purple[500],
  display: "flex",
}));

export const StyledLayoutItem = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "fit-content",
  background: "white",
  padding: "20px",
  marginTop: "20px",
  boxShadow: `0px 1.2824229001998901px 6.412113666534424px 0px #0000001A`,
}));

export const SimulationWrapper = styled(StyledLayoutItem, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
