import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import ImagePreview from "features/metadesk/components/ImagePreview";
import Box from "@mui/material/Box";
import {
  formatWidgetDateAndTime,
  getShortAddress,
} from "features/helpers/utils";

import { get, isEmpty } from "lodash-es";

import { StyledDescription } from "./EditNotificationPreview.styles";
import Linkify from "linkify-react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectMarketingModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { makeCustomTrackingLink } from "../createCampaign/utils";

const options = {
  target: (href) => {
    const { hostname } = window.location;
    if (hostname && href.includes(hostname)) {
      return "";
    }
    return "_blank";
  },
  attributes: {
    rel: "noreferrer noopener",
  },
};

const EditNotificationPreviewNew = ({
  data,
  startFrom,
  imgs = [],
  minHeight = null,
  name,
}) => {
  const swiperRefAsset = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const channels = useSelector(selectMarketingModule);
  const [selectChannel, setSelectChannel] = useState("");

  useEffect(() => {
    if (!isEmpty(data.engagementDistributionSettings)) {
      setSelectChannel(data.engagementDistributionSettings[0].channel);
    }
  }, [data.engagementDistributionSettings]);

  const subtitle = {
    widget: "global.serviceWidget.subtitle",
    xmtp: "integration.xmtp.name",
    push: "integration.push.name",
  };

  const renderLink = (index) => {
    const { linkSetting, shortLink, linkTitle, trackingLink } = data
      .engagementDistributionSettings[index] || {
      linkSetting: {},
      shortLink: "",
      linkTitle: "",
      trackingLink: "",
    };

    if (linkSetting && Object.keys(linkSetting).length > 0) {
      return (
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={trackingLink}
          target="_blank"
          rel="noreferrer"
        >
          {linkSetting?.type === "namingLink"
            ? linkSetting?.linkTitle
            : "https://bit.ly/" + linkSetting?.shortLink}
        </Box>
      );
    }
    if (linkTitle)
      return (
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={trackingLink}
          target="_blank"
          rel="noreferrer"
        >
          {linkTitle}
        </Box>
      );
    if (shortLink) {
      return (
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={shortLink}
          target="_blank"
          rel="noreferrer"
        >
          {shortLink}
        </Box>
      );
    }
    return (
      <>
        {trackingLink ? (
          <Box
            sx={{
              color: theme.customColors.blue[500],
              fontSize: "12px",
              textDecoration: "underline",
              marginTop: "8px",
            }}
            component={"a"}
            href={trackingLink}
            target="_blank"
            rel="noreferrer"
          >
            {trackingLink}
          </Box>
        ) : (
          data.engagementDistributionSettings[index].websiteUrl &&
          "https://" +
            data.engagementDistributionSettings[index].websiteUrl +
            makeCustomTrackingLink(
              data.engagementDistributionSettings,
              name,
              index
            )
        )}
      </>
    );
  };

  const renderWidget = (index) => (
    <Box
      sx={{
        backgroundImage: `url(${require("assets/img/widget_1.png")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        padding: "100px 30px",
      }}
    >
      <Box
        className="card"
        sx={{
          height: "410px",
          maxHeight: "410px",
          padding: 0,
          overflowY: "auto",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            background: theme.customColors.blue[600],
            height: 48,
            width: "100%",
            borderRadius: "5px 5px 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.customColors.white,
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          {formatMessage({ id: "global.serviceWidget.subtitle" })}
        </Box>
        <div style={{ padding: 13, overflowWrap: "break-word" }}>
          <Box
            fontSize={"12px"}
            color={(theme) => theme.customColors.grey[500]}
            mb="5px"
          >
            {formatWidgetDateAndTime(startFrom)}
          </Box>
          <div className="tableValue">{data.engagementTitle}</div>

          <StyledDescription sx={{ marginTop: "20px" }}>
            <Linkify options={options}>{data.engagementDescription}</Linkify>
          </StyledDescription>
          {imgs.length > 0 && (
            <div style={{ marginTop: 5 }}>
              <ImagePreview
                imgs={imgs}
                fullWidth={true}
                editable={false}
                style={{ border: "0px" }}
              />
            </div>
          )}
          {_.get(data, "urls", []).length > 0 && (
            <div style={{ marginTop: 5 }}>
              <a
                href={_.get(data, "urls[0].link")}
                target="_blank"
                rel="noreferrer"
              >
                {_.get(data, "urls[0].title")}
              </a>
            </div>
          )}
          <Box
            sx={{
              color: theme.customColors.blue[500],
              fontSize: "12px",
              textDecoration: "underline",
              marginTop: "8px",
            }}
            component={"a"}
            href={data.engagementDistributionSettings[index]?.trackingLink}
            target="_blank"
            rel="noreferrer"
          >
            {renderLink(index)}
          </Box>
        </div>
      </Box>
    </Box>
  );

  const renderXmtp = (index) => (
    <Box
      sx={{
        backgroundImage: `url(${require("assets/img/XMTP.png")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        padding: "57px 17px",
      }}
    >
      <Box
        sx={{
          height: "510px",
          maxHeight: "510px",
          padding: 0,
          overflowY: "auto",
          marginBottom: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            background: theme.customColors.grey[200],
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.customColors.grey[600],
            fontSize: "15px",
            fontWeight: "600",
            py: 2,
            position: "sticky",
            top: 0,
          }}
        >
          {getShortAddress(channels.xmtp?.address)}
        </Box>
        <div
          style={{
            overflowWrap: "break-word",
            flex: 1,
          }}
        >
          <Box
            fontSize={"12px"}
            fontWeight={500}
            color={(theme) => theme.customColors.grey[500]}
            bgcolor={(theme) => theme.customColors.white}
            p={2.5}
            mb="5px"
          >
            <Box>{data.engagementTitle || "Give a title"}</Box>

            <Box sx={{ marginTop: "20px" }}>
              <Linkify options={options}>
                {data.engagementDescription || "Give a description"}
              </Linkify>
            </Box>
            {imgs.length > 0 && (
              <div style={{ marginTop: 5 }}>
                <ImagePreview
                  imgs={imgs}
                  fullWidth={true}
                  editable={false}
                  style={{ border: "0px" }}
                />
              </div>
            )}
            {_.get(data, "urls", []).length > 0 && (
              <div style={{ marginTop: 5 }}>
                <a
                  href={_.get(data, "urls[0].link")}
                  target="_blank"
                  rel="noreferrer"
                >
                  {_.get(data, "urls[0].title")}
                </a>
              </div>
            )}
            <Box fontSize={"10px"} mt={2.5}>
              {formatWidgetDateAndTime(startFrom || new Date())}
            </Box>

            <Box
              sx={{
                color: theme.customColors.blue[500],
                fontSize: "12px",
                textDecoration: "underline",
                marginTop: "8px",
              }}
            >
              {renderLink(index)}
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const renderPush = (index) => (
    <Box
      sx={{
        backgroundImage: `url(${require("assets/img/XMTP.png")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        padding: "57px 17px",
      }}
    >
      <Box
        sx={{
          height: "510px",
          maxHeight: "510px",
          padding: 0,
          overflowY: "auto",
          marginBottom: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.customColors.grey[200],
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            color: theme.customColors.grey[600],
            fontSize: "15px",
            fontWeight: "600",
            py: 2,
            position: "sticky",
            top: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.customColors.grey[200],
            }}
          >
            {getShortAddress(channels.push?.address)}
          </Box>
        </Box>
        <div
          style={{
            overflowWrap: "break-word",
            // backgroundColor: theme.customColors.grey[200],
            flex: 1,
          }}
        >
          <Box
            fontSize={"12px"}
            fontWeight={500}
            color={(theme) => theme.customColors.grey[500]}
            bgcolor={(theme) => theme.customColors.white}
            p={2.5}
            mb="5px"
          >
            <Box>{data.engagementTitle || "Give a title"}</Box>

            <Box sx={{ marginTop: "20px" }}>
              <Linkify options={options}>
                {data.engagementDescription || "Give a description"}
              </Linkify>
            </Box>
            {imgs.length > 0 && (
              <div style={{ marginTop: 5 }}>
                <ImagePreview
                  imgs={imgs}
                  fullWidth={true}
                  editable={false}
                  style={{ border: "0px" }}
                />
              </div>
            )}
            {_.get(data, "urls", []).length > 0 && (
              <div style={{ marginTop: 5 }}>
                <a
                  href={_.get(data, "urls[0].link")}
                  target="_blank"
                  rel="noreferrer"
                >
                  {_.get(data, "urls[0].title")}
                </a>
              </div>
            )}
            <Box fontSize={"10px"} mt={2.5}>
              {formatWidgetDateAndTime(startFrom || new Date())}
            </Box>

            <Box
              sx={{
                color: theme.customColors.blue[500],
                fontSize: "12px",
                textDecoration: "underline",
                marginTop: "8px",
              }}
            >
              {renderLink(index)}
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const renderPreviewTitle = ({ type }) => {
    switch (type) {
      case "flyer":
        return "Ad Format preview";
      case "widget":
        return "Widget Format preview";
      default:
        return "Ad Format preview";
    }
  };

  const renderTypeButton = ({ type }) => {
    if (type !== "widget") {
      return (
        <Box
          sx={{
            display: "inline-block",
            color: theme.customColors.grey[500],
            backgroundColor: "white",
            padding: "5px 24px",
            borderRadius: "30px",
            cursor: "pointer",
            marginRight: "12px",
            textTransform: "capitalize",
            ...(selectChannel === type && {
              color: theme.customColors.purple[500],
              backgroundColor: theme.customColors.purple[300],
            }),
          }}
          onClick={() => setSelectChannel(type)}
        >
          {type}
        </Box>
      );
    }
  };

  const renderPreview = ({ channel, index }) => {
    switch (channel) {
      case "push":
        return renderPush(index);
      case "xmtp":
        return renderXmtp(index);
      case "widget":
        return renderWidget(index);
      default:
        return renderPush(index);
    }
  };

  return (
    <Box minHeight={minHeight ? minHeight : "80vh"}>
      <Box padding={"0px 32px 20px"} width="100%">
        <Box mb={1}>{renderPreviewTitle({ type: data.type })}</Box>
        <Box color={(theme) => theme.customColors.grey[600]} mb="24px">
          Simply upload images, ad title, and illustrate , and MetaCRM AI will
          automatically generate responsive ad formats based on the websites and
          apps users visit
        </Box>

        <Box
          display={"flex"}
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {data?.engagementDistributionSettings.map(
            (distributionItem, index) => (
              <Box key={index}>
                <Box>
                  {renderTypeButton({ type: distributionItem.channel })}
                </Box>
              </Box>
            )
          )}
        </Box>

        <Box width="100%" maxWidth={"calc(100vw - 150px)"}>
          {data?.engagementDistributionSettings.map(
            (distributionItem, index) => (
              <React.Fragment key={index}>
                {selectChannel === distributionItem.channel &&
                  renderPreview({ channel: distributionItem.channel, index })}
              </React.Fragment>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditNotificationPreviewNew;
