import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const BillingContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ sx }) => ({
  border: "1px solid #E6E6E6",
  width: "100%",
  padding: "16px",
  borderRadius: "8px",
  margin: "16px 0",
  position: "relative",
  background: "white",
  maxWidth: "780px",
  ...sx,
}));

export const BillingBtn = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ sx }) => ({
  border: "0.5px solid #A5A5A5",
  borderRadius: "3px",
  fontSize: "13px",
  color: "#555",
  width: "fit-content",
  padding: "4px",
  marginLeft: "4px",
  textAlign: "center",
  cursor: "pointer",
  ...sx,
}));
