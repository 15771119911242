import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const StyledSearchAddressInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  position: "relative",
}));
StyledSearchAddressInfo.displayName = "StyledSearchAddressInfo";

const StyledSearchContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "22px 20px",
  borderBottom: `1px solid #e6e6e6`,
  borderRight: `1px solid #e6e6e6`,
}));

StyledSearchContainer.displayName = "StyledSearchContainer";

const StyledSearchBar = styled(CustomOutlinedInput, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "&.MuiFormControl-root": {
    borderRadius: "3px",
    "& i": {
      color: theme.customColors.grey[500],
      fontWeight: "500",
    },
    "& .MuiInputBase-input": {
      fontSize: "12px",
      fontWeight: "500",
      "&::placeholder": {
        color: theme.customColors.grey[500],
      },
    },
  },
}));

StyledSearchBar.displayName = "StyledSearchBar";

const StyledSearchContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  overflow: `hidden scroll`,
}));

StyledSearchContentContainer.displayName = "StyledSearchContentContainer";

export {
  StyledSearchAddressInfo,
  StyledSearchContainer,
  StyledSearchBar,
  StyledSearchContentContainer,
};
