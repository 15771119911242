import { Box } from "@mui/material";
import React from "react";
import ReactECharts from "echarts-for-react";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { rainbowColors } from "utils/constants";
import { customerColors } from "features/configure/chartColors";

// 註解一下
// Circle Chart Component
// props: reverse - boolean - default false - if true, the circle chart will be displayed in reverse order.
// props: data - array - required - the data to be displayed in the circle chart.

export default function BehaviorCircleChart({
  reverse = false,
  data = [],
  chartName,
  totalWallet,
  titleSx,
  loading = false,
}) {
  const colors = customerColors.small;
  const totalValue = data?.reduce((sum, item) => sum + parseInt(item.value), 0);
  let updateData = data?.map((item) => {
    return {
      name: item.name || item.label,
      value: (100 * (parseFloat(item.value) / totalValue)).toFixed(1),
    };
  });

  const option = {
    color: colors,
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c}%",
    },

    series: [
      {
        name: chartName,
        type: "pie",
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 2,
        },
        radius: ["50%", "70%"],
        center: ["50%", "50%"], // 中心位置調整為百分比，以便適應不同大小的容器
        label: {
          show: true,
          position: "center",
          formatter: [`{a|Total}`, "{b|Wallets}", `{c|${totalWallet}}`].join(
            "\n"
          ),
          rich: {
            a: {
              align: "center",
              fontSize: 14,
              fontWeight: "bold",
            },
            b: {
              align: "center",
              fontSize: 14,
              fontWeight: "bold",
            },
            c: {
              align: "center",
              fontSize: 18,
              fontWeight: "bold",
              color: "#7B61FF",
              padding: [10, 0, 0, 0],
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: updateData,
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "250px",
        backgroundColor: "#FFF",
        borderRadius: "8px",
        display: "flex",
        flexDirection: reverse ? "row-reverse" : "row",
        alignItems: "center",
        position: "relative",
      }}
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Box
            sx={{
              width: "60%",
              color: "#777",
              paddingRight: reverse ? "12px" : 0,
              paddingLeft: reverse ? "12px" : "50px",
            }}
          >
            <Box
              fontSize="16px"
              fontWeight="500"
              mb="24px"
              color="#555555"
              sx={{ ...titleSx }}
            >
              {chartName}
            </Box>
            <Grid container>
              {updateData?.slice(0, 10).map((item, index) => (
                <Grid
                  xs={6}
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "12px",
                    px: "4px",
                  }}
                >
                  <Box
                    sx={{
                      height: "8px",
                      width: "13px",
                      borderRadius: "2px",
                      backgroundColor: colors[index],
                      mb: "1px",
                      mr: "8px",
                      flexShrink: 0,
                    }}
                  />
                  <Box key={index} sx={{ fontSize: "12px", color: "#383538" }}>
                    {item.name}: {item.value}%
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              width: "40%",
              height: "100%",
              flexShrink: 0,
              padding: "10px",
            }}
          >
            <ReactECharts
              option={option}
              autoResize={true}
              style={{ height: "100%", width: "100%" }}
              notMerge={true}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
