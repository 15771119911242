import React from "react";
import { Grid, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import {
  StyledSettingWidgetStepContainerOutSide,
  StyledSettingWidgetStepContainer,
  StyledSettingWidgetStepNumber,
  StyledSettingWidgetStepTitle,
  StyledSettingWidgetStepDescribe,
} from "../IntegrationWidgetSetting.styles";
import { StyledTrackingPaper } from "./TrackignSetting.styles";
const SDKSettingSteps = ({ SDKsteps, isIsolateComponent, extra }) => {
  return (
    <Box>
      <StyledTrackingPaper>
        {SDKsteps.map((settingStep, index) => (
          <StyledSettingWidgetStepContainerOutSide key={settingStep.title}>
            <StyledSettingWidgetStepContainer
              container
              spacing={1}
              isIsolateComponent={isIsolateComponent}
            >
              <Grid item xs={12} display={"flex"} alignItems={"center"}>
                {settingStep.stepNumber && (
                  <StyledSettingWidgetStepNumber>
                    {settingStep.stepNumber}
                  </StyledSettingWidgetStepNumber>
                )}

                <StyledSettingWidgetStepTitle color={settingStep.titleColor}>
                  {settingStep.title}
                </StyledSettingWidgetStepTitle>
                {settingStep.titleHint && (
                  <Box
                    display={"inline-block"}
                    fontSize={"16px"}
                    color={(theme) => theme.customColors.grey[500]}
                    mr={1}
                  >
                    {settingStep.titleHint}
                  </Box>
                )}
                {settingStep.status && settingStep.status()}
                <>{settingStep.titleContent}</>
                {settingStep.titleButton && settingStep.titleButton}
              </Grid>
              <Grid item xs={12}>
                <StyledSettingWidgetStepDescribe>
                  {settingStep.describe}
                </StyledSettingWidgetStepDescribe>
              </Grid>
              {settingStep.content}
            </StyledSettingWidgetStepContainer>
          </StyledSettingWidgetStepContainerOutSide>
        ))}
      </StyledTrackingPaper>
      {extra}
    </Box>
  );
};

export default SDKSettingSteps;
