import styled from "styled-components";

export const StyledIconImg = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  margin-left: ${({ marginLeft }) => marginLeft};
`;

export const StyledWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledChainIconImg = styled.img`
  position: absolute;
  top: 0px;
  left: -3px;
  width: 9px;
  height: 9px;
  margin-left: ${({ marginLeft }) => marginLeft};
  border-radius: 10px;
`;

export const NFTIconImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-left: ${({ marginLeft }) => marginLeft};
  border-radius: 50%;
`;
