import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";

import {
  StyledContainerTitle,
  StyledTitleIcon,
  StyledWhiteCardContainer,
} from "./component/CampaignCreation.styles";

import axios from "axios";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl } from "features/configure";
import { get, isEmpty } from "lodash-es";

import { AddButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomStepper from "components/CustomStepper/CustomStepper";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import Objective from "./component/Objective";
import Schedule from "./schedule";
import _ from "lodash";

export default function CampaignStep2({ step, stepName }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const { stepData, setStepValues, handleSetIsValid, isTracker } = useContext(
    CampaignCreationContext
  );

  const { objectives, campaignContentSets } = stepData;
  const goal = _.get(campaignContentSets, `${0}.goal`);

  const theme = useTheme();
  const [latestObjectives, setLatestObjectives] = useState([]);
  const [isLatestObjectiveLoading, setIsLatestObjectiveLoading] =
    useState(false);

  const methods = useForm({
    defaultValues: {
      objectiveList: [],
      name: "",
      description: "",
    },
    mode: "onChange",
  });
  const { append, replace, remove } = useFieldArray({
    control: methods.control,
    name: "objectiveList",
  });

  const {
    formState: { errors: errorsInfo, isValid },
  } = methods;

  useEffect(() => {
    if (!isEmpty(stepData)) {
      if (stepData.name) {
        methods.setValue("name", stepData.name, { shouldValidate: true }); // 設置 name 字段
      }
      methods.setValue("description", stepData.description); // 設置 description 字段
    }
  }, [stepData]);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setStepValues({ [name]: value });
  };

  useEffect(() => {
    if (isEmpty(objectives)) {
      setStepValues({
        objectives: [
          {
            type: "",
            chainId: "",
            smartContractAddress: "",
            smartContractABI: "",
            method: null,
            _id: uuidv4(),
          },
        ],
      });
    } else {
      const stepValuesWithoutId = objectives.map((item) => {
        return {
          type: item.type,
          chainId: item.chainId,
          smartContractAddress: item.smartContractAddress,
          smartContractABI: item.smartContractABI,
          method: item.method,
        };
      });
      replace(stepValuesWithoutId);
    }
  }, []);

  useEffect(() => {
    const isNextValid = isValid;

    handleSetIsValid(isNextValid);
  }, [isValid, objectives]);

  const handleAddObjective = () => {
    setStepValues({
      objectives: [
        ...objectives,
        {
          type: "",
          chainId: "",
          smartContractAddress: "",
          smartContractABI: "",
          method: null,
          _id: uuidv4(),
        },
      ],
    });
  };

  const handleDeleteObjective = (targetId, objectiveIndex) => {
    const newArr = objectives.filter((item) => item._id !== targetId);
    setStepValues({ objectives: newArr });
    remove(objectiveIndex);
  };

  const handleGetLatestObjective = async () => {
    setIsLatestObjectiveLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/objective/latest`
      );
      setLatestObjectives(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLatestObjectiveLoading(false);
    }
  };

  useEffect(() => {
    handleGetLatestObjective();
  }, []);

  const handleChangeStartFrom = (value) => {
    setStepValues({ endAt: "" });
    setStepValues({ startFrom: value });
  };

  const handleChangeEndAt = (value) => {
    setStepValues({ endAt: value });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          {isTracker ? (
            <>
              <Box className="title" mb={1}>
                {formatMessage({
                  id: "analytics.attributionLink.settingsSecondStep.headerTitle",
                })}
              </Box>

              <Box className="description" mb={"22px"}>
                {formatMessage({
                  id: "analytics.attributionLink.settingsSecondStep.headerDescription",
                })}
              </Box>
            </>
          ) : (
            <>
              <Box className="title" mb={1}>
                {formatMessage(
                  {
                    id: "campaign.creation.title",
                  },
                  {
                    step: <span style={{ color: "#7B61FF" }}> {step}/3</span>,
                  }
                )}
              </Box>
              <Box className="description" mb={"22px"}>
                {formatMessage({ id: "campaign.creation.description" })}
              </Box>
            </>
          )}
        </Box>

        <>
          <StyledWhiteCardContainer
            display={"flex"}
            justifyContent={"center"}
            sx={{
              paddingBottom: "20px",
              paddingTop: "42px",
              marginBottom: "20px",
            }}
          >
            <Box width="50%">
              <CustomStepper
                steps={stepName}
                activeStep={step - 1}
              ></CustomStepper>
            </Box>
          </StyledWhiteCardContainer>
          <StyledWhiteContainer sx={{ marginBottom: "20px", padding: 0 }}>
            {/* name input */}
            <StyledContainerTitle>
              <StyledTitleIcon>
                <i className="meta-crm-icon-ic_file_outline font-size-18" />
              </StyledTitleIcon>
              {formatMessage({
                id: "campaignDetail.title",
              })}
            </StyledContainerTitle>
            <div className="mhr" style={{ margin: 0 }}></div>

            <Box sx={{ padding: "20px" }}>
              <Box sx={{ marginBottom: "20px" }}>
                <InputField
                  title={formatMessage({
                    id: "campaignBuild.info.campaignName",
                  })}
                  isRequired={true}
                  placeholder={formatMessage({
                    id: "campaignBuild.firstStep.campaignName.placeholder",
                  })}
                  name="name"
                  value={stepData.name}
                  onChange={handleFieldChange}
                  errors={get(errorsInfo, "name.message")}
                  control={methods.control}
                  maxLength={50}
                  rules={{
                    required: {
                      value: true,
                      message:
                        formatMessage({ id: "global.commonWord.name" }) +
                        formatMessage({ id: "global.error.required" }),
                    },
                  }}
                />
              </Box>
              {/* description input */}
              <Box>
                <InputField
                  title={formatMessage({
                    id: "campaigns.header.campaignDescription",
                  })}
                  multiline={true}
                  rows={8}
                  maxLength={1000}
                  placeholder={formatMessage({
                    id: "global.column.description",
                  })}
                  name="description"
                  value={stepData.description}
                  onChange={handleFieldChange}
                />
              </Box>
            </Box>
          </StyledWhiteContainer>

          <Schedule
            startFrom={stepData.startFrom}
            endAt={stepData.endAt}
            onChangeStartFrom={handleChangeStartFrom}
            onChangeEndAt={handleChangeEndAt}
          ></Schedule>
        </>

        {goal !== "message" && (
          <StyledWhiteCardContainer>
            <StyledContainerTitle>
              <StyledTitleIcon>
                <i className="meta-crm-icon-ic_objective font-size-18" />
              </StyledTitleIcon>
              {formatMessage({ id: "campaignBuild.thirdStep.subtitle" })}
            </StyledContainerTitle>
            <div className="mhr" style={{ margin: 0 }}></div>
            <StyledWhiteContainer>
              {objectives.map((objectiveItem, index) => (
                <Objective
                  objectiveIndex={index}
                  key={objectiveItem._id}
                  onDelete={handleDeleteObjective}
                  latestObjectives={latestObjectives}
                  formData={stepData}
                  setFormData={setStepValues}
                ></Objective>
              ))}

              <Box
                sx={{
                  border: `1px dashed ${theme.customColors.grey[400]}`,
                  mb: 3,
                  mt: "14px",
                }}
              ></Box>
              <AddButton color="#E6E6E6" onClick={handleAddObjective}>
                {formatMessage({
                  id: "campaignBuild.thirdStep.addObjective.btn",
                })}
              </AddButton>
            </StyledWhiteContainer>
          </StyledWhiteCardContainer>
        )}
      </FormProvider>
    </>
  );
}
