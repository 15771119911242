import React from "react";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  inboxTicketType: {
    background: "#F8F8F8",
    borderRadius: 3,
    fontSize: 11,
    display: "inline-block",
    width: "65px",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    padding: "4px 5px",
    textAlign: "center",
    position: "relative",
  },
}));

const TicketStatus = ({ status }) => {
  const classes = useStyles();
  if (!status) return <span />;

  let style = {};
  if (status == "Done") style.background = "#C5C5C5";

  return (
    <span className={classes.inboxTicketType} style={style}>
      {_.toUpper(status)}
    </span>
  );
};

export default TicketStatus;
