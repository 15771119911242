import * as React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled/macro";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTheme } from "@mui/styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box } from "@mui/material";

export default function CustomStepper({ steps, activeStep, sx }) {
  const theme = useTheme();
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.customColors.purple[500],
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.customColors.purple[500],
        borderTopStyle: "solid",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.customColors.grey[500],
      borderTopWidth: 1,
      marginLeft: "-13.5px",
      marginRight: "-13.5px",
      borderTopStyle: "dashed",
    },
    "&.Mui-active": {
      [`& .${stepConnectorClasses.line}`]: {
        borderTopStyle: "solid",
        borderColor: theme.customColors.purple[500],
      },
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.customColors.grey[500],
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.customColors.purple[500],
    }),

    "& .MuiSvgIcon-root": {
      width: "8px",
      height: "8px",
    },

    "& .QontoStepIcon-completedIcon": {
      color: theme.customColors.purple[500],
      zIndex: 1,
      width: 8,
      height: 8,
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <FiberManualRecordIcon className="QontoStepIcon-completedIcon" />
        ) : (
          <RadioButtonUncheckedIcon />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
    <Box sx={sx}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{
                ".MuiStepLabel-alternativeLabel": {
                  "& .Mui-active ": {
                    color: theme.customColors.purple[500],
                    fontWeight: 500,
                  },
                  "& .Mui-completed ": {
                    color: theme.customColors.grey[500],
                  },
                  "& .MuiStepLabel-alternativeLabel": {
                    marginTop: "-45px",
                  },
                  color: theme.customColors.grey[500],
                  fontSize: "12px",
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
