import React from "react";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import moment from "moment";
import { Grid } from "@mui/material";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { useTheme } from "@mui/styles";
import { formatDate, getShortAddress } from "features/helpers/utils";
import { useSelector } from "react-redux";
import {
  selectEntityChainList,
  selectEcosystemName,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";

const useStyles = makeStyles((theme) => ({
  row: {
    background: "white",
    padding: "10px",
    fontSize: 12,
    borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
  },
}));

const Transaction = ({ data, renderTxContentBasedOnABI, chains }) => {
  const classes = useStyles();
  const theme = useTheme();
  const chainList = useSelector(selectEntityChainList);
  const ecosystemName = useSelector(selectEcosystemName);

  const renderTx = (row, i) => {
    if (!row._id) return <div />;
    const method = _.get(
      (_.get(row, "method", "") || "").split("("),
      `[0]`,
      ""
    );
    const chain =
      _.find(chains, { id: _.get(row, "chainId", 1) }) || chainList[0];

    const transactionLink = (() => {
      if (ecosystemName === "EVM") {
        return `${chain?.explorer}/tx/${row.hash}`;
      } else if (ecosystemName === "Aptos") {
        return `${chain?.explorer}/transaction/${row.hash}`;
      } else if (ecosystemName === "Solana") {
        return `${chain?.explorer}/tx/${row.hash}`;
      }
    })();
    return (
      <div key={i}>
        <Grid container alignItems="center" className={classes.row}>
          <Grid item xs={5}>
            <a href={transactionLink} target="_blank" rel="noreferrer">
              {chains && (
                <div
                  className="startRow"
                  style={{ fontSize: 8, color: "#555555" }}
                >
                  {`${chain?.name} Chain`}
                  <img
                    style={{ width: 10, height: 10, margin: 3 }}
                    src={`${chain?.logo_url}`}
                  />
                </div>
              )}
              <div
                style={{
                  color: "#555555",
                  marginBottom: 3,
                  fontWeight: 600,
                }}
              >
                <IconTooltip
                  icon={getShortAddress(_.upperFirst(method))}
                  content={_.upperFirst(method)}
                  sx={{
                    color: theme.customColors.grey[700],
                    fontSize: "12px",
                  }}
                ></IconTooltip>
              </div>
              <div style={{ color: "#7B61FF", fontSize: 9 }}>
                {formatDate(row.timestamp * 1000, { time: true })}
              </div>
            </a>
          </Grid>

          <Grid item xs={7}>
            {renderTxContentBasedOnABI ? (
              renderTxContentBasedOnABI(row, method)
            ) : (
              <div />
            )}
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      {data.map((row, i) => {
        return renderTx(row, i);
      })}
    </div>
  );
};

export default Transaction;
