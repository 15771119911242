import _ from "lodash";

export function getUserAvatarIcon(userId, theme, didName) {
  if (didName) {
    return "spaceId.png";
  }
  if (theme && theme.userIcon) {
    return theme.userIcon;
  }
  if (_.isEmpty(userId)) {
    return "roles_ic/ic_alpaca-0.svg";
  }
  const length = userId.length;
  // Extract the last two characters if available, otherwise the last one
  const lastChars = userId.slice(Math.max(length - 2, 0));

  // Convert the last characters to a numeric value
  let totalCharCode = 0;
  for (let char of lastChars) {
    const num = char.charCodeAt(0);
    totalCharCode += num;
  }

  const imageIndex = totalCharCode % 30;
  return `roles_ic/ic_alpaca-${imageIndex}.svg`;
}

export function getNFTImageData(data) {
  let url = _.get(data, "normalized_metadata.image");
  if (_.startsWith(url, "ipfs://")) {
    try {
      url = "https://ipfs.io/ipfs/" + url.split("://")[1];
    } catch (err) {}
  }
  if (_.includes(url, "https://ikzttp.mypinata.cloud/ipfs/")) {
    try {
      url = "https://ipfs.io/ipfs/" + url.split("/ipfs/")[1];
    } catch (err) {}
  }
  if (!url) {
    return require("assets/img/nft_placeholder.png");
  }

  return url;
}

export function openWindow(url, fullScreen = false) {
  const width = 800;
  const height = 1200;
  const left = window.innerWidth - width / 2;
  const top = window.innerHeight / 2 - height / 2;
  if (!url) return;
  if (fullScreen) {
    window.location.replace(url);
  } else {
    window.open(
      url,
      "popup",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  }
}
