import styled from "@emotion/styled/macro";
import { Button, IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import Box from "@mui/material/Box";
import { Link, useLocation } from "react-router-dom";

import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledMsgItemContainerOuter = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse, isFirst }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "self-end",
  marginBottom: "20px",
  justifyContent: "flex-end",
  marginRight: "12px",
  ...(isFirst && { marginTop: "20px" }),
  ...(isAdamResponse && {
    justifyContent: "flex-start",
    marginRight: "0px",
    marginLeft: "12px",
    alignItems: "baseline",
  }),
}));

export const StyledChatAvatar = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse }) => ({
  width: "20px",
  height: "20px",
  marginRight: "4px",

  "& img": {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
}));

export const StyledTimeStamp = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[500],
  textAlign: "right",
  ...(isAdamResponse && { textAlign: "left" }),
}));

export const StyledMsgItemContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse, status }) => ({
  position: "relative",
  background: theme.customColors.purple[300],
  borderRadius: "10px",
  padding: "8px 12px",
  color: theme.customColors.purple[500],
  maxWidth: "90%",
  wordBreak: "break-word",
  marginBottom: "3px",
  width: "fit-content",
  fontSize: "16px",
  ...(!isAdamResponse && {
    marginLeft: "auto",
  }),
  ...(isAdamResponse && {
    color: theme.customColors.grey[700],
    background: theme.customColors.grey[200],
  }),
  ...(status === "sending" && {
    opacity: 0.5,
  }),
}));

export const StyledMsgType = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "16px",
  marginBottom: "16px",
  fontWeight: 700,
}));

export const StyledMsgItemMsgText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse }) => ({
  "& a": {
    textDecoration: "underline",
    color: theme.customColors.purple[500],
  },
  "& .react-datepicker": {
    display: "inline-flex",
    "&__triangle": {
      fill: "#fff",
      color: "#fff",
    },
  },
}));

export const StyledMsgItemImgArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse }) => ({
  display: "flex",
}));

export const StyledMsgItemImgContainer = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse }) => ({
  marginTop: "5px",
  marginBottom: "5px",
  //   color: theme.entityColor.primaryColor,
  "&:not(:last-child)": {
    marginRight: "4px",
  },
}));

export const StyledMsgItemImages = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "32px",
  height: "32px",

  display: "flex",
  borderRadius: "5px",
  border: `1px solid ${theme.customColors.grey[300]}`,
}));

export const StyledExtraCount = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isAdamResponse }) => ({
  width: "32px",
  height: "32px",
  backgroundColor: theme.customColors.white,
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 500,
  cursor: "pointer",
}));

export const StyledMsgItemFileContainer = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.customColors.white,
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  padding: "7px 12px",
  "& i": {
    // color: theme.entityColor.primaryColor,
  },
}));

export const StyledMsgItemFileBox = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "3px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.customColors.white,
}));

export const StyledChatAdminName = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
}));

export const StyleErrorAlert = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  left: "-20px",
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  color: theme.customColors.pink[600],
}));

export const StyledRecallMsg = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[500],
}));
