import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledOnboardingWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100vh",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const StyledOnboardingLeftContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  position: "relative",
  backgroundImage: `url(${require("assets/img/onboardingBg.png")})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const StyledLoginCard = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[800],
  borderRadius: "5px",
  border: `1px solid ${theme.customColors.grey[300]}`,
  fontSize: "18px",
  padding: "11px 24px",
  display: "flex",
  alignItems: "center",
  width: "400px",
  marginBottom: "12px",
  cursor: "pointer",
  background: "white",
  "&:hover": {
    color: theme.customColors.purple[500],
    border: `1px solid ${theme.customColors.purple[500]}`,
  },
}));

export const StyledWarningLink = styled("a", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  textDecorationLine: "underline",
  fontWeight: 700,
  cursor: "pointer",
  color: "#777",
}));
