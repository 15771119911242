import React, { useMemo } from "react";
import _ from "lodash";
import { useTheme } from "@mui/styles";
import Modal from "@mui/material/Modal";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import Member from "./Member";
import { Box } from "@mui/material";
import { map } from "lodash-es";

const AssignmentModal = ({
  onClose,
  onConfirm,
  assignment,
  setAssignment,
  members,
}) => {
  const theme = useTheme();

  const teamMembers = useMemo(() => {
    return [
      { label: "Unassigned", value: null },
      ...map(members, (m) => {
        return {
          label: <Member member={m} />,
          value: m._id,
        };
      }),
    ];
  }, [members]);

  return (
    <Modal
      open={typeof assignment !== "undefined"}
      className={"modal"}
      onClose={onClose}
    >
      {assignment ? (
        <div className="modalPaper" style={{ maxWidth: "350px" }}>
          <Box
            sx={{
              position: "relative",
              minWidth: "350px",
              bgcolor: "#fff",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              outline: "none",
              overFlow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "22px 42px 36px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    color: (theme) => theme.customColors.grey[700],
                    fontSize: "12px",
                    mb: "4px",
                  }}
                >
                  Ticket Type
                </Box>
                <Box
                  sx={{
                    color: (theme) => theme.customColors.grey[700],
                    bgcolor: "#FAF6FF",
                    fontSize: "14px",
                    px: "4px",
                    py: "2.5px",
                    borderRadius: "2.5px",
                  }}
                >
                  {assignment.ticketType.name}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    fontSize: "14px",
                    color: (theme) => theme.customColors.grey[700],
                    mb: 1,
                  }}
                >
                  Assignee:
                </Box>
                <Box width={"100%"}>
                  <CustomDropdown
                    placeholder="Select an agent"
                    fullWidth
                    border
                    data={teamMembers}
                    value={assignment.assignee}
                    onSelect={(e) => {
                      const { value } = e.target;
                      setAssignment((prv) => ({
                        ...prv,
                        assignee: value,
                      }));
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                <Button variant={VARIANT.TEXT} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}
                >
                  Assign
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default AssignmentModal;
