import Button, {
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import UserTag from "features/metadesk/components/UserTag";
import React from "react";

import { Box, Modal } from "@mui/material";
import { Loading } from "features/common";
import { useOutletContext } from "react-router";
import PureModal from "features/metadesk/campaign/campaignList/component/PureModal";
import { useIntl } from "react-intl";

export default function CustomAssignModal({
  open,
  handleClose,
  handleAssign,
  isLoading = false,
  tags,
  setTags,
  ...props
}) {
  const [entityName] = useOutletContext();
  const { formatMessage } = useIntl();
  return (
    <PureModal
      open={open}
      closeBtn={false}
      onClose={handleClose}
      sx={{ zIndex: 10000 }}
      maxWidth="500px"
      {...props}
    >
      <Box p={"48px 40px"} position="relative" sx={{ wi: "100%" }}>
        <Loading open={isLoading} fullScreen={false} />

        <Box
          color="#383538"
          fontSize="18px"
          fontWeight="bold"
          display="flex"
          alignItems="center"
        >
          <i className="meta-crm-icon-ic_tag font-size-24 mgr4" />
          {"Assign Tag"}
        </Box>
        <div style={{ marginBottom: 60 }}>
          <Box my="12px" color="#777">
            {formatMessage({ id: "menu.assign.description" })}
          </Box>
          <div style={{ margin: "40px 0" }}>
            <UserTag
              entityName={entityName}
              width="100%"
              embed={true}
              initTags={tags}
              setInitTags={setTags}
            />
          </div>
        </div>

        <div className="endRow">
          <Button
            color={COLOR.SECONDARY}
            variant={VARIANT.TEXT}
            onClick={handleClose}
            style={{ width: 120 }}
          >
            {formatMessage({ id: "global.btn.cancel" })}
          </Button>
          <Button
            color={COLOR.SECONDARY}
            disabled={tags.length == 0}
            style={{ width: 120 }}
            onClick={handleAssign}
          >
            {formatMessage({ id: "global.btn.assign" })}
          </Button>
        </div>
      </Box>
    </PureModal>
  );
}
