import LottieAnimation from "components/LottieAnimation";
import React from "react";

export default function LoadingIcon(props) {
  return (
    <LottieAnimation
      animationLink={require("assets/animations/loading.json")}
      {...props}
    />
  );
}
