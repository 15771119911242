import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const StyledTicketName = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[500],
  width: "50%",
}));

export const StyledDiscordTicketSettingContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "space-between",
}));

export const StyledColorButtonsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  lineHeight: 0,
}));

export const StyledColorButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

const getSVG = (color) =>
  `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.5655 4.24464L6.21755 12.2292L3 8.18214L4.56553 6.9375L6.21755 9.01543L11 3L12.5655 4.24464Z" fill="${encodeURIComponent(
    color
  )}"/></svg>')`;

export const StyledColorButton = styled(ToggleButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, bgColor = "#707070", readonly }) => ({
  background: bgColor,
  height: "16px",
  width: "16px",
  borderRadius: "50% !important",
  marginRight: "8px",
  border: "0 !important",
  padding: 0,
  cursor: readonly ? "default" : "pointer",
  "&:hover": {
    backgroundColor: bgColor,
  },

  "&.Mui-selected": {
    background: bgColor,
    "&:hover": {
      backgroundColor: bgColor,
    },
    "&:before": {
      content: '""',
      display: "block",
      height: "16px",
      width: "16px",
      backgroundImage: getSVG(theme.customColors.white),
    },
  },
}));

export const StyledAddressSwitchContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
