export const MEMBER_FETCH_BEGIN = "MEMBER_FETCH_BEGIN";
export const MEMBER_FETCH_SUCCESS = "MEMBER_FETCH_SUCCESS";
export const MEMBER_FETCH_FAILURE = "MEMBER_FETCH_FAILURE";
export const MEMBER_TICKET_PERMISSION_FETCH_BEGIN =
  "MEMBER_TICKET_PERMISSION_FETCH_BEGIN";
export const MEMBER_TICKET_PERMISSION_FETCH_SUCCESS =
  "MEMBER_TICKET_PERMISSION_FETCH_SUCCESS";
export const MEMBER_TICKET_PERMISSION_FETCH_FAILURE =
  "MEMBER_TICKET_PERMISSION_FETCH_FAILURE";

export const TICKET_COUNT_FETCH_SUCCESS = "TICKET_COUNT_FETCH_SUCCESS";
export const TICKET_COUNT_FETCH_ERROR = "TICKET_COUNT_FETCH_ERROR";
export const UPDATE_TICKET_LIST_FILTER = "UPDATE_TICKET_LIST_FILTER";
export const TICKETLIST_FETCH_BEGIN = "TICKETLIST_FETCH_BEGIN";
export const TICKETLIST_FETCH_SUCCESS = "TICKETLIST_FETCH_SUCCESS";
export const TICKETLIST_FETCH_FAILURE = "TICKETLIST_FETCH_FAILURE";
export const TICKETLIST_FILTER_SUCCESS = "TICKETLIST_FILTER_SUCCESS";
export const TICKETLIST_ORDER_SUCCESS = "TICKETLIST_ORDER_SUCCESS";

export const USER_LIST_FETCH_BEGIN = "USER_LIST_FETCH_BEGIN";
export const USER_LIST_FETCH_SUCCESS = "USER_LIST_FETCH_SUCCESS";
export const USER_LIST_FETCH_FAILURE = "USER_LIST_FETCH_FAILURE";
export const USER_LIST_UPDATE_SUCCESS = "USER_LIST_UPDATE_SUCCESS";
export const USER_INFO_UPDATE_SUCCESS = "USER_INFO_UPDATE_SUCCESS";

export const USER_INFO_FETCH_BEGIN = "USER_INFO_FETCH_BEGIN";
export const USER_INFO_FETCH_SUCCESS = "USER_INFO_FETCH_SUCCESS";
export const USER_INFO_FETCH_FAILURE = "USER_INFO_FETCH_FAILURE";

export const USER_WALLET_INFO_FETCH_BEGIN = "USER_WALLET_INFO_FETCH_BEGIN";
export const USER_WALLET_INFO_FETCH_SUCCESS = "USER_WALLET_INFO_FETCH_SUCCESS";
export const USER_WALLET_INFO_FETCH_FAILURE = "USER_WALLET_INFO_FETCH_FAILURE";

export const USER_SOURCE_FETCH_BEGIN = "USER_SOURCE_FETCH_BEGIN";
export const USER_SOURCE_FETCH_SUCCESS = "USER_SOURCE_FETCH_SUCCESS";
export const USER_SOURCE_FETCH_FAILURE = "USER_SOURCE_FETCH_FAILURE";

export const USER_SOURCE_DATASET_FETCH_BEGIN =
  "USER_SOURCE_DATASET_FETCH_BEGIN";
export const USER_SOURCE_DATASET_FETCH_SUCCESS =
  "USER_SOURCE_DATASET_FETCH_SUCCESS";
export const USER_SOURCE_DATASET_FETCH_FAILURE =
  "USER_SOURCE_DATASET_FETCH_FAILURE";

export const USER_ADMIN_LIST_FETCH_BEGIN = "USER_ADMIN_LIST_FETCH_BEGIN";
export const USER_ADMIN_LIST_FETCH_SUCCESS = "USER_ADMIN_LIST_FETCH_SUCCESS";
export const USER_ADMIN_LIST_FETCH_FAILURE = "USER_ADMIN_LIST_FETCH_FAILURE";

export const SEARCH_USER_WALLET_INFO_FETCH_BEGIN =
  "SEARCH_USER_WALLET_INFO_FETCH_BEGIN";
export const SEARCH_USER_WALLET_INFO_FETCH_SUCCESS =
  "SEARCH_USER_WALLET_INFO_FETCH_SUCCESS";
export const SEARCH_USER_WALLET_INFO_FETCH_FAILURE =
  "SEARCH_USER_WALLET_INFO_FETCH_FAILURE";

export const USER_TICKET_LIST_FETCH_BEGIN = "USER_TICKET_LIST_FETCH_BEGIN";
export const USER_TICKET_LIST_FETCH_SUCCESS = "USER_TICKET_LIST_FETCH_SUCCESS";
export const USER_TICKET_LIST_FETCH_FAILURE = "USER_TICKET_LIST_FETCH_FAILURE";
export const UPDATE_USER_LIST_FILTER = "UPDATE_USER_LIST_FILTER";
export const TAGS_FETCH_BEGIN = "TAGS_FETCH_BEGIN";
export const TAGS_FETCH_SUCCESS = "TAGS_FETCH_SUCCESS";
export const TAGS_FETCH_FAILURE = "TAGS_FETCH_FAILURE";

export const TAG_ADD_SUCCESS = "TAG_ADD_SUCCESS";
export const TAG_EDIT_SUCCESS = "TAG_EDIT_SUCCESS";
export const TAG_DELETE_SUCCESS = "TAG_DELETE_SUCCESS";

export const ENTITY_CONFIG_FETCH_SUCCESS = "ENTITY_CONFIG_FETCH_SUCCESS";
export const USER_GUIDE_INFO_FETCH_SUCCESS = "USER_GUIDE_INFO_FETCH_SUCCESS";
export const TICKET_TYPE_FETCH_SUCCESS = "TICKET_TYPE_FETCH_SUCCESS";
export const TICKET_TYPE_UPDATE_SUCCESS = "TICKET_TYPE_UPDATE_SUCCESS";
export const SURVEY_CONFIG_UPDATE_SUCCESS = "SURVEY_CONFIG_UPDATE_SUCCESS";
export const ENTITY_CONFIG_UPDATE_SUCCESS = "ENTITY_CONFIG_UPDATE_SUCCESS";
export const ENTITY_CONFIG_UPDATE_BEGIN = "ENTITY_CONFIG_UPDATE_BEGIN";

export const WIDGET_CONFIG_FETCH_BEGIN = "WIDGET_CONFIG_FETCH_BEGIN";
export const WIDGET_CONFIG_FETCH_SUCCESS = "WIDGET_CONFIG_FETCH_SUCCESS";
export const WIDGET_CONFIG_FETCH_FAILURE = "WIDGET_CONFIG_FETCH_FAILURE";

export const WIDGET_CONFIG_UPDATE_SUCCESS = "WIDGET_CONFIG_UPDATE_SUCCESS";
export const WIDGET_CONFIG_UPDATE_BEGIN = "WIDGET_CONFIG_UPDATE_BEGIN";
export const WIDGET_CONFIG_UPDATE_FAILURE = "WIDGET_CONFIG_UPDATE_FAILURE";

export const USER_DISCORD_UPDATE_SUCCESS = "USER_DISCORD_UPDATE_SUCCESS";
export const GROUPS_FETCH_BEGIN = "GROUPS_FETCH_BEGIN";
export const GROUPS_FETCH_SUCCESS = "GROUPS_FETCH_SUCCESS";
export const GROUPS_FETCH_FAILURE = "GROUPS_FETCH_FAILURE";

export const GROUPS_UPDATE_BEGIN = "GROUPS_UPDATE_BEGIN";
export const GROUP_ADD_SUCCESS = "GROUP_ADD_SUCCESS";
export const GROUP_EDIT_SUCCESS = "GROUP_EDIT_SUCCESS";
export const GROUP_DELETE_SUCCESS = "GROUP_DELETE_SUCCESS";

export const GROUPS_USER_FETCH_BEGIN = "GROUPS_USER_FETCH_BEGIN";
export const GROUPS_USER_FETCH_SUCCESS = "GROUPS_USER_FETCH_SUCCESS";
export const GROUPS_USER_FETCH_FAILURE = "GROUPS_USER_FETCH_FAILURE";

export const GET_GROUPS_USERS_BEGIN = "GET_GROUPS_USERS_BEGIN";
export const GET_GROUPS_USERS_SUCCESS = "GET_GROUPS_USERS_SUCCESS";
export const GET_GROUPS_USERS_FAILURE = "GET_GROUPS_USERS_FAILURE";

export const TRANSACTION_TYPE_FETCH_BEGIN = "TRANSACTION_TYPE_FETCH_BEGIN";
export const TRANSACTION_TYPE_FETCH_SUCCESS = "TRANSACTION_TYPE_FETCH_SUCCESS";
export const TRANSACTION_TYPE_FETCH_FAILURE = "TRANSACTION_TYPE_FETCH_FAILURE";

export const TXS_FETCH_BEGIN = "TXS_FETCH_BEGIN";
export const TXS_FETCH_SUCCESS = "TXS_FETCH_SUCCESS";
export const TXS_FETCH_FAILURE = "TXS_FETCH_FAILURE";
export const HEADERS_SET_SUCCESS = "HEADERS_SET_SUCCESS";

export const NOTIFICATIONS_FETCH_BEGIN = "NOTIFICATIONS_FETCH_BEGIN";
export const NOTIFICATIONS_FETCH_SUCCESS = "NOTIFICATIONS_FETCH_SUCCESS";
export const NOTIFICATIONS_FETCH_FAILURE = "NOTIFICATIONS_FETCH_FAILURE";

export const NOTIFICATION_ADD_SUCCESS = "NOTIFICATION_ADD_SUCCESS";
export const NOTIFICATION_UPDATE_FAIL = "NOTIFICATION_UPDATE_FAIL";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const GET_NOTIFICATION_BEGIN = "GET_NOTIFICATION_BEGIN";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
export const NOTIFICATIONS_UPDATE_BEGIN = "NOTIFICATIONS_UPDATE_BEGIN";

export const GET_NOTIFICATION_INFO_BEGIN = "GET_NOTIFICATION_INFO_BEGIN";
export const GET_NOTIFICATION_INFO_SUCCESS = "GET_NOTIFICATION_INFO_SUCCESS";
export const GET_NOTIFICATION_INFO_FAILURE = "GET_NOTIFICATION_INFO_FAILURE";

export const GET_PERFORMANCE_INFO_BEGIN = "GET_PERFORMANCE_INFO_BEGIN";
export const GET_PERFORMANCE_INFO_SUCCESS = "GET_PERFORMANCE_INFO_SUCCESS";
export const GET_PERFORMANCE_INFO_FAILURE = "GET_PERFORMANCE_INFO_FAILURE";

export const GET_SINGLE_PERFORMANCE_BEGIN = "GET_SINGLE_PERFORMANCE_BEGIN";
export const GET_SINGLE_PERFORMANCE_SUCCESS = "GET_SINGLE_PERFORMANCE_SUCCESS";
export const GET_SINGLE_PERFORMANCE_FAILURE = "GET_SINGLE_PERFORMANCE_FAILURE";

export const GET_SINGLE_PERFORMANCE_USERS_BEGIN =
  "GET_SINGLE_PERFORMANCE_USERS_BEGIN";
export const GET_SINGLE_PERFORMANCE_USERS_SUCCESS =
  "GET_SINGLE_PERFORMANCE_USERS_SUCCESS";
export const GET_SINGLE_PERFORMANCE_USERS_FAILURE =
  "GET_SINGLE_PERFORMANCE_USERS_FAILURE";

export const DID_INFO_FETCH_BEGIN = "DID_INFO_FETCH_BEGIN";
export const DID_INFO_FETCH_SUCCESS = "DID_INFO_FETCH_SUCCESS";
export const DID_INFO_FETCH_FAILURE = "DID_INFO_FETCH_FAILURE";

export const INTEGRATION_FETCH_SUCCESS = "INTEGRATION_FETCH_SUCCESS";

export const MACRO_ACTION_TYPES = {
  POST_CATEGORIES_SUCCESS: "macro/POST_CATEGORIES_SUCCESS",
  FETCH_CATEGORIES_SUCCESS: "macro/FETCH_CATEGORIES_SUCCESS",
  EDIT_CATEGORIES_SUCCESS: "macro/EDIT_CATEGORIES_SUCCESS",
  DELETE_CATEGORIES_SUCCESS: "macro/DELETE_CATEGORIES_SUCCESS",
  POST_TAGS_SUCCESS: "macro/POST_TAGS_SUCCESS",
  FETCH_TAGS_SUCCESS: "macro/FETCH_TAGS_SUCCESS",
  EDIT_TAGS_SUCCESS: "macro/EDIT_TAGS_SUCCESS",
  DELETE_TAGS_SUCCESS: "macro/DELETE_TAGS_SUCCESS",
  CREATE_MACRO_START: "macro/CREATE_MACRO_START",
  CREATE_MACRO_SUCCESS: "macro/CREATE_MACRO_SUCCESS",
  CREATE_MACRO_FAILED: "macro/CREATE_MACRO_FAILED",
  FETCH_MACRO_LIST_START: "macro/FETCH_MACRO_LIST_START",
  FETCH_MACRO_LIST_SUCCESS: "macro/FETCH_MACRO_LIST_SUCCESS",
  FETCH_MACRO_LIST_FAILED: "macro/FETCH_MACRO_LIST_FAILED",
  FETCH_MACRO_INFO_SUCCESS: "macro/FETCH_MACRO_INFO_SUCCESS",
  EDIT_MACRO_INFO_SUCCESS: "macro/EDIT_MACRO_INFO_SUCCESS",
  DELETE_MACRO_SUCCESS: "macro/DELETE_MACRO_SUCCESS",
  FETCH_MACRO_EDIT_START: "macro/FETCH_MACRO_EDIT_START",
  FETCH_MACRO_EDIT_SUCCESS: "macro/FETCH_MACRO_EDIT_SUCCESS",
  FETCH_MACRO_EDIT_FAILED: "macro/FETCH_MACRO_EDIT_FAILED",

  FETCH_CATEGORIES_START: "macro/FETCH_CATEGORIES_START",
  FETCH_CATEGORIES_FAILED: "macro/FETCH_CATEGORIES_FAILED",
  POST_CATEGORIES_START: "macro/POST_CATEGORIES_START",
  POST_CATEGORIES_FAILED: "macro/POST_CATEGORIES_FAILED",
  FETCH_TAGS_START: "macro/FETCH_TAGS_START",
  FETCH_TAGS_FAILED: "macro/FETCH_TAGS_FAILED",
  POST_TAGS_START: "macro/POST_TAGS_START",
  POST_TAGS_FAILED: "macro/POST_TAGS_FAILED",
};
export const TRIAL_ACTION_TYPES = {
  POST_REGISTER_VERIFY_START: "trial/POST_REGISTER_VERIFY_START",
  POST_REGISTER_VERIFY_SUCCESS: "trial/POST_REGISTER_VERIFY_SUCCESS",
  POST_REGISTER_VERIFY_FAILED: "trial/POST_REGISTER_VERIFY_FAILED",
  SET_CONFIRM_FALSE: "trial/SET_CONFIRM_FALSE",
};
export const GITHUB_ISSUES_FETCH_BEGIN = "GITHUB_ISSUES_FETCH_BEGIN";
export const GITHUB_ISSUES_FETCH_SUCCESS = "GITHUB_ISSUES_FETCH_SUCCESS";
export const GITHUB_ISSUES_FETCH_FAILURE = "GITHUB_ISSUES_FETCH_FAILURE";

export const GITHUB_ISSUES_CREATE_BEGIN = "GITHUB_ISSUES_CREATE_BEGIN";
export const GITHUB_ISSUES_CREATE_SUCCESS = "GITHUB_ISSUES_CREATE_SUCCESS";
export const GITHUB_ISSUES_CREATE_FAILURE = "GITHUB_ISSUES_CREATE_FAILURE";

export const GITHUB_REPOS_FETCH_SUCCESS = "GITHUB_REPOS_FETCH_SUCCESS";
export const REFRESH_WALLET_INFO = "REFRESH_WALLET_INFO";

export const ATTRIBUTIONS_FETCH_BEGIN = "ATTRIBUTIONS_FETCH_BEGIN";
export const ATTRIBUTIONS_FETCH_SUCCESS = "ATTRIBUTIONS_FETCH_SUCCESS";
export const ATTRIBUTIONS_FETCH_FAILURE = "ATTRIBUTIONS_FETCH_FAILURE";
export const UPDATE_ATTRIBUTIONS_FILTER = "UPDATE_ATTRIBUTIONS_FILTER";
export const GET_ATTRIBUTION_BEGIN = "GET_ATTRIBUTION_BEGIN";
export const GET_ATTRIBUTION_SUCCESS = "GET_ATTRIBUTION_SUCCESS";
export const GET_ATTRIBUTION_FAILURE = "GET_ATTRIBUTION_FAILURE";
export const UPDATE_SINGLE_ATTRIBUTION_FILTER =
  "UPDATE_SINGLE_ATTRIBUTION_FILTER";

export const UPDATE_GROUP_USER_LIST_FILTER = "UPDATE_GROUP_USER_LIST_FILTER";

export const FETCH_AI_SUPPORT_CONFIGS_SUCCESS =
  "FETCH_AI_SUPPORT_CONFIGS_SUCCESS";
export const UPDATE_AI_CONFIG_SUCCESS = "UPDATE_AI_CONFIG_SUCCESS";
export const ENABLE_MAGIC_WAND_SUCCESS = "ENABLE_MAGIC_WAND_SUCCESS";
export const UPDATE_MAGIC_WAND_CONFIG_SUCCESS =
  "UPDATE_MAGIC_WAND_CONFIG_SUCCESS";
