import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import Box from "@mui/material/Box";

import {
  StyledTabContentContainer,
  StyledNoMacroContainer,
  StyledNoMacroText,
} from "./TabContent.styles";
import QuestionBlock from "../QuestionBlock/QuestionBlock";
import { get, isEmpty } from "lodash-es";

import { fetchMacroListAsyncAction } from "features/metadesk/redux/macro/macro.action";
import {
  selectMacroList,
  selectMacroIsLoading,
} from "features/metadesk/redux/macro/macro.selector";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";

const TabContent = ({
  category,
  onLinkifyOptions,
  onSearchValue,
  onOpenEditMacroModal,
  onSelectedTab,
}) => {
  const [entityName, isCustomDomain] = useOutletContext();

  const dispatch = useDispatch();
  const macroList = useSelector(selectMacroList);
  const macroIsLoading = useSelector(selectMacroIsLoading);
  const categoryId = get(category, "_id", "");

  useEffect(() => {
    dispatch(
      fetchMacroListAsyncAction({
        entityName: entityName,
        category: categoryId,
        search: onSearchValue,
      })
    );
  }, [categoryId, onSearchValue]);

  const renderContent = () => {
    if (isEmpty(macroList)) {
      return (
        <StyledNoMacroContainer>
          <img
            src={require("assets/img/no-macro.svg").default}
            alt="no-macro-img"
          />
          <StyledNoMacroText>
            Get ready to create your first macro
          </StyledNoMacroText>
        </StyledNoMacroContainer>
      );
    } else {
      return macroList.map((macro) => (
        <QuestionBlock
          key={macro._id}
          macro={macro}
          onLinkifyOptions={onLinkifyOptions}
          onOpenEditMacroModal={onOpenEditMacroModal}
          tabCategoryId={categoryId}
        />
      ));
    }
  };

  return (
    <StyledTabContentContainer>
      {macroIsLoading ? (
        <Box
          sx={{
            minHeight: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      ) : (
        renderContent()
      )}
    </StyledTabContentContainer>
  );
};

export default TabContent;
