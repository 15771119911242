import styled from "@emotion/styled/macro";
import { Tab, Tabs } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import isStyledPropsValid from "utils/isStyledPropsValid";
import TabPanel from "./TabPanel";

const drawerWidth = 410;

const openedMixin = (theme) => ({
  [theme.breakpoints.down("md")]: {
    position: "relative",
  },
  width: "100%",

  borderRadius: "0 8px 8px 0",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shortest,
  }),
  [theme.breakpoints.up("md")]: {
    width: drawerWidth + 49,
  },

  // overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRadius: "0 8px 8px 0",
  width: `51px`,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    position: "relative",
  },
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  flexShrink: 0,
  zIndex: 100,
  width: "100%",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "> .MuiPaper-root": {
    overflowY: "unset",
    position: "absolute",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

StyledDrawer.displayName = "StyledDrawer";

const StyledControlArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  color: "#000",
  position: "absolute",
  top: "50%",
  left: "-8px",
  width: "16px",
  height: "50px",
  borderRadius: "50px",
  background: "#FFFFFF",
  border: "1px solid #E6E6E6",
  cursor: "pointer",
  display: "flex",
  zIndex: 1000,
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    filter: "drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.10))",
    background: "#FAF6FF",
  },
  [theme.breakpoints.down("md")]: {
    top: "unset",
    left: "unset",
    bottom: "-21px",
    height: "50px",
    width: "16px",
    right: "0",
  },
}));

StyledControlArea.displayName = "StyledControlArea";

const StyledTabs = styled(Tabs, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  width: "50px",
  padding: "5px",
  overflow: "visible",
  "& .MuiTabs-scroller": { overflow: "visible !important" },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    border: `1px solid ${theme.customColors.grey[400]}`,
  },
}));

StyledTabs.displayName = "StyledTabs";

const StyledDrawerContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  display: "flex",
  flexDirection: "row-reverse",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

StyledDrawerContainer.displayName = "StyledDrawerContainer";

const StyledTab = styled(Tab, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  "&.MuiTab-root": {
    minWidth: "unset",
    minHeight: "unset",
    padding: 0,
    width: "39px",
    height: "39px",
    overflow: "visible",
    color: theme.customColors.grey[600],
    [theme.breakpoints.down("md")]: {
      marginBottom: "0",
    },
    "&:hover": {
      color: theme.customColors.purple[500],
    },
    "&.Mui-selected": {
      color: theme.customColors.purple[500],
      backgroundColor: theme.customColors.purple[100],
      borderRadius: 3,
    },
  },
}));
StyledTab.displayName = "StyledTab";

const StyledTabPanel = styled(TabPanel, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  flex: 1,
  width: "100%",
  maxWidth: drawerWidth - 2,
  height: "100%",
  borderRight: "1px solid #e6e6e6",
  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
  },
}));
StyledTabPanel.displayName = "StyledTabPanel";

export {
  StyledControlArea,
  StyledDrawer,
  StyledDrawerContainer,
  StyledTab,
  StyledTabPanel,
  StyledTabs,
};
