import React, { useContext } from "react";
import PerformanceTable from "./PerformanceTable";
import PerformanceLeftChart from "./PerformanceLeftChart";
import PerformanceHeader from "./PerformanceHeader";
import { Box } from "@mui/material";
import PerformanceRightChart from "./PerformanceRightChart";
import PerformanceSearch from "./PerformanceSearch";
import {
  AutoCompleteDropdown,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import {
  PerformanceContext,
  PerformanceProvider,
} from "./context/PerformanceProvider";
import useGetPerformanceChart from "./hook/useGetPerformanceChart";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { Loading } from "features/common";
import { useTheme } from "@mui/styles";
import HoverImage from "features/metadesk/campaign/settings/component/hoverImage";
import useMarketModule from "hooks/useMarketModule";
import { useIntl } from "react-intl";
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { selectWidgetConnected } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";

export default function CampaignPerformance() {
  return (
    <PerformanceProvider>
      <PerformanceHeader />
      <PerformanceContent />
    </PerformanceProvider>
  );
}

function PerformanceContent() {
  const { isLoading } = useContext(PerformanceContext);

  return (
    <Box position={"relative"}>
      <Loading
        fullScreen={false}
        white
        open={isLoading.switchTab || isLoading.chart || isLoading.table}
        topPos={"50vh"}
      />
      <PerformanceSearch />

      <PerformanceCharts />
      <PerformanceTable />
    </Box>
  );
}

function PerformanceCharts() {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { attributionConnect, pushConnected, xmtpConnected } =
    useMarketModule();
  const { timeRangeStats, totalStats } = useGetPerformanceChart();
  const theme = useTheme();
  const [entityName, isCustomDomain] = useOutletContext();
  const navigate = useNavigate();
  const isWidgetConnected = useSelector(selectWidgetConnected);

  const {
    actionOptions,
    actionValue,
    setActionValue,
    startDate,
    endDate,
    promotype,
  } = useContext(PerformanceContext);

  const redirectUrl = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return "analytics/attribution";
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        return "marketing/dashboard/campaign?search=&promotype=flyer&channel=all";
      }
      return "marketing/dashboard/campaign";
    }
  };

  const handleToSet = (targetId, row) => {
    if (location.pathname.includes("/analytics/attribution")) {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/settings/general/integration/attribution?from=${redirectUrl()}`
        )
      );
    }

    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        if (!pushConnected && !xmtpConnected) {
          navigate(
            parseCustomDomainUrl(
              isCustomDomain,
              entityName,
              `/marketing/campaign/create`
            )
          );
          return;
        }
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            `/settings/general/integration/attribution?from=${redirectUrl()}`
          )
        );
        return;
      }

      // widget 沒有安裝trackingTag的狀況
      if (!attributionConnect) {
        if (!isWidgetConnected) {
          navigate(
            parseCustomDomainUrl(
              isCustomDomain,
              entityName,
              `/marketing/campaign/create`
            )
          );
          return;
        }
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            `/settings/general/integration/attribution?from=${redirectUrl()}`
          )
        );
        return;
      }
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/marketing/campaign/create`
        )
      );
    }
  };

  const renderHoverShowImg = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return require("assets/img/img_customization2.svg").default;
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        if (!pushConnected && !xmtpConnected) {
          return require("assets/img/img_customization.png");
        }
        return require("assets/img/img_customization2.svg").default;
      }

      // widget 沒有安裝trackingTag的狀況
      if (!attributionConnect) {
        if (!isWidgetConnected) {
          return require("assets/img/img_customization.png");
        }
        return require("assets/img/img_customization2.svg").default;
      }
      return require("assets/img/img_customization.png");
    }
  };

  const renderShowText = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return "Please install a tracking tag to display your data";
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        if (!pushConnected && !xmtpConnected) {
          return "Please create your campaign to start tracking your performance";
        }
        return "This data requires a tracking tag installation to be displayed, and will only track from the time of installation";
      }

      // widget 沒有安裝trackingTag的狀況
      if (!attributionConnect) {
        if (!isWidgetConnected) {
          return "Please create your campaign to start tracking your performance";
        }
        return "This data requires a tracking tag installation to be displayed, and will only track from the time of installation";
      }
      return "Please create your campaign to start tracking your performance";
    }
  };

  const renderButtonText = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return "Go to Integrate";
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        if (!pushConnected && !xmtpConnected) {
          return "Go to Create";
        }

        // flyer 沒有安裝 push 也沒有 xmtp 也沒有 trackingTag
        return "Go to Integrate";
      }

      // widget 沒有安裝trackingTag的狀況
      if (!attributionConnect) {
        if (!isWidgetConnected) {
          return "Go to Create";
        }
        return "Go to Integrate";
      }
      return "Go to Create";
    }
  };

  const handleChange = (event, newValue) => {
    setActionValue(newValue);
  };

  const renderChartArea = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      if (!attributionConnect) {
        return (
          <Box mb="50px">
            <HoverImage
              originalImg={require("assets/img/All_up.jpg")}
              hoverShowImg={renderHoverShowImg()}
              hoverShowText={renderShowText()}
              onClick={handleToSet}
              buttonText={renderButtonText()}
            ></HoverImage>
          </Box>
        );
      }
    }

    if (location.pathname.includes("/dashboard/campaign")) {
      if (promotype === "widget") {
        if (!attributionConnect && actionValue.value !== "open") {
          return (
            <Box mb="50px">
              <HoverImage
                originalImg={require("assets/img/All_up.jpg")}
                hoverShowImg={renderHoverShowImg()}
                hoverShowText={renderShowText()}
                onClick={handleToSet}
                buttonText={renderButtonText()}
                showDropdown={true}
              ></HoverImage>
            </Box>
          );
        }
        if (!isWidgetConnected) {
          return (
            <Box mb="50px">
              <HoverImage
                originalImg={require("assets/img/All_up.jpg")}
                hoverShowImg={renderHoverShowImg()}
                hoverShowText={renderShowText()}
                onClick={handleToSet}
                buttonText={renderButtonText()}
              ></HoverImage>
            </Box>
          );
        }
      }

      if (promotype === "flyer") {
        if (!attributionConnect) {
          return (
            <Box mb="50px">
              <HoverImage
                originalImg={require("assets/img/All_up.jpg")}
                hoverShowImg={renderHoverShowImg()}
                hoverShowText={renderShowText()}
                onClick={handleToSet}
                buttonText={renderButtonText()}
              ></HoverImage>
            </Box>
          );
        }
      }
    }
    return (
      <>
        <Box
          sx={{
            width: "200px",
            marginLeft: "auto",
            paddingTop: "12px",
            marginRight: "20px",
          }}
        >
          <AutoCompleteDropdown
            options={actionOptions}
            value={actionValue}
            onChange={handleChange}
            size={SIZE.MEDIUM}
          ></AutoCompleteDropdown>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "between",
            alignItems: "center",
          }}
        >
          <PerformanceLeftChart
            timeRangeStats={timeRangeStats}
            actionValue={actionValue}
            startDate={startDate}
            endDate={endDate}
          />
          <Box
            sx={{
              width: "1px",
              height: "350px",
              backgroundColor: theme.customColors.grey[300],
              marginLeft: "5px",
              marginRight: "5px",
            }}
          ></Box>
          <PerformanceRightChart
            totalStats={totalStats}
            actionValue={actionValue}
          />
        </Box>
      </>
    );
  };
  return (
    <Box sx={{ backgroundColor: "white", margin: "8px 0 12px" }}>
      {renderChartArea()}
    </Box>
  );
}
