import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Box } from "@mui/material";
import BarChart from "features/metadesk/ADAM/component/BarChart";
import CircleChart from "features/metadesk/ADAM/component/CircleChart";
import TreeMapChart from "features/metadesk/ADAM/component/TreeMapChart";
import WhiteCard from "features/metadesk/ADAM/component/WhiteCard";
import React, { useEffect, useMemo, useState } from "react";
import BehaviorBarChart from "./BehaviorBarChart";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import BehaviorCircleChart from "./BehaviorCircleChart";

export default function OnChainBehavior() {
  const [entityName] = useOutletContext();
  const [data, setData] = useState({
    addressCount: 0,
    walletValue: 0,
    walletValues: [],
    tokenHoldings: [],
    protocolList: [],
    nftHoldings: [],
    chainPortfolio: [],
    whales: [],
  });
  const [loading, setLoading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axios.get(
          apiUrl + `/api/customerReport/${entityName}?reportType=onchain`
        );
        setData(data);
      } catch (err) {
        dispatchSnackbar({ err });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const walletValuesData = useMemo(() => {
    if (!data.walletValues) return [];
    return data.walletValues?.map((item) => ({
      ...item,
      value: (
        100 *
        (parseFloat(item.value) / (data.addressCount || 1))
      ).toFixed(1),
    }));
  }, [data.walletValues, data.addressCount]);

  const protocolData = useMemo(() => {
    return data.protocolList.slice(0, 10).map((item) => ({
      ...item,
      value: (
        100 *
        (parseFloat(item.value) / (data.addressCount || 1))
      ).toFixed(1),
    }));
  }, [data.protocolList]);

  const tokenHoldingsData = useMemo(() => {
    return data?.tokenHoldings.splice(0, 25);
  }, [data.tokenHoldings]);

  const nftHoldingsData = useMemo(() => {
    return data.nftHoldings.slice(0, 10).map((item) => ({
      ...item,
      value: (
        100 *
        (parseFloat(item.value) / (data.addressCount || 1))
      ).toFixed(1),
    }));
  }, [data.nftHoldings]);

  const chainPortfolioData = useMemo(() => {
    return data.chainPortfolio.slice(0, 25).map((item) => ({
      ...item,
      value: (
        (100 * parseFloat(item.value)) /
        (data.addressCount || 1)
      ).toFixed(1),
    }));
  }, [data.chainPortfolio, data.addressCount]);

  const whalesData = useMemo(() => {
    return data.whales.map((item) => ({
      ...item,
      value: parseFloat(item.value).toFixed(0),
    }));
  });

  return (
    <div>
      <Box sx={{ gap: "10px", display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <WhiteCard
              label={"Address Count"}
              value={parseInt(data.addressCount).toLocaleString()}
              width={"100%"}
            />
          </Grid>
          <Grid item md={3}>
            <WhiteCard
              label="Wallet Value"
              value={`$${parseInt(data.walletValue).toLocaleString()}`}
              width={"100%"}
            />
          </Grid>
        </Grid>

        <BehaviorBarChart
          data={chainPortfolioData}
          chartName="Chain Portfolio"
          type="percent"
          loading={loading}
          labelRotate={90}
        />
        <TreeMapChart
          chartName="Top Token Holdings (Holder Count)"
          loading={loading}
          data={tokenHoldingsData}
        />

        <BehaviorBarChart
          data={protocolData}
          chartName="Protocol List"
          type="percent"
          loading={loading}
        />
        <BehaviorBarChart
          data={nftHoldingsData}
          chartName="NFT Holdings"
          type="percent"
          loading={loading}
        />
        <BehaviorCircleChart
          data={walletValuesData}
          chartName="Wallet Values"
          titleSx={{
            position: "absolute",
            top: "22px",
            left: "16px",
          }}
          totalWallet={data.addressCount}
          loading={loading}
        />
        <TreeMapChart
          chartName="Whales"
          loading={loading}
          data={whalesData}
          type="money"
        />
      </Box>
    </div>
  );
}
