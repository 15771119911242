import React, { useState, createContext, useContext } from "react";
import {
  selectEcosystemName,
  selectEntityChainList,
  selectChainNewAddItem,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";

export const OnChainDataSourceContext = createContext({
  chainList: [],
});

export default function OnChainDataSourceProvider({ children }) {
  const chainList = useSelector(selectEntityChainList);

  const newAddItem = useSelector(selectChainNewAddItem);

  return (
    <OnChainDataSourceContext.Provider value={{ chainList, newAddItem }}>
      {children}
    </OnChainDataSourceContext.Provider>
  );
}
