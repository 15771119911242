import React from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";

const IntegrationSuccess = () => {
  const [entityName, isCustomDomain] = useOutletContext();

  const navigate = useNavigate();

  return (
    <div className="column" style={{ height: "calc(100vh - 60px)" }}>
      <img
        src={require(`assets/img/discordIntegrationDone.svg`).default}
        style={{ height: "50vh", marginTop: 60 }}
      />
      <div className="lgTitle">Integration Completed 🎉</div>
      <div className="description">
        Let’s start to serve your users via MetaCRM!
      </div>
      <div style={{ margin: "32px auto" }}>
        <Button
          variant={VARIANT.OUTLINED}
          color={COLOR.SECONDARY}
          size={SIZE.XL}
          style={{ marginRight: 8, width: 160 }}
          onClick={() => {
            navigate(
              parseCustomDomainUrl(
                isCustomDomain,
                entityName,
                "/settings/general/integration"
              )
            );
          }}
        >
          Back to Setting
        </Button>
        <Button
          color={COLOR.SECONDARY}
          onClick={() => {
            navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/"));
          }}
          size={SIZE.XL}
          style={{ width: 160 }}
        >
          Let's Start
        </Button>
      </div>
    </div>
  );
};

export default IntegrationSuccess;
