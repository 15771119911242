import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import React, { useEffect, useState } from "react";
import General from "./General";
import Customization from "./Customization";
import Code from "./Code";
import { useOutletContext, useParams } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { Loading } from "features/common";
import axios from "axios";
import { apiUrl } from "features/configure";
import Back from "features/metadesk/components/Back";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { isVersionLessThan } from "features/helpers/utils";
import { Link } from "react-router-dom";
import { selectWidgetModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import useNavigateEntity from "hooks/useNavigateEntity";

export default function IntegrationWidgetSetting() {
  const { widgetId } = useParams();
  const dispatchSnackbar = useDispatchSnackbar();
  const [entityName] = useOutletContext();
  const [data, setData] = useState();
  const [integrityInfo, setIntegrityInfo] = useState({});
  const [tabsValue, setTabsValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = (event, newValue) => {
    if (hasChanges) {
      handleLeave(() => setTabsValue(newValue));
      setHasChanges(false);
    } else {
      setTabsValue(newValue);
    }
  };
  const confirm = useConfirm();

  const [hasChanges, setHasChanges] = useState(false);

  const handleFetchWidgetDetail = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${apiUrl}/api/manage/widgetConfig/${entityName}?widgetId=${widgetId}`
      );
      setData(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };
  const widgetModule = useSelector(selectWidgetModule);
  const { latestVersion } = widgetModule || {};

  const fetchIntegrity = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/integrity/widget`);
      setIntegrityInfo(data);
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  const { formatMessage } = useIntl();
  useEffect(() => {
    handleFetchWidgetDetail();
    fetchIntegrity();
  }, []);

  function handleLeave(functionToCall) {
    confirm({
      title: formatMessage({
        id: "popup.delete.title",
      }),
      content: (
        <>
          <Box width={"340px"} sx={{ margin: "0 auto" }}>
            You have unsaved changes that will be deleted if you leave ths page.
          </Box>
        </>
      ),
      confirmText: "Discard",
      cancelText: formatMessage({
        id: "global.btn.cancel",
      }),
    })
      .then(() => {
        if (functionToCall) functionToCall();
      })
      .catch(() => {});
  }

  const tabs = [
    {
      label: `General`,
      content: (
        <General
          data={data}
          handleRefetch={handleFetchWidgetDetail}
          setHasChanges={setHasChanges}
        />
      ),
      _id: 1,
    },
    {
      label: "Customization",
      content: (
        <Customization
          data={data}
          handleRefetch={handleFetchWidgetDetail}
          setHasChanges={setHasChanges}
        />
      ),
      _id: 2,
    },
    {
      label: "API / Code",
      content: (
        <Code
          data={data}
          handleRefetch={handleFetchWidgetDetail}
          integrityInfo={integrityInfo}
        />
      ),
      _id: 3,
    },
  ];

  const canUpdate = isVersionLessThan(
    data?.jsVersion || latestVersion,
    latestVersion
  );
  const { navigateEntity } = useNavigateEntity();

  return (
    <Box sx={{ position: "relative", minHeight: "100vh" }}>
      <Back />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <div className="title">MetaCRM Widget</div>
        {canUpdate && (
          <Box
            fontSize={12}
            ml="12px"
            sx={{ display: "flex", alignItems: "center" }}
          >
            v{latestVersion} -
            <Box
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                ml: "4px",
                color: "#7B61FF",
              }}
              onClick={() => {
                navigateEntity(
                  "/settings/general/app/update-list?updateType=widget"
                );
              }}
            >
              Update now
            </Box>
          </Box>
        )}
      </Box>
      <div className="description" style={{ marginBottom: "22px" }}>
        To enable MetaCRM Widget as your service channel, please complete the
        following steps.
      </div>
      {isLoading ? (
        <Loading open={true}  fullScreen={false} />
      ) : (
        <Tabs tabs={tabs} value={tabsValue} onChange={handleChange} />
      )}
    </Box>
  );
}
