import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { get, isEmpty, has } from "lodash-es";
import Box from "@mui/material/Box";

import { useOutletContext } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import { Button, SIZE, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import {
  StyledInterfaceThemeContainer,
  StyledInterfaceThemeTitle,
  StlyledSelectThemeContainer,
  StyledThemeButtonGroup,
  StyledThemeButton,
  StyledThemeButtonTitle,
  StyledThemeButtonContentContainer,
  StyledThemeButtonHint,
  StyledThemeButtonImg,
  StyledLink,
  StyledTicketTypeTitle,
} from "./InterfaceTheme.styles";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import Prompt from "features/common/Prompt";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import AssignTicketType from "../AssignTicketType/AssignTicketType";
import useUnload from "common/useUnload";
import useGetPagePermission from "hooks/useGetPagePermission";
import { StyledWhiteContainer } from "../../common.styles";
import Back from "features/metadesk/components/Back";
import useAssignTicket from "../../integrations/hook/useAssignTicket";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

const InterfaceTheme = () => {
  const dispatchSnackbar = useDispatchSnackbar();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("customization");

  const [entityName, isCustomDomain] = useOutletContext();
  const { ticketTypeList } = useFetchEntityConfig();

  const [isLoading, setIsLoading] = useState(false);
  const [customTheme, setCustomTheme] = useState("light");
  const [customColor, setCustomColor] = useState({
    background: "#383538",
    border: "#D6D6D6",
    button: "#0096FF",
    buttonText: "#F8F8F8",
    hover: "#CBE6FF",
    primary: "#ffffff",
    text: "#383538",
  });

  const {
    ticketList,
    chooseTicketType,
    setChooseTicketType,
    getTicketTypeList,
    hasChanges,
    handleSaveTicket,
  } = useAssignTicket({ ticketTypeList, type: "SupportCenter" });

  const handleCustomTheme = async (event, newCustomTheme) => {
    if (newCustomTheme !== null) {
      setCustomTheme(newCustomTheme);

      try {
        await axios.post(`${apiUrl}/api/manage/theme/${entityName}`, {
          theme: newCustomTheme,
          ...(newCustomTheme === "custom" && { customColor: customColor }),
        });
      } catch (err) {
        dispatchSnackbar({ err });
      }
    }
  };

  const fetchTheme = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/theme/${entityName}`
      );
      const themeName = get(data, "name");
      const themeColor = get(data, "customColor");
      setCustomTheme(themeName ? themeName : "light");

      if (has(data, "customColor") && !isEmpty(data.customColor)) {
        setCustomColor(themeColor);
      }
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveTicketType = async () => {
    try {
      handleSaveTicket();
      dispatchSnackbar({
        message: formatMessage({
          id: "supportSetting.configure.saveSuccess",
        }),
        variant: "success",
      });
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  useEffect(() => {
    if (!isEmpty(ticketTypeList)) {
      getTicketTypeList(ticketTypeList);
    }
  }, [ticketTypeList]);

  useEffect(() => {
    fetchTheme();
  }, []);

  useUnload((e) => {
    if (hasChanges) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  return (
    <StyledInterfaceThemeContainer>
      <Back />
      <Box mb="20px">
        <Box className="title">Support Center</Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            minHeight: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      ) : (
        <StyledWhiteContainer>
          <Box>
            <StyledInterfaceThemeTitle>
              Support Center Webpage Theme
            </StyledInterfaceThemeTitle>

            <StlyledSelectThemeContainer>
              <StyledThemeButtonGroup
                value={customTheme}
                exclusive
                onChange={canEdit ? handleCustomTheme : null}
              >
                <StyledThemeButton value="light" readonly={readonly}>
                  <StyledThemeButtonTitle>
                    <Box mr={1} fontWeight={500}>
                      <i className="meta-crm-icon-ic_sun font-size-24" />
                    </Box>
                    Light Theme
                  </StyledThemeButtonTitle>
                  <StyledThemeButtonContentContainer>
                    <StyledThemeButtonHint>
                      Configure your support center to bright style.
                    </StyledThemeButtonHint>
                    <Box textAlign="center">
                      <StyledThemeButtonImg
                        src={require("assets/img/light_theme.svg").default}
                        alt="discord-img"
                      ></StyledThemeButtonImg>
                    </Box>
                  </StyledThemeButtonContentContainer>
                </StyledThemeButton>
                <StyledThemeButton value="dark" readonly={readonly}>
                  <StyledThemeButtonTitle>
                    <Box mr={1} fontWeight={500}>
                      <i className="meta-crm-icon-ic_moon font-size-32" />
                    </Box>
                    Dark Theme
                  </StyledThemeButtonTitle>
                  <StyledThemeButtonContentContainer>
                    <StyledThemeButtonHint>
                      Configure your support center to dark style.
                    </StyledThemeButtonHint>
                    <Box textAlign="center">
                      <StyledThemeButtonImg
                        src={require("assets/img/dark_theme.jpg")}
                        alt="discord-img"
                      ></StyledThemeButtonImg>
                    </Box>
                  </StyledThemeButtonContentContainer>
                </StyledThemeButton>
                <StyledThemeButton value="custom" readonly={readonly}>
                  <StyledThemeButtonTitle>
                    <Box mr={1} fontWeight={500}>
                      <i className="meta-crm-icon-ic_color font-size-32" />
                    </Box>
                    Custom Theme
                    <StyledLink
                      to={parseCustomDomainUrl(
                        isCustomDomain,
                        entityName,
                        "/settings/customization/support-center"
                      )}
                    >
                      Settings
                    </StyledLink>
                  </StyledThemeButtonTitle>
                  <StyledThemeButtonContentContainer>
                    <StyledThemeButtonHint>
                      Customize your own color theme for support center!
                    </StyledThemeButtonHint>
                    <Box textAlign="center">
                      <StyledThemeButtonImg
                        src={require("assets/img/light_theme.svg").default}
                        alt="discord-img"
                      ></StyledThemeButtonImg>
                    </Box>
                  </StyledThemeButtonContentContainer>
                </StyledThemeButton>
              </StyledThemeButtonGroup>
            </StlyledSelectThemeContainer>
          </Box>

          <Box
            sx={{
              width: "calc(100% + 40px)",
              background: (theme) => theme.customColors.grey[300],
              height: "1px",
              marginLeft: "-20px",
              marginRight: "-20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></Box>
          <div style={{ overflowX: "auto" }}>
            <Box width={"576px"}>
              <StyledTicketTypeTitle
                display={"flex"}
                alignItems={"center"}
                columnGap={"4px"}
              >
                {formatMessage({
                  id: "supportCenterCustomization.ticketType.title",
                })}{" "}
                <IconTooltip
                  customColor={theme.customColors.grey[500]}
                  content={formatMessage({
                    id: "supportCenterCustomization.ticketType.tooltip",
                  })}
                ></IconTooltip>
              </StyledTicketTypeTitle>

              <Box mb={3} color={(theme) => theme.customColors.grey[600]}>
                {formatMessage({
                  id: "supportCenterCustomization.ticketType.description",
                })}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "14px",
                  color: (theme) => theme.customColors.grey[700],
                  pl: "50px",
                  pr: 1,
                  mb: 1.5,
                }}
              >
                <Box>Ticket Type</Box>
              </Box>
              <AssignTicketType
                serviceName={"supportCenter"}
                canEdit={canEdit}
                ticketList={ticketList}
                chooseTicketType={chooseTicketType}
                setChooseTicketType={setChooseTicketType}
              />

              {canEdit && (
                <FixedButton isShowGap={false}>
                  <Button
                    color={COLOR.SECONDARY}
                    size={SIZE.XL}
                    sx={{
                      width: "120px",
                    }}
                    onClick={handleSaveTicketType}
                    disabled={!hasChanges}
                  >
                    {formatMessage({ id: "global.btn.save" })}
                  </Button>
                </FixedButton>
              )}
            </Box>
          </div>
        </StyledWhiteContainer>
      )}
      <Prompt when={hasChanges} />
    </StyledInterfaceThemeContainer>
  );
};

export default InterfaceTheme;
