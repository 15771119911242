import React, { useState, useEffect, useRef } from "react";
import { useOutletContext, Navigate } from "react-router-dom";
import { useTheme } from "@mui/styles";

import { CampaignCreationProvider } from "./context/CampaignCreationProvider";

import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useNavigate } from "react-router-dom";

import { TrackingOptionsProvider } from "./component/SetOptions/OptionsProvider";
import CreateCampaign from "./CreateCampaign";

const CreateCampaignContainer = () => {
  const { fetchEntityConfig } = useFetchEntityConfig();
  const [entityName, isCustomDomain] = useOutletContext();

  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, []);

  return (
    <CampaignCreationProvider>
      <TrackingOptionsProvider>
        <CreateCampaign></CreateCampaign>
      </TrackingOptionsProvider>
    </CampaignCreationProvider>
  );
};

export default CreateCampaignContainer;
