import Button from "@metacrm/metacrm-material-ui/dist/Button";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledWhiteContainer } from "../settings/common.styles";
import moment from "moment";
import XPopMenu from "./component/XPopMenu";
import useNavigateEntity from "hooks/useNavigateEntity";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { Loading } from "features/common";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useIntl } from "react-intl";
import { formatDate } from "features/helpers/utils";

export default function XCampaign() {
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();
  const [data, setData] = useState([]);
  const [entityName] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const confirm = useConfirm();

  async function getXCampaignData() {
    try {
      setIsLoading(true);
      const { data } = await axios(`${apiUrl}/api/x/${entityName}/campaign`);
      setData(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getXCampaignData();
  }, []);

  async function deleteXCampaignData(xId) {
    try {
      confirm({
        title: formatMessage({ id: "popup.delete.title.xTracking" }),
        content: formatMessage({ id: "popup.delete.description.xTracking" }),
        confirmText: formatMessage({ id: "popup.confirmToDelete" }),
        cancelText: formatMessage({ id: "popup.confirmCancel" }),
      })
        .then(async () => {
          await axios.delete(`${apiUrl}/api/x/${entityName}/campaign/${xId}`);
          await getXCampaignData();
          dispatchSnackbar({ message: "Delete Success", variant: "success" });
        })
        .catch(() => {});
    } catch (err) {
      dispatchSnackbar({ err });
    }
  }

  return (
    <div>
      <Loading open={isLoading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          mb: "24px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <div className="title ">
            {formatMessage({ id: "global.function.xCampaignTracking.title" })}
          </div>
          <div className="description">
            {formatMessage({
              id: "global.function.xCampaignTracking.description",
            })}
          </div>
        </div>
        <Button onClick={() => navigateEntity("/marketing/xCampaign/add")}>
          <i className="meta-crm-icon-ic_shape font-size-16" />
          <Box ml="4px">
            {formatMessage({ id: "global.btn.createNewCampaign" })}
          </Box>
        </Button>
      </Box>
      <Grid container spacing={1}>
        {data.map((item, index) => {
          const { _id, name, description, authors, updated } = item;
          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() =>
                navigateEntity(`/marketing/xCampaign/performance/${_id}`)
              }
            >
              <StyledWhiteContainer
                key={index}
                sx={{
                  marginBottom: "0px",
                  cursor: "pointer",
                  padding: "20px",
                  width: "100%",
                  position: "relative",
                  height: "202px",
                  ":hover": {
                    border: "1px solid #7B61FF",
                  },
                }}
              >
                <Box position="absolute" top="12px" right="8px">
                  <XPopMenu
                    handleDelete={() => deleteXCampaignData(_id)}
                    xId={_id}
                  />
                </Box>
                <Box
                  mb="4px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box fontSize={12} color="#777">
                    {formatMessage({
                      id: "xCampaignTracking.card.campaignName.title",
                    })}
                  </Box>
                </Box>
                <Box
                  fontSize={14}
                  color="#555"
                  fontWeight={700}
                  className="truncate"
                >
                  {name}
                </Box>
                <Box className="truncate" my="16px" color="#777" fontSize={12}>
                  {description}
                </Box>
                <Box mb="8px" color="#777" fontSize={12}>
                  {formatMessage({
                    id: "xCampaignTracking.card.postLinks.title",
                  })}
                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {authors.slice(0, 5)?.map((link, index) => (
                    <Box
                      component="img"
                      src={link}
                      key={index}
                      width={24}
                      height={24}
                      borderRadius="50%"
                      sx={{
                        ml: index !== 0 ? "-4px" : 0,
                        objectFit: "cover",
                        border: "2px solid #FFF",
                      }}
                    />
                  ))}
                  <Box ml="4px" whiteSpace="nowrap">
                    {authors?.length > 0 ? `${authors.length} Links` : "-"}
                  </Box>
                </Box>

                <Box fontSize={10} color="#A5A5A5" mt="24px">
                  {formatMessage({ id: "global.header.lastUpdated" })}
                  {": "}
                  {formatDate(updated)}
                </Box>
              </StyledWhiteContainer>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#7B61FF",
              height: "100%",
              minHeight: "202px",
              width: "100%",
              cursor: "pointer",
              border: "1px dashed #7B61FF",
              borderRadius: "4px",
              ":hover": {
                backgroundColor: "#FAF7FF",
              },
            }}
            onClick={() => navigateEntity("/marketing/xCampaign/add")}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              <i className="meta-crm-icon-ic_add font-size-16" />
              <Box ml="4px">
                {formatMessage({ id: "global.btn.createNewCampaign" })}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
