import React from "react";
import {
  StyledChannelCell,
  StyledChannelCellName,
  StyledChannelIconContainer,
} from "./Channel.styles";
import Box from "@mui/material/Box";
import IconIcSupportCenterNoBg from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcSupportCenterNoBg";
import IconIcWidgetBlack from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcWidgetBlack";
import IconIcDiscordNoBg from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscordNoBg";
import IconIconIcTelegramNoBg from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIconIcTelegramNoBg";

const Channel = ({ ticket }) => {
  if (!ticket) return <span />;

  const renderChannelIcon = (IconComponent, label) => (
    <>
      <StyledChannelIconContainer>
        <IconComponent width="21px" height="21px" />
      </StyledChannelIconContainer>
      <StyledChannelCellName>{label}</StyledChannelCellName>
    </>
  );

  const renderChannel = () => {
    switch (ticket.source) {
      case "discord":
        return renderChannelIcon(IconIcDiscordNoBg, "Discord");
      case "supportCenter":
        return renderChannelIcon(IconIcSupportCenterNoBg, "Support Center");
      case "widget":
        return renderChannelIcon(IconIcWidgetBlack, ticket.sourceName);
      case "telegram":
        return renderChannelIcon(IconIconIcTelegramNoBg, "Telegram");
      default:
        return null;
    }
  };

  return (
    <Box display="flex" alignItems={"center"}>
      <StyledChannelCell>{renderChannel()}</StyledChannelCell>
    </Box>
  );
};

export default Channel;
