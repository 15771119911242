import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Back from "features/metadesk/components/Back";
import { StyledWhiteCardContainer } from "../createCampaign/component/CampaignCreation.styles";
import _ from "lodash";
import { StyledContentContainer } from "../../referral/Referral.styles";
import Objective from "../createCampaign/component/Objective";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { alertInfo, alertServerError } from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useNavigate, useOutletContext } from "react-router";
import useNavigateEntity from "hooks/useNavigateEntity";
import { Loading } from "features/common";

const defaultValues = {
  objectives: [],
};

function TrafficReportObjective() {
  const theme = useTheme();
  const [entityName] = useOutletContext();
  const [errorBarShow, setErrorBarShow] = useState(false);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors: errorsInfo, isValid },
  } = methods;

  const { append, replace, remove } = useFieldArray({
    control,
    name: "objectiveList",
  });
  const [formData, setFormData] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const { navigateEntity } = useNavigateEntity();
  const objectiveRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await axios.get(
          apiUrl + `/api/trackingObjective/${entityName}`
        );
        const data = _.get(result, "data", []);

        setFormData({ objectives: [{ type: "contract", _id: uuidv4() }] });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCreate = async () => {
    try {
      setErrorBarShow(false);
      objectiveRef.current.triggerValidation();

      const isValid = objectiveRef.current.getIsValid();
      if (isValid) {
        await axios.post(apiUrl + `/api/trackingObjective/${entityName}`, {
          objectives: _.map(formData?.objectives, (objective) => ({
            chainId: objective.chainId,
            contractAddress: objective.smartContractAddress,
            eventName: objective.method,
            eventABI: objective.smartContractABI,
            name: objective.objectiveName,
          })),
        });
        dispatch(alertInfo("Create Objective Success"));
        navigateEntity("/analytics/settings/website-traffic");
      } else {
        setErrorBarShow(true);
      }
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  return (
    <Box position={"relative"}>
      <Loading open={loading} fullScreen={true} />
      <FormProvider {...methods}>
        <Back />
        <Box className="title">Create objective</Box>
        <Box
          sx={{
            fontSize: "14px",
            color: theme.customColors.grey[600],
            marginTop: "8px",
            marginBottom: "24px",
          }}
        >
          Set up an objective to monitor specific contract events on the
          blockchain.
        </Box>

        {errorBarShow && (
          <Box
            sx={{
              background: "#FFEEEE",
              borderRadius: "4px",
              padding: "16px 32px",
              color: theme.customColors.pink[600],
              marginBottom: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="i"
              marginRight="8px"
              className="meta-crm-icon-ic_x_circle_contained font-size-16"
            />
            Please complete the highlighted fields below.
          </Box>
        )}

        <StyledWhiteCardContainer>
          <StyledContentContainer>
            <Objective
              ref={objectiveRef}
              objectiveIndex={0}
              formData={formData}
              isTargetEvent={true}
              setFormData={(values) => {
                setFormData({
                  ...formData,
                  ...values,
                });
              }}
            ></Objective>
          </StyledContentContainer>
        </StyledWhiteCardContainer>

        <FixedButton>
          <Button
            variant={VARIANT.TEXT}
            style={{ width: 144 }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit(handleCreate())}
            style={{ width: 144 }}
            // disabled={!isValid}
          >
            Create
          </Button>
        </FixedButton>
      </FormProvider>
    </Box>
  );
}

export default memo(TrafficReportObjective);
