import styled from "@emotion/styled/macro";
import { Accordion, AccordionSummary, Box } from "@mui/material";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { Swiper } from "swiper/react";

export const StyledSwiper = styled(Swiper, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .swiper-pagination-bullets": {
    bottom: "5px",
  },
  "& .swiper-pagination-bullet": {
    "&.swiper-pagination-bullet-active": {
      backgroundColor: "#A9A9A9",
    },
  },
  "& .swiper-button-prev, & .swiper-button-next": {
    top: "unset",
    width: "20px",
    height: "20px",
    zIndex: 11,
    "&:after": {
      fontSize: "unset",
      fontWeight: 900,
      color: theme.customColors.grey[800],
    },
  },
  "& .swiper-button-next": {
    right: 0,
  },
  "& .swiper-button-prev": {
    left: 0,
  },
}));

export const StyledAttributionLinkContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: "14px",
  display: "flex",
  alignItems: "center",
  paddingBottom: "8px",
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.customColors.grey[200]}`,
  },
}));

export const StyledAttributionLink = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[600],
  fontWeight: 400,
  width: "98%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const StyledDescription = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& a": {
    color: theme.customColors.blue[500],
  },
}));
