import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { Box } from "@mui/material";
import React from "react";

export default function StatusLabel({
  label = "Beta",
  labelColor = "#7B61FF",
  content = "This feature is in Beta and may still be unstable. We appreciate your patience as we continue to improve it.",
  sx,
}) {
  return (
    <IconTooltip
      icon={
        <Box
          fontSize="12px"
          color={labelColor}
          border={`1px solid ${labelColor}`}
          padding="0 7px"
          borderRadius="10px"
          className="item-flex"
          justifyContent="center"
          lineHeight="normal"
          {...sx}
        >
          {label}
        </Box>
      }
      content={content}
    />
  );
}
