import { TRIAL_ACTION_TYPES } from "../constants";
import { createAction } from "../reducer.utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import {
  parseToOptionFormat,
  parseSingleAnswerToOptionFormat,
} from "features/metadesk/settings/Macros/CreateMacroModal/dropdown.utils";
import { enqueueSnackbar } from "features/common/redux/actions";
import { get } from "lodash-es";
import { generateKey, encryptToken } from "features/helpers/encrypt";

export const setConfirmFalse = () =>
  createAction(TRIAL_ACTION_TYPES.SET_CONFIRM_FALSE);

export const postRegisterVerifyStart = () =>
  createAction(TRIAL_ACTION_TYPES.POST_REGISTER_VERIFY_START);

export const postRegisterVerifyFailed = () =>
  createAction(TRIAL_ACTION_TYPES.POST_REGISTER_VERIFY_FAILED);

export const postRegisterVerifySuccess = (payload) =>
  createAction(TRIAL_ACTION_TYPES.POST_REGISTER_VERIFY_SUCCESS, payload);

export const postRegisterVerifyAsyncAction = (data, callback) => {
  return async (dispatch) => {
    dispatch(postRegisterVerifyStart());
    try {
      let accessToken = data.accessToken;
      let sessionId = data.sessionId;
      let key = generateKey(sessionId);

      let encryptedAccessToken = encryptToken(accessToken, key);

      localStorage.setItem("session_id", sessionId);
      localStorage.setItem("access_token", encryptedAccessToken);

      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      if (callback) {
        callback();
      }
      dispatch(postRegisterVerifySuccess(data));
    } catch (error) {
      dispatch(postRegisterVerifyFailed());
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};
