import React from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";

const IntegrationSuccess = ({
  title,
  contentText,
  onSuccess,
  navigatePage,
  linkImg,
}) => {
  const theme = useTheme();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleStart = () => {
    if (onSuccess) {
      onSuccess();
    }
    if (navigatePage) {
      navigate(parseCustomDomainUrl(isCustomDomain, entityName, navigatePage));
      return;
    }
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/marketing/campaign/draft"
      )
    );
  };

  return (
    <Box
      className="column"
      sx={{
        minHeight: "100vh",
        background: theme.customColors.white,
      }}
    >
      <img
        src={linkImg ? linkImg : require(`assets/img/link-success.png`)}
        style={{ height: "50vh", marginTop: 60, marginBottom: 40 }}
      />
      <Box className="lgTitle">{title}</Box>
      <Box
        color={(theme) => theme.customColors.grey[600]}
        mt={1}
        width="440px"
        textAlign="center"
        fontWeight={500}
        lineHeight={1.4}
      >
        {contentText}
      </Box>
      <div style={{ margin: "32px auto" }}>
        <Button
          color={COLOR.SECONDARY}
          onClick={handleStart}
          size={SIZE.XL}
          style={{ width: 160 }}
        >
          {formatMessage({
            id: "global.btn.getStarted",
          })}
        </Button>
      </div>
    </Box>
  );
};

IntegrationSuccess.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  navigatePage: PropTypes.string,
  linkImg: PropTypes.string,
};

IntegrationSuccess.defaultProps = {
  title: "Awesome ! 🎉",
  contentText: `Congratulations on successfully generating the MetaCRM Tracking Tag, please remember to embed it on your website!`,
  navigatePage: null,
  linkImg: null,
};

export default IntegrationSuccess;
