import { enqueueSnackbar } from "features/common/redux/snackbar";
import { get } from "lodash";
import { useDispatch } from "react-redux";

export default function useDispatchSnackbar() {
  const dispatch = useDispatch();

  function checkError(errorMessage) {
    console.log("errorMessage", errorMessage);
    if (typeof errorMessage === "object") return "Error";
    return errorMessage;
  }

  function dispatchSnackbar({ err, message, variant = "error" }) {
    dispatch(
      enqueueSnackbar({
        message: err
          ? checkError(get(err, "response.data.error", "Error"))
          : message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant,
        },
      })
    );
  }

  return dispatchSnackbar;
}
