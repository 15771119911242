import React, { useState, useEffect } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import {
  InputField,
  SIZE as InputSize,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import Collapse from "@mui/material/Collapse";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import {
  StyledCheckCollapseContainer,
  StyledTitleRow,
  StyledCheckbox,
  StyledSliderContainer,
  StyledSlider,
  StyledInputField,
  StyledCheckboxContainer,
} from "./CheckCollapse.styles";
import { StyledAutoComplete } from "features/metadesk/chat/MacroModal/MacroModal.styles";

import CreateGroupContext, {
  useCreateGroupContext,
} from "../CreateGroup.context";

import TextField from "@mui/material/TextField";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { StyledTo } from "../CreateGroup.styles";

const CheckCollapseInputField = ({
  inputName,
  onInputValue,
  ...otherProps
}) => {
  const { onInputChange } = useCreateGroupContext();
  return (
    <Box mr={1}>
      <StyledInputField
        name={inputName}
        value={onInputValue}
        onChange={onInputChange}
        size={InputSize.MEDIUM}
        type="text"
        {...otherProps}
      />
    </Box>
  );
};

const CheckCollapseDropdown = ({
  dropdownName,
  onOptions,
  onDropdownValue,
}) => {
  const { onDropdownChange, name } = useCreateGroupContext();
  return (
    <Box mr={1}>
      <StyledAutoComplete
        name={dropdownName}
        sx={{ width: "160px" }}
        disableClearable
        size={InputSize.MEDIUM}
        options={onOptions}
        getOptionLabel={(option) => {
          return option.label;
        }}
        onChange={(e, v) =>
          onDropdownChange({ value: v, formName: name, name: dropdownName })
        }
        value={onDropdownValue}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
};

const CheckCollapseSelect = ({
  data,
  value,
  dropdownName,
  width = 160,
  index = -1,
  ...otherProps
}) => {
  const { onDropdownChange, name } = useCreateGroupContext();
  return (
    <Box mr={1}>
      <CustomDropdown
        sm
        style={{ minWidth: width }}
        name={dropdownName}
        border
        fullWidth
        data={data}
        value={value}
        {...otherProps}
        onSelect={(e) => {
          const data = {
            value: e.target.value,
            formName: name,
            name: dropdownName,
            index,
          };
          onDropdownChange(data);
        }}
      />
    </Box>
  );
};

const CheckCollapseCheckbox = ({ checked, checkboxName, label }) => {
  const { name, onDropdownChange } = useCreateGroupContext();
  return (
    <Box>
      <StyledCheckboxContainer>
        <Switch
          name={checkboxName}
          label={label}
          checked={checked}
          onChange={(newValue) => {
            onDropdownChange({
              value: newValue,
              formName: name,
              name: checkboxName,
            });
          }}
        />
      </StyledCheckboxContainer>
    </Box>
  );
};

const CheckCollapseSlider = ({
  timeUnit,
  onSliderValue,
  onStep,
  ...otherProps
}) => {
  const { onSliderChange, name } = useCreateGroupContext();
  const [sliderMax, setSliderMax] = useState(60);

  const minDistance = onStep;

  useEffect(() => {
    if (timeUnit.value === "days") {
      setSliderMax(60);
    } else if (timeUnit.value === "months") {
      setSliderMax(24);
    } else {
      setSliderMax(12);
    }
    onSliderChange({ name, value: [0, 1] });
  }, [timeUnit.value]);

  const handleChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], sliderMax - minDistance);
        onSliderChange({ name, value: [clamped, clamped + minDistance] });
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        onSliderChange({ name, value: [clamped - minDistance, clamped] });
      }
    } else {
      onSliderChange({ name, value: newValue });
    }
  };
  return (
    <Box mr={1}>
      <div className="startRow">
        <Box mr={1}>
          <StyledInputField
            name={name + "Min"}
            style={{ width: 120 }}
            value={onSliderValue[0]}
            onChange={(e) => {
              handleChange(e, [
                parseInt(e.target.value) || 0,
                onSliderValue[1],
              ]);
            }}
            size={InputSize.MEDIUM}
            type="text"
            rules={{
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: "Positive integer only",
              },
              min: 0,
            }}
            {...otherProps}
          />
        </Box>
        <StyledTo>to</StyledTo>
        <StyledInputField
          name={name + "Max"}
          style={{ width: 120 }}
          value={onSliderValue[1]}
          onChange={(e) => {
            let newValue = e.target.value;
            if (newValue > sliderMax) newValue = sliderMax;
            handleChange(e, [onSliderValue[0], parseInt(newValue) || 0]);
          }}
          size={InputSize.MEDIUM}
          type="text"
          rules={{
            pattern: {
              value: /^(0|[1-9][0-9]*)$/,
              message: "Positive integer only",
            },
            max: sliderMax,
          }}
          {...otherProps}
        />
      </div>
      <Box mt={5} ml={1}>
        <StyledSlider
          sx={{ width: "250px" }}
          value={onSliderValue}
          onChange={handleChange}
          step={onStep}
          max={sliderMax}
          valueLabelDisplay="on"
        />
      </Box>
    </Box>
  );
};

const CheckCollapse = ({ value, children }) => {
  const { checked, onCheck, name, title } = value;
  return (
    <CreateGroupContext.Provider value={value}>
      <StyledCheckCollapseContainer>
        <StyledTitleRow>
          <StyledCheckbox
            checked={checked}
            onChange={() => onCheck(name)}
            label={title}
          />
        </StyledTitleRow>

        <Collapse in={checked} timeout="auto">
          {children}
        </Collapse>
      </StyledCheckCollapseContainer>
    </CreateGroupContext.Provider>
  );
};

CheckCollapse.InputField = CheckCollapseInputField;
CheckCollapse.Dropdown = CheckCollapseDropdown;
CheckCollapse.Slider = CheckCollapseSlider;
CheckCollapse.Select = CheckCollapseSelect;
CheckCollapse.Checkbox = CheckCollapseCheckbox;
export default CheckCollapse;
