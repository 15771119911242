import { MACRO_ACTION_TYPES } from "../constants";
import { replaceItemInArray } from "./macro.action";

export const MACRO_INITIAL_STATE = {
  macro: {
    tags: [],
    categoriesInfo: {
      categories: [],
      totalCount: 0,
    },
    macroList: [],
    macroInfo: {},
    isLoading: false,
    error: null,
    isEditLoading: false,
  },
};

const updateMacro = (state, changes) => {
  return { ...state, macro: { ...state.macro, ...changes } };
};

const replaceMacroListItem = (arr, newItem) => {
  return arr.map((item) => (item._id === newItem._id ? newItem : item));
};

export const reducer = (state = MACRO_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case MACRO_ACTION_TYPES.POST_CATEGORIES_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          categoriesInfo: {
            ...state.macro.categoriesInfo,
            categories: [...state.macro.categoriesInfo.categories, payload],
          },
        },
      };
    case MACRO_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS:
      return updateMacro(state, { categoriesInfo: payload });
    case MACRO_ACTION_TYPES.EDIT_CATEGORIES_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          categoriesInfo: {
            ...state.macro.categoriesInfo,
            categories: replaceItemInArray(
              state.macro.categoriesInfo.categories,
              payload
            ),
          },
        },
      };
    case MACRO_ACTION_TYPES.DELETE_CATEGORIES_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          categoriesInfo: {
            ...state.macro.categoriesInfo,
            categories: [
              ...state.macro.categoriesInfo.categories.filter(
                (item) => item._id !== payload
              ),
            ],
          },
        },
      };

    case MACRO_ACTION_TYPES.POST_TAGS_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          tags: [...state.macro.tags, payload],
        },
      };
    case MACRO_ACTION_TYPES.FETCH_TAGS_SUCCESS:
      return updateMacro(state, { tags: payload });
    case MACRO_ACTION_TYPES.EDIT_TAGS_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          tags: replaceItemInArray(state.macro.tags, payload),
        },
      };
    case MACRO_ACTION_TYPES.DELETE_TAGS_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          tags: [...state.macro.tags.filter((item) => item._id !== payload)],
        },
      };

    case MACRO_ACTION_TYPES.CREATE_MACRO_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          macroList: [payload, ...state.macro.macroList],
        },
      };
    case MACRO_ACTION_TYPES.FETCH_MACRO_LIST_START:
      return updateMacro(state, { isLoading: true });
    case MACRO_ACTION_TYPES.FETCH_MACRO_LIST_SUCCESS:
      return updateMacro(state, { macroList: payload, isLoading: false });
    case MACRO_ACTION_TYPES.FETCH_MACRO_LIST_FAILED:
      return updateMacro(state, { isLoading: false });

    case MACRO_ACTION_TYPES.FETCH_MACRO_INFO_SUCCESS:
      return updateMacro(state, { macroInfo: payload });

    case MACRO_ACTION_TYPES.EDIT_MACRO_INFO_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          macroList: replaceMacroListItem(state.macro.macroList, payload),
        },
      };

    case MACRO_ACTION_TYPES.DELETE_MACRO_SUCCESS:
      return {
        ...state,
        macro: {
          ...state.macro,
          macroList: state.macro.macroList.filter(
            (item) => item._id !== payload
          ),
        },
      };

    case MACRO_ACTION_TYPES.FETCH_MACRO_EDIT_START:
      return updateMacro(state, { isEditLoading: true });
    case MACRO_ACTION_TYPES.FETCH_MACRO_EDIT_SUCCESS:
      return updateMacro(state, { isEditLoading: false });
    case MACRO_ACTION_TYPES.FETCH_MACRO_EDIT_FAILED:
      return updateMacro(state, { isEditLoading: false });

    default:
      return state;
  }
};
