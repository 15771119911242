import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { formatDate, parseCustomDomainUrl } from "features/helpers/utils";
import { PerformanceContext } from "features/metadesk/campaign/campaignPerformance/context/PerformanceProvider";
import HoverImage from "features/metadesk/campaign/settings/component/hoverImage";
import { selectWidgetConnected } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import useMarketModule from "hooks/useMarketModule";
import { isEmpty, map } from "lodash-es";
import moment from "moment";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import useGetPerformanceTable from "./hook/useGetPerformanceTable";

export default function PerformanceTable() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { attributionConnect, pushConnected, xmtpConnected } =
    useMarketModule();

  const [entityName, isCustomDomain] = useOutletContext();
  const isWidgetConnected = useSelector(selectWidgetConnected);

  const {
    headCells,
    data,
    loading,
    page,
    setPage,
    totalCount,
    handleRowClick,
    handleRequestSort,
    order,
    orderBy,
    promotype,
    isAttributionLinkSet,
  } = useGetPerformanceTable();
  const { isAdvanced, setIsAdvanced } = useContext(PerformanceContext);
  const { formatMessage } = useIntl();

  const PAGE_COUNT = 20;
  const rowData = data.data;

  const channelMapping = {
    "": "",
    push: "Push",
    xmtp: "XMTP",
  };

  const handleToCreateTracker = (targetId, row) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/analytics/settings/attribution-link/create-campaign`
      )
    );
  };

  const renderContents = (report) => {
    const isMessageGoal = (value) => {
      if (report?.goal !== "message") return value;
      return (
        <IconTooltip
          icon="-"
          content={formatMessage({
            id: "global.tooltip.uninstalledTrackingTag.notification",
          })}
        />
      );
    };

    const contents = [
      <Box slot={promotype === "tracker" ? "mainDimensions" : "Campaign"}>
        {report.name}
      </Box>,
      <Box slot="channel">{channelMapping[report.channel] || "-"}</Box>,
      <Box slot="dimensionValue">{report.dimensionValue || "-"}</Box>,
      <Box slot="openCount">{report.openCount}</Box>,
      <Box slot="linkClicked">{isMessageGoal(report.linkClicked)}</Box>,
      <Box slot="ctr">
        {isMessageGoal((report.ctr || 0)?.toFixed(2) + "%")}
      </Box>,
      <Box slot="walletConnected">{isMessageGoal(report.walletConnected)}</Box>,
      <Box slot="objectiveAchieved">
        {isMessageGoal(report.objectiveAchieved)}
      </Box>,
      <Box slot="conversionRate">
        {isMessageGoal(report.conversionRate?.toFixed(2) + "%")}
      </Box>,
      <Box slot="startFrom">
        {report.startFrom ? formatDate(report.startFrom, { time: true }) : ""}
      </Box>,
    ];

    return contents;
  };

  const redirectUrl = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return "analytics/attribution";
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        return "marketing/dashboard/campaign?search=&promotype=flyer&channel=all";
      }
      return "marketing/dashboard/campaign";
    }
  };

  const renderImage = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return require("assets/img/Attribution Link_down.jpg");
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (location.search.includes("flyer")) {
        return require("assets/img/flyer_down.jpg");
      }
      return require("assets/img/widget_down.jpg");
    }
  };

  const handleToSet = (targetId, row) => {
    if (location.pathname.includes("/analytics/attribution")) {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/settings/general/integration/attribution?from=${redirectUrl()}`
        )
      );
    }

    if (location.pathname.includes("/dashboard/campaign")) {
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          `/marketing/campaign/create`
        )
      );
    }
  };

  const renderHoverShowImg = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return require("assets/img/img_customization2.svg").default;
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      return require("assets/img/img_customization.png");
    }
  };

  const renderShowText = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return "Please install a tracking tag to display your data";
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      return "Please create your campaign to start tracking your performance";
    }
  };

  const renderButtonText = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      return "Go to Integrate";
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      return "Go to Create";
    }
  };

  const renderTableContent = () => {
    if (location.pathname.includes("/analytics/attribution")) {
      if (!attributionConnect) {
        return (
          <Box mb="50px">
            <HoverImage
              originalImg={renderImage()}
              hoverShowImg={renderHoverShowImg()}
              hoverShowText={renderShowText()}
              onClick={handleToSet}
              buttonText={renderButtonText()}
            ></HoverImage>
          </Box>
        );
      }
    }
    if (location.pathname.includes("/dashboard/campaign")) {
      if (promotype === "widget") {
        if (!isWidgetConnected) {
          return (
            <Box mb="50px">
              <HoverImage
                originalImg={renderImage()}
                hoverShowImg={renderHoverShowImg()}
                hoverShowText={renderShowText()}
                onClick={handleToSet}
                buttonText={renderButtonText()}
              ></HoverImage>
            </Box>
          );
        }
      }

      if (promotype === "flyer") {
        if (!pushConnected && !xmtpConnected) {
          return (
            <Box mb="50px">
              <HoverImage
                originalImg={renderImage()}
                hoverShowImg={renderHoverShowImg()}
                hoverShowText={renderShowText()}
                onClick={handleToSet}
                buttonText={renderButtonText()}
              ></HoverImage>
            </Box>
          );
        }
      }
    }

    return (
      <Box
        sx={{
          ...(isEmpty(rowData) ? { "& td": { padding: 0 } } : {}),
        }}
      >
        <CRMTable
          isDraggable={true}
          containerStyle={{ minHeight: 300 }}
          rowData={rowData}
          headers={headCells}
          noKey={true}
          contents={map(rowData, (user, i) => renderContents(user))}
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
          noDataComponent={
            !isAttributionLinkSet &&
            promotype === "tracker" && (
              <Box
                sx={{
                  backgroundColor: "white",
                  paddingTop: "25px",
                  paddingBottom: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("assets/img/img_customization.png")}
                  width="175px"
                  mb="15px"
                ></Box>
                <Box
                  sx={{
                    fontSize: "16px",
                    color: theme.customColors.grey[700],
                    width: "265px",
                    marginBottom: "15px",
                  }}
                >
                  {formatMessage({ id: "analytics.attributionLink.noData" })}
                </Box>
                <Button onClick={handleToCreateTracker}>Go to Create</Button>
              </Box>
            )
          }
          order={order}
          orderBy={orderBy}
          customTableTop={
            promotype === "tracker" && (
              <Switch
                label={
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{ marginRight: "5px" }}
                  >
                    <Box mr="5px">
                      {formatMessage({
                        id: "campaignPerformance.trackerTab.advanced.sorting",
                      })}
                    </Box>
                    <IconTooltip
                      content={formatMessage({
                        id: "campaignPerformance.tab.tracker.advancedToolTip",
                      })}
                    />
                  </Box>
                }
                labelPlacement="start"
                onChange={() => {
                  setIsAdvanced(!isAdvanced);
                }}
                checked={isAdvanced}
              />
            )
          }
        />
        <CustomPagination
          shape="rounded"
          PAGE_COUNT={PAGE_COUNT}
          count={totalCount}
          page={page}
          setPage={(v) => setPage(v)}
        />
      </Box>
    );
  };

  return <>{renderTableContent()}</>;
}
