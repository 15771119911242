export const pools = [
  {
    pid: 0,
    name: "BUSD",
    address: "0xcC9cAf0c84514D01cDa62C9E0FC7B3e21e45f25D",
    coingeckoId: "binance-usd",
  },
  {
    pid: 1,
    name: "USDT",
    address: "0x1773072c932854291F844b67CFFE65216e6400D4",
    coingeckoId: "tether",
  },
  {
    pid: 2,
    name: "DAI",
    address: "0x1Bfaf418C13e36958b0b6736B27C3E8bC1F9bB91",
    coingeckoId: "dai",
  },
  {
    pid: 3,
    name: "USDC",
    address: "0x8044494b2e0790f56445aA167F59D1D1d843E67c",
    coingeckoId: "usd-coin",
  },
  {
    pid: 4,
    name: "WBNB",
    address: "0xA67e1Ef2483522532417eEF57659C5410D24b973",
    coingeckoId: "wbnb",
  },
  {
    pid: 5,
    name: "BNBx",
    address: "0xC134D4c9C9E99F928835ba2D13E188f45Af56B3D",
    coingeckoId: "stader-bnbx",
  },
  // {
  //   pid: 6,
  //   name: "aBNBc",
  //   address: "0x57dbD8F307740c12B492A9a6333Eb84A680E6878",
  //   coingeckoId: "ankr-reward-bearing-stake"
  // },
  {
    pid: 6,
    name: "stkBNB",
    address: "0x7d49DeDE48CA98967E50dc10a9Dd394359956802",
    coingeckoId: "pstake-staked-bnb",
  },
  {
    pid: 7,
    name: "BUSD (SIDE)",
    address: "0x59DF1bC9c8Ac7b205F2F9717F970E69804B644b7",
    coingeckoId: "binance-usd",
  },
  {
    pid: 8,
    name: "HAY (SIDE)",
    address: "0xaB22C452308eAe8C0CDdad0Ec4E31aa21f1d6c42",
    coingeckoId: "helio-protocol-hay",
  },
  {
    pid: 9,
    name: "mWOM",
    address: "0x027a9d301FB747cd972CFB29A63f3BDA551DFc5c",
    coingeckoId: "wombat-exchange",
  },
  {
    pid: 10,
    name: "MGP",
    address: "0x9B69b06272980FA6BAd9D88680a71e3c3BeB32c6",
    coingeckoId: "magpie",
  },
];

export const rewardToCoingeckoIdMap = {
  MGP: "magpie",
  WOM: "wombat-exchange",
  WBNB: "wbnb",
  SD: "stader",
  ANKR: "ankr",
  PSTAKE: "pstake-finance",
  BUSD: "binance-usd",
  HAY: "helio-protocol-hay",
};
