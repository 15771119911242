import { useCallback, useEffect, useMemo, useState } from "react";
import { user, chat } from "@pushprotocol/restapi";
import { mainnet, useWalletClient } from "wagmi";
import { createWalletClient, custom } from "viem";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import { Box, Grid, IconButton, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";

import IntegrationSettings from "./IntegrationSettings";
import Button, { VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";

import { selectMarketingModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { PUSH_ENV, apiUrl } from "features/configure";
import { alertServerError, alertInfo } from "features/helpers/utils";

import pushConnect from "assets/img/push-connect.png";
import walletConnect1 from "assets/img/xmtp-wallet-connect-1.png";
import walletConnect2 from "assets/img/xmtp-wallet-connect-2.png";

const useStyles = makeStyles(() => ({
  settingPushCard: (props) => ({
    backgroundColor: "#fff",
    boxShadow: "0px 1.28px 6.41px 0px #0000001A",
    padding: "20px",
    paddingRight: "192px",

    "& > p": {
      margin: 0,
      marginTop: "8px",
      color: props.customColors.grey[600],
    },

    "& > p:first-child": {
      fontSize: "16px",
      color: props.customColors.grey[700],
    },

    "& div": {
      fontSize: "16px",
      color: props.customColors.grey[700],
    },

    "& > div": {
      marginTop: "18px",
    },

    "& .address": {
      flex: 1,

      "& > div": {
        flex: 1,
        maxWidth: "440px",
        marginInline: "4px",
      },
    },
  }),
  connectModal: (props) => ({
    zIndex: 6000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& h3": {
      color: props.customColors.grey[700],
      fontSize: 18,
      fontWeight: 700,
      margin: 0,
    },

    "& p": {
      color: props.customColors.grey[600],
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.2,
      marginBottom: 0,
      marginTop: 12,
    },

    "& img": {
      marginTop: 8,
    },
  }),
}));

export const Push = {
  async getUser(address) {
    let usr = await user.get({
      account: address,
      env: PUSH_ENV,
    });

    if (!usr) await user.create({ account: address, env: PUSH_ENV });

    usr = await user.get({
      account: address,
      env: PUSH_ENV,
    });

    return usr;
  },

  async getKey(walletClient, encryptedKey) {
    const keys = await chat.decryptPGPKey({
      encryptedPGPPrivateKey: encryptedKey,
      signer: walletClient,
    });

    return keys;
  },
};

const IntegrationPush = () => {
  const { formatMessage } = useIntl();
  const { data: client } = useWalletClient();
  const marketingModule = useSelector(selectMarketingModule);
  const [entityName] = useOutletContext();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [step, setStep] = useState(0);
  const [account, setAccount] = useState();

  const theme = useTheme();
  const classes = useStyles(theme);

  const connectPush = useCallback(async () => {
    const connectWallet = async () => {
      await client.requestPermissions({ eth_accounts: {} });
      const [currentAccount] = await client.getAddresses();
      setAccount(currentAccount);
      setStep(1);
    };
    const connectPush = async () => {
      const walletClient = createWalletClient({
        account: account,
        chain: mainnet,
        transport: custom(window.ethereum),
      });
      const user = await Push.getUser(walletClient.account.address);
      const keys = await Push.getKey(client, user.encryptedPrivateKey);

      const { data } = await axios.post(
        `${apiUrl}/api/push/key/${entityName}`,
        {
          address: walletClient.account.address,
          keys,
        }
      );

      if (data.error) throw data.error;

      setConnectedAddress(client.account.address);
      dispatch(alertInfo("Push Protocol successfully connected"));
      setStep(0);
      setAccount(undefined);
      setOpen(false);
    };
    try {
      setLoading(true);
      await [connectWallet, connectPush][step]();
    } catch (error) {
      dispatch(alertServerError(error));
    } finally {
      setLoading(false);
    }
  }, [client, account, entityName, dispatch, step]);

  const stepData = useMemo(
    () =>
      [
        [
          {
            label: "Select Account",
            img: walletConnect1,
          },
          {
            label: "Connect Account",
            img: walletConnect2,
          },
        ],
        [
          {
            label: formatMessage({ id: "popup.pushSettings.create.title" }),
            img: pushConnect,
          },
        ],
      ][step],
    [step, formatMessage]
  );

  useEffect(() => {
    if (marketingModule?.push?.address)
      setConnectedAddress(marketingModule?.push?.address);
  }, [marketingModule?.push?.address]);

  return (
    <>
      <IntegrationSettings
        title={formatMessage({ id: "pushSettings.installation.title" })}
        description={formatMessage({
          id: "pushSettings.installation.description",
        })}
        content={
          <Box className={classes.settingPushCard}>
            <p>{formatMessage({ id: "pushSettings.installation.subtitle" })}</p>
            <p>
              {formatMessage({
                id: "pushSettings.installation.subdescription",
              })}
            </p>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div>
                {formatMessage({
                  id: "pushSettings.installation.walletAddress",
                })}
              </div>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
                className="address"
              >
                <InputField
                  style={{ width: "100%" }}
                  placeholder="-"
                  value={connectedAddress}
                  disabled
                />
                <Button onClick={() => setOpen(true)} disabled={loading}>
                  {connectedAddress
                    ? "Change wallet"
                    : "Connect to Push Protocol"}
                </Button>
              </Box>
            </Box>
          </Box>
        }
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={classes.connectModal}
      >
        <Box
          p={6}
          width={480}
          position={"absolute"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
          bgcolor={"#fff"}
          outline={"none"}
          borderRadius={2.25}
        >
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => setOpen(false)}
          >
            <i className="meta-crm-icon-ic_cancel" />
          </IconButton>

          <h3>
            {formatMessage({ id: "popup.pushSettings.installation.title" })}
          </h3>

          <p>
            {formatMessage({
              id: "popup.pushSettings.installation.description",
            })}
          </p>

          <Box
            sx={{
              px: 2.25,
              py: 3,
              mt: 2,
              width: "100%",
              bgcolor: (theme) => theme.customColors.grey[200],
            }}
          >
            <Grid container spacing={2}>
              {stepData.map((data, i, arr) => (
                <Grid
                  key={i}
                  item
                  xs={Math.floor(12 / arr.length)}
                  sx={{ color: (theme) => theme.customColors.grey[600] }}
                >
                  <div>{data.label}</div>
                  <img
                    src={data.img}
                    alt={data.label}
                    style={{ maxWidth: "164px" }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            mt={3}
            gap={0.5}
          >
            <Button disabled={loading} onClick={connectPush}>
              {
                [
                  formatMessage({ id: "global.btn.connect" }),
                  "Enable Push Profile",
                ][step]
              }
            </Button>
            <Button variant={VARIANT.TEXT} onClick={() => setOpen(false)}>
              {formatMessage({ id: "global.btn.cancel" })}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default IntegrationPush;
