import React from "react";
import TreeMapChart from "./TreeMapChart";
import CircleChart from "./CircleChart";
import { Box } from "@mui/material";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import WhiteCard from "./WhiteCard";
import _ from "lodash";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

export default function AdamCharts({ data }) {
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} gap={"10px"}>
        {_.map(_.filter(data, { graph_type: "count" }), (item) => {
          return (
            <WhiteCard
              label={item.data.label}
              value={parseInt(item.data.value)}
            />
          );
        })}
        <WhiteCard
          label="Avg. Customer Value"
          value={`$${parseInt(
            _.filter(data, { graph_type: "count" })[1].data.value /
              _.filter(data, { graph_type: "count" })[0].data.value
          ).toLocaleString()}`}
        ></WhiteCard>
      </Box>
      <TreeMapChart
        chartName="Chain Portfolio"
        data={_.find(data, { graph_type: "treeMap" })?.data}
        type="percentage"
      />
      <Box
        sx={{
          my: "10px",
          flex: 1,
        }}
      >
        <BarChart
          data={_.get(
            _.filter(data, { graph_type: "piechart" })[2],
            "data",
            []
          )}
          chartName="Protocol Usage"
        />
      </Box>
      <Box component={Grid} container spacing={1}>
        <Box component={Grid} item md={12} lg={6}>
          <CircleChart
            reverse
            data={_.get(
              _.filter(data, { graph_type: "piechart" })[0],
              "data",
              []
            )}
            chartName="Token Holdings"
            totalWallet={_.get(
              _.find(data, { graph_type: "count" }),
              "data.value",
              0
            )}
          />
        </Box>
        <Box component={Grid} item md={12} lg={6}>
          <CircleChart
            data={_.get(
              _.filter(data, { graph_type: "piechart" })[1],
              "data",
              []
            )}
            chartName="NFT Holdings"
            totalWallet={_.get(
              _.find(data, { graph_type: "count" }),
              "data.value",
              0
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
