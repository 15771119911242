import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box } from "@mui/material";
import { useState, useMemo } from "react";
import moment from "moment";
import { map } from "lodash-es";
import { Loading } from "features/common";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
import { formatDate } from "features/helpers/utils";

export default function XPerformanceTable({ posts, isLoading }) {
  const headCells = [
    {
      show: true,
      disabledSort: true,
      id: "postLink",
      label: <div>Post</div>,
      title: "Post",
      width: 190,
    },
    {
      show: true,
      disabledSort: false,
      id: "kol",
      label: "KOL",
      title: "KOL",
    },
    {
      show: true,
      disabledSort: false,
      id: "postDate",
      label: "Date",
      title: "Date",
    },
    {
      show: true,
      disabledSort: false,
      id: "impressions",
      label: "Impression",
      title: "Impression",
    },
    {
      show: true,
      disabledSort: false,
      id: "likes",
      label: "Like",
      title: "Like",
    },
    {
      show: true,
      disabledSort: false,
      id: "comments",
      label: "Comment",
      title: "Comment",
    },
    {
      show: true,
      disabledSort: false,
      id: "reposts",
      label: "Retweet",
      title: "Retweet",
    },
  ];
  const renderContents = (postData) => {
    const {
      postLink,
      comments,
      likes,
      reposts,
      impressions,
      postDate,
      author,
    } = postData;
    const {
      name: authorName,
      username: authorUsername,
      image: authorImage,
    } = author;

    const contents = [
      <Box
        slot="postLink"
        sx={{
          width: "190px",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <Box width={"180px"} className="truncate">
          {postLink}
        </Box>
        <a
          href={
            postLink.includes("https://") ? postLink : "https://" + postLink
          }
          target="_blank"
          rel="noopener"
          style={{ color: "#A5A5A5" }}
        >
          <i className="meta-crm-icon-ic_faq font-size-16" />
        </a>
      </Box>,
      <Box slot="kol" sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={authorImage}
          width={25}
          height={25}
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
        <Box
          ml="9px"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box fontSize={12} color="#555">
            {authorName}
          </Box>
          <Box fontSize={10} color="#777" fontWeight={400}>
            @{authorUsername}
          </Box>
        </Box>
      </Box>,
      <Box slot="postDate">{formatDate(postDate)}</Box>,
      <Box slot="impressions">{impressions.toLocaleString()}</Box>,
      <Box slot="likes">{likes.toLocaleString()}</Box>,
      <Box slot="comments">{comments.toLocaleString()}</Box>,
      <Box slot="reposts">{reposts.toLocaleString()}</Box>,
    ];
    return contents;
  };
  const [sort, setSort] = useState("postDate");
  const [order, setOrder] = useState("desc");
  const handleRequestSort = (event, property) => {
    const isAsc = sort === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSort(property);
  };
  const performanceData = useMemo(() => {
    return posts.sort((a, b) => {
      if (sort === "postDate") {
        return order === "asc"
          ? moment(a.postDate).unix() - moment(b.postDate).unix()
          : moment(b.postDate).unix() - moment(a.postDate).unix();
      }
      if (sort === "kol") {
        return order === "asc"
          ? a.author.name.localeCompare(b.author.name)
          : b.author.name.localeCompare(a.author.name);
      } else {
        return order === "asc" ? a[sort] - b[sort] : b[sort] - a[sort];
      }
    });
  }, [posts, sort, order]);

  if (isLoading)
    return (
      <Box position="relative" mt="20px">
        <Box
          height="40px"
          width="100%"
          bgcolor="white"
          className="item-flex"
          justifyContent="space-between"
        >
          {headCells.map((cell, index) => (
            <Box
              borderRight="1px solid transparent"
              width={cell.width || "auto"}
              p="12px"
              color="#777"
            >
              {cell.label}
            </Box>
          ))}
        </Box>
        <Box
          height="300px"
          bgcolor="white"
          className="item-flex"
          justifyContent="center"
        >
          <LoadingIcon style={{ height: 30, width: 30 }} />
        </Box>
      </Box>
    );

  return (
    <CRMTable
      rowData={performanceData}
      headers={headCells}
      contents={map(performanceData, (postData, i) => renderContents(postData))}
      onRowClick={() => {}}
      onRequestSort={handleRequestSort}
      order={order}
      orderBy={sort}
    />
  );
}
