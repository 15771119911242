import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { get, isEmpty, isEqual } from "lodash-es";
import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import moment from "moment";

import { useOutletContext, useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  InputField,
  SIZE as InputSize,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  StyledCustomizationContainer,
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
  StyledLine,
  StyledDiscordSettingContainer,
  StyledDiscordTicketContainer,
  StyledDiscordTicketTitle,
} from "./TelegramBot.styles";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { Loading } from "features/common";
import AssignTicketType from "../AssignTicketType/AssignTicketType";
import { useIntl } from "react-intl";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import useGetPagePermission from "hooks/useGetPagePermission";
import { useTheme } from "@mui/styles";
import { StyledWhiteContainer } from "../../common.styles";
import useNavigateEntity from "hooks/useNavigateEntity";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import Back from "features/metadesk/components/Back";
import { formatDate } from "features/helpers/utils";

const hasDuplicateName = (arr) => {
  if (isEmpty(arr)) {
    return false;
  }
  const nameSet = new Set();

  for (const item of arr) {
    const itemName = get(item, "name");
    if (!itemName) {
      return false;
    }
    if (nameSet.has(itemName.toLowerCase())) {
      return true; // 如果發現重複，則返回 true
    }
    nameSet.add(item.name.toLowerCase());
  }

  return false; // 如果迴圈完成而沒有找到重複，則返回 false
};
const formDataInit = {
  ticketList: [],
  welcomeMessage: "",
};

const mergeTicketTypesArray = (arr1, arr2) => {
  const map = new Map(arr2.map((item) => [item._id, item]));

  return arr1.map((item) => {
    return map.has(item._id) ? map.get(item._id) : item;
  });
};

const TelegramBot = () => {
  const confirm = useConfirm();
  const dispatchSnackbar = useDispatchSnackbar();

  const theme = useTheme();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const [ticketList, setTicketList] = useState(formDataInit.ticketList);
  const [isLoading, setIsLoading] = useState(false);
  const [isEqualData, setIsEqualData] = useState(true);
  const [isEqualTicketType, setIsEqualTicketType] = useState(true);

  const [chooseTicketType, setChooseTicketType] = useState([]);
  const { navigateEntity } = useNavigateEntity();
  const [initialType, setInitialType] = useState([]);
  const [tgBotData, setTgBotData] = useState({});
  const [needToUpdate, setNeedToUpdate] = useState(false);

  const [welcomeMessage, setWelcomeMessage] = useState(
    formDataInit.welcomeMessage
  );
  const { formatMessage } = useIntl();
  const firstFetchData = useRef({});
  const { fetchEntityConfig, entityConfig, ticketTypeList } =
    useFetchEntityConfig();

  const isConnected = _.get(entityConfig, "serviceModule.telegram.connected");
  const isAnyEmptyInTicketList = () => {
    return ticketList.some((obj) => obj.name === "");
  };

  const isValid =
    !hasDuplicateName(ticketList) &&
    welcomeMessage !== "" &&
    (!isEqualData || !isEqualTicketType) &&
    !isAnyEmptyInTicketList();

  useEffect(() => {
    const isEqual = _.isEqual(
      welcomeMessage,
      _.get(firstFetchData, "current.welcomeMessage")
    );

    setIsEqualData(isEqual);
    setIsEqualTicketType(_.isEqual(chooseTicketType, initialType));
    if (needToUpdate) {
      setNeedToUpdate(false);
    }
  }, [chooseTicketType, initialType, welcomeMessage, needToUpdate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchTelegramBotInfo();
      if (!isEmpty(ticketTypeList)) {
        await getTicketTypeList(ticketTypeList);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [ticketTypeList]);

  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, []);

  // 用來更新 telegramBot ticketType 在設定列表的樣式 要是沒有顏色與地址 給定預設值
  const getTicketTypeList = async (data) => {
    // 首先拿到 telegramBot 設定的ticketTypes
    const {
      data: { ticketTypes: ticketTypesFromTelegramBot },
    } = await axios.get(
      `${apiUrl}/api/manage/ticketTypes/${entityName}/telegramBot/${null}`
    );

    // 拿到這個entity全部的ticketTypes , 並且要是沒有  addressRequired , telegramButtonStyle 就給上預設值
    const dataWithDefaultTelegramInfo = data.map((item) => {
      if (item.addressRequired === undefined) {
        return { ...item, addressRequired: false };
      }
      return item;
    });

    // 合併兩個陣列 找到 這個entity全部的ticketTypes的id 有在telegramBot設定的ticketTypes的id的話, 就把這個 ticket替換成 telegramBot的ticket
    // (這樣那些顏色 addressRequired 才會是正確的)
    const newTicketList = mergeTicketTypesArray(
      dataWithDefaultTelegramInfo,
      ticketTypesFromTelegramBot.ticketTypes
    );

    // 設定list為樣式正確的newTicketList
    setTicketList(newTicketList);

    const modifiedTicketTypes = _.map(
      ticketTypesFromTelegramBot.ticketTypes,
      (x) => ({ ...x, assignee: _.get(x.assignee, "_id", null) })
    );

    // 設定 telegram Ticket 選中的結果
    setChooseTicketType(modifiedTicketTypes);

    setInitialType(modifiedTicketTypes);

    // 用來比對頁面ticketType是否有變更用的
    firstFetchData.current = {
      ...firstFetchData.current,
      ticketList: newTicketList,
    };
  };

  useUnload((e) => {
    if (!isEqualData || !isEqualTicketType) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const updateTelegramBotInfo = (data) => {
    const fetchWelcomeMessage = _.get(data, "introDescription", "");

    setWelcomeMessage(fetchWelcomeMessage);

    firstFetchData.current = {
      ...firstFetchData.current,
      welcomeMessage: fetchWelcomeMessage,
    };
  };

  const fetchTelegramBotInfo = async () => {
    try {
      const { data } = await axios.get(
        apiUrl + "/api/telegram/ticketBot/" + entityName
      );

      updateTelegramBotInfo(data);
      setTgBotData({
        created: data.created,
        name: data.name,
      });
    } catch (err) {
      if (
        !err?.response?.data?.error?.includes(
          "Telegram Ticket Bot is not setup correctly for"
        )
      ) {
        dispatchSnackbar({ err });
      }
    }
  };

  const disconnectFunction = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        `${apiUrl}/api/telegram/ticketBot/${entityName}/disconnect`
      );
      fetchEntityConfig({ entityName });
    } catch (error) {
      dispatchSnackbar({ err: error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = async () => {
    confirm({
      title: formatMessage({
        id: "telegram.disconnect.confirm.title",
      }),
      content: formatMessage({
        id: "telegram.disconnect.confirm.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "global.btn.cancel",
      }),
    })
      .then(() => {
        disconnectFunction();
      })
      .catch(() => {});
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (!isEqualTicketType) {
        try {
          const payload = chooseTicketType.filter(
            (ticket) => ticket.name.trim() !== ""
          );

          await axios.post(
            `${apiUrl}/api/manage/ticketTypes/${entityName}/telegramBot/${null}`,
            {
              ticketTypes: payload,
            }
          );

          await getTicketTypeList(ticketTypeList);

          // 成功打完api 後 把新資料設進firstFetchData  這樣比對資料才會更新 又變成equal狀態
          firstFetchData.current = {
            ...firstFetchData.current,
            ticketList: chooseTicketType,
          };
        } catch (error) {
          dispatch(
            enqueueSnackbar({
              message: get(error, "response.data.error", "error"),
              options: {
                variant: "error",
              },
            })
          );
        } finally {
        }
      }
      if (!isEqualData) {
        const { data } = await axios.post(
          apiUrl + `/api/telegram/ticketBot/${entityName}/configure`,
          {
            introDescription: welcomeMessage,
          }
        );
        updateTelegramBotInfo(data);
      }

      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "telegramSetting.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );

      fetchEntityConfig({ entityName });
    } catch (error) {
      console.log("error: ", error);
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
      setNeedToUpdate(true);
    }
  };

  const handleToIntegration = () => {
    navigateEntity("/settings/customization/telegramSetting");
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");
  const integrationPermission = pagePermission("integration");

  const renderInstallPrompt = () => (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={"432px"}
      margin={"0 auto"}
      flexDirection={"column"}
      marginTop={"5%"}
    >
      <Box
        component="img"
        width={"100%"}
        src={require(`assets/img/not_install_telegram_bot.svg`).default}
        mb={3}
      ></Box>

      <Box
        fontSize={"24px"}
        fontWeight={"700"}
        color={(theme) => theme.customColors.grey[800]}
        textAlign={"center"}
        mb="5px"
      >
        {formatMessage({ id: "telegramCustomization.emptyState.title" })}
      </Box>

      <Box
        color={(theme) => theme.customColors.grey[600]}
        textAlign={"center"}
        mb={3}
        fontWeight={400}
        lineHeight={"20px"}
      >
        {formatMessage({ id: "telegramCustomization.emptyState.description" })}
      </Box>
      {integrationPermission.canEdit && (
        <Button onClick={handleToIntegration}>
          {formatMessage({ id: "global.btn.getStarted" })}
        </Button>
      )}
    </Box>
  );

  if (!isConnected) {
    return renderInstallPrompt();
  }

  return (
    <FormProvider>
      <Box
        sx={{
          color: "#A5A5A5",
          cursor: "pointer",
          fontWeight: 500,
          fontSize: 14,
          marginRight: 4,
          margin: "12px 0",
          marginTop: "-30px",
        }}
        onClick={() => {
          navigateEntity("/settings/general/integration");
        }}
      >
        Back
        <i
          className="meta-crm-icon-ic_redo font-size-14"
          style={{ marginLeft: 4 }}
        />
      </Box>
      <Box mb="20px" display={"flex"} justifyContent={"space-between"}>
        <Box className="title">
          {formatMessage({ id: "integration.telegramBot.name" })}
        </Box>
        <Button onClick={handleDisconnect}>
          {formatMessage({ id: "global.btn.disconnect" })}
        </Button>
      </Box>
      <StyledWhiteContainer sx={{ marginBottom: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {formatMessage({ id: "integration.telegramBot.location" })}
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: theme.customColors.grey[200],
                color: theme.customColors.grey[500],
                padding: "12px",
                borderRadius: "3px",
                marginBottom: "4px",
              }}
            >
              {tgBotData.name}
            </Box>

            <Box
              display={"flex"}
              sx={{ fontSize: "12px", alignItems: "center" }}
            >
              <Box color={theme.customColors.grey[600]}>
                {formatMessage({ id: "global.header.createdTime" })} :
              </Box>
              <Box ml={"5px"} color={theme.customColors.purple[500]}>
                {formatDate(tgBotData.created, { time: true })}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </StyledWhiteContainer>
      <StyledWhiteContainer>
        <StyledCustomizationContainer>
          <Loading open={isLoading} fullScreen={false} />
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <StyledSettingTitle>
                  {formatMessage({
                    id: "telegramBotCustomization.intro.subtitle",
                  })}
                  <Box
                    sx={{
                      display: "inline-block",
                      color: theme.customColors.pink[500],
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </Box>
                </StyledSettingTitle>

                <StyledDescription>
                  {formatMessage({
                    id: "telegramBotCustomization.intro.description",
                  })}
                </StyledDescription>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  readonly={readonly}
                  fullWidth
                  size={SIZE.LARGE}
                  multiline
                  rows={5}
                  variant={VARIANT.OUTLINED}
                  maxLength={500}
                  placeholder={formatMessage({
                    id: "telegramBotCustomization.intro.description",
                  })}
                  value={welcomeMessage || ""}
                  onChange={(e) => {
                    setWelcomeMessage(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Box>
          <StyledLine />

          <StyledGeneralSettingsContainer style={{ overflowX: "auto" }}>
            <StyledSettingTitle>
              <span className="mgr4">
                {formatMessage({
                  id: "telegramBotCustomization.ticketType.title",
                })}
              </span>
              <Box
                sx={{
                  display: "inline-block",
                  color: theme.customColors.pink[500],
                  marginRight: "4px",
                }}
              >
                *
              </Box>
              <IconTooltip
                customColor="#A5A5A5"
                content={formatMessage({
                  id: "telegramBotCustomization.ticketType.tooltip",
                })}
                icon={<i className="meta-crm-icon-ic_info font-size-16" />}
              />
            </StyledSettingTitle>
            <StyledDescription>
              {formatMessage({
                id: "telegramBotCustomization.ticketType.description",
              })}
            </StyledDescription>
            <StyledDiscordSettingContainer>
              <StyledDiscordTicketContainer>
                <StyledDiscordTicketTitle>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    pl={"55px"}
                    mb="12px"
                  >
                    <Box flex={"0.4 1 0%"}>
                      {formatMessage({
                        id: "DiscordBotCustomization.ticketType.name",
                      })}
                    </Box>

                    <Box flex={"0.4 1 0%"}>
                      {formatMessage({
                        id: "DiscordBotCustomization.ticketType.requireAddress",
                      })}
                    </Box>
                    <Box flex={"0.3 1 0%"}></Box>
                  </Box>
                </StyledDiscordTicketTitle>

                <AssignTicketType
                  serviceName={"telegram"}
                  canEdit={canEdit}
                  ticketList={ticketList}
                  chooseTicketType={chooseTicketType}
                  setChooseTicketType={setChooseTicketType}
                />
              </StyledDiscordTicketContainer>
            </StyledDiscordSettingContainer>
          </StyledGeneralSettingsContainer>

          {canEdit && (
            <FixedButton>
              <Button
                sx={{ width: "120px" }}
                onClick={handleSave}
                disabled={!isValid || isLoading}
              >
                Save
              </Button>
            </FixedButton>
          )}
          <Prompt when={!isEqualData || !isEqualTicketType} />
        </StyledCustomizationContainer>
      </StyledWhiteContainer>
    </FormProvider>
  );
};

export default TelegramBot;
