import React, { useState, useContext, createContext } from "react";

// Create Context
const OnboardingContext = createContext();

export const useOnboardingState = () => useContext(OnboardingContext);

export const OnboardingContextProvider = ({ children }) => {
  const [step, setStep] = useState(0);

  function nextStep() {
    setStep((prevStep) => prevStep + 1);
  }

  function prevStep() {
    setStep((prevStep) => prevStep - 1);
  }

  return (
    <>
      <OnboardingContext.Provider value={{ step, nextStep, prevStep }}>
        {children}
      </OnboardingContext.Provider>
    </>
  );
};

export default OnboardingContextProvider;
