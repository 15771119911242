import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "features/common/redux/actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledContainer,
  StyledList,
  StyledBottomBar,
  StyledContent,
  StyledInfo,
  StyledTitle,
  OpenLabel,
  CloseLabel,
} from "./GithubIssue.styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { apiUrl } from "features/configure";
import axios from "axios";
import { useTheme } from "@mui/styles";
import ImagePreview from "features/metadesk/components/ImagePreview";
import ImageUpload from "features/metadesk/components/ImageUpload";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import moment from "moment";
import { useFetchGithub } from "features/metadesk/redux/fetchGithub";
import { Loading } from "features/common";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
import { formatDate } from "features/helpers/utils";

const GithubIssue = ({ selectedTicket }) => {
  const [entityName, isCustomDomain] = useOutletContext();
  const [openCreate, setOpenCreate] = useState(false);
  const { formatMessage } = useIntl();
  const {
    githubIssues,
    githubRepos,
    fetchGithubIssues,
    createGithubIssue,
    fetchGithubRepos,
    fetchGithubPending,
    createGithubPending,
  } = useFetchGithub();
  const theme = useTheme();
  const [repository, setRepository] = useState("");
  const [assignee, setAssignee] = useState("");
  let initDescription = `\n\n----------------------------------------------------------\nURL: ${_.get(
    window,
    "location.href"
  )}\n`;
  if (selectedTicket.team) {
    initDescription += `Assignee: ${_.get(
      selectedTicket,
      "team.name"
    )} (${_.get(selectedTicket, "team.address")})\n`;
  }
  const [description, setDescription] = useState(initDescription);
  const [title, setTitle] = useState("");
  const [imgs, setImgs] = useState([]);
  const { entityConfig } = useFetchEntityConfig();
  const { userData } = useConnectWallet();

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("tickets");

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTicket && _.get(entityConfig, "serviceModule.github.connected"))
      fetchGithubIssues({ entityName, ticketId: selectedTicket._id });
  }, [selectedTicket, entityConfig]);

  useEffect(() => {
    if (openCreate) {
      fetchGithubRepos({ entityName });
    }
  }, [openCreate]);

  const renderStatus = (status) => {
    if (status === "open") {
      return (
        <OpenLabel>
          <i
            className="meta-crm-icon-ic-bullseye font-size-12"
            style={{ marginRight: 2 }}
          />{" "}
          {formatMessage({ id: "global.status.open" })}
        </OpenLabel>
      );
    } else {
      return (
        <CloseLabel>
          <div className="startRow">
            <i
              className="meta-crm-icon-ic-checkCircle font-size-12"
              style={{ marginRight: 2 }}
            />{" "}
            {formatMessage({ id: "global.status.close" })}
          </div>
        </CloseLabel>
      );
    }
  };

  const renderContent = () => {
    if (githubIssues.length === 0) {
      return (
        <div className="column" style={{ height: "100%", color: "#A5A5A5" }}>
          <i className="meta-crm-icon-ic_boxOpenLight font-size-64" />
          <div style={{ margin: 8, marginTop: 3, fontWeight: 500 }}>
            {formatMessage({ id: "ticketDrawer.githubIssue.noIssue" })}
          </div>
          {canEdit && (
            <Button
              color={COLOR.SECONDARY}
              size={SIZE.SMALL}
              onClick={() => setOpenCreate(true)}
            >
              {formatMessage({ id: "ticketDrawer.githubIssue.create" })}
            </Button>
          )}
        </div>
      );
    }

    return (
      <div>
        {_.map(githubIssues, (issue, i) => {
          const regex = /<img src="(\S+?)" width="200px"\/>/g;
          const matches = [...issue.desc.matchAll(regex)];
          const images = matches.map((match) => match[1]);
          return (
            <>
              <Accordion
                key={i}
                disableGutters={true}
                id={i}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  borderRadius: "3px",
                  marginBottom: "8px",
                  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
                  "& .Mui-expanded": {
                    border: "1px solid #7B61FF",
                    borderRadius: "3px 3px 0 0",
                    borderBottom: 0,
                  },
                  "& .MuiAccordion-region": {
                    border: "1px solid #7B61FF",
                    borderRadius: "0 0 3px 3px",
                    borderTop: 0,
                  },
                  "&:hover": {
                    background: "#F7F1FE",
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    "& .Mui-expanded": { border: 0, marginBottom: 0 },
                    maxWidth: "100%",
                  }}
                  expandIcon={<ExpandMoreIcon style={{ color: "#7B61FF" }} />}
                >
                  <div>
                    <div
                      className="startRow"
                      style={{
                        marginBottom: 8,
                      }}
                    >
                      <StyledTitle>{issue.title} </StyledTitle>
                      <a
                        style={{ color: "#7B61FF", marginLeft: 4 }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(issue.url);
                          dispatch(
                            enqueueSnackbar({
                              message: (
                                <div
                                  style={{
                                    color: theme.customColors.purple[500],
                                  }}
                                  className="formTitle startRow"
                                >
                                  <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
                                  {formatMessage({
                                    id: "global.status.copied",
                                  })}
                                </div>
                              ),
                              options: {
                                key: new Date().getTime() + Math.random(),
                                variant: "info",
                              },
                            })
                          );
                          e.stopPropagation();
                        }}
                      >
                        <i
                          className="meta-crm-icon-ic_link font-size-16"
                          style={{ color: "#7B61FF" }}
                        />
                      </a>
                    </div>

                    <StyledInfo className="startRow">
                      <i className="meta-crm-icon-ic_book font-size-16 mgr3" />
                      {issue.repo}
                      <i
                        className="meta-crm-icon-ic_clock font-size-16 mgr3"
                        style={{ marginLeft: 4 }}
                      />
                      {issue.created &&
                        formatDate(issue.created, { time: true })}
                    </StyledInfo>
                    {issue.assignees && (
                      <StyledInfo style={{ marginTop: 5 }} className="startRow">
                        <i className="meta-crm-icon-ic_assign font-size-16 mgr3" />{" "}
                        {formatMessage({
                          id: "ticketDrawer.githubIssue.assignedTo",
                        })}
                        {issue.assignees}
                      </StyledInfo>
                    )}

                    <div>{renderStatus(issue.state)}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ color: "#555", fontSize: 12, wordBreak: "break-all" }}
                >
                  {issue.desc.replace(regex, "")}
                  {images &&
                    images.length > 0 &&
                    _.map(images, (image) => (
                      <img src={image} style={{ marginTop: 4, height: 75 }} />
                    ))}
                </AccordionDetails>
              </Accordion>
              <div />{" "}
            </>
          );
        })}
      </div>
    );
  };
  const getAllRelatedFiles = async () => {
    try {
      let messages = await axios.get(
        `${apiUrl}/api/messages/ticket?id=${selectedTicket._id}&userId=${_.get(
          selectedTicket,
          "user._id"
        )}&entityName=${entityName}`
      );
      let images = [];
      for (let message of messages.data) {
        if (message.imagesUrl && message.imagesUrl.length > 0) {
          images = [...images, ...message.imagesUrl];
        }
      }
      setImgs(images);
    } catch (err) {
      console.log(err);
    }
  };

  const renderCreateContent = () => {
    return (
      <>
        <StyledList style={{ background: "white" }}>
          <div className="formField">
            <div className="inputTitle startRow">
              {formatMessage({ id: "ticketDrawer.githubIssue.repository" })}
              <span className="required" style={{ marginRight: 2 }}>
                *
              </span>
            </div>
            <CustomDropdown
              border
              fullWidth={true}
              data={_.map(githubRepos, (o) => {
                return { label: o.name, value: o.name };
              })}
              value={repository}
              onSelect={(e) => {
                setRepository(e.target.value);
              }}
            />
          </div>
          <div className="formField">
            <div className="inputTitle">
              {formatMessage({
                id: "ticketDrawer.githubIssue.assignedToOptional",
              })}
            </div>
            <CustomDropdown
              border
              fullWidth={true}
              data={_.map(
                _.find(githubRepos, ["name", repository])?.assignees,
                (o) => {
                  return { label: o, value: o };
                }
              )}
              value={assignee}
              onSelect={(e) => {
                setAssignee(e.target.value);
              }}
            />
          </div>
          <div className="formField">
            <InputField
              placeholder="Issue title"
              title={"Title"}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="formField">
            <InputField
              minRows={6}
              placeholder="Issue description"
              multiline={true}
              title={"Description"}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div className="formField">
            <div className="inputTitle">
              {formatMessage({ id: "global.commonWord.image" })} （ jpg, png,
              pdf ）
            </div>
            {imgs.length > 0 ? (
              <div
                style={{
                  borderRadius: 3,
                  border: "1px dashed #E6E6E6",
                }}
              >
                <ImagePreview imgs={imgs} setImgs={setImgs} size={75} />
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <ImageUpload
                  acceptType={["png", "jpg", "heic", "jpeg", "pdf"]}
                  uploadComponent={
                    <div
                      style={{
                        borderRadius: 3,
                        border: "1px dashed #E6E6E6",
                      }}
                    >
                      <div style={{ margin: "38px 38px", height: 70 }}>
                        <div
                          style={{
                            width: 200,
                            textAlign: "center",
                          }}
                        >
                          <i
                            className="meta-crm-icon-ic_uploadImage"
                            style={{ color: "#E6E6E6", fontSize: 60 }}
                          />
                          <div style={{ fontSize: 12, color: "#a5a5a5" }}>
                            {formatMessage({
                              id: "ticketDrawer.githubIssue.clickToUpload",
                            })}
                          </div>
                        </div>
                      </div>
                      <Button
                        size={SIZE.SMALL}
                        variant={VARIANT.OUTLINED}
                        style={{
                          width: "128px",
                          position: "absolute",
                          top: 40,
                          right: 30,
                        }}
                      >
                        {formatMessage({ id: "global.btn.chooseFile" })}
                      </Button>
                    </div>
                  }
                  value={imgs}
                  onChange={(list) => {
                    setImgs(list);
                  }}
                />
                <Button
                  onClick={getAllRelatedFiles}
                  size={SIZE.SMALL}
                  style={{ position: "absolute", top: 80, right: 29 }}
                >
                  {formatMessage({ id: "global.btn.allRelatedFiles" })}
                </Button>
              </div>
            )}
          </div>
        </StyledList>
        <StyledBottomBar>
          <div className="endRow">
            <Button
              color={COLOR.SECONDARY}
              variant={VARIANT.TEXT}
              onClick={() => {
                setOpenCreate(false);
              }}
            >
              {formatMessage({ id: "global.btn.cancel" })}
            </Button>
            <Button
              color={COLOR.SECONDARY}
              onClick={async () => {
                try {
                  await createGithubIssue({
                    entityName,
                    ticketId: selectedTicket._id,
                    title,
                    imgs,
                    description,
                    assignee,
                    repository,
                  });
                  setTitle("");
                  setDescription(initDescription);
                  setAssignee("");
                  setImgs([]);
                  setRepository("");
                  setOpenCreate(false);
                } catch (e) {}
              }}
            >
              {formatMessage({ id: "global.btn.create" })}
            </Button>
          </div>
        </StyledBottomBar>
      </>
    );
  };

  return (
    <StyledContainer>
      <Loading
        open={fetchGithubPending || createGithubPending}
        fullScreen={false}
        text={createGithubPending ? "Creating..." : "Loading..."}
      />
      <StyledContent>
        {openCreate ? (
          <div className="modalHeader">
            {formatMessage({ id: "ticketDrawer.githubIssue.createIssue" })}
          </div>
        ) : (
          <div className="betweenRow">
            <div className="modalHeader startRow">
              {formatMessage({ id: "ticketDrawer.githubIssue.title" })}
              <Badge
                color="secondary"
                showZero
                badgeContent={githubIssues.length}
                style={{ marginLeft: 18 }}
              />
            </div>
            {canEdit && (
              <Button
                color={COLOR.SECONDARY}
                size={SIZE.SMALL}
                onClick={() => setOpenCreate(true)}
              >
                {formatMessage({ id: "ticketDrawer.githubIssue.create" })}
              </Button>
            )}
          </div>
        )}
      </StyledContent>
      {openCreate ? (
        renderCreateContent()
      ) : (
        <StyledList>{renderContent()}</StyledList>
      )}
      {!openCreate && (
        <StyledBottomBar>
          <a target="_blank" href="https://github.com">
            <div className="endRow">
              {formatMessage({ id: "ticketDrawer.githubIssue.checkOnGithub" })}
              <i
                className="meta-crm-icon-ic_github font-size-16"
                style={{ marginLeft: 4 }}
              />
            </div>
          </a>
        </StyledBottomBar>
      )}
    </StyledContainer>
  );
};

export default GithubIssue;
