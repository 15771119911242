import React, { useState, useEffect, useRef, useMemo } from "react";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import CodeSnipper from "../components/CodeSnipper";
import { useIntl } from "react-intl";
import Button, {
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import ApiKey from "../components/ApiKey";
import WalletDetect from "../components/WalletDetect";
import axios from "axios";
import { useOutletContext } from "react-router";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

export default function Code({ data, integrityInfo, handleRefetch }) {
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  const handleRegenerateAPI = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/manage/apiKey/${entityName}/widget`,
        {
          widgetId: data?._id,
        }
      );
      await handleRefetch();
      dispatchSnackbar({
        message: "Regenerate API Key Success",
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      // dispatchSnackbar({ err });
    }
  };
  return (
    <>
      <StyledWhiteContainer style={{ margin: "12px 0" }}>
        <ApiKey
          apiKey={data.apiKey}
          apiKeyCreated={data.created}
          handleRegenerate={handleRegenerateAPI}
        />
      </StyledWhiteContainer>

      <StyledWhiteContainer style={{ margin: "12px 0" }}>
        <CodeSnipper data={data} integrityInfo={integrityInfo} />
      </StyledWhiteContainer>
      <StyledWhiteContainer style={{ margin: "12px 0" }}>
        <WalletDetect />
      </StyledWhiteContainer>
      {/* <StyledWhiteContainer
        style={{ marginBottom: "12px", padding: "16px 32px 24px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size={SIZE.XL}
            variant={VARIANT.OUTLINED}
            disabled={true}
            sx={{ width: 120 }}
          >
            Discard
          </Button>
          <Button
            // disabled={isSaveDisabled()}
            size={SIZE.XL}
            sx={{ ml: "12px", width: 120 }}
            // onClick={handleSave}
          >
            {formatMessage({
              id: "global.btn.save",
            })}
          </Button>
        </Box>
      </StyledWhiteContainer> */}
    </>
  );
}
