import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";
export const Loading = ({
  open,
  text,
  fullScreen = true,
  popover,
  white,
  topPos,
  sm,
  longWait,
  zIndex = 100,
}) => {
  const defaultTexts = [
    "Fetching blockchain data...",
    "Aggregating information...",
    "Generating insights...",
    "Finalizing your report...",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    let interval;
    if (open && longWait) {
      interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => {
          if (prevIndex < defaultTexts.length - 1) {
            return prevIndex + 1;
          }
          clearInterval(interval);
          return prevIndex;
        });
      }, 7000);
    } else {
      setCurrentTextIndex(0);
    }
    return () => {
      clearInterval(interval);
    };
  }, [open, longWait]);

  const gif = (
    <LoadingIcon style={{ height: sm ? 20 : 70, width: sm ? 20 : 70 }} />
  );

  const renderText = () => {
    return (
      <div
        style={{
          fontSize: sm ? 14 : 18,
          fontWeight: 700,
          color: "#383538",
        }}
      >
        {longWait ? defaultTexts[currentTextIndex] : text}
      </div>
    );
  };
  if (popover) {
    return (
      <Modal className="modal" open={open}>
        <div
          style={{
            background: "#FFFFFF",
            borderRadius: 10,
            width: 180,
            textAlign: "center",
            padding: 48,
          }}
        >
          {gif}
          {renderText()}
        </div>
      </Modal>
    );
  }

  return (
    <div
      style={{
        visibility: open ? "visible" : "hidden",
        background: white ? "#FFFFFF" : "rgba(255,255,255,0.6)",
        position: fullScreen ? "fixed" : "absolute",
        height: "100%",
        zIndex,
        textAlign: "center",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: topPos ? topPos : "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        {gif}
        {renderText()}
      </div>
    </div>
  );
};

export default Loading;
