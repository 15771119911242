import React, { useState } from "react";
import { Box } from "@mui/material";
import useOutsideClick from "features/metadesk/campaign/campaignDetailPerformance/hook/useOutsideClick";
import {
  StyledDropDownContainer,
  StyledDropDownBtn,
  StyledDropDownText,
  StyledHRLine,
} from "features/metadesk/settings/IntegrationPage/components/integrationComponent.styles";
import { truncate } from "lodash";

export default function XFilterDropDown({
  list,
  selectedFilters,
  setSelectedFilters,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  const handleReset = () => {
    setSelectedFilters([]);
  };

  const handleFilterChange = (filter, setFilterState, currentFilters) => {
    setFilterState(
      currentFilters.includes(filter)
        ? currentFilters.filter((f) => f !== filter) // 移除選中的項目
        : [...currentFilters, filter] // 新增選中的項目
    );
  };

  return (
    <Box ref={ref} position="relative">
      <StyledDropDownBtn
        onClick={() => setIsOpen(!isOpen)}
        active={isOpen}
        sx={{ bgcolor: "white", marginRight: 0 }}
      >
        <i className="meta-crm-icon-ic_filter2 font-size-16" />
        Filter {selectedFilters.length > 0 && `(${selectedFilters.length})`}
      </StyledDropDownBtn>
      {isOpen && (
        <StyledDropDownContainer sx={{ width: "183px" }}>
          <Box sx={{ p: "6px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              height="24px"
              alignItems="center"
              fontSize="10px"
            >
              <Box color="#A5A5A5">Filter</Box>
              <Box
                color="#7B61FF"
                sx={{ cursor: "pointer" }}
                onClick={handleReset}
              >
                Clear All
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "#E5E5E5",
                margin: "4px 0",
              }}
            />
          </Box>
          <Box fontSize={12} color="#555">
            <Box color="#7B61FF" fontSize={10} p="6px 6px 4px" fontWeight={700}>
              KOL {`(${selectedFilters.length})`}
            </Box>
            <Box sx={{ maxHeight: "calc(100vh - 220px)", overflowY: "auto" }}>
              {list.map((item, index) => (
                <StyledDropDownText
                  key={index}
                  onClick={() =>
                    handleFilterChange(
                      item.name,
                      setSelectedFilters,
                      selectedFilters
                    )
                  }
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <img
                      src={item.image}
                      width={24}
                      height={24}
                      style={{
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                    <Box ml="9px">
                      <Box mr="4px" fontSize={12} color="#555" fontWeight={700}>
                        {truncate(item.name, { length: 15 })}
                      </Box>
                      <Box fontSize="10px" color="#777">
                        @{truncate(item.username, { length: 15 })}
                      </Box>
                    </Box>
                  </Box>
                  {selectedFilters.includes(item.name) ? (
                    <i
                      className="meta-crm-icon-ic_check font-size-24"
                      style={{ color: "#7B61FF" }}
                    />
                  ) : (
                    <Box width="24px" />
                  )}
                </StyledDropDownText>
              ))}
            </Box>
          </Box>
        </StyledDropDownContainer>
      )}
    </Box>
  );
}
