import { Box } from "@mui/material";
import AutoCompleteDropdown from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import React, { memo, useContext, useState } from "react";
import { PerformanceContext } from "../context/PerformanceProvider";
import { useIntl } from "react-intl";

function DimensionDropDown({ handleOpenDimension }) {
  const { dimension, setDimension } = useContext(PerformanceContext);
  const { formatMessage } = useIntl();
  const options = [
    { label: formatMessage({ id: "campaign.type.none" }), value: "" },
    { label: formatMessage({ id: "campaign.type.source" }), value: "Source" },
    { label: formatMessage({ id: "campaign.type.medium" }), value: "Medium" },
    { label: formatMessage({ id: "campaign.type.content" }), value: "Content" },
    { label: formatMessage({ id: "campaign.type.term" }), value: "Term" },
  ];
  return (
    <Box display="flex" width={"100%"}>
      <AutoCompleteDropdown
        style={{ backgroundColor: "white" }}
        size="small"
        value={dimension}
        onChange={(event, newValue) => {
          setDimension(newValue.value);
        }}
        options={options}
        placeholder={formatMessage({ id: "campaign.type.none" })}
      />
      {/* <IconButton
        style={{ marginLeft: "10px" }}
        size="large"
        onClick={handleOpenDimension}
      >
        <i className="meta-crm-icon-ic_x_circle font-size-32" />
      </IconButton> */}
    </Box>
  );
}

export default memo(DimensionDropDown);
