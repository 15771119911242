import React, { useState } from "react";
import _ from "lodash";
import { Grid } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import {
  StatusLabel,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useDispatch } from "react-redux";
import { ENTITY_CONFIG_UPDATE_SUCCESS } from "../redux/constants";
import { Box } from "@mui/material";
import LoadingIcon from "components/LoadingComponent/LoadingIcon";

const Security = () => {
  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();
  const { entityConfig, updateEntityConfig, updateEntityConfigPending } =
    useFetchEntityConfig();
  const [isEnableSignature, setIsEnableSignature] = useState(
    !entityConfig?.signatureSignInDisabled
  );

  const dispatch = useDispatch();

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("security");

  async function handleSwitchChange(newValue) {
    setIsEnableSignature(newValue);

    await axios.post(
      `${apiUrl}/api/manage/updateEntityConfig/signatureSignIn`,
      {
        entityName,
        signatureSignInDisabled: !newValue,
      }
    );
    dispatch({
      type: ENTITY_CONFIG_UPDATE_SUCCESS,
      signatureSignInDisabled: !newValue,
    });
  }

  return (
    <div>
      <div className="title">
        {formatMessage({ id: "securitySetting.security.title" })}
      </div>
      <div className="description">
        {formatMessage({ id: "securitySetting.security.description" })}
      </div>

      <StyledWhiteContainer mt={"20px"}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <div className="subTitle startRow">
              <Box className="mgr4">
                {formatMessage({ id: "securitySetting.signatureSignIn.title" })}
              </Box>
              <StatusLabel
                color={isEnableSignature ? COLOR.SECONDARY : COLOR.ERROR}
              >
                {isEnableSignature ? "Enabled" : "Disabled"}
              </StatusLabel>
            </div>
            <div className="description">
              {formatMessage({
                id: "securitySetting.signatureSignIn.description",
              })}
            </div>
          </Grid>
          {canEdit && (
            <Grid item xs={2}>
              {updateEntityConfigPending ? (
                <LoadingIcon className="smIcon" />
              ) : (
                <Switch
                  disabled={updateEntityConfigPending}
                  checked={isEnableSignature}
                  onChange={handleSwitchChange}
                />
              )}
            </Grid>
          )}
        </Grid>
      </StyledWhiteContainer>
    </div>
  );
};

export default Security;
