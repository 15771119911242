import Button from "@metacrm/metacrm-material-ui/dist/Button";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { Box } from "@mui/material";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { PlanBox } from "./ManagePlan.styles";

export default function ManagePlan() {
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  async function handleUpgradePlan() {
    try {
      await confirm({
        title: "Request a Demo",
        content:
          "Please reserve a time slot with us through the form linked below.",
        confirmText: "Go to link",
        color: "secondary",
        onConfirm: () =>
          window.open(
            "https://metaform.xyz/user/633ba1665d7cde34a5658167",
            "_blank",
            "noopener noreferrer"
          ),
      });
    } catch (e) {
      console.log(e);
    }
  }

  const currentPlan = "Pro";

  const plans = [
    {
      name: "Free",
      price: "0",
      btnWord: "Try for free",
      features: [
        "3 Attribution Link per month",
        "300 credits for importing wallet addresses",
        "Credit for refreshing wallet address data 50 times per month",
        "1 outbound marketing campaign",
        "Basic Marketing Analytics",
        "7 Days of History Lookback",
      ],
      icon: (
        <i
          className="meta-crm-icon-ic_moon1 font-size-24"
          style={{ color: "#FFC814" }}
        />
      ),
    },
    {
      name: "Growth",
      price: "299",
      btnWord: "Request a demo",
      features: [
        "15 Attribution Link per month",
        "20,000 credits for importing wallet addresses",
        "Credit for refreshing wallet address data 4,000 times per month",
        "15 outbound marketing campaign",
        "Advanced Marketing Analytics",
        "30 Days of History Lookback",
      ],
      discount: "20% off",
      icon: (
        <i
          className="meta-crm-icon-ic_moon2 font-size-24"
          style={{ color: "#FFC814" }}
        />
      ),
    },
    {
      name: "Pro",
      price: "599",
      btnWord: "Request a demo",
      features: [
        "50 Attribution Link per month",
        "50,000 credits for importing wallet addresses",
        "Credit for refreshing wallet address data 12,000 times per month",
        "60 outbound marketing campaign",
        "Advanced Marketing Analytics",
        "90 Days of History Lookback",
      ],
      discount: "20% off",
      icon: (
        <i
          className="meta-crm-icon-ic_moon3 font-size-24"
          style={{ color: "#FFC814" }}
        />
      ),
    },
    {
      name: "Enterprise",
      price: "Custom",
      btnWord: "Request a demo",
      features: [
        "Everything in Enterprise",
        "Unlimited access with most of the features, with customized data analytics",
      ],
      icon: (
        <i
          className="meta-crm-icon-ic_moon4 font-size-24"
          style={{ color: "#FFC814" }}
        />
      ),
    },
  ];

  const [yearPay, setYearPay] = useState(false);

  return (
    <div>
      <div className="title">
        {formatMessage({ id: "billing.planManagement.btn" })}
      </div>
      <Box
        mb="16px"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="description">
          {formatMessage({ id: "billing.planManagement.description" })}
        </div>
      </Box>

      <Grid container spacing={3}>
        {plans.map((plan, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={3}
            style={{ alignItems: "stretch" }}
          >
            <PlanBox active={plan.name === currentPlan}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: "16px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "16px",
                    color: "#555",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {plan.icon}
                  <span style={{ marginLeft: "4px" }}>{plan.name}</span>
                </Box>
                {plan.discount && (
                  <Box
                    sx={{
                      bgcolor: "#FAA7E0",
                      padding: "0px 5px",
                      color: "white",
                      borderRadius: "2.5px",
                    }}
                  >
                    {plan.discount}
                  </Box>
                )}
              </Box>
              <Box sx={{ fontSize: "28px", color: "#555", fontWeight: 700 }}>
                {plan.name === "Enterprise" ? (
                  "Custom"
                ) : (
                  <>
                    ${plan.price}
                    <span style={{ fontSize: "20px" }}> / month</span>
                  </>
                )}
              </Box>
              <Box mb="16px" sx={{ fontSize: "14px", color: "#777" }}>
                Billed annually
              </Box>
              <Button
                sx={{ mb: "32px" }}
                disabled={plan.name === currentPlan || plan.name === "Free"}
                fullWidth
                onClick={handleUpgradePlan}
              >
                {plan.name === currentPlan ? "Current Plan" : plan.btnWord}
              </Button>
              <Box>
                {plan.features.map((feature, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      mb: "8px",
                    }}
                  >
                    <i
                      className="meta-crm-icon-ic_check font-size-20"
                      style={{ color: "#7B61FF" }}
                    />

                    <Box
                      sx={{
                        fontSize: "14px",
                        color: "#777",
                        mt: "4px",
                      }}
                    >
                      {feature}
                    </Box>
                  </Box>
                ))}
              </Box>
            </PlanBox>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
