import styled from "@emotion/styled";
import { Box } from "@mui/material";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

export const OptionItemGroup = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  columnGap: 12,
  marginBottom: 16,
}));

export const OptionItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: 4,
  padding: 8,
  borderRadius: 5,
  background: "#FAF6FF",
}));

export const SurveyMsgInput = styled(InputField, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isError }) => ({
  "& .MuiInputBase-root": {
    height: 40,
    width: 470,
    border: !isError
      ? "1px solid #E6E6E6"
      : `1px solid ${theme.customColors.pink[600]}`,
    borderRadius: 8,
    marginBottom: 16,

    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `0 !important`,
    boxShadow: "none !important",
  },
}));

export const SurveyMsgContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isError }) => ({
  height: 40,
  width: 470,
  border: !isError
    ? "1px solid #E6E6E6"
    : `1px solid ${theme.customColors.pink[600]}`,
  borderRadius: 8,
  marginBottom: 16,
  color: theme.customColors.grey[700],
  display: "flex",
  alignItems: "center",
  paddingLeft: 11,
}));

export const ErrorText = styled("p", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  margin: 0,
  position: "relative",
  top: -10,
  fontSize: theme.fontSize.TINY,
  color: theme.customColors.pink[600],
}));
