import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

export const StyledDidTabContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  overflow: `hidden`,
}));

export const StyledDIDAddressInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "relative",
  padding: "12px",
}));

export const StyledCustomTabsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));
