import { MACRO_ACTION_TYPES } from "../constants";
import { createAction } from "../reducer.utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import {
  parseToOptionFormat,
  parseSingleAnswerToOptionFormat,
} from "features/metadesk/settings/Macros/CreateMacroModal/dropdown.utils";
import { enqueueSnackbar } from "features/common/redux/actions";
import { get } from "lodash-es";

export const replaceItemInArray = (arr, newItem) => {
  return arr.map((item) => (item._id === newItem._id ? newItem : item));
};

export const fetchCategoriesSuccess = (categoriesArray) =>
  createAction(MACRO_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS, categoriesArray);

export const fetchCategoriesAsyncAction = ({ entityName }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/macros/${entityName}/categories`
      );
      dispatch(fetchCategoriesSuccess(data));
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const postCategoriesSuccess = (categoryData) =>
  createAction(MACRO_ACTION_TYPES.POST_CATEGORIES_SUCCESS, categoryData);

export const postCategoriesAsyncAction = ({ entityName, name, cb }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/macros/${entityName}/categories`,
        {
          name: name,
        }
      );

      dispatch(postCategoriesSuccess(data));
      const newData = parseSingleAnswerToOptionFormat(data);
      cb(newData);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const editCategoriesSuccess = (categoryData) =>
  createAction(MACRO_ACTION_TYPES.EDIT_CATEGORIES_SUCCESS, categoryData);

export const editCategoriesAsyncAction = ({ entityName, id, name, cb }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/manage/macros/${entityName}/categories/${id}`,
        {
          name: name,
        }
      );
      dispatch(editCategoriesSuccess(data));
      const newData = parseSingleAnswerToOptionFormat(data);
      cb(newData);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const deleteCategoriesSuccess = (categoryId) =>
  createAction(MACRO_ACTION_TYPES.DELETE_CATEGORIES_SUCCESS, categoryId);

export const deleteCategoriesAsyncAction = ({
  entityName,
  id,
  selectedValue,
  cb,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/api/manage/macros/${entityName}/categories/${id}`
      );

      dispatch(deleteCategoriesSuccess(id));
      const newValue = selectedValue._id === id ? "" : selectedValue;
      cb(newValue);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const fetchTagsSuccess = (tagsArray) =>
  createAction(MACRO_ACTION_TYPES.FETCH_TAGS_SUCCESS, tagsArray);

export const fetchTagsAsyncAction = ({ entityName }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/tags/${entityName}/ticket`
      );
      dispatch(fetchTagsSuccess(data));
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const postTagsSuccess = (tagData) => {
  return createAction(MACRO_ACTION_TYPES.POST_TAGS_SUCCESS, tagData);
};

export const postTagsAsyncAction = ({
  entityName,
  value,
  color,
  selectedValue,
  cb,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/ticket`,
        {
          value: value,
          color: color,
          type: "ticket",
        }
      );
      const newData = parseSingleAnswerToOptionFormat(data);
      dispatch(postTagsSuccess(data));
      cb([...selectedValue, newData]);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const editTagsSuccess = (tagData) =>
  createAction(MACRO_ACTION_TYPES.EDIT_TAGS_SUCCESS, tagData);

export const editTagsAsyncAction = ({
  entityName,
  id,
  value,
  color,
  selectedValue,
  cb,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/manage/tags/${entityName}/${id}/ticket`,
        {
          value: value,
          color: color,
          type: "ticket",
        }
      );
      dispatch(editTagsSuccess(data));
      const newData = parseSingleAnswerToOptionFormat(data);
      const newValue = replaceItemInArray(selectedValue, newData);
      cb(newValue);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const deleteTagsSuccess = (tagId) =>
  createAction(MACRO_ACTION_TYPES.DELETE_TAGS_SUCCESS, tagId);

export const deleteTagsAsyncAction = ({
  entityName,
  id,
  selectedValue,
  cb,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/api/manage/tags/${entityName}/${id}/ticket`
      );

      dispatch(deleteTagsSuccess(id));
      const newValue = selectedValue.filter((item) => item._id !== id);
      cb(newValue);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const postMacroSuccess = (macro) => {
  return createAction(MACRO_ACTION_TYPES.CREATE_MACRO_SUCCESS, macro);
};

export const postMacroAsyncAction = ({ entityName, sendData, cb }) => {
  const { questions, channels, tags, category, answer } = sendData;
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/macros/${entityName}`,
        {
          questions,
          channels,
          tags,
          category,
          answer,
        }
      );
      dispatch(postMacroSuccess(data));
      cb();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const fetchMacroListStart = () =>
  createAction(MACRO_ACTION_TYPES.FETCH_MACRO_LIST_START);

export const fetchMacroListFailed = () =>
  createAction(MACRO_ACTION_TYPES.FETCH_MACRO_LIST_FAILED);

export const fetchMacroListSuccess = (macrosArray) =>
  createAction(MACRO_ACTION_TYPES.FETCH_MACRO_LIST_SUCCESS, macrosArray);

export const fetchMacroListAsyncAction = ({
  channel = "",
  entityName,
  tags,
  category,
  search,
}) => {
  return async (dispatch) => {
    dispatch(fetchMacroListStart());
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/macros/${entityName}`,
        {
          params: {
            channel,
            tags,
            category,
            search,
          },
        }
      );
      dispatch(fetchMacroListSuccess(data));
    } catch (error) {
      dispatch(fetchMacroListFailed());

      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const fetchMacroInfoSuccess = (macroInfo) =>
  createAction(MACRO_ACTION_TYPES.FETCH_MACRO_INFO_SUCCESS, macroInfo);

export const fetchMacroInfoAsyncAction = ({ entityName, macroId }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/macros/${entityName}/${macroId}`
      );
      await dispatch(fetchMacroInfoSuccess(data));
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const fetchMacroEditStart = () => {
  return createAction(MACRO_ACTION_TYPES.FETCH_MACRO_EDIT_START);
};

export const fetchMacroEditFailed = () =>
  createAction(MACRO_ACTION_TYPES.FETCH_MACRO_EDIT_FAILED);

export const fetchMacroEditSuccess = () =>
  createAction(MACRO_ACTION_TYPES.FETCH_MACRO_EDIT_SUCCESS);

export const fetchCategoriesAndTagsAsyncAction = ({
  entityName,
  macroId,
  cb,
}) => {
  return async (dispatch) => {
    if (macroId) {
      dispatch(fetchMacroEditStart());
    }
    try {
      // 同時調用 fetchCategoriesAsyncAction 和 fetchTagsAsyncAction
      await Promise.all([
        dispatch(fetchCategoriesAsyncAction({ entityName })),
        dispatch(fetchTagsAsyncAction({ entityName })),
      ]);
      // 等待上面兩個動作完成後，再調用 fetchMacroInfoAsyncAction
      if (macroId) {
        await dispatch(fetchMacroInfoAsyncAction({ entityName, macroId, cb }));
        dispatch(fetchMacroEditSuccess());
      }
    } catch (error) {
      if (macroId) {
        dispatch(fetchMacroEditFailed());
      }
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const putMacroInfoSuccess = (macroInfo) => {
  return createAction(MACRO_ACTION_TYPES.EDIT_MACRO_INFO_SUCCESS, macroInfo);
};

export const putMacroInfoAsyncAction = ({ entityName, sendData, id, cb }) => {
  const { questions, channels, tags, category, answer } = sendData;
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/manage/macros/${entityName}/${id}`,
        {
          questions,
          channels,
          tags,
          category,
          answer,
        }
      );
      dispatch(putMacroInfoSuccess(data));
      cb();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};

export const deleteMacroSuccess = (macroId) => {
  return createAction(MACRO_ACTION_TYPES.DELETE_MACRO_SUCCESS, macroId);
};

export const deleteMacroAsyncAction = ({ entityName, id }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/api/manage/macros/${entityName}/${id}`
      );
      dispatch(deleteMacroSuccess(id));
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
};
