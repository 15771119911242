import React, { memo, useState, useCallback, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import Box from "@mui/material/Box";

import styles from "./styles";
import _, { set } from "lodash";
import { Loading } from "features/common";

import CustomTableCell from "./CustomTableCell";
import { StyledTableCell, StyledRowLoading } from "./CustomTable.styles";
import DraggableList from "components/Drag/DraggableList";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";

const useStyles = makeStyles(styles);

const CustomTable = ({
  headers,
  contents,
  type = "table",
  rowKey = "_id",
  rowData = [],
  selectedRowData,
  setSelectedRowData,
  noBorderLeft = {},
  centerText = {},
  style = {},
  sortDataFunction,
  rowSelected,
  noHead,
  collapse,
  disabledSort = {},
  sortBy,
  order,
  checkbox,
  loading,
  sortMapping = {},
  containerStyle = {},
  noDataTitle = "No Data",
  noDataComponent,
  canChangeOrder = false,
  storageKey = "",
  hideCustomize,
  cache = true,
  rowLoadingText,
}) => {
  const classes = useStyles();
  const [headerItems, setHeaderItems] = useState(headers);
  const [selectedAll, setSelectedAll] = useState(false);
  const [edit, setEdit] = useState({
    canSave: false,
    canReset: false,
  });

  let titleClass = classes.tableTitle;
  let valueClass = classes.tableValue;

  useEffect(() => {
    if (!storageKey || !headers) return;
    const savedHeaderItems = JSON.parse(localStorage.getItem(storageKey));
    if (!savedHeaderItems || savedHeaderItems.length === 0)
      return setHeaderItems(headers);
    const showList = savedHeaderItems?.map((title) => {
      return { ...headers.find((item) => item.title === title), show: true };
    });
    const hiddenList = headers.filter((item) => {
      return !savedHeaderItems.includes(item.title);
    });
    const oldShown = headers.filter((item) => {
      if (item.show) return true;
    });

    if (JSON.stringify(showList) !== JSON.stringify(oldShown)) {
      setEdit((prev) => ({ ...prev, canReset: true }));
    }
    setHeaderItems([...showList, ...hiddenList]);
  }, [headers]);

  useEffect(() => {
    const thisPageSelectedCount = _.intersectionBy(
      rowData,
      selectedRowData,
      rowKey
    ).length;
    if (thisPageSelectedCount == rowData.length && rowData.length !== 0) {
      setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
  }, [selectedRowData, rowData]);

  let selectedStyle = ({ isRowLoading }) => {
    return rowSelected
      ? {
          cursor: isRowLoading ? "default" : "pointer",
          background: "#FFFFFF",
        }
      : {};
  };

  const findLastFixedItem = useCallback(() => {
    const item = _.map(
      headerItems,
      (headerItem) => headerItem.fixed
    ).lastIndexOf(true);
    return item;
  }, [headerItems]);
  const lastFixedItem = findLastFixedItem();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const newItems = reorder(headerItems, source.index, destination.index);

    setHeaderItems(newItems);
    setEdit({ canSave: true, canReset: true });
  };

  const handleShowOrHide = (result) => {
    let newArr = _.map(headerItems, (headerItem) => {
      if (headerItem.id === result.id) {
        return result;
      } else {
        return headerItem;
      }
    });
    setHeaderItems(newArr);
    setEdit({ canSave: true, canReset: true });
  };

  const resetHeaderItems = () => {
    setHeaderItems(headers);
    setEdit({ canSave: true, canReset: false });
  };

  const handleSave = () => {
    const saveShownList = [];

    headerItems.forEach((element) => {
      if (element.show) {
        saveShownList.push(element.title);
      }
    });
    localStorage.setItem(storageKey, JSON.stringify(saveShownList));
    if (headerItems !== headers) {
      setEdit({ canSave: false, canReset: true });
    } else {
      setEdit({ canSave: false, canReset: false });
    }
  };

  const calculateLeftDistance = (index, headers) => {
    if (index > lastFixedItem) {
      return null;
    }
    let distance = 0;
    for (let i = 0; i < index; i++) {
      distance += headers[i].width;
    }
    return distance;
  };

  const renderCell = (row, isSelected, index) => {
    if (canChangeOrder) {
      return (
        <CustomTableCell
          rowData={row}
          rows={contents.length}
          rowIndex={index}
          noBorderLeft={noBorderLeft}
          isSelected={isSelected}
          style={style}
          valueClass={valueClass}
          centerText={centerText}
          classes={classes}
          headers={headerItems}
          lastFixedItem={lastFixedItem}
          calculateLeftDistance={calculateLeftDistance}
        />
      );
    } else {
      return row.map((r, rIndex) => (
        <TableCell
          key={rIndex}
          align={centerText[rIndex] == true ? "center" : "left"}
          className={`${classes.value} ${valueClass} ${
            isSelected ? classes.selected : ""
          }`}
          style={{
            position: "relative",
            padding: "3px",
            paddingLeft: rIndex != 0 && noBorderLeft[rIndex] !== true ? 10 : 5,
            ...style,
          }}
        >
          {rIndex != 0 && noBorderLeft[rIndex] !== true && (
            <div
              className="absoluteBorderLeft"
              style={{ left: 2, height: "50%", top: "25%" }}
            />
          )}
          {r}
        </TableCell>
      ));
    }
  };
  return (
    <div>
      {canChangeOrder && !hideCustomize && (
        <DraggableList
          edit={edit}
          onDragEnd={handleDragEnd}
          type={type}
          items={headerItems}
          setHeader={setHeaderItems}
          onShowOrHide={handleShowOrHide}
          onReset={resetHeaderItems}
          onSave={handleSave}
        />
      )}
      <TableContainer
        className={classes.card}
        style={{ height: "65vh", ...containerStyle }}
      >
        <Table
          stickyHeader
          style={{
            borderCollapse: collapse ? "collapse" : "separate",
            borderSpacing: "0 5px",
            tableLayout: "auto",
          }}
        >
          {!noHead && (
            <TableHead>
              <TableRow>
                {checkbox && (
                  <StyledTableCell
                    cellWidth={40}
                    isFixed={true}
                    isHeader
                    className={`${classes.title} ${titleClass}`}
                  >
                    <CustomCheckbox
                      checked={selectedAll}
                      onChange={(e) => {
                        setSelectedAll(e.target.checked);
                        const newSelected = e.target.checked
                          ? _.unionBy(selectedRowData, rowData, rowKey)
                          : _.differenceWith(
                              selectedRowData,
                              rowData,
                              (obj1, obj2) => {
                                return obj1._id == obj2._id;
                              }
                            );

                        setSelectedRowData(newSelected);

                        e.stopPropagation();
                      }}
                    />
                  </StyledTableCell>
                )}
                {_.map(headerItems, (header, index) => {
                  const isSortable = canChangeOrder
                    ? !header.disabledSort
                    : !disabledSort[index];

                  const sortMappingItem = canChangeOrder
                    ? _.get(sortMapping, `[${header.title}]`)
                    : _.get(sortMapping, `[${header}]`);
                  const isFixed = canChangeOrder ? header.fixed : false;
                  const leftDistance = isFixed
                    ? calculateLeftDistance(index, headerItems)
                    : null;

                  if (canChangeOrder && !header.show) {
                    return null;
                  }

                  return (
                    <StyledTableCell
                      rows={contents.length}
                      rowIndex={index}
                      index={index}
                      cellWidth={header.width}
                      isFixed={isFixed}
                      leftDistance={leftDistance}
                      lastFixedItem={lastFixedItem}
                      key={index}
                      align={centerText[index] == true ? "center" : "left"}
                      className={`${classes.title} ${titleClass}`}
                      style={{
                        ...style,
                      }}
                      isHeader
                    >
                      {/* && sortDataFunction && sortMappingItem  */}
                      {isSortable && sortDataFunction && sortMappingItem ? (
                        <TableSortLabel
                          active={sortBy && sortBy === sortMappingItem}
                          className={`tableTitle`}
                          direction={order === 1 ? "asc" : "desc"}
                          onClick={() => {
                            const direction = order === 1 ? -1 : 1;
                            sortDataFunction(sortMappingItem, direction);
                          }}
                        >
                          {canChangeOrder ? header.showText : header}
                        </TableSortLabel>
                      ) : canChangeOrder ? (
                        header.showText
                      ) : (
                        header
                      )}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {_.map(contents, (row, index) => {
              const rowIndex =
                rowData.length > 0
                  ? _.get(rowData, `[${index}][${rowKey}]`)
                  : index;
              const isSelected = _.includes(
                _.map(selectedRowData, rowKey),
                rowIndex
              );

              const isRowLoading = _.get(
                rowData,
                `[${index}].isRowLoading`,
                false
              );

              return (
                <TableRow
                  hover
                  role="checkbox"
                  className={classes.tableRow}
                  key={index}
                  style={selectedStyle({ isRowLoading })}
                  onClick={() => {
                    rowSelected && !isRowLoading && rowSelected(rowIndex);
                  }}
                >
                  {checkbox && (
                    <TableCell
                      className={`${classes.value} ${valueClass} ${
                        isSelected ? classes.selected : ""
                      }`}
                      style={{
                        position: "relative",
                        padding: "3px",
                        ...style,
                      }}
                    >
                      <div
                        onClick={(e) => {
                          if (setSelectedRowData) e.stopPropagation();
                        }}
                      >
                        <CustomCheckbox
                          checked={isSelected}
                          onChange={(e) => {
                            const { value, checked } = e.target;

                            if (!setSelectedRowData) return;
                            if (checked) {
                              setSelectedRowData([
                                ...selectedRowData,
                                rowData[index],
                              ]);
                            } else {
                              setSelectedRowData(
                                _.filter(
                                  selectedRowData,
                                  (item) => item[rowKey] !== rowIndex
                                )
                              );
                            }

                            e.stopPropagation();
                          }}
                        />
                      </div>
                      <div
                        // className="borderRight"
                        style={{
                          height: "50%",
                          top: "25%",
                          position: "absolute",
                          right: 0,
                        }}
                      />
                    </TableCell>
                  )}

                  {row && renderCell(row, isSelected, index)}
                  {isRowLoading && (
                    <StyledRowLoading
                      component="td"
                      rowIndex={index}
                      rows={contents.length}
                    >
                      {rowLoadingText}{" "}
                      <Box>
                        <LoadingComponent
                          width={20}
                          height={20}
                          withText={false}
                        />
                      </Box>
                    </StyledRowLoading>
                  )}
                </TableRow>
              );
            })}
            {contents.length == 0 && (
              <TableRow>
                <TableCell
                  colSpan={_.get(headerItems, "length", 0)}
                  style={{
                    borderBottom: "0px",
                    height: `calc(${_.get(
                      containerStyle,
                      "height",
                      "35vh"
                    )} - 40px)`,
                    textAlign: "center",
                    color: "#777777",
                  }}
                >
                  {noDataComponent ? (
                    noDataComponent
                  ) : (
                    <>
                      <i className="meta-crm-icon-ic_boxOpenLight font-size-64" />
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          marginTop: 5,
                        }}
                      >
                        {noDataTitle}
                      </div>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Loading open={loading} fullScreen={false} white={true} />
      </TableContainer>
    </div>
  );
};

export default memo(CustomTable);
