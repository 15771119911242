import React from "react";
import { useFetchTicketList } from "features/metadesk/redux/hooks";
import useNavigateEntity from "hooks/useNavigateEntity";
import _ from "lodash";
import CustomListItem from "components/CustomListItem/CustomListItem";
import { useLocation } from "react-router";
import { useTheme } from "@mui/styles";

import { Box } from "@mui/material";

export default function MenuList({ data, entityName, isCustomDomain, module }) {
  const { navigateEntity } = useNavigateEntity();

  const theme = useTheme();
  const location = useLocation();
  const { statsCounts } = useFetchTicketList();

  const renderDrawItem = ({
    path,
    title,
    category,
    routeLink,
    icon,
    isMenu,
    children,
    disabled,
    isShow,
    isNeedUpgrade,
  }) => {
    if (!isShow || isNeedUpgrade) return;
    const isSelected = _.includes(location.pathname, routeLink);
    return {
      disabled,
      upgrade: isNeedUpgrade,
      isMenu,
      subItems: _.map(children, (sub) =>
        renderDrawItem({
          ...sub,
          disabled,
          isShow,
          isNeedUpgrade,
          routeLink: `${routeLink}/${sub.path}`,
        })
      ),
      label: (
        <Box
          className="startRow"
          color={
            disabled || isNeedUpgrade ? theme.customColors.grey[500] : "unset"
          }
        >
          <Box
            fontWeight={400}
            mr={"8px"}
            display={"flex"}
            alignItems={"center"}
          >
            {icon}
          </Box>
          {title}
        </Box>
      ),
      rightIcon: category && (
        <Box
          fontSize="12px"
          fontWeight={isSelected ? 600 : 500}
          color={
            isSelected
              ? theme.customColors.purple[500]
              : theme.customColors.grey[500]
          }
        >
          {_.get(statsCounts, `${category}[${_.upperFirst(path)}]`, "0")}
        </Box>
      ),
      to: routeLink,
      selected: isSelected,
    };
  };

  const renderMenuItem = (item) => {
    const {
      title,
      icon,
      path = "",
      isShow,
      disabled,
      isMenu,
      children,
      category,
      isNeedUpgrade,
    } = item;

    const replaceUrl = path;

    const isSelected = _.startsWith(
      location.pathname,
      `/${isCustomDomain ? "" : entityName + "/"}${module}/${replaceUrl}`
    );

    if (!isShow || isNeedUpgrade) return null;
    const formatItems = _.compact(
      _.map(children, (sub) => {
        if (!sub.isShow || sub.isNeedUpgrade || !sub.title) return;
        return renderDrawItem({
          ...sub,
          category,
          routeLink: `/${module}/${replaceUrl}/${sub.path}`,
        });
      })
    );
    if (isMenu && _.isEmpty(formatItems)) return;
    return (
      <CustomListItem
        label={title}
        icon={
          <Box fontWeight={400} mr="8px">
            {icon}
          </Box>
        }
        disabled={disabled}
        isMenu={isMenu}
        selected={isSelected}
        handleSubItemClick={(subItem) => {
          navigateEntity(subItem?.to);
        }}
        subItems={formatItems}
        onClick={() => {
          if (isMenu) return;
          if (disabled) return;
          navigateEntity(`/${module}/${replaceUrl}`);
        }}
      />
    );
  };
  if (!data) return <Box />;
  return <Box>{_.map(data[module], (item, i) => renderMenuItem(item, i))}</Box>;
}
