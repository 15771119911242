import React, { useState, useEffect, useCallback } from "react";
import { get, isEmpty, toNumber } from "lodash-es";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useForm } from "react-hook-form";
import axios from "axios";
import { apiUrl } from "features/configure";
import Box from "@mui/material/Box";
import {
  InputField,
  SIZE as InputSize,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHexImg, parseCustomDomainUrl } from "features/helpers/utils";
import _ from "lodash";
import SideMenu from "components/SideMenu/SideMenu";
import {
  Button,
  IconButton,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import GroupCardNew from "./GroupCardNew/GroupCardNew";
import { enqueueSnackbar } from "features/common/redux/actions";
import {
  StyledCreateGroupContainer,
  StyledLoadingContainer,
  StyledCreateGroupArea,
  StyledPageTitle,
  StyledCreateFormContainer,
  StyledCreateButton,
  StyledLine,
  StyledAlert,
  StyledFilterCategory,
  StyledFilterCategoryTitle,
  StyledCollapseContainer,
  StyledTo,
  StyledDash,
  StyledPreviewContainer,
  StyledPreviewTitle,
  StyledPreviewGroupContainer,
  StyledCollapseStartContainer,
} from "./CreateGroup.styles";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import CheckCollapse from "./CheckCollapse/CheckCollapse";
import SelectToken from "features/metadesk/components/SelectToken/SelectToken";
import Token from "features/metadesk/components/Icons/Token";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import SelectTokenInputField from "features/metadesk/components/SelectToken/SelectTokenInputField";
import UserTag from "features/metadesk/components/UserTag";
import { useFetchTags } from "features/metadesk/redux/fetchTags";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import Back from "features/metadesk/components/Back";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useIntl } from "react-intl";

const defaultFormFields = {
  groupName: "",
  walletBalance: {
    title: (
      <>
        <i className="meta-crm-icon-ic_wallentBalance font-size-18" /> Wallet
        Balance ($)
      </>
    ),
    isExpand: false,
    walletBalanceFromValue: "",
    walletBalanceToValue: "",
  },
  nftAmount: {
    title: (
      <>
        <i className="meta-crm-icon-ic_nft font-size-18" /> NFT Amount (#)
      </>
    ),
    isExpand: false,
    nftAmountFromValue: "",
    nftAmountToValue: "",
  },

  walletAge: {
    title: (
      <>
        <i className="meta-crm-icon-ic_walletAge font-size-18" /> Wallet Age (#)
      </>
    ),
    isExpand: false,
    timeDropdownValue: { label: "Years", value: "years" },
    options: [
      { label: "Days", value: "days" },
      { label: "Months", value: "months" },
      { label: "Years", value: "years" },
    ],
    sliderValue: [0, 1],
    sliderStep: 1,
  },

  txnAmount: {
    title: (
      <>
        <i className="meta-crm-icon-ic_transaction font-size-18" /> Transaction
        (#)
      </>
    ),
    isExpand: false,
    txnAmountFromValue: "",
    txnAmountToValue: "",
  },

  assets: {
    title: (
      <>
        <i className="meta-crm-icon-ic_tokens font-size-18" /> Current Holding
      </>
    ),
    isExpand: false,
    tokens: [],
  },

  protocols: {
    title: (
      <>
        <i className="meta-crm-icon-ic_protocol font-size-18" /> Protocol
      </>
    ),
    isExpand: false,
    protocols: [],
  },
  tags: {
    title: (
      <>
        <i className="meta-crm-icon-ic_tag font-size-18" />
        Tags
      </>
    ),
    isExpand: false,
    selectedTags: [],
  },
};

const CreateGroup = ({ renderBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [entityName, isCustomDomain] = useOutletContext();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isLoading, setIsLoading] = useState(false);
  const [protocolList, setProtocolList] = useState({});
  const [openSelectToken, setOpenSelectToken] = useState("");
  const { tags, fetchTags } = useFetchTags();
  const chainList = useSelector(selectEntityChainList);
  const { formatMessage } = useIntl();

  useEffect(async () => {
    const protocolResult = await axios.get(
      apiUrl + `/api/dataSource/${entityName}/protocolList`
    );
    const data = _.map(protocolResult.data, (r) => {
      return { ...r, label: <Token data={r} />, value: r };
    });
    setProtocolList(data);
  }, []);

  const handleTimeInterval = useCallback(() => {
    const isWalletAgeExist = get(formFields, "walletAge.isExpand");
    if (!isWalletAgeExist) return null;
    const timeUnit = get(formFields, "walletAge.timeDropdownValue.value");
    const timeFrom = get(formFields, "walletAge.sliderValue[1]");
    const timeTo = get(formFields, "walletAge.sliderValue[0]");
    if (timeUnit === "days") {
      return {
        from: moment().subtract(timeFrom, "days").toDate(),
        to: moment().subtract(timeTo, "days").toDate(),
      };
    } else if (timeUnit === "months") {
      return {
        from: moment().subtract(timeFrom, "months").toDate(),
        to: moment().subtract(timeTo, "months").toDate(),
      };
    } else if (timeUnit === "years") {
      return {
        from: moment().subtract(timeFrom, "years").toDate(),
        to: moment().subtract(timeTo, "years").toDate(),
      };
    } else {
      return null;
    }
  }, [formFields]);
  const timeInterval = handleTimeInterval();
  const transformFieldToGroupInfo = useCallback(() => {
    let result = { rules: {} };
    result = { ...result, name: formFields.groupName };
    if (timeInterval) {
      result = { ...result, rules: { ...result.rules, timeInterval } };
    }

    Object.keys(formFields).forEach((key) => {
      if (formFields[key].isExpand) {
        Object.keys(formFields[key]).forEach((subKey) => {
          if (subKey.includes("FromValue")) {
            if (!result.rules[key]) {
              result.rules[key] = {};
            }
            if (formFields[key][subKey]) {
              result.rules[key].from = formFields[key][subKey];
            }
          }
          if (subKey.includes("ToValue")) {
            if (!result.rules[key]) {
              result.rules[key] = {};
            }
            if (formFields[key][subKey]) {
              result.rules[key].to = formFields[key][subKey];
            }
          }
          const tokens = formFields[key][subKey];
          if (subKey == "tokens") {
            result.rules[key] = _.filter(tokens, "token");
          }
          if (subKey == "protocols") {
            result.rules[key] = _.filter(tokens, "contract");
          }
          if (subKey == "selectedTags") {
            result.rules[key] = _.map(
              _.filter(tags[entityName], (t) =>
                _.includes(formFields[key][subKey], t._id)
              ),
              (tag) => {
                return { id: tag._id, included: true, ...tag };
              }
            );
          }
        });
      }
    });

    // 過濾掉result不存在或空物件的屬性;
    Object.keys(result).forEach((key) => {
      if (!result[key] || isEmpty(result[key])) {
        delete result[key];
      }
    });

    return result;
  }, [formFields, timeInterval]);

  const groupInfo = transformFieldToGroupInfo();

  const transformFieldForUseForm = (formFieldsData) => {
    let result = {};
    result.groupName = formFieldsData.groupName;

    Object.keys(formFieldsData).forEach((key) => {
      Object.keys(formFieldsData[key]).forEach((subKey) => {
        if (subKey.includes("FromValue")) {
          result[subKey] = formFieldsData[key][subKey];
        }
        if (subKey.includes("ToValue")) {
          result[subKey] = formFieldsData[key][subKey];
        }

        if (subKey.includes("isExpand")) {
          result[`${key}IsExpand`] = formFieldsData[key][subKey];
        }
      });
    });
    return result;
  };

  const useFormDefaultValue = transformFieldForUseForm(defaultFormFields);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: useFormDefaultValue,
  });

  const walletBalanceIsExpandUseForm = watch("walletBalanceIsExpand");
  const nftAmountIsExpandUseForm = watch("nftAmountIsExpand");
  const txnAmountIsExpandUseForm = watch("txnAmountIsExpand");

  const toggleFormItemExpand = async (name) => {
    const newFormFields = {
      ...formFields,
      [name]: { ...formFields[name], isExpand: !formFields[name].isExpand },
    };

    setFormFields(newFormFields);
    await reset(transformFieldForUseForm(newFormFields));

    if (errorsInfo.groupName) {
      trigger();
    } else {
      trigger("nftAmountToValue");
      trigger("walletBalanceToValue");
      trigger("txnAmountToValue");
    }
  };

  const handleChangeGroupName = (event) => {
    const { name, value } = event.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleChangeValue = (event, formName) => {
    const { name, value } = event.target;
    const newFields = _.cloneDeep(formFields);
    _.set(newFields, `${formName}.${name}`, value);
    setFormFields(newFields);
    if (name.includes("FromValue")) {
      trigger(`${formName}ToValue`);
    }
  };

  const handleChangeDropDown = ({ value, formName, name }) => {
    const newFields = _.cloneDeep(formFields);
    _.set(newFields, `${formName}.${name}`, value);
    setFormFields(newFields);
  };

  const handleChangeMuiltiValues = ({ formName, values }) => {
    const newFields = _.cloneDeep(formFields);
    _.mapKeys(values, function (value, key) {
      _.set(newFields, `${formName}.${key}`, value);
    });

    setFormFields(newFields);
  };

  const handleChangeSlider = ({ name, value }) => {
    setFormFields({
      ...formFields,
      [name]: { ...formFields[name], sliderValue: value },
    });
  };

  const renderPreviewField = () => (
    <StyledPreviewContainer>
      <StyledPreviewTitle mb={2}>Preview</StyledPreviewTitle>
      <StyledPreviewGroupContainer>
        <GroupCardNew isPreview groupInfo={groupInfo} />
      </StyledPreviewGroupContainer>
    </StyledPreviewContainer>
  );

  const handleCleanFilter = () => {
    setFormFields({ ...defaultFormFields, groupName: formFields.groupName });
    reset(transformFieldForUseForm(defaultFormFields));
    clearErrors();
  };

  const validateIsBigger = (value, formValues, compareField) => {
    if (formValues[compareField]) {
      if (
        value &&
        (toNumber(value) < toNumber(formValues[compareField]) ||
          toNumber(value) === toNumber(formValues[compareField]))
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  const sendInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${apiUrl}/api/manage/groups/create`, {
        entityName: entityName,
        name: groupInfo.name,
        rules: {
          walletBalance: {
            from: get(groupInfo, "rules.walletBalance.from"),
            to: get(groupInfo, "rules.walletBalance.to"),
          },
          nftAmount: {
            from: get(groupInfo, "rules.nftAmount.from"),
            to: get(groupInfo, "rules.nftAmount.to"),
          },
          txnAmount: {
            from: get(groupInfo, "rules.txnAmount.from"),
            to: get(groupInfo, "rules.txnAmount.to"),
          },
          timeInterval: {
            from: get(groupInfo, "rules.timeInterval.from"),
            to: get(groupInfo, "rules.timeInterval.to"),
          },
          assets: get(groupInfo, "rules.assets"),
          protocols: get(groupInfo, "rules.protocols"),
          tags: get(groupInfo, "rules.tags"),
        },
      });

      navigate(
        parseCustomDomainUrl(isCustomDomain, entityName, "/customers/segment")
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitInfo = () => {
    handleSubmit(sendInfo)();
  };

  return (
    <StyledCreateGroupContainer>
      {isLoading && (
        <StyledLoadingContainer>
          <LoadingComponent text="Creating..." />
        </StyledLoadingContainer>
      )}

      <StyledCreateGroupArea>
        <Back
          to={parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/customers/segment"
          )}
        />
        <StyledCreateFormContainer>
          <Box>
            <InputField
              control={control}
              title={formatMessage({
                id: "segmentCreation.name.labelName",
              })}
              size={InputSize.MEDIUM}
              value={formFields["groupName"]}
              name="groupName"
              onChange={handleChangeGroupName}
              placeholder={formatMessage({
                id: "segmentCreation.name.placeholder",
              })}
              maxLength="32"
              errors={get(errorsInfo, "groupName.message")}
              rules={{
                required: {
                  value: true,
                  message: "Segment name is required",
                },
                minLength: {
                  value: 1,
                  message: "At least 1 characters",
                },
              }}
            ></InputField>
          </Box>
          <StyledLine />
          {/* <StyledAlert severity="secondary">Filtered Count:</StyledAlert> */}
          <Box
            color={(theme) => theme.customColors.purple[600]}
            fontWeight={700}
            fontSize="12px"
            mt="15px"
            mb="12px"
          >
            {formatMessage({
              id: "segmentCreation.filter.tags",
            })}
          </Box>
          <CheckCollapse
            value={{
              title: formFields["tags"].title,
              onCheck: toggleFormItemExpand,
              name: "tags",
              checked: formFields["tags"].isExpand,
              onDropdownChange: handleChangeDropDown,
            }}
          >
            <StyledCollapseContainer style={{ display: "block" }}>
              <CheckCollapse.Select
                dropdownName="selectedTags"
                sm={false}
                multiple
                width={"100%"}
                value={formFields["tags"].selectedTags}
                renderValue={(selected) => {
                  return (
                    <div className="startRow">
                      {_.map(
                        _.filter(tags[entityName], (t) =>
                          _.includes(selected, t._id)
                        ),
                        (o, i) => (
                          <MetaCRMTag
                            key={i}
                            style={{ fontSize: 14 }}
                            option={{ color: o.color, label: o.value }}
                          />
                        )
                      )}
                    </div>
                  );
                }}
                data={_.map(tags[entityName], (tag) => {
                  return {
                    value: tag._id,
                    label: (
                      <MetaCRMTag
                        key={tag._id}
                        option={{ color: tag.color, label: tag.value }}
                      />
                    ),
                  };
                })}
              />
            </StyledCollapseContainer>
          </CheckCollapse>
          <Box
            color={(theme) => theme.customColors.purple[600]}
            fontWeight={700}
            fontSize="12px"
            mt="15px"
            mb="20px"
          >
            {formatMessage({
              id: "segmentCreation.filter.onChain",
            })}
          </Box>

          <StyledFilterCategory>
            <StyledFilterCategoryTitle>
              {formatMessage({
                id: "segmentCreation.category.portfolio",
              })}
            </StyledFilterCategoryTitle>
            <CheckCollapse
              value={{
                title: formFields["walletBalance"].title,
                onCheck: toggleFormItemExpand,
                name: "walletBalance",
                checked: formFields["walletBalance"].isExpand,
                onInputChange: (e) => handleChangeValue(e, "walletBalance"),
              }}
            >
              <StyledCollapseContainer>
                <CheckCollapse.InputField
                  inputName="walletBalanceFromValue"
                  onInputValue={
                    formFields["walletBalance"].walletBalanceFromValue
                  }
                  placeholder="0"
                  sx={{ width: "120px" }}
                  control={control}
                  errors={get(errorsInfo, "walletBalanceFromValue.message")}
                  errorTextAbsolute
                  rules={{
                    ...(walletBalanceIsExpandUseForm && {
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: "Positive integer only",
                      },
                      maxLength: { value: 16, message: "16 digits max" },
                    }),
                  }}
                />
                <StyledTo>to</StyledTo>
                <CheckCollapse.InputField
                  inputName="walletBalanceToValue"
                  onInputValue={
                    formFields["walletBalance"].walletBalanceToValue
                  }
                  placeholder="∞"
                  sx={{ width: "120px" }}
                  control={control}
                  errors={get(errorsInfo, "walletBalanceToValue.message")}
                  rules={{
                    ...(walletBalanceIsExpandUseForm && {
                      maxLength: { value: 16, message: "16 digits max" },
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: "Positive integer only",
                      },
                      validate: {
                        biggerThanFrom: (value, formValues) =>
                          validateIsBigger(
                            value,
                            formValues,
                            "walletBalanceFromValue"
                          ) || "Max < Min invalid",
                      },
                    }),
                  }}
                  errorTextAbsolute
                />
              </StyledCollapseContainer>
            </CheckCollapse>

            <StyledDash></StyledDash>

            <CheckCollapse
              value={{
                title: formFields["nftAmount"].title,
                onCheck: toggleFormItemExpand,
                name: "nftAmount",
                checked: formFields["nftAmount"].isExpand,
                onInputChange: (e) => handleChangeValue(e, "nftAmount"),
              }}
            >
              <StyledCollapseContainer>
                <CheckCollapse.InputField
                  inputName="nftAmountFromValue"
                  onInputValue={formFields["nftAmount"].nftAmountFromValue}
                  placeholder="0"
                  sx={{ width: "120px" }}
                  control={control}
                  errorTextAbsolute
                  errors={get(errorsInfo, "nftAmountFromValue.message")}
                  rules={{
                    ...(nftAmountIsExpandUseForm && {
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: "Positive integer only",
                      },
                      maxLength: { value: 16, message: "16 digits max" },
                    }),
                  }}
                />
                <StyledTo>to</StyledTo>
                <CheckCollapse.InputField
                  inputName="nftAmountToValue"
                  onInputValue={formFields["nftAmount"].nftAmountToValue}
                  placeholder="∞"
                  sx={{ width: "120px" }}
                  control={control}
                  errors={get(errorsInfo, "nftAmountToValue.message")}
                  errorTextAbsolute
                  rules={{
                    ...(nftAmountIsExpandUseForm && {
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: "Positive integer only",
                      },
                      maxLength: { value: 16, message: "16 digits max" },
                      validate: {
                        biggerThanFrom: (value, formValues) =>
                          validateIsBigger(
                            value,
                            formValues,
                            "nftAmountFromValue"
                          ) || "Max < Min invalid",
                      },
                    }),
                  }}
                />
              </StyledCollapseContainer>
            </CheckCollapse>
            <StyledDash></StyledDash>
            <CheckCollapse
              value={{
                title: formFields["assets"].title,
                onCheck: toggleFormItemExpand,
                name: "assets",
                checked: formFields["assets"].isExpand,
                onDropdownChange: ({ name, value, formName, index }) => {
                  const data = { [name]: value };
                  if (index >= 0) {
                    data[`tokens.${index}.token`] = "";
                  }
                  handleChangeMuiltiValues({
                    formName,
                    values: data,
                  });
                },
              }}
            >
              <StyledCollapseContainer style={{ display: "block" }}>
                {_.map(formFields["assets"].tokens, (token, i) => {
                  return (
                    <div
                      key={i}
                      className="startRow"
                      style={{ marginBottom: 8 }}
                    >
                      <CheckCollapse.Select
                        dropdownName={`tokens.${i}.chainId`}
                        value={token["chainId"]}
                        data={chainList}
                        index={i}
                      />
                      <CheckCollapse.Select
                        dropdownName={`tokens.${i}.assetType`}
                        value={token["assetType"]}
                        width={100}
                        index={i}
                        data={[
                          { label: "Token", value: "token" },
                          { label: "NFT", value: "nft" },
                        ]}
                      />
                      <SelectTokenInputField
                        data={token["token"]}
                        onClick={() => {
                          setOpenSelectToken({
                            chainId: token.chainId,
                            assetType: token.assetType,
                            index: i,
                          });
                        }}
                      />

                      <CheckCollapse.Checkbox
                        checkboxName={`tokens.${i}.included`}
                        checked={token["included"]}
                        label={token["included"] ? "Included" : "Excluded"}
                      />
                      <IconButton
                        size={18}
                        onClick={() => {
                          const newArray = [...formFields["assets"].tokens];
                          _.pullAt(newArray, [i]);
                          handleChangeValue(
                            {
                              target: {
                                name: "tokens",
                                value: newArray,
                              },
                            },
                            "assets"
                          );
                        }}
                      >
                        <i className="meta-crm-icon-ic_trash font-size-16" />
                      </IconButton>
                    </div>
                  );
                })}
                <StyledCreateButton
                  onClick={() => {
                    handleChangeValue(
                      {
                        target: {
                          name: `tokens.${formFields["assets"].tokens.length}`,
                          value: {
                            chainId: 1,
                            assetType: "token",
                            token: "",
                            included: true,
                          },
                        },
                      },
                      "assets"
                    );
                  }}
                >
                  <i className="meta-crm-icon-ic_add font-size-14 mgr8" /> Add
                </StyledCreateButton>
                <SelectToken
                  open={openSelectToken}
                  entityName={entityName}
                  title="Current Holding Menu"
                  handleClose={() => setOpenSelectToken("")}
                  value={_.get(
                    formFields,
                    `assets.tokens.${openSelectToken.index}.token.address`
                  )}
                  setValue={(value) => {
                    handleChangeDropDown({
                      value,
                      formName: "assets",
                      name: `tokens.${openSelectToken.index}.token`,
                    });
                  }}
                  chainId={_.get(openSelectToken, "chainId")}
                  assetType={_.get(openSelectToken, "assetType")}
                />
              </StyledCollapseContainer>
            </CheckCollapse>
          </StyledFilterCategory>

          <StyledFilterCategory>
            <StyledFilterCategoryTitle>
              {formatMessage({
                id: "segmentCreation.category.activity",
              })}
            </StyledFilterCategoryTitle>
            <CheckCollapse
              value={{
                title: formFields["walletAge"].title,
                onCheck: toggleFormItemExpand,
                name: "walletAge",
                checked: formFields["walletAge"].isExpand,
                onDropdownChange: handleChangeDropDown,
                onSliderValue: formFields["walletAge"].sliderValue,
                onSliderChange: handleChangeSlider,
                onStep: formFields["walletAge"].sliderStep,
              }}
            >
              <StyledCollapseStartContainer>
                <CheckCollapse.Slider
                  timeUnit={formFields["walletAge"].timeDropdownValue}
                  onSliderValue={formFields["walletAge"].sliderValue}
                  onStep={formFields["walletAge"].sliderStep}
                  control={control}
                />
                <CheckCollapse.Dropdown
                  dropdownName="timeDropdownValue"
                  onDropdownValue={formFields["walletAge"].timeDropdownValue}
                  onOptions={formFields["walletAge"].options}
                />
              </StyledCollapseStartContainer>
            </CheckCollapse>

            <StyledDash></StyledDash>

            <CheckCollapse
              value={{
                title: formFields["txnAmount"].title,
                onCheck: toggleFormItemExpand,
                name: "txnAmount",
                checked: formFields["txnAmount"].isExpand,
                onInputChange: (e) => handleChangeValue(e, "txnAmount"),
              }}
            >
              <StyledCollapseContainer>
                <CheckCollapse.InputField
                  inputName="txnAmountFromValue"
                  onInputValue={formFields["txnAmount"].txnAmountFromValue}
                  placeholder="0"
                  sx={{ width: "120px" }}
                  control={control}
                  errors={get(errorsInfo, "txnAmountFromValue.message")}
                  errorTextAbsolute
                  rules={{
                    ...(txnAmountIsExpandUseForm && {
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: "Positive integer only",
                      },
                      maxLength: { value: 16, message: "16 digits max" },
                    }),
                  }}
                />
                <StyledTo>to</StyledTo>
                <CheckCollapse.InputField
                  inputName="txnAmountToValue"
                  onInputValue={formFields["txnAmount"].txnAmountToValue}
                  placeholder="∞"
                  sx={{ width: "120px" }}
                  control={control}
                  errors={get(errorsInfo, "txnAmountToValue.message")}
                  errorTextAbsolute
                  rules={{
                    ...(txnAmountIsExpandUseForm && {
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: "Positive integer only",
                      },
                      maxLength: { value: 16, message: "16 digits max" },
                      validate: {
                        biggerThanFrom: (value, formValues) =>
                          validateIsBigger(
                            value,
                            formValues,
                            "txnAmountFromValue"
                          ) || "Max < Min invalid",
                      },
                    }),
                  }}
                />
              </StyledCollapseContainer>
            </CheckCollapse>

            <StyledDash></StyledDash>
            <CheckCollapse
              value={{
                title: formFields["protocols"].title,
                onCheck: toggleFormItemExpand,
                name: "protocols",
                checked: formFields["protocols"].isExpand,
                onDropdownChange: handleChangeDropDown,
              }}
            >
              <StyledCollapseContainer style={{ display: "block" }}>
                {_.map(formFields["protocols"].protocols, (protocol, i) => {
                  return (
                    <div
                      key={i}
                      className="startRow"
                      style={{ marginBottom: 8 }}
                    >
                      <CheckCollapse.Select
                        placeholder="protocols"
                        dropdownName={`protocols.${i}.contract`}
                        value={protocol["contract"]}
                        data={protocolList}
                      />

                      <CheckCollapse.Checkbox
                        checkboxName={`protocols.${i}.included`}
                        checked={protocol["included"]}
                        label={protocol["included"] ? "Included" : "Excluded"}
                      />
                      <IconButton
                        size={18}
                        onClick={() => {
                          const newArray = [
                            ...formFields["protocols"].protocols,
                          ];
                          _.pullAt(newArray, [i]);
                          handleChangeValue(
                            {
                              target: {
                                name: "protocols",
                                value: newArray,
                              },
                            },
                            "protocols"
                          );
                        }}
                      >
                        <i className="meta-crm-icon-ic_trash font-size-16" />
                      </IconButton>
                    </div>
                  );
                })}
                <StyledCreateButton
                  onClick={() => {
                    handleChangeValue(
                      {
                        target: {
                          name: `protocols.${formFields["protocols"].protocols.length}`,
                          value: {
                            included: true,
                          },
                        },
                      },
                      "protocols"
                    );
                  }}
                >
                  <i className="meta-crm-icon-ic_add font-size-14 mgr8" /> Add
                </StyledCreateButton>
              </StyledCollapseContainer>
            </CheckCollapse>
          </StyledFilterCategory>
        </StyledCreateFormContainer>
      </StyledCreateGroupArea>

      <SideMenu
        content={renderPreviewField()}
        anchor="right"
        drawerWidth={375}
      />

      <FixedButton rightPlaceHolder={372}>
        <Button
          sx={{ width: "120px", marginLeft: "8px", whiteSpace: "nowrap" }}
          variant={VARIANT.OUTLINED}
          onClick={handleCleanFilter}
        >
          Clear all filters
        </Button>
        <Button
          sx={{ width: "120px", marginLeft: "8px" }}
          onClick={handleSubmitInfo}
          disabled={!isValid}
        >
          Create
        </Button>
      </FixedButton>
    </StyledCreateGroupContainer>
  );
};

export default CreateGroup;
