import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import Modal from "@mui/material/Modal";

import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

import {
  StyledBasicModalContainer,
  StyledCloseBtn,
  StyledModalContentContainer,
  StyledModalTitle,
  StyledModalContent,
  StyledButtonArea,
  StyledButtonRowArea,
} from "./BasicModal.styles";

const BasicModal = ({
  open,
  onClose,
  modalTitle,
  modalContent,
  confirmText,
  cancelText,
  onHandleConfirm,
  color,
  textAlign = "center",
  children,
  confirmDisabled,
  mode = "alert",
  maxWidth = "500px",
  buttonWidth = "120px",
}) => {
  const theme = useTheme();

  return (
    <Modal open={open} className={"modal"} onClose={onClose}>
      <div className="modalPaper" style={{ maxWidth }}>
        <StyledBasicModalContainer>
          <StyledCloseBtn
            size={24}
            color={theme.customColors.grey[700]}
            onClick={onClose}
          >
            <i className="meta-crm-icon-ic_cancel" />
          </StyledCloseBtn>
          <StyledModalContentContainer>
            <StyledModalTitle textAlign={textAlign}>
              {modalTitle}
            </StyledModalTitle>
            <StyledModalContent textAlign={textAlign}>
              {modalContent}
            </StyledModalContent>
            <div style={{ width: "100%" }}>{children}</div>
            {mode == "action" && (
              <StyledButtonRowArea>
                {cancelText && (
                  <Button
                    color={COLOR.INFO}
                    style={{ marginRight: 4, width: buttonWidth }}
                    variant={VARIANT.TEXT}
                    onClick={onClose}
                  >
                    {cancelText}
                  </Button>
                )}
                {confirmText && (
                  <Button
                    color={color || COLOR.SECONDARY}
                    onClick={onHandleConfirm}
                    disabled={confirmDisabled}
                    style={{ width: buttonWidth }}
                  >
                    {confirmText}
                  </Button>
                )}
              </StyledButtonRowArea>
            )}
            {mode == "alert" && (
              <StyledButtonArea>
                {confirmText && (
                  <Button
                    color={color || COLOR.SECONDARY}
                    onClick={onHandleConfirm}
                    disabled={confirmDisabled}
                  >
                    {confirmText}
                  </Button>
                )}
                {cancelText && (
                  <Button
                    color={COLOR.INFO}
                    style={{ marginTop: 4 }}
                    variant={VARIANT.TEXT}
                    onClick={onClose}
                  >
                    {cancelText}
                  </Button>
                )}
              </StyledButtonArea>
            )}
          </StyledModalContentContainer>
        </StyledBasicModalContainer>
      </div>
    </Modal>
  );
};

export default BasicModal;
