import * as React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

import { StyledTag } from "./MultipleValueTag.styles";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

function MultipleValueTag(props) {
  const { label, onDelete, option, notDelete, ...other } = props;

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <>
      <StyledTag backgroundColor={option.color} {...other}>
        <span>{label}</span>
        {!notDelete && (
          <IconButton
            sx={{ marginLeft: "8px" }}
            size={12}
            onClick={(e) => handleDelete(e)}
          >
            <i className="meta-crm-icon-ic_cancel font-size-32" />
          </IconButton>
        )}
      </StyledTag>
      {/* {isTag ? (
        <StyledTag {...other}>
          <span>{label}</span>
          <CloseIcon onClick={onDelete} />
        </StyledTag>
      ) : (
        <StyledTag {...other}>
          <span>{label}</span>
          <CloseIcon onClick={onDelete} />
        </StyledTag>
      )} */}
    </>
  );
}

export default MultipleValueTag;

MultipleValueTag.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
  notDelete: PropTypes.bool,
};
MultipleValueTag.defaultProps = {
  label: "",
  onDelete: () => {},
  notDelete: false,
};
