import React, { useState, useRef, useContext, createContext } from "react";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import { Box } from "@mui/material";

// Create Context
const ZoomInContext = createContext();

export const useZoomIn = () => useContext(ZoomInContext);

// options 範例
// options = {
//   noNeedConfirm:false,
//   title: "",
//   content: "",
//   confirmText: "",
//   cancelText: "",
// };

export const ZoomInContextProvider = ({ children }) => {
  const [options, setOptions] = useState(null);
  const awaitingPromiseRef = useRef(null);

  const confirm = (options) => {
    if (options.noNeedConfirm) {
      return Promise.resolve();
    }
    setOptions({
      ...options,
      content: <Box component="img" width="100%" src={options.src}></Box>,
    });
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOptions(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOptions(null);
  };

  return (
    <>
      <ZoomInContext.Provider value={confirm}>
        {children}
        <BasicDialog
          dialogRootStyle={{ maxWidth: "60vw" }}
          scroll="body"
          open={Boolean(options)}
          dialogContent={options && options.content ? options.content : ""}
          onClose={handleClose}
        />
      </ZoomInContext.Provider>
    </>
  );
};

export default ZoomInContextProvider;
