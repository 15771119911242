import styled from "@emotion/styled/macro";
export const StyledTokenList = styled("div")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  minWidth: 100,
  textAlign: "left",
  background: "rgba(255, 255, 255, 0.6)",
  borderRadius: 5,
  padding: "12px 25px",
  marginBottom: 8,
  cursor: "pointer",
  boxSizing: "border-box",
  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
}));

export const StyledSelectTokenPaper = styled("div")(({ theme }) => ({
  width: 484,
  color: "#383538",
  backgroundColor: "white",
  borderRadius: 5,
  position: "relative",
}));

export const StyledTokenInputField = styled("div")(({ theme, color }) => ({
  background: "white",
  boxSizing: "border-box",
  height: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #d6d6d6",
  color,
  width: 160,
  marginRight: 5,
  padding: "0 8px",
  fontSize: 14,
  fontWeight: 500,
  cursor: "pointer",
}));
