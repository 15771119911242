import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const ChatSimulationWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: 320,
  height: 606,
  border: `1px solid ${theme.customColors.grey[400]}`,
  borderRadius: 5,
  marginTop: 16,
  background: "#F8F8F8",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ChatHeaderArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  height: 59,
  borderRadius: "5px 5px 0 0",
  background: "white",
  borderBottom: `1px solid ${theme.customColors.grey[400]}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 10px",
  color: "#0B366B",
}));

export const ChatDisplayContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "100%",
  padding: "16px 10px",
  overflowY: "auto",
}));

export const ChatInputArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: "#FFFFFF",
  borderTop: `1px solid ${theme.customColors.grey[400]}`,
  width: "100%",
  height: 120,
}));

export const SendActionArea = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  height: 59,
  background: "white",
  padding: 10,
  borderRadius: "0px 0px 5px 5px",
  borderTop: `1px solid ${theme.customColors.grey[400]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const MsgRow = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  columnGap: 8,
}));

export const MsgWrapper = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 8,
}));

export const MsgHeader = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#0B366B",
  fontSize: theme.fontSize.TINY,
  marginRight: 4,
}));

export const MsgBody = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  maxWidth: 260,
  minHeight: 50,
  padding: 18,
  lineHeight: "18px",
  wordBreak: "break-all",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

export const TrainingMsgContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  fontSize: theme.fontSize.TINY,
  height: 40,
  width: 176,
  display: "flex",
  alignItems: "center",
  columnGap: 8,
  border: `1px solid ${theme.customColors.grey[300]}`,
  padding: "5px 8px 5px 8px",
  borderRadius: 3,
  position: "absolute",
  left: 0,
  right: 0,
  top: 200,
  marginRight: "auto",
  marginLeft: "auto",
}));
