import { cloneDeep } from "lodash-es";
import { v4 as uuidv4 } from "uuid";

// for count total engagement and custom amount
export function countTotalEngagementAndTracking(campaignContentSets) {
  let TrackingTotal = 0;
  let EstimateTotal = 0;
  campaignContentSets?.forEach((currSet) => {
    const { customLinkSettings, engagementDistributionSettings } = currSet;
    // Custom link settings
    if (customLinkSettings) {
      TrackingTotal += countCustomTrackingLink(customLinkSettings);
    }
    // Engagement distribution settings
    if (engagementDistributionSettings) {
      TrackingTotal += countEngagementLink(engagementDistributionSettings);

      const engagementCount = countEngagementReach(
        engagementDistributionSettings
      );

      // 如果engagementCount是 Unlimited 或目前的EstimateTotal已經是 Unlimited，
      // 然後 EstimateTotal 維持 Unlimited
      // 否則，將engagementCount 加入到EstimateTotal。
      EstimateTotal =
        engagementCount === "Unlimited" || EstimateTotal === "Unlimited"
          ? "Unlimited"
          : EstimateTotal + engagementCount;
    }
  });

  return { TrackingTotal, EstimateTotal };
}

// for count single custom trackingLink Amount
export function countCustomTrackingLink(customSet) {
  const count = customSet.reduce((linkAcc, setting) => {
    const mediumsLength = setting.mediums?.length || 0;
    const termsLength = setting.terms?.length || 1;
    const contentsLength = setting.contents?.length || 1;
    return linkAcc + mediumsLength * termsLength * contentsLength;
  }, 0);
  return count;
}

// for count single set engagement link amount
export function countEngagementLink(engagementSet) {
  const count = engagementSet.reduce((engagementAcc, setting) => {
    if (setting.websiteUrl) return engagementAcc + 1;
    return engagementAcc;
  }, 0);

  return count;
}

// for count single set engagement reach amount
export function countEngagementReach(engagementSet) {
  const count = engagementSet.reduce((engagementAcc, setting) => {
    if (setting.audienceType === "all" || engagementAcc === "all")
      return "Unlimited";
    return engagementAcc + setting.estimatedAmount;
  }, 0);

  return count;
}

// for making composition of custom tracking link
export function makeCustomTrackingLink(distributionSetting, name, tabIndex) {
  function getContent() {
    let content = "custom";
    if (!distributionSetting.audienceType) return "";
    if (distributionSetting.audienceType === "all") {
      content = "all";
    }
    if (distributionSetting.userGroup) {
      content = distributionSetting.userGroup;
    }
    if (distributionSetting.tag) {
      content = distributionSetting.tag;
    }

    if (distributionSetting.aiEnhancedUserGroup) {
      content = distributionSetting.aiEnhancedUserGroup;
    }

    return `${content}_flyer_${String.fromCharCode(65 + tabIndex)}`;
  }

  const channelMapping = {
    widget: "MetaCRM",
    debankHi: "Debank",
    xmtp: "XMTP",
    push: "Push_protocol",
  };

  const mediumMapping = {
    "": "",
    widget: "Notification",
    debankHi: "Hi",
    xmtp: "message",
    push: "chat",
  };
  if (!distributionSetting?.channel) return "";

  const trackingUrl = `?utm_campaign=${name}&utm_source=${
    channelMapping[distributionSetting?.channel || ""]
  }&utm_medium=${mediumMapping[distributionSetting?.channel || ""]}`;

  return trackingUrl;
}

// for check unique website url and tracking link
export function compareEngagementWebsiteUrlHasError(
  webUrl,
  trackingLink,
  campaignContentSets
) {
  if (!webUrl) return "Website Url is required when using tracking link";
  let error = null;
  campaignContentSets?.forEach((item) => {
    item.customLinkSettings?.forEach((setting) => {
      const customTrackingLinks = [];
      const mediumsArray = setting.mediums.length > 0 ? setting.mediums : [""];
      const termsArray = setting.terms.length > 0 ? setting.terms : [""];
      const contentsArray =
        setting.contents.length > 0 ? setting.contents : [""];

      mediumsArray.forEach((medium) => {
        termsArray.forEach((term) => {
          contentsArray.forEach((content) => {
            const customTrackingLink =
              `&utm_source=${setting.source}` +
              `&utm_medium=${medium.value}` +
              `${term ? `&utm_term=${term.value}` : ""}` +
              `${content ? `&utm_content=${content.value}` : ""}`;
            customTrackingLinks.push(customTrackingLink);
          });
        });
      });

      if (
        item.customWebsiteUrl === webUrl &&
        customTrackingLinks.includes(trackingLink)
      )
        return (error = "This URL is already used.");
    });

    item.distributionSettings?.forEach((setting) => {
      if (
        setting.websiteUrl === webUrl &&
        setting.trackingLink === trackingLink
      )
        return (error = "This URL is already used.");
    });
  });
  return error;
}

// for get init custom link setting
export function getInitCustomLinkSetting() {
  return cloneDeep({
    source: "",
    mediums: [],
    terms: [],
    contents: [],
    isOpenTerm: false,
    linkCount: 0,
    trackingLinks: [],
  });
}

// for get init engagement distribution setting
export function getInitEngagementDistributionSetting(initValue = {}) {
  return cloneDeep({
    channel: "",
    audienceType: "",
    customType: "",
    aiEnhancedUserGroup: "",
    userGroup: "",
    tag: "",
    useOnchain: false,
    websiteUrl: "",
    trackingLink: "",
    tailoredAudience: "",
    estimatedAmount: 0,
    useLinkFormatSetting: false,
    ...initValue,
  });
}

export function getInitialWidgetObject(
  widgetGoal = "onchain",
  defaultTailoredAudience
) {
  const id = uuidv4();

  return cloneDeep({
    type: "widget",
    goal: widgetGoal,
    engagementTitle: "",
    engagementDescription: "",
    engagementImages: [],
    engagementDistributionSettings: [
      getInitEngagementDistributionSetting({
        channel: "widget",
        audienceType: defaultTailoredAudience ? "custom" : "all",
        useOnchain: widgetGoal === "onchain",
        customType: defaultTailoredAudience ? "campaignAudiences" : "",
      }),
    ],
    _id: id,
  });
}

export function getInitialFlyerObject(flyerGoal = "onchain") {
  const id = uuidv4();

  return cloneDeep({
    type: "flyer",
    goal: flyerGoal,
    engagementTitle: "",
    engagementDescription: "",
    engagementImages: [],
    engagementDistributionSettings: [
      getInitEngagementDistributionSetting({
        channel: "push",
        audienceType: "custom",
        customType: "aiEnhancedUser",
        useOnchain: flyerGoal === "onchain",
      }),
    ],
    _id: id,
  });
}

export function getInitialTrackerObject() {
  const id = uuidv4();

  return cloneDeep({
    type: "tracker",
    goal: "onchain",
    customWebsiteUrl: "",
    customLinkSettings: [getInitCustomLinkSetting()],
    _id: id,
  });
}

export const getCampaignCustomType = (data) => {
  if (data.audienceType == "all") return "all";
  if (data.userGroup) {
    return "segment";
  }
  if (data.tag) {
    return "tag";
  }
  if (data.campaignAudiences) {
    return "campaignAudiences";
  }
  if (data.adamAudience) {
    return "adam";
  }
  return "";
};
