import React, { useState, useEffect } from "react";
import _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";

import Box from "@mui/material/Box";

import {
  Button,
  IconButton,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

import {
  StyledDemoTemplateContainer,
  StyledDemoTemplate,
  StyledDemoTemplateHeader,
  StyledDemoTemplateHeaderLoginInfoBlock,
  StyledDemoTemplateHeaderLoginInfoAvatar,
  StyledMenuButton,
  StyledDemoTemplateBody,
  StyledDemoTemplateInfoArea,
  StyledDemoTemplateSearchContainer,
  StyledDemoTemplateSearchInput,
  StyledDemoTemplateCreateButton,
  StyledFiltersContainer,
  StyledFilterButton,
  StyledCategoryDropDown,
  StyledTicketListContainer,
  StyledTicketListTitleArea,
  StyledStatusTitle,
  StyledTicketTitle,
  StyledLastMessageTitle,
  StyledCreateTitle,
  StyledTicketList,
  StyledTicketItem,
  StyledTicketItemStatus,
  StyledTicketItemTicketTitle,
  StyledTicketItemLastMessage,
  StyledTicketItemCreate,
  StyledDemoTemplateChatArea,
  StyledDemoTemplateChatAreaHeaderContainer,
  StyledDemoTemplateChatCreateInfo,
  StyledDemoTemplateChatCreateInfoTime,
  StyledDemoTemplateChatCreateInfoCategory,
  StyledDemoTemplateChatTicketTitle,
  StyledDemoTemplateChatTicketStatusInfo,
  StyledDemoTemplateChatTicketStatusText,
  StyledDemoTemplateChatTicketStatusTag,
  StyledDemoTemplateChatContainer,
  StyledUserMessageContainer,
  StyledUserInfo,
  StyledUserMessageName,
  StyledUserMessageAvatar,
  StyledUserMessageItemBlock,
  StyledAdminMessageItemMsg,
  StyledUserMessageItemFrom,
  StyledUserMessageItemInfoBox,
  StyledUserMessageItemMsg,
  StyledAdminMessageContainer,
  StyledAdminInfo,
  StyledAdminMessageAvatar,
  StyledAdminMessageItemBlock,
  StyledDemoTemplateReplyContainer,
  StyledDemoTemplateReplyInput,
  StyledDemoTemplateReplyFunctionList,
  StyledDemoTemplateReplyUploadImg,
  StyledDemoTemplateReplySendButton,
} from "./DemoTemplate.styles";

const DemoTemplate = ({ colorArray }) => {
  const convertColorArrayToObject = (array) => {
    let obj = {};
    for (let item of array) {
      obj[item.name] = item.color;
    }
    return obj;
  };

  const colorSetting = convertColorArrayToObject(colorArray);

  const {
    primary: primaryColor,
    text: textColor,
    border: borderColor,
    button: buttonColor,
    buttonText: buttonTextColor,
    hover: hoverColor,
    background,
  } = colorSetting;

  return (
    <StyledDemoTemplateContainer>
      <StyledDemoTemplate>
        <StyledDemoTemplateHeader
          primaryColor={primaryColor}
          borderColor={borderColor}
        >
          <StyledDemoTemplateHeaderLoginInfoBlock borderColor={borderColor}>
            <StyledDemoTemplateHeaderLoginInfoAvatar></StyledDemoTemplateHeaderLoginInfoAvatar>
            <StyledMenuButton textColor={textColor}>
              <i className="meta-crm-icon-ic_menu font-size-12" />
            </StyledMenuButton>
          </StyledDemoTemplateHeaderLoginInfoBlock>
        </StyledDemoTemplateHeader>
        <StyledDemoTemplateBody
          background={background}
          borderColor={borderColor}
        >
          <StyledDemoTemplateInfoArea
            borderColor={borderColor}
            primaryColor={primaryColor}
          >
            <StyledDemoTemplateSearchContainer>
              <StyledDemoTemplateSearchInput
                borderColor={borderColor}
              ></StyledDemoTemplateSearchInput>
              <StyledDemoTemplateCreateButton
                buttonColor={buttonColor}
                buttonTextColor={buttonTextColor}
              ></StyledDemoTemplateCreateButton>
            </StyledDemoTemplateSearchContainer>
            <StyledFiltersContainer>
              {[...Array(2)].map((_, index) => (
                <StyledFilterButton
                  buttonColor={buttonColor}
                  buttonTextColor={buttonTextColor}
                  borderColor={borderColor}
                  key={index}
                />
              ))}
            </StyledFiltersContainer>

            <StyledTicketListContainer>
              {/* <StyledTicketListTitleArea>
                <StyledStatusTitle>Status</StyledStatusTitle>
                <StyledTicketTitle>Ticket Title / Issue type</StyledTicketTitle>
                <StyledLastMessageTitle>Last message</StyledLastMessageTitle>
                <StyledCreateTitle>Create</StyledCreateTitle>
              </StyledTicketListTitleArea> */}
              <StyledTicketList borderColor={borderColor}>
                {[...Array(3)].map((_, index) => (
                  <StyledTicketItem
                    borderColor={borderColor}
                    hoverColor={hoverColor}
                    key={index}
                  >
                    <StyledTicketItemStatus
                      borderColor={borderColor}
                      textColor={textColor}
                    ></StyledTicketItemStatus>
                    <StyledTicketItemTicketTitle
                      textColor={textColor}
                      borderColor={borderColor}
                    ></StyledTicketItemTicketTitle>
                    <StyledTicketItemLastMessage
                      textColor={textColor}
                      borderColor={borderColor}
                    ></StyledTicketItemLastMessage>
                    <StyledTicketItemCreate
                      textColor={textColor}
                    ></StyledTicketItemCreate>
                  </StyledTicketItem>
                ))}
              </StyledTicketList>
            </StyledTicketListContainer>
          </StyledDemoTemplateInfoArea>
          <StyledDemoTemplateChatArea
            borderColor={borderColor}
            primaryColor={primaryColor}
          >
            <StyledDemoTemplateChatAreaHeaderContainer
              borderColor={borderColor}
            >
              <StyledDemoTemplateChatCreateInfo>
                <StyledDemoTemplateChatCreateInfoTime
                  textColor={textColor}
                ></StyledDemoTemplateChatCreateInfoTime>
                <StyledDemoTemplateChatCreateInfoCategory
                  textColor={textColor}
                ></StyledDemoTemplateChatCreateInfoCategory>
              </StyledDemoTemplateChatCreateInfo>
              <StyledDemoTemplateChatTicketTitle
                textColor={textColor}
              ></StyledDemoTemplateChatTicketTitle>
              <StyledDemoTemplateChatTicketStatusInfo>
                <StyledDemoTemplateChatTicketStatusText
                  textColor={textColor}
                ></StyledDemoTemplateChatTicketStatusText>
                <StyledDemoTemplateChatTicketStatusTag
                  textColor={textColor}
                ></StyledDemoTemplateChatTicketStatusTag>
              </StyledDemoTemplateChatTicketStatusInfo>
            </StyledDemoTemplateChatAreaHeaderContainer>
            <StyledDemoTemplateChatContainer>
              <StyledUserMessageContainer>
                <StyledUserInfo>
                  <StyledUserMessageAvatar
                    src={require("assets/img/ic_alpacaBlack.svg").default}
                  ></StyledUserMessageAvatar>
                </StyledUserInfo>
                <StyledUserMessageItemBlock borderColor={borderColor}>
                  <StyledUserMessageItemFrom
                    textColor={textColor}
                  ></StyledUserMessageItemFrom>
                  <Box display="flex" mb="18px">
                    {[...Array(2)].map((_, index) => (
                      <StyledUserMessageItemInfoBox
                        borderColor={borderColor}
                        key={index}
                      />
                    ))}
                  </Box>
                  {[...Array(3)].map((_, index) => (
                    <StyledUserMessageItemMsg
                      textColor={textColor}
                      key={index}
                    />
                  ))}
                </StyledUserMessageItemBlock>
              </StyledUserMessageContainer>
              <StyledAdminMessageContainer>
                <StyledAdminInfo>
                  <StyledAdminMessageAvatar
                    src={require("assets/img/ic_alpacaBlacBigFace.svg").default}
                  ></StyledAdminMessageAvatar>
                </StyledAdminInfo>

                <StyledAdminMessageItemBlock borderColor={borderColor}>
                  <StyledAdminMessageItemMsg
                    textColor={textColor}
                  ></StyledAdminMessageItemMsg>
                </StyledAdminMessageItemBlock>
              </StyledAdminMessageContainer>
            </StyledDemoTemplateChatContainer>

            <StyledDemoTemplateReplyContainer borderColor={borderColor}>
              <StyledDemoTemplateReplyInput
                borderColor={borderColor}
              ></StyledDemoTemplateReplyInput>
              <StyledDemoTemplateReplyFunctionList>
                <StyledDemoTemplateReplyUploadImg
                  borderColor={borderColor}
                ></StyledDemoTemplateReplyUploadImg>
                <StyledDemoTemplateReplySendButton
                  buttonColor={buttonColor}
                  buttonTextColor={buttonTextColor}
                ></StyledDemoTemplateReplySendButton>
              </StyledDemoTemplateReplyFunctionList>
            </StyledDemoTemplateReplyContainer>
          </StyledDemoTemplateChatArea>
        </StyledDemoTemplateBody>
      </StyledDemoTemplate>
    </StyledDemoTemplateContainer>
  );
};

export default DemoTemplate;
