import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { parseCustomDomainUrl } from "features/helpers/utils";
import HoverImage from "features/metadesk/campaign/settings/component/hoverImage";
import ObjectiveItem from "features/metadesk/campaign/settings/component/objectiveItem";
import useMarketModule from "hooks/useMarketModule";
import _ from "lodash";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useOutletContext } from "react-router-dom";

const WebsiteTrafficSettingContent = ({ dataList, loading, onDelete }) => {
  const [entityName, isCustomDomain] = useOutletContext();
  const { attributionConnect } = useMarketModule();
  const theme = useTheme();

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [noResult, setNoResult] = useState(true);

  const handleToSetTracking = (targetId, row) => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/general/integration/attribution?from=analytics/settings/website-traffic`
      )
    );
  };

  const handleToCreateObjective = () => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/analytics/settings/website-traffic/create-objective`
      )
    );
  };

  const renderDataList = () => {
    if (!attributionConnect) {
      return (
        <HoverImage
          originalImg={require("assets/img/website-traffic-placeholder.png")}
          hoverShowImg={require("assets/img/img_customization2.svg").default}
          hoverShowText={formatMessage({
            id: "analytics.websiteTraffic.setting.disableState",
          })}
          onClick={handleToSetTracking}
        ></HoverImage>
      );
    }
    if (loading) {
      return (
        <Box
          height={"60vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <LoadingComponent></LoadingComponent>
        </Box>
      );
    }

    return (
      <Grid container spacing={2}>
        {_.map(dataList, (objectiveItem) => (
          <Grid xs={12} sm={6} md={3} item key={objectiveItem._id}>
            <ObjectiveItem onDelete={onDelete} objectInfo={objectiveItem} />
          </Grid>
        ))}
        <Grid xs={12} sm={6} md={3} item>
          <Box
            sx={{
              height: "280px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px dashed ${theme.customColors.purple[500]}`,
              color: theme.customColors.purple[500],
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={handleToCreateObjective}
          >
            + {formatMessage({ id: "global.btn.createObjective" })}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return <>{renderDataList()}</>;
};

export default WebsiteTrafficSettingContent;
