import { Box } from "@mui/material";
import TextWithTooltip from "components/Text/TextWithTooltip";
import moment from "moment";
import _ from "lodash";
import {
  Button,
  COLOR,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import useNavigateEntity from "hooks/useNavigateEntity";
import { useState } from "react";
import { useConfirm } from "features/home/ConfirmDialogProvider";

const TicketListItem = ({ onClick, isSelected, ticket, goService }) => {
  const { navigateEntity } = useNavigateEntity();
  const confirm = useConfirm();
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      mt="8px"
      pb="8px"
      borderBottom={"1px solid #E6E6E6"}
      style={{ cursor: "pointer" }}
      position={"relative"}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => !goService && onClick(ticket._id)}
    >
      {goService && isHover && (
        <Box
          position={"absolute"}
          left={0}
          top={0}
          right={0}
          bottom={0}
          borderRadius={"4px"}
          backgroundColor={"rgba(255,255,255,0.5)"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            size={SIZE.MEDIUM}
            style={{ borderRadius: "40px" }}
            onClick={() => {
              confirm({
                title: "Are you sure?",
                content: "Do you want to navigate to the Service page?",
                confirmText: "Confirm",
                cancelText: "Cancel",
                confirmButtonColor: COLOR.SECONDARY,
                cancelButtonColor: COLOR.SECONDARY,
              })
                .then(() => {
                  navigateEntity("/service/ticket/all?ticketId=" + ticket._id);
                })
                .catch(() => {});
            }}
          >
            Go Service
          </Button>
        </Box>
      )}
      <Box
        borderRadius={"4px"}
        p="8px"
        backgroundColor={isSelected ? "#FAF6FF" : "white"}
      >
        <TextWithTooltip
          data={ticket.title}
          style={{ fontWeight: 500, color: "#555" }}
          limitRows={1}
        />
        <Box mt="8px">
          <TextWithTooltip
            data={ticket.description}
            style={{ fontWeight: 400, color: "#777" }}
            limitRows={1}
          />
          <Box
            className="betweenRow"
            fontSize={"10px"}
            color="#555"
            mt="12px"
            fontWeight={500}
          >
            <Box backgroundColor="#FAF6FF" p="2.5px 4px" borderRadius={"2.5px"}>
              {_.get(ticket, "type.name")}
            </Box>
            <Box className="endRow">
              <Box
                backgroundColor="#F3F3F3"
                p="3px 5px"
                mr="4px"
                borderRadius={"2.5px"}
                style={{
                  boxShadow: "0.843px 0.843px 0.843px 0px rgba(0, 0, 0, 0.10)",
                }}
              >
                {_.get(ticket, "status")}
              </Box>
              <Box color="#777" fontSize={"10px"} fontWeight={400}>
                {moment(ticket.created).format("MMM DD, YYYY")}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketListItem;
