import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import _, { get } from "lodash";
import { useOutletContext } from "react-router-dom";
import { Button, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useSelector } from "react-redux";
import { selectTicketType } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import {
  selectServiceModule,
  selectMarketingModule,
  selectWidgetModule,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import Back from "../../components/Back";
import useGetIntegrationArr from "./useGetIntegrationArr";
import useNavigateEntity from "hooks/useNavigateEntity";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

const AddIntegration = () => {
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();
  const { navigateEntity } = useNavigateEntity();
  const [entityName, isCustomDomain] = useOutletContext();
  const ticketType = useSelector(selectTicketType);
  const isTicketTypeExist = !_.isEmpty(ticketType);
  const serviceModule = useSelector(selectServiceModule);
  const marketingModule = useSelector(selectMarketingModule);
  const widgetModule = useSelector(selectWidgetModule);
  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, []);

  const isMultiDomainWidget = _.get(widgetModule, "multiDomain", false);

  const tagColorMapping = {
    services: {
      backgroundColor: "#C5E3FF",
      label: "Services",
    },
    marketing: {
      backgroundColor: "#F1E5FF",
      label: "Marketing",
    },
    notification: {
      backgroundColor: "#FFF3C0",
      label: "Notification",
    },
    analytics: {
      backgroundColor: "#FFE8B7",
      label: "Analytics",
    },
    "Project Management": {
      backgroundColor: "#FFDFE6",
      label: "Project Management",
    },
    broadcast: {
      backgroundColor: "#D6F1C0",
      label: "Broadcast",
    },
  };

  const integrationWayArr = useGetIntegrationArr({
    combinedWidgetConfig: false,
  });

  const handleNavigate = ({ extraAction, link, title, addLink }) => {
    if (title === "Discord" && !isTicketTypeExist) {
      return;
    }
    if (link === "github") {
      return extraAction();
    }
    extraAction && extraAction();
    if (link == "discord") return;
    if (link == "telegram") {
      navigateEntity(`/settings/customization/telegramSetting`);
      return;
    }
    if (addLink) return addLink();

    navigateEntity(`/settings/general/integration/update/${link}`);
  };

  const renderAddButton = ({ link, extraAction, title, addLink }) => {
    if (link === "widgetSupport") {
      if (
        (get(widgetModule, `connected`) || get(widgetModule, `connecting`)) &&
        !isMultiDomainWidget
      ) {
        return (
          <Box position={"relative"}>
            <Button
              style={{ marginLeft: 12, minWidth: 0, padding: 10 }}
              color={COLOR.SECONDARY}
              startIcon={<i className="meta-crm-icon-ic_check font-size-16" />}
              variant="text"
            >
              Added
            </Button>
          </Box>
        );
      }
    } else {
      if (
        get(serviceModule, `${link}.connected`) ||
        get(marketingModule, `${link}.connected`) ||
        get(serviceModule, `${link}.connecting`) ||
        get(marketingModule, `${link}.connecting`)
      ) {
        return (
          <Box position={"relative"}>
            <Button
              style={{ marginLeft: 12, minWidth: 0, padding: 10 }}
              color={COLOR.SECONDARY}
              startIcon={<i className="meta-crm-icon-ic_check font-size-16" />}
              variant="text"
            >
              Added
            </Button>
          </Box>
        );
      }
    }

    return (
      <Button
        style={{ marginLeft: 12, minWidth: 0, padding: 10 }}
        color={COLOR.SECONDARY}
        startIcon={<i className="meta-crm-icon-ic_add font-size-16" />}
        onClick={() => handleNavigate({ extraAction, link, title, addLink })}
      >
        Add
      </Button>
    );
  };

  return (
    <div>
      <Back />
      <div className="title">Add Integration</div>
      <div className="description">Choose an integration you wish to add.</div>
      <Box m="30px 0 18px" color="#777" fontSize="14px" fontWeight={700}>
        Integration List
      </Box>

      <Grid container spacing={1} style={{ alignItems: "stretch" }}>
        {integrationWayArr
          // .filter((item) => seitem.link)
          .map((integrationWayItem, index) => {
            const {
              auth,
              icon,
              title,
              description,
              link,
              setting,
              extraAction,
              addLink,
              currentVersion,
              latestVersion,
              canUpdate,
              showTooltip,
              tooltipText,
              tags,
              own,
            } = integrationWayItem;
            if (!auth) return null;
            return (
              <Grid item xs={12} md={6} key={index}>
                <StyledWhiteContainer
                  sx={{ marginBottom: "0px", padding: "16px", height: "100%" }}
                >
                  <Box id={`${link}Guide`}>
                    <Box sx={{ display: "flex" }}>
                      <Box>{icon}</Box>

                      <Box ml="16px" sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#5F5F5F",
                          }}
                        >
                          {title}
                        </Box>
                        <Box
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            mt: "4px",
                            color: "#777",
                          }}
                        >
                          {description}
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          mt={"16px"}
                          flexWrap="wrap"
                        >
                          {tags &&
                            tags.map((tag) => (
                              <Box
                                key={tag}
                                sx={{
                                  backgroundColor:
                                    tagColorMapping[tag].backgroundColor,
                                  color: "#555",
                                  borderRadius: "2.5px",
                                  padding: "2.5px 4px",
                                  fontSize: "12px",
                                  marginRight: "4px",
                                  marginBottom: "4px",
                                }}
                              >
                                {tagColorMapping[tag].label}
                              </Box>
                            ))}
                        </Box>
                      </Box>
                      {renderAddButton({
                        link,
                        setting,
                        extraAction,
                        title,
                        addLink,
                        showTooltip,
                        tooltipText,
                        canUpdate,
                      })}
                    </Box>
                  </Box>
                </StyledWhiteContainer>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default AddIntegration;
