import WhiteCard from "features/metadesk/ADAM/component/WhiteCard";
import React, { useEffect, useMemo, useState } from "react";
import BehaviorBarChart from "./BehaviorBarChart";
import { Box } from "@mui/material";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router";
import axios from "axios";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

export default function TwitterBehavior() {
  const [option, setOption] = useState("all");
  const [loading, setLoading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const [entityName] = useOutletContext();
  const [data, setData] = useState({
    handleCount: 0,
    commonFollowing: [],
    topTenBrand: [],
    topTenKOL: [],
  });
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axios.get(
          apiUrl + `/api/customerReport/${entityName}?reportType=x`
        );
        setData(data);
      } catch (err) {
        dispatchSnackbar({ err });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [option]);

  const commonFollowingData = useMemo(() => {
    return data.commonFollowing.slice(0, 10);
  }, [data.commonFollowing]);

  const topTenBrandData = useMemo(() => {
    return data.topTenBrand.slice(0, 10);
  }, [data.topTenBrand]);

  const topTenKOLData = useMemo(() => {
    return data.topTenKOL.slice(0, 10);
  }, [data.topTenKOL]);

  function onClickLabel(label, value) {
    window.open(`https://x.com/${label}`, "_blank");
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <WhiteCard
            label={"Handle Count"}
            value={parseInt(data.handleCount).toLocaleString()}
            width={"100%"}
          />
        </Grid>
      </Grid>
      <BehaviorBarChart
        loading={loading}
        data={commonFollowingData}
        chartName={"Top 10 Following Handles"}
        singleColorIndex={0}
        title={"Followers"}
        onClick={onClickLabel}
      />
      <Box sx={{ height: "10px" }} />
      <BehaviorBarChart
        loading={loading}
        data={topTenBrandData}
        chartName={"Top 10 Brand"}
        singleColorIndex={1}
        title={"Brand"}
        onClick={onClickLabel}
      />
      <Box sx={{ height: "10px" }} />
      <BehaviorBarChart
        loading={loading}
        data={topTenKOLData}
        chartName={"Top 10 KOL"}
        singleColorIndex={2}
        title={"KOL"}
        onClick={onClickLabel}
      />
    </div>
  );
}
