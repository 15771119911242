import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import UnderMaintenance from "features/metadesk/components/UnderMaintenance.js";
import _ from "lodash";
import InterceptorContext from "features/home/InterceptorContext";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { useGTMConfig } from "hooks/useGTMConfig";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useConnectWallet } from "features/home/redux/hooks";
import useThrottledEffect from "use-throttled-effect";
import { Outlet, useLocation } from "react-router-dom";
import { ThemeContext } from "features/home/CustomThemeProvider";
import NotFound from "features/metadesk/components/NotFound.js";
import {
  useFetchEntityConfig,
  useFetchTeamMember,
  useFetchTags,
} from "features/metadesk/redux/hooks";
import useSSEConnection from "hooks/useSSEConnection.hooks";
import { Loading } from "features/common";
import NoAuth from "features/metadesk/components/NoAuth";

import { useIntl } from "react-intl";
import ConnectWallet from "components/ConnectWallet/ConnectWallet.js";
import { useOpenState } from "contexts/stepContext/Open.context";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function App() {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);

  const { isConnected } = useContext(InterceptorContext);
  const { entity } = useParams();
  const {
    auth,
    userData,
    getAuthenticatedInfo,
    authCheckPending,
    authCheckDone,
    checkAuth,
  } = useConnectWallet();
  const { fetchEntityConfig, fetchTicketTypeList } = useFetchEntityConfig();
  const { fetchTeamMember, fetchTeamMemberByTicketPermission } =
    useFetchTeamMember();
  const setThemeName = useContext(ThemeContext);
  const [entityName, setEntityName] = useState(envEntity || entity);
  const gtmParams = useGTMConfig(entityName);
  const userAddress = _.get(userData, "address", "");
  const { openState } = useOpenState();
  const { fetchTags } = useFetchTags();
  const { formatMessage } = useIntl();

  // 掛載sse的監聽
  useSSEConnection({ auth, userAddress, entityName });

  useEffect(() => {
    setThemeName(entityName);
    if (auth) {
      fetchEntityConfig({ entityName });
      fetchTeamMember({ entityName });
      fetchTeamMemberByTicketPermission({ entityName });
      fetchTags({ entityName });
      fetchTicketTypeList({ entityName });
    }
  }, [entityName, auth]);

  useThrottledEffect(
    () => {
      if (!authCheckPending && authCheckDone && auth) {
        getAuthenticatedInfo(entityName);
      }
    },
    1000,
    [auth]
  );

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return <UnderMaintenance />;
  }
  if (isCustomDomain) {
    if (entityName && entityName != process.env.REACT_APP_ENTITY) {
      return <NotFound />;
    }
  }

  return (
    <GTMProvider state={gtmParams}>
      <Wrapper>
        <main>
          <ConnectWallet
            isCustomDomain={isCustomDomain}
            defaultOpen={true}
            entityName={entityName}
            hidden={true}
          />
          <Loading
            open={
              (isConnected && !checkAuth) || _.get(openState, "loading", false)
            }
          />

          {auth ? (
            <Outlet context={[entityName, isCustomDomain]} />
          ) : (
            <NoAuth />
          )}
        </main>
      </Wrapper>
    </GTMProvider>
  );
}
