import React, { useState, createContext } from "react";
import TrialDialog from "./TrialDialog";

export const OpenTrialContext = createContext();

export const OpenTrialProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openTrial = () => setOpen(true);
  const closeTrial = () => setOpen(false);

  return (
    <OpenTrialContext.Provider value={{ open, openTrial, closeTrial }}>
      {children}
      <TrialDialog open={open} onClose={closeTrial} />
    </OpenTrialContext.Provider>
  );
};

export default OpenTrialProvider;
