import { useState, useRef, useMemo } from "react";
import axios from "axios";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

// ticket type "Discord" , "Telegram" , "Widget" "SupportCenter"
const useAssignTicket = ({ ticketTypeList, type }) => {
  const [entityName] = useOutletContext();
  const [ticketList, setTicketList] = useState([]);
  const [chooseTicketType, setChooseTicketType] = useState([]);
  const [initData, setInitData] = useState(null);
  const dispatchSnackbar = useDispatchSnackbar();

  const mapping = {
    Discord: "discordBot",
    Telegram: "telegramBot",
    Widget: "widget",
    SupportCenter: "supportCenter",
  };

  // 合併新的和舊的 ticketType 設定
  const mergeTicketTypesArray = (arr1, arr2) => {
    const map = new Map(arr2.map((item) => [item._id, item]));

    return arr1.map((item) => {
      return map.has(item._id)
        ? { ...item, assignee: item?.assignee || null }
        : item;
    });
  };

  const cleanerTicketTypes = (ticketTypes) =>
    (ticketTypes || []).map((tt) => ({
      _id: tt.ticketType,
      assignee: tt.assignee || null,
    }));

  // 取得 Ticket Type 列表並設置預設值
  const getTicketTypeList = async (id = null) => {
    try {
      // 根據 ticketType 來決定要請求的 API 路徑
      const apiEndpoint = `${apiUrl}/api/manage/ticketTypes/${entityName}/${mapping[type]}/${id}`;

      // 發送請求並獲取資料
      const { data } = await axios.get(apiEndpoint);

      let ticketTypesFromAPI;
      if (type === "Discord") {
        ticketTypesFromAPI = data.ticketTypes.ticketTypes;
      } else {
        // Widget 的情況需要進行資料清理
        ticketTypesFromAPI = cleanerTicketTypes(data.ticketTypes);
      }

      // 檢查目前 Ticket List 中的項目，並設置預設值（僅對 Discord 需要）
      const dataWithDefaultInfo =
        type === "Discord"
          ? ticketTypeList.map((item) => ({
              ...item,
              discordButtonStyle: item.discordButtonStyle || 1,
              addressRequired: item.addressRequired || false,
            }))
          : ticketTypeList;

      // 合併兩個列表
      const newTicketList = mergeTicketTypesArray(
        dataWithDefaultInfo,
        ticketTypesFromAPI
      );

      // 更新狀態
      setTicketList(newTicketList);
      setChooseTicketType(ticketTypesFromAPI);

      // 設定初始數據
      setInitData(ticketTypesFromAPI);
    } catch (err) {
      console.log(err);
      dispatchSnackbar({ err });
    }
  };

  const handleSaveTicket = async (widgetId = null) => {
    try {
      // 比較 Ticket Type 是否有變更
      if (!_.isEqual(initData?.chooseTicketType, chooseTicketType)) {
        const payload = chooseTicketType.map((item) => ({
          ...item,
          ticketType: item._id,
          assignee: item?.assignee || null,
        }));

        const { data: updatedTicketTypesData } = await axios.post(
          `${apiUrl}/api/manage/ticketTypes/${entityName}/${mapping[type]}/${widgetId}`,
          {
            ticketTypes: payload,
          }
        );

        if (type === "Widget") {
          const updatedData = cleanerTicketTypes(updatedTicketTypesData);
          setChooseTicketType(updatedData);
          setInitData(updatedData);
        } else {
          setInitData(chooseTicketType);
        }
        // 更新初始狀態
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const hasChanges = useMemo(() => {
    if (!initData) return false;
    return !_.isEqual(initData, chooseTicketType); // 選擇的 Ticket 類型有變動
  }, [initData, chooseTicketType]);

  return {
    initData,
    setInitData,
    ticketList,
    setTicketList,
    chooseTicketType,
    setChooseTicketType,
    getTicketTypeList,
    hasChanges,
    handleSaveTicket,
  };
};

export default useAssignTicket;
