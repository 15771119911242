import { sumBy } from "lodash-es";
import React from "react";
import { Box, Grid } from "@mui/material";
import {
  StyledColorLabel,
  StyledMemo,
  StyledSecondaryValue,
  StyledSubtitle,
} from "./PerformancePie.styles";
import { map } from "lodash";
import ReactECharts from "echarts-for-react";
import {
  analysisColors,
  marketingColors,
} from "features/configure/chartColors";
import { useLocation } from "react-router";

export default function PerformancePieChart({
  pieData,
  title,
  value,
  last = false,
}) {
  const hasZero =
    sumBy(pieData, function (o) {
      return o.value == 0;
    }) > 0;
  const { pathname } = useLocation();

  const pieColors = pathname.includes("analytics")
    ? analysisColors.small
    : marketingColors.small;
  const option = {
    tooltip: {
      trigger: "item",
    },
    color: pieColors,

    series: [
      {
        name: "",
        startAngle: hasZero ? 135 : 180,
        minShowLabelAngle: 10,
        type: "pie",
        radius: "70%",
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 2,
        },
        labelLayout: (params) => {
          return {
            align: "center",
            verticalAlign: "bottom",
            dy: -1,
          };
        },
        label: {
          formatter: `{d}%`,
          width: 60,
        },
        labelLine: { legend: 15, length2: 25 },
        data: pieData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      style={{
        borderRight: !last ? "1px solid #E6E6E6" : "",
        margin: "18px 0",
        padding: "0 24px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          {title && (
            <>
              <StyledSubtitle className="startRow">{title}</StyledSubtitle>
              <StyledSecondaryValue>{value}</StyledSecondaryValue>
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {map(pieData, (d, i) => {
              return (
                <StyledMemo
                  className="startRow"
                  key={i}
                  style={{ marginTop: 12 }}
                >
                  <StyledColorLabel background={pieColors[i]} />
                  {d.name}
                </StyledMemo>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <ReactECharts
            option={option}
            style={{ height: "259px" }}
            notMerge={true}
            lazyUpdate={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
