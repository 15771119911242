import { useCallback, useEffect, useState, useMemo } from "react";
import { Client } from "@xmtp/xmtp-js";
import { mainnet, useWalletClient } from "wagmi";
import { createWalletClient, custom } from "viem";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import { Box, Grid, IconButton, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";

import IntegrationSettings from "./IntegrationSettings";
import Button, { VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";

import { selectMarketingModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { XMTP_ENV, apiUrl } from "features/configure";
import { alertServerError, alertInfo } from "features/helpers/utils";

import xmtpConnect1 from "assets/img/xmtp-connect-1.svg";
import xmtpConnect2 from "assets/img/xmtp-connect-2.svg";
import xmtpWalletConnect1 from "assets/img/xmtp-wallet-connect-1.png";
import xmtpWalletConnect2 from "assets/img/xmtp-wallet-connect-2.png";

const useStyles = makeStyles((theme) => ({
  settingXmtpCard: (props) => {
    return {
      backgroundColor: "#fff",
      boxShadow: "0px 1.28px 6.41px 0px #0000001A",
      padding: "20px",
      paddingRight: "192px",

      "& > p": {
        margin: 0,
        marginTop: "8px",
        color: props.customColors.grey[600],
      },

      "& > p:first-child": {
        fontSize: "16px",
        color: props.customColors.grey[700],
      },

      "& div": {
        fontSize: "16px",
        color: props.customColors.grey[700],
      },

      "& > div": {
        marginTop: "18px",
      },

      "& .address": {
        flex: 1,

        "& > div": {
          flex: 1,
          maxWidth: "440px",
          marginInline: "4px",
        },
      },
    };
  },
  connectModal: (props) => {
    return {
      zIndex: 6000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& h3": {
        color: props.customColors.grey[700],
        fontSize: 18,
        fontWeight: 700,
        margin: 0,
      },

      "& p": {
        color: props.customColors.grey[600],
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.2,
        marginBottom: 0,
        marginTop: 12,
      },

      "& img": {
        marginTop: 8,
      },
    };
  },
}));

const clientOptions = {
  env: XMTP_ENV,
  skipContactPublishing: false,
  persistConversations: false,
};

const IntegrationXmtp = () => {
  const { formatMessage } = useIntl();
  const { data: client } = useWalletClient();
  const marketingModule = useSelector(selectMarketingModule);
  const [entityName] = useOutletContext();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [step, setStep] = useState(0);
  const [account, setAccount] = useState();

  const theme = useTheme();
  const classes = useStyles(theme);

  const connectXmtp = useCallback(async () => {
    const connectWallet = async () => {
      await client.requestPermissions({ eth_accounts: {} });
      const [currentAccount] = await client.getAddresses();
      setAccount(currentAccount);
      setStep(1);
    };
    const createIdentity = async () => {
      const walletClient = createWalletClient({
        account: account,
        chain: mainnet,
        transport: custom(window.ethereum),
      });
      const keys = await Client.getKeys(walletClient, clientOptions);
      const { data } = await axios.post(
        `${apiUrl}/api/xmtp/key/${entityName}`,
        {
          address: walletClient.account.address,
          keys: Buffer.from(keys).toString("binary"),
        }
      );

      if (data.error) throw data.error;

      setConnectedAddress(client.account.address);
      dispatch(alertInfo("XMTP successfully connected"));
      setStep(0);
      setAccount(undefined);
      setOpen(false);
    };
    try {
      setLoading(true);
      await [connectWallet, createIdentity][step]();
    } catch (error) {
      dispatch(alertServerError(error));
    } finally {
      setLoading(false);
    }
  }, [client, dispatch, entityName, step, account]);

  const stepData = useMemo(
    () =>
      [
        [
          {
            label: "Select Account",
            img: xmtpWalletConnect1,
          },
          {
            label: "Connect Account",
            img: xmtpWalletConnect2,
          },
        ],
        [
          {
            label: formatMessage({ id: "popup.xmtpSettings.create.title" }),
            img: xmtpConnect1,
          },
          {
            label: formatMessage({ id: "popup.xmtpSettings.enable.title" }),
            img: xmtpConnect2,
          },
        ],
      ][step],
    [step, formatMessage]
  );

  useEffect(() => {
    if (marketingModule?.xmtp?.address)
      setConnectedAddress(marketingModule?.xmtp?.address);
  }, [marketingModule?.xmtp?.address]);

  return (
    <>
      <IntegrationSettings
        title={formatMessage({ id: "xmtpSettings.installation.title" })}
        description={formatMessage({
          id: "xmtpSettings.installation.description",
        })}
        content={
          <Box className={classes.settingXmtpCard}>
            <p>{formatMessage({ id: "xmtpSettings.installation.subtitle" })}</p>
            <p>
              {formatMessage({
                id: "xmtpSettings.installation.subdescription",
              })}
            </p>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div>
                {formatMessage({
                  id: "xmtpSettings.installation.walletAddress",
                })}
              </div>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
                className="address"
              >
                <InputField
                  style={{ width: "100%" }}
                  placeholder="-"
                  value={connectedAddress}
                  disabled
                />
                <Button onClick={() => setOpen(true)} disabled={loading}>
                  {connectedAddress ? "Change wallet" : "Connect to XMTP"}
                </Button>
              </Box>
            </Box>
          </Box>
        }
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={classes.connectModal}
      >
        <Box
          p={6}
          width={480}
          position={"absolute"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
          bgcolor={"#fff"}
          outline={"none"}
          borderRadius={2.25}
        >
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => setOpen(false)}
          >
            <i className="meta-crm-icon-ic_cancel" />
          </IconButton>

          <h3>
            {formatMessage({ id: "popup.xmtpSettings.installation.title" })}
          </h3>

          <p>
            {formatMessage({
              id: "popup.xmtpSettings.installation.description",
            })}
          </p>

          <Box
            sx={{
              px: 2.25,
              py: 3,
              mt: 2,
              width: "100%",
              bgcolor: (theme) => theme.customColors.grey[200],
            }}
          >
            <Grid container spacing={2}>
              {stepData.map((data, i) => (
                <Grid
                  key={i}
                  item
                  xs={6}
                  sx={{ color: (theme) => theme.customColors.grey[600] }}
                >
                  <span>{data.label}</span>
                  <img
                    src={data.img}
                    alt={data.label}
                    style={{ maxWidth: "164px" }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            mt={3}
            gap={0.5}
          >
            <Button disabled={loading} onClick={connectXmtp}>
              {
                [
                  formatMessage({ id: "global.btn.connect" }),
                  "Create Identity",
                ][step]
              }
            </Button>
            <Button variant={VARIANT.TEXT} onClick={() => setOpen(false)}>
              {formatMessage({ id: "global.btn.cancel" })}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default IntegrationXmtp;
