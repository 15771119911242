import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

export const StyledInterfaceThemeContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledInterfaceThemeTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  marginBottom: "24px",
}));

export const StlyledSelectThemeContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledThemeButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",

  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderLeft: "1px",
  },
}));

export const StyledThemeButton = styled(ToggleButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, readonly }) => ({
  marginRight: "24px",
  border: `1px solid ${theme.customColors.grey[400]} !important`,
  borderRadius: "8px !important",
  display: "block",
  padding: 0,
  textAlign: "left",
  width: "290px",
  textTransform: "unset",
  marginBottom: "10px",
  cursor: readonly ? "default" : "pointer",

  "&.Mui-selected": {
    backgroundColor: theme.customColors.white,
    border: `2px solid ${theme.customColors.purple[500]} !important`,
    "&:hover": {
      backgroundColor: theme.customColors.purple[300],
    },
  },

  "&:hover": {
    backgroundColor: theme.customColors.purple[300],
  },
}));

export const StyledThemeButtonTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  color: theme.customColors.grey[700],
  padding: "16px",
  paddingRight: "12px",
  borderBottom: `1px solid ${theme.customColors.grey[300]}`,
  lineHeight: 1,
  display: "flex",
  alignItems: "center",
}));

export const StyledThemeButtonContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "16px",
  paddingBottom: "24px",
}));

export const StyledThemeButtonHint = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  lineHeight: "20px",
  marginBottom: "50px",
}));

export const StyledThemeButtonImg = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  boxShadow: `0px 2px 10px 0px #0000001A`,
}));

export const StyledLink = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  color: theme.customColors.purple[500],
  marginLeft: "auto",
  textDecoration: "underLine",
}));

export const StyledTicketTypeTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.customColors.grey[700],
  marginBottom: 12,
}));

export const StyledTicketTypeName = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  marginRight: "auto",
  padding: "4px",
  borderRadius: "4px",
  backgroundColor: "#FAF6FF",
  fontWeight: 500,
  textWrap: "nowrap",
}));
