import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const AudienceCardTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  color: theme.customColors.grey[600],
  marginBottom: "4px",
}));

export const AudienceCardValue = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: theme.customColors.grey[700],
  marginBottom: "16px",
}));
