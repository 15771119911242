import React, { useState, useEffect } from "react";

import Linkify from "linkify-react";
import _ from "lodash";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import {
  StyledMacroListItem,
  StyledMacroListIcon,
  StyledMacroListText,
  StyleMacroListEndAdornment,
  StyleMacroTooltip,
  StyledToolTipText,
  StyledToolTipImage,
} from "./SingleQuestion.styles";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";

const SingleQuestion = ({ macro, onLinkifyOptions, onSelectMacro }) => {
  const renderTags = (tags) => {
    return (
      <div className="startRow">
        {tags.map((tag) => (
          <MetaCRMTag
            key={tag._id}
            option={{ color: tag.color, label: tag.value }}
          />
        ))}
      </div>
    );
  };

  const renderContent = (answer, tags) => {
    const { text, image } = answer;
    return (
      <>
        {text && (
          <StyledToolTipText>
            <Linkify options={onLinkifyOptions}>{text}</Linkify>
          </StyledToolTipText>
        )}
        {!_.isEmpty(image) &&
          image.map((image, item) => <StyledToolTipImage src={image} />)}
        {!_.isEmpty(tags) && renderTags(tags)}
      </>
    );
  };

  const handleQuestionClick = (data) => {
    onSelectMacro(data);
  };

  return (
    <StyleMacroTooltip
      title={renderContent(macro.answer, macro.tags)}
      placement="right-start"
    >
      <StyledMacroListItem onClick={() => handleQuestionClick(macro)}>
        <StyledMacroListIcon>Q : </StyledMacroListIcon>
        <StyledMacroListText>
          <Linkify options={onLinkifyOptions}>
            {macro.questions[0].text}
          </Linkify>
        </StyledMacroListText>
        <StyleMacroListEndAdornment>
          <ArrowForwardIosRoundedIcon sx={{ width: "18px", height: "18px" }} />
          {/* <i className="meta-crm-icon-ic_arrowLineRight font-size-24" /> */}
        </StyleMacroListEndAdornment>
      </StyledMacroListItem>
    </StyleMacroTooltip>
  );
};

export default SingleQuestion;
