import { Box, Grid } from "@mui/material";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { useContext, useMemo, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import BigNumber from "bignumber.js";
import _ from "lodash";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import ReferrerCountModal from "./component/ReferrerCountModal";
import { useIntl } from "react-intl";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import TextWithTooltip from "components/Text/TextWithTooltip";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import useNavigateEntity from "hooks/useNavigateEntity";
export default function ReferrerTable({
  referrers,
  total,
  setOpenAudience,
  isShowObjective,
  objectiveData,
}) {
  const isDemoSite = useSelector(selectDemoSite);
  const PAGE_COUNT = 5;
  const { navigateEntity } = useNavigateEntity();
  const [countModal, setCountModal] = useState({ isOpen: false, data: [] });
  const { page, setPage, order, setOrder, sort, setSort } =
    useContext(PerformanceContext);
  const { formatMessage } = useIntl();
  const valueStyle = {
    fontWeight: 700,
    fontSize: 24,
    color: "#383538",
    mt: "12px",
  };
  const calculateConversion = (objectiveAchieved = 0, total) => {
    if (!total) return "-%";
    return `${parseFloat((objectiveAchieved * 100) / total).toFixed(1)}%`;
  };
  const calculateDrop = (walletsConnected, total) => {
    if (!total) return "-%";
    return `${parseFloat(((total - walletsConnected) * 100) / total).toFixed(
      1
    )}%`;
  };

  const headCells = useMemo(() => {
    const result = [
      {
        show: true,
        disabledSort: true,
        id: "trafficSource",
        label: (
          <Box className="startRow">
            <Box className="mgr4">
              {formatMessage({
                id: "websiteTraffic.columnName.trafficSource",
              })}
            </Box>
            <IconTooltip
              content={formatMessage({
                id: "websiteTraffic.columnName.trafficSource.toolTip",
              })}
            />
          </Box>
        ),
        size: 4,
      },
      {
        show: true,
        id: "totalUser",
        label: (
          <Box>
            Total Users
            <Box sx={valueStyle}>{total?.users || "-"}</Box>
          </Box>
        ),
      },
      {
        show: true,
        id: "walletsConnected",
        label: (
          <Box>
            Wallet Connected
            <Box sx={valueStyle}>
              {total?.wallets ? new BigNumber(total.wallets).toFormat(0) : "-"}
            </Box>
          </Box>
        ),
      },
    ];
    if (isShowObjective) {
      result.push({
        show: true,
        id: "objectiveAchieved",
        label: (
          <Box>
            <Box className="startRow">
              <Box className="mgr4">Objective Achieved </Box>
              {_.isEmpty(objectiveData) && (
                <IconTooltip
                  content={`Set your "Objective" first to track "Objective Achieved". Click "-" or go to the 'Website Objective page' to set it up.`}
                ></IconTooltip>
              )}
            </Box>
            <Box sx={valueStyle}>
              <Box>
                {total?.objectiveAchieved
                  ? new BigNumber(total.objectiveAchieved).toFormat(0)
                  : "-"}
              </Box>
            </Box>
          </Box>
        ),
      });
    }
    result.push({
      show: true,
      disabledSort: true,
      id: "rate",
      label: (
        <Box>
          {isShowObjective ? "Conversion Rate" : "Drop Rate"}
          <Box sx={valueStyle}>
            {isShowObjective
              ? calculateConversion(total?.objectiveAchieved, total?.users)
              : calculateDrop(total?.wallets, total?.users)}
          </Box>
        </Box>
      ),
    });
    return result;
  }, [isShowObjective, total]);

  const renderObjectiveAchieved = (report, source) => {
    if (report.objectiveAchieved || !_.isEmpty(objectiveData)) {
      return (
        <span
          className="linkCount"
          onClick={(e) => {
            e.stopPropagation();
            setCountModal({
              isOpen: true,
              source,
              data: {
                achievedWallets: report.achievedWallets,
                objectiveAchieved: report.objectiveAchieved,
                walletsConnected: report.walletsConnected,
                totalWallet: total?.wallets,
              },
            });
          }}
        >
          {report.objectiveAchieved ? report.objectiveAchieved : 0}
        </span>
      );
    }

    return (
      <IconTooltip
        content={"Please set up an objective to display value."}
        icon={
          <Box
            onClick={() => {
              navigateEntity("/analytics/settings/website-traffic");
            }}
            style={{ cursor: "pointer" }}
          >
            -
          </Box>
        }
      />
    );
  };

  const renderContents = (report, i) => {
    const data = isDemoSite
      ? `https://test-demo${(page - 1) * PAGE_COUNT + i + 1}.metacrm.inc`
      : report.referer;
    const contents = [
      <Box
        slot="trafficSource"
        className="startRow"
        style={{ flexWrap: "nowrap", width: "100%", alignItems: "flex-start" }}
      >
        <Box sx={{ mr: "12px" }}>{(page - 1) * PAGE_COUNT + i + 1}.</Box>
        <Box
          sx={{
            width: "calc(100% - 24px)",
            maxWidth: "300px",
            overflow: "hidden",
          }}
        >
          <TextWithTooltip data={data} />
        </Box>
      </Box>,
      <Box slot="totalUser" sx={{ maxWidth: "260px" }} className="wordBreak">
        {report.totalUser}
      </Box>,
      <Box slot="walletsConnected">{report.walletsConnected}</Box>,
    ];
    if (isShowObjective) {
      contents.push(
        <Box slot="objectiveAchieved">
          {renderObjectiveAchieved(report, data)}{" "}
        </Box>
      );
    }
    contents.push(
      <Box slot="rate">
        {isShowObjective
          ? calculateConversion(report.objectiveAchieved, report.totalUser)
          : calculateDrop(report.walletsConnected, report.totalUser)}
      </Box>
    );
    return contents;
  };
  const handleRequestSort = (event, property) => {
    const isAsc = sort === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSort(property);
  };

  return (
    <Box>
      <CRMTable
        rowData={referrers.data}
        headers={headCells}
        contents={_.map(referrers.data, (row, i) => renderContents(row, i))}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={sort}
      />
      <ReferrerCountModal
        open={countModal.isOpen}
        setOpenAudience={(objectiveStatus) =>
          setOpenAudience({
            trafficSource: countModal.source,
            objectiveStatus,
          })
        }
        onClose={() => setCountModal({ isOpen: false, data: [] })}
        data={countModal.data}
      />
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={referrers.total}
        page={page}
        setPage={(v) => setPage(v)}
      />
    </Box>
  );
}
