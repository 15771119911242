import { useEffect, useMemo, useState } from "react";
import { alertServerError, msToTime } from "features/helpers/utils";
import { useOutletContext } from "react-router";
import { apiUrl } from "features/configure";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";
import { Loading } from "features/common";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box } from "@mui/material";
import { useFetchTeamMember } from "../redux/fetchTeamMember";
import { getUserAvatarIcon } from "../utils";
import { useTheme } from "@emotion/react";
import IconIcPin from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcPin";

import { agentMetrics } from "./mockData/MockServiceReport";
import { selectDemoSite } from "../redux/entityConfig/entityConfig.selector";

export function AgentAnalytics({ startDate, endDate, platform }) {
  const [entityName, isCustomDomain] = useOutletContext();
  const [analytics, setAnalytics] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("ticketCount.done");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { memberList } = useFetchTeamMember();
  const isDemoSite = useSelector(selectDemoSite);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rowData = useMemo(() => {
    let m = [];
    const agentsData = analytics?.agentMetrics;
    if (agentsData) {
      m = agentsData;
    }

    const result = _.orderBy(
      _.values(m),
      [
        (item) => (item.agentId === null ? 0 : 1),
        (item) => !item[orderBy],
        orderBy,
      ],
      ["asc", "asc", order]
    );
    return result;
  }, [analytics, order, orderBy, memberList]);

  useEffect(async () => {
    try {
      setLoading(true);
      const params = { platform };
      if (startDate) params.startDate = startDate.toISOString();
      if (endDate) params.endDate = endDate.toISOString();
      if (isDemoSite) {
        setAnalytics(agentMetrics);
      } else {
        const _analytics = await axios.get(
          apiUrl + `/api/ticket/analytics/${entityName}/agent`,
          { params }
        );

        setAnalytics(_analytics.data);
      }
    } catch (error) {
      dispatch(alertServerError(error));
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, platform]);

  const headers = [
    { show: true, label: "Agent Name", id: "agentName", width: 220 },
    { show: true, label: "New Tickets", id: "ticketCount.new" },
    { show: true, label: "Ongoing Tickets", id: "ticketCount.ongoing" },
    { show: true, label: "Done Tickets", id: "ticketCount.done" },
    {
      show: true,
      label: "Avg. First Response Time",
      id: "avgFirstResponseTime",
    },
    {
      show: true,
      label: "Avg. Resolve Time",
      id: "avgResolveTime",
    },
    {
      show: true,
      label: "Agent CSAT",
      id: "agentCsat",
    },
  ];

  const theme = useTheme();

  const renderContents = (agent) => {
    const icon = getUserAvatarIcon(agent._id, theme);

    const contents = [
      <Box slot="agentName">
        {agent.agentId ? (
          <Box className="startRow">
            <img
              src={require(`assets/img/${icon}`)}
              className="icon"
              style={{ borderRadius: 20, marginRight: 8 }}
            />
            <Box>
              {agent.agentName}
              {agent.agentType == "discord" && (
                <Box>
                  <i
                    className="meta-crm-icon-ic_discord font-size-14"
                    style={{ color: "#7B61FF" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box className="startRow">
            <IconIcPin
              className="icon"
              style={{ borderRadius: 20, margin: 5 }}
            />
            Unassigned
          </Box>
        )}
      </Box>,
      <Box slot="ticketCount.new">{agent?.ticketCount?.new || "-"}</Box>,
      <Box slot="ticketCount.ongoing">
        {agent?.ticketCount?.ongoing || "-"}
      </Box>,
      <Box slot="ticketCount.done">{agent?.ticketCount?.done || "-"}</Box>,
      <Box slot="avgFirstResponseTime">
        {agent.avgFirstResponseTime
          ? msToTime(agent.avgFirstResponseTime)
          : "-"}
      </Box>,
      <Box slot="avgResolveTime">
        {agent.avgResolveTime ? msToTime(agent.avgResolveTime) : "-"}
      </Box>,
      <Box slot="agentCsat">
        {agent?.agentCsat || "-"}
        {agent?.csatRatingPercent
          ? ` (${parseFloat(agent?.csatRatingPercent * 100).toFixed(
              0
            )}% Rating)`
          : ""}
      </Box>,
    ];
    return contents;
  };

  return (
    <Box position={"relative"} sx={{ mt: "12px" }}>
      <Loading open={loading} fullScreen={false} />

      <CRMTable
        headers={headers}
        rowData={rowData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        contents={_.map(rowData, (agent) => renderContents(agent))}
      />
    </Box>
  );
}
