import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";

export const StyledSettingItem = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
export const StyledSettingItemTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "140px",
  fontWeight: 700,
  color: theme.customColors.grey[700],
}));
export const StyledSettingItemContent = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "relative",
}));
export const StyledSettingItemChooseColorArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .MuiFormHelperText-root": {
    "&.Mui-error": {
      position: "absolute",
      fontSize: "12px",
      bottom: "-20px",
      left: 0,
    },
  },
}));
export const StyledColorDemoCycle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color, readonly }) => ({
  width: "22px",
  height: "22px",
  border: `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "8px",

  "&:hover": {
    cursor: readonly ? "default" : "pointer",
    border: readonly ? "none" : `1px solid ${theme.customColors.purple[500]}`,
  },

  "&:after": {
    content: '""',
    width: "16px",
    height: "16px",
    border: `1px solid ${theme.customColors.grey[200]}`,
    borderRadius: "50%",
    display: "block",
    background: color,
  },
}));

export const StyledColorPickerPopover = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  zIndex: 2,
  left: "25px",
  top: "25px",
}));

export const StyledColorPickerCover = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
}));

export const StyledSettingItemContentHint = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  fontSize: "12px",
  color: theme.customColors.grey[600],
  bottom: "-16px",
}));
