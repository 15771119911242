import React, { useContext } from "react";
import _ from "lodash";
import { IconButton, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
  StyledAddTicketTypeButton,
} from "./OnChainDataSource.styles";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import useGetPagePermission from "hooks/useGetPagePermission";
import { OnChainDataSourceContext } from "./OnChainDataSource.context";
import {
  selectChainNewAddItem,
  selectEntityChainList,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const NFTDisplayPreference = ({ setNftDisplayList, nftDisplayList }) => {
  const chainList = useSelector(selectEntityChainList);
  const newAddItem = useSelector(selectChainNewAddItem);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const updateNftList = (key, value) => {
    const newList = _.cloneDeep(nftDisplayList);
    _.set(newList, key, value);
    setNftDisplayList(newList);
  };

  const handleAddNewNft = (e) => {
    setNftDisplayList([...nftDisplayList, newAddItem]);
  };

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("customization");

  const renderNftSelectList = () => {
    return (
      <>
        <Grid
          container
          spacing={1}
          alignItems="center"
          sx={{ marginBottom: "5px" }}
        >
          <Grid sx={{ color: theme.customColors.grey[700] }} item xs={5}>
            {formatMessage({
              id: "global.filterCriteria.chains",
            })}
          </Grid>
          <Grid sx={{ color: theme.customColors.grey[700] }} item xs={7}>
            {formatMessage({
              id: "global.contract.address",
            })}
          </Grid>
        </Grid>
        <>
          {_.map(nftDisplayList, (nft, i) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                mt={1}
                mb={1}
                key={i}
                position="relative"
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={5}>
                    <CustomDropdown
                      readonly={readonly}
                      border
                      fullWidth
                      size={SIZE.LARGE}
                      placeholder="Select"
                      data={chainList}
                      value={nft.chainId}
                      name={`nftDisplayList[${i}].chainId`}
                      onSelect={(e) => {
                        updateNftList(`[${i}].chainId`, e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={7} style={{ wordBreak: "break-all" }}>
                    <InputField
                      readonly={readonly}
                      name={`nftDisplayList[${i}].address`}
                      size={SIZE.LARGE}
                      placeholder="Enter Contract Address Here"
                      onChange={(e) =>
                        updateNftList(`[${i}].address`, e.target.value)
                      }
                      value={nft.address}
                    />
                  </Grid>
                </Grid>

                {canEdit && (
                  <IconButton
                    size={18}
                    sx={{ position: "absolute", right: "-28px" }}
                    onClick={(e) => {
                      const newList = _.filter(
                        nftDisplayList,
                        (_, index) => index !== i
                      );
                      setNftDisplayList(newList);
                    }}
                  >
                    <i className="meta-crm-icon-ic_trash font-size-14" />
                  </IconButton>
                )}
              </Box>
            );
          })}
        </>
        {canEdit && (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <StyledAddTicketTypeButton onClick={handleAddNewNft}>
                <span className="plus">
                  <i className="meta-crm-icon-ic_add font-size-16" />
                </span>
                {formatMessage({
                  id: "global.btn.add",
                })}
              </StyledAddTicketTypeButton>
            </Grid>
            <Grid item xs={7}>
              <StyledAddTicketTypeButton
                onClick={handleAddNewNft}
              ></StyledAddTicketTypeButton>
            </Grid>
          </Grid>
        )}
      </>
    );
  };
  return (
    <StyledGeneralSettingsContainer>
      <StyledSettingTitle>
        {formatMessage({
          id: "dataSourceSetting.nftDisplay.title",
        })}
      </StyledSettingTitle>
      <StyledDescription>
        {formatMessage({
          id: "dataSourceSetting.nftDisplay.description",
        })}
      </StyledDescription>
      {renderNftSelectList()}
    </StyledGeneralSettingsContainer>
  );
};

export default NFTDisplayPreference;
