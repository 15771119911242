import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Box } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import TicketType from "../components/TicketType";
import RunTest from "../components/RunTest";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useOutletContext } from "react-router";
import _, { get, isEmpty } from "lodash";
import useAssignTicket from "../../hook/useAssignTicket";

export default function General({ data, handleRefetch, setHasChanges }) {
  const initialDomainName = data?.url?.replace(/^https?:\/\//, "") || "";
  const [entityName] = useOutletContext();
  const [domainName, setDomainName] = React.useState(initialDomainName || "");
  const [label, setLabel] = React.useState(data.name);
  const { formatMessage } = useIntl();
  const dispatchSnackbar = useDispatchSnackbar();
  const { updateWidgetConfig } = useFetchWidgetConfig();
  const { entityConfig } = useFetchEntityConfig();
  const hasService = get(entityConfig, "modules.service");

  // 用來保存初始狀態的 Ref
  const firstFetchData = useRef({
    label: data.name,
    domainName: initialDomainName,
  });
  const { ticketTypeList } = useFetchEntityConfig();

  const {
    initData: initTicketTypeData,
    ticketList,
    setTicketList,
    chooseTicketType,
    setChooseTicketType,
    getTicketTypeList,
    hasChanges,
    handleSaveTicket,
  } = useAssignTicket({ ticketTypeList, type: "Widget" });

  const { ticketTypes = [], _id } = data;

  useEffect(() => {
    if (!isEmpty(ticketTypes)) {
      getTicketTypeList(_id);
    } else {
      setTicketList(ticketTypeList);
    }
  }, [ticketTypes]);

  const saveConfig = async () => {
    try {
      await updateWidgetConfig({
        entityName,
        config: {
          name: label,
          url: domainName !== "" ? `https://${domainName}` : "",
          widgetId: get(data, "_id"),
        },
        type: "integration",
      });
      if (hasService) {
        await handleSaveTicket(get(data, "_id"));
      }
      await handleRefetch();
      firstFetchData.current = {
        label: label,
        domainName: domainName,
      };
      setHasChanges(false);
      dispatchSnackbar({
        message: formatMessage({
          id: "widgetSettings.configure.saveSuccess",
        }),
        variant: "success",
      });
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };

  // 處理 Discard 按鈕點擊事件
  const handleDiscard = () => {
    setLabel(firstFetchData.current.label);
    setDomainName(firstFetchData.current.domainName);
    setChooseTicketType(initTicketTypeData);
    setHasChanges(false);
  };

  // 判斷 Save 按鈕是否需要禁用
  const isSaveDisabled = useMemo(() => {
    return (
      label === firstFetchData.current.label &&
      domainName === firstFetchData.current.domainName &&
      !hasChanges
    );
  }, [label, domainName, hasChanges]);

  useEffect(() => {
    setHasChanges(!isSaveDisabled);
  }, [isSaveDisabled, setHasChanges]);

  return (
    <>
      <StyledWhiteContainer sx={{ padding: "32px", margin: "12px 0" }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box color="#555" fontSize="16px">
                Widget Label <span className="required">*</span>{" "}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <InputField
                maxLength={15}
                placeholder="Issue description"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <div className="mhr" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box color="#555" fontSize="16px">
                Domain Name
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <InputField
                placeholder="example.com"
                prefixWord="https://"
                value={domainName}
                onChange={(e) => {
                  setDomainName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledWhiteContainer>
      {hasService && (
        <TicketType
          ticketList={ticketList}
          widgetId={data._id}
          chooseTicketType={chooseTicketType}
          setChooseTicketType={setChooseTicketType}
        />
      )}
      <RunTest
        widgetId={data._id}
        isConnected={data.connected}
        handleRefetch={handleRefetch}
      />
      <StyledWhiteContainer
        style={{ marginBottom: "12px", padding: "16px 32px 24px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {/* Discard 按鈕，僅在存在變更時可點擊 */}
          <Button
            size={SIZE.XL}
            variant={VARIANT.OUTLINED}
            disabled={isSaveDisabled}
            sx={{ width: 120 }}
            onClick={handleDiscard}
          >
            Discard
          </Button>
          {/* Save 按鈕，僅在存在變更時可點擊 */}
          <Button
            disabled={isSaveDisabled}
            size={SIZE.XL}
            sx={{ ml: "12px", width: 120 }}
            onClick={saveConfig}
          >
            {formatMessage({
              id: "global.btn.save",
            })}
          </Button>
        </Box>
      </StyledWhiteContainer>
    </>
  );
}
