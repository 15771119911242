import React, { useState, useEffect } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Switch from "@mui/material/Switch";

import {
  StyledTicketName,
  StyledDiscordTicketSettingContainer,
  StyledColorButtonsContainer,
  StyledColorButtonGroup,
  StyledAddressSwitchContainer,
  StyledColorButton,
} from "./DiscordTicketSettingItem.styles";
import useGetPagePermission from "hooks/useGetPagePermission";

const colors = [
  { discordButtonStyle: 1, color: "#737CFF" },
  { discordButtonStyle: 2, color: "#707070" },
  { discordButtonStyle: 3, color: "#417E4C" },
  { discordButtonStyle: 4, color: "#D93A37" },
];

const DiscordTicketSettingItem = ({
  ticketList,
  chooseTicketType,
  onChangeTicketColor,
  onChangeRequiresAddress,
  serviceName,
}) => {
  const { _id } = ticketList;

  const data = _.find(chooseTicketType, (v) => {
    if (v._id === _id) return v;
  });

  const { discordButtonStyle = 1, addressRequired = false } = data || {};

  const handleChangeColor = (e, newColor) => {
    onChangeTicketColor({ newColor: newColor, ticketId: _id });
  };

  const handleChangeRequiresAddress = (event) => {
    onChangeRequiresAddress({ event: event, ticketId: _id });
  };

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("customization");

  return (
    <>
      {serviceName === "discord" && (
        <div style={{ flex: 0.2 }}>
          <StyledColorButtonsContainer>
            <StyledColorButtonGroup
              value={discordButtonStyle}
              onChange={canEdit ? handleChangeColor : null}
              exclusive
            >
              {colors.map((ticketColor) => (
                <StyledColorButton
                  value={ticketColor.discordButtonStyle}
                  bgColor={ticketColor.color}
                  key={ticketColor.color}
                  readonly={readonly}
                ></StyledColorButton>
              ))}
            </StyledColorButtonGroup>
          </StyledColorButtonsContainer>
        </div>
      )}

      <div style={{ flex: serviceName === "telegram" ? 0.4 : 0.2 }}>
        <StyledAddressSwitchContainer style={{ paddingLeft: 8 }}>
          <Switch
            checked={addressRequired}
            onChange={canEdit ? handleChangeRequiresAddress : null}
            color="secondary"
            size="small"
          />
        </StyledAddressSwitchContainer>
      </div>
    </>
  );
};

export default DiscordTicketSettingItem;
