import { Box } from "@mui/material";
import React from "react";

export default function Percent({ data }) {
  const numericValue = parseFloat(data);
  const isNegative = numericValue < 0;
  const isZero = numericValue === 0;

  if (isZero || data === "-") {
    return (
      <Box fontSize={12} color="#A5A5A5">
        {isZero ? "0" : "-"} %
      </Box>
    );
  }

  return (
    <Box
      className="item-flex"
      gap="1px"
      color={isNegative ? "#FC5555" : "#14C864"}
    >
      <i
        className={`meta-crm-icon-ic_${
          isNegative ? "down" : "up"
        } font-size-12`}
      />
      <Box fontSize="11px" ml="1px">
        {Math.abs(numericValue)}%
      </Box>
    </Box>
  );
}
