import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";

import { apiUrl } from "features/configure";
import axios from "axios";
import { alertServerError } from "features/helpers/utils";
import { useDispatch } from "react-redux";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { enqueueSnackbar } from "features/common/redux/snackbar";
import CustomAssignModal from "components/CustomModals/CustomAssignModal";
const AssignTagsModal = ({ open, onClose, entityName, choose }) => {
  const theme = useTheme();
  const [isAdding, setIsAdding] = useState(false);
  const [tags, setTags] = useState([]);
  const { entityConfig } = useFetchEntityConfig();
  const dispatch = useDispatch();
  const isSelectUser = open == "selected";
  useEffect(async () => {
    setTags([]);
  }, [open]);

  const closeModal = (isRefresh) => {
    setTags([]);
    onClose(isRefresh);
  };

  const handleAssign = async () => {
    try {
      setIsAdding(true);
      const data = { type: "user", tagIds: tags };
      if (isSelectUser) {
        data.targetIds = choose;
      } else {
        data.userSourceId = open;
      }
      const result = await axios.post(
        `${apiUrl}/api/manage/tags/${entityName}/batchApply/user`,
        data
      );

      dispatch(
        enqueueSnackbar({
          message: "Batch assign tags success.",
          options: {
            variant: "info",
          },
        })
      );
      closeModal(true);
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <CustomAssignModal
      maxWidth="500px"
      isLoading={isAdding}
      open={open}
      handleAssign={handleAssign}
      handleClose={() => closeModal(true)}
      tags={tags}
      setTags={setTags}
    />
  );
};

export default AssignTagsModal;
