import { Box } from "@mui/material";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import React, { useState, createContext, useEffect } from "react";

export const SinglePerformanceContext = createContext();

// 提供器组件
export const SinglePerformanceProvider = ({ children }) => {
  const [filterData, setFilterData] = useState({
    userType: "",
    walletBalance: "",
    walletAge: "",
    objectiveAchieved: "",
    tag: "",
    periodStart: null,
    periodEnd: null,
    utmId: "",
  });
  const [filterDetail, setFilterDetail] = useState({
    userType: "",
    walletBalance: "",
    walletAge: "",
    objectiveAchieved: "",
    periodStart: null,
    periodEnd: null,
    tag: "",
  });
  const [update, setUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState({
    chart: false,
    table: false,
  });

  return (
    <Box position={"relative"}>
      {isLoading.chart || isLoading.table ? (
        <LoadingComponent
          width={"50px"}
          height={"50px"}
          isAbsolute
          zIndex={999}
        />
      ) : null}
      <SinglePerformanceContext.Provider
        value={{
          filterDetail,
          setFilterDetail,
          filterData,
          setFilterData,
          update,
          setUpdate,
          isLoading,
          setIsLoading,
        }}
      >
        {children}
      </SinglePerformanceContext.Provider>
    </Box>
  );
};
