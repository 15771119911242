import BasicDialog from "@metacrm/metacrm-material-ui/dist/Dialog";
import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import React, { useEffect, useState } from "react";
import {
  StyledChangelogBox,
  StyledChangelogContent,
  StyledChangelogTag,
} from "./IntegrationVersionInfo.styles";
import { upperCase } from "lodash";
import moment from "moment";
import { formatDate } from "features/helpers/utils";

export default function IntegrationVersionInfo({
  type = "widget",
  handleClose,
}) {
  const [data, setData] = useState([]);
  const dispatchSnackbar = useDispatchSnackbar();
  async function getVersionInfo() {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/integrity/versionInfo/${type}`
      );
      setData(data);
    } catch (err) {
      dispatchSnackbar({ err });
    }
  }

  useEffect(() => {
    getVersionInfo();
  }, []);

  const changelogTypeColor = {
    feature: "#0A9250",
    fixed: "#4576EE",
    changed: "#FF9D2A",
  };

  return (
    <BasicDialog
      open={true}
      onClose={handleClose}
      // onHandleConfirm={handleConfirm}
      dialogRootStyle={{ maxWidth: "800px", width: "100%" }}
      scroll={"paper"}
    >
      <Box
        px="4px"
        paddingBottom="30px"
        sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        <Box
          color="#555"
          fontWeight={900}
          fontSize={24}
          textAlign={"center"}
          px="52px"
        >
          Version Info
        </Box>

        <Box flex={1} overflow={"auto"} px="52px">
          <Box>
            {data.map((item) => (
              <Box key={item.version}>
                <Box
                  my="16px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box color="#000" fontSize={20}>
                    Version {item.version}
                  </Box>
                  <Box color="#A5A5A5" fontSize={14}>
                    {item?.publishedDate
                      ? formatDate(item.publishedDate || Date.now())
                      : "-"}
                  </Box>
                </Box>
                <Box>
                  {item.changelogs.map((changelog, index) => (
                    <StyledChangelogBox key={index}>
                      <StyledChangelogTag
                        bgcolor={changelogTypeColor[changelog.type]}
                      >
                        {upperCase(changelog.type)}
                      </StyledChangelogTag>
                      <StyledChangelogContent>
                        {changelog.content}
                      </StyledChangelogContent>
                    </StyledChangelogBox>
                  ))}
                </Box>
                <Box my="24px" borderBottom="1px solid #E6E6E6"></Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </BasicDialog>
  );
}
