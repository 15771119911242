import React, { useState, useEffect, useMemo } from "react";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "features/configure";

import Box from "@mui/material/Box";
import _ from "lodash";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

import { useOutletContext } from "react-router-dom";
import ImagePreview from "features/metadesk/components/ImagePreview";
import ImageUpload from "features/metadesk/components/ImageUpload";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { get, isEmpty, find, filter } from "lodash-es";

import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";

import {
  StyledDialog,
  StyledDialogContent,
  StyledCloseBtn,
  StyledAddQuestionButton,
  StyledAutoComplete,
  StyleHint,
  StyledInputTitle,
  StyledCustomOutlinedInputContainer,
  StyledTools,
  StyledFormHelperText,
} from "./CreateMacroModal.styles";
import { v4 as uuidv4 } from "uuid";
import ComplexDropdown from "../ComplexDropdown/ComplexDropdown";
import {
  postCategoriesAsyncAction,
  fetchCategoriesAsyncAction,
  editCategoriesAsyncAction,
  deleteCategoriesAsyncAction,
  postMacroAsyncAction,
  putMacroInfoAsyncAction,
  fetchCategoriesAndTagsAsyncAction,
  fetchMacroListAsyncAction,
} from "features/metadesk/redux/macro/macro.action";
import { enqueueSnackbar } from "features/common/redux/actions";
import {
  selectMacroCategories,
  selectMacroTags,
  selectMacroInfo,
  selectMacroIsEditLoading,
} from "features/metadesk/redux/macro/macro.selector";
import {
  parseToOptionFormat,
  parseSingleAnswerToOptionFormat,
} from "./dropdown.utils";
import { TAG_COLORS } from "features/configure";
import { convertToFile } from "features/helpers/utils";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useIntl } from "react-intl";
import TicketTag from "features/metadesk/components/TicketTag";
import useGetPagePermission from "hooks/useGetPagePermission";

const defaultFormFields = {
  questionValue: "",
  answerValue: "",
};

const appliedChannels = [
  { label: "All", isAllOption: true, value: "" },
  { label: "Discord", value: "discord" },
  { label: "Support Center", value: "supportCenter" },
  { label: "Widget", value: "widget" },
];

const CreateMacroModal = ({ open, onClose, editInfo, onSearchValue }) => {
  const dispatch = useDispatch();
  const categoryOptions = useSelector(selectMacroCategories);
  const tagOptions = useSelector(selectMacroTags);
  const macroInfo = useSelector(selectMacroInfo);
  const macroIsEditLoading = useSelector(selectMacroIsEditLoading);
  const macroInfoId = get(macroInfo, "_id");
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [entityName, isCustomDomain] = useOutletContext();
  const [relatedFields, setRelatedFields] = useState([]);
  const { formatMessage } = useIntl();
  const [channelsValue, setChannelsValue] = useState([
    { label: "All", isAllOption: true, value: "" },
  ]);
  const [categoryValue, setCategoryValue] = useState({});
  const [tagValue, setTagValue] = useState([]);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [imgs, setImgs] = useState([]);
  const { questionValue, answerValue } = formFields;

  const theme = useTheme();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultFormFields },
  });

  useEffect(() => {
    if (open) {
      if (editInfo._id) {
        dispatch(
          fetchCategoriesAndTagsAsyncAction({
            entityName: entityName,
            macroId: editInfo._id,
          })
        );
      } else {
        dispatch(fetchCategoriesAndTagsAsyncAction({ entityName: entityName }));
      }
    }
  }, [open]);

  const initModalInfo = () => {
    const {
      _id: macroId,
      answer,
      category,
      channels,
      lastEditor,
      questions,
      tags,
      updated,
    } = macroInfo;

    const mainQuestion = find(questions, { isMain: true });
    const relatedQuestion = filter(questions, { isMain: false });
    const relatedFieldsFormat = relatedQuestion.map((item) => {
      return { id: item._id, questionValue: item.text };
    });
    const imgsFromInfo = get(answer, "image");

    const channelFromInfo = appliedChannels.filter((channel) =>
      channels.includes(channel.value)
    );

    const categoryFromInfo = category
      ? categoryOptions.find((item) => item._id === category._id)
      : null;

    const findTagFromInfo = () => {
      if (!tags) {
        return null;
      }
      const tagValues = tags.map((tag) => tag.value);
      return tagOptions.filter((item) => tagValues.includes(item.value));
    };

    const tagFromInfo = findTagFromInfo();

    setFormFields({
      questionValue: mainQuestion.text,
      answerValue: answer.text,
    });
    reset({ questionValue: mainQuestion.text, answerValue: answer.text });

    setRelatedFields(relatedFieldsFormat);
    setImgs(imgsFromInfo);
    setChannelsValue(
      isEmpty(channelFromInfo)
        ? [{ label: "All", isAllOption: true, value: "" }]
        : channelFromInfo
    );

    setCategoryValue(
      categoryFromInfo ? parseSingleAnswerToOptionFormat(categoryFromInfo) : {}
    );
    setTagValue(tagFromInfo ? parseToOptionFormat(tagFromInfo) : []);
  };

  useEffect(() => {
    if (editInfo._id && !isEmpty(macroInfo)) {
      initModalInfo();
    }
  }, [editInfo._id, macroInfo]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const handleChangeRelatedQuestion = (id, event) => {
    const newInputFields = relatedFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setRelatedFields(newInputFields);
  };

  const handleDeleteRelatedQuestion = (id) => {
    const newInputFields = relatedFields.filter((item) => item.id !== id);
    setRelatedFields(newInputFields);
  };

  const handleAddRelatedQuestion = () => {
    if (relatedFields.length < 5) {
      setRelatedFields([...relatedFields, { id: uuidv4(), questionValue: "" }]);
    }
  };

  const handleAllOptions = (newValue) => {
    const isAllOptionInCurrentValue = _.isArray(channelsValue)
      ? channelsValue.find(({ isAllOption }) => isAllOption)
      : channelsValue;

    const filterNewValue = isAllOptionInCurrentValue
      ? // eslint-disable-next-line no-prototype-builtins
        newValue.filter((item) => !item.hasOwnProperty("isAllOption"))
      : // eslint-disable-next-line no-prototype-builtins
        newValue.filter(
          (item) => item.hasOwnProperty("isAllOption") || item.isAllOption
        );

    setChannelsValue(filterNewValue);
  };

  const handleChangeChannel = (event, newValue) => {
    const isAllOptionInNewValue = newValue.find(
      ({ isAllOption }) => isAllOption
    );

    const existChannel = appliedChannels
      .filter((item) => item.label !== "All")
      .map((item) => item.value);
    const set = new Set(newValue.map((item) => item.value));
    if (existChannel.every((item) => set.has(item))) {
      setChannelsValue([{ label: "All", isAllOption: true, value: "" }]);
      return;
    }
    if (isAllOptionInNewValue) {
      handleAllOptions(newValue);
      return;
    }

    setChannelsValue(newValue);
  };

  const renderChannelAnswer = (selectedValue) => {
    return selectedValue.map((item) => item.label)?.join(", ");
  };

  const handleNewCategory = (value) => {
    dispatch(
      postCategoriesAsyncAction({
        entityName: entityName,
        name: value,
        cb: handleChangeCategory,
      })
    );
  };

  const handleEditCategory = ({ value, cb, target }) => {
    dispatch(
      editCategoriesAsyncAction({
        entityName: entityName,
        id: target.option._id,
        name: value,
        cb: handleChangeCategory,
      })
    );
    if (cb) {
      cb();
    }
  };

  const handleDeleteCategoryOption = ({ target, cb }) => {
    dispatch(
      deleteCategoriesAsyncAction({
        entityName: entityName,
        id: target.option._id,
        selectedValue: categoryValue,
        cb: handleChangeCategory,
      })
    );

    if (cb) {
      cb();
    }
  };

  const handleChangeCategory = (value) => {
    setCategoryValue(value);
  };

  function handleChooseTag(value) {
    setTagValue(value);
  }

  const toggleCategoryDropdown = () => {
    setCategoryDropdownOpen(!categoryDropdownOpen);
  };

  const handleCloseCategoryDropdown = () => {
    setCategoryDropdownOpen(false);
  };

  const handleClear = () => {
    setFormFields(defaultFormFields);
    setRelatedFields([{ id: uuidv4(), questionValue: "" }]);
    setChannelsValue([{ label: "All", isAllOption: true, value: "" }]);
    setCategoryValue({});
    setTagValue([]);
    setImgs([]);
  };

  const handleClose = () => {
    handleClear();
    reset(defaultFormFields);
    onClose();
    dispatch(fetchCategoriesAsyncAction({ entityName }));
  };

  const handleEditClose = () => {
    handleClose();
    dispatch(
      fetchMacroListAsyncAction({
        entityName: entityName,
        category: get(editInfo, "nowTabCategory"),
        search: onSearchValue,
      })
    );
  };

  const sendInfo = (data) => {
    const relatedQuestions = relatedFields
      .filter((question) => question.questionValue)
      .map((question) => {
        return { text: question.questionValue, isMain: false };
      });

    const sendData = {
      questions: [
        { text: data.questionValue, isMain: true },
        ...relatedQuestions,
      ],
      channels: channelsValue.map((channel) => channel.value),
      tags: tagValue.map((tag) => tag._id),
      category: get(categoryValue, "_id", ""),
      answer: { text: data.answerValue, image: imgs },
    };

    editInfo._id
      ? dispatch(
          putMacroInfoAsyncAction({
            entityName: entityName,
            sendData: sendData,
            id: editInfo._id,
            cb: handleEditClose,
          })
        )
      : dispatch(
          postMacroAsyncAction({
            entityName: entityName,
            sendData: sendData,
            cb: handleClose,
          })
        );
  };

  const handleSubmitInfo = () => {
    handleSubmit(sendInfo)();
  };

  const handleUploadImgs = async (list) => {
    try {
      const uploadFiles = [];
      let postFormData = new FormData();
      for (let item of list) {
        const file = await convertToFile(
          _.get(item, "data_url"),
          _.get(item, "file.name")
        );
        if (file) {
          postFormData.append("macro", file);
          uploadFiles.push(item);
        }
      }
      postFormData.append("entityName", entityName);

      const {
        data: { macro },
      } = await axios.post(`${apiUrl}/api/gcp/uploadMacro`, postFormData);
      setImgs(macro);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const pagePermission = useGetPagePermission();
  const ticketPermission = pagePermission("tickets");

  return (
    <StyledDialog scroll="body" open={open}>
      <StyledCloseBtn
        size={24}
        color={theme.customColors.grey[700]}
        onClick={handleClose}
      >
        <i className="meta-crm-icon-ic_cancel" />
      </StyledCloseBtn>

      <StyledDialogContent>
        {macroIsEditLoading ? (
          <Box flex="1">
            <LoadingComponent />
          </Box>
        ) : (
          <Box>
            <Box
              fontSize="18px"
              fontWeight="700"
              mb="12px"
              color={theme.customColors.grey[800]}
            >
              {formatMessage({ id: "macrosSetting.macrosCreate.title" })}
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputField
                  name="questionValue"
                  onChange={(e) => handleChange(e)}
                  value={questionValue}
                  variant="outlined"
                  size={SIZE.XL}
                  startAdornment={<span>Q:</span>}
                  isRequired
                  title={formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionTitle",
                  })}
                  placeholder={formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionPlaceholder",
                  })}
                  control={control}
                  errors={get(errorsInfo, "questionValue.message")}
                  maxLength={60}
                  errorTextAbsolute={false}
                  rules={{
                    required: {
                      value: true,
                      message: formatMessage({
                        id: "macrosSetting.macrosCreate.addQuestionRequiredError",
                      }),
                    },
                    minLength: {
                      value: 5,
                      message: formatMessage({
                        id: "macrosSetting.macrosCreate.addQuestionMinError",
                      }),
                    },
                  }}
                />
              </Grid>

              {relatedFields.map((relatedField) => (
                <Grid item xs={12} key={relatedField.id}>
                  <InputField
                    name="questionValue"
                    onChange={(e) =>
                      handleChangeRelatedQuestion(relatedField.id, e)
                    }
                    value={relatedField.questionValue}
                    variant="outlined"
                    size={SIZE.XL}
                    startAdornment={
                      relatedField.questionValue ? <span>Q:</span> : ""
                    }
                    placeholder="e.g. What's your token's address?"
                    endAdornment={
                      <IconButton
                        size={18}
                        onClick={(e) =>
                          handleDeleteRelatedQuestion(relatedField.id)
                        }
                      >
                        <i className="meta-crm-icon-ic_trash font-size-16" />
                      </IconButton>
                    }
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <StyledAddQuestionButton onClick={handleAddRelatedQuestion}>
                  <span className="plus">
                    <i className="meta-crm-icon-ic_add font-size-16" />
                  </span>
                  {formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionBtn",
                  })}
                </StyledAddQuestionButton>
              </Grid>
            </Grid>

            <Box mb="16px">
              <StyledInputTitle>
                {formatMessage({
                  id: "macrosSetting.macrosCreate.answerQuestionTitle",
                })}
                <span className="required">* </span>
              </StyledInputTitle>
              <StyledCustomOutlinedInputContainer
                error={get(errorsInfo, "answerValue")}
              >
                <InputField
                  name="answerValue"
                  fullWidth
                  multiline
                  rows={3}
                  value={answerValue}
                  placeholder={formatMessage({
                    id: "macrosSetting.macrosCreate.answerQuestionPlaceholder",
                  })}
                  onChange={(e) => handleChange(e)}
                  control={control}
                  maxLength={1000}
                  rules={{
                    required: {
                      value: true,
                      message: formatMessage({
                        id: "macrosSetting.macrosCreate.answerQuestionRequiredError",
                      }),
                    },
                    minLength: {
                      value: 15,
                      message: formatMessage({
                        id: "macrosSetting.macrosCreate.answerQuestionMinError",
                      }),
                    },
                  }}
                />
                {!_.isEmpty(imgs) && (
                  <ImagePreview imgs={imgs} setImgs={setImgs} />
                )}

                <StyledTools>
                  <ImageUpload
                    value={imgs}
                    size={16}
                    color={theme.customColors.grey[500]}
                    onChange={(list) => {
                      handleUploadImgs(list);
                    }}
                  />
                </StyledTools>
              </StyledCustomOutlinedInputContainer>
              {get(errorsInfo, "answerValue.message") && (
                <StyledFormHelperText>
                  {get(errorsInfo, "answerValue.message")}
                </StyledFormHelperText>
              )}
            </Box>

            <Box mb="16px">
              <StyledInputTitle>
                {formatMessage({
                  id: "macrosSetting.macrosCreate.appliedChannels",
                })}
              </StyledInputTitle>
              <StyledAutoComplete
                disableClearable
                multiple
                disableCloseOnSelect
                options={appliedChannels}
                getOptionLabel={(option) => option.label}
                onChange={handleChangeChannel}
                value={channelsValue}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
                renderTags={renderChannelAnswer}
              ></StyledAutoComplete>
              <StyleHint>
                {formatMessage({
                  id: "macrosSetting.macrosCreate.appliedChannelsHint",
                })}
              </StyleHint>
            </Box>

            <Box sx={{ marginBottom: "30px" }}>
              <StyledInputTitle>
                {formatMessage({
                  id: "macrosSetting.macrosCreate.questionCategory",
                })}
                <IconTooltip
                  content={formatMessage({
                    id: "macrosSetting.macroCreate.questionTooltip",
                  })}
                />
              </StyledInputTitle>
              <ComplexDropdown
                onClick={toggleCategoryDropdown}
                onClose={handleCloseCategoryDropdown}
                onOpen={categoryDropdownOpen}
                onEnter={handleNewCategory}
                dropdownOptions={parseToOptionFormat(categoryOptions)}
                onEditOptionItem={({ value, cb, target }) =>
                  handleEditCategory({ value, cb, target })
                }
                onDeleteItem={handleDeleteCategoryOption}
                onChange={(value) => handleChangeCategory(value)}
                onValue={categoryValue}
                multiple={false}
                selectAreaHeight={180}
                placeholder={formatMessage({
                  id: "macrosSetting.macrosCreate.questionCategoryPlaceholder",
                })}
              ></ComplexDropdown>
            </Box>
            {ticketPermission.show && (
              <Box>
                <StyledInputTitle>
                  {formatMessage({
                    id: "macrosSetting.macrosCreate.questionTag",
                  })}
                  <IconTooltip
                    content={formatMessage({
                      id: "macrosSetting.macrosCreate.questionTagTooltip",
                    })}
                  />
                </StyledInputTitle>
                <TicketTag
                  canEdit={ticketPermission.canEdit}
                  tags={tagValue}
                  handleChooseTag={handleChooseTag}
                  placeholder={formatMessage({
                    id: "macrosSetting.macrosCreate.questionTagPlaceholder",
                  })}
                />
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end" marginTop="40px">
              <Button
                sx={{ width: "120px" }}
                color={COLOR.SECONDARY}
                variant={VARIANT.TEXT}
                onClick={handleClose}
              >
                {formatMessage({ id: "global.btn.cancel" })}
              </Button>
              <Button
                sx={{ width: "120px", marginLeft: "8px" }}
                color={COLOR.SECONDARY}
                disabled={!isValid}
                onClick={handleSubmitInfo}
              >
                {editInfo._id
                  ? formatMessage({ id: "global.btn.save" })
                  : formatMessage({ id: "global.btn.create" })}
              </Button>
            </Box>
          </Box>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CreateMacroModal;
