import React, { useState, useEffect, useRef, useContext } from "react";
import _ from "lodash";

import { StyledImportPopoverErrorText } from "features/metadesk/components/ImportList/ImportDataPopover.styles";
import ImportContractSelect from "features/metadesk/components/ImportList/ImportContractSelect";
import {
  StyledAddTicketTypeButton,
  StyledTokensContainer,
} from "./OnChainDataSource.styles";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import BasicModal from "features/metadesk/chat/BasicModal";
import ContractLabelEditor from "features/metadesk/components/ContractLabelEditor";
import { Grid } from "@mui/material";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { getShortAddress, getHexImg } from "features/helpers/utils";
import useGetPagePermission from "hooks/useGetPagePermission";
import { OnChainDataSourceContext } from "./OnChainDataSource.context";

const PortfolioTokens = ({
  entityName,
  setPortfolioTokensData,
  portfolioTokensData,
}) => {
  const { chainList } = useContext(OnChainDataSourceContext);
  const [openAddToken, setOpenAddToken] = useState(false);
  const [error, setError] = useState("");
  const [uploadData, setUploadData] = useState({});
  const [isUploading, setIsUploading] = useState("");
  const tokens = _.get(portfolioTokensData, "tokens", []);
  const excludeChains = _.map(tokens, "chainId");
  const remainedChains =
    excludeChains.length > 0
      ? _.filter(chainList, (obj) => {
          return !excludeChains.includes(obj.value);
        })
      : chainList;

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  return (
    <div>
      <BasicModal
        open={openAddToken}
        textAlign="left"
        confirmDisabled={!uploadData.contract}
        onClose={() => {
          setUploadData({});
          setOpenAddToken(false);
        }}
        modalTitle={"Add New Token"}
        modalContent={"Query the contract you want from the blockchain."}
        cancelText={"Cancel"}
        confirmText={"Create"}
        mode="action"
        onHandleConfirm={() => {
          const newData = { ...portfolioTokensData };
          if (!newData.tokens) newData.tokens = [];
          if (tokens.length == 0) {
            newData.name = _.get(uploadData, "contract.name");
            newData.icon = _.get(uploadData, "contract.icon");
          }
          newData.tokens.push(uploadData.contract);
          setPortfolioTokensData(newData);
          setUploadData({});
          setOpenAddToken(false);
        }}
        children={
          <div style={{ paddingBottom: 40, width: 440 }}>
            <ImportContractSelect
              hideTypeSelect
              background={"#F7F1FE"}
              excludeChains={_.map(tokens, "chainId")}
              entityName={entityName}
              editable={tokens.length == 0}
              setError={setError}
              setIsUploading={setIsUploading}
              uploadData={uploadData}
              setUploadData={setUploadData}
              onSelect={(contractData) => {
                setUploadData({
                  contract:
                    tokens.length == 0
                      ? contractData
                      : {
                          ...contractData,
                          name: portfolioTokensData.name,
                          icon: portfolioTokensData.icon,
                        },
                });
              }}
            />
            {tokens.length > 0 && uploadData.contract && (
              <div className="startRow">
                <i
                  className="meta-crm-icon-ic_info font-size-14 mgr3"
                  style={{ color: "#7B61FF" }}
                />
                Only first one can edit here.
              </div>
            )}
            {error && (
              <StyledImportPopoverErrorText>
                {error}
              </StyledImportPopoverErrorText>
            )}
          </div>
        }
      />
      {tokens.length == 0 ? (
        <>
          {canEdit && (
            <StyledAddTicketTypeButton onClick={() => setOpenAddToken(true)}>
              <span className="plus">
                <i className="meta-crm-icon-ic_add font-size-16" />
              </span>
              Add New Token
            </StyledAddTicketTypeButton>
          )}
        </>
      ) : (
        <StyledTokensContainer>
          <div style={{ marginBottom: 8 }}>Name</div>
          {readonly ? (
            <>{portfolioTokensData.name}</>
          ) : (
            <ContractLabelEditor
              data={portfolioTokensData}
              editable={true}
              updateData={({ editName, images }) => {
                const newData = { ...portfolioTokensData };
                if (editName) newData.name = editName;
                if (images) newData.icon = images[0];
                setPortfolioTokensData(newData);
              }}
            />
          )}
          <div className="hr" style={{ margin: "12px 0" }}></div>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item xs={6}>
              Token
            </Grid>
            <Grid item xs={6}>
              Chain
            </Grid>
            {_.map(tokens, (token, i) => {
              return (
                <React.Fragment key={i}>
                  <Grid item xs={6} className="startRow">
                    <img
                      className="smIcon"
                      style={{ opacity: 0.3, borderRadius: 40 }}
                      src={
                        _.get(token, "icon") ||
                        getHexImg(_.get(token, "address"))
                      }
                    />
                    <div style={{ color: "#E6E6E6", fontSize: 12 }}>
                      {token.name}
                      <div style={{ color: "#777" }}>
                        {getShortAddress(token.address)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={canEdit ? 5 : 6}>
                    {_.find(chainList, { value: token.chainId })?.label}
                  </Grid>
                  {canEdit && (
                    <Grid item xs={1}>
                      <IconButton
                        size={18}
                        onClick={() => {
                          const newList = _.filter(
                            tokens,
                            (_, index) => index !== i
                          );
                          if (newList.length == 0) {
                            setPortfolioTokensData({});
                          } else {
                            setPortfolioTokensData({
                              ...portfolioTokensData,
                              tokens: newList,
                            });
                          }
                        }}
                      >
                        <i className="meta-crm-icon-ic_trash font-size-32" />
                      </IconButton>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            {canEdit && !_.isEmpty(remainedChains) && (
              <>
                <Grid item xs={6}>
                  -
                </Grid>
                <Grid item xs={6}>
                  <StyledAddTicketTypeButton
                    onClick={() => setOpenAddToken(true)}
                    style={{ background: "#F8F8F8" }}
                  >
                    <span className="plus">
                      <i className="meta-crm-icon-ic_add font-size-16" />
                    </span>
                    Add Chain
                  </StyledAddTicketTypeButton>
                </Grid>
              </>
            )}
          </Grid>
        </StyledTokensContainer>
      )}
    </div>
  );
};

export default PortfolioTokens;
