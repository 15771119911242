import React, { useEffect, useState, useCallback } from "react";
import NoWorkspace from "./NoWorkspace";
import HasWorkspace from "./HasWorkspace";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useAccount, useDisconnect } from "wagmi";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useOnboardingState } from "../OnboardingProvider";
import { Box } from "@mui/material";

export default function WorkSpace() {
  const { prevStep } = useOnboardingState();
  const [workSpace, setWorkSpace] = useState([]);
  const [loading, setLoading] = useState(true);
  const { address: evmAddress, isConnected } = useAccount({});
  const sendDataToGTM = useGTMDispatch();

  const trackEvent = useCallback(
    (eventName, eventData = {}) => {
      try {
        sendDataToGTM({
          event: eventName,
          ...eventData,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Failed to send GTM event:", error);
      }
    },
    [sendDataToGTM]
  );

  useEffect(() => {
    trackEvent("onboarding_workspace_page_view");
  }, [trackEvent]);

  useEffect(() => {
    async function getEntityList() {
      try {
        setLoading(true);
        const { data } = await axios.get(`${apiUrl}/api/workspace`, {
          params: { address: evmAddress },
        });
        setWorkSpace(data.entities);
      } catch (error) {
        trackEvent("onboarding_workspace_load_error", {
          error_message: error.message,
        });
      } finally {
        setLoading(false);
      }
    }
    if (evmAddress) {
      getEntityList();
    }
  }, [evmAddress, trackEvent]);

  useEffect(() => {
    if (workSpace.length > 0) {
      trackEvent("onboarding_workspace_exists", {
        workspace_count: workSpace.length,
      });
    } else if (!loading) {
      trackEvent("onboarding_no_workspace");
    }
  }, [workSpace, loading, trackEvent]);

  const { disconnect } = useDisconnect();

  const handleDisconnect = useCallback(async () => {
    trackEvent("onboarding_wallet_disconnect_click");
    try {
      await disconnect();
      trackEvent("onboarding_wallet_disconnected");
      prevStep();
    } catch (error) {
      trackEvent("onboarding_wallet_disconnect_error", {
        error_message: error.message,
      });
    }
  }, [disconnect, prevStep, trackEvent]);

  useEffect(() => {
    if (!isConnected) {
      trackEvent("onboarding_wallet_connection_lost");
      prevStep();
    }
  }, [isConnected, prevStep, trackEvent]);

  return (
    <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Box
            onClick={handleDisconnect}
            sx={{
              position: "absolute",
              top: 24,
              left: 24,
              zIndex: 10,
              color: "#A5A5A5",
              cursor: "pointer",
            }}
          >
            <i className="meta-crm-icon-ic_arrowBackward font-size-36" />
          </Box>

          {workSpace.length > 0 ? (
            <HasWorkspace workSpace={workSpace} />
          ) : (
            <NoWorkspace prevStep={prevStep} />
          )}
        </>
      )}
    </Box>
  );
}
