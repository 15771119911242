import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { get } from "lodash-es";
import { useCallback, useContext } from "react";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import _ from "lodash";
import { TrackingOptionsContext } from "./OptionsProvider";
import { CampaignCreationContext } from "../../context/CampaignCreationProvider";
export default function useCampaignOptions() {
  const { options, setOptions } = useContext(TrackingOptionsContext);
  const { stepData } = useContext(CampaignCreationContext);
  const linkSettings = _.get(
    stepData,
    "campaignContentSets[0].customLinkSettings",
    []
  );
  const [entityName] = useOutletContext();
  const optionUrl = `${apiUrl}/api/campaign/${entityName}/option`;
  const dispatchSnackbar = useDispatchSnackbar();
  const ecosystemName = useSelector(selectEcosystemName);

  const checkValueIsSelected = ({ type, value, id }) => {
    for (const setting of linkSettings) {
      if (setting[type]) {
        const items = setting[type];
        for (const item of items) {
          if (value && value == item.value) return true;
          if (id && id == item._id) return true;
        }
      }
    }
    return false;
  };

  const fetchOptions = async () => {
    try {
      const { data } = await axios.get(optionUrl);
      setOptions(data);
    } catch (err) {
      dispatchSnackbar({
        message: get(err, "response.data.error", "error"),
      });
    }
  };

  const defaultSourceArray = [
    {
      value: "X",
      img: "X.svg",
    },
    {
      value: "Galxe",
      img: "Galxe.svg",
    },
    ...(ecosystemName === "EVM"
      ? [
          {
            value: "QusetN",
            img: "QusetN.svg",
          },
        ]
      : []),
    {
      value: "Zealy",
      img: "Zealy.svg",
    },
    {
      value: "Discord",
      img: "Discord.svg",
    },
    {
      value: "Telegram",
      img: "Telegram.svg",
    },

    {
      value: "Google Ads",
      img: "GoogleAds.svg",
    },
    {
      value: "Youtube",
      img: "Youtube.svg",
    },
    {
      value: "Meta",
      img: "Meta.svg",
    },
    {
      value: "Instagram",
      img: "Instagram.svg",
    },
    {
      value: "LinkedIn",
      img: "LinkedIn.svg",
    },
    {
      value: "TikTok",
      img: "TikTok.svg",
    },
  ];

  const defaultSource = _.map(defaultSourceArray, "value");

  const MappingType = {
    sources: "source",
    mediums: "medium",
    terms: "term",
    contents: "content",
  };

  async function addOption(type) {
    try {
      const randomNum = Math.floor(Math.random() * 1000);
      let value = MappingType[type] + randomNum;
      if (options[type].find((item) => item.value === value)) {
        let newRandomNum = Math.floor(Math.random() * 1000);
        while (options[type].find((item) => item.value === value)) {
          newRandomNum = Math.floor(Math.random() * 1000);
          value = MappingType[type] + newRandomNum;
        }
      }
      const { data } = await axios.post(optionUrl, {
        type: MappingType[type],
        value,
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        [type]: [...prevOptions[type], data],
      }));
    } catch (error) {
      dispatchSnackbar({
        message: get(error, "response.data.error", "error"),
      });
    }
  }

  async function updateOption(type, id, value, oldValue) {
    try {
      if (type === "sources" && defaultSource.includes(value))
        throw "source cannot have the same value with default source";
      if (checkValueIsSelected({ type, id })) {
        throw "Selected options couldn’t be removed. Update the selection and try removing them again.";
      }
      await axios.put(optionUrl + "/" + id, { value });

      setOptions((prevOptions) => ({
        ...prevOptions,
        [type]: prevOptions[type].map((item) =>
          item._id === id ? { ...item, value } : item
        ),
      }));
    } catch (error) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        [type]: prevOptions[type].map((item) =>
          item._id === id ? { ...item, value: oldValue } : item
        ),
      }));
      if (typeof error === "string")
        return dispatchSnackbar({ message: error });
      dispatchSnackbar({
        message: get(error, "response.data.error", "error"),
      });
    }
  }

  async function deleteOption(type, id) {
    try {
      if (checkValueIsSelected({ type, id })) {
        dispatchSnackbar({
          message:
            "Selected options couldn’t be removed. Update the selection and try removing them again.",
        });
        return;
      }
      const temp = _.cloneDeep(options[type]);
      const result = temp.filter((item) => item._id !== id);
      await axios.delete(optionUrl + "/" + id);
      setOptions((prevOptions) => ({
        ...prevOptions,
        [type]: result,
      }));
    } catch (error) {
      dispatchSnackbar({
        message: get(error, "response.data.error", "error"),
      });
    }
  }

  return {
    addOption,
    updateOption,
    deleteOption,
    defaultSourceArray,
    fetchOptions,
  };
}
