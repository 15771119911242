import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  InputField,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/InputField";

export const StyledDraggableTicketRow = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  marginBottom: "8px",
}));

export const StyledDraggableTicketBox = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isError }) => ({
  display: "flex",
  background: theme.customColors.white,
  border: `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: "8px",
  width: "500px",
  alignItems: "center",
  color: theme.customColors.grey[700],
  padding: "9px 14px",
  height: "40px",
  marginRight: "8px",
  ...(isError && {
    background: theme.customColors.pink[100],
    border: `1px solid ${theme.customColors.pink[600]}`,
  }),
}));

export const StyledDraggableTicketBoxIcon = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginRight: "12px",
  color: theme.customColors.grey[500],
  display: "flex",
  alignItems: "center",
}));

export const StyledInputField = styled(InputField, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingLeft: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `0 !important`,
    boxShadow: "none !important",
  },
}));

export const StyledDraggableTicketBoxText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledDeleteIcon = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledAddTicketTypeButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "500px",
  cursor: "pointer",
  backgroundColor: "transparent",
  border: `1px dashed ${theme.customColors.grey[300]}`,
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "8px",
  color: theme.customColors.grey[500],
  height: "40px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
  "& .plus": {
    marginRight: "15px",
    fontWeight: 900,
    display: "flex",
  },
}));
