import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import _ from "lodash";
import { Box, Grid } from "@mui/material";
import { Button, COLOR, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { get } from "lodash";
import ImageUpload from "features/metadesk/components/ImageUpload";
import ImagePreview from "features/metadesk/components/ImagePreview";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import {
  StyledContainerTitle,
  StyledInputContainer,
  StyledWhiteCardContainer,
  StyledTitleIcon,
} from "./CampaignCreation.styles";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import { apiUrl } from "features/configure";
import axios from "axios";
import { useFormContext } from "react-hook-form";
import EditNotificationPreviewNew from "features/metadesk/campaign/campaignEdit/EditNotificationPreviewNew";

import { useConfirm } from "features/home/ConfirmDialogProvider";

import IntergrationCover from "./IntergrationCover";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import CustomStepper from "components/CustomStepper/CustomStepper";
import CallToAction from "./CallToAction";

const CampaignContentPreview = ({ tabIndex, step, stepName }) => {
  const { notificationId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const confirm = useConfirm();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const ecosystemName = useSelector(selectEcosystemName);
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo },
  } = useFormContext();

  const {
    stepData,
    setStepValues,
    isXmtpConnected,
    isPushConnected,
    isWidgetActive,
    isTrackingActive,
  } = useContext(CampaignCreationContext);
  const { campaignContentSets } = stepData;

  const goal = _.get(campaignContentSets, `${tabIndex}.goal`);
  const selectedType = _.get(campaignContentSets, `${tabIndex}.type`);

  const handleFieldChange = ({ event, fieldName }) => {
    const { value } = event.target;
    const temp = [...campaignContentSets];
    temp[tabIndex] = { ...temp[tabIndex], [fieldName]: value };
    setStepValues({ campaignContentSets: temp });
  };

  const setImgs = (value) => {
    handleFieldChange({
      fieldName: "engagementImages",
      event: { target: { value } },
    });
  };

  const handleUploadImg = async (list) => {
    setImgs(list);
    await uploadFile(list[0].file);
  };

  useEffect(() => {
    setImgs(_.get(campaignContentSets[tabIndex], "engagementImages", []));
  }, [_.get(campaignContentSets[tabIndex], "engagementImages", [])]);

  const uploadFile = async (file) => {
    if (file) {
      let imgFormData = new FormData();

      imgFormData.append("campaign", file);
      imgFormData.append("entityName", entityName);
      try {
        const {
          data: { campaign },
        } = await axios.post(`${apiUrl}/api/gcp/uploadCampaign`, imgFormData);
        setImgs([campaign[0]]);
        const temp = [...campaignContentSets];
        temp[tabIndex] = {
          ...temp[tabIndex],
          engagementImages: [campaign[0]],
        };
        setStepValues({ campaignContentSets: temp });
      } catch (error) {
        setImgs([]);
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
      }
    }
  };

  const renderPreviewField = () => {
    return (
      <EditNotificationPreviewNew
        data={campaignContentSets[tabIndex]}
        imgs={campaignContentSets[tabIndex].engagementImages}
        startFrom={stepData.startFrom}
        minHeight={"100%"}
      ></EditNotificationPreviewNew>
    );
  };

  const renderCreateField = () => {
    return (
      <StyledWhiteCardContainer>
        <StyledContainerTitle>
          <StyledTitleIcon>
            <i className="meta-crm-icon-ic_image font-size-18" />
          </StyledTitleIcon>
          {formatMessage({
            id: "campaignBuild.secondStep.webpush.content.title",
          })}
        </StyledContainerTitle>
        <div className="mhr" style={{ margin: 0 }}></div>
        <StyledInputContainer>
          <div className="formField">
            <InputField
              maxLength={50}
              name={`campaignContentSets[${tabIndex}].engagementTitle`}
              control={control}
              title={
                <div className="formHeader">
                  {formatMessage({ id: "global.column.title" })}{" "}
                  <span className="required">*</span>
                </div>
              }
              errors={get(
                errorsInfo,
                `campaignContentSets.[${[tabIndex]}].engagementTitle.message`
              )}
              rules={{
                required: {
                  value: true,
                  message: `Title ${formatMessage({
                    id: "global.error.required",
                  })}`,
                },
                minLength: {
                  value: 5,
                  message: formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionMinError",
                  }),
                },
                maxLength: {
                  value: 50,
                  message: formatMessage({
                    id: "macrosSetting.macrosCreate.addQuestionMinError",
                  }),
                },
              }}
              onChange={(event) =>
                handleFieldChange({
                  event,
                  fieldName: "engagementTitle",
                })
              }
              value={_.get(
                campaignContentSets[tabIndex],
                "engagementTitle",
                ""
              )}
              placeholder={formatMessage({ id: "global.column.title" })}
            />
          </div>
          <div className="formField">
            <InputField
              title={
                <div className="formHeader">
                  {formatMessage({ id: "global.column.description" })}{" "}
                  <span className="required">*</span>
                </div>
              }
              name={`campaignContentSets[${tabIndex}].engagementDescription`}
              control={control}
              maxLength={1500}
              errors={get(
                errorsInfo,
                `campaignContentSets.[${[
                  tabIndex,
                ]}].engagementDescription.message`
              )}
              rules={{
                required: {
                  value: true,
                  message: "Description is Required",
                },
              }}
              minRows={8}
              multiline={true}
              InputProps={{
                rows: 8,
                multiline: true,
                inputComponent: "textarea",
              }}
              onChange={(event) =>
                handleFieldChange({
                  event,
                  fieldName: "engagementDescription",
                })
              }
              value={_.get(
                campaignContentSets[tabIndex],
                "engagementDescription",
                ""
              )}
              placeholder={formatMessage({ id: "global.column.description" })}
            />
          </div>
          <div className="formField">
            <div className="formHeader">
              {formatMessage({ id: "uploader.instruction.image" })}
            </div>
            <div
              style={{
                borderRadius: 3,
                border: "1px dashed #E6E6E6",
              }}
            >
              <div style={{ margin: 35 }} className="column">
                {campaignContentSets[tabIndex].engagementImages.length > 0 ? (
                  <ImagePreview
                    imgs={campaignContentSets[tabIndex].engagementImages}
                    setImgs={setImgs}
                    size={200}
                  />
                ) : (
                  <>
                    <i
                      className="meta-crm-icon-ic_uploadImage font-size-72"
                      style={{
                        color: "#E6E6E6",
                        marginBottom: 12,
                      }}
                    />
                    <ImageUpload
                      multiple={false}
                      acceptType={["png", "jpg", "heic", "jpeg"]}
                      uploadComponent={
                        <Button color={COLOR.SECONDARY}>
                          {formatMessage({ id: "global.btn.uploadImage" })}
                        </Button>
                      }
                      value={campaignContentSets[tabIndex].engagementImages}
                      onChange={handleUploadImg}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </StyledInputContainer>
      </StyledWhiteCardContainer>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flex: 1, flexGrow: 1 }}>
        <Box sx={{ position: "relative" }}>
          {/* <Loading open={loading} white={true} fullScreen={false} /> */}
          <Box display={"flex"}>
            <Box flex={1}>
              <StyledWhiteCardContainer
                display={"flex"}
                justifyContent={"center"}
                sx={{ paddingBottom: "20px", paddingTop: "42px" }}
              >
                <Box width="100%">
                  <CustomStepper
                    steps={stepName}
                    activeStep={step - 1}
                  ></CustomStepper>
                </Box>
              </StyledWhiteCardContainer>
              <Box sx={{ position: "relative" }}>
                <IntergrationCover
                  show={
                    (selectedType === "widget" && !isWidgetActive) ||
                    (selectedType === "flyer" &&
                      !(isPushConnected || isXmtpConnected))
                  }
                  type={selectedType}
                />
                <Box mb="20px"></Box>
                {renderCreateField()}
                <Box mb="20px"></Box>
                {/* {renderScheduleField()} */}
              </Box>

              <CallToAction step={step} tabIndex={tabIndex}></CallToAction>
            </Box>
            <Box width={"380px"}>{renderPreviewField()}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignContentPreview;
