import React, { useEffect, useRef, useState } from "react";
import Back from "../components/Back";
import { StyledWhiteContainer } from "../settings/common.styles";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Box } from "@mui/material";
import Button, { AddButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import FixedButton from "../settings/common/FixedButton";
import { useOutletContext, useParams } from "react-router";
import { isEqual } from "lodash";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import useNavigateEntity from "hooks/useNavigateEntity";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { Loading } from "features/common";
import { useForm } from "react-hook-form";

const initData = {
  name: "",
  description: "",
  links: [{ postLink: "", isNew: true }],
};

export default function EditXCampaign() {
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const [data, setData] = useState(initData);
  const initDataRef = useRef(initData);
  const [isLoading, setIsLoading] = useState(false);
  const { campaignId } = useParams();
  const { navigateEntity } = useNavigateEntity();
  const dispatchSnackbar = useDispatchSnackbar();
  const confirm = useConfirm();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: initData,
  });

  const handleChange = (event) => {
    setData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleChangeLink = (e, index) => {
    const { name, value } = e.target;
    const { links } = data;
    links[index] = { ...links[index], postLink: value };

    setData((prev) => ({
      ...prev,
      links,
    }));
    if (!value.includes("x.com")) {
      setError(`links${index}`, {
        type: "manual",
        message:
          formatMessage({
            id: "global.inputCheck.invalidUrl",
          }) + "on X",
      });
    } else {
      clearErrors(`links${index}`);
    }
  };

  const handleDeleteLink = (index) => {
    const { links } = data;
    links.splice(index, 1);

    setData((prev) => ({
      ...prev,
      links,
    }));
  };

  const handleGetSingleCampaign = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${apiUrl}/api/x/${entityName}/campaign/${campaignId}`
      );
      const settingData = {
        name: data.name,
        description: data.description,
        links: data.postLinks.map((link) => ({
          postLink: link,
          isNew: false,
        })),
      };

      setData(settingData);
      initDataRef.current = settingData;
      reset(settingData);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (campaignId) {
      handleGetSingleCampaign();
    }
  }, [campaignId]);

  const handleUpdateCampaign = async () => {
    try {
      setIsLoading(true);
      const { name, description, links } = data;
      await axios.put(`${apiUrl}/api/x/${entityName}/campaign/${campaignId}`, {
        name,
        description,
        postLinks: links.map((link) => {
          if (link.includes("https://")) return link.replace("https://", "");
          return link;
        }),
      });
      confirm({
        title: formatMessage({ id: "popup.campaign.update.title" }),
        content: formatMessage({ id: "popup.campaign.update.description" }),
        confirmText: formatMessage({ id: "popup.viewCampaign.btn" }),
        cancelText: formatMessage({ id: "popup.backToList.btn" }),
        onConfirm: () =>
          navigateEntity(`/marketing/xCampaign/performance/${campaignId}`),
        onCancel: () => navigateEntity("/marketing/xCampaign"),
        color: "secondary",
        cancelColor: "secondary",
      })
        .then()
        .catch(() => {});
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Back />
      <Loading open={isLoading} fullScreen={false} />
      <div className="title">
        {formatMessage({ id: "xCampaignTracking.edit.title" })}
      </div>
      <div className="description">
        {formatMessage({ id: "xCampaignTracking.edit.description" })}
      </div>
      <StyledWhiteContainer sx={{ mt: "24px", padding: "32px" }}>
        <InputField
          title={formatMessage({
            id: "XCampaignTracking.createNew.campaignName.title",
          })}
          placeholder={formatMessage({
            id: "xCampaignTracking.domain.placeholder",
          })}
          onChange={handleChange}
          name="name"
          value={data.name}
          maxLength={30}
          isRequired
          sx={{ maxWidth: "662px" }}
          rules={{
            required: {
              value: true,
              message:
                formatMessage({ id: "global.commonWord.name" }) +
                formatMessage({ id: "global.error.required" }),
            },
          }}
          control={control}
          errors={errorsInfo.name?.message}
        />
        <Box height={24} />

        <InputField
          title={formatMessage({
            id: "XCampaignTracking.createNew.description.title",
          })}
          placeholder={formatMessage({
            id: "xCampaignTracking.domain.placeholder",
          })}
          onChange={handleChange}
          name="description"
          value={data.description}
          maxLength={300}
          multiline
          isRequired
          rows={5}
          sx={{ maxWidth: "662px" }}
          rules={{
            required: {
              value: true,
              message: `Description ${formatMessage({
                id: "global.error.required",
              })}`,
            },
          }}
          control={control}
          errors={errorsInfo.description?.message}
        />
        <Box height={24} />

        <Box mb="4px" sx={{ color: "#555" }}>
          {formatMessage({ id: "XCampaignTracking.createNew.xPostLink.title" })}
        </Box>
        <Box sx={{ maxHeight: "260px", overflowY: "auto", maxWidth: "685px" }}>
          {data.links.map((linkItem, index) => (
            <>
              <InputField
                placeholder="example.com"
                onChange={(e) => handleChangeLink(e, index)}
                name="links"
                value={linkItem.postLink}
                prefixWord="https://"
                sx={{
                  maxWidth: "662px",
                }}
                disabled={!linkItem.isNew}
                endAdornment={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleDeleteLink(index);
                    }}
                  >
                    <i
                      className="meta-crm-icon-ic_trash font-size-20"
                      style={{ color: "#A5A5A5" }}
                    />
                  </Box>
                }
                errors={errorsInfo[`links${index}`]?.message}
              />
              <Box height={data.links.length === 1 ? "24px" : "12px"} />
            </>
          ))}
        </Box>
        {data.links.length < 200 && (
          <AddButton
            onClick={() => {
              setData((prev) => ({
                ...prev,
                links: [
                  ...prev.links,
                  {
                    postLink: "",
                    isNew: true,
                  },
                ],
              }));
            }}
            color="#7B61FF"
            borderColor="#7B61FF"
            sx={{ mb: "24px", maxWidth: "662px" }}
          >
            {formatMessage({ id: "global.btn.addLink" })}
          </AddButton>
        )}
      </StyledWhiteContainer>

      <FixedButton>
        <Box className="item-flex">
          <Box color="#A5A5A5" mr="12px">
            Tracking Link : {data.links.length}
          </Box>
          <Button
            sx={{ mr: "12px", minWidth: "120px" }}
            variant="outlined"
            disabled={isEqual(data, initDataRef.current)}
            onClick={() => {
              setData(initDataRef.current);
            }}
          >
            {formatMessage({ id: "global.btn.discard" })}
          </Button>
          <Button
            disabled={
              data.name === "" ||
              data.description === "" ||
              !data.links.every((link) => link.postLink !== "") ||
              Object.values(errorsInfo).length > 0
            }
            sx={{ minWidth: "120px" }}
            onClick={handleUpdateCampaign}
          >
            {formatMessage({ id: "global.btn.update" })}
          </Button>
        </Box>
      </FixedButton>
    </Box>
  );
}
