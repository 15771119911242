import React from "react";
import ReactECharts from "echarts-for-react";
import { Box } from "@mui/material";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { rainbowColors } from "utils/constants";
import BigNumber from "bignumber.js";
import { customerColors } from "features/configure/chartColors";

export default function TreeMapChart({
  data,
  loading = false,
  chartName = "Chain Portfolio",
  type = "number",
}) {
  const colors = customerColors.large;

  const totalValue = data.reduce((sum, item) => sum + parseInt(item.value), 0);
  let updateData = data.map((item) => {
    return {
      name: item.name || item.label,
      value: parseFloat(
        (100 * (parseFloat(item.value) / totalValue)).toFixed(1)
      ),
    };
  });

  function formatter(params, tooltip = false) {
    const value = new BigNumber(params.value).toFormat(0);
    const name = params.name;
    let reg = tooltip ? ": " : "\n";
    if (type === "money") return `${name}${reg}$ ${value}`;
    if (type === "percentage") return `${name}${reg}${value}%`;
    return `${name}${reg}${value}`;
  }

  let percentage = type === "percentage";

  const option = {
    color: colors,
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        return formatter(params, true);
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: "Active Chain",
        type: "treemap",
        roam: false,
        nodeClick: false,
        label: {
          show: true,
          formatter: (params) => {
            return formatter(params);
          },
          color: "#383838",
          fontSize: 12,
          fontWeight: 500,
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 2,
        },
        data: percentage ? updateData : data,
        breadcrumb: {
          show: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        flex: 1,
        height: "300px",
        backgroundColor: "#FFF",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          fontWeight: "500",
          color: "#555555",
          fontSize: "16px",
          pt: "22px",
          pl: "16px",
        }}
      >
        {chartName}
      </Box>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ReactECharts
          option={option}
          autoResize={true}
          style={{ height: "250px", width: "100%", mt: "12px" }}
          notMerge={true}
        />
      )}
    </Box>
  );
}
