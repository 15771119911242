import PopperMenu from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import { Box } from "@mui/material";
import useNavigateEntity from "hooks/useNavigateEntity";
import { useRef, useState } from "react";

export default function XPopMenu({ xId, handleDelete }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { navigateEntity } = useNavigateEntity();
  const handleClose = () => {
    setOpen(false);
  };

  const itemList = [
    {
      children: <>Edit</>,
      func: (e) => {
        e.stopPropagation();
        navigateEntity(`/marketing/xCampaign/add/${xId}`);
      },
    },
    {
      children: <>Delete</>,
      func: (e) => {
        e.stopPropagation();
        handleDelete();
      },
    },
  ];

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <i className="meta-crm-icon-ic_more font-size-32" />
      </Box>

      <PopperMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        itemList={itemList}
      />
    </>
  );
}
